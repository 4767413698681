import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
// components
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import Hover from "../../../aside/Hover";
import FormComponent from "../../../common/FormComponent";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
} from "../../../common/Validator";
// redux
import { getLeaveManagement } from "../../../../redux/actions/hcm/lms/leaveManagementAction";
import { CURD_LEAVE_MANAGEMENT } from "../../../../utils/routes";

class ViewLeave extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      searchTerm: "",
      cords: {},
      mouse: {},
      id: "",
      mngLeaveRemarks: "",
      appLeaveModal: false,
      rejectLeaveModal: false,
      message: "",
      error: "",
    };
    this.toggleHoverOn = this.toggleHoverOn.bind(this);
    this.toggleHoverOff = this.toggleHoverOff.bind(this);
    this.getData = this.getData.bind(this);
    this.search = this.search.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  /*
getData function for the fetching all the leaves details
*/
  getData() {
    this.props.getLeaveManagement();
  }

  toggleHoverOn(e, item) {
    this.setState({
      hover: true,
      cords: {
        one: item.leaveType,
        two: item.leaveStatus,
        three: item.leaveRemarks,
      },
      mouse: {
        x: e.screenX,
        y: e.screenY,
      },
    });
  }

  toggleHoverOff() {
    this.setState({
      hover: false,
    });
  }

  /*
search funtion for searching the leave list based on the empId and empName
*/
  search(e) {
    e.preventDefault();
    this.setState({
      searchTerm: e.target.value,
    });
    this.onSearch(e.target.value);
  }

  onSearch(value) {
    let data = {
      pieUserId: value,
    };
    axios
      .post(SEARCH_LEAVE, data, { withCredentials: true })
      .then((res) => {
        this.setState({
          data: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          error: "Search error",
        });
      });
  }

  /*
approveLeave function for updating the leaveStatus field if admin approved the leave
*/
  approveLeave(e) {
    e.preventDefault();
    let data = {
      id: this.state.id,
      leaveStatus: "Approved",
      managerLeaveRemarks: this.state.mngLeaveRemarks,
    };
    if (valid) {
      axios
        .put(CURD_LEAVE_MANAGEMENT, data, { withCredentials: true })
        .then((res) => {
          this.setState({
            message: res.data.message,
          });
        })
        .catch((err) => {
          this.setState({
            error: "update failed",
          });
        });
      setTimeout(() => {
        this.setState({
          message: "",
          error: "",
        });
        this.getData();
        this.approveLeaveModal(this.state.id);
      }, 2000);
    } else {
      this.setState({
        error: "Enter all Mandatory Fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
rejectLeave function for updating the leaveStatus after the admin rejected the leave application
*/
  rejectLeave(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      id: this.state.id,
      leaveStatus: "Rejected",
      managerLeaveRemarks: this.state.mngLeaveRemarks,
    };
    if (valid) {
      axios
        .put(CURD_LEAVE_MANAGEMENT, data, { withCredentials: true })
        .then((res) => {
          this.setState({
            message: res.data.message,
          });
        })
        .catch((err) => {
          this.setState({
            error: "update failed",
          });
        });
      setTimeout(() => {
        this.setState({
          message: "",
          error: "",
        });
        this.getData();
        this.rejectLeaveModal(this.state.id);
      }, 2000);
    } else {
      this.setState({
        error: "Enter all Mandatory Fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /* approveLeaveModal function for toggling the approveLeaveModal for capturing the managerLeaveRemarks */
  approveLeaveModal = (item) => {
    refresh();
    this.setState({
      appLeaveModal: !this.state.appLeaveModal,
      id: item.id,
      mngLeaveRemarks: "",
    });
  };

  /* rejectLeaveModal function for toggling the rejectLeaveModal for capturing the managerLeaveRemarks */
  rejectLeaveModal = (item) => {
    refresh();
    this.setState({
      rejectLeaveModal: !this.state.rejectLeaveModal,
      id: item.id,
      mngLeaveRemarks: "",
    });
  };

  render() {
    const { mngLeaveRemarks } = this.state;
    let shrink = this.props.sidebar ? "scale" : "no-scale";

    return (
      <div className={shrink}>
        <Container className="container-card card pt-2">
          <Link
            to={{
              pathname: "/pie/iam/service_menu",
              moduleName: this.props.location.moduleName,
              data: this.props.location.data,
            }}
          >
            <i className="material-icons cancel-button pointer">cancel</i>
          </Link>
          <h4 className="bold center">Manage Leaves</h4>
          <input
            type="text"
            onChange={this.search}
            value={this.state.searchTerm}
            className="leaveSearchfield form-control-sm"
            placeholder="Search..."
          />
          {this.state.hover && (
            <Hover
              labels={{
                one: "Leave Type",
                two: "Leave Status",
                three: "Leave Remarks",
              }}
              cords={this.state.cords}
              mouse={this.state.mouse}
            />
          )}
          <Row className="pt-3">
            {this.props.leaveManagement.data.length > 0 &&
              this.props.leaveManagement.data.map((item) => {
                if (item.leaveStatus === "PENDING")
                  return (
                    <Col md="4" key={item.id}>
                      <Card className="p-3 mb-2 shadow pointer">
                        <p className="list-item flex-l">
                          Emp Name :{" "}
                          <span className="blue-text">{item.empName}</span>
                        </p>
                        <p className="list-item flex-1">
                          From :{" "}
                          <span className="blue-text">{item.fromDate}</span>{" "}
                          &nbsp;&nbsp; To :{" "}
                          <span className="blue-text">{item.toDate}</span>
                        </p>
                        <Row>
                          <Col md="4">
                            <button
                              id="lms-approve-button"
                              onClick={() => {
                                this.approveLeaveModal(item);
                              }}
                            >
                              Approve
                            </button>
                          </Col>
                          <Col md="4">
                            <button
                              id="lms-reject-button"
                              onClick={() => {
                                this.rejectLeaveModal(item);
                              }}
                            >
                              Reject
                            </button>
                          </Col>
                          <Col md="4">
                            <button
                              id="lms-details-button"
                              onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                              onMouseLeave={this.toggleHoverOff}
                            >
                              Details
                            </button>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  );
              })}
          </Row>
          {this.state.message && <Notifier message={this.state.message} />}
          {this.state.error && <ErrorNotifier message={this.state.error} />}
        </Container>
        <Modal
          isOpen={this.state.appLeaveModal}
          centered
          style={{ marginLeft: "530px" }}
        >
          <ModalHeader toggle={this.approveLeaveModal}></ModalHeader>
          <ModalBody>
            <Form
              className="p-3"
              onSubmit={(e) => this.approveLeave(e)}
              noValidate
            >
              <FormComponent
                labelClass="bold"
                label="Manager Leave Remarks"
                type="textarea"
                name="mngLeaveRemarks"
                change={this.onChange}
                value={mngLeaveRemarks}
                required={true}
              />
              <FormGroup className="center">
                <button type="submit" className="btn login-button white-text">
                  Submit
                </button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.rejectLeaveModal}
          centered
          style={{ marginLeft: "530px" }}
        >
          <ModalHeader toggle={this.rejectLeaveModal}></ModalHeader>
          <ModalBody>
            <Form
              className="p-3"
              onSubmit={(e) => this.rejectLeave(e)}
              noValidate
            >
              <FormComponent
                labelClass="bold"
                label="Manager Leave Remarks"
                type="textarea"
                name="mngLeaveRemarks"
                change={this.onChange}
                value={mngLeaveRemarks}
                required={true}
              />
              <FormGroup className="center">
                <button type="submit" className="btn login-button white-text">
                  Submit
                </button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

ViewLeave.propTypes = {
  getLeaveManagement: PropTypes.func.isRequired,
  leaveManagement: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  leaveManagement: state.leaveManagement,
});

export default connect(mapStateToProps, { getLeaveManagement })(ViewLeave);
