import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { EMPLOYEE_FETCH1 } from "../../../../utils/routes";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import { Col, Row, Container } from "reactstrap";
import DateFns from "date-fns";

/**
 * @class EmployeeInfo
 **/

class EmployeeInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      error: "",
    };
  }

  componentDidMount() {
    let data = {
      empId: this.props.empId,
    };

    axios
      .post(EMPLOYEE_FETCH1, data, { withCredentials: true })
      .then((res) => {
        this.setState({
          data: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          error: err.message,
        });
        setTimeout(() => {
          this.setState({
            error: "",
          });
        }, 2000);
      });
  }

  render() {
    const { error, data } = this.state;
    return (
      <React.Fragment>
        <i
          onClick={this.props.back}
          className="material-icons pointer cancel-button"
        >
          cancel
        </i>
        <Col md="4" className="employee-info-col-one">
          <h5 className="bold employee-info-head">Contact Details</h5>
          <ul className="employee-info-ul">
            <li>
              <span className="label-sm bold ">Home Contact No.</span>
              <span className=" label-sm">
                &nbsp;&nbsp;:&nbsp;&nbsp;
                {data.length
                  ? data[0].ContactDetails.length
                    ? data[0].ContactDetails[0].homeContactNo
                    : null
                  : null}
              </span>
            </li>
            <li>
              <span
                className="label-sm bold "
                id="employee-info-alternative-no"
              >
                Alternative No.
              </span>
              <span className=" label-sm">
                &nbsp;&nbsp;:&nbsp;&nbsp;
                {data.length
                  ? data[0].ContactDetails.length
                    ? data[0].ContactDetails[0].alternativeNo
                    : null
                  : null}
              </span>
            </li>
          </ul>
        </Col>
        <Col md="5" className="employee-info-col-two">
          <h5 className="bold center employee-info-head">Address</h5>
          <ul className="employee-info-ul">
            <li>
              <span className="label-sm bold">Communication Address</span>
              <span className=" label-sm">
                &nbsp;&nbsp;:&nbsp;&nbsp;
                <span className="label-sm ">
                  {data.length
                    ? data[0].ContactDetails.length
                      ? data[0].ContactDetails[0].communicationAddress
                      : null
                    : null}
                </span>
              </span>
            </li>
            <li>
              <span
                className="label-sm bold"
                id="employee-info-address-two-span"
              >
                Permanent Address
              </span>
              <span className=" label-sm">
                &nbsp;&nbsp;:&nbsp;&nbsp;
                <span className="label-sm ">
                  {data.length
                    ? data[0].ContactDetails.length
                      ? data[0].ContactDetails[0].permanentAddress
                      : null
                    : null}
                </span>
              </span>
            </li>
          </ul>
        </Col>
        <Col md="4" className="employee-info-col-three">
          <h5 className="bold center employee-info-head">
            Emergency Contact Details
          </h5>
          <ul className="employee-info-ul">
            <li>
              <span className="label-sm bold ">Contact Name</span>
              <span className=" label-sm">
                &nbsp;&nbsp;:&nbsp;&nbsp;
                <span className="label-sm">
                  {data.length
                    ? data[0].ContactDetails.length
                      ? data[0].ContactDetails[0].contactName
                      : null
                    : null}
                </span>
              </span>
            </li>
            <li>
              <span className="label-sm bold ">Contact No.</span>
              <span className=" label-sm">
                &nbsp;&nbsp;:&nbsp;&nbsp;
                <span className="label-sm">
                  {data.length
                    ? data[0].ContactDetails.length
                      ? data[0].ContactDetails[0].emerContactNo
                      : null
                    : null}
                </span>
              </span>
            </li>
            <li>
              <span className="label-sm bold ">Relation</span>
              <span className=" label-sm">
                &nbsp;&nbsp;:&nbsp;&nbsp;
                <span className="label-sm">
                  {data.length
                    ? data[0].ContactDetails.length
                      ? data[0].ContactDetails[0].relation
                      : null
                    : null}
                </span>
              </span>
            </li>
          </ul>
        </Col>
        <Row className="employee-info-row">
          <Col md="4" className="employee-info-row-two-col-one">
            <h5 className="bold employee-info-head">Other Identifiers</h5>
            <ul className="employee-info-ul">
              <li>
                <span className="label-sm bold" id="employee-info-aadhar-span">
                  Aadhar No.
                </span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  {data.length
                    ? data[0].Passportdetails.length
                      ? data[0].Passportdetails[0].aadharNo
                      : null
                    : null}
                </span>
              </li>
              <li>
                <span className="label-sm bold">Social Security</span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  {data.length
                    ? data[0].Passportdetails.length
                      ? data[0].Passportdetails[0].socialSecurity
                      : null
                    : null}
                </span>
              </li>
            </ul>
          </Col>
          <Col md="5" className="employee-info-row-two-col-two">
            <h5 className="bold center employee-info-head">Passport Details</h5>
            <Row>
              <Col md="6" className="employee-info-inside-row-col">
                <ul className="employee-info-ul">
                  <li>
                    <span className="label-sm bold employee-info-data-one">
                      Country Name
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      <span>
                        {data.length
                          ? data[0].Passportdetails.length
                            ? data[0].Passportdetails[0].countryName
                            : null
                          : null}
                      </span>
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold employee-info-data-one">
                      Passport No.
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      <span>
                        {data.length
                          ? data[0].Passportdetails.length
                            ? data[0].Passportdetails[0].passportNumber
                            : null
                          : null}
                      </span>
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold employee-info-data-one">
                      Remarks
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      <span>
                        {data.length
                          ? data[0].Passportdetails.length
                            ? data[0].Passportdetails[0].remarks
                            : null
                          : null}
                      </span>
                    </span>
                  </li>
                </ul>
              </Col>
              <Col md="6" className="employee-info-inside-row-col">
                <ul className="employee-info-ul">
                  <li>
                    <span className="label-sm bold employee-info-data-two">
                      Issue place
                    </span>
                    <span className=" label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      <span>
                        {data.length
                          ? data[0].Passportdetails.length
                            ? data[0].Passportdetails[0].issuePlace
                            : null
                          : null}
                      </span>
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold employee-info-data-two">
                      Issue Date
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      <span>
                        {data.length
                          ? data[0].Passportdetails.length
                            ? DateFns.format(
                                data[0].Passportdetails[0].issueDate,
                                "DD-MM-YYYY"
                              )
                            : null
                          : null}
                      </span>
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold employee-info-data-two">
                      Expiry Date
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      <span>
                        {data.length
                          ? data[0].Passportdetails.length
                            ? DateFns.format(
                                data[0].Passportdetails[0].expiryDate,
                                "DD-MM-YYYY"
                              )
                            : null
                          : null}
                      </span>
                    </span>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
          <Col md="4" className="employee-info-col-four">
            <h5 className="bold center employee-info-head">
              Family Contact Details
            </h5>
            <ul className="employee-info-ul">
              <li>
                <span className="bold label-sm">Spouse Name</span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  <span className="label-sm">
                    {data.length
                      ? data[0].ContactDetails.length
                        ? data[0].ContactDetails[0].spouseName
                        : null
                      : null}
                  </span>
                </span>
              </li>
              <li>
                <span className="bold label-sm">Spouse Contact No.</span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  <span className="label-sm">
                    {data.length
                      ? data[0].ContactDetails.length
                        ? data[0].ContactDetails[0].spouseContactNo
                        : null
                      : null}
                  </span>
                </span>
              </li>
              <li>
                <span className="bold label-sm">Father's Name</span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  <span className="label-sm">
                    {data.length
                      ? data[0].ContactDetails.length
                        ? data[0].ContactDetails[0].fatherName
                        : null
                      : null}
                  </span>
                </span>
              </li>
              <li>
                <span className="bold label-sm">Father's Contact No.</span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  <span className="label-sm">
                    {data.length
                      ? data[0].ContactDetails.length
                        ? data[0].ContactDetails[0].fatherContactNo
                        : null
                      : null}
                  </span>
                </span>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="employee-info-row">
          <Col md="4" className="employee-info-row-three-col-one">
            <h5 className="bold center employee-info-head">Bank Information</h5>
            <ul className="employee-info-ul">
              <li>
                <span className="label-sm bold employee-info-row-three-col-one-span">
                  Account No.
                </span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  <span className="label-sm">
                    {data.length
                      ? data[0].Employeebankinfo.length
                        ? data[0].Employeebankinfo[0].accountNo
                        : null
                      : null}
                  </span>
                </span>
              </li>
              <li>
                <span className="label-sm bold employee-info-row-three-col-one-span">
                  Account Type
                </span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  <span className="label-sm">
                    {data.length
                      ? data[0].Employeebankinfo.length
                        ? data[0].Employeebankinfo[0].accountType
                        : null
                      : null}
                  </span>
                </span>
              </li>
              <li>
                <span className="label-sm bold employee-info-row-three-col-one-span">
                  IFSC Code
                </span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  <span className="label-sm">
                    {data.length
                      ? data[0].Employeebankinfo.length
                        ? data[0].Employeebankinfo[0].ifscCode
                        : null
                      : null}
                  </span>
                </span>
              </li>
            </ul>
          </Col>
          <Col md="5" className="employee-info-row-three-col-two">
            <h5 className="bold center employee-info-head">Bank Information</h5>
            <Row>
              <Col md="12" className="employee-info-inside-row-col">
                <ul className="employee-info-ul">
                  <li>
                    <span className="label-sm bold">Account Name</span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      <span className="label-sm">
                        {data.length
                          ? data[0].Employeebankinfo.length
                            ? data[0].Employeebankinfo[0].accountName
                            : null
                          : null}
                      </span>
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold">Bank Name</span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      <span className="label-sm">
                        {data.length
                          ? data[0].Employeebankinfo.length
                            ? data[0].Employeebankinfo[0].bankName
                            : null
                          : null}
                      </span>
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold">Branch Name</span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      <span className="label-sm">
                        {data.length
                          ? data[0].Employeebankinfo.length
                            ? data[0].Employeebankinfo[0].branchName
                            : null
                          : null}
                      </span>
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold">Branch Address</span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      <span className="label-sm">
                        {data.length
                          ? data[0].Employeebankinfo.length
                            ? data[0].Employeebankinfo[0].branchAddress
                            : null
                          : null}
                      </span>
                    </span>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
          <Col md="4" className="employee-info-row-three-col-three">
            <h5 className="bold employee-info-head">Other Account Details</h5>
            <ul className="employee-info-ul">
              <li>
                <span className="label-sm bold">PAN Card No.</span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  {data.length
                    ? data[0].Employeebankinfo.length
                      ? data[0].Employeebankinfo[0].pan
                      : null
                    : null}
                </span>
              </li>
              <li>
                <span className="label-sm bold">ESI No.</span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  {data.length
                    ? data[0].Employeebankinfo.length
                      ? data[0].Employeebankinfo[0].esiNumber
                      : null
                    : null}
                </span>
              </li>
              <li>
                <span className="label-sm bold">UAN No.</span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  {data.length
                    ? data[0].Employeebankinfo.length
                      ? data[0].Employeebankinfo[0].uan
                      : null
                    : null}
                </span>
              </li>
              <li>
                <span className="label-sm bold">PF Account No.</span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  {data.length
                    ? data[0].Employeebankinfo.length
                      ? data[0].Employeebankinfo[0].pfNo
                      : null
                    : null}
                </span>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="employee-info-row">
          <Container>
            <h5 className="bold center employee-info-head">
              Education Information
            </h5>
            <Row>
              {data.length
                ? data[0].Education.length
                  ? data[0].Education.map((item) => {
                      return (
                        <Col
                          md="4"
                          key={item.empEduId}
                          className="employee-info-certification-col"
                        >
                          <ul className="employee-info-ul">
                            <li>
                              <span className="label-sm bold">
                                Qualification
                              </span>
                              <span className=" label-sm">
                                &nbsp;&nbsp;:&nbsp;&nbsp;
                                <span className="label-sm">{item.empQual}</span>
                              </span>
                            </li>
                            <li>
                              <span className="label-sm bold">
                                Specialization
                              </span>
                              <span className=" label-sm">
                                &nbsp;&nbsp;:&nbsp;&nbsp;
                                <span className="label-sm">
                                  {item.empSpeci}
                                </span>
                              </span>
                            </li>
                            <li>
                              <span className="label-sm bold">
                                Institute Name
                              </span>
                              <span className=" label-sm">
                                &nbsp;&nbsp;:&nbsp;&nbsp;
                                <span className="label-sm">
                                  {item.instiName}
                                </span>
                              </span>
                            </li>
                            <li>
                              <span className="label-sm bold">
                                University Name
                              </span>
                              <span className=" label-sm">
                                &nbsp;&nbsp;:&nbsp;&nbsp;
                                <span className="label-sm">
                                  {item.univName}
                                </span>
                              </span>
                            </li>
                            <li>
                              <span className="label-sm bold">
                                Completed Date
                              </span>
                              <span className=" label-sm">
                                &nbsp;&nbsp;:&nbsp;&nbsp;
                                <span className="label-sm">
                                  {DateFns.format(item.compDate, "DD-MM-YYYY")}
                                </span>
                              </span>
                            </li>
                            <li>
                              <span className="label-sm bold">Grade</span>
                              <span className=" label-sm">
                                &nbsp;&nbsp;:&nbsp;&nbsp;
                                <span className="label-sm">{item.grade}</span>
                              </span>
                            </li>
                            <li>
                              <span className="label-sm bold">Percentage</span>
                              <span className=" label-sm">
                                &nbsp;&nbsp;:&nbsp;&nbsp;
                                <span className="label-sm">
                                  {item.percentage}
                                </span>
                              </span>
                            </li>
                          </ul>
                        </Col>
                      );
                    })
                  : null
                : null}
            </Row>
          </Container>
        </Row>
        <Container>
          <h5 className="bold center employee-info-head">
            Certification Details
          </h5>
          <Row>
            {data.length
              ? data[0].Certification.length
                ? data[0].Certification.map((item) => {
                    return (
                      <Col
                        md="4"
                        key={item.empCertId}
                        className="employee-info-certification-col"
                      >
                        <ul className="employee-info-ul">
                          <li>
                            <span className="label-sm bold">
                              Certification Name
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span className="label-sm">{item.empCert}</span>
                            </span>
                          </li>
                          <li>
                            <span className="label-sm bold">Version</span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span className="label-sm">{item.version}</span>
                            </span>
                          </li>
                          <li>
                            <span className="label-sm bold">
                              Specialization
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span className="label-sm">{item.empSpeci}</span>
                            </span>
                          </li>
                          <li>
                            <span className="label-sm bold">
                              Institute Name
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span className="label-sm">{item.certName}</span>
                            </span>
                          </li>
                          <li>
                            <span className="label-sm bold">
                              Completed Date
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span className="label-sm">
                                {DateFns.format(item.compDate, "DD-MM-YYYY")}
                              </span>
                            </span>
                          </li>
                          <li>
                            <span className="label-sm bold">Valid Until</span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span className="label-sm">
                                {DateFns.format(item.validUntil, "DD-MM-YYYY")}
                              </span>
                            </span>
                          </li>
                          <li>
                            <span className="label-sm bold">Grade</span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span className="label-sm">{item.grade}</span>
                            </span>
                          </li>
                          <li>
                            <span className="label-sm bold">Percentage</span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span className="label-sm">
                                {item.percentage}
                              </span>
                            </span>
                          </li>
                        </ul>
                      </Col>
                    );
                  })
                : null
              : null}
          </Row>
        </Container>
        <Container>
          <h5 className="bold center employee-info-head">Employment Details</h5>
          <Row>
            {data.length
              ? data[0].Employeeprof.length
                ? data[0].Employeeprof.map((item) => {
                    return (
                      <Col
                        md="4"
                        key={item.empProfId}
                        className="employee-info-certification-col"
                      >
                        <ul className="employee-info-ul">
                          <li>
                            <span className="label-sm bold">Employer Name</span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span className="label-sm">
                                {item.employerName}
                              </span>
                            </span>
                          </li>
                          <li>
                            <span className="label-sm bold">Designation</span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span className="label-sm">
                                {item.designation}
                              </span>
                            </span>
                          </li>
                          <li>
                            <span className="label-sm bold">From Date</span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span className="label-sm">
                                {DateFns.format(item.fromDate, "DD-MM-YYYY")}
                              </span>
                            </span>
                          </li>
                          <li>
                            <span className="label-sm bold">To Date</span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span className="label-sm">
                                {DateFns.format(item.toDate, "DD-MM-YYYY")}
                              </span>
                            </span>
                          </li>
                          <li>
                            <span className="label-sm bold">No of Years</span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span className="label-sm">{item.noOfYears}</span>
                            </span>
                          </li>
                          <li>
                            <span className="label-sm bold">Address</span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span className="label-sm">{item.address}</span>
                            </span>
                          </li>
                        </ul>
                      </Col>
                    );
                  })
                : null
              : null}
          </Row>
        </Container>
        {error && <ErrorNotifier message={error} />}
      </React.Fragment>
    );
  }
}

EmployeeInfo.propTypes = {};
export default EmployeeInfo;
