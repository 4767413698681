import axios from "axios";
import {
  REQUESTS_GET,
  CLOSED_REQUESTS_GET,
  REQUESTS_FETCH,
  REQUESTS_SEARCH,
  VIEW_REQUESTS_SEARCH,
} from "../types";
import {
  CURD_REQUESTS,
  GET_CLOSED_REQUESTS,
  SEARCH_REQUESTS,
  SEARCH_VIEW_REQUESTS,
} from "../../../utils/routes";
import setSuccessMessage from "../common/setSuccessMessage";
import setErrorMessage from "../common/setErrorMessage";
import getDispatch from "../common/getDispatch";

// -------------------------------------------------------------------------------------------

export const addRequests = (data, history) => (dispatch) => {
  const link = "/pie/experience_360/requests";
  axios
    .post(CURD_REQUESTS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, null, history, link));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const searchRequests = (data) => (dispatch) => {
  axios
    .post(SEARCH_REQUESTS, data, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(REQUESTS_SEARCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const searchViewRequests = (data) => (dispatch) => {
  axios
    .post(SEARCH_VIEW_REQUESTS, data, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(VIEW_REQUESTS_SEARCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getRequests = () => (dispatch) => {
  axios
    .get(CURD_REQUESTS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(REQUESTS_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getClosedRequests = () => (dispatch) => {
  axios
    .get(GET_CLOSED_REQUESTS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CLOSED_REQUESTS_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const fetchRequests = (data) => (dispatch) => {
  axios
    .get(`${CURD_REQUESTS}/${data}`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(REQUESTS_FETCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateRequests = (data, back) => (dispatch) => {
  axios
    .put(CURD_REQUESTS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getRequests));
      dispatch(getClosedRequests());
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteRequests = (data) => (dispatch) => {
  axios
    .delete(CURD_REQUESTS, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getRequests));
      dispatch(getClosedRequests);
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
