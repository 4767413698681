import axios from "axios";
import { GET_JOB_BOARD_RSTYPE } from "../../types";
import {
  ADD_JOB_BOARD_RS,
  GET_JOB_BOARD_RS,
  UPDATE_JOB_BOARD_RS,
  DELETE_JOB_BOARD_RS
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addJobBoard = (data, back) => (dispatch) => {
  axios
    .post(ADD_JOB_BOARD_RS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back,  getJobBoard ));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getJobBoard  = () => (dispatch) => {
  axios
    .get( GET_JOB_BOARD_RS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(GET_JOB_BOARD_RSTYPE, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateJobBoard  = (data, back) => (dispatch) => {
  axios
    .put( UPDATE_JOB_BOARD_RS , data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteJobBoard = (data) => (dispatch) => {
  axios
    .delete( DELETE_JOB_BOARD_RS, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null,  getJobBoard ));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
