import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
// import DateTimePicker from '@mui/material/DateTimePicker';
// import TextField from '@mui/material/TextField';
// import AdapterDateFns from '@mui/material/AdapterDateFns';
// import Stack from '@mui/material/Stack';
// import LocalizationProvider from '@mui/material/LocalizationProvider';
import {
  Row,
  Col,
  Form,
  Container,
  FormGroup,
  Input,
  Label,
  FormText,
} from "reactstrap";
import axios from "axios";
import DateFns from "date-fns";
import {
  FETCHBYID,
  TAG_SEARCH_CANDI,
  ADD_APPLY_JOBS,
} from "../../../../utils/routes";
import FormComponent from "../../../common/FormComponent";
import DescriptionIcon from '@material-ui/icons/Description';
import Searchbar from "./searchbar/SearchBar";
import CustTooltip from "../../../common/tooltip/CustTooltip";
import ViewCommemts from "./viewComments"

//   redux
import { AddTagRequirement } from "../../../../redux/actions/rs/requirements/tagCandidate/tagCandidate";
import { getCandidateStatusRs } from "../../../../redux/actions/rs/configuration/candidateStausAction";
import { getHiringStatSelect } from "../../../../redux/actions/rs/configuration/hiringStatus";
var isValid = true;
class AddTagCandidate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changeError: "",
      storeData: "",
      candiStatus: "Hiring Stage",
      hiringStage: "Profile Screening",
      hiringStatus: "Shortlisted",
      hiringStagDate:"",
      hiringStagRating:"",
      hiringFeedBack:"",
      joiningDate: "",
      Tagremarks: "",
      errorTagCan: "",
      errorInSearch: "",
      searchValue: "",
      canDataUserId: "",
      filterHiringStage: [],
      filterHiringStatus: [],
      error: "",
      message: "",
      isOpen:false
    };

    this.searchClick = this.searchClick.bind(this);
    this.handleSubmitData = this.handleSubmitData.bind(this);
    this.searchChange = this.searchChange.bind(this);
    this.searchHiringStatus = this.searchHiringStatus.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleViewComments = this.handleViewComments.bind(this)
    this.backViewComments = this.backViewComments.bind(this)
  }
  searchChange(e) {
    this.setState({
      storeData: "",
    });
    this.setState({
      searchValue: e.target.value,
      changeError: "",
      errorInSearch: "",
    });
  }

  onChange(e) {
    if (e.target.name === "candiStatus") {
      if (e.target.value !== "Hiring Stage") {
        this.setState({
          candiStatus: e.target.value,
          hiringStatus: "",
          hiringStage: "",
        });
      } else if (e.target.value === "Hiring Stage") {
        this.searchHiringStatus(e.target.value);
        this.setState({
          candiStatus: e.target.value,
          hiringStage: "Profile Screening",
          hiringStatus: "Shortlisted",
        });
      }
    } else if (e.target.name === "hiringStage") {
      this.searchHiringStatus(e.target.value);
      this.setState({
        hiringStage: e.target.value,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  searchHiringStatus(data) {
    this.props.hiringStatus.mainData.find((val) => {
      if (val.hiringStageName === data) {
        this.setState({
          filterHiringStatus: val.hiringStatus,
        });
      }
    });
  }

handleViewComments(){
this.setState({
  isOpen:!this.state.isOpen
})
}

backViewComments(){
this.setState({
  isOpen:false
})
}


  searchClick() {
    if (!this.state.searchValue) {
      this.setState({
        changeError: "Please enter keyword to search",
        errorInSearch: "",
      });
    } else {
      this.setState({
        changeError: "",
      });

      if (Number(this.state.searchValue)) {
        const data = {
          search: this.state.searchValue,
        };
        console.log(data);
        axios
          .get(`${TAG_SEARCH_CANDI}/rs/${data.search}`, data, {
            withCredentials: true,
          })
          .then((res) => {
            console.log(res.data);
            if (!res.data.hasOwnProperty("ok")) {
              this.setState({
                storeData: res.data.id,
                canDataUserId: res.data.canUserId,
                errorInSearch: "",
                changeError: "",
              });
            } else if (!res.data.ok) {
              this.setState({
                changeError: "Candidate not available",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        const data = {
          search: this.state.searchValue,
        };
        axios
          .post(`${TAG_SEARCH_CANDI}`, data, { withCredentials: true })
          .then((res) => {
            console.log(res.data);
            if (res.data.length > 0) {
              this.setState({
                storeData: res.data[0].id,
                canDataUserId: res.data[0].canUserId,
                errorInSearch: "",
                changeError: "",
                // searchIdValue:this.state.storeData
              });
            } else {
              this.setState({
                changeError: "Data not available",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }
  handleSubmitData() {
    console.log(this.state.storeData);
      if ( this.props.isShowSearchBar && !this.state.storeData) {
      this.setState({
        errorInSearch: "Please enter keyword to search",
        changeError: "",
      });
      isValid = false;
    } else {
      isValid = true;
    }
    if (!this.state.candiStatus) {
      this.setState({
        errorTagCan: "This field is mandatory",
      });
      isValid = false;
    }
    let data = {
      jobReqirementId: Number(this.props.requirementId?this.props.requirementId:this.props.reqType),
      canUserId: this.state.canDataUserId ? this.state.canDataUserId  :  this.props.canUserId,
      canInfoId: Number(this.state.storeData?this.state.storeData : this.props.canInfoId),
      candidateSource: this.state.storeData?"Tagging" : "Auto Match",
      candidateStatus: this.state.candiStatus,
      comment: this.state.Tagremarks,
      hiringStage: this.state.hiringStage,
      hiringStatus: this.state.hiringStatus,
      dateOfJoin: this.state.joiningDate,
      hiringStagedate:this.state.hiringStagDate,
      hiringStageRating:this.state.hiringStagRating,
      hiringStagefeedback:this.state.hiringFeedBack

    };

    if (isValid) {
      this.props.AddTagRequirement(data, this.props.handleCloseModal);
    } else {
      this.setState({
        error: "Please enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.hiringStatus.mainData !== this.props.hiringStatus.mainData) {
      this.props.hiringStatus.mainData.find((val) => {
        if (val.hiringStageName === this.state.hiringStage) {
          this.setState({
            filterHiringStatus: val.hiringStatus,
          });
        }
      });
    }
  }

  componentDidMount() {
    this.props.getHiringStatSelect();
    this.props.getCandidateStatusRs();
    // this.searchHiringStatus(this.state.hiringStage)
    this.setState({
      changeError: "",
      storeData: "",
      errorTagCan: "",
      errorInSearch: "",
    });
  }
  render() {
    console.log(this.state.storeData)
    console.log(this.props.canInfoId)
    const { hiring } = this.state.hiringStage;
    

    return (
      <div>
        <Modal
          size="lg"
          show={this.props.showPopUp}
          onHide={this.props.handleCloseModal}
        >
          {!this.state.isOpen && <div>
          <i
            data-toggle="tooltip"
            data-placement="top"
            title="Close"
            onClick={this.props.handleCloseModal}
            className="material-icons pointer cancel-button"
          >
            cancel
          </i>


          {/* <Form onSubmit={this.handleSubmitData} className="p-3 mt-2" noValidate> */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "1rem",
            }}
          >
            <Modal.Title style={{fontSize:"1.25rem",fontWeight:400}}>Tag Candidate</Modal.Title>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            
            <ul className="CustDetails-ul">
              <li style={{ marginTop: "1rem" }}>
                <div style={{ display: "flex",width:"100%" }}>
                  <span
                    style={{
                      fontSize: "0.875rem",
                      fontWeight: "500",
                      width: "35%",
                      
                    }}
                    className="label-sm bold customer-span-label-col-1"
                  >
                    Candidate ID : {this.state.storeData}
                  </span>

               {this.props.isShowSearchBar ? <div style={{width:"20rem",display:"flex",flexDirection:"column"}}>
                <Searchbar
              style={{width:"2rem"}}
              widthValue ="50%"
              onChange={this.searchChange}
              value={this.state.searchValue}
              placeholder="Search.."
              onSubmit={this.searchClick}
              title="Search"
              onKeyPress={(e) => {
                if (e.code === "Enter") {
                  this.searchClick();
                }
              }}
              // error={changeError}
              // isAvailable={isAvailable}
            />
              {this.state.errorInSearch && (
                      <span style={{ fontSize: "14px", color: "red" }}>
                        {this.state.errorInSearch}
                      </span>
                    )}
                    {this.state.changeError && (
                      <span style={{ fontSize: "14px", color: "red" }}>
                        {this.state.changeError}
                      </span>
                    )}

            </div> : <span>{this.props.canInfoId}</span>}
          
            {this.state.storeData  &&  <CustTooltip title="View Tracking">
                <h3
                  data-toggle="tooltip"
                  data-placement="top"
                  onClick={this.handleViewComments}
                  style={{
                    color: "#0056b3",
                    marginLeft: "1rem",
                    cursor: "pointer",
                    fontSize: "1.5rem",
                  }}
                >
                  {" "}
                  <DescriptionIcon />{" "}
                </h3>
              </CustTooltip>}

              {this.props.canInfoId  &&  <CustTooltip title="View Tracking">
                <h3
                  data-toggle="tooltip"
                  data-placement="top"
                  onClick={this.handleViewComments}
                  style={{
                    color: "#0056b3",
                    marginLeft: "1rem",
                    cursor: "pointer",
                    fontSize: "1.5rem",
                  }}
                >
                  {" "}
                  <DescriptionIcon />{" "}
                </h3>
              </CustTooltip>}

                  
                  
                
                </div>
              </li>

              <li style={{ marginTop: "2rem" }}>
                <div style={{ display: "flex" }}>
                  <span
                    style={{
                      fontSize: "0.875rem",
                      fontWeight: "500",
                      width: "150px",
                    }}
                    className="label-sm bold customer-span-label-col-1"
                  >
                    Candidate Status
                  </span>
                  
                  <FormGroup>
                    <Input
                      style={{
                        width: "385px",
                        height:"2rem",
                        marginLeft: "3.6rem",
                      }}
                      type="select"
                      name="candiStatus"
                      className="form-control-sm"
                      value={this.state.candiStatus}
                      onChange={this.onChange}
                      required={true}
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.CandidateStatus.mainData.map((item) => {
                        return (
                          <option key={item.id} value={item.candidateStatus}>
                            {item.candidateStatus}
                          </option>
                        );
                      })}
                    </Input>
                    {this.state.errorTagCan && (
                      <FormText
                        style={{ marginLeft: "3.6rem" }}
                        className="error"
                      >
                        {this.state.errorTagCan}
                      </FormText>
                    )}
                  </FormGroup>
                </div>
                {/* <li>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3}>
                <DateTimePicker
               label="Date&Time picker"
            
               renderInput={(params) => <TextField {...params} />}
               />
             </Stack>
               </LocalizationProvider>
                </li> */}
              </li>

              {this.state.candiStatus === "Hiring Stage" && (
                <li>
                  <div style={{ display: "flex" }}>
                    <span
                      style={{
                        fontSize: "0.875rem",
                        fontWeight: "500",
                        width: "150px",
                        marginTop:"1.5rem"
                      }}
                      className="label-sm bold customer-span-label-col-1"
                    >
                      Hiring Stage
                    </span>

                    <div  style={{
                          width: "11.5rem",
                          height:"2rem",
                          marginTop:"1.5rem",
                          marginLeft: "3.6rem",
                        }}>
                    <FormGroup>
                      <Input
                        style={{
                        
                          height:"2rem",
                        
                        }}
                        type="select"
                        name="hiringStage"
                        className="form-control-sm"
                        value={this.state.hiringStage}
                        onChange={this.onChange}
                        required={true}
                      >
                        <option value="" hidden>
                          Select...
                        </option>
                        {this.props.hiringStatus.mainData.map((item) => {
                          return (
                            <option key={item.id} value={item.hiringStageName}>
                              {item.hiringStageName}
                            </option>
                          );
                        })}
                      </Input>
                    
                    </FormGroup>
                     
                    </div>
                     <div >
                    <span style={{ marginLeft:"0.5rem",  fontSize: "0.875rem",fontWeight: "500", }} >date</span>
                   
                     
                    <FormComponent
                        style={{ width: "12rem",marginLeft:"0.5rem",height:"2rem" }}
                        labelClass="bold label-sm"
                        // label="date"
                        type="date"
                        name="hiringStagDate"
                        inputClass="form-control-sm"
                        value={this.state.hiringStagDate}
                        change={this.onChange}
                        required={true}
            
                      />
                      </div>
                     
                   

                  </div>
                </li>
              )}

              {this.state.candiStatus === "Hiring Stage" && (
                <li>
                  <div style={{ display: "flex" }}>
                    <span
                      style={{
                        fontSize: "0.875rem",
                        fontWeight: "500",
                        width: "150px",
                        marginTop:"1.5rem"
                      }}
                      className="label-sm bold customer-span-label-col-1"
                    >
                      Hiring Status
                    </span>
                    <div  style={{
                          width: "11.5rem",
                          height:"2rem",
                         
                          marginLeft: "3.6rem",
                          marginTop:"1.5rem",
                        }}>
                    <FormGroup>
                      <Input
                        style={{
                         height:"2rem",
                          width: "11.5rem",
                         }}
                        type="select"
                        name="hiringStatus"
                        className="form-control-sm"
                        value={this.state.hiringStatus}
                        onChange={this.onChange}
                        required={true}
                      >
                        <option value="" hidden>
                          Select...
                        </option>
                        {this.state.filterHiringStatus.length > 0 &&
                          this.state.filterHiringStatus.map((item) => {
                            return (
                              <option key={item.id} value={item.hiringStatus}>
                                {item.hiringStatus}
                              </option>
                            );
                          })}
                      </Input>
                      {/* {this.state.errorTagCan && (
                      <FormText className="error">
                        {this.state.errorTagCan}
                      </FormText>
                    )} */}
                    </FormGroup>
                    </div>
                
                  <div >
                    <span style={{ marginLeft:"0.5rem",  fontSize: "0.875rem",fontWeight: "500", }} >Rating</span>
                    <FormGroup>
                      <Input
                        style={{
                          width: "12rem",
                          marginLeft:"0.5rem",
                          height:"2rem",
                         }}
                        type="select"
                        name="hiringStagRating"
                        className="form-control-sm"
                        value={this.state.hiringStagRating}
                        onChange={this.onChange}
                        required={true}
                      >
                        <option value="" hidden>
                          Select...
                        </option>
                        <option value = "1">1</option>
                        <option value = "2">2</option>
                       <option  value = "3">3</option>
                        <option value  ="4">4</option>
                        <option value = "5">5</option>
                      
                      </Input>
                    
                    </FormGroup>
                      </div>
                      </div>
                </li>
              )}

              {(this.state.hiringStage === "Offered" ||
                this.state.hiringStage === "HR Discussion" ||
                this.state.hiringStage === "Manager Discussion") && (
                <li style={{ marginTop: "1rem" }}>
                  <div style={{ display: "flex" }}>
                    <span
                      style={{
                        fontSize: "0.875rem",
                      fontWeight: "500",
                        width: "150px",
                      }}
                      className="label-sm bold customer-span-label-col-1"
                    >
                      Date Of Joining
                    </span>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <FormComponent
                        style={{ width: "385px", marginLeft: "3.6rem",height:"2rem" }}
                        labelClass="bold label-sm"
                        // label="Years Of Experience"
                        type="date"
                        name="joiningDate"
                        inputClass="form-control-sm"
                        value={this.state.joiningDate}
                        change={this.onChange}
                        required={true}
                        min={DateFns.format(new Date(), "YYYY-MM-DD")}
                      />
                    </div>
                  </div>
                </li>
              )}
               
               {this.state.candiStatus === "Hiring Stage" && <li style={{ marginTop: "1rem" }}>
                <div style={{ display: "flex" }}>
                  <span
                    style={{
                      fontSize: "0.875rem",
                      fontWeight: "500",
                      width: "150px",
                    }}
                    className="label-sm bold customer-span-label-col-1"
                  >
                    Feedback
                  </span>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <FormComponent
                      style={{ width: "385px", marginLeft: "3.6rem",height:"2rem" }}
                      labelClass="bold label-sm"
                      // label="Years Of Experience"
                      type="textarea"
                      name="hiringFeedBack"
                      inputClass="form-control-sm"
                      value={this.state.hiringFeedBack}
                      change={this.onChange}
                      required={true}
                    />
                  </div>
                </div>
              </li>}
              <li style={{ marginTop: "1rem" }}>
                <div style={{ display: "flex" }}>
                  <span
                    style={{
                      fontSize: "0.875rem",
                      fontWeight: "500",
                      width: "150px",
                    }}
                    className="label-sm bold customer-span-label-col-1"
                  >
                    Comments
                  </span>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <FormComponent
                      style={{ width: "385px", marginLeft: "3.6rem",height:"2rem" }}
                      labelClass="bold label-sm"
                      // label="Years Of Experience"
                      type="textarea"
                      name="Tagremarks"
                      inputClass="form-control-sm"
                      value={this.state.Tagremarks}
                      change={this.onChange}
                      required={true}
                    />
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "1rem",
            }}
          >
            <Button variant="primary" onClick={this.handleSubmitData}>
              Submit
            </Button>
          </div>
          </div>}
         {this.state.isOpen &&  <div>
         <ViewCommemts canInfo={this.state.storeData || this.props.canInfoId} back = {this.backViewComments}/>
          </div>}
        </Modal>
       
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  requirements: state.RSrequirement,
  CandidateStatus: state.CandidateStatus,
  hiringStatus: state.hiringStatus,
});

export default connect(mapStateToProps, {
  AddTagRequirement,
  getHiringStatSelect,
  getCandidateStatusRs,
})(AddTagCandidate);
