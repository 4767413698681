import axios from "axios";
import { MODULE_GET } from "../../../types";
import {
  GET_MODULE,
  ADD_MODULE,
  UPDATE_MODULE,
  DELETE_MODULE,
} from "../../../../../utils/routes";
import setSuccessMessage from "../../../common/setSuccessMessage";
import setErrorMessage from "../../../common/setErrorMessage";
import getDispatch from "../../../common/getDispatch";

export const addModule = (data, back) => (dispatch) => {
  axios
    .post(ADD_MODULE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getModule = () => (dispatch) => {
  axios
    .get(GET_MODULE, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(MODULE_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateModule = (data, back) => (dispatch) => {
  axios
    .put(UPDATE_MODULE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteModule = (data) => (dispatch) => {
  axios
    .delete(DELETE_MODULE, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getModule));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
