import axios from "axios";
import { GET_REQTYPE_RSTYPE, GET_REQTYPE_MAIN } from "../../types";
import {
  ADD_REQTYPE_RS,
  GET_REQTYPE_RS,
  GET_REQTYPE_MAIN_API,
  UPDATE_REQTYPE_RS,
  DELETE_REQTYPE_RS
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addreqType = (data, back) => (dispatch) => {
  axios
    .post( ADD_REQTYPE_RS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getreqType));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getreqType  = () => (dispatch) => {
  axios
    .get(GET_REQTYPE_RS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(GET_REQTYPE_RSTYPE, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getreqTypeRs = () => (dispatch) => {
  axios
    .get(GET_REQTYPE_MAIN_API, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(GET_REQTYPE_MAIN , res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};


export const updatereqType  = (data, back) => (dispatch) => {
  axios
    .put( UPDATE_REQTYPE_RS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deletereqType  = (data) => (dispatch) => {
  axios
    .delete( DELETE_REQTYPE_RS, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getreqType));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
