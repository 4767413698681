import React, { Component } from "react";
import { Form } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// components
import FormComponent from "../../../../../common/FormComponent";
import {
  Validator,
  submitValidator,
  refresh,
  valid,
} from "../../../../../common/Validator";
import Notifier from "../../../../../aside/Notifier";
import ErrorNotifier from "../../../../../aside/ErrorNotifier";
// redux
import { updateContactsAddress } from "../../../../../../redux/actions/hcm/my_profile/contacts_details/contactsAddressAction";

class UpdateAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      communicationAddr: this.props.data.length
        ? this.props.data[0].communicationAddress
        : "",
      permanentAddr: this.props.data.length
        ? this.props.data[0].permanentAddress
        : "",
      message: "",
      error: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      communicationAddress: this.state.communicationAddr,
      permanentAddress: this.state.permanentAddr,
    };
    if (valid) {
      this.props.updateContactsAddress(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    refresh();
    this.setState({
      message: "",
      error: "",
    });
  }

  render() {
    const { communicationAddr, permanentAddr, message, error } = this.state;

    return (
      <React.Fragment>
        <i
          className="material-icons cancel-button pointer"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <FormComponent
            labelClass="bold label-sm"
            label="Communication Address"
            type="textarea"
            name="communicationAddr"
            inputClass="form-control-sm"
            value={communicationAddr}
            change={this.onChange}
            required={true}
          />
          <FormComponent
            labelClass="bold label-sm"
            label="Permanent Address"
            type="textarea"
            name="permanentAddr"
            inputClass="form-control-sm"
            value={permanentAddr}
            change={this.onChange}
            required={true}
          />
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Update
            </button>
          </div>
        </Form>
        {this.props.contactsAddress.message && (
          <Notifier message={this.props.contactsAddress.message} />
        )}
        {this.props.contactsAddress.error && (
          <ErrorNotifier message={this.props.contactsAddress.error} />
        )}
      </React.Fragment>
    );
  }
}

UpdateAddress.propTypes = {
  updateContactsAddress: PropTypes.func.isRequired,
  contactsAddress: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  contactsAddress: state.contactsAddress,
});

export default connect(mapStateToProps, { updateContactsAddress })(
  UpdateAddress
);
