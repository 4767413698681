import axios from "axios";
import { SERVICE_GET } from "../../../types";
import {
  GET_SERVICE,
  ADD_SERVICE,
  UPDATE_SERVICE,
  DELETE_SERVICE,
} from "../../../../../utils/routes";
import setSuccessMessage from "../../../common/setSuccessMessage";
import setErrorMessage from "../../../common/setErrorMessage";
import getDispatch from "../../../common/getDispatch";

export const addService = (data, back) => (dispatch) => {
  axios
    .post(ADD_SERVICE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getService = () => (dispatch) => {
  axios
    .get(GET_SERVICE, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(SERVICE_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateService = (data, back) => (dispatch) => {
  axios
    .put(UPDATE_SERVICE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteService = (data) => (dispatch) => {
  axios
    .delete(DELETE_SERVICE, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getService));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
