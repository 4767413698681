import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import DateFns from "date-fns";
import UpdateInvoice from "./UpdateInvoice";

class InvoiceDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
    };
    this.updateNow = this.updateNow.bind(this);
  }

  updateNow() {
    this.setState({
      edit: true,
    });
  }

  render() {
    const { edit } = this.state;

    return (
      <Container className="card container-card p-3">
        <i
          className="material-icons pointer"
          style={{
            position: "absolute",
            top: "5px",
            right: "5px",
            color: "#C00",
          }}
          onClick={this.props.back}
        >
          cancel
        </i>
        {!edit ? (
          <div>
            <h5 className="center">Invoice Details</h5>
            <hr />
            <Row>
              <Col md="6">
                <ul style={{ listStyle: "none" }}>
                  <li>
                    <span className="label-sm bold inquiry-span-label-col-1">
                      PO No
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.invoiceDetails.poNo}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold inquiry-span-label-col-1">
                      PO Name
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.invoiceDetails.poName}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold inquiry-span-label-col-1">
                      PO Amount
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.invoiceDetails.poAmount}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold inquiry-span-label-col-1">
                      Invoice Type
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.invoiceDetails.invoiceType}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold inquiry-span-label-col-1">
                      Name
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.invoiceDetails.vpsName}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold inquiry-span-label-col-1">
                      Address
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.invoiceDetails.vpsAddress}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold inquiry-span-label-col-1">
                      Contact Name
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.invoiceDetails.contactName}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold inquiry-span-label-col-1">
                      Contact Email
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.invoiceDetails.contactEmail}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold inquiry-span-label-col-1">
                      contact No
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.invoiceDetails.contactMobileNo}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold inquiry-span-label-col-1">
                      Ship To Address
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.invoiceDetails.shipToAddress}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold inquiry-span-label-col-1">
                      contact No
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.invoiceDetails.shipToContactMobileNo}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold inquiry-span-label-col-1">
                      GSTIN
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.props.invoiceDetails.gst}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold inquiry-span-label-col-1">
                      PAN
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.props.invoiceDetails.pan}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold inquiry-span-label-col-1">
                      TIN
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.props.invoiceDetails.tin}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold inquiry-span-label-col-1">
                      Bank Name
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.invoiceDetails.bankName}
                    </span>
                  </li>
                </ul>
              </Col>
              <Col md="6">
                <ul style={{ listStyle: "none" }}>
                  <li>
                    <span className="label-sm bold enter-invoice-span-label-col-2">
                      IFSC Code
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.invoiceDetails.bankIfscCode}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold enter-invoice-span-label-col-2">
                      Account Name
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.invoiceDetails.bankAccName}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold enter-invoice-span-label-col-2">
                      Account No
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.invoiceDetails.bankAccNo}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Invoice No
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.invoiceDetails.invoiceNo}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Invoice Date
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.invoiceDetails.invoiceDate &&
                        DateFns.format(
                          this.props.invoiceDetails.invoiceDate,
                          "DD-MM-YYYY"
                        )}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Invoice Amount
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.invoiceDetails.invoiceAmount}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Invoice GST
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.invoiceDetails.invoiceGst}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Total Amount
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.invoiceDetails.invoiceTotalAmount}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Invoice Currency
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.invoiceDetails.invoiceCurrency}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Payment Terms
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.invoiceDetails.pymtTerm}
                    </span>
                  </li>
                  {/* <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Scheduled Date
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.invoiceDetails.scheduledPymtDate}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Payment Amount
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.invoiceDetails.pymtAmount}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Paid Amount
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.invoiceDetails.paidAmount}
                    </span>
                  </li> */}
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Instructions
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.invoiceDetails.pymtInstructions}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Invoice Status
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.invoiceDetails.invoiceStatus}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Remarks
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.invoiceDetails.remarks}
                    </span>
                  </li>
                </ul>
              </Col>
            </Row>
            <div className="div">
              <hr />
              <div className="center">
                {/* {this.props.inquiry.inqStatus !== "Closed" &&
                this.props.inquiry.inqStatus !== "Lead" ? ( */}
                <button
                  className="btn login-button white-text"
                  onClick={this.updateNow}
                >
                  Update
                </button>
                {/* ) : (
                  ""
                )} */}
              </div>
            </div>
          </div>
        ) : (
          <UpdateInvoice
            invoice={this.props.invoiceDetails}
            back={this.props.back}
          />
        )}
      </Container>
    );
  }
}

export default InvoiceDetails;
