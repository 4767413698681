import axios from "axios";
import {
  SALES_OPPORTUNITY_STAGE_ADD,
  SALES_OPPORTUNITY_STAGE_GET,
  SALES_OPPORTUNITY_STAGE_UPDATE,
  SALES_OPPORTUNITY_STAGE_DELETE,
  GET_ERRORS,
} from "./types";
import {
  ADD_SALES_OPPORTUNITY_STAGE,
  GET_SALES_OPPORTUNITY_STAGE,
  UPDATE_SALES_OPPORTUNITY_STAGE,
  DELETE_SALES_OPPORTUNITY_STAGE,
} from "../../utils/routes";
import { setMessage } from "./salesActions";

export const addSalesOpportunityStage = (data, back) => (dispatch) => {
  axios
    .post(ADD_SALES_OPPORTUNITY_STAGE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getSalesOpportunityStage());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const getSalesOpportunityStage = () => (dispatch) => {
  axios
    .get(GET_SALES_OPPORTUNITY_STAGE, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: SALES_OPPORTUNITY_STAGE_GET,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};

export const updateSalesOpportunityStage = (data, back) => (dispatch) => {
  axios
    .post(UPDATE_SALES_OPPORTUNITY_STAGE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getSalesOpportunityStage());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const deleteSalesOpportunityStage = (data) => (dispatch) => {
  axios
    .post(DELETE_SALES_OPPORTUNITY_STAGE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getSalesOpportunityStage());
      }, 2000);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};
