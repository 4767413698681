import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import FormComponent from "../../../common/FormComponent";
import { addProduct } from "../../../../redux/actions/productAction";
import { getCustomers } from "../../../../redux/actions/customerAction";
import { getAccounts } from "../../../../redux/actions/accountAction";
import { getProductStatus } from "../../../../redux/actions/productStatusAction";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../common/Validator";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";

class AddProduct extends Component {
  constructor() {
    super();
    this.state = {
      productName: "",
      custName: "",
      accountName: "",
      licenseQuantity: "",
      startDate: "",
      endDate: "",
      status: "",
      remarks: "",
      message: "",
      error: "",
      dateError: "",
      formErrors: {
        selectError: "",
      },
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    e.preventDefault();
    Validator(e.target);
    if (e.target.name === "endDate") {
      if (Date.parse(e.target.value) < Date.parse(this.state.startDate)) {
        this.setState({
          [e.target.name]: e.target.value,
          dateError: "endDate must be greater than or equal to startDate",
        });
      } else {
        this.setState({
          [e.target.name]: e.target.value,
          dateError: "",
        });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let product = {
      productName: this.state.productName,
      compCode: this.state.custName,
      accountId: this.state.accountName,
      licenseQty: this.state.licenseQuantity,
      activationStartDate: this.state.startDate,
      activationEndDate: this.state.endDate,
      remarks: this.state.remarks,
      productStatus: this.state.status,
      createdBy: localStorage.getItem("id"),
    };
    if (valid && this.state.custName && this.state.accountName) {
      this.props.addProduct(product, this.props.history);
    } else {
      let errors = this.state.formErrors;
      errors.selectError = "Please select option";
      this.setState({
        formErrors: errors,
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidMount function to reset the fomr validation error data
*/
  componentDidMount() {
    this.props.getCustomers();
    this.props.getAccounts();
    this.props.getProductStatus();
    refresh();
  }

  render() {
    let shrink;
    const { selectError } = this.state.formErrors;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");

    return (
      <div className={shrink}>
        <h5 className="center bold">Add Product</h5>
        <Container className="card container-card">
          <Link to="/pie/pps/products">
            <i className="material-icons cancel-button">cancel</i>
          </Link>
          <Form onSubmit={this.onSubmit} className="p-3" noValidate>
            <Row>
              <Col md="6">
                <FormComponent
                  labelClass="bold label-sm"
                  label="Product Name"
                  type="text"
                  name="productName"
                  inputClass="form-control-sm"
                  value={this.state.productName}
                  change={this.onChange}
                  placeholder="Product Name"
                  required={true}
                />
                <FormGroup>
                  <Label className="label-sm bold">Customer Name</Label>
                  <Input
                    type="select"
                    name="custName"
                    onChange={this.onChange}
                    value={this.state.customerName}
                  >
                    <option hidden>Select...</option>
                    {this.props.customers &&
                      this.props.customers.data.map((item) => {
                        return (
                          <option key={item.compCode} value={item.compCode}>
                            {item.compCode}-{item.compFullName}
                          </option>
                        );
                      })}
                  </Input>
                  {!this.state.custName && (
                    <FormText className="error">{selectError}</FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="label-sm bold">Account Name</Label>
                  <Input
                    type="select"
                    name="accountName"
                    onChange={this.onChange}
                    value={this.state.accountName}
                  >
                    <option hidden>Select...</option>
                    {this.props.account &&
                      this.props.account.data.map((item) => {
                        if (parseInt(this.state.custName) === item.compCode) {
                          return (
                            <option key={item.accountId} value={item.accountId}>
                              {item.accountId}-{item.accountName}
                            </option>
                          );
                        }
                        return null;
                      })}
                  </Input>
                  {!this.state.accountName && (
                    <FormText className="error">{selectError}</FormText>
                  )}
                </FormGroup>
                <FormComponent
                  labelClass="bold label-sm"
                  label="License Quantity"
                  type="number"
                  name="licenseQuantity"
                  inputClass="form-control-sm"
                  value={this.state.licenseQuantity}
                  change={this.onChange}
                  placeholder="License Quantity"
                  required={true}
                />
              </Col>
              <Col md="6">
                <FormComponent
                  labelClass="bold label-sm"
                  label="Activation Start Date"
                  type="date"
                  name="startDate"
                  inputClass="form-control-sm"
                  value={this.state.startDate}
                  change={this.onChange}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Activation End Date"
                  type="date"
                  name="endDate"
                  inputClass="form-control-sm"
                  value={this.state.endDate}
                  change={this.onChange}
                  min={this.state.startDate}
                  error={this.state.dateError}
                  required={true}
                />
                <FormGroup>
                  <Label className="bold label-sm">Product Status</Label>
                  <Input
                    type="select"
                    name="status"
                    value={this.state.status}
                    className="form-control-sm"
                    onChange={this.onChange}
                    required={true}
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.productStatus.data.map((item) => {
                      return (
                        <option
                          key={item.productStatus}
                          value={item.productStatus}
                        >
                          {item.productStatus}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.status && (
                    <FormText className="error">{errors.status}</FormText>
                  )}
                </FormGroup>
                <FormComponent
                  labelClass="bold label-sm"
                  label="Remarks"
                  type="textarea"
                  name="remarks"
                  inputClass="form-control-sm"
                  value={this.state.remarks}
                  change={this.onChange}
                  required={true}
                />
              </Col>
            </Row>
            <div className="center">
              <button
                type="submit"
                className="btn center login-button white-text"
                disabled={this.props.products.buttonDisable ? true : false}
              >
                Add
              </button>
            </div>
          </Form>
        </Container>
        {this.props.products.message ? (
          <Notifier message={this.props.products.message} />
        ) : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
      </div>
    );
  }
}

AddProduct.propTypes = {
  addProduct: PropTypes.func.isRequired,
  products: PropTypes.object.isRequired,
  getCustomers: PropTypes.func.isRequired,
  getAccounts: PropTypes.func.isRequired,
  customers: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  getProductStatus: PropTypes.func.isRequired,
  productStatus: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  products: state.products,
  customers: state.customers,
  account: state.account,
  productStatus: state.productStatus,
});

export default connect(mapStateToProps, {
  addProduct,
  getCustomers,
  getAccounts,
  getProductStatus,
})(AddProduct);
