import axios from "axios";
import { CUSTOMER_GEOGRAPHIES_GET } from "../../types";
import {
  CURD_CUSTOMER_GEOGRAPHIES,
  USER_CUSTOMER_GEOGRAPHIES,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addCustomerGeographies = (data, back) => (dispatch) => {
  axios
    .post(CURD_CUSTOMER_GEOGRAPHIES, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getCustomerGeographies));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getCustomerGeographies = () => (dispatch) => {
  axios
    .get(CURD_CUSTOMER_GEOGRAPHIES, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CUSTOMER_GEOGRAPHIES_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserCustomerGeographies = () => (dispatch) => {
  axios
    .get(USER_CUSTOMER_GEOGRAPHIES, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CUSTOMER_GEOGRAPHIES_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateCustomerGeographies = (data, back) => (dispatch) => {
  axios
    .put(CURD_CUSTOMER_GEOGRAPHIES, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteCustomerGeographies = (data) => (dispatch) => {
  axios
    .delete(CURD_CUSTOMER_GEOGRAPHIES, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getCustomerGeographies));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
