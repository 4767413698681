import axios from "axios";
import { CRM_DEPARTMENT_GET } from "../../types";
import {
  CURD_CRM_DEPARTMENT,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addDepartment = (data, back) => (dispatch) => {
  axios
    .post(CURD_CRM_DEPARTMENT, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getDepartment));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getDepartment = () => (dispatch) => {
  axios
    .get(CURD_CRM_DEPARTMENT, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CRM_DEPARTMENT_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

// export const getUserContactDesignation = () => (dispatch) => {
//   axios
//     .get(USER_CONTACT_DESIGNATION, { withCredentials: true })
//     .then((res) => {
//       dispatch(getDispatch(CONTACT_DESIGNATION_GET, res));
//     })
//     .catch((err) => {
//       dispatch(setErrorMessage(err));
//     });
// };

export const updateDepartment = (data, back) => (dispatch) => {
  axios
    .put(CURD_CRM_DEPARTMENT, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteDepartment = (data) => (dispatch) => {
  axios
    .delete(CURD_CRM_DEPARTMENT, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getDepartment));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
