import {
    GET_CANDI_CURRENT_RECORDS,
    GET_PARTICULAR_CANDI,
    GET_ERRORS,
    SET_MESSAGE,
  } from "../../../actions/types";
  
  const initialState = {
    data: [],
    message: null,
    error: null,
    allData:[]
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case   GET_CANDI_CURRENT_RECORDS:
        return {
          ...state,
          data: action.payload,
        };
        case  GET_PARTICULAR_CANDI:
          return {
            ...state,
            allData: action.payload,
          };
      case SET_MESSAGE:
        return {
          ...state,
          message: action.payload,
        };
      case GET_ERRORS:
        return {
          ...state,
          error: action.payload,
        };
      default:
        return state;
    }
  }