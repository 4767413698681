import axios from "axios";
import { EMP_DOCUMENT_NAME_GET } from "../../types";
import {
  CURD_EMP_DOCUMENT_NAME,
  USER_EMP_DOCUMENT_NAME,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addEmpDocumentName = (data, back) => (dispatch) => {
  axios
    .post(CURD_EMP_DOCUMENT_NAME, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getEmpDocumentName));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getEmpDocumentName = () => (dispatch) => {
  axios
    .get(CURD_EMP_DOCUMENT_NAME, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(EMP_DOCUMENT_NAME_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserEmpDocumentName = () => (dispatch) => {
  axios
    .get(USER_EMP_DOCUMENT_NAME, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(EMP_DOCUMENT_NAME_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateEmpDocumentName = (data, back) => (dispatch) => {
  axios
    .put(CURD_EMP_DOCUMENT_NAME, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getEmpDocumentName));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteEmpDocumentName = (data) => (dispatch) => {
  axios
    .delete(CURD_EMP_DOCUMENT_NAME, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getEmpDocumentName));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
