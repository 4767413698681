import axios from "axios";
import { BUSINESS_LOCATIONS_GET } from "../../types";
import {
  CURD_BUSINESS_LOCATIONS,
  USER_BUSINESS_LOCATIONS,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addBusinessLocations = (data, back) => (dispatch) => {
  axios
    .post(CURD_BUSINESS_LOCATIONS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getBusinessLocations));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getBusinessLocations = () => (dispatch) => {
  axios
    .get(CURD_BUSINESS_LOCATIONS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(BUSINESS_LOCATIONS_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserBusinessLocations = () => (dispatch) => {
  axios
    .get(USER_BUSINESS_LOCATIONS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(BUSINESS_LOCATIONS_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateBusinessLocations = (data, back) => (dispatch) => {
  axios
    .put(CURD_BUSINESS_LOCATIONS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getBusinessLocations));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteBusinessLocations = (data) => (dispatch) => {
  axios
    .delete(CURD_BUSINESS_LOCATIONS, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getBusinessLocations));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
