import axios from "axios";
import { OTHER_IDENTIFIERS_GET } from "../../../types";
import { CURD_OTHER_IDENTIFIERS } from "../../../../../utils/routes";
import setSuccessMessage from "../../../common/setSuccessMessage";
import setErrorMessage from "../../../common/setErrorMessage";
import getDispatch from "../../../common/getDispatch";

export const getOtherIdentifiers = () => (dispatch) => {
  axios
    .get(CURD_OTHER_IDENTIFIERS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(OTHER_IDENTIFIERS_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateOtherIdentifiers = (data, back) => (dispatch) => {
  axios
    .post(CURD_OTHER_IDENTIFIERS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
