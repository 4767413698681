import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
// components
import FormComponent from "../../../common/FormComponent";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../common/Validator";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import SearchSelectBoxWithAdd from "../../../common/selectbox/SearchSelectBoxWithAdd";
// redux
import {
  addCustomerEntity,
  getCustomerEntitySpocName,
} from "../../../../redux/actions/crm/customer_entity/customerEntityAction";
import { getCustomersName } from "../../../../redux/actions/crm/customers/customersAction";
import { getUserCustomerGeographies } from "../../../../redux/actions/crm/crm_setup/customerGeographiesAction";
import { getUserCustomerCountries } from "../../../../redux/actions/crm/crm_setup/customerCountriesAction";
import { getUserCustomerCurrency } from "../../../../redux/actions/crm/crm_setup/customerCurrencyAction";

// ------------------------------------------------------------------------------------------------------------

class AddCustomerEntity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerName: "",
      entityName: "",
      contactId: "",
      spocName: "",
      contactEmail: "",
      dialingCode: "",
      contactNo: "",
      registrationNo: "",
      geography: "",
      country: "",
      panNo: null,
      tanNo: null,
      tinNo: null,
      gstNo: "",
      currency: "",
      billingAddress: "",
      shippingAddress: "",
      comments: "",
      fileName: "",
      buttonValue: false,
      dateError: "",
      spocError: "",
      error: "",
      message: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    e.preventDefault();
    Validator(e.target);

    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleContactNameChange = (value) => {
    this.setState({
      contactId: value && value.contactId,
      spocName: value && value.contactName,
      contactEmail: value && value.contactEmail,
      dialingCode: value && value.contactDialingCode,
      contactNo: value && value.contactMobileNo,
    });
  };

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let customerEntity = {
      customerdetailId: this.state.customerName,
      entityName: this.state.entityName,
      contactId: this.state.contactId,
      contactName: this.state.spocName,
      registrationNo: this.state.registrationNo,
      geography: this.state.geography,
      country: this.state.country.split(",")[0],
      countryCode: this.state.country.split(",")[1],
      panNo: this.state.panNo,
      tanNo: this.state.tanNo,
      tinNo: this.state.tinNo,
      gstNo: this.state.gstNo,
      currency: this.state.currency,
      billingAddress: this.state.billingAddress,
      shippingAddress: this.state.shippingAddress,
      entityStatus: "New",
      comments: this.state.comments,
    };
    if (valid && !this.state.dateError && this.state.spocName) {
      this.props.addCustomerEntity(customerEntity, this.props.history);
    } else {
      this.setState({
        error: "Enter mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
          spocError: this.state.spocName ? "" : "This field is mandatory",
        });
      }, 2000);
    }
  }

  /*
componentDidMount contains the refresh method to refresh the errors data
*/
  componentDidMount() {
    refresh();
    this.props.getCustomersName();
    this.props.getUserCustomerGeographies();
    this.props.getUserCustomerCountries();
    this.props.getUserCustomerCurrency();
    this.setState({
      message: "",
      error: "",
    });
  }

  componentDidUpdate(preProps, prevState) {
    if (prevState.customerName !== this.state.customerName) {
      this.props.getCustomerEntitySpocName(this.state.customerName);
    }
  }

  resetSpocNameError = () => {
    this.setState({
      spocError: "",
    });
  };

  render() {
    let shrink;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");

    return (
      <div className={shrink}>
        <Container className="card container-card">
          <h5 className="center bold mt-2">Add Customer Entity</h5>
          <Link to="/pie/crm/customer_entity">
            <i
              className="material-icons pointer"
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                color: "#C00",
              }}
            >
              cancel
            </i>
          </Link>
          <Form onSubmit={this.onSubmit} className="p-3 mt-2" noValidate>
            <Row>
              <Col md="4">
                <FormGroup>
                  <Label className="bold label-sm">Customer Name</Label>
                  <Input
                    type="select"
                    name="customerName"
                    className="form-control-sm"
                    value={this.state.customerName}
                    onChange={this.onChange}
                    required={true}
                    tabIndex="1"
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.customers.nameData.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.customerNo}-{item.customerName}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.customerName && (
                    <FormText className="error">{errors.customerName}</FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">Geography</Label>
                  <Input
                    type="select"
                    name="geography"
                    className="form-control-sm"
                    value={this.state.geography}
                    onChange={this.onChange}
                    placeholder="Geography"
                    required={true}
                    tabIndex="4"
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.customerGeographies.data.map((item) => {
                      return (
                        <option key={item.id} value={item.geoName}>
                          {item.geoName}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.geography && (
                    <FormText className="error">{errors.geography}</FormText>
                  )}
                </FormGroup>
                <FormComponent
                  labelClass="bold label-sm"
                  label="GST Number"
                  type="text"
                  name="gstNo"
                  change={this.onChange}
                  value={this.state.gstNo}
                  required={true}
                  placeholder="GST ex:05ABDCE1234F1Z2"
                  minLength="15"
                  maxLength="15"
                  pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
                  tabIndex="7"
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="TIN Number"
                  type="text"
                  name="tinNo"
                  change={this.onChange}
                  value={this.state.tinNo}
                  placeholder="Valid TIN Number"
                  minLength="11"
                  maxLength="11"
                  pattern="lettersAndNumbers"
                  tabIndex="10"
                />

                <FormComponent
                  labelClass="bold label-sm"
                  label="Billing Address"
                  type="textarea"
                  name="billingAddress"
                  inputClass="form-control-sm"
                  value={this.state.billingAddress}
                  change={this.onChange}
                  tabIndex="12"
                />
              </Col>
              <Col md="4">
                <FormComponent
                  labelClass="bold label-sm"
                  label="Entity Name"
                  type="text"
                  name="entityName"
                  change={this.onChange}
                  value={this.state.entityName}
                  placeholder="Entity Name"
                  required={true}
                  tabIndex="2"
                />
                <FormGroup>
                  <Label className="bold label-sm">Country</Label>
                  <Input
                    type="select"
                    name="country"
                    className="form-control-sm"
                    value={this.state.country}
                    onChange={this.onChange}
                    placeholder="Country"
                    required={true}
                    tabIndex="5"
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.customerCountries.data.map((item) => {
                      if (this.state.geography === item.geoName) {
                        return (
                          <option
                            key={item.id}
                            value={`${item.countryName},${item.countryCode}`}
                          >
                            {item.countryName}
                          </option>
                        );
                      }
                    })}
                  </Input>
                  {errors.country && (
                    <FormText className="error">{errors.country}</FormText>
                  )}
                </FormGroup>
                <FormComponent
                  labelClass="bold label-sm"
                  label="PAN Number"
                  type="text"
                  name="panNo"
                  change={this.onChange}
                  value={this.state.panNo}
                  placeholder="Valid PAN Number"
                  minLength="10"
                  maxLength="10"
                  pattern="lettersAndNumbers"
                  tabIndex="8"
                />

                <SearchSelectBoxWithAdd
                  options={this.props.customerEntity.spocNameData}
                  value={this.state.spocName}
                  handleChange={(value) => this.handleContactNameChange(value)}
                  reset={this.resetSpocNameError}
                  spocError={this.state.spocError}
                  label="SPOC Name"
                  customerName={this.state.customerName}
                  whichSpoc="entity"
                  tabIndex="11"
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Shipping Address"
                  type="textarea"
                  name="shippingAddress"
                  inputClass="form-control-sm"
                  value={this.state.shippingAddress}
                  change={this.onChange}
                  tabIndex="13"
                />
              </Col>
              <Col md="4">
                <FormComponent
                  labelClass="bold label-sm"
                  label="Registration/CIN Number"
                  type="text"
                  name="registrationNo"
                  change={this.onChange}
                  value={this.state.registrationNo}
                  required={true}
                  placeholder="Registered/CIN Number"
                  minLength="21"
                  maxLength="21"
                  pattern="lettersAndNumbers"
                  tabIndex="3"
                />
                <FormGroup>
                  <Label className="bold label-sm">Customer Currency</Label>
                  <Input
                    type="select"
                    name="currency"
                    className="form-control-sm"
                    value={this.state.currency}
                    onChange={this.onChange}
                    required={true}
                    tabIndex="6"
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.customerCurrency.data.map((item) => {
                      return (
                        <option key={item.id} value={item.currencyCode}>
                          {item.currencyCode}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.currency && (
                    <FormText className="error">{errors.currency}</FormText>
                  )}
                </FormGroup>
                <FormComponent
                  labelClass="bold label-sm"
                  label="TAN Number"
                  type="text"
                  name="tanNo"
                  change={this.onChange}
                  value={this.state.tanNo}
                  placeholder="Valid TAN Number"
                  minLength="10"
                  maxLength="10"
                  pattern="lettersAndNumbers"
                  tabIndex="9"
                />
                <ul className="CustDetails-ul">
                  <li>
                    <span className="label-sm bold customer-span-label-col-1">
                      Email
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.contactEmail}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold customer-span-label-col-1">
                      Mobile
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.state.dialingCode && `${this.state.dialingCode}-`}
                      {this.state.contactNo}
                    </span>
                  </li>
                </ul>

                <FormComponent
                  labelClass="bold label-sm"
                  label="Comments"
                  type="textarea"
                  name="comments"
                  inputClass="form-control-sm"
                  value={this.state.comments}
                  change={this.onChange}
                  tabIndex="14"
                />
              </Col>
            </Row>
            <div className="center">
              <button
                type="submit"
                className="btn center login-button white-text"
                tabIndex="15"
              >
                Submit
              </button>
            </div>
          </Form>
        </Container>
        {this.props.customerEntity.message ? (
          <Notifier message={this.props.customerEntity.message} />
        ) : null}
        {this.props.customerEntity.error ? (
          <ErrorNotifier message={this.props.customerEntity.error} />
        ) : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
      </div>
    );
  }
}

AddCustomerEntity.propTypes = {
  addCustomerEntity: PropTypes.func.isRequired,
  getCustomerEntitySpocName: PropTypes.func.isRequired,
  customerEntity: PropTypes.object.isRequired,
  getCustomersName: PropTypes.func.isRequired,
  customers: PropTypes.object.isRequired,
  getUserCustomerGeographies: PropTypes.func.isRequired,
  customerGeographies: PropTypes.object.isRequired,
  getUserCustomerCountries: PropTypes.func.isRequired,
  customerCountries: PropTypes.object.isRequired,
  getUserCustomerCurrency: PropTypes.func.isRequired,
  customerCurrency: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  customers: state.customers,
  customerEntity: state.customerEntity,
  customerGeographies: state.customerGeographies,
  customerCountries: state.customerCountries,
  customerCurrency: state.customerCurrency,
});

export default connect(mapStateToProps, {
  addCustomerEntity,
  getCustomerEntitySpocName,
  getCustomersName,
  getUserCustomerGeographies,
  getUserCustomerCountries,
  getUserCustomerCurrency,
})(AddCustomerEntity);
