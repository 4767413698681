import React, { Component } from "react";
import { Row, Col, Card } from "reactstrap";
import MenuManagement from "./menu_management/MenuManagement";
import UserGroup from "./user_group/UserGroup";
import Roles from "./role_management/Roles";
import AccessAndPermission from "./access_and_permission/AccessAndPermission";

class AuthConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: "",
    };

    this.back = this.back.bind(this);
    this.chooseForm = this.chooseForm.bind(this);
  }

  /*
back fuction to redirect to the back from details to myprofile tab page
*/
  back() {
    this.setState({
      edit: "",
    });
  }

  /*
chooseForm function to select the particular tab related view should display
*/
  chooseForm() {
    let component = this.state.edit;

    if (component === "menuManagement") {
      return <MenuManagement back={this.back} />;
    }
    if (component === "userGroup") {
      return <UserGroup back={this.back} />;
    }
    if (component === "roleManagement") {
      return <Roles back={this.back} />;
    }
    if (component === "accessAndPermission") {
      return <AccessAndPermission back={this.back} />;
    }
  }

  render() {
    let shrink = this.props.sidebar ? "scale" : "no-scale";

    return (
      <div className={shrink}>
        {!this.state.edit ? (
          <Row>
            <div className="platform-head">
              <p className="platform-p">Auth Configuration</p>
            </div>
            <div className="platform-category-div">
              <Row>
                <Col md="6">
                  <Card
                    className="myprofile-card"
                    onClick={() => this.setState({ edit: "userGroup" })}
                  >
                    User Groups
                  </Card>
                </Col>
                <Col md="6">
                  <Card
                    className="myprofile-card"
                    onClick={() => this.setState({ edit: "roleManagement" })}
                  >
                    Role Management
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Card
                    className="myprofile-card"
                    onClick={() => this.setState({ edit: "menuManagement" })}
                  >
                    Menu Management
                  </Card>
                </Col>
                <Col md="6">
                  <Card
                    className="myprofile-card"
                    onClick={() =>
                      this.setState({ edit: "accessAndPermission" })
                    }
                  >
                    Access And Permission
                  </Card>
                </Col>
              </Row>
            </div>
          </Row>
        ) : (
          this.chooseForm()
        )}
      </div>
    );
  }
}

export default AuthConfiguration;
