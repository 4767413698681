import {
    ADD_NEW_ORDERS,
    GET_ORDERS_LIST,
    UPDATE_ORDERS_ITEM,
    SEARCH_ORDERS_ITEM,
    SET_MESSAGE,
    GET_ERRORS
  } from '../actions/types'
  const initialState = {
    data: [],
    message: null,
    error: null
  };
  
  export default function(state = initialState, action){
    switch(action.type){
      case ADD_NEW_ORDERS:
        return {
          ...state,
          data: action.payload
        }
      case GET_ORDERS_LIST:
        return {
          ...state,
          data: action.payload
        };
      case UPDATE_ORDERS_ITEM:
        return {
          ...state,
          data: action.payload
        }
      case SEARCH_ORDERS_ITEM:
        return {
          ...state,
          data: action.payload
        }
      case SET_MESSAGE:
        return {
          ...state,
          message: action.payload
        }
      case GET_ERRORS:
        return {
          ...state,
          error: action.payload
        }
      default:
        return state;
    }
  }