import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
// components
import AccountDetails from "./AccountDetails";
import Hover from "../../../aside/Hover";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import SearchBar from "../../../common/searchbar/SearchBar";
import AddAccount from "./AddAccount";
import CustTooltip from "../../../common/tooltip/CustTooltip";
// redux
import {
  getAccounts,
  updateAccounts,
  searchAccounts,
} from "../../../../redux/actions/crm/accounts/accountsAction";
import { getCustomers } from "../../../../redux/actions/crm/customers/customersAction";

// -----------------------------------------------------------------------------------------

class Accounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      selection: null,
      selected: {},
      searchTerm: "",
      hover: false,
      nextRow: false,
      cords: {},
      mouse: {},
      addBtn: false,
      currentPermission: {},
      message: "",
      error: "",
    };
    this.getData = this.getData.bind(this);
    this.select = this.select.bind(this);
    this.search = this.search.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.back = this.back.bind(this);
    this.toggleHoverOn = this.toggleHoverOn.bind(this);
    this.toggleHoverOff = this.toggleHoverOff.bind(this);
    this.toggleAddBtn = this.toggleAddBtn.bind(this);
    this.addBack = this.addBack.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.account !== this.props.account) {
      this.setState({
        data: this.props.accounts.data,
      });
    }
  }

  select(item) {
    this.setState({
      selection: item,
      selected: item.id,
      hover: false,
    });
  }

  search(e) {
    e.preventDefault();
    this.setState({
      searchTerm: e.target.value,
    });
    this.onSearch(e.target.value);
  }

  onSearch(value) {
    let data = {
      accountName: value,
    };

    this.props.searchAccounts(data);
  }

  toggleHoverOn(e, item) {
    this.setState({
      hover: true,
      cords: {
        one: item.remarks,
      },
      mouse: {
        x: e.screenX,
        y: e.screenY,
      },
    });
  }

  toggleHoverOff() {
    this.setState({
      hover: false,
    });
  }

  back() {
    this.setState({
      selection: false,
    });
  }

  getData() {
    this.props.getAccounts();
  }

  componentDidMount() {
    for (let a in this.props.auth.data.accesses) {
      if (this.props.auth.data.accesses[a].appName === "CRM") {
        for (let m in this.props.auth.data.accesses[a].pieUserModuleAccess) {
          if (
            this.props.auth.data.accesses[a].pieUserModuleAccess[m]
              .moduleName === "Accounts"
          ) {
            for (let s in this.props.auth.data.accesses[a].pieUserModuleAccess[
              m
            ].pieUserServiceAccess) {
              if (
                this.props.auth.data.accesses[a].pieUserModuleAccess[m]
                  .pieUserServiceAccess[s].serviceName === "Account Management"
              ) {
                this.setState({
                  currentPermission:
                    this.props.auth.data.accesses[a].pieUserModuleAccess[m]
                      .pieUserServiceAccess[s],
                });
              }
            }
          }
        }
      }
    }
    this.getData();
    this.props.getCustomers();
  }

  /*
  toggleAddBtn function to toggle the addition page
  */
  toggleAddBtn() {
    this.setState({
      addBtn: true,
    });
  }

  /*
addBack function to back to the list page from the add page
*/
  addBack() {
    this.setState({
      addBtn: false,
    });

    this.getData();
  }

  render() {
    let shrink;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");
    const { selection, selected, data, message, error, addBtn } = this.state;

    return (
      <div className={shrink}>
        <Container className="card container-card">
          {Object.keys(this.state.currentPermission).length > 0 &&
            this.state.currentPermission.creation && (
              <div className="dive" title="Create Account">
                {!selection && (
                  <Link to="/pie/crm/add/account">
                    <CustTooltip title="Create Account">
                      <button className="btn new-btn white-text label-sm">
                        New
                      </button>
                    </CustTooltip>
                  </Link>
                )}
              </div>
            )}
          {!selection && (
            <SearchBar
              onChange={this.search}
              value={this.state.searchTerm}
              placeholder="Search for Accounts"
              onSubmit={this.onSearch}
              title="Search Account"
            />
          )}
          {!selection ? (
            <Row className="pt-3">
              <Col md="12" className="p-0">
                <ul className="crm-flex-around  ul-head">
                  <li className="list-label accId-width">Acc. Id</li>
                  <li className="list-label accName-width">Account Name</li>
                  <li className="list-label accCust-width">Customer Name</li>
                  <li className="list-label accEntity-width">Entity Name</li>
                  <li className="list-label accContact-width">Contact Name</li>
                  <li className="list-label accStatus-width">Status</li>
                </ul>
              </Col>
            </Row>
          ) : null}
          <Row onMouseMove={this.onMouseMove}>
            {!selection && !addBtn
              ? this.props.accounts.data.length > 0 &&
                this.props.accounts.data.map((item) => {
                  return (
                    <Col md="12" key={item.id} className="p-0">
                      <ul
                        onClick={() => {
                          this.select(item);
                        }}
                        onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                        onMouseLeave={this.toggleHoverOff}
                        className={` list crm-flex-around pointer ${
                          selected && selected === item.id && "selected-item"
                        } InqList`}
                      >
                        <li className="list-data accId-width">
                          {item.accountNo}
                        </li>
                        <li className="list-data accName-width">
                          {item.accountName}
                        </li>

                        <li className="list-data accCust-width">
                          {item.customerName}
                        </li>

                        <li className="list-data accEntity-width">
                          {item.entityName}
                        </li>
                        <li className="list-data accContact-width">
                          {item.contactName}
                        </li>
                        <li className="list-data accStatus-width">
                          {item.accountStatus}
                        </li>
                      </ul>
                    </Col>
                  );
                })
              : null}
          </Row>
          {selection && (
            <AccountDetails
              accountsDetail={this.state.selection}
              back={this.back}
              permission={this.state.currentPermission}
            />
          )}
          {addBtn && <AddAccount back={this.addBack} />}
          {message && <Notifier message={message} />}
          {error && <ErrorNotifier message={error} />}
        </Container>
      </div>
    );
  }
}

Accounts.propTypes = {
  auth: PropTypes.object.isRequired,
  getAccounts: PropTypes.func.isRequired,
  updateAccounts: PropTypes.func.isRequired,
  searchAccounts: PropTypes.func.isRequired,
  accounts: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  getCustomers: PropTypes.func.isRequired,
  customers: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  accounts: state.accounts,
  errors: state.errors,
  customers: state.customers,
});

export default connect(mapStateToProps, {
  getAccounts,
  updateAccounts,
  searchAccounts,
  getCustomers,
})(Accounts);
