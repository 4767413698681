import axios from "axios";
import {QUALIFICATIONRS_GET,GET_QUALIFY_MAIN } from "../../types";
import {
 ADD_QUALIFICATION,
 UPDATE_QUALIFICATION,
 GET_QUALIFICATION_MAIN_API ,
 GET_QUALIFICATION,
 DELETE_QUALIFICATION
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addQualification = (data, back) => (dispatch) => {
  axios
    .post( ADD_QUALIFICATION, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back,getQualification));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getQualification = () => (dispatch) => {
  axios
    .get(GET_QUALIFICATION, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(QUALIFICATIONRS_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
export const getQualificationRs = () => (dispatch) => {
  axios
    .get(GET_QUALIFICATION_MAIN_API , { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(GET_QUALIFY_MAIN, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateQualification = (data, back) => (dispatch) => {
  axios
    .put( UPDATE_QUALIFICATION, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteQualification = (data) => (dispatch) => {
  axios
    .delete( DELETE_QUALIFICATION, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null,getQualification));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
