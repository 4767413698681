import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import ViewTaggedDetails from "./viewTaggedDetails";
import AssignedHr from "./assignedHr";
import { Divider } from "@material-ui/core";
import ViewMatchCandi from "./viewMatchedCandidates";
import { MdCancel } from "react-icons/md";
import DateFns from "date-fns";
import Select from "react-select";
import DetailsPageFooter from "../../../common/details_footer/DetailsPageFooter";
import PublishJob from "./publishJobs";
import CommonFlexComponent from "../../../common/commonFlexComponent";
import AddCandidate from "./addCandidate";

// import {} from 'react-bootstrap'
import {
  FETCHBYID,
  TAG_SEARCH_CANDI,
  ADD_APPLY_JOBS,
} from "../../../../utils/routes";
import {
  Row,
  Col,
  Form,
  Container,
  FormGroup,
  Input,
  Label,
  FormText,
} from "reactstrap";
// import react icons
import { AiFillTags } from "react-icons/ai";
import { AiFillEye } from "react-icons/ai";
import { GrFormView } from "react-icons/gr";
import { BiStreetView } from "react-icons/bi";
import { MdAssignmentInd } from "react-icons/md";
import { IoLogoXbox } from "react-icons/io";
import { MdPublish } from "react-icons/md";
import { MdStreetview } from "react-icons/md";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ViewAssignedHr from "./viewAssignedHr";
import AddTagCandidate from "./addTagCandidate";
// import { MdOutlinePublishedWithChanges  } from 'react-icons/md';
// redux
import { getCustomersName } from "../../../../redux/actions/crm/customers/customersAction";
import { getContactsRSName } from "../../../../redux/actions/crm/contacts/contactsAction";
import { getBillingType } from "../../../../redux/actions/rs/viewbillingtypeaction";
import { getCategorytype } from "../../../../redux/actions/rs/rscategoryaction";
import { getPositionType } from "../../../../redux/actions/rs/viewPositionaction";
import { getRequirementStatus } from "../../../../redux/actions/rs/configuration/requirementStatusAction";
import { getRequiremnetType } from "../../../../redux/actions/rs/addRequirementListAction";
import { getQualification } from "../../../../redux/actions/rs/configuration/qualificationaction";
import { getreqType } from "../../../../redux/actions/rs/configuration/requirementTypeAction";
import { getHrLead } from "../../../../redux/actions/rs/configuration/hrleadAction";
import { updateRequirement } from "../../../../redux/actions/rs/requirements/requiremntsAction";

import { getAllSkillsSelect } from "../../../../redux/actions/rs/configuration/canSkillsAction";
import { getRequirementStatusRs } from "../../../../redux/actions/rs/configuration/requirementStatusAction";
import { getreqTypeRs } from "../../../../redux/actions/rs/configuration/requirementTypeAction";
import { getBillingRsTypeRs } from "../../../../redux/actions/rs/configuration/billingAction";
import { getJobCategoryRs } from "../../../../redux/actions/rs/configuration/jobCategoryAction";
import { getJobPositionRs } from "../../../../redux/actions/rs/configuration/jobPositionAction";
import { getQualificationRs } from "../../../../redux/actions/rs/configuration/qualificationaction";
import { getJobTimingSelect } from "../../../../redux/actions/rs/configuration/jobTiming";
// components

import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../common/Validator";
import FormComponent from "../../../common/FormComponent";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import CandidateKanban from "./candidate_kanban/CandidateKanban";
import CustTooltip from "../../../common/tooltip/CustTooltip";
// import CustomerOrgChart from "./CustomerOrgChart";

let errorCustomer = false;
let errorContact = false;
let errorSkill = false;
let errorCategory = false;
let errorPosition = false;
let errorQualification = false;
let primarySkillGroupError = "";
class ViewRequirement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerName: "",
      spocName: "",
      customerBodyName: "",
      spocContact: "",
      spocMail: "",
      spocBodyContactName: "",
      reqType: "  FTE",
      jobRole: "",
      positionBodyName: "",
      jobCategory: "",
      categoryBodyName: "",
      primarySkillErrorShow: null,
      secondarySkillErrorShow: null,
      description: "",
      location: "",
      qualifications: "",
      qualificationBodyName: "",
      notes: "",
      critical: "",
      custReqId: "",
      // leadRecruiter: "",
      timing: "",
      timingBodyName: "",
      yearsExp: "",
      releventExp: "",
      skillSet: "",
      haveSkills: "",
      niceSkills: "",
      totalPos: "",
      duration: "",
      startDate: "",
      minYears: "",
      maxYears: "",
      endDate: "",
      billingType: "",
      currency: "",
      salaryRange: "",
      reqStatus: "",
      remarks: "",
      edit: false,
      custOrgChart: false,
      data: {},
      dateError: "",
      yearsError: "",
      message: "",
      error: "",
      getData: [],
      dataStore: [],
      showPopUp: false,
      taggedView: false,
      selectedOptionError: null,
      selectedOptionErrorOne: null,
      categoryErrorShow: null,
      qualificationShowError: null,
      positionErrorShow: null,
      searchIdValue: "",
      getTaggedData: [],
      filteredPrimaryValueArray: [],
      filteredSecondaryValueArray: [],
      assignedPopUp: false,
      assignedViewPopUp: false,
      matchedPopUpView: false,
      isUpdate: false,
      isAdd:false,
      maxyearsError: "",
      crucialArr: [
        { key: "High", value: "High" },
        { key: "Medium", value: "Medium" },
        { key: "Low", value: "Low" },
      ],
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.fetchParticularId = this.fetchParticularId.bind(this);
    this.backnavigate = this.backnavigate.bind(this);
    this.handlePopupView = this.handlePopupView.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleCloseTagedModal = this.handleCloseTagedModal.bind(this);
    this.handleTaggedView = this.handleTaggedView.bind(this);
    this.handleCustomerChange = this.handleCustomerChange.bind(this);
    this.handleContactChange = this.handleContactChange.bind(this);
    this.handleAssignedView = this.handleAssignedView.bind(this);
    this.handleAssignedFullView = this.handleAssignedFullView.bind(this);
    this.handleCloseAssignedHrView = this.handleCloseAssignedHrView.bind(this);
    this.handleCloseAddAssignedView =
      this.handleCloseAddAssignedView.bind(this);
    this.handlePublishJobs = this.handlePublishJobs.bind(this);
    this.handleMatchedView = this.handleMatchedView.bind(this);
    this.handleCloseMatchedView = this.handleCloseMatchedView.bind(this);
    this.handlePrimarySkillsChange = this.handlePrimarySkillsChange.bind(this);
    this.handleSecondarySkillsChange =
      this.handleSecondarySkillsChange.bind(this);
    this.closeDataPrimarySkill = this.closeDataPrimarySkill.bind(this);
    this.closeDataSecondarySkill = this.closeDataSecondarySkill.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handlePositionChange = this.handlePositionChange.bind(this);
    this.handleQualificationChange = this.handleQualificationChange.bind(this);
    this.handleTimingChange = this.handleTimingChange.bind(this);
    this.openAddView = this.openAddView.bind(this)
  }

  //   componentDidUpdate(prevProps) {
  //     if (this.props.customers.message) {
  //       setTimeout(() => {
  //         this.props.back();
  //       }, 2000);
  //     }
  //   }

  // onchange event for customer name

  handleCustomerChange(customerName) {
    this.setState({
      customerBodyName: customerName.value,
    });
    this.props.getContactsRSName(customerName.singleValue);
    if (customerName) {
      this.setState({ customerName, selectedOptionError: null });
    } else {
      this.setState({
        selectedOptionError: "This field is mandatory",
      });
    }
  }

  handlePositionChange(jobRole) {
    this.setState({
      positionBodyName: jobRole.value,
    });

    if (jobRole) {
      this.setState({ jobRole, positionErrorShow: null });
    } else {
      this.setState({
        positionErrorShow: "This field is mandatory",
      });
    }
  }

  handleQualificationChange(qualifications) {
    this.setState({
      qualificationBodyName: qualifications.value,
    });

    if (qualifications) {
      this.setState({ qualifications, qualificationShowError: null });
    } else {
      this.setState({
        qualificationShowError: "This field is mandatory",
      });
    }
  }

  handleTimingChange(timing) {
    this.setState({
      timingBodyName: timing.value,
    });

    if (timing) {
      this.setState({ timing });
    }
  }

  handleCategoryChange(jobCategory) {
    this.setState({
      categoryBodyName: jobCategory.value,
    });

    if (jobCategory) {
      this.setState({ jobCategory, categoryErrorShow: null });
    } else {
      this.setState({
        categoryErrorShow: "This field is mandatory",
      });
    }
  }

  handlePrimarySkillsChange(skillSet) {
    this.setState({
      primarySkillErrorShow: null,
    });

    let { filteredPrimaryValueArray } = this.state;
    filteredPrimaryValueArray.push(skillSet.value);
    this.setState({
      filteredPrimaryValueArray: [...new Set(filteredPrimaryValueArray)],
    });
    primarySkillGroupError = "";

  }

  handleSecondarySkillsChange(skillSet) {
    this.setState({
      secondarySkillErrorShow: null,
    });

    let { filteredSecondaryValueArray } = this.state;
    filteredSecondaryValueArray.push(skillSet.value);
    this.setState({
      filteredSecondaryValueArray: [...new Set(filteredSecondaryValueArray)],
    });
    primarySkillGroupError = "";
}

  closeDataPrimarySkill(data) {
    console.log(data);

    let filterData = this.state.filteredPrimaryValueArray.filter(
      (val) => val !== data
    );
    this.setState({
      filteredPrimaryValueArray: filterData,
    });
  }

  closeDataSecondarySkill(data) {
    console.log(data);

    let filterData = this.state.filteredSecondaryValueArray.filter(
      (val) => val !== data
    );
    this.setState({
      filteredSecondaryValueArray: filterData,
    });
  }

  // onchange event for tac name

  handleContactChange(spocName) {
    console.log(spocName);
    this.setState({
      selectedOptionErrorOne: "",
      spocContact: spocName.mobile,
      spocMail: spocName.email,
      spocBodyContactName: spocName.label,
    });

    if (spocName) {
      this.setState({
        spocName,
        selectedOptionErrorOne: null,
      });
    } else {
      this.setState({
        selectedOptionErrorOne: "This field is mandatory",
      });
    }
    console.log(this.state.spocName);
    console.log(this.state.spocMail);
    console.log(this.state.spocContact);
  }

  onChange(e) {
    e.preventDefault();
    Validator(e.target);
    if (e.target.name === "minYears") {
      if (e.target.value.toString().length > 2) {
        this.setState({
          [e.target.name]: e.target.value,
          yearsError: "Years must be lesser than or equal to two",
        });
      } else {
        this.setState({
          [e.target.name]: e.target.value,
          yearsError: "",
        });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
    if (e.target.name === "maxYears") {
      if (e.target.value > 60) {
        this.setState({
          [e.target.name]: e.target.value,
          maxyearsError: "Years must be lesser than or equal to 60",
        });
      } else {
        this.setState({
          [e.target.name]: e.target.value,
          maxyearsError: "",
        });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }

    if (e.target.name === "endDate") {
      if (Date.parse(e.target.value) < Date.parse(this.state.startDate)) {
        this.setState({
          [e.target.name]: e.target.value,
          dateError: "End Date must be greater than or equal to Start Date",
        });
      } else {
        this.setState({
          [e.target.name]: e.target.value,
          dateError: "",
        });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  // close function

  backnavigate() {
    this.setState({
      edit: !this.state.edit,
    });
    this.fetchParticularId();
  }

  // view tag candiate form
  handlePopupView() {
    this.setState({
      showPopUp: true,
      taggedView: false,
      assignedPopUp: false,
      assignedViewPopUp: false,
    });
  }
  // view assigned hr model
  handleAssignedView() {
    this.setState({
      assignedPopUp: true,
      showPopUp: false,
      taggedView: false,
      assignedViewPopUp: false,
    });
  }

  handleAssignedFullView() {
    console.log("working asssigned");
    this.setState({
      assignedViewPopUp: true,
      taggedView: false,
      showPopUp: false,
      assignedPopUp: false,
    });
  }
  handleTaggedView() {
    this.setState({
      taggedView: true,
      showPopUp: false,
      assignedPopUp: false,
      assignedViewPopUp: false,
    });
  }

  handleMatchedView() {
    this.setState({
      matchedPopUpView: true,
      taggedView: false,
      showPopUp: false,
      assignedPopUp: false,
      assignedViewPopUp: false,
    });
  }

  handlePublishJobs() {
    this.setState({
      matchedPopUpView: false,
      taggedView: false,
      showPopUp: false,
      assignedPopUp: false,
      assignedViewPopUp: false,
      isUpdate: true,
    });
  }

  handleClosePublishjob = () => {
    this.setState({
      matchedPopUpView: false,
      taggedView: false,
      showPopUp: false,
      assignedPopUp: false,
      assignedViewPopUp: false,
      isUpdate: false,
    });
  };

  openAddView(){
    this.setState({
      matchedPopUpView: false,
      taggedView: false,
      showPopUp: false,
      assignedPopUp: false,
      assignedViewPopUp: false,
      isUpdate: false,
      isAdd:!this.state.isAdd
    });
  }

  // tag candidate search

  // search onchange function

  /*
  This function for updating the requirement details
  */
  onSubmit(e) {
    console.log("chandra")
    e.preventDefault();
    submitValidator(e.target);
    if (!this.state.customerBodyName) {
      this.setState({
        selectedOptionError: "This field is mandatory",
      });
      errorCustomer = true;
    } else {
      this.setState({
        selectedOptionError: "",
      });
      errorCustomer = false;
    }

    if (!this.state.spocBodyContactName) {
      this.setState({
        selectedOptionErrorOne: "This field is mandatory",
      });
      errorContact = true;
    } else {
      this.setState({
        selectedOptionErrorOne: "",
      });
      errorContact = false;
    }
    if (!this.state.filteredPrimaryValueArray.length) {
      this.setState({
        primarySkillErrorShow: "This field is mandatory",
      });
      errorSkill = true;
    } else {
      this.setState({
        primarySkillErrorShow: "",
      });
      errorSkill = false;
    }

    if (!this.state.categoryBodyName) {
      this.setState({
        categoryErrorShow: "This field is mandatory",
      });
      errorCategory = true;
    } else {
      this.setState({
        categoryErrorShow: "",
      });
      errorCategory = false;
    }

    if (!this.state.positionBodyName) {
      this.setState({
        positionErrorShow: "This field is mandatory",
      });
      errorPosition = true;
    } else {
      this.setState({
        positionErrorShow: "",
      });
      errorPosition = false;
    }
    if (!this.state.qualificationBodyName) {
      this.setState({
        qualificationShowError: "This field is mandatory",
      });
      errorQualification = true;
    } else {
      this.setState({
        qualificationShowError: "",
      });
      errorQualification = false;
    }

    let body = {
      id: this.props.requirementId,
      custReqId: Number(this.state.custReqId),
      custName: this.state.customerBodyName,
      tacName: this.state.spocBodyContactName,
      tacConNum: Number(this.state.spocContact),
      tacEmail: this.state.spocMail,
      jobReqType: this.state.reqType,
      jobCategory: this.state.categoryBodyName,
      jobPosition: this.state.positionBodyName,
      qualifications: this.state.qualificationBodyName,
      billingType: this.state.billingType,
      reqStatus: this.state.reqStatus,

      // skillSets: this.state.filteredValueArray.toString(),
      jobPriority: this.state.critical,
      totalPositions: this.state.totalPos,
      minYearsOfExp: Number(this.state.minYears),
      maxYearsOfExp: Number(this.state.maxYears),
      jobLocation: this.state.location,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      maxCtc: this.state.salaryRange,
      primarySkills: this.state.filteredPrimaryValueArray.toString(),
      secondarySkills: this.state.filteredSecondaryValueArray.toString(),
      jobDescription: this.state.description,
      jobTimings: this.state.timingBodyName,
      comments: this.state.notes,
    };
    console.log(valid)
    console.log(this.state.dateError)
    console.log(errorContact)
    console.log(errorCustomer)
    console.log(errorSkill)
    console.log(errorPosition)
    console.log(errorCategory)
    console.log(errorQualification)
    console.log(this.state.yearsError)
    console.log(this.state.maxyearsError)
    console.log(errors)

    if (
      valid &&
      !this.state.dateError &&
      !errorContact &&
      !errorCustomer &&
      !errorPosition &&
      !errorCategory &&
      !errorSkill &&
      !errorQualification &&
      !this.state.yearsError &&
      !this.state.maxyearsError
    ) {
      this.props.updateRequirement(body, this.backnavigate);

    } else {
      this.setState({
        error: " Please enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  // fetch by id and display function

  fetchParticularId() {
    axios
      .get(`${FETCHBYID}/${this.props.requirementId}`, {
        withCredentials: true,
      })
      .then((res) => {
        console.log(res.data);
        this.setState({
          getData: res.data,
          customerName: res.data[0].custName,
          spocName: res.data[0].tacName,
          spocContact: res.data[0].tacConNum,
          spocMail: res.data[0].tacEmail,
          reqType: res.data[0].jobReqType,
          jobRole: res.data[0].jobPosition,
          description: res.data[0].jobDescription,
          location: res.data[0].jobLocation,
          qualificationBodyName: res.data[0].qualifications,
          notes: res.data[0].notes,
          customerBodyName: res.data[0].custName,
          spocBodyContactName: res.data[0].tacName,
          categoryBodyName: res.data[0].jobCategory,
          positionBodyName: res.data[0].jobPosition,
          critical: res.data[0].jobPriority,
          jobCategory: res.data[0].jobCategory,
          filteredPrimaryValueArray: res.data[0].primarySkills
            ? res.data[0].primarySkills.split(",")
            : [],
          filteredSecondaryValueArray: res.data[0].secondarySkills
            ? res.data[0].secondarySkills.split(",")
            : [],
          custReqId: res.data[0].custReqId,
          timingBodyName: res.data[0].jobTimings,
          minYears: res.data[0].minYearsOfExp,
          maxYears: res.data[0].maxYearsOfExp,
          skillSet: res.data[0].skillSets,
          haveSkills: res.data[0].primarySkills,
          niceSkills: res.data[0].secondarySkills,
          totalPos: res.data[0].totalPositions,
          duration: res.data[0].duration,
          startDate: res.data[0].startDate,
          endDate: res.data[0].endDate,
          billingType: res.data[0].billingType,
          currency: res.data[0].currency,
          salaryRange: res.data[0].maxCtc,
          reqStatus: res.data[0].reqStatus,
          notes: res.data[0].comments,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // closeModel function

  handleCloseModal() {
    this.setState({
      showPopUp: false,
    });
  }

  // close model functions

  handleCloseTagedModal() {
    this.setState({
      taggedView: false,
    });
  }
  handleCloseAddAssignedView() {
    this.setState({
      assignedPopUp: false,
    });
  }

  handleCloseMatchedView() {
    this.setState({
      matchedPopUpView: false,
    });
  }

  handleCloseAssignedHrView() {
    this.setState({
      assignedViewPopUp: false,
    });
  }

  // submit function for tag candidate

  /*
  ComponentDidMount getting the selected requirement details and setting the state of requirement
  */
  componentDidMount() {
    this.props.getCustomersName();
    this.fetchParticularId();
    this.props.getAllSkillsSelect();
    this.props.getRequirementStatusRs();
    this.props.getreqTypeRs();
    this.props.getBillingRsTypeRs();
    this.props.getJobCategoryRs();
    this.props.getJobPositionRs();
    this.props.getQualificationRs();
    this.props.getJobTimingSelect();
    refresh();
  }

  render() {
    console.log(this.props.ReqType.data);

    // populating values search with list for customer name
    let options3 = [];
    console.log(this.props.customers.nameData);
    for (let i = 0; i < this.props.customers.nameData.length; i++) {
      var x1 = {};
      x1["singleValue"] = this.props.customers.nameData[i].customerName;
      x1["value"] = `${this.props.customers.nameData[i].customerName}`;
      x1[
        "label"
      ] = `${this.props.customers.nameData[i].customerNo}-${this.props.customers.nameData[i].customerName}`;

      options3.push(x1);
    }
    console.log(this.state.customerName);
    console.log(options3);
    // populating values search with list for spoc name

    let options4 = [];
    console.log(this.props.crmContacts.nameData);
    for (let i = 0; i < this.props.crmContacts.nameData.length; i++) {
      var x1 = {};
      x1["value"] = this.props.crmContacts.nameData[i].id;

      x1["label"] = `${this.props.crmContacts.nameData[i].contactName}`;
      x1["email"] = this.props.crmContacts.nameData[i].contactEmail;
      x1["mobile"] = this.props.crmContacts.nameData[i].contactMobileNo;

      options4.push(x1);
    }

    let options5 = [];
    for (let i = 0; i < this.props.CanConSkill.allData.length; i++) {
      var x3 = {};
      x3["value"] = this.props.CanConSkill.allData[i].skill;
      x3["label"] = this.props.CanConSkill.allData[i].skill;
      options5.push(x3);
    }

    let options6 = [];
    for (let i = 0; i < this.props.JobCategory.mainData.length; i++) {
      var x4 = {};
      x4["value"] = this.props.JobCategory.mainData[i].jobCatName;
      x4["label"] = this.props.JobCategory.mainData[i].jobCatName;

      options6.push(x4);
    }

    let options7 = [];
    for (let i = 0; i < this.props.JobPosition.mainData.length; i++) {
      var x5 = {};
      x5["value"] = this.props.JobPosition.mainData[i].jobPositionName;
      x5["label"] = this.props.JobPosition.mainData[i].jobPositionName;

      options7.push(x5);
    }
    let options8 = [];
    for (let i = 0; i < this.props.QualificationRs.mainData.length; i++) {
      var x6 = {};
      x6["value"] = this.props.QualificationRs.mainData[i].qualifictiontype;
      x6["label"] = this.props.QualificationRs.mainData[i].qualifictiontype;

      options8.push(x6);
    }

    let options9 = [];
    for (let i = 0; i < this.props.jobTiming.mainData.length; i++) {
      var x7 = {};
      x7["value"] = this.props.jobTiming.mainData[i].jobHours;
      x7["label"] = this.props.jobTiming.mainData[i].jobHours;

      options9.push(x7);
    }

    return (
      <Row>
        {!this.state.isUpdate && !this.state.edit && !this.state.isAdd ? (
          <Col md="12">
            <h5 className="bold center mb-4">
              {" "}
              Requirement : {this.props.requirementId}
            </h5>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                position: "absolute",
                top: "-0.3rem",
                right: "5%",
              }}
            >
              {Object.keys(this.props.permission).length > 0 &&
                this.props.permission.updation && (
                  <CustTooltip title="Update Requirement">
                    <i
                      data-toggle="tooltip"
                      data-placement="top"
                      style={{
                        color: "#0056b3",
                        marginRight: ".5rem",
                        fontSize: "1.25rem",
                      }}
                      className="material-icons pointer edit-btn"
                      onClick={() => this.setState({ edit: !this.state.edit })}
                    >
                      edit
                    </i>
                  </CustTooltip>
                )}
                  <CustTooltip title="Add Candidate">
                  <h3
                  data-toggle="tooltip"
                  data-placement="top"
                  onClick={this.openAddView}
                  style={{
                    color: "#0056b3",
                    marginRight: ".5rem",
                    cursor: "pointer",
                    fontSize: "1.5rem",
                  }}
                >
                  {" "}
                  <PersonAddIcon />{" "}
                </h3>
                  </CustTooltip>
              <CustTooltip title="Publish Jobs">
                <h3
                  data-toggle="tooltip"
                  data-placement="top"
                  onClick={this.handlePublishJobs}
                  style={{
                    color: "#0056b3",
                    marginRight: ".5rem",
                    cursor: "pointer",
                    fontSize: "1.5rem",
                  }}
                >
                  {" "}
                  <MdPublish />{" "}
                </h3>
              </CustTooltip>
              <CustTooltip title="Assign Recruiter">
                <h3
                  data-toggle="tooltip"
                  data-placement="top"
                  onClick={this.handleAssignedView}
                  style={{
                    color: "#0056b3",
                    marginRight: ".5rem",
                    cursor: "pointer",
                    fontSize: "1.5rem",
                  }}
                >
                  {" "}
                  <MdAssignmentInd />{" "}
                </h3>
              </CustTooltip>
              <CustTooltip title="View Assigned Recruiter">
                <h3
                  data-toggle="tooltip"
                  data-placement="top"
                  onClick={this.handleAssignedFullView}
                  style={{
                    color: "#0056b3",
                    marginRight: ".5rem",
                    cursor: "pointer",
                    fontSize: "1.5rem",
                  }}
                >
                  {" "}
                  <BiStreetView />{" "}
                </h3>
              </CustTooltip>
              <CustTooltip title="Tag Candidate">
                <h3
                  data-toggle="tooltip"
                  data-placement="top"
                  onClick={this.handlePopupView}
                  style={{
                    color: "#0056b3",
                    marginRight: ".5rem",
                    cursor: "pointer",
                    fontSize: "1.5rem",
                  }}
                >
                  {" "}
                  <AiFillTags />{" "}
                </h3>
              </CustTooltip>
              <CustTooltip title="Candidate Kanban">
                <h3
                  data-toggle="tooltip"
                  data-placement="top"
                  onClick={this.handleTaggedView}
                  style={{
                    color: "#0056b3",
                    marginRight: ".5rem",
                    cursor: "pointer",
                    fontSize: "1.5rem",
                  }}
                >
                  {" "}
                  <MdStreetview />
                </h3>
              </CustTooltip>
              <CustTooltip title="Matched Candidates">
                <h3
                  data-toggle="tooltip"
                  data-placement="top"
                  onClick={this.handleMatchedView}
                  style={{
                    color: "#0056b3",
                    marginRight: ".5rem",
                    cursor: "pointer",
                    fontSize: "1.5rem",
                  }}
                >
                  {" "}
                  <IoLogoXbox />{" "}
                </h3>
              </CustTooltip>
            </div>
            <i
              data-toggle="tooltip"
              data-placement="top"
              title="Close"
              onClick={() => this.props.isToggleView()}
              className="material-icons pointer cancel-button"
              style={{
                top: "0.1rem",
              }}
            >
              cancel
            </i>
            {this.state.assignedPopUp && (
              <AssignedHr
                requirementId={this.props.requirementId}
                showPopUp={this.state.assignedPopUp}
                handleCloseModal={this.handleCloseAddAssignedView}
              />
            )}

            {this.state.assignedViewPopUp && (
              <ViewAssignedHr
                requirementId={this.props.requirementId}
                showPopUp={this.state.assignedViewPopUp}
                handleCloseModal={this.handleCloseAssignedHrView}
              />
            )}

            {this.state.showPopUp && (
              <AddTagCandidate
                requirementId={this.props.requirementId}
                showPopUp={this.state.showPopUp}
                handleCloseModal={this.handleCloseModal}
                isShowSearchBar = {true}
              />
            )}

            {this.state.taggedView && (
              <CandidateKanban
                reqId={this.props.requirementId}
                taggedView={this.state.taggedView}
                closeModel={this.handleCloseTagedModal}
              />
            )}

            {this.state.matchedPopUpView && (
              <ViewMatchCandi
                reqType={this.props.requirementId}
                taggedData={this.state.getTaggedData}
                matchedView={this.state.matchedPopUpView}
                matchedData={this.state.getData}
                closeModel={this.handleCloseMatchedView}
              />
            )}

            {this.state.getData.length > 0 &&
              this.state.getData.map((data) => {
                return (
                  <div>
                    <Row style={{ width: "100%" }}>
                      <Col style={{ marginLeft: "3rem" }} md="5">
                        <ul className="CustDetails-ul m-0">
                          <li>
                            <CommonFlexComponent
                              label="Customer Name"
                              data={data.custName}
                            />
                          </li>

                          <li>
                            <CommonFlexComponent
                              label=" TAC Name"
                              data={data.tacName}
                            />
                          </li>

                          <li>
                            <CommonFlexComponent
                              label=" TAC Contact No"
                              data={data.tacConNum}
                            />
                          </li>

                          <li>
                            <CommonFlexComponent
                              label="TAC EmailId"
                              data={data.tacEmail}
                            />
                          </li>
                          <li>
                            <CommonFlexComponent
                              label="Req. Type"
                              data={data.jobReqType}
                            />
                          </li>

                          <li></li>
                          <li>
                            <CommonFlexComponent
                              label="Job Category"
                              data={data.jobCategory}
                            />
                          </li>

                          <li>
                            <CommonFlexComponent
                              label="Qualification"
                              data={data.qualifications}
                            />
                          </li>
                          <li>
                            <CommonFlexComponent
                              label="Customer Req. Id"
                              data={data.custReqId}
                            />
                          </li>

                          <li>
                            <CommonFlexComponent
                              label="Start Date"
                              data={
                                data.startDate
                                  ? DateFns.format(data.startDate, "DD-MM-YYYY")
                                  : ""
                              }
                            />
                          </li>

                          <li>
                            <CommonFlexComponent
                              label="  End Date"
                              data={
                                data.endDate
                                  ? DateFns.format(data.endDate, "DD-MM-YYYY")
                                  : ""
                              }
                            />
                          </li>
                          <li>
                            <CommonFlexComponent
                              label="Job Position"
                              data={data.jobPosition}
                            />
                          </li>
                          {/* <li>
                            <CommonFlexComponent
                              label=" Job Description"
                              data={data.jobDescription}
                            />
                          </li> */}
                        </ul>
                      </Col>

                      <Col style={{ marginLeft: "4rem" }} md="5">
                        <ul className="CustDetails-ul m-0">
                          <li>
                            <CommonFlexComponent
                              label="Job Location"
                              data={data.jobLocation}
                            />
                          </li>
                          <li>
                            <CommonFlexComponent
                              label="Primary Skills"
                              data={data.primarySkills}
                            />
                          </li>

                          <li>
                            <CommonFlexComponent
                              label="Secondary Skills"
                              data={data.secondarySkills}
                            />
                          </li>

                          <li>
                            <CommonFlexComponent
                              label="Total Position"
                              data={data.totalPositions}
                            />
                          </li>

                          <li>
                            <CommonFlexComponent
                              label=" Billing Type"
                              data={data.billingType}
                            />
                          </li>

                          <li>
                            <CommonFlexComponent
                              label=" Job Timing"
                              data={data.jobTimings}
                            />
                          </li>

                          <li>
                            <CommonFlexComponent
                              label=" Max CTC"
                              data={data.maxCtc}
                            />
                          </li>

                          <li>
                            <CommonFlexComponent
                              label="Job Priority"
                              data={data.jobPriority}
                            />
                          </li>
                          <li>
                            <CommonFlexComponent
                              label="Min Yrs of Exp"
                              data={data.minYearsOfExp}
                            />
                          </li>

                          <li>
                            <CommonFlexComponent
                              label=" Max Yrs of Exp"
                              data={data.maxYearsOfExp}
                            />
                          </li>
                          <li></li>
                          <li>
                            <CommonFlexComponent
                              label=" Req. Status"
                              data={data.reqStatus}
                            />
                          </li>
                          {/* <li>
                            <CommonFlexComponent
                              label="Comments"
                              data={data.comments}
                            />
                          </li> */}
                        </ul>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" style={{ marginLeft: "3rem" }}>
                        <ul className="CustDetails-ul m-0">
                          <li>
                            <div
                              style={{ display: "flex", marginTop: "0.5rem" }}
                            >
                              <div
                                className="label-sm bold rs-req-span-label-col-1 pt-1"
                                style={{ width: "14.7%" }}
                              >
                                <span
                                  style={{
                                    fontSize: ".875rem",
                                    fontWeight: "500",
                                  }}
                                >
                                  Job Description
                                </span>
                              </div>
                              <div style={{ width: "1%", textAlign: "end" }}>
                                <span className="label-sm">:</span>
                              </div>
                              <div style={{ width: "78%", paddingLeft: "1%" }}>
                                <span style={{fontSize:".75rem ",fontWeight:"400"}}>
                                  {data?.jobDescription}
                                </span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" style={{ marginLeft: "3rem" }}>
                        <ul className="CustDetails-ul">
                          <li>
                            <div
                              style={{ display: "flex", marginTop: "0.5rem" }}
                            >
                              <div
                                className="label-sm bold rs-req-span-label-col-1 pt-1"
                                style={{ width: "14.7%" }}
                              >
                                <span
                                  style={{
                                    fontSize: ".875rem",
                                    fontWeight: "500",
                                  }}
                                >
                                  Comments
                                </span>
                              </div>
                              <div style={{ width: "1%", textAlign: "end" }}>
                                <span className="label-sm">:</span>
                              </div>
                              <div style={{ width: "78%", paddingLeft: "1%" }}>
                                <span style={{fontSize:".75rem ",fontWeight:"400"}}>
                                  {data?.comments}
                                </span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                    <Divider />
                    <DetailsPageFooter
                      createdBy={data.createdBy}
                      createdOn={data.createdOn}
                      updatedBy={data.updatedBy}
                      updatedOn={data.updatedOn}
                    />
                  </div>
                );
              })}
          </Col>
        ) : (
          <div style={{ width: "100%" }}>
            {!this.state.isUpdate && !this.state.isAdd &&  (
              <Col md="12">
                <h5 className="bold center">
                  Update Requirement : {this.props.requirementId}
                </h5>
                <i
                  onClick={() => this.setState({ edit: !this.state.edit })}
                  className="material-icons pointer cancel-button"
                >
                  cancel
                </i>

                <Form onSubmit={this.onSubmit} className="p-3 mt-2" noValidate>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <Label className="bold label-sm">Customer Name</Label>
                        <Select
                          tabIndex="1"
                        
                          defaultInputValue={this.state.customerBodyName}
                          value={this.state.customerName}
                          onChange={this.handleCustomerChange}
                          options={options3}
                          required
                        />
                      </FormGroup>
                      {this.state.selectedOptionError && (
                        <FormText className="error select-search-error">
                          {this.state.selectedOptionError}
                        </FormText>
                      )}

                      <FormGroup>
                        <Label className="bold label-sm">
                          Requirement Type
                        </Label>
                        <Input
                          tabIndex="3"
                          type="select"
                          name="reqType"
                          className="form-control-sm"
                          value={this.state.reqType}
                          onChange={this.onChange}
                          placeholder="Requirement Type"
                          required={true}
                        >
                          <option value="" hidden>
                            Select...
                          </option>
                          {this.props.ReqType.Maindata.map((item) => {
                            return (
                              <option
                                key={item.reqTypeId}
                                value={item.reqTypeName}
                              >
                                {item.reqTypeName}
                              </option>
                            );
                          })}
                        </Input>
                        {errors.reqType && (
                          <FormText className="error">
                            {errors.reqType}
                          </FormText>
                        )}
                      </FormGroup>

                      <FormGroup>
                        <Label className="bold label-sm">
                          Job Qualification
                        </Label>
                        <Select
                          defaultInputValue={this.state.qualificationBodyName}
                          className="select-form-control-sm"
                          tabIndex="6"
                          
                          value={this.state.qualifications}
                          onChange={this.handleQualificationChange}
                          options={options8}
                          required
                        />
                        {this.state.qualificationShowError && (
                          <FormText
                            style={{ marginTop: "0.5rem" }}
                            className="error"
                          >
                            {this.state.qualificationShowError}
                          </FormText>
                        )}
                      </FormGroup>

                      <FormGroup>
                        <Label className="bold label-sm">Job Timing</Label>
                        <Select
                          defaultInputValue={this.state.timingBodyName}
                          className="select-form-control-sm"
                          tabIndex="9"
                         
                          value={this.state.timing}
                          onChange={this.handleTimingChange}
                          options={options9}
                          required
                        />
                      </FormGroup>

                      <Row>
                        <Col md="6" className="p-0">
                          <FormComponent
                            style={{ width: "90%" }}
                            tabIndex="13"
                            labelClass="bold label-sm"
                            label="Start Date"
                            type="date"
                            name="startDate"
                            change={this.onChange}
                            value={this.state.startDate}
                            // required={true}
                          />
                        </Col>
                        <Col md="6" className="pr-0">
                          <FormComponent
                            tabIndex="14"
                            labelClass="bold label-sm"
                            label="End Date"
                            type="date"
                            name="endDate"
                            change={this.onChange}
                            value={this.state.endDate}
                            min={this.state.startDate}
                            error={this.state.dateError}
                            // required={true}
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col md="4">
                      <FormGroup>
                        <Label className="bold label-sm">TAC Name</Label>
                        <Select
                          tabIndex="2"
                         
                          defaultInputValue={this.state.spocBodyContactName}
                          value={this.state.spocName}
                          onChange={this.handleContactChange}
                          options={options4}
                          required
                        />
                        {this.state.selectedOptionErrorOne && (
                          <FormText className="error select-search-error">
                            {this.state.selectedOptionErrorOne}
                          </FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold label-sm">Job Category</Label>
                        <Select
                          className="select-form-control-sm"
                          defaultInputValue={this.state.categoryBodyName}
                          tabIndex="4"
                         
                          value={this.state.jobCategory}
                          onChange={this.handleCategoryChange}
                          options={options6}
                          required
                        />
                      </FormGroup>
                      {this.state.categoryErrorShow && (
                        <FormText
                          style={{ marginTop: "0.5rem" }}
                          className="error select-search-error"
                        >
                          {this.state.categoryErrorShow}
                        </FormText>
                      )}

                      <FormComponent
                        tabIndex="7"
                        labelClass="bold label-sm"
                        label="Job Location"
                        type="text"
                        name="location"
                        change={this.onChange}
                        value={this.state.location}
                        maxlength="27"
                        placeholder="Job Location"
                        required={true}
                      />

                      <FormComponent
                        tabIndex="10"
                        labelClass="bold label-sm"
                        label="Max CTC"
                        type="number"
                        name="salaryRange"
                        change={this.onChange}
                        value={this.state.salaryRange}
                        // required={true}
                        placeholder="Max CTC"
                        maxlength="15"
                      />
                      <div style={{ width: "100%", display: "flex" }}>
                        <div>
                          <FormComponent
                            style={{ paddingRight: "1rem", width: "90%" }}
                            tabIndex="15"
                            labelClass="bold label-sm"
                            label="Years of Exp."
                            type="number"
                            name="minYears"
                            change={this.onChange}
                            value={this.state.minYears}
                            required={true}
                            placeholder="Min Years "
                          />
                          {this.state.yearsError && (
                            <FormText className="error">
                              {this.state.yearsError}
                            </FormText>
                          )}
                        </div>
                        <div>
                          <FormComponent
                            style={{ marginTop: "1.5rem" }}
                            tabIndex="16"
                            labelClass="bold label-sm"
                            type="number"
                            name="maxYears"
                            change={this.onChange}
                            value={this.state.maxYears}
                            placeholder="Max Years "
                          />
                          {this.state.maxyearsError && (
                            <FormText className="error">
                              {this.state.maxyearsError}
                            </FormText>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md="4">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "1rem",
                        }}
                      >
                        <span className="bold label-sm">
                          {" "}
                          TAC Email:
                          <span style={{fontSize:"0.75rem",fontWeight:400, marginLeft: "1rem" }}>
                            {this.state.spocMail}
                          </span>
                        </span>
                        <span
                          style={{ marginTop: "0.5rem" }}
                          className="bold label-sm"
                        >
                          TAC Mobile No:
                          <span style={{fontSize:"0.75rem",fontWeight:400, marginLeft: "1rem" }}>
                            {this.state.spocContact}
                          </span>
                        </span>
                      </div>

                      <FormGroup style={{ marginTop: "0.6rem" }}>
                        <Label className="bold label-sm">Job Position</Label>
                        <Select
                          defaultInputValue={this.state.positionBodyName}
                          tabIndex="5"
                         
                          value={this.state.jobRole}
                          onChange={this.handlePositionChange}
                          options={options7}
                          required
                        />
                      </FormGroup>
                      {this.state.positionErrorShow && (
                        <FormText className="error select-search-error">
                          {this.state.positionErrorShow}
                        </FormText>
                      )}

                      <FormGroup>
                        <Label className="bold label-sm">Job Priority</Label>
                        <Input
                          tabIndex="8"
                          type="select"
                          name="critical"
                          className="form-control-sm"
                          value={this.state.critical}
                          onChange={this.onChange}

                          // required={true}
                        >
                          <option value="" hidden>
                            Select...
                          </option>
                          {this.state.crucialArr.map((item) => {
                            return (
                              <option
                                key={item.jobPositionId}
                                value={item.value}
                              >
                                {item.value}
                              </option>
                            );
                          })}
                        </Input>
                        {errors.critical && (
                          <FormText className="error">
                            {errors.critical}
                          </FormText>
                        )}
                      </FormGroup>
                      <div style={{ width: "100%", display: "flex" }}>
                        <FormComponent
                          style={{ width: "90%" }}
                          tabIndex="11"
                          labelClass="bold label-sm"
                          label="Customer Req Id"
                          type="number"
                          name="custReqId"
                          change={this.onChange}
                          value={this.state.custReqId}
                          // required={true}
                          placeholder="Customer Req Id"
                          maxlength="10"
                        />

                        <FormComponent
                          tabIndex="12"
                          labelClass="bold label-sm"
                          label="Total Positions"
                          type="number"
                          name="totalPos"
                          change={this.onChange}
                          value={this.state.totalPos}
                          // required={true}
                          placeholder="Total Position"
                          maxlength="10"
                        />
                      </div>

                      <div style={{ width: "100%", display: "flex" }}>
                        {this.state.reqType !== "FTE" && (
                          <FormGroup>
                            <Label className="bold label-sm">
                              Billing Type
                            </Label>
                            <Input
                              style={{ width: "10rem" }}
                              tabIndex="17"
                              type="select"
                              name="billingType"
                              className="form-control-sm"
                              value={this.state.billingType}
                              onChange={this.onChange}
                              placeholder="Requirement Type"
                              // required={true}
                            >
                              <option value="" hidden>
                                Select...
                              </option>
                              {this.props.billingmain.mainData.map((item) => {
                                return (
                                  <option
                                    key={item.id}
                                    value={item.billingType}
                                  >
                                    {item.billingType}
                                  </option>
                                );
                              })}
                            </Input>
                            {errors.billingType && (
                              <FormText className="error">
                                {errors.billingType}
                              </FormText>
                            )}
                          </FormGroup>
                        )}

                        <FormGroup>
                          <Label
                            style={{ marginLeft: "0.5rem" }}
                            className="bold label-sm"
                          >
                            Requirement Status
                          </Label>
                          <Input
                            style={{ width: "10rem", marginLeft: "0.5rem" }}
                            tabIndex="18"
                            type="select"
                            name="reqStatus"
                            className="form-control-sm"
                            value={this.state.reqStatus}
                            onChange={this.onChange}
                            placeholder="Requirement Status"
                            // required={true}
                          >
                            <option value="" hidden>
                              Select...
                            </option>
                            {this.props.reqStatus.mainData.map((item) => {
                              return (
                                <option key={item.id} value={item.reqStatus}>
                                  {item.reqStatus}
                                </option>
                              );
                            })}
                          </Input>
                          {errors.reqStatus && (
                            <FormText className="error">
                              {errors.reqStatus}
                            </FormText>
                          )}
                        </FormGroup>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label className="bold label-sm">Primary Skills</Label>
                        <Select
                          tabIndex="18"
                         
                          value={this.state.haveSkills}
                          onChange={this.handlePrimarySkillsChange}
                          options={options5}
                          Primary
                          required
                        />
                      </FormGroup>
                      {this.state.primarySkillErrorShow && (
                        <FormText className="error select-search-error">
                          {this.state.primarySkillErrorShow}
                        </FormText>
                      )}
                      {this.state.filteredPrimaryValueArray.length > 0 && (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexWrap: "wrap",
                            border: "1px solid grey",
                          }}
                        >
                          {this.state.filteredPrimaryValueArray.map((data) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  minWidth: "4rem",
                                  background: "#ECECEC",
                                  border: "none",
                                  height: "2rem",
                                  marginTop: "0.1rem",
                                  marginBottom: "0.1rem",
                                  marginLeft: "0.5rem",
                                  borderRadius: "20px",
                                  paddingRight: "0.2rem",
                                  paddingLeft: "0.2rem",
                                }}
                              >
                                <span style={{fontSize:".75rem"}}>
                                  {data}
                                </span>

                                <h3
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="cancel"
                                  onClick={() =>
                                    this.closeDataPrimarySkill(data)
                                  }
                                  style={{
                                    color: "#04044c",
                                  }}
                                >
                                  {" "}
                                  <MdCancel color="red" size="20" />{" "}
                                </h3>
                              </div>
                            );
                          })}
                        </div>
                      )}
                      <FormComponent
                        style={{ height: "40px" }}
                        tabIndex="20"
                        labelClass="bold label-sm"
                        label="Job Description"
                        type="textarea"
                        name="description"
                        change={this.onChange}
                        value={this.state.description}
                        placeholder="Job Description"
                        required={true}
                      />
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label className="bold label-sm">
                          Secondary Skills
                        </Label>
                        <Select
                          tabIndex="18"
                         
                          value={this.state.niceSkills}
                          onChange={this.handleSecondarySkillsChange}
                          options={options5}
                          Primary
                          required
                        />
                      </FormGroup>

                      {this.state.filteredSecondaryValueArray.length > 0 && (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexWrap: "wrap",
                            border: "1px solid grey",
                            // paddingBottom: "0.5rem",
                          }}
                        >
                          {this.state.filteredSecondaryValueArray.map(
                            (data) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    minWidth: "4rem",
                                    background: "#ECECEC",
                                    border: "none",
                                    height: "2rem",
                                    marginTop: "0.1rem",
                                    marginBottom: "0.1rem",
                                    marginLeft: "0.5rem",
                                    borderRadius: "20px",
                                    paddingRight: "0.2rem",
                                    paddingLeft: "0.2rem",
                                  }}
                                >
                                  <span style={{fontSize:".75rem"}}>
                                    {data}
                                  </span>

                                  <h3
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="cancel"
                                    onClick={() =>
                                      this.closeDataSecondarySkill(data)
                                    }
                                    style={{
                                      color: "#04044c",
                                    }}
                                  >
                                    {" "}
                                    <MdCancel color="red" size="20" />{" "}
                                  </h3>
                                </div>
                              );
                            }
                          )}
                        </div>
                      )}
                      <FormComponent
                        style={{ height: "40px" }}
                        tabIndex="22"
                        labelClass="bold label-sm"
                        label="Comments"
                        type="textarea"
                        name="notes"
                        change={this.onChange}
                        value={this.state.notes}
                        placeholder="Comments"
                        maxlength="10"
                      />
                    </Col>
                  </Row>
                  <div className="center">
                    <button
                      type="submit"
                      className="btn center login-button white-text"
                      tabIndex={23}
                      // disabled={this.state.buttonValue ? "disabled" : null}
                    >
                      Update
                    </button>
                  </div>
                </Form>
              </Col>
            )}{" "}
          </div>
        )}
        {this.state.isUpdate && (
          <PublishJob
            getData={this.state.getData}
            reqType={this.props.requirementId}
            back={this.handleClosePublishjob}
          />
        )}
          {this.state.isAdd && <AddCandidate reqId={this.props.requirementId} toggle={this.openAddView} />}
        {this.state.message ? <Notifier message={this.state.message} /> : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
        {this.props.requirements.message ? (
          <Notifier message={this.props.requirements.message} />
        ) : null}
        {this.props.requirements.error ? (
          <ErrorNotifier message={this.props.requirements.error} />
        ) : null}
      </Row>
    );
  }
}

ViewRequirement.propTypes = {
  updateRequirement: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  requirements: state.RSrequirement,
  RSrequirementlist: state.RSrequirementlist,
  RSbillinglist: state.RSbillinglist,
  RScategorylist: state.RScategorylist,
  Rspositionreducer: state.Rspositionreducer,
  reqStatus: state.reqStatus,
  ReqType: state.ReqType,
  CandidateStatus: state.CandidateStatus,
  HrNotice: state.HrNotice,
  customers: state.customers,
  crmContacts: state.crmContacts,
  QualificationRs: state.QualificationRs,
  CanConSkill: state.CanConSkill,
  JobCategory: state.JobCategory,
  JobPosition: state.JobPosition,
  billingmain: state.billingmain,
  jobTiming: state.jobTiming,
});

export default connect(mapStateToProps, {
  updateRequirement,
  getRequiremnetType,
  getBillingType,
  getreqType,
  getCategorytype,
  getHrLead,
  getPositionType,
  getRequirementStatus,
  getCustomersName,
  getQualification,
  getContactsRSName,
  getAllSkillsSelect,
  getRequirementStatusRs,
  getreqTypeRs,
  getBillingRsTypeRs,
  getJobCategoryRs,
  getJobPositionRs,
  getQualificationRs,
  getJobTimingSelect,
})(withRouter(ViewRequirement));
