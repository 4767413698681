import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Form, FormGroup, Label, Input, FormText } from "reactstrap";
// components
import {
  Validator,
  submitValidator,
  refresh,
  valid,
  errors,
} from "../../../../common/Validator";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";

// redux
import { addTeamAssignment } from "../../../../../redux/actions/experience_360/teamAssignmentAction";
import { getUserProjectTeam } from "../../../../../redux/actions/experience_360/e360_config/projectTeamAction";
import { getUserProjectMember } from "../../../../../redux/actions/experience_360/e360_config/projectMemberAction";

// ------------------------------------------------------------------------------------------------------------

class AddTeamAssignment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestId: this.props.requestId,
      projectTeam: "",
      empNo: "",
      buttonValue: false,
      formErrors: {
        selectError: "",
      },
      error: "",
      message: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    e.preventDefault();
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let contact = {
      e360requestId: this.state.requestId,
      projectTeam: this.state.projectTeam,
      empNo: this.state.empNo.split(",")[0],
      empName: this.state.empNo.split(",")[1],
      isActive: true,
    };
    if (valid) {
      this.props.addTeamAssignment(contact, this.props.back);
    } else {
      let errors = this.state.formErrors;
      errors.selectError = "Please select option";
      this.setState({
        formErrors: errors,
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    refresh();
    this.setState({
      message: "",
      error: "",
    });
    this.props.getUserProjectTeam();
    this.props.getUserProjectMember();
  }

  render() {
    return (
      <>
        <Form onSubmit={this.onSubmit} className="p-3 mt-2" noValidate>
          <Row style={{ width: "50vh" }}>
            <Col md="12">
              <FormGroup>
                <Label className="label-sm bold">Project Team</Label>
                <Input
                  type="select"
                  name="projectTeam"
                  onChange={this.onChange}
                  value={this.state.projectTeam}
                  className="form-control-sm"
                  required
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {this.props.projectTeam.data.length > 0 &&
                    this.props.projectTeam.data.map((item) => {
                      return (
                        <option key={item.id} value={item.teamName}>
                          {" "}
                          {item.teamName}
                        </option>
                      );
                    })}
                </Input>
                {errors.projectTeam && (
                  <FormText className="error">{errors.projectTeam}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="label-sm bold">Project Member</Label>
                <Input
                  type="select"
                  name="empNo"
                  onChange={this.onChange}
                  value={this.state.empNo}
                  className="form-control-sm"
                  required
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {this.props.projectMember.data.length > 0 &&
                    this.props.projectMember.data.map((item) => {
                      if (this.state.projectTeam === item.teamName) {
                        return (
                          <option
                            key={item.id}
                            value={`${item.empNo},${item.empName}`}
                          >
                            {" "}
                            {item.empNo}-{item.empName}
                          </option>
                        );
                      }
                    })}
                </Input>
                {errors.projectMember && (
                  <FormText className="error">{errors.projectMember}</FormText>
                )}
              </FormGroup>
            </Col>
          </Row>
          <div className="center">
            <button
              type="submit"
              className="btn center login-button white-text"
            >
              Submit
            </button>
          </div>
        </Form>
        {this.props.teamAssignment.message ? (
          <Notifier message={this.props.teamAssignment.message} />
        ) : null}
        {this.props.teamAssignment.error ? (
          <ErrorNotifier message={this.props.teamAssignment.error} />
        ) : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
      </>
    );
  }
}

AddTeamAssignment.propTypes = {
  addTeamAssignment: PropTypes.func.isRequired,
  teamAssignment: PropTypes.object.isRequired,
  getUserProjectTeam: PropTypes.func.isRequired,
  projectTeam: PropTypes.object.isRequired,
  getUserProjectMember: PropTypes.func.isRequired,
  projectMember: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  teamAssignment: state.teamAssignment,
  projectTeam: state.projectTeam,
  projectMember: state.projectMember,
});

export default connect(mapStateToProps, {
  addTeamAssignment,
  getUserProjectTeam,
  getUserProjectMember,
})(AddTeamAssignment);
