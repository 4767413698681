import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getEmployeeDetails } from "../../../../redux/actions/employeeAction";
import {
  Container,
  Row,
  Col,
  Table,
  FormGroup,
  FormText,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
} from "reactstrap";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import axios from "axios";
import {
  FETCH_MYDAY,
  CURD_LEAD_ACTIVITY,
  UPDATE_PROSPECTS_ACTIVITY,
  UPDATE_OPPORTUNITY_ACTIVITY,
} from "../../../../utils/routes";
import {
  errors,
  submitValidator,
  Validator,
  valid,
} from "../../../common/Validator";
import DateFns from "date-fns";
import close from "../../../../assets/img/close.png";
import { Link } from "react-router-dom";

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leadActivityData: [],
      prospectActivityData: [],
      opportunityActivityData: [],
      leadActivityId: "",
      leadId: "",
      prospectActivityId: "",
      prospectId: "",
      opportunityActivityId: "",
      opportunityId: "",
      activity: "",
      date: "",
      time: "",
      assignedTo: "",
      activityStatus: "",
      description: "",
      leadActivityUpdateModal: false,
      prospectActivityUpdateModal: false,
      opportunityActivityUpdateModale: false,
      permissionlist: this.props.auth.permissionlist || {},
      message: "",
      error: "",
      formErrors: {
        assignedTo: "",
      },
    };

    this.leadActivityUpdate = this.leadActivityUpdate.bind(this);
    this.leadActivityUpdateToggle = this.leadActivityUpdateToggle.bind(this);
    this.leadActivityUpdateSubmit = this.leadActivityUpdateSubmit.bind(this);
    this.getData = this.getData.bind(this);
    this.prospectActivityUpdate = this.prospectActivityUpdate.bind(this);
    this.prospectActivityUpdateToggle =
      this.prospectActivityUpdateToggle.bind(this);
    this.prospectActivityUpdateSubmit =
      this.prospectActivityUpdateSubmit.bind(this);
    this.opportunityActivityUpdate = this.opportunityActivityUpdate.bind(this);
    this.opportunityActivityUpdateToggle =
      this.opportunityActivityUpdateToggle.bind(this);
    this.opportunityActivityUpdateSubmit =
      this.opportunityActivityUpdateSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.getData();
    this.props.getEmployeeDetails();
  }

  getData() {
    let data = {
      empId: localStorage.getItem("id"),
    };

    axios
      .post(FETCH_MYDAY, data, { withCredentials: true })
      .then((res) => {
        this.setState({
          leadActivityData: res.data[0].Leadactivity,
          prospectActivityData: res.data[0].Prospectactivity,
          opportunityActivityData: res.data[0].Opportunityactivity,
        });
      })
      .catch((err) => {
        this.setState({
          error: err.message,
        });
        setTimeout(() => {
          this.setState({
            error: "",
          });
        }, 2000);
      });
  }

  leadActivityUpdate(item) {
    this.setState({
      leadActivityId: item.leadActivityId,
      leadId: item.leadId,
      activity: item.activityType,
      date: item.date,
      time: item.time,
      assignedTo: item.assignedTo,
      activityStatus: item.status,
      description: item.description,
      leadActivityUpdateModal: !this.state.leadActivityUpdateModal,
    });
  }

  leadActivityUpdateToggle() {
    this.setState({
      leadActivityUpdateModal: !this.state.leadActivityUpdateModal,
    });
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  leadActivityUpdateSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      leadActivityId: this.state.leadActivityId,
      leadId: this.state.leadId,
      activityType: this.state.activity,
      date: this.state.date,
      time: this.state.time,
      assignedTo: this.state.assignedTo,
      status: this.state.activityStatus,
      description: this.state.description,
      updatedBy: localStorage.getItem("id"),
    };
    if (this.state.assignedTo && valid) {
      axios
        .post(CURD_LEAD_ACTIVITY, data, { withCredentials: true })
        .then((res) => {
          this.setState({
            message: res.data,
          });
          setTimeout(() => {
            this.setState({
              message: "",
            });
            this.getData();
            this.leadActivityUpdateToggle();
          });
        }, 2000)
        .catch((err) => {
          this.setState({
            error: "Creation failed",
          });
          setTimeout(() => {
            this.setState({
              message: "",
            });
          }, 2000);
        });
    } else {
      let errors = this.state.formErrors;
      errors.assignedTo = "Please select option";
      this.setState({
        formErrors: errors,
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  prospectActivityUpdate(item) {
    this.setState({
      prospectActivityId: item.prospectActivityId,
      prospectId: item.prospectId,
      activity: item.activityName,
      date: item.date,
      time: item.time,
      assignedTo: item.assignedTo,
      activityStatus: item.status,
      description: item.description,
      prospectActivityUpdateModal: !this.state.prospectActivityUpdateModal,
    });
  }

  prospectActivityUpdateToggle() {
    this.setState({
      prospectActivityUpdateModal: !this.state.prospectActivityUpdateModal,
    });
  }

  prospectActivityUpdateSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      prospectActivityId: this.state.prospectActivityId,
      prospectId: this.state.prospectId,
      activityName: this.state.activity,
      date: this.state.date,
      time: this.state.time,
      assignedTo: this.state.assignedTo,
      status: this.state.activityStatus,
      description: this.state.description,
      updatedBy: localStorage.getItem("id"),
    };
    if (this.state.assignedTo && valid) {
      axios
        .post(UPDATE_PROSPECTS_ACTIVITY, data, { withCredentials: true })
        .then((res) => {
          this.setState({
            message: res.data,
          });
          setTimeout(() => {
            this.setState({
              message: "",
            });
            this.getData();
            this.prospectActivityUpdateToggle();
          }, 2000);
        })
        .catch((err) => {
          this.setState({
            error: err.message,
          });
          setTimeout(() => {
            this.setState({
              error: "",
            });
          }, 2000);
        });
    } else {
      let errors = this.state.formErrors;
      errors.assignedTo = "Please select option";
      this.setState({
        formErrors: errors,
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  opportunityActivityUpdate(item) {
    this.setState({
      opportunityActivityId: item.oppActivityId,
      opportunityId: item.opportunityId,
      activity: item.activity,
      date: item.date,
      time: item.time,
      assignedTo: item.assignedTo,
      activityStatus: item.status,
      description: item.description,
      opportunityActivityUpdateModal:
        !this.state.opportunityActivityUpdateModal,
    });
  }

  opportunityActivityUpdateToggle() {
    this.setState({
      opportunityActivityUpdateModal:
        !this.state.opportunityActivityUpdateModal,
    });
  }

  opportunityActivityUpdateSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      oppActivityId: this.state.opportunityActivityId,
      opportunityId: this.state.opportunityId,
      activity: this.state.activity,
      date: this.state.date,
      time: this.state.time,
      assignedTo: this.state.assignedTo,
      status: this.state.activityStatus,
      description: this.state.description,
      updatedBy: localStorage.getItem("id"),
    };
    if (this.state.assignedTo && valid) {
      axios
        .post(UPDATE_OPPORTUNITY_ACTIVITY, data, {
          withCredentials: true,
        })
        .then((res) => {
          this.setState({
            message: res.data,
          });
          setTimeout(() => {
            this.setState({
              message: "",
            });
            this.getData();
            this.opportunityActivityUpdateToggle();
          }, 2000);
        })
        .catch((err) => {
          this.setState({
            error: err.message,
          });
          setTimeout(() => {
            this.setState({
              error: "",
            });
          }, 2000);
        });
    } else {
      let errors = this.state.formErrors;
      errors.activityAssignedTo = "Please select option";
      this.setState({
        formErrors: errors,
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  render() {
    let shrink;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");
    const {
      leadActivityData,
      prospectActivityData,
      opportunityActivityData,
      permissionlist,
    } = this.state;
    const { assignedTo } = this.state.formErrors;

    let currentPermission;
    for (let x in permissionlist.response.permissions1) {
      if (permissionlist.response.permissions1[x].subMenuId === "1.1") {
        currentPermission = permissionlist.response.permissions1[x];
      }
    }

    return (
      <div className={shrink}>
        <Container className="card container-card close-img-container">
          <div>
            <Link to="/pie">
              <img src={close} className="close-img" />
            </Link>
          </div>
          <Row>
            <Col md="6">
              <div className="card p-2 mt-3">
                <h5 className="center bold">Tasks To Do</h5>
                {leadActivityData.length !== 0 ? (
                  <div>
                    <Table>
                      <thead>
                        <tr>Lead </tr>
                        <tr>
                          {/* <th >ID</th> */}
                          <th className="opp-head">Activity</th>
                          <th className="opp-head">Date</th>
                          <th className="opp-head">Time</th>
                          <th className="opp-head">AssignedTo</th>
                          <th className="opp-head">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {leadActivityData &&
                          leadActivityData.map((item) => {
                            return (
                              <div>
                                {item.status !== "Completed" ? (
                                  <tr
                                  // onMouseEnter={(e) => this.toggleActivityHoverOn(e, item)}
                                  // onMouseLeave={this.toggleActivityHoverOff}
                                  >
                                    {/* <td >{item.leadActivityId}</td> */}
                                    <td className="opp-tableData">
                                      {item.activityType}
                                    </td>
                                    <td className="opp-tableData">
                                      {DateFns.format(item.date, "DD-MM-YYYY")}
                                    </td>
                                    <td className="opp-tableData">
                                      {item.time}
                                    </td>
                                    <td className="opp-tableData">
                                      {item.assignedTo}
                                    </td>
                                    <td className="opp-tableData">
                                      {item.status}
                                    </td>
                                    <td>
                                      {currentPermission.update1 && (
                                        <span
                                          className="product-pq-button"
                                          id="oppr-activity-edit-button"
                                          title="Edit"
                                          style={{ color: "blue" }}
                                          onClick={() =>
                                            this.leadActivityUpdate(item)
                                          }
                                        >
                                          edit
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                ) : null}
                              </div>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                ) : null}
                {prospectActivityData.length !== 0 ? (
                  <div>
                    <hr />
                    <Table>
                      <thead>
                        <tr>Prospects </tr>
                        <tr>
                          {/* <th >ID</th> */}
                          <th className="opp-head">Activity</th>
                          <th className="opp-head">Date</th>
                          <th className="opp-head">Time</th>
                          <th className="opp-head">AssignedTo</th>
                          <th className="opp-head">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {prospectActivityData &&
                          prospectActivityData.map((item) => {
                            return (
                              <div>
                                {item.status !== "Completed" ? (
                                  <tr
                                  // onMouseEnter={(e) => this.toggleActivityHoverOn(e, item)}
                                  // onMouseLeave={this.toggleActivityHoverOff}
                                  >
                                    {/* <td >{item.prospectActivityId}</td> */}
                                    <td className="opp-tableData">
                                      {item.activityName}
                                    </td>
                                    <td className="opp-tableData">
                                      {DateFns.format(item.date, "DD-MM-YYYY")}
                                    </td>
                                    <td className="opp-tableData">
                                      {item.time}
                                    </td>
                                    <td className="opp-tableData">
                                      {item.assignedTo}
                                    </td>
                                    <td className="opp-tableData">
                                      {item.status}
                                    </td>
                                    <td>
                                      {currentPermission.update1 && (
                                        <span
                                          className="product-pq-button"
                                          id="oppr-activity-edit-button"
                                          title="Edit"
                                          style={{ color: "blue" }}
                                          onClick={() =>
                                            this.prospectActivityUpdate(item)
                                          }
                                        >
                                          edit
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                ) : null}
                              </div>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                ) : null}
                {opportunityActivityData.length !== 0 ? (
                  <div>
                    <hr />
                    <Table>
                      <thead>
                        <tr>Opportunity</tr>
                        <tr>
                          {/* <th className="opp-head task-id">ID</th> */}
                          <th className="opp-head">Activity</th>
                          <th className="opp-head">Date</th>
                          <th className="opp-head">Time</th>
                          <th className="opp-head">AssignedTo</th>
                          <th className="opp-head">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {opportunityActivityData &&
                          opportunityActivityData.map((item) => {
                            return (
                              <div>
                                {item.status !== "Completed" ? (
                                  <tr
                                  // onMouseEnter={(e) => this.toggleActivityHoverOn(e, item)}
                                  // onMouseLeave={this.toggleActivityHoverOff}
                                  >
                                    {/* <td className="opp-tableData task-id">{item.oppActivityId}</td> */}
                                    <td className="opp-tableData">
                                      {item.activity}
                                    </td>
                                    <td className="opp-tableData">
                                      {DateFns.format(item.date, "DD-MM-YYYY")}
                                    </td>
                                    <td className="opp-tableData">
                                      {item.time}
                                    </td>
                                    <td className="opp-tableData">
                                      {item.assignedTo}
                                    </td>
                                    <td className="opp-tableData">
                                      {item.status}
                                    </td>
                                    <td>
                                      {currentPermission.update1 && (
                                        <span
                                          className="product-pq-button"
                                          id="oppr-activity-edit-button"
                                          title="Edit"
                                          style={{ color: "blue" }}
                                          onClick={() =>
                                            this.opportunityActivityUpdate(item)
                                          }
                                        >
                                          edit
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                ) : null}
                              </div>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                ) : null}
              </div>
            </Col>
            <Col md="6">
              <div className="card p-2 mt-3">
                <h5 className="bold center">Insights</h5>
              </div>
            </Col>
          </Row>
        </Container>
        <Modal
          size="md"
          isOpen={this.state.leadActivityUpdateModal}
          className="apply-modal"
        >
          <ModalHeader toggle={this.leadActivityUpdateToggle}>
            Lead Update Activity
          </ModalHeader>
          <ModalBody>
            <Form
              className="p-3"
              onSubmit={this.leadActivityUpdateSubmit}
              noValidate
            >
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label className=" bold">Activity</Label>
                    <Input
                      type="text"
                      name="activity"
                      onChange={this.onChange}
                      value={this.state.activity}
                      className="form-control-sm"
                      required={true}
                    />
                    {errors.activity && (
                      <FormText className="error">{errors.activity}</FormText>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label className=" bold">Date</Label>
                    <Input
                      type="date"
                      name="date"
                      value={this.state.date}
                      onChange={this.onChange}
                      className="form-control-sm "
                      required={true}
                    />
                    {errors.date && (
                      <FormText className="error">{errors.date}</FormText>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label className="bold">Time</Label>
                    <Input
                      type="time"
                      name="time"
                      value={this.state.time}
                      onChange={this.onChange}
                      className="form-control-sm"
                      required={true}
                    />
                    {errors.time && (
                      <FormText className="error">{errors.time}</FormText>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label className="bold">Assigned To</Label>
                    <Input
                      type="select"
                      name="assignedTo"
                      value={this.state.assignedTo}
                      className="form-control-sm"
                      onChange={this.onChange}
                    >
                      <option hidden>Not Assigned</option>
                      {this.props.employee &&
                        this.props.employee.data.map((item) => {
                          if (item.empActiveStatus === "Active") {
                            return (
                              <option key={item.empId} value={item.empId}>
                                {item.empId}-{item.firstName}
                              </option>
                            );
                          }
                        })}
                    </Input>
                    {assignedTo && this.state.assignedTo ? (
                      ""
                    ) : (
                      <FormText className="error">{assignedTo}</FormText>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label className="bold">Status</Label>
                    <Input
                      type="select"
                      name="activityStatus"
                      value={this.state.activityStatus}
                      className="form-control-sm"
                      onChange={this.onChange}
                    >
                      <option value="Open">Open</option>
                      <option value="WIP">WIP</option>
                      <option value="Pending">Pending</option>
                      <option value="Hold">Hold</option>
                      <option value="Completed">Completed</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label className=" bold">Description</Label>
                    <Input
                      name="description"
                      type="textarea"
                      value={this.state.description}
                      className="form-control-sm"
                      onChange={this.onChange}
                      required={true}
                    />
                    {errors.description && (
                      <FormText className="error">
                        {errors.description}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup className="center">
                <button type="submit" className="btn white-text login-button">
                  Submit
                </button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
        <Modal
          size="md"
          isOpen={this.state.prospectActivityUpdateModal}
          className="apply-modal"
        >
          <ModalHeader toggle={this.prospectActivityUpdateToggle}>
            Prospect Update Activity
          </ModalHeader>
          <ModalBody>
            <Form
              className="p-3"
              onSubmit={this.prospectActivityUpdateSubmit}
              noValidate
            >
              <Row>
                <Col md="6" className="prospect-activity-col">
                  <FormGroup>
                    <Label className=" bold">Activity</Label>
                    <Input
                      type="select"
                      name="activity"
                      onChange={this.onChange}
                      value={this.state.activity}
                      className="form-control-sm"
                      required={true}
                    >
                      <option value="Capability Presentation">
                        Capability Presentation
                      </option>
                      <option value="Demo">Demo</option>
                      <option value="Solution Presentation">
                        Solution Presentation
                      </option>
                      <option value="Meetings">Meetings</option>
                    </Input>
                    {errors.activity && (
                      <FormText className="error">{errors.activity}</FormText>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label className=" bold">Date</Label>
                    <Input
                      type="date"
                      name="date"
                      value={this.state.date}
                      onChange={this.onChange}
                      className="form-control-sm "
                      required={true}
                    />
                    {errors.date && (
                      <FormText className="error">{errors.date}</FormText>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label className="bold">Time</Label>
                    <Input
                      type="time"
                      name="time"
                      value={this.state.time}
                      onChange={this.onChange}
                      className="form-control-sm"
                      required={true}
                    />
                    {errors.time && (
                      <FormText className="error">{errors.time}</FormText>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label className="bold">Assigned To</Label>
                    <Input
                      type="select"
                      name="assignedTo"
                      value={this.state.assignedTo}
                      className="form-control-sm"
                      onChange={this.onChange}
                    >
                      <option hidden>Not Assigned</option>
                      {this.props.employee &&
                        this.props.employee.data.map((item) => {
                          if (item.empActiveStatus === "Active") {
                            return (
                              <option key={item.empId} value={item.empId}>
                                {item.empId}-{item.firstName}
                              </option>
                            );
                          }
                        })}
                    </Input>
                    {assignedTo && this.state.assignedTo ? (
                      ""
                    ) : (
                      <FormText className="error">{assignedTo}</FormText>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label className="bold">Status</Label>
                    <Input
                      type="select"
                      name="activityStatus"
                      value={this.state.activityStatus}
                      className="form-control-sm"
                      onChange={this.onChange}
                    >
                      <option value="Open">Open</option>
                      <option value="WIP">WIP</option>
                      <option value="Pending">Pending</option>
                      <option value="Hold">Hold</option>
                      <option value="Completed">Completed</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label className=" bold">Description</Label>
                    <Input
                      name="description"
                      type="textarea"
                      value={this.state.description}
                      className="form-control-sm"
                      onChange={this.onChange}
                      required={true}
                    />
                    {errors.description && (
                      <FormText className="error">
                        {errors.description}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup className="center">
                <button type="submit" className="btn white-text login-button">
                  Submit
                </button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
        <Modal
          size="md"
          isOpen={this.state.opportunityActivityUpdateModal}
          className="apply-modal"
        >
          <ModalHeader toggle={this.opportunityActivityUpdateToggle}>
            Opportunity Update Activity
          </ModalHeader>
          <ModalBody>
            <Form
              className="p-3"
              onSubmit={this.opportunityActivityUpdateSubmit}
              noValidate
            >
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label className=" bold">Activity</Label>
                    <Input
                      type="select"
                      name="activity"
                      onChange={this.onChange}
                      value={this.state.activity}
                      className="form-control-sm"
                      required={true}
                    >
                      <option value="Capability Presentation">
                        Capability Presentation
                      </option>
                      <option value="Demo">Demo</option>
                      <option value="Solution Presentation">
                        Solution Presentation
                      </option>
                      <option value="Meetings">Meetings</option>
                      <option value="Follow up">Follow up</option>
                    </Input>
                    {errors.activity && (
                      <FormText className="error">{errors.activity}</FormText>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label className=" bold">Date</Label>
                    <Input
                      type="date"
                      name="date"
                      value={this.state.date}
                      onChange={this.onChange}
                      className="form-control-sm "
                      required={true}
                    />
                    {errors.date && (
                      <FormText className="error">{errors.date}</FormText>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label className="bold">Time</Label>
                    <Input
                      type="time"
                      name="time"
                      value={this.state.time}
                      onChange={this.onChange}
                      className="form-control-sm"
                      required={true}
                    />
                    {errors.time && (
                      <FormText className="error">{errors.time}</FormText>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label className="bold">Assigned To</Label>
                    <Input
                      type="select"
                      name="assignedTo"
                      value={this.state.assignedTo}
                      className="form-control-sm"
                      onChange={this.onChange}
                    >
                      <option hidden>Not Assigned</option>
                      {this.props.employee &&
                        this.props.employee.data.map((item) => {
                          if (item.empActiveStatus === "Active") {
                            return (
                              <option value={item.empId} key={item.empId}>
                                {item.empId}-{item.firstName}
                              </option>
                            );
                          }
                        })}
                    </Input>
                    {assignedTo && this.state.assignedTo ? (
                      ""
                    ) : (
                      <FormText className="error">{assignedTo}</FormText>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label className="bold">Status</Label>
                    <Input
                      type="select"
                      name="activityStatus"
                      value={this.state.activityStatus}
                      className="form-control-sm"
                      onChange={this.onChange}
                    >
                      <option value="Open">Open</option>
                      <option value="WIP">WIP</option>
                      <option value="Pending">Pending</option>
                      <option value="Hold">Hold</option>
                      <option value="Completed">Completed</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label className=" bold">Description</Label>
                    <Input
                      name="description"
                      type="textarea"
                      value={this.state.description}
                      className="form-control-sm"
                      onChange={this.onChange}
                      required={true}
                    />
                    {errors.description && (
                      <FormText className="error">
                        {errors.description}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup className="center">
                <button type="submit" className="btn white-text login-button">
                  Submit
                </button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
        {this.state.message ? <Notifier message={this.state.message} /> : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
      </div>
    );
  }
}

Landing.propTypes = {
  getEmployeeDetails: PropTypes.func.isRequired,
  employee: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  employee: state.employee,
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { getEmployeeDetails })(Landing);
