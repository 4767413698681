import React, { Component } from "react";
import { Container, Table } from "reactstrap";
import AddEmployeeClaim from "./AddEmployeeClaim";
import UpdateEmployeeClaim from "./UpdateEmployeeClaim";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import {
  getEmployeeClaim,
  deleteEmployeeClaim,
} from "../../../../../redux/actions/finance/claims/employeeClaimAction";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DateFns from "date-fns";

class EmployeeClaim extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addBtn: false,
      updateBtn: false,
      updateData: [],
    };

    this.toggleAddBtn = this.toggleAddBtn.bind(this);
    this.addBack = this.addBack.bind(this);
    this.toggleUpdateBtn = this.toggleUpdateBtn.bind(this);
    this.updateBack = this.updateBack.bind(this);
    this.getData = this.getData.bind(this);
    // this.deleteClaimStatus = this.deleteClaimStatus.bind(this);
  }

  /*
toggleAddBtn function to toggle the addition page
*/
  toggleAddBtn() {
    this.setState({
      addBtn: true,
    });
  }

  /*
addBack function to back to the list page from the add page
*/
  addBack() {
    this.setState({
      addBtn: false,
    });

    this.getData();
  }

  /*
toggleUpdateBtn function to toggle the update form
*/
  toggleUpdateBtn(item) {
    this.setState({
      updateBtn: true,
      updateData: item,
    });
  }

  /*
updateBack function to redirect to back main page
*/
  updateBack() {
    this.setState({
      updateBtn: false,
    });

    this.getData();
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    let data = {
      empId: localStorage.getItem("id"),
    };
    this.props.getEmployeeClaim(data);
  }

  //   deleteClaimStatus(id) {
  //     let data = {
  //       claimStatusId: id,
  //     };
  //     this.props.deleteClaimStatus(data);
  //   }

  render() {
    const { addBtn, updateBtn, updateData } = this.state;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-8 mx-auto">
            <Container className="card container-card">
              {!addBtn && !updateBtn ? (
                <React.Fragment>
                  <button
                    className="btn add-button white-text label-sm"
                    onClick={this.toggleAddBtn}
                  >
                    Add
                  </button>
                  <i
                    className="material-icons pointer cancel-button"
                    onClick={this.props.back}
                  >
                    cancel
                  </i>
                  <h5 className="bold center pt-2">Claims</h5>
                  <Table className="center">
                    <thead>
                      <tr>
                        <th className="opp-head ">ID</th>
                        <th className="opp-head ">Date</th>
                        <th className="opp-head ">Type</th>
                        <th className="opp-head ">Amount</th>
                        <th className="opp-head ">Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.employeeClaim.data &&
                        this.props.employeeClaim.data.map((item) => {
                          return (
                            <tr>
                              <td className="opp-tableData">
                                {item.empClaimId}
                              </td>
                              <td className="opp-tableData">
                                {DateFns.format(item.claimDate, "DD-MM-YYYY")}
                              </td>
                              <td className="opp-tableData">
                                {item.claimType}
                              </td>
                              <td className="opp-tableData">{item.amount}</td>
                              <td className="opp-tableData">
                                {item.claimStatus}
                              </td>
                              {item.claimStatus === "Referred Back" ? (
                                <td>
                                  <span
                                    className="material-icons pointer opp-action-edit"
                                    style={{ color: "blue" }}
                                    onClick={() => this.toggleUpdateBtn(item)}
                                  >
                                    edit
                                  </span>
                                </td>
                              ) : (
                                <td></td>
                              )}
                              {/* <td>
                          <i
                            className="material-icons pointer"
                            onClick={() =>
                              this.deleteClaimStatus(item.claimStatusId)
                            }
                          >
                            delete
                          </i>
                        </td>  */}
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </React.Fragment>
              ) : null}
              {addBtn && <AddEmployeeClaim back={this.addBack} />}
              {updateBtn && (
                <UpdateEmployeeClaim back={this.updateBack} data={updateData} />
              )}
              {/* {this.props.employeeClaim.message && (
          <Notifier message={this.props.employeeClaim.message} />
        )}
        {this.props.employeeClaim.error && (
          <ErrorNotifier message={this.props.employeeClaim.error} />
        )} */}
            </Container>
          </div>
        </div>
      </div>
    );
  }
}

EmployeeClaim.propTypes = {
  getEmployeeClaim: PropTypes.func.isRequired,
  deleteEmployeeClaim: PropTypes.func.isRequired,
  employeeClaim: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  employeeClaim: state.employeeClaim,
});

export default connect(mapStateToProps, {
  getEmployeeClaim,
  deleteEmployeeClaim,
})(EmployeeClaim);
