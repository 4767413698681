import axios from "axios";
import { CUSTOMER_DOCUMENTS_GET } from "../../types";
import { CURD_CUSTOMER_DOCUMENTS } from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const uploadCustomerDocuments = (data, back) => (dispatch) => {
  axios
    .post(CURD_CUSTOMER_DOCUMENTS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getCustomerDocuments = (data) => (dispatch) => {
  axios
    .get(`${CURD_CUSTOMER_DOCUMENTS}/${data}`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CUSTOMER_DOCUMENTS_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateCustomerDocuments = (data, back) => (dispatch) => {
  axios
    .put(CURD_CUSTOMER_DOCUMENTS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteCustomerDocuments = (data) => (dispatch) => {
  axios
    .delete(CURD_CUSTOMER_DOCUMENTS, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
