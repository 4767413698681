import React, { Component } from "react";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// components
import FormComponent from "../../../../../common/FormComponent";
import {
  Validator,
  submitValidator,
  refresh,
  valid,
  errors,
} from "../../../../../common/Validator";
import Notifier from "../../../../../aside/Notifier";
import ErrorNotifier from "../../../../../aside/ErrorNotifier";
// redux
import { getUserBusinessGeowise } from "../../../../../../redux/actions/hcm/business_setup/businessGeowiseAction";
import { getUserBusinessCountries } from "../../../../../../redux/actions/hcm/business_setup/businessCountriesAction";
import { updatePassportDetails } from "../../../../../../redux/actions/hcm/my_profile/identification_info/passportDetailsAction";

// --------------------------------------------------------------------------------------------------------------------------------
class UpdatePassportDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      geoName: this.props.data.length ? this.props.data[0].geoName : "",
      countryName: this.props.data.length ? this.props.data[0].countryName : "",
      passportNo: this.props.data.length
        ? this.props.data[0].passportNumber
        : "",
      issuePlace: this.props.data.length ? this.props.data[0].issuePlace : "",
      issueDate: this.props.data.length ? this.props.data[0].issueDate : "",
      expiryDate: this.props.data.length ? this.props.data[0].expiryDate : "",
      remarks: this.props.data.length ? this.props.data[0].remarks : "",
      dateError: "",
      message: "",
      error: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    if (e.target.name === "expiryDate") {
      if (Date.parse(e.target.value) < Date.parse(this.state.issueDate)) {
        this.setState({
          [e.target.name]: e.target.value,
          dateError: "EndDate must be greater than or equal to StartDate",
        });
      } else {
        this.setState({
          [e.target.name]: e.target.value,
          dateError: "",
        });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      geoName: this.state.geoName,
      countryName: this.state.countryName,
      passportNumber: this.state.passportNo,
      issuePlace: this.state.issuePlace,
      issueDate: this.state.issueDate,
      expiryDate: this.state.expiryDate,
      remarks: this.state.remarks,
    };
    if (valid) {
      this.props.updatePassportDetails(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    refresh();
    this.setState({
      message: "",
      error: "",
    });
    this.props.getUserBusinessGeowise();
    this.props.getUserBusinessCountries();
  }

  render() {
    const {
      geoName,
      countryName,
      passportNo,
      issuePlace,
      issueDate,
      expiryDate,
      remarks,
      dateError,
      message,
      error,
    } = this.state;

    return (
      <React.Fragment>
        <i
          className="material-icons cancel-button pointer"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <FormGroup>
            <Label className="bold label-sm">Geography</Label>
            <Input
              type="select"
              name="geoName"
              className="form-control-sm"
              value={this.state.geoName}
              onChange={this.onChange}
              placeholder="Geography"
              required={true}
            >
              <option value="" hidden>
                Select...
              </option>
              {this.props.businessGeowise.data.map((item) => {
                return (
                  <option key={item.id} value={item.geoName}>
                    {item.geoName}
                  </option>
                );
              })}
            </Input>
            {errors.geography && (
              <FormText className="error">{errors.geography}</FormText>
            )}
          </FormGroup>
          <FormGroup>
            <Label className="label-sm bold">Country Name</Label>
            <Input
              type="select"
              className="form-control-sm"
              name="countryName"
              value={countryName}
              onChange={this.onChange}
              required={true}
            >
              <option hidden value="">
                Select...
              </option>
              {this.props.businessCountries &&
                this.props.businessCountries.data.map((item) => {
                  if (this.state.geoName === item.geoName) {
                    return (
                      <option key={item.id} value={item.countryName}>
                        {item.countryName}
                      </option>
                    );
                  }
                })}
            </Input>
            {errors.countryName && (
              <FormText className="error">{errors.countryName}</FormText>
            )}
          </FormGroup>
          <FormComponent
            labelClass="bold label-sm"
            label="Passport No."
            type="text"
            name="passportNo"
            inputClass="form-control-sm"
            value={passportNo}
            change={this.onChange}
            required={true}
            maxlength="8"
          />
          <FormComponent
            labelClass="bold label-sm"
            label="Issue Place"
            type="text"
            name="issuePlace"
            value={issuePlace}
            change={this.onChange}
            required={true}
          />
          <FormComponent
            labelClass="bold label-sm"
            label="Issue Date"
            type="date"
            name="issueDate"
            value={issueDate}
            change={this.onChange}
            required={true}
          />
          <FormComponent
            labelClass="bold label-sm"
            label="Expiry Date"
            type="date"
            name="expiryDate"
            value={expiryDate}
            change={this.onChange}
            required={true}
            error={dateError}
            min={issueDate}
          />
          <FormComponent
            labelClass="bold label-sm"
            label="Remarks"
            type="textarea"
            name="remarks"
            value={remarks}
            change={this.onChange}
            required={true}
          />
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Update
            </button>
          </div>
        </Form>
        {this.props.passportDetails.message && (
          <Notifier message={this.props.passportDetails.message} />
        )}
        {this.props.passportDetails.error && (
          <ErrorNotifier message={this.props.passportDetails.error} />
        )}
      </React.Fragment>
    );
  }
}

UpdatePassportDetails.propTypes = {
  getUserBusinessGeowise: PropTypes.func.isRequired,
  businessGeowise: PropTypes.object.isRequired,
  getUserBusinessCountries: PropTypes.func.isRequired,
  businessCountries: PropTypes.object.isRequired,
  updatePassportDetails: PropTypes.func.isRequired,
  passportDetails: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  businessGeowise: state.businessGeowise,
  businessCountries: state.businessCountries,
  passportDetails: state.passportDetails,
});

export default connect(mapStateToProps, {
  getUserBusinessGeowise,
  getUserBusinessCountries,
  updatePassportDetails,
})(UpdatePassportDetails);
