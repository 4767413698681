import React, { Component } from "react";
import { Form, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent"
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import {updateyearsExpRs } from "../../../../../redux/actions/rs/configuration/yearsOfExpAction";
// update years of exp component
class UpdateExperienceRs extends Component {
  constructor(props) {
    super(props);
    const { id, expRange } = this.props.data;
    
    this.state = {
      userGroupId: id,
      expRangeRs:  expRange,
     
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
//  common onchange function
  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  // update function
  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      id: this.state.userGroupId,
      expRange: this.state.expRangeRs,
    
      updatedBy: localStorage.getItem("id"),
    };
    if (valid) {
      this.props.updateyearsExpRs(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    refresh();
    this.setState({
      error: "",
    });
  }

  render() {
    const { expRangeRs, description, error } = this.state;

    return (
      <React.Fragment>
        <h5 className="center card-heading pt-2">Update Years of Experience</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="6" className="mx-auto">
              <FormComponent
                labelClass="bold label-sm"
                label="Experience Range"
                type="text"
                name="expRangeRs"
                inputClass="form-control-sm"
                value={expRangeRs}
                change={this.onChange}
                required={true}
                length="30"
              />
           
            </Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Update
            </button>
          </div>
        </Form>
        {/* {error && <ErrorNotifier message={error} />}
        {this.props.userGroups.error && (
          <ErrorNotifier message={this.props.userGroups.error} />
        )}
        {this.props.userGroups.message && (
          <ErrorNotifier message={this.props.userGroups.message} />
        )} */}
      </React.Fragment>
    );
  }
}

UpdateExperienceRs.propTypes = {
  UpdateExperience: PropTypes.func.isRequired,
  userGroups: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  userGroups: state.userGroups,
});

export default connect(mapStateToProps, {updateyearsExpRs })( UpdateExperienceRs);
