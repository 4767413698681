import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import DateFns from "date-fns";
// material ui
import { Divider } from "@material-ui/core";
// components
import FlexComponent from "../candidate/tagrequirement.js/FlexComponent";
import DetailsPageFooter from "../requirements/DetailsPageViewFooter";
import ViewTaggedCandidate from "./taggedCandidateInfo/viewTaggedDetails"


// ----------------------------------------------------------------------------------

const CandidateDetails = ({ data, canId }) => {
  const[isOpen,setIsOpen] = useState(false)

  const handleOpenView = () =>{
  setIsOpen(!isOpen)
  }

  const handleOpenTaggedView = ()=>{
    setIsOpen(false)
  }
  
  
  const extractSkill = (data) => {
    return (
      data.length > 0 &&
      data.map((innerArr) => {
        if (innerArr.skill.split(",").length > 1) {
          return innerArr.skill.split(",").map((dataArr) => {
            return <span>{`${dataArr},`} &nbsp;</span>;
          });
        } else {
          return innerArr.skill.split(",").map((dataArr) => {
            return <span>{`${dataArr}`}</span>;
          });
        }
      })
    );
  };

  return (
    <>
     <div className="topnav">
     <a onClick={handleOpenTaggedView} className={!isOpen? "dark-theme-manview":"light-theme-manview"}  >Details</a>
     <a onClick={handleOpenView} className={isOpen? "dark-theme-manview":"light-theme-manview"} style={{marginLeft:"1rem"}}  
      > Requirements</a>
     </div>
     {!isOpen ? <div>
      {data && (
        <>
          <Row  style={{ width: "100%",marginTop:"1rem" }}>
            <Col md="6">
              <ul className="CustDetails-ul" style={{ marginBottom: "0" }}>
                <li>
                  <FlexComponent
                    label="Name"
                    data={`${data.firstName} ${data.lastName} `}
                  />
                </li>
                <li>
                  <FlexComponent label="Email" data={data.eMailId} />
                </li>
                <li>
                  <FlexComponent
                    label="Mobile No."
                    data={`${data.dialingCode}${data.mobileNo}`}
                  />
                </li>
                <li>
                  <FlexComponent
                    label="Alternate Contact No."
                    data={`${data.altDialingCode}${data.altContactNo}`}
                  />
                </li>
                <li>
                  <FlexComponent
                    label="DOB"
                    data={
                      data.dob ? DateFns.format(data.dob, "DD-MM-YYYY") : ""
                    }
                  />
                </li>
                <li>
                  <FlexComponent label="Gender" data={data.gender} />
                </li>
                <li>
                  <FlexComponent label="Country" data={data.country} />
                </li>
                <li>
                  <FlexComponent label="Location" data={data.location} />
                </li>

                <li>
                  <FlexComponent label="Years Of Exp" data={data.yearOfExp} />
                </li>
                <li>
                  <FlexComponent
                    label="Current Employer"
                    data={
                      data.canExpectation
                        ? data.canExpectation.currentEmployeer
                        : ""
                    }
                  />
                </li>
           

             
              </ul>
            </Col>
            <Col md="6">
              <ul
                style={{ marginBottom: "0"}}
                className="CustDetails-ul"
               
              >
                <li>
                  <FlexComponent
                    label="Qualification"
                    data={data.canEducation ? data.canEducation.candQual : ""}
                  />
                </li>
                <li>
                  <FlexComponent
                    label="Year of Passing"
                    data={
                      data.canEducation ? data.canEducation.yearOfPassing : ""
                    }
                  />
                </li>
                <li>
                  <FlexComponent
                    label="Relevant Yrs of Exp."
                    data={
                      data.canExpSummary ? data.canExpSummary.relYearOfExp : ""
                    }
                  />
                </li>
               
                <li>
                  <FlexComponent
                    label="Current CTC"
                    data={
                      data.canExpectation ? data.canExpectation.currentCtc : ""
                    }
                  />
                </li>

                <li>
                  <FlexComponent
                    label="Expected CTC"
                    data={
                      data.canExpectation
                        ? data.canExpectation.exepectedCtc
                        : ""
                    }
                  />
                </li>

                <li>
                  <FlexComponent
                    label="Notice Period"
                    data={
                      data.canExpectation
                        ? data.canExpectation.noticePeriod
                        : ""
                    }
                  />
                </li>

                <li>
                  <FlexComponent
                    label="Preferred Location"
                    data={
                      data.canExpectation
                        ? data.canExpectation.preferredWorkLoction
                        : ""
                    }
                  />
                </li>

                <li>
                  <FlexComponent label="Govt Id Name" data={data.govIdName} />
                </li>

                <li>
                  <FlexComponent label="Govt Id No." data={data.govIdNo} />
                </li>
               
              </ul>
            </Col>
          </Row>
          <Row>
          <Col md="12">
              <ul className="CustDetails-ul">
                <li>
                  <div style={{ display: "flex", marginTop: "0.5rem" }}>
                    <div
                      className="label-sm bold rs-req-span-label-col-1 pt-1"
                      style={{ width: "19.7%" }}
                    >
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        Primary Skills
                      </span>
                    </div>
                    <div style={{ width: "1%", textAlign: "end" }}>
                      <span className="label-sm">:</span>
                    </div>
                    <div style={{ width: "78%", paddingLeft: "1%" }}>
                      <span style={{fontSize:".75rem ",fontWeight:"400"}}>
                      {data.canPrimarySkills.length > 0 &&
                          extractSkill(data.canPrimarySkills)}
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </Col>
            <Col md="12">
              <ul className="CustDetails-ul">
                <li>
                  <div style={{ display: "flex"}}>
                    <div
                      className="label-sm bold rs-req-span-label-col-1 pt-1"
                      style={{ width: "19.7%" }}
                    >
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        Secondary Skills
                      </span>
                    </div>
                    <div style={{ width: "1%", textAlign: "end" }}>
                      <span className="label-sm">:</span>
                    </div>
                    <div style={{ width: "78%", paddingLeft: "1%" }}>
                      <span style={{fontSize:".75rem ",fontWeight:"400"}}>
                      {data.canSecondarySkills.length > 0 &&
                          extractSkill(data.canSecondarySkills)}
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </Col>
            <Col md="12">
              <ul className="CustDetails-ul">
                <li>
                  <div style={{ display: "flex" }}>
                    <div
                      className="label-sm bold rs-req-span-label-col-1 pt-1"
                      style={{ width: "19.7%" }}
                    >
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        Experience Summary
                      </span>
                    </div>
                    <div style={{ width: "1%", textAlign: "end" }}>
                      <span className="label-sm">:</span>
                    </div>
                    <div style={{ width: "78%", paddingLeft: "1%" }}>
                      <span style={{fontSize:".75rem ",fontWeight:"400"}}>
                        {data.canExpSummary
                          ? data.canExpSummary.experienceSumm
                          : ""}
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </Col>
            <Col md="12">
              <ul className="CustDetails-ul">
                <li>
                  <div style={{ display: "flex" }}>
                    <div
                      className="label-sm bold rs-req-span-label-col-1 pt-1"
                      style={{ width: "19.7%" }}
                    >
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                      Notes
                      </span>
                    </div>
                    <div style={{ width: "1%", textAlign: "end" }}>
                      <span className="label-sm">:</span>
                    </div>
                    <div style={{ width: "78%", paddingLeft: "1%" }}>
                      <span style={{fontSize:".75rem ",fontWeight:"400"}}>
                      {data.canExpectation ? data.canExpectation.notes : ""}
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
          <Divider />
          <DetailsPageFooter
            createdBy={data.createdBy}
            createdOn={data.createdOn}
            updatedBy={data.updatedBy}
            updatedOn={data.updatedOn}
          />
        </>
      )}
      </div>:<ViewTaggedCandidate
              reqType={canId}
             />}
    </>
  );
};

export default CandidateDetails;
