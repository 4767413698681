import axios from "axios";
import { GET_GRADE_TYPE_LIST } from "../../types";
import {
  ADD_CANDIDATE_GRADE,
  GET_CANDIDATE_GRADE,
  UPDATE_CANDIDATE_GRADE,
  DELETE_CANDIDATE_GRADE
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addGrade = (data, back) => (dispatch) => {
  axios
    .post( ADD_CANDIDATE_GRADE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getGrade));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getGrade = () => (dispatch) => {
  axios
    .get(  GET_CANDIDATE_GRADE, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch( GET_GRADE_TYPE_LIST, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateGrade = (data, back) => (dispatch) => {
  axios
    .put(  UPDATE_CANDIDATE_GRADE , data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteGrade = (data) => (dispatch) => {
  axios
    .delete( DELETE_CANDIDATE_GRADE, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getGrade));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
