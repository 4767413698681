import React, { useRef, useState } from 'react'
import IdleTimer from 'react-idle-timer'
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button
} from 'reactstrap';

function IdleTimeOut() {
    let idleTimerRef = useRef(null)
    let sessionTimeoutRef = useRef(null)
    const [modalIsOpen, setmodalIsOpen] = useState(false)

    const onIdle = () => {
        setmodalIsOpen(true)
        sessionTimeoutRef.current = setTimeout(logout, (10 * 60000))
    }

    const stayActive = () => {
        setmodalIsOpen(false)
        clearTimeout(sessionTimeoutRef.current)
    }

    const logout = () => {
        setmodalIsOpen(false)
        localStorage.clear()
        sessionStorage.clear()
        window.close()
        clearTimeout(sessionTimeoutRef.current)
    }

    return(
        <div>
            <IdleTimer ref={ idleTimerRef } timeout={ 20 * 60000 } onIdle={ onIdle } />
            <Modal isOpen={modalIsOpen}>
                <ModalHeader>You Have Been Idle!</ModalHeader>
                <ModalBody>
                    You Will Get Timed Out. You want to stay?
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={logout}>Logout</Button>
                    <Button color="primary" onClick={stayActive}>Stay</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default IdleTimeOut