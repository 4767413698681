import { GET_ERRORS, SET_MESSAGE } from "../../actions/types";

const initialState = {
  message: null,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case GET_ERRORS:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}






