import axios from "axios";
import { GET_CANDIDATE_TYPEGEO } from "../../types";
import {
ADD_CANDIDATE_GEO,
GET_CANDIDATE_GEO,
UPDATE_CANDIDATE_GEO,
DELETE_CANDIDATE_GEO
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addCandiGeo = (data, back) => (dispatch) => {
  axios
    .post(ADD_CANDIDATE_GEO, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getCandiGeo));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getCandiGeo = () => (dispatch) => {
  axios
    .get(GET_CANDIDATE_GEO, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(GET_CANDIDATE_TYPEGEO, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateCandiGeo = (data, back) => (dispatch) => {
  axios
    .put( UPDATE_CANDIDATE_GEO , data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteCandiGeo = (data) => (dispatch) => {
  axios
    .delete(DELETE_CANDIDATE_GEO, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getCandiGeo));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
