import axios from "axios";
import { REQUEST_SEVERITY_GET } from "../../types";
import {
  CURD_REQUEST_SEVERITY,
  USER_REQUEST_SEVERITY,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addRequestSeverity = (data, back) => (dispatch) => {
  axios
    .post(CURD_REQUEST_SEVERITY, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getRequestSeverity));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getRequestSeverity = () => (dispatch) => {
  axios
    .get(CURD_REQUEST_SEVERITY, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(REQUEST_SEVERITY_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserRequestSeverity = () => (dispatch) => {
  axios
    .get(USER_REQUEST_SEVERITY, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(REQUEST_SEVERITY_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateRequestSeverity = (data, back) => (dispatch) => {
  axios
    .put(CURD_REQUEST_SEVERITY, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteRequestSeverity = (data) => (dispatch) => {
  axios
    .delete(CURD_REQUEST_SEVERITY, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getRequestSeverity));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
