import axios from "axios";
import { REQUEST_CONVERSATION_GET, REQUEST_CONVERSATION_FETCH } from "../types";
import { CURD_REQUEST_CONVERSATION } from "../../../utils/routes";
import setSuccessMessage from "../common/setSuccessMessage";
import setErrorMessage from "../common/setErrorMessage";
import getDispatch from "../common/getDispatch";

// -------------------------------------------------------------------------------------------

export const addRequestConversation = (data, back, requestId) => (dispatch) => {
  axios
    .post(CURD_REQUEST_CONVERSATION, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
      dispatch(fetchRequestConversation(requestId));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getRequestConversation = () => (dispatch) => {
  axios
    .get(CURD_REQUEST_CONVERSATION, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(REQUEST_CONVERSATION_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const fetchRequestConversation = (data) => (dispatch) => {
  axios
    .get(`${CURD_REQUEST_CONVERSATION}/${data}`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(REQUEST_CONVERSATION_FETCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateRequestConversation = (data, back) => (dispatch) => {
  axios
    .put(CURD_REQUEST_CONVERSATION, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteRequestConversation = (data) => (dispatch) => {
  axios
    .delete(CURD_REQUEST_CONVERSATION, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getAccounts));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
