import axios from "axios";
import { APPLICATION_GET } from "../../../types";
import {
  GET_APPLICATION,
  ADD_APPLICATION,
  UPDATE_APPLICATION,
  DELETE_APPLICATION,
} from "../../../../../utils/routes";
import setSuccessMessage from "../../../common/setSuccessMessage";
import setErrorMessage from "../../../common/setErrorMessage";
import getDispatch from "../../../common/getDispatch";
import { getModule } from "./moduleAction";
import { getService } from "./serviceAction";

export const addApplication = (data, back) => (dispatch) => {
  axios
    .post(ADD_APPLICATION, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getApplication = () => (dispatch) => {
  axios
    .get(GET_APPLICATION, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(APPLICATION_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateApplication = (data, back) => (dispatch) => {
  axios
    .put(UPDATE_APPLICATION, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteApplication = (data) => (dispatch) => {
  axios
    .delete(DELETE_APPLICATION, { data, withCredentials: true })
    .then((res) => {
      dispatch(
        setSuccessMessage(
          res,
          null,
          getApplication,
          null,
          null,
          getModule,
          getService
        )
      );
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
