import React, { Component } from "react";
import { Form, Row, Col, FormGroup, Label, Input, FormText } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// components
import {
  Validator,
  submitValidator,
  valid,
  PercentageValidation,
  refresh,
  errors,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
// redux
import { updateEmpGrade } from "../../../../../redux/actions/hcm/configurations/empGradeAction";
import { getUserEmpPosition } from "../../../../../redux/actions/hcm/configurations/empPositionAction";

// -------------------------------------------------------------------------------------------------------
class UpdateEmployeeGrade extends Component {
  constructor(props) {
    super(props);
    const {
      id,
      emppositionId,
      grade,
      experienceFrom,
      experienceTo,
      description,
    } = this.props.data;
    this.state = {
      gradeId: id,
      positionId: emppositionId,
      grade: grade,
      experienceFrom: experienceFrom,
      experienceTo: experienceTo,
      description: description,
      message: "",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    PercentageValidation(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      id: this.state.gradeId,
      emppositionId: Number(this.state.positionId),
      grade: this.state.grade,
      experienceFrom: Number(this.state.experienceFrom),
      experienceTo: Number(this.state.experienceTo),
      description: this.state.description,
    };
    if (valid) {
      this.props.updateEmpGrade(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    refresh();
    this.setState({
      message: "",
      error: "",
    });
    this.props.getUserEmpPosition();
  }

  render() {
    const {
      positionId,
      grade,
      experienceFrom,
      experienceTo,
      description,
      error,
      message,
    } = this.state;
    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Update Employee Grade</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="6" className="mx-auto">
              <FormGroup>
                <Label className="bold label-sm">Position</Label>
                <Input
                  type="select"
                  name="positionId"
                  value={positionId}
                  className="form-control-sm"
                  onChange={this.onChange}
                  required={true}
                >
                  <option value=" " hidden>
                    Select...
                  </option>
                  {this.props.empPosition.data.length > 0 &&
                    this.props.empPosition.data.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.id}-{item.positionName}
                      </option>
                    ))}
                </Input>
                {errors.positionId && (
                  <FormText className="error">{errors.positionId}</FormText>
                )}
              </FormGroup>
              <FormComponent
                labelClass="bold label-sm"
                label="Grade"
                type="text"
                name="grade"
                inputClass="form-control-sm"
                value={grade}
                change={this.onChange}
                required={true}
              />
              <Row>
                <Col md="6" style={{ paddingLeft: "0px" }}>
                  <FormComponent
                    labelClass="bold label-sm"
                    label="Experience From (yrs)"
                    type="number"
                    name="experienceFrom"
                    inputClass="form-control-sm"
                    value={experienceFrom}
                    change={this.onChange}
                    required={true}
                  />
                </Col>
                <Col md="6" style={{ paddingRight: "0px" }}>
                  <FormComponent
                    labelClass="bold label-sm"
                    label="Experience To (yrs)"
                    type="number"
                    name="experienceTo"
                    inputClass="form-control-sm"
                    value={experienceTo}
                    change={this.onChange}
                    required={true}
                  />
                </Col>
              </Row>
              <FormComponent
                labelClass="bold label-sm"
                label="Description"
                type="textarea"
                name="description"
                inputClass="form-control-sm"
                value={description}
                change={this.onChange}
              />
            </Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Update
            </button>
          </div>
        </Form>
        {this.props.empGrade.message && (
          <Notifier message={this.props.empGrade.message} />
        )}
        {this.props.empGrade.error && (
          <ErrorNotifier message={this.props.empGrade.error} />
        )}
      </React.Fragment>
    );
  }
}

UpdateEmployeeGrade.propTypes = {
  updateEmpGrade: PropTypes.func.isRequired,
  empGrade: PropTypes.object.isRequired,
  getUserEmpPosition: PropTypes.func.isRequired,
  empPosition: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  empGrade: state.empGrade,
  empPosition: state.empPosition,
});

export default connect(mapStateToProps, { updateEmpGrade, getUserEmpPosition })(
  UpdateEmployeeGrade
);
