import axios from "axios";
import { CONTACTS_ADDRESS_FETCH, CONTACTS_ADDRESS_GET } from "../../../types";
import {
  CURD_CONTACTS_ADDRESS,
  FETCH_CONTACTS_ADDRESS,
} from "../../../../../utils/routes";
import setSuccessMessage from "../../../common/setSuccessMessage";
import setErrorMessage from "../../../common/setErrorMessage";
import getDispatch from "../../../common/getDispatch";

export const addContactsAddress = (data, back) => (dispatch) => {
  axios
    .post(CURD_CONTACTS_ADDRESS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getDocuments));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getContactsAddress = () => (dispatch) => {
  axios
    .get(CURD_CONTACTS_ADDRESS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CONTACTS_ADDRESS_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const fetchContactsAddress = () => (dispatch) => {
  axios
    .get(FETCH_CONTACTS_ADDRESS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CONTACTS_ADDRESS_FETCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateContactsAddress = (data, back) => (dispatch) => {
  axios
    .post(CURD_CONTACTS_ADDRESS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteContactsAddress = (data) => (dispatch) => {
  axios
    .delete(CURD_CONTACTS_ADDRESS, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getContactsAddress));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
