import axios from "axios";
import {  GET_HR_LEAD_TYPE,GET_LEADHR_MAIN  } from "../../types";
import {
  ADD_HR_LEAD_RS,
  GET_HR_LEAD_RS,
  UPDATE_HR_LEAD_RS,
  DELETE_HR_LEAD_RS
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addHrLead = (data, back) => (dispatch) => {
  axios
    .post (ADD_HR_LEAD_RS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getHrLead));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getHrLead = () => (dispatch) => {
  axios
    .get(GET_HR_LEAD_RS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch( GET_HR_LEAD_TYPE, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
export const getHrLeadRs = () => (dispatch) => {
  axios
    .get(`${GET_HR_LEAD_RS}/select`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(GET_LEADHR_MAIN , res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateHrLead = (data, back) => (dispatch) => {
  axios
    .put(UPDATE_HR_LEAD_RS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteHrLead = (data) => (dispatch) => {
  axios
    .delete(DELETE_HR_LEAD_RS, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null,getHrLead));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
