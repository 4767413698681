import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

class DropdownComponent extends React.Component {
  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
    this.toggle = this.toggle.bind(this);

    /*
For displaying the dropdown value we getting the role Id from the redux store the we displaying in dropdown as selected role Id
*/
    this.state = {
      dropdownOpen: false,
      permissionlist: this.props.auth.permissionlist || {},
      arrayAllRoles: this.props.auth.permissionlist || [],
    };
  }

  // write a set permission function
  changeValue(e) {
    let DropDownValue = e.target.innerText;
    let selectedRole = DropDownValue;
    let data = {
      roleId: selectedRole,
    };
    this.setState({
      display: selectedRole,
    });
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  render() {
    const { arrayAllRoles } = this.state;
    let arrayAllRoles1 = [];
    for (let i = 0; i < arrayAllRoles.length; i++) {
      arrayAllRoles1.push(arrayAllRoles[i].roleId);
    }

    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle caret>
          {Object.keys(this.props.auth.data).length > 0 &&
            this.props.auth.data.roleMapping.pieUserRole.roleCode}
        </DropdownToggle>
        <DropdownMenu
          modifiers={{
            setMaxHeight: {
              enabled: true,
              order: 890,
              fn: (data) => {
                return {
                  ...data,
                  styles: {
                    ...data.styles,
                    overflow: "auto",
                    maxHeight: 100,
                  },
                };
              },
            },
          }}
        >
          {/* {arrayAllRoles1.map((item) => ( */}
          <DropdownItem>
            <div onClick={this.changeValue}>
              {" "}
              {this.props.auth.data.length > 0 &&
                this.props.auth.data.roleMapping.pieUserRole.roleCode}
            </div>
          </DropdownItem>
          {/* ))} */}
        </DropdownMenu>
      </Dropdown>
    );
  }
}

DropdownComponent.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  auth: state.auth,
});
export default connect(mapStateToProps, {})(DropdownComponent);
