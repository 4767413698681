import axios from "axios";
import { BUSINESS_GEOWISE_GET } from "../../types";
import {
  CURD_BUSINESS_GEOWISE,
  USER_BUSINESS_GEOWISE,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addBusinessGeowise = (data, back) => (dispatch) => {
  axios
    .post(CURD_BUSINESS_GEOWISE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getBusinessGeowise));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getBusinessGeowise = () => (dispatch) => {
  axios
    .get(CURD_BUSINESS_GEOWISE, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(BUSINESS_GEOWISE_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserBusinessGeowise = () => (dispatch) => {
  axios
    .get(USER_BUSINESS_GEOWISE, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(BUSINESS_GEOWISE_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateBusinessGeowise = (data, back) => (dispatch) => {
  axios
    .put(CURD_BUSINESS_GEOWISE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getBusinessGeowise));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteBusinessGeowise = (data) => (dispatch) => {
  axios
    .delete(CURD_BUSINESS_GEOWISE, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getBusinessGeowise));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
