import {
  REQUESTS_GET,
  CLOSED_REQUESTS_GET,
  REQUESTS_FETCH,
  REQUESTS_SEARCH,
  VIEW_REQUESTS_SEARCH,
  GET_ERRORS,
  SET_MESSAGE,
} from "../../actions/types";

const initialState = {
  data: [],
  closedData: [],
  fetchData: [],
  viewData: [],
  message: null,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUESTS_GET:
      return {
        ...state,
        data: action.payload,
      };
    case CLOSED_REQUESTS_GET:
      return {
        ...state,
        closedData: action.payload,
      };
    case REQUESTS_SEARCH:
      return {
        ...state,
        data: action.payload,
      };
    case VIEW_REQUESTS_SEARCH:
      return {
        ...state,
        viewData: action.payload,
      };
    case REQUESTS_FETCH:
      return {
        ...state,
        fetchData: action.payload,
      };
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case GET_ERRORS:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
