import React, { Component } from "react";
import { Container, Table } from "reactstrap";
import AddDocuments from "./AddDocuments";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {Link} from 'react-router-dom'
// components
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import ConfirmDialog from "../../../../common/confirm-dialog/ConfirmDialog";
import ListContainer from '../../../../common/layout/ListContainer'
import {AiFillEye} from 'react-icons/ai'
import {AiOutlineDownload} from 'react-icons/ai'
import { RS_API } from "../../../../../utils/config";
// redux
import {
  getDocuments,
  deleteDocuments,
} from "../../../../../redux/actions/rs/upload/documentsAction";

// ------------------------------------------------------------------------
let isValid = false
class Documents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addBtn: false,
      updateBtn: false,
      data: [],
      updateData: [],
      confirmDialog: {
        isOpen: false,
        title: "",
        subTitle: "",
      },
      isToggle:false,
      uploadOpen:null,
      error: "",
      message: "",
      isCheckingExt :""
    };

    this.toggleAddBtn = this.toggleAddBtn.bind(this);
    this.addBack = this.addBack.bind(this);
    this.toggleUpdateBtn = this.toggleUpdateBtn.bind(this);
    this.updateBack = this.updateBack.bind(this);
    this.getData = this.getData.bind(this);
    this.deleteDocuments = this.deleteDocuments.bind(this);
    this.onOpenDocument = this.onOpenDocument.bind(this)
  }

  /*
toggleAddBtn function to toggle the addition page
*/
  toggleAddBtn() {
    this.setState({
      addBtn: true,
    });
  }

  /*
addBack function to back to the list page from the add page
*/
  addBack() {
    this.setState({
      addBtn: false,
    });

    this.getData();
  }

  /*
toggleUpdateBtn function to toggle the update form
*/
  toggleUpdateBtn(item) {
    this.setState({
      updateBtn: true,
      updateData: item,
    });
  }

  /*
updateBack function to redirect to back main page
*/
  updateBack() {
    this.setState({
      updateBtn: false,
    });

    this.getData();
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.props.getDocuments();
  }
  onOpenDocument(id,data) {
    console.log(data)
  this.setState({
      uploadOpen: id,
      isToggle: !this.state.isToggle,
      isCheckingExt:data
      })
  
   
    }


  deleteDocuments(id) {
    let data = {
      id: id,
    };

   
    this.props.deleteDocuments(data);
    this.setState((prevState) => ({
      ...prevState.confirmDialog,
      confirmDialog: {
        isOpen: false,
      },
      uploadOpen:false
    }));
  }

  setConfirmDialog = (obj) => {
    this.setState((prevProps) => ({
      confirmDialog: {
        ...prevProps.confirmDialog,
        isOpen: obj.isOpen,
      },
    }));
  };

  render() {
    console.log(this.props.templateUplaod)
    const { addBtn, data, updateBtn, message, error } = this.state;

    return (
      <ListContainer sidebar={this.props.sidebar ? "scale" : "no-scale"}>
        {!addBtn && !updateBtn ? (
          <React.Fragment>
            <button
              className="btn add-button white-text label-sm"
              onClick={this.toggleAddBtn}
            >
              Add
            </button>
            <h5 className="bold center pt-2">View Template</h5>
            <Link
              to={{
                pathname: "/pie/iam/service_menu",
                moduleName: this.props.location.moduleName,
                data: this.props.location.data,
              }}
            >
              <i
                className="material-icons cancel-button pointer"
                onClick={this.props.back}
              >
                cancel
              </i>
            </Link>

            {this.props.templateUplaod.data.length > 0 &&
              this.props.templateUplaod.data.map((item) => (
                <div
                  //  key={detail.id}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingLeft: "2rem",
                    
                      width: "100%",
                      // marginTop: "20px",
                      borderRadius: "15px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",

                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: "1rem",
                          width: "30%",
                          margin: "auto",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop:"1.5rem"
                        }}
                      >
                        <span style={{}}> {item.templateName}</span>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            // marginRight:"2rem"
                          }}
                        >
                          {
                           item.location.split(".")[1] === "xlsx" ?
                        <h3
                           style={{ cursor: "pointer" }}
                           onClick={()=>this.onOpenDocument(item.id, item.location.split(".")[1])}
                         >
                           {" "}
                           <AiOutlineDownload />{" "}
                         </h3>:    
                         <h3
                           style={{ cursor: "pointer" }}
                           onClick={()=>this.onOpenDocument(item.id)}
                         >
                           {" "}
                           <AiFillEye />{" "}
                         </h3>

                          }
                        

                          <i
                            style={{ color: "red", marginLeft: "1rem" }}
                            className="material-icons pointer"
                            onClick={() =>
                              this.setState({
                                confirmDialog: {
                                  isOpen: true,
                                  title: "Are you sure to delete this record?",
                                  subTitle: "You can't undo this operation",
                                  onConfirm: () =>
                                    this.deleteDocuments(item.id),
                                },
                              })
                            }
                          >
                            delete
                          </i>
                        </div>
                      </div>
                      {
                        this.state.isCheckingExt == "xlsx"?this.state.uploadOpen === item.id && this.state.isToggle && (
                          <iframe
                            src={`${RS_API}/documents/${item.location}`}
                            width="1px"
                            height="1px"
                          ></iframe>
                        ):  
                        
                        this.state.uploadOpen === item.id && this.state.isToggle && (
                          <iframe
                            src={`${RS_API}/documents/${item.location}`}
                            width="90%"
                            height="600rem"
                          ></iframe>
                        )

                      }

                   
                     
                    </div>
                  </div>
                </div>
              ))}
              <div>
                
              </div>
           
          </React.Fragment>
        ) : null}
        {addBtn && <AddDocuments back={this.addBack} />}
        {/* {updateBtn && <UpdateDocuments back={this.updateBack} data={updateData} />} */}
        {this.props.templateUplaod.message && <Notifier message={this.props.templateUplaod.message} />}
        {this.props.templateUplaod.error && <ErrorNotifier message={this.props.templateUplaod.error} />}
        <ConfirmDialog
          confirmDialog={this.state.confirmDialog}
          setConfirmDialog={this.setConfirmDialog}
        />
      </ListContainer>
    );
  }
}

Documents.propTypes = {
  getDocuments: PropTypes.func.isRequired,
  deleteDocuments: PropTypes.func.isRequired,
  documents: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  templateUplaod: state.templateUplaod,
});
export default connect(mapStateToProps, { getDocuments, deleteDocuments })(
  Documents
);
