import React, { Component } from "react";
import { Container, Card, Row, Col } from "reactstrap";
import UploadForm16 from "./form16_upload/UploadForm16";
import MyForm16 from "./my_form16/MyForm16";

class Form16Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      edit: "",
    };
    this.back = this.back.bind(this);
    this.chooseForm = this.chooseForm.bind(this);
  }

  back() {
    this.setState({
      edit: "",
    });
  }

  chooseForm() {
    let component = this.state.edit;
    if (component === "uploadForm16") {
      return <UploadForm16 back={this.back} />;
    }
    if (component === "myForm16") {
      return <MyForm16 back={this.back} />;
    }
  }

  render() {
    let shrink;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");
    return (
      <div className={shrink}>
        {!this.state.edit ? (
          <div className="container-fluid">
            <div className="row">
              <div className="col-10 mx-auto">
                <h4 className="bold center pt-2 pb-3">Form 16 </h4>
                <Row className="content-center">
                  <Col md="4">
                    <Card
                      className="center profile-edit-card"
                      onClick={() => this.setState({ edit: "uploadForm16" })}
                    >
                      <h6 className="bold">Upload Form 16</h6>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card
                      className="center profile-edit-card"
                      onClick={() => this.setState({ edit: "myForm16" })}
                    >
                      <h6 className="bold">My Form 16</h6>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        ) : (
          this.chooseForm()
        )}
      </div>
    );
  }
}
export default Form16Dashboard;
