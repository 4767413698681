import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  FormText,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import DateFns from "date-fns";
// material-ui
import { IconButton } from "@material-ui/core";
// icons
import EditIcon from "@material-ui/icons/Edit";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";

// components
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../common/Validator";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import FormComponent from "../../../common/FormComponent";
import CustomizedDialogs from "../../../common/customized-dialog/CustomizedDialogs";
import AddTeamAssignment from "./team_assignment/AddTeamAssignment";
import RequestConversation from "./request_conversation/RequestConversation";
import TeamAssignmentCard from "./team_assignment/TeamAssignmentCard";
// redux
import { updateRequests } from "../../../../redux/actions/experience_360/requestsAction";
import { getUserRequestType } from "../../../../redux/actions/experience_360/e360_config/requestTypeAction";
import { getUserRequestSeverity } from "../../../../redux/actions/experience_360/e360_config/requestSeverityAction";
import { getUserRequestPriority } from "../../../../redux/actions/experience_360/e360_config/requestPriorityAction";
import { getUserRequestStatus } from "../../../../redux/actions/experience_360/e360_config/requestStatusAction";
import { getUserRequestResolution } from "../../../../redux/actions/experience_360/e360_config/requestResolutionAction";
import { getUserProjectTeam } from "../../../../redux/actions/experience_360/e360_config/projectTeamAction";
import { getUserReleaseTag } from "../../../../redux/actions/experience_360/e360_config/releaseTagAction";
import { getE360ReleaseTag } from "../../../../redux/actions/experience_360/releaseTagAction";
import { fetchTeamAssignment } from "../../../../redux/actions/experience_360/teamAssignmentAction";
import { getUserProductList } from "../../../../redux/actions/experience_360/e360_config/productListAction";
import { E360_API } from "../../../../utils/config";
import ResumeViewer from "../../../common/ResumeViewer";

// -----------------------------------------------------------------------------------------

class RequestsDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestType: "",
      title: "",
      productName: "",
      feature: "",
      dialingCode: "",
      contactNumber: "",
      detailedDescription: "",
      comments: "",
      severity: "High",
      priority: "High",
      status: "",
      resolution: "",
      releaseName: "",
      edit: false,
      open: false,
      requestOpen: false,
      viewTeamAssignment: false,
      view: false,
      message: "",
      error: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    e.preventDefault();
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  /*
  This function for updating the customer details
  */
  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      id: this.props.feedback.id.toString(),
      reqType: this.state.requestType,
      reqTitle: this.state.title,
      productName: this.state.productName,
      feature: this.state.feature,
      dialingCode: this.state.dialingCode,
      contactNumber: this.state.contactNumber,
      detailedDescription: this.state.detailedDescription,
      comments: this.state.comments,
      severity: this.state.severity,
      priority: this.state.priority,
      resolution: this.state.resolution,
      status: this.state.status,
      releaseName: this.state.releaseName.split(",")[0],
      releaseVersion: this.state.releaseName.split(",")[1],
    };
    if (valid) {
      this.props.updateRequests(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
  ComponentDidMount getting the selected customer details and setting the state of customer details
  */
  componentDidMount() {
    refresh();
    const {
      reqType,
      reqTitle,
      productName,
      feature,
      dialingCode,
      contactNumber,
      detailedDescription,
      comments,
      severity,
      priority,
      status,
      resolution,
      releaseName,
      releaseVersion,
    } = this.props.feedback;
    this.setState({
      requestType: reqType,
      title: reqTitle,
      productName: productName,
      feature: feature,
      dialingCode: dialingCode,
      contactNumber: contactNumber,
      detailedDescription: detailedDescription,
      comments: comments,
      severity: severity,
      priority: priority,
      status: status,
      resolution: resolution,
      releaseName: `${releaseName},${releaseVersion}`,
    });
    this.props.getUserRequestType();
    this.props.getUserRequestSeverity();
    this.props.getUserRequestPriority();
    this.props.getUserRequestStatus();
    this.props.getUserRequestResolution();
    this.props.getUserProjectTeam();
    this.props.getUserReleaseTag();
    this.props.getUserProductList();
  }

  // modal functions
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  // request assignment modal functions
  handleClickRequestOpen = () => {
    this.setState({
      requestOpen: true,
    });
  };

  handleRequestClose = () => {
    this.setState({
      requestOpen: false,
    });
  };

  // modal function for view tagged contacts
  handleClickView = () => {
    this.setState({
      viewTeamAssignment: true,
    });
    this.props.fetchTeamAssignment(Number(this.props.feedback.id));
  };

  // modal function for close view team assignment
  handleCloseView = () => {
    this.setState({
      viewTeamAssignment: false,
    });
  };

  // openView function to open the document
  openView = () => {
    this.setState({
      view: true,
    });
  };

  // toggleView function to toggle the document view
  toggleView = () => {
    this.setState({
      view: !this.state.view,
    });
  };

  render() {
    return (
      <Row>
        {!this.state.edit ? (
          <Col md="12">
            <h5 className="bold center mb-4">
              Request : {Number(this.props.feedback.id)}
            </h5>
            <i
              onClick={() => this.props.back()}
              className="material-icons pointer cancel-button"
            >
              cancel
            </i>
            {typeof this.props.permission !== "undefined" &&
              Object.keys(this.props.permission).length > 0 &&
              this.props.permission.updation && (
                <IconButton
                  className="edit-button"
                  color="primary"
                  onClick={() =>
                    this.setState({ edit: !this.state.edit, comments: "" })
                  }
                  title="Edit Request"
                >
                  <EditIcon />
                </IconButton>
              )}
            <IconButton
              className="tag-button"
              color="primary"
              title="Team Assignment"
              onClick={this.handleClickRequestOpen}
            >
              <AssignmentIndIcon />
            </IconButton>
            <IconButton
              className="eye-button"
              color="primary"
              title="View Team Assignment"
              onClick={this.handleClickView}
            >
              <RemoveRedEyeIcon />
            </IconButton>
            <Row>
              <Col md="6">
                <ul className="CustDetails-ul">
                  <li>
                    <span className="label-sm bold feedback-span-label-col-1">
                      Request Type
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.requestType}
                    </span>
                  </li>

                  <li>
                    <li>
                      <span className="label-sm bold feedback-span-label-col-1">
                        Product Name
                      </span>
                      <span className=" label-sm">
                        &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.productName}
                      </span>
                    </li>
                    <li>
                      <span className="label-sm bold feedback-span-label-col-1">
                        Feature
                      </span>
                      <span className=" label-sm">
                        &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.feature}
                      </span>
                    </li>
                    <div style={{ display: "flex" }}>
                      <div className="label-sm bold feedback-span-label-col-1 pt-1">
                        <span>Title / Summary</span>
                      </div>
                      <div style={{ width: "2%", textAlign: "end" }}>
                        <span className="label-sm">:</span>
                      </div>
                      <div style={{ width: "77%", paddingLeft: "1%" }}>
                        <span className=" label-sm">{this.state.title}</span>
                      </div>
                    </div>
                  </li>

                  <li>
                    <span className="label-sm bold feedback-span-label-col-1">
                      Requester Name
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.feedback.requesterName}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold feedback-span-label-col-1">
                      Contact No
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {`${this.state.dialingCode}-${this.state.contactNumber}`}
                    </span>
                  </li>
                  <li>
                    <div style={{ display: "flex" }}>
                      <div className="label-sm bold feedback-span-label-col-1 pt-1">
                        <span>Attachment</span>
                      </div>
                      <div style={{ width: "77%" }}>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;
                          <a
                            onClick={() => this.openView()}
                            className="bold label-sm"
                            style={{ cursor: "pointer" }}
                          >
                            {this.props.feedback.attachmentPath &&
                              this.props.feedback.attachmentPath}
                          </a>
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div style={{ display: "flex" }}>
                      <div className="label-sm bold feedback-span-label-col-1 pt-1">
                        <span>Detailed Desc.</span>
                      </div>
                      <div style={{ width: "2%", textAlign: "end" }}>
                        <span className="label-sm">:</span>
                      </div>
                      <div style={{ width: "77%", paddingLeft: "1%" }}>
                        <span className=" label-sm">
                          {this.state.detailedDescription}
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </Col>
              <Col md="6">
                <ul className="CustDetails-ul">
                  <li>
                    <span className="label-sm bold feedback-span-label-col-2">
                      Severity
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.severity}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold feedback-span-label-col-2">
                      Priority
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.priority}
                    </span>
                  </li>

                  <li>
                    <span className="label-sm bold feedback-span-label-col-2">
                      Resolution
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.state.resolution}
                    </span>
                  </li>

                  <li>
                    <span className="label-sm bold feedback-span-label-col-2">
                      Status
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.status}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold feedback-span-label-col-2">
                      Release Name
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.state.releaseName.split(",")[0]}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold feedback-span-label-col-2">
                      Release Version
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.state.releaseName.split(",")[1]}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold feedback-span-label-col-2">
                      Created By
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.feedback.createdBy} &nbsp;&nbsp; On
                      &nbsp;&nbsp;{" "}
                      {DateFns.format(
                        this.props.feedback.createdOn,
                        "DD-MM-YYYY"
                      )}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold feedback-span-label-col-2">
                      Updated By
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.feedback.updatedBy} &nbsp;&nbsp; On
                      &nbsp;&nbsp;{" "}
                      {DateFns.format(
                        this.props.feedback.updatedOn,
                        "DD-MM-YYYY"
                      )}
                    </span>
                  </li>
                  <li>
                    <div style={{ display: "flex" }}>
                      <div className="label-sm bold feedback-span-label-col-2 pt-1">
                        <span>Comments</span>
                      </div>
                      <div style={{ width: "3%", textAlign: "end" }}>
                        <span className="label-sm">:</span>
                      </div>
                      <div style={{ width: "77%", paddingLeft: "1%" }}>
                        <span className=" label-sm">
                          {this.state.comments &&
                            this.state.comments
                              .split("|")
                              .slice(0)
                              .reverse()
                              .map((item) => (
                                <>
                                  <span>{item}</span>
                                  <br />
                                </>
                              ))}
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </Col>
            </Row>
            <hr />
            <RequestConversation
              requestId={this.props.feedback.id.toString()}
            />
          </Col>
        ) : (
          <Col md="12">
            <h5 className="bold center">
              Update Request : {this.props.feedback.id}
            </h5>
            <i
              onClick={() => this.props.back()}
              className="material-icons pointer cancel-button"
            >
              cancel
            </i>
            <Form
              onSubmit={this.onSubmit}
              className="p-3 pl--5 pr--5"
              noValidate
            >
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label className="label-sm bold">Request Type</Label>
                    <Input
                      type="select"
                      className="form-control-sm"
                      name="requestType"
                      onChange={this.onChange}
                      value={this.state.requestType}
                      required={true}
                      tabIndex="1"
                      disabled={
                        Object.keys(this.props.auth.data).length > 0 &&
                        this.props.auth.data.pieUser.empNo ===
                          this.props.feedback.createdBy
                          ? false
                          : true
                      }
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.requestType.data.map((item) => {
                        return (
                          <option key={item.id} value={item.reqTypeName}>
                            {item.reqTypeName}
                          </option>
                        );
                      })}
                    </Input>
                    {errors.requestType && (
                      <FormText className="error">
                        {errors.requestType}
                      </FormText>
                    )}
                  </FormGroup>
                  <FormComponent
                    labelClass="bold label-sm"
                    label="Feature"
                    type="text"
                    name="feature"
                    change={this.onChange}
                    value={this.state.feature}
                    required={true}
                    disabled={
                      Object.keys(this.props.auth.data).length > 0 &&
                      this.props.auth.data.pieUser.empNo ===
                        this.props.feedback.createdBy
                        ? false
                        : true
                    }
                    tabIndex="3"
                  />

                  <FormGroup>
                    <Label className="label-sm bold">Severity</Label>
                    <Input
                      type="select"
                      className="form-control-sm"
                      name="severity"
                      onChange={this.onChange}
                      value={this.state.severity}
                      required
                      disabled={
                        Object.keys(this.props.auth.data).length > 0 &&
                        this.props.auth.data.pieUser.empNo ===
                          this.props.feedback.createdBy
                          ? false
                          : true
                      }
                      tabIndex="5"
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.requestSeverity.data.map((item) => {
                        return (
                          <option key={item.id} value={item.severityName}>
                            {item.severityName}
                          </option>
                        );
                      })}
                    </Input>
                    {errors.severity && (
                      <FormText className="error">{errors.severity}</FormText>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label className="label-sm bold">Resolution</Label>
                    <Input
                      type="select"
                      className="form-control-sm"
                      name="resolution"
                      onChange={this.onChange}
                      value={this.state.resolution}
                      tabIndex="7"
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.requestResolution.data.map((item) => {
                        return (
                          <option key={item.id} value={item.resolutionName}>
                            {item.resolutionName}
                          </option>
                        );
                      })}
                    </Input>
                    {errors.resolution && (
                      <FormText className="error">{errors.resolution}</FormText>
                    )}
                  </FormGroup>

                  <Row>
                    <Col md="2" className="p-0">
                      <FormComponent
                        labelClass="bold label-sm"
                        label="Code"
                        type="number"
                        name="dialingCode"
                        change={this.onChange}
                        value={this.state.dialingCode}
                        disabled={
                          Object.keys(this.props.auth.data).length > 0 &&
                          this.props.auth.data.pieUser.empNo ===
                            this.props.feedback.createdBy
                            ? false
                            : true
                        }
                        tabIndex="9"
                      />
                    </Col>
                    <Col md="10" className="p-0">
                      <FormComponent
                        labelClass="bold label-sm"
                        label="Contact No."
                        type="tel"
                        name="contactNumber"
                        change={this.onChange}
                        value={this.state.contactNumber}
                        disabled={
                          Object.keys(this.props.auth.data).length > 0 &&
                          this.props.auth.data.pieUser.empNo ===
                            this.props.feedback.createdBy
                            ? false
                            : true
                        }
                        tabIndex="10"
                      />
                    </Col>
                  </Row>

                  <FormComponent
                    labelClass="bold label-sm"
                    label="Detailed Description"
                    type="textarea"
                    name="detailedDescription"
                    change={this.onChange}
                    value={this.state.detailedDescription}
                    required={true}
                    disabled={
                      Object.keys(this.props.auth.data).length > 0 &&
                      this.props.auth.data.pieUser.empNo ===
                        this.props.feedback.createdBy
                        ? false
                        : true
                    }
                    tabIndex="12"
                  />
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label className="label-sm bold">Product Name</Label>
                    <Input
                      type="select"
                      className="form-control-sm"
                      name="productName"
                      onChange={this.onChange}
                      value={this.state.productName}
                      required={true}
                      tabIndex="2"
                      disabled={
                        Object.keys(this.props.auth.data).length > 0 &&
                        this.props.auth.data.pieUser.empNo ===
                          this.props.feedback.createdBy
                          ? false
                          : true
                      }
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.productList.data.map((item) => {
                        return (
                          <option key={item.id} value={item.productName}>
                            {item.productName}
                          </option>
                        );
                      })}
                    </Input>
                    {errors.productName && (
                      <FormText className="error">
                        {errors.productName}
                      </FormText>
                    )}
                  </FormGroup>

                  <FormComponent
                    labelClass="bold label-sm"
                    label="Request Title/Summary"
                    type="text"
                    name="title"
                    change={this.onChange}
                    value={this.state.title}
                    required={true}
                    disabled={
                      Object.keys(this.props.auth.data).length > 0 &&
                      this.props.auth.data.pieUser.empNo ===
                        this.props.feedback.createdBy
                        ? false
                        : true
                    }
                    tabIndex="4"
                  />

                  <FormGroup>
                    <Label className="label-sm bold">Priority</Label>
                    <Input
                      type="select"
                      className="form-control-sm"
                      name="priority"
                      onChange={this.onChange}
                      value={this.state.priority}
                      disabled={
                        Object.keys(this.props.auth.data).length > 0 &&
                        this.props.auth.data.pieUser.empNo ===
                          this.props.feedback.createdBy
                          ? false
                          : true
                      }
                      tabIndex="6"
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.requestPriority.data.map((item) => {
                        return (
                          <option key={item.id} value={item.priorityName}>
                            {item.priorityName}
                          </option>
                        );
                      })}
                    </Input>
                    {errors.priority && (
                      <FormText className="error">{errors.priority}</FormText>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label className="label-sm bold">Release Tag</Label>
                    <Input
                      type="select"
                      className="form-control-sm"
                      name="releaseName"
                      onChange={this.onChange}
                      value={this.state.releaseName}
                      tabIndex="8"
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.releaseTag.data.map((item) => {
                        return (
                          <option
                            key={item.id}
                            value={`${item.releaseName},${item.releaseVersion}`}
                          >
                            {item.releaseName}-{item.releaseVersion}
                          </option>
                        );
                      })}
                    </Input>
                    {errors.releaseName && (
                      <FormText className="error">
                        {errors.releaseName}
                      </FormText>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label className="label-sm bold">Status</Label>
                    <Input
                      type="select"
                      className="form-control-sm"
                      name="status"
                      onChange={this.onChange}
                      value={this.state.status}
                      required
                      tabIndex="11"
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.requestStatus.data.map((item) => {
                        return (
                          <option key={item.id} value={item.statusName}>
                            {item.statusName}
                          </option>
                        );
                      })}
                    </Input>
                    {errors.status && (
                      <FormText className="error">{errors.status}</FormText>
                    )}
                  </FormGroup>

                  <FormComponent
                    labelClass="bold label-sm"
                    label="Comments"
                    type="textarea"
                    name="comments"
                    change={this.onChange}
                    value={this.state.comments}
                    tabIndex="13"
                  />
                </Col>
              </Row>
              <div className="center">
                <button type="submit" className="btn btn-custom" tabIndex="14">
                  Update
                </button>
              </div>
            </Form>
          </Col>
        )}
        {this.props.requests.message ? (
          <Notifier message={this.props.requests.message} />
        ) : null}
        {this.props.requests.error ? (
          <ErrorNotifier message={this.props.requests.error} />
        ) : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
        <CustomizedDialogs
          open={this.state.open}
          handleClose={this.handleClose}
          title="Release Tag"
        ></CustomizedDialogs>
        <CustomizedDialogs
          open={this.state.requestOpen}
          handleClose={this.handleRequestClose}
          title="Team Assignment"
        >
          <AddTeamAssignment
            back={this.handleRequestClose}
            requestId={Number(this.props.feedback.id)}
          />
        </CustomizedDialogs>
        <Modal size="xl" isOpen={this.state.viewTeamAssignment}>
          <ModalHeader toggle={this.handleCloseView}>
            View Team Assignment
          </ModalHeader>
          <ModalBody>
            <TeamAssignmentCard data={this.props.teamAssignment.fetchData} />
          </ModalBody>
        </Modal>
        <CustomizedDialogs
          open={this.state.view}
          handleClose={this.toggleView}
          title={
            this.props.feedback.attachmentPath &&
            this.props.feedback.attachmentPath
          }
          size="md"
        >
          <ResumeViewer
            URL={`${E360_API}/files/${
              this.props.feedback.attachmentPath &&
              this.props.feedback.attachmentPath
            }`}
          />
        </CustomizedDialogs>
      </Row>
    );
  }
}

RequestsDetails.propTypes = {
  getUserRequestType: PropTypes.func.isRequired,
  requestType: PropTypes.object.isRequired,
  getUserRequestSeverity: PropTypes.func.isRequired,
  requestSeverity: PropTypes.object.isRequired,
  getUserRequestPriority: PropTypes.func.isRequired,
  requestPriority: PropTypes.object.isRequired,
  getUserRequestStatus: PropTypes.func.isRequired,
  requestStatus: PropTypes.object.isRequired,
  updateRequests: PropTypes.func.isRequired,
  requests: PropTypes.object.isRequired,
  getUserRequestResolution: PropTypes.func.isRequired,
  requestResolution: PropTypes.object.isRequired,
  getUserProjectTeam: PropTypes.func.isRequired,
  supportTeam: PropTypes.object.isRequired,
  getUserReleaseTag: PropTypes.func.isRequired,
  releaseTag: PropTypes.object.isRequired,
  getUserProductList: PropTypes.func.isRequired,
  productList: PropTypes.object.isRequired,
  e360ReleaseTag: PropTypes.object.isRequired,
  fetchTeamAssignment: PropTypes.func.isRequired,
  teamAssignment: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  requestType: state.requestType,
  requestSeverity: state.requestSeverity,
  requestPriority: state.requestPriority,
  requestStatus: state.requestStatus,
  requests: state.requests,
  requestResolution: state.requestResolution,
  supportTeam: state.supportTeam,
  releaseTag: state.releaseTag,
  productList: state.productList,
  e360ReleaseTag: state.e360ReleaseTag,
  teamAssignment: state.teamAssignment,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getUserRequestType,
  getUserRequestSeverity,
  getUserRequestPriority,
  getUserRequestStatus,
  updateRequests,
  getUserRequestResolution,
  getUserProjectTeam,
  getUserReleaseTag,
  getUserProductList,
  getE360ReleaseTag,
  fetchTeamAssignment,
})(withRouter(RequestsDetails));
