import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Table, Container } from "reactstrap";
import DateFns from "date-fns";
// components
import AddEducation from "./AddEducation";
import AddCertification from "./AddCertification";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import UpdateEducation from "./UpdateEducation";
import UpdateCertification from "./UpdateCertification";
import ConfirmDialog from "../../../../common/confirm-dialog/ConfirmDialog";
// material ui
import DeleteIcon from "@material-ui/icons/Delete";
// redux
import {
  fetchEducationDetails,
  deleteEducationDetails,
} from "../../../../../redux/actions/hcm/my_profile/educationDetailsAction";
import {
  fetchCertificationDetails,
  deleteCertificationDetails,
} from "../../../../../redux/actions/hcm/my_profile/certificationDetailsAction";

// ----------------------------------------------------------------------------------------
class EducationDetails extends Component {
  constructor() {
    super();
    this.state = {
      educationUpdateData: [],
      certificationUpdateData: [],
      addEdu: false,
      addCert: false,
      updateEdu: false,
      updateCert: false,
      confirmDialog: {
        isOpen: false,
        title: "",
        subTitle: "",
      },
      message: null,
      error: null,
    };
    this.addEducation = this.addEducation.bind(this);
    this.addCertification = this.addCertification.bind(this);
    this.getEducationData = this.getEducationData.bind(this);
    this.getCertificationData = this.getCertificationData.bind(this);
    this.updateEducation = this.updateEducation.bind(this);
    this.deleteEducation = this.deleteEducation.bind(this);
    this.updateCertification = this.updateCertification.bind(this);
    this.deleteCertification = this.deleteCertification.bind(this);
    this.updateEducationBack = this.updateEducationBack.bind(this);
    this.updateCertificationBack = this.updateCertificationBack.bind(this);
  }

  componentDidMount() {
    this.getEducationData();
    this.getCertificationData();
  }

  /*
getEducationData funtion to fetch the education details
*/
  getEducationData() {
    this.props.fetchEducationDetails();
  }

  /*
getCertificationDetails fuction to fetch the certification details
*/
  getCertificationData() {
    this.props.fetchCertificationDetails();
  }

  addEducation() {
    this.setState({
      addEdu: !this.state.addEdu,
    });
    this.getEducationData();
  }

  addCertification() {
    this.setState({
      addCert: !this.state.addCert,
    });
    this.getCertificationData();
  }

  updateEducation(item) {
    this.setState({
      educationUpdateData: item,
      updateEdu: !this.state.updateEdu,
    });
  }

  updateEducationBack() {
    this.setState({
      updateEdu: false,
    });
    this.getEducationData();
  }

  updateCertification(item) {
    this.setState({
      certificationUpdateData: item,
      updateCert: !this.state.updateCert,
    });
  }

  updateCertificationBack() {
    this.setState({
      updateCert: false,
    });
    this.getCertificationData();
  }
  /*
deleteEducation function to delete the education data
*/
  deleteEducation(eduId) {
    let data = {
      id: eduId,
    };
    this.props.deleteEducationDetails(data);
    this.setState((prevState) => ({
      ...prevState.confirmDialog,
      confirmDialog: {
        isOpen: false,
      },
    }));
  }

  /*
deleteCertification function to delete the certification data
*/
  deleteCertification(certId) {
    let data = {
      id: certId,
    };
    this.props.deleteCertificationDetails(data);
    this.setState((prevState) => ({
      ...prevState.confirmDialog,
      confirmDialog: {
        isOpen: false,
      },
    }));
  }

  setConfirmDialog = (obj) => {
    this.setState((prevProps) => ({
      confirmDialog: {
        ...prevProps.confirmDialog,
        isOpen: obj.isOpen,
      },
    }));
  };

  render() {
    let shrink;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");
    const {
      addEdu,
      addCert,
      updateEdu,
      updateCert,
      educationUpdateData,
      certificationUpdateData,
    } = this.state;

    return (
      <Container className="card container-card">
        {!addEdu && !addCert && !updateEdu && !updateCert ? (
          <React.Fragment>
            <i
              className="material-icons pointer cancel-button"
              onClick={this.props.back}
            >
              cancel
            </i>
            <div style={{ position: "relative" }} className="card mt-3 p-3">
              <button
                className="btn add-button white-text label-sm"
                onClick={this.addEducation}
              >
                Add
              </button>
              <h4 className="bold center">Education</h4>
              <Table>
                <thead>
                  <tr>
                    <th className="opp-head">Qualification</th>
                    <th className="opp-head">Specialization</th>
                    <th className="opp-head">Institute Name</th>
                    <th className="opp-head">University Name</th>
                    <th className="opp-head">Completed Date</th>
                    <th className="opp-head">Grade</th>
                    <th className="opp-head">Percentage</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.educationDetails.fetchData.length > 0 &&
                    this.props.educationDetails.fetchData.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td className="opp-tableData">{item.empQual}</td>
                          <td className="opp-tableData">{item.empSpeci}</td>
                          <td className="opp-tableData">{item.instiName}</td>
                          <td className="opp-tableData">{item.univName}</td>
                          <td className="opp-tableData">
                            {DateFns.format(item.compDate, "DD-MM-YYYY")}
                          </td>
                          <td className="opp-tableData">{item.grade}</td>
                          <td className="opp-tableData">{item.percentage}</td>
                          <td>
                            <span
                              className="material-icons pointer opp-action-edit"
                              style={{ color: "blue" }}
                              onClick={() => this.updateEducation(item)}
                            >
                              edit
                            </span>
                          </td>
                          <td>
                            <DeleteIcon
                              className="pointer"
                              onClick={() =>
                                this.setState({
                                  confirmDialog: {
                                    isOpen: true,
                                    title:
                                      "Are you sure to delete this record?",
                                    subTitle: "You can't undo this operation",
                                    onConfirm: () =>
                                      this.deleteEducation(item.id),
                                  },
                                })
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
            <hr />
            <div style={{ position: "relative" }} className="card mt-3 p-3">
              <button
                className="btn add-button white-text label-sm"
                onClick={this.addCertification}
              >
                Add
              </button>
              <h4 className="bold center">Certifications</h4>
              <Table>
                <thead>
                  <tr>
                    <th className="opp-head my-profile-cert-InstName">
                      Certification Name
                    </th>
                    <th className="opp-head my-profile-cert-version">
                      Version
                    </th>
                    <th className="opp-head my-profile-cert-specialization">
                      Specialization
                    </th>

                    <th className="opp-head my-profile-cert-compDate">
                      Completed Date
                    </th>
                    <th className="opp-head my-profile-cert-validDate">
                      Valid Until
                    </th>
                    <th className="opp-head my-profile-cert-grade">Grade</th>
                    <th className="opp-head my-profile-cert-percentage">
                      Percentage
                    </th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.certificationDetails.fetchData.length > 0 &&
                    this.props.certificationDetails.fetchData.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td className="opp-tableData my-profile-cert-InstName">
                            {item.certName}
                          </td>
                          <td className="opp-tableData my-profile-cert-version">
                            {item.version}
                          </td>
                          <td className="opp-tableData my-profile-cert-specialization">
                            {item.areaOfSpecialization}
                          </td>

                          <td className="opp-tableData my-profile-cert-compDate">
                            {DateFns.format(item.completionDate, "DD-MM-YYYY")}
                          </td>
                          <td className="opp-tableData my-profile-cert-validDate">
                            {DateFns.format(item.validUntil, "DD-MM-YYYY")}
                          </td>
                          <td className="opp-tableData my-profile-cert-grade">
                            {item.grade}
                          </td>
                          <td className="opp-tableData my-profile-cert-percentage">
                            {item.percentage}
                          </td>
                          <td>
                            <span
                              className="material-icons pointer opp-action-edit"
                              style={{ color: "blue" }}
                              onClick={() => this.updateCertification(item)}
                            >
                              edit
                            </span>
                          </td>
                          <td>
                            <DeleteIcon
                              className="pointer"
                              onClick={() =>
                                this.setState({
                                  confirmDialog: {
                                    isOpen: true,
                                    title:
                                      "Are you sure to delete this record?",
                                    subTitle: "You can't undo this operation",
                                    onConfirm: () =>
                                      this.deleteCertification(item.id),
                                  },
                                })
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
          </React.Fragment>
        ) : null}
        {addEdu ? <AddEducation back={this.addEducation} /> : null}
        {updateEdu ? (
          <UpdateEducation
            back={this.updateEducationBack}
            data={educationUpdateData}
          />
        ) : null}
        {addCert ? <AddCertification back={this.addCertification} /> : null}
        {updateCert ? (
          <UpdateCertification
            back={this.updateCertificationBack}
            data={certificationUpdateData}
          />
        ) : null}
        {this.props.educationDetails.message ? (
          <Notifier message={this.props.educationDetails.message} />
        ) : null}
        {this.props.educationDetails.error ? (
          <ErrorNotifier message={this.props.educationDetails.error} />
        ) : null}
        {this.props.certificationDetails.message ? (
          <Notifier message={this.props.certificationDetails.message} />
        ) : null}
        {this.props.certificationDetails.error ? (
          <ErrorNotifier message={this.props.certificationDetails.error} />
        ) : null}
        <ConfirmDialog
          confirmDialog={this.state.confirmDialog}
          setConfirmDialog={this.setConfirmDialog}
        />
      </Container>
    );
  }
}

EducationDetails.propTypes = {
  fetchEducationDetails: PropTypes.func.isRequired,
  deleteEducationDetails: PropTypes.func.isRequired,
  educationDetails: PropTypes.object.isRequired,
  fetchCertificationDetails: PropTypes.func.isRequired,
  deleteCertificationDetails: PropTypes.func.isRequired,
  certificationDetails: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  educationDetails: state.educationDetails,
  certificationDetails: state.certificationDetails,
});

export default connect(mapStateToProps, {
  fetchEducationDetails,
  deleteEducationDetails,
  fetchCertificationDetails,
  deleteCertificationDetails,
})(EducationDetails);
