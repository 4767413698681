import axios from "axios";
import {
  INVOICE_TYPE_ADD,
  INVOICE_TYPE_GET,
  INVOICE_TYPE_UPDATE,
  INVOICE_TYPE_DELETE,
  GET_ERRORS,
} from "../../types";
import {
  ADD_INVOICE_TYPE,
  GET_INVOICE_TYPE,
  UPDATE_INVOICE_TYPE,
  DELETE_INVOICE_TYPE,
} from "../../../../utils/routes";
import { setMessage } from "../../salesActions";

export const addInvoiceType = (data, back) => (dispatch) => {
  axios
    .post(ADD_INVOICE_TYPE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getInvoiceType());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const getInvoiceType = () => (dispatch) => {
  axios
    .get(GET_INVOICE_TYPE, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: INVOICE_TYPE_GET,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};

export const updateInvoiceType = (data, back) => (dispatch) => {
  axios
    .post(UPDATE_INVOICE_TYPE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getInvoiceType());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const deleteInvoiceType = (data) => (dispatch) => {
  axios
    .post(DELETE_INVOICE_TYPE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getInvoiceType());
      }, 2000);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};
