import React, { PureComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { Card } from "reactstrap";
import axios from "axios";
import { GET_LENGTH_OF_SALES_CYCLE } from "../../../../utils/routes";

// const data = [
//   {
//     name: "May",
//     days: 4,
//   },
//   {
//     name: "Jun",
//     days: 3,
//   },
//   {
//     name: "Jul",
//     days: 2,
//   },
// ];

const xAxisStyle = {
  fontSize: "8px",
  fontWeight: "400",
  marginBottom: "10px",
};

export default class LengthOfSalesCycle extends PureComponent {
  static jsfiddleUrl = "https://jsfiddle.net/alidingling/xqjtetw0/";

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      error: "",
    };
  }

  componentDidMount() {
    axios
      .get(GET_LENGTH_OF_SALES_CYCLE, { withCredentials: true })
      .then((res) => {
        this.setState({
          data: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          error: err,
        });
      });
  }

  render() {
    const { data } = this.state;

    return (
      <Card className="recharts-card">
        <LineChart
          width={290}
          height={150}
          data={data}
          margin={{
            top: 5,
            right: 20,
            left: -15,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            tick={{ fontSize: "10px", fontWeight: "400" }}
          />
          <YAxis
            tick={{ fontSize: "10px", fontWeight: "400" }}
            domain={[0, "dataMax"]}
          />
          <Tooltip contentStyle={{ fontSize: "12px", fontWeight: "400" }} />
          <Legend iconSize="8px" wrapperStyle={xAxisStyle} />
          <Line
            type="monotone"
            dataKey="days"
            stroke="#8884d8"
            // activeDot={{ r: 8 }}
          />
          {/* <Line type="monotone" dataKey="amount" stroke="#82ca9d" /> */}
        </LineChart>
      </Card>
    );
  }
}
