import {
  PO_ENTRY_GET,
  PO_ENTRY_PURCHASE_ITEM_GET,
  PO_ENTRY_SCHEDULED_PYMT_DATE_GET,
  PO_ENTRY_LATEST_PO_GET,
  SET_MESSAGE,
  GET_ERRORS,
} from "../../../../actions/types";

const initialState = {
  data: [],
  latestPOEntryData: [],
  purchaseItemData: [],
  scheduledPymtDateData: [],
  message: null,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PO_ENTRY_GET:
      return {
        ...state,
        data: action.payload,
      };
    case PO_ENTRY_LATEST_PO_GET:
      return {
        ...state,
        latestPOEntryData: action.payload,
      };
    case PO_ENTRY_PURCHASE_ITEM_GET:
      return {
        ...state,
        purchaseItemData: action.payload,
      };
    case PO_ENTRY_SCHEDULED_PYMT_DATE_GET:
      return {
        ...state,
        scheduledPymtDateData: action.payload,
      };
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case GET_ERRORS:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
