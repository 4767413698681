import React, { Component } from "react";
import { Form, Row, Col, FormGroup, Label, Input, FormText } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DateFns from "date-fns";
// components
import {
  Validator,
  submitValidator,
  valid,
  errors,
  refresh,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
// redux
import { getUserBusinessGeowise } from "../../../../../redux/actions/hcm/business_setup/businessGeowiseAction";
import { getUserBusinessCountries } from "../../../../../redux/actions/hcm/business_setup/businessCountriesAction";
import { updateOrganization } from "../../../../../redux/actions/hcm/business_setup/organizationAction";
import { getUserEnterpriseDetail } from "../../../../../redux/actions/hcm/business_setup/enterpriseDetailAction";

// ---------------------------------------------------------------------------------------------------------

class UpdateOrganization extends Component {
  constructor(props) {
    super(props);
    const {
      id,
      enterpriseName,
      orgLegalEntityName,
      orgLegalEntityRegNo,
      orgLegalEntityCode,
      geoName,
      country,
      panNo,
      taxRegNo,
      gstNo,
      startDate,
      email,
      contactNo,
      endDate,
      status,
      address,
      remarks,
    } = this.props.data;
    this.state = {
      legalEntityId: id,
      enterpriseName,
      legalEntityName: orgLegalEntityName,
      legalEntityRegNo: orgLegalEntityRegNo,
      legalEntityCode: orgLegalEntityCode,
      geoName,
      country,
      panNo: panNo,
      taxRegNo: taxRegNo,
      gstNo: gstNo,
      startDate: DateFns.format(startDate, "YYYY-MM-DD"),
      email,
      contactNo,
      endDate: DateFns.format(endDate, "YYYY-MM-DD"),
      status: status,
      address: address,
      remarks: remarks,
      message: "",
      error: "",
      dateError: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    if (e.target.name === "endDate") {
      if (Date.parse(e.target.value) < Date.parse(this.state.startDate)) {
        this.setState({
          [e.target.name]: e.target.value,
          dateError: "EndDate must be greater than or equal to StartDate",
        });
      } else {
        this.setState({
          [e.target.name]: e.target.value,
          dateError: "",
        });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      id: this.state.legalEntityId,
      enterpriseName: this.state.enterpriseName,
      orgLegalEntityName: this.state.legalEntityName,
      orgLegalEntityRegNo: this.state.legalEntityRegNo,
      orgLegalEntityCode: this.state.legalEntityCode,
      geoName: this.state.geoName,
      country: this.state.country,
      panNo: this.state.panNo,
      taxRegNo: this.state.taxRegNo,
      gstNo: this.state.gstNo,
      startDate: this.state.startDate,
      email: this.state.email,
      contactNo: this.state.contactNo,
      endDate: this.state.endDate,
      status: this.state.status,
      address: this.state.address,
      remarks: this.state.remarks,
    };
    if (valid) {
      this.props.updateOrganization(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    this.props.getUserBusinessGeowise();
    this.props.getUserBusinessCountries();
    this.props.getUserEnterpriseDetail();
    refresh();
    this.setState({
      message: "",
      error: "",
    });
  }

  render() {
    const {
      enterpriseName,
      legalEntityName,
      legalEntityRegNo,
      legalEntityCode,
      geoName,
      country,
      panNo,
      taxRegNo,
      gstNo,
      startDate,
      email,
      contactNo,
      endDate,
      status,
      address,
      remarks,
      error,
      message,
      dateError,
    } = this.state;
    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Update Organization</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="4">
              <FormGroup>
                <Label className="label-sm bold">Enterprise Name</Label>
                <Input
                  type="select"
                  name="enterpriseName"
                  className="form-control-sm"
                  value={enterpriseName}
                  onChange={this.onChange}
                  required={true}
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {this.props.enterpriseDetail.data.map((item) => {
                    return (
                      <option key={item.id} value={item.enterpriseName}>
                        {item.enterpriseName}
                      </option>
                    );
                  })}
                </Input>
                {errors.enterpriseName && (
                  <FormText className="error">{errors.enterpriseName}</FormText>
                )}
              </FormGroup>
              <FormComponent
                labelClass="bold label-sm"
                label="Organization Name"
                type="text"
                name="legalEntityName"
                inputClass="form-control-sm"
                value={legalEntityName}
                change={this.onChange}
                required={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Organization Reg No"
                type="text"
                name="legalEntityRegNo"
                inputClass="form-control-sm"
                value={legalEntityRegNo}
                change={this.onChange}
                required={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Organization Code"
                type="text"
                name="legalEntityCode"
                inputClass="form-control-sm"
                value={legalEntityCode}
                change={this.onChange}
                required={true}
              />
              <FormGroup>
                <Label className="bold label-sm">Geography</Label>
                <Input
                  type="select"
                  name="geoName"
                  className="form-control-sm"
                  value={geoName}
                  onChange={this.onChange}
                  required={true}
                >
                  <option hidden value="">
                    Select...
                  </option>
                  {this.props.businessGeowise.data.length > 0 &&
                    this.props.businessGeowise.data.map((item) => {
                      return (
                        <option key={item.id} value={item.geoName}>
                          {item.geoName}
                        </option>
                      );
                    })}
                </Input>
                {errors.geoName && (
                  <FormText className="error">{errors.geoName}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="bold label-sm">Country</Label>
                <Input
                  type="select"
                  name="country"
                  className="form-control-sm"
                  value={country}
                  onChange={this.onChange}
                  required={true}
                >
                  <option hidden value="">
                    Select...
                  </option>
                  {this.props.businessCountries.data.length > 0 &&
                    this.props.businessCountries.data.map((item) => {
                      if (this.state.geoName === item.geoName) {
                        return (
                          <option key={item.countryId} value={item.countryName}>
                            {item.countryName}
                          </option>
                        );
                      }
                    })}
                </Input>
                {errors.country && (
                  <FormText className="error">{errors.country}</FormText>
                )}
              </FormGroup>
            </Col>
            <Col md="4">
              <FormComponent
                labelClass="bold label-sm"
                label="PAN No."
                type="text"
                name="panNo"
                inputClass="form-control-sm"
                value={panNo}
                change={this.onChange}
                required={true}
                maxlength="10"
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Tax Reg No."
                type="text"
                name="taxRegNo"
                inputClass="form-control-sm"
                value={taxRegNo}
                change={this.onChange}
                required={true}
                maxlength="11"
              />
              <FormComponent
                labelClass="bold label-sm"
                label="GST No."
                type="text"
                name="gstNo"
                inputClass="form-control-sm"
                value={gstNo}
                change={this.onChange}
                required={true}
                maxlength="15"
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Start Date"
                type="date"
                name="startDate"
                inputClass="form-control-sm"
                value={startDate}
                change={this.onChange}
                required={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="End Date"
                type="date"
                name="endDate"
                inputClass="form-control-sm"
                value={endDate}
                change={this.onChange}
                error={dateError}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Status"
                type="text"
                name="status"
                inputClass="form-control-sm"
                value={status}
                change={this.onChange}
                required={true}
              />
            </Col>
            <Col md="4">
              <FormComponent
                labelClass="bold label-sm"
                label="Email"
                type="email"
                name="email"
                inputClass="form-control-sm"
                value={email}
                change={this.onChange}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Contact No"
                type="tel"
                name="contactNo"
                inputClass="form-control-sm"
                value={contactNo}
                change={this.onChange}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Address"
                type="text"
                name="address"
                inputClass="form-control-sm"
                value={address}
                change={this.onChange}
                required={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Remarks"
                type="textarea"
                name="remarks"
                inputClass="form-control-sm"
                value={remarks}
                change={this.onChange}
              />
            </Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Update
            </button>
          </div>
        </Form>
        {this.props.organization.message && (
          <Notifier message={this.props.organization.message} />
        )}
        {this.props.organization.error && (
          <ErrorNotifier message={this.props.organization.error} />
        )}
        {this.state.error && <ErrorNotifier message={this.state.error} />}
      </React.Fragment>
    );
  }
}

UpdateOrganization.propTypes = {
  updateOrganization: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  getUserBusinessGeowise: PropTypes.func.isRequired,
  businessGeowise: PropTypes.object.isRequired,
  getUserBusinessCountries: PropTypes.func.isRequired,
  businessCountries: PropTypes.object.isRequired,
  getUserEnterpriseDetail: PropTypes.func.isRequired,
  enterpriseDetail: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  businessGeowise: state.businessGeowise,
  businessCountries: state.businessCountries,
  organization: state.organization,
  enterpriseDetail: state.enterpriseDetail,
});

export default connect(mapStateToProps, {
  getUserBusinessGeowise,
  getUserBusinessCountries,
  updateOrganization,
  getUserEnterpriseDetail,
})(UpdateOrganization);
