import React, { Component } from 'react'
import { Container, Row, Col,Button } from "reactstrap";
import SimpleBar from "simplebar-react";
import {GrFormView } from "react-icons/gr";
import {AiOutlineDownload} from 'react-icons/ai'
import { Link } from 'react-router-dom';

import "simplebar/dist/simplebar.min.css";

export default class ViewUploadedmessageRs extends Component {
    constructor(props){
     super(props) 

     this.state = {
       isExtractShow:false,
       isProcessedShow:false,
       isUnProcessedShow:false,
       storeResult:[]
     }

     this.handleExtractedView = this.handleExtractedView.bind(this)
     this.handleProcessView = this.handleProcessView.bind(this)
     this.handleUnprocessView = this.handleUnprocessView.bind(this)
     this.downloadClick = this.downloadClick.bind(this)
    
    }


    handleExtractedView(){
    this.setState({
      isExtractShow:!this.state.isExtractShow,
      isProcessedShow:false,
      isUnProcessedShow:false

    })
    }

    handleProcessView(){
      this.setState({
        isExtractShow:this.state.isExtractShow,
        isProcessedShow:!this.state.isProcessedShow,
        isUnProcessedShow:false
  
      })

    }

    handleUnprocessView(){
      this.setState({
        isExtractShow:this.state.isExtractShow,
        isProcessedShow:false,
        isUnProcessedShow:!this.state.isUnProcessedShow
  
      })
    }

    downloadClick(){
    let result =  this.props.viewData.dataUnProcessed.map(res => JSON.stringify(res))
    var blob1 = new Blob(result, { type: "text/plain;charset=utf-8" })
    var isIE = false || !!document.documentMode;
    if (isIE) {
      window.navigator.msSaveBlob(blob1, "Customers.xlsx");
  } else {
      var url = window.URL || window.webkitURL;
      let link
      link = url.createObjectURL(blob1);
      var a = document.createElement("a");
      a.download = "Customers.xlsx";
      a.href = link;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
  }

    }

   

  
    render() {
      
        return (
          <div id="right_panel">
            <div id="resize"></div>

            {/* <SimpleBar
              style={{
                maxHeight: 400,

                minHeight: 200,

                direction: "b",

                color: "#fff",

                marginTop: "2rem",

                border: "1px solid black",
              }}
              autoHide={false}
              className="simple-scrollbar"
            > */}
              <pre style={{ marginRight: "20px", color: "black" }}>
                <div style={{ display: "flex",justifyContent:"center",margin:"auto" }}>
                  <span style={{ fontWeight: "bold",marginTop:"0.5rem" }}>Extracted</span>
                  <h3
                    data-toggle="tooltip"
                    data-placement="top"
                    title="View Uploaded Data"
                    onClick={this.handleExtractedView}
                    style={{ marginLeft: "1.5rem",alignItems:"center" }}
                  >
                    {" "}
                    <GrFormView size="40" />{" "}
                  </h3>
                </div>

                {this.state.isExtractShow &&  this.props.viewData.dataExtracted.map((data) => {
                  return <p>{JSON.stringify(data)}</p>;
                })}
              </pre>
              <pre style={{ marginRight: "20px", color: "black" }}>
              <div style={{ display: "flex",justifyContent:"center",margin:"auto" }}>
                  <span style={{ fontWeight: "bold",marginTop:"0.5rem" }}>Processed</span>
                  <h3
                    data-toggle="tooltip"
                    data-placement="top"
                    title="View Uploaded Data"
                    onClick={this.handleProcessView}
                    style={{ color: "#04044c", marginLeft: "1.5rem",alignItems:"center" }}
                  >
                    {" "}
                    <GrFormView size="40" />{" "}
                  </h3>
                </div>

                { this.state.isProcessedShow &&  this.props.viewData.dataProcessed.map((data) => {
                  return <p>{JSON.stringify(data)}</p>;
                })}
              </pre>
              <pre style={{ marginRight: "20px", color: "black" }}>
              <div style={{ display: "flex",justifyContent:"center",   margin:"auto" }}>
                  <div style={{display: "flex",justifyContent:"center",marginLeft:"4rem"}}>
                  <span style={{ fontWeight: "bold",marginTop:"0.5rem" }}>Unprocessed</span>
                  <h3
                    data-toggle="tooltip"
                    data-placement="top"
                    title="View Uploaded Data"
                    onClick={this.handleUnprocessView}
                    style={{ color: "#04044c", marginLeft: "0.7rem",alignItems:"center" }}
                  >
                    {" "}
                    <GrFormView size="40" />{" "}
                  </h3>
                  {/* <Button     type="button" value="Download JSON" > */}
                  <h3
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Download Unprocessed Data"
                    onClick={this.downloadClick} 
                    style={{ color: "#04044c",alignItems:"center" }}
                  >
                    {" "}
                    <AiOutlineDownload size="40" />{" "}
                  </h3>
                  {/* </Button> */}
                  </div>
                </div>

                {this.state.isUnProcessedShow &&  this.props.viewData.dataUnProcessed.map((data) => {
                  return <p>{JSON.stringify(data)}</p>;
                })}
              </pre>
            {/* </SimpleBar> */}
          </div>
        );
    }
}
