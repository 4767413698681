import React, { Component } from "react";
import { Row, Col, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// components
import {
  Validator,
  submitValidator,
  errors,
  valid,
  refresh,
} from "../../../common/Validator";
//   redux
import { addEmpManagement } from "../../../../redux/actions/hcm/hr/emp_management/empManagementAction";
import { getUserBusinessGeowise } from "../../../../redux/actions/hcm/business_setup/businessGeowiseAction";
import { getUserBusinessCountries } from "../../../../redux/actions/hcm/business_setup/businessCountriesAction";
import { getUserBusinessLocations } from "../../../../redux/actions/hcm/business_setup/businessLocationsAction";
import { getUserEmpType } from "../../../../redux/actions/hcm/configurations/empTypeAction";
import { getUserEmpPosition } from "../../../../redux/actions/hcm/configurations/empPositionAction";
import { getUserEmpGrade } from "../../../../redux/actions/hcm/configurations/empGradeAction";
import { getUserOrganization } from "../../../../redux/actions/hcm/business_setup/organizationAction";
import { getUserBusinessUnit } from "../../../../redux/actions/hcm/business_setup/businessUnitAction";
import { getUserDepartments } from "../../../../redux/actions/hcm/business_setup/departmentsAction";

// --------------------------------------------------------------------------------------------------------------

export class CreateEmployee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organizationName: "",
      businessUnit: "",
      department: "",
      geoName: "",
      country: "",
      location: "",
      empType: "",
      mobile: "",
      grade: "",
      email: "",
      empPosition: "",
      doj: "",
      error: "",
      message: "",
    };
  }

  onChange = (e) => {
    e.preventDefault();
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
    refresh();
    this.props.getUserBusinessGeowise();
    this.props.getUserBusinessCountries();
    this.props.getUserBusinessLocations();
    this.props.getUserEmpType();
    this.props.getUserEmpPosition();
    this.props.getUserEmpGrade();
    this.props.getUserOrganization();
    this.props.getUserBusinessUnit();
    this.props.getUserDepartments();
  }

  onSubmit = (e) => {
    e.preventDefault();
    submitValidator(e.target);
    let employee = {
      hcmpersonalId: this.props.id,
      organizationName: this.state.organizationName,
      empBusinessUnit: this.state.businessUnit,
      empDepartment: this.state.department,
      countryCode: this.state.country.split(",")[1],
      countryName: this.state.country.split(",")[0],
      geoName: this.state.geoName,
      locationName: this.state.location,
      empType: this.state.empType,
      grade: this.state.grade,
      empPosition: this.state.empPosition.split(",")[1],
      organizationEmailId: this.state.email,
      dateOfJoin: this.state.doj,
    };

    if (valid) {
      this.props.addEmpManagement(employee, this.props.apiBack);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  };

  render() {
    const {
      organizationName,
      businessUnit,
      department,
      geoName,
      country,
      location,
      empType,
      grade,
      email,
      empPosition,
      doj,
    } = this.state;

    return (
      <Col md="12">
        <h5 className="bold pointer center pt-2">Create Employee</h5>
        <i
          className="material-icons pointer"
          style={{
            position: "absolute",
            top: "5px",
            right: "5px",
            color: "#C00",
          }}
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label className="bold label-sm">Organization Name</Label>
                <Input
                  type="select"
                  name="organizationName"
                  className="form-control-sm"
                  value={organizationName}
                  onChange={this.onChange}
                  required={true}
                >
                  <option hidden value="">
                    Select...
                  </option>
                  {this.props.organization.data.length > 0 &&
                    this.props.organization.data.map((item) => {
                      return (
                        <option key={item.id} value={item.orgLegalEntityName}>
                          {item.orgLegalEntityName}
                        </option>
                      );
                    })}
                </Input>
                {errors.organizationName && (
                  <FormText className="error">
                    {errors.organizationName}
                  </FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="bold label-sm">Business Unit</Label>
                <Input
                  type="select"
                  name="businessUnit"
                  className="form-control-sm"
                  value={businessUnit}
                  onChange={this.onChange}
                  required={true}
                >
                  <option hidden value="">
                    Select...
                  </option>
                  {this.props.businessUnit.data.length > 0 &&
                    this.props.businessUnit.data
                      .filter(
                        (value) => value.orgLegalEntityName === organizationName
                      )
                      .map((item) => {
                        return (
                          <option key={item.id} value={item.busiUnitName}>
                            {item.busiUnitName}
                          </option>
                        );
                      })}
                </Input>
                {errors.businessUnit && (
                  <FormText className="error">{errors.businessUnit}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="bold label-sm">Department</Label>
                <Input
                  type="select"
                  name="department"
                  className="form-control-sm"
                  value={department}
                  onChange={this.onChange}
                  required={true}
                >
                  <option hidden value="">
                    Select...
                  </option>
                  {this.props.departments.data.length > 0 &&
                    this.props.departments.data
                      .filter((value) => value.busiUnitName === businessUnit)
                      .map((item) => {
                        return (
                          <option key={item.id} value={item.departmentName}>
                            {item.departmentName}
                          </option>
                        );
                      })}
                </Input>
                {errors.department && (
                  <FormText className="error">{errors.department}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="bold label-sm">Geography</Label>
                <Input
                  type="select"
                  name="geoName"
                  className="form-control-sm"
                  value={geoName}
                  onChange={this.onChange}
                  placeholder="Geography"
                  required={true}
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {this.props.businessGeowise.data.map((item) => {
                    return (
                      <option key={item.geoId} value={item.geoName}>
                        {item.geoName}
                      </option>
                    );
                  })}
                </Input>
                {errors.geoName && (
                  <FormText className="error">{errors.geoName}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="bold label-sm">Country</Label>
                <Input
                  type="select"
                  name="country"
                  className="form-control-sm"
                  value={country}
                  onChange={this.onChange}
                  placeholder="Country"
                  required={true}
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {this.props.businessCountries.data.map((item) => {
                    if (item.geoName === geoName) {
                      return (
                        <option
                          key={item.countryId}
                          value={`${item.countryName},${item.countryCode}`}
                        >
                          {item.countryName}
                        </option>
                      );
                    }
                  })}
                </Input>
                {errors.country && (
                  <FormText className="error">{errors.country}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="bold label-sm">Work Location</Label>
                <Input
                  type="select"
                  name="location"
                  className="form-control-sm"
                  value={location}
                  onChange={this.onChange}
                  required={true}
                >
                  <option hidden value="">
                    Select...
                  </option>
                  {this.props.businessLocations &&
                    this.props.businessLocations.data.map((item) => {
                      if (
                        item.countryName === country.split(",")[0].toString()
                      ) {
                        return (
                          <option
                            key={item.locationId}
                            value={item.locationName}
                          >
                            {item.locationCode}-{item.locationName}
                          </option>
                        );
                      }
                    })}
                </Input>
                {errors.location && (
                  <FormText className="error">{errors.location}</FormText>
                )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label className="bold label-sm">Organization Email Id</Label>
                <Input
                  type="email"
                  name="email"
                  className="form-control-sm"
                  value={email}
                  onChange={this.onChange}
                  placeholder="email"
                  required={true}
                />
                {errors.email && (
                  <FormText className="error">{errors.email}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="bold label-sm">Emp Position</Label>
                <Input
                  type="select"
                  name="empPosition"
                  className="form-control-sm"
                  value={empPosition}
                  onChange={this.onChange}
                  required={true}
                >
                  <option hidden value="">
                    Select...
                  </option>
                  {this.props.empPosition.data.length > 0 &&
                    this.props.empPosition.data.map((item) => {
                      return (
                        <option
                          key={item.id}
                          value={`${item.id},${item.positionName}`}
                        >
                          {item.positionName}
                        </option>
                      );
                    })}
                </Input>
                {errors.empPosition && (
                  <FormText className="error">{errors.empPosition}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="bold label-sm">Grade</Label>
                <Input
                  type="select"
                  name="grade"
                  className="form-control-sm"
                  value={grade}
                  onChange={this.onChange}
                  required={true}
                >
                  <option hidden value="">
                    Select...
                  </option>
                  {this.props.empGrade.data.length > 0 &&
                    this.props.empGrade.data.map((item) => {
                      if (
                        Number(this.state.empPosition.split(",")[0]) ===
                        Number(item.emppositionId)
                      ) {
                        return (
                          <option key={item.id} value={item.grade}>
                            {item.grade}
                          </option>
                        );
                      }
                    })}
                </Input>
                {errors.grade && (
                  <FormText className="error">{errors.grade}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="bold label-sm">Employee Type</Label>
                <Input
                  type="select"
                  name="empType"
                  className="form-control-sm"
                  value={empType}
                  onChange={this.onChange}
                  required={true}
                >
                  <option hidden value="">
                    Select...
                  </option>
                  {this.props.empType &&
                    this.props.empType.data.map((item) => {
                      return (
                        <option
                          key={item.employeeTypeId}
                          value={item.employeeTypeName}
                        >
                          {item.employeeTypeName}
                        </option>
                      );
                    })}
                </Input>
                {errors.empType && (
                  <FormText className="error">{errors.empType}</FormText>
                )}
              </FormGroup>

              <FormGroup>
                <Label className="bold label-sm">Date of Join</Label>
                <Input
                  type="date"
                  name="doj"
                  className="form-control-sm"
                  value={doj}
                  onChange={this.onChange}
                  required={true}
                />
                {errors.doj && (
                  <FormText className="error">{errors.doj}</FormText>
                )}
              </FormGroup>
            </Col>
          </Row>
          <div className="center">
            <button
              type="submit"
              className="btn mt-3 center login-button white-text"
            >
              Submit
            </button>
          </div>
        </Form>
      </Col>
    );
  }
}

CreateEmployee.propTypes = {
  prop: PropTypes,
  getUserBusinessGeowise: PropTypes.func.isRequired,
  businessGeowise: PropTypes.object.isRequired,
  getUserBusinessCountries: PropTypes.func.isRequired,
  businessCountries: PropTypes.object.isRequired,
  getUserBusinessLocations: PropTypes.func.isRequired,
  businessLocations: PropTypes.object.isRequired,
  getUserEmpType: PropTypes.func.isRequired,
  empType: PropTypes.object.isRequired,
  addEmpManagement: PropTypes.func.isRequired,
  getUserEmpPosition: PropTypes.func.isRequired,
  empPosition: PropTypes.func.isRequired,
  getUserEmpGrade: PropTypes.func.isRequired,
  empGrade: PropTypes.object.isRequired,
  getUserOrganization: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  getUserBusinessUnit: PropTypes.func.isRequired,
  businessUnit: PropTypes.object.isRequired,
  getUserDepartments: PropTypes.func.isRequired,
  departments: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  businessGeowise: state.businessGeowise,
  businessCountries: state.businessCountries,
  businessLocations: state.businessLocations,
  empType: state.empType,
  empManagement: state.empManagement,
  empPosition: state.empPosition,
  empGrade: state.empGrade,
  organization: state.organization,
  businessUnit: state.businessUnit,
  departments: state.departments,
});

const mapDispatchToProps = (dispatch) => ({
  getUserBusinessGeowise: () => dispatch(getUserBusinessGeowise()),
  getUserBusinessCountries: () => dispatch(getUserBusinessCountries()),
  getUserBusinessLocations: () => dispatch(getUserBusinessLocations()),
  getUserEmpType: () => dispatch(getUserEmpType()),
  addEmpManagement: (employee, back) =>
    dispatch(addEmpManagement(employee, back)),
  getUserEmpPosition: () => dispatch(getUserEmpPosition()),
  getUserEmpGrade: () => dispatch(getUserEmpGrade()),
  getUserOrganization: () => dispatch(getUserOrganization()),
  getUserBusinessUnit: () => dispatch(getUserBusinessUnit()),
  getUserDepartments: () => dispatch(getUserDepartments()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEmployee);
