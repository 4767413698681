import React, { Component } from "react";
import { Form, Row, Col, FormGroup, Label, Input, FormText } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// components
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
// redux
import { updateEmpPosition } from "../../../../../redux/actions/hcm/configurations/empPositionAction";

// --------------------------------------------------------------------------------------------------------

class UpdateEmployeePosition extends Component {
  constructor(props) {
    super(props);
    const {
      id,
      level,
      positionHierarchy,
      positionCode,
      positionName,
      description,
    } = this.props.data;
    this.state = {
      empPositionId: id,
      level: level,
      positionHierarchy: positionHierarchy,
      positionCode: positionCode,
      empPosition: positionName,
      description: description,
      message: "",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      id: this.state.empPositionId,
      level: Number(this.state.level),
      positionHierarchy: this.state.positionHierarchy,
      positionCode: this.state.positionCode,
      positionName: this.state.empPosition,
      description: this.state.description,
    };
    if (valid) {
      this.props.updateEmpPosition(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    refresh();
    this.setState({
      message: "",
      error: "",
    });
  }

  render() {
    const {
      level,
      positionHierarchy,
      positionCode,
      empPosition,
      description,
      error,
      message,
    } = this.state;

    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Update Employee Position</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="3"></Col>
            <Col md="6">
              <FormComponent
                labelClass="bold label-sm"
                label="Level"
                type="number"
                name="level"
                inputClass="form-control-sm"
                value={level}
                change={this.onChange}
                required={true}
                disabled={true}
              />
              <FormGroup>
                <Label className="bold label-sm">Position Hierarchy</Label>
                <Input
                  type="text"
                  name="positionHierarchy"
                  value={positionHierarchy}
                  className="form-control-sm"
                  onChange={this.onChange}
                  required={level > 1 ? true : false}
                  disabled={true}
                ></Input>
                {errors.positionHierarchy && (
                  <FormText className="error">
                    {errors.positionHierarchy}
                  </FormText>
                )}
              </FormGroup>
              <FormComponent
                labelClass="bold label-sm"
                label="Position Code"
                type="text"
                name="positionCode"
                inputClass="form-control-sm"
                value={positionCode}
                change={this.onChange}
                required={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Employee Position"
                type="text"
                name="empPosition"
                inputClass="form-control-sm"
                value={empPosition}
                change={this.onChange}
                required={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Description"
                type="textarea"
                name="description"
                inputClass="form-control-sm"
                value={description}
                change={this.onChange}
              />
            </Col>
            <Col md="6"></Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Update
            </button>
          </div>
        </Form>
        {this.props.empPosition.message && (
          <Notifier message={this.props.empPosition.message} />
        )}
        {this.props.empPosition.error && (
          <ErrorNotifier message={this.props.empPosition.error} />
        )}
      </React.Fragment>
    );
  }
}

UpdateEmployeePosition.propTypes = {
  updateEmpPosition: PropTypes.func.isRequired,
  empPosition: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  empPosition: state.empPosition,
});

export default connect(mapStateToProps, {
  updateEmpPosition,
})(UpdateEmployeePosition);
