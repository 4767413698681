import React, { Component } from "react";
import { Form, Row, Col, FormGroup, Label, Input, FormText } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// components
import {
  Validator,
  submitValidator,
  valid,
  PercentageValidation,
  refresh,
  errors,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
// redux
import {
  updateEnterpriseDetail,
  addEnterpriseDetail,
} from "../../../../../redux/actions/hcm/business_setup/enterpriseDetailAction";
import { getUserBusinessGeowise } from "../../../../../redux/actions/hcm/business_setup/businessGeowiseAction";
import { getUserBusinessCountries } from "../../../../../redux/actions/hcm/business_setup/businessCountriesAction";

// -------------------------------------------------------------------------------------------------------------------

class UpdateEnterpriseDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organizationId: this.props.data.length ? this.props.data[0].id : "",
      enterpriseName: this.props.data.length
        ? this.props.data[0].enterpriseName
        : "",
      domainName: this.props.data.length ? this.props.data[0].domainName : "",
      geoName: this.props.data.length ? this.props.data[0].geoName : "",
      country: this.props.data.length ? this.props.data[0].country : "",
      email: this.props.data.length ? this.props.data[0].email : "",
      contactNo: this.props.data.length ? this.props.data[0].contactNo : "",
      description: this.props.data.length ? this.props.data[0].description : "",
      message: "",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    PercentageValidation(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);

    let fd = new FormData();
    if (this.props.data.length > 0) {
      fd.append("id", this.state.organizationId);
      fd.append("enterpriseName", this.state.enterpriseName);
      fd.append("domainName", this.state.domainName);
      fd.append("geoName", this.state.geoName);
      fd.append("country", this.state.country);
      fd.append("email", this.state.email);
      this.state.contactNo
        ? fd.append("contactNo", this.state.contactNo)
        : null;
      fd.append("description", this.state.description);

      if (valid) {
        this.props.updateEnterpriseDetail(fd, this.props.back);
      } else {
        this.setState({
          error: "Enter all mandatory fields",
        });
        setTimeout(() => {
          this.setState({
            error: "",
          });
        }, 2000);
      }
    } else {
      fd.append("enterpriseName", this.state.enterpriseName);
      fd.append("domainName", this.state.domainName);
      fd.append("geoName", this.state.geoName);
      fd.append("country", this.state.country);
      fd.append("email", this.state.email);
      this.state.contactNo
        ? fd.append("contactNo", this.state.contactNo)
        : null;
      fd.append("description", this.state.description);
      fd.append("logo", this.state.logo);

      if (valid) {
        this.props.addEnterpriseDetail(fd, this.props.back);
      } else {
        this.setState({
          error: "Enter all mandatory fields",
        });
        setTimeout(() => {
          this.setState({
            error: "",
          });
        }, 2000);
      }
    }
  }

  componentDidMount() {
    refresh();
    this.setState({
      message: "",
      error: "",
    });
    this.props.getUserBusinessGeowise();
    this.props.getUserBusinessCountries();
  }

  render() {
    const {
      enterpriseName,
      domainName,
      geoName,
      country,
      email,
      contactNo,
      description,
      error,
      message,
    } = this.state;
    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Update Enterprise Detail</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="6">
              <FormComponent
                labelClass="bold label-sm"
                label="Enterprise Name"
                type="text"
                name="enterpriseName"
                inputClass="form-control-sm"
                value={enterpriseName}
                change={this.onChange}
                required={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Domain Name"
                type="url"
                name="domainName"
                inputClass="form-control-sm"
                value={domainName}
                change={this.onChange}
                required={true}
              />
              <FormGroup>
                <Label className="bold label-sm">Geography</Label>
                <Input
                  type="select"
                  name="geoName"
                  className="form-control-sm"
                  value={geoName}
                  onChange={this.onChange}
                  required={true}
                >
                  <option hidden value="">
                    Select...
                  </option>
                  {this.props.businessGeowise.data.length > 0 &&
                    this.props.businessGeowise.data.map((item) => {
                      return (
                        <option key={item.id} value={item.geoName}>
                          {item.geoName}
                        </option>
                      );
                    })}
                </Input>
                {errors.geoName && (
                  <FormText className="error">{errors.geoName}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="bold label-sm">Country</Label>
                <Input
                  type="select"
                  name="country"
                  className="form-control-sm"
                  value={country}
                  onChange={this.onChange}
                  required={true}
                >
                  <option hidden value="">
                    Select...
                  </option>
                  {this.props.businessCountries.data.length > 0 &&
                    this.props.businessCountries.data.map((item) => {
                      if (this.state.geoName === item.geoName) {
                        return (
                          <option key={item.countryId} value={item.countryName}>
                            {item.countryName}
                          </option>
                        );
                      }
                    })}
                </Input>
                {errors.country && (
                  <FormText className="error">{errors.country}</FormText>
                )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormComponent
                labelClass="bold label-sm"
                label="Email"
                type="email"
                name="email"
                inputClass="form-control-sm"
                value={email}
                change={this.onChange}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Contact No"
                type="tel"
                name="contactNo"
                inputClass="form-control-sm"
                value={contactNo}
                change={this.onChange}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Description"
                type="textarea"
                name="description"
                inputClass="form-control-sm"
                value={description}
                change={this.onChange}
              />
            </Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Update
            </button>
          </div>
        </Form>
        {this.props.enterpriseDetail.message && (
          <Notifier message={this.props.enterpriseDetail.message} />
        )}
        {this.props.enterpriseDetail.error && (
          <ErrorNotifier message={this.props.enterpriseDetail.error} />
        )}
        {this.state.error && <ErrorNotifier message={this.state.error} />}
      </React.Fragment>
    );
  }
}

UpdateEnterpriseDetail.propTypes = {
  addEnterpriseDetail: PropTypes.func.isRequired,
  updateEnterpriseDetail: PropTypes.func.isRequired,
  enterpriseDetail: PropTypes.object.isRequired,
  getUserBusinessGeowise: PropTypes.func.isRequired,
  businessGeowise: PropTypes.object.isRequired,
  getUserBusinessCountries: PropTypes.func.isRequired,
  businessCountries: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  enterpriseDetail: state.enterpriseDetail,
  businessGeowise: state.businessGeowise,
  businessCountries: state.businessCountries,
});

export default connect(mapStateToProps, {
  addEnterpriseDetail,
  updateEnterpriseDetail,
  getUserBusinessGeowise,
  getUserBusinessCountries,
})(UpdateEnterpriseDetail);
