export const FormValidator = ({ formErrors, ...rest }) => {
  let valid = true;
  Object.values(formErrors).forEach(item => {
    item.length > 0 && (valid = false);
  });
  
  Object.values(rest).forEach(item => {    
    item === '' && (valid = false);
  });
  return valid;
}

export const emailChecker = RegExp(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/);

//export const textChecker = RegExp(/^[A-Za-z\s]+$/);

// using alphabets and spaces but not only numbers
export const textChecker = RegExp(/^(?!^\d+$)^.+$/)

export const numChecker = RegExp(/^[0-9]+$/);

export const passChecker = RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");