import React, { Component } from "react";
import { Form, Row, Col, FormText } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
} from "../../../../../common/Validator";
import FormComponent from "../../../../../common/FormComponent";
import Notifier from "../../../../../aside/Notifier";
import ErrorNotifier from "../../../../../aside/ErrorNotifier";
import UploadImage from "../../../../../common/UploadImage";
import { addApplication } from "../../../../../../redux/actions/iam/auth_configuration/menu_management/applicationAction";

class AddApplication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appName: "",
      appGroupId: "",
      appIcon: "",
      message: "",
      error: "",
      fileError: "",
      btnDisable: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  /**
   * This function which helps us to get the selected Files from Submited form.
   * @param {object} event Contains the input field event details
   */
  selectFile = (e) => {
    let allowedExtension = /(\.svg|\.png|\.jpeg|\.jpg)$/i;
    if (allowedExtension.exec(e.target.files[0].name)) {
      if ((e.target.files[0].size / 1024 / 1024).toFixed(2) < 2) {
        this.setState({
          appIcon: e.target.files[0],
          fileError: "",
        });
      } else {
        this.setState({
          appIcon: e.target.files[0],
          fileError: "File size must less than 2MB",
        });
      }
    } else {
      this.setState({
        appIcon: e.target.files[0],
        fileError: "File type not allowed",
      });
    }
  };

  /**
   * This function when update the current file value file error value will reset to the empty.
   * @param {object} prevProps This object for previous props value
   * @param {object} prevState This object for previous state value
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevState.appIcon !== this.state.appIcon && this.state.appIcon) {
      this.setState({
        fileError: "",
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);

    let fd = new FormData();

    fd.append("appName", this.state.appName);
    fd.append("appGroupId", this.state.appGroupId);
    fd.append("appIcon", this.state.appIcon);

    if (valid) {
      this.props.addApplication(fd, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
        fileError: `${this.state.appIcon ? "" : "Please Upload the Icon"}`,
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidMount contains the error refresh method and setState method to call the render method
*/
  componentDidMount() {
    refresh();
    this.setState({
      message: "",
      error: "",
    });
  }

  render() {
    const {
      appName,
      appIcon,
      appGroupId,
      error,
      message,
      btnDisable,
      fileError,
    } = this.state;

    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Add Application</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="3"></Col>
            <Col md="6">
              <FormComponent
                labelClass="bold label-sm"
                label="Application Name"
                type="text"
                name="appName"
                value={appName}
                change={this.onChange}
                required={true}
                minLength="2"
                maxLength="15"
              />
              <UploadImage
                appIcon={appIcon}
                selectFile={this.selectFile}
                currentFile={this.state.appIcon}
              />
              {fileError && <FormText className="error">{fileError}</FormText>}
              <FormComponent
                labelClass="bold label-sm"
                label="Group Id"
                type="number"
                name="appGroupId"
                value={appGroupId}
                change={this.onChange}
                required={true}
              />
            </Col>
            <Col md="3"></Col>
          </Row>
          <div className="center">
            <button
              type="submit"
              className="btn login-button white-text"
              disabled={btnDisable ? true : false}
            >
              Add
            </button>
          </div>
        </Form>
        {message && <Notifier message={message} />}
        {this.props.applications.message && (
          <Notifier message={this.props.applications.message} />
        )}
        {error && <ErrorNotifier message={error} />}
        {this.props.applications.error && (
          <ErrorNotifier message={this.props.applications.error} />
        )}
      </React.Fragment>
    );
  }
}

AddApplication.propTypes = {
  addApplication: PropTypes.func.Required,
  applications: PropTypes.object.Required,
};

const mapStateToProps = (state) => ({
  applications: state.applications,
});

export default connect(mapStateToProps, { addApplication })(AddApplication);
