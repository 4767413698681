import axios from "axios";
import {
  EMP_MANAGEMENT_GET,
  EMP_MANAGEMENT_SEARCH,
  ACTIVE_EMPLOYEES_FETCH,
  EMP_DETAILS_FETCH,
} from "../../../types";
import {
  CURD_EMP_MANAGEMENT,
  SEARCH_EMP_MANAGEMENT,
  FETCH_ACTIVE_EMPLOYEES,
  FETCH_EMP_DETAILS,
} from "../../../../../utils/routes";
import setSuccessMessage from "../../../common/setSuccessMessage";
import setErrorMessage from "../../../common/setErrorMessage";
import getDispatch from "../../../common/getDispatch";

export const addEmpManagement = (data, back) => (dispatch) => {
  axios
    .post(CURD_EMP_MANAGEMENT, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getEmpManagement));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getEmpManagement = () => (dispatch) => {
  axios
    .get(CURD_EMP_MANAGEMENT, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(EMP_MANAGEMENT_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const fetchActiveEmployees = () => (dispatch) => {
  axios
    .get(FETCH_ACTIVE_EMPLOYEES, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(ACTIVE_EMPLOYEES_FETCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const fetchEmployeeDetails = (id) => (dispatch) => {
  axios
    .get(`${FETCH_EMP_DETAILS}/${id}`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(EMP_DETAILS_FETCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const searchEmpManagement = (data) => (dispatch) => {
  axios
    .post(SEARCH_EMP_MANAGEMENT, data, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(EMP_MANAGEMENT_SEARCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateEmpManagement = (data, back) => (dispatch) => {
  axios
    .put(CURD_EMP_MANAGEMENT, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteEmpManagement = (data) => (dispatch) => {
  axios
    .delete(CURD_EMP_MANAGEMENT, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getEmpManagement));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
