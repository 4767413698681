import {
  INVOICE_GET,
  RECIEVE_ITEM_GET,
  LATEST_INVOICE_GET,
  SET_MESSAGE,
  GET_ERRORS,
} from "../../../../actions/types";

const initialState = {
  data: [],
  latestInvoiceData: [],
  recieveItemData: [],
  message: null,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case INVOICE_GET:
      return {
        ...state,
        data: action.payload,
      };
    case LATEST_INVOICE_GET:
      return {
        ...state,
        latestInvoiceData: action.payload,
      };
    case RECIEVE_ITEM_GET:
      return {
        ...state,
        recieveItemData: action.payload,
      };
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case GET_ERRORS:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
