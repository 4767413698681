import {
  ADD_NEW_SUPPLIER,
  GET_SUPPLIER_LIST,
  SUPPLIER_UPDATE,
  SUPPLIER_SEARCH,
  SET_MESSAGE,
  GET_ERRORS,
} from "../actions/types";
const initialState = {
  data: [],
  message: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_NEW_SUPPLIER:
      return {
        ...state,
        data: action.payload,
      };
    case GET_SUPPLIER_LIST:
      return {
        ...state,
        data: action.payload,
      };
    case SUPPLIER_UPDATE:
      return {
        ...state,
        data: action.payload,
      };
    case SUPPLIER_SEARCH:
      return {
        ...state,
        data: action.payload,
      };
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case GET_ERRORS:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
