import {
    // ADD_NEW_CUSTOMER,
    GET_JOB_LIST,
    // UPDATE_CUSTOMER,
    JOB_SEARCH,
    // SET_MESSAGE
  } from '../actions/types'
  const initialState = {
    data: [],
    message: null
  };
  
  export default function(state = initialState, action){
    switch(action.type){
      case JOB_SEARCH:
        return {
          ...state,
          data: action.payload
        }
      case GET_JOB_LIST:
        return {
          ...state,
          data: action.payload
        }
        default:
            return state;
        }
      }