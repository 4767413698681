import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { Card } from "reactstrap";
import axios from "axios";
import { GET_SUPPORT_TICKET } from "../../../../utils/routes";
import { getRequestCategory } from "../../../../redux/actions/requestCategoryAction";
import { connect } from "react-redux";

const LegendStyle = {
  fontSize: "8px",
  fontWeight: "400",
  marginBottom: "10px",
  paddingLeft: "10px",
};

// const data = [
//   {
//     name: "May",
//     data1: [{ Issue: 5 }],
//   },
// {
//   name: "Jun",
//   Issue: 5,
//   Diffect: 6,
//   Suggestion: 8,
//   Enhancement: 9,
// },
// {
//   name: "Jul",
//   Issue: 3,
//   Diffect: 4,
//   Suggestion: 6,
//   Enhancement: 3,
// },
// ];

class SupportTicket extends PureComponent {
  static jsfiddleUrl = "https://jsfiddle.net/alidingling/90v76x08/";

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      error: "",
    };
  }

  componentDidMount() {
    axios
      .get(GET_SUPPORT_TICKET, { withCredentials: true })
      .then((res) => {
        this.setState({
          data: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          error: err,
        });
      });

    this.props.getRequestCategory();
  }

  render() {
    const { data } = this.state;

    return (
      <Card className="recharts-card">
        <BarChart
          width={310}
          height={150}
          data={data}
          margin={{
            top: 5,
            right: 35,
            left: -15,
            bottom: 5,
          }}
          barSize={20}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="Month"
            tick={{ fontSize: "10px", fontWeight: "400" }}
          />
          <YAxis
            tick={{ fontSize: "10px", fontWeight: "400" }}
            domain={[0, "dataMax"]}
          />
          <Tooltip contentStyle={{ fontSize: "10px", fontWeight: "400" }} />
          <Legend iconSize={8} wrapperStyle={LegendStyle} />
          {/* <Bar dataKey="Issue" stackId="a" fill="#3234a8" />
          <Bar dataKey="Defect" stackId="a" fill="#9a32a8" />
          <Bar dataKey="Suggestion" stackId="a" fill="#a86232" />
          <Bar dataKey="Enhancement" stackId="a" fill="#FFBB28" /> */}
          {this.props.requestCategory.data.map((item) => {
            return (
              <Bar
                dataKey={item.requestCategory}
                stackId="a"
                fill={item.color}
              />
            );
          })}
        </BarChart>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  requestCategory: state.requestCategory,
});

export default connect(mapStateToProps, { getRequestCategory })(SupportTicket);
