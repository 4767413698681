import React, { Component } from "react";
import { Form, Row, Col, FormGroup, Label, Input, FormText } from "reactstrap";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
} from "../../../../common/Validator";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Select from "react-select";

// components
import FormComponent from "../../../../common/FormComponent";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
// redux
import { addAccountManager } from "../../../../../redux/actions/crm/crm_setup/accountMangerAcction";
import { getEmployee } from "../../../../../redux/actions/iam/emp_onboarding/empOnboardingAction";

// -------------------------------------------------------------------------------------------------

class AddAccountManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employee: "",
      remarks: "",
      error: "",
      employeeError: "",
      message: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleChange = (employee) => {
    if (employee) {
      this.setState({ employee, employeeError: null });
    } else {
      this.setState({
        employeeError: "This field is mandatory",
      });
    }
  };

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      empNo: this.state.employee.value.split(",")[0],
      empName: this.state.employee.value.split(",")[1],
      remarks: this.state.remarks,
    };
    if (valid && this.state.employee) {
      this.props.addAccountManager(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidMount contains the error refresh method and setState method for calling the render method
*/
  componentDidMount() {
    refresh();
    this.setState({
      error: "",
    });
    this.props.getEmployee();
  }

  render() {
    const { employee, remarks, error } = this.state;

    let options1 = [];
    for (let i = 0; i < this.props.employees.data.length; i++) {
      var x1 = {};
      x1[
        "value"
      ] = `${this.props.employees.data[i].empNo},${this.props.employees.data[i].firstName}`;
      x1[
        "label"
      ] = `${this.props.employees.data[i].empNo}-${this.props.employees.data[i].firstName}`;

      options1.push(x1);
    }

    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Add Account Manager</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="6" className="mx-auto">
              <FormGroup>
                <Label className="bold label-sm">Employee</Label>
                <Select
                  name="employee"
                  value={employee}
                  onChange={this.handleChange}
                  options={options1}
                  required
                />
              </FormGroup>
              {this.state.employeeError && (
                <FormText className="error select-search-error">
                  {this.state.employeeError}
                </FormText>
              )}

              <FormComponent
                labelClass="bold label-sm"
                label="Remarks"
                type="textarea"
                name="remarks"
                inputClass="form-control-sm"
                value={remarks}
                change={this.onChange}
              />
            </Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Add
            </button>
          </div>
        </Form>
        {error && <ErrorNotifier message={error} />}
        {this.props.accountManager.error && (
          <ErrorNotifier message={this.props.accountManager.error} />
        )}
        {this.props.accountManager.message && (
          <Notifier message={this.props.accountManager.message} />
        )}
      </React.Fragment>
    );
  }
}

AddAccountManager.propTypes = {
  addAccountManager: PropTypes.func.isRequired,
  accountManager: PropTypes.object.isRequired,
  getEmployee: PropTypes.func.isRequired,
  employees: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  accountManager: state.accountManager,
  employees: state.employees,
});

export default connect(mapStateToProps, {
  addAccountManager,
  getEmployee,
})(AddAccountManager);
