import axios from "axios";
import { PROJECT_MEMBER_GET } from "../../types";
import {
  CURD_PROJECT_MEMBER,
  USER_PROJECT_MEMBER,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addProjectMember = (data, back) => (dispatch) => {
  axios
    .post(CURD_PROJECT_MEMBER, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getProjectMember));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getProjectMember = () => (dispatch) => {
  axios
    .get(CURD_PROJECT_MEMBER, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(PROJECT_MEMBER_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserProjectMember = () => (dispatch) => {
  axios
    .get(USER_PROJECT_MEMBER, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(PROJECT_MEMBER_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateProjectMember = (data, back) => (dispatch) => {
  axios
    .put(CURD_PROJECT_MEMBER, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteProjectMember = (data) => (dispatch) => {
  axios
    .delete(CURD_PROJECT_MEMBER, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getProjectMember));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
