import axios from "axios";
import { CURD_CREATE_ACCOUNT } from "../../../utils/routes";
import setSuccessMessage from "../common/setSuccessMessage";
import setErrorMessage from "../common/setErrorMessage";
import { HOME } from "../../../utils/config";

export const createAccount = (data) => (dispatch) => {
  axios
    .post(CURD_CREATE_ACCOUNT, data)
    .then((res) => {
      dispatch(setSuccessMessage(res));
      window.location.assign(HOME);
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};


