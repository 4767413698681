import React, { Component } from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import Navigation from "./sidebar/Navigation";
import Sidebar from "./sidebar/Sidebar";
// import MyProfile from "./my_profile/MyProfile";
import EditProfile from "./update_profile/EditProfile";
import ChangePassword from "./change_password/ChangePassword";
import Landing from "./dashboard/my_day_at_work/Landing";
import DashboardInsights from "./dashboard/dashboard_insights/DashboardInsights";
import InnovativeIdea from "./dashboard/innovation/InnovativeIdea";
import NewIdea from "./dashboard/innovation/NewIdea";
import CRMInsights from "./crm/insights/CRMInsights";
// import Prm from "./operations/prm/Prm";
// import Vrm from "./operations/vrm/Vrm";

// import Srm from "./services/srm/Srm";
import MarketingInsights from "./marketing/marketing_insights/MarketingInsights";
import Campaign from "./marketing/campaign/Campaign";
import AddCampaign from "./marketing/campaign/AddCampaign";
// import Inquiries from "./marketing/inquiries/Inquiries";
// import AddInquiry from "./marketing/inquiries/AddInquiry";
// import Leads from "./marketing/leads/Leads";
// import AddNewLead from "./marketing/leads/AddNewLead";
// import SaleInsights from "./sales/sales_insights/SaleInsights";
// import Prospects from "./sales/prospects/Prospects";
// import AddProspect from "./sales/prospects/AddProspect";
// import Opportunity from "./sales/opportunities/Opportunity";
// import AddNewOpportunity from "./sales/opportunities/AddNewOpportunity";
// import Orders from "./sales/orders/Orders";
// import AddNewOrders from "./sales/orders/AddNewOrders";
// import ProductList from "./pricing/products/ProductList";
import AddProductList from "./pricing/products/AddProductList";
import ProductInsights from "./products/product_insights/ProductInsights";
import Products from "./products/license_management/Products";
import AddProduct from "./products/license_management/AddProduct";
// import ProjectInsights from "./projects/project_insights/ProjectInsights";
// import Project from "./projects/project_management/Project";
// import AddProject from "./projects/project_management/AddProject";
// import ServiceInsights from "./services/service_insights/ServiceInsights";
import Employees from "./iam/emp_onboarding/Employees";
import AddUser from "./iam/emp_onboarding/AddUser";
import UserRole from "./iam/role_mapping/UserRole";
import AddUserRole from "./iam/role_mapping/AddUserRole";
import PoliciesMaintance from "./hcm/hr/policies/PoliciesMaintance";
import AddPolicies from "./hcm/hr/policies/AddPolicies";
import Jobs from "./hr/job_posting/Jobs";
import AddJob from "./hr/job_posting/AddJob";
// import BusinessSetup from "./platform/business_setup/BusinessSetup";
// import HcmSetup from "./platform/hcm_setup/HcmSetup";
import MarketingSetup from "./platform/marketing_setup/MarketingSetup";
import SalesSetup from "./platform/sales_setup/SalesSetup";

// iam --> Auth Configuration imports
import UserGroup from "./iam/auth_configuration/user_group/UserGroup";
import Roles from "./iam/auth_configuration/role_management/Roles";
import AddRoles1 from "./iam/auth_configuration/role_management/AddRole";
import MenuManagement from "./iam/auth_configuration/menu_management/MenuManagement";
import AccessAndPermission from "./iam/auth_configuration/access_and_permission/AccessAndPermission";
import IamConfig from "./iam/iam_config/IamConfig";

// ------------------------------------------------------------------------------------------------------

// HCM --> Business Setup modules import
import EnterpriseDetail from "./hcm/business_setup/enterprise_detail/EnterpriseDetail";
import BusinessGeowise from "./hcm/business_setup/business_geowise/BusinessGeowise";
import BusinessCountries from "./hcm/business_setup/business_countries/BusinessCountries";
import BusinessLocations from "./hcm/business_setup/business_locations/BusinessLocations";
import BusinessCurrency from "./hcm/business_setup/business_currency/BusinessCurrency";
import Organization from "./hcm/business_setup/organization/Organization";
import BusinessUnit from "./hcm/business_setup/business_unit/BusinessUnit";
import Departments from "./hcm/business_setup/departments/Departments";

// HCM --> Configuration module imports
import Documents from "./hcm/configurations/documents/Documents";
import EmpRelation from "./hcm/configurations/emp_relation/EmpRelation";
import LeaveType from "./hcm/configurations/leave_type/LeaveType";
import ResignationStatus from "./hcm/configurations/resignation_status/ResignationStatus";
import ClearanceStatus from "./hcm/configurations/employee_clearance_status/ClearanceStatus";
import EmployeePosition from "./hcm/configurations/emp_position/EmployeePosition";
import EmployeeGrade from "./hcm/configurations/employee_grade/EmployeeGrade";
import ResignationReason from "./hcm/configurations/resignation_reason/ResignationReason";
import RevokeReason from "./hcm/configurations/revoke_reason/RevokeReason";
import StatusReason from "./hcm/configurations/employee_status_reason/StatusReason";
import EmployeeType from "./hcm/configurations/employee_type/EmployeeType";
import ManagerResignationReason from "./hcm/configurations/manager_resignation_reason/ManagerResignationReason";
import Qualification from "./hcm/configurations/qualification/Qualification";
import ResignationType from "./hcm/configurations/resignation_type/ResignationType";
import DialingCode from "./hcm/configurations/dialing_code/DialingCode";
import CompanyHolidays from "./hcm/configurations/company_holiday/CompanyHolidays";
import PersonalStatus from "./hcm/configurations/personal_status/PersonalStatus";
import HcmGlobalVar from "./hcm/configurations/hcm_global_var/HcmGlobalVar";
import HcmUserVar from "./hcm/configurations/hcm_user_var/HcmUserVar";

// HCM --> MyProfile module imports
import MyProfile from "./hcm/my_profile/MyProfile";

// HCM --> HR module imports
import HREmployees from "./hcm/onboarding/Employees";
// HCM --> HR --> Attendance service imports
import Attendance from "./hcm/hr/attendance/Attendance";
// HCM --> HR --> Policies
import PolicyPage from "./hcm/hr/policies/PolicyPage";
// HCM --> Onboarding
import HcmOnboarding from "./hcm/onboarding/Employees";
import AddHcmOnboarding from "./hcm/onboarding/AddUser";
// HCM --> Employees
import HcmEmployees from "./hcm/employees/Employees";

// HCM --> LMS module imports
import ApplyLeave from "./hcm/lms/ApplyLeave";
import ViewLeave from "./hcm/lms/ViewLeave";
import LeaveBalance from "./hcm/lms/LeaveBalance";
import MyHolidayCalendar from "./hcm/lms/MyHolidayCalendar";
import PendingLeave from "./hcm/lms/PendingLeave";

// HCM --> LMS --> Attendance Report
import AttendanceReport from "./hcm/lms/attendance_report/AttendanceReport";
import LeaveReport from "./hcm/lms/leave_report/LeaveReport";

// HCM --> Resignation module imports
import EmployeeInitiateResignation from "./hcm/resignation/employee_initiate_resignation/EmployeeInitiateResignation";
import ManagerInitiateResignation from "./hcm/resignation/manager_initiate_resignation/ManagerInitiateResignation";
import ResignationStatusCheck from "./hcm/resignation/resignation_status_check/ResignationStatusCheck";
import ExitClearance from "./hcm/resignation/exit_clearance/ExitClearance";

// Finance --> Claim
import ClaimDashboard from "./finance/claims/ClaimDashboard";
import PayslipDashboard from "./finance/payslips/PayslipDashboard";
import Form16Dashboard from "./finance/form_16/Form16Dashboard";
import AccountsPayableDashboard from "./finance/accounts_payable/AccountsPayableDashboard";
import AccountsReceivableDashboard from "./finance/accounts_receivable/AccountsReceivableDashboard";

import MyProjects from "./dashboard/MyProjects";
import Services from "./pps/services/Services";
import AddService from "./pps/services/AddService";
// import ProjectDetails from "./projects/project_management/ProjectDetails";
import Resources from "./pps/Resources";
import AddResource from "./pps/AddResource";
import Timesheet from "./timesheet/Timesheet";
import TsAdmin from "./techscreening/TsAdmin";
import TsPanel from "./techscreening/TsPanel";
import AddJobs from "./techscreening/AddJobs";
import AddCandidate from "./techscreening/AddCandidate";
import AddPanel from "./techscreening/AddPanel";
import ViewJobs from "./techscreening/ViewJobs";
import ViewCandidates from "./techscreening/ViewCandidates";
import ViewPanel from "./techscreening/ViewPanel";
import CandidateInterview from "./techscreening/CandidateInterview";
import TechReports from "./techscreening/TechReports";
import ViewCandidateCM from "./management/candidateManagement/ViewCandidateCM";
import AddNewCandidate from "./management/candidateManagement/AddNewCandidate";
import ThirdLevelServices from "./sidebar/ThirdLevelServices";
import AuthConfiguration from "./iam/auth_configuration/AuthConfiguration";

// *-------configuration RS --------
import SkillsetRs from "./rs/configuration/skillGroup/SkillGroup";
import Document from "./rs/configuration/documents/documents";
import ExtraCurricular from "./rs/configuration/extraCurricular/extracurricular";
import QualificationRs from "./rs/configuration/qualification/qualification";
import BillingType from "./rs/configuration/billing_Rs/billing";
import LeadRecruiter from "./rs/configuration/lead_recruiter/leadRecruiter";
import HiringStatus from "./rs/configuration/hiring_Status/hiring";
import YearsOfExpirence from "./rs/configuration/Years_of_experience/Experience";
import HiringStage from "./rs/configuration/hiring_Stage/hiring";
import Jobboard from "./rs/configuration/job_Board/job_Board";
import CandidateStatus from "./rs/configuration/candidate_Status/candidate";
import RequirementType from "./rs/configuration/requirement_Type/requirement";
import JobCategory from "./rs/configuration/job_Category/category";
import JobPosition from "./rs/configuration/job_position/job_position";
import NoticePeriod from "./rs/configuration/notice_period/noticePeriod";
import CanCountrtyList from "./rs/configuration/candidate_countries/candidateCountries";
import CanGeoList from "./rs/configuration/can_geo/canGeo";
import RequirementStatus from "./rs/configuration/requirement_status/requirement";
import CandidateSearch from "./rs/candidate/CandidateSearch";
import GoverId from "./rs/configuration/GovtId/govtId";
import CanGrade from "./rs/configuration/canGrade/canGrade";
import GlobalVariable from "./rs/configuration/globalVarible/globalVarible";
import UserVariable from "./rs/configuration/userVariable.js/userVarible";
import JobTiming from "./rs/configuration/job_timings/jobTiming";

// RS-->DASHBOARD ROUTES
import CanTrackingGraph from "./rs/dashboard/insights/canTrackingGraph"
import ManagerInsights from "./rs/dashboard/insights/managerInsights"

// import CandidateSearch  from './rs/candidate/CandidateSearch'
import JobSearch from "./rs/jobs/jobssearch";
import RequirementSearch from "./rs/requirements/requirementsearch";
// import ViewUplodedresume from "./rs/candidate/viewUplodedresume";
import SkillDetail from "./rs/configuration/skill_detail/skillDetail";
// RS--> Manager View import
import ManagerReports from "./rs/manager_reports/ManagerReports";

import MyReports from "./rs/my_reports/myReportsView"

//RS ---> published jobs
import ViewPublishedJobs from "./rs/published_jobs/viewPublishedJobs";

//RS --->Publishing Approval
import PublishingApproval from "./rs/publishingApproval/viewPublishingApproval"

// RS --> Upload --> Requirements
import RequirementsUpload from "./rs/upload/requirements_upload/RequirementsUpload";
import CandidatesUpload from "./rs/upload/candidates_upload/CandidatesUpload";
import CandidateTaggingUpload from "./rs/upload/candidate_tagging_upload/CandidateTaggingUpload";
import TemplateUpload from "./rs/upload/documents/Documents";
import AddResumeUploadCompo  from "./rs/upload/resume_upload/addResumeUpload"

// CRM Routes imports
import CustomerBusinessDomain from "./crm/crm_setup/cust_business_domain/CustomerBusinessDomain";
import CustomerType from "./crm/crm_setup/cust_type/CustomerType";
import AccountStatus from "./crm/crm_setup/cust_account_status/AccountStatus";
import CustomerGeographies from "./crm/crm_setup/customer_geographies/CustomerGeographies";
import CustomerCountries from "./crm/crm_setup/customer_countries/CustomerCountries";
import CustomerCurrency from "./crm/crm_setup/customer_currency/CustomerCurrency";
import CustomerDialingCode from "./crm/crm_setup/customer_dialing_code/CustomerDialingCode";
import ContactDesignation from "./crm/crm_setup/contact_designation/ContactDesignation";
import Department from "./crm/crm_setup/departments/Department";
import ContactWorkingStatus from "./crm/crm_setup/contact_working_status/ContactWorkingStatus";
import DocumentType from "./crm/crm_setup/customer_document_type/DocumentType";
import CrmGlobal from "./crm/crm_setup/crm_global/CrmGlobal";
import CrmUser from "./crm/crm_setup/crm_user/CrmUser";
import AccountManager from "./crm/crm_setup/account_manager/AccountManager";
import SalesTeam from "./crm/crm_setup/sales_team/SalesTeam";

// CRM --> Customer
import Customers from "./crm/customers/Customers";
import AddCustomer from "./crm/customers/AddCustomer";

// CRM --> Customer Entity
import CustomerEntity from "./crm/customer_entity/CustomerEntity";
import AddCustomerEntity from "./crm/customer_entity/AddCustomerEntity";

// CRM --> Accounts
import Accounts from "./crm/accounts/Accounts";
import AddAccount from "./crm/accounts/AddAccount";
// CRM --> Contacts
import Contacts from "./crm/contacts/Contacts";
import AddContact from "./crm/contacts/AddContact";
import govtId from "./rs/configuration/GovtId/govtId";

// Experience 360 --> Requests
import Requests from "./experience_360/requests/Requests";
import AddRequests from "./experience_360/requests/AddRequests";

//  Experience 360 ---> e360 Config
import RequestType from "./experience_360/e360_config/request_type/RequestType";
import RequestStatus from "./experience_360/e360_config/request_status/RequestStatus";
import RequestSeverity from "./experience_360/e360_config/request_severity/RequestSeverity";
import RequestPriority from "./experience_360/e360_config/request_priority/RequestPriority";
import E360Global from "./experience_360/e360_config/e360_global/E360Global";
import E360User from "./experience_360/e360_config/e360_user/E360User";
import ProjectTeam from "./experience_360/e360_config/project_team/ProjectTeam";
import ProjectMember from "./experience_360/e360_config/project_member/ProjectMember";
import ReleaseTag from "./experience_360/e360_config/release_tag/ReleaseTag";
import RequestResolution from "./experience_360/e360_config/request_resolution/RequestResolution";
import KnowledgeType from "./experience_360/e360_config/knowledge_type/KnowledgeType";
import ProductList from "./experience_360/e360_config/product_list/ProductList";

// ------------------------------------------------------------------------------------------------
class Collection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebar: true,
      message: "",
    };
    this.sidebarToggler = this.sidebarToggler.bind(this);
  }

  sidebarToggler() {
    this.setState((prevState) => {
      return {
        sidebar: !prevState.sidebar,
      };
    });
  }

  render() {
    return (
      <div className="nav-pad">
        <Navigation toggle={this.sidebarToggler} />
        <Sidebar show={this.state.sidebar} />

        {/* Third Level Service menu routes */}
        <Route
          exact
          path="/pie/iam/service_menu"
          render={(props) => (
            <ThirdLevelServices {...props} sidebar={this.state.sidebar} />
          )}
        />

        {/* IAM -- Auth Configuration routes */}
        <Route
          exact
          path="/pie/iam/auth_configuration/user_groups"
          render={(props) => (
            <UserGroup {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/iam/auth_configuration/roles"
          render={(props) => <Roles {...props} sidebar={this.state.sidebar} />}
        />
        <Route
          exact
          path="/pie/add/role1"
          render={(props) => (
            <AddRoles1 {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/iam/auth_configuration/menu_management"
          render={(props) => (
            <MenuManagement {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/iam/auth_configuration/access_and_permission"
          render={(props) => (
            <AccessAndPermission {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/iam/auth_configuration/iam_config"
          render={(props) => (
            <IamConfig {...props} sidebar={this.state.sidebar} />
          )}
        />

        {/* IAM --> Onboarding --> Emp Onboarding routes */}
        <Route
          exact
          path="/pie/iam/user_onboarding"
          render={(props) => (
            <Employees {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/iam/user_onboarding/create"
          render={(props) => (
            <AddUser {...props} sidebar={this.state.sidebar} />
          )}
        />

        {/* IAM ---> Onboarding ---> Role Mapping routes */}
        <Route
          exact
          path="/pie/iam/role_mapping"
          render={(props) => (
            <UserRole {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/iam/role_mapping/create"
          render={(props) => (
            <AddUserRole {...props} sidebar={this.state.sidebar} />
          )}
        />

        {/* ----------------------------------------------------------------------------- */}

        {/* HCM --> Business Setup module routes */}

        <Route
          exact
          path="/pie/hcm/business_setup/enterprise_detail"
          render={(props) => (
            <EnterpriseDetail {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/hcm/business_setup/business_geowise"
          render={(props) => (
            <BusinessGeowise {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/hcm/business_setup/business_countries"
          render={(props) => (
            <BusinessCountries {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/hcm/business_setup/business_locations"
          render={(props) => (
            <BusinessLocations {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/hcm/business_setup/business_currency"
          render={(props) => (
            <BusinessCurrency {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/hcm/business_setup/organization"
          render={(props) => (
            <Organization {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/hcm/business_setup/business_unit"
          render={(props) => (
            <BusinessUnit {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/hcm/business_setup/departments"
          render={(props) => (
            <Departments {...props} sidebar={this.state.sidebar} />
          )}
        />

        {/* HCM --> Configurations module routes */}

        <Route
          exact
          path="/pie/hcm/configurations/documents_name"
          render={(props) => (
            <Documents {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/hcm/configurations/emp_relation"
          render={(props) => (
            <EmpRelation {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/hcm/configurations/leave_type"
          render={(props) => (
            <LeaveType {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/hcm/configurations/resignation_status"
          render={(props) => (
            <ResignationStatus {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/hcm/configurations/clearance_status"
          render={(props) => (
            <ClearanceStatus {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/hcm/configurations/emp_position"
          render={(props) => (
            <EmployeePosition {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/hcm/configurations/emp_grade"
          render={(props) => (
            <EmployeeGrade {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/hcm/configurations/resignation_reason"
          render={(props) => (
            <ResignationReason {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/hcm/configurations/revoke_reason"
          render={(props) => (
            <RevokeReason {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/hcm/configurations/emp_status_reason"
          render={(props) => (
            <StatusReason {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/hcm/configurations/emp_type"
          render={(props) => (
            <EmployeeType {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/hcm/configurations/manager_resignation_reason"
          render={(props) => (
            <ManagerResignationReason {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/hcm/configurations/emp_qualification"
          render={(props) => (
            <Qualification {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/hcm/configurations/resignation_type"
          render={(props) => (
            <ResignationType {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/hcm/configurations/dialing_code"
          render={(props) => (
            <DialingCode {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/hcm/configurations/company_holidays"
          render={(props) => (
            <CompanyHolidays {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/hcm/configurations/personal_status"
          render={(props) => (
            <PersonalStatus {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/hcm/configurations/hcm_global_var"
          render={(props) => (
            <HcmGlobalVar {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/hcm/configurations/hcm_user_var"
          render={(props) => (
            <HcmUserVar {...props} sidebar={this.state.sidebar} />
          )}
        />

        {/* HCM --> MyProfile module routes  */}
        <Route
          exact
          path="/pie/hcm/my_profile"
          render={(props) => (
            <MyProfile {...props} sidebar={this.state.sidebar} />
          )}
        />

        {/* HCM --> HR module routes  */}
        <Route
          exact
          path="/pie/hcm/hr/onboarding"
          render={(props) => (
            <HREmployees {...props} sidebar={this.state.sidebar} />
          )}
        />
        {/* HCM  --> HR --> Attendance Service routes */}
        <Route
          exact
          path="/pie/hcm/hr/attendance"
          render={(props) => (
            <Attendance {...props} sidebar={this.state.sidebar} />
          )}
        />

        {/* HCM --> HR --> Policies  */}
        <Route
          exact
          path="/pie/hcm/hr/policies"
          render={(props) => (
            <PolicyPage {...props} sidebar={this.state.sidebar} />
          )}
        />
        {/* HCM --> Onboarding  */}
        <Route
          exact
          path="/pie/hcm/onboarding"
          render={(props) => (
            <HcmOnboarding {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/hcm/onboarding/add"
          render={(props) => (
            <AddHcmOnboarding {...props} sidebar={this.state.sidebar} />
          )}
        />

        {/* HCM --> Employees */}
        <Route
          exact
          path="/pie/hcm/employees"
          render={(props) => (
            <HcmEmployees {...props} sidebar={this.state.sidebar} />
          )}
        />

        {/* HCM --> LMS module routes  */}

        <Route
          exact
          path="/pie/hcm/lms/submit_leave"
          render={(props) => (
            <ApplyLeave {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/hcm/lms/pending_leaves"
          render={(props) => (
            <ViewLeave {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/hcm/lms/leave_balance"
          render={(props) => (
            <LeaveBalance {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/hcm/lms/my_leave_requests"
          render={(props) => (
            <PendingLeave {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/hcm/lms/my_holiday_calendar"
          render={(props) => (
            <MyHolidayCalendar {...props} sidebar={this.state.sidebar} />
          )}
        />

        {/* LMS --> Attendance Report */}
        <Route
          exact
          path="/pie/hcm/lms/attendance_report"
          render={(props) => (
            <AttendanceReport {...props} sidebar={this.state.sidebar} />
          )}
        />

        {/* LMS --> Leave Report */}
        <Route
          exact
          path="/pie/hcm/lms/leave_report"
          render={(props) => (
            <LeaveReport {...props} sidebar={this.state.sidebar} />
          )}
        />

        {/* HCM --> Resignation --> Employee Initiate Resignation */}
        <Route
          exact
          path="/pie/hcm/resignation/emp_initiate_resignation"
          render={(props) => (
            <EmployeeInitiateResignation
              {...props}
              sidebar={this.state.sidebar}
            />
          )}
        />
        <Route
          exact
          path="/pie/hcm/resignation/manager_initiate_resignation"
          render={(props) => (
            <ManagerInitiateResignation
              {...props}
              sidebar={this.state.sidebar}
            />
          )}
        />
        <Route
          exact
          path="/pie/hcm/resignation/resignation_status_check"
          render={(props) => (
            <ResignationStatusCheck {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/hcm/resignation/exit_clearance"
          render={(props) => (
            <ExitClearance {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/profile/edit"
          render={(props) => (
            <EditProfile {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/edit/password"
          render={(props) => (
            <ChangePassword {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/notification"
          render={(props) => (
            <Landing {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie"
          render={(props) => (
            <DashboardInsights {...props} sidebar={this.state.sidebar} />
          )}
        />

        {/* Rs routes */}
        <Route
          exact
          path="/pie/rs/configuration/rs_user_variable"
          render={(props) => (
            <UserVariable {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/rs/configuration/rs_global_variable"
          render={(props) => (
            <GlobalVariable {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/rs/configuration/skill_group"
          render={(props) => (
            <SkillsetRs {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/rs/configuration/qualification"
          render={(props) => (
            <QualificationRs {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/rs/configuration/extracurricular"
          render={(props) => (
            <ExtraCurricular {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/rs/configuration/document"
          render={(props) => (
            <Document {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/rs/configuration/candidate_countries"
          render={(props) => (
            <CanCountrtyList {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/rs/configuration/candidate_geographies"
          render={(props) => (
            <CanGeoList {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/rs/search_candidate"
          render={(props) => {
            return <CandidateSearch {...props} sidebar={this.state.sidebar} />;
          }}
        />
        <Route
          exact
          path="/pie/rs/search_jobs"
          render={(props) => {
            return <JobSearch {...props} sidebar={this.state.sidebar} />;
          }}
        />
        <Route
          exact
          path="/pie/rs/requirements"
          render={(props) => {
            return (
              <RequirementSearch {...props} sidebar={this.state.sidebar} />
            );
          }}
        />

        {/* <Route
          exact
          path="/pie/rs/viewresume"
          render={(props) => {
            return (
              <ViewUplodedresume {...props} sidebar={this.state.sidebar} />
            );
          }}
        ></Route> */}

        <Route
          exact
          path="/pie/rs/configuration/billing_type"
          render={(props) => (
            <BillingType {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/rs/configuration/hiring_status"
          render={(props) => {
            return <HiringStatus {...props} sidebar={this.state.sidebar} />;
          }}
        ></Route>

        <Route
          exact
          path="/pie/rs/configuration/lead_recruiter"
          render={(props) => {
            return <LeadRecruiter {...props} sidebar={this.state.sidebar} />;
          }}
        ></Route>
        <Route
          exact
          path="/pie/rs/configuration/requirement_status"
          render={(props) => {
            return (
              <RequirementStatus {...props} sidebar={this.state.sidebar} />
            );
          }}
        ></Route>
        <Route
          exact
          path="/pie/rs/configuration/years_of_experience"
          render={(props) => {
            return <YearsOfExpirence {...props} sidebar={this.state.sidebar} />;
          }}
        ></Route>
        <Route
          exact
          path="/pie/rs/configuration/hiring_stage"
          render={(props) => {
            return <HiringStage {...props} sidebar={this.state.sidebar} />;
          }}
        ></Route>
        <Route
          exact
          path="/pie/rs/configuration/job_board"
          render={(props) => {
            return <Jobboard {...props} sidebar={this.state.sidebar} />;
          }}
        ></Route>
        <Route
          exact
          path="/pie/rs/configuration/candidate_status"
          render={(props) => {
            return <CandidateStatus {...props} sidebar={this.state.sidebar} />;
          }}
        ></Route>
        <Route
          exact
          path="/pie/rs/configuration/requirement_type"
          render={(props) => {
            return <RequirementType {...props} sidebar={this.state.sidebar} />;
          }}
        ></Route>
        <Route
          exact
          path="/pie/rs/configuration/job_category"
          render={(props) => {
            return <JobCategory {...props} sidebar={this.state.sidebar} />;
          }}
        ></Route>

        <Route
          exact
          path="/pie/rs/configuration/job_position"
          render={(props) => {
            return <JobPosition {...props} sidebar={this.state.sidebar} />;
          }}
        ></Route>

        <Route
          exact
          path="/pie/rs/configuration/notice_period"
          render={(props) => {
            return <NoticePeriod {...props} sidebar={this.state.sidebar} />;
          }}
        ></Route>
        <Route
          exact
          path="/pie/rs/configuration/candidate_skills"
          render={(props) => {
            return <SkillDetail {...props} sidebar={this.state.sidebar} />;
          }}
        ></Route>

        <Route
          exact
          path="/pie/rs/configuration/govt_id"
          render={(props) => {
            return <GoverId {...props} sidebar={this.state.sidebar} />;
          }}
        ></Route>
        <Route
          exact
          path="/pie/rs/configuration/job_timings"
          render={(props) => {
            return <JobTiming {...props} sidebar={this.state.sidebar} />;
          }}
        ></Route>

        <Route
          exact
          path="/pie/rs/configuration/can_grade"
          render={(props) => {
            return <CanGrade {...props} sidebar={this.state.sidebar} />;
          }}
        ></Route>
          {/* RS --> Insights */}
          <Route
          exact
          path="/pie/rs/insights"
          render={(props) => {
            return <CanTrackingGraph {...props} sidebar={this.state.sidebar} />;
          }}
        ></Route>

        <Route
          exact
          path="/pie/rs/manager_insights"
          render={(props) => {
            return <ManagerInsights {...props} sidebar={this.state.sidebar} />;
          }}
        ></Route>

        {/* RS --> Manager View */}
        <Route
          exact
          path="/pie/rs/manager_reports"
          render={(props) => {
            return <ManagerReports {...props} sidebar={this.state.sidebar} />;
          }}
        ></Route>

           <Route
          exact
          path="/pie/rs/my_reports"
          render={(props) => {
            return <MyReports {...props} sidebar={this.state.sidebar} />;
          }}
        ></Route>

        {/* RS --> view published jobs */}
        <Route
          exact
          path="/pie/rs/published_jobs"
          render={(props) => {
            return (
              <ViewPublishedJobs {...props} sidebar={this.state.sidebar} />
            );
          }}
        ></Route>
     {/* //publishing approval// */}
      <Route
          exact
          path="/pie/rs/publishing_approval"
          render={(props) => {
            return (
              <PublishingApproval {...props} sidebar={this.state.sidebar} />
            );
          }}
        ></Route>

        {/* RS --> Upload --> Requirements */}
        <Route
          exact
          path="/pie/rs/upload/requirements"
          render={(props) => {
            return (
              <RequirementsUpload {...props} sidebar={this.state.sidebar} />
            );
          }}
        ></Route>
        <Route
          exact
          path="/pie/rs/upload/candidates"
          render={(props) => {
            return <CandidatesUpload {...props} sidebar={this.state.sidebar} />;
          }}
        ></Route>
        <Route
          exact
          path="/pie/rs/upload/candidate_tagging"
          render={(props) => {
            return (
              <CandidateTaggingUpload {...props} sidebar={this.state.sidebar} />
            );
          }}
        ></Route>

        <Route
          exact
          path="/pie/rs/upload/template_upload"
          render={(props) => {
            return <TemplateUpload {...props} sidebar={this.state.sidebar} />;
          }}
        ></Route>

       <Route
          exact
          path="/pie/rs/upload/resume_upload"
          render={(props) => {
            return <AddResumeUploadCompo  {...props} sidebar={this.state.sidebar} />;
          }}
        ></Route>

        {/* Routes for the CRM */}

        {/* CRM --> CRM Setup Routes */}

        <Route
          exact
          path="/pie/crm/crm_setup/customer_domain"
          render={(props) => (
            <CustomerBusinessDomain {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/crm/crm_setup/customer_type"
          render={(props) => (
            <CustomerType {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/crm/crm_setup/customer_account_status"
          render={(props) => (
            <AccountStatus {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/crm/crm_setup/customer_geographies"
          render={(props) => (
            <CustomerGeographies {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/crm/crm_setup/customer_countries"
          render={(props) => (
            <CustomerCountries {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/crm/crm_setup/customer_currency"
          render={(props) => (
            <CustomerCurrency {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/crm/crm_setup/customer_dialing_code"
          render={(props) => (
            <CustomerDialingCode {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/crm/crm_setup/contact_designation"
          render={(props) => (
            <ContactDesignation {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/crm/crm_setup/departments"
          render={(props) => (
            <Department {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/crm/crm_setup/contact_working_status"
          render={(props) => (
            <ContactWorkingStatus {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/crm/crm_setup/customer_document_type"
          render={(props) => (
            <DocumentType {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/crm/crm_setup/crm_global_var"
          render={(props) => (
            <CrmGlobal {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/crm/crm_setup/crm_user_var"
          render={(props) => (
            <CrmUser {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/crm/crm_setup/account_manager"
          render={(props) => (
            <AccountManager {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/crm/crm_setup/sales_team"
          render={(props) => (
            <SalesTeam {...props} sidebar={this.state.sidebar} />
          )}
        />

        {/* CRM --> Customers  */}
        <Route
          exact
          path="/pie/crm/customers"
          render={(props) => (
            <Customers {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/crm/add/customer"
          render={(props) => (
            <AddCustomer {...props} sidebar={this.state.sidebar} />
          )}
        />

        {/* CRM --> Customer Entity  */}
        <Route
          exact
          path="/pie/crm/customer_entity"
          render={(props) => (
            <CustomerEntity {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/crm/add/customer_entity"
          render={(props) => (
            <AddCustomerEntity {...props} sidebar={this.state.sidebar} />
          )}
        />
        {/* CRM --> Accounts */}
        <Route
          exact
          path="/pie/crm/accounts"
          render={(props) => (
            <Accounts {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/crm/add/account"
          render={(props) => (
            <AddAccount {...props} sidebar={this.state.sidebar} />
          )}
        />
        {/* CRM --> Contacts  */}
        <Route
          exact
          path="/pie/crm/contacts"
          render={(props) => (
            <Contacts {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/crm/add/contact"
          render={(props) => (
            <AddContact {...props} sidebar={this.state.sidebar} />
          )}
        />

        {/* Experience 360 --> Requests  */}
        <Route
          exact
          path="/pie/experience_360/requests"
          render={(props) => (
            <Requests {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/experience_360/requests/add"
          render={(props) => (
            <AddRequests {...props} sidebar={this.state.sidebar} />
          )}
        />

        {/* Experience 360 --> e360 Config  */}
        <Route
          exact
          path="/pie/experience_360/e360_config/request_type"
          render={(props) => (
            <RequestType {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/experience_360/e360_config/request_status"
          render={(props) => (
            <RequestStatus {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/experience_360/e360_config/request_severity"
          render={(props) => (
            <RequestSeverity {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/experience_360/e360_config/request_priority"
          render={(props) => (
            <RequestPriority {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/experience_360/e360_config/e360_global_var"
          render={(props) => (
            <E360Global {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/experience_360/e360_config/e360_user_var"
          render={(props) => (
            <E360User {...props} sidebar={this.state.sidebar} />
          )}
        />

        <Route
          exact
          path="/pie/experience_360/e360_config/project_team"
          render={(props) => (
            <ProjectTeam {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/experience_360/e360_config/project_member"
          render={(props) => (
            <ProjectMember {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/experience_360/e360_config/release_tag"
          render={(props) => (
            <ReleaseTag {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/experience_360/e360_config/request_resolution"
          render={(props) => (
            <RequestResolution {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/experience_360/e360_config/knowledge_type"
          render={(props) => (
            <KnowledgeType {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/experience_360/e360_config/product_list"
          render={(props) => (
            <ProductList {...props} sidebar={this.state.sidebar} />
          )}
        />

        {/* marketing application browser urls */}
        <Route
          exact
          path="/pie/marketing/marketing_insights"
          render={(props) => (
            <MarketingInsights {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/marketing/campaign"
          render={(props) => (
            <Campaign {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/marketing/campaign/add"
          render={(props) => (
            <AddCampaign {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/marketing/inquiries"
          render={(props) => (
            <Inquiries {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/marketing/inquiries/add"
          render={(props) => (
            <AddInquiry {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/marketing/leads"
          render={(props) => (
            <Leads {...props} sidebar={this.state.sidebar} />
          )}
        />
        <Route
          exact
          path="/pie/marketing/lead/add"
          render={(props) => (
            <AddNewLead {...props} sidebar={this.state.sidebar} />
          )}
        />
      </div>
    );
  }
}

export default Collection;
