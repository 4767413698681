import axios from "axios";
import { TEAM_ASSIGNMENT_GET, TEAM_ASSIGNMENT_FETCH } from "../types";
import { CURD_TEAM_ASSIGNMENT } from "../../../utils/routes";
import setSuccessMessage from "../common/setSuccessMessage";
import setErrorMessage from "../common/setErrorMessage";
import getDispatch from "../common/getDispatch";

// -------------------------------------------------------------------------------------------

export const addTeamAssignment = (data, back) => (dispatch) => {
  axios
    .post(CURD_TEAM_ASSIGNMENT, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getTeamAssignment = () => (dispatch) => {
  axios
    .get(CURD_TEAM_ASSIGNMENT, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(TEAM_ASSIGNMENT_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const fetchTeamAssignment = (data) => (dispatch) => {
  axios
    .get(`${CURD_TEAM_ASSIGNMENT}/${data}`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(TEAM_ASSIGNMENT_FETCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateTeamAssignment = (data, back) => (dispatch) => {
  axios
    .put(CURD_TEAM_ASSIGNMENT, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteTeamAssignment = (data) => (dispatch) => {
  axios
    .delete(CURD_TEAM_ASSIGNMENT, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getAccounts));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
