import React, { Component } from 'react'
import { Modal, Button } from "react-bootstrap";
import { Row, Col, Card } from "reactstrap";
import { connect } from "react-redux";
import axios from "axios";
import DateFns from "date-fns";
import { Divider } from "@material-ui/core";

import ConfirmDialog from "../../../../common/confirm-dialog/ConfirmDialog";
import Notifier from "../../../../aside/Notifier";

// import DetailedPageFooter from './DetailsPageViewFooter'
// redux
import {CANDIDATE_ERROR_LOG } from "../../../../../utils/routes"
// import { getTaggedDetails } from "../../../../redux/actions/rs/requirements/requiremntsAction";

// --------------------------------------------------------------------------------

// view assigned hr component



class ViewErrorLog extends Component {
  constructor(props){
    super(props)

  this.state = {
 data:[],
 edit:false,
 message:"",
 uniqueData:null,
 confirmDialog: {
  isOpen: false,
  title: "",
  subTitle: "",
},
  }
  this.getCandidateErrorLog = this.getCandidateErrorLog.bind(this)
//    this.getParticularData = this.getParticularData.bind(this) 
//    this.deleteHandleClick = this.deleteHandleClick.bind(this)
//    this.updateHandleClick = this.updateHandleClick.bind(this)
//    this.closeModelUpdate = this.closeModelUpdate.bind(this)
  }

componentDidMount(){
 this.getCandidateErrorLog()
}

getCandidateErrorLog(){
  axios
  .get(`${CANDIDATE_ERROR_LOG}`, { withCredentials: true })
  .then((res) => {
   this.setState({
     data:res.data
   })
  })
  .catch((err) => {
    console.log(err.message);
  }); 
}

// updateHandleClick(res){
//   this.setState({
//     edit:!this.state.edit,
//     uniqueData:res,
//   })
//   this.getParticularData()
// }

// closeModelUpdate(){
//   this.setState({
//     edit:!this.state.edit,
//   })
//   this.getParticularData()
// }

// deleteHandleClick(id){
//   let data = {
//     id: id,
//   };
 
//   axios
//   .delete(ADD_HR_ASSIGNED_HR,{ data, withCredentials: true })
//   .then((res) => {
//      if(res.data.ok){
//        this.setState({
//          message:res.data.message
//        })
//        setTimeout(()=>{
//          this.setState({
//           message:"" 
//          })
//        },2000)
//      }
//       this.getParticularData()   
// })
//   .catch((err) => {
//     console.log(err.message);
//   });
//   this.setState((prevState) => ({
//     ...prevState.confirmDialog,
//     confirmDialog: {
//       isOpen: false,
//     },
//     uploadOpen:false
//   }));
// }

// setConfirmDialog = (obj) => {
//   this.setState((prevProps) => ({
//     confirmDialog: {
//       ...prevProps.confirmDialog,
//       isOpen: obj.isOpen,
//     },
//   }));
// }

  render() {
  
    return (
      <div>

         <Modal size="xl"  show={this.props.showPopUp}>
        {/* {!this.state.edit && ( */}
          <div>
            <div>
              <i
                data-toggle="tooltip"
                data-placement="top"
                title="Close"
                onClick={this.props.close}
                className="material-icons pointer cancel-button"
              >
                cancel
              </i>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <Modal.Title>View Error Logs</Modal.Title>
            </div>

            <Modal.Body>
              <Row className="mt-3">
                {this.state.data.length > 0 &&
                  this.state.data.map((item) => (
                    <Col md="4" key={item.id} className="employee-list-col">
                      <Card className="p-2 mb-2 shadow pointer">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                         
                          {/* <i
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Delete Assigned HR"
                            style={{ color: "red" }}
                            className="material-icons pointer edit-btn"
                     
                            onClick={() =>
                              this.setState({
                                confirmDialog: {
                                  isOpen: true,
                                  title: "Are you sure to delete this record?",
                                  subTitle: "You can't undo this operation",
                                  onConfirm: () =>
                                  this.deleteHandleClick(item.id),
                                },
                              })
                            }
                         
                          >
                            delete
                          </i> */}
                        </div>
                        <ul className="CustDetails-ul employee-list-ul">
                        
                          <li>
                            <span className="label-sm bold emp-span-label-rs">
                              {" "}
                                Id
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span className="employee-list-span-data-rs">
                                {item.id}
                              </span>
                            </span>
                          </li>
                          <li style={{display:"flex"}}>
                            <span className="label-sm bold emp-span-label-rs">
                              {" "}
                               Candidate Email
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span className="employee-list-span-data-rs">
                                {item.canEmail}
                              </span>
                            </span>
                          </li>

                          <li>
                            <span className="label-sm bold emp-span-label-rs">
                              {" "}
                               Error Message
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span className="employee-list-span-data-rs">
                                {item.errorMessage}
                              </span>
                            </span>
                          </li>
                         
                        </ul>
                        <Divider style ={{marginTop:"0.5rem"}} />
                        {/* <DetailedPageFooter
                          createdBy={item.createdBy}
                          createdOn={item.createdOn}
                          updatedBy={item.updatedBy}
                          updatedOn={item.updatedOn}
                        /> */}
                      </Card>
                    </Col>
                  ))}
              </Row>
            </Modal.Body>
            <Modal.Footer>
          
            </Modal.Footer>
          </div>
        {/* //  )} */}
         {/* {this.state.edit && (
          <div>
            < UpdateAssignedHr
              fetchData={this.state.uniqueData}
              navigate={this.closeModelUpdate}
              requirementId={this.props.requirementId}
            />
          </div>
        )}  */}
      </Modal> 
      {this.state.message && (
            <Notifier message={this.state.message} />
          )}
        <ConfirmDialog
          confirmDialog={this.state.confirmDialog}
          setConfirmDialog={this.setConfirmDialog}
        />
      </div>
    )
  }
}







// const mapStateToProps = (state) => ({
//   requirements: state.requirements,
// });

export default ViewErrorLog

