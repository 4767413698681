import axios from "axios";
import { UPLOAD_CANDIDATE_TAGGING } from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import { SET_MESSAGE, GET_UPLOAD_CAN_TAG_RS_EXTRACTED, GET_UPLOAD_CAN_TAG_RS_PROCESSED, GET_UPLOAD_CAN_TAG_RS_UNPROCESSED  } from "../../types";
import setErrorMessage from "../../common/setErrorMessage";

export const uploadCandidateTagging = (data) => (dispatch) => {
  axios
    .post(UPLOAD_CANDIDATE_TAGGING, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res));
      dispatch({
        type: SET_MESSAGE,
        payload: res.data.message,
      });
      dispatch({
        type:  GET_UPLOAD_CAN_TAG_RS_EXTRACTED,
        payload: res.data.extracted,
      });
      dispatch({
        type:GET_UPLOAD_CAN_TAG_RS_PROCESSED ,
        payload: res.data.processed,
      });
      dispatch({
        type:GET_UPLOAD_CAN_TAG_RS_UNPROCESSED,
        payload: res.data.unprocessed,
      });
      setTimeout(() => {
        dispatch({
          type: SET_MESSAGE,
          payload: "",
          

        });
       
    
       
      }, 2000);

    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
