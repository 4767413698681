import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter, Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Card, Form, FormText, FormGroup, Input } from "reactstrap";
import FormComponent from "../../common/FormComponent";
import logo from "../../../assets/img/pionlogo.png";
import { Validator, submitValidator, valid } from "../../common/Validator";
import { login } from "../../../redux/actions/iam/authentication/authenticationAndAuthorizationAction";
import ErrorNotifier from "../../aside/ErrorNotifier";
import { empIsLoggedIn } from "../../../redux/actions/iam/authentication/authenticationAndAuthorizationAction";

import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../../../utils/config";

// -------------------------------------------------------------------------------------------------------------

class EmployeeLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      empEmail: "",
      Epassword: "",
      selection: null,
      token: "",
      error: "",
      authenticateError: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    e.preventDefault();
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
      authenticateError: "",
    });
  }

  // onChangeToken function to set the token value
  onChangeToken = (token) => {
    this.setState({
      token: token,
    });
  };

  // resetToken function to reset the token value
  resetToken = () => {
    this.setState({
      token: "",
    });
  };

  select(item) {
    this.setState({
      selection: item,
    });
  }

  /**
   * onSubmit function for submitting the user entered value to the database.
   * @param {object} e submit validation
   */
  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);

    let data = {
      email: this.state.empEmail,
      password: this.state.Epassword,
      // token: this.state.token
    };
    this.props.login(data, this.props.history);
  }

  componentDidMount() {
    window.addEventListener("unload", function () {
      localStorage.clear();
    });

    this.props.empIsLoggedIn();
  }

  /**
   * componentWillUnmount to clear the subscriptions.
   */
  componentWillUnmount() {
    window.removeEventListener("unload", function () {
      localStorage.clear();
    });
  }

  render() {
    console.log(this.props.auth.isLoggedIn);
    const { authenticateError } = this.state;
    if (this.props.auth.isLoggedIn) {
      return <Redirect to="/pie" />;
    }

    return (
      <div className="container-fluid p-m-reset">
        <div className="row p-m-reset">
          <div className="col-12 mx-auto p-m-reset">
            <div className="login-flex-div">
              <div className="login-flex-div-one">
                <div id="employee-login-logo">
                  <img src={logo} height="60" alt="PION GLOBAL" id="logo" />
                </div>
                <div id="employee-login-h1">
                  <h1>
                    <span style={{ color: "blue", fontWeight: "bold" }}>P</span>
                    <span style={{ color: "red", fontWeight: "bold" }}>i</span>
                    <span style={{ color: "green", fontWeight: "bold" }}>
                      E
                    </span>
                  </h1>
                </div>
                <div className="login-heading-div-1">
                  <h4
                    className="login-heading"
                    style={{ marginBottom: "30px", color: "green" }}
                  >
                    Welcome to Pion Intelligent Enterprise ...!
                  </h4>
                  <h4
                    className="login-heading"
                    style={{ marginBottom: "60px", color: "red" }}
                  >
                    An Intelligent Platform to Manage the Business ...
                  </h4>
                  <div style={{ width: "80%" }}>
                    <p
                      style={{
                        textAlign: "justify",
                        fontSize: "16px",
                        color: "blue",
                      }}
                    >
                      Our <span className="p-logo">PiE</span> platform has
                      helped us to improve the way we work and brought the
                      digital experience. Many of the operational and business
                      process we carry out every day are now automated through
                      intelligence process resulting in saving time and money.
                      This product aims to share some of solutions we have built
                      that have helped us in our business.
                    </p>
                    <p className="login-footer">
                      Contact us :{" "}
                      <a
                        href="https://info@pionglobal.com"
                        style={{ color: "blue" }}
                      >
                        info@pionglobal.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="login-flex-div-two">
                <Card className="login-card center">
                  <div className="center login-icon-wrap">
                    <i className="material-icons login-icon">account_circle</i>
                  </div>
                  <h4 className="center blue-text">Login</h4>
                  <Form onSubmit={this.onSubmit} className="login-form">
                    <FormComponent
                      type="email"
                      name="empEmail"
                      style={{ width: "50%", marginLeft: "24%" }}
                      placeholder="Email"
                      value={this.state.empEmail}
                      change={this.onChange}
                      required
                    />

                    <FormGroup>
                      <Input
                        type="password"
                        name="Epassword"
                        style={{ width: "50%", marginLeft: "24%" }}
                        placeholder="Password"
                        value={this.state.Epassword}
                        onChange={this.onChange}
                      />
                    </FormGroup>
                    {/* <div
                      className="mx-auto"
                      style={{ width: "304px", marginBottom: "1rem" }}
                    >
                      <ReCAPTCHA
                        ref={this.reCaptcha}
                        sitekey={RECAPTCHA_SITE_KEY}
                        onChange={this.onChangeToken}
                        onErrored={this.resetToken}
                      />
                    </div> */}
                    <button
                      type="submit"
                      className="btn login-button white-text"
                    >
                      Login
                    </button>
                    <FormText className="error">{authenticateError}</FormText>
                  </Form>
                  <Link to="/forgotpassword">
                    <div className="center blue-text pointer">
                      Forgot password?
                    </div>
                  </Link>
                </Card>
              </div>
            </div>
          </div>
        </div>
        {this.props.auth.error && (
          <ErrorNotifier message={this.props.auth.error} />
        )}
      </div>
    );
  }
}

EmployeeLogin.propTypes = {
  errors: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  auth: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
    errors: state.errors,
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {
  login,
  empIsLoggedIn,
})(withRouter(EmployeeLogin));
