import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import { connect } from "react-redux";
import FormComponent from "../../../../common/FormComponent";
import { errors, valid } from "../../../../common/Validator";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import { managerUpdateEmployeeClaim } from "../../../../../redux/actions/finance/claims/employeeClaimAction";

/**
 * @class ApprovalModal
 **/

class ApprovalModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: this.props.status,
      remarks: "",
    };
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    let data = {
      empClaimId: this.props.primaryId,
      claimStatus: this.state.status,
      remarks: this.state.remarks,
    };

    if (valid) {
      this.props.managerUpdateEmployeeClaim(data, this.props.closeModal);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  };

  render() {
    const { status, remarks } = this.state;

    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.openModal}
          centered
          style={{ marginLeft: "530px" }}
        >
          <ModalHeader toggle={this.props.closeModal}>
            Approve Claim
          </ModalHeader>
          <ModalBody>
            <Form className="p-3" onSubmit={this.onSubmit} noValidate>
              <FormGroup>
                <Label className="label-sm bold">Claim Status</Label>
                <Input
                  type="select"
                  name="status"
                  value={status}
                  className="form-control-sm"
                  onChange={this.onChange}
                  required={true}
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {this.props.claimStatus.data.map((item) => {
                    return (
                      <option
                        key={item.claimStatusId}
                        value={item.claimStatusName}
                      >
                        {item.claimStatusName}
                      </option>
                    );
                  })}
                </Input>
                {errors.claimStatus && (
                  <FormText className="error">{errors.claimStatus}</FormText>
                )}
              </FormGroup>
              <FormComponent
                labelClass="bold"
                label="Manager Claim Remarks"
                type="textarea"
                name="remarks"
                change={this.onChange}
                value={remarks}
                required={true}
              />
              <FormGroup className="center">
                <button type="submit" className="btn login-button white-text">
                  Submit
                </button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
        {this.props.employeeClaim.error && (
          <ErrorNotifier message={this.props.employeeClaim.error} />
        )}
        {this.props.employeeClaim.message && (
          <Notifier message={this.props.employeeClaim.message} />
        )}
      </React.Fragment>
    );
  }
}

ApprovalModal.propTypes = {
  managerUpdateEmployeeClaim: PropTypes.func.isRequired,
  employeeClaim: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  employeeClaim: state.employeeClaim,
});

export default connect(mapStateToProps, { managerUpdateEmployeeClaim })(
  ApprovalModal
);
