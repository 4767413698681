import React, { Component } from "react";
import axios from "axios";
import { Table, Container } from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// components
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
// redux
import { fetchLeaveManagement } from "../../../../redux/actions/hcm/lms/leaveManagementAction";

// -----------------------------------------------------------------------------------------------------
class LeaveBalance extends Component {
  componentDidMount() {
    const data = {
      id: 2,
      leaveStatus: "Approved",
    };

    this.props.fetchLeaveManagement(data);
  }

  render() {
    let ExtraLeave = 0;
    let LeaveBalance = 0;
    if (this.props.leaveManagement.fetchData > 18) {
      ExtraLeave = this.props.leaveManagement.fetchData - 18;
    } else {
      LeaveBalance = 18 - this.props.leaveManagement.fetchData;
    }

    let shrink = this.props.sidebar ? "scale" : "no-scale";

    return (
      <div className={shrink}>
        <Container className="card container-card pt-2">
          <Link
            to={{
              pathname: "/pie/iam/service_menu",
              moduleName: this.props.location.moduleName,
              data: this.props.location.data,
            }}
          >
            <i className="material-icons cancel-button pointer">cancel</i>
          </Link>
          <h5 className="bold center pt-2">Leave Balance</h5>
          <Table hover className="platform-hcmsetup-companyholiday-table">
            <thead>
              <tr>
                <th className="opp-head">Total Leave Eligible</th>
                <th className="opp-head">Leave Availed</th>
                <th className="opp-head">Pending for Approval</th>
                <th className="opp-head">Leave Balance</th>
              </tr>
            </thead>
            <tbody></tbody>
          </Table>
          {this.props.leaveManagement.message && (
            <Notifier message={this.props.leaveManagement.message} />
          )}
          {this.props.leaveManagement.error && (
            <ErrorNotifier message={this.props.leaveManagement.error} />
          )}
        </Container>
      </div>
    );
  }
}

LeaveBalance.propTypes = {
  fetchLeaveManagement: PropTypes.func.isRequired,
  leaveManagement: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  leaveManagement: state.leaveManagement,
});

export default connect(mapStateToProps, { fetchLeaveManagement })(LeaveBalance);
