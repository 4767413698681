import React, { Component } from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// components
import LoginNavigation from "./auth/LoginNavigation";
import EmployeeLogin from "./auth/EmployeeLogin";
import Forgotpassword from "./auth/Forgotpassword";
import ForgetpassChange from "./auth/ForgetpassChange";
import CreateAccount from "./auth/CreateAccount";
// redux
import { empIsLoggedIn } from "../../redux/actions/iam/authentication/authenticationAndAuthorizationAction";

class Index extends Component {
  componentDidMount() {
    this.props.empIsLoggedIn();
  }
  render() {
    return (
      <div>
        <Route exact path="/" component={EmployeeLogin} />
        <Route exact path="/forgotpassword" component={Forgotpassword} />
        <Route exact path="/forgotpassword" component={LoginNavigation} />
        <Route exact path="/forgetpassChange" component={LoginNavigation} />
        <Route exact path="/forgetpassChange" component={ForgetpassChange} />
        <Route exact path="/create_account" component={CreateAccount} />
      </div>
    );
  }
}

Index.propTypes = {
  auth: PropTypes.object.isRequired,
  empIsLoggedIn: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { empIsLoggedIn })(Index);
