/* eslint-disable */
import React, { Component } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Details from "./Details";

import {
  getSales,
  updateSales,
  searchSales,
} from "../../../../redux/actions/salesActions";
import { refresh } from "../../../common/Validator";
import UpdateFormCM from "./UpdateFormCM";
import {
  SEARCH_MAINTAIN,
  SELECT_MAINTAIN,
  UPDATE_MAINTAIN,
} from "../../../../utils/routes";
import Hover from "../../../aside/Hover";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";

class ViewCandidateCM extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      permissionlist: this.props.auth.permissionlist || {},
      subItem: {},
      selection: {},
      selected: {},
      searchTerm: "",
      hover: false,
      cords: {},
      mouse: {},
      modal: false,
      remarks: "",
      update: false,
      popup: false,
      message: null,
      error: null,
      redirect: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.select = this.select.bind(this);
    this.back = this.back.bind(this);
    this.search = this.search.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.toggleHoverOn = this.toggleHoverOn.bind(this);
    this.toggleHoverOff = this.toggleHoverOff.bind(this);
    this.beforeUpdate = this.beforeUpdate.bind(this);
    this.afterUpdate = this.afterUpdate.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.getData = this.getData.bind(this);
    this.toggle1 = this.toggle1.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.auth !== this.props.auth) {
      this.setState({
        permissionlist: this.props.auth.permissionlist,
      });
    }
  }
  search(e) {
    e.preventDefault();
    this.setState({
      searchTerm: e.target.value,
    });
    this.onSearch(e.target.value);
  }

  // Searching based on candId and candName
  // where candId is number and candName is string
  onSearch(value) {
    let data = {};
    let isInteger = value.length && /^[0-9]*$/.test(value);
    if (isInteger) {
      data.candId = Number(value);
      data.candName = "null";
    } else {
      data.candId = 0;
      data.candName = value;
    }

    axios
      .post(SEARCH_MAINTAIN, data, { withCredentials: true })
      .then((res) => {
        this.setState({
          data: res.data,
        });
      })
      .catch((err) => {});
  }

  getData() {
    axios
      .get(SELECT_MAINTAIN, { withCredentials: true })
      .then((res) => {
        this.setState({
          data: res.data,
        });
      })
      .catch((err) => {});
    refresh();
    this.setState({
      permissionlist: this.props.auth.permissionlist,
    });
  }

  componentDidMount() {
    this.getData();
  }

  onChange(e) {
    e.preventDefault();
    this.setState({
      remarks: e.target.value,
    });
  }
  onSubmit(e) {
    e.preventDefault();
    const data = this.state.data.filter((item) => {
      if (item.candId === this.state.subItem) {
        return item;
      }
    });

    const dragCandidate = {
      candId: data[0].candId,
      jobId: data[0].jobId,
      candName: data[0].candName,
      email: data[0].email,
      phone: data[0].Phone,
      status: data[0].status,
      comments: this.state.remarks,
    };
    axios
      .post(UPDATE_MAINTAIN, dragCandidate, { withCredentials: true })
      .then((res) => {
        this.setState({
          message: res.data,
        });
        this.toggle();
      });

    setTimeout(() => {
      this.setState({
        message: "",
        error: "",
      });
    }, 5000);
  }

  toggleHoverOn(e, item) {
    this.setState({
      hover: true,
      cords: {
        one: item.jobId,
        two: item.candName,
        three: item.comments,
      },
      mouse: {
        x: e.screenX,
        y: e.screenY,
      },
    });
  }
  toggleHoverOff() {
    this.setState({
      hover: false,
    });
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  /*
Toggle1 fuction for model cancel button here called the getData function
*/
  toggle1() {
    this.setState({
      modal: !this.state.modal,
    });
    this.getData();
  }
  select(item) {
    this.setState({
      selection: item,
      selected: item.candId,
      popup: true,
    });
  }
  beforeUpdate() {
    this.setState({
      update: true,
    });
  }
  afterUpdate() {
    this.setState({
      update: false,
    });
    this.getData();
    refresh();
  }
  back() {
    this.setState({
      popup: false,
    });
  }
  onDragOver(e) {
    e.preventDefault();
  }
  onDragStart(e, item) {
    e.dataTransfer.setData("item", item.candId);
    this.setState({
      subItem: item.candId,
    });
  }
  async onDrop(e, cat) {
    let id = e.dataTransfer.getData("item");
    let newData;
    newData = this.state.data.filter((item) => {
      if (item.candId === parseInt(id, 10)) {
        item.status = cat;
      }
      return newData;
    });
    this.setState({
      ...this.state,
      newData,
    });
    await this.toggle();
  }

  // Render function for view candidate
  render() {
    let shrink;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");
    const {
      data,
      selection,
      selected,
      hover,
      cords,
      modal,
      remarks,
      popup,
      update,
      permissionlist,
    } = this.state;
    let currentPermission;

    // checking permission for groupId 4.7
    for (let x in permissionlist.response.permissions) {
      if (permissionlist.response.permissions[x].groupId === "4.7") {
        currentPermission = permissionlist.response.permissions[x];
      } else {
      }
    }

    return (
      <div className={shrink}>
        {popup ? (
          <Details
            detail={selection}
            update={this.beforeUpdate}
            back={this.back}
          />
        ) : null}
        <Modal isOpen={modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle1}>Confirm changes?</ModalHeader>
          <ModalBody>
            <Form className="p-3" onSubmit={this.onSubmit}>
              <FormGroup>
                <Label className="bold label-sm">Remarks</Label>
                <Input
                  name="remarks"
                  onChange={this.onChange}
                  value={remarks}
                  required={true}
                />
              </FormGroup>
              <FormGroup className="center">
                <button type="submit" className="btn login-button white-text">
                  Submit
                </button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
        {!update ? (
          <Container className="card container-card">
            <input
              type="text"
              onChange={this.search}
              value={this.state.searchTerm}
              className="searchfield form-control-sm"
              placeholder="CandId/Name"
            />
            {hover && (
              <Hover
                labels={{ one: "Job ID", two: "Name", three: "Remarks" }}
                cords={cords}
                mouse={this.state.mouse}
              />
            )}
            {currentPermission.create1 ? (
              <div>
                <Link to="/pie/Add/candidate">
                  <button className="btn add-button label-sm white-text">
                    Add
                  </button>
                </Link>
              </div>
            ) : null}
            <h5 className="bold center p-3">Candidate Management</h5>
            <Row className="p-1">
              <Col
                md="4"
                onDragOver={this.onDragOver}
                onDrop={(e) => this.onDrop(e, "NEW")}
                className="card p-2"
              >
                <h6 className="center bold m-2">New</h6>
                {data &&
                  data.map((item) => {
                    if (item.status === "NEW") {
                      return (
                        <ul
                          key={item.candId}
                          onClick={() => {
                            this.select(item);
                          }}
                          onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                          onMouseLeave={this.toggleHoverOff}
                          draggable
                          onDragStart={(e) => this.onDragStart(e, item)}
                          className={`list flex-around pointer ${
                            selected &&
                            selected === item.candId &&
                            "selected-item"
                          }`}
                        >
                          <li className="list-item pad">{item.candId}</li>
                          <li className="list-item flex-1">{item.candName}</li>
                        </ul>
                      );
                    } else {
                      return null;
                    }
                  })}
              </Col>
              <Col
                md="4"
                onDragOver={this.onDragOver}
                onDrop={(e) => this.onDrop(e, "WIP")}
                className="card p-2"
              >
                <h6 className="center bold m-2">Work in progress</h6>
                {data &&
                  data.map((item) => {
                    if (item.status === "WIP") {
                      return (
                        <ul
                          key={item.candId}
                          onClick={() => {
                            this.select(item);
                          }}
                          onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                          onMouseLeave={this.toggleHoverOff}
                          draggable
                          onDragStart={(e) => this.onDragStart(e, item)}
                          className={`list flex-around pointer ${
                            selected &&
                            selected === item.candId &&
                            "selected-item"
                          }`}
                        >
                          <li className="list-item pad">{item.candId}</li>
                          <li className="list-item flex-1">{item.candName}</li>
                        </ul>
                      );
                    } else {
                      return null;
                    }
                  })}
              </Col>
              <Col
                md="4"
                className="card p-2"
                onDrop={(e) => this.onDrop(e, "COMPLETED")}
                onDragOver={this.onDragOver}
              >
                <h6 className="center bold m-2">Completed</h6>
                {data &&
                  data.map((item) => {
                    if (item.status !== "NEW" && item.status !== "WIP") {
                      return (
                        <ul
                          key={item.candId}
                          onClick={() => {
                            this.select(item);
                          }}
                          onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                          onMouseLeave={this.toggleHoverOff}
                          draggable
                          onDragStart={(e) => this.onDragStart(e, item)}
                          className={`list flex-around pointer ${
                            selected &&
                            selected === item.candId &&
                            "selected-item"
                          } ${item.status === "Won" && "completed-item"}`}
                        >
                          <li className="list-item pad">{item.candId}</li>
                          <li className="list-item flex-1">{item.candName}</li>
                        </ul>
                      );
                    } else {
                      return null;
                    }
                  })}
              </Col>
            </Row>
          </Container>
        ) : (
          <UpdateFormCM item={selection} back={this.afterUpdate} />
        )}
        {this.state.message && <Notifier message={this.state.message} />}
        {this.state.error && <ErrorNotifier message={this.state.error} />}
      </div>
    );
  }
}

ViewCandidateCM.propTypes = {
  getSales: PropTypes.func.isRequired,
  updateSales: PropTypes.func.isRequired,
  searchSales: PropTypes.func.isRequired,
  sales: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sales: state.sales,
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { getSales, updateSales, searchSales })(
  ViewCandidateCM
);
