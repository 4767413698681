import axios from "axios";
import {
  CRM_CONTACTS_GET,
  CRM_CONTACTS_NAME_GET,
  CRM_CONTACTS_FETCH,
  CRM_CONTACTS_SEARCH,
} from "../../types";
import {
  CURD_CRM_CONTACTS,
  SEARCH_CRM_CONTACTS,
  GET_CRM_CONTACTS_NAME,
  GET_CRM_RS_CONTACT_NAME,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

import { getCustomerEntitySpocName } from "../customer_entity/customerEntityAction";
import { getAccountsSpocName } from "../accounts/accountsAction";

export const addContacts = (data, history) => (dispatch) => {
  const link = "/pie/crm/contacts";
  axios
    .post(CURD_CRM_CONTACTS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, null, history, link));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const addDialogContacts =
  (data, back, customerName, entityName, whichSpoc) => (dispatch) => {
    axios
      .post(CURD_CRM_CONTACTS, data, { withCredentials: true })
      .then((res) => {
        dispatch(setSuccessMessage(res, back, getContactsName));
        if (whichSpoc === "entity") {
          dispatch(getCustomerEntitySpocName(customerName));
        } else if (whichSpoc === "account") {
          dispatch(getAccountsSpocName(customerName, entityName));
        }
      })
      .catch((err) => {
        dispatch(setErrorMessage(err));
      });
  };

export const searchContacts = (data) => (dispatch) => {
  axios
    .post(SEARCH_CRM_CONTACTS, data, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CRM_CONTACTS_SEARCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getContacts = () => (dispatch) => {
  axios
    .get(CURD_CRM_CONTACTS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CRM_CONTACTS_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getContactsName = () => (dispatch) => {
  axios
    .get(GET_CRM_CONTACTS_NAME, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CRM_CONTACTS_NAME_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getContactsRSName = (data) => (dispatch) => {
  axios
    .get(`${GET_CRM_RS_CONTACT_NAME}/${data}`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CRM_CONTACTS_NAME_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const fetchContacts = (data) => (dispatch) => {
  axios
    .get(`${CURD_CRM_CONTACTS}/${data}`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CRM_CONTACTS_FETCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateContacts = (data, back) => (dispatch) => {
  axios
    .put(CURD_CRM_CONTACTS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteContacts = (data) => (dispatch) => {
  axios
    .delete(CURD_CRM_CONTACTS, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getContacts));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
