import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import axios from "axios";
import { DOWNLOAD_MAINTAIN } from "../../../../utils/routes";

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permissionlist: this.props.auth.permissionlist || {},
      url1: "",
    };
    this.updateNow = this.updateNow.bind(this);
    this.getReport = this.getReport.bind(this);
    // this.viewReport = this.viewReport.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.auth !== this.props.auth) {
      this.setState({
        permissionlist: this.props.auth.permisssionlist,
      });
    }
  }
  updateNow() {
    this.props.update();
    this.props.back();
  }

  getReport() {
    const data = {
      candId: this.props.detail.candId,
    };

    // Code for downloading the file in candidate maintain page
    axios
      .post(DOWNLOAD_MAINTAIN, data, {
        responseType: "blob",
        withCredentials: true,
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", this.props.detail.resume);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        this.setState({
          message: "Failed fetching data",
        });
        setTimeout(() => {
          this.setState({
            message: "",
          });
        }, 3000);
      });
  }

  /*This function for viewing the candidate resumes
   */
  // viewReport() {
  //   const data = {
  //     candId: this.props.detail.candId,
  //   };

  /*
Code for downloading the file in candidate maintain page
After getting the response convert into blob type using the Blob object
Then blob data converted into the url the file type is application/pdf
*/
  //   axios.post(`${API}/maintain/download`, data, {responseType: 'blob', withCredentials: true})
  //     .then(res => {
  //       this.setState({
  //          url1 : window.URL.createObjectURL(new Blob([res.data]))
  //       })
  //     })
  //     .catch(err => {
  //       this.setState({
  //         message: 'Failed fetching data'
  //       })
  //       setTimeout(() => {
  //         this.setState({
  //           message: ''
  //         })
  //       }, 3000)
  //     })
  // }

  // Getting the access and permission from redux
  componentDidMount() {
    this.setState({
      permissionlist: this.props.auth.permissionlist,
    });
  }

  // render for candidate details page
  render() {
    const { permissionlist } = this.state;
    let currentPermission;

    // Getting the permission for  groupId 2.3

    for (let x in permissionlist.response.permissions) {
      if (permissionlist.response.permissions[x].groupId === "2.3") {
        currentPermission = permissionlist.response.permissions[x];
      }
    }
    return (
      <div className="project-details p-3">
        <i
          className="material-icons pointer"
          style={{
            position: "absolute",
            top: "5px",
            right: "5px",
            color: "#C00",
          }}
          onClick={this.props.back}
        >
          cancel
        </i>
        {/* {!this.state.url1 ? */}
        <div>
          <h5 className="center">Candidate Details</h5>
          <hr />
          <Row>
            <Col md="6">
              <ul style={{ listStyle: "none" }}>
                <li>
                  <span className="label-sm bold grouped-item">
                    Candidate Id
                  </span>
                  <span className="label-sm grouped-item">
                    {this.props.detail.candId}
                  </span>
                </li>
                <li>
                  <span className="label-sm bold grouped-item">
                    Candidate Name
                  </span>
                  <span className="label-sm grouped-item">
                    {this.props.detail.candName}
                  </span>
                </li>
                <li>
                  <span className="label-sm bold grouped-item">Job Id</span>
                  <span className="label-sm grouped-item">
                    {this.props.detail.jobId}
                  </span>
                </li>
                <li>
                  <span className="label-sm bold grouped-item">Status</span>
                  <span className="label-sm grouped-item">
                    {this.props.detail.status}
                  </span>
                </li>
              </ul>
            </Col>
            <Col md="6">
              <ul style={{ listStyle: "none" }}>
                <li>
                  <span className="label-sm bold grouped-item">Email</span>
                  <span className="label-sm grouped-item">
                    {this.props.detail.email}
                  </span>
                </li>
                <li>
                  <span className="label-sm bold grouped-item">
                    Phone Number
                  </span>
                  <span className="label-sm grouped-item">
                    {this.props.detail.phone}
                  </span>
                </li>
                <li>
                  <span className="label-sm bold grouped-item">Comments</span>
                  <span className="label-sm grouped-item">
                    {this.props.detail.comments}
                  </span>
                </li>
              </ul>
            </Col>
          </Row>
          <div className="div">
            <hr />
            {currentPermission.update1 ? (
              <div className="center">
                <button
                  className="btn login-button white-text"
                  onClick={this.updateNow}
                >
                  Update
                </button>
              </div>
            ) : null}
            {/* <div >
          <button className="btn login-button white-text" onClick={this.viewReport} id='viewResume'>View Resume</button>
        </div> */}
            <div>
              <button
                className="btn login-button white-text"
                onClick={this.getReport}
                style={{ float: "right", marginBottom: "5px" }}
              >
                Download Resume
              </button>
            </div>
          </div>
        </div>
        {/* //  : <ResumeViewer URL={this.state.url1} /> } */}
      </div>
    );
  }
}

Details.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(Details);
