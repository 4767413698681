import React, { Component } from "react";
import axios from "axios";
import {
  UPLOAD_SERVICE_DOCUMENTS,
  UPDATE_SERVICE_DOCUMENTS,
  DELETE_SERVICE_DOCUMENTS,
  DOWNLOAD_SERVICE_DOCUMENTS,
  FETCH_SERVICE_DOCUMENTS,
} from "../../../../utils/routes";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";

class DocumentUpload extends Component {
  constructor() {
    super();
    this.state = {
      file: [],
      docs: [],
      name: "",
      message: "",
      error: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getData = this.getData.bind(this);
    this.getDocs = this.getDocs.bind(this);
    this.deleteDoc = this.deleteDoc.bind(this);
  }

  onChange(e) {
    this.setState({
      file: e.target.files[0],
      name: e.target.files[0].name,
    });
  }

  onSubmit() {
    let file = this.state.file;
    let fd = new FormData();
    fd.append("serviceDoc", file);

    axios
      .post(UPLOAD_SERVICE_DOCUMENTS, fd, { withCredentials: true })
      .then((res) => {
        const data = {
          serviceDocId: res.data.serviceDocId,
          serviceId: this.props.id,
        };

        axios
          .post(UPDATE_SERVICE_DOCUMENTS, data, { withCredentials: true })
          .then((res) => {
            this.setState({
              message: "Attachment added successfully",
            });
            setTimeout(() => {
              this.setState({ message: "" });
              this.getData();
            }, 2000);
          })
          .catch((err) => {
            this.setState({ error: err.message });
            setTimeout(() => {
              this.setState({ error: "" });
            }, 2000);
          });
      })
      .catch((err) => {
        this.setState({ error: err.message });
      });
    setTimeout(() => {
      this.setState({ error: "" });
    }, 2000);
  }

  getData() {
    const data = {
      serviceId: this.props.id,
    };
    axios
      .post(FETCH_SERVICE_DOCUMENTS, data, { withCredentials: true })
      .then((res) => {
        this.setState({
          docs: res.data,
        });
      });
  }

  componentDidMount() {
    this.getData();
  }

  getDocs(file) {
    const data = {
      serviceDocId: file.serviceDocId,
    };
    axios
      .post(DOWNLOAD_SERVICE_DOCUMENTS, data, {
        responseType: "blob",
        withCredentials: true,
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file.serviceName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        this.setState({
          error: err.message,
        });
      });
    setTimeout(() => {
      this.setState({
        message: "",
        error: "",
      });
    }, 2000);
  }

  deleteDoc(file) {
    const data = {
      serviceDocId: file.serviceDocId,
    };

    axios
      .post(DELETE_SERVICE_DOCUMENTS, data, { withCredentials: true })
      .then((res) => {
        this.setState({
          message: "Attachment deleted successfully",
        });
        setTimeout(() => {
          this.setState({ message: "" });
        }, 2000);
        this.getData();
      })
      .catch((err) => {
        this.setState({
          error: err.message,
        });
        setTimeout(() => {
          this.setState({ error: "" });
        }, 2000);
      });
  }

  render() {
    return (
      <div className="center">
        <hr />
        <h6 className="center bold">Service Document Attachments</h6>
        {this.state.docs &&
          this.state.docs.map((file) => {
            return (
              <span
                key={file.serviceDocId}
                className="m-3 card document-card pointer"
              >
                <i
                  className="material-icons cancel-button pointer"
                  onClick={() => this.deleteDoc(file)}
                >
                  cancel
                </i>
                <p
                  onClick={() => this.getDocs(file)}
                  className="bold label-sm p-2"
                >
                  {file.serviceName}
                </p>
              </span>
            );
          })}

        <hr />
        <label htmlFor="file" className="bold label-sm">
          Add new attachment :
        </label>
        <input
          type="file"
          name="file"
          className="form-control-sm"
          style={{ fontSize: "11px" }}
          onChange={this.onChange}
          accept=".pdf, .png"
        />
        <input
          type="Submit"
          value="Upload"
          style={{ fontSize: "10px" }}
          onClick={this.onSubmit}
          readOnly
        />
        <hr />
        {this.state.message && <Notifier message={this.state.message} />}
        {this.state.error && <ErrorNotifier message={this.state.error} />}
      </div>
    );
  }
}
export default DocumentUpload;
