import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import {
  Validator,
  submitValidator,
  errors,
  valid,
  refresh,
} from "../../../common/Validator";
import { ADD_CAREERS } from "../../../../utils/routes";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";

class AddJob extends Component {
  constructor() {
    super();
    this.state = {
      domain: "Technology",
      title: "",
      desc: "",
      skills: "",
      exp: "",
      location: "Bengaluru",
      vac: "",
      area: "",
      message: null,
      error: null,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    e.preventDefault();
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let job = {
      jobRole: this.state.title,
      jobDesc: this.state.desc,
      positions: this.state.vac,
      domain: this.state.domain,
      area: this.state.area,
      location: this.state.location,
      skills: this.state.skills,
      experience: this.state.exp,
    };

    // Sending data from frontend to add job
    if (valid) {
      axios
        .post(ADD_CAREERS, job, { withCredentials: true })
        .then((res) => {
          this.setState({
            message: res.data,
          });
          setTimeout(() => {
            this.props.history.push("/pie/jobPosting");
          }, 2000);
        })
        .catch((err) => {
          this.setState({
            error: "Could not update",
          });
        });
      setTimeout(() => {
        this.setState({
          message: null,
          error: null,
        });
      }, 2000);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: null,
        });
      }, 2000);
    }
  }

  /*
componentDidMount fuction is contains the form validation refresh method
*/
  componentDidMount() {
    refresh();
  }

  // render for Add job page
  render() {
    let shrink;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");

    return (
      <div className={shrink}>
        <h5 className="center bold">Add New Job</h5>
        <Container className="container-card" style={{ position: "relative" }}>
          <Link to="/pie/jobPosting">
            <i
              className="material-icons pointer"
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                color: "#C00",
              }}
            >
              cancel
            </i>
          </Link>
          <Form onSubmit={this.onSubmit} className="p-3" noValidate>
            <Row>
              <Col md="4">
                <FormGroup>
                  <Label className="bold label-sm">Domain</Label>
                  <Input
                    type="select"
                    name="domain"
                    className="form-control-sm"
                    value={this.state.domain}
                    onChange={this.onChange}
                    required
                  >
                    <option value="Technology">Technology</option>
                    <option value="Sales">Sales</option>
                    <option value="Support">Support</option>
                    <option value="Consulting">Consulting</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">Job Title</Label>
                  <Input
                    type="text"
                    name="title"
                    className="form-control-sm"
                    value={this.state.title}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    required
                  />
                  {errors.title && (
                    <FormText className="error">{errors.title}</FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">Area</Label>
                  <Input
                    type="text"
                    name="area"
                    className="form-control-sm"
                    value={this.state.area}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    required
                  />
                  {errors.area && (
                    <FormText className="error">{errors.area}</FormText>
                  )}
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label className="bold label-sm">Skills required</Label>
                  <Input
                    type="text"
                    name="skills"
                    className="form-control-sm"
                    value={this.state.skills}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    required
                  />
                  {errors.skills && (
                    <FormText className="error">{errors.skills}</FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">
                    Experience required (years)
                  </Label>
                  <Input
                    type="number"
                    name="exp"
                    className="form-control-sm"
                    value={this.state.exp}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    min="0"
                    max="99"
                    required
                  />
                  {errors.exp && (
                    <FormText className="error">{errors.exp}</FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">Job location</Label>
                  <Input
                    type="select"
                    name="loc"
                    className="form-control-sm"
                    value={this.state.loc}
                    onChange={this.onChange}
                    required
                  >
                    <option value="Bengaluru">Bengaluru</option>
                    <option value="Chennai">Chennai</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label className="bold label-sm">Vacancies</Label>
                  <Input
                    type="number"
                    name="vac"
                    className="form-control-sm"
                    value={this.state.vac}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    min="0"
                    max="99"
                    required
                  />
                  {errors.vac && (
                    <FormText className="error">{errors.vac}</FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">Job Description</Label>
                  <Input
                    type="textarea"
                    name="desc"
                    className="form-control-sm"
                    value={this.state.desc}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    required
                  />
                  {errors.desc && (
                    <FormText className="error">{errors.desc}</FormText>
                  )}
                </FormGroup>
              </Col>
            </Row>
            {this.state.message ? (
              <Notifier message={this.state.message} />
            ) : null}
            <div className="center">
              <button type="submit" className="btn login-button white-text">
                Add Job
              </button>
            </div>
          </Form>
        </Container>
        {this.state.message ? <Notifier message={this.state.message} /> : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
      </div>
    );
  }
}
export default AddJob;
