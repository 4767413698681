import React, { Component } from "react";
import axios from "axios";
import { Card, Form } from "reactstrap";
import { numChecker } from "../../common/FormValidator";
import FormComponent from "../../common/FormComponent";
import ErrorNotifier from "../../aside/ErrorNotifier";
import { FORGOT_PASSWORD } from "../../../utils/routes";
import Notifier from "../../aside/Notifier";
import { Link } from "react-router-dom";

class Forgotpassword extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      selection: null,
      formErrors: {
        email: "",
      },
      error: null,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }
  onChange(e) {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  select(item) {
    this.setState({
      selection: item,
    });
  }

  onBlur(e) {
    const { name, value } = e.target;
    let errors = this.state.formErrors;

    switch (name) {
      case "empId":
        errors.email = numChecker.test(value)
          ? ""
          : `The email ${value} is not valid`;
        break;
      default:
        break;
    }
    this.setState({
      formErrors: errors,
    });
  }
  onSubmit(e) {
    e.preventDefault();
    let User = {
      email: this.state.email,
    };
    axios
      .post(FORGOT_PASSWORD, User)
      .then((res) => {
        this.setState({ message: res.data.message });
        setTimeout(() => {
          this.setState({
            message: "",
            error: "",
          });
          this.props.history.push("/");
        }, 2000);
      })
      .catch((err) => {
        if (err.response.data.error) {
          this.setState({
            error: err.response.data.message,
          });
          setTimeout(() => {
            this.setState({
              error: "",
            });
          }, 2000);
        }
      });
  }

  render() {
    const { email } = this.state.formErrors;
    return (
      <div className="forgot-password">
        <Card className="forgot-password-card center">
          <h4 className="center forgot-password-header">Forgot Password</h4>
          <p>
            Please enter your login email Id for resetting the password. The
            reset password link will be sent to your registered email address.
          </p>
          <Form onSubmit={this.onSubmit} className="login-form" noValidate>
            <FormComponent
              type="text"
              name="email"
              error={email}
              style={{ width: "50%", marginLeft: "24%" }}
              placeholder="Email Id"
              value={this.state.email}
              change={this.onChange}
              required={true}
            />
            <button type="submit" className="btn login-button white-text">
              Submit
            </button>
            <Link to="/">
              <div className="center blue-text pointer">Login</div>
            </Link>
          </Form>
        </Card>
        {this.state.message ? <Notifier message={this.state.message} /> : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
      </div>
    );
  }
}

export default Forgotpassword;
