import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import DateFns from "date-fns";

/**
 * @function DetailsModule
 **/

const DetailsModule = (props) => {
  return (
    <Modal isOpen={props.openModal} centered style={{ marginLeft: "530px" }}>
      <ModalHeader toggle={props.closeModal}>Claim Details</ModalHeader>
      <ModalBody>
        <div className="details-flex-main-div">
          <div className="details-flex-div-one">Project</div>
          <div className="details-flex-div-two">:</div>
          <div className="details-flex-div-three">
            {props.item.projectSelection}
          </div>
        </div>
        <div className="details-flex-main-div">
          <div className="details-flex-div-one">Claim Type</div>
          <div className="details-flex-div-two">:</div>
          <div className="details-flex-div-three">{props.item.claimType}</div>
        </div>
        <div className="details-flex-main-div">
          <div className="details-flex-div-one">Amount</div>
          <div className="details-flex-div-two">:</div>
          <div className="details-flex-div-three">{props.item.amount}</div>
        </div>
        <div className="details-flex-main-div">
          <div className="details-flex-div-one">Currency</div>
          <div className="details-flex-div-two">:</div>
          <div className="details-flex-div-three">{props.item.currency}</div>
        </div>
        <div className="details-flex-main-div">
          <div className="details-flex-div-one">Expense Date</div>
          <div className="details-flex-div-two">:</div>
          <div className="details-flex-div-three">
            {DateFns.format(props.item.expenseDate, "DD-MM-YYYY")}
          </div>
        </div>
        <div className="details-flex-main-div">
          <div className="details-flex-div-one">Claim Date</div>
          <div className="details-flex-div-two">:</div>
          <div className="details-flex-div-three">
            {DateFns.format(props.item.claimDate, "DD-MM-YYYY")}
          </div>
        </div>
        <div className="details-flex-main-div">
          <div className="details-flex-div-one">Description</div>
          <div className="details-flex-div-two">:</div>
          <div className="details-flex-div-three">{props.item.description}</div>
        </div>
        <div className="details-flex-main-div">
          <div className="details-flex-div-one">Remarks</div>
          <div className="details-flex-div-two">:</div>
          <div className="details-flex-div-three">{props.item.remarks}</div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DetailsModule;
