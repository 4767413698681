import React, { Component } from "react";
import axios from "axios";
import { Table, Container } from "reactstrap";
import DateFns from "date-fns";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// components
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import AddProfessionalDetails from "./AddProfessionalDetails";
import UpdateProfessionalDetails from "./UpdateProfessionalDetails";
import ResumeUpload from "./ResumeUpload";
import ConfirmDialog from "../../../../common/confirm-dialog/ConfirmDialog";
// material ui
import DeleteIcon from "@material-ui/icons/Delete";
// redux
import {
  fetchEmpProfessionalInfo,
  deleteEmpProfessionalInfo,
} from "../../../../../redux/actions/hcm/my_profile/empProfessionalInfoAction";

class ProfessionalDetails extends Component {
  constructor() {
    super();
    this.state = {
      professionalAddButton: false,
      professionalUpdateButton: false,
      updateData: [],
      confirmDialog: {
        isOpen: false,
        title: "",
        subTitle: "",
      },
      message: "",
      error: "",
    };
    this.reset = this.reset.bind(this);
    this.addProfessionalDetails = this.addProfessionalDetails.bind(this);
    this.updateProfessionalDetails = this.updateProfessionalDetails.bind(this);
    this.getData = this.getData.bind(this);
    this.deleteProfessionalDetails = this.deleteProfessionalDetails.bind(this);
    this.updateProfessionalDetailsBack =
      this.updateProfessionalDetailsBack.bind(this);
  }

  reset() {
    this.setState({
      data: {},
    });
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.props.fetchEmpProfessionalInfo();
  }

  /*
addProfessionalDetails function toggle the add form for add professional details
*/
  addProfessionalDetails() {
    this.setState({
      professionalAddButton: !this.state.professionalAddButton,
    });
    this.getData();
  }

  /*
updateProfessionalDetails function to toggle the update form for update professional details
*/
  updateProfessionalDetails(item) {
    this.setState({
      updateData: item,
      professionalUpdateButton: !this.state.professionalUpdateButton,
    });
  }

  updateProfessionalDetailsBack() {
    this.setState({
      professionalUpdateButton: false,
    });
    this.getData();
  }

  /*
deleteProfessionalDetails function to delete the professionalDetails data
*/
  deleteProfessionalDetails(item) {
    let data = {
      id: item.id,
    };
    this.props.deleteEmpProfessionalInfo(data);
    this.setState((prevState) => ({
      ...prevState.confirmDialog,
      confirmDialog: {
        isOpen: false,
      },
    }));
  }

  setConfirmDialog = (obj) => {
    this.setState((prevProps) => ({
      confirmDialog: {
        ...prevProps.confirmDialog,
        isOpen: obj.isOpen,
      },
    }));
  };

  render() {
    const { professionalAddButton, professionalUpdateButton } = this.state;

    return (
      <Container className="card container-card">
        {!professionalAddButton && !professionalUpdateButton ? (
          <div>
            <button
              className="btn add-button white-text label-sm"
              onClick={this.addProfessionalDetails}
            >
              Add
            </button>
            <h5 className="bold center pt-2">Employment Details</h5>
            <i
              className="material-icons pointer cancel-button"
              onClick={this.props.back}
            >
              cancel
            </i>
            <Table>
              <thead>
                <tr>
                  <th className="opp-head employment-th-name">Employer Name</th>
                  <th className="opp-head employment-th-desig">Designation</th>
                  <th className="opp-head employment-th-fromDate">From Date</th>
                  <th className="opp-head employment-th-toDate">To Date</th>
                  <th className="opp-head employment-th-noOfYears">
                    No of Years
                  </th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.props.empProfessionalInfo.fetchData.length > 0 &&
                  this.props.empProfessionalInfo.fetchData.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td className="opp-tableData employment-th-name">
                          {item.employerName}
                        </td>
                        <td className="opp-tableData employment-th-desig">
                          {item.designation}
                        </td>
                        <td className="opp-tableData employment-th-fromDate">
                          {DateFns.format(item.fromDate, "DD-MM-YYYY")}
                        </td>
                        <td className="opp-tableData employment-th-toDate">
                          {DateFns.format(item.toDate, "DD-MM-YYYY")}
                        </td>
                        <td className="opp-tableData employment-th-noOfYears">
                          {item.noOfYears}
                        </td>

                        <td className="table-edit-btn">
                          <span
                            className="material-icons pointer span-edit-btn"
                            style={{ color: "blue" }}
                            onClick={() => this.updateProfessionalDetails(item)}
                          >
                            edit
                          </span>
                        </td>
                        <td className="table-delete-btn">
                          <DeleteIcon
                            onClick={() =>
                              this.setState({
                                confirmDialog: {
                                  isOpen: true,
                                  title: "Are you sure to delete this record?",
                                  subTitle: "You can't undo this operation",
                                  onConfirm: () =>
                                    this.deleteProfessionalDetails(item),
                                },
                              })
                            }
                            className="pointer span-delete-btn"
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            {/* <ResumeUpload /> */}
          </div>
        ) : null}
        {professionalAddButton ? (
          <AddProfessionalDetails back={this.addProfessionalDetails} />
        ) : null}
        {professionalUpdateButton ? (
          <UpdateProfessionalDetails
            back={this.updateProfessionalDetailsBack}
            data={this.state.updateData}
          />
        ) : null}
        {this.props.empProfessionalInfo.message ? (
          <Notifier message={this.props.empProfessionalInfo.message} />
        ) : null}
        {this.props.empProfessionalInfo.error ? (
          <ErrorNotifier message={this.props.empProfessionalInfo.error} />
        ) : null}
        <ConfirmDialog
          confirmDialog={this.state.confirmDialog}
          setConfirmDialog={this.setConfirmDialog}
        />
      </Container>
    );
  }
}

ProfessionalDetails.propTypes = {
  fetchEmpProfessionalInfo: PropTypes.func.isRequired,
  deleteEmpProfessionalInfo: PropTypes.func.isRequired,
  empProfessionalInfo: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  empProfessionalInfo: state.empProfessionalInfo,
});

const mapDispatchToProps = (dispatch) => ({
  fetchEmpProfessionalInfo: () => dispatch(fetchEmpProfessionalInfo()),
  deleteEmpProfessionalInfo: (data) =>
    dispatch(deleteEmpProfessionalInfo(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfessionalDetails);
