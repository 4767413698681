import React from "react";
import PropTypes from "prop-types";
import { errors, refresh } from "./Validator";
import { FormGroup, Label, Input, FormText } from "reactstrap";

const FormComponent = ({
  labelClass,
  group,
  label,
  style,
  type,
  name,
  value,
  change,
  blur,
  placeholder,
  error,
  required,
  disabled,
  min,
  max,
  minLength,
  maxLength,
  pattern,
  index,
  tabIndex,
  parentError
}) => {
  return (
    <FormGroup className={group || ""}>
      {label ? <Label className={labelClass}>{label}</Label> : null}
      <Input
        tabIndex={index}
        type={type}
        name={name}
        value={value}
        style={style}
        className="form-control-sm"
        onChange={change}
        onBlur={blur}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        min={min}
        max={max}
        minLength={minLength}
        maxLength={maxLength}
        pattern={pattern}
        tabIndex={tabIndex}
      />
      {parentError && <FormText className="error">{parentError}</FormText>}
      {error && <FormText className="error">{error}</FormText>}
      {errors[name] && <FormText className="error">{errors[name]}</FormText>}
    </FormGroup>
  );
};

FormComponent.propTypes = {
  group: PropTypes.string,
  labelClass: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputClass: PropTypes.string,
  change: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};
FormComponent.defaultProps = {
  type: "text",
  labelClass: "bold",
  inputClass: "form-control",
};

export default FormComponent;
