import React, { Component } from "react";
import DateFns from "date-fns";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Table, Container } from "reactstrap";
// components
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import UpdateExitClearance from "./UpdateExitClearance";
// redux
import { getExitClearance } from "../../../../../redux/actions/hcm/resignation/exitClearanceAction";

class ExistClearance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateBtn: false,
      updateData: [],
      message: "",
      error: "",
    };
    this.showLeaves = this.showLeaves.bind(this);
    this.toggleUpdateBtn = this.toggleUpdateBtn.bind(this);
    this.updateBack = this.updateBack.bind(this);
    this.getData = this.getData.bind(this);
  }

  getData() {
    this.props.getExitClearance();
  }

  componentDidMount() {
    this.getData();
  }

  showLeaves() {
    return (
      <Table hover>
        <thead>
          <tr>
            <th className="opp-head resignation-table">Employee ID</th>
            <th className="opp-head resignation-table">Employee Name</th>
            <th className="opp-head resignation-table">Resignation Date</th>
            <th className="opp-head resignation-table">Last Working Date</th>
            <th className="opp-head resignation-table">Resignation Reason</th>
            <th className="opp-head resignation-table">Resignation Status</th>
            <th className="opp-head resignation-table">
              Admin Clearance Status
            </th>
            <th className="opp-head resignation-table">
              Asset Clearance Status
            </th>
            <th className="opp-head resignation-table">
              Manager Clearance Status
            </th>
            <th className="opp-head resignation-table">
              Finanace Clearance Status
            </th>
            <th className="opp-head resignation-table">HR Clearance Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {this.props.exitClearance.data.length > 0 &&
            this.props.exitClearance.data.map((item, index) => {
              return (
                <tr>
                  <td className="opp-tableData resignation-table">
                    {item.empId}
                  </td>
                  <td className="opp-tableData resignation-table">
                    {item.empName}
                  </td>
                  <td className="opp-tableData resignation-table">
                    {DateFns.format(item.regDate, "DD-MM-YYYY")}
                  </td>
                  <td className="opp-tableData resignation-table">
                    {DateFns.format(item.lastWorkingDate, "DD-MM-YYYY")}
                  </td>
                  <td className="opp-tableData resignation-table">
                    {item.regReason}
                  </td>
                  <td className="opp-tableData resignation-table">
                    {item.regStatus}
                  </td>
                  <td className="opp-tableData resignation-table">
                    {item.adminClearanceStatus}
                  </td>
                  <td className="opp-tableData resignation-table">
                    {item.assetClearanceStatus}
                  </td>
                  <td className="opp-tableData resignation-table">
                    {item.managerClearanceStatus}
                  </td>
                  <td className="opp-tableData resignation-table">
                    {item.financeClearanceStatus}
                  </td>
                  <td className="opp-tableData resignation-table">
                    {item.hrClearanceStatus}
                  </td>
                  <td>
                    <span
                      className="material-icons pointer opp-action-edit"
                      style={{ color: "blue" }}
                      onClick={() => this.toggleUpdateBtn(item)}
                    >
                      edit
                    </span>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    );
  }

  /*
toggleUpdateBtn function to toggle the update form
*/
  toggleUpdateBtn(item) {
    this.setState({
      updateBtn: true,
      updateData: item,
    });
  }

  /*
updateBack function to redirect to back main page
*/
  updateBack() {
    this.setState({
      updateBtn: false,
    });

    this.getData();
  }

  render() {
    const { updateBtn, updateData } = this.state;
    let shrink = this.props.sidebar ? "scale" : "no-scale";

    return (
      <div style={{ position: "relative" }} className={shrink}>
        <Container className="card container-card pt-2 ">
          {!updateBtn ? (
            <React.Fragment>
              <Link
                to={{
                  pathname: "/pie/iam/service_menu",
                  moduleName: this.props.location.moduleName,
                  data: this.props.location.data,
                }}
              >
                <i className="material-icons cancel-button pointer">cancel</i>
              </Link>
              <h5 className="bold center">Exit Clearance</h5>
              {this.showLeaves()}
            </React.Fragment>
          ) : null}
          {updateBtn && (
            <UpdateExitClearance back={this.updateBack} data={updateData} />
          )}
          {this.props.exitClearance.message && (
            <Notifier message={this.props.exitClearance.message} />
          )}
          {this.props.exitClearance.error && (
            <ErrorNotifier message={this.props.exitClearance.error} />
          )}
        </Container>
      </div>
    );
  }
}

ExistClearance.propTypes = {
  getExitClearance: PropTypes.func.isRequired,
  exitClearance: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  exitClearance: state.exitClearance,
});

export default connect(mapStateToProps, { getExitClearance })(ExistClearance);
