import React from "react";
// material ui
import SearchIcon from "@material-ui/icons/Search";
import { IconButton } from "@material-ui/core";
// components
import useStyles from "./styles";
import CustTooltip from "../../../../../common/tooltip/CustTooltip";

// -------------------------------------------------------------------------

const SearchBar = ({
  onChange,
  value,
  placeholder,
  onSubmit,
  title,
  onKeyPress,
  error,
  isAvailable,
  name,
}) => {
  const classes = useStyles();

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "1.8rem",
        
        }}
        class="search-container"
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
          }}
        >
          <input
            className="input-candisearch"
            value={value}
            style={{ textAlign: "center", width: "100%", height: "100%" }}
            onChange={onChange}
            onKeyPress={onKeyPress}
            type="text"
            placeholder={placeholder}
            name={name ? name : "search"}
          />
          <CustTooltip title={title}>
            <IconButton
              onClick={onSubmit}
              type="submit"
              className={classes.iconBtn}
            >
              <SearchIcon className={classes.icon} />
            </IconButton>
          </CustTooltip>
        </div>
        {error && (
          <span style={{ fontSize: "14px", color: "red" }}>{error}</span>
        )}
        {isAvailable && (
          <span style={{ fontSize: "14px", color: "red" }}>{isAvailable}</span>
        )}
      </div>
    </>
  );
};

SearchBar.propTypes = {};

export default SearchBar;
