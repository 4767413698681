import {
    GET_HIRING_STATUS,
    GET_HIRING_STATUS_RS_TYPE,
    GET_ERRORS,
    SET_MESSAGE,
  } from "../../../actions/types";
  
  const initialState = {
    data: [],
    mainData:[],
    message: null,
    error: null,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case   GET_HIRING_STATUS:
        return {
          ...state,
          data: action.payload,
        };
        case  GET_HIRING_STATUS_RS_TYPE:
          return {
            ...state,
            mainData: action.payload,
          };
      case SET_MESSAGE:
        return {
          ...state,
          message: action.payload,
        };
      case GET_ERRORS:
        return {
          ...state,
          error: action.payload,
        };
      default:
        return state;
    }
  }