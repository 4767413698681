import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
// components
import ListContainer from "../../../../common/layout/ListContainer";
import PageTitle from "../../../../common/page_title/PageTitle";
import {
  Validator,
  submitValidator,
  valid,
} from "../../../../common/Validator";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import ViewUploadedMessage from '../../../../common/viewUploadedmessageRs'
// redux
import { uploadCandidateTagging } from "../../../../../redux/actions/rs/upload/candidateTaggingUploadAction";

// ------------------------------------------------------------------------------
export const CandidateTaggingUpload = (props) => {
  const [file, setFile] = useState("");
  const [fileError, setFileError] = useState("");
  const [error, setError] = useState("");
  const[fileValue,setFileValue] = useState("")
  const[isVisible,setIsVisible] = useState(false)

  const onSubmit = (e) => {
    e.preventDefault();
    submitValidator(e.target);
    let fd = new FormData();

    fd.append("file", file);
    if (file && !fileError) {
    props.uploadCandidateTagging(fd);
    setFile("");
    setIsVisible(true)
    } else {
      setError("Enter all mandatory fields");
      setTimeout(() => {
        setError("");
        setFile("");
      }, 2000);
    }
  };

  const handleFile = (e) => {
    Validator(e.target);
    let allowedExtension = /(\.xlsx)$/i;
    if (allowedExtension.exec(e.target.files[0].name)) {
      if ((e.target.files[0].size / 1024 / 1024).toFixed(2) < 2) {
        setFile(e.target.files[0] || []);
        setFileError("");
        setFileValue(e.target.value = "")
      } else {
        setFile(e.target.files[0]);
        setFileError("File size must be less than 2MB");
      }
    } else {
      setFile(e.target.files[0]);
      setFileError(" The upload File should be .xlsx format");
    }
  };

  console.log(props.candidateTaggingUpload)

  return (
    <ListContainer sidebar={props.sidebar ? "scale" : "no-scale"}>
      <PageTitle>Candidate Tagging Upload</PageTitle>
      <Link
        to={{
          pathname: "/pie/iam/service_menu",
          moduleName: props.location.moduleName,
          data: props.location.data,
        }}
      >
        <i className="material-icons cancel-button pointer">cancel</i>
      </Link>
      <Grid container justify="center">
        <Grid item lg={6} sm={12}>
          <Form onSubmit={onSubmit} className="p-3" noValidate>
            <div className="file-div">
              <FormGroup className="file-display">
                <Label
                  className="bold label-sm"
                  style={{ fontSize: ".825rem" }}
                >
                  Attachment
                </Label>
                <Input
                  disabled
                  type="text"
                  value={file.name || "" || fileValue}
                  className="form-control-sm "
                  required={true}
                />
                {fileError && (
                  <FormText className="error ">{fileError}</FormText>
                )}
              </FormGroup>
              <FormGroup className="file-browse-btn-div">
                <label className="btn label-sm file-browse-btn">
                  Browse
                  <Input
                    type="file"
                    name="file"
                    style={{
                      display: "none",
                      marginTop: "23px",
                      marginLeft: "-28px",
                    }}
                    className="form-control-sm file-input"
                    onChange={handleFile}
                    required={true}
                    accept=".pdf, .png"
                  />
                </label>
              </FormGroup>
            </div>
            <div className="center">
              <button type="submit" className="btn login-button white-text">
                Upload
              </button>
            </div>
          </Form>
        </Grid>
      </Grid>
      {isVisible && <ViewUploadedMessage viewData={props.candidateTaggingUpload}/>}
      {props.candidateTaggingUpload.message && (
        <Notifier message={props.candidateTaggingUpload.message} />
      )}
      {props.candidateTaggingUpload.error && (
        <ErrorNotifier message={props.candidateTaggingUpload.error} />
      )}
      {error && <ErrorNotifier message={error} />}
    </ListContainer>
  );
};

CandidateTaggingUpload.propTypes = {
  uploadCandidateTagging: PropTypes.func.isRequired,
  candidateTaggingUpload: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  candidateTaggingUpload: state.candidateTaggingUpload,
});

const mapDispatchToProps = (dispatch) => ({
  uploadCandidateTagging: (fd) => dispatch(uploadCandidateTagging(fd)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateTaggingUpload);
