import React, { Component } from "react";
import { Form, Row, Col, FormGroup, Label, Input, FormText } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../../../common/Validator";
import FormComponent from "../../../../../common/FormComponent";
import Notifier from "../../../../../aside/Notifier";
import ErrorNotifier from "../../../../../aside/ErrorNotifier";
import { updateService } from "../../../../../../redux/actions/iam/auth_configuration/menu_management/serviceAction";

class UpdateService extends Component {
  constructor(props) {
    super(props);
    const { id, serviceName, serviceGroupId, serviceBrowserLink } =
      this.props.data;
    this.state = {
      id: id,
      moduleId: this.props.data.pieUserModule.id,
      serviceName: serviceName,
      serviceGroupId: serviceGroupId,
      serviceBrowserURL: serviceBrowserLink,
      message: "",
      error: "",
      btnDisable: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      id: this.state.id,
      pieUserModuleId: Number(this.state.moduleId),
      serviceName: this.state.serviceName,
      serviceGroupId: this.state.serviceGroupId,
      serviceBrowserLink: this.state.serviceBrowserURL,
    };
    if (valid) {
      this.props.updateService(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidMount contains the error refresh method and setState method to call the render method
*/
  componentDidMount() {
    refresh();
    this.setState({
      message: "",
      error: "",
    });
  }

  render() {
    const {
      moduleId,
      serviceName,
      serviceGroupId,
      serviceBrowserURL,
      error,
      message,
      btnDisable,
    } = this.state;

    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Update Service</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="3"></Col>
            <Col md="6">
              <FormGroup>
                <Label className="label-sm bold">Module</Label>
                <Input
                  type="select"
                  name="moduleId"
                  value={moduleId}
                  onChange={this.onChange}
                  className="form-control-sm"
                >
                  <option hidden value="">
                    Select....
                  </option>
                  {this.props.menuData.length > 0 &&
                    this.props.menuData.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.moduleName}-{item.moduleGroupId}
                        </option>
                      );
                    })}
                </Input>
                {errors.moduleId && (
                  <FormText className="error">{errors.moduleId}</FormText>
                )}
              </FormGroup>
              <FormComponent
                labelClass="bold label-sm"
                label="Service Name"
                type="text"
                name="serviceName"
                value={serviceName}
                change={this.onChange}
                required={true}
                minLength="2"
                maxLength="50"
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Group Id"
                type="text"
                name="serviceGroupId"
                value={serviceGroupId}
                change={this.onChange}
                required={true}
                pattern="serviceGroupId"
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Browser URL"
                type="text"
                name="serviceBrowserURL"
                value={serviceBrowserURL}
                change={this.onChange}
                required={true}
              />
            </Col>
            <Col md="3"></Col>
          </Row>
          <div className="center">
            <button
              type="submit"
              className="btn login-button white-text"
              disabled={btnDisable ? true : false}
            >
              Update
            </button>
          </div>
        </Form>
        {message && <Notifier message={message} />}
        {error && <ErrorNotifier message={error} />}
        {this.props.services.message && (
          <Notifier message={this.props.services.message} />
        )}
        {this.props.services.error && (
          <ErrorNotifier message={this.props.services.error} />
        )}
      </React.Fragment>
    );
  }
}

UpdateService.propTypes = {
  updateService: PropTypes.func.isRequired,
  services: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  services: state.services,
});

export default connect(mapStateToProps, { updateService })(UpdateService);
