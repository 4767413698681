import axios from "axios";
import { GET_POSITION_LIST} from "../types";
import { FETCH_POSITION_RS } from "../../../utils/routes"
import setSuccessMessage from "../common/setSuccessMessage";
import setErrorMessage from "../common/setErrorMessage";
import getDispatch from "../common/getDispatch";

export const addCustomerDomain = (data, back) => (dispatch) => {
  axios
    .post(CURD_CUSTOMER_DOMAIN, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getCustomerDomain));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getPositionType = () => (dispatch) => {
  axios
    .get(FETCH_POSITION_RS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch( GET_POSITION_LIST , res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateCustomerDomain = (data, back) => (dispatch) => {
  axios
    .put(CURD_CUSTOMER_DOMAIN, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteCustomerDomain = (data) => (dispatch) => {
  axios
    .delete(CURD_CUSTOMER_DOMAIN, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getCustomerDomain));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
