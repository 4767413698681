import axios from "axios";
import { RS_DASHBOARD_GET,RS_DASHBOARD_GET_FULL_DETAILS,RS_DASHBOARD_APPLICANTS_DETAILS,RS_DASHBOARD_POSITION_DEPARTMENT,RS_DASHBOARD_POSITION_APPLICANTS,RS_DASHBOARD_FUNNEL,RS_DASHBOARD_APPLICANTS_TABLE_DETAILS,RS_DASHBOARD_RATIO,RS_DASHBOARD_PROFILE_SCREENING,RS_DASHBOARD_SOURCE, } from "../../types";
import { RS_DASHBOARD_API,RS_DASHBOARD_FULL_DETAILS_API,RS_DASHBOARD_FULL_APPLICANTS_API,RS_DASHBOARD_POSITION_DEPARTMENT_API,RS_DASHBOARD_POSITION_APPLICANTS_API,RS_DASHBOARD_FUNNEL_API,RS_DASHBOARD_APPLICANTS_TABLE_API,RS_DASHBOARD_RATIO_API,RS_DASHBOARD_PROFILE_SCREENING_API,RS_DASHBOARD_SOURCE_API  } from "../../../../utils/routes"
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";


export const getDashBoardDetails = (body) => (dispatch) => {
  axios
    .post(RS_DASHBOARD_API,body, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(RS_DASHBOARD_GET , res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getDashBoardMoreDetails = (body) => (dispatch) => {
  axios
    .post(RS_DASHBOARD_FULL_DETAILS_API ,body, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(RS_DASHBOARD_GET_FULL_DETAILS , res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getDashBoardFullApplicants = (data) => (dispatch) => {
  axios
    .post(RS_DASHBOARD_FULL_APPLICANTS_API ,data, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(RS_DASHBOARD_APPLICANTS_DETAILS , res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getDashboardPosition = (data) => (dispatch) => {
  axios
    .post(RS_DASHBOARD_POSITION_DEPARTMENT_API ,data, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(RS_DASHBOARD_POSITION_DEPARTMENT , res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getDashboardApplicants = (data) => (dispatch) => {
  axios
    .post(RS_DASHBOARD_POSITION_APPLICANTS_API ,data, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(RS_DASHBOARD_POSITION_APPLICANTS, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getDashboardFunnel = (data) => (dispatch) => {
  axios
    .post(RS_DASHBOARD_FUNNEL_API ,data, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(RS_DASHBOARD_FUNNEL , res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getDashboardFunnelData = (data) => (dispatch) => {
  axios
    .post(`${RS_DASHBOARD_FUNNEL_API}/${data}` , { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(RS_DASHBOARD_FUNNEL , res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};


export const getDashboardApplicantsDetails = (body) => (dispatch) => {
  axios
    .post(RS_DASHBOARD_APPLICANTS_TABLE_API,body, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(RS_DASHBOARD_APPLICANTS_TABLE_DETAILS , res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getDashboardGraphRatio = (body) => (dispatch) => {
  axios
    .post(RS_DASHBOARD_RATIO_API,body, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(RS_DASHBOARD_RATIO , res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};


export const getProfileScreeningHr = (body) => (dispatch) => {
  axios
    .post(RS_DASHBOARD_PROFILE_SCREENING_API,body, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(RS_DASHBOARD_PROFILE_SCREENING , res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getDashboardSource = (body) => (dispatch) => {
  axios
    .post(RS_DASHBOARD_SOURCE_API ,body, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(RS_DASHBOARD_SOURCE, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

