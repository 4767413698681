import React, { Component } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import DateFns from "date-fns";
// material ui
import { Divider, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import EventNoteIcon from '@material-ui/icons/EventNote';
import VisibilityIcon from '@material-ui/icons/Visibility';
// components
import UpdateCandidateKanban from "./UpdateCandidateKanban";
import CustTooltip from "../../../../common/tooltip/CustTooltip";
import DetailsPageFooter from "./DetailsPageViewFooter";
import ViewHiringStages from "./viewHiringStages";

// -----------------------------------------------------------------

export class CandidateKanbanDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      isOpen:false
    };
    this.handleViewStage = this.handleViewStage.bind(this)
    this.backViewStage = this.backViewStage.bind(this)
  }

  // handleEdit function to edit form display
  handleEdit = () => {
    this.setState({
      edit: true,
    });
  };

  // handleCloseEdit function to close edit form display
  handleCloseEdit = () => {
    this.setState({
      edit: false,
    });
  };
  handleViewStage(){
    this.setState({
      isOpen:!this.state.isOpen,
     
    })
    }
    
    backViewStage(){
    this.setState({
      isOpen:false
    })
    }

  render() {
    const { edit } = this.state;
    return (
      <>
        {edit && !this.state.isOpen ? (
          <>
            <Modal.Title>
              <h5 className="bold center mb-4 mt-2">Edit Candidate Tracking</h5>
              <i
                onClick={() => this.handleCloseEdit()}
                className="material-icons pointer cancel-button"
              >
                cancel
              </i>
            </Modal.Title>
            <Modal.Body>
              <UpdateCandidateKanban
                data={this.props.data}
                back={this.props.back}
              />
            </Modal.Body>
          </>
        ) : (
          <>
           {!this.state.isOpen &&  <div>
            <Modal.Title>
              <h5 className="bold center mb-4 mt-2">
                Candidate Tracking Details
              </h5>
             

              {/* <CustTooltip title="Edit">
                <IconButton
                  className="View Comments"
                  // onClick={() => this.handleEdit()}
                  style={{
                    top: "0.01rem",
                    right: "6rem",
                    color: "#0056b3",
                  }}
                >
                  <EventNoteIcon className="mui-icon-size" />
                </IconButton>
              </CustTooltip> */}
               <CustTooltip title="View Hiring Stages">
                <IconButton
                  className="edit-button"
                  onClick={() => this.handleViewStage()}
                  style={{
                    top: "0.01rem",
                    right: "7rem",
                    color: "#0056b3",
                  }}
                >
                  <VisibilityIcon className="mui-icon-size" />
                </IconButton>
              </CustTooltip>
              
              <CustTooltip title="Edit">
                <IconButton
                  className="edit-button"
                  onClick={() => this.handleEdit()}
                  style={{
                    top: "0.01rem",
                    right: "4rem",
                    color: "#0056b3",
                  }}
                >
                  <EditIcon className="mui-icon-size" />
                </IconButton>
              </CustTooltip>
             
             
              <i
                onClick={() => this.props.back()}
                className="material-icons pointer cancel-button"
              >
                cancel
              </i>
            </Modal.Title>
            <Modal.Body>
              <Row style={{paddingLeft:"6rem"}}>
                <Col md="6">
                  <ul className="CustDetails-ul">
                  <li>
                      <div style={{ display: "flex" }}>
                        <div style={{width:"40%"}} className="label-sm bold feedback-span-label-col-1 pt-1">
                          <span>CanId</span>
                        </div>
                        <div style={{ width: "1%", textAlign: "end" }}>
                          <span className="label-sm">:</span>
                        </div>
                        <div   style={{ width: "77%", paddingLeft: "1%" }}>
                          <span style={{fontSize:".75rem",fontweight:400}} >
                          {this.props.data.canInfoId}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div style={{ display: "flex" }}>
                        <div style={{width:"40%"}} className="label-sm bold feedback-span-label-col-1 pt-1">
                          <span> Candidate Name</span>
                        </div>
                        <div style={{ width: "1%", textAlign: "end" }}>
                          <span className="label-sm">:</span>
                        </div>
                        <div   style={{ width: "77%", paddingLeft: "1%" }}>
                          <span style={{fontSize:".75rem",fontweight:400}} >
                          {this.props.data.firstName}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div style={{ display: "flex" }}>
                        <div style={{width:"40%"}} className="label-sm bold feedback-span-label-col-1 pt-1">
                          <span>  Email</span>
                        </div>
                        <div style={{ width: "1%", textAlign: "end" }}>
                          <span className="label-sm">:</span>
                        </div>
                        <div   style={{ width: "77%", paddingLeft: "1%" }}>
                          <span style={{fontSize:".75rem",fontweight:400}} >
                          {this.props.data.eMailId}
                          </span>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div style={{ display: "flex" }}>
                        <div style={{width:"40%"}} className="label-sm bold feedback-span-label-col-1 pt-1">
                          <span>Mobile No.</span>
                        </div>
                        <div style={{ width: "1%", textAlign: "end" }}>
                          <span className="label-sm">:</span>
                        </div>
                        <div   style={{ width: "77%", paddingLeft: "1%" }}>
                          <span style={{fontSize:".75rem",fontweight:400}} >
                          {this.props.data.mobileNo}
                          </span>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div style={{ display: "flex" }}>
                        <div style={{width:"40%"}} className="label-sm bold feedback-span-label-col-1 pt-1">
                          <span>Candidate Status</span>
                        </div>
                        <div style={{ width: "1%", textAlign: "end" }}>
                          <span className="label-sm">:</span>
                        </div>
                        <div   style={{ width: "77%", paddingLeft: "1%" }}>
                          <span style={{fontSize:".75rem",fontweight:400}} >
                          {this.props.data.candidateStatus}
                          </span>
                        </div>
                      </div>
                    </li>

                    
                    <li>
                      <div style={{ display: "flex" }}>
                        <div  style={{width:"40%"}} className="label-sm bold feedback-span-label-col-1 pt-1">
                          <span> Candidate Source</span>
                        </div>
                        <div style={{ width: "1%", textAlign: "end" }}>
                          <span className="label-sm">:</span>
                        </div>
                        <div   style={{ width: "77%", paddingLeft: "1%" }}>
                          <span style={{fontSize:".75rem",fontweight:400}} >
                          {this.props.data.candidateSource}
                          </span>
                        </div>
                      </div>
                    </li>
                    
                     
                    
                 
                

                   
                  </ul>
                </Col>
                <Col md="6">
                  <ul className="CustDetails-ul">

                  <li>
                      <div style={{ display: "flex" }}>
                        <div style={{width:"40%"}} className="label-sm bold feedback-span-label-col-1 pt-1">
                          <span>  Hiring Stage</span>
                        </div>
                        <div style={{ width: "1%", textAlign: "end" }}>
                          <span className="label-sm">:</span>
                        </div>
                        <div   style={{ width: "77%", paddingLeft: "1%" }}>
                          <span style={{fontSize:".75rem",fontweight:400}} >
                           {this.props.data.hiringStage}
                          </span>
                        </div>
                      </div>
                    </li>
                    
                    
                  <li>
                      <div style={{ display: "flex" }}>
                        <div style={{width:"40%"}} className="label-sm bold feedback-span-label-col-1 pt-1">
                          <span> Hiring Status</span>
                        </div>
                        <div style={{ width: "1%", textAlign: "end" }}>
                          <span className="label-sm">:</span>
                        </div>
                        <div   style={{ width: "77%", paddingLeft: "1%" }}>
                          <span style={{fontSize:".75rem",fontweight:400}} >
                          {this.props.data.hiringStatus}
                          </span>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div style={{ display: "flex" }}>
                        <div style={{width:"40%"}} className="label-sm bold feedback-span-label-col-1 pt-1">
                          <span>  Hiring Stage Date</span>
                        </div>
                        <div style={{ width: "1%", textAlign: "end" }}>
                          <span className="label-sm">:</span>
                        </div>
                        <div   style={{ width: "77%", paddingLeft: "1%" }}>
                          <span style={{fontSize:".75rem",fontweight:400}} >
                          {this.props.data.hiringStagedate === " " || this.props.data.hiringStagedate === ""||
                          this.props.data.hiringStagedate === null 
                          ?
                          null:
                          DateFns.format(
                            this.props.data.hiringStagedate,
                            "DD-MM-YYYY"
                          )}
                          </span>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div style={{ display: "flex" }}>
                        <div style={{width:"40%"}}   className="label-sm bold feedback-span-label-col-1 pt-1">
                          <span>   Expected Joining Date</span>
                        </div>
                        <div style={{ width: "1%", textAlign: "end" }}>
                          <span className="label-sm">:</span>
                        </div>
                        <div   style={{ width: "77%", paddingLeft: "1%" }}>
                          <span style={{fontSize:".75rem",fontweight:400}} >
                          {this.props.data?.dateOfJoin === " " || this.props.data?.dateOfJoin === "" 
                          || this.props.data?.dateOfJoin === null
                          ?
                          null:
                          DateFns.format(
                            this.props.data.dateOfJoin,
                            "DD-MM-YYYY"
                          )}
                          </span>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div style={{ display: "flex" }}>
                        <div style={{width:"40%"}} className="label-sm bold feedback-span-label-col-1 pt-1">
                          <span>   Feedback</span>
                        </div>
                        <div style={{ width: "1%", textAlign: "end" }}>
                          <span className="label-sm">:</span>
                        </div>
                        <div   style={{ width: "77%", paddingLeft: "1%" }}>
                          <span style={{fontSize:".75rem",fontweight:400}} >
                          {this.props.data.hiringStagefeedback}
                          </span>
                        </div>
                      </div>
                    </li>
                    
                    <li>
                      <div style={{ display: "flex" }}>
                        <div style={{width:"40%"}} className="label-sm bold feedback-span-label-col-1 pt-1">
                          <span> Rating</span>
                        </div>
                        <div style={{ width: "1%", textAlign: "end" }}>
                          <span className="label-sm">:</span>
                        </div>
                        <div   style={{ width: "77%", paddingLeft: "1%" }}>
                          <span style={{fontSize:".75rem",fontweight:400}} >
                          {this.props.data.hiringStageRating}
                          </span>
                        </div>
                      </div>
                    </li>
                    
                    <li>
                      <div style={{ display: "flex" }}>
                        <div style={{width:"40%"}} className="label-sm bold feedback-span-label-col-1 pt-1">
                          <span>  Comment</span>
                        </div>
                        <div style={{ width: "1%", textAlign: "end" }}>
                          <span className="label-sm">:</span>
                        </div>
                        <div   style={{ width: "77%", paddingLeft: "1%" }}>
                          <span style={{fontSize:".75rem",fontweight:400}} >
                          {this.props.data.comment}
                          </span>
                        </div>
                      </div>
                    </li>
                    
                     
            </ul>
                </Col>
              </Row>
              <Divider />
              <DetailsPageFooter
                createdBy={this.props.data.taggedBy}
                createdOn={this.props.data.taggedOn}
                updatedBy={this.props.data.updatedBy}
                updatedOn={this.props.data.updatedOn}
              />
            </Modal.Body>
            </div>}
          </>
        )}
          {this.state.isOpen && (
              <ViewHiringStages 
              canId={this.props.data.canInfoId}
              isOpen={this.state.isOpen}
              canInfo={this.props.canTrackId} 
              back = {this.backViewStage}
              />
            )}
      </>
    );
  }
}

export default CandidateKanbanDetails;
