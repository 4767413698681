import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import DocumentUpload from "./DocumentUpload";
import { getCustomers } from "../../../../redux/actions/customerAction";
import { getAccounts } from "../../../../redux/actions/accountAction";
import UpdateProduct from "./UpdateProduct";
import DateFns from "date-fns";

class ProductDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permissionlist: this.props.auth.permissionlist || {},
      update: false,
    };
    this.updateNow = this.updateNow.bind(this);
    this.afterUpdate = this.afterUpdate.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.auth !== this.props.auth) {
      this.setState({
        permissionlist: this.props.auth.permisssionlist,
      });
    }
  }

  updateNow() {
    this.setState({
      update: !this.state.update,
    });
  }

  afterUpdate() {
    this.setState({
      update: !this.state.update,
    });
    this.props.getData();
  }

  componentDidMount() {
    this.setState({
      permissionlist: this.props.auth.permissionlist,
    });

    this.props.getCustomers();
    this.props.getAccounts();
  }

  render() {
    const { permissionlist } = this.state;
    let currentPermission;

    for (let x in permissionlist.response.permissions1) {
      if (permissionlist.response.permissions1[x].subMenuId === "6.1") {
        currentPermission = permissionlist.response.permissions1[x];
      }
    }

    return (
      <div className="project-details p-3">
        {!this.state.update ? (
          <React.Fragment>
            <i
              className="material-icons pointer"
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                color: "#C00",
              }}
              onClick={this.props.back}
            >
              cancel
            </i>
            <h5 className="center">{this.props.detail.productName}</h5>
            <hr />
            <Row>
              <Col md="6" style={{ paddingLeft: "34px" }}>
                <ul style={{ listStyle: "none", paddingInlineStart: 0 }}>
                  <li>
                    <span className="label-sm bold product-span-label-col-1">
                      Product Name
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.props.detail.productName}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold product-span-label-col-1">
                      Product ID
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.props.detail.productId}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold product-span-label-col-1">
                      Customer Name
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.customers &&
                        this.props.customers.data.map((item) => {
                          if (
                            parseInt(this.props.detail.compCode) ===
                            item.compCode
                          ) {
                            return item.compFullName;
                          }
                          return null;
                        })}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold product-span-label-col-1">
                      Customer ID
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.props.detail.compCode}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold product-span-label-col-1">
                      Account Name
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.account &&
                        this.props.account.data.map((item) => {
                          if (
                            parseInt(this.props.detail.accountId) ===
                            item.accountId
                          ) {
                            return item.accountName;
                          }
                          return null;
                        })}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold product-span-label-col-1">
                      Account ID
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.props.detail.accountId}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold product-span-label-col-1">
                      License Quantity
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.props.detail.licenseQty}
                    </span>
                  </li>
                </ul>
              </Col>
              <Col md="6" style={{ paddingLeft: 0 }}>
                <ul style={{ listStyle: "none", paddingInlineStart: 0 }}>
                  <li>
                    <span className="label-sm bold product-span-label-col-2">
                      Start Date
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {DateFns.format(
                        this.props.detail.activationStartDate,
                        "DD-MM-YYYY"
                      )}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold product-span-label-col-2">
                      End Date
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {DateFns.format(
                        this.props.detail.activationEndDate,
                        "DD-MM-YYYY"
                      )}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold product-span-label-col-2">
                      Status
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.props.detail.productStatus}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold product-span-label-col-2">
                      Remarks
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.props.detail.remarks}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold product-span-label-col-2">
                      Created By
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.props.detail.createdBy}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold product-span-label-col-2">
                      Created On
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.detail.createdOn &&
                        DateFns.format(
                          this.props.detail.createdOn,
                          "DD-MM-YYYY"
                        )}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold product-span-label-col-2">
                      Updated By
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.props.detail.updatedBy}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold product-span-label-col-2">
                      Updated On
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.detail.updatedOn &&
                        DateFns.format(
                          this.props.detail.updatedOn,
                          "DD-MM-YYYY"
                        )}
                    </span>
                  </li>
                </ul>
              </Col>
            </Row>
            {currentPermission.update1 ? (
              <div className="center">
                <hr />
                {this.props.detail.productStatus !== "Closed" ? (
                  <button
                    className="btn login-button  white-text label-sm"
                    onClick={this.updateNow}
                  >
                    Update
                  </button>
                ) : (
                  " "
                )}
              </div>
            ) : null}
            <div className="div">
              <DocumentUpload id={this.props.detail.productId} />
            </div>
          </React.Fragment>
        ) : (
          <UpdateProduct item={this.props.detail} back={this.afterUpdate} />
        )}
      </div>
    );
  }
}

ProductDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  getCustomers: PropTypes.func.isRequired,
  getAccounts: PropTypes.func.isRequired,
  customers: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  customers: state.customers,
  account: state.account,
});

export default connect(mapStateToProps, { getCustomers, getAccounts })(
  ProductDetails
);
