import React, { Component } from "react";
import { Form, Row, Col, FormText } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// materail ui
import { FilledInput, FormControl, IconButton } from "@material-ui/core";
import AttachmentIcon from "@material-ui/icons/Attachment";
import SendIcon from "@material-ui/icons/Send";
import PhoneIcon from "@material-ui/icons/Phone";
// components
import { Validator, refresh } from "../../../../common/Validator";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
// redux
import { addRequestConversation } from "../../../../../redux/actions/experience_360/requestConversationAction";

// ----------------------------------------------------------------
class SubmitRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: "",
      file: [],
      fileError: "",
    };
  }

  componentDidMount() {
    refresh();
  }

  onChange = (e) => {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleFile = (e) => {
    Validator(e.target);
    let allowedExtension = /(\.png|\.pdf|\.jpeg)$/i;
    if (allowedExtension.exec(e.target.files[0].name)) {
      if ((e.target.files[0].size / 1024 / 1024).toFixed(2) <= 2) {
        this.setState({
          file: e.target.files[0] || [],
          fileError: "",
        });
      } else {
        this.setState({
          file: e.target.files[0],
          fileError: "File size must less than 2MB",
        });
      }
    } else {
      this.setState({
        file: e.target.files[0],
        fileError: "File type not allowed",
      });
    }
  };

  reset = () => {
    this.setState({
      comment: "",
      file: [],
    });
  };

  onSubmit = () => {
    const { fileError } = this.state;

    let fd = new FormData();
    fd.append("reqComment", this.state.comment);
    fd.append("e360requestId", this.props.requestId);

    fd.append("attachmentPath", this.state.file);
    if (!fileError) {
      this.props.addRequestConversation(fd, this.reset, this.props.requestId);
    }
  };

  render() {
    const { comment, file, fileError } = this.state;

    return (
      <Form onSubmit={this.onSubmit} noValidate>
        <h5 className="pl-3">Chat</h5>
        <Row>
          <Col md="12" className="mx-auto pt-3">
            <FormControl fullWidth>
              <FilledInput
                id="standard-adornment-weight"
                type="textarea"
                name="comment"
                value={comment}
                onChange={this.onChange}
                aria-describedby="standard-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                placeholder="Type comment here"
              />
            </FormControl>
            <div className="file-div ">
              <input
                style={{ display: "none" }}
                id="icon-button-file"
                type="file"
                name="attachmentPath"
                onChange={this.handleFile}
              />
              <label>
                <label htmlFor="icon-button-file">
                  <IconButton
                    aria-label="attachment"
                    title="Attachment"
                    component="span"
                  >
                    <AttachmentIcon />
                  </IconButton>
                </label>
                {/* <IconButton aria-label="phone" title="Phone" component="span">
                  <PhoneIcon />
                </IconButton> */}
              </label>
              <IconButton
                aria-label="send"
                title="Send"
                component="span"
                onClick={() => this.onSubmit()}
              >
                <SendIcon color="primary" />
              </IconButton>
            </div>
            <FormText className="pl-2">{file.name}</FormText>
            {fileError && (
              <FormText className="pl-2 error">{fileError}</FormText>
            )}
          </Col>
        </Row>

        {this.props.requestConversation.message && (
          <Notifier message={this.props.requestConversation.message} />
        )}
        {this.props.requestConversation.error && (
          <ErrorNotifier message={this.props.requestConversation.error} />
        )}
      </Form>
    );
  }
}

SubmitRequest.propTypes = {
  addRequestConversation: PropTypes.func.isRequired,
  requestConversation: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  requestConversation: state.requestConversation,
});

export default connect(mapStateToProps, { addRequestConversation })(
  SubmitRequest
);
