import axios from "axios";
import {GET_CAN_EXP_RSTYPE, GET_CANDI_STATUS_MAIN } from "../../types";
import {
  ADD_CAN_STATUS_RS,
  GET_CAN_STATUS_RS,
  UPDATE_CAN_STATUS_RS,
  DELETE_CAN_STATUS_RS
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addCandidateStatus = (data, back) =>(dispatch) => {
  axios
    .post( ADD_CAN_STATUS_RS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back,getCandidateStatus));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getCandidateStatus = () => (dispatch) => {
  axios
    .get(  GET_CAN_STATUS_RS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(GET_CAN_EXP_RSTYPE, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
export const getCandidateStatusRs = () => (dispatch) => {
  axios
    .get(  `${GET_CAN_STATUS_RS}/select`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch( GET_CANDI_STATUS_MAIN, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateCandidateStatus = (data, back) => (dispatch) => {
  axios
    .put( UPDATE_CAN_STATUS_RS , data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteCandidateStatus = (data) => (dispatch) => {
  axios
    .delete(DELETE_CAN_STATUS_RS, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getCandidateStatus));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
