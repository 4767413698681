import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col, Form, FormGroup, FormText, Label, Input, Row } from "reactstrap";
import DateFns from "date-fns";
// component
import {
  submitValidator,
  valid,
  Validator,
  errors,
  refresh,
} from "../../../../common/Validator";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import Notifier from "../../../../aside/Notifier";
// redux
import { getCandidateStatusRs } from "../../../../../redux/actions/rs/configuration/candidateStausAction";
import { getHiringStatSelect } from "../../../../../redux/actions/rs/configuration/hiringStatus";
import { updateTagRequirement } from "../../../../../redux/actions/rs/requirements/tagCandidate/tagCandidate";

// --------------------------------------------------------------------------------

class UpdateCandidateKanban extends Component {
  constructor(props) {
    super(props);
    this.state = {
      candiStatus: this.props.data.candidateStatus ? this.props.data.candidateStatus:"" ,
      hiringStage: this.props.data.hiringStage ?  this.props.data.hiringStage:"",
      hiringStatus: this.props.data.hiringStatus ? this.props.data.hiringStatus:"",
      joiningDate: this.props.data.dateOfJoin === " " ? "": this.props.data.dateOfJoin,
      Tagremarks: this.props.data.comment      === " " ? "": this.props.data.comment,      
      hiringStagDate:this.props.data.hiringStagedate === " " ? "": this.props.data.hiringStagedate , 
      hiringStagRating:this.props.data.hiringStageRating === " " ? "": this.props.data.hiringStageRating,
      hiringFeedBack:this.props.data.hiringStagefeedback  === " " ? "": this.props.data.hiringStagefeedback,      
      filterHiringStage: [],
      filterHiringStatus: [],
      error: "",
    };
  }

  componentDidMount() {
    this.props.getHiringStatSelect();
    this.props.getCandidateStatusRs();
    refresh();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.hiringStatus.mainData !== this.props.hiringStatus.mainData) {
      this.props.hiringStatus.mainData.find((val) => {
        if (val.hiringStageName === this.state.hiringStage) {
          this.setState({
            filterHiringStatus: val.hiringStatus,
          });
        }
      });
    }
  }

  searchHiringStatus = (data) => {
    this.props.hiringStatus.mainData.find((val) => {
      if (val.hiringStageName === data) {
        this.setState({
          filterHiringStatus: val.hiringStatus,
        });
      }
    });
  };

  onChange = (e) => {
    Validator(e.target);
    if (e.target.name === "candiStatus") {
      if (e.target.value !== "Hiring Stage") {
        this.setState({
          candiStatus: e.target.value,
          hiringStatus: " ",
          hiringStage: " ",
        });
      } else if (e.target.value === "Hiring Stage") {
        this.searchHiringStatus("Profile Screening");
        this.setState({
          candiStatus: e.target.value,
          hiringStage: "Profile Screening",
          hiringStatus: "Shortlisted",
        });
      }
    } else if (e.target.name === "hiringStage") {
      this.searchHiringStatus(e.target.value);
      this.setState({
        hiringStage: e.target.value,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      id: Number(this.props.data.id),
      jobReqirementId: Number(this.props.data.jobRequirementId),
      canUserId: this.props.data.canUserId,
      canInfoId: Number(this.props.data.canInfoId),
      candidateSource: this.props.data.candidateSource,
      candidateStatus: this.state.candiStatus,
      comment: this.state.Tagremarks ?  this.state.Tagremarks :" " ,
      hiringStage: this.state.hiringStage ? this.state.hiringStage :" " ,
      hiringStatus: this.state.hiringStatus?  this.state.hiringStatus:" ",
      dateOfJoin: this.state.joiningDate ? this.state.joiningDate :" ",
      hiringStagedate:this.state.hiringStagDate ? this.state.hiringStagDate:" ",
      hiringStageRating:this.state.hiringStagRating ? this.state.hiringStagRating:" ",
      hiringStagefeedback:this.state.hiringFeedBack ? this.state.hiringFeedBack :" "
    };
    console.log(valid)
    console.log(errors)
    
    if (valid) {
      this.props.updateTagRequirement(
        data,
        this.props.back,
        Number(this.props.data.jobRequirementId)
      );
    } else {
      this.setState({
        error: "Please Enter Mandatory Fields",
      });
    }
  };

  render() {
    console.log(this.props.data)
    return (
      <>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="3"></Col>
            <Col md="6">
              {this.state.candiStatus !== "Hiring Stage" && (
                <FormGroup>
                  <Label className="bold label-sm">Candidate Status</Label>
                  <Input
                    type="select"
                    name="candiStatus"
                    className="form-control-sm"
                    value={this.state.candiStatus}
                    onChange={this.onChange}
                    placeholder="Candidate Status"
                    required={true}
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.CandidateStatus.mainData.map((item) => {
                      return (
                        <option key={item.id} value={item.candidateStatus}>
                          {item.candidateStatus}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.candiStatus && (
                    <FormText className="error">{errors.candiStatus}</FormText>
                  )}
                </FormGroup>
              )}
              {this.state.candiStatus === "Hiring Stage" && (
                <div style={{display:"flex"}}>
                <FormGroup style={{width:"50%"}}>
                  <Label className="bold label-sm"> Hiring Stage</Label>
                  <Input
                    type="select"
                    name="hiringStage"
                    className="form-control-sm"
                    value={this.state.hiringStage}
                    onChange={this.onChange}
                    placeholder="Hiring Stage"
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.hiringStatus.mainData.map((item) => {
                      return (
                        <option key={item.id} value={item.hiringStageName}>
                          {item.hiringStageName}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.hiringStage && (
                    <FormText className="error">{errors.hiringStage}</FormText>
                  )}
                </FormGroup>
                <FormGroup style={{width:"45%",marginLeft:"1.5rem"}}>
                  <Label className="bold label-sm">
                     Date
                  </Label>
                  <Input
                    type="date"
                    name="hiringStagDate"
                    className="form-control-sm"
                    value={this.state.hiringStagDate}
                    onChange={this.onChange}
                  
                  
                  ></Input>
                
                </FormGroup>

                </div>
              )}
              {this.state.candiStatus === "Hiring Stage" && (
                 <div style={{display:"flex"}}>
                <FormGroup  style={{width:"50%"}}>
                  <Label className="bold label-sm"> Hiring Status</Label>
                  <Input
                    type="select"
                    name="hiringStatus"
                    className="form-control-sm"
                    value={this.state.hiringStatus}
                    onChange={this.onChange}
                    placeholder="Hiring Status"
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.state.filterHiringStatus.length > 0 &&
                      this.state.filterHiringStatus.map((item) => {
                        return (
                          <option key={item.id} value={item.hiringStatus}>
                            {item.hiringStatus}
                          </option>
                        );
                      })}
                  </Input>
                  {errors.hiringStatus && (
                    <FormText className="error">{errors.hiringStatus}</FormText>
                  )}
                </FormGroup>
                <FormGroup style={{width:"45%",marginLeft:"1.5rem"}}>
                  <Label className="bold label-sm">
                     Rating
                  </Label>
                  <Input
                   type="select"
                   name="hiringStagRating"
                   className="form-control-sm"
                   value={this.state.hiringStagRating}
                   onChange={this.onChange}
               
                     >
                        <option value="" hidden>
                          Select...
                        </option>
                        <option value = "1">1</option>
                        <option value = "2">2</option>
                       <option  value = "3">3</option>
                        <option value  ="4">4</option>
                        <option value = "5">5</option>
                      
                      </Input>
                
                </FormGroup>
                </div>
              )}
              {(this.state.hiringStage === "Offered" ||
                this.state.hiringStage === "HR Discussion" ||
                this.state.hiringStage === "Manager Discussion") && (
                <FormGroup>
                  <Label className="bold label-sm">
                    Expected Date Of Joining
                  </Label>
                  <Input
                    type="date"
                    name="joiningDate"
                    className="form-control-sm"
                    value={this.state.joiningDate}
                    onChange={this.onChange}
                    placeholder="Expected Date Of Joining"
                    required={true}
                  ></Input>
                  {errors.joiningDate && (
                    <FormText className="error">{errors.joiningDate}</FormText>
                  )}
                </FormGroup>
              )}
                 <FormGroup>
                <Label className="bold label-sm">Feedback</Label>
                <Input
                  type="textarea"
                  name="hiringFeedBack"
                  className="form-control-sm"
                  value={this.state.hiringFeedBack}
                  onChange={this.onChange}
                  placeholder="Feedback"
                ></Input>
                  {errors.hiringFeedBack && (
                    <FormText className="error">{errors.hiringFeedBack}</FormText>
                  )}
               
              </FormGroup>
              <FormGroup>
                <Label className="bold label-sm">Comments</Label>
                <Input
                  type="textarea"
                  name="Tagremarks"
                  className="form-control-sm"
                  value={this.state.Tagremarks}
                  onChange={this.onChange}
                  placeholder="Comments"
                ></Input>
                 {errors.Tagremarks && (
                    <FormText className="error">{errors.Tagremarks}</FormText>
                  )}
               
              </FormGroup>
            </Col>
            <Col md="3"></Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn btn-custom" tabIndex="15">
              Submit
            </button>
          </div>
        </Form>
        {this.state.error && <ErrorNotifier message={this.state.error} />}
      </>
    );
  }
}

UpdateCandidateKanban.propType = {
  getCandidateStatusRs: PropTypes.func.isRequired,
  CandidateStatus: PropTypes.object.isRequired,
  getHiringStatSelect: PropTypes.func.isRequired,
  hiringStatus: PropTypes.object.isRequired,
  updateTagRequirement: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  CandidateStatus: state.CandidateStatus,
  hiringStatus: state.hiringStatus,
});

const mapDispatchToProps = (dispatch) => ({
  getCandidateStatusRs: () => dispatch(getCandidateStatusRs()),
  getHiringStatSelect: () => dispatch(getHiringStatSelect()),
  updateTagRequirement: (data, back, id) =>
    dispatch(updateTagRequirement(data, back, id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateCandidateKanban);
