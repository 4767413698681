import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { SEARCH_CANDI } from "../../../../utils/routes";
import { Container, Table } from "reactstrap";
import Addcandidate from "./addCandidate";
import TagRequirements from "./tagrequirement.js/tagRequirements";
import { getLatestCandidate } from "../../../../redux/actions/rs/candidateFilterView";
import SearchHover from "../../../aside/searchHover";
import Searchbar from "../../../common/searchbar/SearchBar";
import { connect } from "react-redux";
// reactstrap
import { Row, Col, Card } from "reactstrap";
import { data } from "jquery";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
// components
import CustTooltip from "../../../common/tooltip/CustTooltip";

//---------------------------------------------------
const codedData = {
  moduleName: "",
  data: [],
};

// candidate search component
const CandidateSearch = (props) => {
  const [changeError, setChangeError] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [dataar, setdataar] = useState([]);
  const [isAvailable, setIsAvailable] = useState("");
  const [show, setShow] = useState(false);
  const [canUserId, setCanUserId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [viewShow, setViewShow] = useState(false);
  const [canId, setCanId] = useState(null);
  const [viewTaggedShow, setViewTaggedViewShow] = useState(false);
  const [currentPermission, setCurrentPermission] = useState({});
  const [dataVal, setDataVal] = useState({ search: "" });

  const refe = React.useRef(null);
  let history = useHistory();

  let shrink = props.sidebar ? "scale" : "no-scale";

  // onchange function for search candidate
  const searchChange = (e) => {
    if (!e.target.value) {
      props.getLatestCandidate(dataVal);
      setdataar(props.CandidateViewRecordsRs.data);
    }
    setSearchValue(e.target.value);
    setChangeError("");
    setIsAvailable("");

    console.log(searchValue);
  };

  // submit function for search candidate
  const searchClick = () => {
    if (!searchValue) {
      setChangeError("Please enter keyword to search");
    } else {
      setChangeError("");
    }

    const data = {
      search: searchValue,
    };

    if (searchValue) {
      axios
        .post(`${SEARCH_CANDI}`, data, { withCredentials: true })
        .then((res) => {
          console.log(res.data);
          if (res.data.length > 0) {
            setdataar(res.data);
            setIsAvailable("");
          } else {
            setChangeError("");
            setIsAvailable("Candidate not found");
          }
          console.log(dataar);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // navigating to add form for candidate
  const toggleAddBtn = () => {
    setIsOpen(!isOpen);
    props.getLatestCandidate(dataVal);
    // getCollection()
  };

  // handle view
  const handleViewClick = (data, val) => {
    setViewTaggedViewShow(!viewTaggedShow);
    setCanId(data);
    setCanUserId(val);
    // setSearchValue("");
  };

  const backView = () => {
    setViewTaggedViewShow(false);
     searchValue ?  axios
     .post(`${SEARCH_CANDI}`, {search:searchValue}, { withCredentials: true })
     .then((res) => {
       console.log(res.data);
       if (res.data.length > 0) {
         setdataar(res.data);
         setIsAvailable("");
       } else {
         setChangeError("");
         setIsAvailable("Candidate not found");
       }
       console.log(dataar);
     })
     .catch((err) => {
       console.log(err);
     }):props.getLatestCandidate(dataVal);

  };

  // limiting skills validation

  const extractPrimarySkill = (data) => {
    return (
      data.length > 0 &&
      data.map((innerArr) => {
        if (innerArr.skill.split(",").length > 1) {
          return innerArr.skill.split(",").map((dataArr) => {
            return <span>{`${dataArr},`} &nbsp;</span>;
          });
        } else {
          return innerArr.skill.split(",").map((dataArr) => {
            return <span>{`${dataArr}`}</span>;
          });
        }
      })
    );
  };

  useEffect(() => {
    for (let a in props.auth.data.accesses) {
      if (props.auth.data.accesses[a].appName === "RS") {
        for (let m in props.auth.data.accesses[a].pieUserModuleAccess) {
          if (
            props.auth.data.accesses[a].pieUserModuleAccess[m].moduleName ===
            "Candidates"
          ) {
            for (let s in props.auth.data.accesses[a].pieUserModuleAccess[m]
              .pieUserServiceAccess) {
              if (
                props.auth.data.accesses[a].pieUserModuleAccess[m]
                  .pieUserServiceAccess[s].serviceName ===
                "Candidate Management"
              ) {
                setCurrentPermission(
                  props.auth.data.accesses[a].pieUserModuleAccess[m]
                    .pieUserServiceAccess[s]
                );
              }
            }
          }
        }
      }
    }
    props.getLatestCandidate(dataVal);
    setdataar(props.CandidateViewRecordsRs.data);
  }, []);

  useEffect(() => {
    setdataar(props.CandidateViewRecordsRs.data);
  }, [props.CandidateViewRecordsRs.data]);

  console.log(props.CandidateViewRecordsRs);
  return (
    <div className={shrink}>
      {!isOpen && (
        <Container className="card container-card">
          {Object.keys(currentPermission).length > 0 &&
            currentPermission.creation && (
              <div className="dive">
                {!isOpen && !viewTaggedShow && (
                  <CustTooltip title="Create Candidate">
                    <button
                      className="btn new-btn white-text label-sm"
                      onClick={toggleAddBtn}
                    >
                      Add
                    </button>
                  </CustTooltip>
                )}
              </div>
            )}
          {!isOpen && !viewTaggedShow && (
            <Searchbar
              onChange={searchChange}
              value={searchValue}
              placeholder="Search.."
              onSubmit={searchClick}
              title="search"
              onKeyPress={(e) => {
                if (e.code === "Enter") {
                  searchClick();
                }
              }}
              error={changeError}
              isAvailable={isAvailable}
            />
          )}

          {!isOpen && !viewTaggedShow ? (
            <Row className="pt-3 cust-head">
              <Col md="12" className="p-0">
                <ul className="crm-flex-around  ul-head">
                  <li
                    style={{ width: "8%", fontSize: ".875rem", fontWeight: "500" }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    Can Id
                  </li>
                  <li
                    style={{
                      width: "14%",
                      fontSize: ".875rem",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    Name
                  </li>
                  <li
                    style={{
                      width: "17%",
                      overflow: "hidden",
                      fontSize: ".875rem",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    {" "}
                    Email
                  </li>

                  <li
                    style={{
                      width: "10%",
                      fontSize: ".875rem",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    {" "}
                    Mobile No{" "}
                  </li>
                  <li
                    style={{
                      width: "25%",
                      fontSize: ".875rem",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    {" "}
                    Primary Skills
                  </li>
                  <li
                    style={{
                      width: "11%",
                      fontSize: ".875rem",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    {" "}
                    Yrs of Exp
                  </li>

                  <li
                    style={{
                      width: "13%",
                      fontSize: ".875rem",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    {" "}
                    Location
                  </li>
                </ul>
              </Col>
            </Row>
          ) : null}
          {!isOpen && !viewTaggedShow ? (
            <Row style={{ marginTop: "1rem" }} className="pt-3">
              {dataar.length > 0 &&
                dataar.map((item) => {
                  return (
                    <Col md="12" className="p-0" key={item.resReqId}>
                      <ul
                        onClick={() => handleViewClick(item.id, item.canUserId)}
                        className="rs-flex-around"
                      >
                        <li
                          style={{ width: "8%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.id}
                        </li>
                        <li
                          style={{ width: "14%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {`${item.firstName} ${item.lastName}`}
                        </li>

                        <li
                          style={{ width: "17%", overflow: "hidden" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.eMailId}
                        </li>

                        <li
                          style={{ width: "10%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.mobileNo}
                        </li>

                        <li
                          style={{ width: "25%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.canPrimarySkill.length > 0 &&
                            extractPrimarySkill(item.canPrimarySkill)}
                        </li>
                        <li
                          style={{ width: "11%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.yearOfExp}
                        </li>
                        <li
                          style={{ width: "13%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.location}
                        </li>
                      </ul>
                    </Col>
                  );
                })}
            </Row>
          ) : null}

          {viewTaggedShow && (
            <TagRequirements
              canUserId={canUserId}
              isToggleView={handleViewClick}
              toggle={toggleAddBtn}
              canId={canId}
              back={backView}
            />
          )}
        </Container>
      )}
      {isOpen && <Addcandidate toggle={toggleAddBtn} />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  CandidateViewRecordsRs: state.CandidateViewRecordsRs,
});

export default connect(mapStateToProps, {
  getLatestCandidate,
})(CandidateSearch);
