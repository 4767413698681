import React, { Component } from "react";
import { Form, Row, Col, FormGroup, Label, Input, FormText } from "reactstrap";
import { connect } from "react-redux";
import Select from "react-select";
import PropTypes, { array } from "prop-types";

import SelectCountryCode from "../requirements/selectAllCountryCode";
import { getSelectGovtId } from "../../../../redux/actions/rs/configuration/govtIdAction"

import axios from 'axios'
import { MdCancel } from "react-icons/md";

import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../common/Validator";
import FormComponent from "../../../common/FormComponent";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import DateFns from "date-fns";
import { getAllSkillsSelect } from "../../../../redux/actions/rs/configuration/canSkillsAction"
import { TAG_SEARCH_CANDI, CURRENT_MAIL_ID } from '../../../../utils/routes'
import CountryCode from '../requirements/selectCountryCode'

import { updateCandidateResume } from "../../../../redux/actions/rs/candidateResumeUplaod";
import { getQualificationRs } from "../../../../redux/actions/rs/configuration/qualificationaction";
import { getCanCountry } from "../../../../redux/actions/rs/configuration/candidateCountries";
import { getSkillGroup } from "../../../../redux/actions/rs/configuration/skillgroupaction"
import { getCanSkillSelect } from "../../../../redux/actions/rs/configuration/canSkillsAction"

import { getNoticePeriodSelect } from '../../../../redux/actions/rs/configuration/NoticePeriodAction'


let expErr = false
let relExpErr = false
let primarySkillGroupError = ""
let secondarySkillError = ""
// candidate creation component
let arrayOfSkills = [];
let secArrayOfSkills = [];
class UpdateCandidate extends Component {

  constructor(props) {
  
    super(props);
    this.state = {
      candiName:  this.props.canRecords[0].firstName ? this.props.canRecords[0].firstName : "",
      lastName: this.props.canRecords[0].lastName ? this.props.canRecords[0].lastName : "",
      country:  this.props.canRecords[0].country ? this.props.canRecords[0].country.split(",")[0] : "",
      mobile:  this.props.canRecords[0].mobileNo ? this.props.canRecords[0].mobileNo : "",
      gender: this.props.canRecords[0].gender ? this.props.canRecords[0].gender : "",
      location:this.props.canRecords[0].location ? this.props.canRecords[0].location : "",
      currentCtc: this.props.canRecords[0].canExpectation ? this.props.canRecords[0].canExpectation.currentCtc : "",
      experience:  this.props.canRecords[0].yearOfExp ? this.props.canRecords[0].yearOfExp : "",
      currentEmployer: this.props.canRecords[0].canExpectation ? this.props.canRecords[0].canExpectation.currentEmployeer : "",
      dialingContactNo: "",
      qualification: this.props.canRecords[0].canEducation ? this.props.canRecords[0].canEducation.candQual : "",
      years: this.props.canRecords[0].canEducation ? this.props.canRecords[0].canEducation.yearOfPassing : "",
      dob: this.props.canRecords[0].dob ? DateFns.format(this.props.canRecords[0].dob, "YYYY-MM-DD") : "",
      skillSets: "",
      secondarySkills: "",
      dynamicCurrency: "",
      noticePeriod: this.props.canRecords[0].canExpectation ? this.props.canRecords[0].canExpectation.noticePeriod : "",
      expectedCtc: this.props.canRecords[0].canExpectation ? this.props.canRecords[0].canExpectation.exepectedCtc : "",
      prefLocation: this.props.canRecords[0].canExpectation ? this.props.canRecords[0].canExpectation.preferredWorkLoction : "",
      alterDialingContactNo: this.props.canRecords[0].altDialingCode ? this.props.canRecords[0].altDialingCode : "",
      alternativeNo: this.props.canRecords[0].altContactNo ? this.props.canRecords[0].altContactNo : "",
      govtIdName: this.props.canRecords[0].govIdName ? this.props.canRecords[0].govIdName : "",
      govtIdNo: this.props.canRecords[0].govIdNo ? this.props.canRecords[0].govIdNo : "",
      releventYears: this.props.canRecords[0].canExpSummary ? this.props.canRecords[0]?.canExpSummary?.relYearOfExp  : "",
      notes: this.props.canRecords[0].canExpectation ? this.props.canRecords[0].canExpectation.notes : "",
      dynamicCurrency: "",
      noteError: "",
      nextPage: false,
      file: [],
      fileError: "",
      error: "",
      errExpData: "",
      relErrExpData: "",
      filSkillArray:[],
      secFilSkillArray:[],
      filteredValueArray: this.props.canRecords[0].canPrimarySkills.length > 0 ?
        this.props.canRecords[0].canPrimarySkills.map(data => {
         let skills = data.skill.split(",")
          skills.forEach((skill)=>{
           arrayOfSkills.push(skill)
          })
         
         } ):[],
         filteredSecondaryArray: this.props.canRecords[0].canSecondarySkills.length > 0 ?
         this.props.canRecords[0].canSecondarySkills.map(data => {
          let skills = data.skill.split(",")
           skills.forEach((skill)=>{
            secArrayOfSkills.push(skill)
           })
          
          } ):[],
          genderarr: [
            { key: "Male", value: "Male" },
            { key: "Female", value: "Female" },
            { key: "Others", value: "Others" },
          ],

      // filteredSecondaryArray: this.props.canRecords[0].canSecondarySkills.length > 0 ?
      //   this.props.canRecords[0].canSecondarySkills.map(data => data.skill.split(",")) : [],

        

    };
   

   

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleFile = this.handleFile.bind(this)
    this.closeDataSkill = this.closeDataSkill.bind(this)
    this.closeSecondaryDataSkill = this.closeSecondaryDataSkill.bind(this)
    this.handleSkillsChange = this.handleSkillsChange.bind(this)
    this.handleSecondarySkillsChange = this.handleSecondarySkillsChange.bind(this)
    this.isSkillsSet = this.isSkillsSet.bind(this)
    this.isSecSkillsSet = this.isSecSkillsSet.bind(this)
  }

  isSkillsSet(data){
  this.setState({
    filSkillArray:data
    })
  }

  isSecSkillsSet(data){
    this.setState({
      secFilSkillArray:data
      })
    }


  


  // ischecking(data){
  //   let emptyArray = []
  // for(let i = 0 ; i < data.length ; i++){
  // data[i].skill.split(",")
  // }
  // return data
  // }




  // onchange function
  onChange(e) {
    e.preventDefault();
    Validator(e.target);
    if (e.target.name === "releventYears") {
      let patt = new RegExp(/^\d{1,2}(\.\d{1,2})?$/)
      if (e.target.value) {
        let value = e.target.value
        if (patt.test(value)) {
          this.setState({
            [e.target.name]: e.target.value,
            relErrExpData: ""
          })
          relExpErr = false
        }
        else {
          this.setState({
            [e.target.name]: e.target.value,
            relErrExpData: "Please enter years in 00.0 format or Number",
          });
          errors.releventYears = ""
          relExpErr = true
        }
      }
      else {
        this.setState({
          [e.target.name]: e.target.value,
          relErrExpData: "",
        });
        errors.releventYears = ""
        relExpErr = false
      }
    }
    else {
      this.setState({
        [e.target.name]: e.target.value
      })
    }

    if (e.target.name === "experience") {
      if (e.target.value) {
        let value = e.target.value;
        let patt = new RegExp(/^\d{1,2}(\.\d{1,2})?$/);
        if (patt.test(value)) {
          this.setState({
            [e.target.name]: e.target.value,
            errExpData: "",
          });
          expErr = false;
        } else {
          this.setState({
            errExpData: "Please enter years in 00.0 format or Number",
          });
          errors.experience = "";
          expErr = true;
        }
      } else {
        this.setState({
          errExpData: "This field is mandatory",
        });
        errors.experience = "";
        expErr = true;
      }
    }

    if (e.target.name === "country") {
      this.setState({
        country: e.target.value
        // dialingCode: e.target.value.split(",")[1],
        // dynamicCurrency: e.target.value.split(",")[2],
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }


  }

  // onchange function  for handle file

  handleFile = (e) => {
    Validator(e.target);
    let allowedExtension = /(\.pdf)$/i;
    if (allowedExtension.exec(e.target.files[0].name)) {
      if ((e.target.files[0].size / 1024 / 1024).toFixed(2) < 2) {
        this.setState({
          file: e.target.files[0] || [],
          fileError: "",
        });


      } else {
        this.setState({
          file: e.target.files[0],
          fileError: "File size must less than 2MB",
        });
      }
    } else {
      this.setState({
        file: e.target.files[0],
        fileError: "Please attach PDF resume",
      });
    }
  };

  closeDataSkill(data) {
  
  let filterData=  this.state.filSkillArray.filter(val => val !== data)
  this.setState({
    filSkillArray:filterData
  })

   
  }

  closeSecondaryDataSkill(data) {
    let filterData = this.state.secFilSkillArray.filter(val => val !== data)
    this.setState({
      secFilSkillArray: filterData
    })
  }

  handleSkillsChange(skillSets) {

    let { filSkillArray } = this.state
    filSkillArray.push(skillSets.value)
    this.setState({
      filSkillArray: [...new Set(filSkillArray)]
    });
    primarySkillGroupError = ""
  }

  handleSecondarySkillsChange(secondarySkills) {
    let { secFilSkillArray } = this.state
    secFilSkillArray.push(secondarySkills.value)
    this.setState({
      secFilSkillArray: [...new Set(secFilSkillArray)]
    });
  }




  //  submit function for creating candidate

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);

  console.log("update comming")

    if (this.state.filSkillArray.length > 0) {
      primarySkillGroupError = ""
    }
    else {
      primarySkillGroupError = "Primary Skills is mandatory  "
    }
    if (!this.state.errExpData && !this.state.experience) {
      errors.experience = "This field is mandatory";
    } else {
      errors.experience = "";
    }
    if (this.state.relErrExpData) {
      errors.releventYears = ""
    }

    const { fileError } = this.state;

    let isNoticeNumber = this.state.noticePeriod
    let isExpectedNumber = Number(this.state.expectedCtc)
    let fd = new FormData();
    fd.append("canId", this.props.canRecords[0].id);
    this.props.canRecords[0].firstName && fd.append("firstName", this.props.canRecords[0].firstName);
    this.props.canRecords[0].lastName && fd.append("lastName", this.props.canRecords[0].lastName);
    this.props.canRecords[0].mobileNo && fd.append("mobileNo", this.props.canRecords[0].mobileNo);
    this.props.canRecords[0].dialingCode && fd.append("contactDialingCode", this.props.canRecords[0].dialingCode);
    this.props.canRecords[0].eMailId && fd.append("emailId", this.props.canRecords[0].eMailId);
   fd.append("gender", this.state.gender);
  this.state.dob &&  fd.append("dob", this.state.dob);
    fd.append("country", this.state.country);
    fd.append("location", this.state.location);
    fd.append("yearsOfExp", this.state.experience);
    fd.append("currentCtc", this.state.currentCtc )
    fd.append("altMobileNo", this.state.alternativeNo);
    fd.append("altDialingCode", this.state.alterDialingContactNo);
    fd.append("preferredLocation", this.state.prefLocation);
    fd.append("qualification", this.state.qualification);
    fd.append("yearOfPassing", this.state.years);
    fd.append("currentEmployeer", this.state.currentEmployer);
    this.state.releventYears != "NaN" &&  this.state.releventYears 
     && fd.append("relavantYearsOfExp",this.state.releventYears);
    this.state.govtIdName && this.state.govtIdNo ? fd.append("govIdName", this.state.govtIdName) : null;
    this.state.govtIdNo && this.state.govtIdName ? fd.append("govIdNo", this.state.govtIdNo) : null;
    fd.append("primarySkills", this.state.filSkillArray.toString());
    fd.append("secondarySkills", this.state.secFilSkillArray.toString());
    fd.append("noticePeriod", isNoticeNumber);
    fd.append("expectedCtc", isExpectedNumber);
    fd.append("notes", this.state.notes);
    fd.append("file", this.state.file);

    if (valid && !relExpErr &&  !expErr  &&  !primarySkillGroupError) {

      this.props.updateCandidateResume(fd, this.props.back);
    } else {
      this.setState({
        error: "Please enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }


  }

  /*
componentDidMount contains the error refresh method and here set the state for calling render method
*/
  componentDidMount() {
    this.isSkillsSet(arrayOfSkills)
    this.isSecSkillsSet(secArrayOfSkills)
    arrayOfSkills = []
    secArrayOfSkills = []
    this.props.getAllSkillsSelect()
    this.props.getNoticePeriodSelect();
    this.props.getSelectGovtId()
    this.props.getQualificationRs()
    this.props.getCanCountry()
  
    refresh();
    this.setState({
      message: "",
      error: "",
    });
    primarySkillGroupError = ""
  }



  render() {
    console.log(this.state.filSkillArray)
    let options3 = [];

    for (let i = 0; i < this.props.CanConSkill.allData.length; i++) {
      var x1 = {};
      x1["value"] = this.props.CanConSkill.allData[i].skill;
      x1["label"] = this.props.CanConSkill.allData[i].skill;
      options3.push(x1);

    }



    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Update Candidate : {`${this.props.canRecords[0].id} (${this.props.canRecords[0].firstName}  ${this.props.canRecords[0].lastName})`} </h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="4">
            <FormGroup>
                  <Label className="bold label-sm">Country</Label>
                  <Input
                    tabIndex="1"
                    type="select"
                    name="country"
                    className="form-control-sm"
                    value={this.state.country}
                    onChange={this.onChange}
                    placeholder="Country"
                    required={true}
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.CandiCountry.data.map((item) => {
                      return (
                        <option
                          key={item.id}
                          value={item.countryName}
                        >
                          {item.countryName}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.country && (
                    <FormText className="error">{errors.country}</FormText>
                  )}
                </FormGroup>
                <FormComponent
                  tabIndex="4"
                  labelClass="bold label-sm"
                  label="Current Location"
                  type="text"
                  name="location"
                  inputClass="form-control-sm"
                  value={this.state.location}
                  change={this.onChange}
                  required={true}
                  placeholder="Current Location"
                />
           

              <span className="bold label-sm">Alternate Contact Number</span>
              <div style={{ display: "flex" }}>
                <SelectCountryCode
                 tabIndex="8"
                  widthValue="4rem"
                  value={this.state.alterDialingContactNo}
                  // name="alterDialingContactNo"
                  onChange={(e) => {

                    return this.setState({ alterDialingContactNo: e.target.value });
                  }}
                />

                <div style={{ width: "100%" }}>
                  <FormComponent
                    tabIndex="9"
                    style={{ width: "100%" }}
                    labelClass="bold label-sm"
                    type="tel"
                    name="alternativeNo"
                    inputClass="form-control-sm"
                    value={this.state.alternativeNo}
                    pattern="[789][0-9]{9}"
                    change={this.onChange}
                    placeholder="Alternate Contact Number"
                  />
                </div>
              </div>
              <div style={{ width: "100%", display: "flex", }}>
                <div style={{ width: "100%" }}>
                  <FormGroup>
                    <Label className="bold label-sm">Govt Id Name</Label>
                    <Input
                      style={{ width: "85%" }}
                      tabIndex="12"
                      type="select"
                      name="govtIdName"
                      className="form-control-sm"
                      value={this.state.govtIdName}
                      onChange={this.onChange}


                    >
                      <option value="" hidden>
                        Select...
                      </option>

                      {this.props.GovtId.getAll.map((item) => {
                        return (
                          <option key={item.id} value={item.govtIdfName}>
                            {item.govtIdfName}{" "}

                          </option>
                        );
                      })}
                    </Input>

                    {errors.govtIdName && (
                      <FormText className="error">{errors.govtIdName}</FormText>
                    )}
                  </FormGroup>
                </div>

                <FormComponent
                  tabIndex="13"
                  labelClass="bold label-sm"
                  label="Id No."
                  type="number"
                  name="govtIdNo"
                  change={this.onChange}
                  value={this.state.govtIdNo}
                  placeholder="Id No."

                  maxlength="10"
                />
              </div>
              <div>
                  <span className="bold label-sm">
                    Current CTC
                   
                  </span>
                  <FormComponent
                    tabIndex="16"
                    labelClass="bold label-sm"
                    type="number"
                    name="currentCtc"
                    inputClass="form-control-sm"
                    value={this.state.currentCtc}
                    change={this.onChange}
                    required={true}
                    placeholder="Current CTC"
                  />
                </div>


            </Col>

            <Col md="4">
            <FormGroup>
                  <Label className="bold label-sm">Gender</Label>
                  <Input
                    tabIndex="2"
                    type="select"
                    name="gender"
                    className="form-control-sm"
                    value={this.state.gender}
                    onChange={this.onChange}
                    placeholder="Skill Group"
                    required={true}
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.state.genderarr.map((item) => {
                      return (
                        <option key={item.key} value={item.value}>
                          {item.value}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.gender && (
                    <FormText className="error">{errors.gender}</FormText>
                  )}
                </FormGroup>
                <FormComponent
                tabIndex="5"
                labelClass="bold label-sm"
                label="Preferred location"
                type="text"
                name="prefLocation"
                inputClass="form-control-sm"
                value={this.state.prefLocation}
                change={this.onChange}
                required={true}
                placeholder="Preferred Location"
              />
              <FormGroup>
                <Label className="bold label-sm">Notice Period(days/month)</Label>
                <Input
                  tabIndex="10"
                  type="select"
                  name="noticePeriod"
                  className="form-control-sm"
                  value={this.state.noticePeriod}
                  onChange={this.onChange}

                  required={true}
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {this.props.Noticeperiod.getData.map((item) => {
                    return (
                      <option key={item.id} value={item.noticePeriod}>
                        {item.noticePeriod}{" "}

                      </option>
                    );
                  })}
                </Input>
                {errors.noticePeriod && (
                  <FormText className="error">{errors.noticePeriod}</FormText>
                )}
              </FormGroup>
              <FormComponent
                  tabIndex="14"
                  labelClass="bold label-sm"
                  label="Years Of Exp."
                  type="number"
                  name="experience"
                  inputClass="form-control-sm"
                  value={this.state.experience}
                  change={this.onChange}
                  required={true}
                  placeholder="00.0 Format"
                  parentError={this.state.errExpData}
                />

              <div>
                <span className="bold label-sm">
                  Expected CTC{" "}
                 
                </span>
                <FormComponent
                  tabIndex="17"
                  labelClass="bold label-sm"
                  type="number"
                  name="expectedCtc"
                  inputClass="form-control-sm"
                  value={this.state.expectedCtc}
                  change={this.onChange}
                  required={true}
                  placeholder="Expected CTC"
                />
               
              </div>
              



            </Col>

            <Col md="4">
            <FormComponent
                  label="DOB"
                  tabIndex="3"
                  labelClass="bold label-sm"
                  type="date"
                  name="dob"
                  inputClass="form-control-sm"
                  value={this.state.dob}
                  change={this.onChange}
                />
                  <div style={{ width: "100%", display: "flex" }}>
                  <div style={{ width: "100%" }}>
                    <FormGroup>
                      <Label className="bold label-sm">Qualification</Label>
                      <Input
                        style={{ width: "85%" }}
                        tabIndex="6"
                        type="select"
                        name="qualification"
                        className="form-control-sm"
                        value={this.state.qualification}
                        onChange={this.onChange}
                        required={true}
                      >
                        <option value="" hidden>
                          Select
                        </option>
                        {this.props.QualificationRs.mainData.map((item) => {
                          return (
                            <option key={item.id} value={item.qualifictiontype}>
                              {item.qualifictiontype}{" "}
                            </option>
                          );
                        })}
                      </Input>
                      {errors.qualification && (
                        <FormText className="error">
                          {errors.qualification}
                        </FormText>
                      )}
                    </FormGroup>
                  </div>
                  <FormComponent
                    tabIndex="7"
                    labelClass="bold label-sm"
                    label="Year of Passing"
                    type="number"
                    name="years"
                    change={this.onChange}
                    value={this.state.years}
                    // required={true}
                    placeholder="Year of Passing"
                    // placeholder="Total Position"
                  />
                </div>
                <FormComponent
                  tabIndex="11"
                  labelClass="bold label-sm"
                  label="Current Employer"
                  type="text"
                  name="currentEmployer"
                  inputClass="form-control-sm"
                  value={this.state.currentEmployer}
                  change={this.onChange}
                  required={true}
                  placeholder="Current Employer"
                />
                 <FormComponent
                  tabIndex="15"
                  labelClass="bold label-sm"
                  label="Relevent Years of Exp."
                  type="number"
                  name="releventYears"
                  inputClass="form-control-sm"
                  value={this.state.releventYears}
                  change={this.onChange}
                  placeholder="00.0 Format"
                  parentError={this.state.relErrExpData}
                />

             
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label className="bold label-sm">Primary Skills</Label>
                <Select
                  tabIndex="18"
                  // name="options3"
                  value={this.state.skillSets}
                  onChange={this.handleSkillsChange}
                  options={options3}
                  required
                />
              </FormGroup>
              <span style={{ color: "red", fontSize: "12px" }}>{primarySkillGroupError}</span>
              {this.state.filSkillArray.length > 0 && 
              <div style={{ width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      border: "1px solid grey", }}>
                {
                  this.state.filSkillArray.map((data) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          minWidth: "4rem",
                          background: "#ECECEC",
                          border: "none",
                          height: "2rem",
                          marginTop: "0.1rem",
                          marginBottom: "0.1rem",
                          marginLeft: "0.5rem",
                          borderRadius: "20px",
                          paddingRight: "0.2rem",
                          paddingLeft: "0.2rem",
                        }}
                      >
                        <span style={{ marginRight: "4px",fontSize:".75rem" }}>{data}</span>

                        <h3
                          data-toggle="tooltip"
                          data-placement="top"
                          title="cancel"
                          onClick={() => this.closeDataSkill(data)}
                          style={{
                            color: "#04044c",
                          }}
                        >
                          {" "}
                          <MdCancel color="red" size="20" />{" "}
                        </h3>
                      </div>
                    );
                  })
                }
              </div>}
              <FormComponent
                tabIndex="20"
                labelClass="bold label-sm"
                label="Notes"
                type="textarea"
                name="notes"
                inputClass="form-control-sm"
                value={this.state.notes}
                change={this.onChange}
                placeholder="Notes"
              />
            </Col>

            <Col md="6">
              <FormGroup>
                <Label className="bold label-sm">Secondary Skills</Label>
                <Select
                  tabIndex="19"
                  // name="options2"
                  value={this.state.secondarySkills}
                  onChange={this.handleSecondarySkillsChange}
                  options={options3}
                  required
                />
              </FormGroup>

              {this.state.secFilSkillArray.length > 0 && 
                    <div style={{  width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      border: "1px solid grey", }}>
                {
                  this.state.secFilSkillArray.map((data) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          minWidth: "4rem",
                          background: "#ECECEC",
                          border: "none",
                          height: "2rem",
                          marginTop: "0.1rem",
                          marginBottom: "0.1rem",
                          marginLeft: "0.5rem",
                          borderRadius: "20px",
                          paddingRight: "0.2rem",
                          paddingLeft: "0.2rem",
                        }}
                      >
                        <span style={{fontSize:".75rem"}} >{data}</span>

                        <h3
                          data-toggle="tooltip"
                          data-placement="top"
                          title="cancel"
                          onClick={() => this.closeSecondaryDataSkill(data)}
                          style={{
                            color: "#04044c",
                          }}
                        >
                          {" "}
                          <MdCancel color="red" size="20" />{" "}
                        </h3>
                      </div>
                    );
                  })
                }
              </div>}
              <div className="file-div ">
                <FormGroup className="file-display mr-1">
                  <Label className="bold label-sm">Attach Resume</Label>
                  <Input
                    tabIndex="21"
                    disabled
                    name="personalPhoto"
                    // type="text"
                    // name="fileName"
                    value={this.state.file.name || ""}
                    className="form-control-sm "
                   
                  />
                  
                  {this.state.fileError && (
                    <FormText className="error ">
                      {this.state.fileError}
                    </FormText>
                  )}
                  {errors.personalPhoto && (
                    <FormText className="error">
                      {errors.personalPhoto}
                    </FormText>
                  )}
                </FormGroup>
                <FormGroup className="file-browse-btn-div">
                  <label className="btn label-sm file-browse-btn">
                    Browse
                    <Input
                      tabIndex="22"
                      type="file"
                      name="personalPhoto"
                      style={{
                        display: "none",
                        marginTop: "23px",
                        marginLeft: "-28px",
                      }}
                      className="form-control-sm file-input"
                      onChange={this.handleFile}
                    
                      accept=".pdf"
                    />
                  </label>
                </FormGroup>
              </div>

            </Col>
          </Row>
          <div className="center">
            <button tabIndex="23" type="submit" className="btn login-button white-text">
              Update
            </button>
          </div>
        </Form>
        {this.state.message && <Notifier message={this.state.message} />}
        {this.state.error && <ErrorNotifier message={this.state.error} />}
        {this.props.CanUplaodRs.message && (
          <Notifier message={this.props.CanUplaodRs.message} />
        )}
      </React.Fragment>
    );
  }
}

UpdateCandidate.propTypes = {
  addEmpAwards: PropTypes.func.isRequired,
  empAwards: PropTypes.object.isRequired,

};

const mapStateToProps = (state) => ({
  CandiCountry: state.CandiCountry,
  CanConSkill: state.CanConSkill,
  Noticeperiod: state.Noticeperiod,
  QualificationRs: state.QualificationRs,
  CanUplaodRs: state.CanUplaodRs,
  GovtId: state.GovtId,
  
});

export default connect(mapStateToProps, { getCanSkillSelect, 
   getAllSkillsSelect,
   getCanCountry, 
   getNoticePeriodSelect, 
   updateCandidateResume,
   getQualificationRs, 
   getSelectGovtId })(UpdateCandidate);
