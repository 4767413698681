function commonFlexComponent({data,label,children}) {
  return(
    <div style={{ display: "flex",marginTop:"0.5rem" }}>
    <div className="label-sm bold rs-req-span-label-col-1 pt-1">
    <span style={{fontSize:".875rem",fontWeight:"500"}}>{label}</span>
    </div>
    <div style={{ width: "6%", textAlign: "end" }}>
    <span className="label-sm">:</span>
    </div>
    <div style={{ width: "77%", paddingLeft: "1%" }}>
    <span style={{fontSize:".75rem ",fontWeight:"400"}}>
    {data}
    {children}
   </span>
   </div>
    </div>
  );
}

export default commonFlexComponent;