import {
    ADD_NEW_CAMPAIGN,
    GET_CAMPAIGN_LIST,
    UPDATE_CAMPAIGN_ITEM,
    SEARCH_CAMPAIGN_ITEM,
    SET_MESSAGE,
    GET_ERRORS
  } from '../actions/types'
  const initialState = {
    data: [],
    message: null,
    error: null
  };
  
  export default function(state = initialState, action){
    switch(action.type){
      case ADD_NEW_CAMPAIGN:
        return {
          ...state,
          data: action.payload
        }
      case GET_CAMPAIGN_LIST:
        return {
          ...state,
          data: action.payload
        };
      case UPDATE_CAMPAIGN_ITEM:
        return {
          ...state,
          data: action.payload
        }
      case SEARCH_CAMPAIGN_ITEM:
        return {
          ...state,
          data: action.payload
        }
      case SET_MESSAGE:
        return {
          ...state,
          message: action.payload
        }
      case GET_ERRORS: 
        return {
          ...state,
          error: action.payload
        }
      default:
        return state;
    }
  }