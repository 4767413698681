import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";
import { 
ADD_CAN_APPLY_JOBS ,
GET_CAN_APPLY_JOBS,
UPDATE_CAN_APPLY_JOBS,
DELETE_CAN_APPLY_JOBS
} from "../../../../utils/routes";
import { GET_TAGGED_CANDI_TRACKING } from "../../types";
import axios from "axios";


export const AddRequirementTracking = (data, back) => (dispatch) => {
  axios
    .post(ADD_CAN_APPLY_JOBS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getRequirementTracking = (data) => (dispatch) => {
  axios
    .get(`${GET_CAN_APPLY_JOBS}/requirements/${data}`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch( GET_TAGGED_CANDI_TRACKING, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateRequirementTracking = (data, back) => (dispatch) => {
  axios
    .put(UPDATE_CAN_APPLY_JOBS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteRequirementTracking = (data,back) => (dispatch) => {
    axios
      .delete(DELETE_CAN_APPLY_JOBS, { data, withCredentials: true })
      .then((res) => {
        dispatch(setSuccessMessage(res, null));
        dispatch(getAssignHr(back))
       })
      .catch((err) => {
        dispatch(setErrorMessage(err));
      });
  };
  
