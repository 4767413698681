import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
// components
import FormComponent from "../../../common/FormComponent";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../common/Validator";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import SearchSelectBoxWithAdd from "../../../common/selectbox/SearchSelectBoxWithAdd";
// redux
import {
  addCustomers,
  getCustomersSpocName,
} from "../../../../redux/actions/crm/customers/customersAction";
import { getUserCustomerDomain } from "../../../../redux/actions/crm/crm_setup/customerDomainAction";
import { getUserCustomerType } from "../../../../redux/actions/crm/crm_setup/customerTypeAction";
import { getUserCustomerGeographies } from "../../../../redux/actions/crm/crm_setup/customerGeographiesAction";
import { getUserCustomerCountries } from "../../../../redux/actions/crm/crm_setup/customerCountriesAction";

// ------------------------------------------------------------------------------------------------------------

class AddCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coName: "",
      domain: "",
      address: "",
      geography: "",
      country: "",
      state: "",
      location: "",
      pincode: "",
      contactId: "",
      fullName: "",
      contactEmail: "",
      dialingCode: "",
      contactNo: "",
      startDate: null,
      endDate: null,
      custType: "",
      comments: "",
      fileName: "",
      buttonValue: false,
      dateError: "",
      spocError: "",
      error: "",
      message: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    e.preventDefault();
    Validator(e.target);
    if (e.target.name === "endDate") {
      if (Date.parse(e.target.value) < Date.parse(this.state.startDate)) {
        this.setState({
          [e.target.name]: e.target.value,
          dateError: "EndDate must be greater than or equal to StartDate",
        });
      } else {
        this.setState({
          [e.target.name]: e.target.value,
          dateError: "",
        });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  handleContactNameChange = (value) => {
    this.setState({
      fullName: value && value.contactName,
      contactId: value && value.id,
      contactEmail: value && value.contactEmail,
      dialingCode: value && value.contactDialingCode,
      contactNo: value && value.contactMobileNo,
    });
  };

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let customer = {
      customerName: this.state.coName.toUpperCase(),
      businessDomain: this.state.domain,
      corporateAddress: this.state.address,
      geography: this.state.geography,
      country: this.state.country,
      state: this.state.state,
      location: this.state.location,
      pinCode: this.state.pincode,
      contactId: this.state.contactId,
      contactName: this.state.fullName,
      customerStatus: "New",
      agreementStartDate: this.state.startDate,
      agreementEndDate: this.state.endDate,
      customerType: this.state.custType,
      comments: this.state.comments,
    };
    if (valid && !this.state.dateError && this.state.fullName) {
      this.props.addCustomers(customer, this.props.history);
    } else {
      this.setState({
        error: "Enter mandatory fields",
        spocError: this.state.fullName ? "" : "This field is mandatory",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidMount contains the refresh method to refresh the errors data
*/
  componentDidMount() {
    refresh();
    this.props.getCustomersSpocName();
    this.props.getUserCustomerDomain();
    this.props.getUserCustomerType();
    this.props.getUserCustomerGeographies();
    this.props.getUserCustomerCountries();
    this.setState({
      message: "",
      error: "",
    });
  }

  resetSpocNameError = () => {
    this.setState({
      spocError: "",
    });
  };

  render() {
    let shrink;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");

    return (
      <div className={shrink}>
        <Container className="card container-card">
          <h5 className="center bold mt-2">Add Customer</h5>
          <Link to="/pie/crm/customers">
            <i
              className="material-icons pointer"
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                color: "#C00",
              }}
            >
              cancel
            </i>
          </Link>
          <Form onSubmit={this.onSubmit} className="p-3 mt-2" noValidate>
            <Row>
              <Col md="4">
                <FormComponent
                  labelClass="bold label-sm"
                  label="Customer Name"
                  type="text"
                  name="coName"
                  change={this.onChange}
                  value={this.state.coName}
                  placeholder="Customer Name"
                  required={true}
                  tabIndex="1"
                />
                <FormGroup>
                  <Label className="bold label-sm">Geography</Label>
                  <Input
                    type="select"
                    name="geography"
                    className="form-control-sm"
                    value={this.state.geography}
                    onChange={this.onChange}
                    placeholder="Geography"
                    required={true}
                    tabIndex="4"
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.customerGeographies.data.map((item) => {
                      return (
                        <option key={item.id} value={item.geoName}>
                          {item.geoName}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.geography && (
                    <FormText className="error">{errors.geography}</FormText>
                  )}
                </FormGroup>
                <FormComponent
                  labelClass="bold label-sm"
                  label="Location"
                  type="text"
                  name="location"
                  change={this.onChange}
                  value={this.state.location}
                  placeholder="Location"
                  required={true}
                  tabIndex="7"
                />
                <SearchSelectBoxWithAdd
                  options={this.props.customers.spocNameData}
                  value={this.state.fullName}
                  handleChange={(value) => this.handleContactNameChange(value)}
                  reset={this.resetSpocNameError}
                  spocError={this.state.spocError}
                  refresh={this.props.getCustomersSpocName}
                  label="SPOC Name"
                  tabIndex="10"
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Comments"
                  type="textarea"
                  name="comments"
                  value={this.state.comments}
                  change={this.onChange}
                  tabIndex="13"
                />
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label className="bold label-sm">Business Domain</Label>
                  <Input
                    type="select"
                    name="domain"
                    className="form-control-sm"
                    value={this.state.domain}
                    onChange={this.onChange}
                    required={true}
                    tabIndex="2"
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.customerDomain.data.map((item) => {
                      return (
                        <option key={item.id} value={item.domainName}>
                          {item.domainName}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.domain && (
                    <FormText className="error">{errors.domain}</FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">Country</Label>
                  <Input
                    type="select"
                    name="country"
                    className="form-control-sm"
                    value={this.state.country}
                    onChange={this.onChange}
                    placeholder="Country"
                    required={true}
                    tabIndex="5"
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.customerCountries.data.map((item) => {
                      if (this.state.geography === item.geoName) {
                        return (
                          <option key={item.id} value={item.countryName}>
                            {item.countryName}
                          </option>
                        );
                      }
                    })}
                  </Input>
                  {errors.country && (
                    <FormText className="error">{errors.country}</FormText>
                  )}
                </FormGroup>
                <FormComponent
                  labelClass="bold label-sm"
                  label="PinCode"
                  type="number"
                  name="pincode"
                  change={this.onChange}
                  value={this.state.pincode}
                  placeholder="PinCode"
                  tabIndex="8"
                />
                <ul className="CustDetails-ul">
                  <li>
                    <span className="label-sm bold customer-span-label-col-1">
                      Email
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.contactEmail}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold customer-span-label-col-1">
                      Mobile
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.state.dialingCode && `${this.state.dialingCode}-`}
                      {this.state.contactNo}
                    </span>
                  </li>
                </ul>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label className="bold label-sm">Customer Type</Label>
                  <Input
                    type="select"
                    name="custType"
                    value={this.state.custTyype}
                    className="form-control-sm"
                    onChange={this.onChange}
                    tabIndex="3"
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.customerType.data.map((item) => {
                      return (
                        <option key={item.id} value={item.custTypeName}>
                          {item.custTypeName}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.custType && (
                    <FormText className="error">{errors.custType}</FormText>
                  )}
                </FormGroup>
                <FormComponent
                  labelClass="bold label-sm"
                  label="State"
                  type="text"
                  name="state"
                  change={this.onChange}
                  value={this.state.state}
                  placeholder="State"
                  required={true}
                  tabIndex="6"
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Corporate Address"
                  type="text"
                  name="address"
                  change={this.onChange}
                  value={this.state.address}
                  placeholder="Corporate Address"
                  tabIndex="9"
                />
                <Row>
                  <Col md="6" className="p-0">
                    <FormComponent
                      labelClass="bold label-sm"
                      label="Agreement Start Date"
                      type="date"
                      name="startDate"
                      change={this.onChange}
                      value={this.state.startDate}
                      error={this.state.dateError}
                      tabIndex="11"
                    />
                  </Col>
                  <Col md="6" className="p-0">
                    <FormComponent
                      labelClass="bold label-sm"
                      label="Agreement End Date"
                      type="date"
                      name="endDate"
                      change={this.onChange}
                      value={this.state.endDate}
                      min={this.state.startDate}
                      error={this.state.dateError}
                      tabIndex="12"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="center">
              <button
                type="submit"
                className="btn center login-button white-text"
                tabIndex="14"
              >
                Submit
              </button>
            </div>
          </Form>
        </Container>
        {this.props.customers.message ? (
          <Notifier message={this.props.customers.message} />
        ) : null}
        {this.props.customers.error ? (
          <ErrorNotifier message={this.props.customers.error} />
        ) : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
      </div>
    );
  }
}

AddCustomer.propTypes = {
  addCustomers: PropTypes.func.isRequired,
  getCustomersSpocName: PropTypes.func.isRequired,
  customers: PropTypes.object.isRequired,
  getUserCustomerDomain: PropTypes.func.isRequired,
  customerDomain: PropTypes.object.isRequired,
  getUserCustomerType: PropTypes.func.isRequired,
  customerType: PropTypes.object.isRequired,
  getUserCustomerGeographies: PropTypes.func.isRequired,
  customerGeographies: PropTypes.object.isRequired,
  getUserCustomerCountries: PropTypes.func.isRequired,
  customerCountries: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  customers: state.customers,
  customerDomain: state.customerDomain,
  customerType: state.customerType,
  customerGeographies: state.customerGeographies,
  customerCountries: state.customerCountries,
});

export default connect(mapStateToProps, {
  addCustomers,
  getCustomersSpocName,
  getUserCustomerType,
  getUserCustomerDomain,
  getUserCustomerGeographies,
  getUserCustomerCountries,
})(AddCustomer);
