import axios from "axios";
import { GET_Years_expRSTYPE} from "../../types";
import {
  ADD_YEARSEXP_RS,
  GET_YEARSEXP_RS,
  UPDATE_YEARSEXP_RS,
  DELETE_YEARSEXP_RS
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addyearsExpRs = (data, back) => (dispatch) => {
  axios
    .post(ADD_YEARSEXP_RS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getyearsExpRs));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getyearsExpRs = () => (dispatch) => {
  axios
    .get( GET_YEARSEXP_RS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(GET_Years_expRSTYPE, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateyearsExpRs = (data, back) => (dispatch) => {
  axios
    .put(  UPDATE_YEARSEXP_RS , data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteyearsExpRs= (data) => (dispatch) => {
  axios
    .delete( DELETE_YEARSEXP_RS, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getyearsExpRs));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
