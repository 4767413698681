import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
// components
import { textChecker } from "../../../../common/FormValidator";
import FormComponent from "../../../../common/FormComponent";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
// redux
import { addCustomerDialingCode } from "../../../../../redux/actions/crm/crm_setup/customerDialingCodeAction";
import { getUserCustomerCountries } from "../../../../../redux/actions/crm/crm_setup/customerCountriesAction";

// --------------------------------------------------------------------------------------------------------------
class AddCustomerDialingCode extends Component {
  constructor() {
    super();
    this.state = {
      countryName: "",
      dialingCode: "",
      remarks: "",
      message: "",
      error: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  /* This onBlur function is giving validation to the AddDialingCode form
    for condition checking we used switch conditions
*/
  onBlur(e) {
    const { name, value } = e.target;
    let errors = this.state.formErrors;

    switch (name) {
      case "day":
        errors.day = textChecker.test(value)
          ? ""
          : "Use alphabets , numbers or spaces";
        break;
      case "occassion":
        errors.occassion = textChecker.test(value)
          ? ""
          : "Use alphabets , numbers or spaces";
        break;
      default:
        break;
    }
    this.setState({
      formErrors: errors,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    let data = {
      countryName: this.state.countryName,
      dialingCode: this.state.dialingCode,
      remarks: this.state.remarks,
    };
    this.props.addCustomerDialingCode(data, this.props.back);
  }

  componentDidMount() {
    this.props.getUserCustomerCountries();
  }

  render() {
    return (
      <React.Fragment>
        <h5 className="bold center pt-2">Add Customer Dialing Code</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>

        <Row>
          <Col md="2"></Col>
          <Col md="8">
            <Form className="p-3" onSubmit={this.onSubmit}>
              <FormGroup>
                <Label className="bold label-sm">Country</Label>
                <Input
                  type="select"
                  name="countryName"
                  className="form-control-sm"
                  value={this.state.countryName}
                  onChange={this.onChange}
                  required={true}
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {this.props.customerCountries.data.map((item) => {
                    return (
                      <option key={item.id} value={item.countryName}>
                        {item.countryName}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
              <FormComponent
                labelClass="bold label-sm"
                label="Dialing Code"
                type="number"
                name="dialingCode"
                inputClass="form-control-sm"
                value={this.state.dialingCode}
                change={this.onChange}
                required={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Remarks"
                type="textarea"
                name="remarks"
                inputClass="form-control-sm"
                value={this.state.remarks}
                change={this.onChange}
              />
              <div className="center">
                <button type="submit" className="btn login-button white-text">
                  Submit
                </button>
              </div>
            </Form>
          </Col>
        </Row>
        {this.props.customerDialingCode.message && (
          <Notifier message={this.props.customerDialingCode.message} />
        )}
        {this.props.customerDialingCode.error && (
          <ErrorNotifier message={this.props.customerDialingCode.error} />
        )}
      </React.Fragment>
    );
  }
}

AddCustomerDialingCode.propTypes = {
  addCustomerDialingCode: PropTypes.func.isRequired,
  customerDialingCode: PropTypes.object.isRequired,
  getUserCustomerCountries: PropTypes.func.isRequired,
  customerCountries: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  customerDialingCode: state.customerDialingCode,
  customerCountries: state.customerCountries,
});

export default connect(mapStateToProps, {
  addCustomerDialingCode,
  getUserCustomerCountries,
})(AddCustomerDialingCode);
