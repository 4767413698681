import axios from "axios";
import { GET_JON_TIMING_TYPE,GET_JOB_TIMING_SELECT  } from "../../types";
import {
  ADD_JOB_TIMING_API,
  GET_JOB_TIMING_API,
  UPDATE_JOB_TIMING_API,
  DELETE_JOB_TIMING_API
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addJobTiming = (data, back) => (dispatch) => {
  axios
    .post( ADD_JOB_TIMING_API, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back,  getJobTiming ));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getJobTiming = () => (dispatch) => {
  axios
    .get(  GET_JOB_TIMING_API, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(GET_JON_TIMING_TYPE, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
export const getJobTimingSelect = () => (dispatch) => {
  axios
    .get( `${GET_JOB_TIMING_API}/select`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch( GET_JOB_TIMING_SELECT , res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateJobTiming = (data, back) => (dispatch) => {
  axios
    .put(UPDATE_JOB_TIMING_API , data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteJobTiming = (data) => (dispatch) => {
  axios
    .delete(DELETE_JOB_TIMING_API, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null,  getJobTiming ));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
