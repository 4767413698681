import {
   PROSPECTS_GET,
   PROSPECTS_ADD,
   PROSPECTS_UPDATE,
   PROSPECTS_SEARCH,
    SET_MESSAGE
  } from '../actions/types'
  const initialState = {
    data: [],
    message: null
  };
  
  export default function(state = initialState, action){
    switch(action.type){
      case PROSPECTS_ADD:
        return {
          ...state,
          data: action.payload
        }
      case PROSPECTS_GET:
        return {
          ...state,
          data: action.payload
        };
      case PROSPECTS_UPDATE:
        return {
          ...state,
          data: action.payload
        }
      case PROSPECTS_SEARCH:
        return {
          ...state,
          data: action.payload
        }
      case SET_MESSAGE:
        return {
          ...state,
          message: action.payload
        }
      default:
        return state;
    }
  }