import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SEARCH_REQUIREMENT } from "../../../../utils/routes";
import { Container, Table,Button } from "reactstrap";
import SearchHover from "../../../aside/searchHover";
// import AddRequirement from "./addRequirement";
import { RsRequirements } from "../../../../utils/routes";
import ViewJobs from './viewJobs'
// import ViewRequirement from "./viewRequiremnt";
// reactstrap
import { Row, Col, Card } from "reactstrap";
import { data } from "jquery";

//---------------------------------------------------
const codedData = {
  moduleName: "",
  data: [],
};

const Jobs = (props) => {
  const [changeError, setChangeError] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [dataar, setdataar] = useState([]);
  const[isOpen,setIsOpen] = useState(false)
  const [isAvailable, setIsAvailable] = useState("");
  const [viewShow, setViewShow] = useState(false);
  const [requirementId,setRequirementId] = useState("")
  const[fullData,setFullData] = useState([])
  const[final,setFinal] = useState(null)

  const refe = React.useRef(null);

  console.log("chandrahashse");
  // console.log(props.location.data);
  // const { moduleName, data } = props.location;
  let shrink = props.sidebar ? "scale" : "no-scale";

  const searchChange = (e) => {
    setSearchValue(e.target.value);
    setChangeError("");
    setIsAvailable("");
    setdataar([]);
    console.log(searchValue);
  };

  const searchClick = () => {
    if (!searchValue) {
      setChangeError("Please enter keyword to search");
    } else if (searchValue.length <= 2) {
      setChangeError("Please enter valid keyword to search");
    } else {
      setChangeError("");
    }

    const data = {
      search: searchValue,
    };

    if (searchValue) {
      axios
        .post(`${SEARCH_REQUIREMENT}`, data, { withCredentials: true })
        .then((res) => {
          console.log(res.data);
          if (res.data.length > 0) {
            setdataar(res.data);
            setIsAvailable("");
          } else {
            setChangeError("");
            setIsAvailable("data is not available");
          }
          console.log(dataar);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const toggleAddBtn=()=>{
  setIsOpen(!isOpen)
  getCollection()
 
  }




  // const OnMouseCandihover = (e) => {
  //   setShow(true);
  //   console.log(e.target.closest(".opp-tableData"))
  //   let td = e.target.closest(".tablecandidaterow")
  //   console.log(e.target.closest(".opp-tableData").getBoundingClientRect())
  //   let rect = e.target.closest(".tablecandidaterow").getBoundingClientRect()

  
  //  console.log(rect.top)
  //  console.log(rect.left)
  //  console.log(td.querySelector(".tooltiptext"))
  //  td.querySelector(".tooltiptext").style.top = `${rect.top} px`
  //  td.querySelector(".tooltiptext").style.Right = `${rect.Right }px`
 
  // };

  const handleViewClick = (id) =>{
    console.log(id)
    setViewShow(!viewShow)
    setRequirementId(id)
  }

 


  useEffect(() => {
    getCollection()
  }, [])


const getCollection = () =>{
  axios.get(`${RsRequirements}`,{
    withCredentials:true
  }).then((res)=>{
    setFullData(res.data)
  }).catch((err)=>{
    console.log(err)
  })
}
const sizeAdjust = (data) => {
  const toShow = data && data.substring(0, 20) + "...";
  return toShow;
};


const isToggleView = () =>{
  setViewShow(!viewShow)
}



  return (
    <div className={shrink}>
      <Container className="card container-card">
        <div className="dive">
      
         </div>

       {
          !isOpen && !viewShow &&
          (<div
          style={{ height: "50px", width: "60%",margin:"auto",marginTop:"1rem" }}
          class="search-container"
        >
          <div style={{ display: "flex", height: "90%", width: "100%" }}>
            <input
              className="input-candisearch"
              value={searchValue}
              style={{ width: "100%", height: "100" }}
              onChange={searchChange}
              type="text"
              placeholder="Search.."
              name="search"
            />
            <button
              onClick={searchClick}
              style={{ border: "none", background: "#04044c", width: "80px" }}
              type="submit"
            >
              <i style={{ color: "white" }} class="fa fa-search"></i>
            </button>
          </div>
          <span style={{ fontSize: "14px", color: "red" }}>{changeError}</span>
        <span style={{ fontSize: "14px", color: "red" }}>{isAvailable}</span>
        </div>)
       }
     
     { !isOpen && !viewShow ? (
            <Row className="pt-3 cust-head">
              <Col md="12" className="p-0">
                <ul className="crm-flex-around  ul-head">
                  <li className="list-crm-item bold pad-rs-name">
                  Job Id
                  </li>
                  <li className="list-crm-item bold pad-rs-name">
                  Customer Name
                  </li>
                  <li className="list-crm-item bold pad-rs-name"> Job Type</li>
                  <li className="list-crm-item bold pad-rs-name">
                  Job Category
                  </li>
                  <li className="list-crm-item bold pad-rs-name"> Position</li>
                  <li className="list-crm-item bold pad-rs-name"> Job Description</li>
                </ul>
              </Col>
            </Row>
          ) : null}

        <Row   style={{marginTop:"1rem"}} className="pt-3">
            {!isOpen && !viewShow
              ? fullData &&
                fullData.map((item) => {
                  return (
                  
                    <Col md="12" key={item.resReqId}>
                      <ul
                      className="rs-flex-around"
                      onClick={()=>handleViewClick(item.id)}
                        // onClick={() => {
                        //   select(item);
                        // }}

                        // onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                        // onMouseLeave={this.toggleHoverOff}
                       
                       
                      >
                        <li className="list-crm-table-item pad-rs-name">
                          {item.id}
                        </li>
                        <li className="list-crm-table-item pad-rs-name">
                          {item.custName}
                        </li>
                        <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems:"center",
                              justifyContent:"center"
                            }}
                            className="uniqueone"
                          >
                            <td
                              useRef={refe}
                              style={{ border: "none" }}
                              className="opp-tableData"
                            >
                            
                              <div
                                // onClick={() => viewUpdatedResume(item.userId)}
                                className="tooltiptext"
                              >
                                {" "}
                                <span>
                                  {" "}
                                  <span
                                    style={{
                                      fontweight: "bold",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {" "}
                                    Job Location{" "}
                                  </span>
                                  : {item.jobLocation}{" "}
                                </span>
                                <span style={{ marginTop: "1rem" }}>
                                  {" "}
                                  <span
                                    style={{
                                      fontweight: "bold",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Years of Experience{" "}
                                  </span>{" "}
                                  : {item.jobCategory}{" "}
                                </span>
                                <span style={{ marginTop: "1rem" }}>
                                  {" "}
                                  <span
                                    style={{
                                      fontweight: "bold",
                                      fontSize: "14px",
                                    }}
                                  >
                                  SkillSets
                                  </span>{" "}
                                  : {item.skillSets}{" "}
                                </span>
                                <span style={{ marginTop: "1rem" }}>
                                  {" "}
                                  <span
                                    style={{
                                      fontweight: "bold",
                                      fontSize: "14px",
                                    }}
                                  >
                                  Total Positions
                                  </span>{" "}
                                  : {item.totalPositions}{" "}
                                </span>
                                <span style={{ marginTop: "1rem" }}>
                                  {" "}
                                  <span
                                    style={{
                                      fontweight: "bold",
                                      fontSize: "14px",
                                    }}
                                  >
                                Remarks
                                  </span>{" "}
                                  : {item.totalPositions}{" "}
                                </span>
                                <span style={{ marginTop: "1rem" }}>
                                  {" "}
                                  <span
                                    style={{
                                      fontweight: "bold",
                                      fontSize: "14px",
                                    }}
                                  >
                                  Requirement Status
                                  </span>{" "}
                                  : {item.reqStatus}{" "}
                                </span>
                              </div>
                            </td>
                          </div>
                        <li className="list-crm-table-item pad-rs-name">
                          {item.reqType}
                         
                        </li>
                       

                        <li className="list-crm-table-item pad-rs-name">
                          {item.jobCategory}
                        </li>
                        <li className="list-crm-table-item pad-rs-name">
                          {item.jobPosition}
                        </li>
                        <li className="list-crm-table-item pad-rs-name">
                          {item.JobDescription}
                        </li>
                      </ul>
                    </Col>
               
                  );
                })
              : null}

{!isOpen && !viewShow
              ? dataar &&
              dataar.map((item) => {
                  return (
                  
                    <Col md="12" key={item.resReqId}>
                      <ul
                      className="rs-flex-around"
                      onClick={()=>handleViewClick(item.id)}
                        // onClick={() => {
                        //   select(item);
                        // }}

                        // onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                        // onMouseLeave={this.toggleHoverOff}
                       
                       
                      >
                        <li className="list-crm-table-item pad-rs-name">
                          {item.id}
                        </li>
                        <li className="list-crm-table-item pad-rs-name">
                          {item.custName}
                        </li>
                        <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems:"center",
                              justifyContent:"center"
                            }}
                            className="uniqueone"
                          >
                            <td
                              useRef={refe}
                              style={{ border: "none" }}
                              className="opp-tableData"
                            >
                            
                              <div
                                // onClick={() => viewUpdatedResume(item.userId)}
                                className="tooltiptext"
                              >
                                {" "}
                                <span>
                                  {" "}
                                  <span
                                    style={{
                                      fontweight: "bold",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {" "}
                                    Job Location{" "}
                                  </span>
                                  : {item.jobLocation}{" "}
                                </span>
                                <span style={{ marginTop: "1rem" }}>
                                  {" "}
                                  <span
                                    style={{
                                      fontweight: "bold",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Years of Experience{" "}
                                  </span>{" "}
                                  : {item.jobCategory}{" "}
                                </span>
                                <span style={{ marginTop: "1rem" }}>
                                  {" "}
                                  <span
                                    style={{
                                      fontweight: "bold",
                                      fontSize: "14px",
                                    }}
                                  >
                                  SkillSets
                                  </span>{" "}
                                  : {item.skillSets}{" "}
                                </span>
                                <span style={{ marginTop: "1rem" }}>
                                  {" "}
                                  <span
                                    style={{
                                      fontweight: "bold",
                                      fontSize: "14px",
                                    }}
                                  >
                                  Total Positions
                                  </span>{" "}
                                  : {item.totalPositions}{" "}
                                </span>
                                <span style={{ marginTop: "1rem" }}>
                                  {" "}
                                  <span
                                    style={{
                                      fontweight: "bold",
                                      fontSize: "14px",
                                    }}
                                  >
                                Remarks
                                  </span>{" "}
                                  : {item.totalPositions}{" "}
                                </span>
                                <span style={{ marginTop: "1rem" }}>
                                  {" "}
                                  <span
                                    style={{
                                      fontweight: "bold",
                                      fontSize: "14px",
                                    }}
                                  >
                                  Requirement Status
                                  </span>{" "}
                                  : {item.reqStatus}{" "}
                                </span>
                              </div>
                            </td>
                          </div>
                        <li className="list-crm-table-item pad-rs-name">
                          {item.reqType}
                         
                        </li>
                       

                        <li className="list-crm-table-item pad-rs-name">
                          {item.jobCategory}
                        </li>
                        <li className="list-crm-table-item pad-rs-name">
                          {item.jobPosition}
                        </li>
                        <li className="list-crm-table-item pad-rs-name">
                          {item.JobDescription}
                        </li>
                      </ul>
                    </Col>
               
                  );
                })
              : null}
          </Row>
       


   
  
     {
    viewShow && <ViewJobs  requirementId={requirementId} isToggleView={isToggleView}  toggle ={toggleAddBtn}/>
     } 
        </Container>
    </div>
  );
};

export default Jobs;
