import React, { Component } from "react";
import { Container, Table } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DateFns from "date-fns";
// material ui
import { Box } from "@material-ui/core";
// components
import AddAccountManager from "./AddAccountManager";
import UpdateAccountManager from "./UpdateAccountManager";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import ConfirmDialog from "../../../../common/confirm-dialog/ConfirmDialog";
// redux
import {
  getAccountManager,
  deleteAccountManager,
} from "../../../../../redux/actions/crm/crm_setup/accountMangerAcction";

// -------------------------------------------------------------------------------------------

class AccountManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addBtn: false,
      updateBtn: false,
      updateData: [],
      confirmDialog: {
        isOpen: false,
        title: "",
        subTitle: "",
      },
    };

    this.toggleAddBtn = this.toggleAddBtn.bind(this);
    this.addBack = this.addBack.bind(this);
    this.toggleUpdateBtn = this.toggleUpdateBtn.bind(this);
    this.updateBack = this.updateBack.bind(this);
    this.getData = this.getData.bind(this);
    this.deleteAccountManager = this.deleteAccountManager.bind(this);
  }

  /*
toggleAddBtn function to toggle the addition page
*/
  toggleAddBtn() {
    this.setState({
      addBtn: true,
    });
  }

  /*
addBack function to back to the list page from the add page
*/
  addBack() {
    this.setState({
      addBtn: false,
    });

    this.getData();
  }

  /*
toggleUpdateBtn function to toggle the update form
*/
  toggleUpdateBtn(item) {
    this.setState({
      updateBtn: true,
      updateData: item,
    });
  }

  /*
updateBack function to redirect to back main page
*/
  updateBack() {
    this.setState({
      updateBtn: false,
    });

    this.getData();
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.props.getAccountManager();
  }

  deleteAccountManager(id) {
    let data = {
      id: id,
    };
    this.props.deleteAccountManager(data);
    this.setState((prevState) => ({
      ...prevState.confirmDialog,
      confirmDialog: {
        isOpen: false,
      },
    }));
  }

  setConfirmDialog = (obj) => {
    this.setState((prevProps) => ({
      confirmDialog: {
        ...prevProps.confirmDialog,
        isOpen: obj.isOpen,
      },
    }));
  };

  render() {
    const { addBtn, updateBtn, updateData } = this.state;
    let shrink = this.props.sidebar ? "scale" : "no-scale";

    return (
      <Box className={shrink}>
        <Container className="card container-card">
          {!addBtn && !updateBtn ? (
            <React.Fragment>
              {typeof this.props.location.serviceAccessData !== "undefined" &&
                this.props.location.serviceAccessData.creation && (
                  <button
                    className="btn add-button white-text label-sm"
                    onClick={this.toggleAddBtn}
                  >
                    Add
                  </button>
                )}
              <h5 className="bold center pt-2">Account Manager</h5>
              <Link
                to={{
                  pathname: "/pie/iam/service_menu",
                  moduleName: this.props.location.moduleName,
                  data: this.props.location.data,
                }}
              >
                <i className="material-icons cancel-button pointer">cancel</i>
              </Link>
              <Table className="center">
                <thead>
                  <tr>
                    <th className="opp-head ">Emp No.</th>
                    <th className="opp-head ">Emp Name</th>
                    <th className="opp-head ">Remarks</th>
                    <th className="opp-head ">CreatedBy</th>
                    <th className="opp-head ">CreatedOn</th>
                    <th className="opp-head ">UpdatedBy</th>
                    <th className="opp-head ">UpdatedOn</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.accountManager.data &&
                    this.props.accountManager.data.map((item) => {
                      return (
                        <tr>
                          <td className="opp-tableData">{item.empNo}</td>
                          <td className="opp-tableData">{item.empName}</td>
                          <td className="opp-tableData">{item.remarks}</td>
                          <td className="opp-tableData">{item.createdBy}</td>
                          <td className="opp-tableData">
                            {DateFns.format(item.createdOn, "DD-MM-YYYY")}
                          </td>
                          <td className="opp-tableData">{item.updatedBy}</td>
                          <td className="opp-tableData">
                            {DateFns.format(item.updatedOn, "DD-MM-YYYY")}
                          </td>
                          <td>
                            {typeof this.props.location.serviceAccessData !==
                              "undefined" &&
                              this.props.location.serviceAccessData
                                .updation && (
                                <span
                                  className="material-icons pointer opp-action-edit"
                                  style={{ color: "blue" }}
                                  onClick={() => this.toggleUpdateBtn(item)}
                                >
                                  edit
                                </span>
                              )}
                          </td>
                          <td>
                            {typeof this.props.location.serviceAccessData !==
                              "undefined" &&
                              this.props.location.serviceAccessData
                                .deletion && (
                                <i
                                  className="material-icons pointer"
                                  onClick={() =>
                                    this.setState({
                                      confirmDialog: {
                                        isOpen: true,
                                        title:
                                          "Are you sure to delete this record?",
                                        subTitle:
                                          "You can't undo this operation",
                                        onConfirm: () =>
                                          this.deleteAccountManager(item.id),
                                      },
                                    })
                                  }
                                  title="Delete Customer Type"
                                >
                                  delete
                                </i>
                              )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </React.Fragment>
          ) : null}
          {addBtn && <AddAccountManager back={this.addBack} />}
          {updateBtn && (
            <UpdateAccountManager back={this.updateBack} data={updateData} />
          )}
          {this.props.accountManager.message && (
            <Notifier message={this.props.accountManager.message} />
          )}
          {this.props.accountManager.error && (
            <ErrorNotifier message={this.props.accountManager.error} />
          )}
        </Container>
        <ConfirmDialog
          confirmDialog={this.state.confirmDialog}
          setConfirmDialog={this.setConfirmDialog}
        />
      </Box>
    );
  }
}

AccountManager.propTypes = {
  getAccountManager: PropTypes.func.isRequired,
  deleteAccountManager: PropTypes.func.isRequired,
  accountManager: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  accountManager: state.accountManager,
});

export default connect(mapStateToProps, {
  getAccountManager,
  deleteAccountManager,
})(AccountManager);
