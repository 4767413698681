import React, { Component } from "react";
import { Container, Table } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DateFns from "date-fns";
// Material ui
import { Box } from "@material-ui/core";
// components
import AddCustomerGeographies from "./AddCustomerGeographies";
import UpdateCustomerGeographies from "./UpdateCustomerGeographies";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import ConfirmDialog from "../../../../common/confirm-dialog/ConfirmDialog";
// redux
import {
  getCustomerGeographies,
  deleteCustomerGeographies,
} from "../../../../../redux/actions/crm/crm_setup/customerGeographiesAction";

// ---------------------------------------------------------------------------------------------------------------
class CustomerGeographies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addBtn: false,
      updateBtn: false,
      updateData: [],
      confirmDialog: {
        isOpen: false,
        title: "",
        subTitle: "",
      },
    };

    this.toggleAddBtn = this.toggleAddBtn.bind(this);
    this.addBack = this.addBack.bind(this);
    this.toggleUpdateBtn = this.toggleUpdateBtn.bind(this);
    this.updateBack = this.updateBack.bind(this);
    this.getData = this.getData.bind(this);
    this.deleteCustomerGeographies = this.deleteCustomerGeographies.bind(this);
  }

  /*
toggleAddBtn function to toggle the addition page
*/
  toggleAddBtn() {
    this.setState({
      addBtn: true,
    });
  }

  /*
addBack function to back to the list page from the add page
*/
  addBack() {
    this.setState({
      addBtn: false,
    });

    this.getData();
  }

  /*
toggleUpdateBtn function to toggle the update form
*/
  toggleUpdateBtn(item) {
    this.setState({
      updateBtn: true,
      updateData: item,
    });
  }

  /*
updateBack function to redirect to back main page
*/
  updateBack() {
    this.setState({
      updateBtn: false,
    });

    this.getData();
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.props.getCustomerGeographies();
  }

  deleteCustomerGeographies(id) {
    let data = {
      id: id,
    };
    this.props.deleteCustomerGeographies(data);
    this.setState((prevState) => ({
      ...prevState.confirmDialog,
      confirmDialog: {
        isOpen: false,
      },
    }));
  }

  setConfirmDialog = (obj) => {
    this.setState((prevProps) => ({
      confirmDialog: {
        ...prevProps.confirmDialog,
        isOpen: obj.isOpen,
      },
    }));
  };

  render() {
    const { addBtn, updateBtn, updateData } = this.state;
    let shrink = this.props.sidebar ? "scale" : "no-scale";

    return (
      <Box className={shrink}>
        <Container className="card container-card">
          {!addBtn && !updateBtn ? (
            <React.Fragment>
              {typeof this.props.location.serviceAccessData !== "undefined" &&
                this.props.location.serviceAccessData.creation && (
                  <button
                    className="btn add-button white-text label-sm"
                    onClick={this.toggleAddBtn}
                    title="Create Customer Geographies"
                  >
                    Add
                  </button>
                )}
              <h5 className="bold center pt-2">Customer Geographies</h5>
              <Link
                to={{
                  pathname: "/pie/iam/service_menu",
                  moduleName: this.props.location.moduleName,
                  data: this.props.location.data,
                }}
              >
                <i className="material-icons cancel-button pointer">cancel</i>
              </Link>
              <Table className="center">
                <thead>
                  <tr>
                    <th className="opp-head ">Geography</th>
                    <th className="opp-head ">Geo Code</th>
                    <th className="opp-head ">Color</th>
                    <th className="opp-head ">Remarks</th>
                    <th className="opp-head ">CreatedBy</th>
                    <th className="opp-head ">CreatedOn</th>
                    <th className="opp-head ">UpdatedBy</th>
                    <th className="opp-head ">UpdatedOn</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.customerGeographies.data &&
                    this.props.customerGeographies.data.map((item) => {
                      return (
                        <tr>
                          <td className="opp-tableData">{item.geoName}</td>
                          <td className="opp-tableData">{item.geoCode}</td>
                          <td className="opp-tableData">
                            <div
                              style={{
                                backgroundColor: item.color,
                                width: "20px",
                                height: "20px",
                              }}
                            ></div>
                          </td>
                          <td className="opp-tableData">{item.remarks}</td>
                          <td className="opp-tableData">{item.createdBy}</td>
                          <td className="opp-tableData">
                            {DateFns.format(item.createdOn, "DD-MM-YYYY")}
                          </td>
                          <td className="opp-tableData">{item.updatedBy}</td>
                          <td className="opp-tableData">
                            {DateFns.format(item.updatedOn, "DD-MM-YYYY")}
                          </td>
                          <td>
                            {typeof this.props.location.serviceAccessData !==
                              "undefined" &&
                              this.props.location.serviceAccessData
                                .updation && (
                                <span
                                  className="material-icons pointer opp-action-edit"
                                  style={{ color: "blue" }}
                                  onClick={() => this.toggleUpdateBtn(item)}
                                  title="Edit Customer Geographies"
                                >
                                  edit
                                </span>
                              )}
                          </td>
                          <td>
                            {typeof this.props.location.serviceAccessData !==
                              "undefined" &&
                              this.props.location.serviceAccessData
                                .deletion && (
                                <i
                                  className="material-icons pointer"
                                  onClick={() =>
                                    this.setState({
                                      confirmDialog: {
                                        isOpen: true,
                                        title:
                                          "Are you sure to delete this record?",
                                        subTitle:
                                          "You can't undo this operation",
                                        onConfirm: () =>
                                          this.deleteCustomerGeographies(
                                            item.id
                                          ),
                                      },
                                    })
                                  }
                                  title="Delete Customer Geographies"
                                >
                                  delete
                                </i>
                              )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </React.Fragment>
          ) : null}
          {addBtn && <AddCustomerGeographies back={this.addBack} />}
          {updateBtn && (
            <UpdateCustomerGeographies
              back={this.updateBack}
              data={updateData}
            />
          )}
          {this.props.customerGeographies.message && (
            <Notifier message={this.props.customerGeographies.message} />
          )}
          {this.props.customerGeographies.error && (
            <ErrorNotifier message={this.props.customerGeographies.error} />
          )}
        </Container>
        <ConfirmDialog
          confirmDialog={this.state.confirmDialog}
          setConfirmDialog={this.setConfirmDialog}
        />
      </Box>
    );
  }
}

CustomerGeographies.propTypes = {
  getCustomerGeographies: PropTypes.func.isRequired,
  deleteCustomerGeographies: PropTypes.func.isRequired,
  customerGeographies: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  customerGeographies: state.customerGeographies,
});

export default connect(mapStateToProps, {
  getCustomerGeographies,
  deleteCustomerGeographies,
})(CustomerGeographies);
