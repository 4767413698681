import {
    GET_PUBLISH_JOB_TYPERS,
    GET_JOB_APPROVAL,
    SEARCH_PUBLISH_JOBS,
    GET_ERRORS,
    EMPTY_RS_PUBLISH,
    SET_MESSAGE,
  } from "../../../../actions/types";
  
  const initialState = {
    data: [],
    message: null,
    error: null,
    approvedData:[]
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case   GET_PUBLISH_JOB_TYPERS:
        return {
          ...state,
          data: action.payload,
        };
        case  GET_JOB_APPROVAL:
          return {
            ...state,
            approvedData: action.payload,
          };
        case  SEARCH_PUBLISH_JOBS:
          return {
            ...state,
            data: action.payload,
          };
      case SET_MESSAGE:
        return {
          ...state,
          message: action.payload,
        };
      case GET_ERRORS:
        return {
          ...state,
          error: action.payload,
        };
        case EMPTY_RS_PUBLISH:
          return {
            ...state,
            error: action.payload,
          };
      default:
        return state;
    }
  }
  