import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import { MdCancel } from "react-icons/md";
import DateFns from "date-fns";

import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
// components
import FormComponent from "../../../common/FormComponent";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../common/Validator";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
// redux
import { AddRequirementAction } from "../../../../redux/actions/rs/requirements/requiremntsAction";
import { getRequirementStatus } from "../../../../redux/actions/rs/configuration/requirementStatusAction";
import { getRequirementStatusRs } from "../../../../redux/actions/rs/configuration/requirementStatusAction";
import { getreqType } from "../../../../redux/actions/rs/configuration/requirementTypeAction";
import { getreqTypeRs } from "../../../../redux/actions/rs/configuration/requirementTypeAction";
import { getRequiremnetType } from "../../../../redux/actions/rs/addRequirementListAction";
import { getBillingType } from "../../../../redux/actions/rs/viewbillingtypeaction";
import { getBillingRsTypeRs } from "../../../../redux/actions/rs/configuration/billingAction";
import { getCategorytype } from "../../../../redux/actions/rs/rscategoryaction";
import { getJobCategoryRs } from "../../../../redux/actions/rs/configuration/jobCategoryAction";
import { getPositionType } from "../../../../redux/actions/rs/viewPositionaction";
import { getJobPositionRs } from "../../../../redux/actions/rs/configuration/jobPositionAction";
import { getHrLead } from "../../../../redux/actions/rs/configuration/hrleadAction";
import { getCustomersName } from "../../../../redux/actions/crm/customers/customersAction";
import { getContactsRSName } from "../../../../redux/actions/crm/contacts/contactsAction";
import { getQualificationRs } from "../../../../redux/actions/rs/configuration/qualificationaction";
import { getQualification } from "../../../../redux/actions/rs/configuration/qualificationaction";
import { getAllSkillsSelect  } from "../../../../redux/actions/rs/configuration/canSkillsAction";
import { getJobTimingSelect } from "../../../../redux/actions/rs/configuration/jobTiming";
let errorCustomer = false;
let errorContact = false;
let errorCategory = false;
let errorPosition = false
let errorSkill = false;
let errorQualification = false
let primarySkillGroupError = "";
// main component
class AddRequirement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerName: null,
      customerBodyName: "",
      spocName: "",
      spocBodyContactName: "",
      spocContact: "",
      spocMail: "",
      reqType: "FTE",
      jobCategory: "",
      categoryBodyName:"",
      jobRole: "",
      positionBodyName:"",
      description: "",
      location: "",
      qualifications: "",
      qualificationBodyName:"",
      notes: "",
      critical: "High",
      timing: "",
      timingBodyName:"",
      custReqId: "",
      // leadRecriter: "",
      releventExp: "",
      skillSet: "",
      haveSkills: "",
      niceSkills: "",
      totalPos: "",
      duration: "",
      startDate: DateFns.format(this.currentDate(), "YYYY-MM-DD"),
      minYears: "",
      maxYears: "",
      endDate: DateFns.format(this.oneYearDate(), "YYYY-MM-DD"),
      billingType: "",
      currency: "",
      salaryRange: "",
      reqStatus: "",
      remarks: "",
      buttonValue: false,
      selectedOptionError: null,
      selectedOptionErrorOne: null,
      qualificationShowError:null,
      primarySkillErrorShow: null,
      secondarySkillErrorShow: null,
      categoryErrorShow:null,
      positionErrorShow:null,
      dateError: "",
      yearsError: "",
      filterContact: null,
      filteredPrimaryValueArray: [],
      filteredSecondaryValueArray: [],
      maxyearsError:"",
      error: "",
      message: "",
      crucialArr: [
        { key: "High", value: "High" },
        { key: "Medium", value: "Medium" },
        { key: "Low", value: "Low" },
      ],
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleCustomerChange = this.handleCustomerChange.bind(this);
    this.handleContactChange = this.handleContactChange.bind(this);
    this.handlePrimarySkillsChange = this.handlePrimarySkillsChange.bind(this);
    this.handleSecondarySkillsChange =
      this.handleSecondarySkillsChange.bind(this);
    this.closeDataPrimarySkill = this.closeDataPrimarySkill.bind(this);
    this.closeDataSecondarySkill = this.closeDataSecondarySkill.bind(this);
    this.currentDate = this.currentDate.bind(this);
    this.oneYearDate = this.oneYearDate.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this)
    this.handlePositionChange = this.handlePositionChange.bind(this)
    this.handleQualificationChange = this.handleQualificationChange.bind(this)
    this.handleTimingChange = this.handleTimingChange.bind(this)
  }

  // common onchange function

  onChange(e) {
    e.preventDefault();
    Validator(e.target);
    if (e.target.name === "minYears" ) {
      let numberPattern = '^[0-9]*$';
      if ( e.target.value<=60 && e.target.value.match(numberPattern)) {
        this.setState({
          [e.target.name]: e.target.value,
          yearsError: "",
         
        });
      } else {
        this.setState({
          [e.target.name]: e.target.value,
          yearsError: "Years must be number and  lesser than or equal to 60",
        });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }

    if (e.target.name === "maxYears" ) {
      let numberPattern = '^[0-9]*$';
      if ( e.target.value<=60 && e.target.value.match(numberPattern)) {
        this.setState({
          [e.target.name]: e.target.value,
          maxyearsError: "",
        });
      } else {
        this.setState({
          [e.target.name]: e.target.value,
          maxyearsError: "Years must be number and lesser than or equal to 60",
      
        });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }

    if(e.target.name === "description"){
      this.setState({
        [e.target.name]:e.target.value
      })
     
    }

    if (e.target.name === "endDate") {
      if (Date.parse(e.target.value) < Date.parse(this.state.startDate)) {
        this.setState({
          [e.target.name]: e.target.value,
          dateError: "End Date must be greater than or equal to Start Date",
        });
      } else {
        this.setState({
          [e.target.name]: e.target.value,
          dateError: "",
        });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  currentDate() {
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    return date;
  }

  oneYearDate() {
    var today = new Date();
    var date =
      today.getFullYear() +
      1 +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    return date;
  }

  // customerName onchange event

  handleCustomerChange(customerName) {
    this.setState({
      customerBodyName: customerName.value,
    });
    this.props.getContactsRSName(customerName.singleValue);
    if (customerName) {
      this.setState({ customerName, selectedOptionError: null });
    } else {
      this.setState({
        selectedOptionError: "This field is mandatory",
      });
    }
  }

  handlePositionChange(jobRole) {
    this.setState({
      positionBodyName: jobRole.value,
    });
  
    if (jobRole) {
      this.setState({jobRole, positionErrorShow: null });
    } else {
      this.setState({
        positionErrorShow: "This field is mandatory",
      });
    }
  }

  handleQualificationChange(qualifications) {
    this.setState({
      qualificationBodyName: qualifications.value,
    });
  
    if (qualifications) {
      this.setState({qualifications, qualificationShowError: null });
    } else {
      this.setState({
        qualificationShowError: "This field is mandatory",
      });
    }
  }

  handleTimingChange(timing) {
    this.setState({
      timingBodyName: timing.value,
    });
  
    if (timing) {
      this.setState({timing});
    }
  }

  handleCategoryChange(jobCategory) {
    console.log(jobCategory)
    this.setState({
      categoryBodyName: jobCategory.value,
    });

    if (jobCategory) {
      this.setState({ jobCategory, categoryErrorShow: null });
    } else {
      this.setState({
        categoryErrorShow: "This field is mandatory",
      });
    }
  }

  // onChange event when spocNameChnging

  handleContactChange(spocName) {
    this.setState({
      selectedOptionErrorOne: "",
      spocContact: spocName.mobile,
      spocMail: spocName.email,
      spocBodyContactName: spocName.label,
    });

    if (spocName) {
      this.setState({
        spocName,
        selectedOptionErrorOne: null,
      });
    } else {
      this.setState({
        selectedOptionErrorOne: "This field is mandatory",
      });
    }
  }

  handlePrimarySkillsChange(skillSet) {
    this.setState({
      primarySkillErrorShow: null,
    });

    let { filteredPrimaryValueArray } = this.state;
    filteredPrimaryValueArray.push(skillSet.value);
    this.setState({
      filteredPrimaryValueArray: [...new Set(filteredPrimaryValueArray)],
    });
   
    primarySkillGroupError = "";

    if (skillSet) {
      this.setState({ haveSkills: "", buttonAction: false });
    }
    primarySkillGroupError = "";
  }

  
  handleSecondarySkillsChange(skillSet) {
    this.setState({
      secondarySkillErrorShow: null,
    });

    let { filteredSecondaryValueArray } = this.state;
    filteredSecondaryValueArray.push(skillSet.value);
    skillSet.value = ""
    this.setState({
      filteredSecondaryValueArray: [...new Set(filteredSecondaryValueArray)],
    });
    primarySkillGroupError = "";
   

    if (skillSet) {
      this.setState({ niceSkills: "", buttonAction: false });
    }
    primarySkillGroupError = "";
  }

  closeDataPrimarySkill(data) {
    console.log(data);

    let filterData = this.state.filteredPrimaryValueArray.filter(
      (val) => val !== data
    );
    this.setState({
      filteredPrimaryValueArray: filterData,
    });
  }

  closeDataSecondarySkill(data) {
    console.log(data);

    let filterData = this.state.filteredSecondaryValueArray.filter(
      (val) => val !== data
    );
    this.setState({
      filteredSecondaryValueArray: filterData,
    });
  }

  extractPrimarySkill(data){
    console.log(data)
    if(data.length>1){
       return data.join(",  ").toString()
        }
        else{
      return  data.toString()
    }
     }

  // addRequirement

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);

    if (!this.state.customerBodyName) {
      this.setState({
        selectedOptionError: "This field is mandatory",
      });
      errorCustomer = true;
    } else {
      this.setState({
        selectedOptionError: "",
      });
      errorCustomer = false;
    }

    if (!this.state.spocBodyContactName) {
      this.setState({
        selectedOptionErrorOne: "This field is mandatory",
      });
      errorContact = true;
    } else {
      this.setState({
        selectedOptionErrorOne: "",
      });
      errorContact = false;
    }
    if (!this.state.filteredPrimaryValueArray.length) {
      this.setState({
        primarySkillErrorShow: "This field is mandatory",
      });
      errorSkill = true;
    } else {
      this.setState({
        primarySkillErrorShow: "",
      });
      errorSkill = false;
    }
  

    if (!this.state.categoryBodyName) {
      this.setState({
        categoryErrorShow: "This field is mandatory",
      });
      errorCategory = true;
    } else {
      this.setState({
        categoryErrorShow: "",
      });
      errorCategory = false;
    }

    if (!this.state.positionBodyName) {
      this.setState({
        positionErrorShow: "This field is mandatory",
      });
      errorPosition = true;
    } else {
      this.setState({
        positionErrorShow: "",
      });
      errorPosition = false;
    }
    if (!this.state.qualificationBodyName) {
      this.setState({
        qualificationShowError: "This field is mandatory",
      });
      errorQualification = true;
    } else {
      this.setState({
        qualificationShowError: "",
      });
      errorQualification = false;
    }

    let body = {
      custReqId: Number(this.state.custReqId),
      custName: this.state.customerBodyName,
      tacName: this.state.spocBodyContactName,
      tacConNum: Number(this.state.spocContact),
      tacEmail: this.state.spocMail,
      jobReqType: this.state.reqType,
      jobCategory: this.state.categoryBodyName,
      jobPosition: this.state.positionBodyName,
      qualifications: this.state.qualificationBodyName,
      billingType: this.state.billingType,
      reqStatus: this.state.reqStatus,
      // skillSets: this.state.filteredValueArray.toString(),
      jobPriority: this.state.critical,
      totalPositions: this.state.totalPos,
      minYearsOfExp: Number(this.state.minYears),
      maxYearsOfExp: Number(this.state.maxYears),
      jobLocation: this.state.location,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      maxCtc: this.state.salaryRange,
      primarySkills: this.state.filteredPrimaryValueArray.length>0 ?
      this.extractPrimarySkill(this.state.filteredPrimaryValueArray):"",
      secondarySkills: this.state.filteredSecondaryValueArray.length>0 ? 
      this.extractPrimarySkill(this.state.filteredSecondaryValueArray):"",
      jobDescription: this.state.description,
      jobTimings: this.state.timingBodyName,
      comments: this.state.notes,
    };
    
    console.log(valid)
    console.log(this.state.dateError)
    console.log(errorContact)
    console.log(errorCustomer)
    console.log(errorSkill)
    console.log(errorPosition)
    console.log(errorCategory)
    console.log(errorQualification)
    console.log(this.state.yearsError)
    console.log(this.state.maxyearsError)
    console.log(errors)

    if (
      valid &&
      !this.state.dateError &&
      !errorContact &&
      !errorCustomer &&
      !errorSkill &&
      !errorPosition&&
      !errorCategory&&
      !errorQualification&&
      !this.state.yearsError&&
      !this.state.maxyearsError
    ) {
      this.props.AddRequirementAction(body, this.props.toggle);
    } else {
      this.setState({
        error: "Please enter mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

 

  /*
componentDidMount contains the refresh method to refresh the errors data
*/
  componentDidMount() {
    console.log(this.props.jobTiming);
    refresh();
    this.props.getCustomersName();
    this.props.getAllSkillsSelect();
    this.props.getreqTypeRs();
    this.props.getJobCategoryRs();
    this.props.getJobPositionRs();
    this.props.getQualificationRs();
    this.props.getBillingRsTypeRs();
    this.props.getRequirementStatusRs();
    this.props.getJobTimingSelect();

    this.setState({
      message: "",
      error: "",
      // categoryBodyName: this.props.JobCategory.mainData.length>0 && this.props.JobCategory.mainData[0].jobCatName
  });
  }

  // componentDidUpdate(prevProps,prevState){
   
  // if(prevProps.JobCategory.mainData !== this.props.JobCategory.mainData){
  //   console.log(this.props.JobCategory.mainData)
  //   let obj = {}
  //   obj["value"] = this.props.JobCategory.mainData[0].jobCatName
  //   obj["label"] = this.props.JobCategory.mainData[0].jobCatName
  //   this.setState({
  //     categoryBodyName:obj,
    
  //   })
  // }
  // }

  render() {
    console.log(this.props.CanConSkill.data);
    console.log(this.state.categoryBodyName);

    let options3 = [];
    for (let i = 0; i < this.props.customers.nameData.length; i++) {
      var x1 = {};
      x1["value"] = this.props.customers.nameData[i].customerName;
      x1["singleValue"] = `${this.props.customers.nameData[i].customerName}`;
      x1[
        "label"
      ] = `${this.props.customers.nameData[i].customerNo}-${this.props.customers.nameData[i].customerName}`;

      options3.push(x1);
    }
    console.log(options3);

    let options4 = [];
    for (let i = 0; i < this.props.crmContacts.nameData.length; i++) {
      var x1 = {};
      x1["value"] = this.props.crmContacts.nameData[i].id;
      x1["label"] = `${this.props.crmContacts.nameData[i].contactName}`;
      x1["email"] = this.props.crmContacts.nameData[i].contactEmail;
      x1["mobile"] = this.props.crmContacts.nameData[i].contactMobileNo;

      options4.push(x1);
    }
    console.log(this.props.CanConSkill);

    let options5 = [];
    for (let i = 0; i < this.props.CanConSkill.allData.length; i++) {
        var x3 = {};
        x3["value"] = this.props.CanConSkill.allData[i].skill;
        x3["label"] = this.props.CanConSkill.allData[i].skill;
        options5.push(x3);
      
      }
     let options6 = []
      for (let i = 0; i < this.props.JobCategory.mainData.length; i++) {
        var x4 = {};
        x4["value"] = this.props.JobCategory.mainData[i].jobCatName;
        x4["label"] = this.props.JobCategory.mainData[i].jobCatName;

        options6.push(x4);
      }
     

      let options7 = []
      for (let i = 0; i < this.props.JobPosition.mainData.length; i++) {
        var x5 = {};
        x5["value"] = this.props.JobPosition.mainData[i].jobPositionName;
        x5["label"] = this.props.JobPosition.mainData[i].jobPositionName;

        options7.push(x5);
      }
      let options8 = []
      for (let i = 0; i < this.props.QualificationRs.mainData.length; i++) {
        var x6 = {};
        x6["value"] = this.props.QualificationRs.mainData[i].qualifictiontype;
        x6["label"] = this.props.QualificationRs.mainData[i].qualifictiontype;

        options8.push(x6);
      }

      let options9 = []
      for (let i = 0; i < this.props.jobTiming.mainData.length; i++) {
        var x7 = {};
        x7["value"] =  this.props.jobTiming.mainData[i].jobHours;
        x7["label"] =  this.props.jobTiming.mainData[i].jobHours;

        options9.push(x7);
      }


      
    let shrink;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");

    return (
      <div className={shrink}>
        <Container className="card container-card">
          <i
            onClick={this.props.toggle}
            className="material-icons pointer"
            style={{
              position: "absolute",
              top: "5px",
              right: "5px",
              color: "#C00",
            }}
          >
            cancel
          </i>
          <h5 className="bold center mb-4">Add Requirement</h5>

          <Form onSubmit={this.onSubmit} noValidate>
            <Row>
              <Col md="4">
                <FormGroup>
                  <Label className="bold label-sm">Customer Name</Label>
                  <Select
                    tabIndex="1"
                  
                    value={this.state.customerName}
                    onChange={this.handleCustomerChange}
                    options={options3}
                    required
                  />
                    {this.state.selectedOptionError && (
                  <FormText className="error">
                    {this.state.selectedOptionError}
                  </FormText>
                )}

                </FormGroup>
              
                <FormGroup>
                  <Label className="bold label-sm">Requirement Type</Label>
                  <Input
                    tabIndex="3"
                    type="select"
                    name="reqType"
                    className="form-control-sm"
                    value={this.state.reqType}
                    onChange={this.onChange}
                    placeholder="Requirement Type"
                    required={true}
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.ReqType.Maindata.map((item) => {
                      return (
                        <option key={item.reqTypeId} value={item.reqTypeName}>
                          {item.reqTypeName}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.reqType && (
                    <FormText className="error">{errors.reqType}</FormText>
                  )}
                </FormGroup>

                <FormGroup>
                  <Label className="bold label-sm">Job Qualification</Label>
                  <Select
                  tabIndex="6"
                  className="select-form-control-sm"
                
                  
                    value={this.state.qualifications}
                    onChange={this.handleQualificationChange}
                    options={options8}
                    required
                  />
                   {this.state.qualificationShowError && (
                  <FormText style={{marginTop:"0.5rem"}} className="error">
                    {this.state.qualificationShowError}
                  </FormText>
                )}
                </FormGroup>

              
                 <FormGroup>
                  <Label className="bold label-sm">Job Timing</Label>
                  <Select
                
                  className="select-form-control-sm"
                   tabIndex="9"
                  
                    value={this.state.timing}
                    onChange={this.handleTimingChange}
                    options={options9}
                    required
                  />
                  
                </FormGroup>
                <Row>
                  <Col md="6" className="p-0">
                    <FormComponent
                      tabIndex="13"
                      labelClass="bold label-sm"
                      label="Start Date"
                      type="date"
                      name="startDate"
                      change={this.onChange}
                      value={this.state.startDate}
                      // required={true}
                    />
                  </Col>
                  <Col md="6" className="pr-0">
                    <FormComponent
                      tabIndex="14"
                      labelClass="bold label-sm"
                      label="End Date"
                      type="date"
                      name="endDate"
                      change={this.onChange}
                      value={this.state.endDate}
                      min={this.state.startDate}
                      error={this.state.dateError}
                      // required={true}
                    />
                  </Col>
                </Row>
              </Col>

              <Col md="4">
                <FormGroup>
                  <Label className="bold label-sm">TAC Name</Label>
                  <Select
                    tabIndex="2"
                   
                    value={this.state.spocName}
                    onChange={this.handleContactChange}
                    options={options4}
                    required
                  />
                  {this.state.selectedOptionErrorOne && (
                    <FormText className="error">
                      {this.state.selectedOptionErrorOne}
                    </FormText>
                  )}
                </FormGroup>
               
                 <FormGroup>
                  <Label className="bold label-sm">Job Category</Label>
                  <Select
                
                  className="select-form-control-sm"
                   tabIndex="4"
                    
                    value={this.state.jobCategory}
                    onChange={this.handleCategoryChange}
                    options={options6}
                    required
                  />
                   {this.state.categoryErrorShow && (
                  <FormText style={{marginTop:"0.5rem"}} className="error">
                    {this.state.categoryErrorShow}
                  </FormText>
                )}
                </FormGroup>
               

                <FormComponent
           
                  tabIndex="7"
                  labelClass="bold label-sm"
                  label="Job Location"
                  type="text"
                  name="location"
                  change={this.onChange}
                  value={this.state.location}
                  maxlength="27"
                  placeholder="Job Location"
                  required={true}
                />

                <FormComponent
                  tabIndex="10"
                  labelClass="bold label-sm"
                  label="Max CTC"
                  type="number"
                  name="salaryRange"
                  change={this.onChange}
                  value={this.state.salaryRange}
                  // required={true}
                  placeholder="Max CTC"
                  maxlength="15"
                />
                <div style={{ width: "100%", display: "flex" }}>
                  <div>
                    <FormComponent
                      style={{ paddingRight: "1rem", width: "90%" }}
                      tabIndex="15"
                      labelClass="bold label-sm"
                      label="Years of Exp."
                      type="number"
                      name="minYears"
                      change={this.onChange}
                      value={this.state.minYears}
                      required={true}
                      placeholder="Min Years "
                    />
                    {this.state.yearsError && (
                      <FormText className="error">
                        {this.state.yearsError}
                      </FormText>
                    )}
                  </div>
                  <div>
                  <FormComponent
                    style={{ marginTop: "1.5rem" }}
                    tabIndex="16"
                    labelClass="bold label-sm"
                    type="number"
                    name="maxYears"
                    change={this.onChange}
                    value={this.state.maxYears}
                    placeholder="Max Years "
                  />
                   {this.state.maxyearsError && (
                      <FormText className="error">
                        {this.state.maxyearsError}
                      </FormText>
                    )}
                     </div>
                </div>

              
              </Col>
              <Col md="4">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "1rem",
                  }}
                >
                  <span className="bold label-sm">
                    {" "}
                    TAC Email:
                    <span style={{ marginLeft: "1rem",fontSize:"0.75rem",fontWeight:400 }}>
                      {this.state.spocMail}
                    </span>
                  </span>
                  <span style={{ marginTop: "0.5rem" }} className="bold label-sm">
                    TAC Mobile No:
                    <span style={{ marginLeft: "1rem",fontSize:"0.75rem",fontWeight:400 }}>
                      {this.state.spocContact}
                    </span>
                  </span>
                </div>
              
                 <FormGroup style={{marginTop:"0.5rem"}} >
                  <Label className="bold label-sm">Job Position</Label>
                  <Select
                    className="select-form-control-sm"
                    tabIndex="5"
                    name="jobRole"
                    value={this.state.jobRole}
                    onChange={this.handlePositionChange}
                    options={options7}
                    required
                  />
                   {this.state.positionErrorShow && (
                  <FormText className="error">
                    {this.state.positionErrorShow}
                  </FormText>
                )}
                </FormGroup>
               

                <FormGroup>
                  <Label className="bold label-sm">Job Priority</Label>
                  <Input
                    tabIndex="8"
                    type="select"
                    name="critical"
                    className="form-control-sm"
                    value={this.state.critical}
                    onChange={this.onChange}

                    // required={true}
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.state.crucialArr.map((item) => {
                      return (
                        <option key={item.jobPositionId} value={item.value}>
                          {item.value}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.critical && (
                    <FormText className="error">{errors.critical}</FormText>
                  )}
                </FormGroup>
                <div style={{ width: "100%", display: "flex" }}>
                  <FormComponent
                    style={{ width: "90%" }}
                    tabIndex="11"
                    labelClass="bold label-sm"
                    label="Customer Req Id"
                    type="number"
                    name="custReqId"
                    change={this.onChange}
                    value={this.state.custReqId}
                    // required={true}
                    placeholder="Customer Req Id"
                    maxlength="10"
                  />

                  <FormComponent
                    tabIndex="12"
                    labelClass="bold label-sm"
                    label="Total Positions"
                    type="number"
                    name="totalPos"
                    change={this.onChange}
                    value={this.state.totalPos}
                    // required={true}
                    placeholder="Total Position"
                    maxlength="10"
                  />
                </div>

                {this.state.reqType !== "FTE" && (
                  <FormGroup>
                    <Label className="bold label-sm">Billing Type</Label>
                    <Input
                      tabIndex="17"
                      type="select"
                      name="billingType"
                      className="form-control-sm"
                      value={this.state.billingType}
                      onChange={this.onChange}
                      placeholder="Requirement Type"
                      // required={true}
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.billingmain.mainData.map((item) => {
                        return (
                          <option key={item.id} value={item.billingType}>
                            {item.billingType}
                          </option>
                        );
                      })}
                    </Input>
                    {errors.billingType && (
                      <FormText className="error">
                        {errors.billingType}
                      </FormText>
                    )}
                  </FormGroup>
                )}

               
              </Col>
            </Row>
            <Row>
              <Col md="6">
               
                <FormGroup>
                  <Label className="bold label-sm">Primary Skills</Label>
                  <Select
                    tabIndex="18"
                  
                    value={this.state.haveSkills}
                    onChange={this.handlePrimarySkillsChange}
                    options={options5}
                    
                    required
                  />
                    {this.state.primarySkillErrorShow && (
                  <FormText className="error">
                    {this.state.primarySkillErrorShow}
                  </FormText>
                )}
                </FormGroup>
              
                {this.state.filteredPrimaryValueArray.length > 0 && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      border: "1px solid grey",
                      // paddingBottom: "0.5rem",
                    }}
                  >
                    {this.state.filteredPrimaryValueArray.map((data) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent:"space-between",
                             minWidth: "4rem",
                             background: "#ECECEC",
                             border: "none",
                             height:"2rem",
                             marginTop: "0.1rem",
                             marginBottom:"0.1rem",
                             marginLeft: "0.5rem",
                             borderRadius: "20px",
                             paddingRight:"0.2rem",
                             paddingLeft: "0.2rem",
                          }}
                        >
                          <span style={{fontSize:".75rem"}}>{data}</span>

                          <h3
                            data-toggle="tooltip"
                            data-placement="top"
                            title="cancel"
                            onClick={() => this.closeDataPrimarySkill(data)}
                            style={{
                              color: "#04044c",
                            }}
                          >
                            {" "}
                            <MdCancel color="red" size="20" />{" "}
                          </h3>
                        </div>
                      );
                    })}
                  </div>
                )}
                <FormComponent
                  style={{ height: "40px" }}
                  tabIndex="20"
                  labelClass="bold label-sm"
                  label="Job Description"
                  type="textarea"
                  name="description"
                  change={this.onChange}
                  value={this.state.description}
                  placeholder="Job Description"
                  required={true}
                />
              </Col>
              <Col md="6">
              

                <FormGroup>
                  <Label className="bold label-sm">Secondary Skills</Label>
                  <Select
                    tabIndex="18"
                   
                    value={this.state.niceSkills}
                    onChange={this.handleSecondarySkillsChange}
                    options={options5}
                    Primary
                    required
                  />
                   
                </FormGroup>
              
                {this.state.filteredSecondaryValueArray.length > 0 && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      border: "1px solid grey",
                      // paddingBottom: "0.5rem",
                    }}
                  >
                    {this.state.filteredSecondaryValueArray.map((data) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                           alignItems: "center",
                           justifyContent:"space-between",
                            minWidth: "4rem",
                            background: "#ECECEC",
                            border: "none",
                            minHeight:"2rem",
                            marginTop: "0.1rem",
                            marginBottom:"0.1rem",
                            marginLeft: "0.5rem",
                            borderRadius: "20px",
                            paddingRight:"0.2rem",
                            paddingLeft: "0.2rem",
                          
                          }}
                        >
                          <span style={{fontSize:".75rem"}} >{data} </span>

                          <h3
                            data-toggle="tooltip"
                            data-placement="top"
                            title="cancel"
                            onClick={() => this.closeDataSecondarySkill(data)}
                            style={{
                              color: "#04044c",
                            }}
                          >
                            {" "}
                            <MdCancel color="red" size="20" />{" "}
                          </h3>
                        </div>
                      );
                    })}
                  </div>
                )}
                <FormComponent
                  style={{ height: "40px" }}
                  tabIndex="22"
                  labelClass="bold label-sm"
                  label="Comments"
                  type="textarea"
                  name="notes"
                  change={this.onChange}
                  value={this.state.notes}
                  placeholder="Comments"
                  maxlength="10"
                />
              </Col>
            </Row>

            <div className="center">
              <button
                tabIndex="23"
                type="submit"
                className="btn center login-button white-text"
                // disabled={this.state.buttonValue ? "disabled" : null}
              >
                Submit
              </button>
            </div>
          </Form>

          {this.props.requirements.message ? (
            <Notifier message={this.props.requirements.message} />
          ) : null}
          {this.props.requirements.error ? (
            <ErrorNotifier message={this.props.requirements.error} />
          ) : null}
          {this.state.error ? (
            <ErrorNotifier message={this.state.error} />
          ) : null}
        </Container>
      </div>
    );
  }
}



const mapStateToProps = (state) => ({
  requirements: state.RSrequirement,
  RSrequirementlist: state.RSrequirementlist,
  RSbillinglist: state.RSbillinglist,
  RScategorylist: state.RScategorylist,
  Rspositionreducer: state.Rspositionreducer,
  reqStatus: state.reqStatus,
  HrNotice: state.HrNotice,
  customers: state.customers,
  crmContacts: state.crmContacts,
  QualificationRs: state.QualificationRs,
  ReqType: state.ReqType,
  CanConSkill: state.CanConSkill,
  JobCategory: state.JobCategory,
  JobPosition: state.JobPosition,
  billingmain: state.billingmain,
  jobTiming: state.jobTiming,

});

export default connect(mapStateToProps, {
  AddRequirementAction,
  getRequiremnetType,
  getBillingType,
  getCategorytype,
  getPositionType,
  getRequirementStatus,
  getHrLead,
  getCustomersName,
  getContactsRSName,
  getQualification,
  getreqType,
  getAllSkillsSelect ,
  getreqTypeRs,
  getJobCategoryRs,
  getJobPositionRs,
  getQualificationRs,
  getBillingRsTypeRs,
  getRequirementStatusRs,
  getJobTimingSelect,
  //   getCustomerCurrency,
  //   getCustomerAccountStatus,
})(AddRequirement);
