import React, { Component } from "react";
import { Container, Table } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// components
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import AccessAndPermissionDetail from "./AccessAndPermissionDetail";
import SearchBar from "../../../../common/searchbar/SearchBar";
// redux
import {
  getRole,
  searchRole,
} from "../../../../../redux/actions/iam/auth_configuration/role_management/roleAction";

// ------------------------------------------------------------------------------------------------------
class AccessAndPermission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      select: false,
      detailRoleCode: "",
      searchTerm: "",
      error: "",
      message: "",
    };

    this.getRoleIdData = this.getRoleIdData.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onSelectBack = this.onSelectBack.bind(this);
  }

  /*
componentDidMount function contains the role details fetch function
*/
  componentDidMount() {
    this.getRoleIdData();
  }

  onSelect(roleCode) {
    this.setState({
      select: true,
      detailRoleCode: roleCode,
    });
  }

  onSelectBack() {
    this.setState({
      select: false,
    });
  }

  getRoleIdData() {
    this.props.getRole();
  }

  search = (e) => {
    e.preventDefault();
    this.setState({
      searchTerm: e.target.value,
    });
    this.onSearch(e.target.value);
  };

  onSearch = (value) => {
    let data = {
      role: value,
    };
    this.props.searchRole(data);
  };

  render() {
    const { select, detailRoleCode, message, error } = this.state;
    let shrink = this.props.sidebar ? "scale" : "no-scale";

    return (
      <div className={shrink}>
        {!select ? (
          <Container className="card container-card personalDetails-div-1">
            <React.Fragment>
              <SearchBar
                onChange={this.search}
                value={this.state.searchTerm}
                placeholder="Search for Roles"
                title="Search Role"
              />
              <Link
                to={{
                  pathname: "/pie/iam/service_menu",
                  moduleName: this.props.location.moduleName,
                  data: this.props.location.data,
                }}
              >
                <i className="material-icons cancel-button pointer">cancel</i>
              </Link>
              <Table className="center mt-2">
                <thead>
                  <tr>
                    <th className="opp-head">Role Name</th>
                    <th className="opp-head">Role</th>
                    <th className="opp-head">Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.roles.data.length > 0 &&
                    this.props.roles.data.map((item) => {
                      return (
                        <tr
                          className="pointer access-roleId-list"
                          onClick={() => this.onSelect(item.roleCode)}
                        >
                          <td className="opp-tableData">{item.roleName}</td>
                          <td className="opp-tableData">{item.roleCode}</td>
                          <td className="opp-tableData">
                            {item.roleDescription}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </React.Fragment>
          </Container>
        ) : null}
        {select && (
          <AccessAndPermissionDetail
            back={this.onSelectBack}
            roleCode={detailRoleCode}
            serviceAccessData={this.props.location.serviceAccessData}
          />
        )}
        {message && <Notifier message={message} />}
        {error && <ErrorNotifier message={error} />}
      </div>
    );
  }
}

AccessAndPermission.propTypes = {
  getRole: PropTypes.func.isRequired,
  searchRole: PropTypes.func.isRequired,
  roles: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  roles: state.roles,
});

export default connect(mapStateToProps, { getRole, searchRole })(
  AccessAndPermission
);
