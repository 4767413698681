import {
  LEAVE_MANAGEMENT_GET,
  LEAVE_MANAGEMENT_FETCH,
  LEAVE_MANAGEMENT_SEARCH,
  EMPLOYEE_LEAVES_FETCH,
  GET_ERRORS,
  SET_MESSAGE,
} from "../../../actions/types";

const initialState = {
  data: [],
  employeeLeaves: [],
  fetchData: [],
  message: null,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LEAVE_MANAGEMENT_GET:
      return {
        ...state,
        data: action.payload,
      };
    case LEAVE_MANAGEMENT_SEARCH:
      return {
        ...state,
        data: action.payload,
      };
    case LEAVE_MANAGEMENT_FETCH:
      return {
        ...state,
        fetchData: action.payload,
      };
    case EMPLOYEE_LEAVES_FETCH:
      return {
        ...state,
        employeeLeaves: action.payload,
      };
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case GET_ERRORS:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
