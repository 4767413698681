import axios from "axios";
import { EMP_DEPENDENTS_GET, EMP_DEPENDENTS_FETCH } from "../../types";
import {
  CURD_EMP_DEPENDENTS,
  FETCH_EMP_DEPENDENTS,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addEmpDependents = (data, back) => (dispatch) => {
  axios
    .post(CURD_EMP_DEPENDENTS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getEmpDependents));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getEmpDependents = () => (dispatch) => {
  axios
    .get(CURD_EMP_DEPENDENTS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(EMP_DEPENDENTS_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const fetchEmpDependents = () => (dispatch) => {
  axios
    .get(FETCH_EMP_DEPENDENTS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(EMP_DEPENDENTS_FETCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateEmpDependents = (data, back) => (dispatch) => {
  axios
    .put(CURD_EMP_DEPENDENTS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteEmpDependents = (data) => (dispatch) => {
  axios
    .delete(CURD_EMP_DEPENDENTS, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, fetchEmpDependents));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
