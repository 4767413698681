import React, { Component } from "react";
import { Container, Table } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DateFns from "date-fns";
// material ui
import { Box } from "@material-ui/core";
// components
import AddRequestType from "./AddRequestType";
import UpdateRequestType from "./UpdateRequestType";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import ConfirmDialog from "../../../../common/confirm-dialog/ConfirmDialog";
// redux
import {
  getRequestType,
  deleteRequestType,
} from "../../../../../redux/actions/experience_360/e360_config/requestTypeAction";

// -------------------------------------------------------------------------------------------

class RequestType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addBtn: false,
      updateBtn: false,
      updateData: [],
      confirmDialog: {
        isOpen: false,
        title: "",
        subTitle: "",
      },
    };

    this.toggleAddBtn = this.toggleAddBtn.bind(this);
    this.addBack = this.addBack.bind(this);
    this.toggleUpdateBtn = this.toggleUpdateBtn.bind(this);
    this.updateBack = this.updateBack.bind(this);
    this.getData = this.getData.bind(this);
    this.deleteRequestType = this.deleteRequestType.bind(this);
  }

  /*
toggleAddBtn function to toggle the addition page
*/
  toggleAddBtn() {
    this.setState({
      addBtn: true,
    });
  }

  /*
addBack function to back to the list page from the add page
*/
  addBack() {
    this.setState({
      addBtn: false,
    });

    this.getData();
  }

  /*
toggleUpdateBtn function to toggle the update form
*/
  toggleUpdateBtn(item) {
    this.setState({
      updateBtn: true,
      updateData: item,
    });
  }

  /*
updateBack function to redirect to back main page
*/
  updateBack() {
    this.setState({
      updateBtn: false,
    });

    this.getData();
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.props.getRequestType();
  }

  deleteRequestType(id) {
    let data = {
      id: id,
    };
    this.props.deleteRequestType(data);
    this.setState((prevState) => ({
      ...prevState.confirmDialog,
      confirmDialog: {
        isOpen: false,
      },
    }));
  }

  setConfirmDialog = (obj) => {
    this.setState((prevProps) => ({
      confirmDialog: {
        ...prevProps.confirmDialog,
        isOpen: obj.isOpen,
      },
    }));
  };

  render() {
    const { addBtn, updateBtn, updateData } = this.state;
    let shrink = this.props.sidebar ? "scale" : "no-scale";

    return (
      <Box className={shrink}>
        <Container className="card container-card">
          {!addBtn && !updateBtn ? (
            <React.Fragment>
              {typeof this.props.location.serviceAccessData !== "undefined" &&
                this.props.location.serviceAccessData.creation && (
                  <button
                    className="btn add-button white-text label-sm"
                    onClick={this.toggleAddBtn}
                  >
                    Add
                  </button>
                )}
              <h5 className="bold center pt-2">Request Type</h5>
              <Link
                to={{
                  pathname: "/pie/iam/service_menu",
                  moduleName: this.props.location.moduleName,
                  data: this.props.location.data,
                }}
              >
                <i className="material-icons cancel-button pointer">cancel</i>
              </Link>
              <Table className="center">
                <thead>
                  <tr>
                    <th className="opp-head ">Type</th>
                    <th className="opp-head ">Color</th>
                    <th className="opp-head ">Description</th>
                    <th className="opp-head ">CreatedBy</th>
                    <th className="opp-head ">CreatedOn</th>
                    <th className="opp-head ">UpdatedBy</th>
                    <th className="opp-head ">UpdatedOn</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.requestType.data &&
                    this.props.requestType.data.map((item) => {
                      return (
                        <tr>
                          <td className="opp-tableData">{item.reqTypeName}</td>
                          <td className="opp-tableData">
                            <div
                              style={{
                                backgroundColor: item.color,
                                width: "20px",
                                height: "20px",
                              }}
                            ></div>
                          </td>
                          <td className="opp-tableData">
                            {item.reqTypeDescription}
                          </td>
                          <td className="opp-tableData">{item.createdBy}</td>
                          <td className="opp-tableData">
                            {DateFns.format(item.createdOn, "DD-MM-YYYY")}
                          </td>
                          <td className="opp-tableData">{item.updatedBy}</td>
                          <td className="opp-tableData">
                            {DateFns.format(item.updatedOn, "DD-MM-YYYY")}
                          </td>
                          <td>
                            {typeof this.props.location.serviceAccessData !==
                              "undefined" &&
                              this.props.location.serviceAccessData
                                .updation && (
                                <span
                                  className="material-icons pointer opp-action-edit"
                                  style={{ color: "blue" }}
                                  onClick={() => this.toggleUpdateBtn(item)}
                                >
                                  edit
                                </span>
                              )}
                          </td>
                          <td>
                            {typeof this.props.location.serviceAccessData !==
                              "undefined" &&
                              this.props.location.serviceAccessData
                                .deletion && (
                                <i
                                  className="material-icons pointer"
                                  onClick={() =>
                                    this.setState({
                                      confirmDialog: {
                                        isOpen: true,
                                        title:
                                          "Are you sure to delete this record?",
                                        subTitle:
                                          "You can't undo this operation",
                                        onConfirm: () =>
                                          this.deleteRequestType(item.id),
                                      },
                                    })
                                  }
                                  title="Delete Customer Type"
                                >
                                  delete
                                </i>
                              )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </React.Fragment>
          ) : null}
          {addBtn && <AddRequestType back={this.addBack} />}
          {updateBtn && (
            <UpdateRequestType back={this.updateBack} data={updateData} />
          )}
          {this.props.requestType.message && (
            <Notifier message={this.props.requestType.message} />
          )}
          {this.props.requestType.error && (
            <ErrorNotifier message={this.props.requestType.error} />
          )}
        </Container>
        <ConfirmDialog
          confirmDialog={this.state.confirmDialog}
          setConfirmDialog={this.setConfirmDialog}
        />
      </Box>
    );
  }
}

RequestType.propTypes = {
  getRequestType: PropTypes.func.isRequired,
  deleteRequestType: PropTypes.func.isRequired,
  requestType: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  requestType: state.requestType,
});

export default connect(mapStateToProps, {
  getRequestType,
  deleteRequestType,
})(RequestType);
