import axios from "axios";
import { PASSPORT_DETAILS_GET } from "../../../types";
import { CURD_PASSPORT_DETAILS } from "../../../../../utils/routes";
import setSuccessMessage from "../../../common/setSuccessMessage";
import setErrorMessage from "../../../common/setErrorMessage";
import getDispatch from "../../../common/getDispatch";

export const getPassportDetails = () => (dispatch) => {
  axios
    .get(CURD_PASSPORT_DETAILS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(PASSPORT_DETAILS_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updatePassportDetails = (data, back) => (dispatch) => {
  axios
    .post(CURD_PASSPORT_DETAILS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
