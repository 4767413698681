import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Row, Col, Card } from "reactstrap";
import { IconButton } from "@material-ui/core";

// icons
import FilterListIcon from "@material-ui/icons/FilterList";
// components
import EmployeeDetails from "./EmployeeDetails";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import SearchBar from "../../../common/searchbar/SearchBar";
// redux
import {
  getEmpManagement,
  searchEmpManagement,
} from "../../../../redux/actions/hcm/hr/emp_management/empManagementAction";

// ----------------------------------------------------------------------------------------------------------
class Employees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: null,
      searchTerm: "",
      addBtn: false,
      openFilter: false,
      filterValue: "Active",
      message: "",
      error: "",
    };
    this.select = this.select.bind(this);
    this.back = this.back.bind(this);
    this.search = this.search.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.getData = this.getData.bind(this);
  }

  select(item) {
    this.setState({
      selection: item,
    });
  }

  /*
Searching the Employee
*/
  search(e) {
    e.preventDefault();
    this.setState({
      searchTerm: e.target.value,
    });
    this.onSearch(e.target.value);
  }

  /*
onSearch function for setting the search data in redux store by calling the searchAction
*/
  onSearch(value) {
    let data = {
      empNo: value,
    };
    this.props.searchEmpManagement(data);
  }

  /*
In back function called the getData function for after the update successfully it redirect to 
the employee list page with updated data
*/
  back() {
    this.setState({
      selection: false,
    });
    this.getData();
  }

  /*
getData function for list all the employee details in database
*/
  getData() {
    this.props.getEmpManagement();
  }

  componentDidMount() {
    this.getData();
  }

  render() {
    let shrink;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");
    const { selection } = this.state;

    return (
      <div className={shrink}>
        <Card className="container-card m-2">
          {!selection && (
            <div className="hcm-onboarding-search-filter">
              <SearchBar
                onChange={this.search}
                value={this.state.searchTerm}
                placeholder="Search for Employees"
                onSubmit={this.onSearch}
                title="Search Employee"
              />
              <div className="hcm-onboarding-search-filter-icon">
                <IconButton
                  aria-label="FILTER"
                  title="Filter"
                  onClick={() =>
                    this.setState({ openFilter: !this.state.openFilter })
                  }
                >
                  <FilterListIcon className="icon-color" />
                </IconButton>
                <ul
                  className={
                    this.state.openFilter
                      ? "display-filter"
                      : "not-display-filter"
                  }
                >
                  <li
                    onClick={() =>
                      this.setState({
                        filterValue: "Active",
                        openFilter: false,
                      })
                    }
                  >
                    Active
                  </li>
                  <li
                    onClick={() =>
                      this.setState({
                        filterValue: "Inactive",
                        openFilter: false,
                      })
                    }
                  >
                    In-Active
                  </li>
                </ul>
              </div>
            </div>
          )}
          {!selection ? (
            <Row className="mt-3">
              {this.props.empManagement.data.length > 0 &&
                this.props.empManagement.data
                  .filter((value) => value.empStatus === this.state.filterValue)
                  .map((item) => {
                    return (
                      <Col
                        md="4"
                        key={item.id}
                        onClick={() => this.select(item)}
                        className="employee-list-col"
                      >
                        <Card className="p-2 mb-2 shadow pointer">
                          <ul className="CustDetails-ul employee-list-ul">
                            <li>
                              <span className="label-sm bold emp-span-label">
                                Full Name
                              </span>
                              <span className=" label-sm">
                                :&nbsp;&nbsp;
                                <span className="employee-list-span-data">
                                  {item.hcmpersonal.firstName}&nbsp;
                                  {item.hcmpersonal.lastName}
                                </span>
                              </span>
                            </li>
                            <li>
                              <span className="label-sm bold emp-span-label">
                                Emp No
                              </span>
                              <span className=" label-sm">
                                :&nbsp;&nbsp;
                                <span className="employee-list-span-data">
                                  {item.empNo}
                                </span>
                              </span>
                            </li>
                            <li>
                              <span className="label-sm bold emp-span-label">
                                Email Id
                              </span>
                              <span className=" label-sm">
                                :&nbsp;&nbsp;
                                <span className="employee-list-span-data">
                                  {item.organizationEmailId}
                                </span>
                              </span>
                            </li>
                          </ul>
                        </Card>
                      </Col>
                    );
                  })}
            </Row>
          ) : (
            <EmployeeDetails employee={selection} back={this.back} />
          )}
        </Card>

        {this.state.message ? <Notifier message={this.state.message} /> : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
        {this.props.empManagement.message ? (
          <Notifier message={this.props.empManagement.message} />
        ) : null}
        {this.props.empManagement.error ? (
          <ErrorNotifier message={this.props.empManagement.error} />
        ) : null}
      </div>
    );
  }
}

Employees.propTypes = {
  empManagement: PropTypes.object.isRequired,
  searchEmpManagement: PropTypes.func.isRequired,
  getEmpManagement: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  empManagement: state.empManagement,
});

export default connect(mapStateToProps, {
  getEmpManagement,
  searchEmpManagement,
})(Employees);
