import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  FormText,
} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  updateProduct,
  getProducts,
} from "../../../../redux/actions/productAction";
import { getCustomers } from "../../../../redux/actions/customerAction";
import { getAccounts } from "../../../../redux/actions/accountAction";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
} from "../../../common/Validator";
import FormComponent from "../../../common/FormComponent";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import { getProductStatus } from "../../../../redux/actions/productStatusAction";

class UpdateProduct extends Component {
  constructor(props) {
    super(props);
    const {
      productId,
      productName,
      compCode,
      accountId,
      licenseQty,
      activationStartDate,
      activationEndDate,
      productStatus,
      remarks,
    } = this.props.item;
    this.state = {
      productId: productId,
      productName: productName,
      custName: compCode,
      accountName: accountId,
      licenseQuantity: licenseQty,
      startDate: activationStartDate,
      endDate: activationEndDate,
      status: productStatus,
      remarks: remarks,
      message: null,
      error: null,
      dateError: "",
      formErrors: {
        selectError: "",
      },
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    e.preventDefault();
    Validator(e.target);
    if (e.target.name === "endDate") {
      if (Date.parse(e.target.value) < Date.parse(this.state.endDate)) {
        this.setState({
          [e.target.name]: e.target.value,
          dateError: "endDate must be greater than or equal to startDate",
        });
      } else {
        this.setState({
          [e.target.name]: e.target.value,
          dateError: "",
        });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  /*
This function for updating the project with body as the project details
*/
  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let product = {
      productId: this.state.productId,
      productName: this.state.productName,
      compCode: this.state.custName,
      accountId: this.state.accountName,
      licenseQty: this.state.licenseQuantity,
      activationStartDate: this.state.startDate,
      activationEndDate: this.state.endDate,
      productStatus: this.state.status,
      remarks: this.state.remarks,
      updatedBy: localStorage.getItem("id"),
    };
    if (valid && this.state.custName && this.state.accountName) {
      this.props.updateProduct(product);
      setTimeout(() => {
        this.props.back();
      }, 2000);
    } else {
      let errors = this.state.formErrors;
      errors.selectError = "Please select option";
      this.setState({
        formErrors: errors,
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidmount function contains the refresh method to reset the form validation error data
*/
  componentDidMount() {
    this.props.getCustomers();
    this.props.getAccounts();
    this.props.getProductStatus();
    refresh();
  }

  render() {
    const { selectError } = this.state.formErrors;
    return (
      <Container className="container-card" style={{ position: "relative" }}>
        <i
          className="cancel-button material-icons pointer"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <h5 className="bold center">Update Product</h5>
          <Row>
            <Col md="6">
              <FormComponent
                labelClass="bold label-sm"
                label="Product Name"
                type="text"
                name="productName"
                inputClass="form-control-sm"
                value={this.state.productName}
                change={this.onChange}
                placeholder="Product Name"
                required={true}
              />
              <FormGroup>
                <Label className="label-sm bold">Customer Name</Label>
                <Input
                  type="select"
                  name="custName"
                  onChange={this.onChange}
                  value={this.state.custName}
                >
                  <option hidden>Select...</option>
                  {this.props.customers &&
                    this.props.customers.data.map((item) => {
                      return (
                        <option key={item.compCode} value={item.compCode}>
                          {item.compCode}-{item.compFullName}
                        </option>
                      );
                    })}
                </Input>
                {!this.state.custName && (
                  <FormText className="error">{selectError}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="label-sm bold">Account Name</Label>
                <Input
                  type="select"
                  name="accountName"
                  onChange={this.onChange}
                  value={this.state.accountName}
                >
                  <option hidden>Select...</option>
                  {this.props.account &&
                    this.props.account.data.map((item) => {
                      if (parseInt(this.state.custName) === item.compCode) {
                        return (
                          <option key={item.accountId} value={item.accountId}>
                            {item.accountId}-{item.accountName}
                          </option>
                        );
                      }
                      return null;
                    })}
                </Input>
                {!this.state.accountName && (
                  <FormText className="error">{selectError}</FormText>
                )}
              </FormGroup>
              <FormComponent
                labelClass="bold label-sm"
                label="License Quantity"
                type="number"
                name="licenseQuantity"
                inputClass="form-control-sm"
                value={this.state.licenseQuantity}
                change={this.onChange}
                placeholder="License Quantity"
                required={true}
              />
            </Col>
            <Col md="6">
              <FormComponent
                labelClass="bold label-sm"
                label="Activation Start Date"
                type="date"
                name="startDate"
                inputClass="form-control-sm"
                value={this.state.startDate}
                change={this.onChange}
                required={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Activation End Date"
                type="date"
                name="endDate"
                inputClass="form-control-sm"
                value={this.state.endDate}
                change={this.onChange}
                min={this.state.startDate}
                error={this.state.dateError}
                required={true}
              />
              <FormGroup>
                <Label className="bold label-sm">Product Status</Label>
                <Input
                  type="select"
                  name="status"
                  value={this.state.status}
                  className="form-control-sm"
                  onChange={this.onChange}
                >
                  {this.props.productStatus.data.map((item) => {
                    return (
                      <option
                        key={item.productStatus}
                        value={item.productStatus}
                      >
                        {item.productStatus}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
              <FormComponent
                labelClass="bold label-sm"
                label="Remarks"
                type="textarea"
                name="remarks"
                inputClass="form-control-sm"
                value={this.state.remarks}
                change={this.onChange}
                required={true}
              />
            </Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn btn-custom">
              Update
            </button>
          </div>
        </Form>
        {this.props.products.message && (
          <Notifier message={this.props.products.message} />
        )}
        {this.state.error && <ErrorNotifier message={this.state.error} />}
      </Container>
    );
  }
}

UpdateProduct.propTypes = {
  updateProduct: PropTypes.func.isRequired,
  products: PropTypes.object.isRequired,
  getCustomers: PropTypes.func.isRequired,
  getAccounts: PropTypes.func.isRequired,
  customers: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  getProductStatus: PropTypes.func.isRequired,
  productStatus: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  products: state.products,
  customers: state.customers,
  account: state.account,
  productStatus: state.productStatus,
});

export default connect(mapStateToProps, {
  updateProduct,
  getProducts,
  getCustomers,
  getAccounts,
  getProductStatus,
})(UpdateProduct);
