import React, { Component } from "react";
import { Form, Row, Col, FormGroup, Label, Input } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// components
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
// redux
import { updateCrmGlobal } from "../../../../../redux/actions/crm/crm_setup/crmGlobalAction";

// ---------------------------------------------------------------------------------------------

class UpdateCrmGlobal extends Component {
  constructor(props) {
    super(props);
    const { id, key, value, description, isDepended, updateable, deletable } =
      this.props.data;
    this.state = {
      iamConfigId: id,
      key: key,
      value: value,
      description: description,
      isDepended: isDepended,
      updateable: updateable,
      deletable: deletable,
      message: "",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      id: this.state.iamConfigId,
      key: this.state.key,
      value: this.state.value,
      description: this.state.description,
      isDepended: Boolean(this.state.isDepended),
      updateable: Boolean(this.state.updateable),
      deletable: Boolean(this.state.deletable),
    };
    if (valid) {
      this.props.updateCrmGlobal(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    refresh();
    this.setState({
      message: "",
      error: "",
    });
  }

  render() {
    const {
      key,
      value,
      description,
      isDepended,
      updateable,
      deletable,
      error,
      message,
    } = this.state;
    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Update CRM Global Var</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="6" className="mx-auto">
              <FormComponent
                labelClass="bold label-sm"
                label="Key"
                type="text"
                name="key"
                inputClass="form-control-sm"
                value={key}
                change={this.onChange}
                required={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Value"
                type="text"
                name="value"
                inputClass="form-control-sm"
                value={value}
                change={this.onChange}
                required={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Description"
                type="textarea"
                name="description"
                inputClass="form-control-sm"
                value={description}
                change={this.onChange}
              />
              <FormGroup>
                <Label className="label-sm bold">Is Depended</Label>
                <Input
                  type="select"
                  name="isDepended"
                  value={isDepended}
                  onChange={this.onChange}
                  className="form-control-sm"
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  <option value={true}>True</option>
                  <option value={false}>False</option>
                </Input>
                {errors.isDepended && (
                  <FormText className="error">{errors.isDepended}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="label-sm bold">Deletable</Label>
                <Input
                  type="select"
                  name="deletable"
                  value={deletable}
                  onChange={this.onChange}
                  className="form-control-sm"
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  <option value={true}>True</option>
                  <option value={false}>False</option>
                </Input>
                {errors.deletable && (
                  <FormText className="error">{errors.deletable}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="label-sm bold">Updateable</Label>
                <Input
                  type="select"
                  name="updateable"
                  value={updateable}
                  onChange={this.onChange}
                  className="form-control-sm"
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  <option value={true}>True</option>
                  <option value={false}>False</option>
                </Input>
                {errors.updateable && (
                  <FormText className="error">{errors.updateable}</FormText>
                )}
              </FormGroup>
            </Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Update
            </button>
          </div>
        </Form>
        {this.props.crmGlobal.message && (
          <Notifier message={this.props.crmGlobal.message} />
        )}
        {this.props.crmGlobal.error && (
          <ErrorNotifier message={this.props.crmGlobal.error} />
        )}
      </React.Fragment>
    );
  }
}

UpdateCrmGlobal.propTypes = {
  updateCrmGlobal: PropTypes.func.isRequired,
  crmGlobal: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  crmGlobal: state.crmGlobal,
});

export default connect(mapStateToProps, { updateCrmGlobal })(UpdateCrmGlobal);
