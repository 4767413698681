import axios from "axios";
import {GET_REQ_LIST,GET_REQSTATUS_MAIN } from "../../types";
import {
  ADD_REQSTATUS_RS,
  GET_REQSTATUS_RS,
  UPDATE_REQSTATUS_RS,
  DELETE_REQSTATUS_RS
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addRequirementStatus = (data, back) => (dispatch) => {
  axios
    .post( ADD_REQSTATUS_RS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getRequirementStatus));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getRequirementStatus = () => (dispatch) => {
  axios
    .get( GET_REQSTATUS_RS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(GET_REQ_LIST, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
export const getRequirementStatusRs = () => (dispatch) => {
  axios
    .get( `${GET_REQSTATUS_RS}/select`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(GET_REQSTATUS_MAIN, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateRequirementStatus = (data, back) => (dispatch) => {
  axios
    .put(UPDATE_REQSTATUS_RS , data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteRequirementStatus = (data) => (dispatch) => {
  axios
    .delete(DELETE_REQSTATUS_RS, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getRequirementStatus));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
