import React, { Component } from "react";
import { Row, Col, Card } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// components
import PersonalDetails from "./personal_details/PersonalDetails";
import EducationDetails from "./qualifications/EducationDetails";
import Nomination from "./nomination/Nomination";
import ProfessionalDetails from "./previous_employment_details/ProfessionalDetails";
import AccountDetails from "./account_details/AccountDetails";
import ContactDetailsMain from "./contact_details/ContactDetailsMain";
import IdentificationInfo from "./identification_info/IdentificationInfo";
import HonorsAndAwards from "./honors_and_awards/HonorsAndAwards";
import Documents from "./documents/Documents";

class MyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: "",
    };

    this.back = this.back.bind(this);
    this.chooseForm = this.chooseForm.bind(this);
  }

  /*
back fuction to redirect to the back from details to myprofile tab page
*/
  back() {
    this.setState({
      edit: "",
    });
  }

  /*
chooseForm function to select the particular tab related view should display
*/
  chooseForm() {
    let component = this.state.edit;

    if (component === "personal") {
      return <PersonalDetails back={this.back} />;
    }
    if (component === "contact") {
      return <ContactDetailsMain back={this.back} />;
    }
    if (component === "qualifications") {
      return <EducationDetails back={this.back} />;
    }
    if (component === "employment") {
      return <ProfessionalDetails back={this.back} />;
    }
    if (component === "account") {
      return <AccountDetails back={this.back} />;
    }
    if (component === "identification") {
      return <IdentificationInfo back={this.back} />;
    }
    if (component === "nomination") {
      return <Nomination back={this.back} />;
    }
    if (component === "honor") {
      return <HonorsAndAwards back={this.back} />;
    }
    if (component === "documents") {
      return <Documents back={this.back} />;
    }
  }

  render() {
    let shrink = this.props.sidebar ? "scale" : "no-scale";

    return (
      <div className={shrink}>
        {!this.state.edit ? (
          <Row>
            <div id="welcome-div">
              <p id="welcome-p">
                Welcome {this.props.auth.data.pieUser.firstName}&nbsp;
                {this.props.auth.data.pieUser.lastName}...!
              </p>
            </div>
            <div id="myprofile-category-div">
              <Row>
                <Col md="6">
                  <Card
                    className="myprofile-card"
                    onClick={() => this.setState({ edit: "personal" })}
                  >
                    <i className="material-icons">person</i>
                    Personal Details
                  </Card>
                </Col>
                <Col md="6">
                  <Card
                    className="myprofile-card"
                    onClick={() => this.setState({ edit: "contact" })}
                  >
                    <i className="material-icons">contacts</i>
                    Contact Details
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Card
                    className="myprofile-card"
                    onClick={() => this.setState({ edit: "qualifications" })}
                  >
                    <i className="material-icons">school</i>
                    Qualifications
                  </Card>
                </Col>
                <Col md="6">
                  <Card
                    className="myprofile-card"
                    onClick={() => this.setState({ edit: "employment" })}
                  >
                    <i className="material-icons">work</i>
                    Employment Details
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Card
                    className="myprofile-card"
                    onClick={() => this.setState({ edit: "account" })}
                  >
                    <i className="material-icons">account_balance</i>
                    Account Details
                  </Card>
                </Col>
                <Col md="6">
                  <Card
                    className="myprofile-card"
                    onClick={() => this.setState({ edit: "identification" })}
                  >
                    <i className="material-icons">assignment</i>
                    Passport Details
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Card
                    className="myprofile-card"
                    onClick={() => this.setState({ edit: "nomination" })}
                  >
                    <i className="material-icons">supervisor_account</i>
                    Nomination
                  </Card>
                </Col>
                <Col md="6">
                  <Card
                    className="myprofile-card"
                    onClick={() => this.setState({ edit: "honor" })}
                  >
                    <i className="fas fa-award myprofile-icon"></i>
                    Honors and Awards
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Card
                    className="myprofile-card"
                    onClick={() => this.setState({ edit: "documents" })}
                  >
                    <i className="material-icons">folder</i>
                    Documents
                  </Card>
                </Col>
              </Row>
            </div>
          </Row>
        ) : (
          this.chooseForm()
        )}
      </div>
    );
  }
}

MyProfile.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(MyProfile);
