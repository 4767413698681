import React, { Component } from "react";
import axios from 'axios'
import { Form, Row, Col,FormGroup,Label,FormText } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { TA_EMP_DETAIL } from "../../../../../utils/routes";
import Select from 'react-select'
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent"
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import Notifier from "../../../../aside/Notifier";
import {updateHrLead } from "../../../../../redux/actions/rs/configuration/hrleadAction";
let errorCustomer = false;
// update hr lead component
class UpdateLead extends Component {
  constructor(props) {
    super(props);
    const { id,empName,empNo, remarks } = this.props.data;
    
    this.state = {
      userGroupId: id,
      empName: empName,
      empBodyName:"",
      empNo:empNo,
      remark:  remarks,
      error: "",
      selectedOptionError:null,

      getHrData:[]
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleCustomerChange = this.handleCustomerChange.bind(this)
  }

  // common onchange function
  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  // on submit function
  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    if (!this.state.empBodyName) {
      this.setState({
        selectedOptionError: "this field is mandatory",
      });
      errorCustomer = true;
    } else {
      this.setState({
        selectedOptionError: "",
      });
      errorCustomer = false;
    }
    let data = {
      id: this.state.userGroupId,
      empNo: this.state.empNo,
      empName: this.state.empBodyName,
      remarks:this.state.remark,
      updatedBy: localStorage.getItem("id"),
    };
    if (valid) {
      this.props.updateHrLead(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }
  handleCustomerChange(empName) {
    console.log(empName)
    this.setState({
      empBodyName: empName.label,
      empNo:empName.value
    });
    if (empName) {
      this.setState({ empName, selectedOptionError: null });
    } else {
      this.setState({
        selectedOptionError: "This field is mandatory",
      });
    }
  }

  componentDidMount() {
  
    this.getData();
    refresh();
    this.setState({
      error: "",
      empBodyName:this.props.data.empName
    
    });
  }
  getData() {
    axios
      .get(`${TA_EMP_DETAIL}`, { withCredentials: true })
      .then((res) => {
        console.log(res);
        this.setState({
          getHrData: res.data,
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  render() {
    console.log(errors)
    const { empName, remark, error } = this.state;
    let options3 = [];
    for (let i = 0; i < this.state.getHrData.length; i++) {
      var x1 = {};
      x1["value"] = this.state.getHrData[i].empNo;
      x1[
        "label"
      ] =  `${this.state.getHrData[i].firstName}-${this.state.getHrData[i].empNo}`

      options3.push(x1);
    }


    return (
      <React.Fragment>
        <h5 className="center card-heading pt-2">Update Lead Recruiter</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="6" className="mx-auto">
            <FormGroup>
                <Label className="bold label-sm">Emp Name & No.</Label>
                <Select
                  tabIndex="1"
                //  defaultInputValue={this.state.empName}
                 
                  // name="empName"
                  value={this.state.empName}
                  onChange={this.handleCustomerChange}
                  options={options3}
                  required
                />
              </FormGroup>
              {this.state.selectedOptionError && (
                <FormText className="error select-search-error">
                  {this.state.selectedOptionError}
                </FormText>
              )}
            
               
              <FormComponent
                labelClass="bold label-sm"
                label="Remark"
                type="text"
                name="remark"
                inputClass="form-control-sm"
                value={remark}
                change={this.onChange}
             
                length="30"
              />
              
            
            
            </Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Update
            </button>
          </div>
        </Form>
        {error && <ErrorNotifier message={error} />}
        {this.props.HrNotice.error && (
          <ErrorNotifier message={this.props.HrNotice.error} />
        )}
        {this.props.HrNotice.message && (
          <Notifier message={this.props.HrNotice.message} />
        )}
      </React.Fragment>
    );
  }
}

UpdateLead.propTypes = {
  updateHrLead: PropTypes.func.isRequired,
  userGroups: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  HrNotice: state.HrNotice,
});

export default connect(mapStateToProps, {updateHrLead })( UpdateLead);
