import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import DateFns from "date-fns";
// material ui
import { Divider } from "@material-ui/core";
// components
import CommonFlexComponent from "../../../common/commonFlexComponent";
import DetailsPageFooter from "../requirements/DetailsPageViewFooter";

// ------------------------------------------------------------------------------------

const RequirementDetails = ({ data }) => {
  const[isOpen,setIsOpen] = useState("")
  return (
    <>
     
      <Row style={{ width: "100%" }}>
        <Col md="6">
          <ul style={{}} className="CustDetails-ul m-0">
            <li>
              <CommonFlexComponent label="Customer Name" data={data.custName} />
            </li>

            <li>
              <CommonFlexComponent label=" TAC Name" data={data.tacName} />
            </li>

            <li>
              <CommonFlexComponent
                label=" TAC Contact No"
                data={data.tacConNum}
              />
            </li>

            <li>
              <CommonFlexComponent label="TAC EmailId" data={data.tacEmail} />
            </li>
            <li>
              <CommonFlexComponent label="Req. Type" data={data.jobReqType} />
            </li>

            <li></li>
            <li>
              <CommonFlexComponent
                label="Job Category"
                data={data.jobCategory}
              />
            </li>

            <li>
              <CommonFlexComponent
                label="Qualification"
                data={data.qualifications}
              />
            </li>
            <li>
              <CommonFlexComponent
                label="Customer Req. Id"
                data={data.custReqId}
              />
            </li>

            <li>
              <CommonFlexComponent
                label="Start Date"
                data={
                  data.startDate
                    ? DateFns.format(data.startDate, "DD-MM-YYYY")
                    : ""
                }
              />
            </li>

            <li>
              <CommonFlexComponent
                label="  End Date"
                data={
                  data.endDate ? DateFns.format(data.endDate, "DD-MM-YYYY") : ""
                }
              />
            </li>
            <li>
              <CommonFlexComponent
                label="Job Position"
                data={data.jobPosition}
              />
            </li>
            {/* <li>
              <CommonFlexComponent
                label=" Job Description"
                data={data.jobDescription}
              />
            </li> */}
          </ul>
        </Col>

        <Col md="6">
          <ul className="CustDetails-ul m-0">
            <li>
              <CommonFlexComponent
                label="Job Location"
                data={data.jobLocation}
              />
            </li>
            <li>
              <CommonFlexComponent
                label="Primary Skills"
                data={data.primarySkills}
              />
            </li>

            <li>
              <CommonFlexComponent
                label="Secondary Skills"
                data={data.secondarySkills}
              />
            </li>

            <li>
              <CommonFlexComponent
                label="Total Position"
                data={data.totalPositions}
              />
            </li>

            <li>
              <CommonFlexComponent
                label=" Billing Type"
                data={data.billingType}
              />
            </li>

            <li>
              <CommonFlexComponent label=" Job Timing" data={data.jobTimings} />
            </li>

            <li>
              <CommonFlexComponent label=" Max Ctc" data={data.maxCtc} />
            </li>

            <li>
              <CommonFlexComponent
                label="Job Priority"
                data={data.jobPriority}
              />
            </li>
            <li>
              <CommonFlexComponent
                label="Min Yrs of Exp"
                data={data.minYearsOfExp}
              />
            </li>

            <li>
              <CommonFlexComponent
                label=" Max Yrs of Exp"
                data={data.maxYearsOfExp}
              />
            </li>
            <li></li>
            <li>
              <CommonFlexComponent label=" Req. Status" data={data.reqStatus} />
            </li>
            {/* <li>
              <CommonFlexComponent label="Comments" data={data.comments} />
            </li> */}
          </ul>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <ul className="CustDetails-ul m-0">
            <li>
              <div style={{ display: "flex", marginTop: "0.5rem" }}>
                <div
                  className="label-sm bold rs-req-span-label-col-1 pt-1"
                  style={{ width: "18%" }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    Job Description
                  </span>
                </div>
                <div style={{ width: "1%", textAlign: "end" }}>
                  <span style={{fontSize:".75rem ",fontWeight:"400"}}>:</span>
                </div>
                <div style={{ width: "78%", paddingLeft: "1%" }}>
                  <span  style={{fontWeight:400,fontSize:".75rem "}}>{data?.jobDescription}</span>
                </div>
              </div>
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <ul className="CustDetails-ul m-0">
            <li>
              <div style={{ display: "flex", marginTop: "0.5rem" }}>
                <div
                  className="label-sm bold rs-req-span-label-col-1 pt-1"
                  style={{ width: "18%" }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    Lead Recruiter
                  </span>
                </div>
                <div style={{ width: "1%", textAlign: "end" }}>
                  <span className="label-sm">:</span>
                </div>
                <div style={{ width: "78%", paddingLeft: "1%" }}>
                  <span style={{fontSize:".75rem ",fontWeight:"400"}}>
                    {data?.reqAssignment?.length > 0
                      ? data?.reqAssignment?.filter(
                          (item) => item.leadRole === "Primary Lead"
                        ).length > 0
                        ? data?.reqAssignment
                            ?.filter((item) => item.leadRole === "Primary Lead")
                            .map((value) => value.assignedTo)
                        : "Not Assigned"
                      : "Not Assigned"}
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <ul className="CustDetails-ul">
            <li>
              <div style={{ display: "flex", marginTop: "0.5rem" }}>
                <div
                  className="label-sm bold rs-req-span-label-col-1 pt-1"
                  style={{ width: "18%" }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    Comments
                  </span>
                </div>
                <div style={{ width: "1%", textAlign: "end" }}>
                  <span style={{fontSize:".75rem ",fontWeight:"400"}}>:</span>
                </div>
                <div style={{ width: "78%", paddingLeft: "1%" }}>
                  <span className=" label-sm">{data?.comments}</span>
                </div>
              </div>
            </li>
          </ul>
        </Col>
      </Row>

      <Divider />
      <DetailsPageFooter
        createdBy={data.createdBy}
        createdOn={data.createdOn}
        updatedBy={data.updatedBy}
        updatedOn={data.updatedOn}
      />
    </>
  );
};

export default RequirementDetails;
