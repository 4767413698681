import axios from "axios";
import { EXTRA_CURRICULAR_GET } from "../../types";
import {
  ADD_EXTRA ,
 GET_Extra ,
 UPDATE_Extra,
   DELETE_EXTRA 
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addExtraCuricular = (data, back) => (dispatch) => {
  axios
    .post(ADD_EXTRA, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getExtraCuricular));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getExtraCuricular = () => (dispatch) => {
  axios
    .get( GET_Extra, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch( EXTRA_CURRICULAR_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateEXtraCurricular = (data, back) => (dispatch) => {
  axios
    .put(  UPDATE_Extra , data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteExtraCurricular = (data) => (dispatch) => {
  console.log("chandra")
  axios
    .delete(DELETE_EXTRA, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getExtraCuricular));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
