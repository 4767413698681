import React, { Component } from "react";
import { Card, Row, Col } from "reactstrap";

// --------------------------------------------------------------

class TeamAssignmentCard extends Component {
  render() {
    return (
      <Row>
        {this.props.data.map((item) => {
          return (
            <Col md="4">
              <Card className="shadow p-2">
                <ul className="CustDetails-ul">
                  <li>
                    <span className="label-sm bold tag-contact-span-label-col-2">
                      Project Team
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{item.projectTeam}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold tag-contact-span-label-col-2">
                      Emp No.
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{item.empNo}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold tag-contact-span-label-col-2">
                      Emp Name
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{item.empName}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold tag-contact-span-label-col-2">
                      isActive
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{item.isActive.toString()}
                    </span>
                  </li>
                </ul>
              </Card>
            </Col>
          );
        })}
      </Row>
    );
  }
}

export default TeamAssignmentCard;
