import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// components
import UpdateFamilyContactDetails from "./UpdateFamilyContactDetails";
// redux
import { getContactsFamilyDetails } from "../../../../../../redux/actions/hcm/my_profile/contacts_details/contactsFamilyDetailsAction";

class FamilyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateBtn: false,
    };
    this.openUpdateForm = this.openUpdateForm.bind(this);
    this.closeUpdateForm = this.closeUpdateForm.bind(this);
    this.getData = this.getData.bind(this);
  }

  openUpdateForm() {
    this.setState({
      updateBtn: true,
    });
  }

  closeUpdateForm() {
    this.setState({
      updateBtn: false,
    });
    this.getData();
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.props.getContactsFamilyDetails();
  }

  render() {
    const { updateBtn } = this.state;

    return (
      <React.Fragment>
        {!updateBtn ? (
          <React.Fragment>
            <i
              className="material-icons pointer contact-edit-btn"
              onClick={this.openUpdateForm}
            >
              edit
            </i>
            <h5 className="bold center">Family Contact Details</h5>
            <ul>
              <li>
                <span className="bold label-sm contact-family-details-span">
                  Father's Name
                </span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  <span className="label-sm family-contact-span">
                    {this.props.contactsFamilyDetails.data.length
                      ? this.props.contactsFamilyDetails.data[0].fatherName
                      : null}
                  </span>
                </span>
              </li>
              <li>
                <span className="bold label-sm contact-family-details-span">
                  Father's Contact No.
                </span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  <span className="label-sm family-contact-span">
                    {this.props.contactsFamilyDetails.data.length
                      ? `${
                          this.props.contactsFamilyDetails.data[0]
                            .fatherDialingCode
                            ? `${this.props.contactsFamilyDetails.data[0].fatherDialingCode}-`
                            : ""
                        }${
                          this.props.contactsFamilyDetails.data[0]
                            .fatherContactNo
                        }`
                      : null}
                  </span>
                </span>
              </li>
              <li>
                <span className="bold label-sm contact-family-details-span">
                  Spouse Name
                </span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  <span className="label-sm family-contact-span">
                    {this.props.contactsFamilyDetails.data.length
                      ? this.props.contactsFamilyDetails.data[0].spouseName
                      : null}
                  </span>
                </span>
              </li>
              {}
              <li>
                <span className="bold label-sm contact-family-details-span">
                  Spouse Contact No.
                </span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  <span className="label-sm family-contact-span">
                    {this.props.contactsFamilyDetails.data.length
                      ? `${
                          this.props.contactsFamilyDetails.data[0]
                            .spouseDialingCode
                            ? `${this.props.contactsFamilyDetails.data[0].spouseDialingCode}-`
                            : ""
                        }${
                          this.props.contactsFamilyDetails.data[0]
                            .spouseContactNo
                        }`
                      : null}
                  </span>
                </span>
              </li>
            </ul>
          </React.Fragment>
        ) : null}
        {updateBtn && (
          <UpdateFamilyContactDetails
            back={this.closeUpdateForm}
            data={this.props.contactsFamilyDetails.data}
          />
        )}
      </React.Fragment>
    );
  }
}

FamilyDetails.propTypes = {
  getContactsFamilyDetails: PropTypes.func.isRequired,
  contactsFamilyDetails: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  contactsFamilyDetails: state.contactsFamilyDetails,
});

export default connect(mapStateToProps, { getContactsFamilyDetails })(
  FamilyDetails
);
