import axios from "axios";
import { ORGANIZATION_GET } from "../../types";
import { CURD_ORGANIZATION, USER_ORGANIZATION } from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addOrganization = (data, back) => (dispatch) => {
  axios
    .post(CURD_ORGANIZATION, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getOrganization));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getOrganization = () => (dispatch) => {
  axios
    .get(CURD_ORGANIZATION, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(ORGANIZATION_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserOrganization = () => (dispatch) => {
  axios
    .get(USER_ORGANIZATION, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(ORGANIZATION_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateOrganization = (data, back) => (dispatch) => {
  axios
    .put(CURD_ORGANIZATION, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getOrganization));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteOrganization = (data) => (dispatch) => {
  axios
    .delete(CURD_ORGANIZATION, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getOrganization));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
