import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { Card } from "reactstrap";
import { getLeadSource } from "../../../../redux/actions/leadSourceAction";
import { connect } from "react-redux";
import axios from "axios";
import { GET_LEAD_DISTRIBUSION } from "../../../../utils/routes";

const LegendStyle = {
  fontSize: "8px",
  fontWeight: "400",
  marginBottom: "10px",
  paddingLeft: "10px",
};

class CampaignOriginatedTopThreeCustOrders extends PureComponent {
  static jsfiddleUrl = "https://jsfiddle.net/alidingling/90v76x08/";

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      error: "",
    };
  }

  componentDidMount() {
    axios
      .get(GET_LEAD_DISTRIBUSION, { withCredentials: true })
      .then((res) => {
        this.setState({
          data: res.data.rows,
        });
      })
      .catch((err) => {
        this.setState({
          error: err,
        });
      });

    this.props.getLeadSource();
  }

  render() {
    const { data } = this.state;

    return (
      <Card className="recharts-card">
        <BarChart
          width={310}
          height={150}
          data={data}
          margin={{
            top: 5,
            right: 35,
            left: -15,
            bottom: 5,
          }}
          barSize={20}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="domainName"
            tick={{ fontSize: "10px", fontWeight: "400" }}
          />
          <YAxis
            tick={{ fontSize: "10px", fontWeight: "400" }}
            domain={[0, "dataMax"]}
          />
          <Tooltip contentStyle={{ fontSize: "10px", fontWeight: "400" }} />
          <Legend iconSize={8} wrapperStyle={LegendStyle} />
          {this?.props?.leadSource?.data?.length > 0 && this.props.leadSource.data.map((item) => {
            return (
              <Bar dataKey={item.leadSource} stackId="a" fill={item.color} />
            );
          })}
        </BarChart>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  leadSource: state.leadSource,
});

export default connect(mapStateToProps, { getLeadSource })(
  CampaignOriginatedTopThreeCustOrders
);
