import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from "axios";
// components
import DropdownComponent from "./DropdownComponent";
import Notifier from "./Notifier";
import ErrorNotifier from "./ErrorNotifier";
// redux
import { logout } from "../../redux/actions/iam/authentication/authenticationAndAuthorizationAction";
import {
  addMyAttendance,
  updateMyAttendance,
} from "../../redux/actions/hcm/lms/myAttendanceAction";

// ----------------------------------------------------------------------------------------------------
class AccountBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      timeInCondition: false,
      locationip: "",
      message: "",
      error: "",
    };
  }

  timeIn = () => {
    const data = {
      timeInlocIp: this.state.locationip,
      calendarDate: new Date(),
    };
    this.props.addMyAttendance(data);
  };

  timeOut = () => {
    const data = {
      timeOutlocIp: this.state.locationip,
      calendarDate: new Date(),
    };
    this.props.updateMyAttendance(data);
  };

  logout = () => {
    this.props.logout();
  };

  /*
componentDidMount function for fetching the attendance details of the employee
assigned the timeInCondition state value for giving coditional rendering for the timeIn button
*/
  componentDidMount() {
    axios
      .get("https://jsonip.com")
      .then((res) => {
        this.setState({
          locationip: res.data.ip,
        });
      })
      .catch((err) => {});
  }

  render() {
    return (
      <>
        <div
          className="account-card center"
          style={{ left: `${window.innerWidth - 272}px` }}
          onMouseLeave={() => this.props.click()}
        >
          <div className="account-name">
            <DropdownComponent />
            <span className="ml-2">
              {`${this.props.auth.data.pieUser.firstName} ${this.props.auth.data.pieUser.lastName}`}
            </span>
          </div>
          <div className="left">
            <Link to="/pie/hcm/my_profile" className="no-dec">
              <div className="box-label flex-left pointer">
                <i className="material-icons">account_circle</i>
                <span className="ml-3">My Profile</span>
              </div>
            </Link>

            <Link to="/pie/edit/password" className="no-dec">
              <div className="box-label pointer flex-left">
                <i className="material-icons">security</i>
                <span className="no-dec ml-3" to="/pie/edit/password">
                  Change Password
                </span>
              </div>
            </Link>
            <div className="pointer flex-left box-label">
              <i className="material-icons">lock</i>
              <span className="no-dec ml-3" onClick={this.logout}>
                Logout
              </span>
            </div>
            <hr />
            <div className="attendance-box">
              <span className="start-shift pointer" onClick={this.timeIn}>
                <i className="material-icons">timer</i>
                <span>Time In</span>
              </span>
              <span className="end-shift pointer" onClick={this.timeOut}>
                <i className="material-icons">timer_off</i>
                <span>Time Out</span>
              </span>
            </div>
          </div>
        </div>
        {this.state.message ? <Notifier message={this.state.message} /> : null}
        {this.props.myAttendance.message ? (
          <Notifier message={this.props.myAttendance.message} />
        ) : null}
        {this.props.myAttendance.error ? (
          <ErrorNotifier message={this.props.myAttendance.error} />
        ) : null}
      </>
    );
  }
}

AccountBox.propTypes = {
  logout: PropTypes.func.isRequired,
  addMyAttendance: PropTypes.func.isRequired,
  updateMyAttendance: PropTypes.func.isRequired,
  myAttendance: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  myAttendance: state.myAttendance,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  logout,
  addMyAttendance,
  updateMyAttendance,
})(AccountBox);
