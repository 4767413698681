import axios from "axios";
import { CONTACT_DESIGNATION_GET } from "../../types";
import {
  CURD_CONTACT_DESIGNATION,
  USER_CONTACT_DESIGNATION,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addContactDesignation = (data, back) => (dispatch) => {
  axios
    .post(CURD_CONTACT_DESIGNATION, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getContactDesignation));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getContactDesignation = () => (dispatch) => {
  axios
    .get(CURD_CONTACT_DESIGNATION, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CONTACT_DESIGNATION_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserContactDesignation = () => (dispatch) => {
  axios
    .get(USER_CONTACT_DESIGNATION, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CONTACT_DESIGNATION_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateContactDesignation = (data, back) => (dispatch) => {
  axios
    .put(CURD_CONTACT_DESIGNATION, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteContactDesignation = (data) => (dispatch) => {
  axios
    .delete(CURD_CONTACT_DESIGNATION, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getContactDesignation));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
