import React, { Component } from "react";
// components
import Collection from "./Collection";
import IdleTimeOut from "./idle_timeout/IdleTimeOut";

// ----------------------------------------------------------------------------------------------------------------------------------
class EmpIndex extends Component {
  render() {
    return (
      <div>
        <Collection />
        <IdleTimeOut />
      </div>
    );
  }
}

export default EmpIndex;
