import axios from "axios";
import {
  GET_ERRORS,
  INVOICE_GET,
  RECIEVE_ITEM_GET,
  LATEST_INVOICE_GET,
} from "../../../types";
import {
  GET_INVOICE,
  ADD_INVOICE,
  UPDATE_INVOICE,
  ADD_RECIEVE_ITEM,
  UPDATE_RECIEVE_ITEM,
  GET_RECIEVE_ITEM,
  GET_LATEST_INVOICE,
} from "../../../../../utils/routes";
import { setMessage } from "../../../salesActions";

export const getInvoices = () => (dispatch) => {
  axios
    .get(GET_INVOICE, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: INVOICE_GET,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};

export const addInvoice = (data, back) => (dispatch) => {
  axios
    .post(ADD_INVOICE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(latestInvoice(data.createdBy));
        // back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const updateInvoice = (data, back) => (dispatch) => {
  axios
    .post(UPDATE_INVOICE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getInvoices());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const addUpdateInvoice = (data) => (dispatch) => {
  axios
    .post(UPDATE_INVOICE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getInvoices());
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const addRecieveItem = (data, back) => (dispatch) => {
  axios
    .post(ADD_RECIEVE_ITEM, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        // dispatch(getRecieveItems());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const updateRecieveItem = (data, back) => (dispatch) => {
  axios
    .post(UPDATE_RECIEVE_ITEM, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        // dispatch(getRecieveItems());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const getRecieveItems = (data) => (dispatch) => {
  axios
    .post(GET_RECIEVE_ITEM, data, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: RECIEVE_ITEM_GET,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};

export const latestInvoice = (empId) => (dispatch) => {
  let data = {
    createdBy: empId,
  };

  axios
    .post(GET_LATEST_INVOICE, data, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: LATEST_INVOICE_GET,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};

export const resetItems = () => (dispatch) => {
  dispatch({
    type: RECIEVE_ITEM_GET,
    payload: [],
  });
};
