import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormText,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { connect } from "react-redux";
import {
  updateSalesInvoice,
  getRecieveItems,
  updateRecieveItem,
} from "../../../../../redux/actions/finance/accounts_receivable/sales_invoice/salesInvoiceAction";
import { getCurrency } from "../../../../../redux/actions/currencyAction";
import { getPaymentStatus } from "../../../../../redux/actions/finance/invoice/paymentStatusAction";
import { getPartnerPymtTerms } from "../../../../../redux/actions/partnerPymtTermsAction";
import FormComponent from "../../../../common/FormComponent";
import {
  errors,
  Validator,
  submitValidator,
  valid,
  refresh,
} from "../../../../common/Validator";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import Notifier from "../../../../aside/Notifier";

/**
 * @class AddInvoice
 **/

class UpdateSalesInvoice extends Component {
  constructor(props) {
    super(props);
    const {
      salesInvoiceId,
      poId,
      poEntryName,
      invoiceNo,
      invoiceDate,
      invoiceType,
      customerName,
      address,
      contactName,
      contactEmail,
      contactMobileNo,
      tin,
      pan,
      gst,
      bankName,
      bankIfscCode,
      bankAccName,
      bankAccNo,
      invoiceCurrency,
      invoiceAmount,
      invoiceGst,
      invoiceTotalAmount,
      pymtTerm,
      pymtInstructions,
      pymtStatus,
      remarks,
    } = this.props.salesInvoiceDetail;
    this.state = {
      salesInvoiceId: salesInvoiceId,
      poId: poId,
      poName: poEntryName,
      invoiceNumber: invoiceNo,
      invoiceDate: invoiceDate,
      invoiceType: invoiceType,
      customerName: customerName,
      address: address,
      contactName: contactName,
      contactEmail: contactEmail,
      contactPhoneNumber: contactMobileNo,
      gstin: gst,
      pan: pan,
      tin: tin,
      bankName: bankName,
      bankIfscCode: bankIfscCode,
      bankAccountName: bankAccName,
      bankAccountNumber: bankAccNo,
      invoiceCurrency: invoiceCurrency,
      invoiceAmount: invoiceAmount,
      invoiceGst: invoiceGst,
      invoiceTotalAmount: invoiceTotalAmount,
      paymentTerms: pymtTerm,
      paymentInstructions: pymtInstructions,
      status: pymtStatus,
      remarks: remarks,
      recieveItemModal: false,
      salesReceivedItemsId: "",
      purchaseItemNo: "",
      pspName: "",
      quantity: "",
      amount: "",
      amountGST: "",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  componentDidMount() {
    refresh();
    this.props.getCurrency();
    this.props.getPaymentStatus();
    let data1 = {
      salesInvoiceId: this.state.salesInvoiceId,
    };
    this.props.getRecieveItems(data1);
    this.props.getPartnerPymtTerms();
  }

  // componentDidUpdate function to update the some fields
  componentDidUpdate(prevProps, prevState) {
    if (prevState.amount !== this.state.amount) {
      this.setState({
        amountGST: (this.state.amount * 0.18).toFixed(2),
      });
    }
    if (
      prevProps.salesInvoice.recieveItemData !==
      this.props.salesInvoice.recieveItemData
    ) {
      if (this.props.salesInvoice.recieveItemData.length !== 0) {
        let arr = this.props.salesInvoice.recieveItemData;
        let sumAmount = 0;
        let sumAmountGst = 0;
        arr.forEach(function (obj) {
          sumAmount += obj.amount * obj.qty;
          sumAmountGst += obj.gstAmount * obj.qty;
        });
        this.setState({
          invoiceAmount: sumAmount,
          invoiceGst: sumAmountGst.toFixed(2),
          invoiceTotalAmount:
            parseInt(sumAmount) + parseFloat(sumAmountGst.toFixed(2)),
        });
      }
    }
  }

  // onSubmit function to submit the form
  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);

    if (valid) {
      let data = {
        salesInvoiceId: this.props.salesInvoice.salesInvoiceId,
        poId: this.state.poId,
        invoiceNo: this.state.invoiceNumber,
        invoiceDate: this.state.invoiceDate,
        invoiceCurrency: this.state.invoiceCurrency,
        invoiceAmount: this.state.invoiceAmount,
        invoiceGst: this.state.invoiceGst,
        invoiceTotalAmount: this.state.invoiceTotalAmount,
        pymtTerm: this.state.paymentTerms,
        pymtInstructions: this.state.paymentInstructions,
        pymtStatus: this.state.invoiceStatus,
        remarks: this.state.remarks,
        updatedBy: localStorage.getItem("id"),
      };

      this.props.updateSalesInvoice(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all Mandatory Fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  // updateReceivedItem function to update the receive items
  receivedItemUpdate = (item) => {
    this.setState({
      salesReceivedItemsId: item.salesReceivedItemsId,
      // poId: item.poId,
      salesInvoiceId: item.salesInvoiceId,
      purchaseItemNo: item.receivableItemNo,
      pspName: item.ppsName,
      quantity: item.qty,
      amount: item.amount,
      amountGST: item.gstAmount,
      recieveItemModal: true,
    });
  };

  recieveItemToggle = () => {
    this.setState({
      recieveItemModal: !this.state.recieveItemModal,
    });
    refresh();
    let data1 = {
      salesInvoiceId: this.state.salesInvoiceId,
    };
    this.props.getRecieveItems(data1);
  };

  // recieveItemSubmit function for submitting the form details
  recieveItemSubmit = (e) => {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      // poId: this.state.poId,
      salesReceivedItemsId: this.state.salesReceivedItemsId,
      salesInvoiceId: this.state.salesInvoiceId,
      receivableItemNo: this.state.purchaseItemNo,
      ppsName: this.state.pspName,
      qty: this.state.quantity,
      amount: this.state.amount,
      gstAmount: this.state.amountGST,
    };
    if (valid) {
      this.props.updateRecieveItem(data, this.recieveItemToggle);
    } else {
      this.setState({
        error: "Enter all Mandatory Fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  };

  render() {
    const {
      poId,
      poName,
      invoiceNumber,
      invoiceDate,
      invoiceType,
      customerName,
      address,
      contactName,
      contactEmail,
      contactPhoneNumber,
      gstin,
      pan,
      tin,
      bankName,
      bankIfscCode,
      bankAccountName,
      bankAccountNumber,
      invoiceCurrency,
      invoiceAmount,
      invoiceGst,
      invoiceTotalAmount,
      paymentTerms,
      paymentInstructions,
      status,
      remarks,
      recieveItemModal,
      purchaseItemNo,
      pspName,
      quantity,
      amount,
      amountGST,
      error,
    } = this.state;

    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Update Sales Invoice</h5>
        <Row>
          <Col md="6">
            <Form onSubmit={this.onSubmit} className="p-3" noValidate>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label className="label-sm bold">PO Id</Label>
                    <Input
                      type="text"
                      name="poId"
                      value={`${poId}-${poName}`}
                      className="form-control-sm"
                      onChange={this.onChange}
                      required={true}
                      disabled={true}
                    >
                      {/* <option value="" hidden>
                                Select
                              </option>
                              {this.props.poEntry.data.map((item) => {
                                return (
                                  <option key={item.poId} value={item.poId}>
                                    {item.poId}
                                  </option>
                                );
                              })} */}
                    </Input>
                    {errors.poId && (
                      <FormText className="error">{errors.poId}</FormText>
                    )}
                  </FormGroup>
                  <FormComponent
                    label="Invoice Number"
                    labelClass="label-sm bold"
                    type="number"
                    name="invoiceNumber"
                    value={invoiceNumber}
                    className="form-control-sm"
                    change={this.onChange}
                    required={true}
                  />
                  <FormComponent
                    label="Invoice Date"
                    labelClass="label-sm bold"
                    type="date"
                    name="invoiceDate"
                    value={invoiceDate}
                    className="form-control-sm"
                    change={this.onChange}
                    required={true}
                  />
                  <FormGroup>
                    <Label className="label-sm bold">Invoice Type</Label>
                    <Input
                      type="text"
                      name="invoiceType"
                      value={invoiceType}
                      className="form-control-sm"
                      onChange={this.onChange}
                      required={true}
                      disabled={true}
                    >
                      {/* <option value="" hidden>
                                Select
                              </option>
                              {this.props.invoiceTypes.data.map((item) => {
                                return (
                                  <option
                                    key={item.invoiceTypeId}
                                    value={item.invoiceType}
                                  >
                                    {item.invoiceType}
                                  </option>
                                );
                              })} */}
                    </Input>
                    {errors.invoiceType && (
                      <FormText className="error">
                        {errors.invoiceType}
                      </FormText>
                    )}
                  </FormGroup>
                  {/* <FormGroup>
                          <Label className="label-sm bold">Customer Name</Label>
                          <Select
                            name="customerNameOptions"
                            value={customerNameSelectedOption}
                            onChange={this.customerNameHandleChange}
                            options={customerNameOptions}
                            className="select-form-control-sm"
                            required
                          />

                          {customerNameSelectedOptionError && (
                            <FormText className="error ">
                              {customerNameSelectedOptionError}
                            </FormText>
                          )}
                        </FormGroup> */}
                  <FormComponent
                    label="Customer Name"
                    labelClass="label-sm bold"
                    type="text"
                    name="customerName"
                    value={customerName}
                    className="form-control-sm"
                    change={this.onChange}
                    required={true}
                    disabled={true}
                  />
                  <FormComponent
                    label="Address"
                    labelClass="label-sm bold"
                    type="text"
                    name="address"
                    value={address}
                    className="form-control-sm"
                    change={this.onChange}
                    required={true}
                    disabled={true}
                  />
                  <FormComponent
                    label="Contact Name"
                    labelClass="label-sm bold"
                    type="text"
                    name="contactName"
                    value={contactName}
                    className="form-control-sm"
                    change={this.onChange}
                    required={true}
                    disabled={true}
                  />
                  <FormComponent
                    label="Contact Email"
                    labelClass="label-sm bold"
                    type="email"
                    name="contactEmail"
                    value={contactEmail}
                    className="form-control-sm"
                    change={this.onChange}
                    required={true}
                    disabled={true}
                  />
                  <FormComponent
                    label="Contact Phone Number"
                    labelClass="label-sm bold"
                    type="tel"
                    name="contactPhoneNumber"
                    value={contactPhoneNumber}
                    className="form-control-sm"
                    change={this.onChange}
                    required={true}
                    disabled={true}
                  />
                  <FormComponent
                    label="GSTIN"
                    labelClass="label-sm bold"
                    type="text"
                    name="gstin"
                    value={gstin}
                    change={this.onChange}
                    className="form-control-sm"
                    required={true}
                    disabled={true}
                    maxlength="15"
                  />
                  <FormComponent
                    label="PAN"
                    labelClass="label-sm bold"
                    type="text"
                    name="pan"
                    value={pan}
                    change={this.onChange}
                    className="form-control-sm"
                    required={true}
                    disabled={true}
                    maxlength="10"
                  />
                  <FormComponent
                    label="TIN"
                    labelClass="label-sm bold"
                    type="text"
                    name="tin"
                    value={tin}
                    change={this.onChange}
                    className="form-control-sm"
                    required={true}
                    disabled={true}
                    maxlength="11"
                  />
                  <FormComponent
                    label="Bank Name"
                    labelClass="label-sm bold"
                    type="text"
                    name="bankName"
                    value={bankName}
                    className="form-control-sm"
                    change={this.onChange}
                    required={true}
                    disabled={true}
                  />
                </Col>
                <Col md="6">
                  <FormComponent
                    label="Bank IFSC Code"
                    labelClass="label-sm bold"
                    type="text"
                    name="bankIfscCode"
                    value={bankIfscCode}
                    className="form-control-sm"
                    change={this.onChange}
                    required={true}
                    disabled={true}
                  />
                  <FormComponent
                    label="Bank Account Name"
                    labelClass="label-sm bold"
                    type="text"
                    name="bankAccountName"
                    value={bankAccountName}
                    className="form-control-sm"
                    change={this.onChange}
                    required={true}
                    disabled={true}
                  />
                  <FormComponent
                    label="Bank Account Number"
                    labelClass="label-sm bold"
                    type="number"
                    name="bankAccountNumber"
                    value={bankAccountNumber}
                    className="form-control-sm"
                    change={this.onChange}
                    required={true}
                    disabled={true}
                  />
                  <FormGroup>
                    <Label className="bold label-sm">Invoice Currency</Label>
                    <Input
                      type="select"
                      name="invoiceCurrency"
                      className="form-control-sm "
                      value={invoiceCurrency}
                      onChange={this.onChange}
                      required={true}
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.currency.data.map((item) => {
                        return (
                          <option
                            key={item.currencyId}
                            value={item.currencyCode}
                          >
                            {item.currencyCode}
                          </option>
                        );
                      })}
                    </Input>
                    {errors.invoiceCurrency && (
                      <FormText className="error">
                        {errors.invoiceCurrency}
                      </FormText>
                    )}
                  </FormGroup>
                  <FormComponent
                    label="Invoice Amount"
                    labelClass="label-sm bold"
                    type="number"
                    name="invoiceAmount"
                    value={invoiceAmount}
                    className="form-control-sm"
                    change={this.onChange}
                    required={true}
                    disabled={true}
                  />
                  <FormComponent
                    label="Invoice GST"
                    labelClass="label-sm bold"
                    type="number"
                    name="invoiceGst"
                    value={invoiceGst}
                    className="form-control-sm"
                    change={this.onChange}
                    required={true}
                    disabled={true}
                  />
                  <FormComponent
                    label="Invoice Total Amount"
                    labelClass="label-sm bold"
                    type="number"
                    name="invoiceTotalAmount"
                    value={invoiceTotalAmount}
                    className="form-control-sm"
                    change={this.onChange}
                    required={true}
                    disabled={true}
                  />
                  <FormGroup>
                    <Label className="label-sm bold">Payment Terms</Label>
                    <Input
                      type="select"
                      className="form-control-sm"
                      name="paymentTerms"
                      value={paymentTerms}
                      onChange={this.onChange}
                      required={true}
                    >
                      {" "}
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.partnerPymtTerms.data.map((item) => {
                        return (
                          <option
                            key={item.partnerPymtId}
                            value={item.partnerPymtName}
                          >
                            {item.partnerPymtName}
                          </option>
                        );
                      })}
                    </Input>
                    {errors.paymentTerms && (
                      <FormText className="error">
                        {errors.paymentTerms}
                      </FormText>
                    )}
                  </FormGroup>
                  <FormComponent
                    label="Payment Instructions"
                    labelClass="label-sm bold"
                    type="text"
                    name="paymentInstructions"
                    value={paymentInstructions}
                    className="form-control-sm"
                    change={this.onChange}
                    required={true}
                  />
                  <FormGroup>
                    <Label className="label-sm bold">Status</Label>
                    <Input
                      type="select"
                      className="form-control-sm"
                      name="status"
                      value={status}
                      onChange={this.onChange}
                      required
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.paymentStatus.data.map((item) => {
                        return (
                          <option
                            key={item.pymtStatusId}
                            value={item.pymtStatusName}
                          >
                            {item.pymtStatusName}
                          </option>
                        );
                      })}
                    </Input>
                    {errors.invoiceStatus && (
                      <FormText className="error">
                        {errors.invoiceStatus}
                      </FormText>
                    )}
                  </FormGroup>
                  <FormComponent
                    label="Remarks"
                    labelClass="label-sm bold"
                    type="textarea"
                    name="remarks"
                    value={remarks}
                    className="form-control-sm"
                    change={this.onChange}
                    required={true}
                  />
                </Col>
              </Row>
              <div className="center">
                <button
                  type="submit"
                  className="btn center login-button white-text"
                >
                  Update
                </button>
              </div>
            </Form>
          </Col>
          <Col md="6">
            <Row className="milestone-row">
              <div className="milestone-div">
                <h6 className="center">Receive Items</h6>
                <Table>
                  <thead>
                    <tr>
                      <th className="opp-head">Name</th>
                      <th className="opp-head">Qty</th>
                      <th className="opp-head">Amount</th>
                      <th className="opp-head">GST</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.salesInvoice.recieveItemData.map((item) => {
                      return (
                        <tr key={item.recieveItemsId}>
                          <td>{item.ppsName}</td>
                          <td>{item.qty}</td>
                          <td>{item.amount}</td>
                          <td>{item.gstAmount}</td>
                          <td>
                            {" "}
                            <span
                              className="product-pq-button"
                              id="lead-activity-edit-button"
                              title="Edit"
                              style={{ color: "blue" }}
                              onClick={() => this.receivedItemUpdate(item)}
                            >
                              edit
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Row>
          </Col>
        </Row>
        <Modal isOpen={recieveItemModal} size="md">
          <ModalHeader toggle={this.recieveItemToggle}>
            Receive Items
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={this.recieveItemSubmit} noValidate>
              <Row>
                <Col md="6" className="mx-auto">
                  <FormComponent
                    label="Purchase Item Number"
                    labelClass="label-sm  bold"
                    className="form-control-sm"
                    type="number"
                    name="purchaseItemNo"
                    value={purchaseItemNo}
                    change={this.onChange}
                    required={true}
                  />
                  <FormComponent
                    label="Product/Service/Project Name"
                    labelClass="label-sm  bold"
                    className="form-control-sm"
                    type="text"
                    name="pspName"
                    value={pspName}
                    change={this.onChange}
                    required={true}
                  />
                  <FormComponent
                    label="Quantity"
                    labelClass="label-sm  bold"
                    className="form-control-sm"
                    type="number"
                    name="quantity"
                    value={quantity}
                    change={this.onChange}
                    required={true}
                  />
                  <FormComponent
                    label="Amount"
                    labelClass="label-sm  bold"
                    className="form-control-sm"
                    type="number"
                    name="amount"
                    value={amount}
                    change={this.onChange}
                    required={true}
                  />
                  <FormComponent
                    label="Amount GST"
                    labelClass="label-sm  bold"
                    className="form-control-sm"
                    type="number"
                    name="amountGST"
                    value={amountGST}
                    change={this.onChange}
                    required={true}
                    disabled={true}
                  />
                </Col>
              </Row>
              <div className="center">
                <button
                  type="submit"
                  className="btn center login-button white-text"
                >
                  Update
                </button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
        {error && <ErrorNotifier message={error} />}
        {this.props.salesInvoice.message && (
          <Notifier message={this.props.salesInvoice.message} />
        )}
        {this.props.salesInvoice.error && (
          <ErrorNotifier message={this.props.salesInvoice.error} />
        )}
      </React.Fragment>
    );
  }
}

UpdateSalesInvoice.propTypes = {
  updateSalesInvoice: PropTypes.func.isRequired,
  getRecieveItems: PropTypes.func.isRequired,
  updateRecieveItem: PropTypes.func.isRequired,
  salesInvoice: PropTypes.object.isRequired,
  getCurrency: PropTypes.func.isRequired,
  currency: PropTypes.object.isRequired,
  getPaymentStatus: PropTypes.func.isRequired,
  paymentStatus: PropTypes.object.isRequired,
  getPartnerPymtTerms: PropTypes.func.isRequired,
  partnerPymtTerms: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  salesInvoice: state.salesInvoice,
  currency: state.currency,
  paymentStatus: state.paymentStatus,
  partnerPymtTerms: state.partnerPymtTerms,
});

export default connect(mapStateToProps, {
  updateSalesInvoice,
  getRecieveItems,
  updateRecieveItem,
  getCurrency,
  getPaymentStatus,
  getPartnerPymtTerms,
})(UpdateSalesInvoice);
