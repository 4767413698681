import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import DateFns from "date-fns";
import UpdatePOEntry from "./UpdatePOEntry";

class POEntryDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
    };
    this.updateNow = this.updateNow.bind(this);
  }

  updateNow() {
    this.setState({
      edit: true,
    });
  }

  render() {
    const { edit } = this.state;

    return (
      <Container className="card container-card p-3">
        <i
          className="material-icons pointer"
          style={{
            position: "absolute",
            top: "5px",
            right: "5px",
            color: "#C00",
          }}
          onClick={this.props.back}
        >
          cancel
        </i>
        {!edit ? (
          <React.Fragment>
            <h5 className="center">PO Entry Details</h5>
            <hr />
            <Row>
              <Col md="6">
                <ul style={{ listStyle: "none" }}>
                  <li>
                    <span className="label-sm bold poDetails-span-label-col-1">
                      PO ID
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.POEntryDetails.poId}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold poDetails-span-label-col-1">
                      PO Name
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.POEntryDetails.poEntryName}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold poDetails-span-label-col-1">
                      PO Number
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.POEntryDetails.poNo}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold poDetails-span-label-col-1">
                      PO Type
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.POEntryDetails.poType}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold poDetails-span-label-col-1">
                      Name
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.POEntryDetails.customerName}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold poDetails-span-label-col-1">
                      Contact Name
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.POEntryDetails.contactName}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold poDetails-span-label-col-1">
                      Contact Email
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.POEntryDetails.contactEmail}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold poDetails-span-label-col-1">
                      contact No
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.POEntryDetails.contactMobileNo}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold poDetails-span-label-col-1">
                      Ship To Location
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.POEntryDetails.shipToLocation}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold poDetails-span-label-col-1">
                      Ship To Addr
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.POEntryDetails.shipToAddress}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold poDetails-span-label-col-1">
                      Ship To Contact Email
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.POEntryDetails.shipToContactMobileEmail}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold poDetails-span-label-col-1">
                      Ship To Contact No
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.POEntryDetails.shipToContactMobileNo}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold poDetails-span-label-col-1">
                      PO Currency
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.POEntryDetails.poCurrency}
                    </span>
                  </li>
                </ul>
              </Col>
              <Col md="6">
                <ul style={{ listStyle: "none" }}>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      PO Description
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.POEntryDetails.poDescription}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Pymt Terms
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.POEntryDetails.pymtTerm}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Total Amount
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.POEntryDetails.totalAmount}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Total Gst
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.POEntryDetails.totalGst}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      PO Total Amount
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.POEntryDetails.poTotalAmount}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Pymt Instructions
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.POEntryDetails.pymtInstructions}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Status
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.POEntryDetails.poEntryStatus}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Remarks
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.POEntryDetails.remarks}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Created By
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.POEntryDetails.createdBy}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Created On
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.POEntryDetails.createdOn &&
                        DateFns.format(
                          this.props.POEntryDetails.createdOn,
                          "DD-MM-YYYY"
                        )}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Updated By
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.POEntryDetails.updatedBy}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Updated On
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.POEntryDetails.updatedOn &&
                        DateFns.format(
                          this.props.POEntryDetails.updatedOn,
                          "DD-MM-YYYY"
                        )}
                    </span>
                  </li>
                </ul>
              </Col>
            </Row>
            <div className="div">
              <hr />
              <div className="center">
                {/* {this.props.inquiry.inqStatus !== "Closed" &&
                this.props.inquiry.inqStatus !== "Lead" ? ( */}
                <button
                  className="btn login-button white-text"
                  onClick={this.updateNow}
                >
                  Update
                </button>
                {/* ) : (
                  ""
                )} */}
              </div>
            </div>
          </React.Fragment>
        ) : (
          <UpdatePOEntry
            POEntry={this.props.POEntryDetails}
            back={this.props.back}
          />
        )}
      </Container>
    );
  }
}

export default POEntryDetails;
