import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, Form, FormGroup, Input, Label, FormText } from "reactstrap";
// material ui
import { IconButton, Divider } from "@material-ui/core";
// icons
import EditIcon from "@material-ui/icons/Edit";
import LabelIcon from "@material-ui/icons/Label";
// components
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../common/Validator";
import FormComponent from "../../../common/FormComponent";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import CustomizedDialogs from "../../../common/customized-dialog/CustomizedDialogs";
import AddTagContact from "./tage_contact/AddTagContact";
import UpdateTagContact from "./tage_contact/UpdateTagContact";
import DetailsPageFooter from "../../../common/details_footer/DetailsPageFooter";
// redux
import {
  updateContacts,
  getContacts,
} from "../../../../redux/actions/crm/contacts/contactsAction";
import { getCustomers } from "../../../../redux/actions/crm/customers/customersAction";
import { getAccounts } from "../../../../redux/actions/crm/accounts/accountsAction";
import { getUserContactDesignation } from "../../../../redux/actions/crm/crm_setup/contactDesignationAction";
import { getTagContacts } from "../../../../redux/actions/crm/contacts/tagContactsAction";
import { getUserCustomerCountries } from "../../../../redux/actions/crm/crm_setup/customerCountriesAction";

// ---------------------------------------------------------------------------------------------------------

class ContactDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactId: "",
      salutation: "",
      contactName: "",
      contactEmail: "",
      country: "",
      contactDialingCode: "",
      contactMobileNo: "",
      alternateDialingCode: "",
      alternateMobileNo: null,
      designation: "",
      remarks: "",
      status: "",
      edit: false,
      open: false,
      message: "",
      error: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.crmContacts.message) {
      setTimeout(() => {
        this.props.back();
      }, 2000);
    }
  }

  onChange(e) {
    e.preventDefault();
    Validator(e.target);
    if (e.target.name === "country") {
      this.setState({
        contactDialingCode: e.target.value.split(",")[1],
        alternateDialingCode: e.target.value.split(",")[1],
      });
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  /*
  This function for updating the customer details
  */
  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let comp = {
      id: this.state.contactId,
      salutation: this.state.salutation,
      contactName: this.state.contactName,
      contactEmail: this.state.contactEmail,
      country: this.state.country.split(",")[0],
      contactDialingCode: this.state.contactDialingCode,
      contactMobileNo: this.state.contactMobileNo,
      alternateDialingCode: this.state.alternateDialingCode,
      contactAlternateNo: this.state.alternateMobileNo
        ? this.state.alternateMobileNo
        : null,
      contactDesignation: this.state.designation,
      remarks: this.state.remarks,
      contactStatus: this.state.status,
    };
    if (valid) {
      this.props.updateContacts(comp, this.props.back);
      setTimeout(() => {
        this.props.getContacts();
      }, 2000);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
  ComponentDidMount getting the selected customer details and setting the state of customer details
  */
  componentDidMount() {
    const {
      id,
      salutation,
      contactName,
      contactEmail,
      country,
      contactDialingCode,
      contactMobileNo,
      alternateDialingCode,
      contactAlternateNo,
      contactDesignation,
      remarks,
      contactStatus,
    } = this.props.contacts;
    this.setState({
      contactId: id,
      salutation: salutation,
      contactName: contactName,
      contactEmail: contactEmail,
      country: `${country},${contactDialingCode}`,
      contactDialingCode: contactDialingCode,
      contactMobileNo: contactMobileNo,
      alternateDialingCode: alternateDialingCode,
      alternateMobileNo: contactAlternateNo,
      designation: contactDesignation,
      remarks: remarks,
      status: contactStatus,
    });
    this.props.getUserContactDesignation();
    this.props.getTagContacts();
    this.props.getUserCustomerCountries();
    refresh();
  }

  // modal functions
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    return (
      <Row>
        {!this.state.edit ? (
          <Col md="12">
            <i
              onClick={() => this.props.back()}
              className="material-icons pointer cancel-button"
            >
              cancel
            </i>
            <h5 className="bold center mb-4">
              Contact : {this.state.contactId}
            </h5>
            {Object.keys(this.props.permission).length > 0 &&
              this.props.permission.updation && (
                <IconButton
                  className="edit-button"
                  color="primary"
                  onClick={() => this.setState({ edit: !this.state.edit })}
                  title="edit contact"
                >
                  <EditIcon />
                </IconButton>
              )}
            <IconButton
              className="tag-button"
              color="primary"
              title="tag customer"
              onClick={this.handleClickOpen}
            >
              <LabelIcon />
            </IconButton>
            <Row>
              <Col md="6">
                <ul className="CustDetails-ul">
                  <li>
                    <span className="list-label contact-span-label-col-1">
                      Customer Name
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.contacts?.customerName}
                    </span>
                  </li>
                  <li>
                    <span className="list-label contact-span-label-col-1">
                      Entity Name
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.contacts?.entityName}
                    </span>
                  </li>
                  <li>
                    <span className="list-label contact-span-label-col-1">
                      Account Name
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.contacts?.accountName}
                    </span>
                  </li>
                  <li>
                    <span className="list-label contact-span-label-col-1">
                      Salutation
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.salutation}
                    </span>
                  </li>
                  <li>
                    <span className="list-label contact-span-label-col-1">
                      Contact Name
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.contactName}
                    </span>
                  </li>
                  <li>
                    <span className="list-label contact-span-label-col-1">
                      Contact Email
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.contactEmail}
                    </span>
                  </li>
                </ul>
              </Col>
              <Col md="6">
                <ul className="CustDetails-ul">
                  <li>
                    <span className="list-label contact-span-label-col-2">
                      Country
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.props.contacts.country}
                    </span>
                  </li>
                  <li>
                    <span className="list-label contact-span-label-col-2">
                      Contact Mobile No.
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.contactDialingCode}-
                      {this.state.contactMobileNo}
                    </span>
                  </li>
                  <li>
                    <span className="list-label contact-span-label-col-2">
                      Alternate Mobile No.
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.state.alternateMobileNo
                        ? `${this.state.alternateDialingCode}-${this.state.alternateMobileNo}`
                        : this.state.alternateMobileNo}
                    </span>
                  </li>
                  <li>
                    <span className="list-label contact-span-label-col-2">
                      Status
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.status}
                    </span>
                  </li>
                  <li>
                    <span className="list-label contact-span-label-col-2">
                      Designation
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.designation}
                    </span>
                  </li>
                  <li>
                    <span className="list-label contact-span-label-col-2">
                      Remarks
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.remarks}
                    </span>
                  </li>
                </ul>
              </Col>
            </Row>
            <Divider />
            <DetailsPageFooter
              createdBy={this.props.contacts.createdBy}
              createdOn={this.props.contacts.createdOn}
              updatedBy={this.props.contacts.updatedBy}
              updatedOn={this.props.contacts.updatedOn}
            />
          </Col>
        ) : (
          <Col md="12">
            <h5 className="bold center mt-2">Update Contact</h5>
            <i
              onClick={() => this.props.back()}
              className="material-icons pointer cancel-button"
            >
              cancel
            </i>
            <Form onSubmit={this.onSubmit} className="p-3" noValidate>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label className="label-sm bold">Salutation</Label>
                    <Input
                      type="select"
                      name="salutation"
                      onChange={this.onChange}
                      value={this.state.salutation}
                      className="form-control-sm"
                    >
                      <option value="Mr.">Mr.</option>
                      <option value="Mrs.">Mrs.</option>
                      <option value="Ms.">Ms.</option>
                    </Input>
                  </FormGroup>
                  <FormComponent
                    labelClass="bold label-sm"
                    label="Contact Name"
                    type="text"
                    name="contactName"
                    change={this.onChange}
                    value={this.state.contactName}
                    placeholder="Contact Name"
                    maxlength="27"
                    required={true}
                  />
                  <FormComponent
                    labelClass="bold label-sm"
                    label="Contact Email"
                    type="email"
                    name="contactEmail"
                    change={this.onChange}
                    value={this.state.contactEmail}
                    placeholder="Contact Email"
                    required={true}
                  />
                  <FormGroup>
                    <Label className="bold label-sm">Contact Status</Label>
                    <Input
                      type="select"
                      name="status"
                      value={this.state.status}
                      onChange={this.onChange}
                      className="form-control-sm"
                    >
                      <option value="Active">Active</option>
                      <option value="InActive">InActive</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label className="label-sm bold">Country</Label>
                    <Input
                      type="select"
                      name="country"
                      onChange={this.onChange}
                      value={this.state.country}
                      className="form-control-sm"
                      required
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.customerCountries.data.length > 0 &&
                        this.props.customerCountries.data.map((item) => (
                          <option
                            key={item.value}
                            value={`${item.countryName},${item.dialingCode}`}
                          >
                            {item.countryName}
                          </option>
                        ))}
                    </Input>
                    {errors.country && (
                      <FormText className="error">{errors.country}</FormText>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <Row>
                    <Col md="2" className="p-0">
                      <FormComponent
                        labelClass="bold label-sm"
                        label="Code"
                        type="number"
                        value={this.state.contactDialingCode}
                        required={true}
                        disabled={true}
                      />
                    </Col>
                    <Col md="10" className="p-0">
                      <FormComponent
                        labelClass="bold label-sm"
                        label="Contact Mobile Number"
                        type="tel"
                        name="contactMobileNo"
                        change={this.onChange}
                        value={this.state.contactMobileNo}
                        placeholder="Mobile Number"
                        required={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="2" className="p-0">
                      <FormComponent
                        labelClass="bold label-sm"
                        label="Code"
                        type="number"
                        value={this.state.alternateDialingCode}
                        name="alternateDialingCode"
                        onChange={this.onChange}
                      />
                    </Col>
                    <Col md="10" className="p-0">
                      <FormComponent
                        labelClass="bold label-sm"
                        label="Contact Alternate Number"
                        type="tel"
                        name="alternateMobileNo"
                        change={this.onChange}
                        value={this.state.alternateMobileNo}
                        placeholder="Alternate Number"
                      />
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label className="bold label-sm">Contact Designation</Label>
                    <Input
                      type="select"
                      name="designation"
                      value={this.state.designation}
                      onChange={this.onChange}
                      className="form-control-sm"
                      required={true}
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.contactDesignation.data.map((item) => {
                        return (
                          <option key={item.id} value={item.contactDesigName}>
                            {item.contactDesigName}
                          </option>
                        );
                      })}
                    </Input>
                    {errors.designation && (
                      <FormText className="error">
                        {errors.designation}
                      </FormText>
                    )}
                  </FormGroup>

                  <FormComponent
                    labelClass="bold label-sm"
                    label="Remarks"
                    type="textarea"
                    name="remarks"
                    change={this.onChange}
                    value={this.state.remarks}
                  />
                </Col>
              </Row>
              <div className="center">
                <button type="submit" className="btn btn-custom">
                  Update
                </button>
              </div>
            </Form>
          </Col>
        )}
        {this.props.crmContacts.message ? (
          <Notifier message={this.props.crmContacts.message} />
        ) : null}
        {this.props.crmContacts.error ? (
          <ErrorNotifier message={this.props.crmContacts.error} />
        ) : null}
        <CustomizedDialogs
          open={this.state.open}
          handleClose={this.handleClose}
          title="Tag Customer"
        >
          {/* {this.props.crmTagContacts.data.length > 0 ? (
            this.props.crmTagContacts.data.filter(
              (value) => this.state.contactId === value.id
            ).length > 0 ? (
              this.props.crmTagContacts.data
                .filter((value) => this.state.contactId === value.id)
                .map((item) => (
                  <UpdateTagContact back={this.handleClose} tagContact={item} />
                ))
            ) : (
              <AddTagContact
                back={this.handleClose}
                contactName={this.state.contactName}
                contactId={this.state.contactId}
              />
            )
          ) : (
            <AddTagContact
              back={this.handleClose}
              contactName={this.state.contactName}
              contactId={this.state.contactId}
            />
          )} */}
          {this.props.contacts?.customerName ? (
            <UpdateTagContact
              back={this.handleClose}
              tagContact={this.props.contacts}
            />
          ) : (
            <AddTagContact
              back={this.handleClose}
              contactName={this.state.contactName}
              contactId={this.state.contactId}
            />
          )}
        </CustomizedDialogs>
      </Row>
    );
  }
}

ContactDetails.propTypes = {
  crmContacts: PropTypes.object.isRequired,
  updateContacts: PropTypes.func.isRequired,
  getContacts: PropTypes.func.isRequired,
  getCustomers: PropTypes.func.isRequired,
  getAccounts: PropTypes.func.isRequired,
  customers: PropTypes.object.isRequired,
  accounts: PropTypes.object.isRequired,
  getUserContactDesignation: PropTypes.func.isRequired,
  contactDesignation: PropTypes.object.isRequired,
  getTagContacts: PropTypes.func.isRequired,
  crmTagContacts: PropTypes.object.isRequired,
  getUserCustomerCountries: PropTypes.func.isRequired,
  customerCountries: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  crmContacts: state.crmContacts,
  customers: state.customers,
  accounts: state.accounts,
  contactDesignation: state.contactDesignation,
  crmTagContacts: state.crmTagContacts,
  customerCountries: state.customerCountries,
});

export default connect(mapStateToProps, {
  updateContacts,
  getContacts,
  getCustomers,
  getAccounts,
  getUserContactDesignation,
  getTagContacts,
  getUserCustomerCountries,
})(withRouter(ContactDetails));
