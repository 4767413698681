import axios from "axios";
import {
  MANAGER_INITIATE_RESIGNATION_GET,
  MANAGER_INITIATE_RESIGNATION_FETCH,
} from "../../types";
import { CURD_MANAGER_INITIATE_RESIGNATION } from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addManagerInitiateResignation = (data, back) => (dispatch) => {
  axios
    .post(CURD_MANAGER_INITIATE_RESIGNATION, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getManagerInitiateResignation));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getManagerInitiateResignation = () => (dispatch) => {
  axios
    .get(CURD_MANAGER_INITIATE_RESIGNATION, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(MANAGER_INITIATE_RESIGNATION_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const fetchManagerInitiateResignation = (data) => (dispatch) => {
  axios
    .get(CURD_MANAGER_INITIATE_RESIGNATION, {
      params: data,
      withCredentials: true,
    })
    .then((res) => {
      dispatch(getDispatch(MANAGER_INITIATE_RESIGNATION_FETCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateManagerInitiateResignation = (data, back) => (dispatch) => {
  axios
    .put(CURD_MANAGER_INITIATE_RESIGNATION, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteManagerInitiateResignation = (data) => (dispatch) => {
  axios
    .delete(CURD_MANAGER_INITIATE_RESIGNATION, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getManagerInitiateResignation));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
