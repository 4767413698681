import axios from "axios";
import { E360_RELEASE_TAG_GET, E360_RELEASE_TAG_FETCH } from "../types";
import { CURD_E360_RELEASE_TAG, SEARCH_REQUESTS } from "../../../utils/routes";
import setSuccessMessage from "../common/setSuccessMessage";
import setErrorMessage from "../common/setErrorMessage";
import getDispatch from "../common/getDispatch";

// -------------------------------------------------------------------------------------------

export const addE360ReleaseTag = (data, back) => (dispatch) => {
  axios
    .post(CURD_E360_RELEASE_TAG, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getE360ReleaseTag = () => (dispatch) => {
  axios
    .get(CURD_E360_RELEASE_TAG, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(E360_RELEASE_TAG_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const fetchE360ReleaseTag = (data) => (dispatch) => {
  axios
    .get(`${CURD_E360_RELEASE_TAG}/${data}`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(E360_RELEASE_TAG_FETCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateE360ReleaseTag = (data, back) => (dispatch) => {
  axios
    .put(CURD_E360_RELEASE_TAG, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteE360ReleaseTag = (data) => (dispatch) => {
  axios
    .delete(CURD_E360_RELEASE_TAG, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getAccounts));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
