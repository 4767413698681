import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { MdCancel } from "react-icons/md";

import DateFns from "date-fns";
import Select from "react-select";
import { Divider, Tooltip } from "@material-ui/core";
import CommonFlexComponent from "../../../common/commonFlexComponent";


// import {} from 'react-bootstrap'

import {
  Row,
  Col,
  Form,
  Container,
  FormGroup,
  Input,
  Label,
  FormText,
} from "reactstrap";
// import react icons
import { AiFillTags } from "react-icons/ai";
import { GrFormView } from "react-icons/gr";

// redux
import { updatePublishJobs } from "../../../../redux/actions/rs/requirements/publishJobsAction";
import { getJobTimingSelect } from "../../../../redux/actions/rs/configuration/jobTiming";
import { getAllSkillsSelect  } from "../../../../redux/actions/rs/configuration/canSkillsAction";

// components
// import DetailsPageFooter from "./DetailsPageViewFooter";

import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../common/Validator";
import FormComponent from "../../../common/FormComponent";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";


let primarySkillGroupError = "";
class ParticularJobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primarySkills: this.props.storeData[0].pj_primarySkills
        ? this.props.storeData[0].pj_primarySkills
        : "",
      secondarySkills: this.props.storeData[0].pj_secondarySkills
        ? this.props.storeData[0].pj_secondarySkills
        : "",

      jobDescription: this.props.storeData[0].pj_jobDescription
        ? this.props.storeData[0].pj_jobDescription
        : "",
      timing: this.props.storeData[0].pj_jobTimings
        ? this.props.storeData[0].pj_jobTimings
        : "",
      timingBodyName:this.props.storeData[0].pj_jobTimings
        ? this.props.storeData[0].pj_jobTimings
        : "",
      edit: false,
      isUpdate: false,
      boardPopUp: false,
      viewShowPopup: false,
      message: "",
      error: "",
      getData: [],
      dataStore: [],
      showPopUp: false,
      primarySkillErrorShow: null,
      secondarySkillErrorShow: null,
      taggedView: false,
      searchValue: "",
      selectedOptionError: null,
      haveSkills: "",
      niceSkills: "",
    
      filteredPrimaryValueArray:  this.props.storeData[0].pj_primarySkills
      ? this.props.storeData[0].pj_primarySkills.split(",")
      : [],
      filteredSecondaryValueArray: this.props.storeData[0].pj_secondarySkills
      ? this.props.storeData[0].pj_secondarySkills.split(",")
      : [],
      selectedOptionErrorOne: null,
      changeError: "",
    };
    this.handlePopupView = this.handlePopupView.bind(this);
    this.closeBoardPopUp = this.closeBoardPopUp.bind(this);
    this.handleViewOpenJobBoard = this.handleViewOpenJobBoard.bind(this);
    this.handleCloseViewBoard = this.handleCloseViewBoard.bind(this);
    this.handlePrimarySkillsChange = this.handlePrimarySkillsChange.bind(this);
    this.handleSecondarySkillsChange =this.handleSecondarySkillsChange.bind(this);
    this.closeDataPrimarySkill = this.closeDataPrimarySkill.bind(this);
    this.closeDataSecondarySkill = this.closeDataSecondarySkill.bind(this);
    this.handleTimingChange = this.handleTimingChange.bind(this)
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.backnavigate = this.backnavigate.bind(this);
    this.onUpdate = this.onUpdate.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state.edit) {
      // this.props.getJobs()
      // this.props.storeData[0]
      refresh();
    }
    // if(prevProps !== this.props.storeData[0]){
    //   this.props.getJobs()
    //   this.props.storeData[0]
    // }
  }

  // common onchange
  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  // close function
  backnavigate() {
    this.setState({
      edit: false,
    });
   
  }

  handlePopupView() {
    this.setState({
      boardPopUp: true,
    });
  }

  closeBoardPopUp() {
    this.setState({
      boardPopUp: false,
    });
  }

  handleViewOpenJobBoard() {
    this.setState({
      viewShowPopup: !this.state.viewShowPopup,
    });
  }

  handleCloseViewBoard() {
    this.setState({
      viewShowPopup: false,
    });
  }

  handleTimingChange(timing) {
    this.setState({
      timingBodyName: timing.value,
    });

    if (timing) {
      this.setState({ timing });
    }
  }

  handlePrimarySkillsChange(skillSet) {
    this.setState({
      primarySkillErrorShow: null,
    });

    let { filteredPrimaryValueArray } = this.state;
    filteredPrimaryValueArray.push(skillSet.value);
    this.setState({
      filteredPrimaryValueArray: [...new Set(filteredPrimaryValueArray)],
    });
    primarySkillGroupError = "";

  
  
  }

  handleSecondarySkillsChange(skillSet) {
    this.setState({
      secondarySkillErrorShow: null,
    });

    let { filteredSecondaryValueArray } = this.state;
    filteredSecondaryValueArray.push(skillSet.value);
    this.setState({
      filteredSecondaryValueArray: [...new Set(filteredSecondaryValueArray)],
    });
    primarySkillGroupError = "";
}

  closeDataPrimarySkill(data) {
    console.log(data);

    let filterData = this.state.filteredPrimaryValueArray.filter(
      (val) => val !== data
    );
    this.setState({
      filteredPrimaryValueArray: filterData,
    });
  }

  closeDataSecondarySkill(data) {
    console.log(data);

    let filterData = this.state.filteredSecondaryValueArray.filter(
      (val) => val !== data
    );
    this.setState({
      filteredSecondaryValueArray: filterData,
    });
  }

  /*
  This function for updating the published job details
  */
  onSubmit(e) {
    e.preventDefault();
  
    submitValidator(e.target);

    let body = {
      id: this.props.storeData[0].pj_id,
      jobRequirementId: Number(this.props.storeData[0].pj_jobRequirementId),
      jobType: this.props.storeData[0].pj_jobType,
      jobCategory: this.props.storeData[0].pj_jobCategory,
      jobPosition: this.props.storeData[0].pj_jobPosition,
      jobDescription: this.state.jobDescription,
      jobLocation: this.props.storeData[0].pj_jobLocation,
      jobTimings: this.props.storeData[0].pj_jobTiming,
      qualifications: this.props.storeData[0].pj_qualifications,
      skillSets: this.props.storeData[0].pj_skillSets,
      primarySkills: this.props.storeData[0].pj_primarySkills,
      secondarySkills: this.props.storeData[0].pj_secondarySkills,
      totalPositions: this.props.storeData[0].pj_totalPosition,
      startDate: this.props.storeData[0].pj_startDate,
      endDate: this.props.storeData[0].pj_endDate,
      jobStatus: "Approved",
     
      minYearsOfExp: Number(this.props.storeData[0].pj_minYearsOfExp),
      maxYearsOfExp: Number(this.props.storeData[0].pj_maxYearsOfExp),
    };
   
      this.props.updatePublishJobs(body, this.props.back);
    // } else {
    //   this.setState({
    //     error: " Please enter all mandatory fields",
    //   });
    //   setTimeout(() => {
    //     this.setState({
    //       error: "",
    //     });
    //   }, 2000);
    // }
  }

  onUpdate(e) {
    e.preventDefault();
  
    submitValidator(e.target);

    let body = {
      id: this.props.storeData[0].pj_id,
      jobRequirementId: Number(this.props.storeData[0].pj_jobRequirementId),
      jobType: this.props.storeData[0].pj_jobType,
      jobCategory: this.props.storeData[0].pj_jobCategory,
      jobPosition: this.props.storeData[0].pj_jobPosition,
      jobDescription: this.state.jobDescription,
      jobLocation: this.props.storeData[0].pj_jobLocation,
      jobTimings: this.state.timingBodyName,
      qualifications: this.props.storeData[0].pj_qualifications,
      primarySkills: this.state.filteredPrimaryValueArray.toString(),
      secondarySkills: this.state.filteredSecondaryValueArray.toString(),
      totalPositions: this.state.totalPosition,
      startDate: this.props.storeData[0].pj_startDate,
      endDate: this.props.storeData[0].pj_endDate,
      jobStatus: "New",
     
      minYearsOfExp: Number(this.props.storeData[0].pj_minYearsOfExp),
      maxYearsOfExp: Number(this.props.storeData[0].pj_maxYearsOfExp),
    };

     if (valid) {
      this.props.updatePublishJobs(body, this.props.back);
    } else {
      this.setState({
        error: " Please enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount(){
    this.props.getAllSkillsSelect()
    this.props.getJobTimingSelect()
  }

  // fetch by id and display function

  render() {
    let options5 = [];
    for (let i = 0; i < this.props.CanConSkill.allData.length; i++) {
      var x3 = {};
      x3["value"] = this.props.CanConSkill.allData[i].skill;
      x3["label"] = this.props.CanConSkill.allData[i].skill;
      options5.push(x3);
    }

    let options9 = [];
    for (let i = 0; i < this.props.jobTiming.mainData.length; i++) {
      var x7 = {};
      x7["value"] = this.props.jobTiming.mainData[i].jobHours;
      x7["label"] = this.props.jobTiming.mainData[i].jobHours;

      options9.push(x7);
    }
   

    return (
      <Row>
        {!this.state.edit ? (
          <Col md="12">
            <h5 className="bold center mb-4">
              {" "}
              Job Id :
              {this.props.storeData.length > 0 && this.props.storeData[0].pj_id}
            </h5>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                position: "absolute",
                top: "0.2rem",
                right: "5%",
              }}
            >
              <Tooltip title="update published jobs">
                <i
                  data-toggle="tooltip"
                  data-placement="top"
                  style={{
                    color: "#0056b3",
                    marginRight: ".5rem",
                    fontSize: "1.25rem",
                  }}
                  className="material-icons pointer edit-btn"
                  onClick={() => this.setState({ edit: !this.state.edit })}
                >
                  edit
                </i>
              </Tooltip>
            
          
            </div>

            {/* <Link to="/pie/rs/published_jobs"> */}
              <i
                data-toggle="tooltip"
                data-placement="top"
                title="Close"
                onClick={() => this.props.back()}
                className="material-icons pointer cancel-button"
                style={{
                  top: "0.1rem",
                }}
              >
                cancel
              </i>
            {/* </Link> */}
       

         

            {this.props.storeData.length > 0 &&
              this.props.storeData.map((data) => {
                return (
                  <div>
                    <Row>
                      <Col style={{ marginLeft: "3rem" }} md="4">
                        <ul className="CustDetails-ul">
                        <CommonFlexComponent
                            label="Req. Id"
                            data={data.pj_jobRequirementId}
                          />
                          <CommonFlexComponent
                            label="Req. Type"
                            data={data.pj_jobType}
                          />

                          <li>
                            <CommonFlexComponent
                              label="Job Category"
                              data={data.pj_jobCategory}
                            />
                          </li>
                          <li>
                            <CommonFlexComponent
                              label="Job Position"
                              data={data.pj_jobPosition}
                            />
                          </li>
                          <li>
                            <CommonFlexComponent
                              label="Qualification"
                              data={data.pj_qualifications}
                            />
                          </li>

                          <li>
                            <CommonFlexComponent
                              label="Start Date"
                              data={
                                data.pj_startDate
                                  ? DateFns.format(data.pj_startDate, "DD-MM-YYYY")
                                  : ""
                              }
                            />
                          </li>

                          <li>
                            <CommonFlexComponent
                              label="End Date"
                              data={
                                data.pj_endDate
                                  ? DateFns.format(data.pj_endDate, "DD-MM-YYYY")
                                  : ""
                              }
                            />
                          </li>
                        
                          <li>
                            <CommonFlexComponent
                              label=" Min Yrs of Exp."
                              data={data.pj_minYearsOfExp}
                            />
                          </li>
                          <li>
                            <CommonFlexComponent
                              label="Max Yrs of Exp."
                              data={data.pj_maxYearsOfExp}
                            />
                          </li>
                        </ul>
                      </Col>

                      <Col style={{ marginLeft: "10rem" }} md="4">
                        <ul className="CustDetails-ul">
                        <li>
                            <CommonFlexComponent
                              label="Job Status"
                              data={data.pj_jobStatus}
                            />
                          </li>
                          <li>
                            <CommonFlexComponent
                              label="Job Location"
                              data={data.pj_jobLocation}
                            />
                          </li>

                          <li>
                            <CommonFlexComponent
                              label="Primary Skills"
                              data={data.pj_primarySkills}
                            />
                          </li>
                          <li>
                            <CommonFlexComponent
                              label="Secondary Skills"
                              data={data.pj_secondarySkills}
                            />
                          </li>
                          <li>
                            <CommonFlexComponent
                              label="Job Timing"
                              data={data.pj_jobTimings}
                            />
                          </li>

                        
                          <li>
                            <CommonFlexComponent
                              label="Created By"
                              data={data.pj_createdBy}
                            />
                          </li>
                          <li>
                            <CommonFlexComponent
                              label="Created On"
                              data={  data.pj_createdOn
                                ? DateFns.format(data.pj_createdOn, "DD-MM-YYYY")
                                : ""}
                            />
                          </li>
                          <li>
                            <CommonFlexComponent
                              label="Updated By"
                              data={data.pj_updatedBy}
                            />
                          </li>
                          <li>
                            <CommonFlexComponent
                              label="Updated On"
                              data={ data.pj_updatedOn
                                ? DateFns.format(data.pj_updatedOn, "DD-MM-YYYY")
                                : ""}
                            />
                          </li>
                        </ul>
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ marginLeft: "3rem" }} md="12" >
                        <ul className="CustDetails-ul m-0">
                          <li>
                            <div
                              style={{ display: "flex" }}
                            >
                              <div
                                className="label-sm bold rs-req-span-label-col-1 pt-1"
                                style={{ width: "12%" }}
                              >
                                <span
                                  style={{
                                    fontSize: ".875rem",
                                    fontWeight: "500",
                                  }}
                                >
                                  Job Description
                                </span>
                              </div>
                              <div style={{   }}>
                                <span className="label-sm">:</span>
                              </div>
                              <div style={{ width: "78%", paddingLeft: "1%" }}>
                                <span style={{fontSize:".75rem ",fontWeight:"400"}}>
                                  {data?.pj_jobDescription}
                                </span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                    <div style={{ marginTop: "1rem" }} className="center">
                      <button
                        onClick={this.onSubmit}
                        type="submit"
                        className="btn center login-button white-text"
                        // disabled={this.state.buttonValue ? "disabled" : null}
                      >
                       Review & Approve
                      </button>
                    </div>
                    {/* <Divider />
                    <DetailsPageFooter
                      createdBy={data.createdBy}
                      createdOn={data.createdOn}
                      updatedBy={data.updatedBy}
                      updatedOn={data.updatedOn}
                    /> */}
                  </div>
                );
              })}
          </Col>
        ) : (
          <Row>
            <Col md="12">
              <h5 className="bold center">Update Publish Jobs</h5>
              <i
                style={{ marginTop: "0.5rem" }}
                onClick={() => this.setState({ edit: !this.state.edit })}
                className="material-icons pointer cancel-button"
              >
                cancel
              </i>
              <Form onSubmit={this.onUpdate}  className="p-3 mt-2" noValidate>
                <Row>
                  <Col md="4">
                  <FormGroup>
                  <Label className="bold label-sm">Primary Skills</Label>
                  <Select
                    tabIndex="18"
                    // name="options2"
                    value={this.state.haveSkills}
                    onChange={this.handlePrimarySkillsChange}
                    options={options5}
                    Primary
                    required
                  />
                    {this.state.primarySkillErrorShow && (
                  <FormText className="error">
                    {this.state.primarySkillErrorShow}
                  </FormText>
                )}
                </FormGroup>
              
                {this.state.filteredPrimaryValueArray.length > 0 && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      border: "1px solid grey",
                      // paddingBottom: "0.5rem",
                    }}
                  >
                    {this.state.filteredPrimaryValueArray.map((data) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent:"space-between",
                             minWidth: "4rem",
                             background: "#ECECEC",
                             border: "none",
                             height:"2rem",
                             marginTop: "0.1rem",
                             marginBottom:"0.1rem",
                             marginLeft: "0.5rem",
                             borderRadius: "20px",
                             paddingRight:"0.2rem",
                             paddingLeft: "0.2rem",
                          }}
                        >
                          <span style={{fontSize:".75rem"}}>{data}</span>

                          <h3
                            data-toggle="tooltip"
                            data-placement="top"
                            title="cancel"
                            onClick={() => this.closeDataPrimarySkill(data)}
                            style={{
                              color: "#04044c",
                            }}
                          >
                            {" "}
                            <MdCancel color="red" size="20" />{" "}
                          </h3>
                        </div>
                      );
                    })}
                  </div>
                )}

                <FormGroup>
                        <Label className="bold label-sm">Job Timing</Label>
                        <Select
                          defaultInputValue={this.state.timingBodyName}
                          className="select-form-control-sm"
                          tabIndex="9"
                          // name="timing"
                          value={this.state.timing}
                          onChange={this.handleTimingChange}
                          options={options9}
                          required
                        />
                      </FormGroup>
                  </Col>
                  <Col md="4">
                  <FormGroup>
                        <Label className="bold label-sm">
                          Secondary Skills
                        </Label>
                        <Select
                          tabIndex="18"
                          // name="options2"
                          value={this.state.niceSkills}
                          onChange={this.handleSecondarySkillsChange}
                          options={options5}
                          Primary
                          required
                        />
                      </FormGroup>

                      {this.state.filteredSecondaryValueArray.length > 0 && (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexWrap: "wrap",
                            border: "1px solid grey",
                            // paddingBottom: "0.5rem",
                          }}
                        >
                          {this.state.filteredSecondaryValueArray.map(
                            (data) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    minWidth: "4rem",
                                    background: "#ECECEC",
                                    border: "none",
                                    height: "2rem",
                                    marginTop: "0.1rem",
                                    marginBottom: "0.1rem",
                                    marginLeft: "0.5rem",
                                    borderRadius: "20px",
                                    paddingRight: "0.2rem",
                                    paddingLeft: "0.2rem",
                                  }}
                                >
                                  <span style={{fontSize:".75rem"}}>
                                    {data}
                                  </span>

                                  <h3
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="cancel"
                                    onClick={() =>
                                      this.closeDataSecondarySkill(data)
                                    }
                                    style={{
                                      color: "#04044c",
                                    }}
                                  >
                                    {" "}
                                    <MdCancel color="red" size="20" />{" "}
                                  </h3>
                                </div>
                              );
                            }
                          )}
                        </div>
                      )}
                  </Col>
                  <Col md="4">
                    <FormComponent
                      index="3"
                      labelClass="bold label-sm"
                      label="Job Description"
                      type="textarea"
                      name="jobDescription"
                      change={this.onChange}
                      value={this.state.jobDescription}
                      required={true}
                      placeholder="Job Description"
                      maxlength="10"
                    />
                  </Col>
                </Row>
                <div className="center">
                  <button
                    type="submit"
                    className="btn center login-button white-text"

                    // disabled={this.state.buttonValue ? "disabled" : null}
                  >
                    Update
                  </button>
                </div>
              </Form>
            </Col>
          </Row>
        )}

        {this.props.PublishJobs.message ? (
          <Notifier message={this.props.PublishJobs.message} />
        ) : null}
        {this.props.PublishJobs.error ? (
          <ErrorNotifier message={this.props.PublishJobs.error} />
        ) : null}
        {/* {this.state.message ? (
          <Notifier message={this.state.message} />
        ) : null}
        {this.state.error ? (
          <ErrorNotifier message={this.state.error} />
        ) : null} */}
      </Row>
    );
  }
}

ParticularJobs.propTypes = {};

const mapStateToProps = (state) => ({
  PublishJobs: state.PublishJobs,
  CanConSkill: state.CanConSkill,
  jobTiming: state.jobTiming,
});

export default connect(mapStateToProps, {
  updatePublishJobs,
  getAllSkillsSelect,
  getJobTimingSelect 
})(withRouter(ParticularJobs));
