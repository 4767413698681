import React, { Component } from "react";
import {
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Container,
  FormText,
} from "reactstrap";
import DateFns from "date-fns";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// components
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
// redux
import { addEmpInitiateResignation } from "../../../../../redux/actions/hcm/resignation/empInititateResignationAction";
import { getUserResignationReason } from "../../../../../redux/actions/hcm/configurations/resignationReasonAction";
import { getUserResignationStatus } from "../../../../../redux/actions/hcm/configurations/resignationStatusAction";

class EmployeeInitiateResignation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestDate: DateFns.format(new Date(), "YYYY-MM-DD"),
      type: "Voluntary",
      releaseDate: DateFns.format(
        DateFns.addMonths(new Date(), 2),
        "YYYY-MM-DD"
      ),
      reason: "",
      status: "New",
      remarks: "",
      message: "",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      empId: sessionStorage.getItem("id"),
      regRequestDate: this.state.requestDate,
      regType: this.state.type,
      releaseDate: this.state.releaseDate,
      regReason: this.state.reason,
      regStatus: this.state.status,
      remarks: this.state.remarks,
      createdBy: sessionStorage.getItem("id"),
    };
    if (valid) {
      this.props.addEmpInitiateResignation(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidMount contains the error refresh method and setState method for calling the render method
*/
  componentDidMount() {
    refresh();
    this.props.getUserResignationReason();
    this.props.getUserResignationStatus();
    this.setState({
      message: "",
      error: "",
    });
  }

  render() {
    const {
      requestDate,
      type,
      releaseDate,
      reason,
      status,
      remarks,
      error,
      message,
    } = this.state;
    let shrink = this.props.sidebar ? "scale" : "no-scale";

    return (
      <div className={shrink}>
        <Container className="card container-card pt-2">
          <h5 className="center bold pt-2">Employee Initiate Resignation</h5>
          <Link
            to={{
              pathname: "/pie/iam/service_menu",
              moduleName: this.props.location.moduleName,
              data: this.props.location.data,
            }}
          >
            <i className="material-icons cancel-button pointer">cancel</i>
          </Link>
          <Form onSubmit={this.onSubmit} className="p-3" noValidate>
            <Row>
              <Col md="6 mx-auto">
                <FormComponent
                  labelClass="bold label-sm"
                  label="Resignation Request Date"
                  type="date"
                  name="requestDate"
                  inputClass="form-control-sm"
                  value={requestDate}
                  required={true}
                  disabled={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Release Date"
                  type="date"
                  name="releaseDate"
                  inputClass="form-control-sm"
                  value={releaseDate}
                  required={true}
                  disabled={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Resignation Type"
                  type="text"
                  name="type"
                  inputClass="form-control-sm"
                  value={type}
                  required={true}
                  disabled={true}
                />
                <FormGroup>
                  <Label className="bold label-sm">Resignation Reason</Label>
                  <Input
                    type="select"
                    name="reason"
                    className="form-control-sm"
                    value={reason}
                    onChange={this.onChange}
                    required={true}
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.resignationReason.data.map((item) => {
                      return (
                        <option key={item.id} value={item.regReasonName}>
                          {item.regReasonName}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.reason && (
                    <FormText className="error">{errors.reason}</FormText>
                  )}
                </FormGroup>
                {/* <FormGroup>
                <Label className="bold label-sm">Resignation Status</Label>
                <Input
                  type="select"
                  name="status"
                  className="form-control-sm"
                  value={status}
                  onChange={this.onChange}
                  required={true}
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {this.props.resignationStatus.data.map((item) => {
                    return (
                      <option key={item.regStatusId}>
                        {item.regStatusName}
                      </option>
                    );
                  })}
                </Input>
                {errors.status && (
                  <FormText className="error">{errors.status}</FormText>
                )}
              </FormGroup> */}
                <FormComponent
                  labelClass="bold label-sm"
                  label="Remarks"
                  type="textarea"
                  name="remarks"
                  inputClass="form-control-sm"
                  value={remarks}
                  change={this.onChange}
                  required={true}
                />
              </Col>
            </Row>
            <div className="center">
              <button type="submit" className="btn login-button white-text">
                Submit
              </button>
            </div>
          </Form>
          {this.props.empInitiateResignation.message && (
            <Notifier message={this.props.empInitiateResignation.message} />
          )}
          {this.props.empInitiateResignation.error && (
            <ErrorNotifier message={this.props.empInitiateResignation.error} />
          )}
        </Container>
      </div>
    );
  }
}

EmployeeInitiateResignation.propTypes = {
  addEmpInitiateResignation: PropTypes.func.isRequired,
  empInitiateResignation: PropTypes.object.isRequired,
  getUserResignationReason: PropTypes.func.isRequired,
  resignationReason: PropTypes.object.isRequired,
  getUserResignationStatus: PropTypes.func.isRequired,
  resignationStatus: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  empInitiateResignation: state.empInitiateResignation,
  resignationReason: state.resignationReason,
  resignationStatus: state.resignationStatus,
});

export default connect(mapStateToProps, {
  addEmpInitiateResignation,
  getUserResignationReason,
  getUserResignationStatus,
})(EmployeeInitiateResignation);
