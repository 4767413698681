import React from "react";
import { useState } from "react";
import { Col, FormGroup, Input, Label, Row, Table } from "reactstrap";
import DateFns from "date-fns";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// material ui
import { IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
// redux
import { searchViewRequests } from "../../../../redux/actions/experience_360/requestsAction";

// ----------------------------------------------------------------------------

const ViewRequests = (props) => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  // search based on the fromDate and toDate
  const handleSearch = () => {
    let data = {
      fromDate: fromDate,
      toDate: toDate,
    };
    props.searchViewRequests(data);
  };

  return (
    <div>
      <Row style={{ display: "flex", justifyContent: "space-between" }}>
        <Col md="6">
          <Row>
            <Col md="6">
              <FormGroup>
                <Label className="bold label-sm">From Date</Label>
                <Input
                  type="date"
                  name="fromDate"
                  className="form-control-sm"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  required={true}
                  max={DateFns.format(new Date(), "YYYY-MM-DD")}
                ></Input>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label className="bold label-sm">To Date</Label>
                <Input
                  type="date"
                  name="toDate"
                  className="form-control-sm"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  required={true}
                  min={DateFns.format(fromDate, "YYYY-MM-DD")}
                  max={DateFns.format(new Date(), "YYYY-MM-DD")}
                ></Input>
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col md="6">
          <Row
            style={{
              justifyContent: "end",
              alignItems: "center",
              paddingTop: "2%",
            }}
          >
            <Col md="2" className="align-center flex pr-5">
              <IconButton
                color="primary"
                title="search requests"
                onClick={() => handleSearch()}
              >
                <SearchIcon />
              </IconButton>
            </Col>
            <Col md="2" className="align-center flex pr-5">
              <IconButton color="primary" title="download requests">
                <CloudDownloadIcon />
              </IconButton>
            </Col>
          </Row>
        </Col>
      </Row>
      <Table className="mt-2">
        <thead style={{ backgroundColor: "lightgrey" }}>
          <tr>
            <th className="opp-head ">Id</th>
            <th className="opp-head ">Type</th>
            <th className="opp-head ">Product Name</th>
            <th className="opp-head ">Feature</th>
            <th className="opp-head ">Title</th>
            <th className="opp-head ">Severity</th>
            <th className="opp-head ">Priority</th>
            <th className="opp-head ">Status</th>
          </tr>
        </thead>
        <tbody>
          {props.requests.viewData.length > 0 &&
            props.requests.viewData.map((item) => {
              return (
                <tr key={item.id}>
                  <td className="opp-tableData">{item.id}</td>
                  <td className="opp-tableData">{item.reqType}</td>
                  <td className="opp-tableData">{item.productName}</td>
                  <td className="opp-tableData">{item.feature}</td>
                  <td className="opp-tableData">{item.reqTitle}</td>
                  <td className="opp-tableData">{item.severity}</td>
                  <td className="opp-tableData">{item.priority}</td>
                  <td className="opp-tableData">{item.status}</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};

ViewRequests.propTypes = {
  searchViewRequests: PropTypes.func.isRequired,
  requests: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  requests: state.requests,
});

export default connect(mapStateToProps, { searchViewRequests })(ViewRequests);
