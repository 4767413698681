import React, { Component } from "react";
import { Card, Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// component
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import ConfirmDialog from "../../../common/confirm-dialog/ConfirmDialog";
// redux
import {
  deleteTagContacts,
  updateTagContacts,
} from "../../../../redux/actions/crm/contacts/tagContactsAction";

// --------------------------------------------------------------

class TagContactCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDialog: {
        isOpen: false,
        title: "",
        subTitle: "",
      },
    };
  }

  updateTaggedContact = (item) => {
    let data = {
      id: item.id,
      customerName: item.customerName,
      entityName: item.entityName,
      customerAccountId: null,
      contactId: item.contactId,
      contactName: item.contactName,
      contactWorkingStatus: item.contactWorkingStatus,
      remarks: item.remarks,
    };

    let entityData = {
      id: item.id,
      customerName: item.customerName,
      entityName: null,
      customerAccountId: null,
      contactId: item.contactId,
      contactName: item.contactName,
      contactWorkingStatus: item.contactWorkingStatus,
      remarks: item.remarks,
    };
    this.props.updateTagContacts(
      this.props.name === "account" ? data : entityData,
      this.closeDialog
    );
  };

  closeDialog = () => {
    this.setState((prevState) => ({
      ...prevState.confirmDialog,
      confirmDialog: {
        isOpen: false,
      },
    }));
  };

  deleteTaggedContact = (id) => {
    let data = {
      id: id,
    };
    this.props.deleteTagContacts(data);
    this.setState((prevState) => ({
      ...prevState.confirmDialog,
      confirmDialog: {
        isOpen: false,
      },
    }));
  };

  setConfirmDialog = (obj) => {
    this.setState((prevProps) => ({
      confirmDialog: {
        ...prevProps.confirmDialog,
        isOpen: obj.isOpen,
      },
    }));
  };

  render() {
    return (
      <Row>
        {this.props.data.map((item) => {
          return (
            <Col md="4">
              <Card className="shadow p-2">
                {typeof this.props.permission !== "undefined" &&
                  Object.keys(this.props.permission).length > 0 &&
                  this.props.permission.updation && (
                    <i
                      className="material-icons pointer tag-view-delete-btn"
                      onClick={() => {
                        this.props.name === "customer"
                          ? this.setState({
                              confirmDialog: {
                                isOpen: true,
                                title: "Are you sure to delete this record?",
                                subTitle: "You can't undo this operation",
                                onConfirm: () =>
                                  this.deleteTaggedContact(item.id),
                              },
                            })
                          : this.setState({
                              confirmDialog: {
                                isOpen: true,
                                title: "Are you sure to untag this record?",
                                subTitle: "You can't undo this operation",
                                onConfirm: () => this.updateTaggedContact(item),
                              },
                            });
                      }}
                    >
                      delete
                    </i>
                  )}
                <ul className="CustDetails-ul">
                  <li>
                    <span className="label-sm bold tag-contact-span-label-col-2">
                      Contact Name
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{item.contactName}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold tag-contact-span-label-col-2">
                      Email Id
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{item.contactEmail}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold tag-contact-span-label-col-2">
                      Mobile No.
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {item.contactDialingCode && `${item.contactDialingCode}-`}
                      {item.contactMobileNo}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold tag-contact-span-label-col-2">
                      Contact Status
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{item.contactStatus}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold tag-contact-span-label-col-2">
                      Designation
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{item.contactDesignation}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold tag-contact-span-label-col-2">
                      Working Status
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{item.contactWorkingStatus}
                    </span>
                  </li>
                </ul>
              </Card>
            </Col>
          );
        })}
        {this.props.crmTagContacts.message && (
          <Notifier message={this.props.crmTagContacts.message} />
        )}
        {this.props.crmTagContacts.error && (
          <ErrorNotifier message={this.props.crmTagContacts.error} />
        )}
        <ConfirmDialog
          confirmDialog={this.state.confirmDialog}
          setConfirmDialog={this.setConfirmDialog}
        />
      </Row>
    );
  }
}

TagContactCard.propTypes = {
  deleteTagContacts: PropTypes.func.isRequired,
  updateTagContacts: PropTypes.func.isRequired,
  crmTagContacts: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  crmTagContacts: state.crmTagContacts,
});
export default connect(mapStateToProps, {
  deleteTagContacts,
  updateTagContacts,
})(TagContactCard);
