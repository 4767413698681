import {
    GET_NOTICE_PERIODRS,
    GET_NOTICE_MAIN,
    GET_ERRORS,
    SET_MESSAGE,
  } from "../../../actions/types";
  
  const initialState = {
    data: [],
    message: null,
    error: null,
    getData:[]
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case   GET_NOTICE_PERIODRS:
        return {
          ...state,
          data: action.payload,
        };
        case  GET_NOTICE_MAIN:
          return {
            ...state,
            getData: action.payload,
          };
      case SET_MESSAGE:
        return {
          ...state,
          message: action.payload,
        };
      case GET_ERRORS:
        return {
          ...state,
          error: action.payload,
        };
      default:
        return state;
    }
  }