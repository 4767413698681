import axios from "axios";
import {
  EMPLOYEE_CLAIM_ADD,
  EMPLOYEE_CLAIM_GET,
  EMPLOYEE_CLAIM_SELECT,
  EMPLOYEE_CLAIM_UPDATE,
  EMPLOYEE_CLAIM_DELETE,
  GET_ERRORS,
} from "../../types";
import {
  ADD_EMPLOYEE_CLAIM,
  GET_EMPLOYEE_CLAIM,
  SELECT_EMPLOYEE_CLAIM,
  UPDATE_EMPLOYEE_CLAIM,
  DELETE_EMPLOYEE_CLAIM,
} from "../../../../utils/routes";
import { setMessage } from "../../salesActions";

export const addEmployeeClaim = (data, back) => (dispatch) => {
  axios
    .post(ADD_EMPLOYEE_CLAIM, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getEmployeeClaim());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const getEmployeeClaim = (data) => (dispatch) => {
  axios
    .post(GET_EMPLOYEE_CLAIM, data, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: EMPLOYEE_CLAIM_GET,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};

export const selectClaims = () => (dispatch) => {
  axios
    .get(SELECT_EMPLOYEE_CLAIM, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: EMPLOYEE_CLAIM_SELECT,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};

export const updateEmployeeClaim = (data, back) => (dispatch) => {
  axios
    .post(UPDATE_EMPLOYEE_CLAIM, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getEmployeeClaim());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const managerUpdateEmployeeClaim = (data, back) => (dispatch) => {
  axios
    .post(UPDATE_EMPLOYEE_CLAIM, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(selectClaims());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const deleteEmployeeClaim = (data) => (dispatch) => {
  axios
    .post(DELETE_EMPLOYEE_CLAIM, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getEmployeeClaim());
      }, 2000);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};
