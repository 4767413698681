import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
} from "reactstrap";
import { connect } from "react-redux";
import AddSalesInvoice from "./AddSalesInvoice";
import SalesInvoiceDetails from "./SalesInvoiceDetails";
import {
  getSalesInvoices,
  updateSalesInvoice,
  searchInvoice,
  resetItems,
} from "../../../../../redux/actions/finance/accounts_receivable/sales_invoice/salesInvoiceAction";
import {
  submitValidator,
  valid,
  Validator,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import Hover from "../../../../aside/Hover";

/**
 * @class SalesInvoice
 **/

class SalesInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addInvoice: false,
      data: this.props.salesInvoice.data || [],
      selection: false,
      selected: {},
      subItem: {},
      modal: false,
      hover: false,
      cords: {},
      mouse: {},
      searchTerm: "",
      remarks: "",
      message: "",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.select = this.select.bind(this);
    this.back = this.back.bind(this);
    this.search = this.search.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.toggleHoverOn = this.toggleHoverOn.bind(this);
    this.toggleHoverOff = this.toggleHoverOff.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.toggle1 = this.toggle1.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.invoices !== this.props.invoices) {
      this.setState({
        data: this.props.invoices.data,
      });
    }
  }

  search(e) {
    e.preventDefault();
    this.setState({
      searchTerm: e.target.value,
    });
    this.onSearch(e.target.value);
  }

  onSearch(value) {
    let data = {};
    let isInteger = value.length && /^[0-9]*$/.test(value);
    if (isInteger) {
      data.salesInvoiceId = Number(value);
      data.name = "null";
    } else {
      data.salesInvoiceId = 0;
      data.name = value;
    }
    this.props.searchInvoice(data);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      remarks: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    const data = this.state.data.filter((item) => {
      if (item.salesInvoiceId === this.state.subItem) {
        return item;
      } else {
        return null;
      }
    });

    const dragInvoice = {
      salesInvoiceId: data[0].salesInvoiceId,
      pymtStatus: data[0].pymtStatus,
      remarks: this.state.remarks,
    };
    if (valid) {
      this.props.updateSalesInvoice(dragInvoice, this.toggle1);
      setTimeout(() => {
        this.toggle();
        this.getData();
      }, 2000);
    } else {
      this.setState({
        error: "Enter mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  toggleHoverOn(e, item) {
    this.setState({
      hover: true,
      cords: {
        one: item.invoiceNo,
        two: item.invoiceDate,
        three: item.invoiceAmount,
      },
      mouse: {
        x: e.screenX,
        y: e.screenY,
      },
    });
  }

  toggleHoverOff() {
    this.setState({
      hover: false,
    });
  }

  toggle() {
    this.setState({
      modal: false,
      remarks: "",
    });
  }

  /*
Toggle1 function for modal cancel button here called the getData Fuction
*/
  toggle1() {
    this.setState({
      modal: !this.state.modal,
    });
    this.getData();
  }

  select(item) {
    this.setState({
      selection: item,
      selected: item.salesInvoiceId,
      popup: true,
    });
  }

  back() {
    this.setState({
      selection: false,
    });

    this.getData();
  }

  onDragOver(e) {
    e.preventDefault();
  }
  onDragStart(e, item) {
    e.dataTransfer.setData("item", item.salesInvoiceId);
    this.setState({
      subItem: item.salesInvoiceId,
    });
  }

  async onDrop(e, cat) {
    let id = e.dataTransfer.getData("item");
    let newData;
    newData = this.state.data.filter((item) => {
      if (item.salesInvoiceId === parseInt(id, 10)) {
        item.pymtStatus = cat;
        this.setState({
          subItem: item,
        });
      }
      return newData;
    });
    this.setState({
      ...this.state,
      newData,
    });
    await this.toggle();
  }

  // openAddInvoice function to open the Invoice add form
  openAddInvoice = () => {
    this.setState({
      addInvoice: true,
    });
    this.props.resetItems();
  };

  // closeAddInvoice function to close the Invoice add form
  closeAddInvoice = () => {
    this.setState({
      addInvoice: false,
    });
    this.getData();
  };

  // componentDidMount function for calling the Invoice list data
  componentDidMount() {
    this.getData();
  }

  // getData function to call the all the Invoice data
  getData = () => {
    this.props.getSalesInvoices();
  };

  render() {
    const {
      addInvoice,
      data,
      selection,
      selected,
      modal,
      hover,
      cords,
      remarks,
      error,
    } = this.state;

    return (
      <div className="container-fluid">
        <div className="row">
          <Modal isOpen={modal} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle1}>Confirm changes?</ModalHeader>
            <ModalBody>
              <Form className="p-3" onSubmit={this.onSubmit} noValidate>
                <FormComponent
                  labelClass="bold label-sm"
                  label="Remarks"
                  type="text"
                  name="remarks"
                  change={this.onChange}
                  value={remarks}
                  required={true}
                />
                <FormGroup className="center">
                  <button type="submit" className="btn login-button white-text">
                    Submit
                  </button>
                </FormGroup>
              </Form>
            </ModalBody>
          </Modal>
          {!addInvoice ? (
            <div className="col-12 mx-auto">
              {!selection ? (
                <Container className="card container-card">
                  {/* <input
                  type="text"
                  onChange={this.search}
                  value={this.state.searchTerm}
                  className="searchfield form-control-sm"
                  placeholder="Invoice Id/Name"
                /> */}
                  {hover && (
                    <Hover
                      cords={cords}
                      labels={{
                        one: "Invoice Number",
                        two: "Invoice Date",
                        three: "Invoice Amount",
                      }}
                      mouse={this.state.mouse}
                    />
                  )}
                  <div className="div">
                    <button
                      className="btn add-button white-text label-sm"
                      onClick={this.openAddInvoice}
                    >
                      Add
                    </button>
                  </div>
                  <i
                    className="material-icons pointer"
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      color: "#C00",
                    }}
                    onClick={this.props.back}
                  >
                    cancel
                  </i>
                  <h5 className="bold center pt-2">Sales Invoices</h5>
                  <Row className="p-1">
                    <Col
                      md="4"
                      onDragOver={this.onDragOver}
                      onDrop={(e) => this.onDrop(e, "New")}
                      className="card p-2"
                    >
                      <h6 className="center bold m-2">New</h6>
                      <ul className="list flex-around  ul-head">
                        <li className="list-item bold pad">ID</li>
                        <li className="list-item bold flex-1">Type</li>
                        <li className="list-item bold flex-1">Name</li>
                      </ul>
                      {this.props.salesInvoice.data &&
                        this.props.salesInvoice.data.map((item) => {
                          if (item.pymtStatus === "New") {
                            return (
                              <ul
                                key={item.salesInvoiceId}
                                onClick={() => {
                                  this.select(item);
                                }}
                                onMouseEnter={(e) =>
                                  this.toggleHoverOn(e, item)
                                }
                                onMouseLeave={this.toggleHoverOff}
                                draggable
                                onDragStart={(e) => this.onDragStart(e, item)}
                                className={`list flex-around ${
                                  selected &&
                                  selected === item.salesInvoiceId &&
                                  "selected-item"
                                } InqList`}
                              >
                                <li className="list-table-item pad">
                                  {item.salesInvoiceId}
                                </li>
                                <li className="list-table-item flex-1">
                                  {item.invoiceType}
                                </li>
                                <li className="list-table-item flex-1">
                                  {item.customerName}
                                </li>
                              </ul>
                            );
                          } else {
                            return null;
                          }
                        })}
                    </Col>
                    <Col
                      md="4"
                      onDragOver={this.onDragOver}
                      onDrop={(e) => this.onDrop(e, "On Hold")}
                      className="card p-2"
                    >
                      <h6 className="center bold m-2">Work in progress</h6>
                      <ul className="list flex-around  ul-head">
                        <li className="list-item bold pad">ID</li>
                        <li className="list-item bold flex-1">Type</li>
                        <li className="list-item bold flex-1">Name</li>
                      </ul>
                      {data &&
                        data.map((item) => {
                          if (item.pymtStatus === "On Hold") {
                            return (
                              <ul
                                key={item.salesInvoiceId}
                                onClick={() => {
                                  this.select(item);
                                }}
                                onMouseEnter={(e) =>
                                  this.toggleHoverOn(e, item)
                                }
                                onMouseLeave={this.toggleHoverOff}
                                draggable
                                onDragStart={(e) => this.onDragStart(e, item)}
                                className={`list flex-around ${
                                  selected &&
                                  selected === item.salesInvoiceId &&
                                  "selected-item"
                                } InqList`}
                              >
                                <li className="list-table-item pad">
                                  {item.salesInvoiceId}
                                </li>
                                <li className="list-table-item flex-1">
                                  {item.invoiceType}
                                </li>
                                <li className="list-table-item flex-1">
                                  {item.customerName}
                                </li>
                              </ul>
                            );
                          } else {
                            return null;
                          }
                        })}
                    </Col>
                    <Col
                      md="4"
                      className="card p-2"
                      onDrop={(e) => this.onDrop(e, "Paid")}
                      onDragOver={this.onDragOver}
                    >
                      <h6 className="center bold m-2">Completed</h6>
                      <ul className="list flex-around  ul-head">
                        <li className="list-item bold pad">ID</li>
                        <li className="list-item bold flex-1">Type</li>
                        <li className="list-item bold flex-1">Name</li>
                      </ul>
                      {data &&
                        data.map((item) => {
                          if (
                            item.pymtStatus !== "New" &&
                            item.pymtStatus !== "On Hold"
                          ) {
                            return (
                              <ul
                                key={item.salesInvoiceId}
                                onClick={() => {
                                  this.select(item);
                                }}
                                onMouseEnter={(e) =>
                                  this.toggleHoverOn(e, item)
                                }
                                onMouseLeave={this.toggleHoverOff}
                                // draggable
                                // onDragStart={ (e) => this.onDragStart(e, item) }
                                className={`list flex-around ${
                                  selected &&
                                  selected === item.salesInvoiceId &&
                                  "selected-item"
                                } ${
                                  item.pymtStatus === "Complete" &&
                                  "completed-item"
                                } InqList`}
                              >
                                <li className="list-table-item pad">
                                  {item.salesInvoiceId}
                                </li>
                                <li className="list-table-item flex-1">
                                  {item.invoiceType}
                                </li>
                                <li className="list-table-item flex-1">
                                  {item.customerName}
                                </li>
                              </ul>
                            );
                          } else {
                            return null;
                          }
                        })}
                    </Col>
                  </Row>
                </Container>
              ) : (
                <SalesInvoiceDetails
                  back={this.back}
                  salesInvoiceDetails={this.state.selection}
                />
              )}
            </div>
          ) : (
            <AddSalesInvoice back={this.closeAddInvoice} />
          )}
        </div>
      </div>
    );
  }
}

SalesInvoice.propTypes = {
  getSalesInvoices: PropTypes.func.isRequired,
  updateSalesInvoice: PropTypes.func.isRequired,
  salesInvoice: PropTypes.object.isRequired,
  resetItems: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  salesInvoice: state.salesInvoice,
});

export default connect(mapStateToProps, {
  getSalesInvoices,
  resetItems,
  updateSalesInvoice,
})(SalesInvoice);
