import React, { Component } from "react";
import { Container, Table } from "reactstrap";
import AddDealType from "./AddDealType";
import UpdateDealType from "./UpdateDealType";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import {
  getDealType,
  deleteDealType,
} from "../../../../../redux/actions/dealTypeAction";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class DealType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addBtn: false,
      updateBtn: false,
      updateData: [],
    };

    this.toggleAddBtn = this.toggleAddBtn.bind(this);
    this.addBack = this.addBack.bind(this);
    this.toggleUpdateBtn = this.toggleUpdateBtn.bind(this);
    this.updateBack = this.updateBack.bind(this);
    this.getData = this.getData.bind(this);
    this.deleteDealType = this.deleteDealType.bind(this);
  }

  /*
toggleAddBtn function to toggle the addition page
*/
  toggleAddBtn() {
    this.setState({
      addBtn: true,
    });
  }

  /*
addBack function to back to the list page from the add page
*/
  addBack() {
    this.setState({
      addBtn: false,
    });

    this.getData();
  }

  /*
toggleUpdateBtn function to toggle the update form
*/
  toggleUpdateBtn(item) {
    this.setState({
      updateBtn: true,
      updateData: item,
    });
  }

  /*
updateBack function to redirect to back main page
*/
  updateBack() {
    this.setState({
      updateBtn: false,
    });

    this.getData();
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.props.getDealType();
  }

  deleteDealType(id) {
    let data = {
      dealTypeId: id,
    };
    this.props.deleteDealType(data);
  }

  render() {
    const { addBtn, updateBtn, updateData } = this.state;

    return (
      <Container className="card container-card">
        {!addBtn && !updateBtn ? (
          <React.Fragment>
            <button
              className="btn add-button white-text label-sm"
              onClick={this.toggleAddBtn}
            >
              Add
            </button>
            <h5 className="bold center pt-2">Deal Type</h5>
            <i
              className="material-icons cancel-button pointer"
              onClick={this.props.back}
            >
              cancel
            </i>
            <Table className="center">
              <thead>
                <tr>
                  <th className="opp-head ">ID</th>
                  <th className="opp-head ">Type</th>
                  <th className="opp-head ">Description</th>
                  <th className="opp-head ">CreatedBy</th>
                  <th className="opp-head ">CreatedOn</th>
                  <th className="opp-head ">UpdatedBy</th>
                  <th className="opp-head ">UpdatedOn</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.props.dealType.data &&
                  this.props.dealType.data.map((item) => {
                    return (
                      <tr>
                        <td className="opp-tableData">{item.dealTypeId}</td>
                        <td className="opp-tableData">{item.dealType}</td>
                        <td className="opp-tableData">{item.description}</td>
                        <td className="opp-tableData">{item.createdBy}</td>
                        <td className="opp-tableData">{item.createdOn}</td>
                        <td className="opp-tableData">{item.updatedBy}</td>
                        <td className="opp-tableData">{item.updatedOn}</td>
                        <td>
                          <span
                            className="material-icons pointer opp-action-edit"
                            style={{ color: "blue" }}
                            onClick={() => this.toggleUpdateBtn(item)}
                          >
                            edit
                          </span>
                        </td>
                        <td>
                          <i
                            className="material-icons pointer"
                            onClick={() => this.deleteDealType(item.dealTypeId)}
                          >
                            delete
                          </i>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </React.Fragment>
        ) : null}
        {addBtn && <AddDealType back={this.addBack} />}
        {updateBtn && (
          <UpdateDealType back={this.updateBack} data={updateData} />
        )}
        {this.props.dealType.message && (
          <Notifier message={this.props.dealType.message} />
        )}
        {this.props.dealType.error && (
          <ErrorNotifier message={this.props.dealType.error} />
        )}
      </Container>
    );
  }
}

DealType.propTypes = {
  getDealType: PropTypes.func.isRequired,
  deleteDealType: PropTypes.func.isRequired,
  dealType: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  dealType: state.dealType,
});

export default connect(mapStateToProps, {
  getDealType,
  deleteDealType,
})(DealType);
