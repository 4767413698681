import React, { Component } from "react";
import {
  Container,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// components
import { textChecker } from "../../../../common/FormValidator";
import {
  Validator,
  submitValidator,
  valid,
  errors,
} from "../../../../common/Validator";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
// redux
import { addHrPolicies } from "../../../../../redux/actions/hcm/hr/policies/hrPoliciesAction";

// --------------------------------------------------------------------------------------------
class AddPolicies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      poName: "",
      poDoc: "",
      fileError: "",
      formErrors: {
        poName: "",
      },
      message: "",
      error: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
  }

  /*
onChange function is to set the state after the enter the value to the field
*/
  onChange(e) {
    e.preventDefault();
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  /*
onFileChange function for setting the file selected from the user
*/
  onFileChange(e) {
    Validator(e.target);
    let allowedExtension = /(\.pdf|\.png)$/i;
    if (allowedExtension.exec(e.target.files[0].name)) {
      if ((e.target.files[0].size / 1024 / 1024).toFixed(2) < 2) {
        this.setState({
          poDoc: e.target.files[0] || [],
          fileError: "",
        });
      } else {
        this.setState({
          poDoc: e.target.files[0],
          fileError: "File size must less than 2MB",
        });
      }
    } else {
      this.setState({
        poDoc: e.target.files[0],
        fileError: "File type not allowed",
      });
    }
  }

  /* 
onBlur function for the frontEnd validation of forms
*/
  onBlur(e) {
    const { name, value } = e.target;
    let errors = this.state.formErrors;

    switch (name) {
      case "poName":
        errors.poName = textChecker.test(value)
          ? ""
          : "Use alphabets , numbers or spaces";
        break;
      default:
        break;
    }
    this.setState({
      formErrors: errors,
    });
  }

  /*
onSubmit function for submitting the new policies
*/
  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    const { fileError } = this.state;
    let file = this.state.poDoc;
    let fd = new FormData();

    fd.append("policyName", this.state.poName);
    fd.append("policyDoc", file);
    if (!fileError && valid) {
      this.props.addHrPolicies(fd, this.props.history);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  render() {
    let shrink = this.props.sidebar ? "scale" : "no-scale";
    const { fileError } = this.state;
    return (
      <div className={shrink}>
        <h5 className="center bold">Add Policy</h5>
        <Container className="card conatainer-card">
          <Link to="/pie/policiesmaintenance">
            <i
              className="material-icons pointer"
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                color: "#C00",
              }}
            >
              cancel
            </i>
          </Link>
          <Form onSubmit={this.onSubmit} className="p-3 mt-2" noValidate>
            <Row>
              <Col md="3"></Col>
              <Col md="6">
                <FormGroup>
                  <Label className="bold label-sm">Policy Name</Label>
                  <Input
                    type="text"
                    className={"form-control-sm"}
                    name="poName"
                    onChange={this.onChange}
                    value={this.state.poName}
                    onBlur={this.onBlur}
                    required={true}
                  />
                  {errors.poName && (
                    <FormText className="error">{errors.poName}</FormText>
                  )}
                </FormGroup>
                <div className="file-div">
                  <FormGroup className="file-display">
                    <Label className="bold label-sm">Policy Document</Label>
                    <Input
                      disabled
                      type="text"
                      value={this.state.poDoc.name}
                      className="form-control-sm"
                      required={true}
                    />
                    {errors.poDoc && (
                      <FormText className="error">{errors.poDoc}</FormText>
                    )}
                    {fileError && (
                      <FormText className="error">{fileError}</FormText>
                    )}
                  </FormGroup>
                  <FormGroup className="file-browse-btn-div">
                    <label className="btn  label-sm file-browse-btn">
                      Browse
                      <Input
                        type="file"
                        name="poDoc"
                        className="form-control-sm file-input"
                        onChange={this.onFileChange}
                        required={true}
                        accept=".pdf, .png"
                      />
                    </label>
                  </FormGroup>
                </div>
              </Col>
              <Col md="3"></Col>
            </Row>
            <div className="center">
              <button
                type="submit"
                className="btn login-button white-text"
                style={{ marginLeft: "10%" }}
              >
                Add Policy
              </button>
            </div>
          </Form>
        </Container>
        {this.props.hrPolicies.message ? (
          <Notifier message={this.props.hrPolicies.message} />
        ) : null}
        {this.props.hrPolicies.error ? (
          <ErrorNotifier message={this.props.hrPolicies.error} />
        ) : null}
      </div>
    );
  }
}

AddPolicies.propTypes = {
  addHrPolicies: PropTypes.func.isRequired,
  hrPolicies: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  hrPolicies: state.hrPolicies,
});

export default connect(mapStateToProps, { addHrPolicies })(AddPolicies);
