import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Row, Col, Container, Card } from "reactstrap";
import Hover from "../../../aside/Hover";
import UserRoleDetails from "./UserRoleDetails";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import {
  getRoleMapping,
  searchRoleMapping,
} from "../../../../redux/actions/iam/role_mapping/roleMappingAction";
import AddUserRole from "./AddUserRole";

class UserRole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: null,
      selected: null,
      searchTerm: "",
      hover: false,
      addBtn: false,
      message: "",
      error: "",
    };
    this.select = this.select.bind(this);
    this.back = this.back.bind(this);
    this.toggleHoverOn = this.toggleHoverOn.bind(this);
    this.toggleHoverOff = this.toggleHoverOff.bind(this);
    this.search = this.search.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.getData = this.getData.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userRole !== this.props.userRole) {
      this.setState({
        data: this.props.userRole.data,
      });
    }
    if (prevProps.auth !== this.props.auth) {
      this.setState({
        permissionlist: this.props.auth.permissionlist,
      });
    }
  }

  select(item) {
    this.setState({
      selection: item,
      selected: item.compCode,
      hover: false,
    });
  }

  search(e) {
    e.preventDefault();
    this.setState({
      searchTerm: e.target.value,
    });
    this.onSearch(e.target.value);
  }

  onSearch(value) {
    let data = {
      role: value,
    };
    this.props.searchRoleMapping(data);
  }
  // item.secondaryrole.map((item) => `${item}.join(' ')`)
  toggleHoverOn(e, item) {
    this.setState({
      hover: true,
      cords: {
        one: item.secondaryrole.join(", "),
      },
      mouse: {
        x: e.screenX,
        y: e.screenY,
      },
    });
  }

  toggleHoverOff() {
    this.setState({
      hover: false,
    });
  }

  back() {
    this.setState({
      selection: false,
    });
    this.getData();
  }

  /*
getData function for fetching all user Role list from the database
*/
  getData() {
    this.props.getRoleMapping();
  }

  componentDidMount() {
    this.getData();
  }

  addBtnToggle = () => {
    this.setState({
      addBtn: !this.state.addBtn,
    });
  };

  render() {
    let shrink;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");
    const { selection, selected } = this.state;

    return !this.state.addBtn ? (
      <div className={shrink}>
        <Container className="card container-card">
          <div>
            {!selection
              ? typeof this.props.location.serviceAccessData !== "undefined" &&
                this.props.location.serviceAccessData.creation && (
                  <button
                    className="btn add-button white-text label-sm"
                    style={{ position: "absolute", top: "0px", left: "0px" }}
                    onClick={this.addBtnToggle}
                  >
                    Add
                  </button>
                )
              : null}
          </div>
          {this.state.hover && (
            <Hover
              labels={{ one: "Secondary Roles" }}
              cords={this.state.cords}
              mouse={this.state.mouse}
            />
          )}
          {!selection && (
            <h5 className="bold center pt-2">User Role Mapping</h5>
          )}
          <Link
            to={{
              pathname: "/pie/iam/service_menu",
              moduleName: this.props.location.moduleName,
              data: this.props.location.data,
            }}
          >
            <i className="material-icons cancel-button pointer">cancel</i>
          </Link>
          {!selection && (
            <input
              type="text"
              onChange={this.search}
              value={this.state.searchTerm}
              className="searchfield form-control-sm mr-3"
              placeholder="EmpNo/EmpName/Role"
            />
          )}
          {selection ? (
            <i
              onClick={this.back}
              className="material-icons pointer cancel-button"
            >
              cancel
            </i>
          ) : null}
          <Row className="mt-3">
            {!selection ? (
              this.props.roleMapping.data.length > 0 &&
              this.props.roleMapping.data.map((item) => {
                return (
                  <Col
                    md="4"
                    key={item.id}
                    className={`employee-list-col ${
                      selected &&
                      selected === item.pieUser.id &&
                      "selected-item"
                    }`}
                    onClick={() => this.select(item)}
                  >
                    <Card className="p-2 mb-2 shadow pointer">
                      <ul className="CustDetails-ul employee-list-ul">
                        <li>
                          <span className="label-sm bold emp-span-label">
                            Emp No.
                          </span>
                          <span className=" label-sm">
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            <span className="employee-list-span-data">
                              {item.pieUser.empNo}
                            </span>
                          </span>
                        </li>
                        <li>
                          <span className="label-sm bold emp-span-label">
                            Emp Name
                          </span>
                          <span className=" label-sm">
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            <span className="employee-list-span-data">
                              {item.pieUser.firstName}&nbsp;
                              {item.pieUser.lastName}
                            </span>
                          </span>
                        </li>
                        <li>
                          <span className="label-sm bold emp-span-label">
                            User Group
                          </span>
                          <span className=" label-sm">
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            {item.pieUserGroup.groupName}
                          </span>
                        </li>
                        <li>
                          <span className="label-sm bold emp-span-label">
                            Role
                          </span>
                          <span className=" label-sm">
                            &nbsp;&nbsp;:&nbsp;&nbsp;{item.pieUserRole.roleCode}
                          </span>
                        </li>
                      </ul>
                    </Card>
                  </Col>
                );
              })
            ) : (
              <UserRoleDetails
                userRole={selection}
                back={this.back}
                history={this.props.history}
                locationData={this.props.location.serviceAccessData}
              />
            )}
          </Row>
        </Container>
        {this.state.message ? <Notifier message={this.state.message} /> : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
        {this.props.roleMapping.message ? (
          <Notifier message={this.props.roleMapping.message} />
        ) : null}
        {this.props.roleMapping.error ? (
          <ErrorNotifier message={this.props.roleMapping.error} />
        ) : null}
      </div>
    ) : (
      <AddUserRole back={this.addBtnToggle} sidebar={this.props.sidebar} />
    );
  }
}

UserRole.propTypes = {
  auth: PropTypes.object.isRequired,
  getRoleMapping: PropTypes.func.isRequired,
  searchRoleMapping: PropTypes.func.isRequired,
  roleMapping: PropTypes.object.isRequired,
  userRole: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  roleMapping: state.roleMapping,
  userRole: state.userRole,
});

export default connect(mapStateToProps, { getRoleMapping, searchRoleMapping })(
  UserRole
);
