import axios from "axios";
import { GET_GOVTID_TYPELIST,GET_GOVTID_SELECT_TYPELIST } from "../../types";
import {
  ADD_CANDIDATE_GOVID,
  GET_CANDIDATE_GOVID,
  UPDATE_CANDIDATE_GOVID,
  DELETE_CANDIDATE_GOVID,
  GET_CANDIDATE_SELECT_GOVID 
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addGovtId = (data, back) => (dispatch) => {
  axios
    .post(ADD_CANDIDATE_GOVID, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getGovtId));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getGovtId = () => (dispatch) => {
  axios
    .get( GET_CANDIDATE_GOVID, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(GET_GOVTID_TYPELIST, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getSelectGovtId = () => (dispatch) => {
  axios
    .get(GET_CANDIDATE_SELECT_GOVID , { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(GET_GOVTID_SELECT_TYPELIST, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateGovtId = (data, back) => (dispatch) => {
  axios
    .put(  UPDATE_CANDIDATE_GOVID , data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteGovtId = (data) => (dispatch) => {
  axios
    .delete( DELETE_CANDIDATE_GOVID, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null,getGovtId));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
