import axios from "axios";
import { BUSINESS_CURRENCY_GET } from "../../types";
import {
  CURD_BUSINESS_CURRENCY,
  USER_BUSINESS_CURRENCY,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addBusinessCurrency = (data, back) => (dispatch) => {
  axios
    .post(CURD_BUSINESS_CURRENCY, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getBusinessCurrency));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getBusinessCurrency = () => (dispatch) => {
  axios
    .get(CURD_BUSINESS_CURRENCY, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(BUSINESS_CURRENCY_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserBusinessCurrency = () => (dispatch) => {
  axios
    .get(USER_BUSINESS_CURRENCY, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(BUSINESS_CURRENCY_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateBusinessCurrency = (data, back) => (dispatch) => {
  axios
    .put(CURD_BUSINESS_CURRENCY, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getBusinessCurrency));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteBusinessCurrency = (data) => (dispatch) => {
  axios
    .delete(CURD_BUSINESS_CURRENCY, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getBusinessCurrency));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
