import axios from "axios";
import { MANAGER_LEAVE_REPORT_FETCH } from "../../types";
import {
  FETCH_MANAGER_DAY_LEAVE_REPORT,
  FETCH_MANAGER_WEEK_LEAVE_REPORT,
  FETCH_MANAGER_MONTH_LEAVE_REPORT,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

// ---------------------------------------------------------------------

export const fetchManagerDayLeaveReport = (user, setLoading) => (dispatch) => {
  console.log(user);
  axios
    .get(`${FETCH_MANAGER_DAY_LEAVE_REPORT}/${user}`, {
      withCredentials: true,
    })
    .then((res) => {
      dispatch(getDispatch(MANAGER_LEAVE_REPORT_FETCH, res));
      setLoading(false);
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
      setLoading(false);
    });
};

export const fetchManagerWeekLeaveReport = (data, setLoading) => (dispatch) => {
  axios
    .post(FETCH_MANAGER_WEEK_LEAVE_REPORT, data, {
      withCredentials: true,
    })
    .then((res) => {
      dispatch(getDispatch(MANAGER_LEAVE_REPORT_FETCH, res));
      setLoading(false);
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
      setLoading(false);
    });
};

export const fetchManagerMonthLeaveReport =
  (data, setLoading) => (dispatch) => {
    axios
      .post(FETCH_MANAGER_MONTH_LEAVE_REPORT, data, {
        withCredentials: true,
      })
      .then((res) => {
        dispatch(getDispatch(MANAGER_LEAVE_REPORT_FETCH, res));
        setLoading(false);
      })
      .catch((err) => {
        dispatch(setErrorMessage(err));
        setLoading(false);
      });
  };
