import axios from "axios";
import { DIALING_CODE_GET } from "../../types";
import { CURD_DIALING_CODE } from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addDialingCode = (data, back) => (dispatch) => {
  axios
    .post(CURD_DIALING_CODE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getDialingCode));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getDialingCode = () => (dispatch) => {
  axios
    .get(CURD_DIALING_CODE, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(DIALING_CODE_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

// export const fetchDialingCode = () => (dispatch) => {
//   axios
//     .get(FETCH_DIALING_CODE, { withCredentials: true })
//     .then((res) => {
//       dispatch(getDispatch(DIALING_CODE_FETCH, res));
//     })
//     .catch((err) => {
//       dispatch(setErrorMessage(err));
//     });
// };

export const updateDialingCode = (data, back) => (dispatch) => {
  axios
    .put(CURD_DIALING_CODE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getDialingCode));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteDialingCode = (data) => (dispatch) => {
  axios
    .delete(CURD_DIALING_CODE, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getDialingCode));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
