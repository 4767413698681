import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormText,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Select from "react-select";
import { connect } from "react-redux";
import axios from "axios";
import { getPartners } from "../../../../../redux/actions/partnerAction";
import { getVendors } from "../../../../../redux/actions/vendorAction";
import {
  updateInvoice,
  getRecieveItems,
  updateRecieveItem,
} from "../../../../../redux/actions/finance/accounts_payable/enter_invoice/invoiceAction";
import { getCurrency } from "../../../../../redux/actions/currencyAction";
import { getPartnerPymtTerms } from "../../../../../redux/actions/partnerPymtTermsAction";
import { getVendorPymtTerms } from "../../../../../redux/actions/vendorPymtTermsAction";
import { getPaymentStatus } from "../../../../../redux/actions/finance/invoice/paymentStatusAction";
import { getInvoiceType } from "../../../../../redux/actions/finance/invoice/invoiceTypeAction";
import {
  FETCH_PARTNER_TAXPYMT_DETAILS,
  FETCH_VENDOR_TAXPYMT_DETAILS,
  FETCH_PARTNER_BANK_ACC,
  FETCH_VENDOR_BANK_ACC,
} from "../../../../../utils/routes";
import FormComponent from "../../../../common/FormComponent";
import {
  errors,
  Validator,
  submitValidator,
  valid,
  refresh,
} from "../../../../common/Validator";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import Notifier from "../../../../aside/Notifier";

/**
 * @class AddInvoice
 **/

class UpdateInvoice extends Component {
  constructor(props) {
    super(props);
    const {
      purchaseId,
      poNo,
      poName,
      poAmount,
      invoiceNo,
      invoiceDate,
      invoiceType,
      invoiceCurrency,
      vpsName,
      vpsAddress,
      contactName,
      contactEmail,
      contactMobileNo,
      shipToAddress,
      shipToContactMobileNo,
      pan,
      tin,
      gst,
      bankName,
      bankIfscCode,
      bankAccName,
      bankAccNo,
      invoiceAmount,
      invoiceGst,
      invoiceTotalAmount,
      pymtTerm,
      pymtInstructions,
      invoiceStatus,
      remarks,
    } = this.props.invoice;
    this.state = {
      purchaseId: purchaseId,
      poNo: poNo,
      poName: poName,
      poAmount: poAmount,
      invoiceNo: invoiceNo,
      invoiceDate: invoiceDate,
      invoiceType: invoiceType,
      vpsName: vpsName,
      vpsAddress: vpsAddress,
      contactName: contactName,
      contactEmail: contactEmail,
      contactPhoneNumber: contactMobileNo,
      shipToAddress: shipToAddress,
      shipToContactMobileNo: shipToContactMobileNo,
      gstin: gst,
      pan: pan,
      tin: tin,
      bankName: bankName,
      bankIfscCode: bankIfscCode,
      bankAccountName: bankAccName,
      bankAccountNumber: bankAccNo,
      invoiceCurrency: invoiceCurrency,
      invoiceAmount: invoiceAmount,
      invoiceGst: invoiceGst,
      invoiceTotalAmount: invoiceTotalAmount,
      paymentTerms: pymtTerm,
      paymentInstructions: pymtInstructions,
      invoiceStatus: invoiceStatus,
      remarks: remarks,
      recieveItemModal: false,
      receiveItemsId: "",
      purchaseItemNo: "",
      pspName: "",
      quantity: "",
      amount: "",
      amountGST: "",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  componentDidMount() {
    this.props.getCurrency();
    this.props.getPartnerPymtTerms();
    this.props.getVendorPymtTerms();
    this.props.getPaymentStatus();
    this.props.getInvoiceType();
    let data = {
      purchaseId: this.state.purchaseId,
    };
    this.props.getRecieveItems(data);
    refresh();
  }

  // componentDidUpdate function to update the some fields
  componentDidUpdate(prevProps, prevState) {
    if (prevState.amount !== this.state.amount) {
      this.setState({
        amountGST: (this.state.amount * 0.18).toFixed(2),
      });
    }
    if (
      prevProps.invoices.recieveItemData !== this.props.invoices.recieveItemData
    ) {
      if (this.props.invoices.recieveItemData.length !== 0) {
        let arr = this.props.invoices.recieveItemData;
        let sumAmount = 0;
        let sumAmountGst = 0;
        arr.forEach(function (obj) {
          sumAmount += obj.amount * obj.qty;
          sumAmountGst += obj.gstAmount * obj.qty;
        });
        this.setState({
          invoiceAmount: sumAmount,
          invoiceGst: sumAmountGst.toFixed(2),
          invoiceTotalAmount:
            parseInt(sumAmount) + parseFloat(sumAmountGst.toFixed(2)),
        });
      }
    }
  }

  // onSubmit function to submit the form
  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);

    if (valid) {
      let data = {
        purchaseId: this.state.purchaseId,
        poNo: this.state.poNo,
        invoiceNo: this.state.invoiceNumber,
        invoiceDate: this.state.invoiceDate,
        invoiceCurrency: this.state.invoiceCurrency,
        invoiceAmount: this.state.invoiceAmount,
        invoiceGst: this.state.invoiceGst,
        invoiceTotalAmount: this.state.invoiceTotalAmount,
        pymtTerm: this.state.paymentTerms,
        // scheduledPymtDate: this.state.scheduledPaymentDate,
        // pymtAmount: this.state.paymentAmount,
        // paidAmount: this.state.paidAmount,
        pymtInstructions: this.state.paymentInstructions,
        invoiceStatus: this.state.invoiceStatus,
        remarks: this.state.remarks,
        createdBy: localStorage.getItem("id"),
      };

      this.props.updateInvoice(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all Mandatory Fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  // updateReceivedItem function to update the receive items
  receivedItemUpdate = (item) => {
    this.setState({
      receiveItemsId: item.receiveItemsId,
      purchaseId: item.purchaseId,
      // poNo: item.poNo,
      purchaseItemNo: item.purchaseItemNo,
      pspName: item.ppsName,
      quantity: item.qty,
      amount: item.amount,
      amountGST: item.gstAmount,
      recieveItemModal: true,
    });
  };

  // recieveItemToggle close the update form
  recieveItemToggle = () => {
    this.setState({
      recieveItemModal: false,
    });
    let data = {
      purchaseId: this.state.purchaseId,
    };
    this.props.getRecieveItems(data);
  };

  // recieveItemSubmit function to submit the received item update values
  recieveItemSubmit = (e) => {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      receiveItemsId: this.state.receiveItemsId,
      // poNo: this.state.poNo,
      purchaseId: this.state.purchaseId,
      purchaseItemNo: this.state.purchaseItemNo,
      ppsName: this.state.pspName,
      qty: this.state.quantity,
      amount: this.state.amount,
      gstAmount: this.state.amountGST,
    };
    if (valid) {
      this.props.updateRecieveItem(data, this.recieveItemToggle);
    } else {
      this.setState({
        error: "Enter all Mandatory Fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  };

  render() {
    const {
      poNo,
      poName,
      poAmount,
      invoiceNo,
      invoiceDate,
      invoiceType,
      vpsName,
      vpsAddress,
      contactName,
      contactEmail,
      contactPhoneNumber,
      shipToAddress,
      shipToContactMobileNo,
      gstin,
      pan,
      tin,
      bankName,
      bankIfscCode,
      bankAccountName,
      bankAccountNumber,
      invoiceCurrency,
      invoiceAmount,
      invoiceGst,
      invoiceTotalAmount,
      paymentTerms,
      paymentInstructions,
      invoiceStatus,
      remarks,
      recieveItemModal,
      purchaseItemNo,
      pspName,
      amount,
      quantity,
      amountGST,
      error,
    } = this.state;

    return (
      <div>
        <h5 className="center bold pt-2">Update Invoice</h5>

        <Row>
          <Col md="6">
            <Form onSubmit={this.onSubmit} className="p-3" noValidate>
              <Row>
                <Col md="6">
                  <FormComponent
                    label="PO Number"
                    labelClass="label-sm bold"
                    type="text"
                    name="poNo"
                    value={`${poNo} - ${poName}`}
                    change={this.onChange}
                    className="form-control-sm"
                    disabled={!poNo ? false : true}
                  />
                  <FormComponent
                    label="PO Amount"
                    labelClass="label-sm bold"
                    type="number"
                    name="poAmount"
                    value={poAmount}
                    change={this.onChange}
                    className="form-control-sm"
                    required={true}
                    disabled={true}
                  />
                  <FormComponent
                    label="Invoice Number"
                    labelClass="label-sm bold"
                    type="number"
                    name="invoiceNo"
                    value={invoiceNo}
                    change={this.onChange}
                    className="form-control-sm"
                    required={true}
                  />
                  <FormComponent
                    label="Invoice Date"
                    labelClass="label-sm bold"
                    type="date"
                    name="invoiceDate"
                    value={invoiceDate}
                    className="form-control-sm"
                    change={this.onChange}
                    required={true}
                  />
                  <FormGroup>
                    <Label className="label-sm bold">Invoice Type</Label>
                    <Input
                      type="select"
                      name="invoiceType"
                      value={invoiceType}
                      className="form-control-sm"
                      onChange={this.onChange}
                      required={true}
                    >
                      <option value="" hidden>
                        Select
                      </option>
                      {this.props.invoiceTypes.data.map((item) => {
                        return (
                          <option
                            key={item.invoiceTypeId}
                            value={item.invoiceType}
                          >
                            {item.invoiceType}
                          </option>
                        );
                      })}
                    </Input>
                    {errors.invoiceType && (
                      <FormText className="error">
                        {errors.invoiceType}
                      </FormText>
                    )}
                  </FormGroup>
                  <FormComponent
                    label="Partner / Vendor Name"
                    labelClass="label-sm bold"
                    type="text"
                    name="vpsName"
                    value={vpsName}
                    className="form-control-sm"
                    change={this.onChange}
                    required={true}
                    disabled={true}
                  />
                  {/* <FormGroup>
                          <Label className="label-sm bold">
                            Partner / Vendor Name
                          </Label>
                          {invoiceFrom ? (
                            invoiceFrom === "Partner" ? (
                              <Select
                                name="partnerNameOptions"
                                value={partnerNameSelectedOption}
                                onChange={this.partnerNameHandleChange}
                                options={partnerNameOptions}
                                className="select-form-control-sm"
                                required
                              />
                            ) : (
                              <Select
                                name="vendorNameOptions"
                                value={vendorNameSelectedOption}
                                onChange={this.vendorNameHandleChange}
                                options={vendorNameOptions}
                                className="select-form-control-sm"
                                required
                              />
                            )
                          ) : (
                            <Select
                              name="partnerNameOptions"
                              className="select-form-control-sm"
                              required
                            />
                          )}
                          {partnerNameSelectedOptionError && (
                            <FormText className="error ">
                              {partnerNameSelectedOptionError}
                            </FormText>
                          )}
                          {vendorNameSelectedOptionError && (
                            <FormText className="error ">
                              {vendorNameSelectedOptionError}
                            </FormText>
                          )}
                        </FormGroup> */}
                  <FormComponent
                    label="Partner / Vendor Address"
                    labelClass="label-sm bold"
                    type="text"
                    name="vpsAddress"
                    value={vpsAddress}
                    change={this.onChange}
                    className="form-control-sm"
                    required={true}
                    disabled={true}
                  />
                  <FormComponent
                    label="Contact Name"
                    labelClass="label-sm bold"
                    type="text"
                    name="contactName"
                    value={contactName}
                    change={this.onChange}
                    className="form-control-sm"
                    required={true}
                    disabled={true}
                  />
                  <FormComponent
                    label="Contact Email"
                    labelClass="label-sm bold"
                    type="text"
                    name="conatactEmail"
                    value={contactEmail}
                    change={this.onChange}
                    className="form-control-sm"
                    required={true}
                    disabled={true}
                  />
                  <FormComponent
                    label="Contact Phone Number"
                    labelClass="label-sm bold"
                    type="tel"
                    name="contactPhoneNumber"
                    value={contactPhoneNumber}
                    change={this.onChange}
                    className="form-control-sm"
                    required={true}
                    disabled={true}
                  />
                  <FormComponent
                    label="Ship To Address"
                    labelClass="label-sm bold"
                    type="text"
                    name="shipToAddress"
                    value={shipToAddress}
                    change={this.onChange}
                    className="form-control-sm"
                    required={true}
                    disabled={true}
                  />
                  <FormComponent
                    label="Ship To Contact Number"
                    labelClass="label-sm bold"
                    type="tel"
                    name="shipToContactMobileNo"
                    value={shipToContactMobileNo}
                    change={this.onChange}
                    className="form-control-sm"
                    required={true}
                    disabled={true}
                  />
                  <FormComponent
                    label="GSTIN"
                    labelClass="label-sm bold"
                    type="text"
                    name="gstin"
                    value={gstin}
                    change={this.onChange}
                    className="form-control-sm"
                    required={true}
                    disabled={true}
                  />
                  <FormComponent
                    label="PAN"
                    labelClass="label-sm bold"
                    type="text"
                    name="pan"
                    value={pan}
                    change={this.onChange}
                    className="form-control-sm"
                    required={true}
                    disabled={true}
                  />
                </Col>
                <Col md="6">
                  <FormComponent
                    label="TIN"
                    labelClass="label-sm bold"
                    type="text"
                    name="tin"
                    value={tin}
                    change={this.onChange}
                    className="form-control-sm"
                    required={true}
                    disabled={true}
                  />
                  <FormComponent
                    label="Bank Name"
                    labelClass="label-sm bold"
                    type="text"
                    name="bankName"
                    value={bankName}
                    change={this.onChange}
                    className="form-control-sm"
                    required={true}
                    disabled={true}
                  />
                  <FormComponent
                    label="Bank IFSC Code"
                    labelClass="label-sm bold"
                    type="text"
                    name="bankIfscCode"
                    value={bankIfscCode}
                    change={this.onChange}
                    className="form-control-sm"
                    required={true}
                    disabled={true}
                  />
                  <FormComponent
                    label="Bank Account Name"
                    labelClass="label-sm bold"
                    type="text"
                    name="bankAccountName"
                    value={bankAccountName}
                    change={this.onChange}
                    className="form-control-sm"
                    required={true}
                    disabled={true}
                  />
                  <FormComponent
                    label="Bank Account Number"
                    labelClass="label-sm bold"
                    type="number"
                    name="bankAccountNumber"
                    value={bankAccountNumber}
                    change={this.onChange}
                    className="form-control-sm"
                    required={true}
                    disabled={true}
                  />
                  <FormGroup>
                    <Label className="bold label-sm">Invoice Currency</Label>
                    <Input
                      type="select"
                      name="invoiceCurrency"
                      className="form-control-sm "
                      value={invoiceCurrency}
                      onChange={this.onChange}
                      required={true}
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.currency.data.map((item) => {
                        return (
                          <option
                            key={item.currencyId}
                            value={item.currencyCode}
                          >
                            {item.currencyCode}
                          </option>
                        );
                      })}
                    </Input>
                    {errors.invoiceCurrency && (
                      <FormText className="error">
                        {errors.invoiceCurrency}
                      </FormText>
                    )}
                  </FormGroup>
                  <FormComponent
                    label="Invoice Amount"
                    labelClass="label-sm bold"
                    type="number"
                    name="invoiceAmount"
                    value={invoiceAmount}
                    className="form-control-sm"
                    required={true}
                    disabled={true}
                  />
                  <FormComponent
                    label="Invoice GST"
                    labelClass="label-sm bold"
                    type="number"
                    name="invoiceGst"
                    value={invoiceGst}
                    className="form-control-sm"
                    required={true}
                    disabled={true}
                  />
                  <FormComponent
                    label="Invoice Total Amount"
                    labelClass="label-sm bold"
                    type="number"
                    name="invoiceTotalAmount"
                    value={invoiceTotalAmount}
                    className="form-control-sm"
                    required={true}
                    disabled={true}
                  />
                  <FormGroup>
                    <Label className="label-sm bold">Payment Terms</Label>
                    <Input
                      type="select"
                      className="form-control-sm"
                      name="paymentTerms"
                      value={paymentTerms}
                      onChange={this.onChange}
                      required={true}
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.partnerPymtTerms.data.map((item) => {
                        return (
                          <option
                            key={item.partnerPymtId}
                            value={item.partnerPymtName}
                          >
                            {item.partnerPymtName}
                          </option>
                        );
                      })}
                    </Input>
                    {errors.paymentTerms && (
                      <FormText className="error">
                        {errors.paymentTerms}
                      </FormText>
                    )}
                  </FormGroup>
                  <FormComponent
                    label="Payment Instructions"
                    labelClass="label-sm bold"
                    type="text"
                    name="paymentInstructions"
                    value={paymentInstructions}
                    change={this.onChange}
                    className="form-control-sm"
                    required={true}
                  />
                  <FormGroup>
                    <Label className="label-sm bold">Invoice Status</Label>
                    <Input
                      type="select"
                      className="form-control-sm"
                      name="invoiceStatus"
                      value={invoiceStatus}
                      onChange={this.onChange}
                      required
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.paymentStatus.data.map((item) => {
                        return (
                          <option
                            key={item.pymtStatusId}
                            value={item.pymtStatusName}
                          >
                            {item.pymtStatusName}
                          </option>
                        );
                      })}
                    </Input>
                    {errors.invoiceStatus && (
                      <FormText className="error">
                        {errors.invoiceStatus}
                      </FormText>
                    )}
                  </FormGroup>
                  <FormComponent
                    label="Remarks"
                    labelClass="label-sm bold"
                    type="textarea"
                    className="form-control-sm"
                    name="remarks"
                    value={remarks}
                    change={this.onChange}
                    required={true}
                  />
                </Col>
              </Row>
              <div className="center">
                <button
                  type="submit"
                  className="btn center login-button white-text"
                >
                  Update
                </button>
              </div>
            </Form>
          </Col>
          <Col md="6">
            <Row className="milestone-row">
              <div className="milestone-div">
                <h6 className="center">Received Items</h6>
                <Table>
                  <thead>
                    <tr>
                      <th className="opp-head">Name</th>
                      <th className="opp-head">Qty</th>
                      <th className="opp-head">Amount</th>
                      <th className="opp-head">GST</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.invoices.recieveItemData.map((item) => {
                      return (
                        <tr key={item.recieveItemsId}>
                          <td>{item.ppsName}</td>
                          <td>{item.qty}</td>
                          <td>{item.amount}</td>
                          <td>{item.gstAmount}</td>
                          <td>
                            <span
                              className="product-pq-button"
                              id="lead-activity-edit-button"
                              title="Edit"
                              style={{ color: "blue" }}
                              onClick={() => this.receivedItemUpdate(item)}
                            >
                              edit
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Row>
          </Col>
        </Row>
        <Modal isOpen={recieveItemModal} size="md">
          <ModalHeader toggle={this.recieveItemToggle}>
            Received Items
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={this.recieveItemSubmit} noValidate>
              <Row>
                <Col md="6" className="mx-auto">
                  <FormComponent
                    label="Purchase Item Number"
                    labelClass="label-sm  bold"
                    className="form-control-sm"
                    type="number"
                    name="purchaseItemNo"
                    value={purchaseItemNo}
                    change={this.onChange}
                    required={true}
                  />
                  <FormComponent
                    label="Product/Service/Project Name"
                    labelClass="label-sm  bold"
                    className="form-control-sm"
                    type="text"
                    name="pspName"
                    value={pspName}
                    change={this.onChange}
                    required={true}
                  />
                  <FormComponent
                    label="Quantity"
                    labelClass="label-sm  bold"
                    className="form-control-sm"
                    type="number"
                    name="quantity"
                    value={quantity}
                    change={this.onChange}
                    required={true}
                  />
                  <FormComponent
                    label="Amount"
                    labelClass="label-sm  bold"
                    className="form-control-sm"
                    type="number"
                    name="amount"
                    value={amount}
                    change={this.onChange}
                    required={true}
                  />
                  <FormComponent
                    label="Amount GST"
                    labelClass="label-sm  bold"
                    className="form-control-sm"
                    type="number"
                    name="amountGST"
                    value={amountGST}
                    change={this.onChange}
                    required={true}
                    disabled={true}
                  />
                </Col>
              </Row>
              <div className="center">
                <button
                  type="submit"
                  className="btn center login-button white-text"
                >
                  Update
                </button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
        {error && <ErrorNotifier message={error} />}
        {this.props.invoices.message && (
          <Notifier message={this.props.invoices.message} />
        )}
        {this.props.invoices.error && (
          <ErrorNotifier message={this.props.invoices.error} />
        )}
      </div>
    );
  }
}

UpdateInvoice.propTypes = {
  getPartners: PropTypes.func.isRequired,
  partners: PropTypes.object.isRequired,
  getVendors: PropTypes.func.isRequired,
  vendors: PropTypes.object.isRequired,
  updateInvoice: PropTypes.func.isRequired,
  getRecieveItems: PropTypes.func.isRequired,
  updateRecieveItem: PropTypes.func.isRequired,
  invoices: PropTypes.object.isRequired,
  getCurrency: PropTypes.func.isRequired,
  currency: PropTypes.object.isRequired,
  getPartnerPymtTerms: PropTypes.func.isRequired,
  partnerPymtTerms: PropTypes.object.isRequired,
  getVendorPymtTerms: PropTypes.func.isRequired,
  vendorPymtTerms: PropTypes.object.isRequired,
  getPaymentStatus: PropTypes.func.isRequired,
  paymentStatus: PropTypes.object.isRequired,
  getInvoiceType: PropTypes.func.isRequired,
  invoiceTypes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  partners: state.partners,
  vendors: state.vendors,
  invoices: state.invoices,
  currency: state.currency,
  partnerPymtTerms: state.partnerPymtTerms,
  vendorPymtTerms: state.vendorPymtTerms,
  paymentStatus: state.paymentStatus,
  invoiceTypes: state.invoiceTypes,
});

export default connect(mapStateToProps, {
  getPartners,
  getVendors,
  updateInvoice,
  getRecieveItems,
  updateRecieveItem,
  getCurrency,
  getPartnerPymtTerms,
  getVendorPymtTerms,
  getPaymentStatus,
  getInvoiceType,
})(UpdateInvoice);
