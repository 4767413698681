import { GET_ERRORS, VENDOR_DOCUMENTS_DOWNLOAD } from "../../../actions/types";

const initialState = {
  url: null,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case VENDOR_DOCUMENTS_DOWNLOAD:
      return {
        ...state,
        url: action.payload,
      };
    case GET_ERRORS:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
