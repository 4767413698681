import React, { Component } from "react";
import {
  Container,
  Form,
  Row,
  Col,
  Label,
  FormText,
  FormGroup,
  Input,
} from "reactstrap";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import Notifier from "../../../../aside/Notifier";
import { uploadPayslip } from "../../../../../redux/actions/finance/payslip/payslipAction";
import { getEmployeeDetails } from "../../../../../redux/actions/employeeAction";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateFns from "date-fns";
import Select from "react-select";

class UploadPayslip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: new Date(),
      month: DateFns.subMonths(new Date(), 1),
      selectedOption: null,
      selectedOptionError: null,
      empName: "",
      file: [],
      fileError: "",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleFile = this.handleFile.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleChange = (selectedOption) => {
    if (selectedOption) {
      this.setState({ selectedOption, selectedOptionError: null });
    } else {
      this.setState({
        selectedOptionError: "This field is mandatory",
      });
    }
  };

  onChangeYear = (date) => {
    this.setState({
      year: date,
    });
  };

  onChangeMonth = (date) => {
    this.setState({
      month: date,
    });
  };

  handleFile(e) {
    Validator(e.target);
    let allowedExtension = /(\.pdf)$/i;
    if (allowedExtension.exec(e.target.files[0].name)) {
      if ((e.target.files[0].size / 1024 / 1024).toFixed(2) < 2) {
        this.setState({
          file: e.target.files[0] || [],
          fileError: "",
        });
      } else {
        this.setState({
          file: e.target.files[0],
          fileError: "File size must less than 2MB",
        });
      }
    } else {
      this.setState({
        file: e.target.files[0],
        fileError: "File type not allowed",
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    const { selectedOption } = this.state;
    if (valid && selectedOption) {
      let fd = new FormData();

      fd.append("empId", this.state.selectedOption.value);
      fd.append("empName", this.state.empName);
      fd.append("currentYear", DateFns.format(this.state.year, "YYYY"));
      fd.append("month", DateFns.format(this.state.month, "MMM"));
      fd.append("createdBy", localStorage.getItem("id"));
      fd.append("uploadPayslip", this.state.file);

      this.props.uploadPayslip(fd, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      if (!this.state.selectedOption) {
        this.setState({
          selectedOptionError: "This field is mandatory",
        });
      }
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidMount contains the error refresh method and setState method for calling the render method
*/
  componentDidMount() {
    refresh();
    this.setState({
      error: "",
    });

    this.props.getEmployeeDetails();
  }

  // ComponentDidUpdate to set the employee Name
  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedOption !== this.state.selectedOption) {
      this.props.employee.data.map((item) => {
        if (item.empId === this.state.selectedOption.value) {
          this.setState({
            empName: item.firstName,
          });
        }
      });
    }
  }

  arrowRenderer = () => {
    return (
      <div>
        <i className="material-icons pointer cancel-button">close</i>
      </div>
    );
  };

  render() {
    const {
      year,
      month,
      selectedOption,
      selectedOptionError,
      empName,
      file,
      fileError,
      error,
    } = this.state;

    let options = [];
    for (let i = 0; i < this.props.employee.data.length; i++) {
      var x = {};
      x["value"] = this.props.employee.data[i].empId;
      x["label"] = this.props.employee.data[i].empId;

      options.push(x);
    }

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-8 mx-auto">
            <Container className="card container-card cust-container">
              <h5 className="center bold pt-2">Upload Payslip</h5>
              <i
                className="material-icons pointer cancel-button"
                onClick={this.props.back}
              >
                cancel
              </i>
              <Form onSubmit={this.onSubmit} className="p-3" noValidate>
                <Row>
                  <Col md="8" className="mx-auto">
                    <div className="payslip-date-picker">
                      <div className="payslip-flex-div-one">
                        <Label className="label-sm bold">Year</Label>
                        <DatePicker
                          showYearPicker
                          dateFormat="yyyy"
                          selected={year}
                          onChange={this.onChangeYear}
                          maxDate={new Date()}
                        />
                      </div>
                      <div className="payslip-flex-div-two">
                        <Label className="label-sm bold">Month</Label>
                        <DatePicker
                          dateFormat="MM/yyyy"
                          showMonthYearPicker
                          selected={month}
                          onChange={this.onChangeMonth}
                          maxDate={DateFns.subMonths(new Date(), 1)}
                        />
                      </div>
                    </div>
                    <FormGroup>
                      <Label className="label-sm bold">Employee Id</Label>
                      <Select
                        name="options"
                        value={selectedOption}
                        onChange={this.handleChange}
                        options={options}
                        className="select-form-control-sm"
                        arrowRenderer={this.arrowRenderer}
                        optionRenderer={this.props.optionRenderer}
                        required
                      />
                      {selectedOptionError && (
                        <FormText className="error ">
                          {selectedOptionError}
                        </FormText>
                      )}
                    </FormGroup>

                    <FormComponent
                      label="Employee Name"
                      labelClass="label-sm bold"
                      type="text"
                      value={empName}
                      disabled={true}
                    />
                    <div className="file-div">
                      <FormGroup className="file-display">
                        <Label className="bold label-sm">Payslip</Label>
                        <Input
                          disabled
                          type="text"
                          value={file.name || ""}
                          className="form-control-sm"
                          required={true}
                        />
                        {errors.uploadPayslip && (
                          <FormText className="error ">
                            {errors.uploadPayslip}
                          </FormText>
                        )}
                        {fileError && (
                          <FormText className="error ">{fileError}</FormText>
                        )}
                      </FormGroup>
                      <FormGroup className="file-browse-btn-div">
                        <label className="btn  label-sm file-browse-btn">
                          Browse
                          <Input
                            type="file"
                            name="uploadPayslip"
                            className="form-control-sm file-input"
                            onChange={this.handleFile}
                            required={true}
                          />
                        </label>
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <div className="center">
                  <button type="submit" className="btn login-button white-text">
                    Upload
                  </button>
                </div>
              </Form>
              {error && <ErrorNotifier message={error} />}
              {this.props.payslips.error && (
                <ErrorNotifier message={this.props.payslips.error} />
              )}
              {this.props.payslips.message && (
                <Notifier message={this.props.payslips.message} />
              )}
            </Container>
          </div>
        </div>
      </div>
    );
  }
}

UploadPayslip.propTypes = {
  uploadPayslip: PropTypes.func.isRequired,
  payslips: PropTypes.object.isRequired,
  getEmployeeDetails: PropTypes.func.isRequired,
  employee: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  payslips: state.payslips,
  employee: state.employee,
});

export default connect(mapStateToProps, { getEmployeeDetails, uploadPayslip })(
  UploadPayslip
);
