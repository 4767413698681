import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";
import DateFns from "date-fns";
import Select from "react-select";
// material ui
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// redux
import {
  fetchManagerDayAttendanceReport,
  fetchManagerWeekAttendanceReport,
  fetchManagerMonthAttendanceReport,
} from "../../../../../redux/actions/hcm/lms/attendanceReportAction";
import { fetchActiveEmployees } from "../../../../../redux/actions/hcm/hr/emp_management/empManagementAction";

// ----------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },

  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

// --------------------------------------------------------------------

export const AttendanceReport = (props) => {
  const [pieUserId, setPieUserId] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedValue, setSelectedValue] = React.useState("day");
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [optionValue, setOptionValue] = useState([]);
  const [selectedOptionValue, setSelectedOptionValue] = useState({});

  let shrink;
  props.sidebar ? (shrink = "scale") : (shrink = "no-scale");

  useEffect(() => {
    props.fetchActiveEmployees();
  }, []);

  useEffect(() => {
    let options = [];
    for (let i = 0; i < props.empManagement.activeEmployees.length; i++) {
      let x1 = {};
      x1["value"] = props.empManagement.activeEmployees[i].id;
      x1[
        "label"
      ] = `${props.empManagement.activeEmployees[i].empNo}-${props.empManagement.activeEmployees[i].firstName}`;

      options.push(x1);
    }
    setOptionValue(options);
  }, [props.empManagement.activeEmployees]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleButtonClick = () => {
    if (!loading) {
      setLoading(true);
    }
    if (selectedValue === "day") {
      props.fetchManagerDayAttendanceReport(pieUserId, setLoading);
    } else if (selectedValue === "week") {
      let data = {
        hcmemployeeId: pieUserId,
        fromDate: fromDate,
        toDate: toDate,
      };
      props.fetchManagerWeekAttendanceReport(data, setLoading);
    } else {
      let data = {
        hcmemployeeId: pieUserId,
        fromDate: fromDate,
        toDate: toDate,
      };
      props.fetchManagerMonthAttendanceReport(data, setLoading);
    }
  };

  const handleOptionChange = (selectedOption) => {
    setPieUserId(selectedOption.value);
    setSelectedOptionValue(selectedOption);
  };

  return (
    <div className={shrink}>
      <Container className="card container-card pt-2">
        <h5 className="pointer bold center pt-2 mb-2">Attendance Report</h5>
        <Link
          to={{
            pathname: "/pie/iam/service_menu",
            moduleName: props.location.moduleName,
            data: props.location.data,
          }}
        >
          <i className="material-icons cancel-button pointer">cancel</i>
        </Link>
        <RadioGroup
          row
          aria-label="position"
          name="position"
          defaultValue="top"
          className="pl-5"
        >
          <FormControlLabel
            control={
              <Radio
                color="primary"
                checked={selectedValue === "day"}
                onChange={handleChange}
                value="day"
              />
            }
            label="Day"
          />
          <FormControlLabel
            control={
              <Radio
                color="primary"
                checked={selectedValue === "week"}
                onChange={handleChange}
                value="week"
              />
            }
            label="Week"
          />
          <FormControlLabel
            control={
              <Radio
                color="primary"
                checked={selectedValue === "month"}
                onChange={handleChange}
                value="month"
              />
            }
            label="Month"
          />
        </RadioGroup>
        <Row style={{ justifyContent: "space-between" }}>
          <Col md="4" className="pl-5">
            <FormGroup>
              <Label className="bold label-sm">Select Employee</Label>
              <Select
                value={selectedOptionValue}
                onChange={handleOptionChange}
                options={optionValue}
                required
              />
            </FormGroup>
          </Col>
          {selectedValue !== "day" && (
            <>
              <Col md="3">
                <FormGroup>
                  <Label className="bold label-sm">From Date</Label>
                  <Input
                    type={`${selectedValue === "month" ? "month" : "date"}`}
                    name="fromDate"
                    className="form-control-sm"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                    required={true}
                    max={DateFns.format(new Date(), "YYYY-MM-DD")}
                  ></Input>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label className="bold label-sm">To Date</Label>
                  <Input
                    type={`${selectedValue === "month" ? "month" : "date"}`}
                    name="toDate"
                    className="form-control-sm"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                    required={true}
                    min={DateFns.format(fromDate, "YYYY-MM-DD")}
                    max={DateFns.format(new Date(), "YYYY-MM-DD")}
                  ></Input>
                </FormGroup>
              </Col>
            </>
          )}
          <Col md="2" className="align-center flex pr-5">
            <div className={classes.wrapper}>
              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={handleButtonClick}
              >
                View
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Col>
        </Row>
        <Table className="center">
          <thead>
            <tr>
              <th className="opp-head ">Date</th>
              <th className="opp-head ">Day</th>
              <th className="opp-head ">Time In</th>
              <th className="opp-head ">Time Out</th>
              <th className="opp-head ">Total Hrs</th>
              <th className="opp-head ">Status</th>
              <th className="opp-head ">Submitted On</th>
            </tr>
          </thead>
          <tbody>
            {props.attendanceReport.data.length > 0 &&
              props.attendanceReport.data.map((item) => {
                return (
                  <tr>
                    <td className="opp-tableData">
                      {DateFns.format(item.calendarDate, "DD-MM-YYYY")}
                    </td>
                    <td className="opp-tableData">{item.calendarDay}</td>
                    <td className="opp-tableData">{item.timeIn}</td>
                    <td className="opp-tableData">{item.timeOut}</td>
                    <td className="opp-tableData">{item.totalWorkHours}</td>
                    <td className="opp-tableData">{item.attendanceStatus}</td>
                    <td className="opp-tableData">
                      {DateFns.format(item.submittedOn, "DD-MM-YYYY")}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Container>
    </div>
  );
};

AttendanceReport.propTypes = {
  props: PropTypes,
  fetchManagerDayAttendanceReport: PropTypes.func.isRequired,
  fetchManagerWeekAttendanceReport: PropTypes.func.isRequired,
  fetchManagerMonthAttendanceReport: PropTypes.func.isRequired,
  attendanceReport: PropTypes.object.isRequired,
  fetchActiveEmployees: PropTypes.func.isRequired,
  empManagement: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  attendanceReport: state.attendanceReport,
  empManagement: state.empManagement,
});

const mapDispatchToProps = (dispatch) => ({
  fetchManagerDayAttendanceReport: (user, setLoading) =>
    dispatch(fetchManagerDayAttendanceReport(user, setLoading)),
  fetchManagerWeekAttendanceReport: (data, setLoading) =>
    dispatch(fetchManagerWeekAttendanceReport(data, setLoading)),
  fetchManagerMonthAttendanceReport: (data, setLoading) =>
    dispatch(fetchManagerMonthAttendanceReport(data, setLoading)),
  fetchActiveEmployees: () => dispatch(fetchActiveEmployees()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceReport);
