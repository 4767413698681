import axios from "axios";
import {
  MY_ATTENDANCE_GET,
  MY_ATTENDANCE_FETCH,
  MY_ATTENDANCE_SEARCH,
} from "../../types";
import {
  CURD_MY_ATTENDANCE,
  FETCH_MY_ATTENDANCE,
  SEARCH_MY_ATTENDANCE,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addMyAttendance = (data, back) => (dispatch) => {
  axios
    .post(CURD_MY_ATTENDANCE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getMyAttendance));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getMyAttendance = () => (dispatch) => {
  axios
    .get(CURD_MY_ATTENDANCE, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(MY_ATTENDANCE_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const searchMyAttendance = (data) => (dispatch) => {
  axios
    .get(SEARCH_MY_ATTENDANCE, data, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(MY_ATTENDANCE_SEARCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const fetchMyAttendance = (logic) => (dispatch) => {
  axios
    .get(FETCH_MY_ATTENDANCE, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(MY_ATTENDANCE_FETCH, res));
      logic();
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateMyAttendance = (data, back) => (dispatch) => {
  axios
    .put(CURD_MY_ATTENDANCE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteMyAttendance = (data) => (dispatch) => {
  axios
    .delete(CURD_MY_ATTENDANCE, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getMyAttendance));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
