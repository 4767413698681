import React, { Component } from "react";
import { Container, Table } from "reactstrap";
import { Link } from "react-router-dom";
import DateFns from "date-fns";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// components
import AddOrganization from "./AddOrganization";
import UpdateOrganization from "./UpdateOrganization";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import ConfirmDialog from "../../../../common/confirm-dialog/ConfirmDialog";
// material ui
import { Box } from "@material-ui/core";
// redux
import {
  getOrganization,
  deleteOrganization,
} from "../../../../../redux/actions/hcm/business_setup/organizationAction";

// --------------------------------------------------------------------------------

class Organization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addBtn: false,
      updateBtn: false,
      updateData: [],
      confirmDialog: {
        isOpen: false,
        title: "",
        subTitle: "",
      },
      error: "",
      message: "",
    };

    this.toggleAddBtn = this.toggleAddBtn.bind(this);
    this.addBack = this.addBack.bind(this);
    this.toggleUpdateBtn = this.toggleUpdateBtn.bind(this);
    this.updateBack = this.updateBack.bind(this);
    this.getData = this.getData.bind(this);
    this.deleteOrganization = this.deleteOrganization.bind(this);
  }

  /*
toggleAddBtn function to toggle the addition page
*/
  toggleAddBtn() {
    this.setState({
      addBtn: true,
    });
  }

  /*
addBack function to back to the list page from the add page
*/
  addBack() {
    this.setState({
      addBtn: false,
    });
  }

  /*
toggleUpdateBtn function to toggle the update form
*/
  toggleUpdateBtn(item) {
    this.setState({
      updateBtn: true,
      updateData: item,
    });
  }

  /*
updateBack function to redirect to back main page
*/
  updateBack() {
    this.setState({
      updateBtn: false,
    });
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.props.getOrganization();
  }

  deleteOrganization(id) {
    let data = {
      id: id,
    };
    this.props.deleteOrganization(data);
    this.setState((prevState) => ({
      ...prevState.confirmDialog,
      confirmDialog: {
        isOpen: false,
      },
    }));
  }

  setConfirmDialog = (obj) => {
    this.setState((prevProps) => ({
      confirmDialog: {
        ...prevProps.confirmDialog,
        isOpen: obj.isOpen,
      },
    }));
  };

  render() {
    const { addBtn, updateBtn, updateData, message, error } = this.state;
    let shrink = this.props.sidebar ? "scale" : "no-scale";

    return (
      <Box className={shrink}>
        <Container className="card container-card">
          {!addBtn && !updateBtn ? (
            <React.Fragment>
              {typeof this.props.location.serviceAccessData !== "undefined" &&
                this.props.location.serviceAccessData.creation && (
                  <button
                    className="btn add-button white-text label-sm"
                    onClick={this.toggleAddBtn}
                  >
                    Add
                  </button>
                )}
              <h5 className="bold center pt-2">Organization</h5>
              <Link
                to={{
                  pathname: "/pie/iam/service_menu",
                  moduleName: this.props.location.moduleName,
                  data: this.props.location.data,
                }}
              >
                <i className="material-icons cancel-button pointer">cancel</i>
              </Link>
              <Table className="center" size="sm">
                <thead>
                  <tr>
                    <th className="opp-head ">Enterprise Name</th>
                    <th className="opp-head ">Name</th>
                    <th className="opp-head ">Registration No.</th>
                    <th className="opp-head ">Organization Code</th>
                    <th className="opp-head ">PAN No.</th>
                    <th className="opp-head ">Tax Reg No.</th>
                    <th className="opp-head ">GST No.</th>
                    <th className="opp-head platform-legalEntity-date">
                      Start Date
                    </th>
                    <th className="opp-head ">Status</th>
                    <th className="opp-head ">Address</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.organization.data.length > 0 &&
                    this.props.organization.data.map((item) => {
                      return (
                        <tr>
                          <td className="opp-tableData">
                            {item.enterpriseName}
                          </td>
                          <td className="opp-tableData">
                            {item.orgLegalEntityName}
                          </td>
                          <td className="opp-tableData">
                            {item.orgLegalEntityRegNo}
                          </td>
                          <td className="opp-tableData">
                            {item.orgLegalEntityCode}
                          </td>
                          <td className="opp-tableData">{item.panNo}</td>
                          <td className="opp-tableData">{item.taxRegNo}</td>
                          <td className="opp-tableData">{item.gstNo}</td>
                          <td className="opp-tableData platform-legalEntity-date">
                            {item.startDate &&
                              DateFns.format(item.startDate, "DD-MM-YYYY")}
                          </td>

                          <td className="opp-tableData">{item.status}</td>
                          <td className="opp-tableData">{item.address}</td>
                          <td>
                            {typeof this.props.location.serviceAccessData !==
                              "undefined" &&
                              this.props.location.serviceAccessData
                                .updation && (
                                <span
                                  className="material-icons pointer opp-action-edit"
                                  style={{ color: "blue" }}
                                  onClick={() => this.toggleUpdateBtn(item)}
                                >
                                  edit
                                </span>
                              )}
                          </td>
                          <td>
                            {typeof this.props.location.serviceAccessData !==
                              "undefined" &&
                              this.props.location.serviceAccessData
                                .deletion && (
                                <i
                                  className="material-icons pointer"
                                  onClick={() =>
                                    this.setState({
                                      confirmDialog: {
                                        isOpen: true,
                                        title:
                                          "Are you sure to delete this record?",
                                        subTitle:
                                          "You can't undo this operation",
                                        onConfirm: () =>
                                          this.deleteOrganization(item.id),
                                      },
                                    })
                                  }
                                >
                                  delete
                                </i>
                              )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </React.Fragment>
          ) : null}
          {addBtn && <AddOrganization back={this.addBack} />}
          {updateBtn && (
            <UpdateOrganization back={this.updateBack} data={updateData} />
          )}
          {this.props.organization.message && (
            <Notifier message={this.props.organization.message} />
          )}
          {this.props.organization.error && (
            <ErrorNotifier message={this.props.organization.error} />
          )}
        </Container>
        <ConfirmDialog
          confirmDialog={this.state.confirmDialog}
          setConfirmDialog={this.setConfirmDialog}
        />
      </Box>
    );
  }
}

Organization.propTypes = {
  getOrganization: PropTypes.func.isRequired,
  deleteOrganization: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  organization: state.organization,
});

export default connect(mapStateToProps, {
  getOrganization,
  deleteOrganization,
})(Organization);
