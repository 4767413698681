import {
  LOGIN_DETAILS,
  IS_LOGGEDIN,
  SET_MESSAGE,
  GET_ERRORS,
} from "../../../actions/types";

const initialState = {
  data: {},
  isLoggedIn: false,
  message: null,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_DETAILS:
      return {
        ...state,
        data: action.payload,
      };
    case IS_LOGGEDIN:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case GET_ERRORS:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
