import axios from "axios";
import { REQUEST_STATUS_GET } from "../../types";
import {
  CURD_REQUEST_STATUS,
  USER_REQUEST_STATUS,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addRequestStatus = (data, back) => (dispatch) => {
  axios
    .post(CURD_REQUEST_STATUS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getRequestStatus));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getRequestStatus = () => (dispatch) => {
  axios
    .get(CURD_REQUEST_STATUS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(REQUEST_STATUS_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserRequestStatus = () => (dispatch) => {
  axios
    .get(USER_REQUEST_STATUS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(REQUEST_STATUS_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateRequestStatus = (data, back) => (dispatch) => {
  axios
    .put(CURD_REQUEST_STATUS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteRequestStatus = (data) => (dispatch) => {
  axios
    .delete(CURD_REQUEST_STATUS, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getRequestStatus));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
