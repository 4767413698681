import axios from "axios";
import { MANAGER_ATTENDANCE_REPORT_FETCH } from "../../types";
import {
  FETCH_MANAGER_DAY_ATTENDANCE_REPORT,
  FETCH_MANAGER_WEEK_ATTENDANCE_REPORT,
  FETCH_MANAGER_MONTH_ATTENDANCE_REPORT,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

// ---------------------------------------------------------------------

export const fetchManagerDayAttendanceReport =
  (user, setLoading) => (dispatch) => {
    console.log(user);
    axios
      .get(`${FETCH_MANAGER_DAY_ATTENDANCE_REPORT}/${user}`, {
        withCredentials: true,
      })
      .then((res) => {
        dispatch(getDispatch(MANAGER_ATTENDANCE_REPORT_FETCH, res));
        setLoading(false);
      })
      .catch((err) => {
        dispatch(setErrorMessage(err));
        setLoading(false);
      });
  };

export const fetchManagerWeekAttendanceReport =
  (data, setLoading) => (dispatch) => {
    axios
      .post(FETCH_MANAGER_WEEK_ATTENDANCE_REPORT, data, {
        withCredentials: true,
      })
      .then((res) => {
        dispatch(getDispatch(MANAGER_ATTENDANCE_REPORT_FETCH, res));
        setLoading(false);
      })
      .catch((err) => {
        dispatch(setErrorMessage(err));
        setLoading(false);
      });
  };

export const fetchManagerMonthAttendanceReport =
  (data, setLoading) => (dispatch) => {
    axios
      .post(FETCH_MANAGER_MONTH_ATTENDANCE_REPORT, data, {
        withCredentials: true,
      })
      .then((res) => {
        dispatch(getDispatch(MANAGER_ATTENDANCE_REPORT_FETCH, res));
        setLoading(false);
      })
      .catch((err) => {
        dispatch(setErrorMessage(err));
        setLoading(false);
      });
  };
