import React, { Component } from 'react'
import { Modal, Button } from "react-bootstrap";
import { Row, Col, Card } from "reactstrap";
import { connect } from "react-redux";
import axios from "axios";
import DateFns from "date-fns";
import { Divider } from "@material-ui/core";
import {getCandidateComments} from "../../../../redux/actions/rs/requirements/tagCandidate/tagCandidate"



// redux



// --------------------------------------------------------------------------------

// view assigned hr component



class ViewComments extends Component {
  constructor(props){
    super(props)
  this.state = {

  }
   

  }

componentDidMount(){
  this.props.getCandidateComments(this.props.canInfo);
 
}





// closeModelUpdate(){
//   this.setState({
//     edit:!this.state.edit,
//   })
//   this.props.getAssignHr(this.props.requirementId)
 
// }





  render() {
    console.log(this.props.tagReq)
    return (
      <div>
       <div>
              <i
                data-toggle="tooltip"
                data-placement="top"
                title="Close"
                onClick={this.props.back}
                className="material-icons pointer cancel-button"
              >
                cancel
              </i>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <Modal.Title style={{fontSize:"1.25rem",fontWeight:400}}>Tracking History for Candidate id: {this.props.canInfo}</Modal.Title>
            </div>

            <Modal.Body>
            <Row >
              <Col md="12" className="p-0">
                <ul className="crm-flex-around  ul-head">
                  <li
                    style={{
                      width: "8%",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    Req. Id
                  </li>
                  <li
                    style={{
                      width: "12%",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                     Tracking Id
                  </li>
                  <li
                    style={{
                      width: "20%",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                   Candidate Status
                  </li>
                  <li
                    style={{
                      width: "17%",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    {" "}
                 Hiring Stage
                  </li>

                  <li
                    style={{
                      width: "17%",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    {" "}
                  Hiring Status
                  </li>
                  <li
                    style={{
                      width: "20%",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    {" "}
                    Comments
                  </li>
              
               
                </ul>
              </Col>
            </Row>
            <Row >
              {this.props.tagReq.commentsData.length > 0 &&
                this.props.tagReq.commentsData.map((item) => {
                  return (
                    <Col md="12" className="p-0" key={item.resReqId}>
                      <ul
                        // onClick={() => handleViewClick(item.id)}
                        className="rs-flex-around"
                       
                      >
                    <li
                          style={{ overflow: "hidden", width: "8%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.jobRequirementId}
                        </li>

                        <li
                          style={{ overflow: "hidden", width: "12%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.candidateTrackingId}
                        </li>

                        <li
                          style={{ overflow: "hidden", width: "20%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.candidateStatus}
                        </li>
                        <li
                          style={{ overflow: "hidden", width: "17%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.hiringStage}
                        </li>
                        <li
                          style={{ overflow: "hidden", width: "17%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.hiringStageStatus}
                        </li>
                        <li
                          style={{ overflow: "hidden", width: "20%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.comment}
                        </li>
                      </ul>
                    </Col>
                  );
                })}
            </Row>
          
            </Modal.Body>
            
          </div>
        
    ) 
 }
}







const mapStateToProps = (state) => ({
  requirements: state.requirements,
  AssignedHrData:state.AssignedHrData,
  tagReq: state.tagReq,
});

export default connect(mapStateToProps, {  getCandidateComments })(
  ViewComments
);
