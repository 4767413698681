import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// components
import UpdateOtherAccountInformation from "./UpdateOtherAccountInformation";
// redux
import { getOtherAccountsInfo } from "../../../../../../redux/actions/hcm/my_profile/accounts_details/otherAccountsInfoAction";

// ---------------------------------------------------------------------------------------------------------------------------------
class OtherAccountInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateBtn: false,
    };
    this.openUpdateForm = this.openUpdateForm.bind(this);
    this.closeUpdateForm = this.closeUpdateForm.bind(this);
    this.getData = this.getData.bind(this);
  }

  openUpdateForm() {
    this.setState({
      updateBtn: true,
    });
  }

  closeUpdateForm() {
    this.setState({
      updateBtn: false,
    });
    this.getData();
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.props.getOtherAccountsInfo();
  }

  render() {
    const { updateBtn } = this.state;

    return (
      <React.Fragment>
        {!updateBtn ? (
          <React.Fragment>
            <i
              className="material-icons pointer contact-edit-btn"
              onClick={this.openUpdateForm}
            >
              edit
            </i>
            <h5 className="bold center">Other Account Details</h5>
            <ul>
              <li>
                <span className="label-sm bold contact-details-span">
                  PAN Card No.
                </span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  {this.props.otherAccountsInfo.data.length > 0
                    ? this.props.otherAccountsInfo.data[0].pan
                    : null}
                </span>
              </li>
              <li>
                <span className="label-sm bold contact-details-span">
                  ESI No.
                </span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  {this.props.otherAccountsInfo.data.length > 0
                    ? this.props.otherAccountsInfo.data[0].esiNumber
                    : null}
                </span>
              </li>
              <li>
                <span className="label-sm bold contact-details-span">
                  UAN No.
                </span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  {this.props.otherAccountsInfo.data.length > 0
                    ? this.props.otherAccountsInfo.data[0].uan
                    : null}
                </span>
              </li>
              <li>
                <span className="label-sm bold contact-details-span">
                  PF Account No.
                </span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  {this.props.otherAccountsInfo.data.length > 0
                    ? this.props.otherAccountsInfo.data[0].pfNo
                    : null}
                </span>
              </li>
            </ul>
          </React.Fragment>
        ) : null}
        {updateBtn && (
          <UpdateOtherAccountInformation
            back={this.closeUpdateForm}
            data={this.props.otherAccountsInfo.data}
          />
        )}
      </React.Fragment>
    );
  }
}

OtherAccountInformation.propTypes = {
  getOtherAccountsInfo: PropTypes.func.isRequired,
  otherAccountsInfo: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  otherAccountsInfo: state.otherAccountsInfo,
});

export default connect(mapStateToProps, { getOtherAccountsInfo })(
  OtherAccountInformation
);
