import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// components
import {
  Validator,
  submitValidator,
  errors,
  valid,
  refresh,
} from "../../../common/Validator";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
// redux
import { addEmployee } from "../../../../redux/actions/iam/emp_onboarding/empOnboardingAction";
import { getUserBusinessGeowise } from "../../../../redux/actions/hcm/business_setup/businessGeowiseAction";
import { getUserBusinessCountries } from "../../../../redux/actions/hcm/business_setup/businessCountriesAction";
import { getUserEmpPosition } from "../../../../redux/actions/hcm/configurations/empPositionAction";
import { getUserOrganization } from "../../../../redux/actions/hcm/business_setup/organizationAction";

// --------------------------------------------------------------------------------------------------------

class AddUser extends Component {
  constructor() {
    super();
    this.state = {
      empNo: "",
      firstName: "",
      lastName: "",
      dob: "",
      geography: "",
      country: "",
      mobile: "",
      personalEmail: "",
      email: "",
      empPosition: "",
      empStatus: "",
      organizationName: "",
      empStatusReason: "",
      role: "1",
      message: "",
      error: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    e.preventDefault();
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  componentDidMount() {
    refresh();
    this.props.getUserBusinessGeowise();
    this.props.getUserBusinessCountries();
    this.props.getUserEmpPosition();
    this.props.getUserOrganization();
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let User = {
      empNo: this.state.empNo,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      geoName: this.state.geography,
      countryName: this.state.country,
      mobileNumber: this.state.mobile,
      personalEmailId: this.state.personalEmail,
      organizationEmailId: this.state.email,
      empPosition: this.state.empPosition,
      empStatus: "Active",
      organizationName: this.state.organizationName,
    };

    if (valid) {
      this.props.addEmployee(User, this.props.history);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  render() {
    let shrink;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");

    return (
      <div className={shrink}>
        <Container className="card container-card">
          <h5 className="center bold">Add User</h5>
          <Link
            to={{
              pathname: "/pie/iam/user_onboarding",
              moduleName: this.props.location.moduleName,
              data: this.props.location.data,
              serviceAccessData: this.props.location.accessData,
            }}
          >
            <i
              className="material-icons pointer"
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                color: "#C00",
              }}
            >
              cancel
            </i>
          </Link>
          <Form onSubmit={this.onSubmit} className="p-3" noValidate>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label className="bold label-sm">Emp Number</Label>
                  <Input
                    type="text"
                    name="empNo"
                    className="form-control-sm"
                    value={this.state.empNo}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    placeholder="Emp Number (required)"
                    required={true}
                  />
                  {errors.empNo && (
                    <FormText className="error">{errors.empNo}</FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">First Name</Label>
                  <Input
                    type="text"
                    name="firstName"
                    className="form-control-sm"
                    value={this.state.firstName}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    placeholder="First Name (required)"
                    required={true}
                  />
                  {errors.firstName && (
                    <FormText className="error">{errors.firstName}</FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">Last Name</Label>
                  <Input
                    type="text"
                    name="lastName"
                    className="form-control-sm"
                    value={this.state.lastName}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    placeholder="Last name (required)"
                    required={true}
                  />
                  {errors.lastName && (
                    <FormText className="error">{errors.lastName}</FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">Geography</Label>
                  <Input
                    type="select"
                    name="geography"
                    className="form-control-sm"
                    value={this.state.geography}
                    onChange={this.onChange}
                    placeholder="Geography"
                    required={true}
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.businessGeowise.data.map((item) => {
                      return (
                        <option key={item.id} value={item.geoName}>
                          {item.geoName}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.geography && (
                    <FormText className="error">{errors.geography}</FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">Country</Label>
                  <Input
                    type="select"
                    name="country"
                    className="form-control-sm"
                    value={this.state.country}
                    onChange={this.onChange}
                    required={true}
                  >
                    <option hidden value="">
                      Select...
                    </option>
                    {this.props.businessCountries.data.length > 0 &&
                      this.props.businessCountries.data.map((item) => {
                        if (this.state.geography === item.geoName) {
                          return (
                            <option key={item.id} value={item.countryName}>
                              {item.countryName}
                            </option>
                          );
                        }
                      })}
                  </Input>
                  {errors.country && (
                    <FormText className="error">{errors.country}</FormText>
                  )}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label className="bold label-sm">Organization Name</Label>
                  <Input
                    type="select"
                    name="organizationName"
                    className="form-control-sm"
                    value={this.state.organizationName}
                    onChange={this.onChange}
                    required={true}
                  >
                    <option hidden value="">
                      Select...
                    </option>
                    {this.props.organization.data.length > 0 &&
                      this.props.organization.data.map((item) => {
                        return (
                          <option key={item.id} value={item.orgLegalEntityName}>
                            {item.orgLegalEntityName}
                          </option>
                        );
                      })}
                  </Input>
                  {errors.organizationName && (
                    <FormText className="error">
                      {errors.organizationName}
                    </FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">Emp Position</Label>
                  <Input
                    type="select"
                    name="empPosition"
                    className="form-control-sm"
                    value={this.state.empPosition}
                    onChange={this.onChange}
                    required={true}
                  >
                    <option hidden value="">
                      Select...
                    </option>
                    {this.props.empPosition.data.length > 0 &&
                      this.props.empPosition.data.map((item) => {
                        return (
                          <option key={item.id} value={item.positionName}>
                            {item.positionName}
                          </option>
                        );
                      })}
                  </Input>
                  {errors.empPosition && (
                    <FormText className="error">{errors.empPosition}</FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">Mobile No.</Label>
                  <Input
                    type="tel"
                    name="mobile"
                    className="form-control-sm"
                    value={this.state.mobile}
                    onChange={this.onChange}
                    placeholder="Mobile (required)"
                    pattern="[789][0-9]{9}"
                    required={true}
                  />
                  {errors.mobile && (
                    <FormText className="error">{errors.mobile}</FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">Personal Email Id</Label>
                  <Input
                    type="email"
                    name="personalEmail"
                    className="form-control-sm"
                    value={this.state.personalEmail}
                    onChange={this.onChange}
                    placeholder="personalEmail (required)"
                  />
                  {errors.personalEmail && (
                    <FormText className="error">
                      {errors.personalEmail}
                    </FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">Organization Email Id</Label>
                  <Input
                    type="email"
                    name="email"
                    className="form-control-sm"
                    value={this.state.email}
                    onChange={this.onChange}
                    placeholder="email"
                    required={true}
                  />
                  {errors.email && (
                    <FormText className="error">{errors.email}</FormText>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <div className="center">
              <button
                type="submit"
                className="btn mt-3 center login-button white-text"
              >
                Add
              </button>
            </div>
          </Form>
        </Container>
        {this.state.message ? <Notifier message={this.state.message} /> : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
        {this.props.employees.message ? (
          <Notifier message={this.props.employees.message} />
        ) : null}
        {this.props.employees.error ? (
          <ErrorNotifier message={this.props.employees.error} />
        ) : null}
      </div>
    );
  }
}

AddUser.propTypes = {
  addEmployee: PropTypes.func.isRequired,
  employees: PropTypes.object.isRequired,
  getUserBusinessGeowise: PropTypes.object.isRequired,
  businessGeowise: PropTypes.object.isRequired,
  getUserBusinessCountries: PropTypes.func.isRequired,
  businessCountries: PropTypes.object.isRequired,
  getUserEmpPosition: PropTypes.func.isRequired,
  empPosition: PropTypes.object.isRequired,
  getUserOrganization: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  employees: state.employees,
  businessGeowise: state.businessGeowise,
  businessCountries: state.businessCountries,
  empPosition: state.empPosition,
  organization: state.organization,
});

export default connect(mapStateToProps, {
  addEmployee,
  getUserBusinessGeowise,
  getUserBusinessCountries,
  getUserEmpPosition,
  getUserOrganization,
})(AddUser);
