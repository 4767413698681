import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
// components
import ContactDetails from "./ContactDetails";
import Hover from "../../../aside/Hover";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import SearchBar from "../../../common/searchbar/SearchBar";
import AddContact from "./AddContact";
import CustTooltip from "../../../common/tooltip/CustTooltip";
// redux
import {
  getContacts,
  updateContacts,
} from "../../../../redux/actions/crm/contacts/contactsAction";
import {
  getTagContacts,
  searchContacts,
} from "../../../../redux/actions/crm/contacts/tagContactsAction";

// -------------------------------------------------------------------------------------------
class Contacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      selection: null,
      selected: {},
      searchTerm: "",
      hover: false,
      cords: {},
      mouse: {},
      addBtn: false,
      currentPermission: {},
      message: "",
      error: "",
    };
    this.getData = this.getData.bind(this);
    this.select = this.select.bind(this);
    this.search = this.search.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.back = this.back.bind(this);
    this.toggleHoverOn = this.toggleHoverOn.bind(this);
    this.toggleHoverOff = this.toggleHoverOff.bind(this);
    this.toggleAddBtn = this.toggleAddBtn.bind(this);
    this.addBack = this.addBack.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.crmTagContacts !== this.props.crmTagContacts) {
      this.setState({
        data: this.props.crmTagContacts.data,
      });
    }
  }

  select(item) {
    this.setState({
      selection: item,
      selected: item.id,
      hover: false,
    });
  }

  search(e) {
    e.preventDefault();
    this.setState({
      searchTerm: e.target.value,
    });
    this.onSearch(e.target.value);
  }

  onSearch(value) {
    let data = {
      contactName: value,
    };

    this.props.searchContacts(data);
  }

  toggleHoverOn(e, item) {
    this.setState({
      hover: true,
      cords: {
        one: item.remarks,
      },
      mouse: {
        x: e.screenX,
        y: e.screenY,
      },
    });
  }

  toggleHoverOff() {
    this.setState({
      hover: false,
    });
  }

  back() {
    this.setState({
      selection: false,
    });
  }

  getData() {
    this.props.getContacts();
  }

  componentDidMount() {
    for (let a in this.props.auth.data.accesses) {
      if (this.props.auth.data.accesses[a].appName === "CRM") {
        for (let m in this.props.auth.data.accesses[a].pieUserModuleAccess) {
          if (
            this.props.auth.data.accesses[a].pieUserModuleAccess[m]
              .moduleName === "Contacts"
          ) {
            for (let s in this.props.auth.data.accesses[a].pieUserModuleAccess[
              m
            ].pieUserServiceAccess) {
              if (
                this.props.auth.data.accesses[a].pieUserModuleAccess[m]
                  .pieUserServiceAccess[s].serviceName === "Contact Management"
              ) {
                this.setState({
                  currentPermission:
                    this.props.auth.data.accesses[a].pieUserModuleAccess[m]
                      .pieUserServiceAccess[s],
                });
              }
            }
          }
        }
      }
    }
    // this.getData();
    this.props.getTagContacts();
  }

  /*
  toggleAddBtn function to toggle the addition page
  */
  toggleAddBtn() {
    this.setState({
      addBtn: true,
    });
  }

  /*
addBack function to back to the list page from the add page
*/
  addBack() {
    this.setState({
      addBtn: false,
    });

    this.getData();
  }

  render() {
    let shrink;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");
    const { selection, selected, data, message, error, addBtn } = this.state;

    return (
      <div className={shrink}>
        <Container className="card container-card">
          {/* {this.state.hover && (
            <Hover
              labels={{ one: "Remarks" }}
              cords={this.state.cords}
              mouse={this.state.mouse}
            />
          )} */}

          {!selection &&
            Object.keys(this.state.currentPermission).length > 0 &&
            this.state.currentPermission.creation && (
              <div className="dive">
                <Link to="/pie/crm/add/contact">
                  <CustTooltip title="Create Contact">
                    <button
                      className="btn new-btn white-text label-sm"
                      onClick={this.toggleAddBtn}
                    >
                      New
                    </button>
                  </CustTooltip>
                </Link>
              </div>
            )}
          {!selection && (
            <SearchBar
              onChange={this.search}
              value={this.state.searchTerm}
              placeholder="Search for Contacts"
              onSubmit={this.onSearch}
              title="Search Contact"
            />
          )}
          {!selection ? (
            <Row className="pt-3">
              <Col md="12" className="p-0">
                <ul className="crm-flex-around  ul-head">
                  <li className="list-label contId-width">Cont. Id</li>
                  <li className="list-label contName-width">Name</li>
                  <li className="list-label contEmail-width">Email</li>
                  <li className="list-label contMobile-width">Mobile No.</li>
                  <li className="list-label contCust-width">Customer</li>
                  <li className="list-label contEntity-width">Entity</li>
                  <li className="list-label contAcc-width">Account</li>
                  <li className="list-label contStatus-width">Status</li>
                </ul>
              </Col>
            </Row>
          ) : null}
          {!selection && !addBtn ? (
            <Row onMouseMove={this.onMouseMove}>
              {data &&
                data
                  // .sort((a, b) => a.contactName.toUpperCase() > b.contactName.toUpperCase() ? 1 : -1)
                  .map((item) => {
                    return (
                      <Col md="12" key={item.id} className="p-0">
                        <ul
                          onClick={() => {
                            this.select(item);
                          }}
                          onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                          onMouseLeave={this.toggleHoverOff}
                          className={` list crm-flex-around pointer ${
                            selected && selected === item.id && "selected-item"
                          } InqList`}
                        >
                          <li className="list-data contId-width">{item.id}</li>
                          <li className="list-data contName-width">
                            {item.contactName}
                          </li>
                          <li className="list-data contEmail-width">
                            {item.contactEmail}
                          </li>
                          <li className="list-data contMobile-width">
                            {`${item.contactDialingCode}-${item.contactMobileNo}`}
                          </li>
                          <li className="list-data contCust-width">
                            {item.customerName}
                          </li>
                          <li className="list-data contEntity-width">
                            {item.entityName}
                          </li>
                          <li className="list-data contAcc-width">
                            {item.accountName}
                          </li>
                          <li className="list-data contStatus-width">
                            {item.contactStatus}
                          </li>
                        </ul>
                      </Col>
                    );
                  })}
            </Row>
          ) : null}
          {selection && (
            <ContactDetails
              contacts={this.state.selection}
              back={this.back}
              permission={this.state.currentPermission}
            />
          )}
          {addBtn && <AddContact back={this.addBack} />}
          {this.props.crmContacts.message && (
            <Notifier message={this.props.crmContacts.message} />
          )}
          {this.props.crmContacts.error && (
            <ErrorNotifier message={this.props.crmContacts.error} />
          )}
        </Container>
      </div>
    );
  }
}

Contacts.propTypes = {
  auth: PropTypes.object.isRequired,
  getContacts: PropTypes.func.isRequired,
  updateContacts: PropTypes.func.isRequired,
  searchContacts: PropTypes.func.isRequired,
  crmContacts: PropTypes.object.isRequired,
  getTagContacts: PropTypes.func.isRequired,
  crmTagContacts: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  crmContacts: state.crmContacts,
  crmTagContacts: state.crmTagContacts,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getContacts,
  updateContacts,
  searchContacts,
  getTagContacts,
})(Contacts);
