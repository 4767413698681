import React, { Component } from "react";
import { Form, Row, Col, FormGroup, Label, Input, FormText } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// components
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  PercentageValidation,
  errors,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
// redux
import { addEnterpriseDetail } from "../../../../../redux/actions/hcm/business_setup/enterpriseDetailAction";
import { getUserBusinessGeowise } from "../../../../../redux/actions/hcm/business_setup/businessGeowiseAction";
import { getUserBusinessCountries } from "../../../../../redux/actions/hcm/business_setup/businessCountriesAction";

// -----------------------------------------------------------------------------------------------------------------

class AddEnterpriseDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enterpriseName: "",
      domainName: "",
      geoName: "",
      country: "",
      email: "",
      contactNo: null,
      description: "",
      logo: "",
      message: "",
      error: "",
      fileError: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    PercentageValidation(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleFile = (e) => {
    Validator(e.target);
    let allowedExtension = /(\.png)$/i;
    if (allowedExtension.exec(e.target.files[0].name)) {
      if ((e.target.files[0].size / 1024 / 1024).toFixed(2) < 2) {
        this.setState({
          logo: e.target.files[0] || [],
          fileError: "",
        });
      } else {
        this.setState({
          logo: e.target.files[0],
          fileError: "File size must less than 2MB",
        });
      }
    } else {
      this.setState({
        logo: e.target.files[0],
        fileError: "File type not allowed",
      });
    }
  };

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let fd = new FormData();
    fd.append("enterpriseName", this.state.enterpriseName);
    fd.append("domainName", this.state.domainName);
    fd.append("geoName", this.state.geoName);
    fd.append("country", this.state.country);
    fd.append("email", this.state.email);
    this.state.contactNo ? fd.append("contactNo", this.state.contactNo) : null;
    fd.append("description", this.state.description);
    fd.append("logo", this.state.logo);

    if (valid) {
      this.props.addEnterpriseDetail(fd, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidMount contains the error refresh method and setState method for calling the render method
*/
  componentDidMount() {
    refresh();
    this.setState({
      message: "",
      error: "",
    });
    this.props.getUserBusinessGeowise();
    this.props.getUserBusinessCountries();
  }

  render() {
    const {
      enterpriseName,
      domainName,
      geoName,
      country,
      email,
      contactNo,
      logo,
      description,
      error,
      message,
    } = this.state;

    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Add Enterprise Detail</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="6">
              <FormComponent
                labelClass="bold label-sm"
                label="Enterprise Name"
                type="text"
                name="enterpriseName"
                inputClass="form-control-sm"
                value={enterpriseName}
                change={this.onChange}
                required={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Domain Name"
                type="text"
                name="domainName"
                inputClass="form-control-sm"
                value={domainName}
                change={this.onChange}
                required={true}
              />
              <FormGroup>
                <Label className="bold label-sm">Geography</Label>
                <Input
                  type="select"
                  name="geoName"
                  className="form-control-sm"
                  value={geoName}
                  onChange={this.onChange}
                  required={true}
                >
                  <option hidden value="">
                    Select...
                  </option>
                  {this.props.businessGeowise.data.length > 0 &&
                    this.props.businessGeowise.data.map((item) => {
                      return (
                        <option key={item.id} value={item.geoName}>
                          {item.geoName}
                        </option>
                      );
                    })}
                </Input>
                {errors.geoName && (
                  <FormText className="error">{errors.geoName}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="bold label-sm">Country</Label>
                <Input
                  type="select"
                  name="country"
                  className="form-control-sm"
                  value={country}
                  onChange={this.onChange}
                  required={true}
                >
                  <option hidden value="">
                    Select...
                  </option>
                  {this.props.businessCountries.data.length > 0 &&
                    this.props.businessCountries.data.map((item) => {
                      if (this.state.geoName === item.geoName) {
                        return (
                          <option key={item.countryId} value={item.countryName}>
                            {item.countryName}
                          </option>
                        );
                      }
                    })}
                </Input>
                {errors.country && (
                  <FormText className="error">{errors.country}</FormText>
                )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormComponent
                labelClass="bold label-sm"
                label="Email"
                type="email"
                name="email"
                inputClass="form-control-sm"
                value={email}
                change={this.onChange}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Contact No"
                type="tel"
                name="contactNo"
                inputClass="form-control-sm"
                value={contactNo}
                change={this.onChange}
              />
              <div className="file-div ">
                <FormGroup className="file-display mr-1">
                  <Label className="bold label-sm">Logo</Label>
                  <Input
                    disabled
                    type="text"
                    value={logo.name || ""}
                    className="form-control-sm "
                    required={true}
                  />
                  {this.state.fileError && (
                    <FormText className="error ">
                      {this.state.fileError}
                    </FormText>
                  )}
                  {errors.logo && (
                    <FormText className="error">{errors.logo}</FormText>
                  )}
                </FormGroup>
                <FormGroup className="file-browse-btn-div">
                  <label className="btn label-sm file-browse-btn">
                    Browse
                    <Input
                      type="file"
                      name="logo"
                      style={{
                        display: "none",
                        marginTop: "23px",
                        marginLeft: "-28px",
                      }}
                      className="form-control-sm file-input"
                      onChange={this.handleFile}
                      required={true}
                      accept=".png"
                    />
                  </label>
                </FormGroup>
              </div>
              <FormComponent
                labelClass="bold label-sm"
                label="Description"
                type="textarea"
                name="description"
                inputClass="form-control-sm"
                value={description}
                change={this.onChange}
              />
            </Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Add
            </button>
          </div>
        </Form>
        {this.props.enterpriseDetail.message && (
          <Notifier message={this.props.enterpriseDetail.message} />
        )}
        {this.props.enterpriseDetail.error && (
          <ErrorNotifier message={this.props.enterpriseDetail.error} />
        )}
      </React.Fragment>
    );
  }
}

AddEnterpriseDetail.propTypes = {
  addEnterpriseDetail: PropTypes.func.isRequired,
  enterpriseDetail: PropTypes.object.isRequired,
  getUserBusinessGeowise: PropTypes.func.isRequired,
  businessGeowise: PropTypes.object.isRequired,
  getUserBusinessCountries: PropTypes.func.isRequired,
  businessCountries: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  enterpriseDetail: state.enterpriseDetail,
  businessGeowise: state.businessGeowise,
  businessCountries: state.businessCountries,
});

export default connect(mapStateToProps, {
  addEnterpriseDetail,
  getUserBusinessGeowise,
  getUserBusinessCountries,
})(AddEnterpriseDetail);
