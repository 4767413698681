import React, { Component } from "react";
import { Container, Table, Modal, ModalHeader, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DateFns from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getPayslips,
  downloadPayslip,
  viewPayslip,
} from "../../../../../redux/actions/finance/payslip/payslipAction";
import fileView from "../../../../../assets/img/fileView.png";
import fileDownload from "../../../../../assets/img/fileDownload.png";
import ResumeViewer from "../../../../common/ResumeViewer";

class MyPayslip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: "",
      year: new Date(),
      view: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  onChangeYear = (date) => {
    this.setState({
      year: date,
    });
  };

  getData = () => {
    let data = {
      empId: localStorage.getItem("id"),
      currentYear: DateFns.format(this.state.year, "YYYY"),
    };
    this.props.getPayslips(data);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.year !== this.state.year) {
      this.getData();
    }
  }

  downloadPayslip = (item, fileName) => {
    let data = {
      currentYear: item.currentYear,
      month: item.month,
      empId: item.empId,
    };

    this.props.downloadPayslip(data, fileName);
  };

  viewPayslip = (item, fileName) => {
    let data = {
      currentYear: item.currentYear,
      month: item.month,
      empId: item.empId,
    };

    this.props.viewPayslip(data);
    this.setState({
      fileName: fileName,
      view: true,
    });
  };

  toggleView = () => {
    this.setState({
      view: false,
    });
  };

  render() {
    const { view, fileName, year } = this.state;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-8 mx-auto">
            <Container className="card container-card cust-container">
              <i
                className="material-icons pointer cancel-button"
                onClick={this.props.back}
              >
                cancel
              </i>
              <h5 className="bold center pt-2 pb-3">Payslips</h5>
              <Table className="center">
                <thead>
                  <tr>
                    <th className="opp-head ">ID</th>
                    <th className="mypayslips-year-th">
                      <div className="opp-head mypayslip-datepicker-year">
                        Year
                        <DatePicker
                          showYearPicker
                          dateFormat="yyyy"
                          selected={year}
                          onChange={this.onChangeYear}
                          maxDate={new Date()}
                        />
                      </div>
                    </th>
                    <th className="opp-head center">
                      Month{" "}
                      {/* <DatePicker
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        // selected={month}
                        // onChange={this.onChangeMonth}
                        maxDate={DateFns.subMonths(new Date(), 1)}
                      /> */}
                    </th>
                    <th className="opp-head center">View</th>
                    <th className="opp-head center">Download</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.payslips.data &&
                    this.props.payslips.data.map((item) => {
                      return (
                        <tr>
                          <td className="opp-tableData">
                            {item.payslipUploadId}
                          </td>
                          <td className="opp-tableData">
                            {DateFns.format(item.currentYear, "YYYY")}
                          </td>
                          <td className="opp-tableData center">{item.month}</td>
                          <td className="center">
                            <img
                              src={fileView}
                              title="View"
                              className="payslip-download-img"
                              onClick={() =>
                                this.viewPayslip(item, item.fileName)
                              }
                            />
                          </td>
                          <td className="center">
                            <img
                              src={fileDownload}
                              className="payslip-download-img"
                              onClick={() =>
                                this.downloadPayslip(item, item.fileName)
                              }
                              title="Download"
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Container>
            <Modal isOpen={view} size="lg">
              <ModalHeader toggle={this.toggleView}>{fileName}</ModalHeader>
              <ModalBody>
                <ResumeViewer URL={this.props.payslips.url} />
              </ModalBody>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

MyPayslip.propTypes = {
  getPayslips: PropTypes.func.isRequired,
  payslips: PropTypes.object.isRequired,
  downloadPayslip: PropTypes.func.isRequired,
  viewPayslip: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  payslips: state.payslips,
});

export default connect(mapStateToProps, {
  getPayslips,
  downloadPayslip,
  viewPayslip,
})(MyPayslip);
