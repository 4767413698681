import axios from "axios";
import { GET_NOTICE_PERIODRS,GET_NOTICE_MAIN } from "../../types";
import {
  ADD_NOTICE_PERIOD_RS,
  GET_NOTICE_PERIOD_RS,
  GET_NOTICE_PERIOD_MAIN_API,
  UPDATE_NOTICE_PERIOD_RS,
  DELETE_NOTICE_PERIOD_RS
} from "../../../../utils/routes";

import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addNoticePeriod = (data, back) => (dispatch) => {
  axios
    .post( ADD_NOTICE_PERIOD_RS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getNoticePeriod));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getNoticePeriod = () => (dispatch) => {
  axios
    .get( GET_NOTICE_PERIOD_RS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch( GET_NOTICE_PERIODRS, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getNoticePeriodSelect = () => (dispatch) => {
  axios
    .get(  GET_NOTICE_PERIOD_MAIN_API, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(GET_NOTICE_MAIN, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateNoticePeriod = (data, back) => (dispatch) => {
  axios
    .put(  UPDATE_NOTICE_PERIOD_RS , data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteNoticePeriod = (data) => (dispatch) => {
  axios
    .delete( DELETE_NOTICE_PERIOD_RS, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getNoticePeriod));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
