import axios from 'axios';
import {
    EMPLOYEE_GRADE_GET,
    GET_ERRORS
} from './types';
import {
    GET_EMPLOYEE_GRADE
} from '../../utils/routes';

export const getEmployeeGrade = () => dispatch => {
    axios.get(GET_EMPLOYEE_GRADE, {withCredentials: true})
    .then(res => {
        dispatch({
            type: EMPLOYEE_GRADE_GET,
            payload: res.data
        })
    })
    .catch(err => {
        dispatch({
            type: GET_ERRORS,
            payload: err.message
        })
    })
}