import axios from "axios";
import { MANAGER_RESIGNATION_REASON_GET } from "../../types";
import {
  CURD_MANAGER_RESIGNATION_REASON,
  USER_MANAGER_RESIGNATION_REASON,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addManagerResignationReason = (data, back) => (dispatch) => {
  axios
    .post(CURD_MANAGER_RESIGNATION_REASON, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getManagerResignationReason));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getManagerResignationReason = () => (dispatch) => {
  axios
    .get(CURD_MANAGER_RESIGNATION_REASON, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(MANAGER_RESIGNATION_REASON_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserManagerResignationReason = () => (dispatch) => {
  axios
    .get(USER_MANAGER_RESIGNATION_REASON, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(MANAGER_RESIGNATION_REASON_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateManagerResignationReason = (data, back) => (dispatch) => {
  axios
    .put(CURD_MANAGER_RESIGNATION_REASON, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getManagerResignationReason));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteManagerResignationReason = (data) => (dispatch) => {
  axios
    .delete(CURD_MANAGER_RESIGNATION_REASON, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getManagerResignationReason));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
