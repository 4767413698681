import React, { Component } from "react";
import axios from "axios";
import { Row, Col, Form, Card } from "reactstrap";
import { RESET_PASSWORD } from "../../../utils/routes";
import { Validator, submitValidator, valid } from "../../common/Validator";
import FormComponent from "../../common/FormComponent";
import Notifier from "../../aside/Notifier";
import ErrorNotifier from "../../aside/ErrorNotifier";

class ForgetpassChange extends Component {
  constructor() {
    super();
    this.state = {
      ssdic: "",
      sstic: "",
      newPassword: "",
      newPassword2: "",
      message: null,
      error: null,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    e.preventDefault();
    Validator(e.target);
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    if (valid) {
      if (this.state.newPassword === this.state.newPassword2) {
        const data = {
          ssdic: this.state.ssdic,
          sstic: this.state.sstic,
          password: this.state.newPassword,
        };
        axios
          .post(RESET_PASSWORD, data)
          .then((res) => {
            if (res.data.message === "Time Expire Try Again") {
              this.setState({
                error: res.data.message,
              });
            } else {
              this.setState({ message: res.data.message });
            }
            setTimeout(() => {
              this.setState({
                message: "",
                error: "",
              });
              this.props.history.push("/");
            }, 2000);
          })
          .catch((err) => {
            this.setState({
              error: "Could not update password",
            });
            setTimeout(() => {
              this.setState({
                error: null,
              });
            }, 5000);
          });
      } else {
        this.setState({
          error: "Password do not match",
        });
        setTimeout(() => {
          this.setState({
            error: null,
          });
        }, 5000);
      }
    } else {
      this.setState({
        error: "Please enter the required details",
      });
      setTimeout(() => {
        this.setState({
          error: null,
        });
      }, 2000);
    }
  }

  componentDidMount() {
    let url = window.location.href;
    // const exp = /[^?]+$/ ;

    let exp = url.split("?", 2);
    console.log(exp[0]);
    console.log(exp[1]);
    let finalExpfromurl = exp[1];
    this.setState({
      token: finalExpfromurl,
    });
    // console.log(data);

    const queryParams = new URLSearchParams(window.location.search);

    this.setState({
      ssdic: queryParams.get("ssdic"),
      sstic: queryParams.get("sstic"),
    });
  }

  render() {
    let shrink;

    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");
    return (
      <div className={shrink}>
        {this.state.message ? <Notifier message={this.state.message} /> : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
        <div>
          <Card className="forgot-password-card center">
            <div className="center login-icon-wrap"></div>
            <h4 className="center blue-text">Create a New Password</h4>
            <Row>
              <Col md="2"></Col>
              <Col md="8">
                <Form className="p-3" onSubmit={this.onSubmit} noValidate>
                  <FormComponent
                    placeholder="New Password"
                    type="password"
                    name="newPassword"
                    value={this.state.newPassword}
                    change={this.onChange}
                    required={true}
                  />
                  <FormComponent
                    placeholder="Confirm Password"
                    type="password"
                    name="newPassword2"
                    value={this.state.newPassword2}
                    change={this.onChange}
                    required={true}
                  />
                  <div className="center">
                    <button className="btn login-button white-text">
                      Create Password
                    </button>
                  </div>
                </Form>
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    );
  }
}
export default ForgetpassChange;
