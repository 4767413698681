import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DateFns from "date-fns";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
// redux
import { fetchRequestConversation } from "../../../../../redux/actions/experience_360/requestConversationAction";
import { E360_API } from "../../../../../utils/config";
// material ui
import Avatar from "@material-ui/core/Avatar";
import { deepOrange, deepPurple } from "@material-ui/core/colors";
import ViewDocument from "./ViewDocument";

// -----------------------------------------------------------

class RequestFeed extends Component {
  componentDidMount() {
    this.props.fetchRequestConversation(this.props.requestId);
  }

  render() {
    return (
      <SimpleBar style={{ maxHeight: 250 }}>
        <div>
          {this.props.requestConversation.fetchData.length > 0 &&
            this.props.requestConversation.fetchData.map((item) => {
              return (
                <div>
                  <Avatar
                    style={{
                      backgroundColor: "deepPurple[500]",
                      color: "white",
                    }}
                  >
                    U
                  </Avatar>
                  <strong>{item.createdBy}</strong>
                  <br />
                  <small>{DateFns.format(item.createdOn, "DD-MM-YYYY")}</small>
                  <div className="pl-3">
                    <p>{item.reqComment}</p>
                    {/(\.pdf)$/i.exec(
                      item.attachmentPath && item.attachmentPath
                    ) ? (
                      <ViewDocument
                        documentName={
                          item.attachmentPath && item.attachmentPath
                        }
                        documentPath={`${E360_API}/files/${
                          item.attachmentPath && item.attachmentPath
                        }`}
                        size="md"
                      />
                    ) : (
                      item.attachmentPath && (
                        <>
                          <ViewDocument
                            documentName={
                              item.attachmentPath && item.attachmentPath
                            }
                            documentPath={`${E360_API}/files/${
                              item.attachmentPath && item.attachmentPath
                            }`}
                            size="md"
                          />
                        </>
                      )
                    )}
                  </div>
                  <hr />
                </div>
              );
            })}
        </div>
      </SimpleBar>
    );
  }
}

RequestFeed.propTypes = {
  fetchRequestConversation: PropTypes.func.isRequired,
  requestConverSation: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  requestConversation: state.requestConversation,
});

export default connect(mapStateToProps, { fetchRequestConversation })(
  RequestFeed
);
