import React, { Component } from "react";
import { PieChart, Pie, Sector, Cell } from "recharts";
import { Card } from "reactstrap";
import axios from "axios";
import { GET_LEAD_GENERATED_CAMPTYPE } from "../../../../utils/routes";

// const data = [
//   { name: "Group A", value: 10 },
//   { name: "Group B", value: 0 },
//   { name: "Group C", value: 0 },
//   { name: "Group D", value: 0 },
//   { name: "Group E", value: 0 },
//   { name: "Group F", value: 0 },
//   { name: "Group G", value: 0 },
// ];

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#32a834",
  "#9a32a8",
  "#3234a8",
];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.7;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  if (percent !== 0) {
    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor="middle"
        dominantBaseline="middle"
        style={{ fontSize: "10px" }}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  }
};

export default class LeadGeneratedVsCampType extends Component {
  static jsfiddleUrl = "https://jsfiddle.net/alidingling/c9pL8k61/";

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      error: "",
    };
  }

  componentDidMount() {
    axios
      .get(GET_LEAD_GENERATED_CAMPTYPE, { withCredentials: true })
      .then((res) => {
        this.setState({
          data: res.data.rows,
        });
      })
      .catch((err) => {
        this.setState({
          error: err,
        });
      });
  }

  render() {
    const { data } = this.state;

    return (
      <Card className="recharts-card">
        <PieChart width={150} height={150}>
          <Pie
            data={data}
            cx={60}
            cy={60}
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={55}
            innerRadius={10}
            fill="#8884d8"
            dataKey="value"
            startAngle={360}
            endAngle={0}
            minAngle={20}
          >
            {data.length > 0 && data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
        <div className="reCharts-labels-div">
          <ul>
            {data.length > 0 && data.map((item) => {
              // if (item.name === "Organic") {
              return (
                <li className="reCharts-list">
                  <span
                    id="marketingChart1-one-list"
                    style={{ backgroundColor: item.color }}
                  ></span>
                  <span className="recharts-label-sm bold marketingChart1-li-span">
                    {item.leadSource}
                  </span>
                  <span className="recharts-label-sm ">
                    &nbsp;&nbsp;:&nbsp;&nbsp;{item.value}
                  </span>
                </li>
              );
              // }
              // if (item.name === "Direct") {
              //   return (
              //     <li className="reCharts-list">
              //       <span id="marketingChart1-two-list"></span>
              //       <span className="label-sm bold marketingChart1-li-span">
              //         {item.name}
              //       </span>
              //       <span className="label-sm ">
              //         &nbsp;&nbsp;:&nbsp;&nbsp;{item.value}
              //       </span>
              //     </li>
              //   );
              // }
              // if (item.name === "Social Media") {
              //   return (
              //     <li className="reCharts-list">
              //       <span id="marketingChart1-three-list"></span>
              //       <span className="label-sm bold marketingChart1-li-span">
              //         {item.name}
              //       </span>
              //       <span className="label-sm ">
              //         &nbsp;&nbsp;:&nbsp;&nbsp;{item.value}
              //       </span>
              //     </li>
              //   );
              // }
              // if (item.name === "Digital Marketing") {
              //   return (
              //     <li className="reCharts-list">
              //       <span id="marketingChart1-four-list"></span>
              //       <span className="label-sm bold marketingChart1-li-span">
              //         {item.name}
              //       </span>
              //       <span className="label-sm ">
              //         &nbsp;&nbsp;:&nbsp;&nbsp;{item.value}
              //       </span>
              //     </li>
              //   );
              // }
              // if (item.name === "Webinar") {
              //   return (
              //     <li className="reCharts-list">
              //       <span id="marketingChart1-five-list"></span>
              //       <span className="label-sm bold marketingChart1-li-span">
              //         {item.name}
              //       </span>
              //       <span className="label-sm ">
              //         &nbsp;&nbsp;:&nbsp;&nbsp;{item.value}
              //       </span>
              //     </li>
              //   );
              // }
              // if (item.name === "Emails") {
              //   return (
              //     <li className="reCharts-list">
              //       <span id="marketingChart1-six-list"></span>
              //       <span className="label-sm bold marketingChart1-li-span">
              //         {item.name}
              //       </span>
              //       <span className="label-sm ">
              //         &nbsp;&nbsp;:&nbsp;&nbsp;{item.value}
              //       </span>
              //     </li>
              //   );
              // }
              // if (item.name === "Phone") {
              //   return (
              //     <li className="reCharts-list">
              //       <span id="marketingChart1-seven-list"></span>
              //       <span className="label-sm bold marketingChart1-li-span">
              //         {item.name}
              //       </span>
              //       <span className="label-sm ">
              //         &nbsp;&nbsp;:&nbsp;&nbsp;{item.value}
              //       </span>
              //     </li>
              //   );
              // }
            })}
          </ul>
        </div>
      </Card>
    );
  }
}
