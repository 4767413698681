import axios from "axios";
import {
  CRM_TAG_CONTACTS_GET,
  CRM_TAG_CONTACTS_FETCH,
  CRM_TAG_CONTACTS_SEARCH,
  CRM_CONTACTS_SEARCH,
} from "../../types";
import {
  CURD_CRM_TAG_CONTACTS,
  SEARCH_CRM_TAG_CONTACTS,
  SEARCH_CRM_CONTACTS,
  UPDATE_CRM_COMMON_TAG_CONTACTS,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addTagContacts = (data, back) => (dispatch) => {
  axios
    .post(CURD_CRM_TAG_CONTACTS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getTagContacts));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const searchTagContacts = (data) => (dispatch) => {
  axios
    .post(SEARCH_CRM_TAG_CONTACTS, data, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CRM_TAG_CONTACTS_SEARCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const searchContacts = (data) => (dispatch) => {
  axios
    .post(SEARCH_CRM_CONTACTS, data, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CRM_CONTACTS_SEARCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getTagContacts = () => (dispatch) => {
  axios
    .get(CURD_CRM_TAG_CONTACTS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CRM_TAG_CONTACTS_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const fetchTagContacts = (data) => (dispatch) => {
  axios
    .get(`${CURD_CRM_TAG_CONTACTS}/${data}`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CRM_TAG_CONTACTS_FETCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateTagContacts = (data, back) => (dispatch) => {
  axios
    .put(CURD_CRM_TAG_CONTACTS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getTagContacts));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateCommonTagContacts = (data, back) => (dispatch) => {
  axios
    .put(UPDATE_CRM_COMMON_TAG_CONTACTS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getTagContacts));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteTagContacts = (data) => (dispatch) => {
  axios
    .delete(CURD_CRM_TAG_CONTACTS, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getTagContacts));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
