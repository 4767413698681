import { SET_MESSAGE, GET_ERRORS } from "../types";

const setSuccessMessage =
  (
    message,
    back,
    getDetails,
    history,
    link,
    getDetailsTwo,
    getDetailsThree,
    location
  ) =>
  (dispatch) => {
    if (message.data.ok) {
      dispatch({
        type: SET_MESSAGE,
        payload: message.data.message,
      });
      setTimeout(() => {
        dispatch({
          type: SET_MESSAGE,
          payload: null,
        });
        back && back();
        getDetails && dispatch(getDetails());
        history && history.push(link);
        getDetailsTwo && dispatch(getDetailsTwo());
        getDetailsThree && dispatch(getDetailsThree());
        location && window.location.assign(location);
      }, 3000);
    } else if (message.data.error) {
     
      dispatch({
        type: GET_ERRORS,
        payload: message.data.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 3000);
    }
   
  };

export default setSuccessMessage;
