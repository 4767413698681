import React, { Component } from 'react'
import axios from 'axios'
import Notifier from '../../../aside/Notifier';
import {Modal,Button} from 'react-bootstrap'
import { TA_EMP_DETAIL, ADD_HR_ASSIGNED_HR, GET_HR_LEAD_RS,JOB_BOARD_NAME_API } from "../../../../utils/routes";

import { Form, Row, Col,FormGroup,Label,FormText,Input } from "reactstrap";
import Select from 'react-select'
import FormComponent from "../../../common/FormComponent";
import {getJobBoard} from '../../../../redux/actions/rs/configuration/jobBoardAction'
import{
  Validator,
  submitValidator,
  valid,
  refresh,
} from "../../../common/Validator";
import { connect } from 'react-redux';
import ErrorNotifier from '../../../aside/ErrorNotifier';


let validError=false
// assigned Hr component
 class AddJobBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobBoard: "",
      startDate:"",
      endDate:"",
      remarks:"",
      status:"",
      getHrData:[],
      roleOptionError:null,
      dateError:"",
      crucialArr: [
        { key: "Primary Lead", value: "Primary Lead" },
        { key: "Secondary Lead ", value: "Secondary Lead" },
        { key: "Recruiter", value: "Recruiter" },
      ],
      error:"",
      message:"",
      boardErrorMessage :"",
      startErrorMessage:"",
      endErrorMessage:"",
      statusErrorMessage:""
     
    };
 
    this.onSubmit = this.onSubmit.bind(this);
  
    this.onChange = this.onChange.bind(this)

    this.getDataPrimSec = this.getDataPrimSec.bind(this)
  }

  // onSumbit function trigering
  onSubmit(e) {
    console.log("working")
    e.preventDefault();
    submitValidator(e.target);
    if (!this.state.jobBoard) {
      this.setState({
        boardErrorMessage: "This field is mandatory",
      });
      validError = true;
    } else {
      this.setState({
        boardErrorMessage: "",
      });
      validError = false;
    }

    if (!this.state.startDate) {
      this.setState({
        startErrorMessage: "This field is mandatory",
      });
      validError = true;
    } else {
      this.setState({
        startErrorMessage: "",
      });
      validError = false;
    }
    
    if (!this.state.endDate) {
      this.setState({
        endErrorMessage: "This field is mandatory",
      });
      validError = true;
    }
    else {
      this.setState({
        ENDErrorMessage: "",
      });
      validError = false;
    }
    if (!this.state.status) {
      this.setState({
        statusErrorMessage: "This field is mandatory",
      });
      validError = true;
    } else {
      this.setState({
        statusErrorMessage: "",
      });
      validError = false;
    }

    let data = {
    publishedJobId:this.props.jobId,
    jobBoardName:this.state.jobBoard,
    fromDate: this.state.startDate,
    toDate: this.state.endDate,
    status:this.state.status,
    notes:this.state.notes
    };
    if (!validError && !this.state.dateError) {
      axios
        .post(`${JOB_BOARD_NAME_API}`, data, {
          withCredentials: true,
        })
        .then((res) => {
          console.log(res);
          if (res.data.ok) {
            this.setState({
              message: "Successfully created",
            });
         
          }
          setTimeout(() => {
            this.setState({
              message: "",
            });
            this.props.handleCloseModal()
          }, 2000);
        })
        .catch((err) => {
          if (err.response) {
            console.log(err.response);
            this.setState({
              error: err.response.data.message,
            });
            setTimeout(() => {
              this.setState({
                error: "",
              });
            
            }, 2000);
          }
        });
      
    } else {
      this.setState({
        error: "Please enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }
  componentDidMount() {
    this.props.getJobBoard()
  refresh();
    this.setState({
      error: "",
    });
  }

 onChange(e){
   if(e.target.name =="jobBoard"){
    this.setState({
      [e.target.name]:e.target.value,
      boardErrorMessage:""
    })
   }
   if(e.target.name =="startDate"){
    this.setState({
      [e.target.name]:e.target.value,
      startErrorMessage:""
    })
   }
   if(e.target.name =="endDate"){
    if(Date.parse(e.target.value) < Date.parse(this.state.startDate)){
    this.setState({
      [e.target.name]:e.target.value,
      dateError:"End Date must be greater than or equal to Start Date",
      endErrorMessage:""
    })
    }
    else {
      this.setState({
        [e.target.name]:e.target.value,
        endErrorMessage:"",
        dateError:""
      })
    }
 
   }
   if(e.target.name =="status"){
    this.setState({
      [e.target.name]:e.target.value,
      statusErrorMessage:""
    })
   }
   if(e.target.name =="notes"){
    this.setState({
      [e.target.name]:e.target.value,
     
    })
   }
 
  }


 

  // geting Hrdata from employee

 

  getData() {
    axios
      .get(`${TA_EMP_DETAIL}`, { withCredentials: true })
      .then((res) => {
        console.log(res);
        this.setState({
          getHrData: res.data,
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  getDataPrimSec(){
    axios
    .get(`${GET_HR_LEAD_RS}/select`, { withCredentials: true })
    .then((res) => {
      console.log(res);
      this.setState({
        getHrData: res.data,
      });
    })
    .catch((err) => {
      console.log("err", err);
    });
  }
  render() {
   return (
      <div>
        <Modal
          size="lg"
          show={this.props.showPopUp}
          onHide={this.props.handleCloseModal}
        >
          <i
            data-toggle="tooltip"
            data-placement="top"
            title="Close"
            onClick={this.props.handleCloseModal}
            className="material-icons pointer cancel-button"
          >
            cancel
          </i>

          {/* <Form onSubmit={this.handleSubmitData} className="p-3 mt-2" noValidate> */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "1rem",
            }}
          >
            <Modal.Title>Add Job Board</Modal.Title>
          </div>

          <div style={{ paddingLeft: "2rem" }}>
            <ul className="CustDetails-ul">
              <li style={{ marginTop: "1rem" }}>
             
              <div style={{ display: "flex" }}>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      width: "150px",
                    }}
                    className="label-sm bold customer-span-label-col-1"
                  >
                    Job Board Name
                  </span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "50%",
                    }}
                  >
              <FormGroup>
              
                <Input
                  tabIndex="10"
                  type="select"
                  name="jobBoard"
                  className="form-control-sm"
                  value={this.state.jobBoard}
                  onChange={this.onChange}
               
                  // required={true}
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {this.props.JobBoard.data.map((item) => {
                    return (
                      <option key={item.id} value={item.jobboardName}>
                        {item.jobboardName}
                      </option>
                    );
                  })}
                </Input>
              
              </FormGroup>
              {this.state.boardErrorMessage && (
                      <span style={{color:"red",fontSize:"12px"}}>
                        {this.state.boardErrorMessage}
                      </span>
                    )}
                  </div>
                </div>
              
               
               
                <div style={{ display: "flex",marginTop:"1rem" }}>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      width: "150px",
                    }}
                    className="label-sm bold customer-span-label-col-1"
                  >
                    From Date
                  </span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "50%",
                    }}
                  >
              <FormComponent
                index="16"
               type="date"
                name="startDate"
                change={this.onChange}
                value={this.state.startDate}
               
              
              />
               {this.state.startErrorMessage && (
                      <span style={{color:"red",fontSize:"12px"}}>
                        {this.state.startErrorMessage}
                      </span>
                    )}
                  </div>
                </div>
                <div style={{ display: "flex",marginTop:"1rem" }}>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      width: "150px",
                    }}
                    className="label-sm bold customer-span-label-col-1"
                  >
                    To Date
                  </span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "50%",
                    }}
                  >
              <FormComponent
                index="16"
               type="date"
                name="endDate"
                change={this.onChange}
                value={this.state.endDate}
               
              
              />
               {this.state.endErrorMessage && (
                      <span style={{color:"red",fontSize:"12px"}}>
                        {this.state.endErrorMessage}
                      </span>
                    )}
                      {this.state.dateError && (
                      <span style={{color:"red",fontSize:"12px"}}>
                        {this.state.dateError}
                      </span>
                    )}
                  </div>
                </div>
                <div style={{ display: "flex",marginTop:"1rem" }}>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      width: "150px",
                    }}
                    className="label-sm bold customer-span-label-col-1"
                  >
                    Status
                  </span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "50%",
                    }}
                  >
              <FormComponent
                index="16"
               type="text"
                name="status"
                change={this.onChange}
                value={this.state.status}
               />
                {this.state.statusErrorMessage && (
                      <span style={{color:"red",fontSize:"12px"}}>
                        {this.state.statusErrorMessage}
                      </span>
                    )}
                  </div>
                </div>
                <div style={{ display: "flex",marginTop:"1rem" }}>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      width: "150px",
                    }}
                    className="label-sm bold customer-span-label-col-1"
                  >
                    Notes
                  </span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "50%",
                    }}
                  >
              <FormComponent
                index="16"
               type="text"
                name="notes"
                change={this.onChange}
                value={this.state.notes}
               
              
              />
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "1rem",
            }}
          >
            <Button onClick={this.onSubmit} variant="primary">Submit</Button>
          </div>
          {/* </Form> */}
        </Modal>
        {this.state.message && <Notifier message={this.state.message} />}
        {this.state.error && <ErrorNotifier message={this.state.error} />}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  JobBoard: state.JobBoard
});


export default connect(mapStateToProps,{
  getJobBoard 
})(AddJobBoard)





