import axios from "axios";
import { KNOWLEDGE_TYPE_GET } from "../../types";
import {
  CURD_KNOWLEDGE_TYPE,
  USER_KNOWLEDGE_TYPE,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addKnowledgeType = (data, back) => (dispatch) => {
  axios
    .post(CURD_KNOWLEDGE_TYPE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getKnowledgeType));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getKnowledgeType = () => (dispatch) => {
  axios
    .get(CURD_KNOWLEDGE_TYPE, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(KNOWLEDGE_TYPE_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserKnowledgeType = () => (dispatch) => {
  axios
    .get(USER_KNOWLEDGE_TYPE, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(KNOWLEDGE_TYPE_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateKnowledgeType = (data, back) => (dispatch) => {
  axios
    .put(CURD_KNOWLEDGE_TYPE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteKnowledgeType = (data) => (dispatch) => {
  axios
    .delete(CURD_KNOWLEDGE_TYPE, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getKnowledgeType));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
