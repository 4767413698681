import axios from "axios";
import { HR_POLICIES_GET, HR_POLICIES_SEARCH } from "../../../types";
import {
  CURD_HR_POLICIES,
  SEARCH_HR_POLICIES,
} from "../../../../../utils/routes";
import setSuccessMessage from "../../../common/setSuccessMessage";
import setErrorMessage from "../../../common/setErrorMessage";
import getDispatch from "../../../common/getDispatch";

export const addHrPolicies = (data, back) => (dispatch) => {
  axios
    .post(CURD_HR_POLICIES, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getHrPolicies));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getHrPolicies = () => (dispatch) => {
  axios
    .get(CURD_HR_POLICIES, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(HR_POLICIES_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const searchHrPolicies = (data) => (dispatch) => {
  axios
    .post(SEARCH_HR_POLICIES, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(HR_POLICIES_SEARCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateHrPolicies = (data, back) => (dispatch) => {
  axios
    .put(CURD_HR_POLICIES, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteHrPolicies = (data) => (dispatch) => {
  axios
    .delete(CURD_HR_POLICIES, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getHrPolicies));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
