import axios from "axios";
import { USER_GROUP_GET } from "../../../types";
import {
  ADD_USER_GROUP,
  GET_USER_GROUP,
  UPDATE_USER_GROUP,
  DELETE_USER_GROUP,
} from "../../../../../utils/routes";
import setSuccessMessage from "../../../common/setSuccessMessage";
import setErrorMessage from "../../../common/setErrorMessage";
import getDispatch from "../../../common/getDispatch";

export const addUserGroup = (data, back) => (dispatch) => {
  axios
    .post(ADD_USER_GROUP, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getUserGroup));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserGroup = () => (dispatch) => {
  axios
    .get(GET_USER_GROUP, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(USER_GROUP_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateUserGroup = (data, back) => (dispatch) => {
  axios
    .put(UPDATE_USER_GROUP, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteUserGroup = (data) => (dispatch) => {
  axios
    .delete(DELETE_USER_GROUP, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getUserGroup));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
