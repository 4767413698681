import React, { Component } from "react";
import { Card } from "reactstrap";
import axios from "axios";
import { GET_TOTAL_CAMPAIGNS } from "../../../../utils/routes";
import greenUpArrow from "../../../../assets/img/green-up-arrow.png";
import redDownArrow from "../../../../assets/img/red-down-arrow.png";
import yellowUpArrow from "../../../../assets/img/yellow-up-arrow.png";

class TotalCampaigns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      error: "",
    };
  }

  componentDidMount() {
    axios
      .get(GET_TOTAL_CAMPAIGNS, { withCredentials: true })
      .then((res) => {
        this.setState({
          data: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          error: err,
        });
      });
  }

  render() {
    const { data } = this.state;

    return (
      <div className="text-center">
        <p className="  reCharts-title " style={{ marginTop: "10px" }}>
          Campaigns
        </p>
        <Card
          className="recharts-last-card"
          style={{ backgroundColor: "#FFF" }}
        >
          <div className="text-center" style={{ height: "30px" }}>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "bolder",
                marginTop: "4px",
              }}
            >
              {data.leads}
            </p>
          </div>
          <div className="text-center">
            <small>Previous</small>
            <br />
            <small style={{ display: "block", marginBottom: "5px" }}>
              {data.previous}
            </small>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0px 5px",
            }}
          >
            <div className="text-center">
              <small>% Change</small>
              <br></br>
              <small style={{ display: "block" }}>
                {data.percentageChange}
              </small>
            </div>
            <div className="text-center">
              <small>Trend</small>
              <br></br>
              {data.color === "red" && (
                <img
                  src={redDownArrow}
                  alt="arrow"
                  style={{ marginTop: "-10px" }}
                />
              )}
              {data.color === "#FFC200" && (
                <img
                  src={yellowUpArrow}
                  alt="arrow"
                  style={{ marginTop: "-10px" }}
                />
              )}
              {data.color === "#46CB18" && (
                <img
                  src={greenUpArrow}
                  alt="arrow"
                  style={{ marginTop: "-10px" }}
                />
              )}
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

export default TotalCampaigns;
