import React, { Component } from "react";
import { Container, Table } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import dateFns from "date-fns";
import AddBilling from "./addUserVariable";
import UpdateBilling from "./updateUserVariable";
import ConfirmDialog from "../../../../common/confirm-dialog/ConfirmDialog";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import {
  getUserVariable,
  deleteUserVariable,
} from "../../../../../redux/actions/rs/configuration/userVariable";
import { Link } from "react-router-dom";

// main billing component
class UserVariable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addBtn: false,
      updateBtn: false,
      updateData: [],
      confirmDialog: {
        isOpen: false,
        title: "",
        subTitle: "",
      },
    };

    this.toggleAddBtn = this.toggleAddBtn.bind(this);
    this.addBack = this.addBack.bind(this);
    this.toggleUpdateBtn = this.toggleUpdateBtn.bind(this);
    this.updateBack = this.updateBack.bind(this);
    this.getData = this.getData.bind(this);
    this.deleteUserGroup = this.deleteUserGroup.bind(this);
  }

  /*
toggleAddBtn function to toggle the addition page
*/
  toggleAddBtn() {
    this.setState({
      addBtn: true,
    });
  }

  /*
addBack function to back to the list page from the add page
*/
  addBack() {
    this.setState({
      addBtn: false,
    });
    this.getData();
  }

  /*
toggleUpdateBtn function to toggle the update form
*/
  toggleUpdateBtn(item) {
    console.log(item);
    this.setState({
      updateBtn: true,
      updateData: item,
    });
  }

  /*
updateBack function to redirect to back main page
*/
  updateBack() {
    this.setState({
      updateBtn: false,
    });
    this.getData();
  }

  componentDidMount() {
    this.getData();
  }

  // get all billings data
  getData() {
    this.props.getUserVariable();
  }

  
  deleteUserGroup(id) {
    let data = {
      id: id,
    };
    this.props.deleteUserVariable(data);
    this.setState((prevState) => ({
      ...prevState.confirmDialog,
      confirmDialog: {
        isOpen: false,
      },
      uploadOpen:false
    }));
  }

  setConfirmDialog = (obj) => {
    this.setState((prevProps) => ({
      confirmDialog: {
        ...prevProps.confirmDialog,
        isOpen: obj.isOpen,
      },
    }));
  }

  render() {
    console.log(this.props.billingRs);
    const { addBtn, updateBtn, updateData } = this.state;

    let shrink = this.props.sidebar ? "scale" : "no-scale";

    return (
      <div className={shrink}>
        <Container className="card container-card">
          {!addBtn && !updateBtn ? (
            <React.Fragment>
              {typeof this.props.location.serviceAccessData !== "undefined" &&
                this.props.location.serviceAccessData.creation && (
                  <button
                    className="btn add-button white-text label-sm"
                    onClick={this.toggleAddBtn}
                  >
                    Add
                  </button>
                )}
              <h5 className="card-heading center pt-2">User Variable</h5>
              <Link
                to={{
                  pathname: "/pie/iam/service_menu",
                  moduleName: this.props.location.moduleName,
                  data: this.props.location.data,
                }}
              >
                <i className="material-icons cancel-button pointer">cancel</i>
              </Link>
              <Table className="center">
                <thead>
                  <tr>
                    <th className="opp-head ">ID</th>
                    <th className="opp-head ">key</th>

                    <th className="opp-head ">Value</th>
                    <th className="opp-head ">Description</th>
                    <th className="opp-head ">Is Depended</th>
                    <th className="opp-head ">Deletable</th>
                    <th className="opp-head ">Created By</th>
                    <th className="opp-head">Created On</th>
                    <th className="opp-head">Updated By</th>
                    <th className="opp-head">Updated On</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.UserVariableRs.data.length>0  &&
                    this.props.UserVariableRs.data.map((item) => {
                      return (
                        <tr>
                          <td style={{overflow:"hidden"}} className="opp-tableData">{item.id}</td>
                          <td style={{overflow:"hidden"}} className="opp-tableData">{item.key}</td>
                          <td style={{overflow:"hidden"}} className="opp-tableData">
                            {item.value}
                          </td>
                          <td style={{overflow:"hidden"}} className="opp-tableData">
                            {item.description}
                          </td>
                          <td style={{overflow:"hidden"}} className="opp-tableData">
                            {item.isDepended === false?"false":"true" }
                          </td>
                          <td style={{overflow:"hidden"}} className="opp-tableData">
                            {item.deletable === false?"false":"true" }
                          </td>
                          <td style={{overflow:"hidden"}} className="opp-tableData">{item.createdBy}</td>
                          <td style={{overflow:"hidden"}} className="opp-tableData">
                            {item.createdOn &&
                              dateFns.format(item.createdOn, "DD/MM/YYYY")}
                          </td>
                          <td style={{overflow:"hidden"}} className="opp-tableData">{item.updatedBy}</td>
                          <td style={{overflow:"hidden"}} className="opp-tableData">
                            {item.updatedOn &&
                              dateFns.format(item.updatedOn, "DD/MM/YYYY")}
                          </td>
                          {this.props.UserVariableRs.data && (
                            <td>
                              {typeof this.props.location.serviceAccessData !==
                                "undefined" &&
                                this.props.location.serviceAccessData
                                  .updation && (
                                  <span
                                    className="material-icons pointer opp-action-edit"
                                    style={{ color: "blue" }}
                                    onClick={() => this.toggleUpdateBtn(item)}
                                  >
                                    edit
                                  </span>
                                )}
                            </td>
                          )}
                          {this.props.UserVariableRs.data && (
                            <td>
                              {typeof this.props.location.serviceAccessData !==
                                "undefined" &&
                                this.props.location.serviceAccessData
                                  .deletion && (
                                  <i
                                    className="material-icons pointer"
                                    onClick={() =>
                                      this.setState({
                                        confirmDialog: {
                                          isOpen: true,
                                         onConfirm: () =>
                                          this.deleteUserGroup(item.id),
                                        },
                                      })
                                    }
                                    
                                    
                                  >
                                    delete
                                  </i>
                                )}
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </React.Fragment>
          ) : null}
          {addBtn && <AddBilling back={this.addBack} />}
          {updateBtn && (
            <UpdateBilling back={this.updateBack} data={updateData} />
          )}
          {this.props.UserVariableRs.message && (
            <Notifier message={this.props.UserVariableRs.message} />
          )}
          {this.props.UserVariableRs.error && (
            <ErrorNotifier message={this.props.UserVariableRs.error} />
          )}
           <ConfirmDialog
          confirmDialog={this.state.confirmDialog}
          setConfirmDialog={this.setConfirmDialog}
        />
        </Container>
      </div>
    );
  }
}

UserVariable.propTypes = {
  getUserGroup: PropTypes.func.isRequired,
  deleteUserGroup: PropTypes.func.isRequired,
  // userGroups: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  UserVariableRs: state.UserVariableRs,
});

export default connect(mapStateToProps, {
  getUserVariable,
  deleteUserVariable,
})(UserVariable);
