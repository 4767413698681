import React, { Component } from "react";
import { Container, Table } from "reactstrap";
import AddDocuments from "./AddDocuments";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// components
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import ConfirmDialog from "../../../../common/confirm-dialog/ConfirmDialog";
// redux
import {
  getDocuments,
  deleteDocuments,
} from "../../../../../redux/actions/hcm/my_profile/documentsAction";

// ------------------------------------------------------------------------
class Documents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addBtn: false,
      updateBtn: false,
      data: [],
      updateData: [],
      confirmDialog: {
        isOpen: false,
        title: "",
        subTitle: "",
      },
      error: "",
      message: "",
    };

    this.toggleAddBtn = this.toggleAddBtn.bind(this);
    this.addBack = this.addBack.bind(this);
    this.toggleUpdateBtn = this.toggleUpdateBtn.bind(this);
    this.updateBack = this.updateBack.bind(this);
    this.getData = this.getData.bind(this);
    this.deleteDocuments = this.deleteDocuments.bind(this);
  }

  /*
toggleAddBtn function to toggle the addition page
*/
  toggleAddBtn() {
    this.setState({
      addBtn: true,
    });
  }

  /*
addBack function to back to the list page from the add page
*/
  addBack() {
    this.setState({
      addBtn: false,
    });

    this.getData();
  }

  /*
toggleUpdateBtn function to toggle the update form
*/
  toggleUpdateBtn(item) {
    this.setState({
      updateBtn: true,
      updateData: item,
    });
  }

  /*
updateBack function to redirect to back main page
*/
  updateBack() {
    this.setState({
      updateBtn: false,
    });

    this.getData();
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.props.getDocuments();
  }

  deleteDocuments(id) {
    let data = {
      id: id,
    };

    this.props.deleteDocuments(data);
    this.setState((prevState) => ({
      ...prevState.confirmDialog,
      confirmDialog: {
        isOpen: false,
      },
    }));
  }

  setConfirmDialog = (obj) => {
    this.setState((prevProps) => ({
      confirmDialog: {
        ...prevProps.confirmDialog,
        isOpen: obj.isOpen,
      },
    }));
  };

  render() {
    const { addBtn, data, updateBtn, message, error } = this.state;

    return (
      <Container className="card container-card">
        {!addBtn && !updateBtn ? (
          <React.Fragment>
            <button
              className="btn add-button white-text label-sm"
              onClick={this.toggleAddBtn}
            >
              Add
            </button>
            <h5 className="bold center pt-2">Documents</h5>
            <i
              className="material-icons cancel-button pointer"
              onClick={this.props.back}
            >
              cancel
            </i>
            <Table className="center">
              <thead>
                <tr>
                  <th className="opp-head ">Document Name</th>
                  <th className="opp-head ">File Name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.props.documents.data.length > 0 &&
                  this.props.documents.data.map((item) => {
                    return (
                      <tr>
                        <td className="opp-tableData">{item.documentName}</td>
                        <td className="opp-tableData">{item.fileName}</td>
                        <td>
                          <i
                            className="material-icons pointer"
                            onClick={() =>
                              this.setState({
                                confirmDialog: {
                                  isOpen: true,
                                  title: "Are you sure to delete this record?",
                                  subTitle: "You can't undo this operation",
                                  onConfirm: () =>
                                    this.deleteDocuments(item.id),
                                },
                              })
                            }
                          >
                            delete
                          </i>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </React.Fragment>
        ) : null}
        {addBtn && <AddDocuments back={this.addBack} />}
        {/* {updateBtn && <UpdateDocuments back={this.updateBack} data={updateData} />} */}
        {message && <Notifier message={message} />}
        {error && <ErrorNotifier message={error} />}
        <ConfirmDialog
          confirmDialog={this.state.confirmDialog}
          setConfirmDialog={this.setConfirmDialog}
        />
      </Container>
    );
  }
}

Documents.propTypes = {
  getDocuments: PropTypes.func.isRequired,
  deleteDocuments: PropTypes.func.isRequired,
  documents: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  documents: state.documents,
});
export default connect(mapStateToProps, { getDocuments, deleteDocuments })(
  Documents
);
