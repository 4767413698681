import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  FormText,
} from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import { Validator, submitValidator } from "../../../common/Validator";
import { addRoleMapping } from "../../../../redux/actions/iam/role_mapping/roleMappingAction";
import { getEmployee } from "../../../../redux/actions/iam/emp_onboarding/empOnboardingAction";
import { getUserGroup } from "../../../../redux/actions/iam/auth_configuration/user_group/userGroupAction";
import { getRole } from "../../../../redux/actions/iam/auth_configuration/role_management/roleAction";

class AddUserRole extends Component {
  constructor() {
    super();
    this.state = {
      primaryRole: "",
      pieUserId: "",
      userGroupId: "",
      roleId: "",
      message: "",
      error: "",
      options4: [],
      stateOptions1: [],
      selectedOption: null,
      selectedOptionError: null,
      selectedOption1: null,
      selectedOption1Error: null,
      selectedOption2: null,
      selectedOption2Error: null,
      selectedOption3: null,
      selectedOption3Error: null,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.reset = this.reset.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleChange = (selectedOption) => {
    if (selectedOption) {
      this.setState({ selectedOption, selectedOptionError: null });
    } else {
      this.setState({
        selectedOptionError: "This field is mandatory",
      });
    }
  };

  handleChange1 = (selectedOption1) => {
    if (selectedOption1) {
      this.setState({ selectedOption1, selectedOption1Error: null });
      let options1 = [];
      for (let i = 0; i < this.props.roles.data.length; i++) {
        if (
          Number(this.props.roles.data[i].pieUserGroup.id) ===
          Number(selectedOption1.value)
        ) {
          var x = {};
          x["value"] = this.props.roles.data[i].id;
          x["label"] = this.props.roles.data[i].roleCode;

          options1.push(x);
        }
      }
      this.setState({
        stateOptions1: options1,
      });
    } else {
      this.setState({
        selectedOption1Error: "This field is mandatory",
      });
    }
  };

  handleChange2 = (selectedOption2) => {
    if (selectedOption2) {
      this.setState({ selectedOption2, selectedOption2Error: null });
    } else {
      this.setState({
        selectedOption2Error: "This field is mandatory",
      });
    }
  };

  handleChange3 = (selectedOption3) => {
    if (selectedOption3) {
      this.setState({ selectedOption3, selectedOption3Error: null });
    } else {
      this.setState({
        selectedOption3Error: "This field is mandatory",
      });
    }
  };

  reset() {
    this.setState({
      primaryRole: "",
      secondaryrole: "",
      userGroupId: "",
      roleId: "",
      pieUserId: "",
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    if (
      this.state.selectedOption &&
      this.state.selectedOption1 &&
      this.state.selectedOption2 &&
      this.state.selectedOption3
    ) {
      let User = {
        pieUserId: this.state.selectedOption.value,
        pieUserGroupId: Number(this.state.selectedOption1.value),
        pieUserRoleId: Number(this.state.selectedOption2.value),
        primaryRole: this.state.selectedOption3.value,
      };
      this.props.addRoleMapping(User, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      if (!this.state.selectedOption) {
        this.setState({
          selectedOptionError: "This field is mandatory",
        });
      }
      if (!this.state.selectedOption1) {
        this.setState({
          selectedOption1Error: "This field is mandatory",
        });
      }
      if (!this.state.selectedOption2) {
        this.setState({
          selectedOption2Error: "This field is mandatory",
        });
      }
      if (!this.state.selectedOption3) {
        this.setState({
          selectedOption3Error: "This field is mandatory",
        });
      }
      setTimeout(() => {
        this.setState({
          message: "",
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    this.props.getUserGroup();
    this.props.getRole();
    this.props.getEmployee();
  }

  render() {
    const {
      selectedOption,
      selectedOption1,
      selectedOption2,
      selectedOption3,
    } = this.state;
    let shrink;

    let options3 = [];
    for (let i = 0; i < this.props.employees.data.length; i++) {
      var x1 = {};
      x1["value"] = this.props.employees.data[i].id;
      x1[
        "label"
      ] = `${this.props.employees.data[i].empNo}-${this.props.employees.data[i].firstName}`;

      options3.push(x1);
    }

    let options5 = [];
    for (let i = 0; i < this.props.userGroups.data.length; i++) {
      var x1 = {};
      x1["value"] = this.props.userGroups.data[i].id;
      x1[
        "label"
      ] = `${this.props.userGroups.data[i].id}-${this.props.userGroups.data[i].groupName}`;

      options5.push(x1);
    }

    const options4 = [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ];

    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");

    return (
      <div className={shrink}>
        <Container className="card container-card">
          <h5 className="center bold">Map User Roles</h5>
          <i
            className="material-icons pointer"
            style={{
              position: "absolute",
              top: "5px",
              right: "5px",
              color: "#C00",
            }}
            onClick={this.props.back}
          >
            cancel
          </i>
          <Form onSubmit={this.onSubmit}>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label className="bold label-sm">PIE User</Label>
                  <Select
                    name="options2"
                    value={selectedOption}
                    onChange={this.handleChange}
                    options={options3}
                    required
                  />
                </FormGroup>
                {this.state.selectedOptionError && (
                  <FormText className="error select-search-error">
                    {this.state.selectedOptionError}
                  </FormText>
                )}
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label className="bold label-sm">User Group </Label>
                  <Select
                    name="options6"
                    value={selectedOption1}
                    onChange={this.handleChange1}
                    options={options5}
                    required
                  />
                  {this.state.selectedOption1Error && (
                    <FormText className="error select-search-error">
                      {this.state.selectedOption1Error}
                    </FormText>
                  )}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label className="bold label-sm">Role</Label>
                  <Select
                    name="options"
                    value={selectedOption2}
                    onChange={this.handleChange2}
                    options={this.state.stateOptions1}
                    required
                  />
                  {this.state.selectedOption2Error && (
                    <FormText className="error select-search-error">
                      {this.state.selectedOption2Error}
                    </FormText>
                  )}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label className="bold label-sm">Primary Role</Label>
                  <Select
                    name="options4"
                    value={selectedOption3}
                    onChange={this.handleChange3}
                    options={options4}
                    required
                  />
                  {this.state.selectedOption3Error && (
                    <FormText className="error select-search-error">
                      {this.state.selectedOption3Error}
                    </FormText>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <div className="center">
              <button
                type="submit"
                className="btn mt-3 center login-button white-text"
              >
                Map User Role
              </button>
            </div>
          </Form>
        </Container>
        {this.state.message ? <Notifier message={this.state.message} /> : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
        {this.props.roleMapping.message ? (
          <Notifier message={this.props.roleMapping.message} />
        ) : null}
        {this.props.roleMapping.error ? (
          <ErrorNotifier message={this.props.roleMapping.error} />
        ) : null}
      </div>
    );
  }
}

AddUserRole.propTypes = {
  addRoleMapping: PropTypes.func.isRequired,
  roleMapping: PropTypes.object.isRequired,
  getEmployee: PropTypes.func.isRequired,
  employees: PropTypes.object.isRequired,
  getUserGroup: PropTypes.func.isRequired,
  userGroups: PropTypes.object.isRequired,
  getRole: PropTypes.func.isRequired,
  roles: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  employees: state.employees,
  userGroups: state.userGroups,
  roles: state.roles,
  roleMapping: state.roleMapping,
});

export default connect(mapStateToProps, {
  addRoleMapping,
  getEmployee,
  getUserGroup,
  getRole,
})(AddUserRole);
