import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
} from "reactstrap";
import {
  getCampaign,
  updateCampaign,
  searchCampaign,
} from "../../../../redux/actions/campaignAction";
import {
  refresh,
  submitValidator,
  valid,
  Validator,
} from "../../../common/Validator";
import Details from "./Details";
import Hover from "../../../aside/Hover";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import FormComponent from "../../../common/FormComponent";
import DateFns from "date-fns";

class Campaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.campaign.data || [],
      permissionlist: this.props.auth.permissionlist || {},
      selection: false,
      selected: {},
      subItem: {},
      modal: false,
      hover: false,
      cords: {},
      mouse: {},
      searchTerm: "",
      description: "",
      // popup: false,
      // update: false,
      message: "",
      error: "",
    };
    this.getData = this.getData.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.select = this.select.bind(this);
    this.back = this.back.bind(this);
    this.search = this.search.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.toggleHoverOn = this.toggleHoverOn.bind(this);
    this.toggleHoverOff = this.toggleHoverOff.bind(this);
    // this.beforeUpdate = this.beforeUpdate.bind(this);
    // this.afterUpdate = this.afterUpdate.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.toggle1 = this.toggle1.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.campaign !== this.props.campaign) {
      this.setState({
        data: this.props.campaign.data,
      });
    }

    if (prevProps.auth !== this.props.auth) {
      this.setState({
        permissionlist: this.props.auth.permissionlist,
      });
    }
  }

  search(e) {
    e.preventDefault();
    this.setState({
      searchTerm: e.target.value,
    });
    this.onSearch(e.target.value);
  }

  onSearch(value) {
    let data = {};
    let isInteger = value.length && /^[0-9]*$/.test(value);
    if (isInteger) {
      data.campId = Number(value);
      data.campName = "null";
    } else {
      data.campId = 0;
      data.campName = value;
    }
    this.props.searchCampaign(data);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      description: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    const data = this.state.data.filter((item) => {
      if (item.campId === this.state.subItem) {
        return item;
      } else {
        return null;
      }
    });

    const dragCampaign = {
      campId: data[0].campId,
      campName: data[0].campName,
      date: data[0].date,
      time: data[0].time,
      actionOwner: data[0].actionOwner,
      status: data[0].status,
      description: this.state.description,
    };
    if (valid) {
      this.props.updateCampaign(dragCampaign, this.props.history);
      setTimeout(() => {
        this.toggle();
        this.getData();
      }, 2000);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  toggleHoverOn(e, item) {
    this.setState({
      hover: true,
      cords: {
        one: item.activityType,
        two: item.actionOwner,
        three: item.description,
      },
      mouse: {
        x: e.screenX,
        y: e.screenY,
      },
    });
  }

  toggleHoverOff() {
    this.setState({
      hover: false,
    });
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
      description: "",
    });
  }

  /*
Toggle1 function for modal cancel button here called the getData Fuction
*/
  toggle1() {
    this.setState({
      modal: !this.state.modal,
    });
    this.getData();
  }

  select(item) {
    this.setState({
      selection: item,
      selected: item.campId,
      popup: true,
    });
  }
  // beforeUpdate() {
  //   this.setState({
  //     update: true,
  //   });
  // }
  // afterUpdate() {
  //   this.setState({
  //     update: false,
  //   });
  //   this.getData();
  //   refresh();
  // }

  back() {
    this.setState({
      selection: false,
    });
    this.getData();
  }

  onDragOver(e) {
    e.preventDefault();
  }

  onDragStart(e, item) {
    e.dataTransfer.setData("item", item.campId);
    this.setState({
      subItem: item.campId,
    });
  }
  async onDrop(e, cat) {
    let id = e.dataTransfer.getData("item");
    let newData;
    newData = this.state.data.filter((item) => {
      if (item.campId === parseInt(id, 10)) {
        item.status = cat;
        this.setState({
          subItem: item,
        });
      }
      return newData;
    });
    this.setState({
      ...this.state,
      newData,
    });
    await this.toggle();
  }

  componentDidMount() {
    this.getData();
    this.setState({
      permissionlist: this.props.auth.permissionlist,
    });
  }

  getData() {
    this.props.getCampaign();
  }

  render() {
    let shrink;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");
    const {
      data,
      selection,
      selected,
      modal,
      hover,
      cords,
      description,
      popup,
      update,
      error,
      permissionlist,
    } = this.state;
    let currentPermission;

    for (let x in permissionlist.response.permissions1) {
      if (permissionlist.response.permissions1[x].subMenuId === "3.2") {
        currentPermission = permissionlist.response.permissions1[x];
      }
    }

    return (
      <div className={shrink}>
        <Modal isOpen={modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle1}>Confirm changes?</ModalHeader>
          <ModalBody>
            <Form className="p-3" onSubmit={this.onSubmit} noValidate>
              <FormComponent
                labelClass="bold label-sm"
                label="Description"
                type="text"
                name="description"
                change={this.onChange}
                value={description}
                required={true}
              />
              <FormGroup className="center">
                <button type="submit" className="btn login-button white-text">
                  Submit
                </button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
        {!selection ? (
          <Container className="card container-card">
            <input
              type="text"
              onChange={this.search}
              value={this.state.searchTerm}
              className="searchfield form-control-sm"
              placeholder="CampId/Name"
            />
            {hover && (
              <Hover
                cords={cords}
                labels={{
                  one: "Activity Type",
                  two: "Action Owner",
                  three: "Description",
                }}
                mouse={this.state.mouse}
              />
            )}
            {currentPermission.create1 ? (
              <div className="div">
                <Link to="/pie/marketing/campaign/add">
                  <button className="btn add-button white-text label-sm">
                    Add
                  </button>
                </Link>
              </div>
            ) : null}
            <h5 className="pointer bold center pt-2">Campaign</h5>
            <Row className="p-1">
              <Col
                md="4"
                onDragOver={this.onDragOver}
                onDrop={(e) => this.onDrop(e, "New")}
                className="card p-2"
              >
                <h6 className="center bold m-2">New</h6>
                <ul className="list flex-around  ul-head">
                  <li className="list-item bold pad">ID</li>
                  <li className="list-item bold flex-1">Campaign Name</li>
                  <li className="list-item bold flex-1">Date</li>
                </ul>
                {data &&
                  data.map((item) => {
                    if (item.status === "New") {
                      return (
                        <ul
                          key={item.campId}
                          onClick={() => {
                            this.select(item);
                          }}
                          onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                          onMouseLeave={this.toggleHoverOff}
                          draggable
                          onDragStart={(e) => this.onDragStart(e, item)}
                          className={`list flex-around ${
                            selected &&
                            selected === item.campId &&
                            "selected-item"
                          } InqList`}
                        >
                          <li className="list-table-item pad">{item.campId}</li>
                          <li className="list-table-item flex-1">
                            {item.campName}
                          </li>
                          <li className="list-table-item flex-1">
                            {DateFns.format(item.date, "DD-MM-YYYY")}
                          </li>
                        </ul>
                      );
                    } else {
                      return null;
                    }
                  })}
              </Col>
              <Col
                md="4"
                onDragOver={this.onDragOver}
                onDrop={(e) => this.onDrop(e, "WIP")}
                className="card p-2"
              >
                <h6 className="center bold m-2">Work in progress</h6>
                <ul className="list flex-around  ul-head">
                  <li className="list-item bold pad">ID</li>
                  <li className="list-item bold flex-1">Campaign Name</li>
                  <li className="list-item bold flex-1">Date</li>
                </ul>
                {data &&
                  data.map((item) => {
                    if (item.status === "WIP") {
                      return (
                        <ul
                          key={item.campId}
                          onClick={() => {
                            this.select(item);
                          }}
                          onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                          onMouseLeave={this.toggleHoverOff}
                          draggable
                          onDragStart={(e) => this.onDragStart(e, item)}
                          className={`list flex-around ${
                            selected &&
                            selected === item.campId &&
                            "selected-item"
                          } InqList`}
                        >
                          <li className="list-table-item pad">{item.campId}</li>
                          <li className="list-table-item flex-1">
                            {item.campName}
                          </li>
                          <li className="list-table-item flex-1">
                            {DateFns.format(item.date, "DD-MM-YYYY")}
                          </li>
                        </ul>
                      );
                    } else {
                      return null;
                    }
                  })}
              </Col>
              <Col
                md="4"
                className="card p-2"
                onDrop={(e) => this.onDrop(e, "Complete")}
                onDragOver={this.onDragOver}
              >
                <h6 className="center bold m-2">Completed</h6>
                <ul className="list flex-around  ul-head">
                  <li className="list-item bold pad">ID</li>
                  <li className="list-item bold flex-1">Campaign Name</li>
                  <li className="list-item bold flex-1">Date</li>
                </ul>
                {data &&
                  data.map((item) => {
                    if (item.status !== "New" && item.status !== "WIP") {
                      return (
                        <ul
                          key={item.campId}
                          onClick={() => {
                            this.select(item);
                          }}
                          onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                          onMouseLeave={this.toggleHoverOff}
                          // draggable
                          // onDragStart={(e) => this.onDragStart(e, item)}
                          className={`list flex-around ${
                            selected &&
                            selected === item.campId &&
                            "selected-item"
                          } ${
                            item.inqStatus === "Complete" && "completed-item"
                          } InqList`}
                        >
                          <li className="list-table-item pad">{item.campId}</li>
                          <li className="list-table-item flex-1">
                            {item.campName}
                          </li>
                          <li className="list-table-item flex-1">
                            {DateFns.format(item.date, "DD-MM-YYYY")}
                          </li>
                        </ul>
                      );
                    } else {
                      return null;
                    }
                  })}
              </Col>
            </Row>
          </Container>
        ) : (
          <Details
            campaign={selection}
            back={this.back}
            getData={this.getData}
          />
        )}
        {this.props.campaign.message ? (
          <Notifier message={this.props.campaign.message} />
        ) : null}
        {error ? <ErrorNotifier message={error} /> : null}
      </div>
    );
  }
}

Campaign.propTypes = {
  errors: PropTypes.object.isRequired,
  getCampaign: PropTypes.func.isRequired,
  updateCampaign: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  searchCampaign: PropTypes.func.isRequired,
  campaign: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  campaign: state.campaign,
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getCampaign,
  updateCampaign,
  searchCampaign,
})(Campaign);
