import axios from "axios";
import {  GET_UPLOADED_TEMPLATE_RS,GET_CURRENT_FILE_VALUE } from "../../types";
import { CURD_TEMPLATE_RS_DOCUMENTS  } from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addDocuments = (data, back,current) => (dispatch) => {

  axios
    .post(CURD_TEMPLATE_RS_DOCUMENTS , data, { withCredentials: true })
    .then((res) => {
     dispatch(setSuccessMessage(res, back, getDocuments));
      dispatch({
        type:"GET_CURRENT_FILE_VALUE",
        payload:current
      })
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getDocuments = (data) => (dispatch) => {
  console.log(data)
  axios
    .get(`${CURD_TEMPLATE_RS_DOCUMENTS}/common` , { withCredentials: true })
    .then((res) => {
      console.log(res)
      dispatch(getDispatch(GET_UPLOADED_TEMPLATE_RS, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateDocuments = (data, back) => (dispatch) => {
  axios
    .put(CURD_TEMPLATE_RS_DOCUMENTS , data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteDocuments = (data) => (dispatch) => {
  axios
    .delete(CURD_TEMPLATE_RS_DOCUMENTS , { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getDocuments));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
