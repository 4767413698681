import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// components
import UpdateBankInformation from "./UpdateBankInformation";
// redux
import { getBankAccountsInfo } from "../../../../../../redux/actions/hcm/my_profile/accounts_details/bankAccountsInfoAction";

// -------------------------------------------------------------------------------------------------------------------------------
class BankInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateBtn: false,
    };
    this.openUpdateForm = this.openUpdateForm.bind(this);
    this.closeUpdateForm = this.closeUpdateForm.bind(this);
    this.getData = this.getData.bind(this);
  }

  openUpdateForm() {
    this.setState({
      updateBtn: true,
    });
  }

  closeUpdateForm() {
    this.setState({
      updateBtn: false,
    });
    this.getData();
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.props.getBankAccountsInfo();
  }

  render() {
    const { updateBtn } = this.state;

    return (
      <React.Fragment>
        {!updateBtn ? (
          <React.Fragment>
            <i
              className="material-icons pointer contact-edit-btn"
              onClick={this.openUpdateForm}
            >
              edit
            </i>
            <h5 className="bold center">Bank Information</h5>
            <ul>
              <li>
                <span className="label-sm bold contact-details-span">
                  Account Name
                </span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  <span className="label-sm my-profile-bank-info">
                    {this.props.bankAccountsInfo.data.length > 0
                      ? this.props.bankAccountsInfo.data[0].accountName
                      : null}
                  </span>
                </span>
              </li>
              <li>
                <span className="label-sm bold contact-details-span">
                  Account No.
                </span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  <span className="label-sm my-profile-bank-info">
                    {this.props.bankAccountsInfo.data.length > 0
                      ? this.props.bankAccountsInfo.data[0].accountNo
                      : null}
                  </span>
                </span>
              </li>
              <li>
                <span className="label-sm bold contact-details-span">
                  Account Type
                </span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  <span className="label-sm my-profile-bank-info">
                    {this.props.bankAccountsInfo.data.length > 0
                      ? this.props.bankAccountsInfo.data[0].accountType
                      : null}
                  </span>
                </span>
              </li>
              <li>
                <span className="label-sm bold contact-details-span">
                  Bank Name
                </span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  <span className="label-sm my-profile-bank-info">
                    {this.props.bankAccountsInfo.data.length > 0
                      ? this.props.bankAccountsInfo.data[0].bankName
                      : null}
                  </span>
                </span>
              </li>
              <li>
                <span className="label-sm bold contact-details-span">
                  Branch Name
                </span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  <span className="label-sm my-profile-bank-info">
                    {this.props.bankAccountsInfo.data.length > 0
                      ? this.props.bankAccountsInfo.data[0].branchName
                      : null}
                  </span>
                </span>
              </li>
              <li>
                <span className="label-sm bold contact-details-span">
                  Branch Address
                </span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  <span className="label-sm my-profile-bank-info">
                    {this.props.bankAccountsInfo.data.length > 0
                      ? this.props.bankAccountsInfo.data[0].branchAddress
                      : null}
                  </span>
                </span>
              </li>
              <li>
                <span className="label-sm bold contact-details-span">
                  IFSC Code
                </span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  <span className="label-sm my-profile-bank-info">
                    {this.props.bankAccountsInfo.data.length > 0
                      ? this.props.bankAccountsInfo.data[0].ifscCode
                      : null}
                  </span>
                </span>
              </li>
            </ul>
          </React.Fragment>
        ) : null}
        {updateBtn && (
          <UpdateBankInformation
            back={this.closeUpdateForm}
            data={this.props.bankAccountsInfo.data}
          />
        )}
      </React.Fragment>
    );
  }
}

BankInformation.propTypes = {
  getBankAccountsInfo: PropTypes.func.isRequired,
  bankAccountsInfo: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  bankAccountsInfo: state.bankAccountsInfo,
});

export default connect(mapStateToProps, { getBankAccountsInfo })(
  BankInformation
);
