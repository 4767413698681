import React, { Component } from "react";
import { Form, Row, Col, FormGroup, Label, Input, FormText } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// components
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
// redux
import { updateBusinessCurrency } from "../../../../../redux/actions/hcm/business_setup/businessCurrencyAction";
import { getUserBusinessCountries } from "../../../../../redux/actions/hcm/business_setup/businessCountriesAction";

// -----------------------------------------------------------------------------------------------------------
class UpdateBusinessCurrency extends Component {
  constructor(props) {
    super(props);
    const { id, countryName, currencyName, currencyCode, remarks } =
      this.props.data;
    this.state = {
      currencyId: id,
      countryName: countryName,
      currencyName: currencyName,
      currencyCode: currencyCode,
      remarks: remarks,
      message: "",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      id: this.state.currencyId,
      countryName: this.state.countryName,
      currencyName: this.state.currencyName,
      currencyCode: this.state.currencyCode,
      remarks: this.state.remarks,
    };
    if (valid) {
      this.props.updateBusinessCurrency(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidMount contains the error refresh method and setState method to call the render method
*/
  componentDidMount() {
    refresh();
    this.setState({
      message: "",
      error: "",
    });
    this.props.getUserBusinessCountries();
  }

  render() {
    const { countryName, currencyName, currencyCode, remarks, error, message } =
      this.state;

    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Update Business Currency</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Row>
          <Col md="2"></Col>
          <Col md="8">
            <Form className="p-3" onSubmit={this.onSubmit}>
              <FormGroup>
                <Label className="bold label-sm">Country</Label>
                <Input
                  type="select"
                  name="countryName"
                  className="form-control-sm"
                  value={countryName}
                  onChange={this.onChange}
                  required={true}
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {this.props.businessCountries.data.map((item) => {
                    return (
                      <option key={item.id} value={item.countryName}>
                        {item.countryName}
                      </option>
                    );
                  })}
                </Input>
                {errors.countryName && (
                  <FormText className="error">{errors.countryName}</FormText>
                )}
              </FormGroup>
              <FormComponent
                labelClass="bold label-sm"
                label="Currency Name"
                type="text"
                name="currencyName"
                inputClass="form-control-sm"
                value={currencyName}
                change={this.onChange}
                required={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Currency Code"
                type="text"
                name="currencyCode"
                inputClass="form-control-sm"
                value={currencyCode}
                change={this.onChange}
                required={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Remarks"
                type="textarea"
                name="remarks"
                inputClass="form-control-sm"
                value={remarks}
                change={this.onChange}
              />
              <div className="center">
                <button type="submit" className="btn login-button white-text">
                  Submit
                </button>
              </div>
            </Form>
          </Col>
        </Row>
        {this.props.businessCurrency.message && (
          <Notifier message={this.props.businessCurrency.message} />
        )}
        {this.props.businessCurrency.error && (
          <ErrorNotifier message={this.props.businessCurrency.error} />
        )}
      </React.Fragment>
    );
  }
}

UpdateBusinessCurrency.proptypes = {
  updateBusinessCurrency: PropTypes.func.isRequired,
  businessCurrency: PropTypes.object.isRequired,
  getUserBusinessCountries: PropTypes.func.isRequired,
  businessCountries: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  businessCurrency: state.businessCurrency,
  businessCountries: state.businessCountries,
});

export default connect(mapStateToProps, {
  updateBusinessCurrency,
  getUserBusinessCountries,
})(UpdateBusinessCurrency);
