import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
// components
import FormComponent from "../../../common/FormComponent";
import {
  Validator,
  submitValidator,
  refresh,
  valid,
  errors,
} from "../../../common/Validator";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
// redux
import { addContacts } from "../../../../redux/actions/crm/contacts/contactsAction";
import { getUserContactDesignation } from "../../../../redux/actions/crm/crm_setup/contactDesignationAction";
import { getUserCustomerCountries } from "../../../../redux/actions/crm/crm_setup/customerCountriesAction";

// ------------------------------------------------------------------------------------------------------------

class AddContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salutation: "Mr.",
      contactName: "",
      contactEmail: "",
      country: "",
      contactDialingCode: "",
      contactMobileNo: "",
      alternateDialingCode: "",
      alternateMobileNo: null,
      designation: "",
      remarks: "",
      status: "Active",
      buttonValue: false,
      formErrors: {
        selectError: "",
      },
      error: "",
      message: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    e.preventDefault();
    Validator(e.target);
    if (e.target.name === "country") {
      this.setState({
        contactDialingCode: e.target.value.split(",")[1],
        alternateDialingCode: e.target.value.split(",")[1],
      });
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let contact = {
      salutation: this.state.salutation,
      contactName: this.state.contactName,
      contactEmail: this.state.contactEmail,
      country: this.state.country.split(",")[0],
      contactDialingCode: this.state.contactDialingCode,
      contactMobileNo: this.state.contactMobileNo,
      alternateDialingCode: this.state.alternateDialingCode,
      contactAlternateNo: this.state.alternateMobileNo
        ? this.state.alternateMobileNo
        : this.state.alternateMobileNo,
      contactDesignation: this.state.designation,
      remarks: this.state.remarks,
      contactStatus: this.state.status,
    };
    if (valid) {
      this.props.addContacts(contact, this.props.history);
    } else {
      let errors = this.state.formErrors;
      errors.selectError = "Please select option";
      this.setState({
        formErrors: errors,
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    refresh();
    this.setState({
      message: "",
      error: "",
    });
    this.props.getUserContactDesignation();
    this.props.getUserCustomerCountries();
  }

  render() {
    let shrink;
    const { selectError } = this.state.formErrors;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");
    return (
      <div className={shrink}>
        <Container className="card container-card">
          <h5 className="center bold mt-2">Add Contact</h5>
          <Link to="/pie/crm/contacts">
            <i
              className="material-icons pointer"
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                color: "#C00",
              }}
            >
              cancel
            </i>
          </Link>
          <Form onSubmit={this.onSubmit} className="p-3 mt-2" noValidate>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label className="label-sm bold">Salutation</Label>
                  <Input
                    type="select"
                    name="salutation"
                    onChange={this.onChange}
                    value={this.state.salutation}
                    className="form-control-sm"
                  >
                    <option value="Mr.">Mr.</option>
                    <option value="Mrs.">Mrs.</option>
                    <option value="Ms.">Ms.</option>
                  </Input>
                </FormGroup>
                <FormComponent
                  labelClass="bold label-sm"
                  label="Contact Name"
                  type="text"
                  name="contactName"
                  change={this.onChange}
                  value={this.state.contactName}
                  placeholder="Contact Name"
                  maxlength="27"
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Contact Email"
                  type="email"
                  name="contactEmail"
                  change={this.onChange}
                  value={this.state.contactEmail}
                  placeholder="Contact Email"
                  required={true}
                />
                <FormGroup>
                  <Label className="bold label-sm">Contact Status</Label>
                  <Input
                    type="select"
                    name="status"
                    value={this.state.status}
                    onChange={this.onChange}
                    className="form-control-sm"
                  >
                    <option value="Active">Active</option>
                    <option value="InActive">InActive</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label className="label-sm bold">Country</Label>
                  <Input
                    type="select"
                    name="country"
                    onChange={this.onChange}
                    value={this.state.country}
                    className="form-control-sm"
                    required
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.customerCountries.data.length > 0 &&
                      this.props.customerCountries.data.map((item) => (
                        <option
                          key={item.value}
                          value={`${item.countryName},${item.dialingCode}`}
                        >
                          {item.countryName}
                        </option>
                      ))}
                  </Input>
                  {errors.country && (
                    <FormText className="error">{errors.country}</FormText>
                  )}
                </FormGroup>
              </Col>
              <Col md="6">
                <Row>
                  <Col md="2" className="p-0">
                    <FormComponent
                      labelClass="bold label-sm"
                      label="Code"
                      type="number"
                      value={this.state.contactDialingCode}
                      required={true}
                      disabled={true}
                    />
                  </Col>
                  <Col md="10" className="p-0">
                    <FormComponent
                      labelClass="bold label-sm"
                      label="Contact Mobile Number"
                      type="tel"
                      name="contactMobileNo"
                      change={this.onChange}
                      value={this.state.contactMobileNo}
                      placeholder="Mobile Number"
                      required={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="2" className="p-0">
                    <FormComponent
                      labelClass="bold label-sm"
                      label="Code"
                      type="number"
                      value={this.state.alternateDialingCode}
                      name="alternateDialingCode"
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col md="10" className="p-0">
                    <FormComponent
                      labelClass="bold label-sm"
                      label="Contact Alternate Number"
                      type="tel"
                      name="alternateMobileNo"
                      change={this.onChange}
                      value={this.state.alternateMobileNo}
                      placeholder="Alternate Number"
                    />
                  </Col>
                </Row>
                <FormGroup>
                  <Label className="bold label-sm">Contact Designation</Label>
                  <Input
                    type="select"
                    name="designation"
                    value={this.state.designation}
                    onChange={this.onChange}
                    className="form-control-sm"
                    required={true}
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.contactDesignation.data.map((item) => {
                      return (
                        <option key={item.id} value={item.contactDesigName}>
                          {item.contactDesigName}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.designation && (
                    <FormText className="error">{errors.designation}</FormText>
                  )}
                </FormGroup>
                <FormComponent
                  labelClass="bold label-sm"
                  label="Remarks"
                  type="textarea"
                  name="remarks"
                  change={this.onChange}
                  value={this.state.remarks}
                  placeholder="Remarks"
                />
              </Col>
            </Row>
            <div className="center">
              <button
                type="submit"
                className="btn center login-button white-text"
              >
                Add
              </button>
            </div>
          </Form>
        </Container>
        {this.props.crmContacts.message ? (
          <Notifier message={this.props.crmContacts.message} />
        ) : null}
        {this.props.crmContacts.error ? (
          <ErrorNotifier message={this.props.crmContacts.error} />
        ) : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
      </div>
    );
  }
}

AddContact.propTypes = {
  addContacts: PropTypes.func.isRequired,
  crmContacts: PropTypes.object.isRequired,
  getUserContactDesignation: PropTypes.func.isRequired,
  contactDesignation: PropTypes.object.isRequired,
  getUserCustomerCountries: PropTypes.func.isRequired,
  customerCountries: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  crmContacts: state.crmContacts,
  contactDesignation: state.contactDesignation,
  customerCountries: state.customerCountries,
});

export default connect(mapStateToProps, {
  addContacts,
  getUserContactDesignation,
  getUserCustomerCountries,
})(AddContact);
