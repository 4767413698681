/*  eslint-disable  */
import axios from "axios";
import {
  ADD_NEW_CAMPAIGN,
  GET_CAMPAIGN_LIST,
  UPDATE_CAMPAIGN_ITEM,
  SEARCH_CAMPAIGN_ITEM,
  GET_ERRORS,
} from "./types";
import {
  CURD_CAMPAIGN,
  CAMPAIGN_SEARCH,
} from "../../utils/routes";
import { setMessage } from "./salesActions";

export const addCampaign = (data, history) => (dispatch) => {
  axios
    .post(CURD_CAMPAIGN, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        history.push("/pie/marketing/campaign");
      }, 2000);
    })
    .catch((err) => {
      console.log(err.message);
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};

export const getCampaign = () => (dispatch) => {
  axios
    .get(CURD_CAMPAIGN, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: GET_CAMPAIGN_LIST,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};

export const updateCampaign = (data, history) => (dispatch) => {
  axios
    .post(CURD_CAMPAIGN, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        if (history) {
          history.push("/pie/marketing/campaign");
        }
      }, 2000);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};

export const deleteCampaign = (data, history) => (dispatch) => {
  axios
    .delete(CURD_CAMPAIGN, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        if (history) {
          history.push("/pie/marketing/campaign");
        }
      }, 2000);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};

export const searchCampaign = (data) => (dispatch) => {
  axios
    .post(CAMPAIGN_SEARCH, data, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: SEARCH_CAMPAIGN_ITEM,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};
