import React, { Component } from "react";
import { Form, Row, Col, FormGroup, Label, Input, FormText } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { countries } from "countries-list";

// components
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
// redux
import { getCandiGeo } from "../../../../../redux/actions/rs/configuration/candidateGeo";
import { addCanCountry } from "../../../../../redux/actions/rs/configuration/candidateCountries";

// -------------------------------------------------------------------------------------------------------------

class AddCandidateCountries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      geography: "",
      countryName: "",
      color: "#000000",
      remarks: "",
      message: "",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      geoName: this.state.geography.split(",")[1],
      geoCode: this.state.geography.split(",")[0],
      countryName: this.state.countryName.split(",")[0],
      countryCode: this.state.countryName.split(",")[1],
      dialingCode: this.state.countryName.split(",")[2],
      native: this.state.countryName.split(",")[3],
      capital: this.state.countryName.split(",")[4],
      currency: this.state.countryName.split(",")[5],
      languages: "",
      emoji: this.state.countryName.split(",")[6],
      emojiU: this.state.countryName.split(",")[7],
      color: this.state.color,
      remarks: this.state.remarks,
    };
    if (valid) {
      this.props.addCanCountry(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidMount contains the error refresh method and here set the state for calling render method
*/
  componentDidMount() {
    refresh();
    this.setState({
      message: "",
      error: "",
    });
    this.props.getCandiGeo();
  }

  render() {
    const { geography, countryName, color, remarks, error, message } =
      this.state;
    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Add Candidate Countries</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="6" className="mx-auto">
              <FormGroup>
                <Label className="bold label-sm">Geography</Label>
                <Input
                  type="select"
                  name="geography"
                  className="form-control-sm"
                  value={geography}
                  onChange={this.onChange}
                  required={true}
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {this.props.CandiGeo.data.map((item) => {
                    return (
                      <option
                        key={item.id}
                        value={`${item.geoCode},${item.geoName}`}
                      >
                        {item.geoName}
                      </option>
                    );
                  })}
                </Input>
                {errors.geography && (
                  <FormText className="error">{errors.geography}</FormText>
                )}
              </FormGroup>
              <Row>
                <Col md="10" style={{ paddingLeft: "0px" }}>
                  <FormGroup>
                    <Label className="bold label-sm">Country Name</Label>
                    <Input
                      type="select"
                      name="countryName"
                      className="form-control-sm"
                      value={countryName}
                      onChange={this.onChange}
                      required={true}
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {Object.keys(countries).map((item, index) => {
                        if (
                          this.state.geography.split(",")[0] ===
                          countries[item].continent
                        ) {
                          return (
                            <option
                              key={index}
                              value={`${countries[item].name},${item},${countries[item].phone},${countries[item].native},${countries[item].capital},${countries[item].currency},${countries[item].emoji},${countries[item].emojiU}`}
                            >
                              {countries[item].name}
                            </option>
                          );
                        }
                      })}
                    </Input>
                    {errors.countryName && (
                      <FormText className="error">
                        {errors.countryName}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
                <Col md="2" style={{ paddingRight: "0px" }}>
                  <FormComponent
                    labelClass="bold label-sm"
                    label="Color"
                    type="color"
                    name="color"
                    inputClass="form-control-sm"
                    value={color}
                    change={this.onChange}
                  />
                </Col>
              </Row>
              <FormComponent
                labelClass="bold label-sm"
                label="Remarks"
                type="textarea"
                name="remarks"
                inputClass="form-control-sm"
                value={remarks}
                change={this.onChange}
              />
            </Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Add
            </button>
          </div>
        </Form>
        {this.props.CandiCountry.message && (
          <Notifier message={this.props.CandiCountry.message} />
        )}
        {this.props.CandiCountry.error && (
          <ErrorNotifier message={this.props.CandiCountry.error} />
        )}
        {error && <ErrorNotifier message={error} />}
      </React.Fragment>
    );
  }
}

AddCandidateCountries.propTypes = {
  getCandiGeo: PropTypes.func.isRequired,
  addCanCountry: PropTypes.object.isRequired,
  getCustomerGeographies: PropTypes.func.isRequired,
  customerGeographies: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  CandiGeo: state.CandiGeo,
  CandiCountry: state.CandiCountry,
});

export default connect(mapStateToProps, {
  getCandiGeo,
  addCanCountry,
})(AddCandidateCountries);
