import axios from "axios";
import { CONTACTS_GET } from "../../../types";
import { CURD_CONTACTS } from "../../../../../utils/routes";
import setSuccessMessage from "../../../common/setSuccessMessage";
import setErrorMessage from "../../../common/setErrorMessage";
import getDispatch from "../../../common/getDispatch";

export const getContacts = () => (dispatch) => {
  axios
    .get(CURD_CONTACTS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CONTACTS_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateContacts = (data, back) => (dispatch) => {
  axios
    .post(CURD_CONTACTS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
