import React, { Component } from "react";
import { Row, Col, Card } from "reactstrap";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import greenUpArrow from "../../../../../assets/img/green-up-arrow.png";
import redDownArrow from "../../../../../assets/img/red-down-arrow.png";
import yellowRectangle from "../../../../../assets/img/yellow-two.png";
import yellowUpArrow from "../../../../../assets/img/yellow-up-arrow.png";

import * as d3 from "d3";
import D3Funnel from "d3-funnel";
import DateFns from "date-fns";

import { ImSearch } from "react-icons/im";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import CustTooltip from "../../../../common/tooltip/CustTooltip";
import ViewFullDetailsFunnelInfo from "./viewFullDetailsFunnelInfo";

import { Input, FormGroup, Label } from "reactstrap";

import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  ResponsiveContainer,
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Cell,
} from "recharts";
import axios from "axios";
import ViewFullDetailsModel from "./viewFullDetailsInfo";
import ViewCategoryInfo from "./viewPositionCategoryInfo";
import { INSIGHTS_RS_API } from "../../../../../utils/routes";
import {
  getDashBoardDetails,
  getDashBoardMoreDetails,
  getDashBoardFullApplicants,
  getDashboardPosition,
  getDashboardApplicants,
  getDashboardFunnel,
  getDashboardApplicantsDetails,
  getDashboardGraphRatio,
  getProfileScreeningHr,
  getDashboardSource
} from "../../../../../redux/actions/rs/dashboard/dashboardAction";
import { connect } from "react-redux";

const dataTwoGraphs = [
  { name: "Social media", value: "tech" },
  { name: "JobBoards", value: "technology" },
  { name: "Website", value: "IT" },
];

// const CustomizedLabel = React.createClass({
//  render () {
//     const {x, y, fill, value} = this.props;
//      return <text
//                x={x}
//                y={y}

//                fontSize='16'
//                fontFamily='sans-serif'
//                fill={fill}
//                textAnchor="start">{value}%</text>
//   }
// });
const dataFunnel = [
  { label: "Hired", value: "Hired", labelColor: "#ffff00" },
  { label: "Interview", value: "Interview", labelColor: "#329932" },
  { label: "Screening", value: "Screening", labelColor: "#fff" },
  { label: "Applications", value: "Applications", labelColor: "#000" },
];

const CustomizedLabel = (props) => {
  const { x, y, fill, value } = props;
  return (
    <text
      x={x}
      y={y}
      fontSize="14"
      fontFamily="sans-serif"
      fill={fill}
      textAnchor="start"
    >
      {value}
    </text>
  );
};

class ProductsGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      fulleDetailsData: [],
      fullDetailsApplicantsData: [],
      funnelCardData: [],
      applicantsGraphHoverDate: [],
      categoryGraphHoverData: [],
      profileScreeningCardData: [],
      dashboardSourceCardData:[],
      isToogleView: false,
      isOpenFullCandi: false,
      isOpenCandi: false,
      isOpenFunnelCandi: false,
      isOpenScreening: false,
      isOpenCategoryCandi: false,
      isOpenApplicantsCandi: false,
      isOpenHiredCandi: false,
      isOpenOfferedCandi: false,
      isOpenDeclinedCandi: false,
      isOpenShortlistedCandi: false,
      isOpenRejectedCandi: false,
      isOpenSourceCandi:false,
      fromDate: "",
      toDate: "",
      dateError: "",
    };
    this.handleHiredCandi = this.handleHiredCandi.bind(this);
    this.closeDetailsModel = this.closeDetailsModel.bind(this);
    this.handleDeclinedCandi = this.handleDeclinedCandi.bind(this);
    this.handleOfferedCandi = this.handleOfferedCandi.bind(this);
    this.handleShortListedCandi = this.handleShortListedCandi.bind(this);
    this.handleFullApplicantsCandi = this.handleFullApplicantsCandi.bind(this);
    this.handleRejectedCandi = this.handleRejectedCandi.bind(this);
    this.closeDetailsFullModel = this.closeDetailsFullModel.bind(this);
    this.funnelCardClick = this.funnelCardClick.bind(this);
    this.closeDetailsFunelModel = this.closeDetailsFunelModel.bind(this);
    this.profileScreeningClick = this.profileScreeningClick.bind(this);
    this.closeDetailsApplicantsModel =
      this.closeDetailsApplicantsModel.bind(this);
    this.closeDetailsCategoryModel = this.closeDetailsCategoryModel.bind(this);
    this.closeDetailsSourceModel = this.closeDetailsSourceModel.bind(this)
    this.closeDetailsScreeningModel =
      this.closeDetailsScreeningModel.bind(this);
    this.handleDateClick = this.handleDateClick.bind(this);
    this.categaryGraphClick = this.categaryGraphClick.bind(this);
    this.applicantsGraphClick = this.applicantsGraphClick.bind(this);
    this.dashboardSourceClick = this.dashboardSourceClick.bind(this)
    // this.getProductGraphData = this.getProductGraphData.bind(this);
  }

  // componentDidMount calls the getProductGraphData function to get the product graph data

  // getProductGraphData function to get the product graph data

  getProductGraphData() {
    axios.get(INSIGHTS_RS_API, { withCredentials: true }).then((res) => {
      this.setState({
        data: res.data,
      });
    });
  }

  funnelCardClick(data) {
    this.setState({
      profileScreeningCardData: [],
      funnelCardData: this?.props?.rsDashboard?.funnelData[0][data],

      isToogleView: false,
      isOpenFunnelCandi: true,
    });
  }

  profileScreeningClick(data) {
    this.setState({
      profileScreeningCardData:
        this?.props?.rsDashboard?.profileScreeningData[0][data],
      funnelCardData: [],
      isToogleView: false,
      isOpenScreening: true,
    });
  }

  categaryGraphClick(data) {
    console.log(data);
    this.setState({
      categoryGraphHoverData: this?.props?.rsDashboard?.positionData[0][data],
      isToogleView: false,
      isOpenCategoryCandi: !this.state.isOpenCategoryCandi,
    });
  }

  applicantsGraphClick(data) {
    console.log(data);
    this.setState({
      applicantsGraphHoverDate:
        this?.props?.rsDashboard?.positionApplicants[0][data],
      isToogleView: false,
      isOpenApplicantsCandi: !this.state.isOpenApplicantsCandi,
    });
    
  }

  dashboardSourceClick(data) {

  this.setState({
    dashboardSourceCardData:
        this?.props?.rsDashboard?.dashboardSourceData[0][data],
      isToogleView: false,
      isOpenSourceCandi: !this.state.isOpenSourceCandi,
  })



  }

  closeDetailsSourceModel() {
    this.setState({
      isOpenSourceCandi: false,
    });
  }

  closeDetailsCategoryModel() {
    this.setState({
      isOpenCategoryCandi: false,
    });
  }

  closeDetailsApplicantsModel() {
    this.setState({
      isOpenApplicantsCandi: false,
    });
  }

  handleDateClick() {
    let body = {
      fromDate: this.state.fromDate,
      endDate: this.state.toDate,
    };

    if (this.state.fromDate && this.state.toDate) {
      this.setState({
        dateError: "",
      });
      this.props.getDashBoardDetails(body);
      this.props.getDashBoardMoreDetails(body);
      this.props.getDashboardFunnel(body);
      this.props.getDashBoardFullApplicants(body);
      this.props.getDashboardPosition(body);
      this.props.getDashboardApplicants(body);
      this.props.getProfileScreeningHr(body);
      this.props.getDashboardSource(body)
      //  this.props.getDashboardGraphRatio(body)
    } else {
      this.setState({
        dateError: "date should not be empty",
      });
      setTimeout(() => {
        this.setState({
          dateError: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    this.props.getDashboardFunnel();
    // this.getProductGraphData();
    this.props.getDashBoardDetails();
    this.props.getDashBoardMoreDetails();
    this.props.getDashBoardFullApplicants();
    this.props.getDashboardPosition();
    this.props.getDashboardApplicants();
    this.props.getProfileScreeningHr();
    this.props.getDashboardGraphRatio();
    this.props.getDashboardSource()
  }

  componentDidUpdate(prevProps, prevState) {
    const optionsFunnel = {
      chart: {
        height: 180,
        width: 200,
      },
      block: {
        dynamicHeight: true,
        minHeight: 15,
        highlight: true,
        curve: {
          enabled: true,
          height: 20,
        },
        animate: 100,
      },
      tooltip: {
        enabled: true,
        format: "{l}- {f}",
      },
      events: {
        click: {
          block: (label, value) => {
            this.funnelCardClick(value.label.raw);
          },
        },
      },
      label: {
        format: function (label, value) {
          return "" + value;
        },
      },
    };

    if (
      prevProps.rsDashboard.funnelData !== this.props.rsDashboard.funnelData ||
      prevProps.rsDashboard.funnelData == this.props.rsDashboard.funnelData
    ) {
      let resultThree = Object.keys(
        this?.props?.rsDashboard?.funnelData.length > 0 &&
          this?.props?.rsDashboard?.funnelData[0]
      );

      const data03 = [];
      const colorCode = [
        "#FDD017",
        "#E8A317",
        "#FC7F03",
        "#38ACEC",
        "#0000FF",
        "#6CBB3c",
        "#008000",
      ];
      //  for(let i = 0 ; i < resultThree.length ; i++){
      resultThree.forEach((curr, index, arr) => {
        data03.push({
          label: curr,
          value: Number(
            this?.props?.rsDashboard?.funnelData[0][curr].length > 0
              ? this?.props?.rsDashboard?.funnelData[0][curr].length
              : "0"
          ),
          backgroundColor: colorCode[index],
        });
      });

      const chart = new D3Funnel("#funnel");
      data03.length > 0 && chart.draw(data03, optionsFunnel);
    }

    const optionsFunnelTwo = {
      chart: {
        height: 180,
        width: 200,
      },
      block: {
        dynamicHeight: true,
        minHeight: 15,
        highlight: true,
        curve: {
          enabled: true,
          height: 20,
        },
        animate: 100,
      },
      tooltip: {
        enabled: true,
        format: "{l}- {f}",
      },
      events: {
        click: {
          block: (label, value) => {
            this.profileScreeningClick(value.label.raw);
          },
        },
      },
      label: {
        format: function (label, value) {
          return "" + value;
        },
      },
    };

    if (
      prevProps.rsDashboard.profileScreeningData !==
        this.props.rsDashboard.profileScreeningData ||
      prevProps.rsDashboard.profileScreeningData ==
        this.props.rsDashboard.profileScreeningData
    ) {
      let resultThree = Object.keys(
        this?.props?.rsDashboard?.profileScreeningData.length > 0 &&
          this?.props?.rsDashboard?.profileScreeningData[0]
      );

      const data03 = [];
      const colorCode = [
      "#FDD017",
      "#E8A317",
      "#FC7F03",
      "#7BB4E3",
      "#38ACEC",
      "#0000FF",
      "#BCE954",
      "#6CBB3c",
      "#008000",
      ];


      //  for(let i = 0 ; i < resultThree.length ; i++){
      resultThree.forEach((curr, index, arr) => {
        data03.push({
          label: curr,
          value: Number(
            this?.props?.rsDashboard?.profileScreeningData[0][curr].length > 0
              ? this?.props?.rsDashboard?.profileScreeningData[0][curr].length
              : "0"
          ),
          backgroundColor: colorCode[index],
        });
      });

      const chart = new D3Funnel("#funnelTwo");
      data03.length > 0 && chart.draw(data03, optionsFunnelTwo);
    }
  }

  handleHiredCandi(event) {
    this?.props?.rsDashboard.mainData[0][event].length > 0 &&
      this.setState({
        isToogleView: false,
        fulleDetailsData: this?.props?.rsDashboard.mainData[0][event],
        isOpenCandi: !this.state.isOpenCandi,
      });
  }

  handleDeclinedCandi(event) {
    this?.props?.rsDashboard.mainData[0][event].length > 0 &&
      this.setState({
        isToogleView: false,
        fulleDetailsData: this?.props?.rsDashboard.mainData[0][event],
        isOpenCandi: !this.state.isOpenCandi,
      });
  }

  handleOfferedCandi(event) {
    this?.props?.rsDashboard.mainData[0][event].length > 0 &&
      this.setState({
        isToogleView: false,
        fulleDetailsData: this?.props?.rsDashboard.mainData[0][event],
        isOpenCandi: !this.state.isOpenCandi,
      });
  }

  handleRejectedCandi(event) {
    this?.props?.rsDashboard.mainData[0][event].length > 0 &&
      this.setState({
        isToogleView: false,
        fulleDetailsData: this?.props?.rsDashboard.mainData[0][event],
        isOpenCandi: !this.state.isOpenCandi,
      });
  }

  handleShortListedCandi(event) {
    this?.props?.rsDashboard.mainData[0][event].length > 0 &&
      this.setState({
        isToogleView: false,
        fulleDetailsData: this?.props?.rsDashboard.mainData[0][event],
        isOpenCandi: !this.state.isOpenCandi,
      });
  }

  handleOthersCandi(event) {
    this?.props?.rsDashboard.mainData[0][event].length > 0 &&
      this.setState({
        isToogleView: false,
        fulleDetailsData: this?.props?.rsDashboard.mainData[0][event],
        isOpenCandi: !this.state.isOpenCandi,
      });
  }

  handleFullApplicantsCandi(event) {
    console.log("chandra");
    this?.props?.rsDashboard.applicantsData?.length > 0 &&
      this.setState({
        isToogleView: true,
        isOpenFullCandi: !this.state.isOpenFullCandi,
        fullDetailsApplicantsData: this?.props?.rsDashboard?.applicantsData,
      });
  }

  closeDetailsModel() {
    this.setState({
      isOpenCandi: false,
    });
  }

  closeDetailsFullModel() {
    this.setState({
      isOpenFullCandi: false,
    });
  }

  closeDetailsFunelModel() {
    this.setState({
      isOpenFunnelCandi: false,
    });
  }

  closeDetailsScreeningModel() {
    this.setState({
      isOpenScreening: false,
    });
  }

  render() {
    console.log(this.props.rsDashboard)
    let data04 = [
      { name: "Social media", value: 3 },
      { name: "Job Boards", value: 3 },
      { name: "Websites", value: 3 },
      { name: "Referrals", value: 3 },
    ];

    const COLORS = [
      "#FDD017",
      "#E8A317",
      "#FC7F03",
      "#7BB4E3",
      "#38ACEC",
     
    
      "#0000FF",
      "#BCE954",
      "#6CBB3c",
      "#008000",
    ];

    let resultOne = Object.keys(
      this?.props?.rsDashboard?.positionData.length > 0 &&
        this?.props?.rsDashboard?.positionData[0]
    );
    console.log(resultOne);
    const data01 = [];
    for (const key of resultOne) {
      if (this?.props?.rsDashboard?.positionData[0][key].length > 0) {
        data01.push({
          name: key,
          value: this?.props?.rsDashboard?.positionData[0][key].length,
        });
      }
    }

    let resultTwo = Object.keys(
      this?.props?.rsDashboard?.positionApplicants.length > 0 &&
        this?.props?.rsDashboard?.positionApplicants[0]
    );
    console.log(resultTwo);
    const data02 = [];
    for (const key of resultTwo) {
      if (this?.props?.rsDashboard?.positionApplicants[0][key].length > 0) {
        data02.push({
          name: key,
          value: this?.props?.rsDashboard?.positionApplicants[0][key].length,
        });
      }
      console.log(data02);
    }

    let resultThree = Object.keys(
      this?.props?.rsDashboard?.dashboardSourceData.length > 0 &&
        this?.props?.rsDashboard?.dashboardSourceData[0]
    );
    console.log(resultThree);
    const dataFive = [];
    for (const key of resultThree) {
      if (this?.props?.rsDashboard?.dashboardSourceData[0][key].length > 0) {
        dataFive.push({
          name: key,
          value: this?.props?.rsDashboard?.dashboardSourceData[0][key].length,
        });
      }
      console.log(dataFive);
    }

    var dataPoint;
    var total;
    const options = {
      animationEnabled: true,
      title: {
        text: "Sales Analysis",
      },
      data: [
        {
          type: "funnel",
          // toolTipContent: "<b>{label}</b>: {y} <b>({percentage}%)</b>",
          indexLabelPlacement: "inside",
          indexLabel: "{label} ({percentage}%)",
          dataPoints: [
            { y: 1400, label: "Prospects" },
            { y: 1212, label: "Qualified Prospects" },
            { y: 1080, label: "Proposals" },
            { y: 665, label: "Negotiation" },
            { y: 578, label: "Final Sales" },
          ],
        },
      ],
    };
    //calculate percentage
    dataPoint = options.data[0].dataPoints;
    total = dataPoint[0].y;
    for (var i = 0; i < dataPoint.length; i++) {
      if (i == 0) {
        options.data[0].dataPoints[i].percentage = 100;
      } else {
        options.data[0].dataPoints[i].percentage = (
          (dataPoint[i].y / total) *
          100
        ).toFixed(2);
      }
    }

    let shrink = this.props.sidebar ? "scaleGraph" : "no-scaleGraph";
    return (
      <div className={shrink}>
        <div className="flex-top-firstConatiner">
          <div className="flex-Graphcontainer">
            <div onClick={this.handleFullApplicantsCandi}>
              <CustTooltip title="Total Applicants">
                <span style={{ fontWeight: "bold" }}>Applications</span>
              </CustTooltip>
              <span
                style={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  color: "#003C82",
                }}
              >
                {" "}
                {this.props.rsDashboard.data[0]?.TotalApplicants}
              </span>
            </div>
            <div onClick={() => this.handleHiredCandi("HiredCandiadte")}>
              <span style={{ fontWeight: "bold" }}> Hired/Joined </span>
              <span
                style={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  color: "#003C82",
                }}
              >
                {" "}
                {this.props.rsDashboard.data[0]?.HiredCandiadte}{" "}
              </span>
            </div>
            <div onClick={() => this.handleOfferedCandi("OfferedCandidate")}>
              <span style={{ fontWeight: "bold" }}> Offered</span>

              <span
                style={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  color: "#003C82",
                }}
              >
                {" "}
                {this.props.rsDashboard.data[0]?.OfferedCandidate}{" "}
              </span>
            </div>
            <div onClick={() => this.handleOfferedCandi("Accepted")}>
              <CustTooltip title="Offer Accepted">
                <span style={{ fontWeight: "bold" }}>Accepted</span>
              </CustTooltip>
              <span
                style={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  color: "#003C82",
                }}
              >
                {" "}
                {this.props.rsDashboard.data[0]?.Accepted}{" "}
              </span>
            </div>
            <div onClick={() => this.handleDeclinedCandi("DeclineCandidate")}>
              <CustTooltip title="Offer Declined">
                <span style={{ fontWeight: "bold" }}> Declined</span>
              </CustTooltip>
              <span
                style={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  color: "#003C82",
                }}
              >
                {" "}
                {this.props.rsDashboard.data[0]?.DeclineCandidate}
              </span>
            </div>
            <div
              onClick={() =>
                this.handleShortListedCandi("ShortlistedCandidate")
              }
            >
              <CustTooltip title="Interview Shortlisted">
                <span style={{ fontWeight: "bold" }}> Shortlisted</span>
              </CustTooltip>
              <span
                style={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  color: "#003C82",
                }}
              >
                {" "}
                {this.props.rsDashboard.data[0]?.ShortlistedCandidate}{" "}
              </span>
            </div>

            <div onClick={() => this.handleRejectedCandi("RejectedCandidate")}>
              <CustTooltip title="Interview Rejected">
                <span style={{ fontWeight: "bold" }}> Rejected</span>
              </CustTooltip>
              <span
                style={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  color: "#003C82",
                }}
              >
                {" "}
                {this.props.rsDashboard.data[0]?.RejectedCandidate}
              </span>
            </div>

            <div onClick={() => this.handleOthersCandi("Others")}>
              <CustTooltip title="Candidate other than hiring stage">
                <span style={{ fontWeight: "bold" }}> Others</span>
              </CustTooltip>
              <span
                style={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  color: "#003C82",
                }}
              >
                {" "}
                {this.props.rsDashboard.data[0]?.Others}
              </span>
            </div>
          </div>

          <div className="dateManager-classInsights">
            <div className="dateManager-classInsights-one">
              <FormGroup className="mb-0-5">
                <Label
                  style={{
                    fontWeight: "bold",
                    fontSize: "0.80rem",
                    marginLeft: "1.5rem",
                  }}
                >
                  From Date
                </Label>

                <Input
                  type="date"
                  name="sourcedFrom"
                  value={this.state.fromDate}
                  onChange={(e) =>
                    this.setState({
                      fromDate: e.target.value,
                    })
                  }
                  className="form-control-sm manager-view-select-box manager-date-input"
                  required={true}
                  tabIndex={8}
                />
              </FormGroup>

              <FormGroup className="mb-0-5">
                <Label
                  style={{
                    fontWeight: "bold",
                    fontSize: "0.80rem",
                    marginLeft: "1.5rem",
                  }}
                >
                  To Date
                </Label>

                <Input
                  type="date"
                  name="sourcedTo"
                  value={this.state.toDate}
                  onChange={(e) =>
                    this.setState({
                      toDate: e.target.value,
                    })
                  }
                  className="form-control-sm manager-view-select-box manager-date-input"
                  required={true}
                  tabIndex={8}
                />
              </FormGroup>
            </div>

            <h3
              data-toggle="tooltip"
              data-placement="top"
              onClick={this.handleDateClick}
              style={{
                color: "#0056b3",
                marginLeft: ".5rem",
                cursor: "pointer",
                fontSize: "1rem",
              }}
            >
              {" "}
              <ImSearch />{" "}
            </h3>
          </div>
        </div>
        <div className="flex-GraphcontainerTwo">
          {/* <div className="graphChild-col-1">
<span style={{alignSelf:"flex-start",fontSize:"0.7rem",fontWeight:"bold"}}>Open positions by departments</span>

<ResponsiveContainer  width="100%" >

        <PieChart  width={300}>
     
          <Pie
         
            dataKey="value"
            isAnimationActive={false}
            data={data01}
            cx="50%"
            cy="50%"
            outerRadius={80}
            fill="#8884d8"
            label 

          >
           {data01.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          
          </Pie>

          
        
          <Tooltip />

        </PieChart>
       
      </ResponsiveContainer>
 
</div> */}
          <div className="graphChild-col-3">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <span
                style={{
                  fontSize: "0.80rem",
                  fontWeight: "bold",
                  marginBottom: "0.5rem",
                }}
              >
                Recruitment Funnel
              </span>
            </div>
            <div style={{ marginTop: "0.5rem" }} id="funnel" />
          </div>
          <div className="graphChild-col-3">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <span
                style={{
                  fontSize: "0.80rem",
                  fontWeight: "bold",
                  marginBottom: "0.5rem",
                }}
              >
                {" "}
                Profile Screening Status
              </span>
            </div>
            <div style={{ marginTop: "0.5rem" }} id="funnelTwo" />
            {/* <div style={{display:"flex",justifyContent:"center"}}>
<span style={{fontSize:"0.80rem",fontWeight:"bold",marginTop:"0.5rem"}}>Applications received by source</span>
</div> 
<ResponsiveContainer width="100%">
            <PieChart width={200}>
              <Pie
                dataKey="value"
                isAnimationActive={false}
                data={data04}
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#8884d8"

                label
              >
                {data04.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>

             
              <Tooltip />
            </PieChart>
          </ResponsiveContainer> */}

            {/* <ResponsiveContainer>

    	<BarChart 
            data={dataTwoGraphs}
            layout="vertical" barCategoryGap={10}
          >
      <XAxis type="category"  />
       <YAxis type="category"  dataKey="name"/>
            
       <Bar 
           dataKey="value" 
           fill="#003C82"
           label={<CustomizedLabel  />}
           />
           
      </BarChart>
     </ResponsiveContainer> */}
          </div>

          <div className="graphChild-col-4">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <span
                style={{
                  margin: "auto",
                  fontSize: "0.80rem",
                  fontWeight: "bold",
                  marginTop: "0.5rem",
                }}
              >
                Monthly Growth Trends ( % )
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginTop:"1.5rem"
              }}
            >
               <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "1rem",
                }}
              >
                <span style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
                  Requirements
                </span>
                <CustTooltip
                  title={`Past month - ${
                    this.props.rsDashboard.ratioData[0]
                      ?.PastMonthJobRequirementCount
                  }
              ${" "}
              Current month - ${
                this.props.rsDashboard.ratioData[0]
                  ?.CurrentMonthJobRequirementCount
              }
              `}
                >
                  <span style={{ marginLeft: "1rem", cursor: "pointer" }}>
                    {" "}
                    {
                      this.props.rsDashboard.ratioData[0]
                        ?.JobRequirementTrendPercentages
                    }
                  </span>
                </CustTooltip>

                {Number(
                  this.props.rsDashboard.ratioData[0]
                    ?.JobRequirementTrendPercentages
                ) > 0 && (
                  <img
                    style={{ marginLeft: "1rem", marginTop: "-1rem" }}
                    src={greenUpArrow}
                    alt="arrow"
                  />
                )}
                {Number(
                  this.props.rsDashboard.ratioData[0]
                    ?.JobRequirementTrendPercentages
                ) < 0 && (
                  <img
                    style={{ marginLeft: "1rem", marginTop: "-1rem" }}
                    src={redDownArrow}
                    alt="arrow"
                  />
                )}
                {Number(
                  this.props.rsDashboard.ratioData[0]
                    ?.JobRequirementTrendPercentages
                ) === 0 && (
                  <img
                    style={{
                      marginLeft: "1rem",
                      marginTop: "-1rem",
                      height: "3rem",
                      width: "3rem",
                    }}
                    src={yellowRectangle}
                    alt="arrow"
                  />
                )}

                {/* <div style={{ marginLeft: "1rem" }}>
            
              <br></br>

              <img
                  src={redDownArrow}
                  alt="arrow"
                  style={{ marginTop: "-10px" }}
                />

              <img
                  src={yellowUpArrow}
                  alt="arrow"
                  style={{ marginTop: "-10px" }}
                />

             
            </div> */}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "1rem",
                }}
              >
                <span style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
                  Offered
                </span>
                <CustTooltip
                  title={`Past month - ${
                    this.props.rsDashboard.ratioData[0]
                      ?.PastMonthOffredCandidateCount
                  }
              ${" "}
              Current month - ${
                this.props.rsDashboard.ratioData[0]
                  ?.CurrentMonthOfferedCandidateCount
              }
              `}
                >
                  <span style={{ marginLeft: "3rem", cursor: "pointer" }}>
                    {" "}
                    {
                      this.props.rsDashboard.ratioData[0]
                        ?.OfferedTrendPercentage
                    }
                  </span>
                </CustTooltip>

                {Number(
                  this.props.rsDashboard.ratioData[0]?.OfferedTrendPercentage
                ) > 0 && (
                  <img
                    style={{ marginLeft: "1rem", marginTop: "-1rem" }}
                    src={greenUpArrow}
                    alt="arrow"
                  />
                )}
                {Number(
                  this.props.rsDashboard.ratioData[0]?.OfferedTrendPercentage
                ) < 0 && (
                  <img
                    style={{ marginLeft: "1rem", marginTop: "-1rem" }}
                    src={redDownArrow}
                    alt="arrow"
                  />
                )}
                {Number(
                  this.props.rsDashboard.ratioData[0]?.OfferedTrendPercentage
                ) === 0 && (
                  <img
                    style={{
                      marginLeft: "1rem",
                      marginTop: "-1rem",
                      height: "3rem",
                      width: "3rem",
                    }}
                    src={yellowRectangle}
                    alt="arrow"
                  />
                )}

                {/* <small>Trend</small> */}

                {/* <img
                  src={redDownArrow}
                  alt="arrow"
                  style={{ marginTop: "-10px" }}
                /> */}

                {/* <img
                  src={yellowUpArrow}
                  alt="arrow"
                  style={{ marginTop: "-10px" }}
                /> */}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "1rem",
                }}
              >
                <span style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
                  Joined
                </span>
                <CustTooltip
                  title={`Past month - ${
                    this.props.rsDashboard.ratioData[0]
                      ?.PastMonthJoinedCandidateCount
                  }
              ${" "}
              Current month - ${
                this.props.rsDashboard.ratioData[0]
                  ?.CurrentMonthJoinedCandidateCount
              }
              `}
                >
                  <span style={{ marginLeft: "3rem", cursor: "pointer" }}>
                    {" "}
                    {
                      this.props.rsDashboard.ratioData[0]
                        ?.JoinedTrendPercentages
                    }
                  </span>
                </CustTooltip>
                {Number(
                  this.props.rsDashboard.ratioData[0]?.JoinedTrendPercentages
                ) > 0 && (
                  <img
                    style={{ marginLeft: "1rem", marginTop: "-1rem" }}
                    src={greenUpArrow}
                    alt="arrow"
                  />
                )}
                {Number(
                  this.props.rsDashboard.ratioData[0]?.JoinedTrendPercentages
                ) < 0 && (
                  <img
                    style={{ marginLeft: "1rem", marginTop: "-1rem" }}
                    src={redDownArrow}
                    alt="arrow"
                  />
                )}
                {Number(
                  this.props.rsDashboard.ratioData[0]?.JoinedTrendPercentages
                ) === 0 && (
                  <img
                    style={{
                      marginLeft: "1rem",
                      marginTop: "-1rem",
                      height: "3rem",
                      width: "3rem",
                    }}
                    src={yellowRectangle}
                    alt="arrow"
                  />
                )}

                {/* <div style={{ marginLeft: "1rem" }}>
              <small>Trend</small>
              <br></br>

              <img
                  src={redDownArrow}
                  alt="arrow"
                  style={{ marginTop: "-10px" }}
                />

              <img
                  src={yellowUpArrow}
                  alt="arrow"
                  style={{ marginTop: "-10px" }}
                />

             
            </div> */}
              </div>

             
            </div>

            {/* <span  style={{fontSize:"0.8rem",fontWeight:"bold"}}>New Requirements </span>
<span> {this.props.rsDashboard.ratioData[0]?.NewReqiurement
}</span>
<span style={{fontSize:"0.8rem",fontWeight:"bold"}}>Joined Candidates</span>
<span> {this.props.rsDashboard.ratioData[0]?.JoinedCandidate
}</span>
<span style={{fontSize:"0.8rem",fontWeight:"bold"}}>Offered Candidates</span>
<span> {this.props.rsDashboard.ratioData[0]?.OfferedCandidate
}</span> */}
          </div>
        </div>

        <div className="flex-GraphcontainerThree">
        <div className="graphChild-col-1">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <span
                style={{
                  marginTop: "0.5rem",
                  alignSelf: "flex-start",
                  fontSize: "0.80rem",
                  fontWeight: "bold",
                }}
              >
                Open Positions by Job Category
              </span>
            </div>

            <ResponsiveContainer width="100%">
              <PieChart width={300}>
                <Pie
                  dataKey="value"
                  isAnimationActive={false}
                  data={data01}
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                  label
                >
                  {data01.map((entry, index) => (
                    <Cell
                      color="#8884d8"
                      onClick={() => this.categaryGraphClick(entry.name)}
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>

                {/* <Pie dataKey="value" data={data02} cx={500} cy={200} innerRadius={40} outerRadius={80} fill="#82ca9d" /> */}
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>

        <div className="graphChild-col-1">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <span
                style={{
                  marginTop: "0.5rem",
                  fontSize: "0.80rem",
                  fontWeight: "bold",
                }}
              >
                Applicants by Job Category
              </span>
            </div>

            <ResponsiveContainer width="100%">
              <PieChart width={300}>
                <Pie
                  dataKey="value"
                  isAnimationActive={false}
                  data={data02}
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                  label
                >
                  {data02.map((entry, index) => (
                    <Cell
                      onClick={() => this.applicantsGraphClick(entry.name)}
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>

                {/* <Pie dataKey="value" data={data02} cx={500} cy={200} innerRadius={40} outerRadius={80} fill="#82ca9d" /> */}
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>

         

          <div className="graphChild-col-1">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <span
                style={{
                  fontSize: "0.80rem",
                  fontWeight: "bold",
                  marginTop: "0.5rem",
                }}
              >
                Applicants  by Source
              </span>
            </div>
            <ResponsiveContainer width="100%">
              <PieChart width={200}>
                <Pie
                  dataKey="value"
                  isAnimationActive={false}
                  data={dataFive}
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  label
                >
                  {dataFive.map((entry, index) => (
                    <Cell
                    onClick={() => this.dashboardSourceClick(entry.name)}
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>

                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
        
        </div>

        {/* <div className="flex-GraphcontainerThree">
<div className="graphChild-colOne-1">
<ResponsiveContainer >
 <BarChart  width={150} height={200}  data={ data02 }>
          
        <XAxis dataKey="name" />
       <YAxis  />
       <Tooltip />
      <Legend />
    
      <Bar dataKey="applications" fill="#7D187E" />
     
       
    </BarChart>
     
</ResponsiveContainer>


</div>
<div  className="graphChild-colOne-2">
         <TableContainer  >
                    <Table  sx={{ width: "50rem" }} >
                      <TableHead style={{ background: "#003C82" }}>
                        <TableRow >
                          <TableCell  style={{color:"#FFFFFF",width:"30%"}} >Application Name</TableCell>
                          <TableCell style={{color:"#FFFFFF",width:"20%"}}>Email</TableCell>
                          <TableCell style={{color:"#FFFFFF",width:"15%"}}>Job Title</TableCell>
                          <TableCell style={{color:"#FFFFFF",width:"25%"}} width={300}> Applied Date</TableCell>
                          <TableCell style={{color:"#FFFFFF",width:"30%"}}> Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>

                    {
                    this?.props?.rsDashboard?.tableData.length > 0 && 
                     this?.props?.rsDashboard?.tableData.map((table)=>{
                      return(
                        <TableRow style={{height:"60px"}}
                        >
                           <TableCell style={{width:"30%"}} >
                         {`${table.canInfo[0].firstName} ${table.canInfo[0].lastName}`}
                           </TableCell>
                           <TableCell  style={{width:"20%"}}>
                          {table.canInfo[0].eMailId}
                           </TableCell>
                           <TableCell style={{width:"15%"}}>
                          {
                          table.jobRequirement.length &&
                          table.jobRequirement[0].jobPosition

                          }
                           </TableCell>
                           <TableCell style={{width:"25%"}}>
                          {DateFns.format(table.taggedOn,"DD-MM-YYYY")}
                           </TableCell>
                           <TableCell  style={{width:"30%"}}>
                           {
                          table.jobRequirement.length &&
                          table.hiringStatus

                          }
                           </TableCell>
                       
                         </TableRow>
                      )
                     })
                    }
                       
                 
                      </TableBody>
                    </Table>
                  </TableContainer>
</div>

</div> */}

        {this.state.isOpenCandi && (
          <ViewFullDetailsModel
            isToogleView={this.state.isToogleView}
            isOpenCandi={this.state.isOpenCandi}
            closeModel={this.closeDetailsModel}
            data={this.state.fulleDetailsData}
            isReq={false}
          />
        )}

        {this.state.isOpenFullCandi && (
          <ViewFullDetailsModel
            isToogleView={this.state.isToogleView}
            isOpenCandi={this.state.isOpenFullCandi}
            closeModel={this.closeDetailsFullModel}
            data={this.state.fullDetailsApplicantsData}
            isReq={false}
            isShowTac ={false}
          />
        )}

        {this.state.isOpenFunnelCandi && (
          <ViewFullDetailsFunnelInfo
            isToogleView={this.state.isToogleView}
            isOpenCandi={this.state.isOpenFunnelCandi}
            closeModel={this.closeDetailsFunelModel}
            data={this.state.funnelCardData}
            isReq={true}
            isShowTac ={true}

          />
        )}

        {this.state.isOpenScreening && (
          <ViewFullDetailsFunnelInfo
            isToogleView={this.state.isToogleView}
            isOpenCandi={this.state.isOpenScreening}
            closeModel={this.closeDetailsScreeningModel}
            data={this.state.profileScreeningCardData}
            isReq={true}
            isShowTac ={true}
          />
        )}

        {this.state.isOpenCategoryCandi && (
          <ViewCategoryInfo
            isToogleView={this.state.isToogleView}
            isOpenCandi={this.state.isOpenCategoryCandi}
            closeModel={this.closeDetailsCategoryModel}
            data={this.state.categoryGraphHoverData}
          />
        )}

        {this.state.isOpenApplicantsCandi && (
          <ViewFullDetailsModel
            isToogleView={this.state.isToogleView}
            isOpenCandi={this.state.isOpenApplicantsCandi}
            closeModel={this.closeDetailsApplicantsModel}
            data={this.state.applicantsGraphHoverDate}
            isReq={false}
            isShowTac ={false}
          />
        )}

{this.state.isOpenSourceCandi && (
          <ViewFullDetailsModel
            isToogleView={this.state.isToogleView}
            isOpenCandi={this.state.isOpenSourceCandi}
            closeModel={this.closeDetailsSourceModel}
            data={this.state.dashboardSourceCardData}
            isReq={false}
            isShowTac ={true}
           
          />
        )}

        {/* <Col md="6" className="graph-col">
            <Card className="graph-card" >
         <LineChart width={600} height={300} data={this.state.data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
          
        <CartesianGrid strokeDasharray="5 5" />
        <XAxis dataKey="date" />
        <YAxis domain={[0, 60]}  />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="created"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
          <Line
          type="monotone"
          dataKey="tagged"
          stroke="#FBB117"
          activeDot={{ r: 8 }}
        />
  </LineChart>
      </Card>
        </Col> */}

        {this.state.dateError && (
          <ErrorNotifier message={this.state.dateError} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rsDashboard: state.rsDashboard,
});

export default connect(mapStateToProps, {
  getDashBoardDetails,
  getDashBoardMoreDetails,
  getDashBoardFullApplicants,
  getDashboardPosition,
  getDashboardApplicants,
  getDashboardFunnel,
  getDashboardApplicantsDetails,
  getDashboardGraphRatio,
  getProfileScreeningHr,
  getDashboardSource
})(ProductsGraph);
