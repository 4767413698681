import React, { Component } from "react";
import { Container } from "reactstrap";
import ProjectRow from "./ProjectRow";
import Axios from "axios";
import {
  GET_SERVICE_TEAM_ASSIGNMENT,
  TIME_SHEET_EXCEL,
} from "../../../../utils/routes";
import GetAppIcon from "@material-ui/icons/GetApp";

class ServiceAssignPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectData: [],
      projectRows: "",
    };
    this.pqProjectLoadRow = this.pqProjectLoadRow.bind(this);
    this.addNewProjectRow = this.addNewProjectRow.bind(this);
    this.getProjectQuote = this.getProjectQuote.bind(this);
    this.timeSheetDownload = this.timeSheetDownload.bind(this);
  }

  /*
componentDidMount function contains the fetch function for price quote information of particular opportunity
*/
  componentDidMount() {
    this.getProjectQuote();
  }

  /*
getProjectQuote function for getting the project quote details
*/
  getProjectQuote() {
    let data = {
      serviceId: this.props.serviceId,
    };
    Axios.post(GET_SERVICE_TEAM_ASSIGNMENT, data, { withCredentials: true })
      .then((res) => {
        this.setState({
          projectData: res.data,
          projectRows: res.data.length,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  /*
pqProjectLoadRow function for loading the project rows
*/
  pqProjectLoadRow() {
    let array = [];
    for (let i = 0; i < this.state.projectRows; i++) {
      array.push(
        <ProjectRow
          serviceId={this.props.serviceId}
          projectData={this.state.projectData[i]}
          getProjectQuote={this.getProjectQuote}
        />
      );
    }
    return array.map((item) => {
      return item;
    });
  }

  /*
addNewProjectRow function to adding the new row for project quote 
*/
  addNewProjectRow() {
    const newList = this.state.projectData;
    newList.push({});
    this.setState({
      projectData: newList,
      projectRows: this.state.projectRows + 1,
    });
  }

  timeSheetDownload() {
    let data = {
      empId: localStorage.getItem("id"),
    };

    Axios.post(TIME_SHEET_EXCEL, data, {
      responseType: "blob",
      withCredentials: true,
    })
      .then((res) => {
        let url = window.URL.createObjectURL(new Blob([res.data]));
        let link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Report.xls");
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err.message);
      });
  }
  render() {
    return (
      <Container className="card container-card mt--5" id="pq-container">
        <h5 style={{ marginBottom: "-15px", marginTop: "10px" }}>
          Team Assignment
        </h5>
        <div
          id="serviceTimeSheetDownload"
          className="pointer"
          onClick={this.timeSheetDownload}
        >
          <GetAppIcon id="serviceTimeSheetDownloadIcon" />
          <p id="serviceTimeSheetDownloadText">TimeSheet</p>
        </div>
        <hr />
        <table className="pq-table employee-table">
          <thead>
            <tr>
              <td
                className="material-icons pointer"
                onClick={this.addNewProjectRow}
              >
                add
              </td>
              <th className="label-sm bold">ID</th>
              <th className="label-sm bold">Assigned To</th>
              <th className="label-sm bold">Start Date</th>
              <th className="label-sm bold">End Date</th>
              <th className="label-sm bold">%Percentage</th>
              <th className="label-sm bold">Description</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>{this.pqProjectLoadRow()}</tbody>
        </table>
      </Container>
    );
  }
}

export default ServiceAssignPage;
