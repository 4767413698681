import React, { Component } from "react";
import { Form, Row, Col, FormGroup, Label, Input, FormText } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DateFns from "date-fns";
import axios from "axios";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import { ADD_RS_CANDIDATE } from "../../../../../utils/routes";
import { getCandidateStatusRs } from "../../../../../redux/actions/rs/configuration/candidateStausAction";
import { getHiringStatSelect } from "../../../../../redux/actions/rs/configuration/hiringStatus";
import { updateRequirementTracking } from "../../../../../redux/actions/rs/candidate/tagRequirement";



class AddCandidate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      candiName: "",
      lastName: "",
      emailId: "",
      location: "",
      country: "",
      mobileNumber: "",
      gender: "",
      skillGroup: "",
      skillSets: "",
      noticePeriod: "",
      expectedCtc: "",
      currentCtc: "",
      experience: "",
      prefLocation: "",
      isExperience: "Experienced",
      notes: "",
      remarks: "",
      dynamicCurrency: "",

      candiStatus: this.props.fetchData
        ? this.props.fetchData.candidateStatus
        : "",
        Tagremarks: this.props.fetchData.comment === " " ? "": this.props.fetchData.comment,
        hiringStagDate:this.props.fetchData.hiringStagedate === " " ? "": this.props.fetchData.hiringStagedate , 
        hiringStagRating:this.props.fetchData.hiringStageRating === " " ? "": this.props.fetchData.hiringStageRating,
        hiringFeedBack:this.props.fetchData.hiringStagefeedback === " " ? "": this.props.fetchData.hiringStagefeedback,
      dateOf: this.props.fetchData ? this.props.fetchData.dateOfJoin: "",
      id: this.props.fetchData ? this.props.fetchData.id : "",
      jobReqId: this.props.fetchData
        ? this.props.fetchData.jobRequirementId
        : "",
      canInfoId: this.props.fetchData ? this.props.fetchData.canInfoId : "",
      canUserId: this.props.fetchData ? this.props.fetchData.canUserId : "",
      
      hiringStage: this.props.fetchData ? this.props.fetchData.hiringStage:"",
      hiringStatus: this.props.fetchData ? this.props.fetchData.hiringStatus:"",
      // joiningDate: this.props.fetchData ? this.props.fetchData.dateOfJoin:"",
      filterHiringStage: [],
      filterHiringStatus: [],
    
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  // onChange(e) {
  //   Validator(e.target);
  //   this.setState({
  //     [e.target.name]: e.target.value,
  //   });
  // }

  onChange(e) {
    e.preventDefault();
    Validator(e.target);

    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      id: this.state.id,
      jobReqirementId: this.state.jobReqId,
      canUserId: this.state.canUserId,
      canInfoId: this.state.canInfoId,
      hiringStage: this.state.hiringStage,
      hiringStatus: this.state.hiringStatus,
      candidateSource: "Tagging",
      candidateStatus: this.state.candiStatus,
      dateOfJoin: this.state.dateOf,
      comment: this.state.Tagremarks,
      hiringStagedate:this.state.hiringStagDate ? this.state.hiringStagDate:" ",
      hiringStageRating:this.state.hiringStagRating ? this.state.hiringStagRating:" ",
      hiringStagefeedback:this.state.hiringFeedBack ? this.state.hiringFeedBack :" "
    };

    if (valid) {
      this.props.updateRequirementTracking(data, this.props.navigate);
    } else {
      this.setState({
        error: "Please Enter Mandatory Fields",
      });
    }

}

  /*
componentDidMount contains the error refresh method and here set the state for calling render method
*/
  componentDidMount() {
    this.props.getHiringStatSelect();
    this.props.getCandidateStatusRs();
    refresh();
    this.setState({
      message: "",
      error: "",
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.hiringStatus.mainData !== this.props.hiringStatus.mainData) {
      this.props.hiringStatus.mainData.find((val) => {
        if (val.hiringStageName === this.state.hiringStage) {
          this.setState({
            filterHiringStatus: val.hiringStatus,
          });
        }
      });
    }
  }

  searchHiringStatus = (data) => {
    this.props.hiringStatus.mainData.find((val) => {
      if (val.hiringStageName === data) {
        this.setState({
          filterHiringStatus: val.hiringStatus,
        });
      }
    });
  };

  onChange = (e) => {
    Validator(e.target);
    if (e.target.name === "candiStatus") {
      if (e.target.value !== "Hiring Stage") {
        this.setState({
          candiStatus: e.target.value,
          hiringStatus: " ",
          hiringStage: " ",
        });
      } else if (e.target.value === "Hiring Stage") {
        this.searchHiringStatus("Profile Screening");
        this.setState({
          candiStatus: e.target.value,
          hiringStage: "Profile Screening",
          hiringStatus: "Shortlisted",
        });
      }
    } else if (e.target.name === "hiringStage") {
      this.searchHiringStatus(e.target.value);
      this.setState({
        hiringStage: e.target.value,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  render() {
    console.log(
      this.state.noticePeriod,
      this.state.expectedCtc,
      this.state.currentCtc,
      this.state.experience
    );
    const {
      candiName,
      emailId,
      country,
      skillGroup,
      location,
      candiStatus,
      expectedCtc,
      currentCtc,
      remarks,
      dateOf,
      skillSets,
      experience,
      mobileNumber,
      isExperience,
      gender,
      noticePeriod,
      notes,
      prefLocation,
      lastName,

      message,
      error,
    } = this.state;

    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Update Tagged Requirement </h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.navigate}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="3"></Col>
            <Col md="6">
              {this.state.candiStatus !== "Hiring Stage" && (
                <FormGroup>
                  <Label className="bold label-sm">Candidate Status</Label>
                  <Input
                    type="select"
                    name="candiStatus"
                    className="form-control-sm"
                    value={this.state.candiStatus}
                    onChange={this.onChange}
                    placeholder="Candidate Status"
                    required={true}
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.CandidateStatus.mainData.map((item) => {
                      return (
                        <option key={item.id} value={item.candidateStatus}>
                          {item.candidateStatus}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.candiStatus && (
                    <FormText className="error">{errors.candiStatus}</FormText>
                  )}
                </FormGroup>
              )}
              {this.state.candiStatus === "Hiring Stage" && (
                <div style={{display:"flex"}}>
                <FormGroup style={{width:"50%"}}>
                  <Label className="bold label-sm"> Hiring Stage</Label>
                  <Input
                    type="select"
                    name="hiringStage"
                    className="form-control-sm"
                    value={this.state.hiringStage}
                    onChange={this.onChange}
                    placeholder="Hiring Stage"
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.hiringStatus.mainData.map((item) => {
                      return (
                        <option key={item.id} value={item.hiringStageName}>
                          {item.hiringStageName}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.hiringStage && (
                    <FormText className="error">{errors.hiringStage}</FormText>
                  )}
                </FormGroup>
                <FormGroup style={{width:"45%",marginLeft:"1.5rem"}}>
                  <Label className="bold label-sm">
                     Date
                  </Label>
                  <Input
                    type="date"
                    name="hiringStagDate"
                    className="form-control-sm"
                    value={this.state.hiringStagDate}
                    onChange={this.onChange}
                  
                  
                  ></Input>
                
                </FormGroup>

                </div>
              )}
              {this.state.candiStatus === "Hiring Stage" && (
                 <div style={{display:"flex"}}>
                 <FormGroup  style={{width:"50%"}}>
                   <Label className="bold label-sm"> Hiring Status</Label>
                   <Input
                     type="select"
                     name="hiringStatus"
                     className="form-control-sm"
                     value={this.state.hiringStatus}
                     onChange={this.onChange}
                     placeholder="Hiring Status"
                   >
                     <option value="" hidden>
                       Select...
                     </option>
                     {this.state.filterHiringStatus.length > 0 &&
                       this.state.filterHiringStatus.map((item) => {
                         return (
                           <option key={item.id} value={item.hiringStatus}>
                             {item.hiringStatus}
                           </option>
                         );
                       })}
                   </Input>
                   {errors.hiringStatus && (
                     <FormText className="error">{errors.hiringStatus}</FormText>
                   )}
                 </FormGroup>
                 <FormGroup style={{width:"45%",marginLeft:"1.5rem"}}>
                   <Label className="bold label-sm">
                      Rating
                   </Label>
                   <Input
                    type="select"
                    name="hiringStagRating"
                    className="form-control-sm"
                    value={this.state.hiringStagRating}
                    onChange={this.onChange}
                
                      >
                         <option value="" hidden>
                           Select...
                         </option>
                         <option value = "1">1</option>
                         <option value = "2">2</option>
                        <option  value = "3">3</option>
                         <option value  ="4">4</option>
                         <option value = "5">5</option>
                       
                       </Input>
                 
                 </FormGroup>
                 </div>
              )}
              {(this.state.hiringStage === "Offered" ||
                this.state.hiringStage === "HR Discussion" ||
                this.state.hiringStage === "Manager Discussion") && (
                <FormGroup>
                  <Label className="bold label-sm">
                    Expected Date Of Joining
                  </Label>
                  <Input
                    type="date"
                    name="dateOf"
                    className="form-control-sm"
                    value={this.state.dateOf}
                    onChange={this.onChange}
                    placeholder="Expected Date Of Joining"
                    required={true}
                  ></Input>
                  {errors.dateOf && (
                    <FormText className="error">{errors.dateOf}</FormText>
                  )}
                </FormGroup>
              )}
                <FormGroup>
                <Label className="bold label-sm">Feedback</Label>
                <Input
                  type="textarea"
                  name="hiringFeedBack"
                  className="form-control-sm"
                  value={this.state.hiringFeedBack}
                  onChange={this.onChange}
                  placeholder="Feedback"
                ></Input>
                  {errors.hiringFeedBack && (
                  <FormText className="error">{errors.hiringFeedBack}</FormText>
                )}
               
              </FormGroup>
              <FormGroup>
                <Label className="bold label-sm">Comments</Label>
                <Input
                  type="textarea"
                  name="Tagremarks"
                  className="form-control-sm"
                  value={this.state.Tagremarks}
                  onChange={this.onChange}
                  placeholder="Comments"
                ></Input>
                {errors.Tagremarks && (
                  <FormText className="error">{errors.Tagremarks}</FormText>
                )}
              </FormGroup>
            </Col>
            <Col md="3"></Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn btn-custom" tabIndex="15">
              Submit
            </button>
          </div>
        </Form>
        {message && <Notifier message={message} />}
        {error && <ErrorNotifier message={error} />}
        {this.props.TaggedReqTracking.message ? (
          <Notifier message={this.props.TaggedReqTracking.message} />
        ) : null}
        {this.props.TaggedReqTracking.error ? (
          <ErrorNotifier message={this.props.TaggedReqTracking.error} />
        ) : null}
      </React.Fragment>
    );
  }
}

AddCandidate.propTypes = {
  addEmpAwards: PropTypes.func.isRequired,
  empAwards: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({

  CandidateStatus: state.CandidateStatus,
  hiringStatus: state.hiringStatus,
  TaggedReqTracking: state.TaggedReqTracking,
});

export default connect(mapStateToProps, {
  getCandidateStatusRs,
  updateRequirementTracking,
  getHiringStatSelect
})(AddCandidate);
