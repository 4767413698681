import axios from "axios";
import { BUSINESS_COUNTRIES_GET } from "../../types";
import {
  CURD_BUSINESS_COUNTRIES,
  USER_BUSINESS_COUNTRIES,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addBusinessCountries = (data, back) => (dispatch) => {
  axios
    .post(CURD_BUSINESS_COUNTRIES, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getBusinessCountries));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getBusinessCountries = () => (dispatch) => {
  axios
    .get(CURD_BUSINESS_COUNTRIES, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(BUSINESS_COUNTRIES_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserBusinessCountries = () => (dispatch) => {
  axios
    .get(USER_BUSINESS_COUNTRIES, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(BUSINESS_COUNTRIES_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateBusinessCountries = (data, back) => (dispatch) => {
  axios
    .put(CURD_BUSINESS_COUNTRIES, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getBusinessCountries));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteBusinessCountries = (data) => (dispatch) => {
  axios
    .delete(CURD_BUSINESS_COUNTRIES, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getBusinessCountries));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
