import axios from "axios";
import { SALES_TEAM_GET } from "../../types";
import { CURD_SALES_TEAM, USER_SALES_TEAM } from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addSalesTeam = (data, back) => (dispatch) => {
  axios
    .post(CURD_SALES_TEAM, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getSalesTeam));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getSalesTeam = () => (dispatch) => {
  axios
    .get(CURD_SALES_TEAM, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(SALES_TEAM_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserSalesTeam = () => (dispatch) => {
  axios
    .get(USER_SALES_TEAM, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(SALES_TEAM_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateSalesTeam = (data, back) => (dispatch) => {
  axios
    .put(CURD_SALES_TEAM, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteSalesTeam = (data) => (dispatch) => {
  axios
    .delete(CURD_SALES_TEAM, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getSalesTeam));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
