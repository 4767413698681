import axios from "axios";
import {
  EMP_INITIATE_RESIGNATION_GET,
  EMP_INITIATE_RESIGNATION_FETCH,
} from "../../types";
import { CURD_EMP_INITIATE_RESIGNATION } from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addEmpInitiateResignation = (data, back) => (dispatch) => {
  axios
    .post(CURD_EMP_INITIATE_RESIGNATION, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getEmpInitiateResignation));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getEmpInitiateResignation = () => (dispatch) => {
  axios
    .get(CURD_EMP_INITIATE_RESIGNATION, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(EMP_INITIATE_RESIGNATION_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const fetchEmpInitiateResignation = (data) => (dispatch) => {
  axios
    .get(CURD_EMP_INITIATE_RESIGNATION, { params: data, withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(EMP_INITIATE_RESIGNATION_FETCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateEmpInitiateResignation = (data, back) => (dispatch) => {
  axios
    .put(CURD_EMP_INITIATE_RESIGNATION, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteEmpInitiateResignation = (data) => (dispatch) => {
  axios
    .delete(CURD_EMP_INITIATE_RESIGNATION, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getEmpInitiateResignation));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
