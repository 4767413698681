import axios from "axios";
import { CRM_USER_GET } from "../../types";
import { CURD_CRM_USER } from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addCrmUser = (data, back) => (dispatch) => {
  axios
    .post(CURD_CRM_USER, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getCrmUser));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getCrmUser = () => (dispatch) => {
  axios
    .get(CURD_CRM_USER, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CRM_USER_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateCrmUser = (data, back) => (dispatch) => {
  axios
    .put(CURD_CRM_USER, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getCrmUser));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteCrmUser = (data) => (dispatch) => {
  axios
    .delete(CURD_CRM_USER, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getCrmUser));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
