import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormText,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { connect } from "react-redux";
import DateFns from "date-fns";
import { getPartners } from "../../../../../redux/actions/partnerAction";
import { getVendors } from "../../../../../redux/actions/vendorAction";
import {
  updateIssuePO,
  updatePurchaseItem,
  getPurchaseItems,
  updateScheduledPymtDate,
  getScheduledPymtDate,
} from "../../../../../redux/actions/finance/accounts_payable/issue_po/issuePOAction";
import { getCurrency } from "../../../../../redux/actions/currencyAction";
import { getPartnerPymtTerms } from "../../../../../redux/actions/partnerPymtTermsAction";
import { getVendorPymtTerms } from "../../../../../redux/actions/vendorPymtTermsAction";
import { getPaymentStatus } from "../../../../../redux/actions/finance/invoice/paymentStatusAction";
import { getPOType } from "../../../../../redux/actions/finance/accounts_payable/issue_po/poTypeAction";
import { getUserBusinessLocations } from "../../../../../redux/actions/hcm/business_setup/businessLocationsAction";
import { getPOStatus } from "../../../../../redux/actions/finance/accounts_payable/issue_po/poStatusAction";
import FormComponent from "../../../../common/FormComponent";
import {
  errors,
  Validator,
  submitValidator,
  valid,
} from "../../../../common/Validator";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import Notifier from "../../../../aside/Notifier";

/**
 * @class AddInvoice
 **/

class UpdateIssuePO extends Component {
  constructor(props) {
    super(props);
    const {
      poNo,
      poName,
      poType,
      poTo,
      vpsName,
      vpsBillingAddress,
      contactName,
      contactEmail,
      contactMobileNo,
      shipToAddress,
      shipToLocation,
      shipToContactMobileEmail,
      shipToContactMobileNo,
      poCurrency,
      poDescription,
      pymtTerm,
      totalAmount,
      totalGst,
      poTotalAmount,
      pymtInstructions,
      issuePoStatus,
      remarks,
    } = this.props.issuePO;
    this.state = {
      poNo: poNo,
      poName: poName,
      poType: poType,
      poTo: poTo,
      name: vpsName,
      address: vpsBillingAddress,
      contactName: contactName,
      contactEmail: contactEmail,
      contactPhoneNumber: contactMobileNo,
      shipToLocation: shipToLocation,
      shipToAddress: shipToAddress,
      shipToContactEmail: shipToContactMobileEmail,
      shipToContactNo: shipToContactMobileNo,
      totalAmount: totalAmount,
      totalGst: totalGst,
      poTotalAmount: poTotalAmount,
      poCurrency: poCurrency,
      poDescription: poDescription,
      paymentTerms: pymtTerm,
      remarks: remarks,
      paymentInstructions: pymtInstructions,
      issuePoStatus: issuePoStatus,
      purchaseItemModal: false,
      purchaseItemsId: "",
      purchaseItemNo: "",
      pspName: "",
      quantity: "",
      amount: "",
      amountGST: "",
      pymtScheduleModal: false,
      scheduleId: "",
      scheduledPymtDate: "",
      pymtAmount: "",
      toggleUpdatePo: true,
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  componentDidMount() {
    this.props.getPOType();
    this.props.getPOStatus();
    let data = {
      poNo: this.state.poNo,
    };
    this.props.getPurchaseItems(data);
    this.props.getScheduledPymtDate(data);
  }

  // componentDidUpdate function to update the fields
  componentDidUpdate(prevProps, prevState) {
    if (prevState.amount !== this.state.amount) {
      this.setState({
        amountGST: (this.state.amount * 0.18).toFixed(2),
      });
    }
    if (
      prevProps.issuePOs.purchaseItemData !==
      this.props.issuePOs.purchaseItemData
    ) {
      if (this.props.issuePOs.purchaseItemData.length !== 0) {
        let arr = this.props.issuePOs.purchaseItemData;
        let sumAmount = 0;
        let sumAmountGst = 0;
        arr.forEach(function (obj) {
          sumAmount += obj.amount * obj.qty;
          sumAmountGst += obj.gstAmount * obj.qty;
        });
        this.setState({
          totalAmount: sumAmount,
          totalGst: sumAmountGst.toFixed(2),
          poTotalAmount:
            parseInt(sumAmount) + parseFloat(sumAmountGst.toFixed(2)),
        });
      }
    }
  }

  // onSubmit function to submit the form
  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);

    if (valid) {
      let data = {
        poNo: this.state.poNo,
        poType: this.state.poType,
        shipToContactMobileEmail: this.state.shipToContactEmail,
        shipToContactMobileNo: this.state.shipToContactNo,
        poDescription: this.state.poDescription,
        // pymntTerm: this.state.pymntTerm,
        totalAmount: this.state.totalAmount,
        totalGst: this.state.totalGst,
        poTotalAmount: this.state.poTotalAmount,
        pymtInstructions: this.state.paymentInstructions,
        issuePoStatus: this.state.issuePoStatus,
        remarks: this.state.remarks,
        updatedBy: localStorage.getItem("id"),
      };

      this.props.updateIssuePO(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all Mandatory Fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  // purchaseItemUpdate function for when click on the update button it will open the model to update the data
  purchaseItemUpdate = (item) => {
    this.setState({
      purchaseItemsId: item.purchaseItemsId,
      purchaseItemNo: item.purchaseItemNo,
      pspName: item.ppsName,
      quantity: item.qty,
      amount: item.amount,
      amountGST: item.gstAmount,
      purchaseItemModal: true,
    });
  };

  // purchaseItemToggle function to close the purchase update Modal
  purchaseItemToggle = () => {
    this.setState({
      purchaseItemModal: false,
    });
    let data1 = {
      poNo: this.state.poNo,
    };
    this.props.getPurchaseItems(data1);
  };

  // purchaseItemSubmit function to submit the puchase item updated changes
  purchaseItemSubmit = (e) => {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      purchaseItemsId: this.state.purchaseItemsId,
      poNo: this.state.poNo,
      purchaseItemNo: this.state.purchaseItemNo,
      ppsName: this.state.pspName,
      qty: this.state.quantity,
      amount: this.state.amount,
      gstAmount: this.state.amountGST,
    };
    if (valid) {
      this.props.updatePurchaseItem(data, this.purchaseItemToggle);
    } else {
      this.setState({
        error: "Enter all Mandatory Fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  };

  // scheduledPaymentUpdate function to open the schedule payment modal when we click on the edit button
  scheduledPaymentUpdate = (item) => {
    this.setState({
      scheduleId: item.scheduleId,
      scheduledPymtDate: item.scheduledPymtDate,
      pymtAmount: item.paymentAmount,
      pymtScheduleModal: true,
    });
  };

  // pymtScheduleToggle function to close the update schedule payment modal
  pymtScheduleToggle = () => {
    this.setState({
      pymtScheduleModal: false,
    });
    let data1 = {
      poNo: this.state.poNo,
    };
    this.props.getScheduledPymtDate(data1);
  };

  // pymtScheduleSubmit function to submit the updated schedule payment details
  pymtScheduleSubmit = (e) => {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      scheduleId: this.state.scheduleId,
      poNo: this.state.poNo,
      scheduledPymtDate: this.state.scheduledPymtDate,
      paymentAmount: this.state.pymtAmount,
    };
    if (valid) {
      this.props.updateScheduledPymtDate(data, this.pymtScheduleToggle);
    } else {
      this.setState({
        error: "Enter all Mandatory Fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  };

  render() {
    const {
      poName,
      poType,
      poTo,
      name,
      address,
      contactName,
      contactEmail,
      contactPhoneNumber,
      shipToLocation,
      shipToAddress,
      shipToContactEmail,
      shipToContactNo,
      totalAmount,
      totalGst,
      poTotalAmount,
      poCurrency,
      poDescription,
      paymentTerms,
      issuePoStatus,
      paymentInstructions,
      remarks,
      purchaseItemModal,
      purchaseItemNo,
      pspName,
      quantity,
      amount,
      amountGST,
      pymtScheduleModal,
      scheduledPymtDate,
      pymtAmount,
      error,
    } = this.state;

    return (
      <div>
        <h5 className="center bold pt-2">Update Issue PO</h5>

        <Row>
          <Col md="7">
            <Form onSubmit={this.onSubmit} className="p-3" noValidate>
              <Row>
                <Col md="6">
                  <FormComponent
                    label="PO Name"
                    labelClass="label-sm bold"
                    type="text"
                    value={poName}
                    className="form-control-sm"
                    // required={true}
                    disabled={true}
                  />
                  <FormGroup>
                    <Label className="label-sm bold">PO Type</Label>
                    <Input
                      type="select"
                      name="poType"
                      value={poType}
                      className="form-control-sm"
                      onChange={this.onChange}
                      required={true}
                    >
                      <option value="" hidden>
                        Select
                      </option>
                      {this.props.poTypes.data.map((item) => {
                        return (
                          <option key={item.poTypeId} value={item.poType}>
                            {item.poType}
                          </option>
                        );
                      })}
                    </Input>
                    {errors.poType && (
                      <FormText className="error">{errors.poType}</FormText>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label className="label-sm bold">PO To</Label>
                    <Input
                      type="text"
                      value={poTo}
                      className="form-control-sm"
                      // required={true}
                      disabled={true}
                    ></Input>
                  </FormGroup>
                  <FormGroup>
                    <Label className="label-sm bold">
                      Partner / Vendor Name
                    </Label>
                    <Input
                      type="text"
                      className="form-control-sm "
                      value={name}
                      // required={true}
                      disabled={true}
                    ></Input>
                  </FormGroup>
                  <FormComponent
                    label="Partner / Vendor Address"
                    labelClass="label-sm bold"
                    type="text"
                    value={address}
                    className="form-control-sm"
                    // required={true}
                    disabled={true}
                  />
                  <FormComponent
                    label="Contact Name"
                    labelClass="label-sm bold"
                    type="text"
                    value={contactName}
                    className="form-control-sm"
                    // required={true}
                    disabled={true}
                  />
                  <FormComponent
                    label="Contact Email"
                    labelClass="label-sm bold"
                    type="text"
                    value={contactEmail}
                    className="form-control-sm"
                    // required={true}
                    disabled={true}
                  />
                  <FormComponent
                    label="Contact Phone Number"
                    labelClass="label-sm bold"
                    type="tel"
                    value={contactPhoneNumber}
                    className="form-control-sm"
                    // required={true}
                    disabled={true}
                  />
                  <FormGroup>
                    <Label className="bold label-sm">Ship To Location</Label>
                    <Input
                      type="text"
                      name="shipToLocation"
                      className="form-control-sm "
                      value={shipToLocation}
                      onChange={this.onChange}
                      // required={true}
                      disabled={true}
                    ></Input>
                  </FormGroup>
                  <FormGroup>
                    <Label className="bold label-sm">Ship To Address</Label>
                    <Input
                      type="text"
                      className="form-control-sm "
                      value={shipToAddress}
                      // required={true}
                      disabled={true}
                    ></Input>
                  </FormGroup>
                  <FormComponent
                    label="PO Description"
                    labelClass="label-sm bold"
                    type="textarea"
                    name="poDescription"
                    value={poDescription}
                    className="form-control-sm"
                    change={this.onChange}
                    required={true}
                  />
                </Col>
                <Col md="6">
                  <FormComponent
                    label="Ship To Contact Email"
                    labelClass="label-sm bold"
                    type="email"
                    name="shipToContactEmail"
                    value={shipToContactEmail}
                    className="form-control-sm"
                    change={this.onChange}
                    required={true}
                  />
                  <FormComponent
                    label="Ship To Contact Number"
                    labelClass="label-sm bold"
                    type="tel"
                    name="shipToContactNo"
                    value={shipToContactNo}
                    className="form-control-sm"
                    change={this.onChange}
                    required={true}
                  />
                  <FormComponent
                    label="Total Amount"
                    labelClass="label-sm bold"
                    type="number"
                    name="totalAmount"
                    value={totalAmount}
                    className="form-control-sm"
                    required={true}
                    disabled={true}
                  />
                  <FormComponent
                    label="Total GST"
                    labelClass="label-sm bold"
                    type="number"
                    name="totalGst"
                    value={totalGst}
                    className="form-control-sm"
                    required={true}
                    disabled={true}
                  />
                  <FormComponent
                    label="PO Total Amount"
                    labelClass="label-sm bold"
                    type="number"
                    name="poTotalAmount"
                    value={poTotalAmount}
                    className="form-control-sm"
                    required={true}
                    disabled={true}
                  />

                  <FormGroup>
                    <Label className="bold label-sm">PO Currency</Label>
                    <Input
                      type="text"
                      className="form-control-sm "
                      value={poCurrency}
                      // required={true}
                      disabled={true}
                    ></Input>
                  </FormGroup>

                  <FormGroup>
                    <Label className="label-sm bold">Payment Terms</Label>
                    <Input
                      type="text"
                      className="form-control-sm"
                      value={paymentTerms}
                      // required={true}
                      disabled={true}
                    ></Input>
                  </FormGroup>
                  <FormComponent
                    label="Payment Instructions"
                    labelClass="label-sm bold"
                    type="text"
                    name="paymentInstructions"
                    value={paymentInstructions}
                    className="form-control-sm"
                    change={this.onChange}
                    required={true}
                  />
                  <FormGroup>
                    <Label className="label-sm bold">Issue PO Status</Label>
                    <Input
                      type="select"
                      name="issuePoStatus"
                      value={issuePoStatus}
                      className="form-control-sm"
                      onChange={this.onChange}
                      required={true}
                    >
                      <option value="" hidden>
                        Select
                      </option>
                      {this.props.issuePOStatus.data.map((item) => {
                        return (
                          <option
                            key={item.issuePoStatusId}
                            value={item.issuePoStatus}
                          >
                            {item.issuePoStatus}
                          </option>
                        );
                      })}
                    </Input>
                    {errors.issuePoStatus && (
                      <FormText className="error">
                        {errors.issuePoStatus}
                      </FormText>
                    )}
                  </FormGroup>
                  <FormComponent
                    label="Remarks"
                    labelClass="label-sm bold"
                    type="textarea"
                    name="remarks"
                    value={remarks}
                    className="form-control-sm"
                    change={this.onChange}
                    required={true}
                  />
                </Col>
              </Row>
              <div className="center">
                <button
                  type="submit"
                  className="btn center login-button white-text"
                >
                  Update
                </button>
              </div>
            </Form>
          </Col>
          <Col md="5">
            <Row className="updateIssuePo-purchaseItems-div">
              <div className="milestone-div">
                <h6 className="center">Purchase Items</h6>
                <Table>
                  <thead>
                    <tr>
                      <th className="opp-head">Name</th>
                      <th className="opp-head">Qty</th>
                      <th className="opp-head">Amount</th>
                      <th className="opp-head">GST</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.issuePOs.purchaseItemData &&
                      this.props.issuePOs.purchaseItemData.map((item) => {
                        return (
                          <tr key={item.purchaseItemsId}>
                            <td>{item.ppsName}</td>
                            <td>{item.qty}</td>
                            <td>{item.amount}</td>
                            <td>{item.gstAmount}</td>
                            <td>
                              <span
                                className="product-pq-button"
                                id="lead-activity-edit-button"
                                title="Edit"
                                style={{ color: "blue" }}
                                onClick={() => this.purchaseItemUpdate(item)}
                              >
                                edit
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </Row>
            <Row>
              <div className="task-div">
                <h6 className="center">Payment Schedule</h6>
                <Table>
                  <thead>
                    <tr>
                      <th className="opp-head">PO No</th>
                      <th className="opp-head">Date</th>
                      <th className="opp-head">Amount</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.issuePOs.scheduledPymtDateData.map((item) => {
                      return (
                        <tr key={item.scheduleId}>
                          <td>{item.poNo}</td>
                          <td>
                            {item.scheduledPymtDate &&
                              DateFns.format(
                                item.scheduledPymtDate,
                                "DD-MM-YYYY"
                              )}
                          </td>
                          <td>{item.paymentAmount}</td>
                          <td>
                            <span
                              className="product-pq-button"
                              id="lead-activity-edit-button"
                              title="Edit"
                              style={{ color: "blue" }}
                              onClick={() => this.scheduledPaymentUpdate(item)}
                            >
                              edit
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Row>
          </Col>
        </Row>

        <Modal isOpen={purchaseItemModal} size="md">
          <ModalHeader toggle={this.purchaseItemToggle}>
            Purchase Items
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={this.purchaseItemSubmit} noValidate>
              <Row>
                <Col md="6" className="mx-auto">
                  <FormComponent
                    label="Purchase Item Number"
                    labelClass="label-sm  bold"
                    className="form-control-sm"
                    type="number"
                    name="purchaseItemNo"
                    value={purchaseItemNo}
                    change={this.onChange}
                    required={true}
                  />
                  <FormComponent
                    label="Product/Service/Project Name"
                    labelClass="label-sm  bold"
                    className="form-control-sm"
                    type="text"
                    name="pspName"
                    value={pspName}
                    change={this.onChange}
                    required={true}
                  />
                  <FormComponent
                    label="Quantity"
                    labelClass="label-sm  bold"
                    className="form-control-sm"
                    type="number"
                    name="quantity"
                    value={quantity}
                    change={this.onChange}
                    required={true}
                  />
                  <FormComponent
                    label="Amount"
                    labelClass="label-sm  bold"
                    className="form-control-sm"
                    type="number"
                    name="amount"
                    value={amount}
                    change={this.onChange}
                    required={true}
                  />
                  <FormComponent
                    label="Amount GST"
                    labelClass="label-sm  bold"
                    className="form-control-sm"
                    type="number"
                    name="amountGST"
                    value={amountGST}
                    change={this.onChange}
                    required={true}
                    disabled={true}
                  />
                </Col>
              </Row>
              <div className="center">
                <button
                  type="submit"
                  className="btn center login-button white-text"
                >
                  Update
                </button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
        <Modal isOpen={pymtScheduleModal} size="md">
          <ModalHeader toggle={this.pymtScheduleToggle}>
            Schedule Date
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={this.pymtScheduleSubmit} noValidate>
              <Row>
                <Col md="6" className="mx-auto">
                  <FormComponent
                    label="Scheduled Payment Date"
                    labelClass="label-sm  bold"
                    className="form-control-sm"
                    type="date"
                    name="scheduledPymtDate"
                    value={scheduledPymtDate}
                    change={this.onChange}
                    required={true}
                  />
                  <FormComponent
                    label="Payment Amount"
                    labelClass="label-sm  bold"
                    className="form-control-sm"
                    type="number"
                    name="pymtAmount"
                    value={pymtAmount}
                    change={this.onChange}
                    required={true}
                  />
                </Col>
              </Row>
              <div className="center">
                <button
                  type="submit"
                  className="btn center login-button white-text"
                >
                  Update
                </button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
        {error && <ErrorNotifier message={error} />}
        {this.props.issuePOs.message && (
          <Notifier message={this.props.issuePOs.message} />
        )}
        {this.props.issuePOs.error && (
          <ErrorNotifier message={this.props.issuePOs.error} />
        )}
      </div>
    );
  }
}

UpdateIssuePO.propTypes = {
  getPartners: PropTypes.func.isRequired,
  partners: PropTypes.object.isRequired,
  getVendors: PropTypes.func.isRequired,
  vendors: PropTypes.object.isRequired,
  updateIssuePO: PropTypes.func.isRequired,
  updateScheduledPymtDate: PropTypes.func.isRequired,
  getScheduledPymtDate: PropTypes.func.isRequired,
  updatePurchaseItem: PropTypes.func.isRequired,
  getPurchaseItems: PropTypes.func.isRequired,
  issuePOs: PropTypes.object.isRequired,
  getCurrency: PropTypes.func.isRequired,
  currency: PropTypes.object.isRequired,
  getPartnerPymtTerms: PropTypes.func.isRequired,
  partnerPymtTerms: PropTypes.object.isRequired,
  getVendorPymtTerms: PropTypes.func.isRequired,
  vendorPymtTerms: PropTypes.object.isRequired,
  getPaymentStatus: PropTypes.func.isRequired,
  paymentStatus: PropTypes.object.isRequired,
  getPOType: PropTypes.func.isRequired,
  poTypes: PropTypes.object.isRequired,
  getPOStatus: PropTypes.func.isRequired,
  issuePOStatus: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  partners: state.partners,
  vendors: state.vendors,
  issuePOs: state.issuePOs,
  currency: state.currency,
  partnerPymtTerms: state.partnerPymtTerms,
  vendorPymtTerms: state.vendorPymtTerms,
  paymentStatus: state.paymentStatus,
  poTypes: state.poTypes,
  issuePOStatus: state.issuePOStatus,
});

export default connect(mapStateToProps, {
  getPartners,
  getVendors,
  updateIssuePO,
  updatePurchaseItem,
  getPurchaseItems,
  updateScheduledPymtDate,
  getScheduledPymtDate,
  getCurrency,
  getPartnerPymtTerms,
  getVendorPymtTerms,
  getPaymentStatus,
  getPOType,
  getPOStatus,
})(UpdateIssuePO);
