import React, { Component } from "react";
import { Row, Col, Card } from "reactstrap";
import ProspectsStatus from "./prospects_status/ProspectsStatus";
import ProspectsType from "./prospects_type/ProspectsType";
import SalesActivity from "./sales_activity/SalesActivity";
import SalesActivityStatus from "./sales_activity_status/SalesActivityStatus";
import DealType from "./sales_deal_type/DealType";
import OpportunitySource from "./opportunity_source/OpportunitySource";
import OpportunityStage from "./opportunity_stage/OpportunityStage";
import OpportunityType from "./opportunity_type/OpportunityType";
import Milestone from "./sales_milestone/Milestone";
import MilestoneStatus from "./sales_milestone_status/MilestoneStatus";
import MilestoneFeedback from "./sales_milestone_feedback/MilestoneFeedback";
import Potential from "./sales_potential/Potential";

class SalesSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: "",
    };

    this.back = this.back.bind(this);
    this.chooseForm = this.chooseForm.bind(this);
  }

  /*
back fuction to redirect to the back from details to myprofile tab page
*/
  back() {
    this.setState({
      edit: "",
    });
  }

  /*
chooseForm function to select the particular tab related view should display
*/
  chooseForm() {
    let component = this.state.edit;

    if (component === "prospectsStatus") {
      return <ProspectsStatus back={this.back} />;
    }
    if (component === "prospectsType") {
      return <ProspectsType back={this.back} />;
    }
    if (component === "salesActivity") {
      return <SalesActivity back={this.back} />;
    }
    if (component === "salesActivityStatus") {
      return <SalesActivityStatus back={this.back} />;
    }
    if (component === "dealType") {
      return <DealType back={this.back} />;
    }
    if (component === "opportunitySource") {
      return <OpportunitySource back={this.back} />;
    }
    if (component === "opportunityStage") {
      return <OpportunityStage back={this.back} />;
    }
    if (component === "opportunityType") {
      return <OpportunityType back={this.back} />;
    }
    if (component === "milestone") {
      return <Milestone back={this.back} />;
    }
    if (component === "milestoneStatus") {
      return <MilestoneStatus back={this.back} />;
    }
    if (component === "milestoneFeedback") {
      return <MilestoneFeedback back={this.back} />;
    }
    if (component === "potential") {
      return <Potential back={this.back} />;
    }
  }

  render() {
    let shrink = this.props.sidebar ? "scale" : "no-scale";

    return (
      <div className={shrink}>
        {!this.state.edit ? (
          <Row>
            <div className="platform-head">
              <p className="platform-p">Sales Setup</p>
            </div>
            <div className="platform-category-div">
              {/* <Row>
                <Col md="6">
                  <Card
                    className="myprofile-card"
                    onClick={() => this.setState({ edit: "prospectsStatus" })}
                  >
                    Prospects Status
                  </Card>
                </Col>
                <Col md="6">
                  <Card
                    className="myprofile-card"
                    onClick={() => this.setState({ edit: "prospectsType" })}
                  >
                    Prospects Type
                  </Card>
                </Col>
              </Row> */}
              <Row>
                <Col md="6">
                  <Card
                    className="myprofile-card"
                    onClick={() => this.setState({ edit: "salesActivity" })}
                  >
                    Sales Activity
                  </Card>
                </Col>
                <Col md="6">
                  <Card
                    className="myprofile-card"
                    onClick={() =>
                      this.setState({ edit: "salesActivityStatus" })
                    }
                  >
                    Sales Activity Status
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Card
                    className="myprofile-card"
                    onClick={() => this.setState({ edit: "dealType" })}
                  >
                    Deal Type
                  </Card>
                </Col>
                <Col md="6">
                  <Card
                    className="myprofile-card"
                    onClick={() => this.setState({ edit: "opportunityStage" })}
                  >
                    Opportunity Stage
                  </Card>
                </Col>
                {/* <Col md="6">
                  <Card
                    className="myprofile-card"
                    onClick={() => this.setState({ edit: "opportunitySource" })}
                  >
                    Opportunity Source
                  </Card>
                </Col> */}
              </Row>
              <Row>
                <Col md="6">
                  <Card
                    className="myprofile-card"
                    onClick={() => this.setState({ edit: "milestone" })}
                  >
                    Milestone/Goal
                  </Card>
                </Col>
                <Col md="6">
                  <Card
                    className="myprofile-card"
                    onClick={() => this.setState({ edit: "milestoneStatus" })}
                  >
                    Milestone Status
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Card
                    className="myprofile-card"
                    onClick={() => this.setState({ edit: "milestoneFeedback" })}
                  >
                    Milestone Feedback
                  </Card>
                </Col>
                <Col md="6">
                  <Card
                    className="myprofile-card"
                    onClick={() => this.setState({ edit: "potential" })}
                  >
                    Sales Potential
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Card
                    className="myprofile-card"
                    onClick={() => this.setState({ edit: "opportunityType" })}
                  >
                    Opportunity Type
                  </Card>
                </Col>
              </Row>
            </div>
          </Row>
        ) : (
          this.chooseForm()
        )}
      </div>
    );
  }
}

export default SalesSetup;
