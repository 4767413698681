import React, { Component } from "react";
import { Form, Row, Col, FormGroup, Label, Input, FormText } from "reactstrap";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import { addSalesMilestoneFeedback } from "../../../../../redux/actions/salesMilestoneFeedbackAction";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class AddMilestoneFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      milestoneFeedbackName: "",
      description: "",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      milestoneFeedbackName: this.state.milestoneFeedbackName,
      description: this.state.description,
      createdBy: localStorage.getItem("id"),
    };
    if (valid) {
      this.props.addSalesMilestoneFeedback(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidMount contains the error refresh method and setState method for calling the render method
*/
  componentDidMount() {
    refresh();
    this.setState({
      error: "",
    });
  }

  render() {
    const { milestoneFeedbackName, description, error } = this.state;

    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Add Milestone Feedback</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="6" className="mx-auto">
              <FormComponent
                labelClass="bold label-sm"
                label="Milestone Feedback"
                type="text"
                name="milestoneFeedbackName"
                inputClass="form-control-sm"
                value={milestoneFeedbackName}
                change={this.onChange}
                required={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Description"
                type="textarea"
                name="description"
                inputClass="form-control-sm"
                value={description}
                change={this.onChange}
                required={true}
              />
            </Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Add
            </button>
          </div>
        </Form>
        {error && <ErrorNotifier message={error} />}
        {this.props.salesMilestoneFeedback.error && (
          <ErrorNotifier message={this.props.salesMilestoneFeedback.error} />
        )}
        {this.props.salesMilestoneFeedback.message && (
          <ErrorNotifier message={this.props.salesMilestoneFeedback.message} />
        )}
      </React.Fragment>
    );
  }
}

AddMilestoneFeedback.propTypes = {
  addSalesMilestoneFeedback: PropTypes.func.isRequired,
  salesMilestoneFeedback: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  salesMilestoneFeedback: state.salesMilestoneFeedback,
});

export default connect(mapStateToProps, { addSalesMilestoneFeedback })(
  AddMilestoneFeedback
);
