import {
    PRODUCT_LIST,
    PRODUCT_ADD,
    PRODUCT_SEARCH,
    PRODUCT_UPDATE,
    SET_MESSAGE
  } from '../actions/types'
  const initialState = {
    data: [],
    message: null,
    buttonDisable: false
  };
  
  export default function(state = initialState, action){
    switch(action.type){
      case PRODUCT_ADD:
        return {
          ...state,
          buttonDisable: action.payload
        }
      case PRODUCT_LIST:
        return {
          ...state,
          data: action.payload
        };
      case PRODUCT_UPDATE:
        return {
          ...state,
          data: action.payload
        }
      case PRODUCT_SEARCH:
        return {
          ...state,
          data: action.payload
        }
      case SET_MESSAGE:
        return {
          ...state,
          message: action.payload
        }
      default:
        return state;
    }
  }