import {
  CUSTOMERS_GET,
  CUSTOMERS_FETCH,
  CUSTOMERS_SEARCH,
  REQUIREMENT_GET,
  RS_SEARCH_BY_ID,
  RS_SEARCH_BY_STRING,
  GET_ERRORS,
  SET_MESSAGE,
} from "../../../actions/types";

const initialState = {
  data: [],
  fetchData: [],
  reqIdData: [],
  message: null,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUIREMENT_GET:
      return {
        ...state,
        data: action.payload,
      };
    case REQUIREMENT_GET:
      return {
        ...state,
        reqIdData: action.payload,
      };
    case RS_SEARCH_BY_ID:
      return {
        ...state,
        data: action.payload,
      };
    case RS_SEARCH_BY_STRING:
      return {
        ...state,
        data: action.payload,
      };
    case CUSTOMERS_SEARCH:
      return {
        ...state,
        data: action.payload,
      };
    case CUSTOMERS_FETCH:
      return {
        ...state,
        fetchData: action.payload,
      };
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case GET_ERRORS:
      return {
        ...state,
        error: action.payload,
      };
    case "RS_ID_EMPTY_ARRAY":
      return {
        ...state,
        error: action.payload,
      };
    case "RS_STRING_EMPTY_ARRAY":
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
