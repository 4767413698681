import {
    // ADD_NEW_CUSTOMER,
    // GET_CUSTOMER_LIST,
    // UPDATE_CUSTOMER,
    USER_ROLE_SEARCH,
    // SET_MESSAGE
  } from '../actions/types'
  const initialState = {
    data: [],
    message: null
  };
  
  export default function(state = initialState, action){
    switch(action.type){
      case USER_ROLE_SEARCH:
        return {
          ...state,
          data: action.payload
        }
        default:
            return state;
        }
      }