//  eslint-disable
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
} from "reactstrap";
import {
  getProducts,
  updateProduct,
  searchProduct,
} from "../../../../redux/actions/productAction";
import {
  refresh,
  submitValidator,
  Validator,
  valid,
} from "../../../common/Validator";
import { getCustomers } from "../../../../redux/actions/customerAction";
import ProductDetails from "./ProductDetails";
import UpdateProduct from "./UpdateProduct";
import Hover from "../../../aside/Hover";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import FormComponent from "../../../common/FormComponent";

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      permissionlist: this.props.auth.permissionlist || {},
      subItem: {},
      selecion: null,
      selected: {},
      hover: false,
      cords: {},
      mouse: {},
      searchTerm: "",
      update: false,
      popup: false,
      modal: false,
      remarks: "",
      message: null,
      error: null,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.select = this.select.bind(this);
    this.back = this.back.bind(this);
    this.getData = this.getData.bind(this);
    this.search = this.search.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.beforeUpdate = this.beforeUpdate.bind(this);
    this.afterUpdate = this.afterUpdate.bind(this);
    this.toggleHoverOn = this.toggleHoverOn.bind(this);
    this.toggleHoverOff = this.toggleHoverOff.bind(this);
    this.toggle1 = this.toggle1.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.products !== this.props.products) {
      this.setState({
        data: this.props.products.data,
      });
    }

    if (prevProps.auth !== this.props.auth) {
      this.setState({
        permissionlist: this.props.auth.permissionlist,
      });
    }
  }

  search(e) {
    e.preventDefault();
    this.setState({
      searchTerm: e.target.value,
    });
    this.onSearch(e.target.value);
  }

  onSearch(value) {
    let data = {};
    let isInteger = value.length && /^[0-9]*$/.test(value);
    if (isInteger) {
      data.productId = Number(value);
      data.productName = "Null";
    } else {
      data.productId = 0;
      data.productName = value;
    }
    this.props.searchProduct(data);
  }

  getData() {
    this.props.getProducts();
  }

  componentDidMount() {
    this.getData();
    this.setState({
      permissionlist: this.props.auth.permissionlist,
    });
    this.props.getCustomers();
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      remarks: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    const data = this.state.data.filter((item) => {
      if (item.productId === this.state.subItem) {
        return item;
      } else {
        return null;
      }
    });
    let data1 = {
      productId: data[0].productId,
      productName: data[0].productName,
      compCode: data[0].compCode,
      accountId: data[0].accountId,
      licenseQty: data[0].licenseQty,
      activationStartDate: data[0].activationStartDate,
      activationEndDate: data[0].activationEndDate,
      productStatus: data[0].status,
      remarks: this.state.remarks,
      updatedBy: localStorage.getItem("id"),
    };
    if (valid) {
      this.props.updateProduct(data1);
      setTimeout(() => {
        this.toggle();
        this.getData();
      }, 2000);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  toggleHoverOn(e, item) {
    this.setState({
      hover: true,
      cords: {
        one: item.accountId,
        two: item.licenseQty,
        three: item.remarks,
      },
      mouse: {
        x: e.screenX,
        y: e.screenY,
      },
    });
  }

  toggleHoverOff() {
    this.setState({
      hover: false,
    });
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
      remarks: "",
    });
  }

  /*
Toggle1 function for the modal cancel button here called the getData function 
*/
  toggle1() {
    this.setState({
      modal: !this.state.modal,
    });
    this.getData();
  }

  onDragOver(e) {
    e.preventDefault();
  }

  select(item) {
    this.setState({
      selection: item,
      selected: item.productId,
      popup: true,
    });
  }

  onDragStart(e, item) {
    e.dataTransfer.setData("item", item.productId);
    this.setState({
      subItem: item.productId,
    });
  }

  async onDrop(e, cat) {
    let id = e.dataTransfer.getData("item");
    let newData;
    newData = this.state.data.filter((item) => {
      if (item.productId === parseInt(id, 10)) {
        item.status = cat;
      }
      return newData;
    });
    this.setState({
      ...this.state,
      newData,
    });
    await this.toggle();
  }

  beforeUpdate() {
    this.setState({
      update: true,
    });
  }

  afterUpdate() {
    this.setState({
      update: false,
      popup: false,
    });
    this.getData();
    refresh();
  }

  back() {
    this.setState({
      popup: false,
    });
  }

  render() {
    let shrink;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");
    const {
      data,
      selection,
      selected,
      hover,
      cords,
      modal,
      popup,
      remarks,
      update,
      error,
      permissionlist,
    } = this.state;
    let currentPermission;

    for (let x in permissionlist.response.permissions1) {
      if (permissionlist.response.permissions1[x].subMenuId === "6.1") {
        currentPermission = permissionlist.response.permissions1[x];
      }
    }

    return (
      <div className={shrink}>
        {popup && (
          <ProductDetails
            detail={selection}
            update={this.beforeUpdate}
            back={this.back}
            getData={this.getData}
          />
        )}
        <Modal isOpen={modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle1}>Confirm changes?</ModalHeader>
          <ModalBody>
            <Form className="p-3" onSubmit={this.onSubmit} noValidate>
              <FormComponent
                labelClass="bold label-sm"
                label="Remarks"
                type="text"
                name="remarks"
                change={this.onChange}
                value={remarks}
                required={true}
              />
              <FormGroup className="center">
                <button type="submit" className="btn login-button white-text">
                  Submit
                </button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
        {!update ? (
          <Container className="card container-card">
            <input
              type="text"
              onChange={this.search}
              value={this.state.searchTerm}
              className="searchfield form-control-sm"
              placeholder="ProductId/Name"
            />
            {hover && (
              <Hover
                cords={cords}
                labels={{
                  one: "AccountID",
                  two: "License Quantity",
                  three: "Remarks",
                }}
                mouse={this.state.mouse}
              />
            )}
            {currentPermission.create1 ? (
              <div>
                <Link to="/pie/pps/add/product">
                  <button className=" btn add-button white-text label-sm">
                    Add
                  </button>
                </Link>
              </div>
            ) : null}
            <h5 className="bold pointer center pt-2">Products</h5>
            <Row>
              <Col
                md="4"
                onDragOver={this.onDragOver}
                onDrop={(e) => this.onDrop(e, "New")}
                className="card p-2"
              >
                <h6 className="center bold m-2">New</h6>
                <ul className="list flex-around  ul-head">
                  <li className="list-crm-item bold pad-product-id">ID</li>
                  <li className="list-crm-item bold pad-product-name">
                    Product Name
                  </li>
                  <li className="list-crm-item bold pad-product-name">
                    Customer Name
                  </li>
                  <li className="list-crm-item bold pad-product-status">
                    Status
                  </li>
                </ul>
                {data &&
                  data.map((item) => {
                    if (item.productStatus === "New") {
                      return (
                        <ul
                          key={item.productId}
                          onClick={() => this.select(item)}
                          draggable
                          onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                          onMouseLeave={this.toggleHoverOff}
                          onDragStart={(e) => this.onDragStart(e, item)}
                          className={`list flex-around pointer ${
                            selected &&
                            selected === item.productId &&
                            "selected-item"
                          } InqList`}
                        >
                          <li className="list-crm-table-item pad-product-id">
                            {item.productId}
                          </li>
                          <li className="list-crm-table-item pad-product-name">
                            {item.productName}
                          </li>
                          {this.props.customers &&
                            this.props.customers.data.map((custNameItem) => {
                              if (custNameItem.compCode === item.compCode) {
                                return (
                                  <li
                                    className="list-crm-table-item pad-product-name"
                                    key={custNameItem.compCode}
                                  >
                                    {custNameItem.compFullName}
                                  </li>
                                );
                              }
                              return null;
                            })}
                          <li className="list-crm-table-item pad-product-status">
                            {item.productStatus}
                          </li>
                        </ul>
                      );
                    } else {
                      return null;
                    }
                  })}
              </Col>
              <Col
                md="4"
                onDragOver={this.onDragOver}
                onDrop={(e) => this.onDrop(e, "Active")}
                className="card p-2"
              >
                <h6 className="center bold m-2">Active</h6>
                <ul className="list flex-around  ul-head">
                  <li className="list-crm-item bold pad-product-id">ID</li>
                  <li className="list-crm-item bold pad-product-name">
                    Product Name
                  </li>
                  <li className="list-crm-item bold pad-product-name">
                    Customer Name
                  </li>
                  <li className="list-crm-item bold pad-product-status">
                    Status
                  </li>
                </ul>
                {data &&
                  data.map((item) => {
                    if (item.productStatus === "Active") {
                      return (
                        <ul
                          key={item.productId}
                          onClick={() => this.select(item)}
                          draggable
                          onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                          onMouseLeave={this.toggleHoverOff}
                          onDragStart={(e) => this.onDragStart(e, item)}
                          className={`list flex-around pointer ${
                            selected &&
                            selected === item.productId &&
                            "selected-item"
                          } InqList`}
                        >
                          <li className="list-crm-table-item pad-product-id">
                            {item.productId}
                          </li>
                          <li className="list-crm-table-item pad-product-name">
                            {item.productName}
                          </li>
                          {this.props.customers &&
                            this.props.customers.data.map((custNameItem) => {
                              if (custNameItem.compCode === item.compCode) {
                                return (
                                  <li
                                    className="list-crm-table-item pad-product-name"
                                    key={custNameItem.compCode}
                                  >
                                    {custNameItem.compFullName}
                                  </li>
                                );
                              }
                              return null;
                            })}
                          <li className="list-crm-table-item pad-product-status">
                            {item.productStatus}
                          </li>
                        </ul>
                      );
                    } else {
                      return null;
                    }
                  })}
              </Col>
              <Col
                md="4"
                className="card p-2"
                onDrop={(e) => this.onDrop(e, "InActive")}
                onDragOver={this.onDragOver}
              >
                <h6 className="center bold m-2">InActive</h6>
                <ul className="list flex-around  ul-head">
                  <li className="list-crm-item bold pad-product-id">ID</li>
                  <li className="list-crm-item bold pad-product-name">
                    Product Name
                  </li>
                  <li className="list-crm-item bold pad-product-name">
                    Customer Name
                  </li>
                  <li className="list-crm-item bold pad-product-status">
                    Status
                  </li>
                </ul>
                {data &&
                  data.map((item) => {
                    if (
                      item.productStatus !== "New" &&
                      item.productStatus !== "Active"
                    ) {
                      return (
                        <ul
                          key={item.productId}
                          onClick={() => this.select(item)}
                          draggable
                          onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                          onMouseLeave={this.toggleHoverOff}
                          onDragStart={(e) => this.onDragStart(e, item)}
                          className={`list flex-around pointer ${
                            selected &&
                            selected === item.productId &&
                            "selected-item"
                          } InqList`}
                        >
                          <li className="list-crm-table-item pad-product-id">
                            {item.productId}
                          </li>
                          <li className="list-crm-table-item pad-product-name">
                            {item.productName}
                          </li>
                          {this.props.customers &&
                            this.props.customers.data.map((custNameItem) => {
                              if (custNameItem.compCode === item.compCode) {
                                return (
                                  <li
                                    className="list-crm-table-item pad-product-name"
                                    key={custNameItem.compCode}
                                  >
                                    {custNameItem.compFullName}
                                  </li>
                                );
                              }
                              return null;
                            })}
                          <li className="list-crm-table-item pad-product-status">
                            {item.productStatus}
                          </li>
                        </ul>
                      );
                    } else {
                      return null;
                    }
                  })}
              </Col>
            </Row>
          </Container>
        ) : (
          <UpdateProduct item={selection} back={this.afterUpdate} />
        )}
        {this.props.products.message ? (
          <Notifier message={this.props.products.message} />
        ) : null}
        {error ? <ErrorNotifier message={error} /> : null}
      </div>
    );
  }
}

Products.propTypes = {
  getProducts: PropTypes.func.isRequired,
  updateProduct: PropTypes.func.isRequired,
  searchProduct: PropTypes.func.isRequired,
  products: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  getCustomers: PropTypes.func.isRequired,
  customers: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  products: state.products,
  auth: state.auth,
  errors: state.errors,
  customers: state.customers,
});

export default connect(mapStateToProps, {
  getProducts,
  updateProduct,
  searchProduct,
  getCustomers,
})(Products);
