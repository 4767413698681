import { GET_ERRORS } from "../types";
import { HOME } from "../../../utils/config";

// -------------------------------------------------------------------

const setErrorMessage = (error) => (dispatch) => {
  if (error.response) {
    if (error.response.data.error === "Session Expired") {
      window.location = HOME;
    }
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data.message,
    });
    setTimeout(() => {
      dispatch({
        type: GET_ERRORS,
        payload: null,
      });
    }, 3000);
  } else {
    dispatch({
      type: GET_ERRORS,
      payload: error.message,
    });
    setTimeout(() => {
      dispatch({
        type: GET_ERRORS,
        payload: null,
      });
    }, 3000);
  }
};

export default setErrorMessage;
