import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Form, FormGroup, Label, Input, FormText } from "reactstrap";
// components
import { textChecker } from "../../../../common/FormValidator";
import FormComponent from "../../../../common/FormComponent";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../../common/Validator";
// redux
import { addDialingCode } from "../../../../../redux/actions/hcm/configurations/dialingCodeAction";
import { getUserBusinessCountries } from "../../../../../redux/actions/hcm/business_setup/businessCountriesAction";

// --------------------------------------------------------------------------------------------------------------
class AddDialingCode extends Component {
  constructor() {
    super();
    this.state = {
      countryName: "",
      dialingCode: "",
      remarks: "",
      message: "",
      error: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  /* This onBlur function is giving validation to the AddDialingCode form
    for condition checking we used switch conditions
*/
  onBlur(e) {
    const { name, value } = e.target;
    let errors = this.state.formErrors;

    switch (name) {
      case "day":
        errors.day = textChecker.test(value)
          ? ""
          : "Use alphabets , numbers or spaces";
        break;
      case "occassion":
        errors.occassion = textChecker.test(value)
          ? ""
          : "Use alphabets , numbers or spaces";
        break;
      default:
        break;
    }
    this.setState({
      formErrors: errors,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      countryName: this.state.countryName,
      dialingCode: this.state.dialingCode,
      remarks: this.state.remarks,
    };
    if (valid) {
      this.props.addDialingCode(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    this.props.getUserBusinessCountries();
    refresh();
  }

  render() {
    return (
      <React.Fragment>
        <h5 className="bold center pt-2">Add Dialing Code</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>

        <Row>
          <Col md="2"></Col>
          <Col md="8">
            <Form className="p-3" onSubmit={this.onSubmit} noValidate>
              <FormGroup>
                <Label className="bold label-sm">Country</Label>
                <Input
                  type="select"
                  name="countryName"
                  className="form-control-sm"
                  value={this.state.countryName}
                  onChange={this.onChange}
                  required={true}
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {this.props.businessCountries.data.map((item) => {
                    return (
                      <option key={item.id} value={item.countryName}>
                        {item.countryName}
                      </option>
                    );
                  })}
                </Input>
                {errors.countryName && (
                  <FormText className="error">{errors.countryName}</FormText>
                )}
              </FormGroup>
              <FormComponent
                labelClass="bold label-sm"
                label="Dialing Code"
                type="number"
                name="dialingCode"
                inputClass="form-control-sm"
                value={this.state.dialingCode}
                change={this.onChange}
                required={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Remarks"
                type="textarea"
                name="remarks"
                inputClass="form-control-sm"
                value={this.state.remarks}
                change={this.onChange}
                required={true}
              />
              <div className="center">
                <button type="submit" className="btn login-button white-text">
                  Submit
                </button>
              </div>
            </Form>
          </Col>
        </Row>
        {this.props.dialingCode.message && (
          <Notifier message={this.props.dialingCode.message} />
        )}
        {this.props.dialingCode.error && (
          <ErrorNotifier message={this.props.dialingCode.error} />
        )}
      </React.Fragment>
    );
  }
}

AddDialingCode.propTypes = {
  addDialingCode: PropTypes.func.isRequired,
  dialingCode: PropTypes.object.isRequired,
  getUserBusinessCountries: PropTypes.func.isRequired,
  businessCountries: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  dialingCode: state.dialingCode,
  businessCountries: state.businessCountries,
});

export default connect(mapStateToProps, {
  addDialingCode,
  getUserBusinessCountries,
})(AddDialingCode);
