import React, { Component } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { Card } from "reactstrap";
import axios from "axios";
import { GET_MONTHLY_CAMP_PLAN } from "../../../../utils/routes";

// const data = [
//   {
//     name: "Aug",
//     "Planned Budget": "2000",
//   },
//   {
//     name: "Sept",
//     "Planned Budget": "300",
//   },
//   {
//     name: "Oct",
//     "Planned Budget": "40000000",
//   },
// ];

const xAxisStyle = {
  fontSize: "8px",
  fontWeight: "400",
  marginBottom: "10px",
};

export default class MonthlyCampPlan extends Component {
  static jsfiddleUrl = "https://jsfiddle.net/alidingling/30763kr7/";

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      error: "",
    };
  }

  componentDidMount() {
    axios
      .get(GET_MONTHLY_CAMP_PLAN, { withCredentials: true })
      .then((res) => {
        this.setState({
          data: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          error: err,
        });
      });
  }

  render() {
    const { data } = this.state;

    return (
      <Card className="recharts-card">
        <BarChart
          width={310}
          height={150}
          data={data}
          margin={{
            top: 5,
            right: 35,
            left: -15,
            bottom: 5,
          }}
          barSize={20}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="Month"
            tick={{ fontSize: "10px", fontWeight: "400" }}
          />
          <YAxis
            tick={{ fontSize: "10px", fontWeight: "400" }}
            domain={[0, "dataMax"]}
          />
          <Tooltip contentStyle={{ fontSize: "10px", fontWeight: "400" }} />
          <Legend iconSize={8} wrapperStyle={xAxisStyle} />
          <Bar dataKey="planned Budget" fill="#8884d8" />
        </BarChart>
      </Card>
    );
  }
}
