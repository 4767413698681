import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Row, Col, Card } from "reactstrap";
import { connect } from "react-redux";
import axios from "axios";
import DateFns from "date-fns";
import { Divider } from "@material-ui/core";
import UpdateTaggedRequirement from "./updateTaggedRequirement";

import DetailedPageFooter from './DetailsPageViewFooter'
// redux
import { GET_CAN_TAGGED_DETAILS } from "../../../../../utils/routes";
import { getTaggedDetails } from "../../../../../redux/actions/rs/requirements/requiremntsAction";
import {getRequirementTracking} from "../../../../../redux/actions/rs/candidate/tagRequirement"
// --------------------------------------------------------------------------------

function viewTaggedDetails(props) {
  const [data, setData] = useState([]);
  const [edit,setEdit] = useState(false)
  const[uniqueData,setUniqueData] = useState(null)

  useEffect(() => {
    getParticularData()
    }, []);


  const getParticularData=()=>{
  props.getRequirementTracking(props.reqType)
  
  }


  const updateHandleClick = (res) =>{
    console.log(res)
    setEdit(!edit)
    setUniqueData(res)
    getParticularData()
  }

  return (
    <div>
      {/* <Modal size="xl" show={props.taggedView} onHide={props.closeModel}> */}
        {!edit && (
          <div>
           
            {/* <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <Modal.Title style={{fontSize:"1.25rem",fontWeight:400}}>View Tagged Requirements</Modal.Title>
            </div> */}

            <Modal.Body>
              <Row className="mt-3">
                {props.TaggedReqTracking.data.length > 0 &&
                  props.TaggedReqTracking.data.map((item) => (
                    <Col md="4" key={item.id} className="employee-list-col">
                      <Card className="p-2 mb-2 shadow pointer">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <i
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Update Requirement"
                            style={{ color: "green" }}
                            className="material-icons pointer edit-btn"
                            onClick={() => updateHandleClick(item)}
                            // onClick={() => this.setState({ edit: !this.state.edit })}
                          >
                            edit
                          </i>
                        </div>
                        <ul className="CustDetails-ul employee-list-ul">
                        <li>
                            <span className="label-sm bold emp-span-label-rs">
                              {" "}
                              Req. Id
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span style={{fontSize:".75rem ",fontWeight:400}} className="employee-list-span-data-rs">
                                {item.jobRequirementId}
                              </span>
                            </span>
                          </li>
                          <li>
                            <span className="label-sm bold emp-span-label-rs">
                              {" "}
                              Can Info Id
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span style={{fontSize:".75rem ",fontWeight:400}} className="employee-list-span-data-rs">
                                {item.canInfoId}
                              </span>
                            </span>
                          </li>
                          <li>
                            <span className="label-sm bold emp-span-label-rs">
                              Tac Name
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span style={{fontSize:".75rem ",fontWeight:400}} className="employee-list-span-data-rs">
                                {item.tacName}
                              </span>
                            </span>
                          </li>
                          <li>
                            <span className="label-sm bold emp-span-label-rs">
                              Tac Email
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span style={{fontSize:".75rem ",fontWeight:400}} className="employee-list-span-data-rs">
                                {item.tacEmail}
                              </span>
                            </span>
                          </li>
                          <li>
                            <span className="label-sm bold emp-span-label-rs">
                              Tac Contact Number
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span style={{fontSize:".75rem ",fontWeight:400}} className="employee-list-span-data-rs">
                                {item.tacConNum}
                              </span>
                            </span>
                          </li>
                          <li>
                            <span className="label-sm bold emp-span-label-rs">
                              Status
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span style={{fontSize:".75rem ",fontWeight:400}} className="employee-list-span-data-rs">
                                {item.candidateStatus}
                              </span>
                            </span>
                          </li>
                          <li>
                            <span className="label-sm bold emp-span-label-rs">
                              Hiring Stage
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span style={{fontSize:".75rem ",fontWeight:400}} className="employee-list-span-data-rs">
                                {item.hiringStage}
                              </span>
                            </span>
                          </li>
                          <li>
                            <span className="label-sm bold emp-span-label-rs">
                             Hiring Status
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span style={{fontSize:".75rem ",fontWeight:400}} className="employee-list-span-data-rs">
                                {item.hiringStatus}
                              </span>
                            </span>
                          </li>
                          <li>
                            <span className="label-sm bold emp-span-label-rs">
                              Joining Date
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span style={{fontSize:".75rem ",fontWeight:400}} className="employee-list-span-data-rs">
                                { item.dateOfJoin ? DateFns.format(item.dateOfJoin, "DD-MM-YYYY"):""}
                              </span>
                            </span>
                          </li>
                          <li>
                            <span className="label-sm bold emp-span-label-rs">
                              Comments
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span style={{fontSize:".75rem ",fontWeight:400}} className="employee-list-span-data-rs">
                                {item.comment}
                              </span>
                            </span>
                          </li>
                          <li>
                            <span className="label-sm bold emp-span-label-rs">
                             Candidate Source
                            </span>
                            <span className="label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span  style={{fontSize:".75rem ",fontWeight:400}} className="employee-list-span-data-rs">
                                {item.candidateSource}
                              </span>
                            </span>
                          </li>
                        </ul>
                        <Divider style ={{marginTop:"0.5rem"}} />
                        <DetailedPageFooter
                          createdBy={item.taggedBy}
                          createdOn={item.taggedOn}
                          updatedBy={item.updatedBy}
                          updatedOn={item.updatedOn}
                        />
                      </Card>
                    </Col>
                  ))}
              </Row>
            </Modal.Body>
            <Modal.Footer>
          
            </Modal.Footer>
          </div>
        )}
        {edit && (
          <div>
            <UpdateTaggedRequirement
              fetchData={uniqueData}
              navigate={updateHandleClick}
            />
          </div>
        )}
      {/* </Modal> */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  requirements: state.requirements,
  TaggedReqTracking:state.TaggedReqTracking
});

export default connect(mapStateToProps, { getTaggedDetails,getRequirementTracking })(
  viewTaggedDetails
);
