import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// components
import UpdateContactDetails from "./UpdateContactDetails";
// redux
import { getContacts } from "../../../../../../redux/actions/hcm/my_profile/contacts_details/contactsAction";

class ContactDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateBtn: false,
    };
    this.openUpdateForm = this.openUpdateForm.bind(this);
    this.closeUpdateForm = this.closeUpdateForm.bind(this);
    this.getData = this.getData.bind(this);
  }

  openUpdateForm() {
    this.setState({
      updateBtn: true,
    });
  }

  closeUpdateForm() {
    this.setState({
      updateBtn: false,
    });
    this.getData();
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.props.getContacts();
  }

  render() {
    const { addBtn, updateBtn } = this.state;

    return (
      <React.Fragment>
        {!addBtn && !updateBtn ? (
          <React.Fragment>
            <i
              className="material-icons pointer contact-edit-btn"
              onClick={this.openUpdateForm}
            >
              edit
            </i>
            <h5 className="bold center">Contact Details</h5>
            <ul>
              <li>
                <span className="label-sm bold contact-details-span">
                  Home Contact No.
                </span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  {this.props.contacts.data.length
                    ? `${this.props.contacts.data[0].homeDialingCode}-${this.props.contacts.data[0].homeContactNo}`
                    : null}
                </span>
              </li>
              <li>
                <span className="label-sm bold contact-details-span">
                  Alternative No.
                </span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  {this.props.contacts.data.length
                    ? `${this.props.contacts.data[0].alternativeDialingCode}-${this.props.contacts.data[0].alternativeNo}`
                    : null}
                </span>
              </li>
            </ul>
          </React.Fragment>
        ) : null}
        {updateBtn && (
          <UpdateContactDetails
            back={this.closeUpdateForm}
            data={this.props.contacts.data}
          />
        )}
      </React.Fragment>
    );
  }
}

ContactDetails.propTypes = {
  getContacts: PropTypes.func.isRequired,
  contacts: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  contacts: state.contacts,
});

export default connect(mapStateToProps, { getContacts })(ContactDetails);
