import React, { Component } from "react";
import { Form, Row, Col, FormGroup, Label, FormText, Input } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../../../common/Validator";
import FormComponent from "../../../../../common/FormComponent";
import Notifier from "../../../../../aside/Notifier";
import ErrorNotifier from "../../../../../aside/ErrorNotifier";
import { addModule } from "../../../../../../redux/actions/iam/auth_configuration/menu_management/moduleAction";

class AddModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appId: "",
      moduleName: "",
      moduleGroupId: "",
      moduleBrowserLink: "",
      message: "",
      error: "",
      btnDisable: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      pieUserApplicationId: this.state.appId,
      moduleName: this.state.moduleName,
      moduleGroupId: this.state.moduleGroupId,
      moduleBrowserLink: this.state.moduleBrowserLink,
    };
    if (valid) {
      this.props.addModule(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidMount contains the error refresh method and setState method to call the render method
*/
  componentDidMount() {
    refresh();
    this.setState({
      message: "",
      error: "",
    });
  }

  render() {
    const {
      appId,
      moduleName,
      moduleGroupId,
      moduleBrowserLink,
      error,
      message,
      btnDisable,
    } = this.state;

    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Add Module</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="3"></Col>
            <Col md="6">
              <FormGroup>
                <Label className="bold label-sm">Application</Label>
                <Input
                  type="select"
                  name="appId"
                  value={appId}
                  onChange={this.onChange}
                  className="form-control-sm"
                  required={true}
                >
                  <option hidden value="">
                    Select....
                  </option>
                  {this.props.menuData &&
                    this.props.menuData.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.appName}-{item.appGroupId}
                        </option>
                      );
                    })}
                </Input>
                {errors.appId && (
                  <FormText className="error">{errors.appId}</FormText>
                )}
              </FormGroup>
              <FormComponent
                labelClass="bold label-sm"
                label="Module Name"
                type="text"
                name="moduleName"
                value={moduleName}
                change={this.onChange}
                required={true}
                minLength="2"
                maxLength="25"
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Group Id"
                type="number"
                name="moduleGroupId"
                value={moduleGroupId}
                change={this.onChange}
                required={true}
                pattern="moduleGroupId"
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Browser URL"
                type="text"
                name="moduleBrowserLink"
                value={moduleBrowserLink}
                change={this.onChange}
                required={true}
              />
            </Col>
            <Col md="3"></Col>
          </Row>
          <div className="center">
            <button
              type="submit"
              className="btn login-button white-text"
              disabled={btnDisable ? true : false}
            >
              Add
            </button>
          </div>
        </Form>
        {message && <Notifier message={message} />}
        {error && <ErrorNotifier message={error} />}
      </React.Fragment>
    );
  }
}

AddModule.propTypes = {
  addModule: PropTypes.func.isRequired,
  modules: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  modules: state.modules,
});

export default connect(mapStateToProps, { addModule })(AddModule);
