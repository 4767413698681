import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap'
import { getTsCandidates, updateTsCandidate, searchTsCandidates} from '../../../redux/actions/tsCandidateActions'
import { refresh, submitValidator, errors } from '../../common/Validator'
import { getTsJobs } from '../../../redux/actions/tsJobActions'
import CandidateDetails from './CandidateDetails'
import Hover from '../../aside/Hover'
import Notifier from '../../aside/Notifier'
import ErrorNotifier from '../../aside/ErrorNotifier'

class CandidateInterview   extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: this.props.candidates.data,
      selection: false,
      selected: {},
      selectedJob: {},
      subItem: {},
      modal: false,
      hover: false,
      cords: {},
      mouse: {},
      searchTerm: '',
      comment: '',
      popup: false,
      update: false,
      message: '',
      error: ''
    }
    this.getData = this.getData.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.select = this.select.bind(this);
    this.back = this.back.bind(this);
    this.search = this.search.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.toggleHoverOn = this.toggleHoverOn.bind(this);
    this.toggleHoverOff = this.toggleHoverOff.bind(this);
    this.beforeUpdate = this.beforeUpdate.bind(this);
    this.afterUpdate = this.afterUpdate.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.toggle1 = this.toggle1.bind(this);
  }
  componentDidUpdate(prevProps){
    if(prevProps.candidates !== this.props.candidates){
      this.setState({
        data: this.props.candidates.data
      })
    }

    if((prevProps.auth !== this.props.auth) ){
      this.setState({     
        permissionlist: this.props.auth.permissionlist
      })
 
    }
  }
  search(e){
    e.preventDefault();
    this.setState({
      searchTerm: e.target.value
    })
    this.onSearch(e.target.value)
  }
  onSearch(value){
    let data = {
      candName: value
    }
    this.props.searchTsCandidates(data);
  }
  onChange(e){
    this.setState({
      comment: e.target.value
    })
  }
  onSubmit(e){
    e.preventDefault();
    submitValidator(e.target);
    const data = this.state.data.filter(item => {
      if(item.candId === this.state.subItem){
        return item;    
      } else {
        return null;
      }      
    });
    const dragCandidate ={
    candId: data[0].candId,
    contact: data[0].contact,
    skypeId: data[0].skypeId,
    email: data[0].email,
    jobId: data[0].jobId,
    panelName: data[0].panelName,
    status: data[0].status,
    candName: data[0].candName,
    feedStatus: data[0].feedStatus,
    scrStatus: data[0].scrStatus,
    comment: this.state.comment
    }
    if(errors.valid) {   
    this.props.updateTsCandidate(dragCandidate , this.props.history);
    this.toggle();
    } else {
      this.setState({
        error: 'Enter mandatory fields'
      })
      setTimeout(() => {
        this.setState({
          error: ''
        })
      }, 5000)
    }
  }
  toggleHoverOn(e, item){
    this.setState({
      hover: true,
      cords: {
        one: item.candId,
        two: item.jobId,
        three: item.candName
      },
      mouse: {
        x: e.screenX,
        y: e.screenY
      }
    })
  }
  toggleHoverOff(){
    this.setState({
      hover: false
    })
  }
  toggle(){
    this.setState({
      modal: !this.state.modal
    })
  }

/*
Toggle1 function for modal cancel button here called the getData Fuction
*/
  toggle1(){
    this.setState({
      modal: !this.state.modal
    })
    this.getData();
  }
  

 select(item){
    const candJob = this.props.jobs.data.filter(
      job => {
        if(job.jobId === item.jobId){
          return job;
        }
        return null;
      }
    )    
    this.setState({
      selection: item,
      selectedJob: candJob[0] ? candJob[0] : candJob,
       popup: true
    })
  }


  beforeUpdate(){
    this.setState({
      popup: true,
    })
  }
  afterUpdate(){
    this.setState({
      popup: false,
    })
    this.getData();
    refresh();
  }
  back(){
    this.setState({
      popup: false
    })
  }
  onDragOver(e){
    e.preventDefault();
  }
  onDragStart(e, item){
    e.dataTransfer.setData('item', item.candId);
    this.setState({
      subItem: item.candId
    })
  }
  async onDrop(e, cat){
    let id = e.dataTransfer.getData('item');
    let newData;
    newData = this.state.data.filter(item => {
      if(item.candId === parseInt(id, 10)){        
        item.status = cat;
        this.setState({
          subItem : item
        })                
      }      
      return newData;
    })
    this.setState({
      ...this.state,
      newData
    })
    await this.toggle();
  }
  componentDidMount(){
    this.getData();
    
  }
  getData(){
    this.props.getTsCandidates();
    this.props.getTsJobs();
  }
  render() {
    let shrink;
    this.props.sidebar? shrink = 'scale': shrink =  'no-scale';    
    const { data, selection, selected, modal, hover, cords, comment, popup, message, error, selectedJob  } = this.state;
    
    return (
      <div className={shrink}>
        <Modal isOpen={modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle1}>Confirm changes?</ModalHeader>
          <ModalBody>
            <Form className="p-3" onSubmit={this.onSubmit}>
              <FormGroup>
                <Label className="bold label-sm">Remarks</Label>
                <Input name="comment" onChange={this.onChange} value={comment} />
              </FormGroup>
              <FormGroup className="center">
                <button type="submit" className="btn login-button white-text">Submit</button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
        {!popup? 
        (<Container className="card container-card">
           <input
            type="text"
            onChange={this.search}
            value={this.state.searchTerm}
            className="searchfield form-control-sm mr-3"
            placeholder="CandidateName" /> 
          {hover &&
          <Hover 
            cords={cords}
            labels={{one: 'candId', two: 'jobId', three:'candName'}}
            mouse={this.state.mouse} />}
        <h5 className="pointer bold center pt-2">Candidates</h5>
          <Row className="p-1">
            <Col
              md="4"
              onDragOver={this.onDragOver}
              onDrop={e => this.onDrop(e, 'New')}
              className="card p-2"
            >
              <h6 className="center bold m-2">New</h6>
              {
                 data && data.map(item => {
                  if(item.status === 'New'){
                  return <ul
                    key={item.candId}
                    onClick={() => {this.select(item)}}
                    onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                    onMouseLeave={this.toggleHoverOff}
                    draggable 
                    onDragStart={(e) => this.onDragStart(e, item)}
                    className={`list flex-around ${selected && selected === item.candId && 'selected-item'}`}
                  >
                    <li className="list-item pad">{item.candId}</li>
                    <li className="list-item flex-1">{item.jobId}</li>
                    <li className="list-item flex-2">{item.candName}</li>
                  </ul>
                  }else{
                    return null;
                  }
                })
              }       
            </Col>
            <Col
              md="4"
              onDragOver={this.onDragOver}
              onDrop={e => this.onDrop(e, 'WIP')}
              className="card p-2"
            >
              <h6 className="center bold m-2">Work in progress</h6>
              {
                 data && data.map(item => {
                  if( item.status === 'WIP'){
                  return <ul
                    key={item.candId}
                    onClick={() => {this.select(item)}}
                    onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                    onMouseLeave={this.toggleHoverOff}
                    draggable
                    onDragStart={(e) => this.onDragStart(e, item)}
                    className={`list flex-around ${selected && selected === item.candId && 'selected-item'}`}
                  >
                    <li className="list-item pad">{item.candId}</li>
                    <li className="list-item flex-1">{item.jobId}</li>  
                    <li className="list-item flex-2">{item.candName}</li>  
                  </ul>
                  }else{
                    return null;
                  }
                })
              }
            </Col>
            <Col
              md="4"
              className="card p-2"
              onDrop={e => this.onDrop(e, 'Complete')}
              onDragOver={this.onDragOver}
            >
              <h6 className="center bold m-2">Completed</h6>
              {
                 data && data.map(item => {
                  if((item.status !== 'New') && (item.status !== 'WIP')){
                  return <ul
                    key={ item.candId }
                    onClick={() => { this.select(item) }}
                    onMouseEnter={ (e) => this.toggleHoverOn(e, item) }
                    onMouseLeave={ this.toggleHoverOff }
                    draggable
                    onDragStart={ (e) => this.onDragStart(e, item) }
                    className={ `list flex-around ${selected && selected === item.candId && 'selected-item'} ${item.status === 'Complete' && 'completed-item'}` }
                  >
                    <li className="list-item pad">{ item.candId }</li>
                    <li className="list-item flex-1">{ item.jobId }</li>
                    <li className="list-item flex-2">{item.candName}</li>     
                  </ul>
                  }else{
                    return null;
                  }
                })
              }
            </Col>
          </Row> 
        </Container>)
        :<CandidateDetails candidate={selection} job={selectedJob} update={this.beforeUpdate} back={this.afterUpdate} />}
        {message? <Notifier message={message} /> :null}
        {error? <ErrorNotifier message={error} /> :null}
      </div>
    )
  }
}

CandidateInterview.propTypes  = {
  candidates: PropTypes.object.isRequired,
  jobs: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  getTsCandidates: PropTypes.func.isRequired,
  getTsJobs: PropTypes.func.isRequired,
  updateTsCandidate: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  candidates: state.tsCandidates,
  jobs: state.tsJobs,
  errors: state.errors
})

export default connect(
  mapStateToProps,
  { getTsCandidates, updateTsCandidate, searchTsCandidates, getTsJobs
   }
)(withRouter(CandidateInterview ));