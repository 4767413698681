import axios from "axios";
import { ENTERPRISE_DETAIL_GET } from "../../types";
import {
  CURD_ENTERPRISE_DETAIL,
  USER_ENTERPRISE_DETAIL,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addEnterpriseDetail = (data, back) => (dispatch) => {
  axios
    .post(CURD_ENTERPRISE_DETAIL, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getEnterpriseDetail));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getEnterpriseDetail = () => (dispatch) => {
  axios
    .get(CURD_ENTERPRISE_DETAIL, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(ENTERPRISE_DETAIL_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserEnterpriseDetail = () => (dispatch) => {
  axios
    .get(USER_ENTERPRISE_DETAIL, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(ENTERPRISE_DETAIL_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

// export const fetchEmpOrganizationName = () => (dispatch) => {
//   axios
//     .get(FETCH_EMP_ORGANIZATION_NAME, { withCredentials: true })
//     .then((res) => {
//       dispatch(getDispatch(ORGANIZATION_SETUP_FETCH, res));
//     })
//     .catch((err) => {
//       dispatch(setErrorMessage(err));
//     });
// };

export const updateEnterpriseDetail = (data, back) => (dispatch) => {
  axios
    .put(CURD_ENTERPRISE_DETAIL, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getEnterpriseDetail));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteEnterpriseDetail = (data) => (dispatch) => {
  axios
    .delete(CURD_ENTERPRISE_DETAIL, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getEnterpriseDetail));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
