import React, { Component } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import axios from "axios";
import { PRODUCT_COUNTS } from "../../../../utils/routes";

class ProductsGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };

    this.getProductGraphData = this.getProductGraphData.bind(this);
  }

  /*
componentDidMount calls the getProductGraphData function to get the product graph data
*/
  componentDidMount() {
    this.getProductGraphData();
  }

  /*
getProductGraphData function to get the product graph data
*/
  getProductGraphData() {
    axios.get(PRODUCT_COUNTS, { withCredentials: true }).then((res) => {
      this.setState({
        data: res.data,
      });
    });
  }

  render() {
    return (
      <LineChart
        width={500}
        height={210}
        data={this.state.data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="Month" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="Products"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    );
  }
}

export default ProductsGraph;
