import React, { Component } from "react";
import { Form, Row, Col, FormGroup, Label, Input, FormText } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { continents } from "countries-list";

// components
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
// redux
import { updateCustomerGeographies } from "../../../../../redux/actions/crm/crm_setup/customerGeographiesAction";

// ----------------------------------------------------------------------------------------------------------
class UpdateCustomerGeographies extends Component {
  constructor(props) {
    super(props);
    const { id, geoName, geoCode, color, remarks } = this.props.data;
    this.state = {
      geoId: id,
      geoName: `${geoCode},${geoName}`,
      color: color,
      remarks: remarks,
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      id: this.state.geoId,
      geoCode: this.state.geoName.split(",")[0],
      geoName: this.state.geoName.split(",")[1],
      color: this.state.color,
      remarks: this.state.remarks,
    };
    if (valid) {
      this.props.updateCustomerGeographies(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    refresh();
    this.setState({
      error: "",
    });
  }

  render() {
    const { geoName, remarks, color, error } = this.state;

    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Update Customer Geographies</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="6" className="mx-auto">
              <Row>
                <Col md="10" style={{ paddingLeft: "0px" }}>
                  <FormGroup>
                    <Label className="bold label-sm">Geographies</Label>
                    <Input
                      type="select"
                      name="geoName"
                      value={geoName}
                      onChange={this.onChange}
                      required={true}
                    >
                      <option value="" hidden>
                        Select....
                      </option>
                      {Object.keys(continents).map((item) => (
                        <option
                          key={item}
                          value={`${item},${continents[item]}`}
                        >
                          {item}-{continents[item]}
                        </option>
                      ))}
                    </Input>
                    {errors.geoName && (
                      <FormText className="error">{errors.geoName}</FormText>
                    )}
                  </FormGroup>
                </Col>
                <Col md="2" style={{ paddingRight: "0px" }}>
                  <FormComponent
                    labelClass="bold label-sm"
                    label="Color"
                    type="color"
                    name="color"
                    inputClass="form-control-sm"
                    value={color}
                    change={this.onChange}
                  />
                </Col>
              </Row>
              <FormComponent
                labelClass="bold label-sm"
                label="Remarks"
                type="textarea"
                name="remarks"
                inputClass="form-control-sm"
                value={remarks}
                change={this.onChange}
              />
            </Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Update
            </button>
          </div>
        </Form>
        {error && <ErrorNotifier message={error} />}
        {this.props.customerGeographies.error && (
          <ErrorNotifier message={this.props.customerGeographies.error} />
        )}
        {this.props.customerGeographies.message && (
          <Notifier message={this.props.customerGeographies.message} />
        )}
      </React.Fragment>
    );
  }
}

UpdateCustomerGeographies.propTypes = {
  updateCustomerGeographies: PropTypes.func.isRequired,
  customerGeographies: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  customerGeographies: state.customerGeographies,
});

export default connect(mapStateToProps, { updateCustomerGeographies })(
  UpdateCustomerGeographies
);
