const emailChecker = RegExp(
  /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
);

// const textChecker = RegExp(
//   /^(?:[A-Za-z\w\s\.\/"]+[0-9\w\s\.\/"]|[0-9\w\s\.\/"]+[A-Za-z\w\s\.\/"])[A-Za-z0-9\w\s\.\/"]*|[A-Za-z\w\s\.\/"]+$/
// );

const textChecker = RegExp(/^[ A-Za-z0-9_@.,/#-]*$/);

const textOnlyLettersChecker = RegExp(/^[a-zA-Z ]*$/);

const textLettersAndNumberChecker = RegExp(
  /^(?:[A-Za-z0-9]+)(?:[A-Za-z0-9]*)$/
);

const versionChecker = RegExp(/^([A-Za-z0-9])|([0-9]\.[0-9])+$/);

const numChecker = RegExp(/^([0-9])|([0-9]\.[0-9])+$/);

const numberChecker = RegExp(/^([0-9])+$/);

const passChecker = RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
);

const moduleGroupIdChecker = RegExp(/^([0-9]{1,3}?\.[0-9]{1,3})$/);

const serviceGroupIdChecker = RegExp(/^[0-9]{1,3}?\.[0-9_]{1,3}?\.[0-9]{1,3}$/);

export let errors = {};

export let valid = false;

export const refresh = () => {
  errors = {};
};

export const Validator = ({
  name,
  value,
  type,
  required,
  max,
  minLength,
  maxLength,
  pattern,
}) => {
  errors[name] = "";
  if (required) {
    errors[name] = value.trim().length > 0 ? false : "This field is mandatory";
    if (errors[name]) {
      errors.pending = false;
    }
  }

  switch (type) {
    case "text":
      errors[name] = value.length
        ? pattern
          ? pattern.includes("^")
            ? RegExp(pattern).test(value)
              ? false
              : "Invalid Data"
            : pattern === "onlyLetters"
            ? textOnlyLettersChecker.test(value)
              ? false
              : "Use only alphabets"
            : pattern === "lettersAndNumbers"
            ? textLettersAndNumberChecker.test(value)
              ? false
              : "Use Letters and Numbers"
            : textChecker.test(value)
            ? false
            : "Use only alphabets, numbers with alphabets or spaces"
          : minLength > 0 && maxLength > 0
          ? minLength <= value.length && value.length <= maxLength
            ? false
            : `Value should be ${minLength}-${maxLength} characters in length`
          : textChecker.test(value)
          ? false
          : "Use only alphabets, numbers with alphabets or spaces"
        : errors[name];
      break;
    case "textarea":
      errors[name] =
        //  value.indexOf(' ') >= 0 ? "This field is mandatory":
        value.length == 1 
          ? value.length === 1 &&  name !== "description" 
            ? "Length should have more than one character"
            : false
          : value.length > 1
          ? value.match(/^[ A-Za-z0-9_@./#-,]*$/gm) || name === "description"
            ? false
            : "Use only alphabets, numbers with alphabets or spaces"
          : errors[name];
      break;
    case "number":
      console.log(maxLength);
      errors[name] = value.length
        ? pattern
          ? pattern === "moduleGroupId"
            ? moduleGroupIdChecker.test(value)
              ? false
              : "value should be like (1.1)"
            : pattern === "serviceGroupId"
            ? serviceGroupIdChecker.test(value)
              ? false
              : "value should be like (1.1.1)"
            : numberChecker.test(value) || name === "experience" || name ==="releventYears" || name === "minYears" || name === "maxYears" 
            ? false
            : "Please enter numbers only"
          : numberChecker.test(value) || name === "experience" || name ==="releventYears" || name === "minYears" || name === "maxYears"
          ? maxLength > 0
            ? value.length <= maxLength
              ? false
              : `maxLength is ${maxLength}`
            : false
          : "Please enter numbers only"
        : errors[name];
      switch (name) {
        case "esiNo":
          errors[name] =
            value.length === 17 ? false : "ESI Number must be 17 digits";
          break;
        case "uanNo":
          errors[name] =
            value.length === 12 ? false : "UAN Number must be 12 digits";
          break;
        case "aadharNo":
          errors[name] =
            value.length === 12 ? false : "Aadhaar Number must be 12 digits";
          break;
        case "socialSecurity":
          errors[name] =
            value.length === 9
              ? false
              : "Social Security Number must be 9 digits";
          break;
        default:
          errors[name] = errors[name];
      }
      break;
    case "tel":
      console.log(value.length);
      errors[name] =
        value.length > 0
          ? value.length === 10
            ? numberChecker.test(value)
              ? false
              : "Please enter numbers only"
            : "Mobile number must be 10 digits"
          : errors[name];
      break;
    case "email":
      errors[name] = value.length
        ? emailChecker.test(value)
          ? false
          : "Email is not valid"
        : errors[name];
      break;
    // case "textarea":
    //   errors[name] = value.length
    //     ? textChecker.test(value)
    //       ? false
    //       : "Use only alphabets"
    //     : errors[name];
    //   break;
    case "date":
      errors[name] = value.length
        ? max
          ? value < max
            ? false
            : "Invalide Date"
          : false
        : errors[name];
      break;
    case "time":
      errors[name] = errors[name].length > 0 ? errors[name] : false;
      break;
    case "select-one":
      errors[name] = errors[name].length > 0 ? errors[name] : false;
      break;
    case "password":
      errors[name] = value.length
        ? passChecker.test(value)
          ? false
          : "Password length should be atleast 8 characters (1 lowerCase, 1 upperCase, 1 number, 1 special character)"
        : errors[name];
      break;
    case "file":
      errors[name] = errors[name].length > 0 ? errors[name] : false;
      break;
    default:
      errors[name] = false;
  }

  return errors;
};

export const submitValidator = (data) => {
  for (let i = 0; i < data.length; i++) {
    if (data[i].type !== "submit") {
      if (data[i].name === "version") {
        VersionValidator(data[i]);
      }
      if (
        data[i].name === "percentage" ||
        data[i].name === "sharePercentage" ||
        data[i].name === "nomineeAge" ||
        data[i].name === "experience"
      ) {
        PercentageValidation(data[i]);
      } else {
        Validator(data[i]);
      }

      let ob = Object.values(errors)
        .filter((item) => item !== false)
        .filter((value) => value !== "");
        
      if (ob.length > 0) {
        if (
          (ob[0] === "" && ob.length === 1) ||
          (ob[0] === "" && ob.length === 2)
        ) {
          valid = true;
        } else if (ob.includes("This field is mandatory")) {
          valid = false;
        } else {
          valid = false;
        }
      } else {
        valid = true;
      }

      // if (ob.includes("This field is mandatory") || ) {
      //   valid = false;
      // } else {
      //   valid = true;
      // }
    }
  }
  return valid;
};

export const VersionValidator = ({ name, value, type, required }) => {
  errors[name] = "";
  if (required) {
    errors[name] = value.trim().length > 0 ? false : "This field is mandatory";
    if (errors[name]) {
      errors.pending = false;
    }
  }
  switch (type) {
    case "text":
      errors[name] = value.length
        ? versionChecker.test(value)
          ? false
          : "Use only alphabets, numbers  or spaces"
        : errors[name];
      break;
    default:
      errors[name] = false;
  }
  return errors;
};

/*
PercentageValidation function to validate the percentage 
*/
export const PercentageValidation = ({ name, value, type, required }) => {
  errors[name] = "";
  if (required) {
    errors[name] = value.trim().length > 0 ? false : "This field is mandatory";
    if (errors[name]) {
      errors.pending = false;
    }
  }

  switch (name) {
    case "percentage":
      if (value.length) {
        if (value < 0 || value > 100) {
          errors[name] = "Percentage value should be between 0 to 100";
        } else {
          errors[name] = false;
        }
      } else {
        errors[name] = errors[name];
      }
      break;
    case "sharePercentage":
      if (value.length) {
        if (value < 0 || value > 100) {
          errors[name] = "Percentage value should be between 0 to 100";
        } else {
          errors[name] = false;
        }
      } else {
        errors[name] = errors[name];
      }
      break;
    case "nomineeAge":
      if (value.length) {
        if (value < 0 || value > 100) {
          errors[name] = "Age value should be between 0 to 100";
        } else {
          errors[name] = false;
        }
      } else {
        errors[name] = errors[name];
      }
      break;
    case "experienceFrom":
      if (value.length) {
        if (value < 0 || value > 100) {
          errors[name] = "Experience range value should be between 0 to 100";
        } else {
          errors[name] = false;
        }
      } else {
        errors[name] = errors[name];
      }
      break;
    case "experienceTo":
      if (value.length) {
        if (value < 0 || value > 100) {
          errors[name] = "Experience range value should be between 0 to 100";
        } else {
          errors[name] = false;
        }
      } else {
        errors[name] = errors[name];
      }
      break;
    default:
      errors[name] = false;
  }

  return errors;
};
