import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  Table,
  Input,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  Container,
  ModalBody,
} from "reactstrap";
import DateFns from "date-fns";
import * as XLSX from "xlsx/xlsx.mjs";
import Select, { components } from "react-select";

// material ui
import {
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  Popover,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";

import { GET_ERRORS } from "../../../../redux/actions/types";

// material ui icons
import SearchIcon from "@material-ui/icons/Search";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import GetAppIcon from "@material-ui/icons/GetApp";

import { getContactsRSName } from "../../../../redux/actions/crm/contacts/contactsAction";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
// components
import ListContainer from "../../../common/layout/ListContainer";
import CandidateKanban from "../requirements/candidate_kanban/CandidateKanban";
import RequirementDetails from "./RequirementDetails";
import CandidateDetails from "./CandidateDetails";
import CustTooltip from "../../../common/tooltip/CustTooltip";






// redux
import {
  searchManagerView,
  resetManagerView,
  searchManagerViewCandidate,
  getManagerViewCustomers,
  getManagerViewTAC,
  getManagerViewPosition,
  getManagerViewLeadRecruiter,
  getManagerViewSourcedBy,
  getManagerViewUpdatedBy,
  getManagerViewCandidateStatus,
  getManagerViewHiringStage,
  fetchRequirementDetails,
  fetchCandidateDetails,
  getManagerViewHiringStatus,
  getManagerViewSingleMultiTac,
} from "../../../../redux/actions/rs/manager_view/managerViewAction";
import { getHiringStatSelect } from "../../../../redux/actions/rs/configuration/hiringStatus";
import { Box } from "@mui/material";
import { RsRequirements } from "../../../../utils/routes";




// -------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    marginBottom: 0,
    minWidth: 300,
  },
  select: {
    "& .MuiOutlinedInput-input": {
      padding: ".25rem",
    },
  },
  buttonRoot: {
    "& > *": {
      margin: theme.spacing(1),
    },
    width: "100%",
    textAlign: "center",
  },
}));

// -------------------------------------------------------------------------

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 500,
    },
  },
};

// --------------------------------------------------------------------------
export const ManagerView = (props) => {
  const oneDayBeforeDate =() => {
    var today = new Date();
    var date =
      today.getFullYear()  +

      "-" +
      (today.getMonth() + 1 ) +
      "-" +
     ( today.getDate() - 1);
    return date;
  }
  const [reqView, setReqView] = useState(false);
  const [canView, setCanView] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState({
    customer: "",
    tac: "",
    position: "",
    candidateStatus: "",
    hiringStage: "",
    hiringStatus: "",
    leadRecruiter: "",
    sourcedBy: "",
    updatedBy: "",
    sourcedFrom: DateFns.format(oneDayBeforeDate(),"YYYY-MM-DD"),
    sourcedTo:  DateFns.format(new Date(),"YYYY-MM-DD"),
    joinOrDeclFrom: null ,
    joinOrDeclTo: null,
    reqId: "",
    canId: "",
  });

  const [labelValue, setLabelValue] = useState("Tagged");
  const[labelUpdatedValue,setLabelUpdatedValue] = useState(null)
  const [customer, setCustomer] = useState([]);
  const [tac, setTac] = useState([]);
  const [position, setPosition] = useState([]);
  const [leadRecruiter, setLeadRecruiter] = useState([]);
  const [candidateStatus, setCandidateStatus] = useState([]);
  const [sourcedBy, setSourcedBy] = useState([]);
  const [updatedBy, setUpdatedBy] = useState([]);
  const [hiringStage, setHiringStage] = useState([]);
  const [hiringStatus, setHiringStatus] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorTAC, setAnchorTAC] = React.useState(null);
  const [anchorPosition, setAnchorPosition] = React.useState(null);
  const [anchorCandidateStatus, setAnchorCandidateStatus] =
    React.useState(null);
  const [anchorHiringStage, setAnchorHiringStage] = React.useState(null);
  const [anchorHiringStatus, setAnchorHiringStatus] = React.useState(null);
  const [anchorLeadRecruiter, setAnchorLeadRecruiter] = React.useState(null);
  const [anchorTaggedBy, setAnchorTaggedBy] = React.useState(null);
  const [errorEmptyLog, setErrorEmptyLog] = React.useState("");
  const [isValidDate, setIsValidDate] = React.useState(true);
  const [isCalendarView, setIsCalendarView] = React.useState(false);

const [isParagraphStore,setIsParagraphStore] = React.useState(null) 
const[isTacStore,setIsTacStore] = React.useState(null)
const[isLeadStore,setIsLeadStore] = React.useState(null)
const[isHiringStore,setIsHiringStore] = React.useState(null)
const[isHiringStageStore,setIsHiringStageStore] = React.useState(null)
const[isPositionStore,setIsPositionStore] = React.useState(null)
const[isTaggedByStore,setIsTaggedByStore] = React.useState(null)
const[isUpdatedByStore,setIsUpdatedByStore] = React.useState(null)
const[isCanStatusStore,setIsCanStatusStore] = React.useState(null)

const [isFocused, setIsFocused] = useState(true);
const selectRef = React.useRef(null);



  const handlePopoverOpen = (event) => {
    console.log(customer)
    setAnchorEl(customer.length > 0 ? event.currentTarget : null);
  };

  const handlePopoverClose = () => {
    setAnchorEl(customer.length > 0 && null);
  };


  const handleFocus = () => {
   
     setIsFocused(true);
  };

  const handleBlur = () => {
   
    setIsFocused(false);

 
  };





  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handlePopoverOpenTAC = (event) => {
    setAnchorTAC(event.currentTarget);
  };

  const handlePopoverCloseTAC = () => {
    setAnchorTAC(null);
  };

  const openTAC = Boolean(anchorTAC);

  const handlePopoverOpenPosition = (event) => {
    setAnchorPosition(event.currentTarget);
  };

  const handlePopoverClosePosition = () => {
    setAnchorPosition(null);
  };

  const openPosition = Boolean(anchorPosition);

  const handlePopoverOpenCandidateStatus = (event) => {
    setAnchorCandidateStatus(event.currentTarget);
  };

  const handlePopoverCloseCandidateStatus = () => {
    setAnchorCandidateStatus(null);
  };

  const openCandidateStatus = Boolean(anchorCandidateStatus);

  const handlePopoverOpenHiringStage = (event) => {
    setAnchorHiringStage(event.currentTarget);
  };

  const handlePopoverCloseHiringStage = () => {
    setAnchorHiringStage(null);
  };

  const openHiringStage = Boolean(anchorHiringStage);

  const handlePopoverOpenHiringStatus = (event) => {
    setAnchorHiringStatus(event.currentTarget);
  };

  const handlePopoverCloseHiringStatus = () => {
    setAnchorHiringStatus(null);
  };

  const openHiringStatus = Boolean(anchorHiringStatus);

  const handlePopoverOpenLeadRecruiter = (event) => {
    setAnchorLeadRecruiter(event.currentTarget);
  };

  const handlePopoverCloseLeadRecruiter = () => {
    setAnchorLeadRecruiter(null);
  };

  const openLeadRecruiter = Boolean(anchorLeadRecruiter);

  const handlePopoverOpenTaggedBy = (event) => {
    setAnchorTaggedBy(event.currentTarget);
  };

  const handlePopoverCloseTaggedBy = () => {
    setAnchorTaggedBy(null);
  };

  const openTaggedBy = Boolean(anchorTaggedBy);

  const classes = useStyles();

  //initial api triggers for dropdown

  useEffect(() => {
    props.getManagerViewSingleMultiTac({
      customers: [],
      tacNames: [],
      positions: [],
      candidateStatus: [],
      hiringStage: [],
      hiringStatus: [],
    });
    props.getManagerViewLeadRecruiter({
      customers: [],
      tacNames: [],
      positions: [],
      candidateStatus: [],
      hiringStage: [],
      hiringStatus: [],
    });

    props.getManagerViewSourcedBy({
      customers: [],
      tacNames: [],
    });
    props.getManagerViewUpdatedBy({
      customers: [],
      tacNames: [],
    });

    props.resetManagerView();

  
  }, []);

  const handleOpenView = () => {
    setIsOpen(!isOpen);
  };

  const handleOpenTaggedView = () => {
    setIsOpen(false);
  };

  const onChange = (e) => {
    if (e.target.name === "customer") {
      setValues({
        ...values,
        customer: e.target.value,
        tac: "",
        position: "",
        candidateStatus: "",
        hiringStage: "",
        hiringStatus: "",
        leadRecruiter: "",
        sourcedBy: "",
        updatedBy: "",
      });
    } else {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
      });
    }
  };

  // viewRequirementDetails function to view particular requirement details based on the requirement id
  const viewRequirementDetails = (id) => {
    props.fetchRequirementDetails(id);
    setReqView(true);
    setValues({
      ...values,
      reqId: id,
    });
  };

  // viewCandidateDetails function to view particular candidate details based on the candidate id
  const viewCandidateDetails = (id) => {
    let data = {
      id: id,
    };
    props.fetchCandidateDetails(data);
    setCanView(true);
    setValues({
      ...values,
      canId: id,
    });
  };

    //search based on labels 

  const onSearch = async () => {
    const { sourcedFrom, sourcedTo, joinOrDeclFrom, joinOrDeclTo } = values;
   
    let data =
  
    labelValue ?   {
      customers: customer?.length > 0 ? customer : null,
      tacNames: tac?.length > 0 ? tac : null,
      positions: position?.length > 0 ? position : null,
      candidateStatus:
        candidateStatus?.length > 0 ? candidateStatus : null,
      hiringStage: hiringStage?.length > 0 ? hiringStage : null,
      hiringStatus: hiringStatus?.length > 0 ? hiringStatus : null,
      leadRecuit: leadRecruiter?.length > 0 ? leadRecruiter : null,
      sourcedBy: sourcedBy?.length > 0 ? sourcedBy : null,
      // updatedBy: updatedBy?.length > 0 ? updatedBy : null,
      sourcedFromDate: sourcedFrom === "0000-00-00" ? null : sourcedFrom,
      sourcedToDate: sourcedTo === "0000-00-00" ? null : sourcedTo,
      // updatedFromDate: sourcedFrom === "0000-00-00" ? null : sourcedFrom,
      // updatedToDate: sourcedTo === "0000-00-00" ? null : sourcedTo,
      joinedFromDate:
        joinOrDeclFrom === "0000-00-00" ? null : joinOrDeclFrom,
      joinedToDate: joinOrDeclTo === "0000-00-00" ? null : joinOrDeclTo,
    } :

    {
      customers: customer?.length > 0 ? customer : null,
      tacNames: tac?.length > 0 ? tac : null,
      positions: position?.length > 0 ? position : null,
      candidateStatus:
        candidateStatus?.length > 0 ? candidateStatus : null,
      hiringStage: hiringStage?.length > 0 ? hiringStage : null,
      hiringStatus: hiringStatus?.length > 0 ? hiringStatus : null,
      leadRecuit: leadRecruiter?.length > 0 ? leadRecruiter : null,
   
      updatedBy: updatedBy?.length > 0 ? updatedBy : null,
      
      updatedFromDate: sourcedFrom === "0000-00-00" ? null : sourcedFrom,
      updatedToDate: sourcedTo === "0000-00-00" ? null : sourcedTo,
      joinedFromDate:
        joinOrDeclFrom === "0000-00-00" ? null : joinOrDeclFrom,
      joinedToDate: joinOrDeclTo === "0000-00-00" ? null : joinOrDeclTo
    }

    



    
       
    if (isValidDate) {
      await props.searchManagerView(data);
    }

  
  };

  const reset = () => {
    setValues({
      ...values,
      customer: "",
      tac: "",
      position: "",
      candidateStatus: "",
      hiringStage: "",
      hiringStatus: "",
      leadRecruiter: "",
      sourcedBy: "",
      updatedBy: "",
      sourcedFrom: "0000-00-00",
      sourcedTo: "0000-00-00",
      joinOrDeclFrom: "0000-00-00",
      joinOrDeclTo: "0000-00-00",
    });
    setIsCalendarView(false);
    setCustomer([]);
    setTac([]);
    setPosition([]);
    setCandidateStatus([]);
    setHiringStage([]);
    setHiringStatus([]);
    setLeadRecruiter([]);
    setSourcedBy([]);
    setUpdatedBy([]);
    setLabelValue("Tagged");
    setLabelUpdatedValue(null)
    props.getManagerViewSingleMultiTac({
      customers: [],
      tacNames: [],
      positions: [],
      candidateStatus: [],
      hiringStage: [],
      hiringStatus: [],
    });
    props.getManagerViewLeadRecruiter({
      customers: [],
      tacNames: [],
      positions: [],
      candidateStatus: [],
      hiringStage: [],
      hiringStatus: [],
    });

    props.getManagerViewSourcedBy({
      customers: [],
      tacNames: [],
    });
    props.getManagerViewUpdatedBy({
      customers: [],
      tacNames: [],
    });
    props.resetManagerView();
  };

  // downloadData function for downloading the searched data in xlsx file
  const downloadData = () => {
    let filename = "searchData";
    let headings = [
      [
        "Req. Id",
        "Customer Name",
        "TAC Name",
        "Job Position",
        "Primary Skills",
        "Can. Id",
        "Can. Name",
        "Lead Recruiter",
        "Tagged By",
        "Tagged On",
        "Candidate Status",
        "Hiring Stage",
        "Hiring Status",
        "Join/Decl Date",
        "Can Email",
        "Can Phone",
        "Yrs of Exp",
        "Rel Yrs of Exp",
        "Location",
        "Pre. Location",
        "Notice Period",
        "Current CTC",
        "Expected CTC",
        "Current Employer",
        "Primary Skills",
        "Secondary Skills",
        "Comments",
        "Updated By",
        "Updated On",
      ],
    ];
    let wb = XLSX.utils.book_new();

    let ws = XLSX.utils.json_to_sheet(
      props.managerView.data.length > 0 &&
        props.managerView.data.map(
          ({
            jr_id,
            jr_custName,
            jr_tacName,
            jr_jobPosition,
            jr_primarySkills,
            ci_id,
            ci_firstName,
            ra_assignedTo,
            ct_taggedBy,
            ct_taggedOn,
            ct_candidateStatus,
            ct_hiringStage,
            ct_hiringStatus,
            ct_dateOfJoin,
            ci_eMailId,
            ci_mobileNo,
            ci_yearOfExp,
            ces_relYearOfExp,

            ci_location,
            ce_preferredWorkLoction,
            ce_noticePeriod,
            ce_currentCtc,
            ce_exepectedCtc,
            ce_currentEmployeer,
            cps_skill,
            css_skill,
            ct_comment,
            ct_updatedBy,
            ct_updatedOn,
          }) => ({
            jr_id,
            jr_custName,
            jr_tacName,
            jr_jobPosition,
            jr_primarySkills,
            ci_id,
            ci_firstName,
            ra_assignedTo,
            ct_taggedBy,
            ct_taggedOn,
            ct_candidateStatus,
            ct_hiringStage,
            ct_hiringStatus,
            ct_dateOfJoin,
            ci_eMailId,
            ci_mobileNo,
            ci_yearOfExp,
            ces_relYearOfExp,
            ci_location,
            ce_preferredWorkLoction,
            ce_noticePeriod,
            ce_currentCtc,
            ce_exepectedCtc,
            ce_currentEmployeer,
            cps_skill,
            css_skill,
            ct_comment,
            ct_updatedBy,
            ct_updatedOn,
          })
        ),
      { origin: "A2", skipHeader: true }
    );
    XLSX.utils.sheet_add_aoa(ws, headings);

    XLSX.utils.book_append_sheet(wb, ws);

    XLSX.writeFile(wb, filename + ".xlsx");
  };

  const targetHeight = 35;

  const styles = {
    control: (base) => ({
      ...base,
      minHeight: "initial",
    }),
    valueContainer: (base) => ({
      ...base,
      height: `${targetHeight - 1 - 1}px`,
      padding: "0 8px",
      "&::hover": () => handlePopoverOpen,
    }),
    clearIndicator: (base) => ({
      ...base,
      padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
    }),
    display:"none"
  };

  const onChangeCalendorView = () => {
    setIsCalendarView(!isCalendarView);
  };

    // for showing multi selected data in ui (common for all)

   

     //onchange  triggers for every drop down changes

  const onCustomerChange = (data) => {
    data === null ? setCustomer([]) : setCustomer(data);
   
    // selectRef.current.blur();
    setTac([]);
    setPosition([]);
    setCandidateStatus([]);
    setHiringStage([]);
    setHiringStatus([]);
    setSourcedBy([]);
    setUpdatedBy([]);
    setLeadRecruiter([]);
    props.getManagerViewTAC({ customers: data });
    props.getManagerViewSourcedBy({
      customers: data,
      tacNames: tac,
    });
    props.getManagerViewUpdatedBy({
      customers: data,
      tacNames: tac,
    });
    props.getManagerViewLeadRecruiter({
      customers: data,
      tacNames: tac,
      positions: position,
      candidateStatus: candidateStatus,
      hiringStage: hiringStage,
      hiringStatus: hiringStatus,
    });
    props.getManagerViewSingleMultiTac({
      customers: data,
      tacNames: tac,
      positions: position,
      candidateStatus: candidateStatus,
      hiringStage: hiringStage,
      hiringStatus: hiringStatus,
    });

 
  };



  const onPositionsChange = (data) => {
    data === null ? setPosition([]) : setPosition(data);
    setCandidateStatus([]);
    setHiringStage([]);
    setHiringStatus([]);
    setSourcedBy([]);
    setUpdatedBy([]);
    // setLeadRecruiter([]);
    props.getManagerViewLeadRecruiter({
      customers: customer,
      tacNames: tac,
      positions: data,
      candidateStatus: candidateStatus,
      hiringStage: hiringStage,
      hiringStatus: hiringStatus,
    });
    props.getManagerViewSingleMultiTac({
      customers: customer,
      tacNames: tac,
      positions: data,
      candidateStatus: candidateStatus,
      hiringStage: hiringStage,
      hiringStatus: hiringStatus,
    });
  };

  const onTacChange = (data) => {
    console.log(data);
    data === null ? setTac([]) : setTac(data);
    setPosition([]);
    setCandidateStatus([]);
    setHiringStage([]);
    setHiringStatus([]);
    setSourcedBy([]);
    setUpdatedBy([]);
    setLeadRecruiter([]);
    props.getManagerViewSourcedBy({
      customers: customer,
      tacNames: data,
    });
    props.getManagerViewUpdatedBy({
      customers: customer,
      tacNames: data,
    });
    props.getManagerViewLeadRecruiter({
      customers: customer,
      tacNames: data,
      positions: position,
      candidateStatus: candidateStatus,
      hiringStage: hiringStage,
      hiringStatus: hiringStatus,
    });
    props.getManagerViewSingleMultiTac({
      customers: customer,
      tacNames: data,
      positions: position,
      candidateStatus: candidateStatus,
      hiringStage: hiringStage,
      hiringStatus: hiringStatus,
    });
  };

  const onCandiStatusChange = (data) => {
    console.log(data);
    data === null ? setCandidateStatus([]) : setCandidateStatus(data);
    setHiringStage([]);
    setHiringStatus([]);
    // setLeadRecruiter([]);
    props.getManagerViewLeadRecruiter({
      customers: customer,
      tacNames: tac,
      positions: position,
      candidateStatus: data,
      hiringStage: hiringStage,
      hiringStatus: hiringStatus,
    });
    props.getManagerViewSingleMultiTac({
      customers: customer,
      tacNames: tac,
      positions: position,
      candidateStatus: data,
      hiringStage: hiringStage,
      hiringStatus: hiringStatus,
    });
  };

  const onHiringStageChange = (data) => {
    data === null ? setHiringStage([]) : setHiringStage(data);
    setHiringStatus([]);
    // setLeadRecruiter([]);
    props.getManagerViewLeadRecruiter({
      customers: customer,
      tacNames: tac,
      positions: position,
      candidateStatus: candidateStatus,
      hiringStage: data,
      hiringStatus: hiringStatus,
    });
    props.getManagerViewSingleMultiTac({
      customers: customer,
      tacNames: tac,
      positions: position,
      candidateStatus: candidateStatus,
      hiringStage: data,
      hiringStatus: hiringStatus,
    });
  };

  const onHiringStatusChange = (data) => {
    data === null ? setHiringStatus([]) : setHiringStatus(data);
    // setLeadRecruiter([]);
    props.getManagerViewLeadRecruiter({
      customers: customer,
      tacNames: tac,
      positions: position,
      candidateStatus: candidateStatus,
      hiringStage: hiringStage,
      hiringStatus: data,
    });
    props.getManagerViewSingleMultiTac({
      customers: customer,
      tacNames: tac,
      positions: position,
      candidateStatus: candidateStatus,
      hiringStage: hiringStage,
      hiringStatus: data,
    });
  
  };

  useEffect(() => {
    setHiringStatus([]);
  }, [!hiringStage.length]);


  useEffect(()=>{
    let stringValue = ""
    for(let key of customer){
     stringValue += `${key.custName}\n`
    }
    setIsParagraphStore(stringValue)
   
  },[customer])

   useEffect(()=>{
    let TacstringValue = ""
    for(let key of tac){
    TacstringValue += `${key.tacName}\n`
    }
    setIsTacStore(TacstringValue)
   
  },[tac])

   useEffect(()=>{
    let candiStatusStringValue = ""
    for(let key of candidateStatus){
  candiStatusStringValue += `${key.candidateStatus}\n`
    }
    setIsCanStatusStore(candiStatusStringValue)
   
  },[candidateStatus])

   useEffect(()=>{
    let hiringStatusString = ''
    for(let key of hiringStatus){
      hiringStatusString += `${key.hiringStatus}\n` 
    }
    setIsHiringStore(hiringStatusString)
   
  },[hiringStatus])

   useEffect(()=>{
    let hiringStageString = ''
    for(let key of hiringStage){
      hiringStageString += `${key.hiringStage}\n` 
    }
    setIsHiringStageStore(hiringStageString )
   
  },[hiringStage])

  
  useEffect(()=>{
    let TaggedByString = ''
    for(let key of sourcedBy){
     TaggedByString += `${key.taggedBy}\n` 
    }
    setIsTaggedByStore(TaggedByString)
   
  },[sourcedBy])

   useEffect(()=>{
    let updatedByString = ''
    for(let key of updatedBy){
     updatedByString += `${key.updatedBy}\n` 
    }
    setIsUpdatedByStore(updatedByString)
   
   
  },[updatedBy])

   useEffect(()=>{
    let leadByString = ''
    for(let key of leadRecruiter){
     leadByString  += `${key.assignedTo}\n` 
    }
    setIsLeadStore(leadByString )
   
   
  },[leadRecruiter])
   useEffect(()=>{
    let positionByString = ''
    for(let key of position){
    positionByString += `${key.jobPosition}\n` 
    }
    setIsPositionStore(positionByString)
   
   
  },[position])

  const primaryString = "cbhjcbc cjhbhjcbc jhcbhjcbc jchbjchbc hjcbchjbchj chjcb cbhjcbc cjhbhjcbc jhcbhjcbc jchbjchbc hjcbchjbchj chjcb"

  return (
    <ListContainer sidebar={props.sidebar ? "scale" : "no-scale"}>
      <Grid style={{ height: "4.5rem" }} container>
        <Grid item xs={3} container alignItems="center" className="pr-1">
          <Grid item xs={12}>
            <FormGroup className="mb-0-5">
              <div style={{display:"flex"}}>
              <div style={{width:"35%"}}>
              <Label  style={{marginTop:"0.4rem",fontSize:"0.8rem",whiteSpace:"nowrap"}} >Customer</Label>
              </div>
             
              {/* {!isFocused && ( <Tooltip  title={isParagraphStore}> */}
              <div 
              // data-tooltip={isParagraphStore}
              style={{width:"65%"}}  
              title={isParagraphStore}
              >
               
            
                <Select
                
                 onFocus={handleFocus}
                 onBlur={handleBlur}
                name="custName"
                isMulti
                menuPosition="fixed"
              
                // components={{ MultiValueContainer }}
                options={
                  props.managerView?.singleMultiFilterData.custName
                    ? props.managerView.singleMultiFilterData.custName
                    : []
                }
                closeMenuOnSelect={false}
                
                isClearable={true}
                isSearchable={true}
                onChange={(data) => onCustomerChange(data)}
                // item === null ? setCustomer([]) : setCustomer(item)
                value={customer}
                getOptionLabel={(option) => option.custName}
                getOptionValue={(option) => option.custName}
                className=" manager-view-select-box"
                styles={styles}

               />
            
         
               
              </div>
            
           

              {/* {isFocused  && (
              
              <div 
            
              style={{width:"70%"}}  
          
              >
               
              
               <Select
               
                 onFocus={handleFocus}
                 onBlur={handleBlur}
                name="custName"
                isMulti
                menuPosition="fixed"
              
             
                options={
                  props.managerView?.singleMultiFilterData.custName
                    ? props.managerView.singleMultiFilterData.custName
                    : []
                }
                closeMenuOnSelect={false}
                
                isClearable={true}
                isSearchable={true}
                onChange={(data) => onCustomerChange(data)}
                // item === null ? setCustomer([]) : setCustomer(item)
                value={customer}
                getOptionLabel={(option) => option.custName}
                getOptionValue={(option) => option.custName}
                className=" manager-view-select-box"
                styles={styles}

               />
            
          
               
              </div>)
              } */}
            
              
              </div>
            </FormGroup>
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: "none",
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              {customer.length > 0 &&
                customer.map((item) => (
                  <Typography sx={{ p: 1, m: 1 }}>{item.custName}</Typography>
                ))}
            </Popover>
          </Grid>
        
          <Grid style={{ marginBottom: "4.5rem" }} item xs={12}>
            <FormGroup className="mb-0-5">
              <div style={{display:"flex"}}>
              <div style={{width:"35%"}}>
              {/* <Label  style={{marginTop:"0.4rem",fontSize:"0.8rem",whiteSpace:"nowrap"}} >Tagged By</Label> */}
              <FormGroup className="mb-0-5">
                <Label className="bold label-sm">
                  {" "}
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    defaultValue="tagged"
                  >
                    <FormControlLabel
                      value="Tagged"
                      control={
                        <Radio
                        checked={labelValue === "Tagged"}
                          size="small"
                          color="primary"
                          style={{ padding: 0 }}
                          onChange={() => {
                            setLabelValue("Tagged")
                            setLabelUpdatedValue(null)
                        }}
                        />
                      }
                      label={<Label style={{marginTop:"0.4rem",fontSize:"0.8rem",whiteSpace:"nowrap"}}>Tagged By</Label>}
                      labelPlacement="start"
                      className="bold mv-tagged-label"
                    />
                    </RadioGroup>
                    </Label>
                </FormGroup>

              </div>
              <div  style={{width:"65%"}}
              title={isTaggedByStore}
              >
              <Select
                    name="taggedBy"
                    isMulti
                    // components={{ MultiValueContainer }}
                    options={
                     labelValue ? props.managerView?.sourcedByData?.taggedBy?.length > 0
                        ? props.managerView.sourcedByData.taggedBy.filter((data)=> data.taggedBy !== "undefined-undefined"
                       && data.taggedBy !== "Not updated Yet"
                        
                        )
                        : [] :[]
                    }
                    closeMenuOnSelect={false}
                    isClearable={true}
                    isSearchable={true}
                    onChange={(item) =>
                      item === null ? setSourcedBy([]) : setSourcedBy(item)
                    }
                    value={sourcedBy}
                    getOptionLabel={(option) => option.taggedBy}
                    getOptionValue={(option) => option.taggedBy}
                    className=" manager-view-select-box"
                    styles={styles}

                  
                      
                    onMouseEnter={
                      (sourcedBy.length > 0 || updatedBy.length > 0) &&
                      handlePopoverOpenTaggedBy
                    }
                    onMouseLeave={
                      (sourcedBy.length > 0 || updatedBy.length > 0) &&
                      handlePopoverCloseTaggedBy
                    }
                  />
              </div>
              </div>
            </FormGroup>
            <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: "none",
                }}
                open={openTaggedBy}
                anchorEl={anchorTaggedBy}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={handlePopoverCloseTaggedBy}
                disableRestoreFocus
              >
                {sourcedBy.length > 0 &&
                  sourcedBy.map((item) => (
                    <Typography sx={{ p: 1, m: 1 }}>{item.taggedBy}</Typography>
                  ))}
                {updatedBy.length > 0 &&
                  updatedBy.map((item) => (
                    <Typography sx={{ p: 1, m: 1 }}>
                      {item.ct_updatedBy}
                    </Typography>
                  ))}
              </Popover>
          </Grid>

         
         
        </Grid>
        <Grid item xs={3} container alignItems="center" className="pr-1">
          <Grid item xs={12}>
            <FormGroup className="mb-0-5">
            <div style={{display:"flex"}}>
              <div style={{width:"30%"}}>
              <Label  style={{marginTop:"0.4rem",fontSize:"0.8rem",marginLeft:"0.5rem",whiteSpace:"nowrap"}}>TAC</Label>
              </div>
              <div  style={{width:"70%"}}
               title={isTacStore}
              >
              <Select
                name="tacName"
                isMulti
                // components={{ MultiValueContainer }}
                options={
                  // props.managerView.singleMultiFilterData.length &&
                  customer.length > 0
                    ? props?.managerView?.tacData?.tacName?.length > 0
                      ? props.managerView.tacData.tacName
                      : []
                    : []
                }
                closeMenuOnSelect={false}
                isClearable={true}
                isSearchable={true}
                onChange={(item) => onTacChange(item)}
                value={tac}
                getOptionLabel={(option) => option.tacName}
                getOptionValue={(option) => option.tacName}
                className=" manager-view-select-box"
                styles={styles}
               
                onMouseEnter={tac.length > 0 && handlePopoverOpenTAC}
                onMouseLeave={tac.length > 0 && handlePopoverCloseTAC}
              />
              </div>
              </div>
            </FormGroup>
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: "none",
              }}
              open={openTAC}
              anchorEl={anchorTAC}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverCloseTAC}
              disableRestoreFocus
            >
              {tac.length > 0 &&
                tac.map((item) => (
                  <Typography sx={{ p: 1, m: 1 }}>{item.tacName}</Typography>
                ))}
            </Popover>
          </Grid>

          <Grid style={{ marginBottom: "4.5rem" }} item xs={12}>
            <FormGroup className="mb-0-5">
            <div style={{display:"flex"}}>
              <div style={{width:"30%"}}>
              <Label  style={{marginTop:"0.4rem",fontSize:"0.8rem",marginLeft:"0.5rem",whiteSpace:"nowrap"}}>Can. Status</Label>
              </div>
              <div  style={{width:"70%"}}
              title={isCanStatusStore}
              >
              <Select
                name="candidateStatus"
                isMulti
                // components={{ MultiValueContainer }}
                options={
                  // props.managerView.singleMultiFilterData.length &&
                  props.managerView?.singleMultiFilterData?.candidateStatus
                    ?.length > 0
                    ? props.managerView.singleMultiFilterData.candidateStatus
                    : []
                }
                closeMenuOnSelect={false}
                isClearable={true}
                isSearchable={true}
                onChange={(item) => onCandiStatusChange(item)}
                value={candidateStatus}
                getOptionLabel={(option) => option.candidateStatus}
                getOptionValue={(option) => option.candidateStatus}
                className=" manager-view-select-box"
                styles={styles}
                onMouseEnter={
                  candidateStatus.length > 0 && handlePopoverOpenCandidateStatus
                }
                onMouseLeave={
                  candidateStatus.length > 0 &&
                  handlePopoverCloseCandidateStatus
                }
              />
               </div>
              </div>
            </FormGroup>
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: "none",
              }}
              open={openCandidateStatus}
              anchorEl={anchorCandidateStatus}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverCloseCandidateStatus}
              disableRestoreFocus
            >
              {candidateStatus.length > 0 &&
                candidateStatus.map((item) => (
                  <Typography sx={{ p: 1, m: 1 }}>
                    {item.candidateStatus}
                  </Typography>
                ))}
            </Popover>
          </Grid>
        </Grid>

        <Grid item xs={3} container alignItems="center" className="pr-1">
          <>
            <Grid item xs={12}>
              <FormGroup className="mb-0-5">
              <div style={{display:"flex"}}>
              <div style={{width:"35%"}}>
                <Label  style={{marginTop:"0.4rem",fontSize:"0.8rem",whiteSpace:"nowrap",marginLeft:"0.2rem"}}>Lead Recruiter</Label>
              </div>
              <div  style={{width:"65%"}}
              title={isLeadStore}
              >
                <Select
                  name="assignedTo"
                  isMulti
                  // components={{ MultiValueContainer }}
                  options={
                    props.managerView?.leadRecruiterData?.leadRecruiter
                      ?.length > 0
                      ? props.managerView.leadRecruiterData.leadRecruiter
                      : []
                  }
                  closeMenuOnSelect={false}
                  isClearable={true}
                  isSearchable={true}
                  onChange={(item) =>
                    
                    item === null
                      ? setLeadRecruiter([])
                      : setLeadRecruiter(item)
                  }
                  value={leadRecruiter}
                  getOptionLabel={(option) => option.assignedTo}
                  getOptionValue={(option) => option.assignedTo}
                  className="manager-view-select-box"
                  styles={styles}
                  onMouseEnter={
                    leadRecruiter.length > 0 && handlePopoverOpenLeadRecruiter
                  }
                  onMouseLeave={
                    leadRecruiter.length > 0 && handlePopoverCloseLeadRecruiter
                  }
                />
                </div>
              </div>
              </FormGroup>
              <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: "none",
                }}
                open={openLeadRecruiter}
                anchorEl={anchorLeadRecruiter}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={handlePopoverCloseLeadRecruiter}
                disableRestoreFocus
              >
                {leadRecruiter.length > 0 &&
                  leadRecruiter.map((item) => (
                    <Typography sx={{ p: 1, m: 1 }}>
                      {item.ra_assignedTo}
                    </Typography>
                  ))}
              </Popover>
            </Grid>

            <Grid style={{ marginBottom: "4.5rem" }} item xs={12}>
              <FormGroup className="mb-0-5">
              <div style={{display:"flex"}}>
              <div style={{width:"35%"}}>
                <Label  style={{marginTop:"0.4rem",fontSize:"0.8rem",whiteSpace:"nowrap",marginLeft:"0.2rem"}} >Hiring Stage</Label>
                </div>
              <div  style={{width:"65%"}}
              title={isHiringStageStore}
              >
                <Select
                  name="hiringStage"
                  isMulti
                  // components={{ MultiValueContainer }}
                  options={
                    props.managerView?.singleMultiFilterData?.hiringStage
                      ?.length > 0
                      ? props.managerView.singleMultiFilterData.hiringStage.filter(
                          (data) =>
                            data.hiringStage !== "" && data.hiringStage !== " "
                        )
                      : []
                  }
                  closeMenuOnSelect={false}
                  isClearable={() => {
                    setHiringStatus([]);
                    return true;
                  }}
                  isSearchable={true}
                  onChange={(item) => onHiringStageChange(item)}
                  // isDisabled={customer?.length > 0 && tac?.length > 0
                  //   && props.managerView.hiringStageData.length > 0 ? false:true}
                  value={hiringStage}
                  getOptionLabel={(option) => option.hiringStage}
                  getOptionValue={(option) => option.hiringStage}
                  className=" manager-view-select-box"
                  styles={styles}
                  onMouseEnter={
                    hiringStage.length > 0 && handlePopoverOpenHiringStage
                  }
                  onMouseLeave={
                    hiringStage.length > 0 && handlePopoverCloseHiringStage
                  }
                />
                   </div>
              </div>
              </FormGroup>
              <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: "none",
                }}
                open={openHiringStage}
                anchorEl={anchorHiringStage}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={handlePopoverCloseHiringStage}
                disableRestoreFocus
              >
                {hiringStage.length > 0 &&
                  hiringStage.map((item) => (
                    <Typography sx={{ p: 1, m: 1 }}>
                      {item.hiringStage}
                    </Typography>
                  ))}
              </Popover>
            </Grid>
          </>
        </Grid>
        <Grid item xs={3} container alignItems="center" className="pr-1">
          <>
          <Grid  item xs={12}>
            <FormGroup className="mb-0-5">
            <div style={{display:"flex"}}>
              <div style={{width:"34%"}}>
              <Label  style={{marginTop:"0.4rem",fontSize:"0.8rem",marginLeft:"0.5rem",whiteSpace:"nowrap"}}>Position</Label>
              </div>
              <div  style={{width:"66%"}}
              title={isPositionStore}
              >
              <Select
                name="jobPosition"
                isMulti
                // components={{ MultiValueContainer }}
                options={
                  props.managerView?.singleMultiFilterData?.jobPosition
                    ?.length > 0
                    ? props.managerView.singleMultiFilterData.jobPosition
                    : []
                }
              

                closeMenuOnSelect={false}
                isClearable={true}
                isSearchable={true}
                onChange={(item) => onPositionsChange(item)}
                value={position}
                getOptionLabel={(option) => option.jobPosition}
                getOptionValue={(option) => option.jobPosition}
                className=" manager-view-select-box"
                styles={styles}
                onMouseEnter={position.length > 0 && handlePopoverOpenPosition}
                onMouseLeave={position.length > 0 && handlePopoverClosePosition}
              />
               </div>
              </div>
            </FormGroup>
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: "none",
              }}
              open={openPosition}
              anchorEl={anchorPosition}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverClosePosition}
              disableRestoreFocus
            >
              {position.length > 0 &&
                position.map((item) => (
                  <Typography sx={{ p: 1, m: 1 }}>
                    {item.jobPosition}
                  </Typography>
                ))}
            </Popover>
          </Grid>
          

            <Grid style={{ marginBottom: "4.5rem" }} item xs={12}>
              <FormGroup className="mb-0-5">
              <div style={{display:"flex"}}>
              <div style={{width:"34%"}}>
                <Label  style={{marginTop:"0.4rem",fontSize:"0.8rem",marginLeft:"0.5rem",whiteSpace:"nowrap"}} >Hiring Status</Label>
                </div>
              <div  style={{width:"66%"}} title={isHiringStore}>
                <Box>
                  <Select
                    name="hiringStatus"
                    isMulti
                    // components={{ MultiValueContainer }}
                    options={
                      props.managerView?.singleMultiFilterData?.hiringStatus
                        ?.length > 0
                        ? props.managerView.singleMultiFilterData.hiringStatus.filter(
                            (data) =>
                              data.hiringStatus !== "" &&
                              data.hiringStatus !== " "
                          )
                        : []
                    }
                    closeMenuOnSelect={false}
                    isClearable={true}
                    isSearchable={true}
                    onChange={(item) => onHiringStatusChange(item)}
                    value={hiringStatus}
                    getOptionLabel={(option) => option.hiringStatus}
                    getOptionValue={(option) => option.hiringStatus}
                    className=" manager-view-select-box"
                    styles={styles}
                    onMouseEnter={
                      hiringStatus.length > 0 && handlePopoverOpenHiringStatus
                    }
                    onMouseLeave={
                      hiringStatus.length > 0 && handlePopoverCloseHiringStatus
                    }
                  />
                </Box>
                </div>
              </div>
              </FormGroup>
              <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: "none",
                }}
                open={openHiringStatus}
                anchorEl={anchorHiringStatus}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={handlePopoverCloseHiringStatus}
                disableRestoreFocus
              >
                {hiringStatus.length > 0 &&
                  hiringStatus.map((item) => (
                    <Typography sx={{ p: 1, m: 1 }}>
                      {item.hiringStatus}
                    </Typography>
                  ))}
              </Popover>
            </Grid>
          
          </>
        </Grid>
      </Grid>


      <Grid style={{marginTop:"1rem"}}  container>
      <Grid item xs={3} container alignItems="center" className="pr-1">
      <Grid item xs={12}>
              <FormGroup className="mb-0-5">
              <div style={{display:"flex"}}>
              <div style={{width:"35%"}}>
                {/* <Label  style={{marginTop:"0.4rem",fontSize:"0.8rem",whiteSpace:"nowrap",marginLeft:"0.2rem"}}>Updated By</Label> */}
                <FormGroup className="mb-0-5">
                <Label className="bold label-sm">
                 {" "}
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    // defaultValue="tagged"
                  >
                   <FormControlLabel
                      value="updated"
                      control={
                        <Radio
                        checked={labelUpdatedValue === "updated"}
                          size="small"
                          color="primary"
                          style={{ padding: 0 }}
                          onChange={() => {
                            setLabelUpdatedValue("updated")
                            setLabelValue(null)
                          }}
                        />
                      }
                      label={
                        <Label  style={{marginTop:"0.4rem",fontSize:"0.8rem",whiteSpace:"nowrap"}}>Updated By</Label>
                      }
                      labelPlacement="start"
                      className="bold mv-tagged-label"
                    />
                    </RadioGroup>
                    </Label>
                </FormGroup>
                </div>
              <div  style={{width:"65%"}}
              title={isUpdatedByStore}
              >
              <Select
                
                name="updatedBy"
                isMulti
                // components={{ MultiValueContainer }}
                options={
                 labelUpdatedValue ? props.managerView?.updatedByData?.updatedBy?.length > 0
                    ? props.managerView.updatedByData.updatedBy.filter((data)=> data.updatedBy !== "undefined-undefined"
                    && data.taggedBy !== "Not updated Yet"
                    )
                    : [] : []
                }
                closeMenuOnSelect={false}
                isClearable={true}
                isSearchable={true}
                onChange={(item) =>
                  item === null ? setUpdatedBy([]) : setUpdatedBy(item)
                }
                value={updatedBy}
                getOptionLabel={(option) => option.updatedBy}
                getOptionValue={(option) => option.updatedBy}
                // className=" manager-view-select-box"
                styles={styles}
                onMouseEnter={
                  (sourcedBy.length > 0 || updatedBy.length > 0) &&
                  handlePopoverOpenTaggedBy
                }
                onMouseLeave={
                  (sourcedBy.length > 0 || updatedBy.length > 0) &&
                  handlePopoverCloseTaggedBy
                }
              />
                </div>
              </div>
              </FormGroup>
              <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: "none",
                }}
                open={openLeadRecruiter}
                anchorEl={anchorLeadRecruiter}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={handlePopoverCloseLeadRecruiter}
                disableRestoreFocus
              >
                {leadRecruiter.length > 0 &&
                  leadRecruiter.map((item) => (
                    <Typography sx={{ p: 1, m: 1 }}>
                      {item.ra_assignedTo}
                    </Typography>
                  ))}
              </Popover>
            </Grid>
      </Grid>

     <Grid item xs={3} container alignItems="center" className="pr-1">
     <Grid item xs={12}>
            
            <FormGroup className="mb-0-5">
            <div style={{display:"flex"}}>
            <div style={{width:"30%",}}>
             <Label style={{marginTop:"0.4rem",fontSize:"0.8rem",marginLeft:"0.5rem",whiteSpace:"nowrap"}}>From Date</Label>
             </div>
             <div style={{width:"57%"}}>
             <Input
                style={{height:"2rem" }}
                type="date"
                name="sourcedFrom"
                value={values.sourcedFrom}
                onChange={onChange}
                className="form-control-sm manager-view-select-box manager-date-input"
                required={true}
                tabIndex={8}
              />
              </div>
              </div>
           </FormGroup>
          
           
          </Grid>
      </Grid>

      <Grid item xs={3} container alignItems="center" className="pr-1">
      <Grid  item xs={12}>
          
             <FormGroup className="mb-0-5">
             <div style={{display:"flex"}}>
             <div style={{width:"35%"}}>
               <Label style={{marginTop:"0.4rem",fontSize:"0.8rem",marginLeft:"0.1rem",whiteSpace:"nowrap"}}>To Date</Label>
               </div>
               <div style={{width:"52%"}}>
               <Input
                  style={{ height:"2rem" }}
                  type="date"
                  name="sourcedTo"
                  value={values.sourcedTo}
                  onChange={onChange}
                  className="form-control-sm manager-view-select-box manager-date-input"
                  required={true}
                  tabIndex={8}
                />
                </div>
                </div>
             </FormGroup>
           
           </Grid>
      </Grid>

      <Grid item xs={3} container alignItems="center" className="pr-1">
      <Grid  item xs={12}>

      <div style={{display:"flex"}}>
              <div style={{width:"60%"}}>
              <CustTooltip title="Search">
                <IconButton
                  onClick={onSearch}
                  style={{ padding: "0.5rem", paddingTop: 0 }}
                >
                  <SearchIcon
                    style={{ color: "#0056b3", fontSize: "1.25rem" }}
                  />
                </IconButton>
              </CustTooltip>
              <CustTooltip title="Reset">
                <IconButton
                  color="secondary"
                  onClick={reset}
                  style={{ padding: "0.5rem", paddingTop: 0 }}
                >
                  <RotateLeftIcon className="mui-icon-size" />
                </IconButton>
              </CustTooltip>
              <CustTooltip title="Download">
                <IconButton
                  style={{ padding: "0.5rem", paddingTop: 0 }}
                  onClick={downloadData}
                  disabled={props.managerView.data.length > 0 ? false : true}
                >
                  <GetAppIcon
                    style={{ color: "#0056b3", fontSize: "1.25rem" }}
                  />
                </IconButton>
              </CustTooltip> 
              </div>
              <div  style={{width:"40%",marginRight: props.managerView.data.length > 0 && "0.6rem"}}>
            
          <div className="form-control-sm">
            <Label style={{fontSize:"0.8rem",whiteSpace:"nowrap"}}>
              {props.managerView.data?.length} Records Found
            </Label>
          </div>
       

              </div>

              </div>





      </Grid>

      </Grid>

      {/* <Grid item xs={5} container alignItems="center" className="pr-1">
      
      <Grid item xs={12}>
     
      <div style={{ display: "flex", flexDirection: "row" }}>
         
         <div className="dateManagerReports-class-one">
           <Label
            
             style={{
               fontSize:"0.8rem",
               marginTop: "0.3rem",
             }}
           >
             From Date
           </Label>
           <FormGroup className="mb-0-5">
             <Input
               style={{ marginLeft: "0.5rem",width:"9.5rem",height:"2rem" }}
               type="date"
               name="sourcedFrom"
               value={values.sourcedFrom}
               onChange={onChange}
               className="form-control-sm manager-view-select-box manager-date-input"
               required={true}
               tabIndex={8}
             />
           </FormGroup>

           <Label
            
             style={{
               marginLeft: "2rem",
               marginTop: "0.3rem",
               fontSize:"0.8rem",
             }}
           >
             To Date
           </Label>

           <FormGroup className="mb-0-5">
             <Input
               style={{ marginLeft: "3rem",width:"8.5rem",height:"2rem" }}
               type="date"
               name="sourcedTo"
               value={values.sourcedTo}
               onChange={onChange}
               className="form-control-sm manager-view-select-box manager-date-input"
               required={true}
               tabIndex={8}
             />
           </FormGroup>
         </div> */}
      

      
         {/* <div
           style={{ display: "flex", marginTop: "1.5rem",marginRight:"0.5rem" }}
           className="form-control-sm"
         >
           
           <CustTooltip title="Search">
             <IconButton
               onClick={onSearch}
               style={{ padding: "0.5rem", paddingTop: 0 }}
             >
               <SearchIcon
                 style={{ color: "#0056b3", fontSize: "1.25rem" }}
               />
             </IconButton>
           </CustTooltip>
           <CustTooltip title="Reset">
             <IconButton
               color="secondary"
               onClick={reset}
               style={{ padding: "0.5rem", paddingTop: 0 }}
             >
               <RotateLeftIcon className="mui-icon-size" />
             </IconButton>
           </CustTooltip>
           <CustTooltip title="Download">
             <IconButton
               style={{ padding: "0.5rem", paddingTop: 0 }}
               onClick={downloadData}
               disabled={props.managerView.data.length > 0 ? false : true}
             >
               <GetAppIcon
                 style={{ color: "#0056b3", fontSize: "1.25rem" }}
               />
             </IconButton>
           </CustTooltip>
         </div> */}
     
     {/* </div>
     </Grid> */}
      {/* </Grid> */}

     

   

      </Grid>

      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
         
        }}
      >
     
            
              <div style={{display:"flex",width:"20rem",marginTop:"1rem"}}>
              <div style={{width:"29%",}} >
              <Label  style={{marginTop:"0.4rem",fontSize:"0.8rem"}} >Updated By</Label>
              </div>
              <div  style={{width:"67%",}}>
              <Select
                
                    name="updatedBy"
                    isMulti
                    components={{ MultiValueContainer }}
                    options={
                      props.managerView?.updatedByData?.updatedBy?.length > 0
                        ? props.managerView.updatedByData.updatedBy
                        : []
                    }
                    closeMenuOnSelect={false}
                    isClearable={true}
                    isSearchable={true}
                    onChange={(item) =>
                      item === null ? setUpdatedBy([]) : setUpdatedBy(item)
                    }
                    value={updatedBy}
                    getOptionLabel={(option) => option.updatedBy}
                    getOptionValue={(option) => option.updatedBy}
                    // className=" manager-view-select-box"
                    styles={styles}
                    onMouseEnter={
                      (sourcedBy.length > 0 || updatedBy.length > 0) &&
                      handlePopoverOpenTaggedBy
                    }
                    onMouseLeave={
                      (sourcedBy.length > 0 || updatedBy.length > 0) &&
                      handlePopoverCloseTaggedBy
                    }
                  />
                  </div>
              
          
            
           
          
              </div>
       
        <div style={{ display: "flex", flexDirection: "row",width:"40rem", }}>
         
            <div className="dateManagerReports-class-one">
              <Label
               
                style={{
                  fontSize:"0.8rem",
                  marginTop: "0.3rem",
                }}
              >
                From Date
              </Label>
              <FormGroup className="mb-0-5">
                <Input
                  style={{ marginLeft: "0.5rem",width:"9.5rem",height:"2rem" }}
                  type="date"
                  name="sourcedFrom"
                  value={values.sourcedFrom}
                  onChange={onChange}
                  className="form-control-sm manager-view-select-box manager-date-input"
                  required={true}
                  tabIndex={8}
                />
              </FormGroup>

              <Label
               
                style={{
                  marginLeft: "2rem",
                  marginTop: "0.3rem",
                  fontSize:"0.8rem",
                }}
              >
                To Date
              </Label>

              <FormGroup className="mb-0-5">
                <Input
                  style={{ marginLeft: "3rem",width:"8.5rem",height:"2rem" }}
                  type="date"
                  name="sourcedTo"
                  value={values.sourcedTo}
                  onChange={onChange}
                  className="form-control-sm manager-view-select-box manager-date-input"
                  required={true}
                  tabIndex={8}
                />
              </FormGroup>
            </div>
         

         
            <div
              style={{ display: "flex", marginTop: "1.5rem",marginRight:"0.5rem" }}
              className="form-control-sm"
            >
              
              <CustTooltip title="Search">
                <IconButton
                  onClick={onSearch}
                  style={{ padding: "0.5rem", paddingTop: 0 }}
                >
                  <SearchIcon
                    style={{ color: "#0056b3", fontSize: "1.25rem" }}
                  />
                </IconButton>
              </CustTooltip>
              <CustTooltip title="Reset">
                <IconButton
                  color="secondary"
                  onClick={reset}
                  style={{ padding: "0.5rem", paddingTop: 0 }}
                >
                  <RotateLeftIcon className="mui-icon-size" />
                </IconButton>
              </CustTooltip>
              <CustTooltip title="Download">
                <IconButton
                  style={{ padding: "0.5rem", paddingTop: 0 }}
                  onClick={downloadData}
                  disabled={props.managerView.data.length > 0 ? false : true}
                >
                  <GetAppIcon
                    style={{ color: "#0056b3", fontSize: "1.25rem" }}
                  />
                </IconButton>
              </CustTooltip>
            </div>
        
        </div>

        <div style={{marginTop:"1rem",width:"10%",marginLeft:"2rem",marginRight:"0.1rem"}}>
          <div className="form-control-sm">
            <Label style={{fontSize:"0.8rem",whiteSpace:"nowrap"}}>
              {props.managerView.data?.length} Records Found
            </Label>
          </div>
        </div>
      </div> */}

      <div style={{ overflowX: "scroll" }}>
        <Table style={{ width: "200rem" }} className="mt-2">
          <thead className="ul-head">
            <tr>
              <th
                className="list-label p-1"
                style={{ width: "5%", whiteSpace: "nowrap" }}
              >
                Req. Id
              </th>
              <th
                className="list-label p-1"
                style={{ width: "9%", whiteSpace: "nowrap" }}
              >
                Req. Primary Skills
              </th>
              <th
                className="list-label p-1 "
                style={{ width: "3%", whiteSpace: "nowrap" }}
              >
                Can. Id
              </th>
              <th
                className="list-label p-1 "
                style={{ width: "4%", whiteSpace: "nowrap" }}
              >
                Can. Name
              </th>

              <th
                className="list-label p-1"
                style={{ width: "4%", whiteSpace: "nowrap" }}
              >
                Tag/Updated By
              </th>
              <th
                className="list-label p-1"
                style={{ width: "4%", whiteSpace: "nowrap" }}
              >
               Can. Status
              </th>
              <th
                className="list-label p-1"
                style={{ width: "4%", whiteSpace: "nowrap" }}
              >
                Hiring Stage
              </th>
              <th
                className="list-label p-1"
                style={{ width: "4%", whiteSpace: "nowrap" }}
              >
                Hiring Status
              </th>
              <th
                className="list-label p-1"
                style={{ width: "4%", whiteSpace: "nowrap" }}
              >
                Joined Date
              </th>
              <th
                className="list-label p-1"
                style={{ width: "4%", whiteSpace: "nowrap" }}
              >
                Can Email
              </th>
              <th
                className="list-label p-1"
                style={{ width: "4%", whiteSpace: "nowrap" }}
              >
                Can Phone
              </th>
              <th
                className="list-label p-1"
                style={{ width: "4%", whiteSpace: "nowrap" }}
              >
                Yrs of Exp
              </th>
              <th
                className="list-label p-1"
                style={{ width: "4%", whiteSpace: "nowrap" }}
              >
                Rel Yrs of Exp
              </th>
              <th
                className="list-label p-1"
                style={{ width: "4%", whiteSpace: "nowrap" }}
              >
                Location
              </th>
              <th
                className="list-label p-1"
                style={{ width: "4%", whiteSpace: "nowrap" }}
              >
                Pre. Location
              </th>

              <th
                className="list-label p-1"
                style={{ width: "4%", whiteSpace: "nowrap" }}
              >
                Notice Period
              </th>
              <th
                className="list-label p-1"
                style={{ width: "4%", whiteSpace: "nowrap" }}
              >
                Current CTC
              </th>
              <th
                className="list-label p-1"
                style={{ width: "4%", whiteSpace: "nowrap" }}
              >
                Expected CTC
              </th>
              <th
                className="list-label p-1"
                style={{ width: "4%", whiteSpace: "nowrap" }}
              >
                Current Employer
              </th>
              <th
                className="list-label p-1"
                style={{ width: "7%", whiteSpace: "nowrap" }}
              >
                Can Primary Skills
              </th>

              <th
                className="list-label p-1"
                style={{ width: "5%", whiteSpace: "nowrap" }}
              >
                Can Secondary Skills
              </th>
              <th
                className="list-label p-1"
                style={{ width: "5%", whiteSpace: "nowrap" }}
              >
                Comments
              </th>

              <th
                className="list-label p-1"
                style={{ width: "5%", whiteSpace: "nowrap" }}
              >
                Tagged On
              </th>

              <th
                className="list-label p-1"
                style={{ width: "5%", whiteSpace: "nowrap" }}
              >
                Updated On
              </th>
            </tr>
          </thead>
          <tbody>
            {props.managerView.data.length > 0 &&
              props.managerView.data.map((item) => (
                <tr key={item.id}>
                  <CustTooltip title="More Details">
                    <td
                      className="list-data p-1 pointer "
                      style={{ width: "5%", color: "blue" }}
                      onClick={() => viewRequirementDetails(item.jr_id)}
                    >
                      {item.jr_id}
                    </td>
                  </CustTooltip>
                  <td className="list-data p-1" style={{ width: "9%" }}>
                  
                  {item?.jr_primarySkills ? item?.jr_primarySkills.length >=35 ? `${item?.jr_primarySkills.substring(0,35)}...`:
                  item?.jr_primarySkills :""
                  
                  }
                  </td>
                  <CustTooltip title="More Details">
                    <td
                      className="list-data p-1 pointer"
                      style={{ width: "3%", color: "blue" }}
                      onClick={() => viewCandidateDetails(item.ci_id)}
                    >
                      {item.ci_id}
                    </td>
                  </CustTooltip>
                  <td className="list-data p-1" style={{ width: "4%" }}>
                    {item.ci_firstName !== null &&
                      item.ci_lastName !== null &&
                      `${item.ci_firstName} ${item.ci_lastName}`}
                  </td>
                  {labelValue === "Tagged" && (
                    <td className="list-data p-1" style={{ width: "4%" }}>
                      {item.ct_taggedBy}
                    </td>
                  )}
                  {labelUpdatedValue === "updated" && (
                    <td className="list-data p-1" style={{ width: "4%" }}>
                      {item.ct_updatedBy}
                    </td>
                  )}
                   {/* {labelUpdatedValue === null && (
                    <td className="list-data p-1" style={{ width: "5%" }}>
                      {item.ct_taggedBy}
                    </td>
                  )} */}
                    <td className="list-data p-1" style={{ width: "4%" }}>
                    {item.ct_candidateStatus}
                  </td>

                  <td className="list-data p-1" style={{ width: "4%" }}>
                    {item.ct_hiringStage}
                  </td>
                  <td className="list-data p-1" style={{ width: "4%" }}>
                    {item.ct_hiringStatus}
                  </td>
                  <td className="list-data p-1" style={{ width: "4%" }}>
                        {item.ct_dateOfJoin === " " || item.ct_dateOfJoin === ""||
                          item.ct_dateOfJoin === null 
                          ?
                          null:
                          DateFns.format(
                            item.ct_dateOfJoin,
                            "DD-MM-YYYY"
                          )}
                   
                  </td>
                  <td className="list-data p-1" style={{ width: "4%" }}>
                    {item.ci_eMailId}
                  </td>
                  <td className="list-data p-1" style={{ width: "4%" }}>
                    {item.ci_mobileNo}
                  </td>
                  <td className="list-data p-1" style={{ width: "4%" }}>
                    {item.ci_yearOfExp}
                  </td>
                  <td className="list-data p-1" style={{ width: "4%" }}>
                    {item.ces_relYearOfExp}
                  </td>
                  <td className="list-data p-1" style={{ width: "4%" }}>
                    {item.ci_location}
                  </td>
                  <td className="list-data p-1" style={{ width: "4%" }}>
                    {item.ce_preferredWorkLoction}
                  </td>
                  <td className="list-data p-1" style={{ width: "4%" }}>
                    {item.ce_noticePeriod}
                  </td>
                  <td className="list-data p-1" style={{ width: "4%" }}>
                    {item.ce_currentCtc}
                  </td>
                  <td className="list-data p-1" style={{ width: "4%" }}>
                    {item.ce_exepectedCtc}
                  </td>
                  <td className="list-data p-1" style={{ width: "4%" }}>
                    {item.ce_currentEmployeer}
                  </td>
                  <td className="list-data p-1" style={{ width: "7%" }}>
                  {item.cps_skill ? item.cps_skill.length >=35 ? `${item.cps_skill.substring(0,35)}...`:
                 item.cps_skill:""
                  }
                  </td>

                  <td className="list-data p-1" style={{ width: "5%" }}>
                    
                    {item.css_skill}
                  </td>
                  <td className="list-data p-1" style={{ width: "5%" }}>
                    {item.ct_comment}
                  </td>

                  <td className="list-data p-1" style={{ width: "5%" }}>
                    {item.ct_taggedOn &&
                      DateFns.format(item.ct_taggedOn, "DD-MM-YYYY")}
                  </td>

                  <td className="list-data p-1" style={{ width: "5%" }}>
                    {item.ct_updatedOn &&
                      DateFns.format(item.ct_updatedOn, "DD-MM-YYYY")}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      {!isOpen ? (
        <Modal style={{ maxWidth: "70%" }} isOpen={reqView}>
          <ModalHeader toggle={() => setReqView(false)}>
            Requirement : {values.reqId}
          </ModalHeader>
          <ModalBody>
            <div className="topnav">
              <a
                onClick={handleOpenTaggedView}
                className={
                  !isOpen ? "dark-theme-manview" : "light-theme-manview"
                }
              >
                Details
              </a>
              <a
                onClick={handleOpenView}
                className={
                  isOpen ? "dark-theme-manview" : "light-theme-manview"
                }
                style={{ marginLeft: "1rem" }}
              >
                {" "}
                Candidates
              </a>
            </div>
            <RequirementDetails
              data={
                props.managerView.reqData.length > 0 &&
                props.managerView.reqData[0]
              }
            />
          </ModalBody>
        </Modal>
      ) : (
        <CandidateKanban
          reqId={values.reqId}
          taggedView={isOpen}
          closeModel={handleOpenTaggedView}
        />
      )}
      <Modal style={{ maxWidth: "70%" }} isOpen={canView}>
        <ModalHeader toggle={() => setCanView(false)}>
          Candidate : {values.canId}
        </ModalHeader>
        <ModalBody>
          <CandidateDetails
            canId={values.canId}
            data={
              props.managerView.canData.length > 0 &&
              props.managerView.canData[0]
            }
          />
        </ModalBody>
      </Modal>

      {/* {props.managerView.data.message ? (
          <Notifier message={props.managerView.data.message } />
        ) : null} */}
      {/* {errorMessage ? (
          <ErrorNotifier message={errorMessage} />
        ) : null} */}

      {props.managerView.error ? (
        <ErrorNotifier message={props.managerView.error} />
      ) : null}
      {errorEmptyLog ? <ErrorNotifier message={errorEmptyLog} /> : null}
    </ListContainer>
  );
};

ManagerView.propTypes = {
  props: PropTypes,
  searchManagerView: PropTypes.func.isRequired,
  resetManagerView: PropTypes.func.isRequired,
  searchManagerViewCandidate: PropTypes.func.isRequired,
  managerView: PropTypes.object.isRequired,
  getManagerViewCustomers: PropTypes.func.isRequired,
  getManagerViewTAC: PropTypes.func.isRequired,
  getManagerViewPosition: PropTypes.func.isRequired,
  getManagerViewLeadRecruiter: PropTypes.func.isRequired,
  getManagerViewSourcedBy: PropTypes.func.isRequired,
  getManagerViewUpdatedBy: PropTypes.func.isRequired,
  getManagerViewCandidateStatus: PropTypes.func.isRequired,
  getManagerViewHiringStage: PropTypes.func.isRequired,
  getHiringStatSelect: PropTypes.func.isRequired,
  hiringStatus: PropTypes.object.isRequired,
  fetchRequirementDetails: PropTypes.func.isRequired,
  fetchCandidateDetails: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  managerView: state.managerView,
  RSrequirement: state.RSrequirement,
  hiringStatus: state.hiringStatus,
});

const mapDispatchToProps = (dispatch) => ({
  searchManagerView: (data) => dispatch(searchManagerView(data)),
  getContactsRSName: (data) => dispatch(getContactsRSName(data)),
  resetManagerView: () => dispatch(resetManagerView()),
  searchManagerViewCandidate: (candidateData) =>
    dispatch(searchManagerViewCandidate(candidateData)),
  getManagerViewCustomers: () => dispatch(getManagerViewCustomers()),
  getManagerViewTAC: (data) => dispatch(getManagerViewTAC(data)),
  getManagerViewPosition: () => dispatch(getManagerViewPosition()),
  getManagerViewLeadRecruiter: (data) =>
    dispatch(getManagerViewLeadRecruiter(data)),
  getManagerViewSourcedBy: (data) => dispatch(getManagerViewSourcedBy(data)),
  getManagerViewUpdatedBy: (data) => dispatch(getManagerViewUpdatedBy(data)),
  getManagerViewCandidateStatus: () =>
    dispatch(getManagerViewCandidateStatus()),
  getManagerViewHiringStage: () => dispatch(getManagerViewHiringStage()),
  getHiringStatSelect: () => dispatch(getHiringStatSelect()),
  getManagerViewHiringStatus: () => dispatch(getManagerViewHiringStatus()),
  fetchRequirementDetails: (id) => dispatch(fetchRequirementDetails(id)),
  fetchCandidateDetails: (data) => dispatch(fetchCandidateDetails(data)),
  getManagerViewSingleMultiTac: (data) =>
    dispatch(getManagerViewSingleMultiTac(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManagerView);
