import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// components
import UpdateAddress from "./UpdateAddress";
// redux
import { getContactsAddress } from "../../../../../../redux/actions/hcm/my_profile/contacts_details/contactsAddressAction";

class Address extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateBtn: false,
    };
    this.openUpdateForm = this.openUpdateForm.bind(this);
    this.closeUpdateForm = this.closeUpdateForm.bind(this);
    this.getData = this.getData.bind(this);
  }

  openUpdateForm() {
    this.setState({
      updateBtn: true,
    });
  }

  closeUpdateForm() {
    this.setState({
      updateBtn: false,
    });
    this.getData();
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.props.getContactsAddress();
  }

  render() {
    const { addBtn, updateBtn } = this.state;

    return (
      <React.Fragment>
        {!addBtn && !updateBtn ? (
          <React.Fragment>
            <i
              className="material-icons pointer contact-edit-btn"
              onClick={this.openUpdateForm}
            >
              edit
            </i>
            <h5 className="bold center">Address</h5>
            <ul>
              <li className="contact-address-li">
                <span className="label-sm bold contact-address-span">
                  Communication Address
                </span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  <span className="label-sm contact-address-data">
                    {this.props.contactsAddress.data.length
                      ? this.props.contactsAddress.data[0].communicationAddress
                      : null}
                  </span>
                </span>
              </li>
              <li>
                <span className="label-sm bold contact-address-span">
                  Permanent Address
                </span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  <span className="label-sm contact-address-data">
                    {this.props.contactsAddress.data.length
                      ? this.props.contactsAddress.data[0].permanentAddress
                      : null}
                  </span>
                </span>
              </li>
            </ul>
          </React.Fragment>
        ) : null}
        {updateBtn && (
          <UpdateAddress
            back={this.closeUpdateForm}
            data={this.props.contactsAddress.data}
          />
        )}
      </React.Fragment>
    );
  }
}

Address.propTypes = {
  getContactsAddress: PropTypes.func.isRequired,
  contactsAddress: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  contactsAddress: state.contactsAddress,
});

export default connect(mapStateToProps, { getContactsAddress })(Address);
