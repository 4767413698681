import axios from "axios";
import {
  LEAVE_MANAGEMENT_GET,
  LEAVE_MANAGEMENT_FETCH,
  LEAVE_MANAGEMENT_SEARCH,
  EMPLOYEE_LEAVES_FETCH,
} from "../../types";
import {
  CURD_LEAVE_MANAGEMENT,
  FETCH_LEAVE_MANAGEMENT,
  SEARCH_LEAVE_MANAGEMENT,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addLeaveManagement = (data, back) => (dispatch) => {
  axios
    .post(CURD_LEAVE_MANAGEMENT, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getLeaveManagement));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getLeaveManagement = () => (dispatch) => {
  axios
    .get(CURD_LEAVE_MANAGEMENT, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(LEAVE_MANAGEMENT_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const searchLeaveManagement = (data) => (dispatch) => {
  axios
    .get(SEARCH_LEAVE_MANAGEMENT, data, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(LEAVE_MANAGEMENT_SEARCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const fetchLeaveManagement = (data) => (dispatch) => {
  axios
    .post(FETCH_LEAVE_MANAGEMENT, data, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(LEAVE_MANAGEMENT_FETCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const fetchEmployeeLeaves = (data) => (dispatch) => {
  axios
    .get(`${CURD_LEAVE_MANAGEMENT}/${data}`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(EMPLOYEE_LEAVES_FETCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateLeaveManagement = (data, back) => (dispatch) => {
  axios
    .put(CURD_LEAVE_MANAGEMENT, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteLeaveManagement = (data) => (dispatch) => {
  axios
    .delete(CURD_LEAVE_MANAGEMENT, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getLeaveManagement));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
