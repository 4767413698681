import React from "react";
import Iframe from "react-iframe";

/*
This class component for viewing the file using the Iframe is a react component use library is react-iframe
*/
class ResumeViewer extends React.Component {
  render() {
    let file = this.props.URL;
    return (
      <div>
        <Iframe url={file} width="100%" height="600px" />
      </div>
    );
  }
}
export default ResumeViewer;
