import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

// redux
import { getEmpAccessAndPermissionDetails } from "../../../redux/actions/iam/authentication/authenticationAndAuthorizationAction";
import { IAM_API } from "../../../utils/config";

// ---------------------------------------------------------------------------------------------------------------------------------
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applicationId: "",
      moduleId: "",
    };
  }

  toggleApplication = (id) => {
    if (Number(this.state.applicationId) === Number(id)) {
      this.setState({
        applicationId: "",
      });
    } else {
      this.setState({
        applicationId: id,
      });
    }
  };

  toggleModule = (id) => {
    this.setState({
      moduleId: id,
    });
  };

  async componentDidMount() {
    await this.props.getEmpAccessAndPermissionDetails();
  }

  render() {
    const { applicationId, moduleId } = this.state;

    /*
giving conditional css for the sidebar div
*/
    let sidebarId = "no-sidebar";

    if (this.props.show) {
      sidebarId = "sidebar";
    }

    return (
      <div id={sidebarId}>
        <SimpleBar
          style={{ maxHeight: 600, direction: "b", color: "#fff" }}
          autoHide={false}
          className="simple-scrollbar"
        >
          <div className="sidebar-block">
            {typeof this.props.auth.data.accesses !== "undefined" &&
              this.props.auth.data.accesses.length > 0 &&
              this.props.auth.data.accesses.map((item) => {
                return (
                  item.access && (
                    <div key={item.id}>
                      <div
                        className="sidebar-main-item left white-text"
                        style={{ display: "flex" }}
                        onClick={() => this.toggleApplication(item.id)}
                      >
                        <img
                          src={`${IAM_API}/all/${item.appIcon}`}
                          alt={item.appName}
                          height={25}
                          width={25}
                          className="sidebar-app-icon"
                        />
                        <h6 className="sidebar-mainmenu-header">
                          {item.appName}
                        </h6>
                      </div>
                      {Number(applicationId) === Number(item.id) ? (
                        item.pieUserModuleAccess.map((item1) => {
                          return (
                            <div
                              key={item1.id}
                              className="sidebar-side-items left"
                            >
                              {item1.access && (
                                <Link
                                  to={{
                                    pathname: item1.moduleBrowserLink,
                                    moduleName: item1.moduleName,
                                    data: item1.pieUserServiceAccess,
                                  }}
                                  className="no-dec"
                                  onClick={() => this.toggleModule(item1.id)}
                                >
                                  <p
                                    className={
                                      moduleId === item1.id
                                        ? "sidebar-side-item selected white-text"
                                        : "sidebar-side-item white-text"
                                    }
                                  >
                                    {item1.moduleName}
                                  </p>
                                </Link>
                              )}
                            </div>
                          );
                        })
                      ) : (
                        <Redirect to="/pie" />
                      )}
                    </div>
                  )
                );
              })}
          </div>
        </SimpleBar>
      </div>
    );
  }
}

/*
To check the property type 
*/
Sidebar.propTypes = {
  auth: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  getEmpAccessAndPermissionDetails: PropTypes.func.isRequired,
};

/*
function to take redux value
*/
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { getEmpAccessAndPermissionDetails })(
  Sidebar
);
