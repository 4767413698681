import axios from "axios";
import {
  EMP_PROFESSIONAL_INFO_GET,
  EMP_PROFESSIONAL_INFO_FETCH,
} from "../../types";
import {
  CURD_EMP_PROFESSIONAL_INFO,
  FETCH_EMP_PROFESSIONAL_INFO,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addEmpProfessionalInfo = (data, back) => (dispatch) => {
  axios
    .post(CURD_EMP_PROFESSIONAL_INFO, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getEmpProfessionalInfo));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getEmpProfessionalInfo = () => (dispatch) => {
  axios
    .get(CURD_EMP_PROFESSIONAL_INFO, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(EMP_PROFESSIONAL_INFO_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const fetchEmpProfessionalInfo = () => (dispatch) => {
  axios
    .get(FETCH_EMP_PROFESSIONAL_INFO, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(EMP_PROFESSIONAL_INFO_FETCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateEmpProfessionalInfo = (data, back) => (dispatch) => {
  axios
    .put(CURD_EMP_PROFESSIONAL_INFO, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteEmpProfessionalInfo = (data) => (dispatch) => {
  axios
    .delete(CURD_EMP_PROFESSIONAL_INFO, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, fetchEmpProfessionalInfo));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
