import axios from "axios";
import {
  SALES_MILESTONE_FEEDBACK_ADD,
  SALES_MILESTONE_FEEDBACK_GET,
  SALES_MILESTONE_FEEDBACK_UPDATE,
  SALES_MILESTONE_FEEDBACK_DELETE,
  GET_ERRORS,
} from "./types";
import {
  ADD_SALES_MILESTONE_FEEDBACK,
  GET_SALES_MILESTONE_FEEDBACK,
  UPDATE_SALES_MILESTONE_FEEDBACK,
  DELETE_SALES_MILESTONE_FEEDBACK,
} from "../../utils/routes";
import { setMessage } from "./salesActions";

export const addSalesMilestoneFeedback = (data, back) => (dispatch) => {
  axios
    .post(ADD_SALES_MILESTONE_FEEDBACK, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getSalesMilestoneFeedback());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const getSalesMilestoneFeedback = () => (dispatch) => {
  axios
    .get(GET_SALES_MILESTONE_FEEDBACK, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: SALES_MILESTONE_FEEDBACK_GET,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};

export const updateSalesMilestoneFeedback = (data, back) => (dispatch) => {
  axios
    .post(UPDATE_SALES_MILESTONE_FEEDBACK, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getSalesMilestoneFeedback());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const deleteSalesMilestoneFeedback = (data) => (dispatch) => {
  axios
    .post(DELETE_SALES_MILESTONE_FEEDBACK, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getSalesMilestoneFeedback());
      }, 2000);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};
