import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form } from "reactstrap";
// components
import FormComponent from "../../../../../common/FormComponent";
import {
  Validator,
  submitValidator,
  refresh,
  valid,
} from "../../../../../common/Validator";
import Notifier from "../../../../../aside/Notifier";
import ErrorNotifier from "../../../../../aside/ErrorNotifier";
// redux
import { updateOtherAccountsInfo } from "../../../../../../redux/actions/hcm/my_profile/accounts_details/otherAccountsInfoAction";

// ------------------------------------------------------------------------------------------------------------------------------------
class UpdateOtherAccountDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      panCardNo: this.props.data.length ? this.props.data[0].pan : "",
      esiNo: this.props.data.length ? this.props.data[0].esiNumber : "",
      uanNo: this.props.data.length ? this.props.data[0].uan : "",
      pfAccountNo: this.props.data.length ? this.props.data[0].pfNo : "",
      message: "",
      error: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      pan: this.state.panCardNo,
      esiNumber: this.state.esiNo,
      uan: this.state.uanNo,
      pfNo: this.state.pfAccountNo,
    };
    if (valid) {
      this.props.updateOtherAccountsInfo(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    refresh();
    this.setState({
      message: "",
      error: "",
    });
  }

  render() {
    const { panCardNo, esiNo, uanNo, pfAccountNo, message, error } = this.state;

    return (
      <React.Fragment>
        <i
          className="material-icons cancel-button pointer"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <FormComponent
            labelClass="bold label-sm"
            label="PAN Card No."
            type="text"
            name="panCardNo"
            inputClass="form-control-sm"
            value={panCardNo}
            change={this.onChange}
            required={true}
            maxlength="10"
          />
          <FormComponent
            labelClass="bold label-sm"
            label="ESI No."
            type="number"
            name="esiNo"
            inputClass="form-control-sm"
            value={esiNo}
            change={this.onChange}
            required={true}
          />
          <FormComponent
            labelClass="bold label-sm"
            label="UAN No."
            type="number"
            name="uanNo"
            inputClass="form-control-sm"
            value={uanNo}
            change={this.onChange}
            required={true}
          />
          <FormComponent
            labelClass="bold label-sm"
            label="PF Account No."
            type="text"
            name="pfAccountNo"
            inputClass="form-control-sm"
            value={pfAccountNo}
            change={this.onChange}
            required={true}
            maxlength="22"
          />
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Update
            </button>
          </div>
        </Form>
        {this.props.otherAccountsInfo.message && (
          <Notifier message={this.props.otherAccountsInfo.message} />
        )}
        {this.props.otherAccountsInfo.error && (
          <ErrorNotifier message={this.props.otherAccountsInfo.error} />
        )}
      </React.Fragment>
    );
  }
}

UpdateOtherAccountDetails.propTypes = {
  updateOtherAccountsInfo: PropTypes.func.isRequired,
  otherAccountsInfo: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  otherAccountsInfo: state.otherAccountsInfo,
});

export default connect(mapStateToProps, { updateOtherAccountsInfo })(
  UpdateOtherAccountDetails
);
