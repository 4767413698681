import React, { Component } from "react";
import { Table, Container } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DateFns from "date-fns";
// components
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import AddNomination from "./AddNomination";
import UpdateNomination from "./UpdateNomination";
import AddDependent from "./AddDependent";
import UpdateDependent from "./UpdateDependent";
import DeleteIcon from "@material-ui/icons/Delete";
import { refresh } from "../../../../common/Validator";
import ConfirmDialog from "../../../../common/confirm-dialog/ConfirmDialog";
// redux
import {
  fetchEmpNominee,
  deleteEmpNominee,
} from "../../../../../redux/actions/hcm/my_profile/empNomineeAction";
import {
  fetchEmpDependents,
  deleteEmpDependents,
} from "../../../../../redux/actions/hcm/my_profile/empDependentsAction";

class Nomination extends Component {
  constructor() {
    super();
    this.state = {
      nominationAddButton: false,
      nominationUpdateButton: false,
      addDependentBtn: false,
      updateDependentBtn: false,
      data: [],
      dependentData: [],
      updateData: [],
      updateDependentData: [],
      confirmDialog: {
        isOpen: false,
        title: "",
        subTitle: "",
      },
      message: null,
      error: null,
    };

    this.addNominationDetails = this.addNominationDetails.bind(this);
    this.updateNominationDetails = this.updateNominationDetails.bind(this);
    this.deleteNominationDetails = this.deleteNominationDetails.bind(this);
    this.getData = this.getData.bind(this);
    this.updateNominationDetailsBack =
      this.updateNominationDetailsBack.bind(this);
    this.addDependent = this.addDependent.bind(this);
    this.getDependentsData = this.getDependentsData.bind(this);
    this.updateDependent = this.updateDependent.bind(this);
    this.updateDependentBack = this.updateDependentBack.bind(this);
    this.deleteDependent = this.deleteDependent.bind(this);
  }

  componentDidMount() {
    this.getData();
    this.getDependentsData();
  }

  getData() {
    this.props.fetchEmpNominee();
  }

  getDependentsData() {
    this.props.fetchEmpDependents();
  }

  addNominationDetails() {
    this.setState({
      nominationAddButton: !this.state.nominationAddButton,
    });

    this.getData();
    refresh();
  }

  addDependent() {
    this.setState({
      addDependentBtn: !this.state.addDependentBtn,
    });

    this.getDependentsData();
  }

  updateNominationDetails(item) {
    this.setState({
      updateData: item,
      nominationUpdateButton: !this.state.nominationUpdateButton,
    });
  }

  updateDependent(item) {
    this.setState({
      updateDependentData: item,
      updateDependentBtn: true,
    });
  }

  updateNominationDetailsBack() {
    this.setState({
      nominationUpdateButton: false,
    });
    this.getData();
  }

  updateDependentBack() {
    this.setState({
      updateDependentBtn: false,
    });

    this.getDependentsData();
  }

  deleteNominationDetails(item) {
    let data = {
      id: item.id,
    };
    this.props.deleteEmpNominee(data);
    this.setState((prevState) => ({
      ...prevState.confirmDialog,
      confirmDialog: {
        isOpen: false,
      },
    }));
  }

  deleteDependent(item) {
    let data = {
      id: item.id,
    };
    this.props.deleteEmpDependents(data);
    this.setState((prevState) => ({
      ...prevState.confirmDialog,
      confirmDialog: {
        isOpen: false,
      },
    }));
  }

  setConfirmDialog = (obj) => {
    this.setState((prevProps) => ({
      confirmDialog: {
        ...prevProps.confirmDialog,
        isOpen: obj.isOpen,
      },
    }));
  };

  render() {
    const {
      data,
      dependentData,
      updateDependentData,
      nominationAddButton,
      addDependentBtn,
      nominationUpdateButton,
      updateDependentBtn,
    } = this.state;

    return (
      <Container className="card container-card">
        {!nominationAddButton &&
        !nominationUpdateButton &&
        !addDependentBtn &&
        !updateDependentBtn ? (
          <React.Fragment>
            <i
              className="material-icons pointer cancel-button"
              onClick={this.props.back}
            >
              cancel
            </i>
            <div style={{ position: "relative" }} className="card mt-3 p-3">
              <button
                className="btn add-button white-text label-sm"
                onClick={this.addDependent}
              >
                Add
              </button>
              <h5 className="bold center">Dependents</h5>
              <Table>
                <thead>
                  <tr>
                    <th className="opp-head">Name</th>
                    <th className="opp-head">Type</th>
                    <th className="opp-head my-profile-nominee-dob">DOB</th>
                    <th className="opp-head">Age</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.empDependents.fetchData.length > 0 &&
                    this.props.empDependents.fetchData.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td className="opp-tableData">
                            {item.dependentName}
                          </td>
                          <td className="opp-tableData">
                            {item.dependentType}
                          </td>
                          <td className="opp-tableData my-profile-nominee-dob">
                            {DateFns.format(item.dateOfBirth, "DD-MM-YYYY")}
                          </td>
                          <td className="opp-tableData">{item.age}</td>
                          <td className="dependent-edit-btn-td">
                            <span
                              className="material-icons pointer opp-action-edit"
                              style={{ color: "blue" }}
                              onClick={() => this.updateDependent(item)}
                            >
                              edit
                            </span>
                          </td>
                          <td className="dependent-delete-btn-td">
                            <DeleteIcon
                              onClick={() =>
                                this.setState({
                                  confirmDialog: {
                                    isOpen: true,
                                    title:
                                      "Are you sure to delete this record?",
                                    subTitle: "You can't undo this operation",
                                    onConfirm: () => this.deleteDependent(item),
                                  },
                                })
                              }
                              className="pointer"
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
            <hr />
            <div style={{ position: "relative" }} className="card mt-3 p-3">
              <button
                className="btn add-button white-text label-sm"
                onClick={this.addNominationDetails}
              >
                Add
              </button>
              <h5 className="bold center">Nomination Details</h5>
              <Table>
                <thead>
                  <tr>
                    <th className="opp-head">Name</th>
                    <th className="opp-head">Age</th>
                    <th className="opp-head my-profile-nominee-dob">DOB</th>
                    <th className="opp-head">Relation</th>
                    <th className="opp-head">Address</th>
                    <th className="opp-head">Geography</th>
                    <th className="opp-head">Country</th>
                    <th className="opp-head">Dialing Code</th>
                    <th className="opp-head">Contact No.</th>
                    <th className="opp-head">Email Id</th>
                    <th className="opp-head">Share Percentage</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.empNominee.fetchData.length > 0 &&
                    this.props.empNominee.fetchData.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td className="opp-tableData">{item.nomineeName}</td>
                          <td className="opp-tableData">{item.nomineeAge}</td>
                          <td className="opp-tableData my-profile-nominee-dob">
                            {DateFns.format(item.nomineeDob, "DD-MM-YYYY")}
                          </td>
                          <td className="opp-tableData">{item.relation}</td>
                          <td className="opp-tableData">
                            {item.nomineeAddress}
                          </td>
                          <td className="opp-tableData">{item.geography}</td>
                          <td className="opp-tableData">{item.country}</td>
                          <td className="opp-tableData">{item.dialingCode}</td>
                          <td className="opp-tableData">
                            {item.nomineeContact}
                          </td>
                          <td className="opp-tableData">{item.nomineeEmail}</td>
                          <td className="opp-tableData">
                            {item.sharePercentage}
                          </td>
                          <td>
                            <span
                              className="material-icons pointer opp-action-edit"
                              style={{ color: "blue" }}
                              onClick={() => this.updateNominationDetails(item)}
                            >
                              edit
                            </span>
                          </td>
                          <td>
                            <DeleteIcon
                              onClick={() =>
                                this.setState({
                                  confirmDialog: {
                                    isOpen: true,
                                    title:
                                      "Are you sure to delete this record?",
                                    subTitle: "You can't undo this operation",
                                    onConfirm: () =>
                                      this.deleteNominationDetails(item),
                                  },
                                })
                              }
                              className="pointer"
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
          </React.Fragment>
        ) : null}
        {nominationAddButton ? (
          <AddNomination back={this.addNominationDetails} />
        ) : null}
        {nominationUpdateButton ? (
          <UpdateNomination
            back={this.updateNominationDetailsBack}
            data={this.state.updateData}
          />
        ) : null}
        {addDependentBtn ? <AddDependent back={this.addDependent} /> : null}
        {updateDependentBtn ? (
          <UpdateDependent
            back={this.updateDependentBack}
            data={updateDependentData}
          />
        ) : null}
        {this.state.message ? <Notifier message={this.state.message} /> : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
        <ConfirmDialog
          confirmDialog={this.state.confirmDialog}
          setConfirmDialog={this.setConfirmDialog}
        />
      </Container>
    );
  }
}

Nomination.propTypes = {
  fetchEmpNominee: PropTypes.func.isRequired,
  deleteEmpNominee: PropTypes.func.isRequired,
  empNominee: PropTypes.object.isRequired,
  fetchEmpDependents: PropTypes.func.isRequired,
  deleteEmpDependents: PropTypes.func.isRequired,
  empDependents: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  empNominee: state.empNominee,
  empDependents: state.empDependents,
});

export default connect(mapStateToProps, {
  fetchEmpNominee,
  deleteEmpNominee,
  fetchEmpDependents,
  deleteEmpDependents,
})(Nomination);
