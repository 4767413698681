import axios from "axios";
import {
  LEAD_SOURCE_ADD,
  LEAD_SOURCE_GET,
  LEAD_SOURCE_UPDATE,
  LEAD_SOURCE_DELETE,
  GET_ERRORS,
} from "./types";
import {
  ADD_LEAD_SOURCE,
  GET_LEAD_SOURCE,
  UPDATE_LEAD_SOURCE,
  DELETE_LEAD_SOURCE,
} from "../../utils/routes";
import { setMessage } from "./salesActions";

export const addLeadSource = (data, back) => (dispatch) => {
  axios
    .post(ADD_LEAD_SOURCE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getLeadSource());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const getLeadSource = () => (dispatch) => {
  axios
    .get(GET_LEAD_SOURCE, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: LEAD_SOURCE_GET,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};

export const updateLeadSource = (data, back) => (dispatch) => {
  axios
    .post(UPDATE_LEAD_SOURCE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getLeadSource());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const deleteLeadSource = (data) => (dispatch) => {
  axios
    .post(DELETE_LEAD_SOURCE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getLeadSource());
      }, 2000);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};
