/*  eslint-disable  */
import axios from "axios";
import { GET_VENDOR_LIST, VENDOR_SEARCH, GET_ERRORS } from "./types";
import {
  ADD_VENDOR,
  GET_VENDOR,
  UPDATE_VENDOR,
  SEARCH_VENDORS,
} from "../../utils/routes";
import { setMessage } from "./salesActions";

export const addVendor = (data, history) => (dispatch) => {
  axios
    .post(ADD_VENDOR, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
      }, 2000);
    })
    .catch((err) => {
      console.log(err.message);
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};

export const getVendors = () => (dispatch) => {
  axios
    .get(GET_VENDOR, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: GET_VENDOR_LIST,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};

export const updateVendor = (data, history) => (dispatch) => {
  axios
    .post(UPDATE_VENDOR, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
      }, 2000);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};

export const searchVendor = (data) => (dispatch) => {
  axios
    .post(SEARCH_VENDORS, data, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: VENDOR_SEARCH,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};

// export const addLeadCompany = (data, history) => (dispatch) => {
//   axios
//     .post(ADD_CUSTOMER, data, { withCredentials: true })
//     .then((res) => {
//       dispatch(setMessage(res.data));
//       setTimeout(() => {
//         dispatch(setMessage(null));
//       }, 2000);
//     })
//     .catch((err) => {
//       dispatch({
//         type: GET_ERRORS,
//         payload: err.message,
//       });
//     });
// };
