import axios from "axios";
import {
  PARTNER_PYMT_TERMS_ADD,
  PARTNER_PYMT_TERMS_GET,
  PARTNER_PYMT_TERMS_UPDATE,
  PARTNER_PYMT_TERMS_DELETE,
  GET_ERRORS,
} from "./types";
import {
  ADD_PARTNER_PYMT_TERMS,
  GET_PARTNER_PYMT_TERMS,
  UPDATE_PARTNER_PYMT_TERMS,
  DELETE_PARTNER_PYMT_TERMS,
} from "../../utils/routes";
import { setMessage } from "./salesActions";

export const addPartnerPymtTerms = (data, back) => (dispatch) => {
  axios
    .post(ADD_PARTNER_PYMT_TERMS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getPartnerPymtTerms());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const getPartnerPymtTerms = () => (dispatch) => {
  axios
    .get(GET_PARTNER_PYMT_TERMS, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: PARTNER_PYMT_TERMS_GET,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};

export const updatePartnerPymtTerms = (data, back) => (dispatch) => {
  axios
    .post(UPDATE_PARTNER_PYMT_TERMS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getPartnerPymtTerms());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const deletePartnerPymtTerms = (data) => (dispatch) => {
  axios
    .post(DELETE_PARTNER_PYMT_TERMS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getPartnerPymtTerms());
      }, 2000);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};
