import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Container,
  FormText,
} from "reactstrap";
import {
  Validator,
  submitValidator,
  valid,
  errors,
  refresh,
} from "../../../common/Validator";
import Select from "react-select";
import { CREATE_CANDIDATE, GET_CAREERS } from "../../../../utils/routes";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";

class AddNewCandidate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      resume: "",
      options: [],
      jobid: "",
      modal: false,
      selectedOption: null,
      message: "",
      error: "",
    };
    this.toggle = this.toggle.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onFileChange(e) {
    if (e.target.files[0]) {
      this.setState({
        resume: e.target.files[0],
      });
    }
  }

  /*
  This function for set the selected value from dropDown
  */
  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  /*
  Function for upload Resume
  */
  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    if (this.state.selectedOption && valid) {
      let file = this.state.resume;
      let fd = new FormData();

      fd.append("jobId", this.state.selectedOption.value);
      fd.append("candName", this.state.name);
      fd.append("email", this.state.email);
      fd.append("phone", this.state.phone);
      fd.append("resume", file);
      axios
        .post(CREATE_CANDIDATE, fd, { withCredentials: true })
        .then((res) => {
          this.setState({
            message: res.data,
          });
        })
        .catch((err) => {
          this.setState({
            error: "could not add",
          });
        });
      setTimeout(() => {
        this.setState({
          message: "",
          error: "",
        });
      }, 2000);
    } else {
      this.setState({
        error: "please fill out all the fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  /*
  Fetching the job details for jobid dropdown
  */
  componentDidMount() {
    const data = { domain: ["Technology", "Consulting", "Sales", "Support"] };
    axios
      .post(GET_CAREERS, data, { withCredentials: true })
      .then((res) => {
        this.setState({
          options: res.data,
        });
      })
      .catch((err) => {});
    refresh();
  }

  // render for add candidate page
  render() {
    const { options, selectedOption } = this.state;
    const { name, email } = this.state.formErrors;

    /*
    Getting the jobCode value into the options1 array
    */
    let options1 = [];
    for (let i = 0; i < options.length; i++) {
      var x = {};

      x["label"] = options[i].jobCode;
      x["value"] = options[i].jobCode;

      options1.push(x);
    }
    let shrink;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");

    return (
      <div className={shrink}>
        <h5 className="center bold">Add New Candidate</h5>
        <Container className="container-card" style={{ position: "relative" }}>
          <Link to="/pie/candidatemanagement">
            <i
              className="material-icons pointer"
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                color: "#C00",
              }}
            >
              cancel
            </i>
          </Link>
          <Form onSubmit={this.onSubmit} className="p-3" noValidate>
            <Row>
              <Col md="4">
                <FormGroup>
                  <Label className="bold label-sm">Name</Label>
                  <Input
                    type="text"
                    className={`form-control-sm ${name && "is-invalid"}`}
                    name="name"
                    onChange={this.onChange}
                    value={this.state.name}
                    onBlur={this.onBlur}
                    required={true}
                  />
                  {errors.name && (
                    <FormText className="error">{errors.name}</FormText>
                  )}
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label className="bold label-sm">Email</Label>
                  <Input
                    type="email"
                    name="email"
                    onChange={this.onChange}
                    value={this.state.email}
                    onBlur={this.onBlur}
                    className={`form-control-sm ${email && "is-invalid"}`}
                    required={true}
                  />
                  {errors.email && (
                    <FormText className="error">{errors.email}</FormText>
                  )}
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label className="bold label-sm">Job Id</Label>
                  <Select
                    className="form-control-sm"
                    name="jobid"
                    value={selectedOption}
                    onChange={this.handleChange}
                    options={options1}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label className="bold label-sm">Phone</Label>
                  <Input
                    type="number"
                    name="phone"
                    value={this.state.phone}
                    className="form-control-sm"
                    onChange={this.onChange}
                    required={true}
                  />
                  {errors.phone && (
                    <FormText className="error">{errors.phone}</FormText>
                  )}
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label className="bold label-sm">Resume</Label>
                  <Input
                    disabled
                    type="text"
                    value={this.state.resume.name || ""}
                    className="form-control-sm"
                    required={true}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <label
                    className="btn file-button btn-file label-sms"
                    id="browseButton"
                  >
                    Browse
                    <Input
                      type="file"
                      name="resume"
                      style={{
                        display: "none",
                        marginTop: "23px",
                        marginLeft: "-28px",
                      }}
                      className="form-control-sm"
                      onChange={this.onFileChange}
                      required={true}
                      accept=".pdf, .png"
                    />
                  </label>
                </FormGroup>
              </Col>
            </Row>
            {this.state.message ? (
              <Notifier message={this.state.message} />
            ) : null}
            <div className="center">
              <button type="submit" className="btn login-button white-text">
                Apply
              </button>
            </div>
          </Form>
        </Container>
        {this.state.message ? <Notifier message={this.state.message} /> : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
      </div>
    );
  }
}

AddNewCandidate.propTypes = {
  job: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  job: state.job,
});
export default connect(mapStateToProps, null)(AddNewCandidate);
