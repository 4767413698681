import axios from "axios";
import { ROLE_GET, ROLE_SEARCH } from "../../../types";
import {
  ADD_ROLE,
  GET_ROLE,
  UPDATE_ROLE,
  DELETE_ROLE,
  SEARCH_ROLE,
} from "../../../../../utils/routes";
import setSuccessMessage from "../../../common/setSuccessMessage";
import setErrorMessage from "../../../common/setErrorMessage";
import getDispatch from "../../../common/getDispatch";

export const addRole = (data, back) => (dispatch) => {
  axios
    .post(ADD_ROLE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getRole = () => (dispatch) => {
  axios
    .get(GET_ROLE, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(ROLE_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const searchRole = (data) => (dispatch) => {
  axios
    .post(SEARCH_ROLE, data, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(ROLE_SEARCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateRole = (data, back) => (dispatch) => {
  axios
    .put(UPDATE_ROLE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteRole = (data, back) => (dispatch) => {
  axios
    .delete(DELETE_ROLE, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
