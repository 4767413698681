import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";
import { 
ADD_RS_PUBLISH_JOBS,
GET_RS_PUBLISH_JOBS,
UPDATE_RS_PUBLISH_JOBS,
DELETE_RS_PUBLISH_JOBS,
RS_PUBLISH_JOBS_SEARCH_API,
GET_JOB_APPROVAL_API 
} from "../../../../utils/routes";
import {  GET_PUBLISH_JOB_TYPERS,SEARCH_PUBLISH_JOBS,EMPTY_RS_PUBLISH,GET_JOB_APPROVAL  } from "../../types";
import axios from "axios";

export const AddPublishJobs = (data, back) => (dispatch) => {
  axios
    .post(ADD_RS_PUBLISH_JOBS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getPublishJobs));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getPublishJobs = () => (dispatch) => {
  axios
    .get(`${GET_RS_PUBLISH_JOBS}/approve`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(GET_PUBLISH_JOB_TYPERS, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getJobApproval = () => (dispatch) => {
  axios
    .get(GET_JOB_APPROVAL_API , { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(GET_JOB_APPROVAL, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const searchPublishJobs = (data) => (dispatch) => {
  axios
    .post(RS_PUBLISH_JOBS_SEARCH_API,data, { withCredentials: true })
    .then((res) => {
      if(!res.data.length){
        dispatch({
          type:EMPTY_RS_PUBLISH ,
          payload:"Data not found"
        })
      setTimeout(()=>{
          dispatch({
            type:EMPTY_RS_PUBLISH ,
            payload:""
          })
        },3000)
        }
        else{
          dispatch(getDispatch(SEARCH_PUBLISH_JOBS,res))
        }
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updatePublishJobs = (data, back) => (dispatch) => {
  axios
    .put(UPDATE_RS_PUBLISH_JOBS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deletePublishJobs = (data) => (dispatch) => {
    axios
      .delete( DELETE_RS_PUBLISH_JOBS, { data, withCredentials: true })
      .then((res) => {
        dispatch(setSuccessMessage(res, null, getPublishJobs));
      })
      .catch((err) => {
        dispatch(setErrorMessage(err));
      });
  };
  
