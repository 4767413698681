import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form, FormGroup, Label, Input, FormText, Row, Col } from "reactstrap";
// components
import FormComponent from "../../../../../common/FormComponent";
import {
  Validator,
  submitValidator,
  refresh,
  valid,
  errors,
} from "../../../../../common/Validator";
import Notifier from "../../../../../aside/Notifier";
import ErrorNotifier from "../../../../../aside/ErrorNotifier";
// redux
import { getUserEmpRelation } from "../../../../../../redux/actions/hcm/configurations/empRelationAction";
import { updateContactsEmergencyDetails } from "../../../../../../redux/actions/hcm/my_profile/contacts_details/contactsEmergencyDetailsAction";

class UpdateEmergencyContactDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactName: this.props.data.length
        ? this.props.data[0].emerContactName
        : "",
      emerDialingCode: this.props.data.length
        ? this.props.data[0].emerDialingCode
        : "",
      contactNo: this.props.data.length ? this.props.data[0].emerContactNo : "",
      relation: this.props.data.length ? this.props.data[0].relation : "",
      message: "",
      error: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      emerContactName: this.state.contactName,
      emerDialingCode: this.state.emerDialingCode,
      emerContactNo: this.state.contactNo,
      relation: this.state.relation,
    };
    if (valid) {
      this.props.updateContactsEmergencyDetails(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    refresh();
    this.setState({
      message: "",
      error: "",
    });
    this.props.getUserEmpRelation();
  }

  render() {
    const {
      contactName,
      emerDialingCode,
      contactNo,
      relation,
      message,
      error,
    } = this.state;

    return (
      <React.Fragment>
        <i
          className="material-icons cancel-button pointer"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <FormComponent
            labelClass="bold label-sm"
            label="Contact Name"
            type="text"
            name="contactName"
            inputClass="form-control-sm"
            value={contactName}
            change={this.onChange}
            required={true}
          />
          <Row>
            <Col md="2" className="p-0">
              <FormComponent
                labelClass="bold label-sm"
                label=" "
                type="number"
                name="emerDialingCode"
                inputClass="form-control-sm"
                value={emerDialingCode}
                change={this.onChange}
                required={true}
                maxLength={4}
              />
            </Col>
            <Col md="10" className="p-0">
              <FormComponent
                labelClass="bold label-sm"
                label="Contact Number"
                type="tel"
                name="contactNo"
                inputClass="form-control-sm"
                value={contactNo}
                change={this.onChange}
                required={true}
              />
            </Col>
          </Row>
          <FormGroup>
            <Label className="label-sm bold">Relation</Label>
            <Input
              type="select"
              name="relation"
              className="form-control-sm"
              value={relation}
              onChange={this.onChange}
              required={true}
            >
              <option hidden>Select...</option>
              {this.props.empRelation &&
                this.props.empRelation.data.map((item) => {
                  return (
                    <option
                      key={item.contactRelationId}
                      value={item.contactRelationName}
                    >
                      {item.contactRelationName}
                    </option>
                  );
                })}
            </Input>
            {errors.relation && (
              <FormText className="error">{errors.relation}</FormText>
            )}
          </FormGroup>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Update
            </button>
          </div>
        </Form>
        {this.props.contactsEmergencyDetails.message && (
          <Notifier message={this.props.contactsEmergencyDetails.message} />
        )}
        {this.props.contactsEmergencyDetails.error && (
          <ErrorNotifier message={this.props.contactsEmergencyDetails.error} />
        )}
      </React.Fragment>
    );
  }
}

UpdateEmergencyContactDetails.propTypes = {
  updateContactsEmergencyDetails: PropTypes.func.isRequired,
  contactsEmergencyDetails: PropTypes.object.isRequired,
  getUserEmpRelation: PropTypes.func.isRequired,
  empRelation: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  empRelation: state.empRelation,
  contactsEmergencyDetails: state.contactsEmergencyDetails,
});

export default connect(mapStateToProps, {
  getUserEmpRelation,
  updateContactsEmergencyDetails,
})(UpdateEmergencyContactDetails);
