import React, { Component } from "react";
import {
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormText,
  Container,
} from "reactstrap";
import { connect } from "react-redux";
import Select from "react-select";
import PropTypes from "prop-types";
import axios from "axios";
import { MdCancel } from "react-icons/md";

import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../common/Validator";
import FormComponent from "../../../common/FormComponent";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import { TAG_SEARCH_CANDI, CURRENT_MAIL_ID } from "../../../../utils/routes";
import CountryCode from "./selectCountryCode";
import SelectCountryCode from "./selectAllCountryCode";

import { addCandidateRsCreation,addCandidateRsCreationTwo } from "../../../../redux/actions/rs/requirements/reaCandidateCreation";
import { getCandidateStatusRs } from "../../../../redux/actions/rs/configuration/candidateStausAction";
import { getSkillGroup } from "../../../../redux/actions/rs/configuration/skillgroupaction";
import { getSelectGovtId } from "../../../../redux/actions/rs/configuration/govtIdAction";
import { getQualificationRs } from "../../../../redux/actions/rs/configuration/qualificationaction";
import { getAllSkillsSelect } from "../../../../redux/actions/rs/configuration/canSkillsAction";
import { getCanCountry } from "../../../../redux/actions/rs/configuration/candidateCountries";
import { getNoticePeriodSelect } from "../../../../redux/actions/rs/configuration/NoticePeriodAction";
import { CONTACT_DESIGNATION_DELETE } from "../../../../redux/actions/types";

let expErr = false;
let relExpErr = false;
let primarySkillGroupError = "";
let secondarySkillError = "";
// candidate creation component
class AddCandidate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      candiName: "",
      lastName: "",
      emailId: "",
      location: "",
      country: "",
      mobile: "",
      gender: "",
      skillGroup: "",
      skillSets: "",
      skillBodyName: "",
      noticePeriod: "",
      expectedCtc: "",
      currentCtc: "",
      experience: "",
      prefLocation: "",
      isExperience: "Experienced",
      alternativeNo: "",
      dialingContactNo: "",
      alterDialingContactNo: "",
      qualification: "",
      years: "",
      currentEmployer: "",
      govtIdName: null,
      govtIdNo: "",
      releventYears: "",
      primarySkills: "",
      secondarySkills: "",
      dob: "",
      notes: "",
      remarks: "",
      dynamicCurrency: "",
      noteError: "",
      nextPage: false,
      file: [],
      fileError: "",
      errExpData: "",
      relErrExpData: "",
      error: "",
      isEmailValid: true,
      getMailId: "",
      selectedOptionError: "",
      skillsData: [],
      filteredValueArray: [],
      filteredSecondaryArray: [],
      candiStatus: "",
      hiringStage:"",
      hiringStatus:"",
      expArr: [
        { key: "Experienced", value: "Experienced" },
        { key: "Fresher", value: "Fresher" },
      ],
      genderarr: [
        { key: "Male", value: "Male" },
        { key: "Female", value: "Female" },
        { key: "Others", value: "Others" },
      ],

      dialingCode: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.verifyEmailCheck = this.verifyEmailCheck.bind(this);
    this.handleSkillsChange = this.handleSkillsChange.bind(this);
    this.closeDataSkill = this.closeDataSkill.bind(this);
    this.handleSecondarySkillsChange =
      this.handleSecondarySkillsChange.bind(this);
    this.closeSecondaryDataSkill = this.closeSecondaryDataSkill.bind(this);
  }

  verifyEmailCheck(data) {
    let body = {
      search: data,
    };
    axios
      .post(`${TAG_SEARCH_CANDI}`, body, { withCredentials: true })
      .then((res) => {
        console.log(res.data);
        if (res.data.length > 0) {
          this.setState({
            isEmailValid: false,
          });
        } else {
          this.setState({
            isEmailValid: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleSkillsChange(skillSets) {
    let { filteredValueArray } = this.state;
    filteredValueArray.push(skillSets.value);
    this.setState({
      filteredValueArray: [...new Set(filteredValueArray)],
    });
    primarySkillGroupError = "";
  }

  handleSecondarySkillsChange(secondarySkills) {
    let { filteredSecondaryArray } = this.state;
    filteredSecondaryArray.push(secondarySkills.value);
    this.setState({
      filteredSecondaryArray: [...new Set(filteredSecondaryArray)],
    });
    secondarySkillError = "";
  }

  closeDataSkill(data) {
    let filterData = this.state.filteredValueArray.filter(
      (val) => val !== data
    );
    this.setState({
      filteredValueArray: filterData,
    });
  }

  closeSecondaryDataSkill(data) {
    let filterData = this.state.filteredSecondaryArray.filter(
      (val) => val !== data
    );
    this.setState({
      filteredSecondaryArray: filterData,
    });
  }

  // onchange function
  onChange(e) {
    e.preventDefault();
    Validator(e.target);

    if (e.target.name === "emailId") {
      this.getCurrentEmail(e.target.value);
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
    if (e.target.name === "experience") {
      if (e.target.value) {
        let value = e.target.value;
        let patt = new RegExp(/^\d{1,2}(\.\d{1,2})?$/);
        if (patt.test(value)) {
          this.setState({
            [e.target.name]: e.target.value,
            errExpData: "",
          });
          expErr = false;
        } else {
          this.setState({
            errExpData: "Please enter years in 00.0 format or Number",
          });
          errors.experience = "";
          expErr = true;
        }
      } else {
        this.setState({
          errExpData: "This field is mandatory",
        });
        errors.experience = "";
        expErr = true;
      }
    }
    if (e.target.name === "releventYears") {
      let patt = new RegExp(/^\d{1,2}(\.\d{1,2})?$/);
      if (e.target.value) {
        let value = e.target.value;
        // let patt = new RegExp(/^\d{1,2}(\.\d{1,1})?$/)
        if (patt.test(value)) {
          this.setState({
            [e.target.name]: e.target.value,
            relErrExpData: "",
          });
          relExpErr = false;
        } else {
          this.setState({
            relErrExpData: "Please enter years in 00.0 format or Number",
          });
          errors.releventYears = "";
          relExpErr = true;
        }
      } else {
        this.setState({
          relErrExpData: "",
        });
        errors.releventYears = "";
        relExpErr = true;
      }
    }
    if (e.target.name === "country") {
      let countryValue = e.target.value
      this.setState({
        country: countryValue,
        dialingCode: e.target.value.split(",")[1],
        dynamicCurrency: e.target.value.split(",")[2],
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
    if(e.target.name === "candiStatus"){
      this.setState({
        hiringStage:"Profile Screening",
        hiringStatus:"Awaiting Feedback",
        [e.target.name]:e.target.value
      })
      if(e.target.value !="Tagged" && e.target.value !="Hiring Stage" ){
        this.setState({
          currentEmployer:"",
          prefLocation: "",
          releventYears: "",
          govtIdName: null,
          govtIdNo: "",
          expectedCtc: "",
          currentCtc: "",
         
          filteredSecondaryArray: [],
          noticePeriod: "",
          experience:""
        })
        errors.location = ""
        errors.govtIdName = ""
        errors.noticePeriod = ""
        errors.prefLocation = ""
        errors.currentCtc = ""
        errors.currentEmployer = ""
        errors.expectedCtc = ""
       errors.experience = ""
      }
     this.setState({
      [e.target.name]:e.target.value
     })

    }
    else {
      this.setState({
        [e.target.name]: e.target.value,

      });
    }
  }
 

  // onchange function  for handle file

  handleFile = (e) => {
    Validator(e.target);
    let allowedExtension = /(\.pdf)$/i;
    if (allowedExtension.exec(e.target.files[0].name)) {
      if ((e.target.files[0].size / 1024 / 1024).toFixed(2) < 2) {
        this.setState({
          file: e.target.files[0] || [],
          fileError: "",
        });
      } else {
        this.setState({
          file: e.target.files[0],
          fileError: "File size must less than 2MB",
        });
      }
    } else {
      this.setState({
        file: e.target.files[0],
        fileError: "Please attach PDF resume",
      });
    }
  };

  // get current email id
  getCurrentEmail(data) {
    let body = {
      email: data,
      ipAddress: "123.0.0.1",
      browser: "chrome",
      location: "sweden",
      OS: "windows",
      isPhone: false,
      isBot: false,
      isTablet: false,
      isSuspicious: false,
    };
    axios
      .post(`${CURRENT_MAIL_ID}`, body, { withCredentials: true })
      .then((res) => {
        if (!res.data.ok) {
          this.setState({
            noteError: res.data.error,
          });
        } else {
          this.setState({
            noteError: "",
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  //  submit function for creating candidate

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);

      if (this.state.filteredValueArray.length > 0) {
        primarySkillGroupError = "";
      } else {
        primarySkillGroupError = "Primary Skills is mandatory  ";
      
  
      if (!this.state.errExpData && !this.state.experience) {
        errors.experience = "This field is mandatory";
      } else {
        errors.experience = "";
      }
  
      if (this.state.relErrExpData) {
        errors.releventYears = "";
      }
  }
  const { fileError } = this.state;
   if(this.state.candiStatus ==="Tagged" || this.state.candiStatus === "Hiring Stage"){
    let isExpNumber = Number(this.state.experience);
    let isNoticeNumber = this.state.noticePeriod;
    let isCurrentNumber = Number(this.state.currentCtc);
    let isExpectedNumber = Number(this.state.expectedCtc);
    let fd = new FormData();
    fd.append("firstName", this.state.candiName);
    fd.append("lastName", this.state.lastName);
    fd.append("mobileNo", this.state.mobile);
    fd.append("contactDialingCode", this.state.dialingCode);
    fd.append("altMobileNo", this.state.alternativeNo);
    fd.append("altDialingCode", this.state.alterDialingContactNo);
    fd.append("emailId", this.state.emailId);
    fd.append("gender", this.state.gender);
    this.state.dob && fd.append("dob", this.state.dob);
    fd.append("qualification", this.state.qualification);
    fd.append("yearOfPassing", this.state.years);
    fd.append("currentEmployeer", this.state.currentEmployer);
    fd.append("jobRequirementId",this.props.reqId)
    fd.append("candidateStatus",this.state.candiStatus)
    fd.append("hiringStage",this.state.candiStatus ==="Tagged" ? null : this.state.hiringStage)
    fd.append("hiringStatus",this.state.candiStatus ==="Tagged" ? null : this.state.hiringStatus)
    fd.append("candidateSource","Tagging")
    this.state.govtIdName && this.state.govtIdNo
      ? fd.append("govIdName", this.state.govtIdName)
      : null;
    this.state.govtIdNo && this.state.govtIdName
      ? fd.append("govIdNo", this.state.govtIdNo)
      : null;
    fd.append("country", this.state.country);
    this.state.location && fd.append("location", this.state.location);
    this.state.filteredValueArray.length>0 && 
    fd.append("primarySkills", this.state.filteredValueArray.toString());
    this.state.filteredSecondaryArray && 
    fd.append("secondarySkills", this.state.filteredSecondaryArray.toString());
    this.state.prefLocation && fd.append("preferredLocation", this.state.prefLocation);
    this.state.isExperience && fd.append("isExperienced", this.state.isExperience);
    isExpNumber &&  fd.append("yearsOfExp", isExpNumber);
    this.state.releventYears && fd.append("relavantYearsOfExp", this.state.releventYears);
    isNoticeNumber && fd.append("noticePeriod", isNoticeNumber);
    isCurrentNumber && fd.append("currentCtc", isCurrentNumber);
    isExpectedNumber && fd.append("expectedCtc", isExpectedNumber);
    this.state.notes && fd.append("notes", this.state.notes);
    fd.append("file", this.state.file);
    console.log(valid, errors);
    console.log(fileError)
    console.log(relExpErr)
    console.log(primarySkillGroupError)
    if (
      valid &&
      !fileError &&
      !expErr &&
      !relExpErr &&
      !primarySkillGroupError
    ) {
      if (!this.state.noteError) {
        this.props.addCandidateRsCreation(fd, this.props.toggle);
      } else {
        this.setState({
          error: "Email Already Exists",
        });
        setTimeout(() => {
          this.setState({
            error: "",
          });
        }, 2000);
      }
    } else {
      this.setState({
        error: "Please enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  else if(this.state.candiStatus !="Tagged" || this.state.candiStatus != "Hiring Stage"){
       console.log("other than tagged")
      let fd = new FormData(); 
      fd.append("firstName", this.state.candiName);
      fd.append("lastName", this.state.lastName);
      fd.append("mobileNo", this.state.mobile);
      fd.append("country", this.state.country);
      fd.append("contactDialingCode", this.state.dialingCode);
      fd.append("altMobileNo", this.state.alternativeNo);
      fd.append("altDialingCode", this.state.alterDialingContactNo);
      fd.append("emailId", this.state.emailId);
      fd.append("gender", this.state.gender);
      this.state.location && fd.append("location", this.state.location);
      this.state.dob && fd.append("dob", this.state.dob);
      fd.append("jobRequirementId",this.props.reqId)
      fd.append("qualification", this.state.qualification);
      fd.append("yearOfPassing", this.state.years);
      fd.append("candidateSource","Tagging")
      fd.append("candidateStatus",this.state.candiStatus)
      this.state.filteredValueArray.length>0 && 
      fd.append("primarySkills", this.state.filteredValueArray.toString());
      this.state.notes && fd.append("notes", this.state.notes);
      fd.append("file", this.state.file);
      if (
        valid &&!fileError 
      ) {
        if (!this.state.noteError) {
          this.props.addCandidateRsCreationTwo(fd, this.props.toggle);
        } else {
          this.setState({
            error: "Email Already Exists",
          });
          setTimeout(() => {
            this.setState({
              error: "",
            });
          }, 2000);
        }
      } else {
        this.setState({
          error: "Please enter all mandatory fields",
        });
        setTimeout(() => {
          this.setState({
            error: "",
          });
        }, 2000);
      }
    }
  
  }

  /*
componentDidMount contains the error refresh method and here set the state for calling render method
*/
  componentDidMount() {
    // this.getCurrentEmail();
    this.props.getAllSkillsSelect();
    this.props.getSelectGovtId();
    this.props.getSkillGroup();
    this.props.getCanCountry();
    this.props.getNoticePeriodSelect();
    this.props.getQualificationRs();
    this.props.getCandidateStatusRs();
    refresh();
    secondarySkillError = "";
    primarySkillGroupError = "";
    this.setState({
      message: "",
      error: "",
      dialingCode: this.props.auth.data.pieUser.dialingCode,
      alterDialingContactNo: this.props.auth.data.pieUser.dialingCode,
    });
  }

  render() {
    console.log(this.props.auth.data.pieUser.dialingCode);

    let options3 = [];

    for (let i = 0; i < this.props.CanConSkill.allData.length; i++) {
      var x1 = {};
      x1["value"] = this.props.CanConSkill.allData[i].skill;
      x1["label"] = this.props.CanConSkill.allData[i].skill;
      options3.push(x1);
    }

    const {
      candiName,
      emailId,
      country,
      skillGroup,
      location,
      expectedCtc,
      currentCtc,
      remarks,
      skillSets,
      experience,
      mobile,
      isExperience,
      gender,
      noticePeriod,
      notes,
      prefLocation,
      lastName,

      message,
      error,
    } = this.state;

    return (
      <React.Fragment>
          <div style={{display:"flex",flexDirection:"column",width:"100%"}}>
          <h5 className="center bold pt-2">Add Candidate for Req Id : {this.props.reqId} </h5>
          <i
            className="material-icons pointer cancel-button"
            onClick={this.props.toggle}
          >
            cancel
          </i>
          <Form onSubmit={this.onSubmit}  noValidate>
            <Row>
              <Col md="4">
                <FormComponent
                  tabIndex="1"
                  labelClass="bold label-sm"
                  label="First Name"
                  type="text"
                  name="candiName"
                  inputClass="form-control-sm"
                  value={candiName}
                  change={this.onChange}
                  required={true}
                  placeholder="First Name"
                />
                <FormGroup>
                  <Label className="bold label-sm">Country</Label>
                  <Input
                    tabIndex="4"
                    type="select"
                    name="country"
                    className="form-control-sm"
                    value={country}
                    onChange={this.onChange}
                    placeholder="Country"
                    required={true}
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.CandiCountry.data.map((item) => {
                      return (
                        <option
                          key={item.id}
                          value={`${item.countryName},${item.dialingCode},${item.currency}`}
                        >
                          {item.countryName}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.country && (
                    <FormText className="error">{errors.country}</FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">Gender</Label>
                  <Input
                    tabIndex="9"
                    type="select"
                    name="gender"
                    className="form-control-sm"
                    value={gender}
                    onChange={this.onChange}
                    placeholder="Skill Group"
                    required={true}
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.state.genderarr.map((item) => {
                      return (
                        <option key={item.key} value={item.value}>
                          {item.value}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.gender && (
                    <FormText className="error">{errors.gender}</FormText>
                  )}
                </FormGroup>
                <FormGroup>
                <Label className="bold label-sm">Candidate Status</Label>
                    <Input
                      tabIndex="13"
                      type="select"
                      name="candiStatus"
                      className="form-control-sm"
                      value={this.state.candiStatus}
                      onChange={this.onChange}
                      required={true}
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.CandidateStatus.mainData.map((item) => {
                        return (
                          <option key={item.id} value={item.candidateStatus}>
                            {item.candidateStatus}
                          </option>
                        );
                      })}
                    </Input>
                    {errors.candiStatus && (
                    <FormText className="error">{errors.candiStatus}</FormText>
                  )}
                  </FormGroup>
                 
            
               { (this.state.candiStatus === "Tagged" || this.state.candiStatus === "Hiring Stage") && <div style={{ width: "100%", display: "flex" }}>
                  <div style={{ width: "100%" }}>
                    <FormGroup>
                      <Label className="bold label-sm">Govt Id Name</Label>
                      <Input
                        style={{ width: "85%" }}
                        tabIndex="16"
                        type="select"
                        name="govtIdName"
                        className="form-control-sm"
                        value={this.state.govtIdName}
                        onChange={this.onChange}
                      >
                        <option value="" hidden>
                          Select...
                        </option>

                        {this.props.GovtId.getAll.map((item) => {
                          return (
                            <option key={item.id} value={item.govtIdfName}>
                              {item.govtIdfName}{" "}
                            </option>
                          );
                        })}
                      </Input>

                      {errors.govtIdName && (
                        <FormText className="error">
                          {errors.govtIdName}
                        </FormText>
                      )}
                    </FormGroup>
                  </div>

                <FormComponent
                    tabIndex="17"
                    labelClass="bold label-sm"
                    label="Id No."
                    type="number"
                    name="govtIdNo"
                    change={this.onChange}
                    value={this.state.govtIdNo}
                    placeholder="Id No."
                    // placeholder="Total Position"
                    maxlength="10"
                  />
                </div>}
               { (this.state.candiStatus === "Tagged" || this.state.candiStatus === "Hiring Stage") && <FormGroup>
                  <Label className="bold label-sm">
                    Notice Period
                  </Label>
                  <Input
                    tabIndex="20"
                    type="select"
                    name="noticePeriod"
                    className="form-control-sm"
                    value={noticePeriod}
                    onChange={this.onChange}
                    placeholder="Skill Group"
                    required={true}
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.Noticeperiod.getData.map((item) => {
                      return (
                        <option key={item.id} value={item.noticePeriod}>
                          {item.noticePeriod}{" "}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.noticePeriod && (
                    <FormText className="error">{errors.noticePeriod}</FormText>
                  )}
                </FormGroup>}
                {(this.state.candiStatus === "Tagged" || this.state.candiStatus === "Hiring Stage") && <FormComponent
                  tabIndex="23"
                  labelClass="bold label-sm"
                  label="Years of Exp."
                  type="number"
                  name="experience"
                  inputClass="form-control-sm"
                  value={experience}
                  change={this.onChange}
                  required={true}
                  placeholder="00.0 Format"
                  parentError={this.state.errExpData}
                />}
              </Col>

              <Col md="4">
                <FormComponent
                  label="Last Name"
                  tabIndex="2"
                  labelClass="bold label-sm"
                  type="text"
                  name="lastName"
                  inputClass="form-control-sm"
                  value={lastName}
                  change={this.onChange}
                  required={true}
                  placeholder="Last Name"
                />
                <span className="bold label-sm">Mobile Number</span>
                <div style={{ display: "flex" }}>
                  <CountryCode
                    widthValue="4rem"
                    value={this.state.dialingCode}
                    index="5"
                  />
                  <div style={{ width: "100%" }}>
                    <FormComponent
                      tabIndex="6"
                      style={{ width: "100%" }}
                      labelClass="bold label-sm"
                      type="tel"
                      name="mobile"
                      inputClass="form-control-sm"
                      value={mobile}
                      pattern="[789][0-9]{9}"
                      change={this.onChange}
                      required={true}
                      placeholder="Mobile Number"
                    />
                  </div>
                </div>
                <FormComponent
                  label="DOB"
                  tabIndex="10"
                  labelClass="bold label-sm"
                  type="date"
                  name="dob"
                  inputClass="form-control-sm"
                  value={this.state.dob}
                  change={this.onChange}
                />
                  <FormComponent
                  tabIndex="15"
                  labelClass="bold label-sm"
                  label="Current Location"
                  type="text"
                  name="location"
                  inputClass="form-control-sm"
                  value={location}
                  change={this.onChange}
                  required={true}
                  placeholder="Current Location"
                />
                 {(this.state.candiStatus === "Tagged" || this.state.candiStatus === "Hiring Stage") &&<div>
                  <span className="bold label-sm">
                    Current CTC
                    {this.state.dynamicCurrency &&
                      `(${this.state.dynamicCurrency})`}
                  </span>
                  <FormComponent
                    tabIndex="18"
                    labelClass="bold label-sm"
                    type="number"
                    name="currentCtc"
                    inputClass="form-control-sm"
                    value={currentCtc}
                    change={this.onChange}
                    required={true}
                    placeholder="Current CTC"
                  />
                </div>}

                 
           {(this.state.candiStatus === "Tagged" || this.state.candiStatus === "Hiring Stage") && <FormComponent
                  tabIndex="21"
                  labelClass="bold label-sm"
                  label="Preferred location"
                  type="text"
                  name="prefLocation"
                  inputClass="form-control-sm"
                  value={prefLocation}
                  change={this.onChange}
                  required={true}
                  placeholder="Preferred Location"
                />}
              
               
                {(this.state.candiStatus === "Tagged" || this.state.candiStatus === "Hiring Stage") && <FormComponent
                  tabIndex="24"
                  labelClass="bold label-sm"
                  label="Relevent Years of Exp."
                  type="number"
                  name="releventYears"
                  inputClass="form-control-sm"
                  value={this.state.releventYears}
                  change={this.onChange}
                  placeholder="00.0 Format"
                  parentError={this.state.relErrExpData}
                />}

               

              </Col>

              <Col md="4">
                <FormComponent
                  blur={this.getCurrentEmail}
                  tabIndex="3"
                  labelClass="bold label-sm"
                  label="EmailId"
                  type="email"
                  name="emailId"
                  inputClass="form-control-sm"
                  value={emailId}
                  change={this.onChange}
                  required={true}
                  placeholder="Email Id"
                />
                <span className="bold label-sm">Alternate Contact Number</span>
                <div style={{ display: "flex" }}>
                  <SelectCountryCode
                    widthValue="4rem"
                    labelClass="bold label-sm"
                    value={this.state.alterDialingContactNo}
                    // name="alterDialingContactNo"
                    onChange={(e) => {
                      // console.log(e.target.value)
                      return this.setState({
                        alterDialingContactNo: e.target.value,
                      });
                    }}
                  />

                  <div style={{ width: "100%" }}>
                    <FormComponent
                      tabIndex="7"
                      style={{ width: "100%" }}
                      labelClass="bold label-sm"
                      type="tel"
                      name="alternativeNo"
                      inputClass="form-control-sm"
                      value={this.state.alternativeNo}
                      pattern="[789][0-9]{9}"
                      change={this.onChange}
                      placeholder="Alternate Contact Number"
                    />
                  </div>
                </div>
                <div style={{ width: "100%", display: "flex" }}>
                  <div style={{ width: "100%" }}>
                    <FormGroup>
                      <Label className="bold label-sm">Qualification</Label>
                      <Input
                        style={{ width: "85%" }}
                        tabIndex="11"
                        type="select"
                        name="qualification"
                        className="form-control-sm"
                        value={this.state.qualification}
                        onChange={this.onChange}
                        required={true}
                      >
                        <option value="" hidden>
                          Select
                        </option>
                        {this.props.QualificationRs.mainData.map((item) => {
                          return (
                            <option key={item.id} value={item.qualifictiontype}>
                              {item.qualifictiontype}{" "}
                            </option>
                          );
                        })}
                      </Input>
                      {errors.qualification && (
                        <FormText className="error">
                          {errors.qualification}
                        </FormText>
                      )}
                    </FormGroup>
                  </div>

                  <FormComponent
                    tabIndex="12"
                    labelClass="bold label-sm"
                    label="Year of Passing"
                    type="number"
                    name="years"
                    change={this.onChange}
                    value={this.state.years}
                    // required={true}
                    placeholder="Year of Passing"
                    // placeholder="Total Position"
                  />
                         </div>
                      <FormGroup>
                  <Label className="bold label-sm">Primary Skills</Label>
                  <Select
                    style={{fontSize:"0.75rem", fontWeight:400}}
                    tabIndex="15"
                    // name="options3"
                    value={this.state.skillSets}
                    onChange={this.handleSkillsChange}
                    options={options3}
                    required
                  />
                </FormGroup>
                <FormText style={{marginTop:"-.6rem"}} className="error">
                  {primarySkillGroupError}
                </FormText>
                {this.state.filteredValueArray.length > 0 && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      border: "1px solid grey",
                    }}
                  >
                    {this.state.filteredValueArray.map((data) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            minWidth: "4rem",
                            background: "#ECECEC",
                            border: "none",
                            minHeight: "2rem",
                            marginTop: "0.1rem",
                            marginBottom: "0.1rem",
                            marginLeft: "0.5rem",
                            borderRadius: "20px",
                            paddingRight: "0.2rem",
                            paddingLeft: "0.2rem",
                          }}
                        >
                          <span style={{fontSize:".75rem"}}>{data}</span>

                          <h3
                            data-toggle="tooltip"
                            data-placement="top"
                            title="cancel"
                            onClick={() => this.closeDataSkill(data)}
                            style={{
                              color: "#04044c",
                            }}
                          >
                            {" "}
                            <MdCancel color="red" size="20" />{" "}
                          </h3>
                        </div>
                      );
                    })}
                  </div>
                   )}
                   
         
                   {(this.state.candiStatus === "Tagged" || this.state.candiStatus === "Hiring Stage") &&<div>
                  <span className="bold label-sm">
                    Expected CTC{" "}
                    {this.state.dynamicCurrency &&
                      `(${this.state.dynamicCurrency})`}
                  </span>
                  <FormComponent
                    tabIndex="19"
                    labelClass="bold label-sm"
                    type="number"
                    name="expectedCtc"
                    inputClass="form-control-sm"
                    value={expectedCtc}
                    change={this.onChange}
                    required={true}
                    placeholder="Expected CTC"
                  />
                </div>}
               
               {(this.state.candiStatus === "Tagged" || this.state.candiStatus === "Hiring Stage") && <FormComponent
                  tabIndex="22"
                  labelClass="bold label-sm"
                  label="Current Employer"
                  type="text"
                  name="currentEmployer"
                  inputClass="form-control-sm"
                  value={this.state.currentEmployer}
                  change={this.onChange}
                  required={true}
                  placeholder="Current Employer"
                />}
                 
              
              
              </Col>
            </Row>
          <Row>
              <Col md="6">
              {(this.state.candiStatus === "Tagged" || this.state.candiStatus === "Hiring Stage") &&<div>
                <FormGroup>
                  <Label className="bold label-sm">Secondary Skills</Label>
                  <Select
                    style={{fontSize:"0.75rem", fontWeight:400}}
                    tabIndex="26"
                    // name="options2"
                    value={this.state.secondarySkills}
                    onChange={this.handleSecondarySkillsChange}
                    options={options3}
                    required
                  />
                </FormGroup>

                {this.state.filteredSecondaryArray.length > 0 && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      border: "1px solid grey",
                    }}
                  >
                    {this.state.filteredSecondaryArray.map((data) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            minWidth: "4rem",
                            background: "#ECECEC",
                            border: "none",
                            minHeight: "2rem",
                            marginTop: "0.1rem",
                            marginBottom: "0.1rem",
                            marginLeft: "0.5rem",
                            borderRadius: "20px",
                            paddingRight: "0.2rem",
                            paddingLeft: "0.2rem",
                          }}
                        >
                          <span style={{fontSize:".75rem"}}>{data}</span>

                          <h3
                            data-toggle="tooltip"
                            data-placement="top"
                            title="cancel"
                            onClick={() => this.closeSecondaryDataSkill(data)}
                            style={{
                              color: "#04044c",
                            }}
                          >
                            {" "}
                            <MdCancel color="red" size="20" />{" "}
                          </h3>
                        </div>
                      );
                    })}
                  </div>
                )}
                </div>}
               
             
                 <div className="file-div ">
                  <FormGroup className="file-display mr-1">
                    <Label className="bold label-sm">Attach Resume</Label>
                    <Input
                      tabIndex="28"
                      disabled
                      type="text"
                      name="personalPhoto"
                      value={this.state.file.name || ""}
                      className="form-control-sm "
                      required={true}
                    />
                    {this.state.fileError && (
                      <FormText className="error ">
                        {this.state.fileError}
                      </FormText>
                    )}
                    {errors.personalPhoto && (
                      <FormText className="error">
                        {errors.personalPhoto}
                      </FormText>
                    )}
                  </FormGroup>
                  <FormGroup className="file-browse-btn-div">
                    <label className="btn label-sm file-browse-btn">
                      Browse
                      <Input
                        tabIndex="17"
                        type="file"

                        name="personalPhoto"
                        style={{
                          display: "none",
                          marginTop: "23px",
                          marginLeft: "-28px",
                        }}
                        className="form-control-sm file-input"
                        onChange={this.handleFile}
                        required={true}
                        accept=".pdf"
                      />
                    </label>
                  </FormGroup>
                </div>
              </Col>

              <Col md="6">
              <FormComponent
                  tabIndex="27"
                  labelClass="bold label-sm"
                  label="Notes"
                  type="textarea"
                  name="notes"
                  inputClass="form-control-sm"
                  value={notes}
                  change={this.onChange}
                  placeholder="Notes"
                />
              
               
              </Col>
            </Row> 

            <div className="center">
              <button
                tabIndex="27"
                type="submit"
                className="btn login-button white-text"
              >
                Submit
              </button>
            </div>
          </Form>
          {message && <Notifier message={message} />}
          {error && <ErrorNotifier message={error} />}
          {this.props.CanUplaodRs.message && (
            <Notifier message={this.props.CanUplaodRs.message} />
          )}
          {this.props.CanUplaodRs.error && (
            <ErrorNotifier message={this.props.CanUplaodRs.error} />
          )}
     </div>
      </React.Fragment>
    );
  }
}

AddCandidate.propTypes = {
  addEmpAwards: PropTypes.func.isRequired,
  empAwards: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  skillGroups: state.skillGroups,
  CandiCountry: state.CandiCountry,
  Noticeperiod: state.Noticeperiod,
  CanUplaodRs: state.CanUplaodRs,
  CanConSkill: state.CanConSkill,
  QualificationRs: state.QualificationRs,
  CandidateStatus: state.CandidateStatus,
  GovtId: state.GovtId,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getAllSkillsSelect,
  getSelectGovtId,
  getQualificationRs,
  getSkillGroup,
  getCanCountry,
  getNoticePeriodSelect,
  addCandidateRsCreation,
  addCandidateRsCreationTwo,
  getCandidateStatusRs
})(AddCandidate);
