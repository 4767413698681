import React, { Component } from "react";
import { Form, Row, Col, FormGroup, Label, Input, FormText } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// components
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../../common/Validator";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
// redux
import { addDocuments } from "../../../../../redux/actions/hcm/my_profile/documentsAction";
import { getUserEmpDocumentName } from "../../../../../redux/actions/hcm/configurations/empDocumentNameAction";

class AddDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documentName: "",
      file: [],
      fileError: "",
      message: "",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleFile = this.handleFile.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    const { fileError } = this.state;
    let fd = new FormData();
    fd.append("empId", localStorage.getItem("id"));
    fd.append("documentName", this.state.documentName);
    fd.append("createdBy", localStorage.getItem("id"));
    fd.append("browseField", this.state.file);
    if (valid && !fileError) {
      this.props.addDocuments(fd, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidMount contains the error refresh method and here set the state for calling render method
*/
  componentDidMount() {
    refresh();
    this.setState({
      message: "",
      error: "",
    });
    this.props.getUserEmpDocumentName();
  }

  handleFile(e) {
    Validator(e.target);
    let allowedExtension = /(\.pdf|\.png)$/i;
    if (allowedExtension.exec(e.target.files[0].name)) {
      if ((e.target.files[0].size / 1024 / 1024).toFixed(2) < 2) {
        this.setState({
          file: e.target.files[0] || [],
          fileError: "",
        });
      } else {
        this.setState({
          file: e.target.files[0],
          fileError: "File size must less than 2MB",
        });
      }
    } else {
      this.setState({
        fileError: "File type not allowed",
        file: e.target.files[0],
      });
    }
  }

  render() {
    const { documentName, file, fileError, error, message } = this.state;
    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Upload Documents </h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="3"></Col>
            <Col md="6">
              <FormGroup>
                <Label className="label-sm bold">Document Name</Label>
                <Input
                  type="select"
                  name="documentName"
                  className="form-control-sm"
                  value={documentName}
                  onChange={this.onChange}
                  required={true}
                >
                  <option hidden value="">
                    Select...
                  </option>
                  {this.props.empDocumentName.data.length > 0 &&
                    this.props.empDocumentName.data.map((item) => {
                      return (
                        <option key={item.id} value={item.documentsName}>
                          {item.documentsName}
                        </option>
                      );
                    })}
                </Input>
                {errors.documentName && (
                  <FormText className="error">{errors.documentName}</FormText>
                )}
              </FormGroup>
              <div className="file-div">
                <FormGroup className="file-display">
                  <Label className="bold label-sm">Document</Label>
                  <Input
                    disabled
                    type="text"
                    value={file.name || ""}
                    className="form-control-sm"
                    required={true}
                  />
                  {fileError && (
                    <FormText className="error ">{fileError}</FormText>
                  )}
                  {errors.document && (
                    <FormText className="error ">{errors.document}</FormText>
                  )}
                </FormGroup>
                <FormGroup className="file-browse-btn-div">
                  <label className="btn label-sm file-browse-btn">
                    Browse
                    <Input
                      type="file"
                      name="document"
                      style={{
                        display: "none",
                        marginTop: "23px",
                        marginLeft: "-28px",
                      }}
                      className="form-control-sm file-input"
                      onChange={this.handleFile}
                      required={true}
                      accept=".pdf, .png"
                    />
                  </label>
                </FormGroup>
              </div>
            </Col>
            <Col md="3"></Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Upload
            </button>
          </div>
        </Form>
        {message && <Notifier message={message} />}
        {error && <ErrorNotifier message={error} />}
      </React.Fragment>
    );
  }
}

AddDocuments.propTypes = {
  addDocuments: PropTypes.func.isRequired,
  documents: PropTypes.object.isRequired,
  getUserEmpDocumentName: PropTypes.func.isRequired,
  empDocumentName: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  empDocumentName: state.empDocumentName,
  documents: state.documents,
});

export default connect(mapStateToProps, {
  addDocuments,
  getUserEmpDocumentName,
})(AddDocuments);
