import axios from "axios";
import {GET_HIRING_STATUS,GET_HIRING_STATUS_RS_TYPE } from "../../types";
import {
  ADD_HIRING_RS,
  GET_HIRING_RS,
  UPDATE_HIRING_RS,
  DELETE_HIRING_RS,
  GET_HIRING_RS_SELECT 
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addHiringRs = (data, back) => (dispatch) => {
  axios
    .post(ADD_HIRING_RS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getHiringRs));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getHiringRs = () => (dispatch) => {
  axios
    .get(  GET_HIRING_RS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(GET_HIRING_STATUS, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getHiringStatSelect = () => (dispatch) => {
  axios
    .get(GET_HIRING_RS_SELECT , { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(GET_HIRING_STATUS_RS_TYPE, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};



export const updateHiringRs = (data, back) => (dispatch) => {
  axios
    .put(  UPDATE_HIRING_RS , data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteHiringRs = (data) => (dispatch) => {
  axios
    .delete( DELETE_HIRING_RS, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getHiringRs));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
