import axios from "axios";
import {
  CUSTOMERS_GET,
  CUSTOMERS_FETCH,
  CUSTOMERS_SEARCH,
  CUSTOMERS_NAME_GET,
  CUSTOMERS_SPOC_NAME_GET,
  UPDATE_CUSTOMERS_SPOC_NAME_GET,
  CUSTOMER_TAGGED_CONTACTS_GET,
} from "../../types";
import {
  CURD_CUSTOMERS,
  SEARCH_CUSTOMERS,
  GET_CUSTOMERS_NAME,
  GET_CUSTOMERS_SPOC_NAME,
  GET_UPDATE_CUSTOMERS_SPOC_NAME,
  GET_CUSTOMER_TAGGED_CONTACTS,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addCustomers = (data, history) => (dispatch) => {
  let link = "/pie/crm/customers";
  axios
    .post(CURD_CUSTOMERS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getCustomers, history, link));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const searchCustomers = (data) => (dispatch) => {
  axios
    .post(SEARCH_CUSTOMERS, data, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CUSTOMERS_SEARCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getCustomers = () => (dispatch) => {
  axios
    .get(CURD_CUSTOMERS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CUSTOMERS_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getCustomersName = () => (dispatch) => {
  axios
    .get(GET_CUSTOMERS_NAME, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CUSTOMERS_NAME_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getCustomersSpocName = () => (dispatch) => {
  axios
    .get(GET_CUSTOMERS_SPOC_NAME, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CUSTOMERS_SPOC_NAME_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUpdateCustomersSpocName = (data) => (dispatch) => {
  axios
    .get(`${GET_UPDATE_CUSTOMERS_SPOC_NAME}/${data}`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(UPDATE_CUSTOMERS_SPOC_NAME_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getCustomerTaggedContacts = (data) => (dispatch) => {
  axios
    .get(`${GET_CUSTOMER_TAGGED_CONTACTS}/${data}`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CUSTOMER_TAGGED_CONTACTS_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const fetchCustomers = (data) => (dispatch) => {
  axios
    .get(`${CURD_CUSTOMERS}/${data}`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CUSTOMERS_FETCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateCustomers = (data, back) => (dispatch) => {
  axios
    .put(CURD_CUSTOMERS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteCustomers = (data) => (dispatch) => {
  axios
    .delete(CURD_CUSTOMERS, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getCustomers));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
