import React, { Component } from "react";
import { Row, Col, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DateFns from "date-fns";
// components
import {
  Validator,
  VersionValidator,
  submitValidator,
  valid,
  errors,
  refresh,
  PercentageValidation,
} from "../../../../common/Validator";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import Notifier from "../../../../aside/Notifier";
// redux
import { addCertificationDetails } from "../../../../../redux/actions/hcm/my_profile/certificationDetailsAction";

// -----------------------------------------------------------------------------------------------------------------
class AddCertification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      certification: "",
      version: "",
      specialization: "",
      institute: "",
      completion: "",
      validUntil: "",
      grade: "",
      percentage: "",
      dateError: "",
      error: "",
      message: "",
      dateError: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    if (e.target.name === "version") {
      VersionValidator(e.target);
    }
    if (e.target.name === "percentage") {
      PercentageValidation(e.target);
    } else {
      Validator(e.target);
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    const data = {
      version: this.state.version,
      areaOfSpecialization: this.state.specialization,
      certName: this.state.institute,
      completionDate: this.state.completion,
      validUntil: this.state.validUntil,
      grade: this.state.grade,
      percentage: this.state.percentage,
    };
    if (valid) {
      this.props.addCertificationDetails(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidMount contains the error form data refresh method
*/
  componentDidMount() {
    refresh();
    this.setState({
      message: "",
      error: "",
    });
  }

  render() {
    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Add Certification </h5>
        <i
          className="material-icons cancel-button pointer"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label className="label-sm bold">Certification Name</Label>
                <Input
                  type="text"
                  name="institute"
                  value={this.state.institute}
                  onChange={this.onChange}
                  className="form-control-sm"
                  onBlur={this.onBlur}
                  required={true}
                />
                {errors.institute && (
                  <FormText className="error">{errors.institute}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="label-sm bold">Version</Label>
                <Input
                  type="text"
                  name="version"
                  value={this.state.version}
                  onChange={this.onChange}
                  className="form-control-sm"
                  onBlur={this.onBlur}
                  required={true}
                />
                {errors.version && (
                  <FormText className="error">{errors.version}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="label-sm bold">Specialization</Label>
                <Input
                  type="text"
                  name="specialization"
                  value={this.state.specialization}
                  onChange={this.onChange}
                  className="form-control-sm "
                  onBlur={this.onBlur}
                  required={true}
                />
                {errors.specialization && (
                  <FormText className="error">{errors.specialization}</FormText>
                )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label className="label-sm bold">Completed on</Label>
                <Input
                  type="date"
                  name="completion"
                  value={this.state.completion}
                  onChange={this.onChange}
                  className="form-control-sm"
                  required={true}
                  max={DateFns.format(new Date(), "YYYY-MM-DD")}
                />
                {errors.completion && (
                  <FormText className="error">{errors.completion}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="label-sm bold">Valid Until</Label>
                <Input
                  type="date"
                  name="validUntil"
                  value={this.state.validUntil}
                  onChange={this.onChange}
                  className="form-control-sm"
                  min={this.state.completion}
                  required={true}
                />
                {errors.validUntil && (
                  <FormText className="error">{errors.validUntil}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="label-sm bold">Grade</Label>
                <Input
                  type="text"
                  name="grade"
                  value={this.state.grade}
                  onChange={this.onChange}
                  className="form-control-sm"
                  onBlur={this.onBlur}
                  required={true}
                />
                {errors.grade && (
                  <FormText className="error">{errors.grade}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="label-sm bold">Percentage</Label>
                <Input
                  type="number"
                  name="percentage"
                  value={this.state.percentage}
                  onChange={this.onChange}
                  className="form-control-sm"
                  onBlur={this.onBlur}
                  required={true}
                />
                {errors.percentage && (
                  <FormText className="error">{errors.percentage}</FormText>
                )}
              </FormGroup>
            </Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Add
            </button>
          </div>
        </Form>
        {this.props.certificationDetails.error && (
          <ErrorNotifier message={this.props.certificationDetails.error} />
        )}
        {this.props.certificationDetails.message && (
          <Notifier message={this.props.certificationDetails.message} />
        )}
      </React.Fragment>
    );
  }
}

AddCertification.propTypes = {
  addCertificationDetails: PropTypes.func.isRequired,
  certificationDetails: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  certificationDetails: state.certificationDetails,
});

export default connect(mapStateToProps, { addCertificationDetails })(
  AddCertification
);
