import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Row, Col, Card } from "reactstrap";
import { connect } from "react-redux";
import axios from "axios";
import DateFns from "date-fns";
import AddTagCandidate from "./addTagCandidate";
import UpdateTaggedRequirement from "./updateTaggedRequirement";
import ViewUplodedresume from "./viewMatchedCandiResume";
import CustTooltip from "../../../common/tooltip/CustTooltip";
import { IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { AiFillTags } from "react-icons/ai";
import { ImSearch } from "react-icons/im";
import ErrorNotifier from "../../../aside/ErrorNotifier";
// redux
import { GET_MATCHED_CANDI } from "../../../../utils/routes";
import { getTaggedDetails } from "../../../../redux/actions/rs/requirements/requiremntsAction";

import { Input, FormGroup, Label } from "reactstrap";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
// --------------------------------------------------------------------------------

// view matched candidate component
function ViewMatchedCandidates({
  matchedView,
  closeModel,
  matchedData,
  reqType,
}) {
  const [data, setData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [canId, setCanId] = useState(null);
  const[canInfoId,setCanInfoId] = useState(null)
  const [uniqueData, setUniqueData] = useState(null);
  const[showPopUpTag,setShowPopUpTag] = useState(false)
  const[searchTerm,setSearchTerm] = useState("")
  const[canUserKeyId,setUserKeyId] = useState(null)
  const[fromDate,setFromDate] = useState("")
  const[toDate,setTodate] = useState("")
  const[dateError,setDateError] = useState("")
  console.log(matchedData);
  useEffect(() => {
    let body = {
      primarySkills: matchedData[0].primarySkills
        ? matchedData[0].primarySkills
        : "",
      secondarySkills: matchedData[0].secondarySkills
        ? matchedData[0].secondarySkills
        : "",
      jobLocation: matchedData[0].jobLocation ? matchedData[0].jobLocation : "",
      minYearsOfExp: matchedData[0].minYearsOfExp.toString()
        ? matchedData[0].minYearsOfExp.toString()
        : "",
      maxCtc: matchedData[0].maxCtc ? matchedData[0].maxCtc : "",
      joiningDate: matchedData[0].startDate ? matchedData[0].startDate : "",
      jobDescription: matchedData[0].jobDescription
        ? matchedData[0].jobDescription
        : "",
      reqType: matchedData[0].jobReqType ? matchedData[0].jobReqType : "",
      qualification: matchedData[0].qualifications
        ? matchedData[0].qualifications
        : "",
      jobPosition: matchedData[0].jobPosition ? matchedData[0].jobPosition : "",
      
    };

    axios
      .post(`${GET_MATCHED_CANDI}`, body, { withCredentials: true })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  // resume open
  const viewResumeClick = (id) => {
    console.log(id);
    setShowPopUpTag(false)
    setCanId(id);
    setEdit(true);
  };
  const handlePopUpView = (id,idTwo) =>{
    setEdit(false);
    setShowPopUpTag(true)
    setCanInfoId(id)
    setUserKeyId(idTwo)
  }

  const closeTaggedPopUpView = () =>{
    setShowPopUpTag(false)
  }

  const search = (e) => {
   setSearchTerm(e.target.value)
  };

  const dateSearchClick = () =>{
    let body = {
      primarySkills: matchedData[0].primarySkills
        ? matchedData[0].primarySkills
        : "",
      secondarySkills: matchedData[0].secondarySkills
        ? matchedData[0].secondarySkills
        : "",
      jobLocation: matchedData[0].jobLocation ? matchedData[0].jobLocation : "",
      minYearsOfExp: matchedData[0].minYearsOfExp.toString()
        ? matchedData[0].minYearsOfExp.toString()
        : "",
      maxCtc: matchedData[0].maxCtc ? matchedData[0].maxCtc : "",
      joiningDate: matchedData[0].startDate ? matchedData[0].startDate : "",
      jobDescription: matchedData[0].jobDescription
        ? matchedData[0].jobDescription
        : "",
      reqType: matchedData[0].jobReqType ? matchedData[0].jobReqType : "",
      qualification: matchedData[0].qualifications
        ? matchedData[0].qualifications
        : "",
      jobPosition: matchedData[0].jobPosition ? matchedData[0].jobPosition : "",
      fromDate:fromDate,
      toDate:toDate
    };

  

    if (fromDate && toDate) {
      axios
      .post(`${GET_MATCHED_CANDI}`, body, { withCredentials: true })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
      setDateError("");

    }
      // props.getDashboardGraphRatio(body);
     else {
      setDateError("Date should not be empty");
      setTimeout(() => {
        setDateError("");
      }, 2000);
    }
  }
   



  

  // close resume toggle

  const toggleBackView = () => {
    setEdit(false);
  };

  return (
    <div>
      <Modal size="xl" show={matchedView} onHide={closeModel}>
        {!edit && (
          <div>
            <div>
              <i
                data-toggle="tooltip"
                data-placement="top"
                title="Close"
                onClick={closeModel}
                className="material-icons pointer cancel-button"
              >
                cancel
              </i>
            </div>
         
            <div
              style={{
                display: "flex",
                flexDirection:"row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "1rem",
                paddingLeft:"2rem",
                paddingRight:"2rem"
                // fontSize:"1.25rem",
                // fontWeight:400
              }}
            >

           <Modal.Title style={{ fontSize: "1.25rem", fontWeight: 400 }}>
                View Matched Candidates
            </Modal.Title>

             

              

              <div style={{marginLeft:"8rem"}}>
                  <input
                      type="text"
                      onChange={search}
                      value={searchTerm}
                      style={{ border: "1px solid #aaa",top:"0.65rem"}}
                      className="form-control-sm"
                      placeholder="Search for Profile Ratings"
                    />
                    <CustTooltip title="Search">
                      <IconButton
                        type="submit"
                        style={{
                          border: "none",
                          width: "3%",
                          // position: "absolute",
                          // top: "0.65rem",
                          right: "-10%",
                          height: "1.9rem",
                          color: "#0056b3",
                          padding: ".1rem",
                        }}
                      >
                        <SearchIcon className="mui-icon-size" />
                      </IconButton>
                    </CustTooltip>
                    </div>

              <div style={{marginTop:"0.2rem"}} className="matched-cando-classMatch">
            <div className="dateMatchedReq-class-one">
            <Label
                  style={{
                    fontWeight: "bold",
                    fontSize: "0.80rem",
                    marginLeft: "1.5rem",
                    marginTop:"0.2rem"
                  }}
                >
                  From Date
                </Label>
              <FormGroup className="mb-0-5">
               

                <Input
                   style={{marginLeft:"0.5rem"}}
                  type="date"
                  name="sourcedFrom"
                  value={fromDate}
                  onChange={(e)=>setFromDate(e.target.value)
                   
                  }
                  className="form-control-sm manager-view-select-box manager-date-input"
                  required={true}
                  tabIndex={8}
                />
              </FormGroup>

              <Label
                  style={{
                    fontWeight: "bold",
                    fontSize: "0.80rem",
                    marginLeft: "1.5rem",
                    marginTop:"0.2rem"
                  }}
                >
                  To Date
                </Label>

              <FormGroup className="mb-0-5">
              

                <Input
                 style={{marginLeft:"0.5rem"}}
                  type="date"
                  name="sourcedTo"
                  value={toDate}
                  onChange={(e) =>
                   setTodate(e.target.value)
                  }
                  className="form-control-sm manager-view-select-box manager-date-input"
                  required={true}
                  tabIndex={8}
                />
              </FormGroup>
              
            <h3
              data-toggle="tooltip"
              data-placement="top"
              onClick={dateSearchClick}
              style={{
                color: "#0056b3",
                marginTop:"0.3rem",
                marginLeft:"1.1rem",
                cursor: "pointer",
                fontSize: "0.9rem",
              }}
            >
              {" "}
              <ImSearch />{" "}
            </h3>
            </div>

          </div>
                


            </div>

            <Modal.Body>
              <Row className="mt-3">
                { data.length > 0 &&
                 data.filter((val)=>{
                 if(searchTerm === " "){
                 return val
                 }
                 else if(val.profileRating.includes(searchTerm)){
                return val
                 }
                 })
               .map((item) => (
                    <Col
                     
                      md="4"
                      key={item.id}
                      className="employee-list-col"
                    >
                      <Card className="p-2 mb-2 shadow pointer">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                           <CustTooltip title="View Resume">
                            <h3
                              data-toggle="tooltip"
                              data-placement="top"
                              onClick={() =>viewResumeClick(item.canInfo)}
                              
                              style={{
                                color: "#0056b3",
                                marginRight: ".5rem",
                                cursor: "pointer",
                                fontSize: "1.7rem",
                              }}
                            >
                              {" "}
                              < PictureAsPdfIcon />{" "}
                            </h3>
                          </CustTooltip>
                          <CustTooltip title="Tag Candidate">
                            <h3
                              data-toggle="tooltip"
                              data-placement="top"
                              onClick ={()=>handlePopUpView(item.canInfo,item.canUserId)}
                              
                              style={{
                                color: "#0056b3",
                                marginRight: ".5rem",
                                cursor: "pointer",
                                fontSize: "1.7rem",
                              }}
                            >
                              {" "}
                              <AiFillTags />{" "}
                            </h3>
                          </CustTooltip>

                        </div>
                        <ul className="CustDetails-ul employee-list-ul">
                          <li>
                            <span
                              style={{ fontWeight: 500 }}
                              className="label-sm bold emp-span-label-rs"
                            >
                              {" "}
                              Profile Rating
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span
                                style={{ fontSize: "0.75rem", fontWeight: 400 }}
                                className="employee-list-span-data-rs"
                              >
                                {item?.profileRating}
                              </span>
                            </span>
                          </li>
                          <li>
                            <span
                              style={{ fontWeight: 500 }}
                              className="label-sm bold emp-span-label-rs"
                            >
                              {" "}
                              Id
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span
                                style={{ fontSize: "0.75rem", fontWeight: 400 }}
                                className="employee-list-span-data-rs"
                              >
                                {item?.canInfo}
                              </span>
                            </span>
                          </li>
                          <li>
                            <span
                              style={{ fontWeight: 500 }}
                              className="label-sm bold emp-span-label-rs"
                            >
                              {" "}
                              Name
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span
                                style={{ fontSize: "0.75rem", fontWeight: 400 }}
                                className="employee-list-span-data-rs"
                              >
                                {`${item?.firstName} ${item?.lastName}`}
                              </span>
                            </span>
                          </li>

                          <li>
                            <span
                              style={{ fontWeight: 500 }}
                              className="label-sm bold emp-span-label-rs"
                            >
                              {" "}
                              Email Id
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span
                                style={{ fontSize: "0.75rem", fontWeight: 400 }}
                                className="employee-list-span-data-rs"
                              >
                                {item?.emailId}
                              </span>
                            </span>
                          </li>
                          <li>
                            <span
                              style={{ fontWeight: 500 }}
                              className="label-sm bold emp-span-label-rs"
                            >
                              {" "}
                              Phone No.
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span
                                style={{ fontSize: "0.75rem", fontWeight: 400 }}
                                className="employee-list-span-data-rs"
                              >
                                {item?.mobileNo}
                              </span>
                            </span>
                          </li>
                          <li>
                            <span
                              style={{ fontWeight: 500 }}
                              className="label-sm bold emp-span-label-rs"
                            >
                              {" "}
                             Skills Updated On
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span
                                style={{ fontSize: "0.75rem", fontWeight: 400 }}
                                className="employee-list-span-data-rs"
                              >
                                {item?.skillsUpdatedOn ?DateFns.format(item?.skillsUpdatedOn, "DD-MM-YYYY"):""}
                              </span>
                            </span>
                          </li>
                          <li>
                            <span
                              style={{ fontWeight: 500 }}
                              className="label-sm bold emp-span-label-rs"
                            >
                              {" "}
                              Location
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span
                                style={{ fontSize: "0.75rem", fontWeight: 400 }}
                                className="employee-list-span-data-rs"
                              >
                                {item?.location}
                              </span>
                            </span>
                          </li>
                          <li>
                            <span
                              style={{ fontWeight: 500 }}
                              className="label-sm bold emp-span-label-rs"
                            >
                              {" "}
                              Yrs Of Experience
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span
                                style={{ fontSize: "0.75rem", fontWeight: 400 }}
                                className="employee-list-span-data-rs"
                              >
                                {item?.yrOfExp === "Null" ? "" : item?.yrOfExp  }
                              </span>
                            </span>
                          </li>
                          <li>
                            <span
                              style={{ fontWeight: 500 }}
                              className="label-sm bold emp-span-label-rs"
                            >
                              {" "}
                              Notice Period
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span
                                style={{ fontSize: "0.75rem", fontWeight: 400 }}
                                className="employee-list-span-data-rs"
                              >
                                {item?.noticePd}
                              </span>
                            </span>
                          </li>
                        </ul>
                        {/* <Divider style ={{marginTop:"0.5rem"}} />
                        <DetailedPageFooter
                          createdBy={item.createdBy}
                          createdOn={item.createdOn}
                          updatedBy={item.updatedBy}
                          updatedOn={item.updatedOn}
                        /> */}
                      </Card>
                    </Col>
                  ))}
              </Row>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
            {showPopUpTag && (
              <AddTagCandidate
                isShowSearchBar = {false}
                canInfoId ={canInfoId}
                reqType={reqType}
                showPopUp={showPopUpTag}
                canUserId={canUserKeyId}
                handleCloseModal={closeTaggedPopUpView}
              />
            )}

          </div>
        )}

        {edit && (
          <div>
            <ViewUplodedresume fetchid={canId} navigate={toggleBackView} />
          </div>
        )}

{dateError ? (
          <ErrorNotifier message={dateError} />
        ) : null}

      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  requirements: state.requirements,
});

export default connect(mapStateToProps, { getTaggedDetails })(
  ViewMatchedCandidates
);
