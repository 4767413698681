import axios from "axios";
import { SKILL_GROUP_GET } from "../../types";
import {
  ADD_SKILL_GROUP,
  GET_SKILL_GROUP,
  UPDATE_SKILL_GROUP,
  DELETE_SKILL_GROUP,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addSkillGroup = (data, back) => (dispatch) => {
  axios
    .post( ADD_SKILL_GROUP, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getSkillGroup));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getSkillGroup = () => (dispatch) => {
  axios
    .get(GET_SKILL_GROUP, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(SKILL_GROUP_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateSkillGroup = (data, back) => (dispatch) => {
  axios
    .put( UPDATE_SKILL_GROUP , data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteSkillGroup = (data) => (dispatch) => {
  axios
    .delete(DELETE_SKILL_GROUP, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getSkillGroup));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
