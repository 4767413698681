import React, { Component } from "react";
import { Form, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// components
import {
  Validator,
  submitValidator,
  valid,
  refresh,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
// redux
import { updateCustomerDomain } from "../../../../../redux/actions/crm/crm_setup/customerDomainAction";

// -------------------------------------------------------------------------------------------------------
class UpdateCustomerBusinessDomain extends Component {
  constructor(props) {
    super(props);
    const { id, domainName, description } = this.props.data;
    this.state = {
      domainId: id,
      domainName: domainName,
      description: description,
      message: "",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      id: this.state.domainId,
      domainName: this.state.domainName,
      description: this.state.description,
    };
    if (valid) {
      this.props.updateCustomerDomain(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    refresh();
    this.setState({
      message: "",
      error: "",
    });
  }

  render() {
    const { domainName, description, error, message } = this.state;

    return (
      <React.Fragment>
        <h5 className="center bold mt-3">Update Customer Domain</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="3"></Col>
            <Col md="6">
              <FormComponent
                labelClass="bold label-sm"
                label="Domain Name"
                type="text"
                name="domainName"
                inputClass="form-control-sm"
                value={domainName}
                change={this.onChange}
                required={true}
                pattern="onlyLetters"
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Description"
                type="textarea"
                name="description"
                inputClass="form-control-sm"
                value={description}
                change={this.onChange}
              />
            </Col>
            <Col md="3"></Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Update
            </button>
          </div>
        </Form>
        {this.props.customerDomain.message && (
          <Notifier message={this.props.customerDomain.message} />
        )}
        {this.props.customerDomain.error && (
          <ErrorNotifier message={this.props.customerDomain.error} />
        )}
        {error && <ErrorNotifier message={error} />}
      </React.Fragment>
    );
  }
}

UpdateCustomerBusinessDomain.propTypes = {
  updateCustomerDomain: PropTypes.func.isRequired,
  customerDomain: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  customerDomain: state.customerDomain,
});

export default connect(mapStateToProps, { updateCustomerDomain })(
  UpdateCustomerBusinessDomain
);
