import React, { Component } from "react";
import { Form, Row, Col, FormGroup, Label, Input, FormText } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Select from "react-select";

// components
import {
  Validator,
  submitValidator,
  valid,
  refresh,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
// redux
import { updateProjectMember } from "../../../../../redux/actions/experience_360/e360_config/projectMemberAction";
import { getProjectTeam } from "../../../../../redux/actions/experience_360/e360_config/projectTeamAction";
import { getEmployee } from "../../../../../redux/actions/iam/emp_onboarding/empOnboardingAction";

// ----------------------------------------------------------------------------------------------------------------------

class UpdateProjectMember extends Component {
  constructor(props) {
    super(props);
    const { id, projectteamId, empNo, empName, remarks } = this.props.data;
    this.state = {
      id: id,
      projectTeamId: projectteamId,
      employee: { value: `${empNo},${empName}`, label: `${empNo}-${empName}` },
      remarks: remarks,
      error: "",
      employeeError: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleChange = (employee) => {
    if (employee) {
      this.setState({ employee, employeeError: null });
    } else {
      this.setState({
        employeeError: "This field is mandatory",
      });
    }
  };

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      id: this.state.id,
      projectteamId: Number(this.state.projectTeamId),
      empNo: this.state.employee.value.split(",")[0],
      empName: this.state.employee.value.split(",")[1],
      remarks: this.state.remarks,
    };
    if (valid && this.state.employee) {
      this.props.updateProjectMember(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    refresh();
    this.setState({
      error: "",
    });
    this.props.getEmployee();
    this.props.getProjectTeam();
  }

  render() {
    const { projectTeamId, employee, remarks, error } = this.state;

    let options1 = [];
    for (let i = 0; i < this.props.employees.data.length; i++) {
      var x1 = {};
      x1[
        "value"
      ] = `${this.props.employees.data[i].empNo},${this.props.employees.data[i].firstName}`;
      x1[
        "label"
      ] = `${this.props.employees.data[i].empNo}-${this.props.employees.data[i].firstName}`;

      options1.push(x1);
    }

    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Update Project Member</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="6" className="mx-auto">
              <FormGroup>
                <Label className="bold label-sm">Project Team</Label>
                <Input
                  type="select"
                  className="form-control-sm"
                  name="projectTeamId"
                  value={projectTeamId}
                  onChange={this.onChange}
                  required
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {this.props.projectTeam.data.length > 0 &&
                    this.props.projectTeam.data.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.id}-{item.teamName}
                      </option>
                    ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label className="bold label-sm">Employee</Label>
                <Select
                  name="employee"
                  value={employee}
                  onChange={this.handleChange}
                  options={options1}
                  required
                />
              </FormGroup>
              {this.state.employeeError && (
                <FormText className="error select-search-error">
                  {this.state.employeeError}
                </FormText>
              )}

              <FormComponent
                labelClass="bold label-sm"
                label="Remarks"
                type="textarea"
                name="remarks"
                inputClass="form-control-sm"
                value={remarks}
                change={this.onChange}
              />
            </Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Update
            </button>
          </div>
        </Form>
        {error && <ErrorNotifier message={error} />}
        {this.props.projectMember.error && (
          <ErrorNotifier message={this.props.projectMember.error} />
        )}
        {this.props.projectMember.message && (
          <Notifier message={this.props.projectMember.message} />
        )}
      </React.Fragment>
    );
  }
}

UpdateProjectMember.propTypes = {
  updateProjectMember: PropTypes.func.isRequired,
  projectMember: PropTypes.object.isRequired,
  getEmployee: PropTypes.func.isRequired,
  employees: PropTypes.object.isRequired,
  getProjectTeam: PropTypes.func.isRequired,
  projectTeam: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  projectMember: state.projectMember,
  projectTeam: state.projectTeam,
  employees: state.employees,
});

export default connect(mapStateToProps, {
  updateProjectMember,
  getEmployee,
  getProjectTeam,
})(UpdateProjectMember);
