import axios from "axios";
import {
  FORM16_UPLOAD,
  FORM16_GET,
  FORM16_DOWNLOAD,
  GET_ERRORS,
} from "../../types";
import {
  UPLOAD_FORM16,
  GET_FORM16,
  DOWNLOAD_FORM16,
} from "../../../../utils/routes";
import { setMessage } from "../../salesActions";

export const uploadForm16 = (data, back) => (dispatch) => {
  axios
    .post(UPLOAD_FORM16, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const getForm16 = (data) => (dispatch) => {
  axios
    .post(GET_FORM16, data, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: FORM16_GET,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};

export const downloadForm16 = (data, fileName) => (dispatch) => {
  axios
    .post(DOWNLOAD_FORM16, data, {
      responseType: "blob",
      withCredentials: true,
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};

export const viewForm16 = (data, fileName) => (dispatch) => {
  axios
    .post(DOWNLOAD_FORM16, data, {
      responseType: "blob",
      withCredentials: true,
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      dispatch({
        type: FORM16_DOWNLOAD,
        payload: url,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};
