import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FETCHBYID } from "../../../../utils/routes";
import { Row, Col, Form,Container, FormGroup, Input, Label, FormText } from "reactstrap";
import { AiFillEye } from 'react-icons/ai';
import DateFns from "date-fns";
import { updateRequirement } from "../../../../redux/actions/rs/requirements/requiremntsAction";
// components

import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../common/Validator";
import FormComponent from "../../../common/FormComponent";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
// import CustomerOrgChart from "./CustomerOrgChart";
import { CURD_CUSTOMERS } from "../../../../utils/routes";
// redux
// import {
//   updateCustomers,
//   getCustomers,
//   fetchCustomers,
// } from "../../../../redux/actions/crm/customers/customersAction";
// import { getCustomerDomain } from "../../../../redux/actions/crm/crm_setup/customerDomainAction";
// import { getCustomerType } from "../../../../redux/actions/crm/crm_setup/customerTypeAction";
// import { getCustomerGeographies } from "../../../../redux/actions/crm/crm_setup/customerGeographiesAction";
// import { getCustomerCountries } from "../../../../redux/actions/crm/crm_setup/customerCountriesAction";
// import { getCustomerCurrency } from "../../../../redux/actions/crm/crm_setup/customerCurrencyAction";
// import { getCustomerAccountStatus } from "../../../../redux/actions/crm/crm_setup/customerAccountStatusAction";

class ViewJobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerName: "",
      spouseName: "",
      spouseContact:"",
      spouseMail: "",
      reqType:"", 
      jobRole:"",
      description: "",
      location: "",

      timing: "",
      yearsExp: "",
      releventExp: "",
      skillSet:"",
      haveSkills:"",
      niceSkills: "",
      totalPos: "",
      duration:"",
      startDate: "",

      endDate: "",
      billingType:"",
      currency: "",
      salaryRange: "",
      reqStatus:"",
      remarks: "",
      edit: false,
      custOrgChart: false,
      data: {},
      dateError: "",
      message: "",
      error: "",
      getData:[],
      dataStore:[]
      
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.fetchParticularId = this.fetchParticularId.bind(this)
    this.backnavigate = this.backnavigate.bind(this)
  }

//   componentDidUpdate(prevProps) {
//     if (this.props.customers.message) {
//       setTimeout(() => {
//         this.props.back();
//       }, 2000);
//     }
//   }

  onChange(e) {
    e.preventDefault();
    Validator(e.target);
    if (e.target.name === "endDate") {
      if (Date.parse(e.target.value) < Date.parse(this.state.startDate)) {
        this.setState({
          [e.target.name]: e.target.value,
          dateError: "EndDate must be greater than or equal to StartDate",
        });
      } else {
        this.setState({
          [e.target.name]: e.target.value,
          dateError: "",
        });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  backnavigate(){
    this.setState({
      edit:!this.state.edit
    })
    this.fetchParticularId()
  }

  /*
  This function for updating the customer details
  */
  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let body = {
      resReqId:this.props.requirementId,
      custName:this.state.customerName,
      custSpocName:this.state.spouseName,
      custSpocConNum:this.state.spouseContact,
      custSpocConEmail:this.state.spouseMail,
      reqType:this.state.reqType,
      jobCategory:this.state.jobCategory,
      jobRole:this.state.jobRole,
      JobDescription:this.state.description,
      jobLocation:this.state.location,
      JobTimings:this.state.timing,
      yearsOfExp: Number(this.state.yearsExp),
      releventExp:Number(this.state.releventExp),
      skillSets:this.state.skillSet,
      mustToHaveSkills:this.state.haveSkills,
      niceToHaveSkills:this.state.niceSkills,
      totalPositions:this.state.totalPos,
      startDate:this.state.startDate,
      endDate:this.state.endDate,
      billingType:this.state.billingType,
      currency:this.state.currency,
      salaryRange:this.state.salaryRange,
      reqStatus:this.state.reqStatus,
      remarks:this.state.remarks,
      duration:this.state.duration
 
     };
    if (valid && !this.state.dateError) {
      this.props.updateRequirement(body,this.backnavigate);
      // setTimeout(() => {
      //   this.props.getCustomers();
      // }, 2000);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

fetchParticularId(){
axios
  .get(`${FETCHBYID}/${this.props.requirementId}`, {
    // params: {
    //   resReqId: this.props.requirementId,
    // },
    withCredentials: true,
  })
  .then((res) => {
    console.log(res.data)
  this.setState({
  getData:[res.data],
  customerName: res.data.custName,
  spouseName: res.data.custSpocName,
  spouseContact:res.data. custSpocConNum,
  spouseMail: res.data.custSpocConEmail,
  reqType:res.data.reqType, 
  jobRole:res.data.jobRole,
  description: res.data.JobDescription,
  location: res.data.jobLocation,

  timing:  res.data.JobTimings,
  yearsExp:  res.data.releventExp,
  releventExp:  res.data.releventExp,
  skillSet: res.data.skillSets,
  haveSkills: res.data. mustToHaveSkills,
 
  niceSkills:  res.data.niceToHaveSkills,
  totalPos:  res.data.totalPositions,
  duration: res.data.duration,
  startDate:  res.data.startDate,

  endDate:  res.data.endDate,
  billingType: res.data.billingType,
  currency:  res.data.currency,
  salaryRange:  res.data.salaryRange,
  reqStatus: res.data.reqStatus,
  remarks:  res.data.remarks,
  })
  })
  .catch((err) => {
    console.log(err);
  });

}






  /*
  ComponentDidMount getting the selected customer details and setting the state of customer details
  */
  componentDidMount() {
    this.fetchParticularId()
   
    refresh();
  }

 
  render() {
    console.log(this.state.dataStore)
    return (
      
        <Row>
     
        {!this.state.edit ? (
           
          <Col md="12">
            <div style={{display:"flex",justifyContent:"flex-end",alignItems:"center"}}>
            <i
              style={{ color: "green",marginRight:"3rem" }}
              className="material-icons pointer edit-btn"
              onClick={()=>this.setState({edit:!this.state.edit})}
            >
              edit
            </i>
            <h3 style={{color:"#04044c",marginRight:"3rem",}}>  <AiFillEye /> </h3>
            <i
              onClick={() => this.props.isToggleView()}
              className="material-icons pointer cancel-button"
            >
              cancel
            </i>
            </div>

         
            <h5 style={{fontweight:700}} className="bold center mb-4"> Jobs</h5>

         
            {this.state.getData.map((data) => {
              return (
                <Row>
                  <Col md="4">
                    <ul className="CustDetails-ul">
                      <li style={{marginTop:"1rem"}}>
                        <span style={{fontSize:"14px",fontWeight:"bold",width:"150px"}} className="label-sm bold customer-span-label-col-1">
                          Customer Name
                        </span>
                        <span style={{fontSize:"12px"}} className="label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{data.custName}
                        </span>
                      </li>
                      <li  style={{marginTop:"1rem"}}>
                        <span style={{fontSize:"14px",fontWeight:"bold",width:"150px"}} className="label-sm bold customer-span-label-col-1">
                          Customer Spoc Name
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{data.custSpocName}
                        </span>
                      </li>
                      <li  style={{marginTop:"1rem"}}>
                        <span style={{fontSize:"14px",fontWeight:"bold",width:"150px"}} className="label-sm bold customer-span-label-col-1">
                          Spoc Contact No.
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{data.custSpocConNum}
                        </span>
                      </li>
                      <li  style={{marginTop:"1rem"}}>
                        <span style={{fontSize:"14px",fontWeight:"bold",width:"150px"}} className="label-sm bold customer-span-label-col-1">
                          Spoc Email
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{data.custSpocConEmail}
                        </span>
                      </li>
                      <li style={{marginTop:"1rem"}}>
                        <span style={{fontSize:"14px",fontWeight:"bold",width:"150px"}} className="label-sm bold customer-span-label-col-1">
                          Requirement Type
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{data.reqType}
                        </span>
                      </li>
                      <li style={{marginTop:"1rem"}}>
                        <span style={{fontSize:"14px",fontWeight:"bold",width:"150px"}} className="label-sm bold customer-span-label-col-1">
                          Job Category
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{data.jobCategory}
                        </span>
                      </li>
                      <li style={{marginTop:"1rem"}}>
                        <span style={{fontSize:"14px",fontWeight:"bold",width:"150px"}} className="label-sm bold customer-span-label-col-1">
                          Job Position
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{data.jobRole}
                        </span>
                      </li>
                      <li style={{marginTop:"1rem"}}>
                        <span style={{fontSize:"14px",fontWeight:"bold",width:"150px"}} className="label-sm bold customer-span-label-col-1">
                          Job Description
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{data.JobDescription}
                        </span>
                      </li>
                      <li style={{marginTop:"1rem"}}>
                        <span style={{fontSize:"14px",fontWeight:"bold",width:"150px"}} className="label-sm bold customer-span-label-col-1">
                          Job Location
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{data.jobLocation}
                        </span>
                      </li>
                      <li style={{marginTop:"1rem"}}>
                        <span style={{fontSize:"14px",fontWeight:"bold",width:"150px"}} className="label-sm bold customer-span-label-col-1">
                          Customer Type
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.custType}
                        </span>
                      </li>
                      <li style={{marginTop:"1rem"}}>
                        <span style={{fontSize:"14px",fontWeight:"bold",width:"150px"}} className=" bold label-sm customer-span-label-col-1">
                          Job Timing
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{data.jobTimings}
                        </span>
                      </li>
                      <li style={{marginTop:"1rem"}}>
                        <span style={{fontSize:"14px",fontWeight:"bold",width:"150px",width:"150px"}} className="label-sm bold customer-span-label-col-1">
                          Years Of Experience
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{data.JobTimings}
                          {/* &nbsp;&nbsp;:&nbsp;&nbsp;
                            {DateFns.format(this.state.startDate, "DD-MM-YYYY")} */}
                        </span>
                      </li>
                    </ul>
                  </Col>
                  {/* <Col md="4">
                    <ul className="CustDetails-ul">
                    
                    
                    </ul>
                  </Col> */}
                  <Col style={{marginLeft:"10rem"}} md="4">
                    <ul className="CustDetails-ul">
                    <li style={{marginTop:"1rem"}}>
                        <span style={{fontSize:"14px",fontWeight:"bold",width:"150px"}} className="label-sm bold customer-span-label-col-1">
                          Skills
                        </span>
                        <span  className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{data.niceToHaveSkills}
                        </span>
                      </li>
                      <li style={{marginTop:"1rem"}}>
                        <span style={{fontSize:"14px",fontWeight:"bold",width:"150px"}} className="label-sm bold customer-span-label-col-1">
                          Relevent Experience
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{data.releventExp}
                        </span>
                      </li>
                      <li style={{marginTop:"1rem"}}>
                        <span style={{fontSize:"14px",fontWeight:"bold",width:"150px"}} className="label-sm bold customer-span-label-col-1">
                          Skill Sets
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{data.skillSets}
                        </span>
                      </li>

                      <li style={{marginTop:"1rem"}}>
                        <span style={{fontSize:"14px",fontWeight:"bold",width:"150px"}} className="label-sm bold customer-span-label-col-1">
                          Skills
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{data.mustToHaveSkills}
                        </span>
                      </li>
                      <li style={{marginTop:"1rem"}}>
                        <span style={{fontSize:"14px",fontWeight:"bold",width:"150px"}} className="label-sm bold customer-span-label-col-3">
                          Total Position
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{data.totalPositions}
                        </span>
                      </li>
                      <li style={{marginTop:"1rem"}}>
                        <span style={{fontSize:"14px",fontWeight:"bold",width:"150px"}} className="label-sm bold customer-span-label-col-3">
                          Duration
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{data.duration}
                        </span>
                      </li>
                      <li style={{marginTop:"1rem"}}>
                        <span style={{fontSize:"14px",fontWeight:"bold",width:"150px"}} className="label-sm bold customer-span-label-col-3">
                          Start Date
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;
                          {DateFns.format(data.startDate, "DD-MM-YYYY")}
                        </span>
                      </li>
                      {/* <li>
                          <span className="label-sm bold customer-span-label-col-3">
                            Remarks
                          </span>
                          <span className=" label-sm">
                            &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.remarks}
                          </span>
                        </li> */}
                      <li style={{marginTop:"1rem"}}>
                        <span style={{fontSize:"14px",fontWeight:"bold",width:"150px"}} className="label-sm bold customer-span-label-col-3">
                          End Date
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;
                          {DateFns.format(data.endDate, "DD-MM-YYYY")}
                        </span>
                      </li>
                      <li style={{marginTop:"1rem"}}>
                        <span style={{fontSize:"14px",fontWeight:"bold",width:"150px"}} className="label-sm bold customer-span-label-col-3">
                          Billing Type
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{data.billingType}
                        </span>
                      </li>
                      <li style={{marginTop:"1rem"}}>
                        <span style={{fontSize:"14px",fontWeight:"bold",width:"150px"}} className="label-sm bold customer-span-label-col-3">
                          Currency
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{data.currency}
                        </span>
                      </li>
                      <li style={{marginTop:"1rem"}}>
                        <span style={{fontSize:"14px",fontWeight:"bold",width:"150px"}} className="label-sm bold customer-span-label-col-3">
                          Salary Range
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{data.salaryRange}
                        </span>
                      </li>
                      <li style={{marginTop:"1rem"}}>
                        <span style={{fontSize:"14px",fontWeight:"bold",width:"150px"}} className="label-sm bold customer-span-label-col-3">
                          Remarks
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{data.remarks}
                        </span>
                      </li>
                    </ul>
                  </Col>
                
                </Row>
              );
            })}
          </Col>
         
        ) : (
          <Col md="12">
              
            <h5 className="bold center">Update Reuirement</h5>
            <i
              onClick={() => this.setState({ edit: !this.state.edit })}
              className="material-icons pointer cancel-button"
            >
              cancel
            </i>
            <Form onSubmit={this.onSubmit} className="p-3 mt-2" noValidate>
            <Row>
              <Col md="4">
                <FormComponent
                  labelClass="bold label-sm"
                  label="Customer Name"
                  type="text"
                  name="customerName"
                  change={this.onChange}
                    value={this.state.customerName}
                  placeholder="Customer Name"
                  maxlength="27"
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Customer Spoc Name"
                  type="text"
                  name="spouseName"
                  change={this.onChange}
                    value={this.state.spouseName}
                  placeholder="Customer Spoc Name"
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Customer Spoc Contact Number"
                  type="text"
                  name="spouseContact"
                  change={this.onChange}
                    value={this.state.spouseContact}
                  placeholder="Spoc Contact Number"
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Customer Spouse Email Id"
                  type="text"
                  name="spouseMail"
                  change={this.onChange}
                    value={this.state.spouseMail}
                  placeholder="Spoc Email Id"
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Requirement Type"
                  type="text"
                  name="reqType"
                  change={this.onChange}
                    value={this.state.reqType}
                  placeholder="Requirement Type"
                  required={true}
                />

                <FormComponent
                  labelClass="bold label-sm"
                  label="Job Category"
                  type="text"
                  name="jobCategory"
                  change={this.onChange}
                    value={this.state.jobCategory}
                  placeholder="Job Category"
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Job Position"
                  type="text"
                  name="jobRole"
                  change={this.onChange}
                    value={this.state.jobRole}
                  placeholder="Job Position"
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Job Description"
                  type="text"
                  name="description"
                  change={this.onChange}
                    value={this.state.description}
                  placeholder="Job Description"
                  required={true}
                />
              </Col>
              <Col md="4">
                <FormComponent
                  labelClass="bold label-sm"
                  label="Job Location"
                  type="text"
                  name="location"
                  change={this.onChange}
                    value={this.state.location}
                  maxlength="27"
                  placeholder="Job Location"
                  required={true}
                />
                {/* <FormComponent
                  labelClass="bold label-sm"
                  label="Procurement Contact E-mail ID"
                  type="email"
                  name="email"
                  change={this.onChange}
                  value={this.state.email}
                  placeholder="Valid email address"
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Procurement Contact Phone Number"
                  type="tel"
                  name="contact"
                  change={this.onChange}
                  value={this.state.contact}
                  pattern="^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$"
                  placeholder="Valid contact number"
                  required={true}
                /> */}
                <FormComponent
                  labelClass="bold label-sm"
                  label="Job Timing"
                  type="text"
                  name="timing"
                  change={this.onChange}
                    value={this.state.timing}
                  placeholder="PinCode"
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Years Of Experience"
                  type="number"
                  name="yearsExp"
                  change={this.onChange}
                    value={this.state.yearsExp}
                  placeholder="Years Of Expereince"
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Relevent Experience"
                  type="number"
                  name="releventExp"
                  change={this.onChange}
                    value={this.state.releventExp}
                  placeholder="Relevent Expereince"
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Skill Sets"
                  type="text"
                  name="skillSet"
                  change={this.onChange}
                  value={this.state.skillSet}
                  required={true}
                  placeholder="Skill Sets"
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Must To Have Skills"
                  type="text"
                  name="haveSkills"
                  change={this.onChange}
                    value={this.state.haveSkills}
                  required={true}
                  placeholder="Must To Have Skills"
                  maxlength="21"
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Nice To Have Skills"
                  type="text"
                  name="niceSkills"
                  change={this.onChange}
                    value={this.state.niceSkills}
                  required={true}
                  placeholder="Skills"
                  maxlength="10"
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Total Positions"
                  type="text"
                  name="totalPos"
                  change={this.onChange}
                    value={this.state.totalPos}
                  required={true}
                  placeholder="Total Position"
                  maxlength="10"
                />
              </Col>
              <Col md="4">
                <FormComponent
                  labelClass="bold label-sm"
                  label="Duration"
                  type="text"
                  name="duration"
                  change={this.onChange}
                    value={this.state.duration}
                  placeholder="Duration"
                  maxlength="11"
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Start Date"
                  type="date"
                  name="startDate"
                  change={this.onChange}
                  value={this.state.startDate}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="End Date"
                  type="date"
                  name="endDate"
                  change={this.onChange}
                  value={this.state.endDate}
                  min={this.state.startDate}
                  error={this.state.dateError}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Billing Type"
                  type="text"
                  name="billingType"
                  change={this.onChange}
                    value={this.state.billingType}
                  required={true}
                  placeholder="Billing type"
                  maxlength="15"
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Currency"
                  type="text"
                  name="currency"
                  change={this.onChange}
                    value={this.state.currency}
                  required={true}
                  placeholder="Currency"
                  maxlength="15"
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Salary Range"
                  type="text"
                  name="salaryRange"
                  change={this.onChange}
                    value={this.state.salaryRange}
                  required={true}
                  placeholder="Salary Range"
                  maxlength="15"
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Requirement Status"
                  type="text"
                  name="reqStatus"
                  change={this.onChange}
                    value={this.state.reqStatus}
                  required={true}
                  placeholder="Requirement Status"
                  maxlength="15"
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Remarks"
                  type="text"
                  name="remarks"
                  change={this.onChange}
                    value={this.state.remarks}
                  required={true}
                  placeholder="Remarks"
                  maxlength="15"
                />

                {/* <FormComponent
                  labelClass="bold label-sm"
                  label="Remarks"
                  type="textarea"
                  name="remarks"
                  change={this.onChange}
                  value={this.state.remarks}
                  required={true}
                  placeholder="Remarks"
                /> */}
              </Col>
            </Row>
            <div className="center">
              <button
                type="submit"
                className="btn center login-button white-text"
                // disabled={this.state.buttonValue ? "disabled" : null}
              >
                Update
              </button>
            </div>
          </Form>
       
          </Col>
        )}
        {this.props.requirements.message ? (
          <Notifier message={this.props.requirements.message} />
        ) : null}
        {this.props.requirements.error ? (
          <ErrorNotifier message={this.props.requirements.error} />
        ) : null}
      </Row>
    );
  }
}

ViewJobs.propTypes = {

  updateRequirement: PropTypes.func.isRequired,
 
};

const mapStateToProps = (state) => ({
  requirements: state.RSrequirement,
});



export default connect(mapStateToProps, {
  updateRequirement
})(withRouter(ViewJobs));
