import React from "react";
// material ui
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// ----------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    padding: "1%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    minWidth: 0,
    wordWrap: "break-word",
    backgroundColor: "#fff",
    backgroundClip: "border-box",
    border: "1px solid rgba(0,0,0,.125)",
    borderRadius: ".25rem",
  },
}));

// ------------------------------------------------------
function ListContainer(props) {
  const classes = useStyles();
  return (
    <div className={props.sidebar}>
      <Container maxWidth="lg" className={classes.root}>
        {props.children}
      </Container>
    </div>
  );
}

export default ListContainer;
