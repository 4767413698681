import {
  ADD_NEW_PARTNER,
  GET_PARTNER_LIST,
  PARTNER_UPDATE,
  PARTNER_SEARCH,
  SET_MESSAGE,
  GET_ERRORS,
} from "../actions/types";
const initialState = {
  data: [],
  message: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_NEW_PARTNER:
      return {
        ...state,
        data: action.payload,
      };
    case GET_PARTNER_LIST:
      return {
        ...state,
        data: action.payload,
      };
    case PARTNER_UPDATE:
      return {
        ...state,
        data: action.payload,
      };
    case PARTNER_SEARCH:
      return {
        ...state,
        data: action.payload,
      };
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case GET_ERRORS:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
