import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Row, Col, Card } from "reactstrap";
// material ui
import { IconButton } from "@material-ui/core";
// material icons
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import FilterListIcon from "@material-ui/icons/FilterList";
// components
import EmployeeDetails from "./EmployeeDetails";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import AddUser from "./AddUser";
import SearchBar from "../../../common/searchbar/SearchBar";
import ViewUploadedPopUp from "./viewUploadedPopUp";
// redux
import {
  getEmpPersonalInfo,
  searchEmpPersonalInfo,
  userBatchUpload,
} from "../../../../redux/actions/hcm/hr/emp_management/empPersonalInfoAction";

// ----------------------------------------------------------------------------------------------------------
class Employees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: null,
      empManagementData: {},
      searchTerm: "",
      addBtn: false,
      currentPermission: {},
      message: "",
      error: "",
      setValue: "",
      showPopUp: false,
      openFilter: false,
      filterValue: "New",
    };
    this.select = this.select.bind(this);
    this.back = this.back.bind(this);
    this.search = this.search.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.getData = this.getData.bind(this);
    this.closeModel = this.closeModel.bind(this);
  }

  /*
Updating searched employee data from the redux store
*/
  componentDidUpdate(prevProps) {
    if (prevProps.employee !== this.props.employee) {
      this.setState({
        data: this.props.employees.data,
      });
    }
  }

  select(item) {
    this.setState({
      selection: item,
    });
  }

  secondSelect = (item) => {
    this.setState({
      empManagementData: item,
    });
  };

  /*
Searching the Employee
*/
  search(e) {
    e.preventDefault();
    this.setState({
      searchTerm: e.target.value,
    });
    this.onSearch(e.target.value);
  }

  /*
onSearch function for setting the search data in redux store by calling the searchAction
*/
  onSearch(value) {
    let data = {
      firstName: value,
    };
    this.props.searchEmpPersonalInfo(data);
  }

  /*
In back function called the getData function for after the update successfully it redirect to 
the employee list page with updated data
*/
  back() {
    this.setState({
      selection: false,
      empManagementData: {},
    });
    this.getData();
  }

  /*
getData function for list all the employee details in database
*/
  getData() {
    this.props.getEmpPersonalInfo();
  }

  componentDidMount() {
    for (let a in this.props.auth.data.accesses) {
      if (this.props.auth.data.accesses[a].appName === "HCM") {
        for (let m in this.props.auth.data.accesses[a].pieUserModuleAccess) {
          if (
            this.props.auth.data.accesses[a].pieUserModuleAccess[m]
              .moduleName === "Onboarding"
          ) {
            for (let s in this.props.auth.data.accesses[a].pieUserModuleAccess[
              m
            ].pieUserServiceAccess) {
              if (
                this.props.auth.data.accesses[a].pieUserModuleAccess[m]
                  .pieUserServiceAccess[s].serviceName === "New Hires"
              ) {
                this.setState({
                  currentPermission:
                    this.props.auth.data.accesses[a].pieUserModuleAccess[m]
                      .pieUserServiceAccess[s],
                });
              }
            }
          }
        }
      }
    }
    this.getData();
  }

  addBtnToggle = () => {
    this.setState({
      addBtn: !this.state.addBtn,
    });
  };

  // handleFileChange for getting the file value and uploading to the system
  handleFileChange = (e) => {
    let fd = new FormData();
    fd.append("file", e.target.files[0]);

    if (e.target.files.length > 0) {
      this.setState({
        setValue: (e.target.value = ""),
      });

      this.props.userBatchUpload(fd);
      this.setState({
        showPopUp: true,
      });
    }
  };
  
  closeModel() {
    this.setState({
      showPopUp: false,
    });
  }

  render() {
    let shrink;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");
    const { selection } = this.state;

    return (
      <div className={shrink}>
        {this.state.showPopUp && (
          <ViewUploadedPopUp
            viewData={this.props.empPersonalInfo}
            closeModel={this.closeModel}
            popUp={this.state.showPopUp}
          />
        )}

        {!this.state.addBtn ? (
          <Card className="container-card m-2">
            {Object.keys(this.state.currentPermission).length > 0 &&
              this.state.currentPermission.creation && (
                <div className="dive">
                  {!selection ? (
                    <button
                      className="btn add-button white-text label-sm"
                      style={{ position: "relative", top: "0px", left: "0px" }}
                      onClick={this.addBtnToggle}
                    >
                      New
                    </button>
                  ) : null}
                </div>
              )}
            {!selection && (
              <div className="hcm-onboarding-search-filter">
                <SearchBar
                  onChange={this.search}
                  value={this.state.searchTerm}
                  placeholder="Search for Onboarding"
                  onSubmit={this.onSearch}
                  title="Search User"
                />
                <div className="hcm-onboarding-search-filter-icon">
                  <IconButton
                    aria-label="FILTER"
                    title="Filter"
                    onClick={() =>
                      this.setState({ openFilter: !this.state.openFilter })
                    }
                  >
                    <FilterListIcon className="icon-color" />
                  </IconButton>
                  <ul
                    className={
                      this.state.openFilter
                        ? "display-filter"
                        : "not-display-filter"
                    }
                  >
                    <li
                      onClick={() =>
                        this.setState({ filterValue: "New", openFilter: false })
                      }
                    >
                      New
                    </li>
                    <li
                      onClick={() =>
                        this.setState({
                          filterValue: "Re-Hire",
                          openFilter: false,
                        })
                      }
                    >
                      Re-Hire
                    </li>
                    <li
                      onClick={() =>
                        this.setState({
                          filterValue: "Active",
                          openFilter: false,
                        })
                      }
                    >
                      Active
                    </li>
                    <li
                      onClick={() =>
                        this.setState({
                          filterValue: "Inactive",
                          openFilter: false,
                        })
                      }
                    >
                      In-Active
                    </li>
                  </ul>
                </div>
              </div>
            )}
            {!selection && (
              <>
                <input
                  style={{ display: "none" }}
                  id="icon-button-file"
                  type="file"
                  name="file"
                  // value={this.state.setValue}
                  onChange={this.handleFileChange}
                />
                <label htmlFor="icon-button-file">
                  <IconButton
                    aria-label="upload"
                    title="Upload"
                    className="user-onboarding-icon-btn"
                    component="span"
                  >
                    <CloudUploadOutlinedIcon />
                  </IconButton>
                </label>
              </>
            )}
            <Row>
              {!selection ? (
                this.props.empPersonalInfo.data.length > 0 &&
                this.props.empPersonalInfo.data
                  .filter(
                    (personal) =>
                      personal.personalStatus === this.state.filterValue
                  )
                  .map((item) => {
                    return (
                      <Col
                        md="4"
                        key={item.id}
                        onClick={() => this.select(item)}
                        className="employee-list-col mt-2"
                      >
                        <Card className="p-2 mb-2 shadow pointer">
                          <ul className="CustDetails-ul employee-list-ul">
                            <li>
                              <span className="label-sm bold emp-span-label">
                                Name
                              </span>
                              <span className=" label-sm">
                                &nbsp;&nbsp;:&nbsp;&nbsp;
                                <span className="employee-list-span-data">{`${item.firstName} ${item.lastName}`}</span>
                              </span>
                            </li>
                            <li>
                              <span className="label-sm bold emp-span-label">
                                Mobile No.
                              </span>
                              <span className=" label-sm">
                                &nbsp;&nbsp;:&nbsp;&nbsp;
                                <span className="employee-list-span-data">
                                  {`${item.dialingCode}-${item.mobileNumber}`}
                                </span>
                              </span>
                            </li>
                            <li>
                              <span className="label-sm bold emp-span-label">
                                Email Id
                              </span>
                              <span className=" label-sm">
                                &nbsp;&nbsp;:&nbsp;&nbsp;
                                <span className="employee-list-span-data">
                                  {item.personalEmailId}
                                </span>
                              </span>
                            </li>
                          </ul>
                        </Card>
                      </Col>
                    );
                  })
              ) : (
                <EmployeeDetails
                  employee={selection}
                  back={this.back}
                  permission={this.state.currentPermission}
                />
              )}
            </Row>
          </Card>
        ) : (
          <AddUser back={this.addBtnToggle} />
        )}
        {this.state.message ? <Notifier message={this.state.message} /> : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
        {this.props.employees.message ? (
          <Notifier message={this.props.employees.message} />
        ) : null}
        {this.props.employees.error ? (
          <ErrorNotifier message={this.props.employees.error} />
        ) : null}
      </div>
    );
  }
}

Employees.propTypes = {
  auth: PropTypes.object.isRequired,
  getEmpPersonalInfo: PropTypes.func.isRequired,
  employees: PropTypes.object.isRequired,
  searchEmployee: PropTypes.func.isRequired,
  empPersonalInfo: PropTypes.object.isRequired,
  searchEmpPersonalInfo: PropTypes.func.isRequired,
  userBatchUpload: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  employees: state.employees,
  empPersonalInfo: state.empPersonalInfo,
});

export default connect(mapStateToProps, {
  getEmpPersonalInfo,
  searchEmpPersonalInfo,
  userBatchUpload,
})(Employees);
