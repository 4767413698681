import React, { Component } from 'react'
import axios from 'axios'
import Notifier from '../../../aside/Notifier';
import {Modal,Button} from 'react-bootstrap'
import { TA_EMP_DETAIL, GET_HR_LEAD_RS } from "../../../../utils/routes";

import { Form, Row, Col,FormGroup,Label,FormText,Input } from "reactstrap";
import Select from 'react-select'
import FormComponent from "../../../common/FormComponent";
import {getHrLeadRs} from '../../../../redux/actions/rs/configuration/hrleadAction'
import { AddAssignHr } from '../../../../redux/actions/rs/requirements/assignedHr/assignedHr';
import{
  Validator,
  submitValidator,
  valid,
  refresh,
} from "../../../common/Validator";
import { connect } from 'react-redux';


let errorCustomer=false
// assigned Hr component
 class AssignedHr extends Component {
  constructor(props) {
    super(props);
    this.state = {
      empName: "",
      empBodyName:"",
      selectedOptionError:null,
     
      getHrData:[],
      roleOptionError:null,
      role:"",
      remarks:"",
      crucialArr: [
        { key: "Primary Lead", value: "Primary Lead" },
        { key: "Secondary Lead ", value: "Secondary Lead" },
       
      ],

      crucialArrOne:[ { key: "Recruiter", value: "Recruiter" }],
      isLead:[{key:"Yes",value:"Yes"},{key:"No",value:"No"}],
      leadValue:"Yes",
      error:"",
      message:""
     
    };
 
    this.onSubmit = this.onSubmit.bind(this);
    this.handleCustomerChange = this.handleCustomerChange.bind(this)
    this.onChange = this.onChange.bind(this)

    this.getDataPrimSec = this.getDataPrimSec.bind(this)
  }

  // onSumbit function trigering
  onSubmit(e) {
    console.log("working")
    e.preventDefault();
    submitValidator(e.target);
    if (!this.state.empBodyName) {
      this.setState({
        selectedOptionError: "This field is mandatory",
      });
      errorCustomer = true;
    } else {
      this.setState({
        selectedOptionError: "",
      });
      errorCustomer = false;
    }

    if (!this.state.role) {
      this.setState({
        roleOptionError: "This field is mandatory",
      });
      errorCustomer = true;
    } else {
      this.setState({
        roleOptionError: "",
      });
      errorCustomer = false;
    }

 let data = {
      jobRequirementId: this.props.requirementId,
      assignedTo: this.state.empBodyName,
      leadRole:this.state.role,
      remarks:this.state.remarks,
      createdBy: localStorage.getItem("id"),
    };

    let dataTwo = {
      jobRequirementId: this.props.requirementId,
      assignedTo: this.state.empBodyName,
      recruiter:this.state.role,
      remarks:this.state.remarks,
      createdBy: localStorage.getItem("id"),
    };
    if (!errorCustomer) {
    if(this.state.role === "Recruiter"){
      this.props.AddAssignHr(dataTwo,this.props.handleCloseModal)
    }
    else{
      this.props.AddAssignHr(data,this.props.handleCloseModal)
    }
     
    } else {
      this.setState({
        error: "Please enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }
  componentDidMount() {
  refresh();
    this.setState({
      error: "",
    });
  }

  async onChange(e){
  if(e.target.name === "role"){
    this.setState({
      roleOptionError:null
    })
    console.log(e.target.value)
    if(e.target.value === "Primary Lead" ){
    this.setState({
        role:e.target.value,
      })
      this.getDataPrimSec()
      }
      else if(e.target.value === "Secondary Lead" ){
       this.setState({
          role:e.target.value,
        })
        this.getDataPrimSec()
        }
    else if(e.target.value === "Recruiter") {
     this.getData()
      this.setState({
        role:e.target.value
      })
    }
  }
  else if(e.target.name === "leadValue"){
   this.setState({
     leadValue:"Yes"
   })
  }
  else if(e.target.name === "recValue"){
    this.setState({
      leadValue:"No"
    })
  }
  else{
    this.setState({
      [e.target.name]:e.target.value
    })
   
  }
  }


  // onchange event for hr change
  handleCustomerChange(empName) {
    console.log(empName)
    this.setState({
      empBodyName: empName.label,
      empNo:empName.value
    });
    if (empName) {
      this.setState({ empName, selectedOptionError: null });
    } else {
      this.setState({
        selectedOptionError: "This field is mandatory",
      });
    }
  }

  // geting Hrdata from employee

 

  getData() {
    axios
      .get(`${TA_EMP_DETAIL}`, { withCredentials: true })
      .then((res) => {
        console.log(res);
        this.setState({
          getHrData: res.data,
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  getDataPrimSec(){
    axios
    .get(`${GET_HR_LEAD_RS}/select`, { withCredentials: true })
    .then((res) => {
      console.log(res);
      this.setState({
        getHrData: res.data,
      });
    })
    .catch((err) => {
      console.log("err", err);
    });
  }
  render() {
   console.log(this.props.HrNotice)

    let options3 = [];
    if(this.state.role === "Recruiter"){
      for (let i = 0; i < this.state.getHrData.length; i++) {
        var x1 = {};
        x1["value"] = this.state.getHrData[i].empNo;
        x1[
          "label"
        ] = `${this.state.getHrData[i].firstName}-${this.state.getHrData[i].empNo}`
  
        options3.push(x1);
      }
    }
    else if(this.state.role === "Primary Lead" || this.state.role === "Secondary Lead" ) {
      
      for (let i = 0; i < this.state.getHrData.length; i++) {
        var x1 = {};
        x1["value"] = this.state.getHrData[i].empNo;
        x1[
          "label"
        ] = `${this.state.getHrData[i].empName}`
  
        options3.push(x1);
      }
    }
    
    return (
      <div>
        <Modal
          size="lg"
          show={this.props.showPopUp}
          onHide={this.props.handleCloseModal}
        >
          <i
            data-toggle="tooltip"
            data-placement="top"
            title="Close"
            onClick={this.props.handleCloseModal}
            className="material-icons pointer cancel-button"
          >
            cancel
          </i>

          {/* <Form onSubmit={this.handleSubmitData} className="p-3 mt-2" noValidate> */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "1rem",
            }}
          >
            <Modal.Title  style={{fontSize:"1.25rem",fontWeight:400}}>Assign Recruiter</Modal.Title>
          </div>

          <div style={{ paddingLeft: "8rem" }}>
            <ul className="CustDetails-ul">
              <li style={{ marginTop: "1rem" }}>
    <div style={{ display: "flex" }}>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      width: "9rem",
                     
                    }}
                    className="label-sm bold customer-span-label-col-1"
                  >
                    Lead Recruiter
                  </span>
               
              <FormGroup>
              
                <Input
                //  style={{marginLeft:"6rem"}}
                 type="radio"
                 checked={this.state.leadValue === "Yes"}
                  name="leadValue"
                  style={{cursor:"pointer"}}
                  value={this.state.leadValue}
                  onChange={this.onChange}
               >
                 </Input>
                 </FormGroup>
                   <span
                    style={{
                      marginLeft:"3rem",
                      fontSize: "14px",
                      fontWeight: "500",
                      width: "7rem",
                    }}
                    className="label-sm bold customer-span-label-col-1"
                  >
                    Recruiter
                  </span>
                 
              <FormGroup>
              <Input
                  style={{cursor:"pointer"}}
                  type="radio"
                  name="recValue"
                  checked={this.state.leadValue === "No"}
                  value={this.state.leadValue}
                  onChange={this.onChange}
               >
                </Input>
              </FormGroup>
             
</div>
               
             
              <div style={{ display: "flex",marginTop:"2rem" }}>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      width: "150px",
                    }}
                    className="label-sm bold customer-span-label-col-1"
                  >
                    Role
                  </span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "50%",
                    }}
                  >
              <FormGroup>
              
                <Input
                  tabIndex="10"
                  type="select"
                  name="role"
                  className="form-control-sm"
                  value={this.state.role}
                  onChange={this.onChange}
               
                  // required={true}
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {  this.state.leadValue === "Yes" ?  this.state.crucialArr.map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {item.value}
                      </option>
                    );
                  }): this.state.leadValue === "No" && this.state.crucialArrOne.map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {item.value}
                      </option>
                    );
                  })}
                </Input>
              
              </FormGroup>
              {this.state.roleOptionError && (
                      <span style={{color:"red",fontSize:"12px"}}>
                        {this.state.roleOptionError}
                      </span>
                    )}
                  </div>
                </div>
              
                <div style={{ display: "flex",marginTop:"1rem" }}>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      width: "150px",
                    }}
                    className="label-sm bold customer-span-label-col-1"
                  >
                    Recruiter Name
                  </span>
                  <div
                    style={{
                     
                      display: "flex",
                      flexDirection: "column",
                      width: "50%",
                    }}
                  >
                    <FormGroup style={{ width: "100%" }}>
                      <Select
                        tabIndex="1"
                        value={this.state.empName}
                        onChange={this.handleCustomerChange}
                        options={options3}
                        required
                        required
                      />
                    </FormGroup>
                    {this.state.selectedOptionError && (
                      <span style={{color:"red",fontSize:"12px"}}>
                        {this.state.selectedOptionError}
                      </span>
                    )}
                   
                  </div>
                </div>
               
                <div style={{ display: "flex",marginTop:"1rem" }}>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      width: "150px",
                    }}
                    className="label-sm bold customer-span-label-col-1"
                  >
                    Remarks
                  </span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "50%",
                    }}
                  >
              <FormComponent
                index="16"
               type="text"
                name="remarks"
                change={this.onChange}
                value={this.state.remarks}
               
              
              />
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "1rem",
            }}
          >
            <Button onClick={this.onSubmit} className="btn login-button white-text" >Submit</Button>
          </div>
          {/* </Form> */}
        </Modal>
        {this.state.message && <Notifier message={this.state.message} />}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  HrNotice: state.HrNotice,
});


export default connect(mapStateToProps,{
  getHrLeadRs,
  AddAssignHr
})(AssignedHr)





