import axios from "axios";
import { CUSTOMER_TYPE_GET } from "../../types";
import {
  CURD_CUSTOMER_TYPE,
  USER_CUSTOMER_TYPE,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addCustomerType = (data, back) => (dispatch) => {
  axios
    .post(CURD_CUSTOMER_TYPE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getCustomerType));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getCustomerType = () => (dispatch) => {
  axios
    .get(CURD_CUSTOMER_TYPE, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CUSTOMER_TYPE_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserCustomerType = () => (dispatch) => {
  axios
    .get(USER_CUSTOMER_TYPE, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CUSTOMER_TYPE_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateCustomerType = (data, back) => (dispatch) => {
  axios
    .put(CURD_CUSTOMER_TYPE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteCustomerType = (data) => (dispatch) => {
  axios
    .delete(CURD_CUSTOMER_TYPE, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getCustomerType));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
