import axios from "axios";
import { SERVICE_ACCESS_PERMISSION_GET } from "../../../types";
import {
  GET_SERVICE_ACCESS_PERMISSION,
  UPDATE_SERVICE_ACCESS_PERMISSION,
} from "../../../../../utils/routes";
import setSuccessMessage from "../../../common/setSuccessMessage";
import setErrorMessage from "../../../common/setErrorMessage";
import getDispatch from "../../../common/getDispatch";

export const addServiceAccessPermission = (data, back) => (dispatch) => {
  axios
    .post(UPDATE_SERVICE_ACCESS_PERMISSION, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getServiceAccessPermission = (roleId) => (dispatch) => {
  axios
    .get(GET_SERVICE_ACCESS_PERMISSION, {
      withCredentials: true,
    })
    .then((res) => {
      dispatch(getDispatch(SERVICE_ACCESS_PERMISSION_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const fetchServiceAccessPermission = (roleCode) => (dispatch) => {
  axios
    .get(GET_SERVICE_ACCESS_PERMISSION, {
      params: { role: roleCode },
      withCredentials: true,
    })
    .then((res) => {
      dispatch(getDispatch(SERVICE_ACCESS_PERMISSION_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateServiceAccessPermission = (data, back) => (dispatch) => {
  axios
    .put(UPDATE_SERVICE_ACCESS_PERMISSION, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteServiceAccessPermission = (data) => (dispatch) => {
  axios
    .delete(UPDATE_SERVICE_ACCESS_PERMISSION, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
