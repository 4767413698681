import React, { Component } from "react";
// components
import CustomizedDialogs from "../../../../common/customized-dialog/CustomizedDialogs";
import ResumeViewer from "../../../../common/ResumeViewer";

// --------------------------------------------------------------------------------------

export default class ViewDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: false,
    };
  }

  // openView function to open the document
  openView = (file) => {
    this.setState({
      view: true,
    });
  };

  // toggleView function to toggle the document view
  toggleView = () => {
    this.setState({
      view: !this.state.view,
    });
  };

  render() {
    return (
      <div>
        <CustomizedDialogs
          open={this.state.view}
          handleClose={this.toggleView}
          title={this.props.documentName}
          size={this.props.size && this.props.size}
        >
          <ResumeViewer URL={this.props.documentPath} />
        </CustomizedDialogs>
        <a
          onClick={() => this.openView()}
          className="bold label-sm p-2"
          style={{ cursor: "pointer" }}
        >
          {this.props.documentName}
        </a>
      </div>
    );
  }
}
