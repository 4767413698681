import React, { Component } from "react";
import { Container, Table, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// components
import AddApplication from "./applications/AddApplication";
import AddModule from "./modules/AddModule";
import AddService from "./services/AddService";
import UpdateApplication from "./applications/UpdateApplication";
import UpdateModule from "./modules/UpdateModule";
import UpdateService from "./services/UpdateService";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import ConfirmDialog from "../../../../common/confirm-dialog/ConfirmDialog";
// redux
import {
  getApplication,
  deleteApplication,
} from "../../../../../redux/actions/iam/auth_configuration/menu_management/applicationAction";
import {
  getModule,
  deleteModule,
} from "../../../../../redux/actions/iam/auth_configuration/menu_management/moduleAction";
import {
  getService,
  deleteService,
} from "../../../../../redux/actions/iam/auth_configuration/menu_management/serviceAction";

// ---------------------------------------------------------------------------------------------------
class MenuManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appAddBtn: false,
      moduleAddBtn: false,
      serviceAddBtn: false,
      appUpdateBtn: false,
      moduleUpdateBtn: false,
      serviceUpdateBtn: false,
      appUpdateData: [],
      moduleUpdateData: [],
      serviceUpdateData: [],
      confirmDialog: {
        isOpen: false,
        title: "",
        subTitle: "",
      },
      error: "",
      message: "",
    };

    this.toggleAppAddBtn = this.toggleAppAddBtn.bind(this);
    this.toggleModuleAddBtn = this.toggleModuleAddBtn.bind(this);
    this.toggleServiceAddBtn = this.toggleServiceAddBtn.bind(this);
    this.appAddBack = this.appAddBack.bind(this);
    this.moduleAddBack = this.moduleAddBack.bind(this);
    this.serviceAddBack = this.serviceAddBack.bind(this);
    this.toggleAppUpdateBtn = this.toggleAppUpdateBtn.bind(this);
    this.toggleModuleUpdateBtn = this.toggleModuleUpdateBtn.bind(this);
    this.toggleServiceUpdateBtn = this.toggleServiceUpdateBtn.bind(this);
    this.appUpdateBack = this.appUpdateBack.bind(this);
    this.moduleUpdateBack = this.moduleUpdateBack.bind(this);
    this.serviceUpdateBack = this.serviceUpdateBack.bind(this);
    this.getAppData = this.getAppData.bind(this);
    this.getModuleData = this.getModuleData.bind(this);
    this.getServiceData = this.getServiceData.bind(this);
    this.deleteApp = this.deleteApp.bind(this);
    this.deleteModule = this.deleteModule.bind(this);
    this.deleteService = this.deleteService.bind(this);
  }

  /*
toggleAppAddBtn function to toggle the addition page
*/
  toggleAppAddBtn() {
    this.setState({
      appAddBtn: true,
    });
  }

  toggleModuleAddBtn() {
    this.setState({
      moduleAddBtn: true,
    });
  }

  toggleServiceAddBtn() {
    this.setState({
      serviceAddBtn: true,
    });
  }

  /*
addBack function to back to the list page from the add page
*/
  appAddBack() {
    this.setState({
      appAddBtn: false,
    });

    this.getAppData();
  }

  moduleAddBack() {
    this.setState({
      moduleAddBtn: false,
    });

    this.getModuleData();
  }

  serviceAddBack() {
    this.setState({
      serviceAddBtn: false,
    });

    this.getServiceData();
  }

  /*
toggleUpdateBtn function to toggle the update form
*/
  toggleAppUpdateBtn(item) {
    this.setState({
      appUpdateBtn: true,
      appUpdateData: item,
    });
  }

  toggleModuleUpdateBtn(item) {
    this.setState({
      moduleUpdateBtn: true,
      moduleUpdateData: item,
    });
  }

  toggleServiceUpdateBtn(item) {
    this.setState({
      serviceUpdateBtn: true,
      serviceUpdateData: item,
    });
  }

  /*
updateBack function to redirect to back main page
*/
  appUpdateBack() {
    this.setState({
      appUpdateBtn: false,
    });

    this.getAppData();
  }

  moduleUpdateBack() {
    this.setState({
      moduleUpdateBtn: false,
    });

    this.getModuleData();
  }

  serviceUpdateBack() {
    this.setState({
      serviceUpdateBtn: false,
    });

    this.getServiceData();
  }

  componentDidMount() {
    this.getAppData();
    this.getModuleData();
    this.getServiceData();
  }

  getAppData() {
    this.props.getApplication();
  }

  getModuleData() {
    this.props.getModule();
  }

  getServiceData() {
    this.props.getService();
  }

  deleteApp(id, groupId) {
    let data = {
      id: id,
      appGroupId: groupId,
    };
    this.props.deleteApplication(data);
    this.setState((prevState) => ({
      ...prevState.confirmDialog,
      confirmDialog: {
        isOpen: false,
      },
    }));
  }

  deleteModule(id, moduleGroupId) {
    let data = {
      id: id,
      moduleGroupId: moduleGroupId,
    };
    this.props.deleteModule(data);
    this.setState((prevState) => ({
      ...prevState.confirmDialog,
      confirmDialog: {
        isOpen: false,
      },
    }));
  }

  deleteService(id, serviceGroupId) {
    let data = {
      id: id,
      serviceGroupId: serviceGroupId,
    };
    this.props.deleteService(data);
    this.setState((prevState) => ({
      ...prevState.confirmDialog,
      confirmDialog: {
        isOpen: false,
      },
    }));
  }

  setConfirmDialog = (obj) => {
    this.setState((prevProps) => ({
      confirmDialog: {
        ...prevProps.confirmDialog,
        isOpen: obj.isOpen,
      },
    }));
  };

  render() {
    const {
      appAddBtn,
      moduleAddBtn,
      serviceAddBtn,
      appUpdateBtn,
      moduleUpdateBtn,
      serviceUpdateBtn,
      appUpdateData,
      moduleUpdateData,
      serviceUpdateData,
      message,
      error,
    } = this.state;
    let shrink = this.props.sidebar ? "scale" : "no-scale";

    return (
      <div className={shrink}>
        <Container className="card container-card">
          {!appAddBtn &&
          !appUpdateBtn &&
          !moduleAddBtn &&
          !moduleUpdateBtn &&
          !serviceAddBtn &&
          !serviceUpdateBtn ? (
            <React.Fragment>
              <Link
                to={{
                  pathname: "/pie/iam/service_menu",
                  moduleName: this.props.location.moduleName,
                  data: this.props.location.data,
                }}
              >
                <i className="material-icons cancel-button pointer">cancel</i>
              </Link>
              <Row>
                <Col md="4">
                  {typeof this.props.location.serviceAccessData !==
                    "undefined" &&
                    this.props.location.serviceAccessData.creation && (
                      <button
                        className="btn add-button white-text label-sm"
                        onClick={this.toggleAppAddBtn}
                      >
                        Add
                      </button>
                    )}
                  <h5 className="bold center pt-2">Applications</h5>
                  <Table className="center">
                    <thead>
                      <tr>
                        <th className="opp-head ">Name</th>
                        <th className="opp-head ">GroupId</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.applications.data.length > 0 &&
                        this.props.applications.data.map((item) => {
                          return (
                            <tr>
                              <td className="opp-tableData">{item.appName}</td>
                              <td className="opp-tableData">
                                {item.appGroupId}
                              </td>
                              {typeof this.props.location.serviceAccessData !==
                                "undefined" &&
                                this.props.location.serviceAccessData
                                  .updation && (
                                  <td>
                                    <span
                                      className="material-icons pointer opp-action-edit"
                                      style={{ color: "blue" }}
                                      onClick={() =>
                                        this.toggleAppUpdateBtn(item)
                                      }
                                    >
                                      edit
                                    </span>
                                  </td>
                                )}
                              {typeof this.props.location.serviceAccessData !==
                                "undefined" &&
                                this.props.location.serviceAccessData
                                  .deletion && (
                                  <td>
                                    <i
                                      className="material-icons pointer"
                                      onClick={() =>
                                        this.setState({
                                          confirmDialog: {
                                            isOpen: true,
                                            title:
                                              "Are you sure to delete this record?",
                                            subTitle:
                                              "You can't undo this operation",
                                            onConfirm: () =>
                                              this.deleteApp(
                                                item.id,
                                                item.appGroupId
                                              ),
                                          },
                                        })
                                      }
                                    >
                                      delete
                                    </i>
                                  </td>
                                )}
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Col>
                <Col md="4">
                  {typeof this.props.location.serviceAccessData !==
                    "undefined" &&
                    this.props.location.serviceAccessData.creation && (
                      <button
                        className="btn add-button white-text label-sm"
                        onClick={this.toggleModuleAddBtn}
                      >
                        Add
                      </button>
                    )}
                  <h5 className="bold center pt-2">Modules</h5>
                  <Table className="center">
                    <thead>
                      <tr>
                        <th className="opp-head ">Name</th>
                        <th className="opp-head ">GroupId</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.modules.data.length > 0 &&
                        this.props.modules.data.map((item) => {
                          return (
                            <tr>
                              <td className="opp-tableData">
                                {item.moduleName}
                              </td>
                              <td className="opp-tableData">
                                {item.moduleGroupId}
                              </td>
                              {typeof this.props.location.serviceAccessData !==
                                "undefined" &&
                                this.props.location.serviceAccessData
                                  .updation && (
                                  <td>
                                    <span
                                      className="material-icons pointer opp-action-edit"
                                      style={{ color: "blue" }}
                                      onClick={() =>
                                        this.toggleModuleUpdateBtn(item)
                                      }
                                    >
                                      edit
                                    </span>
                                  </td>
                                )}
                              {typeof this.props.location.serviceAccessData !==
                                "undefined" &&
                                this.props.location.serviceAccessData
                                  .deletion && (
                                  <td>
                                    <i
                                      className="material-icons pointer"
                                      onClick={() =>
                                        this.setState({
                                          confirmDialog: {
                                            isOpen: true,
                                            title:
                                              "Are you sure to delete this record?",
                                            subTitle:
                                              "You can't undo this operation",
                                            onConfirm: () =>
                                              this.deleteModule(
                                                item.id,
                                                item.moduleGroupId
                                              ),
                                          },
                                        })
                                      }
                                    >
                                      delete
                                    </i>
                                  </td>
                                )}
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Col>
                <Col md="4">
                  {typeof this.props.location.serviceAccessData !==
                    "undefined" &&
                    this.props.location.serviceAccessData.creation && (
                      <button
                        className="btn add-button white-text label-sm"
                        onClick={this.toggleServiceAddBtn}
                      >
                        Add
                      </button>
                    )}
                  <h5 className="bold center pt-2">Services</h5>
                  <Table className="center">
                    <thead>
                      <tr>
                        <th className="opp-head ">Name</th>
                        <th className="opp-head ">GroupId</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.services.data.length > 0 &&
                        this.props.services.data.map((item) => {
                          return (
                            <tr>
                              <td className="opp-tableData">
                                {item.serviceName}
                              </td>
                              <td className="opp-tableData">
                                {item.serviceGroupId}
                              </td>
                              {typeof this.props.location.serviceAccessData !==
                                "undefined" &&
                                this.props.location.serviceAccessData
                                  .updation && (
                                  <td>
                                    <span
                                      className="material-icons pointer opp-action-edit"
                                      style={{ color: "blue" }}
                                      onClick={() =>
                                        this.toggleServiceUpdateBtn(item)
                                      }
                                    >
                                      edit
                                    </span>
                                  </td>
                                )}
                              {typeof this.props.location.serviceAccessData !==
                                "undefined" &&
                                this.props.location.serviceAccessData
                                  .deletion && (
                                  <td>
                                    <i
                                      className="material-icons pointer"
                                      onClick={() =>
                                        this.setState({
                                          confirmDialog: {
                                            isOpen: true,
                                            title:
                                              "Are you sure to delete this record?",
                                            subTitle:
                                              "You can't undo this operation",
                                            onConfirm: () =>
                                              this.deleteService(
                                                item.id,
                                                item.serviceGroupId
                                              ),
                                          },
                                        })
                                      }
                                    >
                                      delete
                                    </i>
                                  </td>
                                )}
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </React.Fragment>
          ) : null}
          {appAddBtn && <AddApplication back={this.appAddBack} />}
          {moduleAddBtn && (
            <AddModule
              back={this.moduleAddBack}
              menuData={this.props.applications.data}
            />
          )}
          {serviceAddBtn && (
            <AddService
              back={this.serviceAddBack}
              menuData={this.props.modules.data}
            />
          )}
          {appUpdateBtn && (
            <UpdateApplication back={this.appUpdateBack} data={appUpdateData} />
          )}
          {moduleUpdateBtn && (
            <UpdateModule
              back={this.moduleUpdateBack}
              application={this.props.applications.data}
              data={moduleUpdateData}
            />
          )}
          {serviceUpdateBtn && (
            <UpdateService
              back={this.serviceUpdateBack}
              menuData={this.props.modules.data}
              data={serviceUpdateData}
            />
          )}
          {message && <Notifier message={message} />}
          {error && <ErrorNotifier message={error} />}
          {this.props.applications.message && (
            <Notifier message={this.props.applications.message} />
          )}
          {this.props.applications.error && (
            <ErrorNotifier message={this.props.applications.error} />
          )}
          {this.props.modules.message && (
            <Notifier message={this.props.modules.message} />
          )}
          {this.props.modules.error && (
            <ErrorNotifier message={this.props.modules.error} />
          )}
          {this.props.services.message && (
            <Notifier message={this.props.services.message} />
          )}
          {this.props.services.error && (
            <ErrorNotifier message={this.props.services.error} />
          )}
        </Container>
        <ConfirmDialog
          confirmDialog={this.state.confirmDialog}
          setConfirmDialog={this.setConfirmDialog}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  applications: state.applications,
  modules: state.modules,
  services: state.services,
});

export default connect(mapStateToProps, {
  getApplication,
  deleteApplication,
  getModule,
  deleteModule,
  getService,
  deleteService,
})(MenuManagement);
