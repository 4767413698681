import React, { Component } from "react";
import { Container, Table,FormGroup,Label } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import dateFns from "date-fns";
import Select from 'react-select'
import AddSkillDetail from "./addSkillDetail";
import UpdateSkillDetail from "./updateSkillDetail";
import ConfirmDialog from "../../../../common/confirm-dialog/ConfirmDialog";
import ViewUploadedPopUp from "./viewUploadedPopUp";
import {getSkillGroup} from "../../../../../redux/actions/rs/configuration/skillgroupaction"
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import { uploadCanSkillRs } from "../../../../../redux/actions/rs/configuration/skillUploadAction";
import { IconButton } from "@material-ui/core";
import {
  getCanSkill,
  deleteCanSkill,
} from "../../../../../redux/actions/rs/configuration/canSkillsAction";

import { Link } from "react-router-dom";

class CanSkills extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addBtn: false,
      updateBtn: false,
      updateData: [],
      skillGroup: "",
      searchValue:"",
      filterSkillData:[],
      setValue:"",
      searchFilterSkillData:[],
      confirmDialog: {
        isOpen: false,
        title: "",
        subTitle: "",
      },
      showPopUp: false,
      subSkillGroup:{}
    };

    this.toggleAddBtn = this.toggleAddBtn.bind(this);
    this.addBack = this.addBack.bind(this);
    this.toggleUpdateBtn = this.toggleUpdateBtn.bind(this);
    this.updateBack = this.updateBack.bind(this);
    this.getData = this.getData.bind(this);
    this.filterInnerSkill = this.filterInnerSkill.bind(this)
    this.handleSkillGroupChange = this.handleSkillGroupChange.bind(this)
    this.searchChange = this.searchChange.bind(this)
    this.handleFileChange = this.handleFileChange.bind(this)
    this.closeModel = this.closeModel.bind(this);
    // this.deleteUserGroup = this.deleteUserGroup.bind(this);
  }

  /*
toggleAddBtn function to toggle the addition page
*/
  toggleAddBtn() {
    this.setState({
      addBtn: true,
    });
  }

  /*
addBack function to back to the list page from the add page
*/
  addBack() {
    this.setState({
      addBtn: false,
    });
    this.getData();
  }

  /*
toggleUpdateBtn function to toggle the update form
*/
  toggleUpdateBtn(item) {
    console.log(item);
    this.setState({
      updateBtn: true,
      updateData: item,
    });
  }

  /*
updateBack function to redirect to back main page
*/
  updateBack() {
    // console.log("update triggering",this.state.skillGroup)
    this.setState({
      updateBtn: false,
    });
    this.getData();
    
  }

  searchChange(e){
   if(!e.target.value){
    this.setState({
      searchFilterSkillData:[]
    })
    this.handleSkillGroupChange(this.state.skillGroup)
   }
  let value = e.target.value.toLowerCase()
  this.setState({
   searchValue:value 
  })
  let result = []
  result = this.state.filterSkillData.filter((data)=>{
    return data.skill.toLowerCase().includes(this.state.searchValue)
  })
 this.setState({
   searchFilterSkillData:result
 })

  }

  componentDidMount() {
    this.getData();
    this.props.getSkillGroup()
  }

  componentDidUpdate(prevProps,prevState){
  if(prevProps.CanConSkill.data !== this.props.CanConSkill.data){
    this.handleSkillGroupChange(this.state.skillGroup)
  }
  }

  filterInnerSkill(data){
  return data.map(arr=> `${arr.skill} ,`
  )

  }

  getData() {
    this.props.getCanSkill();
   
  }

  deleteUserGroup(id) {
    let data = {
      id: id,
    };
    this.props.deleteCanSkill(data);
    this.setState((prevState) => ({
      ...prevState.confirmDialog,
      confirmDialog: {
        isOpen: false,
      },
      uploadOpen:false
    }));
  }
  setConfirmDialog = (obj) => {
    this.setState((prevProps) => ({
      confirmDialog: {
        ...prevProps.confirmDialog,
        isOpen: obj.isOpen,
      },
    }));
}

  
  handleSkillGroupChange(skillGroup) {
    this.setState({
      searchFilterSkillData:[]
    })
    if(skillGroup) {
      this.setState({ skillGroup,
      });
    } 
  console.log(this.props.CanConSkill.data)
  this.props.CanConSkill.data.find(val =>{
  
    if(val.skillGroup===skillGroup.label){
    console.log(val.canSkill)
     this.setState({
      filterSkillData:val.canSkill
     })
     }
   })
   
    
  }

  handleFileChange = (e) => {
    let fd = new FormData();
    fd.append("file", e.target.files[0]);

    if (e.target.files.length > 0) {
      this.setState({
        setValue: (e.target.value = ""),
      });
    this.props.uploadCanSkillRs(fd);
      this.setState({
        showPopUp: true,
      });
    }
  };

  closeModel() {
    this.setState({
      showPopUp: false,
    });
  }


  render() {
    console.log(this.props.SkillUploadRsXl)
   
    let options3 = [];
    for (let i = 0; i < this.props.skillGroups.data.length; i++) {
      var x1 = {};
    
      x1[
        "label"
      ] = this.props.skillGroups.data[i].skillGroup;

      options3.push(x1);
    }

    const { addBtn, updateBtn, updateData } = this.state;

    let shrink = this.props.sidebar ? "scale" : "no-scale";

    return (
      <div className={shrink}>
         {this.state.showPopUp && (
          <ViewUploadedPopUp
            viewData={this.props.SkillUploadRsXl}
            closeModel={this.closeModel}
            popUp={this.state.showPopUp}
          />
        )}
        <Container className="card container-card">
        {!addBtn && !updateBtn ? (
        <div
            style={{
              height: "50px",
              width: "100%",
              display:"flex",
              justifyContent:"space-between",
              marginTop: "1rem",
            }}
            class="search-container"
          >
               <FormGroup style={{width:"40%",marginLeft:"3rem"}}>
                <Label style = {{fontWeight:"bold",fontSize:"0.8rem"}} className="bold label-sm">Skill Group</Label>
                <Select
               
                  tabIndex="1"
                  name="options3"
                  value={this.state.skillGroup}
                  onChange={this.handleSkillGroupChange}
                  options={options3}
                  required
                />
              </FormGroup>
            <div style={{ display: "flex", height: "90%", width: "100%",justifyContent:"flex-end",marginTop:"1rem",marginRight:"2rem"}}>

              <input
              // onKeyPress={(e) => {
              // if (e.code === "Enter") {
              // searchClick();
              // }
              // }}
                className="input-candisearch"
                value={this.state.searchValue}
                style={{ width: "30%", height: "100",background:"white" }}
                onChange={this.searchChange}
                type="text"
                placeholder="Search.."
                name="search"
              />
              <button
                // onClick={searchClick}
                style={{ border: "none", background: "#04044c", width: "60px" }}
                type="submit"
              >
                <i style={{ color: "white" }} class="fa fa-search"></i>
              </button>

              <div>
              <input
                  style={{ display: "none" }}
                  id="icon-button-file"
                  type="file"
                  name="file"
                  value={this.state.setValue}
                  onChange={this.handleFileChange}
                />
                <label htmlFor="icon-button-file">
                  <IconButton
                    size="40"
                    aria-label="upload"
                    title="Upload"
                    // className="user-onboarding-icon-btn"
                    component="span"
                  >
                    <CloudUploadOutlinedIcon />
                  </IconButton>
                </label>
              </div>


            </div>
            {/* <span style={{ fontSize: "14px", color: "red" }}>
              {changeError}
            </span>
            <span style={{ fontSize: "14px", color: "red" }}>
              {isAvailable}
            </span> */}
          </div>  ) : null}
        
          {!addBtn && !updateBtn ? (
            <React.Fragment>
              {typeof this.props.location.serviceAccessData !== "undefined" &&
                this.props.location.serviceAccessData.creation && (
                  <button
                    className="btn add-button white-text label-sm"
                    onClick={this.toggleAddBtn}
                  >
                    Add
                  </button>
                )}
              <h5 className="card-heading center pt-2">Skill Detail</h5>
              <Link
                to={{
                  pathname: "/pie/iam/service_menu",
                  moduleName: this.props.location.moduleName,
                  data: this.props.location.data,
                }}
              >
                <i className="material-icons cancel-button pointer">cancel</i>
              </Link>
           
              <Table className="center">
                <thead>
                  <tr>
                    <th className="opp-head ">ID</th>
                  
                    <th className="opp-head ">Skill</th>

                 
                    <th className="opp-head ">Created By</th>
                    <th className="opp-head">Created On</th>
                    <th className="opp-head">Updated By</th>
                    <th className="opp-head">Updated On</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                {this.state.filterSkillData&& !this.state.searchFilterSkillData.length &&
                    this.state.filterSkillData.map((item) => {
                    
                      return (
                        <tr>
                          <td style={{width:"10%"}} className="opp-tableData">{item.id}</td>
{/*                         
                          {item.canSkill.length>0 && this.filterInnerSkill(item.canSkill)} */}
                          <td style={{width:"40%"}} className="opp-tableData">{item.skill}</td>
                         
                          <td className="opp-tableData">{item.createdBy}</td>
                          <td className="opp-tableData">
                            {item.createdOn &&
                              dateFns.format(item.createdOn, "DD/MM/YYYY")}
                          </td>
                          <td className="opp-tableData">{item.updatedBy}</td>
                          <td className="opp-tableData">
                            {item.updatedOn &&
                              dateFns.format(item.updatedOn, "DD/MM/YYYY")}
                          </td>
                          {this.props.CanConSkill.data && (
                            <td>
                              {typeof this.props.location.serviceAccessData !==
                                "undefined" &&
                                this.props.location.serviceAccessData
                                  .updation && (
                                  <span
                                    className="material-icons pointer opp-action-edit"
                                    style={{ color: "blue" }}
                                    onClick={() => this.toggleUpdateBtn(item)}
                                  >
                                    edit
                                  </span>
                                )}
                            </td>
                          )}
                          {this.props.CanConSkill.data && (
                            <td>
                              {typeof this.props.location.serviceAccessData !==
                                "undefined" &&
                                this.props.location.serviceAccessData
                                  .deletion && (
                                  <i
                                    className="material-icons pointer"
                                    onClick={() =>
                                      this.setState({
                                        confirmDialog: {
                                          isOpen: true,
                                         
                                          onConfirm: () =>
                                          this.deleteUserGroup(item.id),
                                        },
                                      })
                                    }
                                  >
                                    delete
                                  </i>
                                )}
                            </td>
                          )}
                        </tr>
                      );
                    })}
              

                  {this.state.searchFilterSkillData.length>0&&
                    this.state.searchFilterSkillData.map((item) => {
                    
                      return (
                        <tr>
                          <td style={{width:"10%"}} className="opp-tableData">{item.id}</td>
{/*                         
                          {item.canSkill.length>0 && this.filterInnerSkill(item.canSkill)} */}
                          <td style={{width:"40%"}} className="opp-tableData">{item.skill}</td>
                         
                          <td className="opp-tableData">{item.createdBy}</td>
                          <td className="opp-tableData">
                            {item.createdOn &&
                              dateFns.format(item.createdOn, "DD/MM/YYYY")}
                          </td>
                          <td className="opp-tableData"></td>
                          <td className="opp-tableData">
                            {item.updatedOn &&
                              dateFns.format(item.updatedOn, "DD/MM/YYYY")}
                          </td>
                          {this.props.CanConSkill.data && (
                            <td>
                              {typeof this.props.location.serviceAccessData !==
                                "undefined" &&
                                this.props.location.serviceAccessData
                                  .updation && (
                                  <span
                                    className="material-icons pointer opp-action-edit"
                                    style={{ color: "blue" }}
                                    onClick={() => this.toggleUpdateBtn(item)}
                                  >
                                    edit
                                  </span>
                                )}
                            </td>
                          )}
                          {this.props.CanConSkill.data && (
                            <td>
                              {typeof this.props.location.serviceAccessData !==
                                "undefined" &&
                                this.props.location.serviceAccessData
                                  .deletion && (
                                  <i
                                    className="material-icons pointer"
                                    onClick={() =>
                                      this.setState({
                                        confirmDialog: {
                                          isOpen: true,
                                         
                                          onConfirm: () =>
                                          this.deleteUserGroup(item.id),
                                        },
                                      })
                                    }
                                  >
                                    delete
                                  </i>
                                )}
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </React.Fragment>
          ) : null}
          {addBtn && <AddSkillDetail back={this.addBack} />}
          {updateBtn && (
            <UpdateSkillDetail handleSkill={this.handleSkillGroupChange} skillGroup={this.state.skillGroup} back={this.updateBack} data={updateData} filterSkill= {this.filterInnerSkill} />
          )}
          {this.props.CanConSkill.message && (
            <Notifier message={this.props.CanConSkill.message} />
          )}
          {this.props.CanConSkill.error && (
            <ErrorNotifier message={this.props.CanConSkill.error} />
          )}
            <ConfirmDialog
          confirmDialog={this.state.confirmDialog}
          setConfirmDialog={this.setConfirmDialog}
        />
        </Container>
      </div>
    );
  }
}

CanSkills.propTypes = {
  getUserGroup: PropTypes.func.isRequired,
  deleteUserGroup: PropTypes.func.isRequired,
  // userGroups: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  billingRs: state.billingRs,
  CanConSkill: state.CanConSkill,
  skillGroups:state.skillGroups,
  SkillUploadRsXl:state.SkillUploadRsXl
});

export default connect(mapStateToProps, {
  getCanSkill,
  deleteCanSkill,
  getSkillGroup,
  uploadCanSkillRs
})(CanSkills);
