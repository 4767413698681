import axios from "axios";
import {
  PROSPECTS_TYPE_ADD,
  PROSPECTS_TYPE_GET,
  PROSPECTS_TYPE_UPDATE,
  PROSPECTS_TYPE_DELETE,
  GET_ERRORS,
} from "./types";
import {
  ADD_PROSPECTS_TYPE,
  GET_PROSPECTS_TYPE,
  UPDATE_PROSPECTS_TYPE,
  DELETE_PROSPECTS_TYPE,
} from "../../utils/routes";
import { setMessage } from "./salesActions";

export const addProspectsType = (data, back) => (dispatch) => {
  axios
    .post(ADD_PROSPECTS_TYPE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getProspectsType());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const getProspectsType = () => (dispatch) => {
  axios
    .get(GET_PROSPECTS_TYPE, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: PROSPECTS_TYPE_GET,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};

export const updateProspectsType = (data, back) => (dispatch) => {
  axios
    .post(UPDATE_PROSPECTS_TYPE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getProspectsType());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const deleteProspectsType = (data) => (dispatch) => {
  axios
    .post(DELETE_PROSPECTS_TYPE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getProspectsType());
      }, 2000);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};
