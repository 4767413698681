import axios from "axios";
import { GET_HIRING_STAGE_RSTYPE } from "../../types";
import {
  ADD_HIRING_STAGE_RS,
  GET_HIRING_STAGE_RS,
  UPDATE_HIRING_STAGE_RS,
  DELETE_HIRING_STAGE_RS
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addHiringStage = (data, back) => (dispatch) => {
  axios
    .post(  ADD_HIRING_STAGE_RS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getHiringStage));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getHiringStage = () => (dispatch) => {
  axios
    .get( GET_HIRING_STAGE_RS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(GET_HIRING_STAGE_RSTYPE , res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateHiringStage = (data, back) => (dispatch) => {
  axios
    .put(UPDATE_HIRING_STAGE_RS , data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteHiringStage = (data) => (dispatch) => {
  axios
    .delete( DELETE_HIRING_STAGE_RS, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getHiringStage));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
