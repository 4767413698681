import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
// components
import CustomerDetails from "./CustomerDetails";
import Hover from "../../../aside/Hover";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import AddCustomer from "./AddCustomer";
import SearchBar from "../../../common/searchbar/SearchBar";
import CustTooltip from "../../../common/tooltip/CustTooltip";
// redux
import {
  getCustomers,
  updateCustomers,
  searchCustomers,
} from "../../../../redux/actions/crm/customers/customersAction";
// import Searchbar from "../../../common/Searchbar";

// -----------------------------------------------------------------------------------------------
class Customers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      selection: null,
      selected: {},
      searchTerm: "",
      hover: false,
      cords: {},
      mouse: {},
      addBtn: false,
      currentPermission: {},
      message: "",
      error: "",
    };
    this.getData = this.getData.bind(this);
    this.select = this.select.bind(this);
    this.search = this.search.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.back = this.back.bind(this);
    this.toggleHoverOn = this.toggleHoverOn.bind(this);
    this.toggleHoverOff = this.toggleHoverOff.bind(this);
    this.toggleAddBtn = this.toggleAddBtn.bind(this);
    this.addBack = this.addBack.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.customers !== this.props.customers) {
      this.setState({
        data: this.props.customers.data,
      });
    }
  }

  select(item) {
    this.setState({
      selection: item,
      selected: item.id,
      hover: false,
    });
  }

  search(e) {
    e.preventDefault();
    this.setState({
      searchTerm: e.target.value,
    });
    this.onSearch(e.target.value);
  }

  onSearch(value) {
    let data = {
      customerName: value,
    };

    this.props.searchCustomers(data);
  }

  toggleHoverOn(e, item) {
    this.setState({
      hover: true,
      cords: {
        one: item.contactNo,
        two: item.comments,
      },
      mouse: {
        x: e.screenX,
        y: e.screenY,
      },
    });
  }

  toggleHoverOff() {
    this.setState({
      hover: false,
    });
  }

  back() {
    this.setState({
      selection: false,
    });
  }

  getData() {
    this.props.getCustomers();
  }

  componentDidMount() {
    for (let a in this.props.auth.data.accesses) {
      if (this.props.auth.data.accesses[a].appName === "CRM") {
        for (let m in this.props.auth.data.accesses[a].pieUserModuleAccess) {
          if (
            this.props.auth.data.accesses[a].pieUserModuleAccess[m]
              .moduleName === "Customers"
          ) {
            for (let s in this.props.auth.data.accesses[a].pieUserModuleAccess[
              m
            ].pieUserServiceAccess) {
              if (
                this.props.auth.data.accesses[a].pieUserModuleAccess[m]
                  .pieUserServiceAccess[s].serviceName === "Customer Management"
              ) {
                this.setState({
                  currentPermission:
                    this.props.auth.data.accesses[a].pieUserModuleAccess[m]
                      .pieUserServiceAccess[s],
                });
              }
            }
          }
        }
      }
    }
    this.getData();
  }

  /*
  toggleAddBtn function to toggle the addition page
  */
  toggleAddBtn() {
    this.setState({
      addBtn: true,
    });
  }

  /*
  addBack function to back to the list page from the add page
  */
  addBack() {
    this.setState({
      addBtn: false,
    });

    this.getData();
  }

  render() {
    let shrink;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");
    const { selection, selected, data, message, error, addBtn } = this.state;

    return (
      <div className={shrink}>
        <Container className="card container-card">
          {this.state.hover && (
            <Hover
              labels={{ one: "Contact No.", two: "Comments" }}
              cords={this.state.cords}
              mouse={this.state.mouse}
            />
          )}
          {Object.keys(this.state.currentPermission).length > 0 &&
            this.state.currentPermission.creation && (
              <div className="dive">
                {!selection && (
                  <Link to="/pie/crm/add/customer">
                    <CustTooltip title="Create Customer">
                      <button
                        className="btn new-btn white-text label-sm"
                        onClick={this.toggleAddBtn}
                      >
                        New
                      </button>
                    </CustTooltip>
                  </Link>
                )}
              </div>
            )}
          {!selection && (
            <SearchBar
              onChange={this.search}
              value={this.state.searchTerm}
              placeholder="Search for Customers"
              onSubmit={this.onSearch}
              title="Search Customer"
            />
          )}
          {!selection ? (
            <Row className="pt-3">
              <Col md="12" className="p-0">
                <ul className="crm-flex-around  ul-head">
                  <li className="list-label custId-width">Cust. Id</li>
                  <li className="list-label custName-width">Customer Name</li>
                  <li className="list-label custType-width">Customer Type</li>
                  <li className="list-label custDomain-width">Domain</li>
                  <li className="list-label custContact-width">Contact Name</li>
                  <li className="list-label custStatus-width">Status</li>
                </ul>
              </Col>
            </Row>
          ) : null}
          <Row onMouseMove={this.onMouseMove}>
            {!selection && !addBtn
              ? data &&
                data.map((item) => {
                  return (
                    <Col md="12" key={item.id} className="p-0">
                      <ul
                        onClick={() => {
                          this.select(item);
                        }}
                        // onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                        // onMouseLeave={this.toggleHoverOff}
                        className={`list crm-flex-around pointer ${
                          selected && selected === item.id && "selected-item"
                        } InqList`}
                      >
                        <li className="list-data custId-width">
                          {item.customerNo}
                        </li>
                        <li className="list-data custName-width">
                          {item.customerName}
                        </li>
                        <li className="list-data custType-width">
                          {item.customerType}
                        </li>
                        <li className="list-data custDomain-width">
                          {item.businessDomain}
                        </li>
                        <li className="list-data custContact-width">
                          {item.contactName}
                        </li>
                        <li className="list-data custStatus-width">
                          {item.customerStatus}
                        </li>
                      </ul>
                    </Col>
                  );
                })
              : null}
          </Row>
          {selection && (
            <CustomerDetails
              customer={this.state.selection}
              back={this.back}
              permission={this.state.currentPermission}
            />
          )}
          {addBtn && <AddCustomer back={this.addBack} />}
          {message && <Notifier message={message} />}
          {error && <ErrorNotifier message={error} />}
        </Container>
      </div>
    );
  }
}

Customers.propTypes = {
  auth: PropTypes.object.isRequired,
  getCustomers: PropTypes.func.isRequired,
  updateCustomers: PropTypes.func.isRequired,
  searchCustomers: PropTypes.func.isRequired,
  customers: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  customers: state.customers,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getCustomers,
  updateCustomers,
  searchCustomers,
})(Customers);
