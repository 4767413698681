import React from "react";
import { Container, Row, Col } from "reactstrap";
import DateFns from "date-fns";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// components
import ErrorNotifier from "../../../../aside/ErrorNotifier";

class PersonalDetails extends React.Component {
  /*
fetch the employee details 
*/

  render() {
    let empType;
    if (this.props.auth.data && this.props.auth.data.pieUser.empType === "E") {
      empType = "Employee";
    } else if (
      this.props.auth.data &&
      this.props.auth.data.pieUser.empType === "C"
    ) {
      empType = "Contigent";
    } else {
      empType = "Trainee";
    }

    return (
      <Container className="card container-card personalDetails-div-1">
        <h5 className="bold mb-4 pt-2 personal-head">Personal Details</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <div className="personal-details-div">
          <Row>
            <ul>
              <li>
                <span className="label-sm bold personal-span-col-1">
                  Emp Number
                </span>
                <span className="label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  {this.props.auth.data.pieUser &&
                    this.props.auth.data.pieUser.empNo}
                </span>
              </li>
              <li>
                <span className="label-sm bold personal-span-col-1">
                  First Name
                </span>
                <span className="label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  {this.props.auth.data.pieUser &&
                    this.props.auth.data.pieUser.firstName}
                </span>
              </li>
              <li>
                <span className="label-sm bold personal-span-col-1">
                  Last Name
                </span>
                <span className="label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  {this.props.auth.data.pieUser &&
                    this.props.auth.data.pieUser.lastName}
                </span>
              </li>
              <li>
                <span className="label-sm bold personal-span-col-1">
                  Geography
                </span>
                <span className="label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  {this.props.auth.data.pieUser &&
                    this.props.auth.data.pieUser.geoName}
                </span>
              </li>
              <li>
                <span className="label-sm bold personal-span-col-1">
                  Country
                </span>
                <span className="label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  {this.props.auth.data.pieUser &&
                    this.props.auth.data.pieUser.countryName}
                </span>
              </li>
              <li>
                <span className="label-sm bold personal-span-col-1">
                  Mobile Number
                </span>
                <span className="label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  {this.props.auth.data.pieUser &&
                    this.props.auth.data.pieUser.mobileNumber}
                </span>
              </li>
              <li>
                <span className="label-sm bold personal-span-col-1">
                  Personal Email ID
                </span>
                <span className="label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  {this.props.auth.data.pieUser &&
                    this.props.auth.data.pieUser.personalEmailId}
                </span>
              </li>
              <li>
                <span className="label-sm bold personal-span-col-1">
                  Email ID
                </span>
                <span className="label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  {this.props.auth.data.pieUser &&
                    this.props.auth.data.pieUser.organizationEmailId}
                </span>
              </li>
              <li>
                <span className="label-sm bold personal-span-col-1">
                  Emp Position
                </span>
                <span className="label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  {this.props.auth.data.pieUser &&
                    this.props.auth.data.pieUser.empPosition}
                </span>
              </li>
              <li>
                <span className="label-sm bold personal-span-col-1">
                  Emp Status
                </span>
                <span className="label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  {this.props.auth.data.pieUser &&
                    this.props.auth.data.pieUser.empStatus}
                </span>
              </li>
            </ul>
          </Row>
        </div>

        {this.props.auth.data.error ? (
          <ErrorNotifier message={this.props.auth.data.error} />
        ) : null}
      </Container>
    );
  }
}

PersonalDetails.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(PersonalDetails);
