import axios from "axios";
import { EMP_GRADE_GET } from "../../types";
import { CURD_EMP_GRADE, USER_EMP_GRADE } from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addEmpGrade = (data, back) => (dispatch) => {
  axios
    .post(CURD_EMP_GRADE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getEmpGrade));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getEmpGrade = () => (dispatch) => {
  axios
    .get(CURD_EMP_GRADE, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(EMP_GRADE_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserEmpGrade = () => (dispatch) => {
  axios
    .get(USER_EMP_GRADE, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(EMP_GRADE_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateEmpGrade = (data, back) => (dispatch) => {
  axios
    .put(CURD_EMP_GRADE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getEmpGrade));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteEmpGrade = (data) => (dispatch) => {
  axios
    .delete(CURD_EMP_GRADE, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getEmpGrade));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
