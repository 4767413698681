import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  FormText,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import DateFns from "date-fns";
// material ui
import { IconButton } from "@material-ui/core";
// icons
import LabelIcon from "@material-ui/icons/Label";
import EditIcon from "@material-ui/icons/Edit";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
// components
import AgreementUpload from "./AgreementUpload";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../common/Validator";
import FormComponent from "../../../common/FormComponent";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import CustomerOrgChart from "./CustomerOrgChart";
import { CURD_CUSTOMERS } from "../../../../utils/routes";
import DetailsPageFooter from "../../../common/details_footer/DetailsPageFooter";
import SearchSelectBoxWithAdd from "../../../common/selectbox/SearchSelectBoxWithAdd";
import CustomizedDialogs from "../../../common/customized-dialog/CustomizedDialogs";
import AddTagContact from "../common/AddTagContact";
import TagContactCard from "../common/TagContactCard";
// redux
import {
  updateCustomers,
  getCustomers,
  getUpdateCustomersSpocName,
  getCustomersSpocName,
  getCustomerTaggedContacts,
} from "../../../../redux/actions/crm/customers/customersAction";
import { getUserCustomerDomain } from "../../../../redux/actions/crm/crm_setup/customerDomainAction";
import { getUserCustomerType } from "../../../../redux/actions/crm/crm_setup/customerTypeAction";
import { getUserCustomerGeographies } from "../../../../redux/actions/crm/crm_setup/customerGeographiesAction";
import { getUserCustomerCountries } from "../../../../redux/actions/crm/crm_setup/customerCountriesAction";
import { getUserCustomerAccountStatus } from "../../../../redux/actions/crm/crm_setup/customerAccountStatusAction";

// ------------------------------------------------------------------------------------------------------------------

class CustomerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cName: "",
      domain: "",
      addr: "",
      geography: "",
      country: "",
      state: "",
      location: "",
      pincode: "",
      currency: "",
      contactId: "",
      pocName: "",
      contactEmail: "",
      dialingCode: "",
      contactNo: "",
      status: "",
      startDate: "",
      endDate: "",
      custType: "",
      comments: "",
      edit: false,
      custOrgChart: false,
      data: {},
      open: false,
      viewTaggedContacts: false,
      dateError: "",
      spocError: "",
      message: "",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.orgChartDetailsFunction = this.orgChartDetailsFunction.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.customers.message) {
      setTimeout(() => {
        this.props.back();
      }, 2000);
    }
    if (
      prevState.cName !== this.state.cName ||
      prevState.edit !== this.state.edit
    ) {
      this.props.getUpdateCustomersSpocName(this.props.customer.id);
    }
  }

  onChange(e) {
    e.preventDefault();
    Validator(e.target);
    if (e.target.name === "endDate") {
      if (Date.parse(e.target.value) < Date.parse(this.state.startDate)) {
        this.setState({
          [e.target.name]: e.target.value,
          dateError: "EndDate must be greater than or equal to StartDate",
        });
      } else {
        this.setState({
          [e.target.name]: e.target.value,
          dateError: "",
        });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  handleContactNameChange = (value) => {
    this.setState({
      contactId: value && value.contactId,
      pocName: value && value.contactName,
      contactEmail: value && value.contactEmail,
      dialingCode: value && value.contactDialingCode,
      contactNo: value && value.contactMobileNo,
    });
  };

  /*
  This function for updating the customer details
  */
  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let comp = {
      id: this.props.customer.id.toString(),
      customerName: this.state.cName.toUpperCase(),
      businessDomain: this.state.domain,
      corporateAddress: this.state.addr,
      geography: this.state.geography,
      country: this.state.country,
      state: this.state.state,
      location: this.state.location,
      pinCode: this.state.pincode,
      contactName: this.state.pocName,
      customerStatus: this.state.status,
      comments: this.state.comments,
      agreementStartDate: this.state.startDate,
      agreementEndDate: this.state.endDate,
      customerType: this.state.custType,
    };
    if (valid && !this.state.dateError && this.state.pocName) {
      this.props.updateCustomers(comp, this.props.back);
      setTimeout(() => {
        this.props.getCustomers();
      }, 2000);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
        spocError: this.state.pocName ? " " : "This field is mandatory",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
  ComponentDidMount getting the selected customer details and setting the state of customer details
  */
  componentDidMount() {
    const {
      customerName,
      businessDomain,
      corporateAddress,
      geography,
      country,
      state,
      location,
      pinCode,
      contactName,
      customerStatus,
      comments,
      agreementStartDate,
      agreementEndDate,
      customerType,
    } = this.props.customer;
    this.setState({
      cName: customerName,
      domain: businessDomain,
      addr: corporateAddress,
      geography: geography,
      country: country,
      state: state,
      location: location,
      pincode: pinCode,
      pocName: contactName,
      status: customerStatus,
      startDate: agreementStartDate,
      endDate: agreementEndDate,
      custType: customerType,
      comments: comments,
    });
    this.props.getUserCustomerDomain();
    this.props.getUserCustomerType();
    this.props.getUserCustomerGeographies();
    this.props.getUserCustomerCountries();
    this.props.getUserCustomerAccountStatus();
    this.props.getCustomersSpocName();
    refresh();
  }

  orgChartDetailsFunction() {
    axios
      .get(`${CURD_CUSTOMERS}/${this.props.customer.id}`, {
        withCredentials: true,
      })
      .then((res) => {
        this.setState({
          data: res.data,
          custOrgChart: true,
        });
      })
      .catch((err) => {
        this.setState({
          error: err.message,
        });
        setTimeout(() => {
          this.setState({
            error: "",
          });
        }, 2000);
      });
  }

  resetSpocNameError = () => {
    this.setState({
      spocError: "",
    });
  };

  // modal function for open
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  // modal function for close
  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  // modal function for view tagged contacts
  handleClickView = () => {
    this.setState({
      viewTaggedContacts: true,
    });
    this.props.getCustomerTaggedContacts(this.props.customer.id);
  };

  // modal function for close view tagged contacts
  handleCloseView = () => {
    this.setState({
      viewTaggedContacts: false,
    });
  };

  render() {
    return (
      <Row>
        {!this.state.edit ? (
          !this.state.custOrgChart ? (
            <Col md="12">
              <h5 className="bold center mb-4">
                Customer : {this.props.customer.customerNo}
              </h5>
              <button
                onClick={() => this.orgChartDetailsFunction()}
                className="btn cust-chart-button white-text"
                title="View Customer Details"
              >
                Details
              </button>
              {typeof this.props.permission !== "undefined" &&
                Object.keys(this.props.permission).length > 0 &&
                this.props.permission.updation && (
                  <IconButton
                    className="edit-button"
                    color="primary"
                    onClick={() => this.setState({ edit: !this.state.edit })}
                    title="edit customer"
                  >
                    <EditIcon />
                  </IconButton>
                )}
              <IconButton
                className="tag-button"
                color="primary"
                title="tag contact"
                onClick={this.handleClickOpen}
              >
                <LabelIcon />
              </IconButton>
              <IconButton
                className="eye-button"
                color="primary"
                title="view tagged contacts"
                onClick={this.handleClickView}
              >
                <RemoveRedEyeIcon />
              </IconButton>
              <i
                onClick={() => this.props.back()}
                className="material-icons pointer cancel-button"
              >
                cancel
              </i>
              <Row>
                <Col md="6">
                  <ul className="CustDetails-ul">
                    <li>
                      <span className="list-label customer-span-label-col-1">
                        Customer Name
                      </span>
                      <span className="list-data">
                        &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.cName}
                      </span>
                    </li>
                    <li>
                      <span className="list-label customer-span-label-col-1">
                        Business Domain
                      </span>
                      <span className="list-data">
                        &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.domain}
                      </span>
                    </li>
                    <li>
                      <span className="list-label customer-span-label-col-1">
                        Customer Type
                      </span>
                      <span className="list-data">
                        &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.custType}
                      </span>
                    </li>
                    <li>
                      <span className="list-label customer-span-label-col-1">
                        Geography
                      </span>
                      <span className="list-data">
                        &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.geography}
                      </span>
                    </li>
                    <li>
                      <span className="list-label customer-span-label-col-1">
                        Country
                      </span>
                      <span className="list-data">
                        &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.country}
                      </span>
                    </li>
                    <li>
                      <span className="list-label customer-span-label-col-1">
                        State
                      </span>
                      <span className="list-data">
                        &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.state}
                      </span>
                    </li>
                    <li>
                      <span className="list-label customer-span-label-col-1">
                        Location
                      </span>
                      <span className="list-data">
                        &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.location}
                      </span>
                    </li>
                  </ul>
                </Col>
                <Col md="6">
                  <ul className="CustDetails-ul">
                    <li>
                      <span className="list-label customer-span-label-col-2">
                        Status
                      </span>
                      <span className="list-data">
                        &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.status}
                      </span>
                    </li>
                    <li>
                      <span className="list-label customer-span-label-col-2">
                        SPOC Name
                      </span>
                      <span className="list-data">
                        &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.pocName}
                      </span>
                    </li>
                    <li>
                      <span className="list-label customer-span-label-col-2">
                        Start Date
                      </span>
                      <span className="list-data">
                        &nbsp;&nbsp;:&nbsp;&nbsp;
                        {this.state.startDate &&
                          DateFns.format(this.state.startDate, "DD-MM-YYYY")}
                      </span>
                    </li>
                    <li>
                      <span className="list-label customer-span-label-col-2">
                        End Date
                      </span>
                      <span className="list-data">
                        &nbsp;&nbsp;:&nbsp;&nbsp;
                        {this.state.endDate &&
                          DateFns.format(this.state.endDate, "DD-MM-YYYY")}
                      </span>
                    </li>
                    <li>
                      <span className="list-label customer-span-label-col-2">
                        Corporate Addr.
                      </span>
                      <span className="list-data">
                        &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.addr}
                      </span>
                    </li>
                    <li>
                      <span className="list-label customer-span-label-col-2">
                        PinCode
                      </span>
                      <span className="list-data">
                        &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.pincode}
                      </span>
                    </li>

                    <li>
                      <span className="list-label customer-span-label-col-2">
                        Comments
                      </span>
                      <span className="list-data">
                        &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.comments}
                      </span>
                    </li>
                  </ul>
                </Col>
                <Row>
                  <Col md="12">
                    <AgreementUpload comp={this.props.customer.id} />
                  </Col>
                </Row>
              </Row>
              <DetailsPageFooter
                createdBy={this.props.customer.createdBy}
                createdOn={this.props.customer.createdOn}
                updatedBy={this.props.customer.updatedBy}
                updatedOn={this.props.customer.updatedOn}
              />
            </Col>
          ) : (
            <Col md="12">
              <i
                onClick={() => this.setState({ custOrgChart: false })}
                className="material-icons pointer cancel-button"
              >
                cancel
              </i>
              <CustomerOrgChart data={this.state.data} />
            </Col>
          )
        ) : (
          <Col md="12">
            <h5 className="bold center">
              Update Customer : {this.props.customer.customerNo}
            </h5>
            <i
              onClick={() => this.setState({ edit: !this.state.edit })}
              className="material-icons pointer cancel-button"
            >
              cancel
            </i>
            <Form onSubmit={this.onSubmit} className="p-3" noValidate>
              <Row>
                <Col md="4">
                  <FormComponent
                    labelClass="bold label-sm"
                    label="Customer name"
                    name="cName"
                    type="text"
                    change={this.onChange}
                    value={this.state.cName}
                    maxlength="27"
                    required={true}
                    tabIndex="1"
                  />
                  <FormGroup>
                    <Label className="bold label-sm">Geography</Label>
                    <Input
                      type="select"
                      name="geography"
                      className="form-control-sm"
                      value={this.state.geography}
                      onChange={this.onChange}
                      placeholder="Geography"
                      required={true}
                      tabIndex="4"
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.customerGeographies.data.map((item) => {
                        return (
                          <option key={item.id} value={item.geoName}>
                            {item.geoName}
                          </option>
                        );
                      })}
                    </Input>
                    {errors.geography && (
                      <FormText className="error">{errors.geography}</FormText>
                    )}
                  </FormGroup>
                  <FormComponent
                    labelClass="bold label-sm"
                    label="Location"
                    name="location"
                    type="text"
                    change={this.onChange}
                    value={this.state.location}
                    maxlength="27"
                    required={true}
                    tabIndex="7"
                  />

                  <SearchSelectBoxWithAdd
                    options={this.props.customers.spocNameData}
                    value={this.state.pocName}
                    handleChange={(value) =>
                      this.handleContactNameChange(value)
                    }
                    reset={this.resetSpocNameError}
                    spocError={this.state.spocError}
                    label="SPOC Name"
                    tabIndex="10"
                  />
                  <FormGroup>
                    <Label className="bold label-sm">Company Status</Label>
                    <Input
                      type="select"
                      name="status"
                      className="form-control-sm"
                      value={this.state.status}
                      onChange={this.onChange}
                      placeholder="Status"
                      required={true}
                      tabIndex="13"
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.customerAccountStatus.data.map((item) => {
                        return (
                          <option key={item.id} value={item.statusName}>
                            {item.statusName}
                          </option>
                        );
                      })}
                    </Input>
                    {errors.status && (
                      <FormText className="error">{errors.status}</FormText>
                    )}
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label className="bold label-sm">Business Domain</Label>
                    <Input
                      type="select"
                      name="domain"
                      className="form-control-sm"
                      value={this.state.domain}
                      onChange={this.onChange}
                      required
                      tabIndex="2"
                    >
                      {this.props.customerDomain.data.map((item) => {
                        return (
                          <option key={item.id} value={item.domainName}>
                            {item.domainName}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label className="bold label-sm">Country</Label>
                    <Input
                      type="select"
                      name="country"
                      className="form-control-sm"
                      value={this.state.country}
                      onChange={this.onChange}
                      placeholder="Country"
                      required={true}
                      tabIndex="5"
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.customerCountries.data.map((item) => {
                        if (this.state.geography === item.geoName) {
                          return (
                            <option key={item.id} value={item.countryName}>
                              {item.countryName}
                            </option>
                          );
                        }
                      })}
                    </Input>
                    {errors.country && (
                      <FormText className="error">{errors.country}</FormText>
                    )}
                  </FormGroup>

                  <FormComponent
                    labelClass="bold label-sm"
                    label="PinCode"
                    type="number"
                    name="pincode"
                    change={this.onChange}
                    value={this.state.pincode}
                    placeholder="PinCode"
                    tabIndex="8"
                  />
                  <ul className="CustDetails-ul">
                    <li>
                      <span className="label-sm bold customer-span-label-col-1">
                        Email
                      </span>
                      <span className=" label-sm">
                        &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.contactEmail}
                      </span>
                    </li>
                    <li>
                      <span className="label-sm bold customer-span-label-col-1">
                        Mobile
                      </span>
                      <span className=" label-sm">
                        &nbsp;&nbsp;:&nbsp;&nbsp;
                        {this.state.dialingCode && `${this.state.dialingCode}-`}
                        {this.state.contactNo}
                      </span>
                    </li>
                  </ul>
                  <FormComponent
                    labelClass="bold label-sm"
                    label="Comments"
                    name="comments"
                    type="textarea"
                    change={this.onChange}
                    value={this.state.comments}
                    tabIndex="14"
                  />
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label className="bold label-sm">Customer Type</Label>
                    <Input
                      type="select"
                      name="custType"
                      value={this.state.custType}
                      onChange={this.onChange}
                      className="form-control-sm"
                      tabIndex="3"
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.customerType.data.map((item) => {
                        return (
                          <option key={item.id} value={item.custTypeName}>
                            {item.custTypeName}
                          </option>
                        );
                      })}
                    </Input>
                    {errors.custType && (
                      <FormText className="error">{errors.custType}</FormText>
                    )}
                  </FormGroup>
                  <FormComponent
                    labelClass="bold label-sm"
                    label="State"
                    type="text"
                    name="state"
                    change={this.onChange}
                    value={this.state.state}
                    placeholder="State"
                    required={true}
                    tabIndex="6"
                  />
                  <FormComponent
                    labelClass="bold label-sm"
                    label="Corporate Address"
                    name="addr"
                    type="text"
                    change={this.onChange}
                    value={this.state.addr}
                    tabIndex="9"
                  />
                  <Row>
                    <Col md="6" className="p-0">
                      <FormComponent
                        labelClass="bold label-sm"
                        label="Agreement start date"
                        name="startDate"
                        type="date"
                        change={this.onChange}
                        value={
                          this.state.startDate &&
                          DateFns.format(this.state.startDate, "YYYY-MM-DD")
                        }
                        tabIndex="11"
                      />
                    </Col>
                    <Col md="6" className="p-0">
                      <FormComponent
                        labelClass="bold label-sm"
                        label="Agreement end date"
                        name="endDate"
                        type="date"
                        change={this.onChange}
                        value={
                          this.state.endDate &&
                          DateFns.format(this.state.endDate, "YYYY-MM-DD")
                        }
                        min={this.state.startDate}
                        error={this.state.dateError}
                        tabIndex="12"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className="center">
                <button type="submit" className="btn btn-custom" tabIndex="15">
                  Submit
                </button>
              </div>
            </Form>
          </Col>
        )}
        {this.props.customers.message ? (
          <Notifier message={this.props.customers.message} />
        ) : null}
        {this.props.customers.error ? (
          <ErrorNotifier message={this.props.customers.error} />
        ) : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
        <CustomizedDialogs
          open={this.state.open}
          handleClose={this.handleClose}
          title="Tag Contact"
        >
          <AddTagContact
            back={this.handleClose}
            customerName={this.props.customer.id}
            spocNameData={this.props.customers.spocNameData}
            getData={this.props.getCustomersSpocName}
            name="customer"
          />
        </CustomizedDialogs>

        <Modal size="xl" isOpen={this.state.viewTaggedContacts}>
          <ModalHeader toggle={this.handleCloseView}>
            View Tag Contacts
          </ModalHeader>
          <ModalBody>
            <TagContactCard
              data={this.props.customers.taggedContactData}
              permission={this.props.permission}
              name="customer"
            />
          </ModalBody>
        </Modal>
      </Row>
    );
  }
}

CustomerDetails.propTypes = {
  customers: PropTypes.object.isRequired,
  updateCustomers: PropTypes.func.isRequired,
  getCustomersSpocName: PropTypes.func.isRequired,
  getUpdateCustomersSpocName: PropTypes.func.isRequired,
  getCustomerTaggedContacts: PropTypes.func.isRequired,
  getUserCustomerDomain: PropTypes.func.isRequired,
  customerDomain: PropTypes.object.isRequired,
  getUserCustomerType: PropTypes.func.isRequired,
  customerType: PropTypes.object.isRequired,
  getUserCustomerGeographies: PropTypes.func.isRequired,
  customerGeographies: PropTypes.object.isRequired,
  getUserCustomerCountries: PropTypes.func.isRequired,
  customerCountries: PropTypes.object.isRequired,
  getUserCustomerAccountStatus: PropTypes.func.isRequired,
  customerAccountStatus: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  customers: state.customers,
  customerDomain: state.customerDomain,
  customerType: state.customerType,
  customerGeographies: state.customerGeographies,
  customerCountries: state.customerCountries,
  customerAccountStatus: state.customerAccountStatus,
});

export default connect(mapStateToProps, {
  updateCustomers,
  getCustomers,
  getUpdateCustomersSpocName,
  getCustomerTaggedContacts,
  getCustomersSpocName,
  getUserCustomerType,
  getUserCustomerDomain,
  getUserCustomerGeographies,
  getUserCustomerCountries,
  getUserCustomerAccountStatus,
})(withRouter(CustomerDetails));
