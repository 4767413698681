import axios from "axios";
import { EDUCATION_DETAILS_GET, EDUCATION_DETAILS_FETCH } from "../../types";
import {
  CURD_EDUCATION_DETAILS,
  FETCH_EDUCATION_DETAILS,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addEducationDetails = (data, back) => (dispatch) => {
  axios
    .post(CURD_EDUCATION_DETAILS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, fetchEducationDetails));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getEducationDetails = () => (dispatch) => {
  axios
    .get(CURD_EDUCATION_DETAILS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(EDUCATION_DETAILS_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const fetchEducationDetails = () => (dispatch) => {
  axios
    .get(FETCH_EDUCATION_DETAILS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(EDUCATION_DETAILS_FETCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateEducationDetails = (data, back) => (dispatch) => {
  axios
    .put(CURD_EDUCATION_DETAILS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteEducationDetails = (data) => (dispatch) => {
  axios
    .delete(CURD_EDUCATION_DETAILS, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, fetchEducationDetails));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
