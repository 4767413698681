import React, { Component } from "react";
import { Form, Row, Col, FormGroup, Label, Input } from "reactstrap";
import PropTypes from "prop-types";
import DateFns from "date-fns";
import { connect } from "react-redux";
// components
import {
  Validator,
  submitValidator,
  valid,
  refresh,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
// redux
import { updateExitClearance } from "../../../../../redux/actions/hcm/resignation/exitClearanceAction";
import { getUserResignationStatus } from "../../../../../redux/actions/hcm/configurations/resignationStatusAction";
import { getUserClearanceStatus } from "../../../../../redux/actions/hcm/configurations/clearanceStatusAction";

class UpdateExitClearance extends Component {
  constructor(props) {
    super(props);
    const {
      empId,
      empName,
      regDate,
      lastWorkingDate,
      regReason,
      regStatus,
      adminClearanceStatus,
      assetClearanceStatus,
      managerClearanceStatus,
      financeClearanceStatus,
      hrClearanceStatus,
    } = this.props.data;
    this.state = {
      empId: empId,
      empName: empName,
      regDate: DateFns.format(regDate, "YYYY-MM-DD"),
      lastWorkingDate: DateFns.format(lastWorkingDate, "YYYY-MM-DD"),
      regReason: regReason,
      regStatus: regStatus,
      adminClearanceStatus: adminClearanceStatus,
      assetClearanceStatus: assetClearanceStatus,
      managerClearanceStatus: managerClearanceStatus,
      financeClearanceStatus: financeClearanceStatus,
      hrClearanceStatus: hrClearanceStatus,
      clearanceStatus: false,
      message: "",
      error: "",
      dateError: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.clearanceValidation = this.clearanceValidation.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    if (e.target.name === "lastWorkingDate") {
      if (DateFns.format(e.target.value, "DD-MM-YYYY") < this.state.regDate) {
        this.setState({
          [e.target.name]: e.target.value,
          dateError:
            "lastWorkingDate must be greater than or equal to resignationDate",
        });
      } else {
        this.setState({
          [e.target.name]: e.target.value,
          dateError: "",
        });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    const { dateError } = this.state;
    let data = {
      id: this.props.data.id,
      empId: this.state.empId,
      empName: this.state.empName,
      regDate: this.state.regDate,
      lastWorkingDate: this.state.lastWorkingDate,
      regReason: this.state.regReason,
      regStatus: this.state.regStatus,
      adminClearanceStatus: this.state.adminClearanceStatus,
      assetClearanceStatus: this.state.assetClearanceStatus,
      financeClearanceStatus: this.state.financeClearanceStatus,
      managerClearanceStatus: this.state.managerClearanceStatus,
      hrClearanceStatus: this.state.hrClearanceStatus,
      updatedBy: sessionStorage.getItem("id"),
    };
    if (valid && !dateError) {
      this.props.updateExitClearance(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    refresh();
    this.props.getUserResignationStatus();
    this.props.getUserClearanceStatus();
    this.clearanceValidation();
    this.setState({
      message: "",
      error: "",
    });
  }

  clearanceValidation() {
    const { lastWorkingDate } = this.state;
    let currentDate = new Date();
    let lastDate = new Date(lastWorkingDate);
    let diffDay = parseInt(
      (lastDate - currentDate) / (1000 * 60 * 60 * 24),
      10
    );
    if (diffDay > 7) {
      this.setState({
        clearanceStatus: true,
      });
    } else {
      this.setState({
        clearanceStatus: false,
      });
    }
  }

  render() {
    const {
      empId,
      empName,
      regDate,
      lastWorkingDate,
      regReason,
      regStatus,
      adminClearanceStatus,
      assetClearanceStatus,
      managerClearanceStatus,
      financeClearanceStatus,
      hrClearanceStatus,
      clearanceStatus,
      error,
      message,
      dateError,
    } = this.state;
    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Update Exit Clearance</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="6" className={`${clearanceStatus && "mx-auto"} `}>
              <FormComponent
                labelClass="bold label-sm"
                label="EmpId/EmpName"
                type="text"
                inputClass="form-control-sm"
                value={`${empId}-${empName}`}
                required={true}
                disabled={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Resignation Date"
                type="text"
                inputClass="form-control-sm"
                value={regDate}
                required={true}
                // change={this.onChange}
                disabled={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Last Working Date"
                type="date"
                name="lastWorkingDate"
                inputClass="form-control-sm"
                value={lastWorkingDate}
                change={this.onChange}
                required={true}
                error={dateError}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Resignation Reason"
                type="text"
                inputClass="form-control-sm"
                value={regReason}
                required={true}
                disabled={true}
              />
              <FormGroup>
                <Label className="bold label-sm">Resignation Status</Label>
                <Input
                  type="select"
                  name="regStatus"
                  className="form-control-sm"
                  value={regStatus}
                  onChange={this.onChange}
                  required={true}
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {this.props.resignationStatus.data.map((item) => {
                    return (
                      <option key={item.id} value={item.regStatusName}>
                        {item.regStatusName}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
            </Col>
            {!clearanceStatus && (
              <Col md="6">
                <FormGroup>
                  <Label className="bold label-sm">
                    Admin Clearance Status
                  </Label>
                  <Input
                    type="select"
                    name="adminClearanceStatus"
                    className="form-control-sm"
                    value={adminClearanceStatus}
                    onChange={this.onChange}
                    required={true}
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.clearanceStatus.data.map((item) => {
                      return (
                        <option key={item.id} value={item.clearanceStatusName}>
                          {item.clearanceStatusName}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">
                    Asset Clearance Status
                  </Label>
                  <Input
                    type="select"
                    name="assetClearanceStatus"
                    className="form-control-sm"
                    value={assetClearanceStatus}
                    onChange={this.onChange}
                    required={true}
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.clearanceStatus.data.map((item) => {
                      return (
                        <option key={item.id} value={item.clearanceStatusName}>
                          {item.clearanceStatusName}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">
                    Manager Clearance Status
                  </Label>
                  <Input
                    type="select"
                    name="managerClearanceStatus"
                    className="form-control-sm"
                    value={managerClearanceStatus}
                    onChange={this.onChange}
                    required={true}
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.clearanceStatus.data.map((item) => {
                      return (
                        <option key={item.id} value={item.clearanceStatusName}>
                          {item.clearanceStatusName}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">
                    Finance Clearance Status
                  </Label>
                  <Input
                    type="select"
                    name="financeClearanceStatus"
                    className="form-control-sm"
                    value={financeClearanceStatus}
                    onChange={this.onChange}
                    required={true}
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.clearanceStatus.data.map((item) => {
                      return (
                        <option key={item.id} value={item.clearanceStatusName}>
                          {item.clearanceStatusName}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">HR Clearance Status</Label>
                  <Input
                    type="select"
                    name="hrClearanceStatus"
                    className="form-control-sm"
                    value={hrClearanceStatus}
                    onChange={this.onChange}
                    required={true}
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.clearanceStatus.data.map((item) => {
                      return (
                        <option key={item.id} value={item.clearanceStatusName}>
                          {item.clearanceStatusName}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
            )}
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Update
            </button>
          </div>
        </Form>
        {this.props.exitClearance.message && (
          <Notifier message={this.props.exitClearance.message} />
        )}
        {this.props.exitClearance.error && (
          <ErrorNotifier message={this.props.exitClearance.error} />
        )}
      </React.Fragment>
    );
  }
}

UpdateExitClearance.propTypes = {
  updateExitClearance: PropTypes.func.isRequired,
  exitClearance: PropTypes.object.isRequired,
  getUserResignationStatus: PropTypes.func.isRequired,
  resignationStatus: PropTypes.object.isRequired,
  getUserClearanceStatus: PropTypes.func.isRequired,
  clearanceStatus: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  exitClearance: state.exitClearance,
  resignationStatus: state.resignationStatus,
  clearanceStatus: state.clearanceStatus,
});

export default connect(mapStateToProps, {
  updateExitClearance,
  getUserResignationStatus,
  getUserClearanceStatus,
})(UpdateExitClearance);
