import axios from "axios";
import {
  BUSINESS_GEOGRAPHIES_ADD,
  BUSINESS_GEOGRAPHIES1_GET,
  BUSINESS_GEOGRAPHIES_UPDATE,
  BUSINESS_GEOGRAPHIES_DELETE,
  GET_ERRORS,
} from "./types";
import {
  ADD_BUSINESS_GEOGRAPHIES,
  GET_BUSINESS_GEOGRAPHIES1,
  UPDATE_BUSINESS_GEOGRAPHIES,
  DELETE_BUSINESS_GEOGRAPHIES,
} from "../../utils/routes";
import { setMessage } from "./salesActions";
import { getBusinessGeographies } from "./busiGeographiesAction";

export const addBusinessGeographies = (data, back) => (dispatch) => {
  axios
    .post(ADD_BUSINESS_GEOGRAPHIES, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getBusinessGeographies());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const getBusinessGeographies1 = () => (dispatch) => {
  axios
    .get(GET_BUSINESS_GEOGRAPHIES1, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: BUSINESS_GEOGRAPHIES1_GET,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};

export const updateBusinessGeographies = (data, back) => (dispatch) => {
  axios
    .post(UPDATE_BUSINESS_GEOGRAPHIES, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getBusinessGeographies());
        back();
      }, 2000);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};

export const deleteBusinessGeographies = (data) => (dispatch) => {
  axios
    .post(DELETE_BUSINESS_GEOGRAPHIES, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getBusinessGeographies());
      }, 2000);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};
