import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
// components
import PassportDetails from "./passport_details/PassportDetails";
import OtherIdentifiers from "./other_identifiers/OtherIdentifiers";

class IdentificationInfo extends Component {
  render() {
    return (
      <Container className="card container-card">
        <i
          className="material-icons contact-cancel-button pointer"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Row className="bank-info-row">
          <Col className="contact-details-col">
            <PassportDetails />
          </Col>
          {/* <Col className="contact-details-col">
            <OtherIdentifiers />
          </Col> */}
        </Row>
      </Container>
    );
  }
}

export default IdentificationInfo;
