/*  eslint-disable  */
import axios from "axios";
import {
  ACCOUNT_ALL_LIST,
  ACCOUNT_LIST,
  ACCOUNT_ADD,
  ACCOUNT_UPDATE,
  ACCOUNT_SEARCH,
  GET_ERRORS,
} from "./types";
import {
  ADD_ACCOUNT,
  GET_ACCOUNT,
  GET_ALL_ACCOUNT,
  UPDATE_ACCOUNT,
  SEARCH_ACCOUNT,
} from "../../utils/routes";
import { setMessage } from "./salesActions";

export const addAccount = (data, history) => (dispatch) => {
  axios
    .post(ADD_ACCOUNT, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        history.push("/pie/crm/accounts");
      }, 2000);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};

export const getAccounts = () => (dispatch) => {
  axios
    .get(GET_ACCOUNT, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: ACCOUNT_LIST,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};

export const getAllAccounts = () => (dispatch) => {
  axios
    .get(GET_ALL_ACCOUNT, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: ACCOUNT_ALL_LIST,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};

export const updateAccount = (data, history) => (dispatch) => {
  axios
    .post(UPDATE_ACCOUNT, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
      }, 2000);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};

export const searchAccount = (data) => (dispatch) => {
  axios
    .post(SEARCH_ACCOUNT, data, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: ACCOUNT_SEARCH,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};

export const addLeadAccount = (data, history) => (dispatch) => {
  axios
    .post(ADD_ACCOUNT, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
      }, 2000);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};
