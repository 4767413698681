import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  FormText,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
// material ui
import { IconButton, Divider } from "@material-ui/core";
// icons
import EditIcon from "@material-ui/icons/Edit";
import LabelIcon from "@material-ui/icons/Label";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";

// components
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../common/Validator";
import FormComponent from "../../../common/FormComponent";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import DetailsPageFooter from "../../../common/details_footer/DetailsPageFooter";
import SearchSelectBoxWithAdd from "../../../common/selectbox/SearchSelectBoxWithAdd";
import CustomizedDialogs from "../../../common/customized-dialog/CustomizedDialogs";
import UpdateTagContact from "../common/UpdateTagContact";
import TagContactCard from "../common/TagContactCard";

// redux
import {
  updateCustomerEntity,
  getCustomerEntity,
  getCustomerEntitySpocName,
  getUpdateCustomerEntitySpocName,
  getCustomerEntityTaggedContacts,
} from "../../../../redux/actions/crm/customer_entity/customerEntityAction";
import { getCustomersName } from "../../../../redux/actions/crm/customers/customersAction";
import { getUserCustomerGeographies } from "../../../../redux/actions/crm/crm_setup/customerGeographiesAction";
import { getUserCustomerCountries } from "../../../../redux/actions/crm/crm_setup/customerCountriesAction";
import { getUserCustomerCurrency } from "../../../../redux/actions/crm/crm_setup/customerCurrencyAction";
import { getUserCustomerAccountStatus } from "../../../../redux/actions/crm/crm_setup/customerAccountStatusAction";

// ------------------------------------------------------------------------------------------------------------------

class CustomerEntityDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: "",
      entityName: "",
      contactId: "",
      spocName: "",
      contactEmail: "",
      dialingCode: "",
      contactNo: "",
      registrationNo: "",
      panNo: "",
      tanNo: "",
      tinNo: "",
      gstNo: "",
      geography: "",
      country: "",
      countryCode: "",
      currency: "",
      billingAddress: "",
      shippingAddress: "",
      entityStatus: "",
      comments: "",
      edit: false,
      data: {},
      open: false,
      viewTaggedContacts: false,
      dateError: "",
      spocError: "",
      message: "",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.customerEntity.message) {
      setTimeout(() => {
        this.props.back();
      }, 2000);
    }
    if (prevState.customerId !== this.state.customerId) {
      this.props.getUpdateCustomerEntitySpocName(
        this.props.customerEntities.customerdetailId
      );
    }
  }

  onChange(e) {
    e.preventDefault();
    Validator(e.target);

    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleContactNameChange = (value) => {
    this.setState({
      contactId: value && value.contactId,
      spocName: value && value.contactName,
      contactEmail: value && value.contactEmail,
      dialingCode: value && value.contactDialingCode,
      contactNo: value && value.contactMobileNo,
    });
  };

  /*
  This function for updating the customer Entity details
  */
  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let comp = {
      id: this.props.customerEntities.id.toString(),
      customerdetailId: this.state.customerId.toString(),
      entityName: this.state.entityName,
      contactId: this.state.contactId,
      contactName: this.state.spocName,
      registrationNo: this.state.registrationNo,
      panNo: this.state.panNo,
      tanNo: this.state.tanNo,
      tinNo: this.state.tinNo,
      gstNo: this.state.gstNo,
      geography: this.state.geography,
      country: this.state.country.split(",")[0],
      countryCode: this.state.country.split(",")[1],
      currency: this.state.currency,
      billingAddress: this.state.billingAddress,
      shippingAddress: this.state.shippingAddress,
      entityStatus: this.state.entityStatus,
      comments: this.state.comments,
    };
    if (valid && !this.state.dateError && this.state.spocName) {
      this.props.updateCustomerEntity(comp, this.props.back);
      setTimeout(() => {
        this.props.getCustomerEntity();
      }, 2000);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
        spocError: this.state.spocName ? " " : "This field is mandatory",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
  ComponentDidMount getting the selected customer entity details and setting the state of customer details
  */
  componentDidMount() {
    const {
      customerdetailId,
      entityName,
      contactId,
      contactName,
      registrationNo,
      panNo,
      tanNo,
      tinNo,
      gstNo,
      geography,
      country,
      countryCode,
      currency,
      billingAddress,
      shippingAddress,
      entityStatus,
      comments,
    } = this.props.customerEntities;
    this.setState({
      customerId: customerdetailId,
      entityName: entityName,
      contactId: contactId,
      spocName: contactName,
      registrationNo: registrationNo,
      panNo: panNo,
      tanNo: tanNo,
      tinNo: tinNo,
      gstNo: gstNo,
      geography: geography,
      country: `${country},${countryCode}`,
      currency: currency,
      billingAddress: billingAddress,
      shippingAddress,
      entityStatus,
      comments: comments,
    });
    this.props.getCustomersName();
    this.props.getUserCustomerGeographies();
    this.props.getUserCustomerCountries();
    this.props.getUserCustomerCurrency();
    this.props.getUserCustomerAccountStatus();

    refresh();
  }

  resetSpocNameError = () => {
    this.setState({
      spocError: "",
    });
  };

  // modal function for open
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  // modal function for close
  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  // modal function for view tagged contacts
  handleClickView = () => {
    this.setState({
      viewTaggedContacts: true,
    });
    this.props.getCustomerEntityTaggedContacts(
      this.state.customerId,
      this.props.customerEntities.id
    );
  };

  // modal function for close view tagged contacts
  handleCloseView = () => {
    this.setState({
      viewTaggedContacts: false,
    });
  };

  render() {
    console.log(this.props.customerEntities);
    return (
      <Row>
        {!this.state.edit ? (
          <Col md="12">
            <h5 className="bold center mb-4">
              Customer Entity : {this.props.customerEntities.entityNo}
            </h5>
            {typeof this.props.permission !== "undefined" &&
              Object.keys(this.props.permission).length > 0 &&
              this.props.permission.updation && (
                <IconButton
                  className="edit-button"
                  color="primary"
                  onClick={() => this.setState({ edit: !this.state.edit })}
                  title="edit customer entity"
                >
                  <EditIcon />
                </IconButton>
              )}
            <IconButton
              className="tag-button"
              color="primary"
              title="tag contact"
              onClick={this.handleClickOpen}
            >
              <LabelIcon />
            </IconButton>
            <IconButton
              className="eye-button"
              color="primary"
              title="view tagged contacts"
              onClick={this.handleClickView}
            >
              <RemoveRedEyeIcon />
            </IconButton>
            <i
              onClick={() => this.props.back()}
              className="material-icons pointer cancel-button"
            >
              cancel
            </i>
            <Row>
              <Col md="6">
                <ul className="CustDetails-ul">
                  <li>
                    <span className="list-label customer-span-label-col-1">
                      Entity Name
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.entityName}
                    </span>
                  </li>
                  <li>
                    <span className="list-label customer-span-label-col-1">
                      Geography
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.geography}
                    </span>
                  </li>
                  <li>
                    <span className="list-label customer-span-label-col-1">
                      Country
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.country}
                    </span>
                  </li>
                  <li>
                    <span className="list-label customer-span-label-col-1">
                      Currency
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.currency}
                    </span>
                  </li>
                  <li>
                    <span className="list-label customer-span-label-col-1">
                      SPOC Name
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.spocName}
                    </span>
                  </li>
                  <li>
                    <span className="list-label customer-span-label-col-1">
                      Billing Address
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.billingAddress}
                    </span>
                  </li>
                  <li>
                    <span className="list-label customer-span-label-col-1">
                      Shipping Address
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.shippingAddress}
                    </span>
                  </li>
                  {/* <li>
                    <span className="list-label customer-span-label-col-1">
                      Customer Id
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.customerId}
                    </span>
                  </li>
                  <li>
                    <span className="list-label customer-span-label-col-1">
                      Customer Name
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.customerName}
                    </span>
                  </li> */}
                </ul>
              </Col>
              <Col md="6">
                <ul className="CustDetails-ul">
                  <li>
                    <span className="list-label customer-span-label-col-2">
                      Entity Status
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.entityStatus}
                    </span>
                  </li>
                  <li>
                    <span className="list-label customer-span-label-col-2">
                      Registration No.
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.registrationNo}
                    </span>
                  </li>
                  <li>
                    <span className="list-label customer-span-label-col-2">
                      GST No.
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.gstNo}
                    </span>
                  </li>
                  <li>
                    <span className="list-label customer-span-label-col-2">
                      Pan No.
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.panNo}
                    </span>
                  </li>
                  <li>
                    <span className="list-label customer-span-label-col-2">
                      Tan No.
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.tanNo}
                    </span>
                  </li>
                  <li>
                    <span className="list-label customer-span-label-col-2">
                      Tin No.
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.tinNo}
                    </span>
                  </li>
                  <li>
                    <span className="list-label customer-span-label-col-2">
                      Comments
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.comments}
                    </span>
                  </li>
                </ul>
              </Col>
            </Row>
            <Divider />
            <DetailsPageFooter
              createdBy={this.props.customerEntities.createdBy}
              createdOn={this.props.customerEntities.createdOn}
              updatedBy={this.props.customerEntities.updatedBy}
              updatedOn={this.props.customerEntities.updatedOn}
            />
          </Col>
        ) : (
          <Col md="12">
            <h5 className="bold center">
              Update Customer Entity : {this.props.customerEntities.entityNo}
            </h5>
            <i
              onClick={() => this.props.back()}
              className="material-icons pointer cancel-button"
            >
              cancel
            </i>
            <Form onSubmit={this.onSubmit} className="p-3" noValidate>
              <Row>
                <Col md="4">
                  {/* <FormGroup>
                    <Label className="bold label-sm">Customer Name</Label>
                    <Input
                      type="select"
                      name="customerId"
                      className="form-control-sm"
                      value={this.state.customerId}
                      onChange={this.onChange}
                      required={true}
                      disabled={true}
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.customers.nameData.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.id}-{item.customerName}
                          </option>
                        );
                      })}
                    </Input>
                    {errors.customerId && (
                      <FormText className="error">{errors.customerId}</FormText>
                    )}
                  </FormGroup> */}

                  <FormComponent
                    labelClass="bold label-sm"
                    label="Entity Name"
                    type="text"
                    name="entityName"
                    change={this.onChange}
                    value={this.state.entityName}
                    placeholder="Entity Name"
                    required={true}
                    tabIndex="1"
                  />
                  <FormGroup>
                    <Label className="bold label-sm">Geography</Label>
                    <Input
                      type="select"
                      name="geography"
                      className="form-control-sm"
                      value={this.state.geography}
                      onChange={this.onChange}
                      placeholder="Geography"
                      required={true}
                      tabIndex="4"
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.customerGeographies.data.map((item) => {
                        return (
                          <option key={item.id} value={item.geoName}>
                            {item.geoName}
                          </option>
                        );
                      })}
                    </Input>
                    {errors.geography && (
                      <FormText className="error">{errors.geography}</FormText>
                    )}
                  </FormGroup>
                  <FormComponent
                    labelClass="bold label-sm"
                    label="GST Number"
                    type="text"
                    name="gstNo"
                    change={this.onChange}
                    value={this.state.gstNo}
                    required={true}
                    placeholder="GST ex:05ABDCE1234F1Z2"
                    minLength="15"
                    maxLength="15"
                    pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
                    tabIndex="7"
                  />
                  <FormComponent
                    labelClass="bold label-sm"
                    label="TIN Number"
                    type="text"
                    name="tinNo"
                    change={this.onChange}
                    value={this.state.tinNo}
                    placeholder="Valid TIN Number"
                    minLength="11"
                    maxLength="11"
                    pattern="lettersAndNumbers"
                    tabIndex="10"
                  />

                  <FormComponent
                    labelClass="bold label-sm"
                    label="Billing Address"
                    type="textarea"
                    name="billingAddress"
                    inputClass="form-control-sm"
                    value={this.state.billingAddress}
                    change={this.onChange}
                    tabIndex="12"
                  />
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label className="bold label-sm">Entity Status</Label>
                    <Input
                      type="select"
                      name="entityStatus"
                      className="form-control-sm"
                      value={this.state.entityStatus}
                      onChange={this.onChange}
                      required={true}
                      tabIndex="2"
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.customerAccountStatus.data.map((item) => {
                        return (
                          <option key={item.id} value={item.statusName}>
                            {item.statusName}
                          </option>
                        );
                      })}
                    </Input>
                    {errors.entityStatus && (
                      <FormText className="error">
                        {errors.entityStatus}
                      </FormText>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label className="bold label-sm">Country</Label>
                    <Input
                      type="select"
                      name="country"
                      className="form-control-sm"
                      value={this.state.country}
                      onChange={this.onChange}
                      placeholder="Country"
                      required={true}
                      tabIndex="5"
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.customerCountries.data.map((item) => {
                        if (this.state.geography === item.geoName) {
                          return (
                            <option
                              key={item.id}
                              value={`${item.countryName},${item.countryCode}`}
                            >
                              {item.countryName}
                            </option>
                          );
                        }
                      })}
                    </Input>
                    {errors.country && (
                      <FormText className="error">{errors.country}</FormText>
                    )}
                  </FormGroup>
                  <FormComponent
                    labelClass="bold label-sm"
                    label="PAN Number"
                    type="text"
                    name="panNo"
                    change={this.onChange}
                    value={this.state.panNo}
                    placeholder="Valid PAN Number"
                    minLength="10"
                    maxLength="10"
                    pattern="lettersAndNumbers"
                    tabIndex="8"
                  />

                  <SearchSelectBoxWithAdd
                    options={this.props.customerEntity.spocNameData}
                    value={this.state.spocName}
                    handleChange={(value) =>
                      this.handleContactNameChange(value)
                    }
                    reset={this.resetSpocNameError}
                    spocError={this.state.spocError}
                    label="SPOC Name"
                    tabIndex="11"
                  />
                  <FormComponent
                    labelClass="bold label-sm"
                    label="Shipping Address"
                    type="textarea"
                    name="shippingAddress"
                    inputClass="form-control-sm"
                    value={this.state.shippingAddress}
                    change={this.onChange}
                    tabIndex="13"
                  />
                </Col>
                <Col md="4">
                  <FormComponent
                    labelClass="bold label-sm"
                    label="Registration/CIN Number"
                    type="text"
                    name="registrationNo"
                    change={this.onChange}
                    value={this.state.registrationNo}
                    required={true}
                    placeholder="Registered/CIN Number"
                    minLength="21"
                    maxLength="21"
                    pattern="lettersAndNumbers"
                    tabIndex="3"
                  />
                  <FormGroup>
                    <Label className="bold label-sm">Customer Currency</Label>
                    <Input
                      type="select"
                      name="currency"
                      className="form-control-sm"
                      value={this.state.currency}
                      onChange={this.onChange}
                      required={true}
                      tabIndex="6"
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.customerCurrency.data.map((item) => {
                        return (
                          <option key={item.id} value={item.currencyCode}>
                            {item.currencyCode}
                          </option>
                        );
                      })}
                    </Input>
                    {errors.currency && (
                      <FormText className="error">{errors.currency}</FormText>
                    )}
                  </FormGroup>
                  <FormComponent
                    labelClass="bold label-sm"
                    label="TAN Number"
                    type="text"
                    name="tanNo"
                    change={this.onChange}
                    value={this.state.tanNo}
                    placeholder="Valid TAN Number"
                    minLength="10"
                    maxLength="10"
                    pattern="lettersAndNumbers"
                    tabIndex="9"
                  />
                  <ul className="CustDetails-ul">
                    <li>
                      <span className="label-sm bold customer-span-label-col-1">
                        Email
                      </span>
                      <span className=" label-sm">
                        &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.contactEmail}
                      </span>
                    </li>
                    <li>
                      <span className="label-sm bold customer-span-label-col-1">
                        Mobile
                      </span>
                      <span className=" label-sm">
                        &nbsp;&nbsp;:&nbsp;&nbsp;
                        {this.state.dialingCode && `${this.state.dialingCode}-`}
                        {this.state.contactNo}
                      </span>
                    </li>
                  </ul>

                  <FormComponent
                    labelClass="bold label-sm"
                    label="Comments"
                    type="textarea"
                    name="comments"
                    inputClass="form-control-sm"
                    value={this.state.comments}
                    change={this.onChange}
                    tabIndex="14"
                  />
                </Col>
              </Row>
              <div className="center">
                <button type="submit" className="btn btn-custom" tabIndex="15">
                  Submit
                </button>
              </div>
            </Form>
          </Col>
        )}
        {this.props.customerEntity.message ? (
          <Notifier message={this.props.customerEntity.message} />
        ) : null}
        {this.props.customerEntity.error ? (
          <ErrorNotifier message={this.props.customerEntity.error} />
        ) : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
        <CustomizedDialogs
          open={this.state.open}
          handleClose={this.handleClose}
          title="Tag Contact"
        >
          <UpdateTagContact
            back={this.handleClose}
            customerdetailId={this.state.customerId}
            customerentityId={this.props.customerEntities.id}
            spocNameData={this.props.customerEntity.spocNameData}
            getData={this.props.getCustomerEntitySpocName}
            name="customerEntity"
          />
        </CustomizedDialogs>
        <Modal size="xl" isOpen={this.state.viewTaggedContacts}>
          <ModalHeader toggle={this.handleCloseView}>
            View Tag Contacts
          </ModalHeader>
          <ModalBody>
            <TagContactCard
              data={this.props.customerEntity.taggedContactData}
              permission={this.props.permission}
              name="customerEntity"
            />
          </ModalBody>
        </Modal>
      </Row>
    );
  }
}

CustomerEntityDetails.propTypes = {
  customerEntity: PropTypes.object.isRequired,
  updateCustomerEntity: PropTypes.func.isRequired,
  getCustomersName: PropTypes.func.isRequired,
  getCustomerEntitySpocName: PropTypes.func.isRequired,
  getUpdateCustomerEntitySpocName: PropTypes.func.isRequired,
  getCustomerEntityTaggedContacts: PropTypes.func.isRequired,
  customers: PropTypes.object.isRequired,
  getUserCustomerGeographies: PropTypes.func.isRequired,
  customerGeographies: PropTypes.object.isRequired,
  getUserCustomerCountries: PropTypes.func.isRequired,
  customerCountries: PropTypes.object.isRequired,
  getUserCustomerCurrency: PropTypes.func.isRequired,
  customerCurrency: PropTypes.object.isRequired,
  getUserCustomerAccountStatus: PropTypes.func.isRequired,
  customerAccountStatus: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  customerEntity: state.customerEntity,
  customers: state.customers,
  customerGeographies: state.customerGeographies,
  customerCountries: state.customerCountries,
  customerCurrency: state.customerCurrency,
  customerAccountStatus: state.customerAccountStatus,
});

export default connect(mapStateToProps, {
  updateCustomerEntity,
  getCustomerEntitySpocName,
  getUpdateCustomerEntitySpocName,
  getCustomerEntityTaggedContacts,
  getCustomerEntity,
  getCustomersName,
  getUserCustomerGeographies,
  getUserCustomerCountries,
  getUserCustomerCurrency,
  getUserCustomerAccountStatus,
})(withRouter(CustomerEntityDetails));
