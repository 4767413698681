import React, { Component } from "react";
import { Form, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import Notifier from "../../../../aside/Notifier";
import { addGrade } from "../../../../../redux/actions/rs/configuration/gradeAction";
import { Grade, GradeSharp } from "@material-ui/icons";

// add candidate grade component
class AddCanGrade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Grade: "",
      jobPositionDescription: "",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  // common onchange function
  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  // submit function
  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      gradeName: this.state.grade,
      
     
    };
    if (valid) {
      this.props.addGrade(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidMount contains the error refresh method and setState method for calling the render method
*/
  componentDidMount() {
    refresh();
    this.setState({
      error: "",
    });
  }

  render() {
    const { grade, error } = this.state;

    return (
      <React.Fragment>
        <h5 className="center card-heading pt-2">Add Grade</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="6" className="mx-auto">
              <FormComponent
                labelClass="bold label-sm"
                label="Grade"
                type="text"
                name="grade"
                inputClass="form-control-sm"
                value={grade}
                change={this.onChange}
                required={true}
                length="30"
              />

            
             
            </Col>
           
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Add
            </button>
          </div>
        </Form>
        {error && <ErrorNotifier message={error} />}
        {this.props.CanGrade.error && (
          <ErrorNotifier message={this.props.CanGrade.error} />
        )}
        {this.props.CanGrade.message && (
          <Notifier message={this.props.CanGrade.message} />
        )}
      </React.Fragment>
    );
  }
}

AddCanGrade.propTypes = {
  addBillingRs: PropTypes.func.isRequired,
  userGroups: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  CanGrade: state.CanGrade,
});

export default connect(mapStateToProps, {  addGrade })(  AddCanGrade);
