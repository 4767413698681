import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormText,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { connect } from "react-redux";
import DateFns from "date-fns";
import {
  updatePOEntry,
  updatePurchaseItem,
  getPurchaseItems,
  updateScheduledPymtDate,
  getScheduledPymtDate,
} from "../../../../../redux/actions/finance/accounts_receivable/po_entry/poEntryAction";
import { getPOType } from "../../../../../redux/actions/finance/accounts_payable/issue_po/poTypeAction";
import { getCustomers } from "../../../../../redux/actions/customerAction";
import { getPartnerPymtTerms } from "../../../../../redux/actions/partnerPymtTermsAction";
import FormComponent from "../../../../common/FormComponent";
import {
  errors,
  Validator,
  submitValidator,
  valid,
} from "../../../../common/Validator";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import Notifier from "../../../../aside/Notifier";

/**
 * @class UpdatePOEntry
 **/

class UpdatePOEntry extends Component {
  constructor(props) {
    super(props);
    const {
      poId,
      poNo,
      poType,
      customerName,
      contactName,
      contactEmail,
      contactMobileNo,
      shipToAddress,
      shipToLocation,
      shipToContactMobileEmail,
      shipToContactMobileNo,
      poCurrency,
      poDescription,
      pymtTerm,
      totalAmount,
      totalGst,
      poTotalAmount,
      pymtInstructions,
      poEntryStatus,
      remarks,
    } = this.props.POEntry;
    this.state = {
      poId: poId,
      poNo: poNo,
      poType: poType,
      customerName: customerName,
      shipToAddress: shipToAddress,
      contactName: contactName,
      contactEmail: contactEmail,
      contactPhoneNumber: contactMobileNo,
      shipToLocation: shipToLocation,
      shipToContactEmail: shipToContactMobileEmail,
      shipToContactNo: shipToContactMobileNo,
      poCurrency: poCurrency,
      poDescription: poDescription,
      paymentTerms: pymtTerm,
      totalAmount: totalAmount,
      totalGst: totalGst,
      poTotalAmount: poTotalAmount,
      paymentInstructions: pymtInstructions,
      remarks: remarks,
      status: poEntryStatus,
      purchaseItemModal: false,
      purchaseItemsId: "",
      purchaseItemNo: "",
      pspName: "",
      quantity: "",
      amount: "",
      amountGST: "",
      pymtScheduleModal: false,
      scheduledPymtDate: "",
      pymtAmount: "",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  componentDidMount() {
    this.props.getPOType();
    let data = {
      poId: this.state.poId,
    };
    this.props.getPurchaseItems(data);
    this.props.getScheduledPymtDate(data);
    this.props.getPartnerPymtTerms();
  }

  // componentDidUpdate function to calculate the gst and amount
  componentDidUpdate(prevProps, prevState) {
    if (prevState.amount !== this.state.amount) {
      this.setState({
        amountGST: (this.state.amount * 0.18).toFixed(2),
      });
    }
    if (
      prevProps.poEntry.purchaseItemData !== this.props.poEntry.purchaseItemData
    ) {
      if (this.props.poEntry.purchaseItemData.length !== 0) {
        let arr = this.props.poEntry.purchaseItemData;
        let sumAmount = 0;
        let sumAmountGst = 0;
        arr.forEach(function (obj) {
          sumAmount += obj.amount * obj.qty;
          sumAmountGst += obj.gstAmount * obj.qty;
        });
        this.setState({
          totalAmount: sumAmount,
          totalGst: sumAmountGst.toFixed(2),
          poTotalAmount:
            parseInt(sumAmount) + parseFloat(sumAmountGst.toFixed(2)),
        });
      }
    }
  }

  // onSubmit function to submit the form
  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);

    if (valid) {
      let data = {
        poId: this.state.poId,
        poType: this.state.poType,
        shipToContactMobileEmail: this.state.shipToContactEmail,
        shipToContactMobileNo: this.state.shipToContactNo,
        poDescription: this.state.poDescription,
        pymtTerm: this.state.paymentTerms,
        totalAmount: this.state.totalAmount,
        totalGst: this.state.totalGst,
        poTotalAmount: this.state.poTotalAmount,
        pymtInstructions: this.state.paymentInstructions,
        remarks: this.state.remarks,
        updatedBy: localStorage.getItem("id"),
      };

      this.props.updatePOEntry(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all Mandatory Fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  //  purchaseItemUpdate function for when click on the update button it will open the model to update the data
  purchaseItemUpdate = (item) => {
    this.setState({
      purchaseItemsId: item.receivableItemsId,
      poId: item.poId,
      purchaseItemNo: item.receivableItemNo,
      pspName: item.ppsName,
      quantity: item.qty,
      amount: item.amount,
      amountGST: item.gstAmount,
      purchaseItemModal: true,
    });
  };

  // purchaseItemToggle function to close the purchase update Modal
  purchaseItemToggle = () => {
    this.setState({
      purchaseItemModal: false,
    });
    let data1 = {
      poId: this.state.poId,
    };
    this.props.getPurchaseItems(data1);
  };

  // purchaseItemSubmit function to submit the puchase item updated changes
  purchaseItemSubmit = (e) => {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      receivableItemsId: this.state.purchaseItemsId,
      poId: this.state.poId,
      receivableItemNo: this.state.purchaseItemNo,
      ppsName: this.state.pspName,
      qty: this.state.quantity,
      amount: this.state.amount,
      gstAmount: this.state.amountGST,
    };
    if (valid) {
      this.props.updatePurchaseItem(data, this.purchaseItemToggle);
    } else {
      this.setState({
        error: "Enter all Mandatory Fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  };

  // scheduledPaymentUpdate function to open the schedule payment modal when we click on the edit button
  scheduledPaymentUpdate = (item) => {
    this.setState({
      scheduleId: item.scheduleId,
      scheduledPymtDate: item.scheduledPymtDate,
      pymtAmount: item.paymentAmount,
      pymtScheduleModal: true,
    });
  };

  // pymtScheduleToggle function to close the update schedule payment modal
  pymtScheduleToggle = () => {
    this.setState({
      pymtScheduleModal: false,
    });
    let data1 = {
      poId: this.state.poId,
    };
    this.props.getScheduledPymtDate(data1);
  };

  // pymtScheduleSubmit function to submit the updated schedule payment details
  pymtScheduleSubmit = (e) => {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      scheduleId: this.state.scheduleId,
      poId: this.state.poId,
      scheduledPymtDate: this.state.scheduledPymtDate,
      paymentAmount: this.state.pymtAmount,
    };
    if (valid) {
      this.props.updateScheduledPymtDate(data, this.pymtScheduleToggle);
    } else {
      this.setState({
        error: "Enter all Mandatory Fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  };

  render() {
    const {
      poNo,
      poType,
      customerName,
      shipToAddress,
      contactName,
      contactEmail,
      contactPhoneNumber,
      shipToLocation,
      shipToContactEmail,
      shipToContactNo,
      poCurrency,
      poDescription,
      paymentTerms,
      totalAmount,
      totalGst,
      poTotalAmount,
      paymentInstructions,
      remarks,
      status,
      purchaseItemModal,
      purchaseItemNo,
      pspName,
      quantity,
      amount,
      amountGST,
      pymtScheduleModal,
      scheduledPymtDate,
      pymtAmount,
      error,
    } = this.state;

    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Update PO Entry</h5>

        <Row>
          <Col md="6">
            <Form onSubmit={this.onSubmit} className="p-3" noValidate>
              <Row>
                <Col md="6">
                  <FormComponent
                    label="PO Number"
                    labelClass="label-sm bold"
                    type="number"
                    name="poNo"
                    value={poNo}
                    className="form-control-sm"
                    required={true}
                    disabled={true}
                    change={this.onChange}
                  />
                  <FormGroup>
                    <Label className="label-sm bold">PO Type</Label>
                    <Input
                      type="select"
                      name="poType"
                      value={poType}
                      className="form-control-sm"
                      onChange={this.onChange}
                      required={true}
                    >
                      <option value="" hidden>
                        Select
                      </option>
                      {this.props.poTypes.data.map((item) => {
                        return (
                          <option key={item.poTypeId} value={item.poType}>
                            {item.poType}
                          </option>
                        );
                      })}
                    </Input>
                    {errors.poType && (
                      <FormText className="error">{errors.poType}</FormText>
                    )}
                  </FormGroup>
                  <FormComponent
                    label="Customer Name"
                    labelClass="label-sm bold"
                    type="text"
                    name="customerName"
                    value={customerName}
                    className="form-control-sm"
                    required={true}
                    disabled={true}
                    change={this.onChange}
                  />
                  <FormComponent
                    label="Contact Name"
                    labelClass="label-sm bold"
                    type="text"
                    name="contactName"
                    value={contactName}
                    className="form-control-sm"
                    required={true}
                    change={this.onChange}
                    disabled={true}
                  />
                  <FormComponent
                    label="Contact Email"
                    labelClass="label-sm bold"
                    type="email"
                    name="contactEmail"
                    value={contactEmail}
                    className="form-control-sm"
                    required={true}
                    change={this.onChange}
                    disabled={true}
                  />
                  <FormComponent
                    label="Contact Phone Number"
                    labelClass="label-sm bold"
                    type="tel"
                    name="contactPhoneNumber"
                    value={contactPhoneNumber}
                    className="form-control-sm"
                    required={true}
                    change={this.onChange}
                    disabled={true}
                  />
                  <FormGroup>
                    <Label className="bold label-sm">Ship To Location</Label>
                    <Input
                      type="text"
                      name="shipToLocation"
                      className="form-control-sm "
                      value={shipToLocation}
                      onChange={this.onChange}
                      required={true}
                      disabled={true}
                    >
                      {/* <option value="" hidden>
                        Select...
                      </option>
                      {this.props.busiLocation.data.map((item) => {
                        return (
                          <option
                            key={item.locationId}
                            value={item.locationName}
                          >
                            {item.locationCode}-{item.locationName}
                          </option>
                        );
                      })} */}
                    </Input>
                    {errors.shipToLocation && (
                      <FormText className="error">
                        {errors.shipToLocation}
                      </FormText>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label className="bold label-sm">Ship To Address</Label>
                    <Input
                      type="text"
                      name="shipToAddress"
                      className="form-control-sm "
                      value={shipToAddress}
                      onChange={this.onChange}
                      required={true}
                      disabled={true}
                    ></Input>
                    {errors.shipToAddress && (
                      <FormText className="error">
                        {errors.shipToAddress}
                      </FormText>
                    )}
                  </FormGroup>
                  <FormComponent
                    label="PO Description"
                    labelClass="label-sm bold"
                    type="textarea"
                    name="poDescription"
                    value={poDescription}
                    className="form-control-sm"
                    change={this.onChange}
                    required={true}
                  />
                </Col>
                <Col md="6">
                  <FormComponent
                    label="Ship To Contact Email"
                    labelClass="label-sm bold"
                    type="email"
                    name="shipToContactEmail"
                    value={shipToContactEmail}
                    className="form-control-sm"
                    change={this.onChange}
                    required={true}
                  />
                  <FormComponent
                    label="Ship To Contact Number"
                    labelClass="label-sm bold"
                    type="tel"
                    name="shipToContactNo"
                    value={shipToContactNo}
                    className="form-control-sm"
                    change={this.onChange}
                    required={true}
                  />
                  <FormGroup>
                    <Label className="bold label-sm">PO Currency</Label>
                    <Input
                      type="text"
                      name="poCurrency"
                      className="form-control-sm "
                      value={poCurrency}
                      onChange={this.onChange}
                      required={true}
                      disabled={true}
                    ></Input>
                    {errors.poCurrency && (
                      <FormText className="error">{errors.poCurrency}</FormText>
                    )}
                  </FormGroup>

                  <FormGroup>
                    <Label className="label-sm bold">Payment Terms</Label>
                    <Input
                      type="text"
                      className="form-control-sm"
                      name="paymentTerms"
                      value={paymentTerms}
                      onChange={this.onChange}
                      required={true}
                    >
                      {" "}
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.partnerPymtTerms.data.map((item) => {
                        return (
                          <option
                            key={item.partnerPymtId}
                            value={item.partnerPymtName}
                          >
                            {item.partnerPymtName}
                          </option>
                        );
                      })}
                    </Input>
                    {errors.paymentTerms && (
                      <FormText className="error">
                        {errors.paymentTerms}
                      </FormText>
                    )}
                  </FormGroup>
                  <FormComponent
                    label="Total Amount"
                    labelClass="label-sm bold"
                    type="number"
                    name="totalAmount"
                    value={totalAmount}
                    className="form-control-sm"
                    change={this.onChange}
                    required={true}
                    disabled={true}
                  />
                  <FormComponent
                    label="Total GST"
                    labelClass="label-sm bold"
                    type="number"
                    name="totalGst"
                    value={totalGst}
                    className="form-control-sm"
                    change={this.onChange}
                    required={true}
                    disabled={true}
                  />
                  <FormComponent
                    label="PO Total Amount"
                    labelClass="label-sm bold"
                    type="number"
                    name="poTotalAmount"
                    value={poTotalAmount}
                    className="form-control-sm"
                    change={this.onChange}
                    required={true}
                    disabled={true}
                  />
                  <FormComponent
                    label="Payment Instructions"
                    labelClass="label-sm bold"
                    type="text"
                    name="paymentInstructions"
                    value={paymentInstructions}
                    className="form-control-sm"
                    change={this.onChange}
                    required={true}
                  />
                  <FormGroup>
                    <Label className="label-sm bold">Status</Label>
                    <Input
                      type="select"
                      className="form-control-sm"
                      name="status"
                      value={status}
                      onChange={this.onChange}
                      required={true}
                    >
                      <option value="New">New</option>
                      <option value="WIP">WIP</option>
                      <option value="Closed">Closed</option>
                    </Input>
                    {errors.poEntryStatus && (
                      <FormText className="error">
                        {errors.poEntryStatus}
                      </FormText>
                    )}
                  </FormGroup>
                  <FormComponent
                    label="Remarks"
                    labelClass="label-sm bold"
                    type="textarea"
                    name="remarks"
                    value={remarks}
                    className="form-control-sm"
                    change={this.onChange}
                    required={true}
                  />
                </Col>
              </Row>

              <div className="center">
                <button
                  type="submit"
                  className="btn center login-button white-text"
                >
                  Update
                </button>
              </div>
            </Form>
          </Col>
          <Col md="6">
            <Row className="UpdatepoEntry-purchaseItem-row">
              <div className="milestone-div">
                <h6 className="center">Purchase Items</h6>
                <Table>
                  <thead>
                    <tr>
                      <th className="opp-head">Name</th>
                      <th className="opp-head">Qty</th>
                      <th className="opp-head">Amount</th>
                      <th className="opp-head">GST</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.poEntry.purchaseItemData.map((item) => {
                      return (
                        <tr key={item.purchaseItemsId}>
                          <td>{item.ppsName}</td>
                          <td>{item.qty}</td>
                          <td>{item.amount}</td>
                          <td>{item.gstAmount}</td>
                          <td>
                            <span
                              className="product-pq-button"
                              id="lead-activity-edit-button"
                              title="Edit"
                              style={{ color: "blue" }}
                              onClick={() => this.purchaseItemUpdate(item)}
                            >
                              edit
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Row>
            <Row>
              <div className="task-div">
                <h6 className="center">Payment Schedule</h6>
                <Table>
                  <thead>
                    <tr>
                      <th className="opp-head">PO Id</th>
                      <th className="opp-head">Date</th>
                      <th className="opp-head">Amount</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.poEntry.scheduledPymtDateData.map((item) => {
                      return (
                        <tr key={item.scheduleId}>
                          <td>{item.poId}</td>
                          <td>
                            {item.scheduledPymtDate &&
                              DateFns.format(
                                item.scheduledPymtDate,
                                "DD-MM-YYYY"
                              )}
                          </td>
                          <td>{item.paymentAmount}</td>
                          <td>
                            <span
                              className="product-pq-button"
                              id="lead-activity-edit-button"
                              title="Edit"
                              style={{ color: "blue" }}
                              onClick={() => this.scheduledPaymentUpdate(item)}
                            >
                              edit
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Row>
          </Col>
        </Row>
        <Modal isOpen={purchaseItemModal} size="md">
          <ModalHeader toggle={this.purchaseItemToggle}>
            Purchase Items
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={this.purchaseItemSubmit} noValidate>
              <Row>
                <Col md="6" className="mx-auto">
                  <FormComponent
                    label="Purchase Item Number"
                    labelClass="label-sm  bold"
                    className="form-control-sm"
                    type="number"
                    name="purchaseItemNo"
                    value={purchaseItemNo}
                    change={this.onChange}
                    required={true}
                  />
                  <FormComponent
                    label="Product/Service/Project Name"
                    labelClass="label-sm  bold"
                    className="form-control-sm"
                    type="text"
                    name="pspName"
                    value={pspName}
                    change={this.onChange}
                    required={true}
                  />
                  <FormComponent
                    label="Quantity"
                    labelClass="label-sm  bold"
                    className="form-control-sm"
                    type="number"
                    name="quantity"
                    value={quantity}
                    change={this.onChange}
                    required={true}
                  />
                  <FormComponent
                    label="Amount"
                    labelClass="label-sm  bold"
                    className="form-control-sm"
                    type="number"
                    name="amount"
                    value={amount}
                    change={this.onChange}
                    required={true}
                  />
                  <FormComponent
                    label="Amount GST"
                    labelClass="label-sm  bold"
                    className="form-control-sm"
                    type="number"
                    name="amountGST"
                    value={amountGST}
                    change={this.onChange}
                    required={true}
                    disabled={true}
                  />
                </Col>
              </Row>
              <div className="center">
                <button
                  type="submit"
                  className="btn center login-button white-text"
                >
                  Add
                </button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
        <Modal isOpen={pymtScheduleModal} size="md">
          <ModalHeader toggle={this.pymtScheduleToggle}>
            Schedule Date
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={this.pymtScheduleSubmit} noValidate>
              <Row>
                <Col md="6" className="mx-auto">
                  <FormComponent
                    label="Scheduled Payment Date"
                    labelClass="label-sm  bold"
                    className="form-control-sm"
                    type="date"
                    name="scheduledPymtDate"
                    value={scheduledPymtDate}
                    change={this.onChange}
                    required={true}
                  />
                  <FormComponent
                    label="Payment Amount"
                    labelClass="label-sm  bold"
                    className="form-control-sm"
                    type="number"
                    name="pymtAmount"
                    value={pymtAmount}
                    change={this.onChange}
                    required={true}
                  />
                </Col>
              </Row>
              <div className="center">
                <button
                  type="submit"
                  className="btn center login-button white-text"
                >
                  Add
                </button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
        {error && <ErrorNotifier message={error} />}
        {this.props.poEntry.message && (
          <Notifier message={this.props.poEntry.message} />
        )}
        {this.props.poEntry.error && (
          <ErrorNotifier message={this.props.poEntry.error} />
        )}
      </React.Fragment>
    );
  }
}

UpdatePOEntry.propTypes = {
  getCustomers: PropTypes.func.isRequired,
  customers: PropTypes.object.isRequired,
  updatePOEntry: PropTypes.func.isRequired,
  updatePurchaseItem: PropTypes.func.isRequired,
  getPurchaseItems: PropTypes.func.isRequired,
  updateScheduledPymtDate: PropTypes.func.isRequired,
  getScheduledPymtDate: PropTypes.func.isRequired,
  poEntry: PropTypes.object.isRequired,
  getPOType: PropTypes.func.isRequired,
  poTypes: PropTypes.object.isRequired,
  getPartnerPymtTerms: PropTypes.func.isRequired,
  partnerPymtTerms: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  customers: state.customers,
  poEntry: state.poEntry,
  poTypes: state.poTypes,
  partnerPymtTerms: state.partnerPymtTerms,
});

export default connect(mapStateToProps, {
  getCustomers,
  updatePOEntry,
  updatePurchaseItem,
  getPurchaseItems,
  updateScheduledPymtDate,
  getScheduledPymtDate,
  getPOType,
  getPartnerPymtTerms,
})(UpdatePOEntry);
