import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
// components
import ContactDetails from "./contact_detail/ContactDetails";
import Address from "./address/Address";
import EmergencyContactDetails from "./emergency_contact_details/EmergencyContactDetails";
import FamilyContactDetails from "./family_contact_details/FamilyContactDetails";

class ContactDetailsMain extends Component {
  render() {
    return (
      <Container className="card container-card">
        <i
          className="material-icons contact-cancel-button pointer"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Row>
          <Col className="contact-details-col">
            <ContactDetails />
          </Col>
          <Col className="contact-details-col">
            <Address />
          </Col>
        </Row>
        <Row>
          <Col className="contact-details-col">
            <EmergencyContactDetails />
          </Col>
          <Col className="contact-details-col">
            <FamilyContactDetails />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ContactDetailsMain;
