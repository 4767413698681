import axios from "axios";
import { EMP_STATUS_REASON_GET } from "../../types";
import {
  CURD_EMP_STATUS_REASON,
  USER_EMP_STATUS_REASON,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addEmpStatusReason = (data, back) => (dispatch) => {
  axios
    .post(CURD_EMP_STATUS_REASON, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getEmpStatusReason));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getEmpStatusReason = () => (dispatch) => {
  axios
    .get(CURD_EMP_STATUS_REASON, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(EMP_STATUS_REASON_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserEmpStatusReason = () => (dispatch) => {
  axios
    .get(USER_EMP_STATUS_REASON, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(EMP_STATUS_REASON_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateEmpStatusReason = (data, back) => (dispatch) => {
  axios
    .put(CURD_EMP_STATUS_REASON, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getEmpStatusReason));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteEmpStatusReason = (data) => (dispatch) => {
  axios
    .delete(CURD_EMP_STATUS_REASON, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getEmpStatusReason));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
