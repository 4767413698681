import React, { Component } from "react";
import axios from "axios";
import { Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
import {
  UPDATE_EMPLOYEE_PERSONAL,
  EMPLOYEE_PERSONAL_INFO,
  EMPLOYEE_LIST,
  EMPLOYEE_UPDATE,
  CREATE_PERSONAL_INFO,
} from "../../../../utils/routes";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
} from "../../../common/Validator";
import FormComponent from "../../../common/FormComponent";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";

class PersonalInformation extends Component {
  constructor() {
    super();
    this.state = {
      data: {
        empPerId: null,
        homeContact: "",
        emerContact: "",
        contactPerson: "",
        personRelation: "",
        spouseName: "",
        spouseContact: 0,
        fatherName: "",
        otherDepend: "",
        bloodGroup: "A+",
        maritalStatus: "Single",
        presentAddr: "",
        permanentAddr: "",
      },
      dataEmployee: [
        {
          email: "",
          firstName: "",
          middleName: "",
          lastName: "",
          mobileNumber: "",
          genderType: "",
          dob: "",
        },
      ],
      message: "",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onChange1 = this.onChange1.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCreateSubmit = this.onCreateSubmit.bind(this);
  }

  componentDidMount() {
    const id = {
      empId: localStorage.getItem("id"),
    };
    axios
      .post(EMPLOYEE_PERSONAL_INFO, id, { withCredentials: true })
      .then((res) => {
        if (res.data.data[0]) {
          this.setState({
            data: res.data.data[0],
          });
        }
      });
    const id2 = {
      empId: localStorage.getItem("id"),
    };
    axios.post(EMPLOYEE_LIST, id2, { withCredentials: true }).then((res) => {
      if (res.data[0]) {
        this.setState({
          dataEmployee: res.data[0],
        });
      }
    });

    refresh();
  }

  onChange(e) {
    e.preventDefault();
    Validator(e.target);
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  }

  onChange1(e) {
    e.preventDefault();
    Validator(e.target);
    this.setState({
      dataEmployee: {
        ...this.state.dataEmployee,
        [e.target.name]: e.target.value,
      },
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let { data } = this.state;
    let details;
    details = {
      empId: localStorage.getItem("id"),
      empPerId: data.empPerId,
      emailId: this.state.dataEmployee.email,
      homeContact: data.homeContact,
      emerContact: data.emerContact,
      contactPerson: data.contactPerson,
      personRelation: data.personRelation,
      spouseName: data.spouseName,
      spouseContact: data.spouseContact || 0,
      fatherName: data.fatherName,
      otherDepend: data.otherDepend,
      bloodGroup: data.bloodGroup,
      maritalStatus: data.maritalStatus,
      presentAddr: data.presentAddr,
      permanentAddr: data.permanentAddr,
      updatedBy: localStorage.getItem("id"),
    };
    if (valid) {
      axios
        .post(UPDATE_EMPLOYEE_PERSONAL, details ? details : null, {
          withCredentials: true,
        })
        .then((res) => {
          this.setState({
            message: res.data,
          });
          setTimeout(() => {
            this.setState({
              message: "",
            });
            this.props.back();
          }, 2000);
        })
        .catch((err) => {
          this.setState({
            error: err.message,
          });
          setTimeout(() => {
            this.setState({
              error: "",
            });
          }, 2000);
        });
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }

    let { dataEmployee } = this.state;
    if (this.state.dataEmployee) {
      let details2 = {
        empId: localStorage.getItem("id"),
        mobileNumber: dataEmployee.mobileNumber,
        firstName: dataEmployee.firstName,
        middleName: dataEmployee.middleName,
        lastName: dataEmployee.lastName,
        genderType: dataEmployee.genderType,
        dob: dataEmployee.dob,
      };
      if (valid) {
        axios
          .post(EMPLOYEE_UPDATE, details2, { withCredentials: true })
          .then((res) => {
            this.setState({
              message: res.data,
            });
            setTimeout(() => {
              this.setState({
                message: "",
              });
              this.props.back();
            }, 2000);
          })
          .catch((err) => {
            this.setState({
              error: err.message,
            });
            setTimeout(() => {
              this.setState({
                error: "",
              });
            }, 2000);
          });
      } else {
        this.setState({
          error: "Enter all mandatory fields",
        });
        setTimeout(() => {
          this.setState({
            error: "",
          });
        }, 2000);
      }
    }
  }

  onCreateSubmit(e) {
    console.log(this.state.error);
    e.preventDefault();
    submitValidator(e.target);
    let { data } = this.state;
    let details;
    details = {
      empId: localStorage.getItem("id"),
      emailId: this.state.dataEmployee.email,
      homeContact: data.homeContact,
      emerContact: data.emerContact,
      contactPerson: data.contactPerson,
      personRelation: data.personRelation,
      spouseName: data.spouseName,
      spouseContact: data.spouseContact || 0,
      fatherName: data.fatherName,
      otherDepend: data.otherDepend,
      bloodGroup: data.bloodGroup,
      maritalStatus: data.maritalStatus,
      presentAddr: data.presentAddr,
      permanentAddr: data.permanentAddr,
      createdBy: localStorage.getItem("id"),
    };
    if (valid) {
      axios
        .post(CREATE_PERSONAL_INFO, details ? details : null, {
          withCredentials: true,
        })
        .then((res) => {
          this.setState({
            message: res.data,
          });
          setTimeout(() => {
            this.setState({
              message: "",
            });
            this.props.back();
          }, 2000);
        })
        .catch((err) => {
          this.setState({
            error: err.message,
          });
          setTimeout(() => {
            this.setState({
              error: "",
            });
          }, 2000);
        });
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }

    let { dataEmployee } = this.state;
    if (this.state.dataEmployee) {
      let details2 = {
        empId: localStorage.getItem("id"),
        mobileNumber: dataEmployee.mobileNumber,
        firstName: dataEmployee.firstName,
        middleName: dataEmployee.middleName,
        lastName: dataEmployee.lastName,
        genderType: dataEmployee.genderType,
        dob: dataEmployee.dob,
      };
      if (valid) {
        axios
          .post(EMPLOYEE_UPDATE, details2, { withCredentials: true })
          .then((res) => {
            this.setState({
              message: res.data,
            });
            setTimeout(() => {
              this.setState({
                message: "",
              });
              this.props.back();
            }, 2000);
          })
          .catch((err) => {
            this.setState({
              error: err.message,
            });
            setTimeout(() => {
              this.setState({
                error: "",
              });
            }, 2000);
          });
      } else {
        this.setState({
          error: "Enter all mandatory fields",
        });
        setTimeout(() => {
          this.setState({
            error: "",
          });
        }, 2000);
      }
    }
  }

  render() {
    console.log(this.state.error);
    const { data, dataEmployee } = this.state;

    return (
      <div>
        <h5 className="bold center">Personal Information</h5>
        {data.empPerId ? (
          <Form onSubmit={this.onSubmit} className="p-2" noValidate>
            <Row>
              <Col md="4">
                <FormComponent
                  labelClass="bold label-sm"
                  label="First Name"
                  type="text"
                  name="firstName"
                  value={dataEmployee && dataEmployee.firstName}
                  change={this.onChange1}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Middle Name"
                  type="text"
                  name="middleName"
                  value={dataEmployee && dataEmployee.middleName}
                  change={this.onChange1}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Last Name"
                  type="text"
                  name="lastName"
                  value={dataEmployee && dataEmployee.lastName}
                  change={this.onChange1}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Email"
                  type="email"
                  name="email"
                  value={dataEmployee && dataEmployee.email}
                  change={this.onChange1}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Mobile Number"
                  type="tel"
                  name="mobileNumber"
                  value={dataEmployee && dataEmployee.mobileNumber}
                  change={this.onChange1}
                  maxlength="12"
                  required={true}
                />
                <FormGroup>
                  <Label className="bold label-sm">Gender</Label>
                  <Input
                    type="select"
                    name="genderType"
                    value={dataEmployee && dataEmployee.genderType}
                    className="form-control-sm"
                    onChange={this.onChange1}
                    required={true}
                  >
                    <option value="Female">Female</option>
                    <option value="Male">Male</option>
                    <option value="Other">Other</option>
                  </Input>
                </FormGroup>
                <FormComponent
                  labelClass="bold label-sm"
                  label="Date of birth"
                  type="date"
                  name="dob"
                  value={dataEmployee && dataEmployee.dob}
                  change={this.onChange1}
                  required={true}
                />
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label className="bold label-sm">Marital Status</Label>
                  <Input
                    type="select"
                    name="maritalStatus"
                    value={data && data.maritalStatus}
                    className="form-control-sm"
                    onChange={this.onChange}
                    required={true}
                  >
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                    <option value="Divorced">Divorced</option>
                    <option value="Widowed">Widowed</option>
                  </Input>
                </FormGroup>
                <FormComponent
                  labelClass="bold label-sm"
                  label="Spouse Name"
                  type="text"
                  name="spouseName"
                  value={data && data.spouseName}
                  change={this.onChange}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Spouse Contact No."
                  type="number"
                  name="spouseContact"
                  value={data && data.spouseContact}
                  change={this.onChange}
                  // required ={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Home Conatct No."
                  type="number"
                  name="homeContact"
                  value={data.homeContact}
                  change={this.onChange}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Emergency Contact Person"
                  type="text"
                  name="contactPerson"
                  value={data && data.contactPerson}
                  change={this.onChange}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Emergency Contact No."
                  type="number"
                  name="emerContact"
                  value={data && data.emerContact}
                  change={this.onChange}
                  required={true}
                />
              </Col>
              <Col md="4">
                <FormComponent
                  labelClass="bold label-sm"
                  label="Contact Person Relation"
                  type="text"
                  name="personRelation"
                  value={data && data.personRelation}
                  change={this.onChange}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Father's Name"
                  type="text"
                  name="fatherName"
                  value={data && data.fatherName}
                  change={this.onChange}
                  required={true}
                />
                <FormGroup>
                  <Label className="bold label-sm">Blood Group</Label>
                  <Input
                    type="select"
                    name="bloodGroup"
                    value={data && data.bloodGroup}
                    className="form-control-sm"
                    onChange={this.onChange}
                    required={true}
                  >
                    <option value="A+">A Positive</option>
                    <option value="A-">A Negative</option>
                    <option value="B+">B Positive</option>
                    <option value="B-">B Negative</option>
                    <option value="AB+">AB Positive</option>
                    <option value="AB-">AB Negative</option>
                    <option value="O+">O Positive</option>
                    <option value="O-">O Negative</option>
                  </Input>
                </FormGroup>
                <FormComponent
                  labelClass="bold label-sm"
                  label="Other Dependants"
                  type="text"
                  name="otherDepend"
                  value={data && data.otherDepend}
                  change={this.onChange}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Present Address"
                  type="text"
                  name="presentAddr"
                  value={data && data.presentAddr}
                  change={this.onChange}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Permanent Address"
                  type="text"
                  name="permanentAddr"
                  value={data && data.permanentAddr}
                  change={this.onChange}
                  required={true}
                />
              </Col>
            </Row>
            <div className="center">
              <button type="submit" className="btn login-button white-text">
                Update
              </button>
            </div>
          </Form>
        ) : (
          <Form onSubmit={this.onCreateSubmit} className="p-2" noValidate>
            <Row>
              <Col md="4">
                <FormComponent
                  labelClass="bold label-sm"
                  label="First Name"
                  type="text"
                  name="firstName"
                  value={dataEmployee && dataEmployee.firstName}
                  change={this.onChange1}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Middle Name"
                  type="text"
                  name="middleName"
                  value={dataEmployee && dataEmployee.middleName}
                  change={this.onChange1}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Last Name"
                  type="text"
                  name="lastName"
                  value={dataEmployee && dataEmployee.lastName}
                  change={this.onChange1}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Email"
                  type="email"
                  name="email"
                  value={dataEmployee && dataEmployee.email}
                  change={this.onChange1}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Mobile Number"
                  type="tel"
                  name="mobileNumber"
                  value={dataEmployee && dataEmployee.mobileNumber}
                  change={this.onChange1}
                  maxlength="12"
                  required={true}
                />
                <FormGroup>
                  <Label className="bold label-sm">Gender</Label>
                  <Input
                    type="select"
                    name="genderType"
                    value={dataEmployee && dataEmployee.genderType}
                    className="form-control-sm"
                    onChange={this.onChange1}
                    required={true}
                  >
                    <option value="Female">Female</option>
                    <option value="Male">Male</option>
                    <option value="Other">Other</option>
                  </Input>
                </FormGroup>
                <FormComponent
                  labelClass="bold label-sm"
                  label="Date of birth"
                  type="date"
                  name="dob"
                  value={dataEmployee && dataEmployee.dob}
                  change={this.onChange1}
                  required={true}
                />
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label className="bold label-sm">Marital Status</Label>
                  <Input
                    type="select"
                    name="maritalStatus"
                    value={data && data.maritalStatus}
                    className="form-control-sm"
                    onChange={this.onChange}
                    required={true}
                  >
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                    <option value="Divorced">Divorced</option>
                    <option value="Widowed">Widowed</option>
                  </Input>
                </FormGroup>
                <FormComponent
                  labelClass="bold label-sm"
                  label="Spouse Name"
                  type="text"
                  name="spouseName"
                  value={data && data.spouseName}
                  change={this.onChange}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Spouse Contact No."
                  type="number"
                  name="spouseContact"
                  value={data && data.spouseContact}
                  change={this.onChange}
                  // required ={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Home Conatct No."
                  type="number"
                  name="homeContact"
                  value={data.homeContact}
                  change={this.onChange}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Emergency Contact Person"
                  type="text"
                  name="contactPerson"
                  value={data && data.contactPerson}
                  change={this.onChange}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Emergency Contact No."
                  type="number"
                  name="emerContact"
                  value={data && data.emerContact}
                  change={this.onChange}
                  required={true}
                />
              </Col>
              <Col md="4">
                <FormComponent
                  labelClass="bold label-sm"
                  label="Contact Person Relation"
                  type="text"
                  name="personRelation"
                  value={data && data.personRelation}
                  change={this.onChange}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Father's Name"
                  type="text"
                  name="fatherName"
                  value={data && data.fatherName}
                  change={this.onChange}
                  required={true}
                />
                <FormGroup>
                  <Label className="bold label-sm">Blood Group</Label>
                  <Input
                    type="select"
                    name="bloodGroup"
                    value={data && data.bloodGroup}
                    className="form-control-sm"
                    onChange={this.onChange}
                    required={true}
                  >
                    <option value="A+">A Positive</option>
                    <option value="A-">A Negative</option>
                    <option value="B+">B Positive</option>
                    <option value="B-">B Negative</option>
                    <option value="AB+">AB Positive</option>
                    <option value="AB-">AB Negative</option>
                    <option value="O+">O Positive</option>
                    <option value="O-">O Negative</option>
                  </Input>
                </FormGroup>
                <FormComponent
                  labelClass="bold label-sm"
                  label="Other Dependants"
                  type="text"
                  name="otherDepend"
                  value={data && data.otherDepend}
                  change={this.onChange}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Present Address"
                  type="text"
                  name="presentAddr"
                  value={data && data.presentAddr}
                  change={this.onChange}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Permanent Address"
                  type="text"
                  name="permanentAddr"
                  value={data && data.permanentAddr}
                  change={this.onChange}
                  required={true}
                />
              </Col>
            </Row>
            <div className="center">
              <button type="submit" className="btn login-button white-text">
                Create
              </button>
            </div>
          </Form>
        )}
        {this.state.message ? <Notifier message={this.state.message} /> : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
      </div>
    );
  }
}
export default PersonalInformation;
