import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import DateFns from "date-fns";
import UpdateIssuePO from "./UpdateIssuePO";

class IssuePODetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
    };
    this.updateNow = this.updateNow.bind(this);
  }

  updateNow() {
    this.setState({
      edit: true,
    });
  }

  render() {
    const { edit } = this.state;

    return (
      <Container className="card container-card p-3">
        <i
          className="material-icons pointer"
          style={{
            position: "absolute",
            top: "5px",
            right: "5px",
            color: "#C00",
          }}
          onClick={this.props.back}
        >
          cancel
        </i>
        {!edit ? (
          <div>
            <h5 className="center">PO Details</h5>
            <hr />
            <Row>
              <Col md="6">
                <ul style={{ listStyle: "none" }}>
                  <li>
                    <span className="label-sm bold poDetails-span-label-col-1">
                      PO Number
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.issuePODetails.poNo}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold poDetails-span-label-col-1">
                      PO Name
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.issuePODetails.poName}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold poDetails-span-label-col-1">
                      PO Type
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.issuePODetails.poType}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold poDetails-span-label-col-1">
                      PO To
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.issuePODetails.poTo}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold poDetails-span-label-col-1">
                      Name
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.issuePODetails.vpsName}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold poDetails-span-label-col-1">
                      Billing Addr
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.issuePODetails.vpsBillingAddress}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold poDetails-span-label-col-1">
                      Contact Name
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.issuePODetails.contactName}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold poDetails-span-label-col-1">
                      Contact Email
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.issuePODetails.contactEmail}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold poDetails-span-label-col-1">
                      contact No
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.issuePODetails.contactMobileNo}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold poDetails-span-label-col-1">
                      Ship To Location
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.issuePODetails.shipToLocation}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold poDetails-span-label-col-1">
                      Ship To Addr
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.issuePODetails.shipToAddress}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold poDetails-span-label-col-1">
                      Ship To Contact Email
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.issuePODetails.shipToContactMobileEmail}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold poDetails-span-label-col-1">
                      Ship To Contact No
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.issuePODetails.shipToContactMobileNo}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold poDetails-span-label-col-1">
                      PO Date
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.issuePODetails.poDate &&
                        DateFns.format(
                          this.props.issuePODetails.poDate,
                          "DD-MM-YYYY"
                        )}
                    </span>
                  </li>
                </ul>
              </Col>
              <Col md="6">
                <ul style={{ listStyle: "none" }}>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      PO Currency
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.issuePODetails.poCurrency}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      PO Description
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.issuePODetails.poDescription}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Pymt Terms
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.issuePODetails.pymtTerm}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Total Amount
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.issuePODetails.totalAmount}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Total GST
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.issuePODetails.totalGst}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      PO Total Amount
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.issuePODetails.poTotalAmount}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Instructions
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.issuePODetails.pymtInstructions}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Issue PO Status
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.issuePODetails.issuePoStatus}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Remarks
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.issuePODetails.remarks}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Created By
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.issuePODetails.createdBy}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Created On
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.issuePODetails.createdOn &&
                        DateFns.format(
                          this.props.issuePODetails.createdOn,
                          "DD-MM-YYYY"
                        )}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Updated By
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.issuePODetails.updatedBy}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Updated On
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.issuePODetails.updatedOn &&
                        DateFns.format(
                          this.props.issuePODetails.updatedOn,
                          "DD-MM-YYYY"
                        )}
                    </span>
                  </li>
                </ul>
              </Col>
            </Row>
            <div className="div">
              <hr />
              <div className="center">
                {/* {this.props.inquiry.inqStatus !== "Closed" &&
                this.props.inquiry.inqStatus !== "Lead" ? ( */}
                <button
                  className="btn login-button white-text"
                  onClick={this.updateNow}
                >
                  Update
                </button>
                {/* ) : (
                  ""
                )} */}
              </div>
            </div>
          </div>
        ) : (
          <UpdateIssuePO
            issuePO={this.props.issuePODetails}
            back={this.props.back}
          />
        )}
      </Container>
    );
  }
}

export default IssuePODetails;
