import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { FormText } from "reactstrap";

// components
import CustomizedDialogs from "../../common/customized-dialog/CustomizedDialogs";
import AddContact from "../../employee/crm/customers/AddContact";

// ----------------------------------------------------------------------------------

const filter = createFilterOptions();

// ----------------------------------------------------------------------------------

export default function SearchSelectBoxWithAdd(props) {
  const [open, toggleOpen] = React.useState(false);

  const handleClose = () => {
    toggleOpen(false);
    props.refresh && props.refresh();
  };

  return (
    <>
      <span className="bold label-sm">{props.label}</span>
      <Autocomplete
        name="spocName"
        value={props.value}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              // toggleOpen(true);
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
          } else {
            props.handleChange(newValue);
            props.reset();
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (filtered.length === 0) {
            filtered.push({
              inputValue: params.inputValue,
              contactName: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={props.options}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.contactName;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(option) => option.contactName}
        style={{ width: "100%", margin: "0 0 1.1rem 0" }}
        freeSolo
        size="small"
        renderInput={(params) => (
          <TextField {...params} variant="outlined" tabIndex={props.tabIndex} />
        )}
        required
      />
      <CustomizedDialogs open={open} handleClose={handleClose}>
        <AddContact
          back={handleClose}
          customerName={props.customerName}
          entityName={props.entityName}
          whichSpoc={props.whichSpoc}
        />
      </CustomizedDialogs>
      {props.spocError && (
        <FormText className="error">{props.spocError}</FormText>
      )}
    </>
  );
}
