import React from "react";
import ReactDOM from "react-dom";
import PgReactPortal from "./PG_React_Portal";
import registerServiceWorker from "./registerServiceWorker";

import ErrorBoundary from "./components/common/error_boundaries/ErrorBoundary";

ReactDOM.render(
  <ErrorBoundary>
    <PgReactPortal />
  </ErrorBoundary>,
  document.getElementById("root")
);
registerServiceWorker();


