import {
    PROJECT_TEAM_ASSIGNMENT_LIST,
    GET_ERRORS
} from './types';
import {
    GET_PROJECT_TEAM_ASSIGNMENT
} from '../../utils/routes';
import axios from  'axios';


export const getProjectTeamAssignments = (data) => dispatch => {
    axios.post(GET_PROJECT_TEAM_ASSIGNMENT, data, {withCredentials : true})
    .then(res => {
        dispatch({
            type: PROJECT_TEAM_ASSIGNMENT_LIST,
            payload: res.data
        })
    })
    .catch(err => {
        dispatch({
            type: GET_ERRORS,
            payload: err.message
        })
    })
}