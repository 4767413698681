import axios from "axios";
import { RELEASE_TAG_GET } from "../../types";
import { CURD_RELEASE_TAG, USER_RELEASE_TAG } from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addReleaseTag = (data, back) => (dispatch) => {
  axios
    .post(CURD_RELEASE_TAG, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getReleaseTag));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getReleaseTag = () => (dispatch) => {
  axios
    .get(CURD_RELEASE_TAG, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(RELEASE_TAG_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserReleaseTag = () => (dispatch) => {
  axios
    .get(USER_RELEASE_TAG, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(RELEASE_TAG_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateReleaseTag = (data, back) => (dispatch) => {
  axios
    .put(CURD_RELEASE_TAG, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteReleaseTag = (data) => (dispatch) => {
  axios
    .delete(CURD_RELEASE_TAG, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getReleaseTag));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
