import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import dateFns from "date-fns";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  FormText,
  Button,
} from "reactstrap";
// material ui
import { Divider } from "@material-ui/core";
// components
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import {
  Validator,
  valid,
  refresh,
  submitValidator,
  errors,
} from "../../../common/Validator";
import CreateEmployee from "./CreateEmployee";
import DetailsPageFooter from "../../../common/details_footer/DetailsPageFooter";
import EditIconButton from "../../../common/buttons/EditIconButton";
// redux
import { updateEmpPersonalInfo } from "../../../../redux/actions/hcm/hr/emp_management/empPersonalInfoAction";
import { getUserBusinessGeowise } from "../../../../redux/actions/hcm/business_setup/businessGeowiseAction";
import { getUserBusinessCountries } from "../../../../redux/actions/hcm/business_setup/businessCountriesAction";
import { getUserPersonalStatus } from "../../../../redux/actions/hcm/configurations/personalStatusAction";

// ------------------------------------------------------------------------------------------------------------------
class EmployeeDetails extends Component {
  constructor(props) {
    super(props);
    const {
      id,
      title,
      firstName,
      middleName,
      lastName,
      dob,
      geoName,
      countryName,
      gender,
      aadharNo,
      panNo,
      dialingCode,
      mobileNumber,
      personalEmailId,
      bloodGroup,
      personalStatus,
      socialSecurityNo,
    } = this.props.employee;
    this.state = {
      id: id,
      title: title,
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      dob: dob,
      geoName: geoName,
      country: `${countryName},${dialingCode}`,
      gender: gender,
      aadharNo: aadharNo,
      panNo: panNo,
      bloodGroup: bloodGroup,
      dialingCode: dialingCode,
      mobile: mobileNumber,
      personalEmail: personalEmailId,
      status: personalStatus,
      socialSecurityNo: socialSecurityNo,
      edit: false,
      employeeInfo: false,
      message: "",
      error: "",
      formErrors: {
        action: "",
      },
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    if (e.target.name === "country") {
      this.setState({
        dialingCode: e.target.value.split(",")[1],
      });
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  /*
This function update the employee details
body as the employee details
*/

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let fd = new FormData();

    fd.append("id", this.state.id);
    fd.append("title", this.state.title);
    fd.append("firstName", this.state.firstName);
    fd.append("middleName", this.state.middleName);
    fd.append("lastName", this.state.lastName);
    fd.append("dob", this.state.dob);
    fd.append("geoName", this.state.geoName);
    fd.append("countryName", this.state.country.split(",")[0]);
    fd.append("gender", this.state.gender);
    fd.append("aadharNo", this.state.aadharNo);
    fd.append("panNo", this.state.panNo);
    fd.append("dialingCode", this.state.dialingCode);
    fd.append("mobileNumber", this.state.mobile);
    fd.append("personalEmailId", this.state.personalEmail);
    fd.append("bloodGroup", this.state.bloodGroup);
    fd.append("socialSecurityNo", this.state.socialSecurityNo);
    fd.append("personalStatus", this.state.status);

    if (valid) {
      this.props.updateEmpPersonalInfo(fd, this.props.back);
    } else {
      let errors = this.state.formErrors;
      errors.action = "Please select the option";
      this.setState({
        error: "Enter all mandatory fields",
        formErrors: errors,
      });
      setTimeout(() => {
        this.setState({
          message: "",
          error: "",
        });
      }, 2000);
    }
  }

  /*
toggle function to set state for modle toggling
*/
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  componentDidMount() {
    refresh();
    this.props.getUserBusinessGeowise();
    this.props.getUserBusinessCountries();
    this.props.getUserPersonalStatus();
  }

  employeeInfoToggle = () => {
    this.setState({
      employeeInfo: !this.state.employeeInfo,
    });
    refresh();
  };

  render() {
    const { employeeInfo } = this.state;

    return (
      <Row className="mt-0">
        {!employeeInfo ? (
          <React.Fragment>
            <i
              onClick={this.props.back}
              className="material-icons pointer cancel-button"
            >
              cancel
            </i>

            {!this.state.edit ? (
              <Col md="12">
                <h5 className="bold pointer center pt-2">
                  Personal Information
                </h5>
                {(this.state.status === "New" ||
                  this.state.status === "Re-Hire") && (
                  <Button
                    color="primary"
                    style={{ position: "absolute", top: "-1px", left: "0px" }}
                    onClick={this.employeeInfoToggle}
                  >
                    Create Employee
                  </Button>
                )}
                {Object.keys(this.props.permission).length > 0 &&
                  this.props.permission.updation && (
                    <EditIconButton
                      classes="details-edit-button"
                      color="primary"
                      handleClick={() =>
                        this.setState({ edit: !this.state.edit })
                      }
                      title="edit Personal Info"
                    />
                  )}
                <Row>
                  <Col md="6">
                    <ul className="CustDetails-ul">
                      <li>
                        <span className="label-sm bold emp-span-label-col-1">
                          Title
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.title}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-1">
                          First Name
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.firstName}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-1">
                          Middle Name
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.middleName}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-1">
                          Last Name
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.lastName}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-1">
                          Date of Birth
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;
                          {dateFns.format(this.state.dob, "DD-MM-YYYY")}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-1">
                          Gender
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.gender}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-1">
                          Blood Group
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.bloodGroup}
                        </span>
                      </li>
                    </ul>
                  </Col>
                  <Col md="6">
                    <ul className="CustDetails-ul">
                      <li>
                        <span className="label-sm bold emp-span-label-col-2">
                          Aadhar No.
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.aadharNo}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-2">
                          PAN No.
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.panNo}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-2">
                          Geography
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.geoName}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-2">
                          Country
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;
                          {this.state.country.split(",")[0]}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-2">
                          Mobile No.
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;
                          {`${this.state.dialingCode}-${this.state.mobile}`}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-2">
                          Personal Email
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.personalEmail}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-2">
                          Social Security No.
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.socialSecurityNo}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-2">
                          Status
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.status}
                        </span>
                      </li>
                    </ul>
                  </Col>
                </Row>
                <Divider />
                <DetailsPageFooter
                  createdBy={this.props.employee.createdBy}
                  createdOn={this.props.employee.createdOn}
                  updatedBy={this.props.employee.updatedBy}
                  updatedOn={this.props.employee.updatedOn}
                />
              </Col>
            ) : (
              <Col md="12">
                <h5 className="bold pointer center pt-2">
                  Update Personal Information
                </h5>
                <Form onSubmit={this.onSubmit} className="p-3" noValidate>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <Label className="bold label-sm">Title</Label>
                        <Input
                          type="select"
                          name="title"
                          className="form-control-sm"
                          value={this.state.title}
                          onChange={this.onChange}
                          required={true}
                        >
                          <option value="" hidden>
                            Select...
                          </option>
                          <option value="Mr.">Mr.</option>
                          <option value="Ms.">Ms.</option>
                          <option value="Miss.">Miss.</option>
                          <option value="Mrs.">Mrs.</option>
                        </Input>
                        {errors.title && (
                          <FormText className="error">{errors.title}</FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold label-sm">First Name</Label>
                        <Input
                          type="text"
                          name="firstName"
                          className="form-control-sm"
                          value={this.state.firstName}
                          onChange={this.onChange}
                          onBlur={this.onBlur}
                          placeholder="First Name (required)"
                          required={true}
                        />
                        {errors.firstName && (
                          <FormText className="error">
                            {errors.firstName}
                          </FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold label-sm">Middle Name</Label>
                        <Input
                          type="text"
                          name="middleName"
                          className="form-control-sm"
                          value={this.state.middleName}
                          onChange={this.onChange}
                          placeholder="Middle name "
                          onBlur={this.onBlur}
                        />
                        {errors.middleName && (
                          <FormText className="error">
                            {errors.middleName}
                          </FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold label-sm">Last Name</Label>
                        <Input
                          type="text"
                          name="lastName"
                          className="form-control-sm"
                          value={this.state.lastName}
                          onChange={this.onChange}
                          onBlur={this.onBlur}
                          placeholder="Last name (required)"
                          required={true}
                        />
                        {errors.lastName && (
                          <FormText className="error">
                            {errors.lastName}
                          </FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold label-sm">Date of Birth</Label>
                        <Input
                          type="date"
                          name="dob"
                          className="form-control-sm"
                          value={dateFns.format(this.state.dob, "YYYY-MM-DD")}
                          onChange={this.onChange}
                          placeholder="Date of birth (required)"
                          required={true}
                        />
                        {errors.dob && (
                          <FormText className="error">{errors.dob}</FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Label className="bold label-sm">Gender</Label>
                        <Input
                          type="select"
                          name="gender"
                          className="form-control-sm"
                          value={this.state.gender}
                          onChange={this.onChange}
                          required={true}
                        >
                          <option value="" hidden>
                            Select...
                          </option>
                          <option value="Female">Female</option>
                          <option value="Male">Male</option>
                          <option value="Other">Other</option>
                        </Input>
                        {errors.gender && (
                          <FormText className="error">{errors.gender}</FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold label-sm">Blood Group</Label>
                        <Input
                          type="select"
                          name="bloodGroup"
                          className="form-control-sm"
                          value={this.state.bloodGroup}
                          onChange={this.onChange}
                          required={true}
                        >
                          <option value="" hidden>
                            Select...
                          </option>
                          <option value="O +ive">O +ive</option>
                          <option value="O -ive">O -ive</option>
                          <option value="A +ive">A +ive</option>
                          <option value="A -ive">A -ive</option>
                          <option value="B +ive">B +ive</option>
                          <option value="B -ive">B -ive</option>
                          <option value="AB +ive">AB +ive</option>
                          <option value="AB -ive">AB -ive</option>
                        </Input>
                        {errors.bloodGroup && (
                          <FormText className="error">
                            {errors.bloodGroup}
                          </FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold label-sm">Geography</Label>
                        <Input
                          type="select"
                          name="geoName"
                          className="form-control-sm"
                          value={this.state.geoName}
                          onChange={this.onChange}
                          required={true}
                        >
                          <option hidden value="">
                            Select...
                          </option>
                          {this.props.businessGeowise.data.length > 0 &&
                            this.props.businessGeowise.data.map((item) => {
                              return (
                                <option key={item.geoId} value={item.geoName}>
                                  {item.geoName}
                                </option>
                              );
                            })}
                        </Input>
                        {errors.geoName && (
                          <FormText className="error">
                            {errors.geoName}
                          </FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold label-sm">Country</Label>
                        <Input
                          type="select"
                          name="country"
                          className="form-control-sm"
                          value={this.state.country}
                          onChange={this.onChange}
                          required={true}
                        >
                          <option hidden value="">
                            Select...
                          </option>
                          {this.props.businessCountries.data.length > 0 &&
                            this.props.businessCountries.data.map((item) => {
                              if (this.state.geoName === item.geoName) {
                                return (
                                  <option
                                    key={item.countryId}
                                    value={`${item.countryName},${item.dialingCode}`}
                                  >
                                    {item.countryName}
                                  </option>
                                );
                              }
                            })}
                        </Input>
                        {errors.country && (
                          <FormText className="error">
                            {errors.country}
                          </FormText>
                        )}
                      </FormGroup>
                      <Row>
                        <Col md="2" className="p-0">
                          <FormGroup>
                            <Label className="bold label-sm"> </Label>
                            <Input
                              type="number"
                              name="dialingCode"
                              className="form-control-sm"
                              value={this.state.dialingCode}
                              onChange={this.onChange}
                              required={true}
                              disabled={true}
                            />
                            {errors.dialingCode && (
                              <FormText className="error">
                                {errors.dialingCode}
                              </FormText>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md="10" className="p-0">
                          <FormGroup>
                            <Label className="bold label-sm">Mobile No.</Label>
                            <Input
                              type="tel"
                              name="mobile"
                              className="form-control-sm"
                              value={this.state.mobile}
                              onChange={this.onChange}
                              placeholder="Mobile (required)"
                              pattern="[789][0-9]{9}"
                              required={true}
                            />
                            {errors.mobile && (
                              <FormText className="error">
                                {errors.mobile}
                              </FormText>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Label className="bold label-sm">
                          Personal Email Id
                        </Label>
                        <Input
                          type="email"
                          name="personalEmail"
                          className="form-control-sm"
                          value={this.state.personalEmail}
                          onChange={this.onChange}
                          placeholder="personalEmail (required)"
                          required={true}
                        />
                        {errors.personalEmail && (
                          <FormText className="error">
                            {errors.personalEmail}
                          </FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold label-sm">Aadhar No.</Label>
                        <Input
                          type="text"
                          name="aadharNo"
                          className="form-control-sm"
                          value={this.state.aadharNo}
                          onChange={this.onChange}
                          placeholder="Aadhar Number"
                          required={true}
                          pattern="^[0-9]{12}$"
                        />
                        {errors.aadharNo && (
                          <FormText className="error">
                            {errors.aadharNo}
                          </FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold label-sm">PAN No.</Label>
                        <Input
                          type="text"
                          name="panNo"
                          className="form-control-sm"
                          value={this.state.panNo}
                          onChange={this.onChange}
                          placeholder="PAN Number"
                          required={true}
                          pattern="^[A-Z]{5}[0-9]{4}[A-Z]{1}$"
                        />
                        {errors.panNo && (
                          <FormText className="error">{errors.panNo}</FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold label-sm">
                          Social Security No.
                        </Label>
                        <Input
                          type="text"
                          name="socialSecurityNo"
                          className="form-control-sm"
                          value={this.state.socialSecurityNo}
                          onChange={this.onChange}
                          placeholder="Social Security No"
                          pattern="^(?!666|000)[001-899]{3}-[01-99]{2}-[0001-9999]{4}$"
                        />
                        {errors.socialSecurityNo && (
                          <FormText className="error">
                            {errors.socialSecurityNo}
                          </FormText>
                        )}
                      </FormGroup>
                      {this.props.employee.personalStatus === "Inactive" && (
                        <FormGroup>
                          <Label className="bold label-sm">Status</Label>
                          <Input
                            type="select"
                            name="status"
                            className="form-control-sm"
                            value={this.state.status}
                            onChange={this.onChange}
                            required={true}
                          >
                            <option hidden value="">
                              Select...
                            </option>
                            {/* {this.props.personalStatus.data.length > 0 &&
                            this.props.personalStatus.data.map((item) => {
                              return (
                                <option
                                  key={item.id}
                                  value={item.persoanlStatus}
                                >
                                  {item.personalStatus}
                                </option>
                              );
                            })} */}
                            <option value="Re-Hire">Re-Hire</option>
                          </Input>
                          {errors.status && (
                            <FormText className="error">
                              {errors.status}
                            </FormText>
                          )}
                        </FormGroup>
                      )}
                    </Col>
                  </Row>

                  <div className="center">
                    <button type="submit" className="btn btn-custom">
                      Update
                    </button>
                  </div>
                </Form>
              </Col>
            )}
          </React.Fragment>
        ) : (
          <CreateEmployee
            back={this.employeeInfoToggle}
            apiBack={this.props.back}
            id={this.state.id}
          />
        )}
        {this.state.message ? <Notifier message={this.state.message} /> : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
        {this.props.empPersonalInfo.message ? (
          <Notifier message={this.props.empPersonalInfo.message} />
        ) : null}
        {this.props.empPersonalInfo.error ? (
          <ErrorNotifier message={this.props.empPersonalInfo.error} />
        ) : null}
      </Row>
    );
  }
}

EmployeeDetails.propTypes = {
  prop: PropTypes,
  getUserBusinessGeowise: PropTypes.func.isRequired,
  businessGeowise: PropTypes.object.isRequired,
  getUserBusinessCountries: PropTypes.func.isRequired,
  businessCountries: PropTypes.object.isRequired,
  updateEmpPersonalInfo: PropTypes.func.isRequired,
  empPersonalInfo: PropTypes.object.isRequired,
  getUserPersonalStatus: PropTypes.func.isRequired,
  personalStatus: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  businessGeowise: state.businessGeowise,
  businessCountries: state.businessCountries,
  empPersonalInfo: state.empPersonalInfo,
  personalStatus: state.personalStatus,
});

const mapDispatchToProps = (dispatch) => ({
  getUserBusinessGeowise: () => dispatch(getUserBusinessGeowise()),
  getUserBusinessCountries: () => dispatch(getUserBusinessCountries()),
  updateEmpPersonalInfo: (fd, back) =>
    dispatch(updateEmpPersonalInfo(fd, back)),
  getUserPersonalStatus: () => dispatch(getUserPersonalStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeDetails);
