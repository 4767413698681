import {  GET_UPLOADED_RESUME_RS , GET_ERRORS, SET_MESSAGE,GET_CURRENT_FILE_VALUE } from "../../../actions/types";

const initialState = {
  data: [],
  message: null,
  error: null,
  currentFile:[]
};

export default function (state = initialState, action) {
  switch (action.type) {
    case  GET_UPLOADED_RESUME_RS:
      return {
        ...state,
        data: action.payload,
   };
      case GET_CURRENT_FILE_VALUE :
        return {
          ...state,
          currentFile: action.payload,
        };
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case GET_ERRORS:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
