import axios from "axios";
import {
  SALES_POTENTIAL_ADD,
  SALES_POTENTIAL_GET,
  SALES_POTENTIAL_UPDATE,
  SALES_POTENTIAL_DELETE,
  GET_ERRORS,
} from "./types";
import {
  ADD_SALES_POTENTIAL,
  GET_SALES_POTENTIAL,
  UPDATE_SALES_POTENTIAL,
  DELETE_SALES_POTENTIAL,
} from "../../utils/routes";
import { setMessage } from "./salesActions";

export const addSalesPotential = (data, back) => (dispatch) => {
  axios
    .post(ADD_SALES_POTENTIAL, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getSalesPotential());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const getSalesPotential = () => (dispatch) => {
  axios
    .get(GET_SALES_POTENTIAL, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: SALES_POTENTIAL_GET,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};

export const updateSalesPotential = (data, back) => (dispatch) => {
  axios
    .post(UPDATE_SALES_POTENTIAL, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getSalesPotential());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const deleteSalesPotential = (data) => (dispatch) => {
  axios
    .post(DELETE_SALES_POTENTIAL, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getSalesPotential());
      }, 2000);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};
