import axios from "axios";
import { CONTACTS_FAMILY_DETAILS_GET } from "../../../types";
import { CURD_CONTACTS_FAMILY_DETAILS } from "../../../../../utils/routes";
import setSuccessMessage from "../../../common/setSuccessMessage";
import setErrorMessage from "../../../common/setErrorMessage";
import getDispatch from "../../../common/getDispatch";

export const getContactsFamilyDetails = () => (dispatch) => {
  axios
    .get(CURD_CONTACTS_FAMILY_DETAILS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CONTACTS_FAMILY_DETAILS_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateContactsFamilyDetails = (data, back) => (dispatch) => {
  axios
    .post(CURD_CONTACTS_FAMILY_DETAILS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
