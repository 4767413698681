import {
    EXTRA_CURRICULAR_GET,
    GET_ERRORS,
    SET_MESSAGE,
  } from "../../../actions/types";
  
  const initialState = {
    data: [],
    message: null,
    error: null,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case  EXTRA_CURRICULAR_GET:

    //   console.log(action.payload)
        return {
          ...state,
          data:action.payload,
        };
      case SET_MESSAGE:
        return {
          ...state,
          message: action.payload,
        };
      case GET_ERRORS:
        return {
          ...state,
          error: action.payload,
        };
      default:
        return state;
    }
  
  }