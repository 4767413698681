import React, { Component } from "react";
import { Form, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// components
import {
  Validator,
  submitValidator,
  valid,
  refresh,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
// redux
import { updateContactWorkingStatus } from "../../../../../redux/actions/crm/crm_setup/contactWorkingStatusAction";

// ----------------------------------------------------------------------------------------------------------------
class UpdateContactWorkingStatus extends Component {
  constructor(props) {
    super(props);
    const { id, conStatus, remarks } = this.props.data;
    this.state = {
      conStatusId: id,
      status: conStatus,
      remarks: remarks,
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      id: this.state.conStatusId,
      conStatus: this.state.status,
      remarks: this.state.remarks,
    };
    if (valid) {
      this.props.updateContactWorkingStatus(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    refresh();
    this.setState({
      error: "",
    });
  }

  render() {
    const { status, remarks, error } = this.state;

    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Update Contact Working Status</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="6" className="mx-auto">
              <FormComponent
                labelClass="bold label-sm"
                label="Contact Working Status"
                type="text"
                name="status"
                inputClass="form-control-sm"
                value={status}
                change={this.onChange}
                required={true}
                pattern="onlyLetters"
              />

              <FormComponent
                labelClass="bold label-sm"
                label="Remarks"
                type="textarea"
                name="remarks"
                inputClass="form-control-sm"
                value={remarks}
                change={this.onChange}
              />
            </Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Update
            </button>
          </div>
        </Form>
        {error && <ErrorNotifier message={error} />}
        {this.props.contactWorkingStatus.error && (
          <ErrorNotifier message={this.props.contactWorkingStatus.error} />
        )}
        {this.props.contactWorkingStatus.message && (
          <Notifier message={this.props.contactWorkingStatus.message} />
        )}
      </React.Fragment>
    );
  }
}

UpdateContactWorkingStatus.propTypes = {
  updateContactWorkingStatus: PropTypes.func.isRequired,
  contactWorkingStatus: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  contactWorkingStatus: state.contactWorkingStatus,
});

export default connect(mapStateToProps, { updateContactWorkingStatus })(
  UpdateContactWorkingStatus
);
