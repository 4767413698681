import axios from "axios";
import { HCM_USER_VAR_GET } from "../../types";
import { CURD_HCM_USER_VAR, USER_HCM_USER_VAR } from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addHcmUserVar = (data, back) => (dispatch) => {
  axios
    .post(CURD_HCM_USER_VAR, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getHcmUserVar));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getHcmUserVar = () => (dispatch) => {
  axios
    .get(CURD_HCM_USER_VAR, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(HCM_USER_VAR_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserHcmUserVar = () => (dispatch) => {
  axios
    .get(USER_HCM_USER_VAR, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(HCM_USER_VAR_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

// export const fetchEmpOrganizationName = () => (dispatch) => {
//   axios
//     .get(FETCH_EMP_ORGANIZATION_NAME, { withCredentials: true })
//     .then((res) => {
//       dispatch(getDispatch(ORGANIZATION_SETUP_FETCH, res));
//     })
//     .catch((err) => {
//       dispatch(setErrorMessage(err));
//     });
// };

export const updateHcmUserVar = (data, back) => (dispatch) => {
  axios
    .put(CURD_HCM_USER_VAR, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getHcmUserVar));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteHcmUserVar = (data) => (dispatch) => {
  axios
    .delete(CURD_HCM_USER_VAR, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getHcmUserVar));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
