import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

import { GET_CANDIDATE_ACTIVE_STATUS } from "../../types";
import {GET_STATUS_INFO_CAN } from "../../../../utils/routes";
import axios from "axios";

export const getCandidateActiveStatus = (data, back) => (dispatch) => {
    let body = {
        canUserId:data
    }
    axios
      .post(GET_STATUS_INFO_CAN, body, { withCredentials: true })
      .then((res) => {
        dispatch(getDispatch( GET_CANDIDATE_ACTIVE_STATUS, res));
      })
      .catch((err) => {
        dispatch(setErrorMessage(err));
      });
  };
  