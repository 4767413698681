import axios from "axios";
import { GET_CAN_TYPE_SKILL,GET_CAN_SKILL_RS_SELECT_TYPE,GET_CAN_SKILL_RS_ALL_SELECT_TYPE} from "../../types";
import {
ADD_CAN_SKILL_RS,
GET_CAN_SKILL_RS,
UPDATE_CAN_SKILL_RS,
DELETE_CAN_SKILL_RS,
GET_CAN_SKILL_RS_SELECT ,
GET_CAN_SKILL_RS_SELECT_ALL_API 
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addCanSkill = (data, back) => (dispatch) => {
  axios
    .post(ADD_CAN_SKILL_RS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getCanSkill));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getCanSkill = () => (dispatch) => {
  axios
    .get( GET_CAN_SKILL_RS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(GET_CAN_TYPE_SKILL, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getCanSkillSelect = () => (dispatch) => {
  axios
    .get( GET_CAN_SKILL_RS_SELECT , { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(GET_CAN_SKILL_RS_SELECT_TYPE, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getAllSkillsSelect = () => (dispatch) => {
  axios
    .get( GET_CAN_SKILL_RS_SELECT_ALL_API , { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(GET_CAN_SKILL_RS_ALL_SELECT_TYPE, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateCanSkill = (data, back) => (dispatch) => {
  axios
    .put( UPDATE_CAN_SKILL_RS , data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteCanSkill = (data) => (dispatch) => {
  axios
    .delete( DELETE_CAN_SKILL_RS, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getCanSkill));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
