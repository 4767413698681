import {
  EMP_MANAGEMENT_GET,
  EMP_MANAGEMENT_SEARCH,
  ACTIVE_EMPLOYEES_FETCH,
  EMP_DETAILS_FETCH,
  GET_ERRORS,
  SET_MESSAGE,
} from "../../../../actions/types";

const initialState = {
  data: [],
  activeEmployees: [],
  detailsData: [],
  message: null,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case EMP_MANAGEMENT_GET:
      return {
        ...state,
        data: action.payload,
      };
    case EMP_MANAGEMENT_SEARCH:
      return {
        ...state,
        data: action.payload,
      };
    case ACTIVE_EMPLOYEES_FETCH:
      return {
        ...state,
        activeEmployees: action.payload,
      };
    case EMP_DETAILS_FETCH:
      return {
        ...state,
        detailsData: action.payload,
      };
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case GET_ERRORS:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
