import {
    GET_CAN_TYPE_SKILL,
    GET_CAN_SKILL_RS_SELECT_TYPE,
    GET_CAN_SKILL_RS_ALL_SELECT_TYPE,
    GET_ERRORS,
    SET_MESSAGE,
  } from "../../../actions/types";
  
  const initialState = {
    data: [],
    selectData:[],
    message: null,
    error: null,
    allData:[]
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case   GET_CAN_TYPE_SKILL:
        return {
          ...state,
          data: action.payload,
        };
        case  GET_CAN_SKILL_RS_SELECT_TYPE:
          return {
            ...state,
            selectData: action.payload,
          };
          case  GET_CAN_SKILL_RS_ALL_SELECT_TYPE:
            return {
              ...state,
              allData: action.payload,
            };
      case SET_MESSAGE:
        return {
          ...state,
          message: action.payload,
        };
      case GET_ERRORS:
        return {
          ...state,
          error: action.payload,
        };
      default:
        return state;
    }
  }