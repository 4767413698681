import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import {
  errors,
  submitValidator,
  Validator,
  valid,
} from "../../../common/Validator";
import {
  CREATE_SERVICE_ACTIVITY,
  FETCH_SERVICE_ACTIVITY,
  UPDATE_SERVICE_ACTIVITY,
} from "../../../../utils/routes";
import axios from "axios";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import { getEmployeeDetails } from "../../../../redux/actions/employeeAction";
import { connect } from "react-redux";
import Hover from "../../../aside/Hover";
import FormComponent from "../../../common/FormComponent";

class TeamActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activityAddModal: false,
      activityId: "",
      activity: "",
      startDate: "",
      endDate: "",
      deliverables: "",
      assignedTo: "",
      description: "",
      status: "New",
      remarks: "",
      dateError: "",
      message: "",
      error: "",
      data: [],
      hover: false,
      cords: {},
      mouse: {},
      activityUpdateModal: false,
      dragModal: false,
      subItem: {},
    };

    this.activityAddModalToggle = this.activityAddModalToggle.bind(this);
    this.onChange = this.onChange.bind(this);
    this.activitySubmit = this.activitySubmit.bind(this);
    this.getData = this.getData.bind(this);
    this.toggleHoverOn = this.toggleHoverOn.bind(this);
    this.toggleHoverOff = this.toggleHoverOff.bind(this);
    this.activityUpdate = this.activityUpdate.bind(this);
    this.activityUpdateModalToggle = this.activityUpdateModalToggle.bind(this);
    this.activityUpdateSubmit = this.activityUpdateSubmit.bind(this);
    this.activityReset = this.activityReset.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.dragModalToggle = this.dragModalToggle.bind(this);
    this.dragSubmit = this.dragSubmit.bind(this);
  }

  /*
activityAddModalToggle function to toggle the activity add modal 
*/
  activityAddModalToggle() {
    this.setState({
      activityAddModal: !this.state.activityAddModal,
    });
    this.activityReset();
  }

  onChange(e) {
    Validator(e.target);
    if (e.target.name === "endDate") {
      if (Date.parse(e.target.value) < Date.parse(this.state.startDate)) {
        this.setState({
          dateError: "endDate must be greater than or equal to startDate",
        });
      } else {
        this.setState({
          [e.target.name]: e.target.value,
          dateError: "",
        });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  activitySubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      serviceId: this.props.serviceId,
      serviceActivityName: this.state.activity,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      deliverables: this.state.deliverables,
      assignedTo: this.state.assignedTo,
      description: this.state.description,
      activityStatus: this.state.status,
      remarks: this.state.remarks,
      createdBy: localStorage.getItem("id"),
    };
    axios
      .post(CREATE_SERVICE_ACTIVITY, data, { withCredentials: true })
      .then((res) => {
        this.setState({
          message: res.data,
        });
        setTimeout(() => {
          this.setState({
            message: "",
          });
          this.getData();
          this.activityAddModalToggle();
        }, 2000);
      })
      .catch((err) => {
        this.setState({
          error: err.message,
        });
        setTimeout(() => {
          this.setState({
            error: "",
          });
        }, 2000);
      });
  }

  componentDidMount() {
    this.props.getEmployeeDetails();
    this.getData();
  }

  getData() {
    let data = {
      serviceId: this.props.serviceId,
    };

    axios
      .post(FETCH_SERVICE_ACTIVITY, data, { withCredentials: true })
      .then((res) => {
        this.setState({
          data: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          error: err.message,
        });
        setTimeout(() => {
          this.setState({
            error: "",
          });
        }, 2000);
      });
  }

  toggleHoverOn(e, item) {
    this.setState({
      hover: true,
      cords: {
        one: item.description,
        two: item.deliverables,
        three: item.remarks,
      },
      mouse: {
        x: e.screenX,
        y: e.screenY,
      },
    });
  }

  toggleHoverOff() {
    this.setState({
      hover: false,
    });
  }

  activityUpdate(item) {
    this.setState({
      activityId: item.serviceActivityId,
      activity: item.serviceActivityName,
      startDate: item.startDate,
      endDate: item.endDate,
      deliverables: item.deliverables,
      assignedTo: item.assignedTo,
      description: item.description,
      status: item.activityStatus,
      remarks: item.remarks,
      activityUpdateModal: true,
    });
  }

  activityUpdateModalToggle() {
    this.setState({
      activityUpdateModal: !this.state.activityUpdateModal,
    });
  }

  activityUpdateSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      serviceActivityId: this.state.activityId,
      serviceId: this.props.serviceId,
      serviceActivityName: this.state.activity,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      deliverables: this.state.deliverables,
      assignedTo: this.state.assignedTo,
      description: this.state.description,
      activityStatus: this.state.status,
      remarks: this.state.remarks,
      updatedBy: localStorage.getItem("id"),
    };
    axios
      .post(UPDATE_SERVICE_ACTIVITY, data, { withCredentials: true })
      .then((res) => {
        this.setState({
          message: res.data,
        });
        setTimeout(() => {
          this.setState({
            message: "",
          });
          this.getData();
          this.activityUpdateModalToggle();
        }, 2000);
      })
      .catch((err) => {
        this.setState({
          error: err.message,
        });
        setTimeout(() => {
          this.setState({
            error: "",
          });
        }, 2000);
      });
  }

  activityReset() {
    this.setState({
      activity: "",
      startDate: "",
      endDate: "",
      deliverables: "",
      assignedTo: "",
      description: "",
      status: "New",
      remarks: "",
    });
  }

  dragModalToggle() {
    this.setState({
      dragModal: !this.state.dragModal,
    });
  }

  onDragOver(e) {
    e.preventDefault();
  }

  onDragStart(e, item) {
    e.dataTransfer.setData("item", item.serviceActivityId);
    this.setState({
      subItem: item.serviceActivityId,
    });
  }

  async onDrop(e, cat) {
    let id = e.dataTransfer.getData("item");
    let newData;
    newData = this.state.data.filter((item) => {
      if (item.serviceActivityId === parseInt(id, 10)) {
        item.status = cat;
      }
      return newData;
    });
    this.setState({
      ...this.state,
      newData,
    });
    await this.dragModalToggle();
  }

  dragSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    const data = this.state.data.filter((item) => {
      if (item.serviceActivityId === this.state.subItem) {
        return item;
      }
      return null;
    });
    let data1 = {
      serviceActivityId: data[0].serviceActivityId,
      serviceId: this.props.serviceId,
      serviceActivityName: data[0].serviceActivityName,
      startDate: data[0].startDate,
      endDate: data[0].endDate,
      deliverables: data[0].deliverables,
      assignedTo: data[0].assignedTo,
      description: this.state.description,
      activityStatus: data[0].status,
      remarks: this.state.remarks,
      updatedBy: localStorage.getItem("id"),
    };
    axios
      .post(UPDATE_SERVICE_ACTIVITY, data1, { withCredentials: true })
      .then((res) => {
        this.setState({
          message: res.data,
        });
        setTimeout(() => {
          this.setState({
            message: "",
          });
          this.getData();
          this.dragModalToggle();
        }, 2000);
      })
      .catch((err) => {
        this.setState({
          error: err.message,
        });
        setTimeout(() => {
          this.setState({
            error: "",
          });
        }, 2000);
      });
  }

  render() {
    const {
      activityAddModal,
      data,
      hover,
      cords,
      mouse,
      activityUpdateModal,
      dragModal,
    } = this.state;

    return (
      <React.Fragment>
        <Modal isOpen={dragModal}>
          <ModalHeader toggle={this.dragModalToggle}>
            Confirm changes?
          </ModalHeader>
          <ModalBody>
            <Form className="p-3" onSubmit={this.dragSubmit} noValidate>
              <FormComponent
                labelClass="bold label-sm"
                label="Remarks"
                type="text"
                name="remarks"
                change={this.onChange}
                value={this.state.remarks}
                required={true}
              />
              <FormGroup className="center">
                <button type="submit" className="btn login-button white-text">
                  Submit
                </button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
        {hover && (
          <Hover
            cords={cords}
            labels={{
              one: "Description",
              two: "Deliverables",
              three: "Remarks",
            }}
            mouse={this.state.mouse}
          />
        )}
        <Container className="card container-card">
          <button
            className=" btn add-button white-text label-sm"
            onClick={this.activityAddModalToggle}
          >
            Add
          </button>
          <h5 className="bold pointer center pt-2">Team Activity</h5>
          <i
            onClick={() => this.props.back()}
            className="material-icons pointer cancel-button"
          >
            cancel
          </i>
          <Row>
            <Col
              md="4"
              className="card p-2"
              onDragOver={this.onDragOver}
              onDrop={(e) => this.onDrop(e, "New")}
            >
              <h6 className="center bold m-2">New</h6>
              <ul className="crm-flex-around InqList">
                <li className="list-crm-item bold pad-projActivity-id">ID</li>
                <li className="list-crm-item bold pad-projActivity-name">
                  Activity
                </li>
                <li className="list-crm-item bold pad-projActivity-date">
                  StartDate
                </li>
                <li className="list-crm-item bold pad-projActivity-date">
                  EndDate
                </li>
                <li className="list-crm-item bold pad-projActivity-date">
                  AssignedTo
                </li>
              </ul>
              {data &&
                data.map((item) => {
                  if (item.activityStatus === "New") {
                    return (
                      <ul
                        key={item.serviceActivityId}
                        // onClick={() => this.select(item)}
                        draggable
                        onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                        onMouseLeave={this.toggleHoverOff}
                        onDragStart={(e) => this.onDragStart(e, item)}
                        className="crm-flex-around InqList pointer"
                      >
                        <li className="list-crm-table-item pad-projActivity-id">
                          {item.serviceActivityId}
                        </li>
                        <li className="list-crm-table-item pad-projActivity-name">
                          {item.serviceActivityName}
                        </li>
                        <li className="list-crm-table-item pad-projActivity-date">
                          {item.startDate}
                        </li>
                        <li className="list-crm-table-item pad-projActivity-date">
                          {item.endDate}
                        </li>
                        <li className="list-crm-table-item pad-projActivity-date">
                          {item.assignedTo}
                        </li>
                        {hover && (
                          <li className="list-crm-table-item">
                            <span
                              className="product-pq-button"
                              style={{ color: "blue", fontSize: "20px" }}
                              onClick={() => this.activityUpdate(item)}
                            >
                              edit
                            </span>
                          </li>
                        )}
                      </ul>
                    );
                  } else {
                    return null;
                  }
                })}
            </Col>
            <Col
              md="4"
              className="card p-2"
              onDragOver={this.onDragOver}
              onDrop={(e) => this.onDrop(e, "WIP")}
            >
              <h6 className="center bold m-2">Work in progress</h6>
              <ul className="crm-flex-around InqList">
                <li className="list-crm-item bold pad-projActivity-id">ID</li>
                <li className="list-crm-item bold pad-projActivity-name">
                  Activity
                </li>
                <li className="list-crm-item bold pad-projActivity-date">
                  StartDate
                </li>
                <li className="list-crm-item bold pad-projActivity-date">
                  EndDate
                </li>
                <li className="list-crm-item bold pad-projActivity-date">
                  AssignedTo
                </li>
              </ul>
              {data &&
                data.map((item) => {
                  if (item.activityStatus === "WIP") {
                    return (
                      <ul
                        key={item.serviceActivityId}
                        // onClick={() => this.select(item)}
                        draggable
                        onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                        onMouseLeave={this.toggleHoverOff}
                        onDragStart={(e) => this.onDragStart(e, item)}
                        className="crm-flex-around InqList pointer"
                      >
                        <li className="list-crm-table-item pad-projActivity-id">
                          {item.serviceActivityId}
                        </li>
                        <li className="list-crm-table-item pad-projActivity-name">
                          {item.serviceActivityName}
                        </li>
                        <li className="list-crm-table-item pad-projActivity-date">
                          {item.startDate}
                        </li>
                        <li className="list-crm-table-item pad-projActivity-date">
                          {item.endDate}
                        </li>
                        <li className="list-crm-table-item pad-projActivity-date">
                          {item.assignedTo}
                        </li>
                        {hover && (
                          <li className="list-crm-table-item">
                            <span
                              className="product-pq-button"
                              style={{ color: "blue", fontSize: "20px" }}
                              onClick={() => this.activityUpdate(item)}
                            >
                              edit
                            </span>
                          </li>
                        )}
                      </ul>
                    );
                  } else {
                    return null;
                  }
                })}
            </Col>
            <Col
              md="4"
              className="card p-2"
              onDrop={(e) => this.onDrop(e, "Closed")}
              onDragOver={this.onDragOver}
            >
              <h6 className="center bold m-2">Closed</h6>
              <ul className="crm-flex-around InqList">
                <li className="list-crm-item bold pad-projActivity-id">ID</li>
                <li className="list-crm-item bold pad-projActivity-name">
                  Activity
                </li>
                <li className="list-crm-item bold pad-projActivity-date">
                  StartDate
                </li>
                <li className="list-crm-item bold pad-projActivity-date">
                  EndDate
                </li>
                <li className="list-crm-item bold pad-projActivity-date">
                  AssignedTo
                </li>
              </ul>
              {data &&
                data.map((item) => {
                  if (
                    item.activityStatus !== "New" &&
                    item.activityStatus !== "WIP"
                  ) {
                    return (
                      <ul
                        key={item.serviceActivityId}
                        // onClick={() => this.select(item)}
                        draggable
                        onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                        onMouseLeave={this.toggleHoverOff}
                        onDragStart={(e) => this.onDragStart(e, item)}
                        className="crm-flex-around InqList pointer"
                      >
                        <li className="list-crm-table-item pad-projActivity-id">
                          {item.serviceActivityId}
                        </li>
                        <li className="list-crm-table-item pad-projActivity-name">
                          {item.serviceActivityName}
                        </li>
                        <li className="list-crm-table-item pad-projActivity-date">
                          {item.startDate}
                        </li>
                        <li className="list-crm-table-item pad-projActivity-date">
                          {item.endDate}
                        </li>
                        <li className="list-crm-table-item pad-projActivity-date">
                          {item.assignedTo}
                        </li>
                        {hover && (
                          <li className="list-crm-table-item">
                            <span
                              className="product-pq-button"
                              style={{ color: "blue", fontSize: "20px" }}
                              onClick={() => this.activityUpdate(item)}
                            >
                              edit
                            </span>
                          </li>
                        )}
                      </ul>
                    );
                  } else {
                    return null;
                  }
                })}
            </Col>
          </Row>
        </Container>
        <Modal size="md" isOpen={activityUpdateModal}>
          <ModalHeader toggle={this.activityUpdateModalToggle}>
            Update Activity
          </ModalHeader>
          <ModalBody>
            <Form
              className="p-3"
              onSubmit={this.activityUpdateSubmit}
              noValidate
            >
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label className="bold">Activity</Label>
                    <Input
                      type="text"
                      name="activity"
                      onChange={this.onChange}
                      value={this.state.activity}
                      className="form-control-sm"
                      required={true}
                    />
                    {errors.activity && (
                      <FormText className="error">{errors.activity}</FormText>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label className="bold">Start Date</Label>
                    <Input
                      type="date"
                      name="startDate"
                      value={this.state.startDate}
                      onChange={this.onChange}
                      className="form-control-sm"
                      required={true}
                    />
                    {errors.startDate && (
                      <FormText className="error">{errors.startDate}</FormText>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label className="bold">End Date</Label>
                    <Input
                      type="date"
                      name="endDate"
                      value={this.state.endDate}
                      onChange={this.onChange}
                      className="form-control-sm"
                      min={this.state.startDate}
                      required={true}
                    />
                    {errors.endDate && (
                      <FormText className="error">{errors.endDate}</FormText>
                    )}
                    {this.state.dateError && (
                      <FormText className="error">
                        {this.state.dateError}
                      </FormText>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label className="bold">Deliverables</Label>
                    <Input
                      name="deliverables"
                      type="text"
                      value={this.state.deliverables}
                      className="form-control-sm"
                      onChange={this.onChange}
                      required={true}
                    />
                    {errors.deliverables && (
                      <FormText className="error">
                        {errors.deliverables}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label className="bold">Assigned To</Label>
                    <Input
                      type="select"
                      name="assignedTo"
                      value={this.state.assignedTo}
                      onChange={this.onChange}
                      className="form-control-sm "
                    >
                      <option hidden>Not Assigned...</option>
                      {this.props.employee &&
                        this.props.employee.data.map((item) => {
                          if (item.empActiveStatus === "Active") {
                            return (
                              <option key={item.empId} value={item.empId}>
                                {item.empId}-{item.firstName}
                              </option>
                            );
                          }
                        })}
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label className="bold">Status</Label>
                    <Input
                      type="select"
                      name="status"
                      value={this.state.status}
                      onChange={this.onChange}
                      className="form-control-sm"
                    >
                      <option value="New">New</option>
                      <option value="WIP">WIP</option>
                      <option value="Closed">Closed</option>
                      <option value="Hold">Hold</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label className="bold">Description</Label>
                    <Input
                      name="description"
                      type="textarea"
                      value={this.state.description}
                      className="form-control-sm"
                      onChange={this.onChange}
                      required={true}
                    />
                    {errors.description && (
                      <FormText className="error">
                        {errors.description}
                      </FormText>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label className="bold">Remarks</Label>
                    <Input
                      name="remarks"
                      type="textarea"
                      value={this.state.remarks}
                      className="form-control-sm"
                      onChange={this.onChange}
                      required={true}
                    />
                    {errors.remarks && (
                      <FormText className="error">{errors.remarks}</FormText>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup className="center">
                <button type="submit" className="btn white-text login-button">
                  Update
                </button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
        <Modal size="md" isOpen={activityAddModal}>
          <ModalHeader toggle={this.activityAddModalToggle}>
            Add new Activity
          </ModalHeader>
          <ModalBody>
            <Form className="p-3" onSubmit={this.activitySubmit} noValidate>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label className="bold">Activity</Label>
                    <Input
                      type="text"
                      name="activity"
                      onChange={this.onChange}
                      value={this.state.activity}
                      className="form-control-sm"
                      required={true}
                    />
                    {errors.activity && (
                      <FormText className="error">{errors.activity}</FormText>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label className="bold">Start Date</Label>
                    <Input
                      type="date"
                      name="startDate"
                      value={this.state.startDate}
                      onChange={this.onChange}
                      className="form-control-sm"
                      required={true}
                    />
                    {errors.startDate && (
                      <FormText className="error">{errors.startDate}</FormText>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label className="bold">End Date</Label>
                    <Input
                      type="date"
                      name="endDate"
                      value={this.state.endDate}
                      onChange={this.onChange}
                      className="form-control-sm"
                      min={this.state.startDate}
                      required={true}
                    />
                    {errors.endDate && (
                      <FormText className="error">{errors.endDate}</FormText>
                    )}
                    {this.state.dateError && (
                      <FormText className="error">
                        {this.state.dateError}
                      </FormText>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label className="bold">Deliverables</Label>
                    <Input
                      name="deliverables"
                      type="text"
                      value={this.state.deliverables}
                      className="form-control-sm"
                      onChange={this.onChange}
                      required={true}
                    />
                    {errors.deliverables && (
                      <FormText className="error">
                        {errors.deliverables}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label className="bold">Assigned To</Label>
                    <Input
                      type="select"
                      name="assignedTo"
                      value={this.state.assignedTo}
                      onChange={this.onChange}
                      className="form-control-sm "
                    >
                      <option hidden>Not Assigned...</option>
                      {this.props.employee &&
                        this.props.employee.data.map((item) => {
                          return (
                            <option key={item.empId} value={item.empId}>
                              {item.empId}-{item.firstName}
                            </option>
                          );
                        })}
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label className="bold">Description</Label>
                    <Input
                      name="description"
                      type="textarea"
                      value={this.state.description}
                      className="form-control-sm"
                      onChange={this.onChange}
                      required={true}
                    />
                    {errors.description && (
                      <FormText className="error">
                        {errors.description}
                      </FormText>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label className="bold">Status</Label>
                    <Input
                      type="select"
                      name="status"
                      value={this.state.status}
                      onChange={this.onChange}
                      className="form-control-sm"
                    >
                      <option value="New">New</option>
                      <option value="WIP">WIP</option>
                      <option value="Closed">Closed</option>
                      <option value="Hold">Hold</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label className="bold">Remarks</Label>
                    <Input
                      name="remarks"
                      type="textarea"
                      value={this.state.remarks}
                      className="form-control-sm"
                      onChange={this.onChange}
                      required={true}
                    />
                    {errors.remarks && (
                      <FormText className="error">{errors.remarks}</FormText>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup className="center">
                <button type="submit" className="btn white-text login-button">
                  Create
                </button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
        {this.state.message ? <Notifier message={this.state.message} /> : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  employee: state.employee,
});

export default connect(mapStateToProps, { getEmployeeDetails })(TeamActivity);
