import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Row, Col, Card, Badge } from "reactstrap";
// material ui
import { IconButton } from "@material-ui/core";
// material icons
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import FilterListIcon from "@material-ui/icons/FilterList";
// components
import EmployeeDetails from "./EmployeeDetails";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import SearchBar from "../../../common/searchbar/SearchBar";
import CustTooltip from "../../../common/tooltip/CustTooltip";
// redux
import {
  getEmployee,
  searchUser,
} from "../../../../redux/actions/iam/emp_onboarding/empOnboardingAction";
import { resendEmail } from "../../../../redux/actions/iam/authentication/authenticationAndAuthorizationAction";

// --------------------------------------------------------------------------------------------------

class Employees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: null,
      searchTerm: "",
      openFilter: false,
      filterValue: "Active",
      message: "",
      error: "",
    };
    this.select = this.select.bind(this);
    this.back = this.back.bind(this);
    this.search = this.search.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.getData = this.getData.bind(this);
  }

  select(item) {
    this.setState({
      selection: item,
    });
  }

  /*
Searching the Employee
*/
  search(e) {
    e.preventDefault();
    this.setState({
      searchTerm: e.target.value,
    });
    this.onSearch(e.target.value);
  }

  /*
onSearch function for setting the search data in redux store by calling the searchAction
*/
  onSearch(value) {
    let data = {
      search: value,
    };
    this.props.searchUser(data);
  }

  /*
In back function called the getData function for after the update successfully it redirect to 
the employee list page with updated data
*/
  back() {
    this.setState({
      selection: false,
    });
    this.getData();
  }

  /*
getData function for list all the employee details in database
*/
  getData() {
    this.props.getEmployee();
  }

  componentDidMount() {
    this.getData();
  }

  resendMail(value) {
    let data = {
      orgEmail: value,
    };
    this.props.resendEmail(data);
  }

  render() {
    let shrink;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");
    const { selection } = this.state;

    return (
      <div className={shrink}>
        <Card className="container-card m-2">
          <div>
            {!selection
              ? typeof this.props.location.serviceAccessData !== "undefined" &&
                this.props.location.serviceAccessData.creation && (
                  <Link
                    to={{
                      pathname: "/pie/iam/user_onboarding/create",
                      accessData: this.props.location.serviceAccessData,
                      moduleName: this.props.location.moduleName,
                      data: this.props.location.data,
                    }}
                  >
                    <CustTooltip title="Onboard User">
                      <button className="btn white-text label-sm new-btn">
                        New
                      </button>
                    </CustTooltip>
                  </Link>
                )
              : null}
          </div>
          {!selection && (
            <div className="hcm-onboarding-search-filter">
              <SearchBar
                onChange={this.search}
                value={this.state.searchTerm}
                placeholder="Search for Users"
                // onSubmit={this.onSearch}
                title="Search User"
              />
              <div className="hcm-onboarding-search-filter-icon">
                <CustTooltip title="Filter">
                  <IconButton
                    aria-label="filter"
                    onClick={() =>
                      this.setState({ openFilter: !this.state.openFilter })
                    }
                    style={{
                      padding: ".125rem",
                    }}
                  >
                    <FilterListIcon className="icon-color" />
                  </IconButton>
                </CustTooltip>
                <ul
                  className={
                    this.state.openFilter
                      ? "display-filter"
                      : "not-display-filter"
                  }
                >
                  <li
                    onClick={() =>
                      this.setState({
                        filterValue: "Active",
                        openFilter: false,
                      })
                    }
                  >
                    Active
                  </li>
                  <li
                    onClick={() =>
                      this.setState({
                        filterValue: "Inactive",
                        openFilter: false,
                      })
                    }
                  >
                    In-Active
                  </li>
                </ul>
              </div>
            </div>
          )}
          {!selection && (
            <CustTooltip title="Upload">
              <IconButton
                aria-label="upload"
                className="user-onboarding-icon-btn"
              >
                <CloudUploadOutlinedIcon />
              </IconButton>
            </CustTooltip>
          )}
          <Link
            to={{
              pathname: "/pie/iam/service_menu",
              moduleName: this.props.location.moduleName,
              data: this.props.location.data,
            }}
          >
            <i className="material-icons cancel-button pointer">cancel</i>
          </Link>
          <Row className="mt-3">
            {!selection ? (
              this.props.employees.data.length > 0 &&
              this.props.employees.data
                .filter((value) => value.empStatus === this.state.filterValue)
                .map((item) => {
                  return (
                    <Col
                      md="4"
                      key={item.id}
                      onClick={() => this.select(item)}
                      className="employee-list-col"
                    >
                      <Card className="p-2 mb-2 shadow pointer">
                        {item.resendMail && (
                          <div onClick={(e) => e.stopPropagation()}>
                            <Badge
                              color="warning"
                              pill
                              className="resend-badge"
                              onClick={() =>
                                this.resendMail(item.organizationEmailId)
                              }
                            >
                              Re-Activate
                            </Badge>
                          </div>
                        )}
                        <ul className="CustDetails-ul employee-list-ul">
                          <li>
                            <span className="label-sm bold emp-span-label">
                              Name
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span className="employee-list-span-data">{`${item.firstName} ${item.lastName}`}</span>
                            </span>
                          </li>
                          <li>
                            <span className="label-sm bold emp-span-label">
                              Emp No.
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;{item.empNo}
                            </span>
                          </li>
                          <li>
                            <span className="label-sm bold emp-span-label">
                              Mobile No.
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;{item.mobileNumber}
                            </span>
                          </li>
                        </ul>
                      </Card>
                    </Col>
                  );
                })
            ) : (
              <EmployeeDetails
                employee={selection}
                back={this.back}
                locationData={this.props.location.serviceAccessData}
              />
            )}
          </Row>
        </Card>
        {this.state.message ? <Notifier message={this.state.message} /> : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
        {this.props.employees.message ? (
          <Notifier message={this.props.employees.message} />
        ) : null}
        {this.props.employees.error ? (
          <ErrorNotifier message={this.props.employees.error} />
        ) : null}
      </div>
    );
  }
}

Employees.propTypes = {
  getEmployee: PropTypes.func.isRequired,
  employees: PropTypes.object.isRequired,
  searchUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  resendEmail: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  employees: state.employees,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  searchUser,
  getEmployee,
  resendEmail,
})(Employees);
