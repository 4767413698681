import React, { Component } from "react";
import { Form, Row, Col, FormGroup, Label, Input, FormText } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Validator,
  submitValidator,
  valid,
  errors,
  refresh,
} from "../../../../common/Validator";
// components
import FormComponent from "../../../../common/FormComponent";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
// redux
import { updateRequestStatus } from "../../../../../redux/actions/experience_360/e360_config/requestStatusAction";

// -------------------------------------------------------------------------------------

class UpdateRequestStatus extends Component {
  constructor(props) {
    super(props);
    const { id, statusName, color, statusDescription } = this.props.data;
    this.state = {
      id: id,
      status: statusName,
      color: color,
      description: statusDescription,
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      id: this.state.id,
      statusName: this.state.status,
      color: this.state.color,
      statusDescription: this.state.description,
    };
    if (valid) {
      this.props.updateRequestStatus(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    refresh();
    this.setState({
      error: "",
    });
  }

  render() {
    const { status, color, description, error } = this.state;

    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Update Request Status</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="6" className="mx-auto">
              <Row>
                <Col md="10" className="p-0">
                  <FormComponent
                    labelClass="bold label-sm"
                    label="Request Status"
                    type="text"
                    name="status"
                    inputClass="form-control-sm"
                    value={status}
                    change={this.onChange}
                    required={true}
                  />
                </Col>
                <Col md="2" className="pr-0">
                  <FormComponent
                    labelClass="bold label-sm"
                    label="Color"
                    type="color"
                    name="color"
                    inputClass="form-control-sm"
                    value={color}
                    change={this.onChange}
                  />
                </Col>
              </Row>

              <FormComponent
                labelClass="bold label-sm"
                label="Description"
                type="textarea"
                name="description"
                inputClass="form-control-sm"
                value={description}
                change={this.onChange}
              />
            </Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Update
            </button>
          </div>
        </Form>
        {error && <ErrorNotifier message={error} />}
        {this.props.requestStatus.error && (
          <ErrorNotifier message={this.props.requestStatus.error} />
        )}
        {this.props.requestStatus.message && (
          <ErrorNotifier message={this.props.requestStatus.message} />
        )}
      </React.Fragment>
    );
  }
}

UpdateRequestStatus.propTypes = {
  updateRequestStatus: PropTypes.func.isRequired,
  requestStatus: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  requestStatus: state.requestStatus,
});

export default connect(mapStateToProps, { updateRequestStatus })(
  UpdateRequestStatus
);
