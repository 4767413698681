import React, { PureComponent } from "react";
import { Card, Table } from "reactstrap";
import axios from "axios";
import { GET_TOP_FIVE_LEAD_SOURCE } from "../../../../utils/routes";

// const data = [
//   {
//     CampaignType: "Organic",
//     CampaignCount: 10,
//     LeadCount: 5,
//   },
//   {
//     CampaignType: "Direct",
//     CampaignCount: 8,
//     LeadCount: 5,
//   },
//   {
//     CampaignType: "Social Media",
//     CampaignCount: 5,
//     LeadCount: 4,
//   },
//   {
//     CampaignType: "Digital Marketing",
//     CampaignCount: 12,
//     LeadCount: 6,
//   },
//   {
//     CampaignType: "Webinar",
//     CampaignCount: 10,
//     LeadCount: 5,
//   },
// ];

export default class InquiryConvertedToLead extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      error: "",
    };
  }

  componentDidMount() {
    axios
      .get(GET_TOP_FIVE_LEAD_SOURCE, { withCredentials: true })
      .then((res) => {
        this.setState({
          data: res.data.rows,
        });
      })
      .catch((err) => {
        this.setState({
          error: err,
        });
      });
  }

  render() {
    const { data } = this.state;

    return (
      <Card
        className="recharts-card center"
        style={{ height: "150px", padding: "5px" }}
      >
        <Table hover>
          <thead>
            <tr>
              <th
                style={{
                  padding: "0 0 0 5px",
                  fontWeight: "600",
                  fontSize: "12px",
                  textAlign: "left",
                }}
              >
                Camp Type
              </th>
              <th
                style={{
                  padding: "0px",
                  fontWeight: "600",
                  fontSize: "12px",
                }}
              >
                Camp Count
              </th>
              <th
                style={{ padding: "0px", fontWeight: "600", fontSize: "12px" }}
              >
                Lead Count
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 && data.map((item) => {
              return (
                <tr>
                  <td
                    style={{
                      padding: "0 0 0 5px",
                      fontWeight: "400",
                      fontSize: "12px",
                      textAlign: "left",
                    }}
                  >
                    {item.campaignType}
                  </td>
                  <td
                    style={{
                      padding: "0px",
                      fontWeight: "400",
                      fontSize: "12px",
                    }}
                  >
                    {item.CampaignCount}
                  </td>
                  <td
                    style={{
                      padding: "0px",
                      fontWeight: "400",
                      fontSize: "12px",
                    }}
                  >
                    {item.LeadCount}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>
    );
  }
}
