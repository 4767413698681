import React from "react";
import { Row, Col, Card } from "reactstrap";
import CampTypeToLeadRatio from "./CampTypeToLeadRatio";
import OpenLeadsByPotential from "./OpenLeadsByPotential";
import LeadGeneratedVsCampType from "./LeadGeneratedVsCampType";
import PlannedVsActualSpend from "./PlannedVsActualSpend";
import MonthlyCampPlan from "./MonthlyCampPlan";
import CampaignOriginatedTopThreeCustOrders from "./CampaignOriginatedTopThreeCustOrders";
import InquiryConvertedToLead from "./InquiryConvertedToLead";
import LeadsInLastOneQuarterGeowise from "./LeadsInLastOneQuarterGeowise";
import TopFiveLeadSourceForLastYear from "./TopFiveLeadSourceForLastYear";
import TotalCampaigns from "./TotalCampaigns";
import TotalInquiries from "./TotalInquiries";
import TotalLeads from "./TotalLeads";

const MarketingInsights = (props) => {
  let shrink = props.sidebar ? "scale" : "no-scale";

  return (
    <div className={shrink}>
      <Row style={{ marginTop: "-20px" }}>
        <Col
          md="4"
          className="reCharts-col"
          style={{ marginTop: "10px", marginLeft: "15px" }}
        >
          <p className=" center reCharts-title">Campaign Distribution</p>
          <CampTypeToLeadRatio />
        </Col>
        <Col md="4" className="reCharts-col" style={{ marginTop: "10px" }}>
          <p className=" center reCharts-title">Potential Leads</p>
          <OpenLeadsByPotential />
        </Col>
        <Col md="4" className="reCharts-col" style={{ marginTop: "10px" }}>
          <p className=" center reCharts-title">Lead Source</p>
          <LeadGeneratedVsCampType />
        </Col>
        <TotalCampaigns />
      </Row>
      <Row>
        <Col md="4" className="reCharts-col" style={{ marginLeft: "15px" }}>
          <p className=" center reCharts-title">Spend Analysis</p>
          <PlannedVsActualSpend />
        </Col>
        <Col md="4" className="reCharts-col">
          <p className=" center reCharts-title">Budget Projection</p>
          <MonthlyCampPlan />
        </Col>
        <Col md="4" className="reCharts-col">
          <p className=" center reCharts-title">Leads Distribution</p>
          <CampaignOriginatedTopThreeCustOrders />
        </Col>
        <TotalInquiries />
      </Row>
      <Row>
        <Col
          md="4"
          className="reCharts-last-row-col"
          style={{ marginLeft: "15px" }}
        >
          <p className=" center reCharts-title">Inquiries Trend</p>
          <InquiryConvertedToLead />
        </Col>
        <Col md="4" className="reCharts-last-row-col">
          <p className=" center reCharts-title">Geowise Leads</p>
          <LeadsInLastOneQuarterGeowise />
        </Col>
        <Col md="4" className="reCharts-last-row-col">
          <p className=" center reCharts-title">Campaign Analysis</p>
          <TopFiveLeadSourceForLastYear />
        </Col>
        <TotalLeads />
      </Row>
    </div>
  );
};

export default MarketingInsights;
