import axios from "axios";
import { PRODUCT_LIST_GET } from "../../types";
import { CURD_PRODUCT_LIST, USER_PRODUCT_LIST } from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addProductList = (data, back) => (dispatch) => {
  axios
    .post(CURD_PRODUCT_LIST, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getProductList));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getProductList = () => (dispatch) => {
  axios
    .get(CURD_PRODUCT_LIST, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(PRODUCT_LIST_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserProductList = () => (dispatch) => {
  axios
    .get(USER_PRODUCT_LIST, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(PRODUCT_LIST_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateProductList = (data, back) => (dispatch) => {
  axios
    .put(CURD_PRODUCT_LIST, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteProductList = (data) => (dispatch) => {
  axios
    .delete(CURD_PRODUCT_LIST, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getProductList));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
