import setSuccessMessage from "../../../common/setSuccessMessage";
import setErrorMessage from "../../../common/setErrorMessage";
import getDispatch from "../../../common/getDispatch";
import {
  ADD_APPLY_JOBS,
  GET_APPLY_JOBS,
  GET_CANDIDATE_KANBAN,
  GET_CANDIDATE_COMMENTS_API,
  GET_CANDIDATE_STAGE_TRACKING_API,
  UPDATE_APPLY_JOBS,
  DELETE_APPLY_JOBS,
} from "../../../../../utils/routes";
import { GET_TAGGED_REQUIREMENT, CANDIDATE_KANBAN_GET, CANDIDATE_COMMENTS_VIEW, CANDIDATE_STAGE_TRACKING_VIEW  } from "../../../types";
import axios from "axios";

export const AddTagRequirement = (data, back) => (dispatch) => {
  axios
    .post(ADD_APPLY_JOBS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getTagRequirement = (data) => (dispatch) => {
  axios
    .get(`${GET_APPLY_JOBS}/rs/${data}`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(GET_TAGGED_REQUIREMENT, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getCandidateKanban = (data) => (dispatch) => {
  axios
    .get(`${GET_CANDIDATE_KANBAN}/${data}`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CANDIDATE_KANBAN_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getCandidateComments = (data) => (dispatch) => {
  axios
    .get(`${GET_CANDIDATE_COMMENTS_API}/${data}`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CANDIDATE_COMMENTS_VIEW, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getCandidateStageTracking = (data) => (dispatch) => {
  axios
    .get(`${GET_CANDIDATE_STAGE_TRACKING_API}/${data}`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CANDIDATE_STAGE_TRACKING_VIEW , res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateTagRequirement = (data, back, id) => (dispatch) => {
  console.log(id);
  axios
    .put(UPDATE_APPLY_JOBS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
      dispatch(getCandidateKanban(id));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteTagRequirement = (data, back) => (dispatch) => {
  axios
    .delete(DELETE_APPLY_JOBS, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null));
      dispatch(getAssignHr(back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
