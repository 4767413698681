/*  eslint-disable  */
import axios from "axios";
import {
  SERVICE_LIST,
  SERVICE_ADD,
  SERVICE_SEARCH,
  SERVICE_UPDATE,
  GET_ERRORS,
} from "./types";
import {
  GET_SERVICE,
  ADD_SERVICE,
  SEARCH_SERVICE,
  UPDATE_SERVICE,
} from "../../utils/routes";
import { setMessage } from "./salesActions";

export const addService = (data, history) => (dispatch) => {
  axios
    .post(ADD_SERVICE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      dispatch({ type: SERVICE_ADD, payload: true });
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch({ type: SERVICE_ADD, payload: false });
        history.push("/pie/pps/services");
      }, 2000);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};

export const getServices = () => (dispatch) => {
  axios
    .get(GET_SERVICE, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: SERVICE_LIST,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};

export const updateService = (data) => (dispatch) => {
  axios
    .post(UPDATE_SERVICE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
      }, 2000);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};

export const searchService = (data) => (dispatch) => {
  axios
    .post(SEARCH_SERVICE, data, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: SERVICE_SEARCH,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};
