import React, { PureComponent } from "react";
import { Card, Table } from "reactstrap";
import axios from "axios";
import { GET_PROPOSALS_VS_WINS } from "../../../../utils/routes";

export default class ProposalsVsWins extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      error: "",
    };
  }

  componentDidMount() {
    axios
      .get(GET_PROPOSALS_VS_WINS, { withCredentials: true })
      .then((res) => {
        this.setState({
          data: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          error: err,
        });
      });
  }

  render() {
    const { data } = this.state;

    return (
      <Card
        className="recharts-card center"
        style={{ height: "150px", padding: "5px" }}
      >
        <Table hover>
          <thead>
            <tr>
              <th
                style={{
                  padding: "0 0 0 5px",
                  fontWeight: "600",
                  fontSize: "12px",
                  textAlign: "left",
                }}
              >
                Quarter
              </th>
              <th
                style={{ padding: "0px", fontWeight: "600", fontSize: "12px" }}
              >
                Proposals
              </th>
              <th
                style={{ padding: "0px", fontWeight: "600", fontSize: "12px" }}
              >
                Wins
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => {
              return (
                <tr>
                  <td
                    style={{
                      padding: "0 0 0 5px",
                      fontSize: "12px",
                      fontWeight: "400",
                      textAlign: "left",
                    }}
                  >
                    {item.QUARTER}
                  </td>
                  <td
                    style={{
                      padding: "0px",
                      fontSize: "12px",
                      fontWeight: "400",
                    }}
                  >
                    {item.PROPOSALS}
                  </td>
                  <td
                    style={{
                      padding: "0px",
                      fontSize: "12px",
                      fontWeight: "400",
                    }}
                  >
                    {item.WINS}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>
    );
  }
}
