import axios from "axios";
import {
  GET_ROLE_MAPPING,
  ADD_ROLE_MAPPING,
  UPDATE_ROLE_MAPPING,
  DELETE_ROLE_MAPPING,
  SEARCH_ROLE_MAPPING,
} from "../../../../utils/routes";
import { ROLE_MAPPING_GET, ROLE_MAPPING_SEARCH } from "../../types";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addRoleMapping = (data, back) => (dispatch) => {
  let link = "/pie/iam/role_mapping";
  axios
    .post(ADD_ROLE_MAPPING, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getRoleMapping));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getRoleMapping = () => (dispatch) => {
  axios
    .get(GET_ROLE_MAPPING, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(ROLE_MAPPING_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const searchRoleMapping = (data) => (dispatch) => {
  axios
    .post(SEARCH_ROLE_MAPPING, data, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(ROLE_MAPPING_SEARCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateRoleMapping = (data, back) => (dispatch) => {
  axios
    .put(UPDATE_ROLE_MAPPING, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getRoleMapping));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteRoleMapping = (data) => (dispatch) => {
  axios
    .delete(DELETE_ROLE_MAPPING, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getRoleMapping));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
