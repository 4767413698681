import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Form } from "reactstrap";
// components
import { textChecker } from "../../../../common/FormValidator";
import FormComponent from "../../../../common/FormComponent";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
// redux
import { addCustomerDocumentType } from "../../../../../redux/actions/crm/crm_setup/customerDocumentTypeAction";

// --------------------------------------------------------------------------------------------------------------
class AddDocumentType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documentType: "",
      remarks: "",
      message: "",
      error: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  /* This onBlur function is giving validation to the AddDialingCode form
    for condition checking we used switch conditions
*/
  onBlur(e) {
    const { name, value } = e.target;
    let errors = this.state.formErrors;

    switch (name) {
      case "day":
        errors.day = textChecker.test(value)
          ? ""
          : "Use alphabets , numbers or spaces";
        break;
      case "occassion":
        errors.occassion = textChecker.test(value)
          ? ""
          : "Use alphabets , numbers or spaces";
        break;
      default:
        break;
    }
    this.setState({
      formErrors: errors,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    let data = {
      custDocType: this.state.documentType,
      remarks: this.state.remarks,
    };
    this.props.addCustomerDocumentType(data, this.props.back);
  }

  render() {
    return (
      <React.Fragment>
        <h5 className="bold center pt-2">Add Customer Document Type</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>

        <Row>
          <Col md="3"></Col>
          <Col md="6">
            <Form className="p-3" onSubmit={this.onSubmit}>
              <FormComponent
                labelClass="bold label-sm"
                label="Document Type"
                type="text"
                name="documentType"
                inputClass="form-control-sm"
                value={this.state.documentType}
                change={this.onChange}
                required={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Remarks"
                type="textarea"
                name="remarks"
                inputClass="form-control-sm"
                value={this.state.remarks}
                change={this.onChange}
              />
              <div className="center">
                <button type="submit" className="btn login-button white-text">
                  Submit
                </button>
              </div>
            </Form>
          </Col>
        </Row>
        {this.props.customerDocumentType.message && (
          <Notifier message={this.props.customerDocumentType.message} />
        )}
        {this.props.customerDocumentType.error && (
          <ErrorNotifier message={this.props.customerDocumentType.error} />
        )}
      </React.Fragment>
    );
  }
}

AddDocumentType.propTypes = {
  addCustomerDocumentType: PropTypes.func.isRequired,
  customerDocumentType: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  customerDocumentType: state.customerDocumentType,
});

export default connect(mapStateToProps, {
  addCustomerDocumentType,
})(AddDocumentType);
