import React, { Component } from "react";
import { FormText } from "reactstrap";
import axios from "axios";
// components
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import UploadCustDocModel from "./UploadCustDocModel";
import CustomizedDialogs from "../../../common/customized-dialog/CustomizedDialogs";
import ResumeViewer from "../../../common/ResumeViewer";
import ConfirmDialog from "../../../common/confirm-dialog/ConfirmDialog";
// redux
import { CURD_CUSTOMER_DOCUMENTS } from "../../../../utils/routes";
import { CRM_API } from "../../../../utils/config";

// ------------------------------------------------------------------------------------------------------

class AgreementUpload extends Component {
  constructor() {
    super();
    this.state = {
      file: [],
      docs: [],
      name: "",
      open: false,
      view: false,
      viewDoc: {},
      confirmDialog: {
        isOpen: false,
        title: "",
        subTitle: "",
      },
      fileError: "",
      message: "",
      error: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getData = this.getData.bind(this);
    this.getDocs = this.getDocs.bind(this);
    this.deleteDoc = this.deleteDoc.bind(this);
  }

  onChange(e) {
    let allowedExtension = /(\.pdf|\.png)$/i;
    if (allowedExtension.exec(e.target.files[0].name)) {
      if ((e.target.files[0].size / 1024 / 1024).toFixed(2) < 2) {
        this.setState({
          file: e.target.files[0] || [],
          name: e.target.files[0].name || "",
          fileError: "",
        });
      } else {
        this.setState({
          file: e.target.files[0],
          fileError: "File size must less than 2MB",
        });
      }
    } else {
      this.setState({
        file: e.target.files[0],
        fileError: "File type not allowed",
      });
    }
  }

  onSubmit() {
    const { fileError } = this.state;

    let file = this.state.file;
    let fd = new FormData();

    fd.append("documentPath", file);

    if (!fileError) {
      axios
        .post(CURD_CUSTOMER_DOCUMENTS, fd, { withCredentials: true })

        .then((res) => {
          const data = {
            docId: res.data.docId,
            compCode: this.props.comp,
          };

          axios
            .put(CURD_CUSTOMER_DOCUMENTS, data, { withCredentials: true })
            .then((res) => {
              this.setState({
                message: "Attachment added successfully",
                docs: res.data,
              });
              setTimeout(() => {
                this.setState({ message: null });
                this.getData();
              }, 5000);
            })
            .catch((err) => {
              this.setState({ error: "Upload failed" });
              setTimeout(() => {
                this.setState({ error: null });
              }, 5000);
            });
        })
        .catch((err) => {
          this.setState({ error: "Upload failed" });
        });
      setTimeout(() => {
        this.setState({ error: null });
      }, 5000);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  getData() {
    axios
      .get(`${CURD_CUSTOMER_DOCUMENTS}/${this.props.comp}`, {
        withCredentials: true,
      })
      .then((res) => {
        this.setState({
          docs: res.data,
        });
      });
  }

  componentDidMount() {
    this.getData();
  }

  /* 
This function is for downloading the document with respect to the document Id
*/
  getDocs(file) {
    const data = {
      docId: file.docId,
    };
    axios
      .post(CURD_CUSTOMER_DOCUMENTS, data, {
        responseType: "blob",
        withCredentials: true,
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file.agrmntName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        this.setState({
          error: "download failed",
        });
      });
    setTimeout(() => {
      this.setState({
        message: "",
        error: "",
      });
    }, 2000);
  }

  deleteDoc(file) {
    const data = {
      id: file.id,
    };

    axios
      .delete(CURD_CUSTOMER_DOCUMENTS, { data, withCredentials: true })
      .then((res) => {
        this.setState({
          message: "Attachment deleted successfully",
        });
        setTimeout(() => {
          this.setState({ message: null });
          this.setState((prevState) => ({
            ...prevState.confirmDialog,
            confirmDialog: {
              isOpen: false,
            },
          }));
        }, 5000);
        this.getData();
      })
      .catch((err) => {
        this.setState({
          error: "Could not delete",
        });
        setTimeout(() => {
          this.setState({ error: null });
        }, 5000);
      });
  }

  setConfirmDialog = (obj) => {
    this.setState((prevProps) => ({
      confirmDialog: {
        ...prevProps.confirmDialog,
        isOpen: obj.isOpen,
      },
    }));
  };

  // toggleModel function to toggle the module open and close
  toggleModel = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  // openView function to open the document
  openView = (file) => {
    this.setState({
      view: true,
      viewDoc: file,
    });
  };

  // toggleView function to toggle the document view
  toggleView = () => {
    this.setState({
      view: !this.state.view,
    });
  };

  render() {
    const { fileError } = this.state;
    return (
      <div className="center">
        <hr />
        <h6 className="center bold">Documents</h6>
        <button
          className="btn add-button white-text label-sm"
          style={{ position: "absolute", top: "1.5rem", left: "58rem" }}
          onClick={this.toggleModel}
          title="Upload Customer Docs"
        >
          Upload
        </button>
        <CustomizedDialogs
          open={this.state.open}
          handleClose={this.toggleModel}
          title="Upload Document"
        >
          <UploadCustDocModel back={this.toggleModel} id={this.props.comp} />
        </CustomizedDialogs>
        <CustomizedDialogs
          open={this.state.view}
          handleClose={this.toggleView}
          title={this.state.viewDoc.documentPath}
        >
          <ResumeViewer
            URL={`${CRM_API}/files/${this.state.viewDoc.documentPath}`}
          />
        </CustomizedDialogs>
        {this.state.docs &&
          this.state.docs.map((file) => {
            return (
              <span
                key={file.docId}
                className="m-3 card document-card pointer left"
              >
                <i
                  className="material-icons cancel-button pointer"
                  onClick={() =>
                    this.setState({
                      confirmDialog: {
                        isOpen: true,
                        title: "Are you sure to delete this record?",
                        subTitle: "You can't undo this operation",
                        onConfirm: () => this.deleteDoc(file),
                      },
                    })
                  }
                  title="Delete Document"
                >
                  delete
                </i>
                <p
                  onClick={() => this.openView(file)}
                  className="bold label-sm p-2"
                >
                  {file.documentName}&nbsp;:&nbsp;{file.documentPath}
                </p>
              </span>
            );
          })}
        <hr />
        {/* <label htmlFor="file" className="bold label-sm">
          Add new attachment :
        </label>
        <input
          type="file"
          name="file"
          className="form-control-sm"
          style={{ fontSize: "11px" }}
          onChange={this.onChange}
          accept=".pdf, .png"
        />
        <input
          type="Submit"
          style={{ fontSize: "10px" }}
          onClick={this.onSubmit}
        />
        {fileError && <FormText className="error">{fileError}</FormText>}
        <hr /> */}
        {this.state.message && <Notifier message={this.state.message} />}
        {this.state.error && <ErrorNotifier message={this.state.error} />}
        <ConfirmDialog
          confirmDialog={this.state.confirmDialog}
          setConfirmDialog={this.setConfirmDialog}
        />
      </div>
    );
  }
}
export default AgreementUpload;
