import React, { Component } from "react";
import { Form, Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import { updateRole } from "../../../../../redux/actions/iam/auth_configuration/role_management/roleAction";

class UpdateRole extends Component {
  constructor(props) {
    super(props);
    const { id, roleCode, roleName, roleDescription } = this.props.data;
    this.state = {
      roleId: id,
      userGroup: this.props.data.pieUserGroup.id,
      roleCode: roleCode,
      roleName: roleName,
      roleDesc: roleDescription,
      message: "",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      id: this.state.roleId,
      pieUserGroupId: Number(this.state.userGroup),
      roleCode: this.state.roleCode,
      roleName: this.state.roleName,
      roleDescription: this.state.roleDesc,
    };
    if (valid) {
      this.props.updateRole(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    refresh();
    this.setState({
      message: "",
      error: "",
    });
  }

  render() {
    const { userGroup, roleCode, roleName, roleDesc, error, message } =
      this.state;

    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Update Role</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="3"></Col>
            <Col md="6">
              <FormComponent
                labelClass="bold label-sm"
                label="Role Code"
                type="text"
                inputClass="form-control-sm"
                value={roleCode}
                disabled={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Role Name"
                type="text"
                name="roleName"
                value={roleName}
                change={this.onChange}
                required={true}
                minLength="2"
                maxLength="36"
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Description"
                type="textarea"
                name="roleDesc"
                value={roleDesc}
                change={this.onChange}
                placeholder="description (optional)"
                maxLength="50"
              />
            </Col>
            <Col md="3"></Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Update
            </button>
          </div>
        </Form>
        {message && <Notifier message={message} />}
        {error && <ErrorNotifier message={error} />}
        {this.props.roles.message && (
          <Notifier message={this.props.roles.message} />
        )}
        {this.props.roles.error && (
          <ErrorNotifier message={this.props.roles.error} />
        )}
      </React.Fragment>
    );
  }
}

UpdateRole.propTypes = {
  updateRole: PropTypes.func.isRequired,
  roles: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  roles: state.roles,
});

export default connect(mapStateToProps, { updateRole })(UpdateRole);
