import React, { Component } from "react";
import { Form, Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// components
import FormComponent from "../../../../../common/FormComponent";
import {
  Validator,
  submitValidator,
  refresh,
  valid,
} from "../../../../../common/Validator";
import Notifier from "../../../../../aside/Notifier";
import ErrorNotifier from "../../../../../aside/ErrorNotifier";
// redux
import { updateContacts } from "../../../../../../redux/actions/hcm/my_profile/contacts_details/contactsAction";

class UpdateContactDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      homeDialingCode: this.props.data.length
        ? this.props.data[0].homeDialingCode
        : "",
      homeContactNo: this.props.data.length
        ? this.props.data[0].homeContactNo
        : "",
      alternativeNo: this.props.data.length
        ? this.props.data[0].alternativeNo
        : "",
      alternativeDialingCode: this.props.data.length
        ? this.props.data[0].alternativeDialingCode
        : "",
      message: "",
      error: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      homeDialingCode: this.state.homeDialingCode,
      homeContactNo: this.state.homeContactNo,
      alternativeDialingCode: this.state.alternativeDialingCode,
      alternativeNo: this.state.alternativeNo,
    };
    if (valid) {
      this.props.updateContacts(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    refresh();
    this.setState({
      message: "",
      error: "",
    });
  }

  render() {
    const {
      homeDialingCode,
      homeContactNo,
      alternativeDialingCode,
      alternativeNo,
      message,
      error,
    } = this.state;

    return (
      <React.Fragment>
        <i
          className="material-icons cancel-button pointer"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="2" className="p-0">
              <FormComponent
                labelClass="bold label-sm"
                label=" "
                type="number"
                name="homeDialingCode"
                inputClass="form-control-sm"
                value={homeDialingCode}
                change={this.onChange}
                required={true}
                maxLength={4}
              />
            </Col>
            <Col md="10" className="p-0">
              <FormComponent
                labelClass="bold label-sm"
                label="Home Contact No."
                type="tel"
                name="homeContactNo"
                inputClass="form-control-sm"
                value={homeContactNo}
                change={this.onChange}
                required={true}
              />
            </Col>
          </Row>
          <Row>
            <Col md="2" className="p-0">
              <FormComponent
                labelClass="bold label-sm"
                label=" "
                type="number"
                name="alternativeDialingCode"
                inputClass="form-control-sm"
                value={alternativeDialingCode}
                change={this.onChange}
                required={true}
                maxLength={4}
              />
            </Col>
            <Col md="10" className="p-0">
              <FormComponent
                labelClass="bold label-sm"
                label="Alternative No."
                type="tel"
                name="alternativeNo"
                inputClass="form-control-sm"
                value={alternativeNo}
                change={this.onChange}
                required={true}
              />
            </Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Update
            </button>
          </div>
        </Form>
        {this.props.contacts.message && (
          <Notifier message={this.props.contacts.message} />
        )}
        {this.props.contacts.error && (
          <ErrorNotifier message={this.props.contacts.error} />
        )}
      </React.Fragment>
    );
  }
}

UpdateContactDetails.propTypes = {
  updateContacts: PropTypes.func.isRequired,
  contacts: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  contacts: state.contacts,
});

export default connect(mapStateToProps, { updateContacts })(
  UpdateContactDetails
);
