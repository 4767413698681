import React, { Component } from "react";
import { Form, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import Notifier from "../../../../aside/Notifier";
import { addJobBoard  } from "../../../../../redux/actions/rs/configuration/jobBoardAction";

// add job board component
class AddJobBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  // common onchange function
  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  // submit function
  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      jobboardName: this.state.jobboardName,
      jobboardDesc: this.state.jobboardDesc,
      jobboardURL: this.state.jobboardURL,
    };
    if (valid) {
      this.props.addJobBoard(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidMount contains the error refresh method and setState method for calling the render method
*/
  componentDidMount() {
    refresh();
    this.setState({
      error: "",
    });
  }

  render() {
    const { jobboardName,jobboardURL, jobboardDesc, error } = this.state;

    return (
      <React.Fragment>
        <h5 className="center card-heading pt-2">Add Job Board</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="6" className="mx-auto">
              <FormComponent
                labelClass="bold label-sm"
                label="Board Name"
                type="text"
                name="jobboardName"
                inputClass="form-control-sm"
                value={jobboardName}
                change={this.onChange}
                required={true}
                length="30"
              />

              <FormComponent
                labelClass="bold label-sm"
                label="Board Url"
                type="text"
                name="jobboardURL"
                inputClass="form-control-sm"
                value={jobboardURL}
                change={this.onChange}
                required={true}
                length="30"
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Board Description"
                type="text"
                name="jobboardDesc"
                inputClass="form-control-sm"
                value={jobboardDesc}
                change={this.onChange}
                required={true}
                length="30"
              />
            </Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Add
            </button>
          </div>
        </Form>
        {error && <ErrorNotifier message={error} />}
        {this.props.JobBoard.error && (
          <ErrorNotifier message={this.props.JobBoard.error} />
        )}
        {this.props.JobBoard.message && (
          <Notifier message={this.props.JobBoard.message} />
        )}
      </React.Fragment>
    );
  }
}

AddJobBoard.propTypes = {
  addSkillGroup: PropTypes.func.isRequired,
  userGroups: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  JobBoard: state.JobBoard,
});

export default connect(mapStateToProps, { addJobBoard })(AddJobBoard );
