import axios from "axios";
import { ACCOUNT_MANAGER_GET } from "../../types";
import {
  CURD_ACCOUNT_MANAGER,
  USER_ACCOUNT_MANAGER,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addAccountManager = (data, back) => (dispatch) => {
  axios
    .post(CURD_ACCOUNT_MANAGER, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getAccountManager));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getAccountManager = () => (dispatch) => {
  axios
    .get(CURD_ACCOUNT_MANAGER, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(ACCOUNT_MANAGER_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserAccountManager = () => (dispatch) => {
  axios
    .get(USER_ACCOUNT_MANAGER, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(ACCOUNT_MANAGER_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateAccountManager = (data, back) => (dispatch) => {
  axios
    .put(CURD_ACCOUNT_MANAGER, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteAccountManager = (data) => (dispatch) => {
  axios
    .delete(CURD_ACCOUNT_MANAGER, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getAccountManager));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
