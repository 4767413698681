import React, { Component } from "react";
import { Row, Col, Form ,FormGroup,Input,Label,FormText, } from "reactstrap";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../common/Validator";
import ErrorNotifier from '../../../aside/ErrorNotifier';
import { connect } from "react-redux";
import { AddPublishJobs } from "../../../../redux/actions/rs/requirements/publishJobsAction";
import { getJobTimingSelect } from "../../../../redux/actions/rs/configuration/jobTiming";
import { getAllSkillsSelect  } from "../../../../redux/actions/rs/configuration/canSkillsAction";
import CommonFlexComponent from "../../../common/commonFlexComponent";
import UpdatePublishJobs from "./updatedPublishJob";
import { Divider } from "@material-ui/core";
import DateFns from "date-fns";
import { MdCancel } from "react-icons/md";
import Select from "react-select";
import DetailsPageFooter from "../../../common/details_footer/DetailsPageFooter";
import FormComponent from "../../../common/FormComponent";
let errorValid = false;
let errorSkill = false;
class PublishJob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateView: false,
      primarySkills: this.props.getData[0].primarySkills
        ? this.props.getData[0].primarySkills
        : "",
      secondarySkills: this.props.getData[0].secondarySkills
        ? this.props.getData[0].secondarySkills
        : "",
      jobTimings: this.props.getData[0].jobTimings
        ? this.props.getData[0].jobTimings
        : "",
      jobDescription: this.props.getData[0].jobDescription
        ? this.props.getData[0].jobDescription
        : "",
        timingBodyName:this.props.getData[0].jobTimings
        ? this.props.getData[0].jobTimings
        : "",
        filteredPrimaryValueArray:  this.props.getData[0].primarySkills
        ? this.props.getData[0].primarySkills.split(",")
        : [],
        filteredSecondaryValueArray: this.props.getData[0].secondarySkills
        ? this.props.getData[0].secondarySkills.split(",")
        : [],
        haveSkills: "",
        niceSkills: "",
      skillErrorShow: "",
      primarySkillErrorShow:"",
      DescShow: "",
      error: "",
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.closeUpdate = this.closeUpdate.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handlePrimarySkillsChange = this.handlePrimarySkillsChange.bind(this);
    this.handleSecondarySkillsChange =this.handleSecondarySkillsChange.bind(this);
    this.closeDataPrimarySkill = this.closeDataPrimarySkill.bind(this);
    this.closeDataSecondarySkill = this.closeDataSecondarySkill.bind(this);
    this.handleTimingChange = this.handleTimingChange.bind(this)
   
  }

  closeUpdate() {
    this.setState({
      updateView: false,
    });
  }

  onChange(e) {
    if (e.target.name === "primarySkills") {
      this.setState({
        skillErrorShow: "",
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "jobDescription") {
      this.setState({
        DescShow: "",
        [e.target.name]: e.target.value,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  handlePrimarySkillsChange(skillSet) {
    this.setState({
      primarySkillErrorShow: null,
    });

    let { filteredPrimaryValueArray } = this.state;
    filteredPrimaryValueArray.push(skillSet.value);
    this.setState({
      filteredPrimaryValueArray: [...new Set(filteredPrimaryValueArray)],
    });
  }

  handleSecondarySkillsChange(skillSet) {
    this.setState({
      secondarySkillErrorShow: null,
    });

    let { filteredSecondaryValueArray } = this.state;
    filteredSecondaryValueArray.push(skillSet.value);
    this.setState({
      filteredSecondaryValueArray: [...new Set(filteredSecondaryValueArray)],
    });
  
}

  closeDataPrimarySkill(data) {
    console.log(data);

    let filterData = this.state.filteredPrimaryValueArray.filter(
      (val) => val !== data
    );
    this.setState({
      filteredPrimaryValueArray: filterData,
    });
  }

  closeDataSecondarySkill(data) {
    console.log(data);

    let filterData = this.state.filteredSecondaryValueArray.filter(
      (val) => val !== data
    );
    this.setState({
      filteredSecondaryValueArray: filterData,
    });
  }

  handleTimingChange(timing) {
    this.setState({
      timingBodyName: timing.value,
    });

    if (timing) {
      this.setState({ timing });
    }
  }

  onSubmit(e) {
    console.log("chandra");
    e.preventDefault();
    submitValidator(e.target);

    if (!this.state.filteredPrimaryValueArray.length) {
      this.setState({
        primarySkillErrorShow: "This field is mandatory",
      });
      errorSkill = true;
    } else {
      this.setState({
        primarySkillErrorShow: "",
      });
      errorSkill = false;
    }

    if (!this.state.jobDescription) {
      this.setState({
        DescShow: "This field is mandatory",
      });
      errorValid = true;
    } else {
      this.setState({
        DescShow: "",
      });
      errorValid = false;
    }
    let body = {
      jobReqirementId: Number(this.props.reqType),
      jobType: this.props.getData[0].jobReqType,
      jobCategory: this.props.getData[0].jobCategory,
      jobPosition: this.props.getData[0].jobPosition,
      jobDescription: this.state.jobDescription,
      jobLocation: this.props.getData[0].jobLocation,
      jobTimings: this.state.timingBodyName,
      qualifications: this.props.getData[0].qualifications,
      skillSets: this.props.getData[0].skillSets,
      primarySkills: this.state.filteredPrimaryValueArray.toString(),
      secondarySkills: this.state.filteredSecondaryValueArray.toString(),
      totalPositions: this.props.getData[0].totalPositions,
      startDate: this.props.getData[0].startDate,
      endDate: this.props.getData[0].endDate,
      reqStatus: this.props.getData[0].reqStatus,
      minYearsOfExp: Number(this.props.getData[0].minYearsOfExp),
      maxYearsOfExp: Number(this.props.getData[0].maxYearsOfExp),
    };
    if (!errorValid) {
      if(this.props.getData[0].reqStatus === "Assigned" ||
      this.props.getData[0].reqStatus === "Work In Progress"
      ){
        this.props.AddPublishJobs(body,this.props.back);
      }
      else{
        this.setState({
          error: "Requirement Status should be Assigned or Work In Progress",
        });
        setTimeout(() => {
          this.setState({
            error: "",
          });
        }, 2000);  
      }
     
    } else {
      this.setState({
        error: "Please enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
}

componentDidMount(){
  this.props.getAllSkillsSelect()
  this.props.getJobTimingSelect()
}


  render() {
    let options5 = [];
    for (let i = 0; i < this.props.CanConSkill.allData.length; i++) {
      var x3 = {};
      x3["value"] = this.props.CanConSkill.allData[i].skill;
      x3["label"] = this.props.CanConSkill.allData[i].skill;
      options5.push(x3);
    }

    let options9 = [];
    for (let i = 0; i < this.props.jobTiming.mainData.length; i++) {
      var x7 = {};
      x7["value"] = this.props.jobTiming.mainData[i].jobHours;
      x7["label"] = this.props.jobTiming.mainData[i].jobHours;

      options9.push(x7);
    }
   
    return (
      <div style={{ width: "100%" }}>
        {/* {!this.state.updateView &&  <div style={{display:"flex",justifyContent:"flex-end"}}>
                  <i 
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Update Requirement"
                    style={{ color: "green",alignItems:"end",marginRight:"3rem",marginTop:"-2.5rem"}}
                    className="material-icons pointer edit-btn"
                    onClick={() => this.setState({ updateView: !this.state.updateView })}
                  >
                    edit
                  </i>
               </div>} */}

        {!this.state.updateView && (
          <div>
            <h5 style={{ fontweight: 700 }} className="bold center mb-4">
              {" "}
              Publish Jobs
            </h5>
            <i
              data-toggle="tooltip"
              data-placement="top"
              title="Close"
              onClick={() => this.props.back()}
              className="material-icons pointer cancel-button"
              style={{
                top: "0.1rem",
              }}
            >
              cancel
            </i>
            {this.props.getData.length &&
              this.props.getData.map((data) => {
                return (
                  <div>
                    <Row>
                      <Col md="4">
                        <ul className="CustDetails-ul">
                        {/* <li style={{ marginTop: "1rem" }}>
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: 500,
                                width: "150px",
                              }}
                              className="label-sm bold customer-span-label-col-1"
                            >
                              Req. Id
                            </span>
                            <span style={{
                                   fontSize: ".75rem",
                                  fontWeight: 400,}}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;{this.props.reqType}
                            </span>
                          </li> */}
                          <li>
                            <CommonFlexComponent
                              label="Req Id"
                              data={this.props.reqType}
                            />
                          </li>
                          {/* <li style={{ marginTop: "1rem" }}>
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: 500,
                                width: "150px",
                              }}
                              className="label-sm bold customer-span-label-col-1"
                            >
                              Req. Type
                            </span>
                            <span style={{
                                   fontSize: ".75rem",
                                  fontWeight: 400,}}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;{data.jobReqType}
                            </span>
                          </li> */}
                          <li>
                            <CommonFlexComponent
                              label="Req Type"
                              data={data.jobReqType}
                            />
                          </li>
                          {/* <li style={{ marginTop: "1rem" }}>
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: 500,
                                width: "150px",
                              }}
                              className="label-sm bold customer-span-label-col-1"
                            >
                              Job Category
                            </span>
                            <span style={{
                                   fontSize: ".75rem",
                                  fontWeight: 400,}}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;{data.jobCategory}
                            </span>
                          </li> */}

                       <li>
                            <CommonFlexComponent
                              label="Job Category"
                              data={data.jobCategory}
                            />
                          </li>
                          {/* <li style={{ marginTop: "1rem" }}>
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: 500,
                                width: "150px",
                              }}
                              className="label-sm bold customer-span-label-col-1"
                            >
                              Job Position
                            </span>
                            <span style={{
                                   fontSize: ".75rem",
                                  fontWeight: 400,}}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;{data.jobPosition}
                            </span>
                          </li> */}

                        <li>
                            <CommonFlexComponent
                              label="Job Positions"
                              data={data.jobPosition}
                            />
                          </li>
                         

                          {/* <li style={{ marginTop: "1rem" }}>
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: 500,
                                width: "150px",
                              }}
                              className="label-sm bold customer-span-label-col-1"
                            >
                              Start Date
                            </span>
                            <span style={{
                                   fontSize: ".75rem",
                                  fontWeight: 400,}}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              {data.startDate
                                ? DateFns.format(data.startDate, "DD-MM-YYYY")
                                : ""}
                            </span>
                          </li> */}

                           <li>
                            <CommonFlexComponent
                              label="Start Date"
                              data={data.startDate
                                ? DateFns.format(data.startDate, "DD-MM-YYYY")
                                : ""}
                            />
                          </li>

                          {/* <li style={{ marginTop: "1rem" }}>
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: 500,
                                width: "150px",
                              }}
                              className="label-sm bold customer-span-label-col-1"
                            >
                              End Date
                            </span>
                            <span style={{
                                   fontSize: ".75rem",
                                  fontWeight: 400,}} >
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              {data.endDate
                                ? DateFns.format(data.endDate, "DD-MM-YYYY")
                                : ""}
                            </span>
                          </li> */}

                          <li>
                            <CommonFlexComponent
                              label="End Date"
                              data={data.endDate
                                ? DateFns.format(data.endDate, "DD-MM-YYYY")
                                : ""}
                            />
                          </li>

                          <li>
                            <CommonFlexComponent
                              label="Primary Skills"
                              data={this.state.primarySkills}
                            />
                          </li>

                          {/* <li style={{ marginTop: "1rem" }}>
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: 500,
                                width: "150px",
                              }}
                              className="label-sm bold customer-span-label-col-1"
                            >
                              Primary Skills
                            </span>
                            <span style={{
                                   fontSize: ".75rem",
                                  fontWeight: 400,}} >
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              {this.state.primarySkills}
                            </span>
                          </li> */}
                          {/* <li style={{ marginTop: "1rem" }}>
                          <FormGroup>
                  <Label className="bold label-sm">Primary Skills</Label>
                  <Select
                    tabIndex="18"
                    name="options2"
                    value={this.state.haveSkills}
                    onChange={this.handlePrimarySkillsChange}
                    options={options5}
                    Primary
                    required
                  />
                    {this.state.primarySkillErrorShow && (
                  <FormText className="error">
                    {this.state.primarySkillErrorShow}
                  </FormText>
                )}
                </FormGroup>
              
                {this.state.filteredPrimaryValueArray.length > 0 && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      border: "1px solid grey",
                      // paddingBottom: "0.5rem",
                    }}
                  >
                    {this.state.filteredPrimaryValueArray.map((data) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent:"space-between",
                             minWidth: "4rem",
                             background: "#ECECEC",
                             border: "none",
                             height:"2rem",
                             marginTop: "0.1rem",
                             marginBottom:"0.1rem",
                             marginLeft: "0.5rem",
                             borderRadius: "20px",
                             paddingRight:"0.2rem",
                             paddingLeft: "0.2rem",
                          }}
                        >
                          <span style={{fontSize:".75rem",fontweight:400}}>{data}</span>

                          <h3
                            data-toggle="tooltip"
                            data-placement="top"
                            title="cancel"
                            onClick={() => this.closeDataPrimarySkill(data)}
                            style={{
                              color: "#04044c",
                            }}
                          >
                            {" "}
                            <MdCancel color="red" size="20" />{" "}
                          </h3>
                        </div>
                      );
                    })}
                  </div>
                )}
                  </li> */}
                {/* <li>

                  <FormGroup style={{marginTop:"1rem"}}>
                        <Label className="bold label-sm">Job Timing</Label>
                        <Select
                          defaultInputValue={this.state.timingBodyName}
                          className="select-form-control-sm"
                          tabIndex="9"
                          name="timing"
                          value={this.state.timing}
                          onChange={this.handleTimingChange}
                          options={options9}
                          required
                        />
                      </FormGroup>
                      </li> */}

<li>
                            <CommonFlexComponent
                              label="Job Timings"
                              data={this.state.jobTimings}
                            />
                          </li>

                  {/* <li style={{ marginTop: "1rem" }}>
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: 500,
                                width: "150px",
                              }}
                              className="label-sm bold customer-span-label-col-1"
                            >
                            Job Timing
                            </span>
                            <span style={{
                                   fontSize: ".75rem",
                                  fontWeight: 400,}} >
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              {this.state.timing}
                            </span>
                          </li> */}
                        
                        </ul>
                      </Col>

                      <Col style={{ marginLeft: "10rem" }} md="4">
                        <ul className="CustDetails-ul">
                        {/* <li style={{ marginTop: "1rem" }}>
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: 500,
                                width: "150px",
                              }}
                              className="label-sm bold customer-span-label-col-1"
                            >
                              Qualification
                            </span>
                            <span style={{
                                   fontSize: ".75rem",
                                  fontWeight: 400,}}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;{data.qualifications}
                            </span>
                          </li> */}
                          <li>
                            <CommonFlexComponent
                              label="Qualification"
                              data={data.qualifications}
                            />
                          </li>
                          {/* <li style={{ marginTop: "1rem" }}>
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: 500,
                                width: "150px",
                              }}
                              className="label-sm bold customer-span-label-col-1"
                            >
                              Job Location
                            </span>
                            <span style={{
                                   fontSize: ".75rem",
                                  fontWeight: "400",}}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;{data.jobLocation}
                            </span>
                          </li> */}

<li>
                            <CommonFlexComponent
                              label="Location"
                              data={data.jobLocation}
                            />
                          </li>
                          

                          {/* <li style={{ marginTop: "1rem" }}>
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: 500,
                                width: "150px",
                              }}
                              className="label-sm bold customer-span-label-col-1"
                            >
                              Total Position
                            </span>
                            <span style={{
                                   fontSize: ".75rem ",
                                  fontWeight: 400,}}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;{data.totalPositions}
                            </span>
                          </li> */}
                          <li>
                            <CommonFlexComponent
                              label="Total Position"
                              data={data.totalPositions}
                            />
                          </li>

                          {/* <li style={{ marginTop: "1rem" }}>
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: 500,
                                width: "150px",
                              }}
                              className="label-sm bold customer-span-label-col-1"
                            >
                              Min Yrs of Exp.
                            </span>
                            <span style={{
                                   fontSize: ".75rem ",
                                  fontWeight: 400,}}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;{data.minYearsOfExp}
                            </span>
                          </li> */}

                        <li>
                            <CommonFlexComponent
                              label="Min Yrs of Exp"
                              data={data.minYearsOfExp}
                            />
                          </li>
                          {/* <li style={{ marginTop: "1rem" }}>
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: 500,
                                width: "150px",
                              }}
                              className="label-sm bold customer-span-label-col-1"
                            >
                              Max Yrs of Exp.
                            </span>
                            <span style={{
                                   fontSize: ".75rem ",
                                  fontWeight: 400,}}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;{data.maxYearsOfExp}
                            </span>
                          </li> */}

                          <li>
                            <CommonFlexComponent
                              label="Max Yrs of Exp"
                              data={data.maxYearsOfExp}
                            />
                          </li>

                          <li>
                            <CommonFlexComponent
                              label="Req. Status"
                              data={data.reqStatus}
                            />
                          </li>
                          {/* <li style={{ marginTop: "1rem" }}>
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: 500,
                                width: "150px",
                              }}
                              className="label-sm bold customer-span-label-col-1"
                            >
                              Req. Status
                            </span>
                            <span style={{
                                   fontSize: ".75rem",
                                  fontWeight: 400,}}>
                              &nbsp;&nbsp;:&nbsp;&nbsp;{data.reqStatus}
                            </span>
                          </li> */}
                          {/* <li style={{ marginTop: "1rem" }}>
                          <FormGroup>
                        <Label className="bold label-sm">
                          Secondary Skills
                        </Label>
                        <Select
                          tabIndex="18"
                          name="options2"
                          value={this.state.niceSkills}
                          onChange={this.handleSecondarySkillsChange}
                          options={options5}
                          Primary
                          required
                        />
                      </FormGroup>

                      {this.state.filteredSecondaryValueArray.length > 0 && (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexWrap: "wrap",
                            border: "1px solid grey",
                            // paddingBottom: "0.5rem",
                          }}
                        >
                          {this.state.filteredSecondaryValueArray.map(
                            (data) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    minWidth: "4rem",
                                    background: "#ECECEC",
                                    border: "none",
                                    height: "2rem",
                                    marginTop: "0.1rem",
                                    marginBottom: "0.1rem",
                                    marginLeft: "0.5rem",
                                    borderRadius: "20px",
                                    paddingRight: "0.2rem",
                                    paddingLeft: "0.2rem",
                                  }}
                                >
                                  <span style={{fontSize:".75rem"}}>
                                    {data}
                                  </span>

                                  <h3
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="cancel"
                                    onClick={() =>
                                      this.closeDataSecondarySkill(data)
                                    }
                                    style={{
                                      color: "#04044c",
                                    }}
                                  >
                                    {" "}
                                    <MdCancel color="red" size="20" />{" "}
                                  </h3>
                                </div>
                              );
                            }
                          )}
                        </div>
                      )}
                          </li> */}

                        <li>
                            <CommonFlexComponent
                              label="Secondary Skills"
                              data={this.state.secondarySkills}
                            />
                          </li>

                    {/* <li style={{ marginTop: "1rem" }}>
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: 500,
                                width: "150px",
                              }}
                              className="label-sm bold customer-span-label-col-1"
                            >
                             Secondary Skills
                            </span>
                            <span style={{
                                   fontSize: ".75rem",
                                  fontWeight: 400,}} >
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              {this.state.secondarySkills}
                            </span>
                          </li> */}


                          <li>
                            <CommonFlexComponent
                              label="Job Description"
                              data={this.state.jobDescription}
                            />
                          </li>

                          {/* <li style={{ marginTop: "1rem" }}>
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: 500,
                                width: "150px",
                              }}
                              className="label-sm bold customer-span-label-col-1"
                            >
                             Job Description
                            </span>
                            <span style={{
                                   fontSize: ".75rem",
                                  fontWeight: 400,}} >
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              {this.state.jobDescription}
                            </span>
                          </li> */}
                        </ul>
                      </Col>
                    </Row>

                    <div style={{ marginTop: "1rem" }} className="center">
                      <button
                        onClick={this.onSubmit}
                        type="submit"
                        className="btn center login-button white-text"
                        // disabled={this.state.buttonValue ? "disabled" : null}
                      >
                        Publish
                      </button>
                    </div>
                    <Divider style={{ marginTop: "1rem" }} />
                    <DetailsPageFooter
                      createdBy={data.createdBy}
                      createdOn={data.createdOn}
                      updatedBy={data.updatedBy}
                      updatedOn={data.updatedOn}
                    />
                  </div>
                );
              })}
              {this.state.error && <ErrorNotifier message={this.state.error} />}
          </div>
        )}
        {/* {this.state.updateView && <UpdatePublishJobs back = {this.closeUpdate} reqType={this.props.reqType} getData={this.props.getData}/>} */}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  AddPublishJobs: AddPublishJobs,
  CanConSkill: state.CanConSkill,
  jobTiming: state.jobTiming,
});

export default connect(mapStateToProps, {
  AddPublishJobs,
  getAllSkillsSelect,
  getJobTimingSelect 
})(PublishJob);
