import axios from "axios";
import { GET_BILLING_LIST, GET_BILLING_MAIN  } from "../../types";
import {
  ADD_BILLING_RS,
  GET_BILLING_RS,
  UPDATE_BILLING_RS,
  DELETE_BILLING_RS
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addBillingRs = (data, back) => (dispatch) => {
  axios
    .post(ADD_BILLING_RS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getBillingRs));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getBillingRs = () => (dispatch) => {
  axios
    .get( GET_BILLING_RS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(GET_BILLING_LIST, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
export const getBillingRsTypeRs = () => (dispatch) => {
  axios
    .get( `${GET_BILLING_RS}/select`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch( GET_BILLING_MAIN , res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateBillingRs = (data, back) => (dispatch) => {
  axios
    .put( UPDATE_BILLING_RS , data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteBillingRs = (data) => (dispatch) => {
  axios
    .delete( DELETE_BILLING_RS, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getBillingRs));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
