import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Row, Col, Card } from "reactstrap";
import { selectClaims } from "../../../../../redux/actions/finance/claims/employeeClaimAction";
import { getClaimStatus } from "../../../../../redux/actions/finance/claims/claimStatusAction";
import ApprovalModal from "./ApprovalModal";
import DetailsModal from "./DetailsModule";
/**
 * @class ApproveClaim
 **/

class ApproveClaim extends Component {
  constructor(props) {
    super(props);
    this.state = {
      approvalModal: false,
      detailsModal: false,
      status: "",
      primaryId: "",
      item: {},
    };
  }

  // function for open approvalModal
  openApprovalModal = (item) => {
    this.setState({
      status: item.claimStatus,
      primaryId: item.empClaimId,
      approvalModal: true,
    });
  };

  // function for close approvalModal
  closeApprovalModal = () => {
    this.setState({
      approvalModal: false,
    });
  };

  // function for open detailsModal
  openDetailsModal = (item) => {
    this.setState({
      item: item,
      detailsModal: true,
    });
  };

  // function for close detailsModal
  closeDetailsModal = () => {
    this.setState({
      detailsModal: false,
    });
  };

  // getting all claims data
  componentDidMount() {
    this.props.selectClaims();
    this.props.getClaimStatus();
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 mx-auto">
            <Container className="card container-card">
              <i
                className="material-icons pointer cancel-button"
                onClick={this.props.back}
              >
                cancel
              </i>
              <h5 className="bold center pt-2">Approve Claim</h5>
              <Row className="pt-3">
                {this.props.employeeClaim &&
                  this.props.employeeClaim.allData.map((item) => {
                    return (
                      <Col md="4" key={item.empClaimId}>
                        <Card className="p-3 mb-2 shadow pointer">
                          <div className="details-flex-main-div">
                            <div className="details-flex-div-one details-head">
                              EmpId
                            </div>
                            <div className="details-flex-div-two details-colon">
                              :
                            </div>
                            <div className="details-flex-div-three details-value">
                              {item.empId}
                            </div>
                          </div>
                          <div className="details-flex-main-div">
                            <div className="details-flex-div-one details-head">
                              Name
                            </div>
                            <div className="details-flex-div-two details-colon">
                              :
                            </div>
                            <div className="details-flex-div-three  details-value">
                              {item.empName}
                            </div>
                          </div>
                          <div className="details-flex-main-div">
                            <div className="details-flex-div-one details-head">
                              Type
                            </div>
                            <div className="details-flex-div-two details-colon">
                              :
                            </div>
                            <div className="details-flex-div-three details-value">
                              {item.claimType}
                            </div>
                          </div>
                          <div className="details-flex-main-div">
                            <div className="details-flex-div-one details-head ">
                              Status
                            </div>
                            <div className="details-flex-div-two details-colon">
                              :
                            </div>
                            <div
                              className="details-flex-div-three details-value"
                              style={{ paddingBottom: "10px" }}
                            >
                              {item.claimStatus}
                            </div>
                          </div>

                          <Row>
                            <Col md="4">
                              <button
                                id="lms-approve-button"
                                onClick={() => this.openApprovalModal(item)}
                              >
                                Approve/Reject
                              </button>
                            </Col>
                            <Col md="4"></Col>
                            <Col md="4">
                              <button
                                id="lms-details-button"
                                onClick={() => this.openDetailsModal(item)}
                              >
                                Details
                              </button>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    );
                  })}
              </Row>
            </Container>
            {this.state.approvalModal && (
              <ApprovalModal
                openModal={this.state.approvalModal}
                closeModal={this.closeApprovalModal}
                claimStatus={this.props.claimStatus}
                status={this.state.status}
                primaryId={this.state.primaryId}
              />
            )}
            {this.state.detailsModal && (
              <DetailsModal
                openModal={this.state.detailsModal}
                closeModal={this.closeDetailsModal}
                item={this.state.item}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

ApproveClaim.propTypes = {
  selectClaims: PropTypes.func.isRequired,
  employeeClaim: PropTypes.object.isRequired,
  getClaimStatus: PropTypes.func.isRequired,
  claimStatus: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  employeeClaim: state.employeeClaim,
  claimStatus: state.claimStatus,
});

export default connect(mapStateToProps, { selectClaims, getClaimStatus })(
  ApproveClaim
);
