import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Form, FormGroup, Input, Label, FormText } from "reactstrap";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import {
  Validator,
  submitValidator,
  errors,
  valid,
  refresh,
} from "../../../common/Validator";
import { updateRoleMapping } from "../../../../redux/actions/iam/role_mapping/roleMappingAction";
import { getUserGroup } from "../../../../redux/actions/iam/auth_configuration/user_group/userGroupAction";
import { getRole } from "../../../../redux/actions/iam/auth_configuration/role_management/roleAction";

class UserRoleDetails extends Component {
  constructor(props) {
    super(props);
    const { id, primaryRole } = this.props.userRole;
    const { groupName } = this.props.userRole.pieUserGroup;
    const { roleCode } = this.props.userRole.pieUserRole;

    this.state = {
      roleMapId: id,
      primaryRole: primaryRole,
      employeeId: this.props.userRole.pieUser.id,
      empNo: this.props.userRole.pieUser.empNo,
      empName: `${this.props.userRole.pieUser.firstName} ${this.props.userRole.pieUser.lastName}`,
      userGroupId: this.props.userRole.pieUserGroup.id,
      groupName: groupName,
      roleId: this.props.userRole.pieUserRole.id,
      role: roleCode,
      edit: false,
      selectedOption: null,
      selectedOptionError: null,
      message: "",
      error: "",
      selectedOption: null,
      redirect: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.auth !== this.props.auth) {
      this.setState({
        permissionlist: this.props.auth.permisssionlist,
      });
    }
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleChange = (selectedOption) => {
    if (selectedOption) {
      this.setState({ selectedOption, selectedOptionError: null });
    } else {
      this.setState({
        selectedOptionError: "This field is mandatory",
      });
    }
  };

  /*
This function for updating the UserRoleDetails with body as UserRoleDetails
*/
  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let emp = {
      id: Number(this.state.roleMapId),
      pieUserId: this.state.employeeId,
      pieUserGroupId: Number(this.state.userGroupId),
      pieUserRoleId: Number(this.state.roleId),
      primaryRole: !!JSON.parse(String(this.state.primaryRole).toLowerCase()),
    };
    if (valid && !this.state.selectedOptionError) {
      this.props.updateRoleMapping(emp, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/pie/iam/role_mapping" />;
    }
  };

  componentDidMount() {
    this.props.getUserGroup();
    this.props.getRole();
    this.setState({
      permissionlist: this.props.auth.permissionlist,
    });
    refresh();
  }

  render() {
    const { userGroupId, roleId } = this.state;

    return (
      <Row>
        {!this.state.edit ? (
          <Col md="12">
            {typeof this.props.locationData !== "undefined" &&
              this.props.locationData.updation && (
                <i
                  className="material-icons edit-button pointer mt-4"
                  style={{ paddingLeft: "10px" }}
                  onClick={() => this.setState({ edit: !this.state.edit })}
                >
                  edit
                </i>
              )}
            <h5 className="bold pointer center pt-2">User Role Details</h5>
            <Row>
              <Col md="4" className="m-auto">
                <ul className="CustDetails-ul">
                  <li>
                    <span className="label-sm bold emp-span-label-col-1">
                      Emp Number
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.empNo}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold emp-span-label-col-1">
                      Emp Name
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.empName}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold emp-span-label-col-1">
                      User Group
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.groupName}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold emp-span-label-col-1">
                      Role
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.role}
                    </span>
                  </li>

                  <li>
                    <span className="label-sm bold emp-span-label-col-1">
                      Primary Role
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.state.primaryRole.toString()}
                    </span>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        ) : (
          <Col md="12">
            <h5 className="bold pointer center pt-2">
              Update User Role Details
            </h5>
            <Form onSubmit={this.onSubmit} className="p-3">
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label className="bold label-sm">PIE User</Label>
                    <Input
                      name="employeeId"
                      type="text"
                      className="form-control-sm"
                      value={this.state.empNo}
                      pattern="^[A-Za-z0-9_-]*$"
                      disabled
                    />
                    {errors.employeeId && (
                      <FormText className="error select-search-error">
                        {errors.employeeId}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label className="bold label-sm">User Group</Label>
                    <Input
                      type="select"
                      name="userGroupId"
                      value={userGroupId}
                      onChange={this.onChange}
                      className="form-control-sm"
                      required={true}
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.userGroups.data.map((item) => {
                        return (
                          <option value={item.id}>
                            {item.id}-{item.groupName}
                          </option>
                        );
                      })}
                    </Input>
                    {errors.userGroupId && (
                      <FormText className="error">
                        {errors.userGroupId}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label className="bold label-sm">Role</Label>
                    <Input
                      type="select"
                      name="roleId"
                      value={roleId}
                      onChange={this.onChange}
                      className="form-control-sm"
                      required={true}
                    >
                      <option value="" hidden>
                        Select...{" "}
                      </option>
                      {this.props.roles.data.map((item) => {
                        if (
                          Number(item.pieUserGroup.id) === Number(userGroupId)
                        ) {
                          return (
                            <option value={item.id}>
                              {item.id}-{item.roleCode}
                            </option>
                          );
                        }
                      })}
                    </Input>
                    {errors.roleId && (
                      <FormText className="error">{errors.roleId}</FormText>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label className="bold label-sm">Primary Role</Label>
                    <Input
                      type="select"
                      name="primaryRole"
                      className="form-control-sm"
                      value={this.state.primaryRole}
                      onChange={this.onChange}
                      required
                    >
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <div className="center">
                {this.renderRedirect()}
                <button type="submit" className="btn btn-custom">
                  Update
                </button>
              </div>
            </Form>
          </Col>
        )}
        {this.state.message ? <Notifier message={this.state.message} /> : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
        {this.props.roleMapping.message ? (
          <Notifier message={this.props.roleMapping.message} />
        ) : null}
        {this.props.roleMapping.error ? (
          <ErrorNotifier message={this.props.roleMapping.error} />
        ) : null}
      </Row>
    );
  }
}

UserRoleDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  updateRoleMapping: PropTypes.func.isRequired,
  roleMapping: PropTypes.object.isRequired,
  getUserGroup: PropTypes.func.isRequired,
  userGroups: PropTypes.object.isRequired,
  getRole: PropTypes.func.isRequired,
  roles: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  roleMapping: state.roleMapping,
  userGroups: state.userGroups,
  roles: state.roles,
});

export default connect(mapStateToProps, {
  updateRoleMapping,
  getUserGroup,
  getRole,
})(UserRoleDetails);
