import React, { Component } from "react";
import { Container, Table } from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DateFns from "date-fns";
// material ui
import { Box } from "@material-ui/core";
// components
import AddCustomerDialingCode from "./AddCustomerDialingCode";
import UpdateCustomerDialingCode from "./UpdateCustomerDialingCode";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import ConfirmDialog from "../../../../common/confirm-dialog/ConfirmDialog";
// redux
import {
  getCustomerDialingCode,
  deleteCustomerDialingCode,
} from "../../../../../redux/actions/crm/crm_setup/customerDialingCodeAction";

// -------------------------------------------------------------------------------------------------------------------------------------------
class CustomerDialingCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addBtn: false,
      updateBtn: false,
      updateData: [],
      confirmDialog: {
        isOpen: false,
        title: "",
        subTitle: "",
      },
      error: "",
      message: "",
    };

    this.toggleAddBtn = this.toggleAddBtn.bind(this);
    this.addBack = this.addBack.bind(this);
    this.toggleUpdateBtn = this.toggleUpdateBtn.bind(this);
    this.updateBack = this.updateBack.bind(this);
    this.getData = this.getData.bind(this);
    this.deleteCustomerDialingCode = this.deleteCustomerDialingCode.bind(this);
  }

  /*
toggleAddBtn function to toggle the addition page
*/
  toggleAddBtn() {
    this.setState({
      addBtn: true,
    });
  }

  /*
addBack function to back to the list page from the add page
*/
  addBack() {
    this.setState({
      addBtn: false,
    });

    this.getData();
  }

  /*
toggleUpdateBtn function to toggle the update form
*/
  toggleUpdateBtn(item) {
    this.setState({
      updateBtn: true,
      updateData: item,
    });
  }

  /*
updateBack function to redirect to back main page
*/
  updateBack() {
    this.setState({
      updateBtn: false,
    });

    this.getData();
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.props.getCustomerDialingCode();
  }

  deleteCustomerDialingCode(id) {
    let data = {
      id: id,
    };
    this.props.deleteCustomerDialingCode(data);
    this.setState((prevState) => ({
      ...prevState.confirmDialog,
      confirmDialog: {
        isOpen: false,
      },
    }));
  }

  setConfirmDialog = (obj) => {
    this.setState((prevProps) => ({
      confirmDialog: {
        ...prevProps.confirmDialog,
        isOpen: obj.isOpen,
      },
    }));
  };

  render() {
    const { addBtn, updateBtn, updateData, message, error } = this.state;
    let shrink = this.props.sidebar ? "scale" : "no-scale";

    return (
      <Box className={shrink}>
        <Container className="card container-card">
          {!addBtn && !updateBtn ? (
            <React.Fragment>
              <button
                className="btn add-button white-text label-sm"
                onClick={this.toggleAddBtn}
              >
                Add
              </button>
              <h5 className="bold center pt-2">Customer Dialing Code</h5>
              <Link
                to={{
                  pathname: "/pie/iam/service_menu",
                  moduleName: this.props.location.moduleName,
                  data: this.props.location.data,
                }}
              >
                <i className="material-icons cancel-button pointer">cancel</i>
              </Link>
              <Table className="center">
                <thead>
                  <tr>
                    <th className="opp-head ">Country</th>
                    <th className="opp-head ">Dialing Code</th>
                    <th className="opp-head ">Remarks</th>
                    <th className="opp-head ">CreatedBy</th>
                    <th className="opp-head ">CreatedOn</th>
                    <th className="opp-head ">UpdatedBy</th>
                    <th className="opp-head ">UpdatedOn</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.customerDialingCode.data.length > 0 &&
                    this.props.customerDialingCode.data.map((item) => {
                      return (
                        <tr>
                          <td className="opp-tableData">{item.countryName}</td>
                          <td className="opp-tableData">{item.dialingCode}</td>
                          <td className="opp-tableData">{item.remarks}</td>
                          <td className="opp-tableData">{item.createdBy}</td>
                          <td className="opp-tableData">
                            {DateFns.format(item.createdOn, "DD-MM-YYYY")}
                          </td>
                          <td className="opp-tableData">{item.updatedBy}</td>
                          <td className="opp-tableData">
                            {DateFns.format(item.updatedOn, "DD-MM-YYYY")}
                          </td>
                          <td>
                            <span
                              className="material-icons pointer opp-action-edit"
                              style={{ color: "blue" }}
                              onClick={() => this.toggleUpdateBtn(item)}
                            >
                              edit
                            </span>
                          </td>
                          <td>
                            <i
                              className="material-icons pointer"
                              onClick={() =>
                                this.setState({
                                  confirmDialog: {
                                    isOpen: true,
                                    title:
                                      "Are you sure to delete this record?",
                                    subTitle: "You can't undo this operation",
                                    onConfirm: () =>
                                      this.deleteCustomerDialingCode(item.id),
                                  },
                                })
                              }
                            >
                              delete
                            </i>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </React.Fragment>
          ) : null}
          {addBtn && <AddCustomerDialingCode back={this.addBack} />}
          {updateBtn && (
            <UpdateCustomerDialingCode
              back={this.updateBack}
              data={updateData}
            />
          )}
          {this.props.customerDialingCode.message && (
            <Notifier message={this.props.customerDialingCode.message} />
          )}
          {this.props.customerDialingCode.error && (
            <ErrorNotifier message={this.props.customerDialingCode.error} />
          )}
        </Container>
        <ConfirmDialog
          confirmDialog={this.state.confirmDialog}
          setConfirmDialog={this.setConfirmDialog}
        />
      </Box>
    );
  }
}

CustomerDialingCode.propTypes = {
  getCustomerDialingCode: PropTypes.func.isRequired,
  deleteCustomerDialingCode: PropTypes.func.isRequired,
  customerDialingCode: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  customerDialingCode: state.customerDialingCode,
});

export default connect(mapStateToProps, {
  getCustomerDialingCode,
  deleteCustomerDialingCode,
})(CustomerDialingCode);
