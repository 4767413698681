import axios from "axios";
import { EMP_POSITION_GET, EMP_POSITION_HIERARCHY_FETCH } from "../../types";
import { CURD_EMP_POSITION, USER_EMP_POSITION } from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addEmpPosition = (data, back) => (dispatch) => {
  axios
    .post(CURD_EMP_POSITION, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getEmpPosition));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getEmpPosition = () => (dispatch) => {
  axios
    .get(CURD_EMP_POSITION, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(EMP_POSITION_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserEmpPosition = () => (dispatch) => {
  axios
    .get(USER_EMP_POSITION, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(EMP_POSITION_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateEmpPosition = (data, back) => (dispatch) => {
  axios
    .put(CURD_EMP_POSITION, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getEmpPosition));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteEmpPosition = (data) => (dispatch) => {
  axios
    .delete(CURD_EMP_POSITION, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getEmpPosition));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

// getting the position list based on the level
export const fetchEmpPositionHierarchy = (data) => (dispatch) => {
  axios
    .get(`${CURD_EMP_POSITION}/${data}`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(EMP_POSITION_HIERARCHY_FETCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
