import React, { Component } from "react";
import { Container, Row, Col, Card } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// components
import {
  getHrPolicies,
  searchHrPolicies,
} from "../../../../../redux/actions/hcm/hr/policies/hrPoliciesAction";

class PolicyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
    };
    this.search = this.search.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.downloadPoDoc = this.downloadPoDoc.bind(this);
  }

  /*
componentDidMount lifeCycle fetch all the policies details
*/
  componentDidMount() {
    this.props.getHrPolicies();
  }

  /*
search function for searching the policies 
*/
  search(e) {
    e.preventDefault();
    this.setState({
      searchTerm: e.target.value,
    });
    this.onSearch(e.target.value);
  }

  onSearch(value) {
    let data = {
      policyName: value,
    };

    this.props.searchHrPolicies(data);
  }

  /*
downloadPoDoc function is to download the policy document
*/
  downloadPoDoc(item) {
    let data = {
      id: item.id,
    };
    // axios
    //   .post(DOWNLOAD_POLICIES, data, {
    //     responseType: "blob",
    //     withCredentials: true,
    //   })
    //   .then((res) => {
    //     const url = window.URL.createObjectURL(new Blob([res.data]));
    //     const link = document.createElement("a");
    //     link.href = url;
    //     link.setAttribute("download", item.docName);
    //     document.body.appendChild(link);
    //     link.click();
    //   })
    //   .catch((err) => {
    //     this.setState({
    //       error: "download failed",
    //     });
    //   });
    // setTimeout(() => {
    //   this.setState({
    //     message: "",
    //     error: "",
    //   });
    // }, 2000);
  }

  render() {
    let shrink = this.props.sidebar ? "scale" : "no-scale";

    return (
      <div className={shrink}>
        <Container className="card container-card pt-2">
          <input
            type="text"
            onChange={this.search}
            value={this.state.searchTerm}
            className="searchfield form-control-sm mr-3"
            placeholder="policyId/policyName"
          />
          <h5 className="pointer bold center">Policies </h5>
          <Link
            to={{
              pathname: "/pie/iam/service_menu",
              moduleName: this.props.location.moduleName,
              data: this.props.location.data,
            }}
          >
            <i className="material-icons cancel-button pointer">cancel</i>
          </Link>
          <Row className="pt-3">
            {this.props.hrPolicies.data.length > 0 &&
              this.props.hrPolicies.data.map((item) => {
                return (
                  <Col
                    md="3"
                    key={item.id}
                    onClick={() => this.downloadPoDoc(item)}
                  >
                    <Card className="p-3 mb-2 shadow pointer list flex-around p-0 m-0 mb-2">
                      <p className="list-item flex-1">
                        {item.id}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {item.policyName}
                      </p>
                      <p className="list-item flex-1">{item.docName}</p>
                    </Card>
                  </Col>
                );
              })}
          </Row>
        </Container>
      </div>
    );
  }
}

PolicyPage.propTypes = {
  getHrPolicies: PropTypes.func.isRequired,
  searchHrPolicies: PropTypes.func.isRequired,
  hrPolicies: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  hrPolicies: state.hrPolicies,
});

export default connect(mapStateToProps, { getHrPolicies, searchHrPolicies })(
  PolicyPage
);
