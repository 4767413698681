import React, { Component } from "react";
import { Provider } from "react-redux";
import store from "./redux/store/store";
import { BrowserRouter, Switch, Route } from "react-router-dom";
// css files
import "./assets/fonts/ubuntu.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/index.css";
// components
import Index from "./components/home/Index";
import EmpIndex from "./components/employee/EmpIndex";
import Forgotpassword from "./components/home/auth/Forgotpassword";
import ForgetpassChange from "./components/home/auth/ForgetpassChange";
import { TITLE } from "./utils/config";

// -------------------------------------------------------------------------------

class PG_React_Portal extends Component {
  // setting the web page title value
  componentDidMount() {
    document.title = TITLE;
  }

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <div className="App">
            <Switch>
              <Route path="/pie" component={EmpIndex} />
              <Route path="/" component={Index} />
              <Route path="/forgotpassword" component={Forgotpassword} />
              <Route path="/forgetpassChange" component={ForgetpassChange} />
            </Switch>
          </div>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default PG_React_Portal;
