import axios from "axios";
import {
  CLAIM_TYPE_ADD,
  CLAIM_TYPE_GET,
  CLAIM_TYPE_UPDATE,
  CLAIM_TYPE_DELETE,
  GET_ERRORS,
} from "../../types";
import {
  ADD_CLAIM_TYPE,
  GET_CLAIM_TYPE,
  UPDATE_CLAIM_TYPE,
  DELETE_CLAIM_TYPE,
} from "../../../../utils/routes";
import { setMessage } from "../../salesActions";

export const addClaimType = (data, back) => (dispatch) => {
  axios
    .post(ADD_CLAIM_TYPE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getClaimType());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const getClaimType = () => (dispatch) => {
  axios
    .get(GET_CLAIM_TYPE, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: CLAIM_TYPE_GET,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};

export const updateClaimType = (data, back) => (dispatch) => {
  axios
    .post(UPDATE_CLAIM_TYPE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getClaimType());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const deleteClaimType = (data) => (dispatch) => {
  axios
    .post(DELETE_CLAIM_TYPE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getClaimType());
      }, 2000);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};
