import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
// components
import FormComponent from "../../../common/FormComponent";
import {
  Validator,
  submitValidator,
  refresh,
  valid,
  errors,
} from "../../../common/Validator";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import SearchSelectBoxWithAdd from "../../../common/selectbox/SearchSelectBoxWithAdd";

// redux
import { addTagContacts } from "../../../../redux/actions/crm/contacts/tagContactsAction";
import { getCustomersName } from "../../../../redux/actions/crm/customers/customersAction";
import { getCustomerEntity } from "../../../../redux/actions/crm/customer_entity/customerEntityAction";
import { getAccounts } from "../../../../redux/actions/crm/accounts/accountsAction";
import { getUserContactWorkingStatus } from "../../../../redux/actions/crm/crm_setup/contactWorkingStatusAction";

// ------------------------------------------------------------------------------------------------------------

class AddTagContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerName: this.props.customerName ? this.props.customerName : "",
      customerEntity: this.props.entityName ? this.props.entityName : null,
      accountName: this.props.accountName
        ? this.props.accountName.toString()
        : null,
      contactId: "",
      contactName: this.props.contactName ? this.props.contactName : "",
      status: "",
      remarks: "",
      buttonValue: false,
      formErrors: {
        selectError: "",
      },
      error: "",
      spocError: "",
      message: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    e.preventDefault();
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleContactNameChange = (value) => {
    this.setState({
      contactId: value && value.id,
      contactName: value && value.contactName,
    });
  };

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let contact = {
      customerdetailId: this.state.customerName,
      customerentityId: this.state.customerEntity,
      customeraccountId: this.state.accountName,
      contactId: this.state.contactId,
      contactName: this.state.contactName,
      contactWorkingStatus: this.state.status,
      remarks: this.state.remarks,
    };
    if (valid) {
      this.props.addTagContacts(contact, this.props.back);
    } else {
      let errors = this.state.formErrors;
      errors.selectError = "Please select option";
      this.setState({
        formErrors: errors,
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    refresh();
    this.setState({
      message: "",
      error: "",
    });
    this.props.getUserContactWorkingStatus();
    this.props.getCustomersName();
    this.props.getCustomerEntity();
    this.props.getAccounts();
    if (this.props.name === "customer") {
      this.props.getData();
    } else if (this.props.name === "customerEntity") {
      this.props.getData(this.props.data);
    } else {
      this.props.getData(this.props.customerName, this.props.entityName);
    }
  }

  resetSpocNameError = () => {
    this.setState({
      spocError: "",
    });
  };

  render() {
    return (
      <>
        <Form onSubmit={this.onSubmit} className="p-3 mt-2" noValidate>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label className="label-sm bold">Customer Name</Label>
                <Input
                  type="select"
                  name="customerName"
                  onChange={this.onChange}
                  value={this.state.customerName}
                  className="form-control-sm"
                  required
                  disabled={this.state.customerName ? true : false}
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {this.props.customers.nameData.length > 0 &&
                    this.props.customers.nameData.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {" "}
                          {item.customerName}
                        </option>
                      );
                    })}
                </Input>
                {errors.customerName && (
                  <FormText className="error">{errors.customerName}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="label-sm bold">Customer Entity</Label>
                <Input
                  type="select"
                  name="customerEntity"
                  onChange={this.onChange}
                  value={this.state.customerEntity}
                  className="form-control-sm"
                  disabled={this.state.customerEntity ? true : false}
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {this.props.customerEntity.data.length > 0 &&
                    this.props.customerEntity.data.map((item) => {
                      if (this.state.customerName === item.customerdetailId) {
                        return (
                          <option key={item.id} value={item.id}>
                            {" "}
                            {item.entityName}
                          </option>
                        );
                      }
                    })}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label className="label-sm bold">Account Name</Label>
                <Input
                  type="select"
                  name="accountName"
                  onChange={this.onChange}
                  value={this.state.accountName}
                  className="form-control-sm"
                  disabled={this.state.accountName ? true : false}
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {this.props.accounts.data.length > 0 &&
                    this.props.accounts.data.map((item) => {
                      if (this.state.customerEntity === item.customerentityId) {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.accountName}
                          </option>
                        );
                      }
                    })}
                </Input>
              </FormGroup>

              <SearchSelectBoxWithAdd
                options={this.props.spocNameData}
                value={this.state.contactName}
                handleChange={(value) => this.handleContactNameChange(value)}
                reset={this.resetSpocNameError}
                spocError={this.state.spocError}
                refresh={this.props.getdata}
                label="Contact Name"
              />
            </Col>
            <Col md="6">
              <FormGroup>
                <Label className="bold label-sm">Contact Working Status</Label>
                <Input
                  type="select"
                  name="status"
                  value={this.state.status}
                  onChange={this.onChange}
                  className="form-control-sm"
                  required
                >
                  <option value="" hidden>
                    Select....
                  </option>
                  {this.props.contactWorkingStatus.data.length > 0 &&
                    this.props.contactWorkingStatus.data.map((item) => {
                      return (
                        <option key={item.id} value={item.conStatus}>
                          {" "}
                          {item.conStatus}
                        </option>
                      );
                    })}
                </Input>
                {errors.status && (
                  <FormText className="error">{errors.status}</FormText>
                )}
              </FormGroup>

              <FormComponent
                labelClass="bold label-sm"
                label="Remarks"
                type="textarea"
                name="remarks"
                change={this.onChange}
                value={this.state.remarks}
                placeholder="Remarks"
              />
            </Col>
          </Row>
          <div className="center">
            <button
              type="submit"
              className="btn center login-button white-text"
            >
              Submit
            </button>
          </div>
        </Form>
        {this.props.crmTagContacts.message ? (
          <Notifier message={this.props.crmTagContacts.message} />
        ) : null}
        {this.props.crmTagContacts.error ? (
          <ErrorNotifier message={this.props.crmTagContacts.error} />
        ) : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
      </>
    );
  }
}

AddTagContact.propTypes = {
  addTagContacts: PropTypes.func.isRequired,
  crmTagContacts: PropTypes.object.isRequired,
  getCustomersName: PropTypes.func.isRequired,
  customers: PropTypes.object.isRequired,
  getCustomerEntity: PropTypes.func.isRequired,
  customerEntity: PropTypes.object.isRequired,
  getAccounts: PropTypes.func.isRequired,
  accounts: PropTypes.object.isRequired,
  getUserContactWorkingStatus: PropTypes.func.isRequired,
  contactWorkingStatus: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  crmTagContacts: state.crmTagContacts,
  contactWorkingStatus: state.contactWorkingStatus,
  customers: state.customers,
  customerEntity: state.customerEntity,
  accounts: state.accounts,
});

export default connect(mapStateToProps, {
  addTagContacts,
  getUserContactWorkingStatus,
  getCustomersName,
  getCustomerEntity,
  getAccounts,
})(AddTagContact);
