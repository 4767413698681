import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import { addCampaign } from "../../../../redux/actions/campaignAction";
import { getEmployeeDetails } from "../../../../redux/actions/employeeAction";
import { getCampaignType } from "../../../../redux/actions/campaignTypeAction";
import { getSuccessCriteria } from "../../../../redux/actions/campaignSuccessCriteriaAction";
import { getCurrency } from "../../../../redux/actions/currencyAction";
import FormComponent from "../../../common/FormComponent";
import {
  Validator,
  submitValidator,
  refresh,
  valid,
  errors,
} from "../../../common/Validator";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";

class AddCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignName: "",
      campaignType: "",
      successCriteria: "",
      plannedBudget: "",
      currency: "",
      actualSpend: "",
      activityType: "",
      date: "",
      time: "",
      description: "",
      actionOwner: "",
      buttonValue: false,
      formErrors: {
        actionOwner: "",
      },
      message: "",
      error: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    const data = {
      campName: this.state.campaignName,
      campaignType: this.state.campaignType,
      successCriteria: this.state.successCriteria,
      plannedBudget: this.state.plannedBudget,
      currencyField: this.state.currency,
      actualSpend: this.state.actualSpend ? this.state.actualSpend : 0,
      activityType: this.state.activityType,
      date: this.state.date,
      time: this.state.time,
      description: this.state.description,
      actionOwner: this.state.actionOwner,
      createdBy: localStorage.getItem("id"),
    };

    if (this.state.actionOwner && valid) {
      this.props.addCampaign(data, this.props.history);
      this.setState({
        buttonValue: !this.state.buttonValue,
      });
    } else {
      let errors = this.state.formErrors;
      errors.actionOwner = "Please select option";
      this.setState({
        formErrors: errors,
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    refresh();
    this.setState({
      message: "",
      error: "",
    });
    this.props.getEmployeeDetails();
    this.props.getCampaignType();
    this.props.getSuccessCriteria();
    this.props.getCurrency();
  }

  render() {
    let shrink = this.props.sidebar ? "scale" : "no-scale";
    const { actionOwner } = this.state.formErrors;
    return (
      <div className={shrink}>
        <Container className="card container-card">
          <Link to="/pie/marketing/campaign">
            <i
              className="material-icons pointer"
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                color: "#C00",
              }}
            >
              cancel
            </i>
          </Link>
          <h5 className="bold center pt-3">Add Campaign</h5>
          <Form onSubmit={this.onSubmit} className="p-3" noValidate>
            <Row>
              <Col md="6">
                <FormComponent
                  labelClass="label-sm bold"
                  label="Campaign Name"
                  name="campaignName"
                  type="text"
                  value={this.state.campaignName}
                  change={this.onChange}
                  required={true}
                />
                <FormGroup>
                  <Label className="bold label-sm">Campaing Type</Label>
                  <Input
                    type="select"
                    className="form-control-sm"
                    name="campaignType"
                    onChange={this.onChange}
                    value={this.state.campaignType}
                    required={true}
                  >
                    <option hidden value="">
                      Select...
                    </option>
                    {this.props.campaignType.data.map((item) => {
                      return (
                        <option
                          key={item.campaignTypeId}
                          value={item.campaignType}
                        >
                          {item.campaignType}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.campaignType && (
                    <FormText className="error">{errors.campaignType}</FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">Success Criteria</Label>
                  <Input
                    type="select"
                    className="form-control-sm"
                    name="successCriteria"
                    onChange={this.onChange}
                    value={this.state.successCriteria}
                    required={true}
                  >
                    <option hidden value="">
                      Select...
                    </option>
                    {this.props.campaignSuccessCriteria.data.map((item) => {
                      return (
                        <option
                          key={item.successCriteriaId}
                          value={item.successCriteria}
                        >
                          {item.successCriteria}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.successCriteria && (
                    <FormText className="error">
                      {errors.successCriteria}
                    </FormText>
                  )}
                </FormGroup>
                <Row>
                  <Col md="6" style={{ paddingLeft: "0px" }}>
                    <FormComponent
                      labelClass="bold label-sm"
                      label="Planned Budget"
                      type="number"
                      name="plannedBudget"
                      change={this.onChange}
                      value={this.state.plannedBudget}
                      required={true}
                    />
                  </Col>
                  <Col md="6" style={{ paddingRight: "0px" }}>
                    <Label className="bold label-sm">Currency</Label>
                    <Input
                      type="select"
                      name="currency"
                      className="form-control-sm"
                      value={this.state.currency}
                      onChange={this.onChange}
                      required={true}
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.currency.data.map((item) => {
                        return (
                          <option
                            key={item.currencyId}
                            value={item.currencyCode}
                          >
                            {item.currencyCode}
                          </option>
                        );
                      })}
                    </Input>
                    {errors.currency && (
                      <FormText className="error">{errors.currency}</FormText>
                    )}
                  </Col>
                </Row>
                <FormComponent
                  labelClass="label-sm bold"
                  label="Actual Spend"
                  name="actualSpend"
                  type="number"
                  value={this.state.actualSpend}
                  change={this.onChange}
                />
              </Col>
              <Col md="6">
                <FormComponent
                  labelClass="label-sm bold"
                  label="Activity Type"
                  name="activityType"
                  type="text"
                  value={this.state.activityType}
                  change={this.onChange}
                  required={true}
                />
                <Row>
                  <Col md="6" style={{ paddingLeft: "0px" }}>
                    <FormComponent
                      labelClass="bold label-sm"
                      label="Date"
                      inputClass="form-control-sm"
                      type="date"
                      name="date"
                      change={this.onChange}
                      value={this.state.date}
                      required={true}
                    />
                  </Col>
                  <Col md="6" style={{ paddingRight: "0px" }}>
                    <FormComponent
                      labelClass="bold label-sm"
                      label="Time"
                      inputClass="form-control-sm"
                      type="time"
                      name="time"
                      change={this.onChange}
                      value={this.state.time}
                      required={true}
                    />
                  </Col>
                </Row>
                <FormGroup className="mt--7">
                  <Label className="bold label-sm">Action Owner</Label>
                  <Input
                    type="select"
                    className="form-control-sm"
                    name="actionOwner"
                    onChange={this.onChange}
                    value={this.state.actionOwner}
                  >
                    <option hidden>Not assigned</option>
                    {this.props.employee.data.map((item) => {
                      if (item.empActiveStatus === "Active") {
                        return (
                          <option key={item.empId} value={item.empId}>
                            {item.empId}-{item.firstName}
                          </option>
                        );
                      }
                    })}
                  </Input>
                  {!this.state.actionOwner && (
                    <FormText className="error">{actionOwner}</FormText>
                  )}
                </FormGroup>
                <FormComponent
                  labelClass="label-sm bold"
                  label="Description"
                  name="description"
                  type="textarea"
                  value={this.state.description}
                  change={this.onChange}
                  required={true}
                />
              </Col>
            </Row>
            <div className="center">
              <button
                className="btn login-button white-text"
                disabled={this.state.buttonValue ? "disabled" : null}
              >
                Add
              </button>
            </div>
          </Form>
          {this.props.campaign.message ? (
            <Notifier message={this.props.campaign.message} />
          ) : null}
          {this.state.error ? (
            <ErrorNotifier message={this.state.error} />
          ) : null}
        </Container>
      </div>
    );
  }
}

AddCampaign.propTypes = {
  addCampaign: PropTypes.func.isRequired,
  getEmployeeDetails: PropTypes.func.isRequired,
  employee: PropTypes.object.isRequired,
  campaign: PropTypes.object.isRequired,
  getCampaignType: PropTypes.func.isRequired,
  campaignType: PropTypes.object.isRequired,
  getSuccessCriteria: PropTypes.func.isRequired,
  campaignSuccessCriteria: PropTypes.object.isRequired,
  getCurrency: PropTypes.func.isRequired,
  currency: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  employee: state.employee,
  campaign: state.campaign,
  campaignType: state.campaignType,
  campaignSuccessCriteria: state.campaignSuccessCriteria,
  currency: state.currency,
});

export default connect(mapStateToProps, {
  addCampaign,
  getEmployeeDetails,
  getCampaignType,
  getSuccessCriteria,
  getCurrency,
})(AddCampaign);
