import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Container,
  FormText,
} from "reactstrap";
import DateFns from "date-fns";
// components
import FormComponent from "../../../common/FormComponent";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../common/Validator";
// redux
import { addLeaveManagement } from "../../../../redux/actions/hcm/lms/leaveManagementAction";
import { getUserLeaveType } from "../../../../redux/actions/hcm/configurations/leaveTypeAction";
import { getUserCompanyHolidays } from "../../../../redux/actions/hcm/configurations/companyHolidaysAction";

class ApplyLeave extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      startDate: "",
      endDate: "",
      noOfDays: "",
      dialingCode: "",
      contactNumber: "",
      remarks: "",
      approver: "",
      dateError: "",
      startDateError: "",
      message: "",
      error: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.noOfDaysCalculation = this.noOfDaysCalculation.bind(this);
  }

  onChange(e) {
    Validator(e.target);

    if (e.target.name === "endDate") {
      if (
        Date.parse(e.target.value) < Date.parse(this.state.startDate) ||
        !this.state.startDate
      ) {
        this.setState({
          [e.target.name]: e.target.value,
          dateError: "EndDate must be greater than or equal to StartDate",
        });
      } else {
        this.setState({
          [e.target.name]: e.target.value,
          dateError: "",
        });
        this.noOfDaysCalculation(e.target.value);
      }
    } else if (e.target.name === "startDate") {
      if (
        Date.parse(e.target.value) <
        Date.parse(DateFns.format(new Date(), "YYYY-MM-DD"))
      ) {
        this.setState({
          [e.target.name]: e.target.value,
          startDateError:
            "StartDate must be greater than or equal to current date",
          endDate: "",
        });
      } else {
        this.setState({
          [e.target.name]: e.target.value,
          startDateError: "",
          endDate: "",
        });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  /* noOfDaysCalculation function for calculating the number of days from startDate and EndDate */
  noOfDaysCalculation(endDate) {
    let startDate = new Date(this.state.startDate);

    let toDate = new Date(endDate);

    let companyHolidays = null;

    while (startDate < toDate) {
      this.props.companyHolidays.data.length > 0 &&
        this.props.companyHolidays.data.map((item) => {
          if (
            item.phDate === DateFns.format(startDate, "YYYY-MM-DD").toString()
          ) {
            companyHolidays = companyHolidays + 1;
          }
        });
      if (DateFns.format(startDate, "ddd").toString() === "Sun") {
        companyHolidays = companyHolidays + 1;
      }
      startDate = DateFns.addDays(startDate, 1);
    }

    let date1 = new Date(this.state.startDate);
    let date2 = new Date(endDate);

    let differenceTime = date2.getTime() - date1.getTime();
    let differenceDay = differenceTime / (1000 * 3600 * 24) - companyHolidays;

    this.setState({
      noOfDays: differenceDay + 1,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      empName: this.props.auth.data.pieUser.firstName,
      leaveType: this.state.type,
      fromDate: this.state.startDate,
      leaveDate: this.state.startDate,
      toDate: this.state.endDate,
      noOfDays: this.state.noOfDays,
      dialingCode: this.state.dialingCode,
      contactNumber: this.state.contactNumber,
      leaveRemarks: this.state.remarks,
      approver: this.state.approver,
      leaveStatus: "PENDING",
    };
    if (valid && !this.state.dateError) {
      this.props.addLeaveManagement(data, this.reset);
    } else {
      this.setState({
        error: "Enter all Mandatory Fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    this.props.getUserLeaveType();
    this.props.getUserCompanyHolidays();
    refresh();
  }

  reset = () => {
    this.state = {
      type: "",
      startDate: "",
      endDate: "",
      noOfDays: "",
      dialingCode: "",
      contactNumber: "",
      remarks: "",
      approver: "",
      dateError: "",
      message: "",
      error: "",
    };
  };

  render() {
    let shrink = this.props.sidebar ? "scale" : "no-scale";

    return (
      <div className={shrink}>
        <Container className="card container-card pt-2">
          <Link
            to={{
              pathname: "/pie/iam/service_menu",
              moduleName: this.props.location.moduleName,
              data: this.props.location.data,
            }}
          >
            <i className="material-icons cancel-button pointer">cancel</i>
          </Link>
          <h5 className="bold center">Apply New Leave</h5>
          <Form className="p-3" onSubmit={this.onSubmit} noValidate>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label className="bold label-sm">Type of leave</Label>
                  <Input
                    type="select"
                    name="type"
                    className="form-control-sm"
                    value={this.state.type}
                    onChange={this.onChange}
                    required={true}
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.leaveType.data.length > 0 &&
                      this.props.leaveType.data.map((item) => {
                        return (
                          <option key={item.id}>{item.leaveTypeName}</option>
                        );
                      })}
                  </Input>
                  {errors.type && (
                    <FormText className="error">{errors.type}</FormText>
                  )}
                </FormGroup>
                <FormComponent
                  labelClass="bold label-sm"
                  label="Leave start date"
                  type="date"
                  name="startDate"
                  inputClass="form-control-sm"
                  value={this.state.startDate}
                  change={this.onChange}
                  error={this.state.startDateError}
                  required={true}
                  min={DateFns.format(new Date(), "YYYY-MM-DD").toString()}
                  max="9999-12-31"
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Leave end date"
                  type="date"
                  name="endDate"
                  inputClass="form-control-sm"
                  value={this.state.endDate}
                  change={this.onChange}
                  required={true}
                  error={this.state.dateError}
                  min={this.state.startDate}
                  max="9999-12-31"
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="No. of Days"
                  type="number"
                  inputClass="form-control-sm"
                  value={this.state.noOfDays}
                  disabled={true}
                />
              </Col>
              <Col md="6">
                <Row>
                  <Col md="2" className="p-0">
                    <FormComponent
                      labelClass="bold label-sm"
                      label=" "
                      type="number"
                      name="dialingCode"
                      inputClass="form-control-sm"
                      value={this.state.dialingCode}
                      change={this.onChange}
                      required={true}
                    />
                  </Col>
                  <Col md="10" className="p-0">
                    <FormComponent
                      labelClass="bold label-sm"
                      label="Contact Number"
                      type="tel"
                      name="contactNumber"
                      inputClass="form-control-sm"
                      value={this.state.contactNumber}
                      change={this.onChange}
                      required={true}
                    />
                  </Col>
                </Row>
                <FormComponent
                  labelClass="bold label-sm"
                  label="Approver Email"
                  type="email"
                  name="approver"
                  inputClass="form-control-sm"
                  value={this.state.approver}
                  change={this.onChange}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Leave remarks"
                  type="textarea"
                  name="remarks"
                  inputClass="form-control-sm"
                  value={this.state.remarks}
                  change={this.onChange}
                  required={true}
                />
              </Col>
            </Row>
            {typeof this.props.location.serviceAccessData !== "undefined" &&
            this.props.location.serviceAccessData.creation ? (
              <div className="center">
                <button type="submit" className="btn login-button white-text">
                  Apply Leave
                </button>
              </div>
            ) : null}
          </Form>
          {this.props.leaveManagement.message && (
            <Notifier message={this.props.leaveManagement.message} />
          )}
          {this.props.leaveManagement.error && (
            <ErrorNotifier message={this.props.leaveManagement.error} />
          )}
        </Container>
      </div>
    );
  }
}

ApplyLeave.propTypes = {
  getUserLeaveType: PropTypes.func.isRequired,
  leaveType: PropTypes.object.isRequired,
  getUserCompanyHolidays: PropTypes.func.isRequired,
  companyHolidays: PropTypes.object.isRequired,
  addLeaveManagement: PropTypes.func.isRequired,
  leaveManagement: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  leaveType: state.leaveType,
  companyHolidays: state.companyHolidays,
  leaveManagement: state.leaveManagement,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getUserLeaveType,
  getUserCompanyHolidays,
  addLeaveManagement,
})(ApplyLeave);
