import React from "react";
import { Typography } from "@material-ui/core";

// --------------------------------------
function PageTitle(props) {
  return (
    <div>
      <h5 className="center">{props.children}</h5>
    </div>
  );
}

export default PageTitle;
