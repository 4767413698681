import React, { Component } from "react";
import { Container, Card, Row, Col } from "reactstrap";
import UploadPayslip from "./payslip_upload/UploadPayslip";
import MyPayslip from "./my_payslip/MyPayslip";

class PayslipDashboard extends Component {
  constructor() {
    super();
    this.state = {
      edit: "",
    };
    this.back = this.back.bind(this);
    this.chooseForm = this.chooseForm.bind(this);
  }

  back() {
    this.setState({
      edit: "",
    });
  }

  chooseForm() {
    let component = this.state.edit;
    if (component === "payslipUpload") {
      return <UploadPayslip back={this.back} />;
    }
    if (component === "myPayslips") {
      return <MyPayslip back={this.back} />;
    }
  }

  render() {
    let shrink;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");
    return (
      <div className={shrink}>
        {!this.state.edit ? (
          <div className="container-fluid">
            <div className="row">
              <div className="col-10 mx-auto">
                <h4 className="bold center pt-2 pb-3">Payslips</h4>
                <Row className="content-center">
                  <Col md="4">
                    <Card
                      className="center profile-edit-card"
                      onClick={() => this.setState({ edit: "payslipUpload" })}
                    >
                      <h6 className="bold">Payslip Upload</h6>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card
                      className="center profile-edit-card"
                      onClick={() => this.setState({ edit: "myPayslips" })}
                    >
                      <h6 className="bold">My Payslips</h6>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        ) : (
          this.chooseForm()
        )}
      </div>
    );
  }
}
export default PayslipDashboard;
