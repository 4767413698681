import axios from "axios";
import { E360_GLOBAL_GET } from "../../types";
import { CURD_E360_GLOBAL, USER_E360_GLOBAL } from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addE360Global = (data, back) => (dispatch) => {
  axios
    .post(CURD_E360_GLOBAL, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getE360Global));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getE360Global = () => (dispatch) => {
  axios
    .get(CURD_E360_GLOBAL, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(E360_GLOBAL_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserE360Global = () => (dispatch) => {
  axios
    .get(USER_E360_GLOBAL, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(E360_GLOBAL_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateE360Global = (data, back) => (dispatch) => {
  axios
    .put(CURD_E360_GLOBAL, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getE360Global));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteE360Global = (data) => (dispatch) => {
  axios
    .delete(CURD_E360_GLOBAL, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getE360Global));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
