/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
} from "reactstrap";
// material ui
import { IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
// components
import {
  refresh,
  submitValidator,
  valid,
  Validator,
} from "../../../common/Validator";
import RequestsDetails from "./RequestsDetails";
import Hover from "../../../aside/Hover";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import FormComponent from "../../../common/FormComponent";
import CustomizedDialogs from "../../../common/customized-dialog/CustomizedDialogs";
import ViewRequests from "./ViewRequests";
import CustTooltip from "../../../common/tooltip/CustTooltip";
// redux
import {
  addRequests,
  searchRequests,
  updateRequests,
  fetchRequests,
  getRequests,
  getClosedRequests,
} from "../../../../redux/actions/experience_360/requestsAction";

// -----------------------------------------------------------------------------------

class Requests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      roleIdData: [],
      subItem: {},
      selection: null,
      hover: false,
      cords: {},
      mouse: {},
      popup: false,
      searchTerm: "",
      remarks: "",
      selected: "",
      modal: false,
      viewRequests: false,
      currentPermission: {},
      message: null,
      error: null,
    };
    this.getData = this.getData.bind(this);
    this.getRoleIdData = this.getRoleIdData.bind(this);
    this.select = this.select.bind(this);
    this.search = this.search.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.cancel = this.cancel.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.toggleHoverOn = this.toggleHoverOn.bind(this);
    this.toggleHoverOff = this.toggleHoverOff.bind(this);
    this.toggle1 = this.toggle1.bind(this);
    this.back = this.back.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.requests !== this.props.requests) {
      this.setState({
        data: this.props.requests.data,
      });
    }
  }

  search(e) {
    e.preventDefault();
    this.setState({
      searchTerm: e.target.value,
    });
    this.onSearch(e.target.value);
  }

  onSearch(value) {
    let data = {
      reqType: value,
    };

    this.props.searchRequests(data);
  }

  select(item) {
    this.setState({
      selection: item,
      selected: item.id,
      popup: true,
    });
  }

  cancel() {
    this.setState({
      popup: false,
    });
    this.getData();
  }

  onChange(e) {
    Validator(e.target);
    e.preventDefault();
    this.setState({
      remarks: e.target.value,
    });
  }

  /*
To validate the remarks form during onSubmit using the subitValidator and errors function
*/
  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    const data = this.props.requests.data.filter((item) => {
      if (item.id === this.state.subItem) {
        return item;
      } else {
        return null;
      }
    });
    data[0].comments = this.state.remarks;
    const feedback = {
      id: data[0].id.toString(),
      reqType: data[0].reqType,
      reqTitle: data[0].reqTitle,
      contactNumber: data[0].contactNumber,
      detailedDescription: data[0].detailedDescription,
      serverity: data[0].serverity,
      priority: data[0].priority,
      status: data[0].status,
      comments: data[0].comments,
    };
    if (valid) {
      this.props.updateRequests(feedback, this.toggle);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /* 
This toggle function is for the model toggle after the submission of remarks
*/
  toggle() {
    this.setState({
      modal: !this.state.modal,
      remarks: "",
    });
  }

  toggle1() {
    this.setState({
      modal: !this.state.modal,
      remarks: "",
    });
    this.getData();
    this.getRoleIdData();
  }

  /*
First time calling the getData and refresh methods
getData method for getting the idea list 
refresh method for resetting the errors object
*/
  componentDidMount() {
    for (let a in this.props.auth.data.accesses) {
      if (this.props.auth.data.accesses[a].appName === "E360") {
        for (let m in this.props.auth.data.accesses[a].pieUserModuleAccess) {
          if (
            this.props.auth.data.accesses[a].pieUserModuleAccess[m]
              .moduleName === "Requests"
          ) {
            for (let s in this.props.auth.data.accesses[a].pieUserModuleAccess[
              m
            ].pieUserServiceAccess) {
              if (
                this.props.auth.data.accesses[a].pieUserModuleAccess[m]
                  .pieUserServiceAccess[s].serviceName === "User Requests"
              ) {
                this.setState({
                  currentPermission:
                    this.props.auth.data.accesses[a].pieUserModuleAccess[m]
                      .pieUserServiceAccess[s],
                });
              }
            }
          }
        }
      }
    }
    this.getRoleIdData();
    refresh();
    this.props.getClosedRequests();
  }

  /*
Here calling the redux getIdea method to getting the idea list
*/
  getData() {
    let data = 1;
    this.props.fetchRequests(data);
  }

  getRoleIdData() {
    this.props.getRequests();
  }

  toggleHoverOn(e, item) {
    this.setState({
      hover: true,
      cords: {
        one: item.requestDescription,
      },
      mouse: {
        x: e.screenX,
        y: e.screenY,
      },
    });
  }
  toggleHoverOff() {
    this.setState({
      hover: false,
    });
  }
  onDragOver(e) {
    e.preventDefault();
  }
  onDragStart(e, item) {
    e.dataTransfer.setData("item", item.id);
    this.setState({
      subItem: item.id,
    });
  }

  async onDrop(e, cat) {
    let id = e.dataTransfer.getData("item");
    let newData;
    newData = this.props.requests.data.filter((item) => {
      if (item.id === parseInt(id, 10)) {
        item.status = cat;
      }
      return newData;
    });
    this.setState({
      ...this.state,
      newData,
    });
    await this.toggle();
  }

  back() {
    this.setState({
      popup: false,
    });
    this.getData();
    this.getRoleIdData();
  }

  handleViewRequests = () => {
    this.setState({
      viewRequests: true,
    });
  };

  handleCloseViewRequests = () => {
    this.setState({
      viewRequests: false,
    });
  };

  render() {
    let shrink;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");

    /*
This if condition for redirecting to the login page after the refresh of the page
*/
    const {
      data,
      roleIdData,
      selection,
      selected,
      hover,
      cords,
      popup,
      modal,
      remarks,
      currentPermission,
    } = this.state;

    return (
      <div className={shrink}>
        <Modal isOpen={modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle1}>Confirm changes?</ModalHeader>
          <ModalBody>
            <Form className="p-3" onSubmit={this.onSubmit} noValidate>
              <FormComponent
                labelClass="bold label-sm"
                label="Comments"
                type="textarea"
                name="remarks"
                change={this.onChange}
                value={remarks}
                required={true}
              />
              <FormGroup className="center">
                <button type="submit" className="btn login-button white-text">
                  Submit
                </button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
        {!popup ? (
          <Card className="container-card m-2">
            <input
              type="text"
              onChange={this.search}
              value={this.state.searchTerm}
              className="e360-request-searchfield form-control-sm"
              placeholder="ReqId/ReqType/Title/Status"
            />
            <CustTooltip title="Search">
              <IconButton
                type="submit"
                style={{
                  border: "none",
                  width: "3%",
                  position: "absolute",
                  top: "0.65rem",
                  right: "5%",
                  height: "1.9rem",
                  color: "#0056b3",
                  padding: ".1rem",
                }}
              >
                <SearchIcon />
              </IconButton>
            </CustTooltip>
            <CustTooltip title="View Requests">
              <IconButton
                className="e360-request-eye-button"
                onClick={this.handleViewRequests}
              >
                <RemoveRedEyeIcon />
              </IconButton>
            </CustTooltip>
            {Object.keys(this.state.currentPermission).length > 0 &&
              this.state.currentPermission.creation && (
                <div className="div">
                  <Link to="/pie/experience_360/requests/add">
                    <CustTooltip title="Create Request">
                      <button className="btn new-btn white-text label-sm">
                        New
                      </button>
                    </CustTooltip>
                  </Link>
                </div>
              )}
            <h5 className="center bold">Request Kanban</h5>
            <Row className="pt-3">
              <Col
                md="4"
                onDragOver={this.onDragOver}
                onDrop={(e) => this.onDrop(e, "Open")}
                className="card p-2"
              >
                <h6 className="center bold m-2">Open</h6>
                <ul className="idea-flex-around ul-head">
                  <li className="list-idea-item pad-req-id bold">ID</li>
                  <li className="list-idea-item pad-reqType bold">Type</li>
                  <li className="list-idea-item pad-title bold">Title</li>
                  <li className="list-idea-item pad-severity bold">Severity</li>
                </ul>

                {this.props.requests.data.length > 0 &&
                  this.props.requests.data.map((item) => {
                    if (
                      item.status === "Open" ||
                      item.status === "Re-Open" ||
                      item.status === "New"
                    ) {
                      return (
                        <ul
                          key={item.id}
                          onClick={() => {
                            this.select(item);
                          }}
                          onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                          onMouseLeave={this.toggleHoverOff}
                          draggable
                          onDragStart={(e) => this.onDragStart(e, item)}
                          className={`list idea-flex-around pointer ${
                            selected && selected === item.id && "selected-item"
                          } InqList`}
                        >
                          <li className="list-req-item pad-req-id">
                            {item.id}
                          </li>
                          <li className="list-req-item pad-reqType">
                            {item.reqType}
                          </li>
                          <li className="list-req-item pad-title">
                            {item.reqTitle}
                          </li>
                          <li
                            className="list-req-item pad-severity"
                            style={{ paddingTop: "1%" }}
                          >
                            <div
                              style={{
                                backgroundColor: item.color,
                                width: "10px",
                                height: "10px",
                                borderRadius: "100%",
                                margin: "auto",
                              }}
                            ></div>
                          </li>
                        </ul>
                      );
                    } else {
                      return null;
                    }
                  })}
              </Col>
              <Col
                md="4"
                onDragOver={this.onDragOver}
                onDrop={(e) => this.onDrop(e, "Work in Progress")}
                className="card p-2"
              >
                <h6 className="center bold m-2">Work In Progress</h6>
                <ul className="idea-flex-around ul-head">
                  <li className="list-idea-item pad-req-id bold">ID</li>
                  <li className="list-idea-item pad-reqType bold">Type</li>
                  <li className="list-idea-item pad-title bold">Title</li>
                  <li className="list-idea-item pad-severity bold">Severity</li>
                </ul>
                {this.props.requests.data.length > 0 &&
                  this.props.requests.data.map((item) => {
                    if (item.status === "Work in Progress") {
                      return (
                        <ul
                          key={item.id}
                          onClick={() => {
                            this.select(item);
                          }}
                          onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                          onMouseLeave={this.toggleHoverOff}
                          draggable
                          onDragStart={(e) => this.onDragStart(e, item)}
                          className={`list idea-flex-around pointer ${
                            selected && selected === item.id && "selected-item"
                          } InqList`}
                        >
                          <li className="list-req-item pad-req-id">
                            {item.id}
                          </li>
                          <li className="list-req-item pad-reqType">
                            {item.reqType}
                          </li>
                          <li className="list-req-item pad-title">
                            {item.reqTitle}
                          </li>
                          <li
                            className="list-req-item pad-severity"
                            style={{ paddingTop: "1%" }}
                          >
                            <div
                              style={{
                                backgroundColor: item.color,
                                width: "10px",
                                height: "10px",
                                borderRadius: "100%",
                                margin: "auto",
                              }}
                            ></div>
                          </li>
                        </ul>
                      );
                    } else {
                      return null;
                    }
                  })}
              </Col>
              <Col
                md="4"
                className="card p-2"
                onDrop={(e) => this.onDrop(e, "Closed")}
                onDragOver={this.onDragOver}
              >
                <h6 className="center bold m-2">Closed</h6>
                <ul className="idea-flex-around ul-head">
                  <li className="list-idea-item pad-req-id bold">ID</li>
                  <li className="list-idea-item pad-reqType bold">Type</li>
                  <li className="list-idea-item pad-title bold">Title</li>
                  <li className="list-idea-item pad-severity bold">Severity</li>
                </ul>
                {this.props.requests.closedData.length > 0 &&
                  this.props.requests.closedData.map((item) => {
                    return (
                      <ul
                        key={item.id}
                        onClick={() => {
                          this.select(item);
                        }}
                        onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                        onMouseLeave={this.toggleHoverOff}
                        draggable
                        onDragStart={(e) => this.onDragStart(e, item)}
                        className={`list idea-flex-around pointer ${
                          selected && selected === item.id && "selected-item"
                        } InqList`}
                      >
                        <li className="list-req-item pad-req-id">{item.id}</li>
                        <li className="list-req-item pad-reqType">
                          {item.reqType}
                        </li>
                        <li className="list-req-item pad-title">
                          {item.reqTitle}
                        </li>
                        <li
                          className="list-req-item pad-severity"
                          style={{ paddingTop: "1%" }}
                        >
                          <div
                            style={{
                              backgroundColor: item.color,
                              width: "10px",
                              height: "10px",
                              borderRadius: "100%",
                              margin: "auto",
                            }}
                          ></div>
                        </li>
                      </ul>
                    );
                  })}
              </Col>
              {this.state.message && <Notifier message={this.state.message} />}
              {this.state.error && <ErrorNotifier message={this.state.error} />}
            </Row>
          </Card>
        ) : (
          <Card className="container-card m-2">
            <RequestsDetails
              feedback={selection}
              back={this.back}
              cancel={this.cancel}
              ideaData={this.getData}
              permission={this.state.currentPermission}
            />
          </Card>
        )}
        <CustomizedDialogs
          open={this.state.viewRequests}
          handleClose={this.handleCloseViewRequests}
          title="View Requests"
          size="md"
        >
          <ViewRequests />
        </CustomizedDialogs>
        {this.props.requests.message && (
          <Notifier message={this.props.requests.message} />
        )}
        {this.props.requests.error && (
          <ErrorNotifier message={this.props.requests.error} />
        )}
      </div>
    );
  }
}

Requests.propTypes = {
  errors: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  addRequests: PropTypes.func.isRequired,
  searchRequests: PropTypes.func.isRequired,
  updateRequests: PropTypes.func.isRequired,
  fetchRequests: PropTypes.func.isRequired,
  getRequests: PropTypes.func.isRequired,
  getClosedRequests: PropTypes.func.isRequired,
  requests: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  auth: state.auth,
  requests: state.requests,
});

export default connect(mapStateToProps, {
  addRequests,
  searchRequests,
  updateRequests,
  fetchRequests,
  getRequests,
  getClosedRequests,
})(Requests);
