import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
// components
import BankInformation from "./bank_information/BankInformation";
import OtherAccountInformation from "./other_account_information/OtherAccountInformation";

class AccountDetails extends Component {
  render() {
    return (
      <Container className="card container-card">
        <i
          className="material-icons contact-cancel-button pointer"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Row className="bank-info-row">
          <Col className="contact-details-col">
            <BankInformation />
          </Col>
          {/* <Col className="contact-details-col">
            <OtherAccountInformation />
          </Col> */}
        </Row>
      </Container>
    );
  }
}

export default AccountDetails;
