import axios from "axios";
import { GET_USER_VARIABLE_LIST_RS } from "../../types";
import {
  ADD_USER_VARIABLE_RS,
  GET_USER_VARIABLE_RS,
  UPDATE_USER_VARIABLE_RS,
  DELETE_USER_VARIABLE_RS
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addUserVarible = (data, back) => (dispatch) => {
  axios
    .post(ADD_USER_VARIABLE_RS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getUserVariable ));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserVariable = () => (dispatch) => {
  axios
    .get( GET_USER_VARIABLE_RS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(GET_USER_VARIABLE_LIST_RS, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateUserVariable = (data, back) => (dispatch) => {
  axios
    .put( UPDATE_USER_VARIABLE_RS , data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteUserVariable = (data) => (dispatch) => {
  axios
    .delete(DELETE_USER_VARIABLE_RS, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getUserVariable ));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
