import axios from "axios";
import { CURRENCY_GET, GET_ERRORS } from "./types";
import { GET_CURRENCY } from "../../utils/routes";

export const getCurrency = () => (dispatch) => {
  axios
    .get(GET_CURRENCY, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: CURRENCY_GET,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};
