import React, { Component } from "react";
import {
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Container,
  FormText,
} from "reactstrap";
import PropTypes from "prop-types";
import DateFns from "date-fns";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// components
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
// redux
import { addManagerInitiateResignation } from "../../../../../redux/actions/hcm/resignation/managerInitiateResignationAction";
import { getEmpPersonalInfo } from "../../../../../redux/actions/hcm/hr/emp_management/empPersonalInfoAction";
import { getUserManagerResignationReason } from "../../../../../redux/actions/hcm/configurations/managerResignationReasonAction";
import { getUserResignationType } from "../../../../../redux/actions/hcm/configurations/resignationTypeAction";
import { getUserResignationStatus } from "../../../../../redux/actions/hcm/configurations/resignationStatusAction";

// ---------------------------------------------------------------------------------------------
class ManagerInitiateResignation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      empId: "",
      requestDate: DateFns.format(new Date(), "YYYY-MM-DD"),
      type: "",
      releaseDate: DateFns.format(
        DateFns.addMonths(new Date(), 2),
        "YYYY-MM-DD"
      ),
      reason: "",
      status: "New",
      remarks: "",
      suggestions: [],
      message: "",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      empId: this.state.empId,
      regRequestDate: this.state.requestDate,
      regType: this.state.type,
      releaseDate: this.state.releaseDate,
      regReason: this.state.reason,
      regStatus: this.state.status,
      remarks: this.state.remarks,
      createdBy: sessionStorage.getItem("id"),
    };
    if (valid) {
      this.props.addManagerInitiateResignation(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidMount contains the error refresh method and setState method for calling the render method
*/
  componentDidMount() {
    refresh();
    this.props.getEmpPersonalInfo();
    this.props.getUserManagerResignationReason();
    this.props.getUserResignationType();
    this.props.getUserResignationStatus();
    this.setState({
      message: "",
      error: "",
    });
  }

  onTextChanged = (e) => {
    Validator(e.target);
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      suggestions = this.props.empPersonalInfo.data
        .sort()
        .filter((v) => regex.test(v.firstName));
    }
    this.setState(() => ({ suggestions, empId: value }));
  };

  suggestionSelected = (value) => {
    this.setState(() => ({
      empId: value,
      suggestions: [],
    }));
  };

  renderSuggestions = () => {
    const { suggestions } = this.state;
    if (suggestions.length === 0) {
      return null;
    }
    return (
      <ul className="suggestions" style={{ marginTop: "-5px" }}>
        {suggestions.map((item) => {
          return (
            <li onClick={() => this.suggestionSelected(item.firstName)}>
              {item.firstName}
            </li>
          );
        })}
      </ul>
    );
  };

  render() {
    const {
      empId,
      requestDate,
      type,
      releaseDate,
      reason,
      status,
      remarks,
      error,
      message,
    } = this.state;
    let shrink = this.props.sidebar ? "scale" : "no-scale";

    return (
      <div className={shrink}>
        <Container className="card container-card pt-2">
          <h5 className="center bold pt-2">Manager Initiate Resignation</h5>
          <Link
            to={{
              pathname: "/pie/iam/service_menu",
              moduleName: this.props.location.moduleName,
              data: this.props.location.data,
            }}
          >
            <i className="material-icons cancel-button pointer">cancel</i>
          </Link>
          <Form onSubmit={this.onSubmit} className="p-3" noValidate>
            <Row>
              <Col md="6 mx-auto">
                <div className="autocompleteDiv">
                  <FormGroup>
                    <Label className="bold label-sm">Employee</Label>
                    <Input
                      type="text"
                      name="empId"
                      onChange={this.onTextChanged}
                      value={empId}
                      autoComplete="off"
                      required={true}
                      className="form-control-sm"
                      placeholder="Search Employee by Name"
                    />
                    {errors.empId && (
                      <FormText className="error">{errors.empId}</FormText>
                    )}
                  </FormGroup>
                  {this.renderSuggestions()}
                </div>
                <FormComponent
                  labelClass="bold label-sm"
                  label="Resignation Request Date"
                  type="date"
                  name="requestDate"
                  inputClass="form-control-sm"
                  value={requestDate}
                  required={true}
                  disabled={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Release Date"
                  type="date"
                  name="releaseDate"
                  inputClass="form-control-sm"
                  value={releaseDate}
                  required={true}
                  disabled={true}
                />
                <FormGroup>
                  <Label className="bold label-sm">Resignation Type</Label>
                  <Input
                    type="select"
                    name="type"
                    className="form-control-sm"
                    value={type}
                    onChange={this.onChange}
                    required={true}
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.resignationType.data.map((item) => {
                      return (
                        <option key={item.id} value={item.regTypeName}>
                          {item.regTypeName}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.type && (
                    <FormText className="error">{errors.type}</FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">Resignation Reason</Label>
                  <Input
                    type="select"
                    name="reason"
                    className="form-control-sm"
                    value={reason}
                    onChange={this.onChange}
                    required={true}
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.managerResignationReason.data.map((item) => {
                      return (
                        <option key={item.id} value={item.managerRegName}>
                          {item.managerRegName}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.reason && (
                    <FormText className="error">{errors.reason}</FormText>
                  )}
                </FormGroup>

                <FormComponent
                  labelClass="bold label-sm"
                  label="Remarks"
                  type="textarea"
                  name="remarks"
                  inputClass="form-control-sm"
                  value={remarks}
                  change={this.onChange}
                  required={true}
                />
              </Col>
            </Row>
            <div className="center">
              <button type="submit" className="btn login-button white-text">
                Submit
              </button>
            </div>
          </Form>
          {this.props.managerInitiateResignation.message && (
            <Notifier message={this.props.managerInitiateResignation.message} />
          )}
          {this.props.managerInitiateResignation.error && (
            <ErrorNotifier
              message={this.props.managerInitiateResignation.error}
            />
          )}
        </Container>
      </div>
    );
  }
}

ManagerInitiateResignation.propTypes = {
  addManagerInitiateResignation: PropTypes.func.isRequired,
  managerInitiateResignation: PropTypes.object.isRequired,
  getEmpPersonalInfo: PropTypes.func.isRequired,
  empPersonalInfo: PropTypes.object.isRequired,
  getUserManagerResignationReason: PropTypes.func.isRequired,
  managerResignationReason: PropTypes.object.isRequired,
  getUserResignationType: PropTypes.func.isRequired,
  resignationType: PropTypes.object.isRequired,
  getUserResignationStatus: PropTypes.func.isRequired,
  resignationStatus: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  empPersonalInfo: state.empPersonalInfo,
  managerResignationReason: state.managerResignationReason,
  resignationType: state.resignationType,
  resignationStatus: state.resignationStatus,
  managerInitiateResignation: state.managerInitiateResignation,
});

export default connect(mapStateToProps, {
  getEmpPersonalInfo,
  getUserManagerResignationReason,
  getUserResignationType,
  getUserResignationStatus,
  addManagerInitiateResignation,
})(ManagerInitiateResignation);
