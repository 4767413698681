import axios from "axios";
import { EMP_ONBOARDING_GET } from "../../types";
import {
  GET_EMP_ONBOARDING,
  CURD_EMP_ONBOARDING,
  SEARCH_EMP_ONBOARDING,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addEmployee = (data, history) => (dispatch) => {
  let link = "/pie/iam/user_onboarding";
  axios
    .post(CURD_EMP_ONBOARDING, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, null, history, link));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getEmployee = () => (dispatch) => {
  axios
    .get(GET_EMP_ONBOARDING, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(EMP_ONBOARDING_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const searchUser = (data) => (dispatch) => {
  axios
    .post(SEARCH_EMP_ONBOARDING, data, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(EMP_ONBOARDING_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateEmployee = (data, back) => (dispatch) => {
  axios
    .put(CURD_EMP_ONBOARDING, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteEmployee = (data) => (dispatch) => {
  axios
    .delete(CURD_EMP_ONBOARDING, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getEmployee));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
