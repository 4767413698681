import axios from "axios";
import {
  CAMPAIGN_TYPE_ADD,
  CAMPAIGN_TYPE_GET,
  CAMPAIGN_TYPE_UPDATE,
  CAMPAIGN_TYPE_DELETE,
  GET_ERRORS,
} from "./types";
import {
  ADD_CAMPAIGN_TYPE,
  GET_CAMPAIGN_TYPE,
  UPDATE_CAMPAIGN_TYPE,
  DELETE_CAMPAIGN_TYPE,
} from "../../utils/routes";
import { setMessage } from "./salesActions";

export const addCampaignType = (data, back) => (dispatch) => {
  axios
    .post(ADD_CAMPAIGN_TYPE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getCampaignType());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const getCampaignType = () => (dispatch) => {
  axios
    .get(GET_CAMPAIGN_TYPE, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: CAMPAIGN_TYPE_GET,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};

export const updateCampaignType = (data, back) => (dispatch) => {
  axios
    .post(UPDATE_CAMPAIGN_TYPE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getCampaignType());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const deleteCampaignType = (data) => (dispatch) => {
  axios
    .post(DELETE_CAMPAIGN_TYPE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getCampaignType());
      }, 2000);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};
