import axios from "axios";
import {
  CERTIFICATION_DETAILS_GET,
  CERTIFICATION_DETAILS_FETCH,
} from "../../types";
import {
  CURD_CERTIFICATION_DETAILS,
  FETCH_CERTIFICATION_DETAILS,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addCertificationDetails = (data, back) => (dispatch) => {
  axios
    .post(CURD_CERTIFICATION_DETAILS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, fetchCertificationDetails));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getCertificationDetails = () => (dispatch) => {
  axios
    .get(CURD_CERTIFICATION_DETAILS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CERTIFICATION_DETAILS_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const fetchCertificationDetails = () => (dispatch) => {
  axios
    .get(FETCH_CERTIFICATION_DETAILS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CERTIFICATION_DETAILS_FETCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateCertificationDetails = (data, back) => (dispatch) => {
  axios
    .put(CURD_CERTIFICATION_DETAILS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteCertificationDetails = (data) => (dispatch) => {
  axios
    .delete(CURD_CERTIFICATION_DETAILS, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, fetchCertificationDetails));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
