import axios from "axios";
import {
  GET_ERRORS,
  ISSUE_PO_GET,
  PURCHASE_ITEM_GET,
  SCHEDULED_PYMT_DATE_GET,
  LATEST_PO_GET,
} from "../../../types";
import {
  GET_ISSUE_PO,
  ADD_ISSUE_PO,
  UPDATE_ISSUE_PO,
  ADD_PURCHASE_ITEM,
  UPDATE_PURCHASE_ITEM,
  GET_PURCHASE_ITEM,
  ADD_SCHEDULED_PYMT_DATE,
  UPDATE_SCHEDULED_PYMT_DATE,
  GET_SCHEDULED_PYMT_DATE,
  GET_LATEST_PO,
} from "../../../../../utils/routes";
import { setMessage } from "../../../salesActions";

export const getIssuePOs = () => (dispatch) => {
  axios
    .get(GET_ISSUE_PO, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: ISSUE_PO_GET,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};

export const addIssuePO = (data, back) => (dispatch) => {
  axios
    .post(ADD_ISSUE_PO, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(latestPO(data.createdBy));
        // back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const updateIssuePO = (data, back) => (dispatch) => {
  axios
    .post(UPDATE_ISSUE_PO, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getIssuePOs());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const addUpdateIssuePO = (data) => (dispatch) => {
  axios
    .post(UPDATE_ISSUE_PO, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getIssuePOs());
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const addPurchaseItem = (data, back) => (dispatch) => {
  axios
    .post(ADD_PURCHASE_ITEM, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        // dispatch(getIssuePOs());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const updatePurchaseItem = (data, back) => (dispatch) => {
  axios
    .post(UPDATE_PURCHASE_ITEM, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        // dispatch(getIssuePOs());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const getPurchaseItems = (data) => (dispatch) => {
  axios
    .post(GET_PURCHASE_ITEM, data, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: PURCHASE_ITEM_GET,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};

export const addScheduledPymtDate = (data, back) => (dispatch) => {
  axios
    .post(ADD_SCHEDULED_PYMT_DATE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        // dispatch(getIssuePOs());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const updateScheduledPymtDate = (data, back) => (dispatch) => {
  axios
    .post(UPDATE_SCHEDULED_PYMT_DATE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        // dispatch(getIssuePOs());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const getScheduledPymtDate = (data) => (dispatch) => {
  axios
    .post(GET_SCHEDULED_PYMT_DATE, data, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: SCHEDULED_PYMT_DATE_GET,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};

export const latestPO = (empId) => (dispatch) => {
  let data = {
    createdBy: empId,
  };

  axios
    .post(GET_LATEST_PO, data, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: LATEST_PO_GET,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};

export const resetItems = () => (dispatch) => {
  dispatch({
    type: PURCHASE_ITEM_GET,
    payload: [],
  });
  dispatch({
    type: SCHEDULED_PYMT_DATE_GET,
    payload: [],
  });
};
