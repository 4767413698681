import React, { useState,useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { GrView } from "react-icons/gr";

import { Container, Row, Col } from "reactstrap";
// components
import ListContainer from "../../../../common/layout/ListContainer";
import PageTitle from "../../../../common/page_title/PageTitle";
import {
  Validator,
  submitValidator,
  valid,
} from "../../../../common/Validator";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import ViewUploadedMessage from '../../../../common/viewUploadedmessageRs'

// redux
import { addResumeUpload } from "../../../../../redux/actions/rs/upload/resumeUploadAction";

// ------------------------------------------------------------------------------
export const ResumeUpload = (props) => {
  const [file, setFile] = useState("");
  const [fileError, setFileError] = useState("");
  const [error, setError] = useState("");
  const[fileValue,setFileValue] = useState("")
  const[selected,setSelected] = useState({})
  const[newformData,setNewFormData] = useState({})
  const[hover,setHover] = useState(false)
  const[isVisible,setIsVisible] = useState(false)
  const[isOpen,setIsOpen] = useState(false)

  

  const onSubmit = (e) => {
    e.preventDefault();
    submitValidator(e.target);
    const formData = new FormData()
    if(file.length>1){
   for(let i = 0; i < file.length; i++){
   formData.append("files",file[i])
   console.log(formData.get("files"))
    }
    }
    else{
    formData.append("files",file)
    }
  
if (file) {
    props.addResumeUpload(formData);
    setFile("");
    setIsVisible(true)
    } else {
      setError("Enter all mandatory fields");
      setTimeout(() => {
        setError("");
        setFile("");
      }, 2000);
    }
  };

 
  
  const handleFile = (e) => {
    console.log(e.target.files.length)
    Validator(e.target);
    if(e.target.files.length === 1){
      setFile(e.target.files[0] || []);
    }
    else{
      setFile(e.target.files || []);
    }
  
    
  // let allowedExtension = /(\.pdf)$/i;
    // if (allowedExtension.exec(e.target.files[0].name)) {
    //   if ((e.target.files[0].size / 1024 / 1024).toFixed(2) < 2) {
    //     setFile(e.target.files[0] || []);
    //     setFileError("");
    //     setFileValue(e.target.value = "")
    //   } else {
    //     setFile(e.target.files[0]);
    //     setFileError("File size must less than 2MB");
    //   }
    // } else {
    //   setFile(e.target.files[0]);
    //   setFileError("The upload File should be .xlsx format");
    // }
  };

 

  const openErrorLog = () => {
    console.log("first")
  setIsOpen(!isOpen)
  }

 

  const handleCloseErrorLog = () =>{
    setIsOpen(false)
  }
// useEffect(() => {
//  props.candidatesUpload.dataExtracted = []
//  props.candidatesUpload.dataProcessed = []
//  props.candidatesUpload.dataUnProcessed = []
// }, [])


  return (
    <ListContainer sidebar={props.sidebar ? "scale" : "no-scale"}>
      
      <PageTitle>Resume Upload</PageTitle>
    
      <Link
        to={{
          pathname: "/pie/iam/service_menu",
          moduleName: props.location.moduleName,
          data: props.location.data,
        }}
      >
        <i className="material-icons cancel-button pointer">cancel</i>
      </Link>
            



      <Grid container justify="center">
        <Grid item lg={6} sm={12}>
          <Form onSubmit={onSubmit} className="p-3" noValidate>
            <div className="file-div">
              <FormGroup className="file-display">
                <Label
                  className="bold label-sm"
                  style={{ fontSize: ".9rem",fontWeight:400 }}
                >
                  Attachment
                </Label>
                <Input
                  disabled
                  type="text"
                  value={file.name || "" || fileValue}
                  className="form-control-sm "
                  required={true}
                 
                />
                {fileError && (
                  <FormText className="error ">{fileError}</FormText>
                )}
              </FormGroup>
              <FormGroup className="file-browse-btn-div">
                <label className="btn label-sm file-browse-btn">
                  Browse
                  <Input
                    type="file"
                    name="file"
                    style={{
                      display: "none",
                      marginTop: "23px",
                      marginLeft: "-28px",
                    }}
                    className="form-control-sm file-input"
                    onChange={handleFile}
                    multiple
                    required={true}
                    accept=".pdf, .png"
                  />
                </label>
              </FormGroup>
            </div>
            <div className="center">
              <button style={{marginTop:"0.5rem"}} type="submit" className="btn login-button white-text">
                Upload
              </button>
            </div>
          </Form>
        </Grid>
      </Grid>

      {/* <ViewUploadedMessage viewUploadedData ={props.candidatesUpload}/> */}
   {/* {isVisible && <ViewUploadedMessage viewData={props.candidatesUpload}/>} */}

      {props.candidatesUpload.message && (
        <Notifier message={props.candidatesUpload.message} />
      )}
      {props.candidatesUpload.error && (
        <ErrorNotifier message={props.candidatesUpload.error} />
      )}
      {error && <ErrorNotifier message={error} />}
    </ListContainer>
  );
};

ResumeUpload.propTypes = {
  uploadCandidates: PropTypes.func.isRequired,
  candidatesUpload: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  candidatesUpload: state.candidatesUpload,
});

const mapDispatchToProps = (dispatch) => ({
  addResumeUpload: (fd) => dispatch(addResumeUpload(fd)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResumeUpload);
