import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormText,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Select from "react-select";
import { connect } from "react-redux";
import DateFns from "date-fns";
import {
  addPOEntry,
  addUpdatePoEntry,
  getPOEntry,
  addPurchaseItem,
  getPurchaseItems,
  addScheduledPymtDate,
  getScheduledPymtDate,
} from "../../../../../redux/actions/finance/accounts_receivable/po_entry/poEntryAction";
import { getPOType } from "../../../../../redux/actions/finance/accounts_payable/issue_po/poTypeAction";
import { getUserBusinessLocations } from "../../../../../redux/actions/hcm/business_setup/businessLocationsAction";
import { getCustomers } from "../../../../../redux/actions/customerAction";
import { getPartnerPymtTerms } from "../../../../../redux/actions/partnerPymtTermsAction";
import FormComponent from "../../../../common/FormComponent";
import {
  errors,
  Validator,
  submitValidator,
  valid,
  refresh,
} from "../../../../common/Validator";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import Notifier from "../../../../aside/Notifier";

/**
 * @class AddPOEntry
 **/

// formValid function to handle the form validation
// const formValid = (formErrors) => {
//   let valid;

//   Object.values(formErrors).forEach((val) => {
//     val.length > 0 && (valid = false);
//   });

//   return valid;
// };

class AddPOEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      poId: "",
      poEntryName: "",
      poNo: "",
      poType: "",
      customerNameSelectedOption: "",
      shipToAddress: "",
      contactName: "",
      contactEmail: "",
      contactPhoneNumber: "",
      shipToLocation: "",
      shipToContactEmail: "",
      shipToContactNo: "",
      poCurrency: "",
      poDescription: "",
      paymentTerms: "",
      totalAmount: "",
      totalGst: "",
      poTotalAmount: "",
      purchaseItemsModal: false,
      purchaseItemNo: "",
      pspName: "",
      quantity: "",
      amount: "",
      amountGST: "",
      pymtScheduleModal: false,
      scheduledPymtDate: "",
      pymtAmount: "",
      toggleCreatePoEntry: true,
      customerNameSelectedOptionError: "",
      error: "",
      // formErrors: {
      //   shipToAddress: ''
      // }
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  customerNameHandleChange = (customerNameSelectedOption) => {
    if (customerNameSelectedOption) {
      this.setState({
        customerNameSelectedOption,
        customerNameSelectedOptionError: null,
      });
    } else {
      this.setState({
        customerNameSelectedOptionError: "This field is mandatory",
      });
    }
  };

  componentDidMount() {
    this.props.getPOType();
    this.props.getUserBusinessLocations();
    this.props.getCustomers();
    this.props.getPartnerPymtTerms();
    refresh();
  }

  // componentDidUpdate function we will get the vendor and partners details based on the invoice type selection
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.poEntry.latestPOEntryData !==
      this.props.poEntry.latestPOEntryData
    ) {
      this.setState({
        toggleCreatePoEntry: false,
        poId: this.props.poEntry.latestPOEntryData[0].poId,
      });
    }
    if (prevState.shipToLocation !== this.state.shipToLocation) {
      this.props.busiLocation.data.map((item) => {
        if (this.state.shipToLocation === item.locationName) {
          this.setState({
            shipToAddress: item.address,
            poCurrency: item.currency,
          });
        }
      });
    }
    if (prevState.amount !== this.state.amount) {
      this.setState({
        amountGST: (this.state.amount * 0.18).toFixed(2),
      });
    }
    if (
      prevProps.poEntry.purchaseItemData !== this.props.poEntry.purchaseItemData
    ) {
      if (this.props.poEntry.purchaseItemData.length !== 0) {
        let arr = this.props.poEntry.purchaseItemData;
        let sumAmount = 0;
        let sumAmountGst = 0;
        arr.forEach(function (obj) {
          sumAmount += obj.amount * obj.qty;
          sumAmountGst += obj.gstAmount * obj.qty;
        });
        this.setState({
          totalAmount: sumAmount,
          totalGst: sumAmountGst.toFixed(2),
        });
      }
    }
  }

  // onSubmit function to submit the form
  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    const { customerNameSelectedOption } = this.state;
    if (valid && customerNameSelectedOption) {
      let data = {
        poEntryName: this.state.poEntryName,
        poNo: this.state.poNo,
        poType: this.state.poType,
        customerName:
          this.state.customerNameSelectedOption &&
          this.state.customerNameSelectedOption.value,
        contactName: this.state.contactName,
        contactEmail: this.state.contactEmail,
        contactMobileNo: this.state.contactPhoneNumber,
        shipToLocation: this.state.shipToLocation,
        shipToAddress: this.state.shipToAddress,
        shipToContactMobileEmail: this.state.shipToContactEmail,
        shipToContactMobileNo: this.state.shipToContactNo,
        poDescription: this.state.poDescription,
        poCurrency: this.state.poCurrency,
        pymtTerm: this.state.paymentTerms,
        createdBy: localStorage.getItem("id"),
      };

      this.props.addPOEntry(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all Mandatory Fields",
      });
      if (!customerNameSelectedOption) {
        this.setState({
          customerNameSelectedOptionError: "This field is mandatory",
        });
      }
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  // purchaseItemSubmit function for submitting the form details
  purchaseItemSubmit = (e) => {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      poId: this.state.poId,
      receivableItemNo: this.state.purchaseItemNo,
      ppsName: this.state.pspName,
      qty: this.state.quantity,
      amount: this.state.amount,
      gstAmount: this.state.amountGST,
    };
    if (valid) {
      this.props.addPurchaseItem(data, this.purchaseItemToggle);
    } else {
      this.setState({
        error: "Enter all Mandatory Fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  };

  // pymtScheduleSubmit function for submitting the form details
  pymtScheduleSubmit = (e) => {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      poId: this.state.poId,
      scheduledPymtDate: this.state.scheduledPymtDate,
      paymentAmount: this.state.pymtAmount,
    };
    if (valid) {
      this.props.addScheduledPymtDate(data, this.pymtScheduleToggle);
    } else {
      this.setState({
        error: "Enter all Mandatory Fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  };

  purchaseItemReset = () => {
    this.setState({
      purchaseItemNo: "",
      pspName: "",
      quantity: "",
      amount: "",
      amountGST: "",
    });
  };

  purchaseItemToggle = () => {
    this.setState({
      purchaseItemModal: !this.state.purchaseItemModal,
    });
    refresh();
    this.purchaseItemReset();
    let data1 = {
      poId: this.state.poId,
    };
    this.props.getPurchaseItems(data1);
  };

  pymtScheduleReset = () => {
    this.setState({
      scheduledPymtDate: "",
      pymtAmount: "",
    });
  };

  pymtScheduleToggle = () => {
    this.setState({
      pymtScheduleModal: !this.state.pymtScheduleModal,
    });
    refresh();
    this.pymtScheduleReset();
    let data1 = {
      poId: this.state.poId,
    };
    this.props.getScheduledPymtDate(data1);
  };

  // updatePOEntry function to update the totalAmount, totalGst and poTotalAmount in POEntryTable
  updatePOEntry = (poTotalAmount) => {
    let data = {
      poId: this.state.poId,
      totalAmount: this.state.totalAmount,
      poTotalAmount: poTotalAmount,
      totalGst: this.state.totalGst,
      updatedBy: localStorage.getItem("id"),
    };
    this.props.addUpdatePoEntry(data);
  };

  render() {
    const {
      poNo,
      poEntryName,
      poType,
      customerNameSelectedOption,
      shipToAddress,
      contactName,
      contactEmail,
      contactPhoneNumber,
      shipToLocation,
      shipToContactEmail,
      shipToContactNo,
      poCurrency,
      poDescription,
      paymentTerms,
      totalAmount,
      totalGst,
      purchaseItemModal,
      purchaseItemNo,
      pspName,
      quantity,
      amount,
      amountGST,
      pymtScheduleModal,
      scheduledPymtDate,
      pymtAmount,
      toggleCreatePoEntry,
      customerNameSelectedOptionError,
      error,
    } = this.state;

    let customerNameOptions = [];
    for (let i = 0; i < this.props.customers.data.length; i++) {
      var x = {};
      x["value"] = this.props.customers.data[i].compFullName;
      x["label"] = this.props.customers.data[i].compFullName;
      x["fetchValue"] = this.props.customers.data[i].compCode;

      customerNameOptions.push(x);
    }

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 mx-auto">
            <Container className="card container-card">
              <h5 className="center bold pt-2">New PO Entry</h5>
              <i
                className="material-icons pointer cancel-button"
                onClick={this.props.back}
              >
                cancel
              </i>
              <Row>
                {toggleCreatePoEntry ? (
                  <Col md="8" className="mx-auto">
                    <Form onSubmit={this.onSubmit} className="p-3" noValidate>
                      <Row>
                        <Col md="6">
                          <FormComponent
                            label="PO Entry Name"
                            labelClass="label-sm bold"
                            type="text"
                            name="poEntryName"
                            value={poEntryName}
                            className="form-control-sm"
                            required={true}
                            change={this.onChange}
                          />
                          <FormComponent
                            label="PO Number"
                            labelClass="label-sm bold"
                            type="number"
                            name="poNo"
                            value={poNo}
                            className="form-control-sm"
                            required={true}
                            change={this.onChange}
                          />
                          <FormGroup>
                            <Label className="label-sm bold">PO Type</Label>
                            <Input
                              type="select"
                              name="poType"
                              value={poType}
                              className="form-control-sm"
                              onChange={this.onChange}
                              required={true}
                            >
                              <option value="" hidden>
                                Select
                              </option>
                              {this.props.poTypes.data.map((item) => {
                                return (
                                  <option
                                    key={item.poTypeId}
                                    value={item.poType}
                                  >
                                    {item.poType}
                                  </option>
                                );
                              })}
                            </Input>
                            {errors.poType && (
                              <FormText className="error">
                                {errors.poType}
                              </FormText>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label className="label-sm bold">
                              Customer Name
                            </Label>
                            <Select
                              name="customerNameOptions"
                              value={customerNameSelectedOption}
                              onChange={this.customerNameHandleChange}
                              options={customerNameOptions}
                              className="select-form-control-sm"
                              required
                            />

                            {customerNameSelectedOptionError && (
                              <FormText className="error ">
                                {customerNameSelectedOptionError}
                              </FormText>
                            )}
                          </FormGroup>
                          <FormComponent
                            label="Contact Name"
                            labelClass="label-sm bold"
                            type="text"
                            name="contactName"
                            value={contactName}
                            className="form-control-sm"
                            required={true}
                            change={this.onChange}
                          />
                          <FormComponent
                            label="Contact Email"
                            labelClass="label-sm bold"
                            type="email"
                            name="contactEmail"
                            value={contactEmail}
                            className="form-control-sm"
                            required={true}
                            change={this.onChange}
                          />
                          <FormComponent
                            label="Contact Phone Number"
                            labelClass="label-sm bold"
                            type="tel"
                            name="contactPhoneNumber"
                            value={contactPhoneNumber}
                            className="form-control-sm"
                            required={true}
                            change={this.onChange}
                          />
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label className="bold label-sm">
                              Ship To Location
                            </Label>
                            <Input
                              type="select"
                              name="shipToLocation"
                              className="form-control-sm "
                              value={shipToLocation}
                              onChange={this.onChange}
                              required={true}
                            >
                              <option value="" hidden>
                                Select...
                              </option>
                              {this.props.busiLocation.data.map((item) => {
                                return (
                                  <option
                                    key={item.locationId}
                                    value={item.locationName}
                                  >
                                    {item.locationCode}-{item.locationName}
                                  </option>
                                );
                              })}
                            </Input>
                            {errors.shipToLocation && (
                              <FormText className="error">
                                {errors.shipToLocation}
                              </FormText>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label className="bold label-sm">
                              Ship To Address
                            </Label>
                            <Input
                              type="text"
                              name="shipToAddress"
                              className="form-control-sm "
                              value={shipToAddress}
                              onChange={this.onChange}
                              disabled={true}
                            ></Input>
                          </FormGroup>
                          <FormComponent
                            label="Ship To Contact Email"
                            labelClass="label-sm bold"
                            type="email"
                            name="shipToContactEmail"
                            value={shipToContactEmail}
                            className="form-control-sm"
                            change={this.onChange}
                            required={true}
                          />
                          <FormComponent
                            label="Ship To Contact Number"
                            labelClass="label-sm bold"
                            type="tel"
                            name="shipToContactNo"
                            value={shipToContactNo}
                            className="form-control-sm"
                            change={this.onChange}
                            required={true}
                          />
                          <FormGroup>
                            <Label className="bold label-sm">PO Currency</Label>
                            <Input
                              type="text"
                              name="poCurrency"
                              className="form-control-sm "
                              value={poCurrency}
                              onChange={this.onChange}
                              disabled={true}
                            ></Input>
                            {errors.poCurrency && (
                              <FormText className="error">
                                {errors.poCurrency}
                              </FormText>
                            )}
                          </FormGroup>
                          <FormComponent
                            label="PO Description"
                            labelClass="label-sm bold"
                            type="textarea"
                            name="poDescription"
                            value={poDescription}
                            className="form-control-sm"
                            change={this.onChange}
                            required={true}
                          />
                          <FormGroup>
                            <Label className="label-sm bold">
                              Payment Terms
                            </Label>
                            <Input
                              type="select"
                              className="form-control-sm"
                              name="paymentTerms"
                              value={paymentTerms}
                              onChange={this.onChange}
                              required={true}
                            >
                              {" "}
                              <option value="" hidden>
                                Select...
                              </option>
                              {this.props.partnerPymtTerms.data.map((item) => {
                                return (
                                  <option
                                    key={item.partnerPymtId}
                                    value={item.partnerPymtName}
                                  >
                                    {item.partnerPymtName}
                                  </option>
                                );
                              })}
                            </Input>
                            {errors.paymentTerms && (
                              <FormText className="error">
                                {errors.paymentTerms}
                              </FormText>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="center">
                        <button
                          type="submit"
                          className="btn center login-button white-text"
                        >
                          Create
                        </button>
                      </div>
                    </Form>
                  </Col>
                ) : (
                  <React.Fragment>
                    <Col md="6">
                      <h6>PO Entry Details</h6>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          PO Id
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.poEntry.latestPOEntryData[0] &&
                            this.props.poEntry.latestPOEntryData[0].poId}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          PO Name
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.poEntry.latestPOEntryData[0] &&
                            this.props.poEntry.latestPOEntryData[0].poEntryName}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          PO No
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.poEntry.latestPOEntryData[0] &&
                            this.props.poEntry.latestPOEntryData[0].poNo}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Customer Name
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.poEntry.latestPOEntryData[0] &&
                            this.props.poEntry.latestPOEntryData[0]
                              .customerName}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Contact Name
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.poEntry.latestPOEntryData[0] &&
                            this.props.poEntry.latestPOEntryData[0].contactName}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Contact Email
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.poEntry.latestPOEntryData[0] &&
                            this.props.poEntry.latestPOEntryData[0]
                              .contactEmail}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Contact No
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.poEntry.latestPOEntryData[0] &&
                            this.props.poEntry.latestPOEntryData[0]
                              .contactMobileNo}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Ship To Location
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.poEntry.latestPOEntryData[0] &&
                            this.props.poEntry.latestPOEntryData[0]
                              .shipToLocation}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Ship To Address
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.poEntry.latestPOEntryData[0] &&
                            this.props.poEntry.latestPOEntryData[0]
                              .shipToAddress}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Contact Email
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.poEntry.latestPOEntryData[0] &&
                            this.props.poEntry.latestPOEntryData[0]
                              .shipToContactMobileEmail}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Contact No
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.poEntry.latestPOEntryData[0] &&
                            this.props.poEntry.latestPOEntryData[0]
                              .shipToContactMobileNo}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          PO Description
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.poEntry.latestPOEntryData[0] &&
                            this.props.poEntry.latestPOEntryData[0]
                              .poDescription}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          PO Currency
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.poEntry.latestPOEntryData[0] &&
                            this.props.poEntry.latestPOEntryData[0].poCurrency}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Payment Term
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.poEntry.latestPOEntryData[0] &&
                            this.props.poEntry.latestPOEntryData[0].pymtTerm}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Status
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.poEntry.latestPOEntryData[0] &&
                            this.props.poEntry.latestPOEntryData[0]
                              .poEntryStatus}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <Row className="poEntry-purchaseItem-row">
                        <div className="milestone-div">
                          <h6 className="center">Purchase Items</h6>
                          <button
                            className="btn milestone-add-btn white-text label-sm"
                            onClick={this.purchaseItemToggle}
                          >
                            Add
                          </button>
                          <Table>
                            <thead>
                              <tr>
                                <th className="opp-head">Name</th>
                                <th className="opp-head">Qty</th>
                                <th className="opp-head">Amount</th>
                                <th className="opp-head">GST</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.props.poEntry.purchaseItemData.map(
                                (item) => {
                                  return (
                                    <tr key={item.purchaseItemsId}>
                                      <td>{item.ppsName}</td>
                                      <td>{item.qty}</td>
                                      <td>{item.amount}</td>
                                      <td>{item.gstAmount}</td>
                                    </tr>
                                  );
                                }
                              )}
                              {this.props.poEntry.purchaseItemData.length !==
                                0 && (
                                <tr>
                                  <td>Total Amount:</td>
                                  <td>{totalAmount}</td>
                                  <td>Total GST:</td>
                                  <td>{totalGst}</td>
                                </tr>
                              )}
                              {this.props.poEntry.purchaseItemData.length !==
                                0 && (
                                <tr>
                                  <td></td>
                                  <td>
                                    <button
                                      className="btn center login-button white-text"
                                      onClick={() =>
                                        this.updatePOEntry(
                                          this.state.totalAmount +
                                            parseFloat(this.state.totalGst)
                                        )
                                      }
                                    >
                                      Update
                                    </button>
                                  </td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </Row>
                      <Row>
                        <div className="task-div">
                          <h6 className="center">Payment Schedule</h6>
                          <button
                            className="btn label-sm white-text poEntry-pymtSchedule-add-btn"
                            onClick={this.pymtScheduleToggle}
                          >
                            Add
                          </button>
                          <Table>
                            <thead>
                              <tr>
                                <th className="opp-head">PO Id</th>
                                <th className="opp-head">Date</th>
                                <th className="opp-head">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.props.poEntry.scheduledPymtDateData.map(
                                (item) => {
                                  return (
                                    <tr key={item.scheduleId}>
                                      <td>{item.poId}</td>
                                      <td>
                                        {item.scheduledPymtDate &&
                                          DateFns.format(
                                            item.scheduledPymtDate,
                                            "DD-MM-YYYY"
                                          )}
                                      </td>
                                      <td>{item.paymentAmount}</td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </Row>
                    </Col>
                  </React.Fragment>
                )}
              </Row>

              <Modal isOpen={purchaseItemModal} size="md">
                <ModalHeader toggle={this.purchaseItemToggle}>
                  Purchase Items
                </ModalHeader>
                <ModalBody>
                  <Form onSubmit={this.purchaseItemSubmit} noValidate>
                    <Row>
                      <Col md="6" className="mx-auto">
                        <FormComponent
                          label="Purchase Item Number"
                          labelClass="label-sm  bold"
                          className="form-control-sm"
                          type="number"
                          name="purchaseItemNo"
                          value={purchaseItemNo}
                          change={this.onChange}
                          required={true}
                        />
                        <FormComponent
                          label="Product/Service/Project Name"
                          labelClass="label-sm  bold"
                          className="form-control-sm"
                          type="text"
                          name="pspName"
                          value={pspName}
                          change={this.onChange}
                          required={true}
                        />
                        <FormComponent
                          label="Quantity"
                          labelClass="label-sm  bold"
                          className="form-control-sm"
                          type="number"
                          name="quantity"
                          value={quantity}
                          change={this.onChange}
                          required={true}
                        />
                        <FormComponent
                          label="Amount"
                          labelClass="label-sm  bold"
                          className="form-control-sm"
                          type="number"
                          name="amount"
                          value={amount}
                          change={this.onChange}
                          required={true}
                        />
                        <FormComponent
                          label="Amount GST"
                          labelClass="label-sm  bold"
                          className="form-control-sm"
                          type="number"
                          name="amountGST"
                          value={amountGST}
                          change={this.onChange}
                          required={true}
                          disabled={true}
                        />
                      </Col>
                    </Row>
                    <div className="center">
                      <button
                        type="submit"
                        className="btn center login-button white-text"
                      >
                        Add
                      </button>
                    </div>
                  </Form>
                </ModalBody>
              </Modal>
              <Modal isOpen={pymtScheduleModal} size="md">
                <ModalHeader toggle={this.pymtScheduleToggle}>
                  Schedule Date
                </ModalHeader>
                <ModalBody>
                  <Form onSubmit={this.pymtScheduleSubmit} noValidate>
                    <Row>
                      <Col md="6" className="mx-auto">
                        <FormComponent
                          label="Scheduled Payment Date"
                          labelClass="label-sm  bold"
                          className="form-control-sm"
                          type="date"
                          name="scheduledPymtDate"
                          value={scheduledPymtDate}
                          change={this.onChange}
                          required={true}
                        />
                        <FormComponent
                          label="Payment Amount"
                          labelClass="label-sm  bold"
                          className="form-control-sm"
                          type="number"
                          name="pymtAmount"
                          value={pymtAmount}
                          change={this.onChange}
                          required={true}
                        />
                      </Col>
                    </Row>
                    <div className="center">
                      <button
                        type="submit"
                        className="btn center login-button white-text"
                      >
                        Add
                      </button>
                    </div>
                  </Form>
                </ModalBody>
              </Modal>
            </Container>
            {error && <ErrorNotifier message={error} />}
            {this.props.poEntry.message && (
              <Notifier message={this.props.poEntry.message} />
            )}
            {this.props.poEntry.error && (
              <ErrorNotifier message={this.props.poEntry.error} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

AddPOEntry.propTypes = {
  addPurchaseItem: PropTypes.func.isRequired,
  getPOType: PropTypes.func.isRequired,
  poTypes: PropTypes.object.isRequired,
  getUserBusinessLocations: PropTypes.func.isRequired,
  busiLocations: PropTypes.object.isRequired,
  getPurchaseItems: PropTypes.func.isRequired,
  addScheduledPymtDate: PropTypes.func.isRequired,
  getScheduledPymtDate: PropTypes.func.isRequired,
  getCustomers: PropTypes.func.isRequired,
  customers: PropTypes.object.isRequired,
  addPOEntry: PropTypes.func.isRequired,
  addUpdatePoEntry: PropTypes.func.isRequired,
  getPOEntry: PropTypes.func.isRequired,
  poEntry: PropTypes.object.isRequired,
  getPartnerPymtTerms: PropTypes.func.isRequired,
  partnerPymtTerms: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  currency: state.currency,
  poTypes: state.poTypes,
  busiLocation: state.busiLocation,
  customers: state.customers,
  poEntry: state.poEntry,
  partnerPymtTerms: state.partnerPymtTerms,
});

export default connect(mapStateToProps, {
  addPurchaseItem,
  getPOType,
  getUserBusinessLocations,
  getPurchaseItems,
  addScheduledPymtDate,
  getScheduledPymtDate,
  addPOEntry,
  addUpdatePoEntry,
  getPOEntry,
  getCustomers,
  getPartnerPymtTerms,
})(AddPOEntry);
