import setSuccessMessage from "../../../common/setSuccessMessage";
import setErrorMessage from "../../../common/setErrorMessage";
import getDispatch from "../../../common/getDispatch";
import { 
ADD_ASSIGNED_HR_API,
VIEW_ASSIGNED_HR_API,
UPDATE_ASSIGNED_HR_API,
DELETE_ASSIGNED_HR_API
} from "../../../../../utils/routes";
import {  GET_ASSIGNED_HR_LIST } from "../../../types";
import axios from "axios";
import { removeData } from "jquery";

export const AddAssignHr = (data, back) => (dispatch) => {
  
  axios
    .post(ADD_ASSIGNED_HR_API, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getAssignHr = (data) => (dispatch) => {
  axios
    .get(`${VIEW_ASSIGNED_HR_API}/${data}`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(GET_ASSIGNED_HR_LIST, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateAssignHr = (data, back) => (dispatch) => {
  axios
    .put(UPDATE_ASSIGNED_HR_API, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteAssignHr = (data,back) => (dispatch) => {
    axios
      .delete(DELETE_ASSIGNED_HR_API, { data, withCredentials: true })
      .then((res) => {
        dispatch(setSuccessMessage(res, null));
        dispatch(getAssignHr(back))
       })
      .catch((err) => {
        dispatch(setErrorMessage(err));
      });
  };
  
