import {
  EMPLOYEE_CLAIM_ADD,
  EMPLOYEE_CLAIM_GET,
  EMPLOYEE_CLAIM_SELECT,
  EMPLOYEE_CLAIM_UPDATE,
  EMPLOYEE_CLAIM_DELETE,
  GET_ERRORS,
  SET_MESSAGE,
} from "../../../actions/types";

const initialState = {
  data: [],
  allData: [],
  message: null,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case EMPLOYEE_CLAIM_GET:
      return {
        ...state,
        data: action.payload,
      };
    case EMPLOYEE_CLAIM_SELECT:
      return {
        ...state,
        allData: action.payload,
      };
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case GET_ERRORS:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
