import {
  SALES_INVOICE_GET,
  SALES_INVOICE_RECIEVE_ITEM_GET,
  SET_MESSAGE,
  GET_ERRORS,
  LATEST_SALES_INVOICE_GET,
} from "../../../../actions/types";

const initialState = {
  data: [],
  latestSalesInvoiceData: [],
  recieveItemData: [],
  message: null,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SALES_INVOICE_GET:
      return {
        ...state,
        data: action.payload,
      };
    case LATEST_SALES_INVOICE_GET:
      return {
        ...state,
        latestSalesInvoiceData: action.payload,
      };
    case SALES_INVOICE_RECIEVE_ITEM_GET:
      return {
        ...state,
        recieveItemData: action.payload,
      };
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case GET_ERRORS:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
