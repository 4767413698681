import {
    PROJECT_TEAM_ASSIGNMENT_LIST
} from '../actions/types';

const initialState = {
    data: []
}

export default function(state = initialState, action ) {
    switch(action.type){
        case PROJECT_TEAM_ASSIGNMENT_LIST:
            return {
                ...state,
                data: action.payload
            }
        default:
            return state;
    }
}