import React, { useState } from "react";
import { Col, FormGroup, Modal, Row } from "react-bootstrap";
import { Input, Label, Table } from "reactstrap";
import DateFns from "date-fns";
// material ui
import { IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
// components
import CustTooltip from "../../../../common/tooltip/CustTooltip";

// --------------------------------------------------------------------------

const ViewKanbanCandidates = (props) => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [data, setData] = useState([]);
  const [search, setSearch] = useState(false);

  const prinatbleArea = React.useRef(null);

  const handleSearch = () => {
    setData(
      props.data.length > 0 &&
        props.data.filter(
          (value) =>
            new Date(value.taggedOn).getTime() >=
              new Date(fromDate).getTime() &&
            new Date(value.taggedOn).getTime() <= new Date(toDate).getTime()
        )
    );
    setSearch(true);
  };

  const clickPdfRenderer = () => {
    const win = window.open();
    if (prinatbleArea.current?.innerHTML) {
      win?.document.write(prinatbleArea.current.innerHTML);
      win?.print();
    }
    win?.close();
  };


  return (
    <>
      <Modal.Title>
        <div>
          <i
            data-toggle="tooltip"
            data-placement="top"
            title="Close"
            onClick={() => props.closeModal()}
            className="material-icons pointer cancel-button"
          >
            cancel
          </i>
        </div>
        <h5 className="center bold pt-3">View Candidates</h5>
      </Modal.Title>
      <Modal.Body ref={prinatbleArea}>
        <Row style={{ display: "flex", justifyContent: "space-between" }}>
          <Col md="6">
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label className="bold label-sm">TaggedOn From Date</Label>
                  <Input
                    type="date"
                    name="fromDate"
                    className="form-control-sm"
                    value={fromDate}
                    onChange={(e) => 
                      setFromDate(e.target.value)
                      
                    }
                    required={true}
                    max={DateFns.format(new Date(), "YYYY-MM-DD")}
                  ></Input>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label className="bold label-sm">TaggedOn To Date</Label>
                  <Input
                    type="date"
                    name="toDate"
                    className="form-control-sm"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                    required={true}
                    // min={DateFns.format(fromDate, "YYYY-MM-DD")}
                    max={DateFns.format(new Date(), "YYYY-MM-DD")}
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col md="6">
            <Row
              style={{
                justifyContent: "end",
                alignItems: "center",
                paddingTop: "2%",
              }}
            >
              <Col md="1" className="align-center flex">
                <CustTooltip title="Search Candidates">
                  <IconButton color="primary" onClick={() => handleSearch()}>
                    <SearchIcon className="mui-icon-size" />
                  </IconButton>
                </CustTooltip>
              </Col>
              <Col md="1" className="align-center flex">
                <CustTooltip title="Download Candidates">
                  <IconButton onClick={clickPdfRenderer} color="primary">
                    <CloudDownloadIcon className="mui-icon-size" />
                  </IconButton>
                </CustTooltip>
              </Col>
            </Row>
          </Col>
        </Row>
        <Table className="mt-2">
          <thead style={{ backgroundColor: "lightgrey" }}>
            <tr>
              <th  style={{fontSize: ".875rem",fontWeight: "500",}} className="opp-head ">Id</th>
              <th style={{fontSize: ".875rem",fontWeight: "500",}} className="opp-head ">Name</th>
              <th style={{fontSize: ".875rem",fontWeight: "500",}} className="opp-head ">Mobile No.</th>
              <th style={{fontSize: ".875rem",fontWeight: "500",}} className="opp-head ">Email</th>
              <th style={{fontSize: ".875rem",fontWeight: "500",}} className="opp-head ">Can. Status</th>
              <th style={{fontSize: ".875rem",fontWeight: "500",}} className="opp-head ">Hiring Stage</th>
              <th style={{fontSize: ".875rem",fontWeight: "500",}} className="opp-head ">Hiring Status</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0
              ? data.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td className="opp-tableData">{item.canInfoId}</td>
                      <td className="opp-tableData">
                        {item.firstName}&nbsp;{item.lastName}
                      </td>
                      <td className="opp-tableData">{item.mobileNo}</td>
                      <td className="opp-tableData">{item.eMailId}</td>
                      <td className="opp-tableData">{item.candidateStatus}</td>
                      <td className="opp-tableData">{item.hiringStage}</td>
                      <td className="opp-tableData">{item.hiringStatus}</td>
                    </tr>
                  );
                })
              : search && (
                  <tr>
                    <h5>No Data Exist</h5>
                  </tr>
                )}
          </tbody>
        </Table>
      </Modal.Body>
    </>
  );
};

export default ViewKanbanCandidates;
