import { GET_ERRORS, SET_MESSAGE,GET_UPLOAD_CAN_RS_EXTRACTED,GET_UPLOAD_CAN_RS_PROCESSED,GET_UPLOAD_CAN_RS_UNPROCESSED  } from "../../../actions/types";

const initialState = {
  message: null,
  dataExtracted:[],
  dataProcessed:[],
  dataUnProcessed:[],
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
      case GET_UPLOAD_CAN_RS_EXTRACTED:
        return {
          ...state,
          dataExtracted: action.payload,
        };
        case GET_UPLOAD_CAN_RS_PROCESSED:
          return {
            ...state,
            dataProcessed: action.payload,
          };
          case GET_UPLOAD_CAN_RS_UNPROCESSED:
            return {
              ...state,
              dataUnProcessed: action.payload,
            };
    case GET_ERRORS:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
