import axios from "axios";
import { GET_CANDI_CURRENT_RECORDS, GET_PARTICULAR_CANDI} from "../types";
import { CANDIDATE_RESUME_UPLOAD_RS,CANDIDATE_LATEST_VIEW,CANDIDATE_FULL_VIEW ,FETCH_PARTICULAR_CANDIDATES} from "../../../utils/routes"
import setSuccessMessage from "../common/setSuccessMessage";
import setErrorMessage from "../common/setErrorMessage";
import getDispatch from "../common/getDispatch";



export const getLatestCandidate = (data) =>(dispatch) => {
    console.log(data)
   axios.post(CANDIDATE_LATEST_VIEW, data , {withCredentials:true}).then((res)=>{
      dispatch(getDispatch(GET_CANDI_CURRENT_RECORDS,res))
    }).catch((err)=>{
      dispatch(setErrorMessage(err))
    })
  }

  export const getParticularCandidate = (data) =>(dispatch) => {
  
   axios.post(FETCH_PARTICULAR_CANDIDATES, data , {withCredentials:true}).then((res)=>{
    dispatch(getDispatch(GET_PARTICULAR_CANDI, res))

    }).catch((err)=>{
      dispatch(setErrorMessage(err))
    })
  }


  // export const getLatestCandidate = (data) =>(dispatch) => {
  //  axios.get(CANDIDATE_FULL_VIEW,{withCredentials:true}).then((res)=>{
  //     dispatch(getDispatch(GET_CANDI_CURRENT_RECORDS,res))
  //   }).catch((err)=>{
  //     dispatch(setErrorMessage(err))
  //   })
  // }