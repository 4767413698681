import axios from 'axios';
import {
    BUSINESS_UNIT_GET,
    GET_ERRORS
} from './types';
import {
    GET_BUSINESS_UNIT
} from '../../utils/routes';

export const getBusinessUnit = () => dispatch => {
    axios.get(GET_BUSINESS_UNIT, {withCredentials: true})
    .then(res => {
        dispatch({
            type: BUSINESS_UNIT_GET,
            payload: res.data
        })
    })
    .catch(err => {
        dispatch({
            type: GET_ERRORS,
            payload: err.message
        })
    })
}