import { combineReducers } from "redux";
import authenticationAndAuthorizationReducer from "./iam/authentication/authenticationAndAuthorizationReducer";
import registerReducer from "./registerReducer";
import employeeReducer from "./employeeReducer";
import ideaReducer from "./ideaReducer";
import customerReducer from "./customerReducer";
import partnerReducer from "./partnerReducer";
import vendorReducer from "./vendorReducer";
import supplierReducer from "./supplierReducer";
import accountReducer from "./accountReducer";
import contactReducer from "./contactReducer";
// import productListReducer from "./productListReducer";
import salesReducer from "./salesReducer";
import orderReducer from "./orderReducer";
import leadsReducer from "./leadsReducer";
import prospectsReducer from "./prospectsReducer";
import inquiryReducer from "./inquiryReducer";
import campaignReducer from "./campaignReducer";
import productReducer from "./productReducer";
import productStatusReducer from "./productStatusReducer";
import projectReducer from "./projectReducer";
import projectTeamAssignmentReducer from "./projectTeamAssignmentReducer";
import projectMailsStoneReducer from "./projectMailsStoneReducer";
import projectStatusReducer from "./projectStatusReducer";
import projectTypeReducer from "./projectTypeReducer";
import billingTypeReducer from "./billingTypeReducer";
import serviceReducer from "./serviceReducer";
import saveTimesheetReducer from "./saveTimesheetReducer";
import tsJobReducer from "./tsJobReducer";
import tsCandidateReducer from "./tsCandidateReducer";
import tsPanelReducer from "./tsPanelReducer";
import errorReducer from "./errorReducer";
import accessReducer from "./accessReducer";
import applicationReducer from "./iam/auth_configuration/menu_management/applicationReducer";
import moduleReducer from "./iam/auth_configuration/menu_management/moduleReducer";
import serviceMenuReducer from "./iam/auth_configuration/menu_management/serviceReducer";
import userGroupReducer from "./iam/auth_configuration/user_group/userGroupReducer";
import iamConfigReducer from "./iam/config/iamConfigReducer";
import roleReducer from "./iam/auth_configuration/role_management/roleReducer";
import userRoleReducer from "./userRoleReducer";
import jobReducer from "./jobReducer";
import candidateMReducer from "./candidateMReducer";
import serviceTeamAssignmentReducer from "./serviceTeamAssignmentReducer";
import busiGeographiesReducer from "./busiGeographiesReducer";
import businessGeographiesReducer from "./businessGeographiesReducer";
import busiUnitReducer from "./busiUnitReducer";
import businessTypeReducer from "./businessTypeReducer";
import ideaStatusReducer from "./ideaStatusReducer";
import requestCategoryReducer from "./requestCategoryReducer";
import feedbackSeverityReducer from "./feedbackSeverityReducer";
import feedbackPriorityReducer from "./feedbackPriorityReducer";
import feedbackStatusReducer from "./feedbackStatusReducer";
import prospectsStatusReducer from "./prospectsStatusReducer";
import prospectsTypeReducer from "./prospectsTypeReducer";
import campaignTypeReducer from "./campaignTypeReducer";
import campaignSuccessCriteriaReducer from "./campaignSuccessCriteriaReducer";
import leadSourceReducer from "./leadSourceReducer";
import salesActivityReducer from "./salesActivityReducer";
import salesActivityStatusReducer from "./salesActivityStatusReducer";
import dealTypeReducer from "./dealTypeReducer";
import opportunitySourceReducer from "./opportunitySourceReducer";
import opportunityStageReducer from "./opportunityStageReducer";
import opportunityTypeReducer from "./opportunityTypeReducer";
import salesMilestoneReducer from "./salesMilestoneReducer";
import salesMilestoneStatusReducer from "./salesMilestoneStatusReducer";
import salesMilestoneFeedbackReducer from "./salesMilestoneFeedbackReducer";
import salesPotentialReducer from "./salesPotentialReducer";
import currencyReducer from "./currencyReducer";
import customerBusinessDomainReducer from "./customerBusinessDomainReducer";
import partnerTypeReducer from "./partnerTypeReducer";
import partnerServicesReducer from "./partnerServicesReducer";
import partnerPymtTermsReducer from "./partnerPymtTermsReducer";
import vendorTypeReducer from "./vendorTypeReducer";
import vendorServicesReducer from "./vendorServicesReducer";
import vendorPymtTermsReducer from "./vendorPymtTermsReducer";
import claimStatusReducer from "./finance/claims/claimStatusReducer";
import claimTypeReducer from "./finance/claims/claimTypeReducer";
import paymentStatusReducer from "./finance/invoice/paymentStatusReducer";
import receivableStatusReducer from "./finance/invoice/receivableStatusReducer";
import employeeClaimReducer from "./finance/claims/employeeClaimReducer";
import payslipReducer from "./finance/payslip/payslipReducer";
import form16Reducer from "./finance/form16/form16Reducer";
import invoiceReducer from "./finance/accounts_payable/enter_invoice/invoiceReducer";
import issuePOReducer from "./finance/accounts_payable/issue_po/issuePOReducer";
import poTypeReducer from "./finance/accounts_payable/issue_po/poTypeReducer";
import poStatusReducer from "./finance/accounts_payable/issue_po/poStatusReducer";
import invoiceTypeReducer from "./finance/invoice/invoiceTypeReducer";
import accountStatusReducer from "./accountStatusReducer";
import poEntryReducer from "./finance/accounts_receivable/po_entry/poEntryReducer";
import salesInvoiceReducer from "./finance/accounts_receivable/sales_invoice/salesInvoiceReducer";
import PartnerDocumentsUploadReducer from "./partners/documents_upload/DocumentsUploadReducer";
import VendorDocumentsUploadReducer from "./vendors/documents_upload/DocumentsUploadReducer";
import applicationAccessPermissionReducer from "./iam/auth_configuration/access_and_permission/applicationAccessPermissionReducer";
import moduleAccessPermissionReducer from "./iam/auth_configuration/access_and_permission/moduleAccessPermissionReducer";
import serviceAccessPermissionReducer from "./iam/auth_configuration/access_and_permission/serviceAccessPermissionReducer";
import roleMappingReducer from "./iam/role_mapping/roleMappingReducer";

// -------------------------------------------------------------------------------------------------
// IAM --> EMP ONBOARDING REDUCERS
import empOnboardingReducer from "./iam/emp_onboarding/empOnboardingReducer";

// IAM --> EMP ACCOUNT CREATION REDUCER
import createAccountReducer from "./auth/createAccountReducer";

// --------------------------------------------------------------------------------------------------

// HCM --> Business Setup --> EnterpriseDetail
import enterpriseDetailReducer from "./hcm/business_setup/enterpriseDetailReducer";

// HCM --> Business Setup --> BusinessGeowise
import businessGeowiseReducer from "./hcm/business_setup/businessGeowiseReducer";

// HCM --> Business Setup --> BusinessCountries
import businessCountriesReducer from "./hcm/business_setup/businessCountriesReducer";

// HCM --> Business Setup --> BusinessLocations
import businessLocationsReducer from "./hcm/business_setup/businessLocationsReducer";

// HCM --> Business Setup --> Business Currency
import businessCurrencyReducer from "./hcm/business_setup/businessCurrencyReducer";

// HCM --> Business Setup --> Organization
import organizationReducer from "./hcm/business_setup/organizationReducer";

// HCM --> Business Setup --> BusinessUnit
import businessUnitReducer from "./hcm/business_setup/businessUnitReducer";

// HCM --> Business Setup --> Departments
import departmentsReducer from "./hcm/business_setup/departmentsReducer";

// HCM --> Configuration --> ManagerResignationReason
import managerResignationReasonReducer from "./hcm/configurations/managerResignationReasonReducer";

// HCM --> Configuration --> ResignationType
import resignationTypeReducer from "./hcm/configurations/resignationTypeReducer";

// HCM --> Configuration --> EmpRelation
import empRelationReducer from "./hcm/configurations/empRelationReducer";

// HCM --> Configuration --> EmpDesignation
import empDesignationReducer from "./hcm/configurations/empDesignationReducer";
import empPositionReducer from "./hcm/configurations/empPositionReducer";

// HCM --> Configuration --> EmpGrade
import empGradeReducer from "./hcm/configurations/empGradeReducer";

// HCM --> Configuration --> LeaveType
import leaveTypeReducer from "./hcm/configurations/leaveTypeReducer";

// HCM --> Configuration --> EmpDocumentName
import empDocumentNameReducer from "./hcm/configurations/empDocumentNameReducer";

// HCM --> Configuration --> RevokeReason
import revokeReasonReducer from "./hcm/configurations/revokeReasonReducer";

// HCM --> Configuration --> EmpQualification
import empQualificationReducer from "./hcm/configurations/empQualificationReducer";

// HCM --> Configuration --> ResignationReason
import resignationReasonReducer from "./hcm/configurations/resignationReasonReducer";

// HCM --> Configuration --> ResignationStatus
import resignationStatusReducer from "./hcm/configurations/resignationStatusReducer";

// HCM --> Configuration --> EmpType
import empTypeReducer from "./hcm/configurations/empTypeReducer";

// HCM --> Configuration --> ClearanceStatus
import clearanceStatusReducer from "./hcm/configurations/clearanceStatusReducer";

// HCM --> Configurations --> Company Holidays
import companyHolidaysReducer from "./hcm/configurations/companyHolidaysReducer";

// HCM --> Configurations --> DailingCode
import dialingCodeReducer from "./hcm/configurations/dialingCodeReducer";

// HCM --> Configurations --> Emp Status Reason
import empStatusReasonReducer from "./hcm/configurations/empStatusReasonReducer";

// HCM --> Configurations --> Personal Status
import personalStatusReducer from "./hcm/configurations/personalStatusReducer";

// HCM --> Configurations --> Global and User
import hcmGlobalVarReducer from "./hcm/configurations/hcmGlobalVarReducer";
import hcmUserVarReducer from "./hcm/configurations/hcmUserVarReducer";

// HCM --> MyProfile --> CertificationDetails
import certificationDetailsReducer from "./hcm/my_profile/certificationDetailsReducer";

// HCM --> MyProfile --> Documents
import documentsReducer from "./hcm/my_profile/documentsReducer";

// HCM --> MyProfile --> EducationDetails
import educationDetailsReducer from "./hcm/my_profile/educationDetailsReducer";

// HCM --> MyProfile --> EmpAwards
import empAwardsReducer from "./hcm/my_profile/empAwardsReducer";

// HCM --> MyProfile --> EmpDependents
import empDependentsReducer from "./hcm/my_profile/empDependentsReducer";

// HCM --> MyProfile --> EmpBenifits
import empBenifitsReducer from "./hcm/my_profile/empBenifitsReducer";

// HCM --> MyProfile --> EmpNominee
import empNomineeReducer from "./hcm/my_profile/empNomineeReducer";

// HCM --> Myprofile --> EmpOrganization
import empOrganizationReducer from "./hcm/configurations/empOrganizationReducer";

// HCM --> MyProfile --> EmpProfessionalDocs
import empProfessionalDocsReducer from "./hcm/my_profile/empProfessionalDocsReducer";

// HCM --> MyProfile --> EmpProfessionalInfo
import empProfessionalInfoReducer from "./hcm/my_profile/empProfessionalInfoReducer";

// HCM --> MyProfile --> EmpPersonalInfo
import empPersonalInfoReducer from "./hcm/hr/emp_management/empPersonalInfoReducer";

// HCM --> MyProfile --> Contacts Address
import contactsAddressReducer from "./hcm/my_profile/contacts_address/contactsAddressReducer";

// HCM -->MyProfile --> Contacts
import contactsReducer from "./hcm/my_profile/contacts_address/contactsReducer";

// HCM --> MyProfile --> Contacts Emergency Details
import contactsEmergencyDetailsReducer from "./hcm/my_profile/contacts_address/contactsEmergencyDetailsReducer";

// HCM --> MyProfile --> Contacts Family Details
import contactsFamilyDetailsReducer from "./hcm/my_profile/contacts_address/contactsFamilyDetailsReducer";

// HCM --> MyProfile --> Accounts Details
import bankAccountsInfoReducer from "./hcm/my_profile/accounts_details/bankAccountsInfoReducer";
import otherAccountsInfoReducer from "./hcm/my_profile/accounts_details/otherAccountsInfoReducer";

// HCM --> MyProfile --> Identification Info
import passportDetailsReducer from "./hcm/my_profile/identification_info/passportDetailsReducer";
import otherIdentifiersReducer from "./hcm/my_profile/identification_info/otherIdentifiersReducer";

// HCM --> HR --> HrPolicies
import hrPoliciesReducer from "./hcm/hr/policies/hrPoliciesReducer";

// HCM --> HR --> EMP Managment
import empManagementReducer from "./hcm/hr/emp_management/empManagementReducer";

// HCM --> HR --> Attendance
import attendanceReducer from "./hcm/hr/attendance/attendanceReducer";

// HCM  --> lms --> LeaveManagement
import leaveManagementReducer from "./hcm/lms/leaveManagementReducer";

// HCM --> LMS --> Attendance Report
import attendanceReportReducer from "./hcm/lms/attendanceReportReducer";
import leaveReportReducer from "./hcm/lms/leaveReportReducer";

// HCM  --> lms --> My Attendance
import myAttendanceReducer from "./hcm/lms/myAttendanceReducer";

// HCM --> Resignation --> EmpInitiateResignation
import empInitiateResignationReducer from "./hcm/resignation/empInitiateResignationReducer";

// HCM --> Resignation --> ManagerInitiateResignation
import managerInitiateResignationReducer from "./hcm/resignation/managerInitiateResignationReducer";

// HCM --> Resignation --> Exit Clearance
import exitClearanceReducer from "./hcm/resignation/exitClearanceReducer";

import skillreducer from "./rs/configuration/skillreducer";

import extraCurricularActivity from "./rs/configuration/extracurricular";

import DocumentRS from "./rs/configuration/documentreducer";

import QualificationRs from "./rs/configuration/qualificationreducer";
import RSrequirement from "./rs/Rsreequirement/requirementreducer";
import RSrequirementlist from "./rs/Rsreequirement/requirementlistreducer";
import RSbillinglist from "./rs/Rsreequirement/rsbillingreducer";
import RScategorylist from "./rs/Rsreequirement/categoryreducersrs";
import Rspositionreducer from "./rs/Rsreequirement/Rspositionreducer";
import billingreducer from "./rs/Rsreequirement/rsbillingreducer";
import billingmain from "./rs/configuration/billingRsReducer";
import Hiringreducer from "./rs/configuration/hiringReducer";
import ReqReducer from "./rs/configuration/rsReqReducer";
import RsExpYears from "./rs/configuration/yearExpReducer";
import HiringStage from "./rs/configuration/hireStageReducer";
import JobBoard from "./rs/configuration/jobBoardReducer";
import CandidateStatus from "./rs/configuration/candidateStatusReducer";
import ReqType from "./rs/configuration/reqTypeReducer";
import JobCategory from "./rs/configuration/jobCategoryReducer";
import JobPosition from "./rs/configuration/jobPositionreducer";
import Noticeperiod from "./rs/configuration/noticePeriodReducer";
import HrNotice from "./rs/configuration/hrReducer";
import CandiCountry from "./rs/configuration/candiCountriesreducer";
import CandiGeo from "./rs/configuration/candiCountrygeo";
import CanConSkillReducer from "./rs/configuration/canSkillreducer";
import GovtId from "./rs/configuration/govtReducer";
import CanGrade from "./rs/configuration/gradeReducer";
import UserVariableRs from "./rs/configuration/userVaribleReducer";
import GlobalVariableRs from "./rs/configuration/globalVaribleReducer.";
import JobTimingReducer from "./rs/configuration/jobTimingReducer";

// rs-->skill upload

import SkillUploadReducer from "./rs/configuration/skillUploadReducer";

import CanUplaodRs from "./rs/candidate/candidateUploadReducer";
import CandidateViewRecordsRs from "./rs/candidate/viewCandidateRecords";

// RS --> Publish jobs
import PublishJobs from "./rs/Rsreequirement/publishJobs/publishJobsReducer";

// candidate verify
import candiVerify from "./rs/candidate/statusVerifyReducer"

//RS -->assigned HR
import AssignedHr from "./rs/Rsreequirement/assigned_hr_reducer/assigned_hr";

// RS -->tagged requirement

import TaggedRequirement from "./rs/Rsreequirement/tagRequirementReducer/tagReducer";

// CAN-->tagg candi tracking

import TaggedReqTracking from "./rs/candidate/tagRequirement"
// RS --> Upload --> Requirements
import requirementsUploadReducer from "./rs/upload/requirementsUploadReducer";
import candidatesUploadReducer from "./rs/upload/candidatesUploadReducer";
import candidateTaggingUploadReducer from "./rs/upload/candidateTaggingUploadReducer";
import templateUplaod from "./rs/upload/documentsReducer";
import ResumeRsUpload from "./rs/upload/resumeResucer"

// RS --> Manager View
import managerViewReducer from "./rs/manager_view/managerViewReducer";
import managerViewTwoReducer from "./rs/manager_view_two/managerViewReducer"

import myReportsReducer from "./rs/my_reports/myReportsReducer"

// CRM Reducers import
// CRM Setup Reducers
import customerDomainReducer from "./crm/crm_setup/customerDomainReducer";
import customerTypeReducer from "./crm/crm_setup/customerTypeReducer";
import customerAccountStatusReducer from "./crm/crm_setup/customerAccountStatusReducer";
import customerGeographiesReducer from "./crm/crm_setup/customerGeographiesReducer";
import customerCountriesReducer from "./crm/crm_setup/customerCountriesReducer";
import customerCurrencyReducer from "./crm/crm_setup/customerCurrencyReducer";
import customerDialingCodeReducer from "./crm/crm_setup/customerDialingCodeReducer";
import customerDocumentTypeReducer from "./crm/crm_setup/customerDocumentTypeReducer";
import contactDesignationReducer from "./crm/crm_setup/contactDesignationReducer";
import departmentReducer from "./crm/crm_setup/departmentReducer";
import contactWorkingStatusReducer from "./crm/crm_setup/contactWorkingStatusReducer";
import crmGlobalReducer from "./crm/crm_setup/crmGlobalReducer";
import crmUserReducer from "./crm/crm_setup/crmUserReducer";
import accountManagerReducer from "./crm/crm_setup/accountManagerReducer";
import salesTeamReducer from "./crm/crm_setup/salesTeamReducer";

// CRM --> Customers
import customersReducer from "./crm/customers/customersReducer";
import customerDocumentsReducer from "./crm/customers/customerDocumentsReducer";

// CRM --> Customer Entity
import customerEntityReducer from "./crm/customer_entity/customerEntityReducer";

// CRM --> Accounts
import accountsReducer from "./crm/accounts/accountsReducer";

// CRM --> Contacts
import crmContactsReducer from "./crm/contacts/contactsReducer";

// CRM --> Tag Contacts
import crmTagContactsReducer from "./crm/contacts/tagContactsReducer";

// Experience 360 --> Requests
import requestsReducer from "./experience_360/requestsReducer";

// Experience 360 --> Release Tag
import e360ReleaseTagReducer from "./experience_360/releaseTagReducer";

// Experience 360 ---> Request Assignment
import teamAssignmentReducer from "./experience_360/teamAssignmentReducer";

// Experience 360 ---> Request Conversation
import requestConversationReducer from "./experience_360/requestConversationReducer";

// Experience 360 --> e360 Config
import requestTypeReducer from "./experience_360/e360_config/requestTypeReducer";
import requestStatusReducer from "./experience_360/e360_config/requestStatusReducer";
import requestSeverityReducer from "./experience_360/e360_config/requestSeverityReducer";
import requestPriorityReducer from "./experience_360/e360_config/requestPriorityReducer";
import e360GlobalReducer from "./experience_360/e360_config/e360GlobalReducer";
import e360UserReducer from "./experience_360/e360_config/e360UserReducer";
import projectTeamReducer from "./experience_360/e360_config/projectTeamReducer";
import projectMemberReducer from "./experience_360/e360_config/projectMemberReducer";
import releaseTagReducer from "./experience_360/e360_config/releaseTagReducer";
import knowledgeTypeReducer from "./experience_360/e360_config/knowledgeTypeReducer";
import requestResolutionReducer from "./experience_360/e360_config/requestResolutionReducer";
import productListReducer from "./experience_360/e360_config/productListReducer";

import RsCandidateCreation from "./rs/Rsreequirement/rsCandidateReducer"

// -----------------------------------------------------------------------------------------------

//rs dashboard 
import RsDashboard from "./rs/dashboard/dashboardReducer"
import ManagerInsights from "./rs/dashboard/managerInsightsReducers"

export default combineReducers({
  auth: authenticationAndAuthorizationReducer,
  reg: registerReducer,
  employees: empOnboardingReducer,
  idea: ideaReducer,
  partners: partnerReducer,
  vendors: vendorReducer,
  suppliers: supplierReducer,
  // productList: productListReducer,
  sales: salesReducer,
  orders: orderReducer,
  leads: leadsReducer,
  prospects: prospectsReducer,
  inquiries: inquiryReducer,
  campaign: campaignReducer,
  products: productReducer,
  productStatus: productStatusReducer,
  projects: projectReducer,
  projectTeamAssignment: projectTeamAssignmentReducer,
  projectMailsStone: projectMailsStoneReducer,
  projectStatus: projectStatusReducer,
  projectType: projectTypeReducer,
  billingType: billingTypeReducer,
  services: serviceMenuReducer,
  serviceTeamAssignment: serviceTeamAssignmentReducer,
  savedTS: saveTimesheetReducer,
  tsJobs: tsJobReducer,
  tsCandidates: tsCandidateReducer,
  tsPanel: tsPanelReducer,
  errors: errorReducer,
  access: accessReducer,
  roles: roleReducer,
  applications: applicationReducer,
  modules: moduleReducer,
  servicesMenu: serviceMenuReducer,
  applicationPermissions: applicationAccessPermissionReducer,
  modulePermissions: moduleAccessPermissionReducer,
  servicePermissions: serviceAccessPermissionReducer,
  userGroups: userGroupReducer,
  iamConfig: iamConfigReducer,
  userRole: userRoleReducer,
  roleMapping: roleMappingReducer,
  job: jobReducer,
  candidateMR: candidateMReducer,
  busiGeographies: busiGeographiesReducer,
  businessGeographies: businessGeographiesReducer,
  busiUnit: busiUnitReducer,
  businessType: businessTypeReducer,
  ideaStatus: ideaStatusReducer,
  requestCategory: requestCategoryReducer,
  severity: feedbackSeverityReducer,
  priority: feedbackPriorityReducer,
  status: feedbackStatusReducer,
  prospectsStatus: prospectsStatusReducer,
  prospectsType: prospectsTypeReducer,
  campaignType: campaignTypeReducer,
  campaignSuccessCriteria: campaignSuccessCriteriaReducer,
  leadSource: leadSourceReducer,
  salesActivity: salesActivityReducer,
  salesActivityStatus: salesActivityStatusReducer,
  dealType: dealTypeReducer,
  opportunitySource: opportunitySourceReducer,
  opportunityStage: opportunityStageReducer,
  opportunityType: opportunityTypeReducer,
  salesMilestone: salesMilestoneReducer,
  salesMilestoneStatus: salesMilestoneStatusReducer,
  salesMilestoneFeedback: salesMilestoneFeedbackReducer,
  salesPotential: salesPotentialReducer,
  currency: currencyReducer,
  customerBusinessDomain: customerBusinessDomainReducer,
  partnerType: partnerTypeReducer,
  partnerServices: partnerServicesReducer,
  partnerPymtTerms: partnerPymtTermsReducer,
  vendorType: vendorTypeReducer,
  vendorServices: vendorServicesReducer,
  vendorPymtTerms: vendorPymtTermsReducer,
  claimStatus: claimStatusReducer,
  claimType: claimTypeReducer,
  paymentStatus: paymentStatusReducer,
  receivableStatus: receivableStatusReducer,
  employeeClaim: employeeClaimReducer,
  payslips: payslipReducer,
  form16: form16Reducer,
  invoices: invoiceReducer,
  issuePOs: issuePOReducer,
  poTypes: poTypeReducer,
  issuePOStatus: poStatusReducer,
  invoiceTypes: invoiceTypeReducer,
  accountStatus: accountStatusReducer,
  poEntry: poEntryReducer,
  salesInvoice: salesInvoiceReducer,
  partnerDocuments: PartnerDocumentsUploadReducer,
  vendorDocuments: VendorDocumentsUploadReducer,
  // --------------------------------------------------------
  // IAM --> Create Account
  createAccountValues: createAccountReducer,

  // -------------------------------------------------------

  // HCM --> Business Setup
  enterpriseDetail: enterpriseDetailReducer,
  businessGeowise: businessGeowiseReducer,
  businessCountries: businessCountriesReducer,
  businessLocations: businessLocationsReducer,
  businessCurrency: businessCurrencyReducer,
  organization: organizationReducer,
  businessUnit: businessUnitReducer,
  departments: departmentsReducer,

  // HCM --> Configuration
  managerResignationReason: managerResignationReasonReducer,
  resignationType: resignationTypeReducer,
  empRelation: empRelationReducer,
  empDesignation: empDesignationReducer,
  empPosition: empPositionReducer,
  empGrade: empGradeReducer,
  leaveType: leaveTypeReducer,
  empDocumentName: empDocumentNameReducer,
  revokeReason: revokeReasonReducer,
  empQualification: empQualificationReducer,
  resignationReason: resignationReasonReducer,
  resignationStatus: resignationStatusReducer,
  empType: empTypeReducer,
  clearanceStatus: clearanceStatusReducer,
  companyHolidays: companyHolidaysReducer,
  dialingCode: dialingCodeReducer,
  empStatusReason: empStatusReasonReducer,
  personalStatus: personalStatusReducer,
  hcmGlobalVar: hcmGlobalVarReducer,
  hcmUserVar: hcmUserVarReducer,

  // HCM --> MyProfile
  certificationDetails: certificationDetailsReducer,
  documents: documentsReducer,
  educationDetails: educationDetailsReducer,
  empAwards: empAwardsReducer,
  empDependents: empDependentsReducer,
  empBenifits: empBenifitsReducer,
  empNominee: empNomineeReducer,
  empOrganization: empOrganizationReducer,
  empProfessionalDocs: empProfessionalDocsReducer,
  empProfessionalInfo: empProfessionalInfoReducer,
  empPersonalInfo: empPersonalInfoReducer,
  contactsAddress: contactsAddressReducer,
  contacts: contactsReducer,
  contactsEmergencyDetails: contactsEmergencyDetailsReducer,
  contactsFamilyDetails: contactsFamilyDetailsReducer,
  bankAccountsInfo: bankAccountsInfoReducer,
  otherAccountsInfo: otherAccountsInfoReducer,
  passportDetails: passportDetailsReducer,
  otherIdentifiers: otherIdentifiersReducer,

  // HCM --> HR --> Policies
  hrPolicies: hrPoliciesReducer,

  // HCM --> HR --> Attendance
  attendance: attendanceReducer,

  // HCM --> HR -->  Emp Management
  empManagement: empManagementReducer,

  // HCM  --> LMS
  leaveManagement: leaveManagementReducer,
  myAttendance: myAttendanceReducer,
  attendanceReport: attendanceReportReducer,
  leaveReport: leaveReportReducer,

  // HCM --> Resignation --> empInitiateResignation
  empInitiateResignation: empInitiateResignationReducer,
  // HCM --> Resignation --> managerInitiateResignation
  managerInitiateResignation: managerInitiateResignationReducer,
  // HCM --> Resignation --> exit clearance
  exitClearance: exitClearanceReducer,
  // RS --> configuration --> skill group
  skillGroups: skillreducer,
  // RS --> configuration --> extracurricular
  extraCurricularActivity: extraCurricularActivity,
  // RS --> configuration --> Documents
  Documentsrs: DocumentRS,
  // RS --> configuration --> qualification
  QualificationRs: QualificationRs,
  billingRs: billingreducer,
  billingmain: billingmain,
  hiringStatus: Hiringreducer,
  reqStatus: ReqReducer,
  ReqType: ReqType,
  JobCategory: JobCategory,
  JobPosition: JobPosition,
  Noticeperiod: Noticeperiod,
  CandiCountry: CandiCountry,
  CandiGeo: CandiGeo,
  CanConSkill: CanConSkillReducer,
  jobTiming: JobTimingReducer,
  GovtId: GovtId,
  CanGrade: CanGrade,
  CanUplaodRs: CanUplaodRs,

  RSrequirement: RSrequirement,
  RSrequirementlist: RSrequirementlist,
  RSbillinglist: RSbillinglist,
  RScategorylist: RScategorylist,
  Rspositionreducer: Rspositionreducer,
  RsExpYears: RsExpYears,
  HiringStage: HiringStage,
  JobBoard: JobBoard,
  CandidateStatus: CandidateStatus,
  HrNotice: HrNotice,

  UserVariableRs: UserVariableRs,
  GlobalVariableRs: GlobalVariableRs,
  CandidateViewRecordsRs: CandidateViewRecordsRs,
  RsCandidateCreation:RsCandidateCreation,

  // Rs -->skill upload
  SkillUploadRsXl:SkillUploadReducer,
  //RS --->assigned hr
  AssignedHrData: AssignedHr,

  // RS --> publish jobs
  PublishJobs: PublishJobs,

  // RS -->tag requirement
  tagReq: TaggedRequirement,
  TaggedReqTracking:TaggedReqTracking,

  // RS -->  Upload --> Requirements
  requirementsUpload: requirementsUploadReducer,
  candidatesUpload: candidatesUploadReducer,
  candidateTaggingUpload: candidateTaggingUploadReducer,
  templateUplaod: templateUplaod,
  ResumeRsUpload:ResumeRsUpload,

  // RS --> Manager View
  managerView: managerViewReducer,
  myReports:myReportsReducer,
  managerViewTwo: managerViewTwoReducer,
  //RS--->RS DASHBOARD
  rsDashboard: RsDashboard,
  managerInsights:ManagerInsights,

  // can verify
  candiVerify : candiVerify ,

  // CRM Redux values
  // CRM --> CRM Setup redux values
  customerDomain: customerDomainReducer,
  customerType: customerTypeReducer,
  customerAccountStatus: customerAccountStatusReducer,
  customerGeographies: customerGeographiesReducer,
  customerCountries: customerCountriesReducer,
  customerCurrency: customerCurrencyReducer,
  customerDialingCode: customerDialingCodeReducer,
  customerDocumentType: customerDocumentTypeReducer,
  contactDesignation: contactDesignationReducer,
  crmDepartments: departmentReducer,
  contactWorkingStatus: contactWorkingStatusReducer,
  crmGlobal: crmGlobalReducer,
  crmUser: crmUserReducer,
  accountManager: accountManagerReducer,
  salesTeam: salesTeamReducer,

  // CRM --> Customers
  customers: customersReducer,
  customerDocuments: customerDocumentsReducer,

  // CRM --> Customer Entity
  customerEntity: customerEntityReducer,

  // CRM --> Accounts
  accounts: accountsReducer,

  // CRM --> Contacts
  crmContacts: crmContactsReducer,

  //CRM  --> Tag Contacts
  crmTagContacts: crmTagContactsReducer,

  // Experience 360 --> Requests
  requests: requestsReducer,

  e360ReleaseTag: e360ReleaseTagReducer,
  teamAssignment: teamAssignmentReducer,
  requestConversation: requestConversationReducer,

  // Experience 360 --> e360 Config
  requestType: requestTypeReducer,
  requestStatus: requestStatusReducer,
  requestSeverity: requestSeverityReducer,
  requestPriority: requestPriorityReducer,
  e360Global: e360GlobalReducer,
  e360User: e360UserReducer,
  projectTeam: projectTeamReducer,
  projectMember: projectMemberReducer,
  releaseTag: releaseTagReducer,
  knowledgeType: knowledgeTypeReducer,
  requestResolution: requestResolutionReducer,
  productList: productListReducer,
});
