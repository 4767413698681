import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form } from "reactstrap";
import { addProductList } from "../../../../redux/actions/productListAction";
import FormComponent from "../../../common/FormComponent";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
} from "../../../common/Validator";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";

class AddProductList extends Component {
  constructor() {
    super();
    this.state = {
      productName: "",
      listPrice: "",
      licenceQuantity: "",
      maxDiscount: "",
      description: "",
      buttonValue: false,
      maxDiscountError: "",
      error: "",
      message: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    e.preventDefault();
    Validator(e.target);
    if (e.target.name === "maxDiscount") {
      if (e.target.value >= 0 && e.target.value <= 100) {
        this.setState({
          [e.target.name]: e.target.value,
          maxDiscountError: "",
        });
      } else {
        this.setState({
          maxDiscountError: "maxDiscount value should be between 0 to 100",
        });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let productList = {
      createdBy: localStorage.getItem("id"),
      productCatName: this.state.productName,
      listPrice: this.state.listPrice,
      licenseQuantity: this.state.licenceQuantity,
      maxDiscount: this.state.maxDiscount,
      description: this.state.description,
    };
    if (valid && !this.state.maxDiscountError) {
      this.props.addProductList(productList, this.props.history);
      this.setState({
        buttonValue: !this.state.buttonValue,
      });
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: null,
        });
      }, 2000);
    }
  }

  /*
componentDidMount contains the form validation error refresh method
*/
  componentDidMount() {
    refresh();
  }

  render() {
    let shrink;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");

    return (
      <div className={shrink}>
        <h5 className="center bold">Add Products</h5>
        <Container className="card container-card">
          <Link to="/pie/productList">
            <i
              className="material-icons pointer"
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                color: "#C00",
              }}
            >
              cancel
            </i>
          </Link>
          <Form onSubmit={this.onSubmit} className="p-3 mt-2" noValidate>
            <Row>
              <Col md="6">
                <FormComponent
                  labelClass="bold label-sm"
                  label="Product Name"
                  type="text"
                  name="productName"
                  change={this.onChange}
                  value={this.state.productName}
                  maxlength="27"
                  placeholder="Product Name"
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="List Price"
                  type="number"
                  name="listPrice"
                  change={this.onChange}
                  value={this.state.listPrice}
                  placeholder="List Price"
                  maxlength="27"
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Licence Quantity"
                  type="number"
                  name="licenceQuantity"
                  change={this.onChange}
                  value={this.state.licenceQuantity}
                  placeholder="Licence Quantity"
                  required={true}
                />
              </Col>
              <Col md="6">
                <FormComponent
                  labelClass="bold label-sm"
                  label="Max Discount"
                  type="number"
                  name="maxDiscount"
                  change={this.onChange}
                  value={this.state.maxDiscount}
                  placeholder="Discount Range"
                  error={this.state.maxDiscountError}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Description"
                  type="textarea"
                  name="description"
                  change={this.onChange}
                  value={this.state.description}
                  placeholder="Description"
                  required={true}
                />
              </Col>
            </Row>
            <div className="center">
              <button
                type="submit"
                className="btn center login-button white-text"
                disabled={this.state.buttonValue ? "disabled" : null}
              >
                Add
              </button>
            </div>
          </Form>
        </Container>
        {this.props.productList.message ? (
          <Notifier message={this.props.productList.message} />
        ) : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
      </div>
    );
  }
}

AddProductList.propTypes = {
  addProductList: PropTypes.func.isRequired,
  productList: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  productList: state.productList,
});

export default connect(mapStateToProps, { addProductList })(AddProductList);
