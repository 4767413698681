import axios from "axios";
import { EMP_AWARDS_GET } from "../../types";
import { CURD_EMP_AWARDS } from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addEmpAwards = (data, back) => (dispatch) => {
  axios
    .post(CURD_EMP_AWARDS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getEmpAwards));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getEmpAwards = () => (dispatch) => {
  axios
    .get(CURD_EMP_AWARDS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(EMP_AWARDS_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateEmpAwards = (data, back) => (dispatch) => {
  axios
    .put(CURD_EMP_AWARDS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteEmpAwards = (data) => (dispatch) => {
  axios
    .delete(CURD_EMP_AWARDS, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getEmpAwards));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
