import React, { Component } from "react";
import { Form, Row, Col, FormGroup, Label, Input, FormText } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// components
import {
  Validator,
  submitValidator,
  valid,
  errors,
  refresh,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
// redux
import { updateBusinessUnit } from "../../../../../redux/actions/hcm/business_setup/businessUnitAction";
import { getUserOrganization } from "../../../../../redux/actions/hcm/business_setup/organizationAction";

// -------------------------------------------------------------------------------------------------------------

class UpdateBusinessUnit extends Component {
  constructor(props) {
    super(props);
    const { orgLegalEntityName, id, busiUnitCode, busiUnitName, remarks } =
      this.props.data;
    this.state = {
      legalEntityName: orgLegalEntityName,
      businessUnitId: id,
      businessUnitCode: busiUnitCode,
      businessUnitName: busiUnitName,
      remarks: remarks,
      message: "",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      orgLegalEntityName: this.state.legalEntityName,
      id: this.state.businessUnitId,
      busiUnitCode: this.state.businessUnitCode,
      busiUnitName: this.state.businessUnitName,
      remarks: this.state.remarks,
      updatedBy: localStorage.getItem("id"),
    };
    if (valid) {
      this.props.updateBusinessUnit(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    this.props.getUserOrganization();
    refresh();
    this.setState({
      message: "",
      error: "",
    });
  }

  render() {
    const {
      legalEntityName,
      businessUnitCode,
      businessUnitName,
      remarks,
      error,
      message,
    } = this.state;
    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Update Business Unit</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="6" className="mx-auto">
              <FormGroup>
                <Label className="label-sm bold">Organization Name</Label>
                <Input
                  type="select"
                  name="legalEntityName"
                  className="form-control-sm"
                  value={legalEntityName}
                  onChange={this.onChange}
                  required={true}
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {this.props.organization.data.map((item) => {
                    return (
                      <option key={item.id} value={item.orgLegalEntityName}>
                        {item.orgLegalEntityName}
                      </option>
                    );
                  })}
                </Input>
                {errors.legalEntityName && (
                  <FormText className="error">
                    {errors.legalEntityName}
                  </FormText>
                )}
              </FormGroup>
              <FormComponent
                labelClass="bold label-sm"
                label="Business Unit Name"
                type="text"
                name="businessUnitName"
                inputClass="form-control-sm"
                value={businessUnitName}
                change={this.onChange}
                required={true}
              />

              <FormComponent
                labelClass="bold label-sm"
                label="Business Unit Code"
                type="text"
                name="businessUnitCode"
                inputClass="form-control-sm"
                value={businessUnitCode}
                change={this.onChange}
                required={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Remarks"
                type="textarea"
                name="remarks"
                inputClass="form-control-sm"
                value={remarks}
                change={this.onChange}
              />
            </Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Update
            </button>
          </div>
        </Form>
        {this.props.businessUnit.message && (
          <Notifier message={this.props.businessUnit.message} />
        )}
        {this.props.businessUnit.error && (
          <ErrorNotifier message={this.props.businessUnit.error} />
        )}
      </React.Fragment>
    );
  }
}

UpdateBusinessUnit.propTypes = {
  updateBusinessUnit: PropTypes.func.isRequired,
  businessUnit: PropTypes.object.isRequired,
  getUserOrganization: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  organization: state.organization,
  businessUnit: state.businessUnit,
});

export default connect(mapStateToProps, {
  getUserOrganization,
  updateBusinessUnit,
})(UpdateBusinessUnit);
