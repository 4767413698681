import axios from "axios";
import {
  PAYMENT_STATUS_ADD,
  PAYMENT_STATUS_GET,
  PAYMENT_STATUS_UPDATE,
  PAYMENT_STATUS_DELETE,
  GET_ERRORS,
} from "../../types";
import {
  ADD_PAYMENT_STATUS,
  GET_PAYMENT_STATUS,
  UPDATE_PAYMENT_STATUS,
  DELETE_PAYMENT_STATUS,
} from "../../../../utils/routes";
import { setMessage } from "../../salesActions";

export const addPaymentStatus = (data, back) => (dispatch) => {
  axios
    .post(ADD_PAYMENT_STATUS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getPaymentStatus());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const getPaymentStatus = () => (dispatch) => {
  axios
    .get(GET_PAYMENT_STATUS, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: PAYMENT_STATUS_GET,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};

export const updatePaymentStatus = (data, back) => (dispatch) => {
  axios
    .post(UPDATE_PAYMENT_STATUS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getPaymentStatus());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const deletePaymentStatus = (data) => (dispatch) => {
  axios
    .post(DELETE_PAYMENT_STATUS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getPaymentStatus());
      }, 2000);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};
