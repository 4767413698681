import React, { Component } from "react";
import axios from "axios";
import {
  UPLOAD_PROFESSION_DOCUMENTS,
  FETCH_PROFESSION_DOCUMENTS,
  DOWNLOAD_PROFESSION_DOCUMENTS,
  DELETE_PROFESSION_DOCUMENTS,
} from "../../../../../utils/routes";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import { FormText } from "reactstrap";

class ResumeUpload extends Component {
  constructor() {
    super();
    this.state = {
      file: [],
      docs: [],
      name: "",
      fileError: "",
      message: "",
      error: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getData = this.getData.bind(this);
    this.getDocs = this.getDocs.bind(this);
    this.deleteDoc = this.deleteDoc.bind(this);
  }

  onChange(e) {
    let allowedExtension = /(\.pdf|\.docx)$/i;
    if (allowedExtension.exec(e.target.files[0].name)) {
      if ((e.target.files[0].size / 1024 / 1024).toFixed(2) < 2) {
        this.setState({
          file: e.target.files[0] || [],
          name: e.target.files[0].name,
          fileError: "",
        });
      } else {
        this.setState({
          file: e.target.files[0],
          fileError: "File size must less than 2MB",
        });
      }
    } else {
      this.setState({
        file: e.target.files[0],
        fileError: "File type not allowed",
      });
    }
  }

  onSubmit() {
    const { fileError, file } = this.state;
    let fd = new FormData();
    fd.append("empResume", file);
    if (!fileError && file.length !== 0) {
      axios
        .post(UPLOAD_PROFESSION_DOCUMENTS, fd, { withCredentials: true })
        .then((res) => {
          this.setState({
            message: res.data.message,
          });
          setTimeout(() => {
            this.setState({
              message: "",
              file: [],
            });
            this.getData();
          }, 2000);
        })
        .catch((err) => {
          this.setState({ error: "Upload failed" });
          setTimeout(() => {
            this.setState({ error: "" });
          }, 2000);
        });
      setTimeout(() => {
        this.setState({ error: null });
      }, 2000);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  getData() {
    const data = {
      empId: localStorage.getItem("id"),
    };
    // axios
    //   .post(FETCH_PROFESSION_DOCUMENTS, data, { withCredentials: true })
    //   .then((res) => {
    //     this.setState({
    //       docs: res.data,
    //     });
    //   });
  }

  componentDidMount() {
    this.getData();
  }

  /* 
This function is for downloading the document with respect to the document Id
*/
  getDocs(file) {
    let data = {
      id: file.id,
    };
    axios
      .post(DOWNLOAD_PROFESSION_DOCUMENTS, data, {
        responseType: "blob",
        withCredentials: true,
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file.docsName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        this.setState({
          error: "download failed",
        });
      });
    setTimeout(() => {
      this.setState({
        message: "",
        error: "",
      });
      this.getData();
    }, 2000);
  }

  deleteDoc(file) {
    let data = {
      id: file.id,
    };

    axios
      .post(DELETE_PROFESSION_DOCUMENTS, data, { withCredentials: true })
      .then((res) => {
        this.setState({
          message: res.data,
        });
        setTimeout(() => {
          this.setState({ message: null });
        }, 2000);
        this.getData();
      })
      .catch((err) => {
        this.setState({
          error: "Could not delete",
        });
        setTimeout(() => {
          this.setState({ error: null });
        }, 2000);
      });
  }

  render() {
    const { fileError } = this.state;
    return (
      <div className="center">
        <hr />
        <h6 className="center bold">Upload Resume</h6>
        {this.state.docs &&
          this.state.docs.map((file) => {
            return (
              <span key={file.id} className="m-3 card document-card pointer">
                <i
                  className="material-icons cancel-button pointer"
                  onClick={() => this.deleteDoc(file)}
                >
                  cancel
                </i>
                <p
                  onClick={() => this.getDocs(file)}
                  className="bold label-sm p-2"
                >
                  {file.docsName}
                </p>
              </span>
            );
          })}
        <hr />
        <label htmlFor="file" className="bold label-sm">
          Resume :
        </label>
        <input
          type="file"
          name="empResume"
          className="form-control-sm"
          style={{ fontSize: "11px" }}
          onChange={this.onChange}
          accept=".pdf, .png"
          required={true}
        />
        <button
          style={{ fontSize: "10px" }}
          onClick={this.onSubmit}
          className="btn btn-secondary"
        >
          Submit
        </button>
        {fileError && <FormText className="error">{fileError}</FormText>}
        <hr />
        {this.state.message && <Notifier message={this.state.message} />}
        {this.state.error && <ErrorNotifier message={this.state.error} />}
      </div>
    );
  }
}

export default ResumeUpload;
