import React, { Component } from "react";
import { Form, Row, Col, Label, FormText, FormGroup, Input } from "reactstrap";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import Notifier from "../../../../aside/Notifier";
import { addEmployeeClaim } from "../../../../../redux/actions/finance/claims/employeeClaimAction";
import { getClaimType } from "../../../../../redux/actions/finance/claims/claimTypeAction";
import { getCurrency } from "../../../../../redux/actions/currencyAction";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DateFns from "date-fns";

class AddEmployeeClaim extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: "Pion Internal Project",
      claimType: "",
      currency: "",
      amount: "",
      expenseDate: "",
      claimDate: new Date(),
      description: "",
      file: [],
      fileError: "",
      declaration: false,
      declarationError: "",
      dateError: "",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleFile = this.handleFile.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    if (e.target.name === "declaration") {
      this.setState({
        declaration: e.target.checked,
        declarationError: "",
      });
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  expenseDateOnchange = (e) => {
    console.log(e.target.value);
    if (e.target.value > DateFns.format(new Date(), "YYYY-MM-DD")) {
      this.setState({
        [e.target.name]: e.target.value,
        dateError: "Expense Date should less than current Date",
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        dateError: "",
      });
    }
  };

  handleFile(e) {
    Validator(e.target);
    let allowedExtension = /(\.pdf|\.png)$/i;
    if (allowedExtension.exec(e.target.files[0].name)) {
      if ((e.target.files[0].size / 1024 / 1024).toFixed(2) < 2) {
        this.setState({
          file: e.target.files[0] || [],
          fileError: "",
        });
      } else {
        this.setState({
          file: e.target.files[0],
          fileError: "File size must less than 2MB",
        });
      }
    } else {
      this.setState({
        file: e.target.files[0],
        fileError: "File type not allowed",
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    const { dateError } = this.state;

    if (!this.state.declaration) {
      this.setState({
        declarationError: "Not Agree",
      });
    }

    let fd = new FormData();

    fd.append("projectSelection", this.state.project);
    fd.append("empId", localStorage.getItem("id"));
    fd.append("empName", localStorage.getItem("username"));
    fd.append("claimType", this.state.claimType);
    fd.append("claimStatus", "Pending for Approval");
    fd.append("currency", this.state.currency);
    fd.append("amount", this.state.amount);
    fd.append("expenseDate", this.state.expenseDate);
    fd.append("claimDate", this.state.claimDate);
    fd.append("description", this.state.description);
    fd.append("declarationStatement", this.state.declaration);
    fd.append("createdBy", localStorage.getItem("id"));
    fd.append("uploadBills", this.state.file);

    if (valid && this.state.declaration && !dateError) {
      this.props.addEmployeeClaim(fd, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidMount contains the error refresh method and setState method for calling the render method
*/
  componentDidMount() {
    refresh();
    this.setState({
      error: "",
    });
    this.props.getClaimType();
    this.props.getCurrency();
  }

  render() {
    const { file, declaration, declarationError, dateError, fileError, error } =
      this.state;

    console.log(errors.declaration);
    return (
      <React.Fragment>
        <h5 className="center bold pt-2">New Claim</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="8" className="mx-auto">
              <FormGroup>
                <Label className="label-sm bold">Project</Label>
                <Input
                  type="select"
                  name="project"
                  value={this.state.project}
                  className="form-control-sm"
                  onChange={this.onChange}
                  required={true}
                >
                  <option value="Pion Internal Project">
                    Pion Internal Project
                  </option>
                </Input>
                {errors.project && (
                  <FormText className="error">{errors.project}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="label-sm bold">Claim Type</Label>
                <Input
                  type="select"
                  name="claimType"
                  value={this.state.claimType}
                  className="form-control-sm"
                  onChange={this.onChange}
                  required={true}
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {this.props.claimType.data.map((item) => {
                    return (
                      <option key={item.claimTypeId} value={item.claimTypeName}>
                        {item.claimTypeName}
                      </option>
                    );
                  })}
                </Input>
                {errors.claimType && (
                  <FormText className="error">{errors.claimType}</FormText>
                )}
              </FormGroup>
              <Row>
                <Col md="6" style={{ paddingLeft: "0px" }}>
                  <FormComponent
                    labelClass="bold label-sm"
                    label="Amount"
                    type="number"
                    name="amount"
                    change={this.onChange}
                    value={this.state.amount}
                    required={true}
                  />
                </Col>
                <Col md="6" style={{ paddingRight: "0px" }}>
                  <Label className="bold label-sm">Currency</Label>
                  <Input
                    type="select"
                    name="currency"
                    className="form-control-sm"
                    value={this.state.currency}
                    onChange={this.onChange}
                    required={true}
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.currency.data.map((item) => {
                      return (
                        <option key={item.currencyId} value={item.currencyCode}>
                          {item.currencyCode}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.currency && (
                    <FormText className="error">{errors.currency}</FormText>
                  )}
                </Col>
              </Row>
              <FormComponent
                labelClass="bold label-sm"
                label="Expense Date"
                type="date"
                name="expenseDate"
                inputClass="form-control-sm"
                value={this.state.expenseDate}
                change={this.expenseDateOnchange}
                required={true}
                error={dateError}
                max={DateFns.format(new Date(), "YYYY-MM-DD")}
                min={DateFns.format(
                  DateFns.subMonths(new Date(), 3),
                  "YYYY-MM-DD"
                )}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Description"
                type="textarea"
                name="description"
                inputClass="form-control-sm"
                value={this.state.description}
                change={this.onChange}
                required={true}
              />
              <div className="file-div">
                <FormGroup className="file-display">
                  <Label className="bold label-sm">Bills</Label>
                  <Input
                    disabled
                    type="text"
                    value={file.name || ""}
                    className="form-control-sm"
                    required={true}
                  />
                  {errors.uploadBills && (
                    <FormText className="error ">{errors.uploadBills}</FormText>
                  )}
                  {fileError && (
                    <FormText className="error ">{fileError}</FormText>
                  )}
                </FormGroup>
                <FormGroup className="file-browse-btn-div">
                  <label className="btn  label-sm file-browse-btn">
                    Browse
                    <Input
                      type="file"
                      name="uploadBills"
                      className="form-control-sm file-input"
                      onChange={this.handleFile}
                      required={true}
                    />
                  </label>
                </FormGroup>
              </div>
              <input
                type="checkbox"
                id="declaration"
                name="declaration"
                checked={this.state.declaration}
                onChange={this.onChange}
                required={true}
              />
              {<FormText className="error">{declarationError}</FormText>}
              <label for="declaration">
                I declare that I have not raised these claims in the past and I
                am aware of consequences for any false or duplicate claims under
                the Business Ethics Policy which I have read and understood
              </label>
            </Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Add
            </button>
          </div>
        </Form>
        {error && <ErrorNotifier message={error} />}
        {this.props.employeeClaim.error && (
          <ErrorNotifier message={this.props.employeeClaim.error} />
        )}
        {this.props.employeeClaim.message && (
          <Notifier message={this.props.employeeClaim.message} />
        )}
      </React.Fragment>
    );
  }
}

AddEmployeeClaim.propTypes = {
  addEmployeeClaim: PropTypes.func.isRequired,
  employeeClaim: PropTypes.object.isRequired,
  getClaimType: PropTypes.func.isRequired,
  claimType: PropTypes.object.isRequired,
  getCurrency: PropTypes.func.isRequired,
  currency: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  employeeClaim: state.employeeClaim,
  claimType: state.claimType,
  currency: state.currency,
});

export default connect(mapStateToProps, {
  addEmployeeClaim,
  getClaimType,
  getCurrency,
})(AddEmployeeClaim);
