import React from "react";
import DateFns from "date-fns";
// material ui
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// ----------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
  },
  text: {
    fontSize: "0.75rem",
    fontWeight: "400",
  },
}));

// -----------------------------------------------------------
function DetailsPageFooter({ createdBy, createdOn, updatedBy, updatedOn }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="caption" color="textSecondary">
        Tagged By {createdBy} on{" "}
        {createdOn && DateFns.format(createdOn, "DD-MM-YYYY")} and Updated By{" "}
        {updatedBy} on {updatedOn && DateFns.format(updatedOn, "DD-MM-YYYY")}
      </Typography>
    </div>
  );
}

export default DetailsPageFooter;
