import axios from "axios";
import { EMP_NOMINEE_GET, EMP_NOMINEE_FETCH } from "../../types";
import { CURD_EMP_NOMINEE, FETCH_EMP_NOMINEE } from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addEmpNominee = (data, back) => (dispatch) => {
  axios
    .post(CURD_EMP_NOMINEE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getEmpNominee));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getEmpNominee = () => (dispatch) => {
  axios
    .get(CURD_EMP_NOMINEE, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(EMP_NOMINEE_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const fetchEmpNominee = () => (dispatch) => {
  axios
    .get(FETCH_EMP_NOMINEE, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(EMP_NOMINEE_FETCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateEmpNominee = (data, back) => (dispatch) => {
  axios
    .put(CURD_EMP_NOMINEE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteEmpNominee = (data) => (dispatch) => {
  axios
    .delete(CURD_EMP_NOMINEE, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getEmpNominee));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
