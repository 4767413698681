import axios from "axios";
import { MODULE_ACCESS_PERMISSION_GET } from "../../../types";
import {
  GET_MODULE_ACCESS_PERMISSION,
  UPDATE_MODULE_ACCESS_PERMISSION,
} from "../../../../../utils/routes";
import setSuccessMessage from "../../../common/setSuccessMessage";
import setErrorMessage from "../../../common/setErrorMessage";
import getDispatch from "../../../common/getDispatch";

export const addModuleAccessPermission = (data, back) => (dispatch) => {
  axios
    .post(UPDATE_MODULE_ACCESS_PERMISSION, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getModuleAccessPermission = (roleCode) => (dispatch) => {
  axios
    .get(`${GET_MODULE_ACCESS_PERMISSION}?role=${roleCode}`, {
      withCredentials: true,
    })
    .then((res) => {
      dispatch(getDispatch(MODULE_ACCESS_PERMISSION_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const fetchModuleAccessPermission = (roleCode) => (dispatch) => {
  axios
    .get(GET_MODULE_ACCESS_PERMISSION, {
      params: { role: roleCode },
      withCredentials: true,
    })
    .then((res) => {
      dispatch(getDispatch(MODULE_ACCESS_PERMISSION_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateModuleAccessPermission = (data, back) => (dispatch) => {
  axios
    .put(UPDATE_MODULE_ACCESS_PERMISSION, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteModuleAccessPermission = (data) => (dispatch) => {
  axios
    .delete(UPDATE_MODULE_ACCESS_PERMISSION, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
