import axios from "axios";
import { REQUEST_TYPE_GET } from "../../types";
import { CURD_REQUEST_TYPE, USER_REQUEST_TYPE } from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addRequestType = (data, back) => (dispatch) => {
  axios
    .post(CURD_REQUEST_TYPE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getRequestType));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getRequestType = () => (dispatch) => {
  axios
    .get(CURD_REQUEST_TYPE, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(REQUEST_TYPE_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserRequestType = () => (dispatch) => {
  axios
    .get(USER_REQUEST_TYPE, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(REQUEST_TYPE_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateRequestType = (data, back) => (dispatch) => {
  axios
    .put(CURD_REQUEST_TYPE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteRequestType = (data) => (dispatch) => {
  axios
    .delete(CURD_REQUEST_TYPE, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getRequestType));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
