import React, { Component } from "react";
import { Form, Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// components
import FormComponent from "../../../../../common/FormComponent";
import {
  Validator,
  submitValidator,
  refresh,
  valid,
} from "../../../../../common/Validator";
import Notifier from "../../../../../aside/Notifier";
import ErrorNotifier from "../../../../../aside/ErrorNotifier";
// redux
import { updateContactsFamilyDetails } from "../../../../../../redux/actions/hcm/my_profile/contacts_details/contactsFamilyDetailsAction";

class UpdateFamilyContactDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spouseName: this.props.data.length ? this.props.data[0].spouseName : "",
      spouseDialingCode: this.props.data.length
        ? this.props.data[0].spouseDialingCode
        : "",
      spouseNo: this.props.data.length
        ? this.props.data[0].spouseContactNo
        : "",
      fatherName: this.props.data.length ? this.props.data[0].fatherName : "",
      fatherDialingCode: this.props.data.length
        ? this.props.data[0].fatherDialingCode
        : "",
      fatherNo: this.props.data.length
        ? this.props.data[0].fatherContactNo
        : "",
      message: "",
      error: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    if (e.target.name === "spouseName") {
      if (e.target.value.toUpperCase() === "NA") {
        this.setState({
          spouseName: e.target.value,
          spouseNo: "0",
        });
      } else {
        this.setState({
          spouseName: e.target.value,
        });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      spouseName: this.state.spouseName,
      spouseDialingCode: this.state.spouseDialingCode,
      spouseContactNo: this.state.spouseNo,
      fatherName: this.state.fatherName,
      fatherDialingCode: this.state.fatherDialingCode,
      fatherContactNo: this.state.fatherNo,
    };
    if (valid) {
      this.props.updateContactsFamilyDetails(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    refresh();
    this.setState({
      message: "",
      error: "",
    });
  }

  render() {
    const {
      spouseName,
      spouseDialingCode,
      spouseNo,
      fatherName,
      fatherDialingCode,
      fatherNo,
      message,
      error,
    } = this.state;

    return (
      <React.Fragment>
        <i
          className="material-icons cancel-button pointer"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <FormComponent
            labelClass="bold label-sm"
            label="Father's Name"
            type="text"
            name="fatherName"
            inputClass="form-control-sm"
            value={fatherName}
            change={this.onChange}
            required={true}
          />
          <Row>
            <Col md="2" className="p-0">
              <FormComponent
                labelClass="bold label-sm"
                label=" "
                type="number"
                name="fatherDialingCode"
                inputClass="form-control-sm"
                value={fatherDialingCode}
                change={this.onChange}
                required={true}
              />
            </Col>
            <Col md="10" className="p-0">
              <FormComponent
                labelClass="bold label-sm"
                label="Father's Number"
                type="tel"
                name="fatherNo"
                inputClass="form-control-sm"
                value={fatherNo}
                change={this.onChange}
                required={true}
              />
            </Col>
          </Row>
          <FormComponent
            labelClass="bold label-sm"
            label="Spouse Name"
            type="text"
            name="spouseName"
            inputClass="form-control-sm"
            value={spouseName}
            change={this.onChange}
          />
          {this.state.spouseName &&
          this.state.spouseName.toUpperCase() !== "NA" ? (
            <Row>
              <Col md="2" className="p-0">
                <FormComponent
                  labelClass="bold label-sm"
                  label=" "
                  type="number"
                  name="spouseDialingCode"
                  inputClass="form-control-sm"
                  value={spouseDialingCode}
                  change={this.onChange}
                  maxLength={4}
                />
              </Col>
              <Col md="10" className="p-0">
                <FormComponent
                  labelClass="bold label-sm"
                  label="Spouse Number"
                  type="tel"
                  name="spouseNo"
                  inputClass="form-control-sm"
                  value={spouseNo}
                  change={this.onChange}
                />
              </Col>
            </Row>
          ) : null}
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Update
            </button>
          </div>
        </Form>
        {this.props.contactsFamilyDetails.message && (
          <Notifier message={this.props.contactsFamilyDetails.message} />
        )}
        {this.props.contactsFamilyDetails.error && (
          <ErrorNotifier message={this.props.contactsFamilyDetails.error} />
        )}
      </React.Fragment>
    );
  }
}

UpdateFamilyContactDetails.propTypes = {
  updateContactsFamilyDetails: PropTypes.func.isRequired,
  contactsFamilyDetails: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  contactsFamilyDetails: state.contactsFamilyDetails,
});

export default connect(mapStateToProps, { updateContactsFamilyDetails })(
  UpdateFamilyContactDetails
);
