import React, { Component } from "react";
import { PieChart, Pie, Sector, Cell } from "recharts";
import { Card } from "reactstrap";
import axios from "axios";
import { GET_CUSTOMER_LOCATION } from "../../../../utils/routes";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.7;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  if (percent !== 0) {
    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor="middle"
        dominantBaseline="middle"
        style={{ fontSize: "10px" }}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  }
};

export default class CampTypeToLeadRatio extends Component {
  static jsfiddleUrl = "https://jsfiddle.net/alidingling/c9pL8k61/";

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      error: "",
    };
  }

  componentDidMount() {
    axios
      .get(GET_CUSTOMER_LOCATION, { withCredentials: true })
      .then((res) => {
        this.setState({
          data: res.data.rows,
        });
      })
      .catch((err) => {
        this.setState({
          error: err,
        });
      });
  }
  render() {
    const { data } = this.state;

    return (
      <Card className="recharts-card">
        <PieChart width={150} height={150}>
          <Pie
            data={data}
            cx={60}
            cy={60}
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={55}
            innerRadius={10}
            fill="#8884d8"
            dataKey="value"
            startAngle={360}
            endAngle={0}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
        <div className="reCharts-labels-div">
          <ul>
            {data.map((item) => {
              return (
                <li className="reCharts-list">
                  <span
                    id="marketingChart1-one-list"
                    style={{ backgroundColor: item.color }}
                  ></span>
                  <span className="recharts-label-sm bold marketingChart1-li-span-crm-customerLocation">
                    {item.geoName}
                  </span>
                  <span className="recharts-label-sm ">
                    &nbsp;&nbsp;:&nbsp;&nbsp;{item.value}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </Card>
    );
  }
}
