import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { UPDATE_MAINTAIN } from "../../../../utils/routes";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import {
  Validator,
  submitValidator,
  errors,
  valid,
  refresh,
} from "../../../common/Validator";
import { updateSales } from "../../../../redux/actions/salesActions";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";

class UpdateForm extends Component {
  constructor(props) {
    super(props);
    const {
      candId,
      jobId,
      candName,
      email,
      phone,
      resume,
      status,
      comments,
    } = props.item;
    this.state = {
      candid: candId,
      jobid: jobId,
      candname: candName,
      Email: email,
      Phone: phone,
      Resume: resume,
      Status: status,
      Comments: comments,
      message: null,
      error: null,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate() {
    if (this.props.sales.message) {
      setTimeout(() => {
        this.props.back();
      }, 2000);
    }
  }

  onChange(e) {
    e.preventDefault();
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    const data = {
      candId: this.state.candid,
      jobId: this.state.jobid,
      candName: this.state.candname,
      email: this.state.Email,
      phone: this.state.Phone,
      status: this.state.Status,
      comments: this.state.Comments,
    };

    //  Function called for candidate update
    if (valid) {
      axios
        .post(UPDATE_MAINTAIN, data, { withCredentials: true })
        .then((res) => {
          this.setState({
            message: res.data,
          });
          setTimeout(() => {
            this.props.back();
          }, 2000);
        })
        .catch((err) => {
          this.setState({
            error: err,
          });
        });
      setTimeout(() => {
        this.setState({
          message: "",
          error: "",
        });
      }, 2000);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidMount function contains the form validation error refresh method
*/
  componentDidMount() {
    refresh();
  }

  // Render function for update candidate
  render() {
    let shrink;
    const { candname, Email, Comments } = this.state.formErrors;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");
    return (
      <div className={shrink}>
        <Container className="card container-card mt--5">
          <i
            className="material-icons pointer"
            style={{
              position: "absolute",
              top: "5px",
              right: "5px",
              color: "#C00",
            }}
            onClick={() => this.props.back()}
          >
            cancel
          </i>
          <h5 className="center bold p-1">Update Candidate</h5>
          <Form onSubmit={this.onSubmit} className="p-1" noValidate>
            <Row>
              <Col md="4">
                <FormGroup className="mt--5">
                  <Label className="bold label-sm">Candidate Id</Label>
                  <Input
                    type="number"
                    name="candid"
                    value={this.state.candid}
                    className="form-control-sm"
                    disabled
                  ></Input>
                </FormGroup>
                <FormGroup className="mt--5">
                  <Label className="bold label-sm">Candidate Name</Label>
                  <Input
                    type="text"
                    name="candname"
                    value={this.state.candname}
                    className={`form-control-sm ${candname && "is-invalid"}`}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    required={true}
                  ></Input>
                  {errors.candname && (
                    <FormText className="error">{errors.candname}</FormText>
                  )}
                </FormGroup>
                <FormGroup className="mt--5">
                  <Label className="bold label-sm">Job Id</Label>
                  <Input
                    type="number"
                    name="jobid"
                    value={this.state.jobid}
                    className="form-control-sm"
                    onChange={this.onChange}
                    required={true}
                  />
                  {errors.jobid && (
                    <FormText className="error">{errors.jobid}</FormText>
                  )}
                </FormGroup>
                <FormGroup className="mt--5">
                  <Label className="bold label-sm">Status</Label>
                  <Input
                    type="select"
                    name="Status"
                    value={this.state.Status}
                    className="form-control-sm"
                    onChange={this.onChange}
                    required={true}
                  >
                    <option value="NEW">NEW</option>
                    <option value="WIP">WIP</option>
                    <option value="COMPLETED">COMPLETED</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup className="mt--5">
                  <Label className="bold label-sm">Email</Label>
                  <Input
                    type="email"
                    name="Email"
                    value={this.state.Email}
                    className={`form-control-sm ${Email && "is-invalid"}`}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    required={true}
                  />
                  {errors.Email && (
                    <FormText className="error">{errors.Email}</FormText>
                  )}
                </FormGroup>
                <FormGroup className="mt--5">
                  <Label className="bold label-sm">Phone</Label>
                  <Input
                    type="number"
                    name="Phone"
                    value={this.state.Phone}
                    className="form-control-sm"
                    onChange={this.onChange}
                    required={true}
                  />
                  {errors.Phone && (
                    <FormText className="error">{errors.Phone}</FormText>
                  )}
                </FormGroup>
                <FormGroup className="mt--5">
                  <Label className="bold label-sm">Comments</Label>
                  <Input
                    type="textarea"
                    name="Comments"
                    value={this.state.Comments}
                    className={`form-control-sm ${Comments && "is-invalid"}`}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    required={true}
                  />
                  {errors.Comments && (
                    <FormText className="error">{errors.Comments}</FormText>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <div className="center">
              <button className="btn center login-button white-text">
                Update Candidate
              </button>
            </div>
          </Form>
        </Container>
        {this.state.message ? <Notifier message={this.state.message} /> : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
      </div>
    );
  }
}

UpdateForm.propTypes = {
  sales: PropTypes.object.isRequired,
  updateSales: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  sales: state.sales,
});

export default connect(mapStateToProps, { updateSales })(UpdateForm);
