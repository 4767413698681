import React, { Component } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import { Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// redux
import { getContacts } from "../../../../redux/actions/crm/contacts/contactsAction";

// -----------------------------------------------------------------------------------------

class CustomerOrgChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      entityModal: false,
      entityData: {},
      accountModal: false,
      accountData: {},
      contactModal: false,
      contactData: {},
      contact: {},
    };

    this.accountDetailsModal = this.accountDetailsModal.bind(this);
    this.accountToggle = this.accountToggle.bind(this);
    this.entityDetailsModal = this.entityDetailsModal.bind(this);
    this.entityToggle = this.entityToggle.bind(this);
    this.contactDetailsModal = this.contactDetailsModal.bind(this);
    this.contactToggle = this.contactToggle.bind(this);
  }

  componentDidMount() {
    this.props.getContacts();
  }

  /*
entityDetailsModal function to display the entity details in modal
*/
  entityDetailsModal(data) {
    this.setState({
      entityModal: true,
      entityData: data,
    });
  }

  /*
entityToggle function to toggle the entity details modal
*/
  entityToggle() {
    this.setState({
      entityModal: false,
    });
  }

  /*
accountDetailsModal function to display the account details in modal
*/
  accountDetailsModal(data) {
    this.setState({
      accountModal: true,
      accountData: data,
    });
  }

  /*
accountToggle function to toggle the account details modal
*/
  accountToggle() {
    this.setState({
      accountModal: false,
    });
  }

  /*
contactDetailsModal function to display the contact details in modal
*/
  contactDetailsModal(data) {
    this.setState({
      contactModal: true,
      contactData: data,
    });
    this.props.crmContacts.data.map((item) => {
      if (item.id === data.contactId) {
        this.setState({
          contact: item,
        });
      }
    });
  }

  /*
contactToggle function to toggle the contact details modal
*/
  contactToggle() {
    this.setState({
      contactModal: false,
    });
  }

  render() {
    let { data, entityData, accountData, contactData, contact } = this.state;

    return (
      <div>
        <Tree
          lineWidth={"2px"}
          lineColor={"green"}
          lineBorderRadius={"10px"}
          label={<div className="tree-div-level1">{data.customerName}</div>}
        >
          {data.customerentity.length > 0 &&
            data.customerentity.map((item) => {
              return (
                <TreeNode
                  key={item.id}
                  label={
                    <div
                      className="tree-div pointer"
                      onClick={() => this.entityDetailsModal(item)}
                    >
                      {item.entityName}
                    </div>
                  }
                >
                  {item.customeraccount.length > 0 &&
                    item.customeraccount.map((item1) => {
                      return (
                        <TreeNode
                          key={item1.id}
                          label={
                            <div
                              className="tree-div pointer"
                              onClick={() => this.accountDetailsModal(item1)}
                            >
                              <p>{item1.accountName}</p>
                            </div>
                          }
                        >
                          {item1.customercontact.length > 0 &&
                            item1.customercontact.map((item2) => {
                              return (
                                <TreeNode
                                  key={item2.id}
                                  label={
                                    <div
                                      className="tree-div pointer"
                                      onClick={() =>
                                        this.contactDetailsModal(item2)
                                      }
                                    >
                                      <p>{item2.contactName}</p>
                                      <p>{item2.designation}</p>
                                    </div>
                                  }
                                />
                              );
                            })}
                        </TreeNode>
                      );
                    })}
                </TreeNode>
              );
            })}
        </Tree>
        <Modal size="md" isOpen={this.state.entityModal}>
          <ModalHeader toggle={this.entityToggle}>Entity Details</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="12">
                <ul className="CustDetails-ul">
                  <li>
                    <span className=" label-sm bold account-modal-details">
                      Entity No.
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{entityData.id}
                    </span>
                  </li>
                  <li>
                    <span className=" label-sm bold account-modal-details">
                      Entity Name
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{entityData.entityName}
                    </span>
                  </li>
                  <li>
                    <span className=" label-sm bold account-modal-details">
                      Reg Number
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{entityData.registrationNo}
                    </span>
                  </li>
                  <li>
                    <span className=" label-sm bold account-modal-details">
                      PAN Number
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{entityData.panNo}
                    </span>
                  </li>
                  <li>
                    <span className=" label-sm bold account-modal-details">
                      TAN Number
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{entityData.tanNo}
                    </span>
                  </li>
                  <li>
                    <span className=" label-sm bold account-modal-details">
                      TIN Number
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{entityData.tinNo}
                    </span>
                  </li>
                  <li>
                    <span className=" label-sm bold account-modal-details">
                      GST Number
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{entityData.gstNo}
                    </span>
                  </li>
                  <li>
                    <span className=" label-sm bold account-modal-details">
                      Currency
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{entityData.currency}
                    </span>
                  </li>
                  <li>
                    <span className=" label-sm bold account-modal-details">
                      Billing Addr.
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{entityData.billingAddress}
                    </span>
                  </li>
                  <li>
                    <span className=" label-sm bold account-modal-details">
                      Shipping Addr.
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{entityData.shippingAddress}
                    </span>
                  </li>
                  <li>
                    <span className=" label-sm bold account-modal-details">
                      Status
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{entityData.entityStatus}
                    </span>
                  </li>
                  <li>
                    <span className=" label-sm bold account-modal-details">
                      Remarks
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{entityData.remarks}
                    </span>
                  </li>
                </ul>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        <Modal size="md" isOpen={this.state.accountModal}>
          <ModalHeader toggle={this.accountToggle}>Account Details</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="12">
                <ul className="CustDetails-ul">
                  <li>
                    <span className=" label-sm bold account-modal-details">
                      Account No.
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{accountData.id}
                    </span>
                  </li>
                  <li>
                    <span className=" label-sm bold account-modal-details">
                      Account Name
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{accountData.accountName}
                    </span>
                  </li>
                  <li>
                    <span className=" label-sm bold account-modal-details">
                      Geography
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{accountData.geography}
                    </span>
                  </li>
                  <li>
                    <span className=" label-sm bold account-modal-details">
                      Country
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{accountData.country}
                    </span>
                  </li>
                  <li>
                    <span className=" label-sm bold account-modal-details">
                      Currency
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{accountData.currency}
                    </span>
                  </li>
                  <li>
                    <span className=" label-sm bold account-modal-details">
                      Status
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{accountData.accountStatus}
                    </span>
                  </li>
                  <li>
                    <span className=" label-sm bold account-modal-details">
                      Remarks
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{accountData.remarks}
                    </span>
                  </li>
                </ul>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        <Modal size="md" isOpen={this.state.contactModal}>
          <ModalHeader toggle={this.contactToggle}>Contact Details</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="12">
                <ul className="CustDetails-ul">
                  <li>
                    <span className=" label-sm bold contact-modal-details">
                      Contact ID
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{contact.id}
                    </span>
                  </li>
                  <li>
                    <span className=" label-sm bold contact-modal-details">
                      Contact Name
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{contactData.contactName}
                    </span>
                  </li>
                  <li>
                    <span className=" label-sm bold contact-modal-details">
                      Contact Email
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{contact.contactEmail}
                    </span>
                  </li>
                  <li>
                    <span className=" label-sm bold contact-modal-details">
                      Contact Number
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{contact.contactMobileNo}
                    </span>
                  </li>
                  <li>
                    <span className=" label-sm bold contact-modal-details">
                      Designation
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{contact.contactDesignation}
                    </span>
                  </li>
                  <li>
                    <span className=" label-sm bold contact-modal-details">
                      Country
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{contact.country}
                    </span>
                  </li>
                  <li>
                    <span className=" label-sm bold contact-modal-details">
                      Contact Status
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{contact.contactStatus}
                    </span>
                  </li>
                  <li>
                    <span className=" label-sm bold contact-modal-details">
                      Working Status
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {contactData.contactWorkingStatus}
                    </span>
                  </li>
                  <li>
                    <span className=" label-sm bold contact-modal-details">
                      Remarks
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{contactData.remarks}
                    </span>
                  </li>
                </ul>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

CustomerOrgChart.propTypes = {
  getContacts: PropTypes.func.isRequired,
  crmContacts: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  crmContacts: state.crmContacts,
});

export default connect(mapStateToProps, { getContacts })(CustomerOrgChart);
