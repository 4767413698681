import {
    BUSINESS_UNIT_GET,
    GET_ERRORS
} from '../actions/types';

const initialState = {
    data: [],
    message: null
  };

export default function(state = initialState, action){
    switch(action.type){
    case BUSINESS_UNIT_GET:
        return {
        ...state,
        data: action.payload
        }
    default:
        return state;
    }
}