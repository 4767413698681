import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SEARCH_REQUIREMENT } from "../../../../utils/routes";
import { Container, Table, Button } from "reactstrap";
import SearchHover from "../../../aside/searchHover";
import AddRequirement from "./addRequirement";
import { RsRequirements } from "../../../../utils/routes";
import { SEARCH_WITH_ID } from "../../../../utils/routes";
import ViewRequirement from "./viewRequiremnt";
import Searchbar from "../../../common/searchbar/SearchBar";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import CandidateKanban from "./candidate_kanban/CandidateKanban";
import {
  getRequirement,
  getAllRequirement,
  ReqsearchWithId,
  searchWithString,
} from "../../../../redux/actions/rs/requirements/requiremntsAction";
import { connect } from "react-redux";
import CustTooltip from "../../../common/tooltip/CustTooltip";



// reactstrap
import { Row, Col, Card } from "reactstrap";
import { data } from "jquery";
import { pointers } from "d3";

//---------------------------------------------------
const codedData = {
  moduleName: "",
  data: [],
};

const Requirement = (props) => {
  const [changeError, setChangeError] = useState("");
  const [searchValue, setSearchValue] = useState();
  const [dataar, setdataar] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isAvailable, setIsAvailable] = useState("");
  const [viewShow, setViewShow] = useState(false);
  const [requirementId, setRequirementId] = useState("");
  const [fullData, setFullData] = useState([]);
  const [final, setFinal] = useState(null);
  const [selection, setSelection] = useState(null);
  const [selected, setSelected] = useState({});
  const [hover, setHover] = useState(false);
  const [currentPermission, setCurrentPermission] = useState({});
  const [taggedView, setTaggedView] = useState(false);
  const [reqId, setReqId] = useState("");
  const[isToogleFullView,setIsToggleFullView] = useState(true)
  const [isCheckBackView,setIsCheckBackView] = useState(true)
  const refe = React.useRef(null);

  console.log("chandrahashse");
  // console.log(props.location.data);
  // const { moduleName, data } = props.location;
  let shrink = props.sidebar ? "scale" : "no-scale";

  // onchange event for search
  const searchChange = (e) => {
    if (!e.target.value) {
      setdataar([]);
      props.getRequirement();
    }
    setSearchValue(e.target.value);
    setChangeError("");
    setIsAvailable("");
    // setdataar([]);

    console.log(searchValue);
  };

  //  onsubmit event for search
  const searchClick = () => {
    if (!searchValue) {
      setChangeError("Please enter keyword to search");
    } else {
      setChangeError("");
      const data = {
        search: searchValue,
      };
      props.searchWithString(data);

      // }
    }
  };

  const extractPrimarySkill = (data) => {
    console.log(data);
    if (data.split(",").length > 1) {
      return <span>{data.split(", ").join(", ").toString()} &nbsp;</span>;
    } else {
      return data.split(",").map((arr) => arr.toString());
    }
  };

  // toogleAddButton
  const toggleAddBtn = () => {
    setIsOpen(!isOpen);
    props.getRequirement();
  };

  const isToggleView = () => {
    setViewShow(!viewShow);
    searchValue ?  props.searchWithString({search:searchValue}) :  
    isCheckBackView ? props.getRequirement() :  props.getAllRequirement();
  };

  const handleViewClick = (id) => {

    setViewShow(!viewShow);
    setRequirementId(id);
    // setSearchValue("");
  };

  useEffect(() => {
    for (let a in props.auth.data.accesses) {
      if (props.auth.data.accesses[a].appName === "RS") {
        for (let m in props.auth.data.accesses[a].pieUserModuleAccess) {
          if (
            props.auth.data.accesses[a].pieUserModuleAccess[m].moduleName ===
            "Requirements"
          ) {
            for (let s in props.auth.data.accesses[a].pieUserModuleAccess[m]
              .pieUserServiceAccess) {
              if (
                props.auth.data.accesses[a].pieUserModuleAccess[m]
                  .pieUserServiceAccess[s].serviceName ===
                "Requirement Management"
              ) {
                setCurrentPermission(
                  props.auth.data.accesses[a].pieUserModuleAccess[m]
                    .pieUserServiceAccess[s]
                );
              }
            }
          }
        }
      }
    }
    props.getRequirement();

    // getCollection();
  }, []);

  // useEffect(() => {
  //   setFullData(props.requirements.data)
  //  }, [props.requirements.data]);

  // getting requiremnt data
  console.log(fullData);
  console.log("requirements working");
  const getCollection = () => {
    axios
      .get(`${RsRequirements}/select`, {
        withCredentials: true,
      })
      .then((res) => {
        setFullData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

const handleAllReqClick =  () =>{
  if(isToogleFullView){
    props.getAllRequirement()
    setIsToggleFullView(false)
    setIsCheckBackView(false)
  }
  else{
    props.getRequirement();  
    setIsToggleFullView(true)
    setIsCheckBackView(true)
  }
  
}

  

  // close the candidate kanban modal
  const handleCloseTagedModal = () => {
    setTaggedView(false);
    searchValue ?  props.searchWithString({search:searchValue}) :  
    isCheckBackView ? props.getRequirement() :  props.getAllRequirement();
    
  };

  return (
    <div className={shrink}>
      {!isOpen && (
        <Container className="card container-card">
      
          {Object.keys(currentPermission).length > 0 &&
            currentPermission.creation && (
              <div className="dive">
                {!isOpen && !viewShow && (
                  <CustTooltip title="Create Requirement">
                    <button
                      className="btn new-btn white-text label-sm"
                      onClick={toggleAddBtn}
                    >
                      Add
                    </button>
                  </CustTooltip>
                )}
              </div>
            )}
          
       
          
        
          {!isOpen && !viewShow && (
            <Searchbar
             isCheckBackView ={isCheckBackView}
              isSearchbar={true}
              handleSelect={handleAllReqClick}
              onChange={searchChange}
              value={searchValue}
              placeholder="Search.."
              onSubmit={searchClick}
              title="Search"
              onKeyPress={(e) => {
                if (e.code === "Enter") {
                  searchClick();
                }
              }}
              error={changeError}
              isAvailable={isAvailable}
            />
          )}
            
           
            

         

        

          {!isOpen && !viewShow ? (
            <Row className="pt-3 cust-head">
              <Col md="12" className="p-0">
                <ul className="crm-flex-around  ul-head">
                  <li
                    style={{
                      width: "8%",
                      fontSize: ".875rem",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    Req. Id
                  </li>
                  <li
                    style={{
                      width: "12%",
                      fontSize: ".875rem",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    Cust. Name
                  </li>
                  <li
                    style={{
                      width: "10%",
                      fontSize: ".875rem",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    {" "}
                    TAC Name
                  </li>

                  <li
                    style={{
                      width: "12%",
                      fontSize: ".875rem",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    {" "}
                    Job Position
                  </li>
                  <li
                    style={{
                      width: "20%",
                      fontSize: ".875rem",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    {" "}
                    Primary Skills
                  </li>
                  <li
                    style={{
                      width: "11%",
                      fontSize: ".875rem",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    {" "}
                    Yrs. of Exp.
                  </li>

                  <li
                    style={{
                      width: "9%",
                      fontSize: ".875rem",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    {" "}
                    Location
                  </li>
                  <li
                    style={{
                      width: "10%",
                      fontSize: ".875rem",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    {" "}
                    Req. Status
                  </li>
                  <li
                    style={{
                      width: "10%",
                      fontSize: ".875rem",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    {" "}
                    Candidates
                  </li>
                </ul>
              </Col>
            </Row>
          ) : null}
          {!isOpen && !viewShow ? (
            <Row style={{ marginTop: "1rem" }} className="pt-3">
              {props.requirements.data.length > 0 &&
                props.requirements.data.map((item) => {
                  return (
                    <Col md="12" className="p-0" key={item.resReqId}>
                      <ul
                        // onClick={() => handleViewClick(item.id)}
                        className="rs-flex-around"
                        // onMouseEnter={OnMouseCandihover}
                        // onClick={() => {
                        //   select(item);
                        // }}

                        // onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                        // onMouseLeave={this.toggleHoverOff}
                      >
                        <li
                          className="list-crm-table-item pad-rs-name id-cursor "
                          onClick={() => handleViewClick(item.id)}
                        >
                          {item.id}
                        </li>
                        <li
                          style={{ overflow: "hidden", width: "12%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.custName}
                        </li>

                        <li
                          style={{ overflow: "hidden", width: "10%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.tacName}
                        </li>

                        <li
                          style={{ overflow: "hidden", width: "12%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.jobPosition}
                        </li>

                        <li
                          style={{ overflow: "hidden", width: "20%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.primarySkills}
                        </li>
                        <li
                          style={{ overflow: "hidden", width: "11%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.minYearsOfExp}
                        </li>
                        <li
                          style={{ overflow: "hidden", width: "9%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.jobLocation}
                        </li>
                        <li
                          style={{ overflow: "hidden", width: "10%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.reqStatus}
                        </li>
                        <li
                          className="list-crm-table-item pad-rs-name  cancount-cursor"
                          onClick={() => {
                            setTaggedView(true);
                            setReqId(item.id);
                            
                          }}
                        >
                          {item.candidateTracking?.length}
                        </li>
                      </ul>
                    </Col>
                  );
                })}
            </Row>
          ) : null}

          {/* {!isOpen && !viewShow
            ? dataar.length > 0 &&
              dataar.map((item) => {
                return (
                  <Col md="12" className="p-0" key={item.resReqId}>
                    <ul
                      onClick={() => handleViewClick(item.id)}
                      className="rs-flex-around"
                  
                    >
                      <li
                        style={{ width: "10%" , paddingLeft: "2%" }}
                        className="list-crm-table-item pad-rs-name"
                      >
                        {item.id}
                      </li>
                      <li
                         style={{overflow:"hidden", width: "12%"}}
                        className="list-crm-table-item pad-rs-name"
                      >
                        {item.custName}
                      </li>

          {!isOpen && !viewShow ? (
            <Row className="pt-3 cust-head">
              <Col md="12" className="p-0">
                <ul className="crm-flex-around  ul-head">
                  <li
                    style={{
                      width: "8%",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    Req. Id
                  </li>
                  <li
                    style={{
                      width: "15%",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    Cust. Name
                  </li>
                  <li
                    style={{
                      width: "15%",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    {" "}
                    TAC Name
                  </li>

                  <li
                    style={{
                      width: "10%",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    {" "}
                    Position
                  </li>
                  <li
                    style={{
                      width: "20%",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    {" "}
                    Primary Skills
                  </li>
                  <li
                    style={{
                      width: "10%",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    {" "}
                    Yrs. of Exp.
                  </li>

                  <li
                    style={{ width: "8%", fontSize: "14px", fontWeight: "500" }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    {" "}
                    Location
                  </li>
                  <li
                    style={{
                      width: "10%",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    {" "}
                    Candidates
                  </li>
                </ul>
              </Col>
            </Row>
          ) : null}
          <Row style={{ marginTop: "1rem" }} className="pt-3">
            {!isOpen && !viewShow
              ? fullData.length > 0 &&
                fullData.map((item) => {
                  return (
                    <Col md="12" className="p-0" key={item.resReqId}>
                      <ul
                        onClick={() => handleViewClick(item.id)}
                        className="rs-flex-around"
                        // onMouseEnter={OnMouseCandihover}
                        // onClick={() => {
                        //   select(item);
                        // }}

                        // onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                        // onMouseLeave={this.toggleHoverOff}
                      >
                        <li
                          style={{ width: "8%", paddingLeft: "2%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.id}
                        </li>
                        <li
                          style={{ overflow: "hidden", width: "15%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.custName}
                        </li>

                        <li
                          style={{ overflow: "hidden", width: "15%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.tacName}
                        </li>

                        <li
                          style={{ overflow: "hidden", width: "10%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.jobPosition}
                        </li>

                        <li
                          style={{ overflow: "hidden", width: "20%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.primarySkills}
                        </li>
                        <li
                          style={{ overflow: "hidden", width: "10%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.minYearsOfExp}
                        </li>
                        <li
                          style={{ overflow: "hidden", width: "8%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.jobLocation}
                        </li>
                        <li
                          style={{ overflow: "hidden", width: "10%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.JobDescription}
                        </li>
                      </ul>
                    </Col>
                  );
                })
              : null}

            {!isOpen && !viewShow
              ? dataar.length > 0 &&
                dataar.map((item) => {
                  return (
                    <Col md="12" className="p-0" key={item.resReqId}>
                      <ul
                        onClick={() => handleViewClick(item.id)}
                        className="rs-flex-around"
                        // onMouseEnter={OnMouseCandihover}
                        // onClick={() => {
                        //   select(item);
                        // }}

                        // onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                        // onMouseLeave={this.toggleHoverOff}
                      >
                        <li
                          style={{ width: "10%", paddingLeft: "2%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.id}
                        </li>
                        <li
                          style={{ overflow: "hidden", width: "12%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.custName}
                        </li>

                        <li
                          style={{ overflow: "hidden", width: "12%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.tacName}
                        </li>

                        <li
                          style={{ overflow: "hidden", width: "10%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.jobReqType}
                        </li>

                        <li
                          style={{ overflow: "hidden", width: "28%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.skillSets}
                        </li>
                        <li
                          style={{ overflow: "hidden", width: "10%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.minYearsOfExp}
                        </li>
                        <li
                          style={{ overflow: "hidden", width: "8%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.jobLocation}
                        </li>
                        <li
                          style={{ overflow: "hidden", width: "10%" }}
                          className="list-crm-table-item pad-rs-name"
                        >
                          {item.JobDescription}
                        </li>
                      </ul>
                    </Col>
                  );
                })
              : null}

                      <li
                        style={{overflow:"hidden",width: "28%"}}
                        className="list-crm-table-item pad-rs-name"
                      >
                        {item.skillSets}
                      </li>
                      <li
                     style={{overflow:"hidden",width:"10%"}}
                        className="list-crm-table-item pad-rs-name"
                      >
                        {item.minYearsOfExp}
                      </li>
                      <li
                         style={{overflow:"hidden",width:"8%"}}
                        className="list-crm-table-item pad-rs-name"
                      >
                        {item.jobLocation}
                      </li>
                      <li
                         style={{overflow:"hidden",width:"10%"}}
                        className="list-crm-table-item pad-rs-name"
                      >
                        {item.JobDescription}
                      </li>
                    </ul>
                  </Col>
                );
              })
            : null} */}
          {/* </Row> */}

          {viewShow && (
            <ViewRequirement
              requirementId={requirementId}
              isToggleView={isToggleView}
              toggle={toggleAddBtn}
              permission={currentPermission}
            />
          )}
          {props.requirements.message ? (
            <Notifier message={props.requirements.message} />
          ) : null}
          {props.requirements.error ? (
            <ErrorNotifier message={props.requirements.error} />
          ) : null}
        </Container>
      )}
      {isOpen && <AddRequirement toggle={toggleAddBtn} />}
      {taggedView && (
        <CandidateKanban
          reqId={reqId}
          taggedView={taggedView}
          closeModel={handleCloseTagedModal}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  requirements: state.RSrequirement,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getRequirement,
  getAllRequirement,
  searchWithString,
  ReqsearchWithId,
})(Requirement);
