import React, { Component } from 'react'
import { Modal, Button } from "react-bootstrap";
import { Row, Col, Card } from "reactstrap";
import { connect } from "react-redux";
import axios from "axios";
import ViewUploadedmessageRs from "../../../../common/viewUploadedmessageRs";
import DateFns from "date-fns";


class ViewUploadedPopUp extends Component {
    constructor(props){
    super(props)
    this.state = {

    }    
    }
    render() {
      
        return (
            <div>
    <Modal show={this.props.popUp} size="xl" >
    
    <div>
      <div>
        <i
          data-toggle="tooltip"
          data-placement="top"
          title="Close"
          onClick={this.props.closeModel}
          className="material-icons pointer cancel-button"
        >
          cancel
        </i>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <Modal.Title>View Uploaded Data</Modal.Title>
      </div>

      <Modal.Body>

      <ViewUploadedmessageRs viewData ={this.props.viewData}/>
      </Modal.Body>
      <Modal.Footer>
    
      </Modal.Footer>
   
    </div>
   


</Modal>
                
            </div>
        )
    }
}




const mapStateToProps = (state) => ({
  requirements: state.requirements,
});

export default connect(mapStateToProps)(
    ViewUploadedPopUp
);
