import React, { PureComponent } from "react";
import { Card, Table } from "reactstrap";
import axios from "axios";
import { GET_INQUIRY_CONVERT_LEAD } from "../../../../utils/routes";

// const data = [
//   {
//     Month: "Jun",
//     Inquiries: 10,
//     ConvertedInquiries: 5,
//   },
//   {
//     Month: "May",
//     Inquiries: 8,
//     ConvertedInquiries: 4,
//   },
//   {
//     Month: "Apr",
//     Inquiries: 6,
//     ConvertedInquiries: 3,
//   },
//   {
//     Month: "March",
//     Inquiries: 8,
//     ConvertedInquiries: 6,
//   },
//   {
//     Month: "Feb",
//     Inquiries: 10,
//     ConvertedInquiries: 7,
//   },
// ];

export default class InquiryConvertedToLead extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      error: "",
    };
  }

  componentDidMount() {
    axios
      .get(GET_INQUIRY_CONVERT_LEAD, { withCredentials: true })
      .then((res) => {
        this.setState({
          data: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          error: err,
        });
      });
  }

  render() {
    const { data } = this.state;

    return (
      <Card
        className="recharts-card center"
        style={{ height: "150px", padding: "5px" }}
      >
        <Table hover>
          <thead>
            <tr>
              <th
                style={{
                  padding: "0 0 0 5px",
                  fontWeight: "600",
                  fontSize: "12px",
                  textAlign: "left",
                }}
              >
                Month
              </th>
              <th
                style={{ padding: "0px", fontWeight: "600", fontSize: "12px" }}
              >
                Inquiries
              </th>
              <th
                style={{ padding: "0px", fontWeight: "600", fontSize: "12px" }}
              >
                Converted Inquiries
              </th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 && data.map((item) => {
              return (
                <tr>
                  <td
                    style={{
                      padding: "0 0 0 5px",
                      fontSize: "12px",
                      fontWeight: "400",
                      textAlign: "left",
                    }}
                  >
                    {item.Month}
                  </td>
                  <td
                    style={{
                      padding: "0px",
                      fontSize: "12px",
                      fontWeight: "400",
                    }}
                  >
                    {item.Inquiries}
                  </td>
                  <td
                    style={{
                      padding: "0px",
                      fontSize: "12px",
                      fontWeight: "400",
                    }}
                  >
                    {item.ConvertedInquiries}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>
    );
  }
}
