import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DateFns from "date-fns";
import {
  Table,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
// components
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import revokeImage from "../../../../../assets/img/revoke.png";
import FormComponent from "../../../../common/FormComponent";
import {
  Validator,
  submitValidator,
  valid,
  errors,
} from "../../../../common/Validator";
// redux
import { getUserRevokeReason } from "../../../../../redux/actions/hcm/configurations/revokeReasonAction";
import {
  updateExitClearance,
  fetchExitClearance,
} from "../../../../../redux/actions/hcm/resignation/exitClearanceAction";

// ------------------------------------------------------------------------------------------------------------
class ResignationStatusCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      revoke: false,
      revokeReason: "",
      revokeRemarks: "",
      message: "",
      error: "",
    };
    this.toggleRevoke = this.toggleRevoke.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getData = this.getData.bind(this);
  }

  getData() {
    this.props.fetchExitClearance();
  }

  componentDidMount() {
    this.getData();
    this.props.getUserRevokeReason();
  }

  /* toggleRevoke function for the toggle the revoke modal page */
  toggleRevoke() {
    this.setState({
      revoke: !this.state.revoke,
    });
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    const { data } = this.state;
    e.preventDefault();
    submitValidator(e.target);
    let revokeData = {
      id: data[0].exitClearId,
      revokeReason: this.state.revokeReason,
      revokeRemarks: this.state.revokeRemarks,
      empId: data[0].empId,
      empName: data[0].empName,
      adminClearanceStatus: data[0].adminClearanceStatus,
      assetClearanceStatus: data[0].assetClearanceStatus,
      financeClearanceStatus: data[0].financeClearanceStatus,
      managerClearanceStatus: data[0].managerClearanceStatus,
      hrClearanceStatus: data[0].hrClearanceStatus,
      regDate: data[0].regDate,
      regReason: data[0].regReason,
      lastWorkingDate: data[0].lastWorkingDate,
      regStatus: "Revoked",
      updatedBy: sessionStorage.getItem("id"),
    };
    if (valid) {
      this.props.updateExitClearance(data, this.toggleRevoke);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  render() {
    let shrink = this.props.sidebar ? "scale" : "no-scale";

    return (
      <div style={{ position: "relative" }} className={shrink}>
        <Container className="card container-card pt-2 personalDetails-div-1">
          <h5 className="bold mb-4 pt-2 personal-head">
            Resignation Status Check
          </h5>
          <Link
            to={{
              pathname: "/pie/iam/service_menu",
              moduleName: this.props.location.moduleName,
              data: this.props.location.data,
            }}
          >
            <i className="material-icons cancel-button pointer">cancel</i>
          </Link>
          {this.props.exitClearance.fetchData.length > 0 && (
            <div
              className="revoke-img center pointer"
              onClick={this.toggleRevoke}
            >
              <img src={revokeImage} alt="revoke" title="revoke" />
              <p>Revoke</p>
            </div>
          )}
          <div className="personal-details-div">
            <Row>
              {/* <Col md="6"> */}
              <ul>
                <li>
                  <span className="label-sm bold resignation-span-col-1">
                    Resignation Date
                  </span>
                  <span className="label-sm">
                    &nbsp;&nbsp;:&nbsp;&nbsp;
                    {!(
                      Object.keys(this.props.exitClearance.fetchData).length ===
                        0 &&
                      this.props.exitClearance.fetchData.constructor === Object
                    ) &&
                      DateFns.format(
                        this.props.exitClearance.fetchData.regDate,
                        "DD-MM-YYYY"
                      )}
                  </span>
                </li>
                <li>
                  <span className="label-sm bold resignation-span-col-1">
                    Last Working Date
                  </span>
                  <span className="label-sm">
                    &nbsp;&nbsp;:&nbsp;&nbsp;
                    {!(
                      Object.keys(this.props.exitClearance.fetchData).length ===
                        0 &&
                      this.props.exitClearance.fetchData.constructor === Object
                    ) &&
                      !(
                        this.props.exitClearance.fetchData === "empId not exist"
                      ) &&
                      DateFns.format(
                        this.props.exitClearance.fetchData.lastWorkingDate,
                        "DD-MM-YYYY"
                      )}
                  </span>
                </li>
                <li>
                  <span className="label-sm bold resignation-span-col-1">
                    Resignation Status
                  </span>
                  <span className="label-sm">
                    &nbsp;&nbsp;:&nbsp;&nbsp;
                    {!(
                      Object.keys(this.props.exitClearance.fetchData).length ===
                        0 &&
                      this.props.exitClearance.fetchData.constructor === Object
                    ) && this.props.exitClearance.fetchData.regStatus}
                  </span>
                </li>
                <li>
                  <span className="label-sm bold resignation-span-col-1">
                    Admin Clearance Status
                  </span>
                  <span className="label-sm">
                    &nbsp;&nbsp;:&nbsp;&nbsp;
                    {!(
                      Object.keys(this.props.exitClearance.fetchData).length ===
                        0 &&
                      this.props.exitClearance.fetchData.constructor === Object
                    ) &&
                      this.props.exitClearance.fetchData.adminClearanceStatus}
                  </span>
                </li>
                <li>
                  <span className="label-sm bold resignation-span-col-1">
                    Asset Clearance Status
                  </span>
                  <span className="label-sm">
                    &nbsp;&nbsp;:&nbsp;&nbsp;
                    {!(
                      Object.keys(this.props.exitClearance.fetchData).length ===
                        0 &&
                      this.props.exitClearance.fetchData.constructor === Object
                    ) &&
                      this.props.exitClearance.fetchData.assetClearanceStatus}
                  </span>
                </li>
                <li>
                  <span className="label-sm bold resignation-span-col-1">
                    Finanace Clearance Status
                  </span>
                  <span className="label-sm">
                    &nbsp;&nbsp;:&nbsp;&nbsp;
                    {!(
                      Object.keys(this.props.exitClearance.fetchData).length ===
                        0 &&
                      this.props.exitClearance.fetchData.constructor === Object
                    ) &&
                      this.props.exitClearance.fetchData.financeClearanceStatus}
                  </span>
                </li>
                <li>
                  <span className="label-sm bold resignation-span-col-1">
                    Manager Clearance Status
                  </span>
                  <span className="label-sm">
                    &nbsp;&nbsp;:&nbsp;&nbsp;
                    {!(
                      Object.keys(this.props.exitClearance.fetchData).length ===
                        0 &&
                      this.props.exitClearance.fetchData.constructor === Object
                    ) &&
                      this.props.exitClearance.fetchData.managerClearanceStatus}
                  </span>
                </li>
                <li>
                  <span className="label-sm bold resignation-span-col-1">
                    HR Clearance Status
                  </span>
                  <span className="label-sm">
                    &nbsp;&nbsp;:&nbsp;&nbsp;
                    {!(
                      Object.keys(this.props.exitClearance.fetchData).length ===
                        0 &&
                      this.props.exitClearance.fetchData.constructor === Object
                    ) && this.props.exitClearance.fetchData.hrClearanceStatus}
                  </span>
                </li>
              </ul>
              {/* </Col> */}
            </Row>
          </div>
          {this.props.exitClearance.message && (
            <Notifier message={this.props.exitClearance.message} />
          )}
          {this.props.exitClearance.error && (
            <ErrorNotifier message={this.props.exitClearance.error} />
          )}
        </Container>
        <Modal isOpen={this.state.revoke}>
          <ModalHeader toggle={this.toggleRevoke}>
            Revoke Resignation
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={this.onSubmit} noValidate>
              <FormGroup>
                <Label className="bold label-sm">Revoke Reason</Label>
                <Input
                  className="form-control-sm"
                  type="select"
                  name="revokeReason"
                  onChange={this.onChange}
                  value={this.state.revokeReason}
                  required={true}
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {this.props.revokeReason.data.map((item) => {
                    return (
                      <option key={item.id} value={item.revokeReasonName}>
                        {item.revokeReasonName}
                      </option>
                    );
                  })}
                </Input>
                {errors.revokeReason && (
                  <FormText className="error">{errors.revokeReason}</FormText>
                )}
              </FormGroup>
              <FormComponent
                label="Revoke Remarks"
                labelClass="bold label-sm"
                inputClass="form-control-sm"
                type="textarea"
                name="revokeRemarks"
                change={this.onChange}
                value={this.state.revokeRemarks}
                required={true}
              />
              <div className="center">
                <button type="submit" className=" btn login-button">
                  Submit
                </button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

ResignationStatusCheck.propTypes = {
  revokeReason: PropTypes.object.isRequired,
  getUserRevokeReason: PropTypes.func.isRequired,
  fetchExitClearance: PropTypes.func.isRequired,
  updateExitClearance: PropTypes.func.isRequired,
  exitClearance: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  exitClearance: state.exitClearance,
  revokeReason: state.revokeReason,
});

export default connect(mapStateToProps, {
  getUserRevokeReason,
  fetchExitClearance,
  updateExitClearance,
})(ResignationStatusCheck);
