import axios from "axios";
import { EMP_QUALIFICATION_GET } from "../../types";
import {
  CURD_EMP_QUALIFICATION,
  USER_EMP_QUALIFICATION,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addEmpQualification = (data, back) => (dispatch) => {
  axios
    .post(CURD_EMP_QUALIFICATION, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getEmpQualification));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getEmpQualification = () => (dispatch) => {
  axios
    .get(CURD_EMP_QUALIFICATION, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(EMP_QUALIFICATION_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserEmpQualification = () => (dispatch) => {
  axios
    .get(USER_EMP_QUALIFICATION, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(EMP_QUALIFICATION_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateEmpQualification = (data, back) => (dispatch) => {
  axios
    .put(CURD_EMP_QUALIFICATION, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getEmpQualification));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteEmpQualification = (data) => (dispatch) => {
  axios
    .delete(CURD_EMP_QUALIFICATION, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getEmpQualification));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
