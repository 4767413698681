import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
} from "reactstrap";
import {
  getServices,
  updateService,
  searchService,
} from "../../../../redux/actions/serviceAction";
import {
  refresh,
  submitValidator,
  Validator,
  valid,
} from "../../../common/Validator";
import ServiceDetails from "./ServiceDetails";
import UpdateService from "./UpdateService";
import Hover from "../../../aside/Hover";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/Notifier";
import FormComponent from "../../../common/FormComponent";

class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      permissionlist: this.props.auth.permissionlist || {},
      selection: null,
      selected: {},
      hover: false,
      cords: {},
      mouse: {},
      searchItem: "",
      update: false,
      popup: false,
      subItem: {},
      modal: false,
      remarks: "",
      message: null,
      error: null,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.select = this.select.bind(this);
    this.back = this.back.bind(this);
    this.getData = this.getData.bind(this);
    this.search = this.search.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.beforeUpdate = this.beforeUpdate.bind(this);
    this.afterUpdate = this.afterUpdate.bind(this);
    this.toggleHoverOn = this.toggleHoverOn.bind(this);
    this.toggleHoverOff = this.toggleHoverOff.bind(this);
    this.toggle1 = this.toggle1.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.services !== this.props.services) {
      this.setState({
        data: this.props.services.data,
      });
    }
    if (prevProps.auth !== this.props.auth) {
      this.setState({
        permissionlist: this.props.auth.permissionlist,
      });
    }
  }

  search(e) {
    e.preventDefault();
    this.setState({
      searchTerm: e.target.value,
    });
    this.onSearch(e.target.value);
  }

  onSearch(value) {
    let data = {};
    let isInteger = value.length && /^[0-9]*$/.test(value);
    if (isInteger) {
      data.serviceId = Number(value);
      data.serviceName = "Null";
    } else {
      data.serviceId = 0;
      data.serviceName = value;
    }
    this.props.searchService(data);
  }

  getData() {
    this.props.getServices();
  }

  componentDidMount() {
    this.getData();
    this.setState({
      permissionlist: this.props.auth.permissionlist,
    });
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      remarks: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    const data = this.state.data.filter((item) => {
      if (item.serviceId === this.state.subItem) {
        return item;
      } else {
        return null;
      }
    });
    let data1 = {
      serviceId: data[0].serviceId,
      companyName: data[0].compName,
      serviceName: data[0].serviceName,
      plannedStart: data[0].plannedStart,
      plannedEnd: data[0].plannedEnd,
      actualStart: data[0].actualStart,
      actualEnd: data[0].actualEnd,
      effort: data[0].effort,
      budget: data[0].budget,
      currency: data[0].currency,
      location: data[0].location,
      status: data[0].status,
      description: data[0].description,
      serviceCustSpoc: data[0].serviceCustSpoc,
      serviceSpocContactNo: data[0].serviceSpocContactNo,
      serviceSpocEmail: data[0].serviceSpocEmail,
      resources: data[0].resources,
      servicePmName: data[0].servicePmName,
      serviceType: data[0].serviceType,
      billingType: data[0].billType,
      comment: this.state.remarks,
    };
    if (valid) {
      this.props.updateService(data1);
      setTimeout(() => {
        this.toggle();
      }, 2000);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  toggleHoverOn(e, item) {
    this.setState({
      hover: true,
      cords: {
        one: item.serviceId,
        two: item.companyName,
        three: item.description,
      },
      mouse: {
        x: e.screenX,
        y: e.screenY,
      },
    });
  }

  toggleHoverOff() {
    this.setState({
      hover: false,
    });
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  /*
Toggle1 function for the modal cancel button here called the getData function
*/
  toggle1() {
    this.setState({
      modal: !this.state.modal,
    });
    this.getData();
  }

  onDragOver(e) {
    e.preventDefault();
  }

  select(item) {
    this.setState({
      selection: item,
      selected: item.serviceId,
      popup: true,
    });
  }

  onDragStart(e, item) {
    e.dataTransfer.setData("item", item.serviceId);
    this.setState({
      subItem: item.serviceId,
    });
  }

  async onDrop(e, cat) {
    let id = e.dataTransfer.getData("item");
    let newData;
    newData = this.state.data.filter((item) => {
      if (item.serviceId === parseInt(id, 10)) {
        item.status = cat;
      }
      return newData;
    });
    this.setState({
      ...this.state,
      newData,
    });
    await this.toggle();
  }

  beforeUpdate() {
    this.setState({
      update: true,
    });
  }

  afterUpdate() {
    this.setState({
      update: false,
    });
    this.getData();
    refresh();
  }

  back() {
    this.setState({
      popup: false,
    });
  }

  render() {
    let shrink;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");
    const {
      data,
      selection,
      selected,
      hover,
      cords,
      modal,
      popup,
      remarks,
      update,
      error,
      permissionlist,
    } = this.state;
    let currentPermission;

    for (let x in permissionlist.response.permissions) {
      if (permissionlist.response.permissions[x].groupId === "3.4") {
        currentPermission = permissionlist.response.permissions[x];
      }
    }

    return (
      <div className={shrink}>
        {popup && (
          <ServiceDetails
            detail={selection}
            update={this.beforeUpdate}
            back={this.back}
          />
        )}
        <Modal isOpen={modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle1}>Confirm changes?</ModalHeader>
          <ModalBody>
            <Form className="p-3" onSubmit={this.onSubmit} noValidate>
              <FormComponent
                labelClass="bold label-sm"
                label="Remarks"
                type="text"
                name="remarks"
                change={this.onChange}
                value={remarks}
                required={true}
              />
              <FormGroup className="center">
                <button type="submit" className="btn login-button white-text">
                  Submit
                </button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
        {!update ? (
          <Container className="card container-card">
            <input
              type="text"
              onChange={this.search}
              value={this.state.searchTerm || ""}
              className="searchfield form-control-sm"
              placeholder="ServiceId/Name"
            />
            {hover && (
              <Hover
                cords={cords}
                labels={{
                  one: "Service Id",
                  two: "Customer Name",
                  three: "Description",
                }}
                mouse={this.state.mouse}
              />
            )}
            {currentPermission.create1 ? (
              <div>
                <Link to="/pie/pps/add/service">
                  <button className=" btn add-button white-text label-sm">
                    Add
                  </button>
                </Link>
              </div>
            ) : null}
            <h5 className="bold pointer center pt-2">Services</h5>
            <Row>
              <Col
                md="4"
                onDragOver={this.onDragOver}
                onDrop={(e) => this.onDrop(e, "New")}
                className="card p-2"
              >
                <h6 className="center bold m-2">New</h6>
                <ul className="list flex-around  InqList">
                  <li className="list-item bold flex-1">ServiceName</li>
                  <li className="list-item bold flex-1">Location</li>
                </ul>
                {data &&
                  data.map((item) => {
                    if (item.status === "New") {
                      return (
                        <ul
                          key={item.serviceId}
                          onClick={() => this.select(item)}
                          draggable
                          onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                          onMouseLeave={this.toggleHoverOff}
                          onDragStart={(e) => this.onDragStart(e, item)}
                          className={`list flex-around pointer ${
                            selected &&
                            selected === item.serviceId &&
                            "selected-item"
                          } InqList`}
                        >
                          <li className="list-table-item flex-1">
                            {item.serviceName}
                          </li>
                          <li className="list-table-item flex-1">
                            {item.location}
                          </li>
                        </ul>
                      );
                    } else {
                      return null;
                    }
                  })}
              </Col>
              <Col
                md="4"
                onDragOver={this.onDragOver}
                onDrop={(e) => this.onDrop(e, "WIP")}
                className="card p-2"
              >
                <h6 className="center bold m-2">Work in progress</h6>
                <ul className="list flex-around  InqList">
                  <li className="list-item bold flex-1">ServiceName</li>
                  <li className="list-item bold flex-1">Location</li>
                </ul>
                {data &&
                  data.map((item) => {
                    if (item.status === "WIP") {
                      return (
                        <ul
                          key={item.serviceId}
                          onClick={() => this.select(item)}
                          draggable
                          onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                          onMouseLeave={this.toggleHoverOff}
                          onDragStart={(e) => this.onDragStart(e, item)}
                          className={`list flex-around pointer ${
                            selected &&
                            selected === item.serviceId &&
                            "selected-item"
                          } InqList`}
                        >
                          <li className="list-table-item flex-1">
                            {item.serviceName}
                          </li>
                          <li className="list-table-item flex-1">
                            {item.location}
                          </li>
                        </ul>
                      );
                    } else {
                      return null;
                    }
                  })}
              </Col>
              <Col
                md="4"
                className="card p-2"
                onDrop={(e) => this.onDrop(e, "Complete")}
                onDragOver={this.onDragOver}
              >
                <h6 className="center bold m-2">Others</h6>
                <ul className="list flex-around  InqList">
                  <li className="list-item bold flex-1">ServiceName</li>
                  <li className="list-item bold flex-1">Location</li>
                </ul>
                {data &&
                  data.map((item) => {
                    if (item.status !== "New" && item.status !== "WIP") {
                      return (
                        <ul
                          key={item.serviceId}
                          onClick={() => this.select(item)}
                          draggable
                          onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                          onMouseLeave={this.toggleHoverOff}
                          onDragStart={(e) => this.onDragStart(e, item)}
                          className={`list flex-around pointer ${
                            selected &&
                            selected === item.serviceId &&
                            "selected-item"
                          } InqList`}
                        >
                          <li className="list-table-item flex-1">
                            {item.serviceName}
                          </li>
                          <li className="list-table-item flex-1">
                            {item.location}
                          </li>
                        </ul>
                      );
                    } else {
                      return null;
                    }
                  })}
              </Col>
            </Row>
          </Container>
        ) : (
          <UpdateService item={selection} back={this.afterUpdate} />
        )}
        {this.props.services.message ? (
          <Notifier message={this.props.services.message} />
        ) : null}
        {error ? <ErrorNotifier message={error} /> : null}
      </div>
    );
  }
}

Services.propTypes = {
  getServices: PropTypes.func.isRequired,
  updateService: PropTypes.func.isRequired,
  searchService: PropTypes.func.isRequired,
  services: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  services: state.services,
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getServices,
  updateService,
  searchService,
})(Services);
