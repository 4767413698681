import React, { Component } from "react";
import {
  Row,
  Col,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  Container,
} from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import Notifier from "../../../../aside/Notifier";
import {
  fetchApplicationAccessPermission,
  updateApplicationAccessPermission,
  deleteApplicationAccessPermission,
} from "../../../../../redux/actions/iam/auth_configuration/access_and_permission/applicationAccessAndPermissionAction";
import {
  fetchModuleAccessPermission,
  updateModuleAccessPermission,
  deleteModuleAccessPermission,
} from "../../../../../redux/actions/iam/auth_configuration/access_and_permission/moduleAccessAndPermission";
import {
  fetchServiceAccessPermission,
  updateServiceAccessPermission,
  deleteServiceAccessPermission,
} from "../../../../../redux/actions/iam/auth_configuration/access_and_permission/serviceAccessAndPermission";

class AccessAndPermissionDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accessApplication: [],
      accessModule: [],
      accessService: [],
      updateAppAccModal: false,
      accAppId: "",
      appGroupId: "",
      appName: "",
      appIcon: "",
      appAccess: false,
      updateModuleAccModal: false,
      accModuleId: "",
      moduleGroupId: "",
      moduleName: "",
      moduleAccess: false,
      updateServiceAccModal: false,
      accServiceId: "",
      serviceGroupId: "",
      serviceName: "",
      serviceAccess: false,
      serviceCreate: false,
      serviceView: false,
      serviceUpdate: false,
      serviceDelete: false,
      error: "",
      message: "",
    };

    this.getAppAccessDetails = this.getAppAccessDetails.bind(this);
    this.getModuleAccessDetails = this.getModuleAccessDetails.bind(this);
    this.getServiceAccessDetails = this.getServiceAccessDetails.bind(this);
    this.updateAppAccess = this.updateAppAccess.bind(this);
    this.appOnChange = this.appOnChange.bind(this);
    this.onSubmitAppAccess = this.onSubmitAppAccess.bind(this);
    this.updateAppAccesstoggle = this.updateAppAccesstoggle.bind(this);
    this.deleteAppAccess = this.deleteAppAccess.bind(this);
    this.updateModuleAccess = this.updateModuleAccess.bind(this);
    this.moduleOnChange = this.moduleOnChange.bind(this);
    this.onSubmitModuleAccess = this.onSubmitModuleAccess.bind(this);
    this.updateModuleAccesstoggle = this.updateModuleAccesstoggle.bind(this);
    this.deleteModuleAccess = this.deleteModuleAccess.bind(this);
    this.updateServiceAccess = this.updateServiceAccess.bind(this);
    this.serviceOnChange = this.serviceOnChange.bind(this);
    this.onSubmitServiceAccess = this.onSubmitServiceAccess.bind(this);
    this.updateServiceAccesstoggle = this.updateServiceAccesstoggle.bind(this);
    this.deleteServiceAccess = this.deleteServiceAccess.bind(this);
  }

  componentDidMount() {
    this.getAppAccessDetails();
    this.getModuleAccessDetails();
    this.getServiceAccessDetails();
  }

  getAppAccessDetails() {
    this.props.fetchApplicationAccessPermission(this.props.roleCode);
  }

  getModuleAccessDetails() {
    this.props.fetchModuleAccessPermission(this.props.roleCode);
  }

  getServiceAccessDetails() {
    this.props.fetchServiceAccessPermission(this.props.roleCode);
  }

  updateAppAccess(e, item) {
    this.setState({
      updateAppAccModal: true,
      accAppId: item.id,
      appGroupId: item.appGroupId,
      appName: item.appName,
      appIcon: item.appIcon,
      appAccess: item.access,
    });
  }

  updateAppAccesstoggle() {
    this.setState({
      updateAppAccModal: false,
    });

    this.getAppAccessDetails();
    this.getModuleAccessDetails();
    this.getServiceAccessDetails();
  }

  appOnChange(e) {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  }

  onSubmitAppAccess() {
    let data = {
      id: this.state.accAppId,
      roleId: this.props.roleId,
      appGroupId: this.state.appGroupId,
      access: this.state.appAccess,
      appName: this.state.appName,
      appIcon: this.state.appIcon,
    };

    this.props.updateApplicationAccessPermission(
      data,
      this.updateAppAccesstoggle
    );
  }

  deleteAppAccess(id) {
    let data = {
      accMainId: id,
    };

    this.props.deleteApplicationAccessPermission(data);
  }

  updateModuleAccess(e, item) {
    this.setState({
      updateModuleAccModal: true,
      accModuleId: item.id,
      moduleGroupId: item.moduleGroupId,
      moduleName: item.moduleName,
      moduleAccess: item.access,
    });
  }

  updateModuleAccesstoggle() {
    this.setState({
      updateModuleAccModal: false,
    });

    this.getAppAccessDetails();
    this.getModuleAccessDetails();
    this.getServiceAccessDetails();
  }

  moduleOnChange(e) {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  }

  onSubmitModuleAccess() {
    let data = {
      id: this.state.accModuleId,
      roleId: this.props.roleId,
      menuId: this.state.moduleGroupId,
      menuName: this.state.moduleName,
      access: this.state.moduleAccess,
    };

    this.props.updateModuleAccessPermission(
      data,
      this.updateModuleAccesstoggle
    );
  }

  deleteModuleAccess() {
    let data = {
      accMainId: 1,
    };

    this.props.deleteModuleAccessPermission(data);
  }

  /*
functions for service
*/
  updateServiceAccess(e, item) {
    this.setState({
      updateServiceAccModal: true,
      accServiceId: item.id,
      serviceGroupId: item.serviceGroupId,
      serviceName: item.serviceName,
      serviceAccess: item.access,
      serviceCreate: item.creation,
      serviceView: item.view1,
      serviceUpdate: item.updation,
      serviceDelete: item.deletion,
    });
  }

  updateServiceAccesstoggle() {
    this.setState({
      updateServiceAccModal: false,
    });

    this.getAppAccessDetails();
    this.getModuleAccessDetails();
    this.getServiceAccessDetails();
  }

  serviceOnChange(e) {
    if (e.target.name === "serviceAccess") {
      if (e.target.checked) {
        this.setState({
          serviceAccess: true,
        });
      } else {
        this.setState({
          serviceAccess: false,
          serviceView: false,
          serviceCreate: false,
          serviceUpdate: false,
          serviceDelete: false,
        });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.checked,
      });
    }
  }

  onSubmitServiceAccess() {
    let data = {
      id: this.state.accServiceId,
      roleId: this.props.roleId,
      subMenuId: this.state.serviceGroupId,
      serviceName: this.state.serviceName,
      access: this.state.serviceAccess,
      creation: this.state.serviceCreate,
      updation: this.state.serviceUpdate,
      deletion: this.state.serviceDelete,
    };

    this.props.updateServiceAccessPermission(
      data,
      this.updateServiceAccesstoggle
    );
  }

  deleteServiceAccess(id) {
    let data = {
      accId: id,
    };

    this.props.deleteServiceAccessPermission(data);
  }

  render() {
    const {
      error,
      message,
      appGroupId,
      appName,
      appAccess,
      updateAppAccModal,
      moduleGroupId,
      moduleName,
      moduleAccess,
      updateModuleAccModal,
      serviceGroupId,
      serviceName,
      updateServiceAccModal,
      serviceAccess,
      serviceCreate,
      serviceUpdate,
      serviceDelete,
    } = this.state;

    return (
      <Container className="card container-card">
        <h5 className="bold center pt-2">
          {this.props.roleCode}&nbsp;-&nbsp;Access And Permissions Details
        </h5>
        <i
          className="material-icons cancel-button pointer"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Row>
          <Col md="3">
            <h5 className="bold center pt-2">Applications</h5>
            <Table className="center">
              <thead>
                <tr>
                  <th className="access-opp-head">GroupId</th>
                  <th className="access-opp-head">Application</th>
                  <th className="access-opp-head ">Access</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.props.applicationPermissions.data.length > 0 &&
                  this.props.applicationPermissions.data.map((item) => {
                    return (
                      <tr>
                        <td className="access-opp-tableData">
                          {item.appGroupId}
                        </td>
                        <td className="access-opp-tableData">{item.appName}</td>
                        <td className="access-opp-tableData">
                          {item.access.toString()}
                        </td>
                        <td>
                          {typeof this.props.serviceAccessData !==
                            "undefined" &&
                            this.props.serviceAccessData.updation && (
                              <span
                                className="material-icons pointer opp-action-edit access-edit-btn"
                                style={{ color: "blue" }}
                                onClick={(e) => this.updateAppAccess(e, item)}
                              >
                                edit
                              </span>
                            )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Col>
          <Col md="3">
            <h5 className="bold center pt-2">Modules</h5>
            <Table className="center">
              <thead>
                <tr>
                  <th className="access-opp-head">GroupId</th>
                  <th className="access-opp-head">Module</th>
                  <th className="access-opp-head ">Access</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.props.modulePermissions.data.length > 0 &&
                  this.props.modulePermissions.data.map((item) => {
                    return (
                      <tr>
                        <td className="access-opp-tableData">
                          {item.moduleGroupId}
                        </td>
                        <td className="access-opp-tableData">
                          {item.moduleName}
                        </td>
                        <td className="access-opp-tableData">
                          {item.access.toString()}
                        </td>
                        <td>
                          <span
                            className="material-icons pointer opp-action-edit access-edit-btn"
                            style={{ color: "blue" }}
                            onClick={(e) => this.updateModuleAccess(e, item)}
                          >
                            edit
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Col>
          <Col md="6">
            <h5 className="bold center pt-2">Services</h5>
            <Table className="center">
              <thead>
                <tr>
                  <th className="access-opp-head ">GroupId</th>
                  <th className="access-opp-head ">Service</th>
                  <th className="access-opp-head ">Access/View</th>
                  <th className="access-opp-head ">Create</th>
                  <th className="access-opp-head ">Update</th>
                  <th className="access-opp-head ">Delete</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.props.servicePermissions.data.length > 0 &&
                  this.props.servicePermissions.data.map((item) => {
                    return (
                      <tr>
                        <td className="access-opp-tableData">
                          {item.serviceGroupId}
                        </td>
                        <td className="access-opp-tableData">
                          {item.serviceName}
                        </td>
                        <td className="access-opp-tableData">
                          {item.access.toString()}
                        </td>
                        <td className="access-opp-tableData">
                          {item.creation.toString()}
                        </td>
                        <td className="access-opp-tableData">
                          {item.updation.toString()}
                        </td>
                        <td className="access-opp-tableData">
                          {item.deletion.toString()}
                        </td>
                        <td>
                          <span
                            className="material-icons pointer opp-action-edit access-edit-btn"
                            style={{ color: "blue" }}
                            onClick={(e) => this.updateServiceAccess(e, item)}
                          >
                            edit
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Modal isOpen={updateAppAccModal}>
          <ModalHeader toggle={this.updateAppAccesstoggle}>
            Update Application Access
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md="6">
                {appName} - {appGroupId}
              </Col>
              <Col md="4">
                <input
                  type="checkbox"
                  name="appAccess"
                  onChange={this.appOnChange}
                  checked={appAccess}
                />
                <label className="access-and-permission-label">Access</label>
              </Col>
              <span
                className="product-pq-button access-main-menu-done-btn"
                title="Create"
                onClick={this.onSubmitAppAccess}
              >
                done
              </span>
            </Row>
          </ModalBody>
        </Modal>
        <Modal isOpen={updateModuleAccModal}>
          <ModalHeader toggle={this.updateModuleAccesstoggle}>
            Update Module Access
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md="6">
                {moduleName} - {moduleGroupId}
              </Col>
              <Col md="4">
                <input
                  type="checkbox"
                  name="moduleAccess"
                  onChange={this.moduleOnChange}
                  checked={moduleAccess}
                />
                <label className="access-and-permission-label">Access</label>
              </Col>
              <span
                className="product-pq-button access-main-menu-done-btn"
                title="Create"
                onClick={this.onSubmitModuleAccess}
              >
                done
              </span>
            </Row>
          </ModalBody>
        </Modal>
        <Modal isOpen={updateServiceAccModal} size="lg">
          <ModalHeader toggle={this.updateServiceAccesstoggle}>
            Update Service Access
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md="4" className="sub-menu-access-update">
                {serviceName} - {serviceGroupId}
              </Col>
              <Col
                md="4"
                className="sub-menu-access-update sub-menu-update-access"
              >
                <input
                  type="checkbox"
                  name="serviceAccess"
                  onChange={this.serviceOnChange}
                  checked={serviceAccess}
                />
                <label className="access-and-permission-label">
                  Access/View
                </label>
              </Col>
              {serviceAccess && (
                <React.Fragment>
                  <Col
                    md="2"
                    className="sub-menu-access-update sub-menu-update-create"
                  >
                    <input
                      type="checkbox"
                      name="serviceCreate"
                      onChange={this.serviceOnChange}
                      checked={serviceCreate}
                    />
                    <label className="access-and-permission-label">
                      Create
                    </label>
                  </Col>
                  <Col
                    md="2"
                    className="sub-menu-access-update sub-menu-update-update"
                  >
                    <input
                      type="checkbox"
                      name="serviceUpdate"
                      onChange={this.serviceOnChange}
                      checked={serviceUpdate}
                    />
                    <label className="access-and-permission-label">
                      Update
                    </label>
                  </Col>
                  <Col
                    md="2"
                    className="sub-menu-access-update sub-menu-update-delete"
                  >
                    <input
                      type="checkbox"
                      name="serviceDelete"
                      onChange={this.serviceOnChange}
                      checked={serviceDelete}
                    />
                    <label className="access-and-permission-label">
                      Delete
                    </label>
                  </Col>
                </React.Fragment>
              )}
              <span
                className="product-pq-button access-sub-menu-done-btn"
                title="Create"
                onClick={this.onSubmitServiceAccess}
              >
                done
              </span>
            </Row>
          </ModalBody>
        </Modal>
        {error && <ErrorNotifier message={error} />}
        {message && <Notifier message={message} />}
        {this.props.applicationPermissions.error && (
          <ErrorNotifier message={this.props.applicationPermissions.error} />
        )}
        {this.props.applicationPermissions.message && (
          <Notifier message={this.props.applicationPermissions.message} />
        )}
        {this.props.modulePermissions.error && (
          <ErrorNotifier message={this.props.modulePermissions.error} />
        )}
        {this.props.modulePermissions.message && (
          <Notifier message={this.props.modulePermissions.message} />
        )}
        {this.props.servicePermissions.error && (
          <ErrorNotifier message={this.props.servicePermissions.error} />
        )}
        {this.props.servicePermissions.message && (
          <Notifier message={this.props.servicePermissions.message} />
        )}
      </Container>
    );
  }
}

AccessAndPermissionDetail.propTypes = {
  fetchApplicationAccessPermission: PropTypes.func.isRequired,
  updateApplicationAccessPermission: PropTypes.func.isRequired,
  deleteApplicationAccessPermission: PropTypes.func.isRequired,
  fetchModuleAccessPermission: PropTypes.func.isRequired,
  updateModuleAccessPermission: PropTypes.func.isRequired,
  deleteModuleAccessPermission: PropTypes.func.isRequired,
  fetchServiceAccessPermission: PropTypes.func.isRequired,
  updateServiceAccessPermission: PropTypes.func.isRequired,
  deleteServiceAccessPermission: PropTypes.func.isRequired,
  applicationPermissions: PropTypes.object.isRequired,
  modulePermissions: PropTypes.object.isRequired,
  servicePermissions: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  applicationPermissions: state.applicationPermissions,
  modulePermissions: state.modulePermissions,
  servicePermissions: state.servicePermissions,
});

export default connect(mapStateToProps, {
  fetchApplicationAccessPermission,
  updateApplicationAccessPermission,
  deleteApplicationAccessPermission,
  fetchModuleAccessPermission,
  updateModuleAccessPermission,
  deleteModuleAccessPermission,
  fetchServiceAccessPermission,
  updateServiceAccessPermission,
  deleteServiceAccessPermission,
})(AccessAndPermissionDetail);
