import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import moment from "moment";
import dateFns from "date-fns";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// redux
import { getUserCompanyHolidays } from "../../../../redux/actions/hcm/configurations/companyHolidaysAction";
import { fetchEmployeeLeaves } from "../../../../redux/actions/hcm/lms/leaveManagementAction";

// ---------------------------------------------------------------------------------------------------------
/**
 * @class MyHolidayCalendar
 **/

class MyHolidayCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      currentMonth: new Date(),
    };
    this.renderDays = this.renderDays.bind(this);
    this.renderDates = this.renderDates.bind(this);
  }

  /* renderCalendar function to rendar the monthly wise calendar */
  async componentDidMount() {
    await this.props.getUserCompanyHolidays();

    await this.props.fetchEmployeeLeaves(this.props.auth.data.pieUser.id);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.leaveManagement.employeeLeaves !==
      this.props.leaveManagement.employeeLeaves
    ) {
      let copy = [];
      for (let i = 1; i <= 12; i++) {
        copy.push(
          <Col md="4" className="card my-holiday-calendar-card shadow">
            <div className="my-holiday-calendar-card-head">
              <h6>{moment(i, "M").format("MMM")}</h6>
            </div>
            <div className="horizantal-line"></div>
            {this.renderDays()}
            {this.renderDates(i)}
          </Col>
        );
      }
      this.setState({
        rows: copy,
      });
    }
  }

  /* renderDays is for displaying the days */
  renderDays() {
    const dateFormat = "ddd";
    const days = [];
    let startDate = dateFns.startOfWeek(this.state.currentMonth);

    for (let i = 0; i < 7; i++) {
      days.push(dateFns.format(dateFns.addDays(startDate, i), dateFormat));
    }

    return (
      <div className="days row">
        {days.map((day) => {
          return (
            <div
              className="col col-center my-holiday-calendar-days-div"
              key={day}
            >
              <b>{day}</b>
            </div>
          );
        })}
      </div>
    );
  }

  /* renderDates function to render the dates of the month */
  renderDates(i) {
    const selectedDate = new Date();
    const monthStart = dateFns.startOfMonth(
      new Date(dateFns.getYear(new Date()), i - 1, 1)
    );
    const monthEnd = dateFns.endOfMonth(
      new Date(dateFns.getYear(new Date()), i - 1, 1)
    );
    const startDate = dateFns.startOfWeek(monthStart);
    const endDate = dateFns.endOfWeek(monthEnd);
    const dateFormat = "D";
    const monthFormat = "MM";
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = "";
    let validation = false;
    let myHolidayValidation = false;
    let Occasion = "";

    // Logic for displaying date in calender

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = dateFns.format(day, dateFormat);
        console.log(this.props.leaveManagement.employeeLeaves);
        this.props.leaveManagement.employeeLeaves.map((item) => {
          if (
            dateFns.format(item.leaveDate, "YYYY-MM-DD") ===
              dateFns.format(day, "YYYY-MM-DD").toString() &&
            dateFns.isSameMonth(day, monthStart)
          ) {
            console.log("working");
            return (validation = true), (Occasion = item.leaveType);
          }
        });
        this.props.companyHolidays.data.map((item) => {
          if (
            dateFns.format(item.holidayDate, "YYYY-MM-DD") ===
              dateFns.format(day, "YYYY-MM-DD").toString() &&
            dateFns.isSameMonth(day, monthStart)
          ) {
            return (
              (myHolidayValidation = true), (Occasion = item.holidayOccasion)
            );
          }
        });
        days.push(
          <div
            className={`col cell holiday-date-div ${
              !dateFns.isSameMonth(day, monthStart) ? "color" : null
            }`}
            key={day}
          >
            <div
              className={` numberWorkDay ${
                validation
                  ? "numberHoliday pointer"
                  : // : dateFns.isSameDay(day, new Date())
                  // ? "my-holiday-calendar-current-date"
                  myHolidayValidation
                  ? "myNumberHoliday "
                  : null
              }`}
              title={myHolidayValidation ? Occasion : null}
            >
              <span className="number" key={day}>
                {formattedDate}
              </span>
            </div>
          </div>
        );
        day = dateFns.addDays(day, 1);
        validation = false;
        myHolidayValidation = false;
      }
      rows.push(
        <div className="row mb-1" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  }

  render() {
    let shrink = this.props.sidebar ? "scale" : "no-scale";
    return (
      <div className={shrink}>
        <Container className="card container-card pt-2">
          <Link
            to={{
              pathname: "/pie/iam/service_menu",
              moduleName: this.props.location.moduleName,
              data: this.props.location.data,
            }}
          >
            <i className="material-icons cancel-button pointer">cancel</i>
          </Link>
          <h5 className="bold center">Holiday Calendar</h5>
          <Row>{this.state.rows}</Row>
        </Container>
      </div>
    );
  }
}

MyHolidayCalendar.propTypes = {
  getUserCompanyHolidays: PropTypes.func.isRequired,
  companyHolidays: PropTypes.object.isRequired,
  fetchEmployeeLeaves: PropTypes.func.isRequired,
  leaveManagement: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  companyHolidays: state.companyHolidays,
  leaveManagement: state.leaveManagement,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getUserCompanyHolidays,
  fetchEmployeeLeaves,
})(MyHolidayCalendar);
