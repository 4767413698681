import axios from "axios";
import { BANK_ACCOUNTS_INFO_GET } from "../../../types";
import { CURD_BANK_ACCOUNTS_INFO } from "../../../../../utils/routes";
import setSuccessMessage from "../../../common/setSuccessMessage";
import setErrorMessage from "../../../common/setErrorMessage";
import getDispatch from "../../../common/getDispatch";

export const getBankAccountsInfo = () => (dispatch) => {
  axios
    .get(CURD_BANK_ACCOUNTS_INFO, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(BANK_ACCOUNTS_INFO_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateBankAccountsInfo = (data, back) => (dispatch) => {
  axios
    .post(CURD_BANK_ACCOUNTS_INFO, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
