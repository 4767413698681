import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import {
  RsRequirements,
  GET_TAGGED_DETAILS,
  SEARCH_REQUIREMENT,
  SEARCH_WITH_ID,
  FETCHBYID,
} from "../../../../utils/routes";
import {
  REQUIREMENT_GET,
  TAGGED_DETAILS_GET,
  RS_SEARCH_BY_ID,
  RS_SEARCH_BY_STRING,
} from "../../types";
import axios from "axios";
import getDispatch from "../../common/getDispatch";

export const AddRequirementAction = (data, back) => (dispatch) => {
  axios
    .post(RsRequirements, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getRequirement));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getRequirement = () => (dispatch) => {
  axios
    .get(`${RsRequirements}/select`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(REQUIREMENT_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getAllRequirement = () => (dispatch) => {
  axios
    .get(`${RsRequirements}/select-all`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(REQUIREMENT_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const fetchRequirement = (id) => (dispatch) => {
  axios
    .get(`${RsRequirements}/${id}`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(REQUIREMENT_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const ReqsearchWithId = (data) => (dispatch) => {
  axios
    .get(`${SEARCH_WITH_ID}/${data}`, { withCredentials: true })
    .then((res) => {
      if (!res.data.length) {
        dispatch({
          type: "RS_ID_EMPTY_ARRAY",
          payload: "Data not found",
        });
        setTimeout(() => {
          dispatch({
            type: "RS_ID_EMPTY_ARRAY",
            payload: "",
          });
        }, 3000);
      } else {
        dispatch(getDispatch(RS_SEARCH_BY_ID, res));
      }
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const searchWithString = (data) => (dispatch) => {
  axios
    .post(`${SEARCH_REQUIREMENT}`, data, { withCredentials: true })
    .then((res) => {
      if (!res.data.length) {
        dispatch({
          type: "RS_STRING_EMPTY_ARRAY",
          payload: "Data not found",
        });
        setTimeout(() => {
          dispatch({
            type: "RS_STRING_EMPTY_ARRAY",
            payload: "",
          });
        }, 3000);
      } else {
        dispatch(getDispatch(RS_SEARCH_BY_STRING, res));
      }
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

// export const getRequirement = () => (dispatch) => {
//   axios
//     .get(`${RsRequirements}/select`, { withCredentials: true })
//     .then((res) => {
//       dispatch(getDispatch(REQUIREMENT_GET, res));
//     })
//     .catch((err) => {
//       dispatch(setErrorMessage(err));
//     });
// };

export const updateRequirement = (data, back) => (dispatch) => {
  axios
    .put(RsRequirements, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getTaggedDetails = () => (dispatch) => {
  axios
    .get(GET_TAGGED_DETAILS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(TAGGED_DETAILS_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
