import React, { Component } from "react";
import { Form, Row, Col, FormGroup, Label, Input, FormText } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from 'axios'
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../common/Validator";
import FormComponent from "../../../common/FormComponent";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import {ADD_RS_CANDIDATE,APPLY_JOBS} from '../../../../utils/routes'
import {getCandidateStatus} from "../../../../redux/actions/rs/configuration/candidateStausAction"
import {updateTagRequirement} from "../../../../redux/actions/rs/requirements/tagCandidate/tagCandidate";



class AddCandidate extends Component {
  constructor(props) {
    super(props);
    this.state = {
   candiName:"",
   lastName:"",
   emailId:"",
   location:"",
   country:"",
   mobileNumber:"",
   gender:"",
   skillGroup:"",
   skillSets:"",
   noticePeriod:"",
   expectedCtc:"",
   currentCtc:"",
   experience:"",
   prefLocation:"",
   isExperience:"Experienced",
   notes:"",
   remarks:"",
   dynamicCurrency:"",

   candiStatus:this.props.fetchData
   ? this.props.fetchData.candidateStatus
   : "",
   remarks:this.props.fetchData
   ? this.props.fetchData.remarks
   : "",
   dateOf:this.props.fetchData
   ? this.props.fetchData.joiningDate
   : "",
   id:this.props.fetchData
   ? this.props.fetchData.id
   : "",
   jobReqId:this.props.fetchData
   ? this.props.fetchData.jobRequirementId
   : "",
   canInfoId:this.props.fetchData
   ? this.props.fetchData.canInfoId
   : "",
   canUserId:this.props.fetchData
   ? this.props.fetchData.canUserId
   : "",


   expArr:[
     {key:"Experienced",value:"Experienced"},
     {key:"Fresher",value:"Fresher"}
   ],
   genderarr:[
     {key:"Male",value:"Male"},
     {key:"female",value:"female",},
     {key:"others",value:"others"}
   ],
  
   dialingCode:"91"
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
   
  }

  // onChange(e) {
  //   Validator(e.target);
  //   this.setState({
  //     [e.target.name]: e.target.value,
  //   });
  // }



  onChange(e) {
    e.preventDefault();
    Validator(e.target);
 
      this.setState({
        [e.target.name]: e.target.value,
      });
    
  }





 
// update function for updating tagged requirement
  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
        id:this.state.id,
        jobReqirementId:this.state.jobReqId,
        canUserId:this.state.canUserId,
        canInfoId:this.state.canInfoId,
        candidateSource: "Tagging",
        candidateStatus:this.state.candiStatus,
        joiningDate:this.state.dateOf,
        remarks:this.state.remarks
    }
    if (valid ) {
    this.props.updateTagRequirement(data,this.props.navigate)
    } else {
      this.setState({
        error: "Please enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidMount contains the error refresh method and here set the state for calling render method
*/
  componentDidMount() {
 
    this.props.getCandidateStatus()
    refresh();
    this.setState({
      message: "",
      error: "",
    });
  }

 

  render() {

    const {
    remarks,
    dateOf,
    message,
    error

    } = this.state;

    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Update Tagged Candidate </h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.navigate}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <div style={{ marginLeft: "30%", width: "44%" }}>
              {/* <Col md="6"> */}
              <span className="bold label-sm">Candidate status</span>

              <FormGroup>
                <Input
                
                  type="select"
                  name="candiStatus"
                  className="form-control-sm"
                  value={this.state.candiStatus}
                  onChange={this.onChange}
                  placeholder="Requirement Type"
                  required={true}
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {this.props.CandidateStatus.data.map((item) => {
                    return (
                      <option key={item.id} value={item.candidateStatus}>
                        {item.candidateStatus}
                      </option>
                    );
                  })}
                </Input>
                {this.state.errorTagCan && (
                  <FormText className="error">
                    {this.state.errorTagCan}
                  </FormText>
                )}
              </FormGroup>
              <FormComponent
                index="8"
                labelClass="bold label-sm"
                label="Joining Date"
                type="date"
                name="dateOf"
                inputClass="form-control-sm"
                value={dateOf}
                change={this.onChange}
              
              />
              <FormComponent
                index="6"
                labelClass="bold label-sm"
                label="Remarks"
                type="textarea"
                name="remarks"
                inputClass="form-control-sm"
                value={remarks}
                change={this.onChange}
              
              />
            

              {/* </Col> */}
            </div>
            <Col md="3"></Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Update
            </button>
          </div>
        </Form>
        {message && <Notifier message={message} />}
        {error && <ErrorNotifier message={error} />}
      </React.Fragment>
    );
  }
}

AddCandidate.propTypes = {
  addEmpAwards: PropTypes.func.isRequired,
  empAwards: PropTypes.object.isRequired,
  
};

const mapStateToProps = (state) => ({
  skillGroups: state.skillGroups,
  CandiCountry:state.CandiCountry,
  Noticeperiod:state.Noticeperiod,
  CandidateStatus:state.CandidateStatus
});

export default connect(mapStateToProps, {  getCandidateStatus,updateTagRequirement })( AddCandidate);
