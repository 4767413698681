import axios from "axios";
import { PROJECT_TEAM_GET } from "../../types";
import { CURD_PROJECT_TEAM, USER_PROJECT_TEAM } from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addProjectTeam = (data, back) => (dispatch) => {
  axios
    .post(CURD_PROJECT_TEAM, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getProjectTeam));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getProjectTeam = () => (dispatch) => {
  axios
    .get(CURD_PROJECT_TEAM, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(PROJECT_TEAM_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserProjectTeam = () => (dispatch) => {
  axios
    .get(USER_PROJECT_TEAM, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(PROJECT_TEAM_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateProjectTeam = (data, back) => (dispatch) => {
  axios
    .put(CURD_PROJECT_TEAM, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteProjectTeam = (data) => (dispatch) => {
  axios
    .delete(CURD_PROJECT_TEAM, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getProjectTeam));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
