import axios from "axios";
import { CRM_GLOBAL_GET } from "../../types";
import { CURD_CRM_GLOBAL } from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addCrmGlobal = (data, back) => (dispatch) => {
  axios
    .post(CURD_CRM_GLOBAL, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getCrmGlobal));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getCrmGlobal = () => (dispatch) => {
  axios
    .get(CURD_CRM_GLOBAL, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CRM_GLOBAL_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateCrmGlobal = (data, back) => (dispatch) => {
  axios
    .put(CURD_CRM_GLOBAL, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getCrmGlobal));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteCrmGlobal = (data) => (dispatch) => {
  axios
    .delete(CURD_CRM_GLOBAL, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getCrmGlobal));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
