import React from 'react'

/*
Hover is function component it accepting the cords for data to display, labels for label data 
and mouse for giving the style to hover box based on the mouse pointer.
*/
const Hover = ({ cords, labels, mouse }) => {
  let hoverBoxStyle = {
    position: 'fixed',
    top: `${mouse.y - 50}px`,
    left: `${mouse.x}px`,
    zIndex: 50,    
  }  
  return (
    <div style={hoverBoxStyle} className="hover-box">
      <p className="label-sm bold">{labels.one}:</p>
      <p className="label-sm">{cords&&cords.one}</p>
      {labels.two ? 
      <div>
      <hr />
      <p className="label-sm bold">{labels.two}:</p>
      <p className="label-sm">{cords&&cords.two}</p>
      </div>
      : null}
      {labels.three ? 
      <div>
      <hr />
      <p className="label-sm bold">{labels.three}:</p>
      <p className="label-sm">{cords&&cords.three}</p>
      </div>
      : null}
    </div>
  )
}

export default Hover;