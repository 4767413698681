import React, { Component } from "react";
import { Row, Col, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DateFns from "date-fns";
// components
import {
  Validator,
  submitValidator,
  valid,
  errors,
  PercentageValidation,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
// redux
import { addEmpNominee } from "../../../../../redux/actions/hcm/my_profile/empNomineeAction";
import { getUserBusinessGeowise } from "../../../../../redux/actions/hcm/business_setup/businessGeowiseAction";
import { getUserBusinessCountries } from "../../../../../redux/actions/hcm/business_setup/businessCountriesAction";
import { getUserEmpRelation } from "../../../../../redux/actions/hcm/configurations/empRelationAction";

// -----------------------------------------------------------------------------------------------------------
class AddNomination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        nomineeName: "",
        nomineeAge: "",
        nomineeDob: "",
        relation: "",
        nomineeAddress: "",
        geography: "",
        country: "",
        dialingCode: "",
        nomineeContact: "",
        nomineeEmail: "",
        sharePercentage: "",
        dateError: "",
      },
      message: null,
      error: null,
      buttonDisable: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    e.preventDefault();
    if (e.target.name === "sharePercentage" || e.target.name === "nomineeAge") {
      PercentageValidation(e.target);
    } else {
      Validator(e.target);
    }
    if (e.target.name === "nomineeDob") {
      if (
        Date.parse(e.target.value) >
        Date.parse(DateFns.format(new Date(), "YYYY-MM-DD"))
      ) {
        this.setState({
          data: {
            ...this.state.data,
            [e.target.name]: e.target.value,
            dateError: "Date must be less than currentDate",
          },
        });
      } else {
        this.setState({
          data: {
            ...this.state.data,
            [e.target.name]: e.target.value,
            dateError: "",
          },
        });
      }
    } else {
      this.setState({
        data: {
          ...this.state.data,
          [e.target.name]: e.target.value,
        },
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let { data } = this.state;
    let details = {
      nomineeName: data.nomineeName,
      nomineeAge: data.nomineeAge,
      nomineeDob: data.nomineeDob,
      relation: data.relation,
      nomineeAddress: data.nomineeAddress,
      geography: data.geography,
      country: data.country,
      dialingCode: data.dialingCode,
      nomineeContact: data.nomineeContact,
      nomineeEmail: data.nomineeEmail,
      sharePercentage: data.sharePercentage,
    };
    if (valid && !this.state.data.dateError) {
      this.props.addEmpNominee(details, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    this.props.getUserBusinessGeowise();
    this.props.getUserBusinessCountries();
    this.props.getUserEmpRelation();
  }

  render() {
    const { data, buttonDisable, dateError } = this.state;

    return (
      <React.Fragment>
        <h5 className="bold center pt-2">Add Nominee</h5>
        <i
          className="material-icons cancel-button pointer"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="6">
              <FormComponent
                labelClass="bold label-sm"
                label="Nominee Name"
                type="text"
                name="nomineeName"
                inputClass="form-control-sm"
                value={data && data.nomineeName}
                change={this.onChange}
                required={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Nominee Age"
                type="number"
                name="nomineeAge"
                inputClass="form-control-sm"
                value={data && data.nomineeAge}
                change={this.onChange}
                required={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Date Of Birth"
                type="date"
                name="nomineeDob"
                inputClass="form-control-sm"
                value={data && data.nomineeDob}
                change={this.onChange}
                required={true}
                error={data && data.dateError}
              />
              <FormGroup>
                <Label className="label-sm bold">Relation</Label>
                <Input
                  type="select"
                  name="relation"
                  className="form-control-sm"
                  value={data && data.relation}
                  onChange={this.onChange}
                  required={true}
                >
                  <option hidden>Select...</option>
                  {this.props.empRelation &&
                    this.props.empRelation.data.map((item) => {
                      return (
                        <option
                          key={item.contactRelationId}
                          value={item.contactRelationName}
                        >
                          {item.contactRelationName}
                        </option>
                      );
                    })}
                </Input>
                {errors.relation && (
                  <FormText className="error">{errors.relation}</FormText>
                )}
              </FormGroup>
              <FormComponent
                labelClass="bold label-sm"
                label="Nominee Address"
                type="text"
                name="nomineeAddress"
                inputClass="form-control-sm"
                value={data && data.nomineeAddress}
                change={this.onChange}
                required={true}
              />
            </Col>
            <Col md="6">
              <FormGroup>
                <Label className="bold label-sm">Geography</Label>
                <Input
                  type="select"
                  name="geography"
                  className="form-control-sm"
                  value={data && data.geography}
                  onChange={this.onChange}
                  required={true}
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {this.props.businessGeowise.data.map((item) => {
                    return (
                      <option key={item.id} value={item.geoName}>
                        {item.geoName}
                      </option>
                    );
                  })}
                </Input>
                {errors.geography && (
                  <FormText className="error">{errors.geography}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="bold label-sm">Country</Label>
                <Input
                  type="select"
                  name="country"
                  className="form-control-sm"
                  value={data && data.country}
                  onChange={this.onChange}
                  required={true}
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {this.props.businessCountries.data.map((item) => {
                    if (data.geography === item.geoName) {
                      return (
                        <option key={item.id} value={item.countryName}>
                          {item.countryName}
                        </option>
                      );
                    }
                  })}
                </Input>
              </FormGroup>

              <Row>
                <Col md="2" className="p-0">
                  <FormGroup>
                    <Label className="bold label-sm">Dialing Code</Label>
                    <Input
                      type="select"
                      name="dialingCode"
                      className="form-control-sm"
                      value={data && data.dialingCode}
                      onChange={this.onChange}
                      required={true}
                      maxLength={4}
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.businessCountries.data.map((item) => {
                        if (data.country === item.countryName) {
                          return (
                            <option key={item.id} value={item.dialingCode}>
                              {item.dialingCode}
                            </option>
                          );
                        }
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="10" className="p-0">
                  <FormComponent
                    labelClass="bold label-sm"
                    label="Nominee Contact Number"
                    type="tel"
                    name="nomineeContact"
                    inputClass="form-control-sm"
                    value={data && data.nomineeContact}
                    change={this.onChange}
                    required={true}
                  />
                </Col>
              </Row>

              <FormComponent
                labelClass="bold label-sm"
                label="Nominee Email ID"
                type="email"
                name="nomineeEmail"
                inputClass="form-control-sm"
                value={data && data.nomineeEmail}
                change={this.onChange}
                required={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Share Percentage"
                type="number"
                name="sharePercentage"
                inputClass="form-control-sm"
                value={data && data.sharePercentage}
                change={this.onChange}
                required={true}
              />
            </Col>
          </Row>
          <div className="center">
            <button
              type="submit"
              className="btn login-button white-text"
              disabled={buttonDisable ? true : null}
            >
              Add
            </button>
          </div>
        </Form>
        {this.props.empNominee.message ? (
          <Notifier message={this.props.empNominee.message} />
        ) : null}
        {this.props.empNominee.error ? (
          <ErrorNotifier message={this.props.empNominee.error} />
        ) : null}
      </React.Fragment>
    );
  }
}

AddNomination.propTypes = {
  addEmpNominee: PropTypes.func.isRequired,
  empNominee: PropTypes.object.isRequired,
  getUserBusinessGeowise: PropTypes.func.isRequired,
  businessGeowise: PropTypes.object.isRequired,
  getUserBusinessCountries: PropTypes.func.isRequired,
  businessCountries: PropTypes.object.isRequired,
  getUserEmpRelation: PropTypes.func.isRequired,
  empRelation: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  empNominee: state.empNominee,
  businessGeowise: state.businessGeowise,
  businessCountries: state.businessCountries,
  empRelation: state.empRelation,
});
export default connect(mapStateToProps, {
  addEmpNominee,
  getUserBusinessGeowise,
  getUserBusinessCountries,
  getUserEmpRelation,
})(AddNomination);
