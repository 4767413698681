import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import DateFns from "date-fns";
import UpdateCampaign from "./UpdateCampaign";

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permissionlist: this.props.auth.permissionlist || {},
      edit: false,
    };
    this.updateNow = this.updateNow.bind(this);
    this.back = this.back.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.auth !== this.props.auth) {
      this.setState({
        permissionlist: this.props.auth.permisssionlist,
      });
    }
  }

  updateNow() {
    this.setState({
      edit: true,
    });
  }

  back() {
    this.setState({
      edit: false,
    });
    this.props.getData();
  }

  componentDidMount() {
    this.setState({
      permissionlist: this.props.auth.permissionlist,
    });
  }

  render() {
    const { permissionlist, edit } = this.state;
    let currentPermission;

    for (let x in permissionlist.response.permissions1) {
      if (permissionlist.response.permissions1[x].subMenuId === "3.2") {
        currentPermission = permissionlist.response.permissions1[x];
      }
    }
    return (
      <Container className="card container-card p-3">
        <i
          className="material-icons pointer"
          style={{
            position: "absolute",
            top: "5px",
            right: "5px",
            color: "#C00",
          }}
          onClick={this.props.back}
        >
          cancel
        </i>
        {!edit ? (
          <React.Fragment>
            <h5 className="center">Campaign Details</h5>
            <hr />
            <Row>
              <Col md="6">
                <ul style={{ listStyle: "none" }}>
                  <li>
                    <span className="label-sm bold campaign-span-label-col-1">
                      Campaign Name
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.props.campaign.campName}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold campaign-span-label-col-1">
                      Campaign Type
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.campaign.campaignType}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold campaign-span-label-col-1">
                      Success Criteria
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.campaign.successCriteria}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold campaign-span-label-col-1">
                      Planned Budget
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.campaign.plannedBudget}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold campaign-span-label-col-1">
                      Currency
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.campaign.currencyField}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold campaign-span-label-col-1">
                      Actual Spend
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.props.campaign.actualSpend}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold campaign-span-label-col-1">
                      Activity Type
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.campaign.activityType}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold campaign-span-label-col-1">
                      Date
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {DateFns.format(this.props.campaign.date, "DD-MM-YYYY")}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold campaign-span-label-col-1">
                      Time
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.props.campaign.time}
                    </span>
                  </li>
                </ul>
              </Col>
              <Col md="6">
                <ul style={{ listStyle: "none" }}>
                  <li>
                    <span className="label-sm bold campaign-span-label-col-2">
                      Action Owner
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.props.campaign.actionOwner}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold campaign-span-label-col-2">
                      Status
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.props.campaign.status}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold campaign-span-label-col-2">
                      Description
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.props.campaign.description}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold campaign-span-label-col-2">
                      Created By
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.props.campaign.createdBy}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold campaign-span-label-col-2">
                      Created On
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {DateFns.format(
                        this.props.campaign.createdOn,
                        "DD-MM-YYYY"
                      )}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold campaign-span-label-col-2">
                      Updated By
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.props.campaign.updatedBy}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold campaign-span-label-col-2">
                      Updated On
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.campaign.updatedOn &&
                        DateFns.format(
                          this.props.campaign.updatedOn,
                          "DD-MM-YYYY"
                        )}
                    </span>
                  </li>
                </ul>
              </Col>
            </Row>
            {currentPermission.update1 ? (
              <div className="div">
                <hr />
                <div className="center">
                  <button
                    className="btn login-button white-text"
                    onClick={this.updateNow}
                  >
                    Update
                  </button>
                </div>
              </div>
            ) : null}
          </React.Fragment>
        ) : (
          <UpdateCampaign
            back={this.props.back}
            upCampaign={this.props.campaign}
          />
        )}
      </Container>
    );
  }
}

Details.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(Details);
