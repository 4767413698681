import axios from "axios";
import { UPLOAD_CANDIDATES } from "../../../../utils/routes";
import { SET_MESSAGE, GET_UPLOAD_CAN_RS_EXTRACTED,GET_UPLOAD_CAN_RS_PROCESSED,GET_UPLOAD_CAN_RS_UNPROCESSED  } from "../../types";
import setSuccessMessage from "../../common/setSuccessMessage";
import { push } from 'react-router-redux';
import { useHistory, useLocation } from "react-router-dom";
import { Redirect } from 'react-router'
import setErrorMessage from "../../common/setErrorMessage";

export const uploadCandidates = (data) => (dispatch) => {

  axios
    .post(UPLOAD_CANDIDATES, data, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: SET_MESSAGE,
        payload: res.data.message,
      });
      dispatch({
        type:  GET_UPLOAD_CAN_RS_EXTRACTED,
        payload: res.data.extracted,
      });
      dispatch({
        type:GET_UPLOAD_CAN_RS_PROCESSED ,
        payload: res.data.processed,
      });
      dispatch({
        type:GET_UPLOAD_CAN_RS_UNPROCESSED,
        payload: res.data.unprocessed,
      });

      setTimeout(() => {
        dispatch({
          type: SET_MESSAGE,
          payload: "",
        });
       }, 2000);
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
