import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, FormGroup, Label, Input, FormText } from "reactstrap";
// components
import {
  Validator,
  submitValidator,
  errors,
  valid,
  refresh,
} from "../../../common/Validator";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
// redux
import { uploadCustomerDocuments } from "../../../../redux/actions/crm/customers/customerDocumentsAction";
import { getUserCustomerDocumentType } from "../../../../redux/actions/crm/crm_setup/customerDocumentTypeAction";

// -------------------------------------------------------

class UploadCustDocModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documentType: "",
      documentName: "",
      startDate: "",
      endDate: "",
      file: [],
      remarks: "",
      fileError: "",
      dateError: "",
    };
  }

  onChange = (e) => {
    Validator(e.target);
    if (e.target.name === "endDate") {
      if (Date.parse(e.target.value) < Date.parse(this.state.startDate)) {
        this.setState({
          [e.target.name]: e.target.value,
          dateError: "EndDate must be greater than or equal to StartDate",
        });
      } else {
        this.setState({
          [e.target.name]: e.target.value,
          dateError: "",
        });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  handleFile = (e) => {
    Validator(e.target);
    let allowedExtension = /(\.pdf)$/i;
    if (allowedExtension.exec(e.target.files[0].name)) {
      if ((e.target.files[0].size / 1024 / 1024).toFixed(2) < 2) {
        this.setState({
          file: e.target.files[0] || [],
          fileError: "",
        });
      } else {
        this.setState({
          file: e.target.files[0],
          fileError: "File size must less than 2MB",
        });
      }
    } else {
      this.setState({
        file: e.target.files[0],
        fileError: "File type not allowed",
      });
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    submitValidator(e.target);
    const { fileError, dateError } = this.state;
    let fd = new FormData();
    fd.append("customerdetailId", this.props.id.toString());
    fd.append("documentType", this.state.documentType);
    fd.append("documentName", this.state.documentName);
    fd.append("docStartDate", this.state.startDate);
    fd.append("docEndDate", this.state.endDate);
    fd.append("remarks", this.state.remarks);
    fd.append("documentPath", this.state.file);

    if (valid && !fileError && !dateError) {
      this.props.uploadCustomerDocuments(fd, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  };

  componentDidMount() {
    refresh();
    this.props.getUserCustomerDocumentType();
  }

  render() {
    const {
      documentType,
      documentName,
      startDate,
      endDate,
      file,
      remarks,
      fileError,
      dateError,
    } = this.state;

    return (
      <>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="12" className="mx-auto">
              <FormGroup>
                <Label className="bold label-sm">Document Type</Label>
                <Input
                  type="select"
                  name="documentType"
                  className="form-control-sm"
                  value={documentType}
                  onChange={this.onChange}
                  required={true}
                >
                  <option hidden value="">
                    Select...
                  </option>
                  {this.props.customerDocumentType.data.length > 0 &&
                    this.props.customerDocumentType.data.map((item) => {
                      return (
                        <option key={item.id} value={item.custDocType}>
                          {item.custDocType}
                        </option>
                      );
                    })}
                </Input>
                {errors.documentType && (
                  <FormText className="error">{errors.documentType}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="bold label-sm">Document Name</Label>
                <Input
                  type="text"
                  name="documentName"
                  className="form-control-sm"
                  value={documentName}
                  onChange={this.onChange}
                  required={true}
                />
                {errors.documentName && (
                  <FormText className="error">{errors.documentName}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="bold label-sm">Start Date</Label>
                <Input
                  type="date"
                  name="startDate"
                  className="form-control-sm"
                  value={startDate}
                  onChange={this.onChange}
                  required={true}
                />
                {errors.startDate && (
                  <FormText className="error">{errors.startDate}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="bold label-sm">End Date</Label>
                <Input
                  type="date"
                  name="endDate"
                  className="form-control-sm"
                  value={endDate}
                  onChange={this.onChange}
                  min={this.state.startDate}
                  required={true}
                />
                {errors.endDate && (
                  <FormText className="error">{errors.endDate}</FormText>
                )}
                {dateError && (
                  <FormText className="error">{dateError}</FormText>
                )}
              </FormGroup>
              <div className="file-div ">
                <FormGroup className="file-display mr-1">
                  <Label className="bold label-sm">Document</Label>
                  <Input
                    disabled
                    type="text"
                    value={file.name || ""}
                    className="form-control-sm "
                    required={true}
                  />
                  {fileError && (
                    <FormText className="error ">{fileError}</FormText>
                  )}
                  {errors.documentPath && (
                    <FormText className="error">{errors.documentPath}</FormText>
                  )}
                </FormGroup>
                <FormGroup className="file-browse-btn-div">
                  <label className="btn label-sm file-browse-btn">
                    Browse
                    <Input
                      type="file"
                      name="documentPath"
                      style={{
                        display: "none",
                        marginTop: "23px",
                        marginLeft: "-28px",
                      }}
                      className="form-control-sm file-input"
                      onChange={this.handleFile}
                      required={true}
                    />
                  </label>
                </FormGroup>
              </div>
              <FormGroup>
                <Label className="bold label-sm">Remarks</Label>
                <Input
                  type="textarea"
                  name="remarks"
                  className="form-control-sm"
                  value={remarks}
                  onChange={this.onChange}
                />
                {errors.remarks && (
                  <FormText className="error">{errors.remarks}</FormText>
                )}
              </FormGroup>
            </Col>
          </Row>
          <div className="center">
            <button
              type="submit"
              className="btn mt-3 center login-button white-text"
            >
              Upload
            </button>
          </div>
        </Form>
        {this.props.customerDocuments.message && (
          <Notifier message={this.props.customerDocuments.message} />
        )}
        {this.props.customerDocuments.error && (
          <ErrorNotifier message={this.props.customerDocuments.error} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  customerDocuments: state.customerDocuments,
  customerDocumentType: state.customerDocumentType,
});

const mapDispatchToProps = (dispatch) => ({
  uploadCustomerDocuments: (fd, back) =>
    dispatch(uploadCustomerDocuments(fd, back)),
  getUserCustomerDocumentType: () => dispatch(getUserCustomerDocumentType()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadCustDocModel);
