import React, { useEffect } from "react";
import { Row, Col, Card } from "reactstrap";
import CustomerType from "../../crm/insights/CustomerType";
import CampaignDistribution from "../../marketing/marketing_insights/CampTypeToLeadRatio";
import PotentialLeads from "../../marketing/marketing_insights/OpenLeadsByPotential";
// import OrderDistribution from "../../sales/sales_insights/OrderByBusinessType";
// import OpportunitiesTrend from "../../sales/sales_insights/OpportunitiesPipeline";
// import SalesGrowth from "../../sales/sales_insights/SalesGrowth";
// import InquiriesTrend from "../../marketing/marketing_insights/InquiryConvertedToLead";
// import Top5OrdersByCustomer from "../../sales/sales_insights/TopFiveOrdersByCustomer";
// import Top5OrdersByLeadSource from "../../sales/sales_insights/TopFiveOrdersByLeadSource";
// import CustomerChurn from "./CustomerChurn";
// import RateOfRenewal from "./RateOfRenewal";
// import RateOfExpansion from "./RateOfExpansion";

// config variable
import { IAM_API } from "../../../../utils/config";
// web socket import
import { io } from "socket.io-client";

// ------------------------------------------------------------------------------------------------------

const DashboardInsights = (props) => {
  let shrink = props.sidebar ? "scale" : "no-scale";

  useEffect(() => {
    // const socket = io("ws://apidev.growthgamut.com/pieiamapi", {
    //   transports: ["websocket"],
    //   upgrade: false,
    //   reconnection: true,
    //   reconnectionAttempts: 10,
    //   reconnectionDelay: 500,
    //   reconnectionDelayMax: 5000,
    //   reconnectionMax: 100,
    //   pingTimeout: 30000,
    //   pingInterval: 30000,
    //   autoConnect: true,
    //   withCredentials: true,
    // });
    // socket.on("connect", () => {
    //   console.log(socket.connected); // true
    // });
    // socket.on("disconnect", () => {
    //   console.log(socket.connected); // false
    // });
  }, []);

  return (
    <div className={shrink}>
      <Card className="card container-card center">
        <h1 style={{ padding: "5rem" }}>
          <b>Welcome to Pion Intelligent Enterprise</b>
        </h1>
      </Card>
      {/* <Row style={{ marginTop: "-20px" }}>
        <Col
          md="4"
          className="reCharts-col"
          style={{ marginTop: "10px", marginLeft: "15px" }}
        >
          <p className=" center reCharts-title">Customer Type</p>
          <CustomerType />
        </Col>
        <Col md="4" className="reCharts-col" style={{ marginTop: "10px" }}>
          <p className=" center reCharts-title">Campaign Distribution</p>
          <CampaignDistribution />
        </Col>
        <Col md="4" className="reCharts-col" style={{ marginTop: "10px" }}>
          <p className=" center reCharts-title">Potential Leads</p>
          <PotentialLeads />
        </Col>
        <CustomerChurn />
      </Row>
      <Row>
        <Col md="4" className="reCharts-col" style={{ marginLeft: "15px" }}>
          <p className=" center reCharts-title">Opportunities Trend</p>
          <OpportunitiesTrend />
        </Col>
        <Col md="4" className="reCharts-col">
          <p className=" center reCharts-title">Sales Growth</p>
          <SalesGrowth />
        </Col>
        <Col md="4" className="reCharts-col">
          <p className=" center reCharts-title">Order Distribution</p>
          <OrderDistribution />
        </Col>
        <RateOfRenewal />
      </Row>
      <Row>
        <Col
          md="4"
          className="reCharts-last-row-col"
          style={{ marginLeft: "15px" }}
        >
          <p className=" center reCharts-title">Inquiries Trend</p>
          <InquiriesTrend />
        </Col>
        <Col md="4" className="reCharts-last-row-col">
          <p className=" center reCharts-title">Top 5 Orders By Customer</p>
          <Top5OrdersByCustomer />
        </Col>
        <Col md="4" className="reCharts-last-row-col">
          <p className=" center reCharts-title">Top 5 Orders By LeadSource</p>
          <Top5OrdersByLeadSource />
        </Col>
        <RateOfExpansion />
      </Row> */}
    </div>
  );
};

export default DashboardInsights;
