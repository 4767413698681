import React, { Component } from "react";
import { Form, Row, Col, FormGroup, Label, Input, FormText } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// components
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
// redux
import { addEmpType } from "../../../../../redux/actions/hcm/configurations/empTypeAction";

class AddEmployeeType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeTypeName: "",
      description: "",
      message: "",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      employeeTypeName: this.state.employeeTypeName,
      description: this.state.description,
      createdBy: localStorage.getItem("id"),
    };
    if (valid) {
      this.props.addEmpType(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidMount contains the error refresh method and setState method to call render method
*/
  componentDidMount() {
    refresh();
    this.setState({
      message: "",
      error: "",
    });
  }

  render() {
    const { employeeTypeName, description, error, message } = this.state;

    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Add Employee Type</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="6" className="mx-auto">
              <FormGroup>
                <Label className="bold label-sm">Employee Type</Label>
                <Input
                  type="text"
                  name="employeeTypeName"
                  className="form-control-sm"
                  value={employeeTypeName}
                  onChange={this.onChange}
                  required={true}
                />

                {errors.employeeTypeName && (
                  <FormText className="error">
                    {errors.employeeTypeName}
                  </FormText>
                )}
              </FormGroup>

              <FormComponent
                labelClass="bold label-sm"
                label="Description"
                type="textarea"
                name="description"
                inputClass="form-control-sm"
                value={description}
                change={this.onChange}
              />
            </Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Add
            </button>
          </div>
        </Form>
        {this.props.empType.message && (
          <Notifier message={this.props.empType.message} />
        )}
        {this.props.empType.error && (
          <ErrorNotifier message={this.props.empType.error} />
        )}
      </React.Fragment>
    );
  }
}

AddEmployeeType.propTypes = {
  addEmpType: PropTypes.func.isRequired,
  empType: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  empType: state.empType,
});

export default connect(mapStateToProps, { addEmpType })(AddEmployeeType);
