import React, { Component } from "react";
import { Container, Table, Button, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import {
  getJobApproval,
  searchPublishJobs,
} from "../../../../redux/actions/rs/requirements/publishJobsAction";
import ParticularPublishedApproval from "./particularPublishedApproval";
import Searchbar from "../../../common/searchbar/SearchBar";

// --------------------------------------------------------------------------------

class ViewPublishingApproval extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      storeData: [],
      searchValue: "",
      errorSearch: "",
    };
    this.handleViewClick = this.handleViewClick.bind(this);
    this.backNavigate = this.backNavigate.bind(this);
    this.searchClick = this.searchClick.bind(this);
    this.searchChange = this.searchChange.bind(this);
  }


  componentDidMount() {
    this.props.getJobApproval();
  }

  handleViewClick(data) {
    this.setState({
      isEdit: !this.state.isEdit,
      storeData: [data],
    });
  }

  backNavigate() {
    this.setState({
      isEdit: false,
    });
    this.props.getJobApproval()
   
  }

  searchChange(e) {
    if (!e.target.value) {
      this.props.getJobApproval();
    }
    this.setState({
      [e.target.name]: e.target.value,
      errorSearch: "",
    });
  }

  searchClick() {
    if (this.state.searchValue) {
      let body = {
        search: this.state.searchValue,
      };
      this.props.searchPublishJobs(body);
    } else {
      this.setState({
        errorSearch: "Please enter keyword to search",
      });
    }
  }

  render() {
    console.log("worked")
 
    let shrink = this.props.sidebar ? "scale" : "no-scale";
    return (
      <div className={shrink}>
        <Container className="card container-card">
          <Row>
            {/* { !this.state.isEdit &&  <div style={{width:"100%",display:"flex",fontSize:"1.2rem",justifyContent:"center"}}>
           <span>View Published Jobs</span>
           </div>  } */}

            {/* {!this.state.isEdit && (
              <Searchbar
                onChange={this.searchChange}
                value={this.state.searchValue}
                placeholder="Search.."
                onSubmit={this.searchClick}
                title="search"
                onKeyPress={(e) => {
                  if (e.code === "Enter") {
                    this.searchClick();
                  }
                }}
                error={this.state.errorSearch}
                name="searchValue"
              />
             
            )} */}

            {!this.state.isEdit && (
              <Col style={{ marginTop: "1rem" }} md="12" className="p-0">
                <ul className="crm-flex-around  ul-head">
                  <li
                    style={{ width: "8%", fontSize: "14px", fontWeight: "500" }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    Job Id
                  </li>
                  <li
                    style={{ width: "8%", fontSize: "14px", fontWeight: "500" }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    Req. Id
                  </li>
                  <li
                    style={{
                      width: "10%",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    Category
                  </li>
                  <li
                    style={{
                      width: "12%",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    {" "}
                    Position
                  </li>

                  <li
                    style={{
                      width: "10%",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    Location
                  </li>
                  <li
                    style={{
                      width: "35%",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    {" "}
                    Primary Skills
                  </li>
                  <li
                    style={{
                      width: "10%",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    {" "}
                    Experience
                  </li>

                  <li
                    style={{
                      width: "12%",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    className="list-crm-item bold pad-rs-name"
                  >
                    {" "}
                    Qualification
                  </li>
                </ul>
              </Col>
            )}
            {!this.state.isEdit &&
              this.props.PublishJobs.approvedData.length > 0 &&
              this.props.PublishJobs.approvedData.map((item) => {
                return (
                  <Col md="12" className="p-0" key={item.pj_id}>
                    <ul
                      onClick={() => this.handleViewClick(item)}
                      className="rs-flex-around"
                      
                    >
                      <li
                        style={{ width: "8%", paddingLeft: "2%" }}
                        className="list-crm-table-item pad-rs-name"
                      >
                        {item.pj_id}
                      </li>

                      <li
                        style={{ width: "8%", paddingLeft: "2%" }}
                        className="list-crm-table-item pad-rs-name"
                      >
                        {item.pj_jobRequirementId}
                      </li>
                      <li
                        style={{ overflow: "hidden", width: "10%" }}
                        className="list-crm-table-item pad-rs-name"
                      >
                        {item.pj_jobCategory}
                      </li>

                      <li
                        style={{ overflow: "hidden", width: "12%" }}
                        className="list-crm-table-item pad-rs-name"
                      >
                        {item.pj_jobPosition}
                      </li>

                      <li
                        style={{ overflow: "hidden", width: "10%" }}
                        className="list-crm-table-item pad-rs-name"
                      >
                        {item.pj_jobLocation}
                      </li>
                      <li
                        style={{ overflow: "hidden", width: "35%" }}
                        className="list-crm-table-item pad-rs-name"
                      >
                        {item.pj_primarySkills}
                      </li>
                      <li
                        style={{ overflow: "hidden", width: "10%" }}
                        className="list-crm-table-item pad-rs-name"
                      >
                        {item.pj_minYearsOfExp}
                      </li>

                      <li
                        style={{ overflow: "hidden", width: "12%" }}
                        className="list-crm-table-item pad-rs-name"
                      >
                        {item.pj_qualifications}
                      </li>
                    </ul>
                  </Col>
                );
              })}
          </Row>
          {this.state.isEdit && (
            <ParticularPublishedApproval
              getJobs={this.props.getJobApproval}
              back={this.backNavigate}
              storeData={this.state.storeData}
            />
          )}
          {/* {this.props.PublishJobs.error && (
            <ErrorNotifier message={this.props.PublishJobs.error} />
          )} */}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  PublishJobs: state.PublishJobs,
});



export default connect(mapStateToProps, {
  getJobApproval,
  searchPublishJobs,
})(ViewPublishingApproval);
