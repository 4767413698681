/*  eslint-disable  */
import axios from "axios";
import {
  PRODUCTLIST_ADD,
  PRODUCTLIST_LIST,
  PRODUCTLIST_UPDATE,
  PRODUCTLIST_SEARCH,
  GET_ERRORS,
} from "./types";
import {
  ADD_PRODUCTLIST,
  GET_PRODUCTLIST,
  UPDATE_PRODUCTLIST,
  SEARCH_PRODUCTLIST,
} from "../../utils/routes";
import { setMessage } from "./salesActions";

export const addProductList = (data, history) => (dispatch) => {
  axios
    .post(ADD_PRODUCTLIST, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        history.push("/pie/productlist");
      }, 2000);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};

export const getProductLists = () => (dispatch) => {
  axios
    .get(GET_PRODUCTLIST, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: PRODUCTLIST_LIST,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};

export const updateProductList = (data, history) => (dispatch) => {
  axios
    .post(UPDATE_PRODUCTLIST, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
      }, 2000);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};

export const searchProductList = (data) => (dispatch) => {
  axios
    .post(SEARCH_PRODUCTLIST, data, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: PRODUCTLIST_SEARCH,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};
