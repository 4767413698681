/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import {
  getIdea,
  updateIdea,
  searchIdea,
} from "../../../../redux/actions/ideaActions";
import {
  refresh,
  submitValidator,
  valid,
  Validator,
} from "../../../common/Validator";
import ViewIdea from "./ViewIdea";
import Hover from "../../../aside/Hover";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import FormComponent from "../../../common/FormComponent";

class InnovativeIdea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.idea.data,
      permissionlist: this.props.auth.permissionlist || {},
      subItem: {},
      selection: null,
      hover: false,
      cords: {},
      mouse: {},
      popup: false,
      searchTerm: "",
      remarks: "",
      selected: "",
      modal: false,
      message: null,
      error: null,
    };
    this.getData = this.getData.bind(this);
    this.select = this.select.bind(this);
    this.search = this.search.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.cancel = this.cancel.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.toggleHoverOn = this.toggleHoverOn.bind(this);
    this.toggleHoverOff = this.toggleHoverOff.bind(this);
    this.toggle1 = this.toggle1.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.idea !== this.props.idea) {
      this.setState({
        data: this.props.idea.data,
      });
    }

    if (prevProps.auth !== this.props.auth) {
      this.setState({
        permissionlist: this.props.auth.permisssionlist,
      });
    }
  }
  search(e) {
    e.preventDefault();
    this.setState({
      searchTerm: e.target.value,
    });
    this.onSearch(e.target.value);
  }
  onSearch(value) {
    let data = {};
    let isInteger = value.length && /^[0-9]*$/.test(value);
    if (isInteger) {
      data.ideaCode = Number(value);
      data.problemStmt = "null";
    } else {
      data.ideaCode = 0;
      data.problemStmt = value;
    }
    this.props.searchIdea(data);
  }
  select(item) {
    this.setState({
      selection: item,
      selected: item.ideaCode,
      popup: true,
    });
  }

  cancel() {
    this.setState({
      selection: false,
    });
    this.getData();
  }

  onChange(e) {
    Validator(e.target);
    e.preventDefault();
    this.setState({
      remarks: e.target.value,
    });
  }

  /*
To validate the remarks form during onSubmit using the subitValidator and errors function
*/
  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    const data = this.state.data.filter((item) => {
      if (item.ideaCode === this.state.subItem) {
        return item;
      } else {
        return null;
      }
    });
    data[0].remarks = this.state.remarks;
    const ideadrag = {
      ideaCode: data[0].ideaCode,
      problemStmt: data[0].problemStmt,
      benefits: data[0].benefits,
      status: data[0].status,
      remarks: data[0].remarks,
      businessDesc: data[0].businessDesc,
      useCase: data[0].useCase,
      solution: data[0].solution,
      customers: data[0].customers,
    };
    if (valid) {
      this.props.updateIdea(ideadrag);
      this.toggle();
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /* 
This toggle function is for the model toggle after the submission of remarks
*/
  toggle() {
    this.setState({
      modal: !this.state.modal,
      remarks: "",
    });
  }

  toggle1() {
    this.setState({
      modal: !this.state.modal,
      remarks: "",
    });
    this.getData();
  }
  /*
First time calling the getData and refresh methods
getData method for getting the idea list 
refresh method for resetting the errors object
*/
  componentDidMount() {
    this.getData();
    refresh();
  }

  /*
Here calling the redux getIdea method to getting the idea list
*/
  getData() {
    this.props.getIdea();
  }

  toggleHoverOn(e, item) {
    this.setState({
      hover: true,
      cords: {
        one: item.ideaCode,
        two: item.remarks,
      },
      mouse: {
        x: e.screenX,
        y: e.screenY,
      },
    });
  }
  toggleHoverOff() {
    this.setState({
      hover: false,
    });
  }
  onDragOver(e) {
    e.preventDefault();
  }
  onDragStart(e, item) {
    e.dataTransfer.setData("item", item.ideaCode);
    this.setState({
      subItem: item.ideaCode,
    });
  }

  async onDrop(e, cat) {
    let id = e.dataTransfer.getData("item");
    let newData;
    newData = this.state.data.filter((item) => {
      if (item.ideaCode === parseInt(id, 10)) {
        item.status = cat;
      }
      return newData;
    });
    this.setState({
      ...this.state,
      newData,
    });
    await this.toggle();
  }

  render() {
    let shrink;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");

    /*
This if condition for redirecting to the login page after the refresh of the page
*/
    const {
      data,
      selection,
      selected,
      hover,
      cords,
      popup,
      modal,
      remarks,
      permissionlist,
    } = this.state;
    let currentPermission;
    for (let x in permissionlist.response.permissions1) {
      if (permissionlist.response.permissions1[x].subMenuId === "1.2") {
        currentPermission = permissionlist.response.permissions1[x];
      }
    }

    return (
      <div className={shrink}>
        <Modal isOpen={modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle1}>Confirm changes?</ModalHeader>
          <ModalBody>
            <Form className="p-3" onSubmit={this.onSubmit} noValidate>
              <FormComponent
                labelClass="bold label-sm"
                label="Remarks"
                type="text"
                name="remarks"
                change={this.onChange}
                value={remarks}
                required={true}
              />
              <FormGroup className="center">
                <button type="submit" className="btn login-button white-text">
                  Submit
                </button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
        {!selection ? (
          <Card className="container-card m-2">
            <input
              type="text"
              onChange={this.search}
              value={this.state.searchTerm}
              className="searchfield form-control-sm"
              style={{ width: "205px", marginRight: "40px", marginTop: "10px" }}
              placeholder="IdeaCode/ProblemStatement"
            />
            {hover && (
              <Hover
                labels={{ one: "ideaCode", two: "Remarks" }}
                cords={cords}
                mouse={this.state.mouse}
              />
            )}
            {currentPermission.create1 ? (
              <div className="div">
                <Link to="/pie/new/idea">
                  <button
                    className="btn add-button white-text label-sm"
                    style={{ position: "absolute", top: "0px", left: "0px" }}
                  >
                    Add
                  </button>
                </Link>
              </div>
            ) : null}
            <h4 onClick={this.back} className="pointer center bold pt-2">
              Innovations
            </h4>
            <Row className="p-1">
              <Col
                md="4"
                onDragOver={this.onDragOver}
                onDrop={(e) => this.onDrop(e, "New")}
                className="card p-2"
              >
                <h6 className="center bold m-2">New Ideas</h6>
                <ul className="idea-flex-around ul-head">
                  <li className="list-idea-item pad-id bold">ID</li>
                  <li className="list-idea-item flex-1 bold">
                    Problem Statement
                  </li>
                </ul>

                {data &&
                  data.map((item) => {
                    if (item.status === "New") {
                      return (
                        <ul
                          key={item.ideaCode}
                          onClick={() => {
                            this.select(item);
                          }}
                          onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                          onMouseLeave={this.toggleHoverOff}
                          draggable
                          onDragStart={(e) => this.onDragStart(e, item)}
                          className={`list flex-around pointer ${
                            selected &&
                            selected === item.ideaCode &&
                            "selected-item"
                          } InqList`}
                        >
                          <li className="list-table-item pad-id">
                            {item.ideaCode}
                          </li>
                          <li className="list-table-item flex-1">
                            {item.problemStmt}
                          </li>
                        </ul>
                      );
                    } else {
                      return null;
                    }
                  })}
              </Col>
              <Col
                md="4"
                onDragOver={this.onDragOver}
                onDrop={(e) => this.onDrop(e, "Evolution")}
                className="card p-2"
              >
                <h6 className="center bold m-2">Evolution</h6>
                <ul className="idea-flex-around ul-head">
                  <li className="list-idea-item pad-id bold">ID</li>
                  <li className="list-idea-item flex-1 bold">
                    Problem Statement
                  </li>
                </ul>
                {data &&
                  data.map((item) => {
                    if (item.status === "Evolution") {
                      return (
                        <ul
                          key={item.ideaCode}
                          onClick={() => {
                            this.select(item);
                          }}
                          onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                          onMouseLeave={this.toggleHoverOff}
                          draggable
                          onDragStart={(e) => this.onDragStart(e, item)}
                          className={`list flex-around pointer ${
                            selected &&
                            selected === item.ideaCode &&
                            "selected-item"
                          } InqList`}
                        >
                          <li className="list-table-item pad-id">
                            {item.ideaCode}
                          </li>
                          <li className="list-table-item flex-1">
                            {item.problemStmt}
                          </li>
                        </ul>
                      );
                    } else {
                      return null;
                    }
                  })}
              </Col>
              <Col
                md="4"
                className="card p-2"
                onDrop={(e) => this.onDrop(e, "Complete")}
                onDragOver={this.onDragOver}
              >
                <h6 className="center bold m-2">Others</h6>
                <ul className="idea-flex-around ul-head">
                  <li className="list-idea-item pad-id bold">ID</li>
                  <li className="list-idea-item flex-1 bold">
                    Problem Statement
                  </li>
                </ul>
                {data &&
                  data.map((item) => {
                    if (item.status !== "New" && item.status !== "Evolution") {
                      return (
                        <ul
                          key={item.ideaCode}
                          onClick={() => {
                            this.select(item);
                          }}
                          onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                          onMouseLeave={this.toggleHoverOff}
                          // draggable
                          // onDragStart={(e) => this.onDragStart(e, item)}
                          className={`list flex-around pointer ${
                            selected &&
                            selected === item.saleId &&
                            "selected-item"
                          } InqList`}
                        >
                          <li className="list-table-item pad-id">
                            {item.ideaCode}
                          </li>
                          <li className="list-table-item flex-1">
                            {item.problemStmt}
                          </li>
                        </ul>
                      );
                    } else {
                      return null;
                    }
                  })}
              </Col>
              {this.state.message && <Notifier message={this.state.message} />}
              {this.state.error && <ErrorNotifier message={this.state.error} />}
            </Row>
          </Card>
        ) : (
          <ViewIdea
            idea={selection}
            cancel={this.cancel}
            ideaData={this.getData}
          />
        )}
        {this.props.idea.message && (
          <Notifier message={this.props.idea.message} />
        )}
      </div>
    );
  }
}

InnovativeIdea.propTypes = {
  idea: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  getIdea: PropTypes.func.isRequired,
  updateIdea: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  searchIdea: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  idea: state.idea,
  errors: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps, { getIdea, updateIdea, searchIdea })(
  InnovativeIdea
);
