import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Validator,
  submitValidator,
  errors,
  valid,
  refresh,
} from "../../../common/Validator";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import { addEmpPersonalInfo } from "../../../../redux/actions/hcm/hr/emp_management/empPersonalInfoAction";
import { getUserBusinessGeowise } from "../../../../redux/actions/hcm/business_setup/businessGeowiseAction";
import { getUserBusinessCountries } from "../../../../redux/actions/hcm/business_setup/businessCountriesAction";

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      firstName: "",
      middleName: "",
      lastName: "",
      dob: "",
      gender: "",
      empType: "",
      geography: "",
      country: "",
      aadharNo: "",
      panNo: "",
      location: "",
      doj: "",
      legalEntityName: "",
      businessUnit: "",
      designation: "",
      grade: "",
      dialingCode: "",
      mobile: "",
      personalEmail: "",
      email: "",
      bloodGroup: "",
      socialSecurityNo: "",
      file: [],
      fileError: "",
      role: "1",
      message: "",
      error: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.reset = this.reset.bind(this);
  }

  onChange(e) {
    e.preventDefault();
    Validator(e.target);
    if (e.target.name === "country") {
      this.setState({
        dialingCode: e.target.value.split(",")[1],
      });
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  handleFile = (e) => {
    Validator(e.target);
    let allowedExtension = /(\.png|\.jpeg)$/i;
    if (allowedExtension.exec(e.target.files[0].name)) {
      if ((e.target.files[0].size / 1024 / 1024).toFixed(2) < 2) {
        this.setState({
          file: e.target.files[0] || [],
          fileError: "",
        });
      } else {
        this.setState({
          file: e.target.files[0],
          fileError: "File size must less than 2MB",
        });
      }
    } else {
      this.setState({
        file: e.target.files[0],
        fileError: "File type not allowed",
      });
    }
  };

  componentDidMount() {
    refresh();
    this.props.getUserBusinessGeowise();
    this.props.getUserBusinessCountries();
  }

  reset() {
    this.setState({
      email: "",
      password: "",
      password2: "",
      dialingCode: "",
      mobile: "",
      firstName: "",
      middleName: "",
      lastName: "",
      dob: "",
      gender: "",
      legalEntityName: "",
      eType: "",
      designation: "",
      doj: "",
      bloodGroup: "",
      role: "",
      aadharNo: "",
      panNo: "",
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    const { fileError } = this.state;
    let fd = new FormData();
    fd.append("title", this.state.title);
    fd.append("firstName", this.state.firstName);
    fd.append("middleName", this.state.middleName);
    fd.append("lastName", this.state.lastName);
    fd.append("dob", this.state.dob);
    fd.append("geoName", this.state.geography);
    fd.append("countryName", this.state.country.split(",")[0]);
    fd.append("gender", this.state.gender);
    fd.append("dialingCode", this.state.dialingCode);
    fd.append("mobileNumber", this.state.mobile);
    fd.append("personalEmailId", this.state.personalEmail);
    fd.append("aadharNo", this.state.aadharNo);
    fd.append("panNo", this.state.panNo);
    fd.append("bloodGroup", this.state.bloodGroup);
    fd.append("socialSecurityNo", this.state.socialSecurityNo);
    fd.append("personalPhoto", this.state.file);

    if (valid && !fileError) {
      this.props.addEmpPersonalInfo(fd, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  render() {
    let shrink;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");

    return (
      <>
        <Container className="card container-card">
          <i
            className="material-icons pointer"
            style={{
              position: "absolute",
              top: "5px",
              right: "5px",
              color: "#C00",
            }}
            onClick={this.props.back}
          >
            cancel
          </i>
          <h5 className="center bold mt-2">Add Personal Information</h5>
          <Form onSubmit={this.onSubmit} className="p-3" noValidate>
            <Row>
              <Col md="4">
                <FormGroup>
                  <Label className="bold label-sm">Title</Label>
                  <Input
                    type="select"
                    name="title"
                    className="form-control-sm"
                    value={this.state.title}
                    onChange={this.onChange}
                    required={true}
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    <option value="Mr.">Mr.</option>
                    <option value="Ms.">Ms.</option>
                    <option value="Miss.">Miss.</option>
                    <option value="Mrs.">Mrs.</option>
                  </Input>
                  {errors.gender && (
                    <FormText className="error">{errors.gender}</FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">First Name</Label>
                  <Input
                    type="text"
                    name="firstName"
                    className="form-control-sm"
                    value={this.state.firstName}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    placeholder="First Name (required)"
                    required={true}
                  />
                  {errors.firstName && (
                    <FormText className="error">{errors.firstName}</FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">Middle Name</Label>
                  <Input
                    type="text"
                    name="middleName"
                    className="form-control-sm"
                    value={this.state.middleName}
                    onChange={this.onChange}
                    placeholder="Middle name "
                    onBlur={this.onBlur}
                  />
                  {errors.middleName && (
                    <FormText className="error">{errors.middleName}</FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">Last Name</Label>
                  <Input
                    type="text"
                    name="lastName"
                    className="form-control-sm"
                    value={this.state.lastName}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    placeholder="Last name (required)"
                    required={true}
                  />
                  {errors.lastName && (
                    <FormText className="error">{errors.lastName}</FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">Date of Birth</Label>
                  <Input
                    type="date"
                    name="dob"
                    className="form-control-sm"
                    value={this.state.dob}
                    onChange={this.onChange}
                    placeholder="Date of birth (required)"
                    required={true}
                  />
                  {errors.dob && (
                    <FormText className="error">{errors.dob}</FormText>
                  )}
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label className="bold label-sm">Gender</Label>
                  <Input
                    type="select"
                    name="gender"
                    className="form-control-sm"
                    value={this.state.gender}
                    onChange={this.onChange}
                    required={true}
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    <option value="Female">Female</option>
                    <option value="Male">Male</option>
                    <option value="Other">Other</option>
                  </Input>
                  {errors.gender && (
                    <FormText className="error">{errors.gender}</FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">Blood Group</Label>
                  <Input
                    type="select"
                    name="bloodGroup"
                    className="form-control-sm"
                    value={this.state.bloodGroup}
                    onChange={this.onChange}
                    required={true}
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    <option value="O +ive">O +ive</option>
                    <option value="O -ive">O -ive</option>
                    <option value="A +ive">A +ive</option>
                    <option value="A -ive">A -ive</option>
                    <option value="B +ive">B +ive</option>
                    <option value="B -ive">B -ive</option>
                    <option value="AB +ive">AB +ive</option>
                    <option value="AB -ive">AB -ive</option>
                  </Input>
                  {errors.bloodGroup && (
                    <FormText className="error">{errors.bloodGroup}</FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">Geography</Label>
                  <Input
                    type="select"
                    name="geography"
                    className="form-control-sm"
                    value={this.state.geography}
                    onChange={this.onChange}
                    required={true}
                  >
                    <option hidden value="">
                      Select...
                    </option>
                    {this.props.businessGeowise.data.length > 0 &&
                      this.props.businessGeowise.data.map((item) => {
                        return (
                          <option key={item.id} value={item.geoName}>
                            {item.geoName}
                          </option>
                        );
                      })}
                  </Input>
                  {errors.geography && (
                    <FormText className="error">{errors.geography}</FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">Country</Label>
                  <Input
                    type="select"
                    name="country"
                    className="form-control-sm"
                    value={this.state.country}
                    onChange={this.onChange}
                    required={true}
                  >
                    <option hidden value="">
                      Select...
                    </option>
                    {this.props.businessCountries.data.length > 0 &&
                      this.props.businessCountries.data.map((item) => {
                        if (this.state.geography === item.geoName) {
                          return (
                            <option
                              key={item.countryId}
                              value={`${item.countryName},${item.dialingCode}`}
                            >
                              {item.countryName}
                            </option>
                          );
                        }
                      })}
                  </Input>
                  {errors.country && (
                    <FormText className="error">{errors.country}</FormText>
                  )}
                </FormGroup>
                <Row>
                  <Col md="2" className="p-0">
                    <FormGroup>
                      <Label className="bold label-sm"> </Label>
                      <Input
                        type="number"
                        name="dialingCode"
                        className="form-control-sm"
                        value={this.state.dialingCode}
                        onChange={this.onChange}
                        required={true}
                        disabled={true}
                      />
                      {errors.dialingCode && (
                        <FormText className="error">
                          {errors.dialingCode}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="10" className="p-0">
                    <FormGroup>
                      <Label className="bold label-sm">Mobile No.</Label>
                      <Input
                        type="tel"
                        name="mobile"
                        className="form-control-sm"
                        value={this.state.mobile}
                        onChange={this.onChange}
                        placeholder="Mobile (required)"
                        pattern="[789][0-9]{9}"
                        required={true}
                      />
                      {errors.mobile && (
                        <FormText className="error">{errors.mobile}</FormText>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label className="bold label-sm">Personal Email Id</Label>
                  <Input
                    type="email"
                    name="personalEmail"
                    className="form-control-sm"
                    value={this.state.personalEmail}
                    onChange={this.onChange}
                    placeholder="personalEmail (required)"
                    required={true}
                  />
                  {errors.personalEmail && (
                    <FormText className="error">
                      {errors.personalEmail}
                    </FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">Aadhar No.</Label>
                  <Input
                    type="text"
                    name="aadharNo"
                    className="form-control-sm"
                    value={this.state.aadharNo}
                    onChange={this.onChange}
                    placeholder="Aadhar Number"
                    required={true}
                    pattern="^[0-9]{12}$"
                  />
                  {errors.aadharNo && (
                    <FormText className="error">{errors.aadharNo}</FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">PAN No.</Label>
                  <Input
                    type="text"
                    name="panNo"
                    className="form-control-sm"
                    value={this.state.panNo}
                    onChange={this.onChange}
                    placeholder="PAN Number"
                    required={true}
                    pattern="^[A-Z]{5}[0-9]{4}[A-Z]{1}$"
                  />
                  {errors.panNo && (
                    <FormText className="error">{errors.panNo}</FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">Social Security No.</Label>
                  <Input
                    type="text"
                    name="socialSecurityNo"
                    className="form-control-sm"
                    value={this.state.socialSecurityNo}
                    onChange={this.onChange}
                    placeholder="Social Security No"
                    pattern="^(?!666|000)[001-899]{3}-[01-99]{2}-[0001-9999]{4}$"
                  />
                  {errors.socialSecurityNo && (
                    <FormText className="error">
                      {errors.socialSecurityNo}
                    </FormText>
                  )}
                </FormGroup>
                <div className="file-div ">
                  <FormGroup className="file-display mr-1">
                    <Label className="bold label-sm">Photo</Label>
                    <Input
                      disabled
                      type="text"
                      value={this.state.file.name || ""}
                      className="form-control-sm "
                      required={true}
                    />
                    {this.state.fileError && (
                      <FormText className="error ">
                        {this.state.fileError}
                      </FormText>
                    )}
                    {errors.personalPhoto && (
                      <FormText className="error">
                        {errors.personalPhoto}
                      </FormText>
                    )}
                  </FormGroup>
                  <FormGroup className="file-browse-btn-div">
                    <label className="btn label-sm file-browse-btn">
                      Browse
                      <Input
                        type="file"
                        name="personalPhoto"
                        style={{
                          display: "none",
                          marginTop: "23px",
                          marginLeft: "-28px",
                        }}
                        className="form-control-sm file-input"
                        onChange={this.handleFile}
                        required={true}
                        accept=".png"
                      />
                    </label>
                  </FormGroup>
                </div>
              </Col>
            </Row>
            <div className="center">
              <button
                type="submit"
                className="btn mt-3 center login-button white-text"
              >
                Add
              </button>
            </div>
          </Form>
        </Container>
        {this.state.message ? <Notifier message={this.state.message} /> : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
        {this.props.empPersonalInfo.message ? (
          <Notifier message={this.props.empPersonalInfo.message} />
        ) : null}
        {this.props.empPersonalInfo.error ? (
          <ErrorNotifier message={this.props.empPersonalInfo.error} />
        ) : null}
      </>
    );
  }
}

AddUser.propTypes = {
  addEmpPersonalInfo: PropTypes.func.isRequired,
  empPersonalInfo: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getUserBusinessGeowise: PropTypes.func.isRequired,
  businessGeowise: PropTypes.object.isRequired,
  getUserBusinessCountries: PropTypes.func.isRequired,
  businessCountries: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  empPersonalInfo: state.employees,
  businessGeowise: state.businessGeowise,
  businessCountries: state.businessCountries,
});

export default connect(mapStateToProps, {
  addEmpPersonalInfo,
  getUserBusinessGeowise,
  getUserBusinessCountries,
})(AddUser);
