import React, { Component } from "react";
import { Form, Row, Col, FormGroup,Button, Label, Input, FormText } from "reactstrap";

import PropTypes from "prop-types";
import axios from 'axios'
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../common/Validator";
import FormComponent from "../../../common/FormComponent";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import { TA_EMP_DETAIL,GET_HR_LEAD_RS} from '../../../../utils/routes'
import {getHrLeadRs} from '../../../../redux/actions/rs/configuration/hrleadAction'
import { updateAssignHr } from '../../../../redux/actions/rs/requirements/assignedHr/assignedHr';
import { connect } from 'react-redux';
import Select from 'react-select'


let errorCustomer=false
class UpdateAssignedHr extends Component {
  constructor(props) {
    super(props);
    this.state = {
      empName: this.props.fetchData.assignedTo,
      empBodyName:"",
      selectedOptionError:null,
      roleOptionError:null,
      role:this.props.fetchData.leadRole,
      rec:this.props.fetchData.recruiter,
      remarks:this.props.fetchData.remarks,
      crucialArr: [
        { key: "Primary Lead", value: "Primary Lead" },
        { key: "Secondary Lead ", value: "Secondary Lead" },
      
      ],
      crucialArrOne:[ { key: "Recruiter", value: "Recruiter" }],
      isLead:[{key:"Yes",value:"Yes"},{key:"No",value:"No"}],
      leadValue:this.props.fetchData.leadRole ? "Yes":"No" ,
      getHrData:[],
};

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleCustomerChange = this.handleCustomerChange.bind(this)
    this.updateCheck = this.updateCheck.bind(this)
    this.getDataPrimSec = this.getDataPrimSec.bind(this)
  }

//   componentDidUpdate(prevState,prevProps){
//  if(prevState.role !==this.state.role ){
//   this.updateCheck(this.state.role)
//  }
//   }

 
  updateCheck(value,valueTwo){
    console.log(value)
    if(value === "Primary Lead" ){
    this.setState({
      role:value,
     })
     this.getDataPrimSec()
      }
      else if(value === "Secondary Lead" ){
      this.setState({
         role:value,
         })
         this.getDataPrimSec()
        }
    else if(value === "Recruiter") {
      this.setState({
        role:valueTwo
      })
      this.getData()
     
    }
  }

  getDataPrimSec(){
    axios
    .get(`${GET_HR_LEAD_RS}/select`, { withCredentials: true })
    .then((res) => {
      console.log(res);
      this.setState({
        getHrData: res.data,
      });
    })
    .catch((err) => {
      console.log("err", err);
    });
  }

  


  onChange(e) {
    if(e.target.name === "role"){
    
      this.setState({
        roleOptionError:null
      })
      if(e.target.value === "Primary Lead" ){
       this.setState({
          role:e.target.value,
         })
         this.getDataPrimSec() 
        }
        else if(e.target.value === "Secondary Lead" ){
       
          this.setState({
            role:e.target.value,
          })
          this.getDataPrimSec() 
          }
      else if(e.target.value === "Recruiter") {
      
        this.getData()
        this.setState({
          role:e.target.value
        })
      }
    }
    else if(e.target.name === "leadValue"){
      this.setState({
        leadValue:"Yes"
      })
     }
     else if(e.target.name === "recValue"){
       this.setState({
         leadValue:"No"
       })
     }
    else{
      this.setState({
        [e.target.name]:e.target.value
      })
     
    }
    
  }

  // onchange event for hr list change
  handleCustomerChange(empName) {
    console.log(empName)
    this.setState({
      empBodyName: empName.label,
      empNo:empName.value
    });
    if (empName) {
      this.setState({ empName, selectedOptionError: null });
    } else {
      this.setState({
        selectedOptionError: "This field is mandatory",
      });
    }
  }

  // get hrlist from config 
  getData() {
    axios
      .get(`${TA_EMP_DETAIL}`, { withCredentials: true })
      .then((res) => {
        console.log(res);
        this.setState({
          getHrData: res.data,
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  }


  // update function for updating hr name
  onSubmit(e) {
    console.log("working")
    e.preventDefault();
    submitValidator(e.target);
    if (!this.state.empBodyName) {
      this.setState({
        selectedOptionError: "This field is mandatory",
      });
      errorCustomer = true;
    } else {
      this.setState({
        selectedOptionError: "",
      });
      errorCustomer = false;
    }
    let data = {
      id:this.props.fetchData.id,
      jobRequirementId: this.props.requirementId,
      assignedTo: this.state.empBodyName,
      leadRole:this.state.role,
      remarks:this.state.remarks,
      createdBy: localStorage.getItem("id"),
    };
    let dataTwo = {
      id:this.props.fetchData.id,
      jobRequirementId: this.props.requirementId,
      assignedTo: this.state.empBodyName,
      recruiter:this.state.role,
      remarks:this.state.remarks,
      createdBy: localStorage.getItem("id"),
    };
    if (!errorCustomer) {
      if(this.state.role === "Recruiter"){
        this.props.updateAssignHr(dataTwo,this.props.navigate,)
      }
      else{
        this.props.updateAssignHr(data,this.props.navigate,)
      }
    
      
    } else {
      this.setState({
        error: "Please enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }





 

  

  /*
componentDidMount contains the error refresh method and here set the state for calling render method
*/
  componentDidMount() {
  this.updateCheck(this.state.role,this.state.rec)
   
    refresh();
    this.setState({
      empBodyName:this.props.fetchData.assignedTo,
      empName: this.props.fetchData.assignedTo,
      message: "",
      error: "",
    });
  }

 

  render() {
    console.log(this.props.fetchData.assignedTo)
    let options3 = [];
    if(this.state.role === "Recruiter"){
      for (let i = 0; i < this.state.getHrData.length; i++) {
        var x1 = {};
        x1["value"] = this.state.getHrData[i].empNo;
        x1[
          "label"
        ] = `${this.state.getHrData[i].firstName}-${this.state.getHrData[i].empNo}`
  
        options3.push(x1);
      }
    }
    else if(this.state.role === "Primary Lead" || this.state.role === "Secondary Lead" ) {
      for (let i = 0; i < this.state.getHrData.length; i++) {
        var x1 = {};
        x1["value"] = this.state.getHrData[i].empNo;
        x1[
          "label"
        ] = `${this.state.getHrData[i].empName}`
  
        options3.push(x1);
      }
    }
  
    const {
    message,
    error,
    role,
    remarks


    } = this.state;

    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Update Assigned HR </h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.navigate}
        >
          cancel
        </i>
        <div style={{marginLeft:"15rem"}}>
            <ul className="CustDetails-ul">
              <li style={{ marginTop: "1rem" }}>
              <div style={{ display: "flex" }}>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      width: "9rem",
                    }}
                    className="label-sm bold customer-span-label-col-1"
                  >
                     Lead Recruiter
                  </span>
               
              <FormGroup>
              
                <Input
                 type="radio"
                 checked={this.state.leadValue === "Yes"}
                  name="leadValue"
                  style={{cursor:"pointer"}}
                  value={this.state.leadValue}
                  onChange={this.onChange}
               >
                 </Input>
                 </FormGroup>
                   <span
                    style={{
                      marginLeft:"1rem",
                      fontSize: "14px",
                      fontWeight: "500",
                      width: "7rem",
                    }}
                    className="label-sm bold customer-span-label-col-1"
                  >
                    Recruiter
                  </span>
                 
              <FormGroup>
              <Input
                  style={{cursor:"pointer"}}
                  type="radio"
                  name="recValue"
                  checked={this.state.leadValue === "No"}
                  value={this.state.leadValue}
                  onChange={this.onChange}
               >
                </Input>
              </FormGroup>
             
</div>
             
              <div style={{ display: "flex",marginTop:"2rem" }}>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      width: "150px",
                    }}
                    className="label-sm bold customer-span-label-col-1"
                  >
                    Role
                  </span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "40%",
                    }}
                  >
              <FormGroup>
              
                <Input
                  tabIndex="10"
                  type="select"
                  name="role"
                  className="form-control-sm"
                  value={role}
                  onChange={this.onChange}
               
                  // required={true}
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {  this.state.leadValue === "Yes" ?  this.state.crucialArr.map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {item.value}
                      </option>
                    );
                  }): this.state.leadValue === "No" && this.state.crucialArrOne.map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {item.value}
                      </option>
                    );
                  })}
                </Input>
               
              
              </FormGroup>
              {this.state.roleOptionError && (
                      <span style={{color:"red",fontSize:"12px"}}>
                        {this.state.roleOptionError}
                      </span>
                    )}
                  </div>
                </div>
              
                <div style={{ display: "flex",marginTop:"1rem" }}>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      width: "150px",
                    }}
                    className="label-sm bold customer-span-label-col-1"
                  >
                    Recruiter Name
                  </span>
                  <div
                    style={{
                     
                      display: "flex",
                      flexDirection: "column",
                      width: "40%",
                    }}
                  >
                    <FormGroup style={{ width: "100%" }}>
                      <Select
                        tabIndex="1"
                        value={this.state.empName}
                        onChange={this.handleCustomerChange}
                        options={options3}
                        required
                       
                      />
                    </FormGroup>
                    {this.state.selectedOptionError && (
                      <span style={{color:"red",fontSize:"12px"}}>
                        {this.state.selectedOptionError}
                      </span>
                    )}
                   
                  </div>
                </div>
               
                <div style={{ display: "flex",marginTop:"1rem" }}>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      width: "150px",
                    }}
                    className="label-sm bold customer-span-label-col-1"
                  >
                    Remarks
                  </span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "40%",
                    }}
                  >
              <FormComponent
                index="16"
               type="text"
                name="remarks"
                change={this.onChange}
                value={remarks}
               
              
              />
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "1rem",
            }}
          >
            <Button onClick={this.onSubmit} className="btn login-button white-text">Update</Button>
          </div>

        {message && <Notifier message={message} />}
        {error && <ErrorNotifier message={error} />}
      </React.Fragment>
    );
  }
}

UpdateAssignedHr.propTypes = {
 
  
};

const mapStateToProps = (state) => ({
  HrNotice: state.HrNotice,
});

export default connect(mapStateToProps, { getHrLeadRs,updateAssignHr })( UpdateAssignedHr);
