import React, { Component } from "react";
import { FormText } from "reactstrap";
import axios from "axios";
import {
  CREATE_SERVICE_ASSIGN,
  UPDATE_SERVICE_ASSIGN,
  DELETE_SERVICE_ASSIGN,
} from "../../../../utils/routes";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getEmployeeDetails } from "../../../../redux/actions/employeeAction";
import DeleteIcon from "@material-ui/icons/Delete";

class ProjectRow extends Component {
  constructor(props) {
    super(props);
    const {
      assignmentId,
      assignedTo,
      startDate,
      endDate,
      percentage,
      description,
    } = props.projectData;
    this.state = {
      assignmentId: assignmentId,
      assignedTo: assignedTo,
      startDate: startDate,
      endDate: endDate,
      percentage: percentage,
      description: description,
      serviceTeamEdit: false,
      percentageError: "",
      message: "",
      error: "",
    };
    this.onChange = this.onChange.bind(this);
    this.serviceTeamCreate = this.serviceTeamCreate.bind(this);
    this.serviceTeamUpdate = this.serviceTeamUpdate.bind(this);
    this.serviceTeamDelete = this.serviceTeamDelete.bind(this);
    this.serviceTeamEdit = this.serviceTeamEdit.bind(this);
  }

  /*
onChange function for getting the changed values
*/
  onChange(e) {
    if (e.target.name === "percentage") {
      if (e.target.value >= 0 && e.target.value <= 100) {
        this.setState({
          [e.target.name]: e.target.value,
          percentageError: "",
        });
      } else {
        this.setState({
          percentageError: "Range from 0 to 100",
        });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  /*
componentDidUpdate function to set the pqProjectId
*/
  componentDidUpdate() {
    if (this.state.assignmentId !== this.props.projectData.serviceAssignId) {
      this.setState({
        assignmentId: this.props.projectData.serviceAssignId,
      });
    }
  }

  /*
componentDidMount function to get all employee details
*/
  componentDidMount() {
    this.props.getEmployeeDetails();
  }

  /*
serviceTeamCreate function to create the new service assignment data
*/
  serviceTeamCreate() {
    const { percentageError } = this.state;
    let data = {
      serviceId: this.props.serviceId,
      assignedTo: this.state.assignedTo,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      percentage: this.state.percentage,
      description: this.state.description,
      createdBy: localStorage.getItem("id"),
    };
    if (!percentageError) {
      axios
        .post(CREATE_SERVICE_ASSIGN, data, { withCredentials: true })
        .then((res) => {
          this.setState({
            message: res.data,
          });
        })
        .catch((err) => {
          this.setState({
            error: err.message,
          });
        });
      setTimeout(() => {
        this.setState({
          message: "",
          error: "",
        });
        this.props.getProjectQuote();
      }, 2000);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
serviceTeamUpdate function is first time it will create the new data for second time it will update the existing data
*/
  serviceTeamUpdate() {
    const { percentageError } = this.state;
    let data = {
      serviceId: this.props.serviceId,
      serviceAssignId: this.state.assignmentId,
      assignedTo: this.state.assignedTo,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      percentage: this.state.percentage,
      description: this.state.description,
      // createdBy: localStorage.getItem('id')
    };
    if (!percentageError) {
      axios
        .post(UPDATE_SERVICE_ASSIGN, data, { withCredentials: true })
        .then((res) => {
          this.setState({
            message: res.data,
          });
          setTimeout(() => {
            this.setState({
              message: "",
              serviceTeamEdit: !this.state.serviceTeamEdit,
            });
            this.props.getProjectQuote();
          }, 2000);
        })
        .catch((err) => {
          this.setState({
            error: err.message,
          });
          setTimeout(() => {
            this.setState({
              error: "",
            });
          }, 2000);
        });
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
serviceTeamDelete function to delete the product quote row 
*/
  serviceTeamDelete() {
    let data = {
      serviceAssignId: this.state.assignmentId,
    };
    axios
      .post(DELETE_SERVICE_ASSIGN, data, { withCredentials: true })
      .then((res) => {
        this.setState({
          message: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          error: err.message,
        });
      });
    setTimeout(() => {
      this.setState({
        message: "",
        error: "",
      });
      this.props.getProjectQuote();
    }, 2000);
  }

  /*
serviceTeamEdit function to activate the edit functionality
*/
  serviceTeamEdit() {
    this.setState({
      serviceTeamEdit: !this.state.serviceTeamEdit,
    });
  }

  render() {
    const {
      assignedTo,
      startDate,
      endDate,
      percentage,
      description,
      percentageError,
      assignmentId,
      serviceTeamEdit,
    } = this.state;
    return (
      <tr>
        <td></td>
        <td className="label-sm ">{assignmentId}</td>
        <td className="label-sm bold">
          <select
            className={`pq-name-input ${
              !assignedTo || serviceTeamEdit ? "pq-empty-input" : ""
            }`}
            name="assignedTo"
            value={assignedTo}
            onChange={this.onChange}
            required={true}
          >
            <option hidden>Select...</option>
            {this.props.employee &&
              this.props.employee.data.map((item) => {
                return (
                  <option key={item.empId} value={item.empId}>
                    {item.empoId}-{item.firstName}
                  </option>
                );
              })}
          </select>
        </td>
        <td className="label-sm bold">
          <input
            type="date"
            className={`pq-name-input ${
              !startDate || serviceTeamEdit ? "pq-empty-input" : ""
            }`}
            name="startDate"
            value={startDate}
            onChange={this.onChange}
            required={true}
          />
        </td>
        <td className="label-sm bold">
          <input
            type="date"
            className={`pq-name-input ${
              !endDate || serviceTeamEdit ? "pq-empty-input" : ""
            }`}
            name="endDate"
            value={endDate}
            onChange={this.onChange}
            min={startDate}
            required={true}
          />
        </td>
        <td className="label-sm bold">
          <input
            type="number"
            className={`pq-input ${
              !percentage || serviceTeamEdit ? "pq-empty-input" : ""
            }`}
            name="percentage"
            value={percentage}
            onChange={this.onChange}
            required={true}
          />
          {percentageError && (
            <FormText className="error">{percentageError}</FormText>
          )}
        </td>
        <td className="label-sm bold">
          <input
            type="textarea"
            className={`pq-input ${
              !description || serviceTeamEdit ? "pq-empty-input" : ""
            }`}
            name="description"
            value={description}
            onChange={this.onChange}
            required={true}
          />
        </td>
        {/* <td>
                    <span
                        className="timesheet-button save-button pq-button"
                        onClick={this.pqProjectUpdate}>
                        A/U
                    </span>
                </td>
                <td>
                    <span
                    className="material-icons pointer"
                    style={{ color: '#C00'}}
                    onClick={this.pqProjectDelete}>
                        cancel
                </span>
                </td> */}
        <td className="pq-product-td ">
          {!assignmentId ? (
            <span
              className="product-pq-button "
              id="service-team-add-button"
              title="Create"
              onClick={() => this.serviceTeamCreate()}
            >
              done
            </span>
          ) : null}
        </td>
        <td className="pq-product-td">
          {assignmentId && serviceTeamEdit ? (
            <span
              className="product-pq-button"
              id="service-team-save-button"
              title="Save"
              onClick={() => this.serviceTeamUpdate()}
            >
              save
            </span>
          ) : null}
        </td>
        <td className="pq-product-td">
          {assignmentId ? (
            <span
              className="product-pq-button"
              id="service-team-edit-button"
              title="Edit"
              style={{ color: "blue" }}
              onClick={this.serviceTeamEdit}
            >
              edit
            </span>
          ) : null}
        </td>
        <td className="pq-product-td" title="Delete">
          {assignmentId ? (
            <DeleteIcon
              onClick={this.serviceTeamDelete}
              className="product-pq-button"
              id="service-team-delete-button"
            />
          ) : null}
        </td>
        {this.state.message && <Notifier message={this.state.message} />}
        {this.state.error && <ErrorNotifier message={this.state.error} />}
      </tr>
    );
  }
}

ProjectRow.porpTypes = {
  getEmployeeDetails: PropTypes.func.isRequired,
  employee: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  employee: state.employee,
});

export default connect(mapStateToProps, { getEmployeeDetails })(ProjectRow);
