import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
// reactstrap
import { Row, Col, Card } from "reactstrap";
// material-ui
import { IconButton, makeStyles } from "@material-ui/core";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

//---------------------------------------------------

const useStyles = makeStyles(() => ({}));

const ThirdLevelServices = (props) => {
  const { moduleName, data } = props.location;
  let shrink = props.sidebar ? "scale" : "no-scale";
  const classes = useStyles();
  return (
    <div className={shrink}>
      <Row>
        {/* <div className="platform-head">
          <p className="platform-p">{moduleName}</p>
        </div> */}
        <div className="platform-category-div">
          <Row>
            {typeof data !== "undefined" &&
              data.length > 0 &&
              data.map((item) => {
                return (
                  <Col md="6" key={item.id}>
                    <Link
                      to={{
                        pathname: item.serviceBrowserLink,
                        moduleName: moduleName,
                        data: data,
                        serviceAccessData: item,
                      }}
                      className="no-dec"
                    >
                      <Card className="myprofile-card">
                        <VerifiedUserIcon className="myprofile-card-icon m-auto" />
                        {item.serviceName}
                      </Card>
                    </Link>
                  </Col>
                );
              })}
          </Row>
        </div>
      </Row>
    </div>
  );
};

export default ThirdLevelServices;
