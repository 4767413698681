import axios from "axios";
import { DOCUMENTS_GET } from "../../types";
import { CURD_DOCUMENTS } from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addDocuments = (data, back) => (dispatch) => {
  axios
    .post(CURD_DOCUMENTS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getDocuments));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getDocuments = () => (dispatch) => {
  axios
    .get(CURD_DOCUMENTS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(DOCUMENTS_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateDocuments = (data, back) => (dispatch) => {
  axios
    .put(CURD_DOCUMENTS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteDocuments = (data) => (dispatch) => {
  axios
    .delete(CURD_DOCUMENTS, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getDocuments));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
