import axios from "axios";
import {
  CAMPAIGN_SUCCESS_CRITERIA_ADD,
  CAMPAIGN_SUCCESS_CRITERIA_GET,
  CAMPAIGN_SUCCESS_CRITERIA_UPDATE,
  CAMPAIGN_SUCCESS_CRITERIA_DELETE,
  GET_ERRORS,
} from "./types";
import {
  ADD_CAMPAIGN_SUCCESS_CRITERIA,
  GET_CAMPAIGN_SUCCESS_CRITERIA,
  UPDATE_CAMPAIGN_SUCCESS_CRITERIA,
  DELETE_CAMPAIGN_SUCCESS_CRITERIA,
} from "../../utils/routes";
import { setMessage } from "./salesActions";

export const addSuccessCriteria = (data, back) => (dispatch) => {
  axios
    .post(ADD_CAMPAIGN_SUCCESS_CRITERIA, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getSuccessCriteria());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const getSuccessCriteria = () => (dispatch) => {
  axios
    .get(GET_CAMPAIGN_SUCCESS_CRITERIA, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: CAMPAIGN_SUCCESS_CRITERIA_GET,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};

export const updateSuccessCriteria = (data, back) => (dispatch) => {
  axios
    .post(UPDATE_CAMPAIGN_SUCCESS_CRITERIA, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getSuccessCriteria());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const deleteSuccessCriteria = (data) => (dispatch) => {
  axios
    .post(DELETE_CAMPAIGN_SUCCESS_CRITERIA, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getSuccessCriteria());
      }, 2000);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};
