import React, { Component } from "react";
import { Container, Row, Col, Card } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// components
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import UpdatePolicy from "./UpdatePolicy";
// redux
import {
  getHrPolicies,
  deleteHrPolicies,
} from "../../../../../redux/actions/hcm/hr/policies/hrPoliciesAction";

// ---------------------------------------------------------------------------------------------------------------
class PoliciesMaintance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      showComponet: false,
      updateData: [],
      message: "",
      error: "",
    };
    this.search = this.search.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.downloadPoDoc = this.downloadPoDoc.bind(this);
    this.deletePoDoc = this.deletePoDoc.bind(this);
    this.getData = this.getData.bind(this);
    this.updatePoDoc = this.updatePoDoc.bind(this);
    this.back = this.back.bind(this);
  }

  /*
componentDidMount lifeCycle fetch all the policies details
*/
  componentDidMount() {
    this.getData();
  }

  /*
getData function for fetching the policies list from the database
*/
  getData() {
    this.props.getHrPolicies();
  }

  /*
componentWillUnmount function for asgining the false to _isMounted variable
*/
  componentWillUnmount() {
    this._isMounted = false;
  }

  /*
search function for searching the policies 
*/
  search(e) {
    e.preventDefault();
    this.setState({
      searchTerm: e.target.value,
    });
    this.onSearch(e.target.value);
  }

  onSearch(value) {
    let data = {};
    let isInteger = value.length && /^[0-9]*$/.test(value);
    if (isInteger) {
      data.id = Number(value);
      data.policyName = "null";
    } else {
      data.id = 0;
      data.policyName = value;
    }
    // axios
    //   .post(SEARCH_POLICIES, data, { withCredentials: true })
    //   .then((res) => {
    //     this.setState({
    //       data: res.data,
    //     });
    //   })
    //   .catch((err) => {
    //     this.setState({
    //       error: "No Data",
    //     });
    //   });
    // setTimeout(() => {
    //   this.setState({
    //     message: "",
    //     error: "",
    //   });
    // }, 5000);
  }

  /*
downloadPoDoc function is to download the policy document
*/
  downloadPoDoc(item) {
    let data = {
      id: item.id,
    };
    // axios
    //   .post(DOWNLOAD_POLICIES, data, {
    //     responseType: "blob",
    //     withCredentials: true,
    //   })
    //   .then((res) => {
    //     const url = window.URL.createObjectURL(new Blob([res.data]));
    //     const link = document.createElement("a");
    //     link.href = url;
    //     link.setAttribute("download", item.docName);
    //     document.body.appendChild(link);
    //     link.click();
    //   })
    //   .catch((err) => {
    //     this.setState({
    //       error: "download failed",
    //     });
    //   });
    // setTimeout(() => {
    //   this.setState({
    //     message: "",
    //     error: "",
    //   });
    // }, 2000);
  }

  /*
deletePoDoc function is to delete the policy lest
*/
  deletePoDoc(policyId) {
    let data = {
      id: 6,
    };
    this.props.deleteHrPolicies(data);
  }

  /*
updatePoDoc function to updating the policy file
*/
  updatePoDoc(item) {
    this.setState({
      showComponet: true,
      updateData: item,
    });
  }
  /*
back function for setting the showComponent value to false
*/
  back() {
    this.setState({
      showComponet: false,
    });
    this.getData();
  }

  render() {
    let shrink = this.props.sidebar ? "scale" : "no-scale";
    return (
      <div className={shrink}>
        {!this.state.showComponet ? (
          <Container className="card container-cord">
            <input
              type="text"
              onChange={this.search}
              value={this.state.searchTerm}
              className="searchfield form-control-sm mr-3"
              placeholder="policyId/policyName"
            />
            <Link to="/pie/add/policiesmaintenance">
              <button className="btn add-button white-text label-sm">
                Add
              </button>
            </Link>
            <h5 className="pointer bold center">Policies Maintenance</h5>
            <Row className="pt-3">
              {this.props.hrPolicies.data.length > 0 &&
                this.props.hrPolicies.data.map((item) => {
                  return (
                    <Col md="3" key={item.id}>
                      <Card className="p-3 mb-2 shadow pointer">
                        <p className="list-item flex-1">
                          {item.id}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {item.policyName}
                        </p>
                        <p className="list-item flex-1">{item.docName}</p>
                        <i
                          id="policyUpdate"
                          onClick={() => this.updatePoDoc(item)}
                        >
                          Update
                        </i>
                        <i
                          id="pendingLeaveCancel"
                          onClick={() => this.deletePoDoc(item.id)}
                        >
                          Delete
                        </i>
                      </Card>
                    </Col>
                  );
                })}
            </Row>
          </Container>
        ) : (
          <UpdatePolicy item={this.state.updateData} back={this.back} />
        )}
        {this.props.hrPolicies.message ? (
          <Notifier message={this.props.hrPolicies.message} />
        ) : null}
        {this.props.hrPolicies.error ? (
          <ErrorNotifier message={this.props.hrPolicies.error} />
        ) : null}
      </div>
    );
  }
}

PoliciesMaintance.propTypes = {
  getHrPolicies: PropTypes.func.isRequired,
  deleteHrPolicies: PropTypes.func.isRequired,
  hrPolicies: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  hrPolicies: state.hrPolicies,
});

export default connect(mapStateToProps, { getHrPolicies, deleteHrPolicies })(
  PoliciesMaintance
);
