import axios from "axios";
import { CUSTOMER_COUNTRIES_GET } from "../../types";
import {
  CURD_CUSTOMER_COUNTRIES,
  USER_CUSTOMER_COUNTRIES,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addCustomerCountries = (data, back) => (dispatch) => {
  axios
    .post(CURD_CUSTOMER_COUNTRIES, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getCustomerCountries));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getCustomerCountries = () => (dispatch) => {
  axios
    .get(CURD_CUSTOMER_COUNTRIES, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CUSTOMER_COUNTRIES_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserCustomerCountries = () => (dispatch) => {
  axios
    .get(USER_CUSTOMER_COUNTRIES, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CUSTOMER_COUNTRIES_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateCustomerCountries = (data, back) => (dispatch) => {
  axios
    .put(CURD_CUSTOMER_COUNTRIES, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteCustomerCountries = (data) => (dispatch) => {
  axios
    .delete(CURD_CUSTOMER_COUNTRIES, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getCustomerCountries));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
