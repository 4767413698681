import React, { Component } from "react";
import { Form } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// redux
import FormComponent from "../../../../../common/FormComponent";
import {
  Validator,
  submitValidator,
  refresh,
  valid,
} from "../../../../../common/Validator";
import Notifier from "../../../../../aside/Notifier";
import ErrorNotifier from "../../../../../aside/ErrorNotifier";
// redux
import { updateOtherIdentifiers } from "../../../../../../redux/actions/hcm/my_profile/identification_info/otherIdentifiersAction";

// --------------------------------------------------------------------------------------------------------------------------------------
class UpdateOtherIdentifiers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aadharNo: this.props.data.length ? this.props.data[0].aadharNo : "",
      socialSecurity: this.props.data.length
        ? this.props.data[0].socialSecurity
        : "",
      message: "",
      error: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      aadharNo: this.state.aadharNo,
      socialSecurity: this.state.socialSecurity,
      createdBy: localStorage.getItem("id"),
    };
    if (valid) {
      this.props.updateOtherIdentifiers(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    refresh();
    this.setState({
      message: "",
      error: "",
    });
  }

  render() {
    const { aadharNo, socialSecurity, message, error } = this.state;

    return (
      <React.Fragment>
        <i
          className="material-icons cancel-button pointer"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <FormComponent
            labelClass="bold label-sm"
            label="Aadhar No."
            type="number"
            name="aadharNo"
            inputClass="form-control-sm"
            value={aadharNo}
            change={this.onChange}
            required={true}
          />
          <FormComponent
            labelClass="bold label-sm"
            label="Social Security/Others"
            type="number"
            name="socialSecurity"
            value={socialSecurity}
            change={this.onChange}
            required={true}
          />
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Update
            </button>
          </div>
        </Form>
        {this.props.otherIdentifiers.message && (
          <Notifier message={this.props.otherIdentifiers.message} />
        )}
        {this.props.otherIdentifiers.error && (
          <ErrorNotifier message={this.props.otherIdentifiers.error} />
        )}
      </React.Fragment>
    );
  }
}

UpdateOtherIdentifiers.propTypes = {
  updateOtherIdentifiers: PropTypes.func.isRequired,
  otherIdentifiers: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  otherIdentifiers: state.otherIdentifiers,
});

export default connect(mapStateToProps, { updateOtherIdentifiers })(
  UpdateOtherIdentifiers
);
