import axios from "axios";
import { GET_GLOBAL_VARIABLE_LIST_RS } from "../../types";
import {
  ADD_GLOBAL_VARIABLE_RS,
  GET_GLOBAL_VARIABLE_RS,
  UPDATE_GLOBAL_VARIABLE_RS,
  DELETE_GLOBAL_VARIABLE_RS
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addGlobalVaribleRs = (data, back) => (dispatch) => {
  axios
    .post(ADD_GLOBAL_VARIABLE_RS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back,  getGlobalVaribleRs));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getGlobalVaribleRs = () => (dispatch) => {
  axios
    .get( GET_GLOBAL_VARIABLE_RS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(GET_GLOBAL_VARIABLE_LIST_RS, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateGlobalVaribleRs = (data, back) => (dispatch) => {
  axios
    .put( UPDATE_GLOBAL_VARIABLE_RS , data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteGlobalVaribleRs = (data) => (dispatch) => {
  axios
    .delete(DELETE_GLOBAL_VARIABLE_RS, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getGlobalVaribleRs));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
