import axios from "axios";
import {
  ACCOUNTS_GET,
  ACCOUNTS_FETCH,
  ACCOUNTS_SEARCH,
  ACCOUNTS_SPOC_NAME_GET,
  UPDATE_ACCOUNTS_SPOC_NAME_GET,
  ACCOUNT_TAGGED_CONTACTS_GET,
} from "../../types";
import {
  CURD_ACCOUNTS,
  SEARCH_ACCOUNTS,
  GET_ACCOUNTS_SPOC_NAME,
  GET_UPDATE_ACCOUNTS_SPOC_NAME,
  GET_ACCOUNT_TAGGED_CONTACTS,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addAccounts = (data, history) => (dispatch) => {
  const link = "/pie/crm/accounts";
  axios
    .post(CURD_ACCOUNTS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, null, history, link));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const searchAccounts = (data) => (dispatch) => {
  axios
    .post(SEARCH_ACCOUNTS, data, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(ACCOUNTS_SEARCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getAccounts = () => (dispatch) => {
  axios
    .get(CURD_ACCOUNTS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(ACCOUNTS_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
export const getAccountsSpocName = (customer, entity) => (dispatch) => {
  axios
    .get(`${GET_ACCOUNTS_SPOC_NAME}/${customer}/${entity}`, {
      withCredentials: true,
    })
    .then((res) => {
      dispatch(getDispatch(ACCOUNTS_SPOC_NAME_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUpdateAccountsSpocName = (data) => (dispatch) => {
  axios
    .get(`${GET_UPDATE_ACCOUNTS_SPOC_NAME}/${data}`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(UPDATE_ACCOUNTS_SPOC_NAME_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getAccountTaggedContacts =
  (customer, entity, account) => (dispatch) => {
    axios
      .get(`${GET_ACCOUNT_TAGGED_CONTACTS}/${customer}/${entity}/${account}`, {
        withCredentials: true,
      })
      .then((res) => {
        dispatch(getDispatch(ACCOUNT_TAGGED_CONTACTS_GET, res));
      })
      .catch((err) => {
        dispatch(setErrorMessage(err));
      });
  };

export const fetchAccounts = (data) => (dispatch) => {
  axios
    .get(`${CURD_ACCOUNTS}/${data}`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(ACCOUNTS_FETCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateAccounts = (data, back) => (dispatch) => {
  axios
    .put(CURD_ACCOUNTS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteAccounts = (data) => (dispatch) => {
  axios
    .delete(CURD_ACCOUNTS, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getAccounts));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
