import React, { Component } from "react";
import { Form, Row, Col,FormGroup,Label,FormText,Input } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import Notifier from "../../../../aside/Notifier";
import { addHiringRs  } from "../../../../../redux/actions/rs/configuration/hiringStatus";
import {
  getHiringStage,
 
} from "../../../../../redux/actions/rs/configuration/hiringStageAction";
// add hiring status component
class AddHiring extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hiringStatus: "",
      hiringDescription: "",
      hiringStageId:"",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
//  common onchange component
  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  // submit function
  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      hiringStatus: this.state.hiringStatus,
      hiringStageId: Number(this.state.hiringStageId),
      createdBy: localStorage.getItem("id"),
    };
    if (valid) {
      this.props.addHiringRs(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidMount contains the error refresh method and setState method for calling the render method
*/
  componentDidMount() {
    this.props.getHiringStage()
    refresh();
    this.setState({
      error: "",
    });
  }

  render() {
    const { hiringStatus, hiringDescription, error } = this.state;

    return (
      <React.Fragment>
        <h5 className="center card-heading pt-2">Add Hiring Status</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="6" className="mx-auto">
            <FormGroup>
                <Label className="bold label-sm">Hiring Stage </Label>
                <Input
                  type="select"
                  name="hiringStageId"
                  className="form-control-sm"
                  value={this.state.hiringStageId}
                  onChange={this.onChange}
                  placeholder="Requirement Type"
                  required={true}
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {this.props.HiringStagers.data.map((item) => {
                    return (
                      <option
                        key={item.id}
                        value={item.id}
                      >
                        {item.hiringStageName}
                      </option>
                    );
                  })}
                </Input>
                {errors.skillGroupId && (
                  <FormText className="error">{errors.skillGroupId}</FormText>
                )}
              </FormGroup>
              <FormComponent
                labelClass="bold label-sm"
                label="Hiring Status"
                type="text"
                name="hiringStatus"
                inputClass="form-control-sm"
                value={hiringStatus}
                change={this.onChange}
                required={true}
                length="30"
              />
              
            </Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Add
            </button>
          </div>
        </Form>
        {error && <ErrorNotifier message={error} />}
        {this.props.hiringStatus.error && (
          <ErrorNotifier message={this.props.hiringStatus.error} />
        )}
        {this.props.hiringStatus.message && (
          <Notifier message={this.props.hiringStatus.message} />
        )}
      </React.Fragment>
    );
  }
}

AddHiring.propTypes = {
  addHiringRs: PropTypes.func.isRequired,
  userGroups: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  hiringStatus: state.hiringStatus,
  HiringStagers: state.HiringStage,
});

export default connect(mapStateToProps, { addHiringRs, getHiringStage, })( AddHiring);
