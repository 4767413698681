import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { connect } from "react-redux";
import { Divider } from "@material-ui/core";
import { withRouter, Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import ViewTaggedDetails from "./viewTaggedDetails";
import ViewUploadResume from "./viewUplodedresume";
import UpdateCandidateInfo from "../updateCandidateInfo";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DescriptionIcon from '@material-ui/icons/Description';
import ViewComments from "./viewComments";
import Searchbar from "./searchbar/SearchBar";

import {
  getRequirementTracking,
  AddRequirementTracking,
} from "../../../../../redux/actions/rs/candidate/tagRequirement";
import { getCandidateActiveStatus } 
from "../../../../../redux/actions/rs/candidate/candidateActiveInfo";
import FlexComponent from "./FlexComponent";
import {
  FETCHBYID,
  TAG_SEARCH_CANDI,
  SEARCH_CANDI,
  SEARCH_REQID_CAN,
  FETCH_PARTICULAR_CANDIDATES,
} from "../../../../../utils/routes";
import {
  Row,
  Col,
  Form,
  Container,
  FormGroup,
  Input,
  Label,
  FormText,
} from "reactstrap";
import { AiFillTags } from "react-icons/ai";
import { AiFillEye } from "react-icons/ai";
import { GrDocumentPdf} from "react-icons/gr";

import DateFns from "date-fns";
import { updateRequirement } from "../../../../../redux/actions/rs/requirements/requiremntsAction";
import { getCandidateStatusRs } from "../../../../../redux/actions/rs/configuration/candidateStausAction";
import { getHiringStatSelect } from "../../../../../redux/actions/rs/configuration/hiringStatus";
import { getParticularCandidate } from "../../../../../redux/actions/rs/candidateFilterView";
// components

import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../../common/Validator";
import DetailsPageFooter from "../../../../common/details_footer/DetailsPageFooter";
import FormComponent from "../../../../common/FormComponent";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import updateCandidateInfo from "../updateCandidateInfo";
import CustTooltip from "../../../../common/tooltip/CustTooltip";

var isValid = true;
// view tagged requirement component
class TagRequirement extends Component {
  constructor(props) {

    super(props);
    this.state = {
      errorInSearch: "",
      edit: false,
      custOrgChart: false,
      data: {},
      dateError: "",
      message: "",
      error: "",
      getData: [],
      dataStore: [],
      showPopUp: false,
      taggedView: false,
      searchValue: "",
      changeError: "",
      storeData: "",
      candiStatus: "Hiring Stage",
      hiringStage: "Profile Screening",
      hiringStatus: "Shortlisted",
      joiningDate: "",
      hiringStagDate:"",
      hiringStagRating:"",
      hiringFeedBack:"",
      filterHiringStage: [],
      filterHiringStatus: [],
      Tagremarks: "",
      errorTagCan: "",
      uploadOpenView: false,
      searchIdValue: "",
      canDataUserId: "",
      getTaggedData: [],
      particulatData: [],
      isOpen:false
    };

    this.onChange = this.onChange.bind(this);
    this.fetchParticularId = this.fetchParticularId.bind(this);
    this.backnavigate = this.backnavigate.bind(this);
    this.handlePopupView = this.handlePopupView.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleCloseTagedModal = this.handleCloseTagedModal.bind(this);
    this.handleTaggedView = this.handleTaggedView.bind(this);
    this.searchClick = this.searchClick.bind(this);
    this.searchChange = this.searchChange.bind(this);
    this.handleSubmitData = this.handleSubmitData.bind(this);
    this.searchHiringStatus = this.searchHiringStatus.bind(this);
    this.extractSkill = this.extractSkill.bind(this);
    // this.getData = this.getData.bind(this)
    this.onTagChange = this.onTagChange.bind(this);
    this.skillExtractWhole = this.skillExtractWhole.bind(this);
    this.viewToggleClick = this.viewToggleClick.bind(this);
    this.updateBack = this.updateBack.bind(this);
    // this.extractPrimarySkill = this.extractPrimarySkill.bind(this)
    this.extractSecondarySkill = this.extractSecondarySkill.bind(this);
    this.extractExperience = this.extractExperience.bind(this);
    this.handleViewComments = this.handleViewComments.bind(this)
    this.backViewComments = this.backViewComments.bind(this)
    // this.backViewClick = this.backViewClick.bind(this)
  }

  //   componentDidUpdate(prevProps) {
  //     if (this.props.customers.message) {
  //       setTimeout(() => {
  //         this.props.back();
  //       }, 2000);
  //     }
  //   }

  // onchange event for from date and to date
  onChange(e) {
    e.preventDefault();
    Validator(e.target);
    if (e.target.name === "endDate") {
      if (Date.parse(e.target.value) < Date.parse(this.state.startDate)) {
        this.setState({
          [e.target.name]: e.target.value,
          dateError: "End Date must be greater than or equal to Start Date",
        });
      } else {
        this.setState({
          [e.target.name]: e.target.value,
          dateError: "",
        });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  // navigating to main
  backnavigate() {
    this.setState({
      edit: !this.state.edit,
    });
    this.fetchParticularId();
  }

  // pop up tag requirement model
  handlePopupView() {
    this.setState({
      showPopUp: true,
      taggedView: false,
    });
  }

  // view tagged requirement
  handleTaggedView() {
    this.setState({
      taggedView: true,
      showPopUp: false,
    });
  }

  handleViewComments(){
    this.setState({
      isOpen:!this.state.isOpen,
      showPopUp: false,
      taggedView: false,
    })
    }
    
    backViewComments(){
    this.setState({
      isOpen:false
    })
    }

  //  tag requirement search
  searchClick() {
    if (!this.state.searchValue) {
      this.setState({
        changeError: "Please enter keyword to search",
        errorInSearch: "",
      });
    } else {
      this.setState({
        changeError: "",
      });

      if (Number(this.state.searchValue)) {
        axios
          .get(`${SEARCH_REQID_CAN}/${this.state.searchValue}`, {
            withCredentials: true,
          })
          .then((res) => {
            console.log(res.data);
            if (!res.data.hasOwnProperty("ok")) {
              this.setState({
                storeData: res.data[0].id,
                canDataUserId: res.data.canUserId,
                errorInSearch: "",
                changeError: "",
                // searchIdValue:this.state.storeData
              });
            } else if (!res.data.ok) {
              this.setState({
                changeError: "Requirement not available",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        const data = {
          search: this.state.searchValue,
        };
        axios
          .post(`${SEARCH_REQID_CAN}`, data, { withCredentials: true })
          .then((res) => {
            console.log(res.data);
            if (res.data.length > 0) {
              this.setState({
                storeData: res.data[0].id,
                canDataUserId: res.data[0].custReqId,
                errorInSearch: "",
                changeError: "",
                // searchIdValue:this.state.storeData
              });
            } else {
              this.setState({
                changeError: "Data  not available",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }

  searchChange(e) {
    this.setState({
      storeData: "",
    });
    this.setState({
      searchValue: e.target.value,
      changeError: "",
      errorInSearch: "",
    });
  }

  fetchParticularId() {
    let body = {
      id: Number(this.props.canId),
    };
    this.props.getParticularCandidate(body);
  }

  handleCloseModal() {
    this.setState({
      showPopUp: false,
    });
  }

  extractSkill(data) {
    return (
      data.length > 0 &&
      data.length > 1 ? 
      data.map((innerArr) => {
        if (innerArr.skill.split(",").length > 1) {
          return innerArr.skill.split(",").map((dataArr) => {
            return <span>{`${dataArr}`}, &nbsp;</span>;
          });
        } else {
          return innerArr.skill.split(",").map((dataArr) => {
            return <span>{`${dataArr}`},</span>;
          });
        }
      }): data.map((inner)=>{
        return inner.skill.split(",").map((dataArr) => {
          return <span>{`${dataArr}`}, &nbsp;</span>
        });
      })
    )
  }

  // extractPrimarySkill(data){
  //   console.log(data)
  // return data.length>0 &&  data.map(data => data.skill.split(",").map(dataArr=>`${dataArr}-`))
  // }

  extractSecondarySkill(data) {
    return (
      data.length > 0 &&
      data.map((data) => <span>{data.skill.split(",", " ")}</span>)
    );
  }

  extractExperience(data) {
    return (
      data.length > 0 &&
      data.map((data) => (
        <span className=" label-sm">{data.experienceSumm}</span>
      ))
    );
  }

  // close tagged model

  handleCloseTagedModal() {
    this.setState({
      taggedView: false,
    });
  }

  searchHiringStatus(data) {
   
    this.props.hiringStatus.mainData.find((val) => {
      if (val.hiringStageName === data) {
        this.setState({
          filterHiringStatus: val.hiringStatus,
        });
      }
    });
  }

  handleSubmitData() {
    if (!this.state.storeData) {
      this.setState({
        errorInSearch: "Please enter keyword to search",
        changeError: "",
      });
      isValid = false;
    } else {
      isValid = true;
    }
    if (!this.state.candiStatus) {
      this.setState({
        errorTagCan: "This field is mandatory",
      });
      isValid = false;
    }
    let data = {
      jobReqirementId: this.state.storeData,
      canUserId: this.props.canUserId,
      canInfoId: Number(this.props.canId),
      candidateStatus: this.state.candiStatus,
      candidateSource: "Tagging",
      candidateStatus: this.state.candiStatus,
      comment: this.state.Tagremarks,
      hiringStage: this.state.hiringStage,
      hiringStatus: this.state.hiringStatus,
      dateOfJoin: this.state.joiningDate,
      hiringStagedate:this.state.hiringStagDate,
      hiringStageRating:this.state.hiringStagRating,
      hiringStagefeedback:this.state.hiringFeedBack
    };

    if (isValid) {
      this.props.AddRequirementTracking(data, this.handleCloseModal);
    } else {
      this.setState({
        error: "Please enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.hiringStatus.mainData !== this.props.hiringStatus.mainData) {
      this.props.hiringStatus.mainData.find((val) => {
        if (val.hiringStageName === this.state.hiringStage) {
          this.setState({
            filterHiringStatus: val.hiringStatus,
          });
        }
      });
    }

    // if(this.props.CandidateViewRecordsRs.allData !== props.CandidateViewRecordsRs.allData){
    //   this.fetchParticularId()
    // }
  }

  skillExtractWhole(val) {
    var firstObjectValue = ``;
    if (val.length > 1) {
      for (var index = 0; index < val.length; index++) {
        firstObjectValue += `${val[index].primarySkills} ,`;
        //       break;
      }
      return firstObjectValue;
    }
  }

  viewToggleClick() {
    this.setState({
      uploadOpenView: !this.state.uploadOpenView,
    });
  }

  updateBack() {
    this.setState({
      edit: false,
      uploadOpenView: false,
    });
    this.fetchParticularId();
  }

  onTagChange(e) {
    console.log(e.target.value)
    if (e.target.name === "candiStatus") {
      if (e.target.value !== "Hiring Stage") {
        this.setState({
          candiStatus: e.target.value,
          hiringStatus: "",
          hiringStage: "",
        });
      } else if (e.target.value === "Hiring Stage") {
        this.searchHiringStatus(e.target.value);
        this.setState({
          candiStatus: e.target.value,
          hiringStage: "Profile Screening",
          hiringStatus: "Shortlisted",
        });
      }
    } else if (e.target.name === "hiringStage") {
      this.searchHiringStatus(e.target.value);
      this.setState({
        hiringStage: e.target.value,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }
  
  
  // componentDidUpdate(prevProps,prevState) {
  //     if (this.props.CandidateViewRecordsRs.allData !== prevProps.allData) {
  //       this.props.getCandidateActiveStatus(
  //         this.props.CandidateViewRecordsRs.allData.length>0 &&
  //         this.props.CandidateViewRecordsRs.allData[0].canUserId
  //        );
  //     }
  //   }

  /*
  ComponentDidMount getting the selected customer details and setting the state of customer details
  */
  componentDidMount() {
    this.props.getHiringStatSelect();
   
    this.setState({
      changeError: "",
      storeData: "",
      errorTagCan: "",
      errorInSearch: "",
    });
    this.fetchParticularId();
    this.props.getCandidateStatusRs();
    this.props.getCandidateActiveStatus(this.props.canUserId);
    // this.getData();
    refresh();
  }



  render() {
    console.log(this.props.canUserId)
    console.log(this.props.candiVerify)
    return (
      <Row>
        {!this.state.uploadOpenView && !this.state.edit && (
          <Col md="12">
            <h5 className="bold center mb-4">
              {" "}
              Candidate Id : {this.props.canId}
            </h5>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                position: "absolute",
                top: "-0.3rem",
                right: "5%",
              }}
            >
             { this.props.candiVerify && this.props.candiVerify.data.code === "426A0" && 
             <CustTooltip title="Update Candidate">
                <i
                  data-toggle="tooltip"
                  data-placement="top"
                  style={{ color: "#0056b3", marginRight: ".5rem" }}
                  className="material-icons pointer edit-btn"
                  onClick={() => this.setState({ edit: !this.state.edit })}
                >
                  edit
                </i>
              </CustTooltip>}
              <CustTooltip title="View Tracking">
                <h3
                  onClick={this.handleViewComments}
                  data-toggle="tooltip"
                  data-placement="top"
                  style={{
                    color: "#0056b3",
                    marginRight: ".5rem",
                    cursor: "pointer",
                    fontSize: "1.5rem",
                  }}
                >
                  {" "}
                  <DescriptionIcon size="19" color="#0056b3" />{" "}
                </h3>
              </CustTooltip>
              <CustTooltip title="View Resume">
                <h3
                  onClick={this.viewToggleClick}
                  data-toggle="tooltip"
                  data-placement="top"
                  style={{
                    color: "#0056b3",
                    marginRight: ".5rem",
                    cursor: "pointer",
                    fontSize: "1.5rem",
                  }}
                >
                  {" "}
                  <PictureAsPdfIcon size="19" color="#0056b3" />{" "}
                </h3>
              </CustTooltip>
              <CustTooltip title="Tag Requirement">
                <h3
                  data-toggle="tooltip"
                  data-placement="top"
                  onClick={this.handlePopupView}
                  style={{
                    color: "#0056b3",
                    marginRight: ".5rem",
                    cursor: "pointer",
                    fontSize: "1.5rem",
                  }}
                >
                  {" "}
                  <AiFillTags />{" "}
                </h3>
              </CustTooltip>
              <CustTooltip title="View Tagged Requirements">
                <h3
                  data-toggle="tooltip"
                  data-placement="top"
                  onClick={this.handleTaggedView}
                  style={{
                    color: "#0056b3",
                    marginRight: ".5rem",
                    cursor: "pointer",
                    fontSize: "1.5rem",
                  }}
                >
                  {" "}
                  <AiFillEye />{" "}
                </h3>
              </CustTooltip>
            </div>

            <i
              onClick={() => this.props.back()}
              data-toggle="tooltip"
              data-placement="top"
              title="Close"
              className="material-icons pointer cancel-button"
              style={{
                top: "0.1rem",
              }}
            >
              cancel
            </i>

            <div>
              {this.props.CandidateViewRecordsRs.allData.length > 0 &&
                this.props.CandidateViewRecordsRs.allData.map((data) => {
                  return (
                    <div>
                    <Row style={{ width: "100%" }}>
                      <Col style={{ marginLeft: "3rem" }} md="5">
                        <ul style={{}} className="CustDetails-ul">
                          <li>
                            <FlexComponent
                              label="Name"
                              data={`${data.firstName} ${data.lastName} `}
                            />
                          </li>
                          <li>
                            <FlexComponent label="Email" data={data.eMailId} />
                          </li>
                          <li>
                            <FlexComponent
                              label="Mobile No."
                              data={`${data.dialingCode?data.dialingCode:""}${data.mobileNo?data.mobileNo:""}`}
                            />
                          </li>
                          <li>
                            <FlexComponent
                              label="Alt.Contact No."
                              data={`${data.altDialingCode?data.altDialingCode:""}${data.altContactNo? 
                                data.altContactNo:"" }`}
                            />
                          </li>
                          <li>
                            <FlexComponent
                              label="DOB"
                              data={
                                data.dob
                                  ? DateFns.format(data.dob, "DD-MM-YYYY")
                                  : ""
                              }
                            />
                          </li>
                          <li>
                            <FlexComponent label="Gender" data={data.gender} />
                          </li>
                          <li>
                            <FlexComponent
                              label="Country"
                              data={data.country ? data.country.split(",")[0]:""}
                            />
                          </li>
                          <li>
                            <FlexComponent
                              label="Location"
                              data={data.location}
                            />
                          </li>
                          <li>
                          <FlexComponent
                              label="Qualification"
                              data={
                                data.canEducation
                                  ? data.canEducation.candQual
                                  : ""
                              }
                            />
                            </li>


                          <li>
                            <FlexComponent
                              label="Years of Exp"
                              data={data.yearOfExp}
                            />
                          </li>

                        

                       

                         
                        </ul>
                      </Col>
                      <Col style={{ marginLeft: "4rem" }} md="5">
                        <ul style={{}} className="CustDetails-ul">
                          <li>
                          
                          </li>
                          <li>
                            <FlexComponent
                              label="Year of Passing"
                              data={
                                data.canEducation
                                  ? data.canEducation.yearOfPassing
                                  : ""
                              }
                            />
                          </li>
                          <li>
                            <FlexComponent
                              label="Relevant Yrs of Exp."
                              data={
                                data.canExpSummary
                                  ? data.canExpSummary.relYearOfExp
                                  : ""
                              }
                            />
                          </li>
                          <li>
                            <FlexComponent
                              label="Current Employer"
                              data={
                                data.canExpectation
                                  ? data.canExpectation.currentEmployeer
                                  : ""
                              }
                            />
                          </li>
                          <li>
                            <FlexComponent
                              label="Current CTC"
                              data={
                                data.canExpectation
                                  ? data.canExpectation.currentCtc
                                  : ""
                              }
                            />
                          </li>

                          <li>
                            <FlexComponent
                              label="Expected CTC"
                              data={
                                data.canExpectation
                                  ? data.canExpectation.exepectedCtc
                                  : ""
                              }
                            />
                          </li>

                          <li>
                            <FlexComponent
                              label="Notice Period"
                              data={
                                data.canExpectation
                                  ? data.canExpectation.noticePeriod
                                  : ""
                              }
                            />
                          </li>

                          <li>
                            <FlexComponent
                              label="Preferred Location"
                              data={
                                data.canExpectation
                                  ? data.canExpectation.preferredWorkLoction
                                  : ""
                              }
                            />
                          </li>

                          <li>
                            <FlexComponent
                              label="Govt Id Name"
                              data={data.govIdName}
                            />
                          </li>

                          <li>
                            <FlexComponent
                              label="Govt Id No."
                              data={data.govIdNo}
                            />
                          </li>
                        
                        </ul>

                      </Col>
                     
                     
                     </Row>
                     <Row>
            <Col md="12">
              <ul className="CustDetails-ul">
                <li>
                  <div style={{ display: "flex",marginBottom:"0.7rem" }}>
                    <div
                      className="label-sm bold rs-req-span-label-col-1 pt-1"
                      style={{ width: "16.4%",marginLeft:"2.9rem" }}
                    >
                      <span
                        style={{
                          fontSize: ".875rem",
                          fontWeight: "500",
                        }}
                      >
                        Primary Skills
                      </span>
                    </div>
                    <div style={{ width: "1%", textAlign: "end" }}>
                      <span className="label-sm">:</span>
                    </div>
                    <div style={{ width: "78%", paddingLeft: "1%" }}>
                      <span style={{fontSize:".75rem ",fontWeight:"400"}}>
                      {data.canPrimarySkills.length > 0 &&
                                    this.extractSkill(data.canPrimarySkills)}
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div style={{ display: "flex",marginBottom:"0.7rem" }}>
                    <div
                      className="label-sm bold rs-req-span-label-col-1 pt-1"
                      style={{ width: "16.4%",marginLeft:"2.9rem" }}
                    >
                      <span
                        style={{
                          fontSize: ".875rem",
                          fontWeight: "500",
                        }}
                      >
                        Secondary Skills
                      </span>
                    </div>
                    <div style={{ width: "1%", textAlign: "end" }}>
                      <span className="label-sm">:</span>
                    </div>
                    <div style={{ width: "78%", paddingLeft: "1%" }}>
                      <span style={{fontSize:".75rem ",fontWeight:"400"}}>
                      {data.canSecondarySkills.length > 0 &&
                                    this.extractSkill(data.canSecondarySkills)}
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div style={{ display: "flex",marginBottom:"0.7rem" }}>
                    <div
                      className="label-sm bold rs-req-span-label-col-1 pt-1"
                      style={{ width: "16.4%",marginLeft:"2.9rem" }}
                    >
                      <span
                        style={{
                          fontSize: ".875rem",
                          fontWeight: "500",
                        }}
                      >
                        Experience Summary
                      </span>
                    </div>
                    <div style={{ width: "1%", textAlign: "end" }}>
                      <span className="label-sm">:</span>
                    </div>
                    <div style={{ width: "78%", paddingLeft: "1%" }}>
                      <span style={{fontSize:".75rem ",fontWeight:"400"}}>
                        {data.canExpSummary
                          ? data.canExpSummary.experienceSumm
                          : ""}
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div style={{ display: "flex",marginBottom:"0.7rem" }}>
                    <div
                      className="label-sm bold rs-req-span-label-col-1 pt-1"
                      style={{ width: "16.4%",marginLeft:"2.9rem" }}
                    >
                      <span
                        style={{
                          fontSize: ".875rem",
                          fontWeight: "500",
                        }}
                      >
                        Notes
                      </span>
                    </div>
                    <div style={{ width: "1%", textAlign: "end" }}>
                      <span className="label-sm">:</span>
                    </div>
                    <div style={{ width: "78%", paddingLeft: "1%" }}>
                      <span style={{fontSize:".75rem ",fontWeight:"400"}}>
                      {data.canExpectation
                                  ? data.canExpectation.notes
                                  : ""
                              }
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
                     <Divider />
                  <DetailsPageFooter
                    createdBy={data.createdBy}
                    createdOn={data.createdOn}
                    updatedBy={data.updatedBy}
                    updatedOn={data.updatedOn}
                  />
                     </div>
                      

                    
                  );
                 
                
                })}
                 
            </div>
          
            {this.state.showPopUp && (
              <div>
                <Modal
                  size="lg"
                  show={this.state.showPopUp}
                  onHide={this.handleCloseModal}
                >
                  <i
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Close"
                    onClick={this.handleCloseModal}
                    className="material-icons pointer cancel-button"
                  >
                    cancel
                  </i>
                  {/* <Form onSubmit={this.handleSubmitData} className="p-3 mt-2" noValidate> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <Modal.Title style={{fontSize:"1.25rem",fontWeight:400}}>Tag Requirements</Modal.Title>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <ul className="CustDetails-ul">
                      <li style={{ marginTop: "1rem" }}>
                        <div style={{ display: "flex" }}>
                          <span
                            style={{
                               fontSize: "0.875rem",
                                fontWeight: "500",
                                width: "35%",
                                }}
                            className="label-sm bold customer-span-label-col-1"
                          >
                            Requirement Id : {this.state.storeData}
                          </span>

                          <div style={{width:"20rem",display:"flex",flexDirection:"column"}}>
                <Searchbar
                 style={{width:"2rem"}}
              widthValue ="50%"
              onChange={this.searchChange}
              value={this.state.searchValue}
              placeholder="Search.."
              onSubmit={this.searchClick}
              title="Search"
              onKeyPress={(e) => {
                if (e.code === "Enter") {
                  this.searchClick();
                }
              }}
              // error={changeError}
              // isAvailable={isAvailable}
            />
              {this.state.errorInSearch && (
                      <span style={{ fontSize: "14px", color: "red" }}>
                        {this.state.errorInSearch}
                      </span>
                    )}
                    {this.state.changeError && (
                      <span style={{ fontSize: "14px", color: "red" }}>
                        {this.state.changeError}
                      </span>
                    )}

            </div>

                        
                        </div>
                      </li>

                      <li style={{ marginTop: "2rem" }}>
                        <div style={{ display: "flex" }}>
                          <span
                            style={{
                              fontSize: "0.875rem",
                              fontWeight: "500",
                              width: "150px",
                            }}
                            className="label-sm bold customer-span-label-col-1"
                          >
                            Candidate Status
                          </span>

                          <FormGroup>
                            <Input
                              style={{
                                width: "385px",
                                height:"2rem",
                                marginLeft: "3.6rem",
                              }}
                              type="select"
                              name="candiStatus"
                              className="form-control-sm"
                              value={this.state.candiStatus}
                              onChange={this.onTagChange}
                              required={true}
                            >
                              <option value="" hidden>
                                Select...
                              </option>
                              {this.props.CandidateStatus.mainData.map(
                                (item) => {
                                  return (
                                    <option
                                      key={item.id}
                                      value={item.candidateStatus}
                                    >
                                      {item.candidateStatus}
                                    </option>
                                  );
                                }
                              )}
                            </Input>
                            {this.state.errorTagCan && (
                              <FormText
                                style={{ marginLeft: "3.6rem" }}
                                className="error"
                              >
                                {this.state.errorTagCan}
                              </FormText>
                            )}
                          </FormGroup>
                        </div>
                      </li>

                      {this.state.candiStatus === "Hiring Stage" && (
                       <li>
                       <div style={{ display: "flex" }}>
                         <span
                           style={{
                             fontSize: "0.875rem",
                             fontWeight: "500",
                             width: "150px",
                             marginTop:"1.5rem"
                           }}
                           className="label-sm bold customer-span-label-col-1"
                         >
                           Hiring Stage
                         </span>
     
                         <div  style={{
                               width: "11.5rem",
                               height:"2rem",
                               marginTop:"1.5rem",
                               marginLeft: "3.6rem",
                             }}>
                         <FormGroup>
                           <Input
                             style={{
                             
                               height:"2rem",
                             
                             }}
                             type="select"
                             name="hiringStage"
                             className="form-control-sm"
                             value={this.state.hiringStage}
                             onChange={this.onTagChange}
                             required={true}
                           >
                             <option value="" hidden>
                               Select...
                             </option>
                             {this.props.hiringStatus.mainData.map((item) => {
                               return (
                                 <option key={item.id} value={item.hiringStageName}>
                                   {item.hiringStageName}
                                 </option>
                               );
                             })}
                           </Input>
                         
                         </FormGroup>
                          
                         </div>
                          <div >
                         <span style={{ marginLeft:"0.5rem",  fontSize: "0.875rem",fontWeight: "500", }} >date</span>
                        
                          
                         <FormComponent
                             style={{ width: "12rem",marginLeft:"0.5rem",height:"2rem" }}
                             labelClass="bold label-sm"
                             // label="date"
                             type="date"
                             name="hiringStagDate"
                             inputClass="form-control-sm"
                             value={this.state.hiringStagDate}
                             change={this.onChange}
                             required={true}
                 
                           />
                           </div>
                          
                        
     
                       </div>
                     </li>
                      )}

                      {this.state.candiStatus === "Hiring Stage" && (
                       <li>
                       <div style={{ display: "flex" }}>
                         <span
                           style={{
                             fontSize: "0.875rem",
                             fontWeight: "500",
                             width: "150px",
                             marginTop:"1.5rem"
                           }}
                           className="label-sm bold customer-span-label-col-1"
                         >
                           Hiring Status
                         </span>
                         <div  style={{
                               width: "11.5rem",
                               height:"2rem",
                              
                               marginLeft: "3.6rem",
                               marginTop:"1.5rem",
                             }}>
                         <FormGroup>
                           <Input
                             style={{
                              height:"2rem",
                               width: "11.5rem",
                              }}
                             type="select"
                             name="hiringStatus"
                             className="form-control-sm"
                             value={this.state.hiringStatus}
                             onChange={this.onChange}
                             required={true}
                           >
                             <option value="" hidden>
                               Select...
                             </option>
                             {this.state.filterHiringStatus.length > 0 &&
                               this.state.filterHiringStatus.map((item) => {
                                 return (
                                   <option key={item.id} value={item.hiringStatus}>
                                     {item.hiringStatus}
                                   </option>
                                 );
                               })}
                           </Input>
                           {/* {this.state.errorTagCan && (
                           <FormText className="error">
                             {this.state.errorTagCan}
                           </FormText>
                         )} */}
                         </FormGroup>
                         </div>
                     
                       <div >
                         <span style={{ marginLeft:"0.5rem",  fontSize: "0.875rem",fontWeight: "500", }} >Rating</span>
                         <FormGroup>
                           <Input
                             style={{
                               width: "12rem",
                               marginLeft:"0.5rem",
                               height:"2rem",
                              }}
                             type="select"
                             name="hiringStagRating"
                             className="form-control-sm"
                             value={this.state.hiringStagRating}
                             onChange={this.onChange}
                             required={true}
                           >
                             <option value="" hidden>
                               Select...
                             </option>
                             <option value = "1">1</option>
                             <option value = "2">2</option>
                            <option  value = "3">3</option>
                             <option value  ="4">4</option>
                             <option value = "5">5</option>
                           
                           </Input>
                         
                         </FormGroup>
                           </div>
                           </div>
                     </li>
                      )}

                      {(this.state.hiringStage === "Offered" ||
                        this.state.hiringStage === "HR Discussion" ||
                        this.state.hiringStage === "Manager Discussion") && (
                        <li style={{ marginTop: "1rem" }}>
                          <div style={{ display: "flex" }}>
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                width: "150px",
                              }}
                              className="label-sm bold customer-span-label-col-1"
                            >
                              Date Of Joining
                            </span>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <FormComponent
                                style={{ width: "385px", marginLeft: "3.6rem" }}
                                labelClass="bold label-sm"
                                // label="Years Of Experience"
                                type="date"
                                name="joiningDate"
                                inputClass="form-control-sm"
                                value={this.state.joiningDate}
                                change={this.onTagChange}
                                required={true}
                              />
                            </div>
                          </div>
                        </li>
                      )}

             {this.state.candiStatus === "Hiring Stage" && <li style={{ marginTop: "1rem" }}>
                <div style={{ display: "flex" }}>
                  <span
                    style={{
                      fontSize: "0.875rem",
                      fontWeight: "500",
                      width: "150px",
                    }}
                    className="label-sm bold customer-span-label-col-1"
                  >
                    Feedback
                  </span>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <FormComponent
                      style={{ width: "385px", marginLeft: "3.6rem",height:"2rem" }}
                      labelClass="bold label-sm"
                      // label="Years Of Experience"
                      type="textarea"
                      name="hiringFeedBack"
                      inputClass="form-control-sm"
                      value={this.state.hiringFeedBack}
                      change={this.onChange}
                      required={true}
                    />
                  </div>
                </div>
              </li>}

                      <li style={{ marginTop: "1rem" }}>
                        <div style={{ display: "flex" }}>
                          <span
                            style={{
                                fontSize: "0.875rem",
                                fontWeight: "500",
                                width: "35%",
                                 }}
                            className="label-sm bold customer-span-label-col-1"
                          >
                            Comments
                          </span>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <FormComponent
                                style={{ width: "385px", marginLeft: "3.6rem",height:"2rem" }}
                              labelClass="bold label-sm"
                              type="textarea"
                              name="Tagremarks"
                              inputClass="form-control-sm"
                              value={this.state.Tagremarks}
                              change={this.onTagChange}
                              required={true}
                            />
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingBottom: "1rem",
                    }}
                  >
                    <Button variant="primary" onClick={this.handleSubmitData}>
                      Submit
                    </Button>
                  </div>
                  {/* </Form> */}
                </Modal>
              </div>
            )}

            {this.state.taggedView && (
              <ViewTaggedDetails
                reqType={this.props.canId}
                taggedData={this.state.getTaggedData}
                taggedView={this.state.taggedView}
                closeModel={this.handleCloseTagedModal}
              />
            )}
            
            {this.state.isOpen && (
              <ViewComments
              isOpen={this.state.isOpen}
              canInfo={this.props.canId} 
              back = {this.backViewComments}
              />
            )}
          </Col>
        )}
        {this.state.uploadOpenView && (
          <div style={{ width: "100%" }}>
            <ViewUploadResume
              canId={this.props.CandidateViewRecordsRs.allData[0].id}
              back={this.viewToggleClick}
            />
          </div>
        )}
        {this.state.edit && (
          <div style={{ width: "100%" }}>
            <UpdateCandidateInfo
              canRecords={this.props.CandidateViewRecordsRs.allData}
              // canInfo = {this.props.CandidateViewRecordsRs.allData[0].id}
              back={this.updateBack}
            />
          </div>
        )}

        {this.state.message ? <Notifier message={this.state.message} /> : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
        {this.props.TaggedReqTracking.message ? (
          <Notifier message={this.props.TaggedReqTracking.message} />
        ) : null}
        {this.props.TaggedReqTracking.error ? (
          <ErrorNotifier message={this.props.TaggedReqTracking.error} />
        ) : null}
      </Row>
    );
  }
}

TagRequirement.propTypes = {
  updateRequirement: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  requirements: state.RSrequirement,
  CandidateStatus: state.CandidateStatus,
  HrNotice: state.HrNotice,
  hiringStatus: state.hiringStatus,
  TaggedReqTracking: state.TaggedReqTracking,
  CandidateViewRecordsRs: state.CandidateViewRecordsRs,
  candiVerify:state.candiVerify
});

export default connect(mapStateToProps, {
  getCandidateActiveStatus ,
  updateRequirement,
  getCandidateStatusRs,
  getHiringStatSelect,
  AddRequirementTracking,
  getParticularCandidate,
})(withRouter(TagRequirement));
