import axios from "axios";
import { IAM_CONFIG_GET } from "../../types";
import { CURD_IAM_CONFIG } from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addIamConfig = (data, back) => (dispatch) => {
  axios
    .post(CURD_IAM_CONFIG, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getIamConfig));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getIamConfig = () => (dispatch) => {
  axios
    .get(CURD_IAM_CONFIG, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(IAM_CONFIG_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateIamConfig = (data, back) => (dispatch) => {
  axios
    .put(CURD_IAM_CONFIG, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getIamConfig));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteIamConfig = (data) => (dispatch) => {
  axios
    .delete(CURD_IAM_CONFIG, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getIamConfig));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
