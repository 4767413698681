import axios from "axios";
import {
  GET_ERRORS,
  SALES_INVOICE_GET,
  SALES_INVOICE_RECIEVE_ITEM_GET,
  LATEST_SALES_INVOICE_GET,
} from "../../../types";
import {
  GET_SALES_INVOICE,
  ADD_SALES_INVOICE,
  UPDATE_SALES_INVOICE,
  ADD_SALES_INVOICE_RECIEVE_ITEM,
  UPDATE_SALES_INVOICE_RECIEVE_ITEM,
  GET_SALES_INVOICE_RECIEVE_ITEM,
  GET_LATEST_SALES_INVOICE,
} from "../../../../../utils/routes";
import { setMessage } from "../../../salesActions";

export const getSalesInvoices = () => (dispatch) => {
  axios
    .get(GET_SALES_INVOICE, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: SALES_INVOICE_GET,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};

export const addSalesInvoice = (data, back) => (dispatch) => {
  axios
    .post(ADD_SALES_INVOICE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(latestSalesInvoice(data.createdBy));
        // back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const updateSalesInvoice = (data, back) => (dispatch) => {
  axios
    .post(UPDATE_SALES_INVOICE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getSalesInvoices());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const addUpdateSalesInvoice = (data) => (dispatch) => {
  axios
    .post(UPDATE_SALES_INVOICE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch(getSalesInvoices());
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const addRecieveItem = (data, back) => (dispatch) => {
  axios
    .post(ADD_SALES_INVOICE_RECIEVE_ITEM, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        // dispatch(getRecieveItems());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const updateRecieveItem = (data, back) => (dispatch) => {
  axios
    .post(UPDATE_SALES_INVOICE_RECIEVE_ITEM, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        // dispatch(getRecieveItems());
        back();
      }, 2000);
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.message,
        });
        setTimeout(() => {
          dispatch({
            type: GET_ERRORS,
            payload: null,
          });
        }, 2000);
      }
    });
};

export const getRecieveItems = (data) => (dispatch) => {
  axios
    .post(GET_SALES_INVOICE_RECIEVE_ITEM, data, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: SALES_INVOICE_RECIEVE_ITEM_GET,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};

export const latestSalesInvoice = (empId) => (dispatch) => {
  let data = {
    createdBy: empId,
  };

  axios
    .post(GET_LATEST_SALES_INVOICE, data, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: LATEST_SALES_INVOICE_GET,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};

export const resetItems = () => (dispatch) => {
  dispatch({
    type: SALES_INVOICE_RECIEVE_ITEM_GET,
    payload: [],
  });
};
