import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";
import { getResetErrorMessage } from "../../common/getResetErrorMessage";
import {
  SEARCH_MANAGER_VIEW,
  SEARCH_MANAGER_VIEW_CANDIDATE,
  GET_MANAGER_VIEW_CUSTOMERS,
  GET_MANAGER_VIEW_TAC,
  GET_MANAGER_VIEW_SKILL_SETS,
  GET_MANAGER_VIEW_JOB_POSITION,
  GET_MANAGER_VIEW_LEAD_RECRUITER,
  GET_MANAGER_VIEW_SOURCED_BY,
  GET_MANAGER_VIEW_UPDATED_BY,
  GET_MANAGER_VIEW_SOURCED_DATE,
  GET_MANAGER_VIEW_JOINED_OR_DECLINED_DATE,
  GET_MANAGER_VIEW_CANDIDATE_STATUS,
  GET_MANAGER_VIEW_HIRING_STAGE,
  FETCH_REQUIREMENT_DETAILS_BY_ID,
  FETCH_CANDIDATE_DETAILS_BY_ID,
  FETCH_MANAGER_HIRING_STATUS_API,
  FETCH_MANAGER_MULTI_SINGLE_CUST_API
} from "../../../../utils/routes";
import {
  MANAGER_VIEW_SEARCH,
  MANAGER_VIEW_CANDIDATE_SEARCH,
  MANAGER_VIEW_CUSTOMERS_GET,
  MANAGER_VIEW_TAC_GET,
  MANAGER_VIEW_SKILL_SETS_GET,
  MANAGER_VIEW_JOB_POSITION_GET,
  MANAGER_VIEW_LEAD_RECRUITER_GET,
  MANAGER_VIEW_SOURCED_BY_GET,
  MANAGER_VIEW_UPDATED_BY_GET,
  MANAGER_VIEW_SOURCED_BY_DATE_GET,
  MANAGER_VIEW_JOINED_OR_DECLINED_DATE_GET,
  MANAGER_VIEW_CANDIDATE_STATUS_GET,
  MANAGER_VIEW_HIRING_STAGE_GET,
  REQUIREMENT_DETAILS_BY_ID_FETCH,
  CANDIDATE_DETAILS_BY_ID_FETCH,
  MANAGER_HIRING_STATUS,
  FETCH_MANAGER_MULTI_SINGLE_CUST,

  GET_ERRORS,
} from "../../types";
import axios from "axios";

// ---------------------------------------------------------------------

export const searchManagerView = (data) => (dispatch) => {
  axios
    .post(SEARCH_MANAGER_VIEW, data, { withCredentials: true })
    .then((res) => {
      console.log("res")
      dispatch(getDispatch(MANAGER_VIEW_SEARCH, res));
      dispatch(setSuccessMessage(res))
     })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const resetManagerView = () => (dispatch) => {
  const res = {
    data: [],
  };
  dispatch(getDispatch(MANAGER_VIEW_SEARCH, res));
};

export const searchManagerViewCandidate = (data) => (dispatch) => {
  axios
    .post(SEARCH_MANAGER_VIEW_CANDIDATE, data, { withCredentials: true })
    .then((res) => {
     
      dispatch(getDispatch(MANAGER_VIEW_CANDIDATE_SEARCH, res));
    })
    .catch((err) => {
      
      dispatch(setErrorMessage(err));
    });
};

export const getManagerViewCustomers = () => async (dispatch) => {
  // axios
  //   .get(GET_MANAGER_VIEW_CUSTOMERS, { withCredentials: true })
  //   .then((res) => {
  //     dispatch(getDispatch(MANAGER_VIEW_CUSTOMERS_GET, res));
  //   })
  //   .catch((err) => {
  //     dispatch(setErrorMessage(err));
  //   });
  try {
    const response = await axios.get(GET_MANAGER_VIEW_CUSTOMERS, {withCredentials: true})
    dispatch(getDispatch(MANAGER_VIEW_CUSTOMERS_GET, response))
  } catch(err) {
    dispatch(setErrorMessage(err))
  }
};

export const getManagerViewSingleMultiTac = (data) => async (dispatch) => {
 
  try {
    const response = await axios.post(FETCH_MANAGER_MULTI_SINGLE_CUST_API,data, {withCredentials: true})
    dispatch(getDispatch(FETCH_MANAGER_MULTI_SINGLE_CUST, response))
  } catch(err) {
    dispatch(setErrorMessage(err))
  }
};

export const getManagerViewTAC = (data) => async (dispatch) => {
  console.log(data)
  try {
    const response = await axios.post(GET_MANAGER_VIEW_TAC,data, {withCredentials: true})
    dispatch(getDispatch(MANAGER_VIEW_TAC_GET, response))
  } catch(err) {
    dispatch(setErrorMessage(err))
  }
  // axios
  //   .get(GET_MANAGER_VIEW_TAC, { withCredentials: true })
  //   .then((res) => {
  //     dispatch(getDispatch(MANAGER_VIEW_TAC_GET, res));
  //   })
  //   .catch((err) => {
  //     dispatch(setErrorMessage(err));
  //   });
};

export const getManagerViewSkillSets = () => async (dispatch) => {
  try {
    const response = await axios.get(GET_MANAGER_VIEW_SKILL_SETS, {withCredentials: true})
    dispatch(getDispatch(MANAGER_VIEW_SKILL_SETS_GET, response))
  } catch(err) {
    dispatch(setErrorMessage(err))
  }
  // axios
  //   .get(GET_MANAGER_VIEW_SKILL_SETS, { withCredentials: true })
  //   .then((res) => {
  //     dispatch(getDispatch(MANAGER_VIEW_SKILL_SETS_GET, res));
  //   })
  //   .catch((err) => {
  //     dispatch(setErrorMessage(err));
  //   });
};

export const getManagerViewPosition = () => async (dispatch) => {
  try {
    const response = await axios.get(GET_MANAGER_VIEW_JOB_POSITION, {withCredentials: true})
    dispatch(getDispatch(MANAGER_VIEW_JOB_POSITION_GET, response))
  } catch(err) {
    dispatch(setErrorMessage(err))
  }
  // axios
  //   .get(GET_MANAGER_VIEW_JOB_POSITION, { withCredentials: true })
  //   .then((res) => {
  //     dispatch(getDispatch(MANAGER_VIEW_JOB_POSITION_GET, res));
  //   })
  //   .catch((err) => {
  //     dispatch(setErrorMessage(err));
  //   });
};

export const getManagerViewLeadRecruiter = (data) => async (dispatch) => {
  try {
    const response = await axios.post(GET_MANAGER_VIEW_LEAD_RECRUITER,data, {withCredentials: true})
    dispatch(getDispatch(MANAGER_VIEW_LEAD_RECRUITER_GET, response))
  } catch(err) {
    dispatch(setErrorMessage(err))
  }
  // axios
  //   .get(GET_MANAGER_VIEW_LEAD_RECRUITER, { withCredentials: true })
  //   .then((res) => {
  //     dispatch(getDispatch(MANAGER_VIEW_LEAD_RECRUITER_GET, res));
  //   })
  //   .catch((err) => {
  //     dispatch(setErrorMessage(err));
  //   });
};

export const getManagerViewSourcedBy = (data) => async (dispatch) => {
  try {
    const response = await axios.post(GET_MANAGER_VIEW_SOURCED_BY,data, {withCredentials: true})
    dispatch(getDispatch(MANAGER_VIEW_SOURCED_BY_GET, response))
  } catch(err) {
    dispatch(setErrorMessage(err))
  }
  // axios
  //   .get(GET_MANAGER_VIEW_SOURCED_BY, { withCredentials: true })
  //   .then((res) => {
  //     dispatch(getDispatch(MANAGER_VIEW_SOURCED_BY_GET, res));
  //   })
  //   .catch((err) => {
  //     dispatch(setErrorMessage(err));
  //   });
};

export const getManagerViewUpdatedBy = (data) => async (dispatch) => {
  try {
    const response = await axios.post(GET_MANAGER_VIEW_UPDATED_BY,data, {withCredentials: true})
    dispatch(getDispatch(MANAGER_VIEW_UPDATED_BY_GET, response))
  } catch(err) {
    dispatch(setErrorMessage(err))
  }
  // axios
  //   .get(GET_MANAGER_VIEW_UPDATED_BY, { withCredentials: true })
  //   .then((res) => {
  //     dispatch(getDispatch(MANAGER_VIEW_UPDATED_BY_GET, res));
  //   })
  //   .catch((err) => {
  //     dispatch(setErrorMessage(err));
  //   });
};

export const getManagerViewSourcedDate = () => async (dispatch) => {
  try {
    const response = await axios.get(GET_MANAGER_VIEW_SOURCED_DATE, {withCredentials: true})
    dispatch(getDispatch(MANAGER_VIEW_SOURCED_BY_DATE_GET, response))
  } catch(err) {
    dispatch(setErrorMessage(err))
  }
  // axios
  //   .get(GET_MANAGER_VIEW_SOURCED_DATE, { withCredentials: true })
  //   .then((res) => {
  //     dispatch(getDispatch(MANAGER_VIEW_SOURCED_BY_DATE_GET, res));
  //   })
  //   .catch((err) => {
  //     dispatch(setErrorMessage(err));
  //   });
};

export const getManagerViewJoinedOrDeclinedDate = () => async (dispatch) => {
  try{
    const response = await axios.get(GET_MANAGER_VIEW_JOINED_OR_DECLINED_DATE, {withCredentials: true})
    dispatch(getDispatch(MANAGER_VIEW_JOINED_OR_DECLINED_DATE_GET, response))
  } catch(err) {
    dispatch(setErrorMessage(err))
  }
  // axios
  //   .get(GET_MANAGER_VIEW_JOINED_OR_DECLINED_DATE, { withCredentials: true })
  //   .then((res) => {
  //     dispatch(getDispatch(MANAGER_VIEW_JOINED_OR_DECLINED_DATE_GET, res));
  //   })
  //   .catch((err) => {
  //     dispatch(setErrorMessage(err));
  //   });
};

export const getManagerViewCandidateStatus = () => async (dispatch) => {
  try {
    const response = await axios.get(GET_MANAGER_VIEW_CANDIDATE_STATUS, {withCredentials: true})
  dispatch(getDispatch(MANAGER_VIEW_CANDIDATE_STATUS_GET, response))
  } catch(err) {
    dispatch(setErrorMessage(err))
  }
  
  // axios
  //   .get(GET_MANAGER_VIEW_CANDIDATE_STATUS, { withCredentials: true })
  //   .then((res) => {
  //     dispatch(getDispatch(MANAGER_VIEW_CANDIDATE_STATUS_GET, res));
  //   })
  //   .catch((err) => {
  //     dispatch(setErrorMessage(err));
  //   });
};

export const getManagerViewHiringStage = () => async (dispatch) => {
  try{
    const response = await axios.get(GET_MANAGER_VIEW_HIRING_STAGE, {withCredentials: true})
    dispatch(getDispatch(MANAGER_VIEW_HIRING_STAGE_GET, response))
  } catch(err) {
    dispatch(setErrorMessage(err))
  }

  
  // axios
  //   .get(GET_MANAGER_VIEW_HIRING_STAGE, { withCredentials: true })
  //   .then((res) => {
  //     dispatch(getDispatch(MANAGER_VIEW_HIRING_STAGE_GET, res));
  //   })
  //   .catch((err) => {
  //     dispatch(setErrorMessage(err));
  //   });
};

export const getManagerViewHiringStatus = () => async (dispatch) => {
  try{
    const response = await axios.get(FETCH_MANAGER_HIRING_STATUS_API, {withCredentials: true})
    dispatch(getDispatch(MANAGER_HIRING_STATUS, response))
  } catch(err) {
    dispatch(setErrorMessage(err))
  }
}

export const fetchRequirementDetails = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`${FETCH_REQUIREMENT_DETAILS_BY_ID}/${id}`, {withCredentials: true})
    dispatch(getDispatch(REQUIREMENT_DETAILS_BY_ID_FETCH, response))
  } catch(err) {
    dispatch(setErrorMessage(err))
  }
  // axios
  //   .get(`${FETCH_REQUIREMENT_DETAILS_BY_ID}/${id}`, { withCredentials: true })
  //   .then((res) => {
  //     dispatch(getDispatch(REQUIREMENT_DETAILS_BY_ID_FETCH, res));
  //   })
  //   .catch((err) => {
  //     dispatch(setErrorMessage(err));
  //   });
};

export const fetchCandidateDetails = (data) => (dispatch) => {
  axios
    .post(FETCH_CANDIDATE_DETAILS_BY_ID, data, {
      withCredentials: true,
    })
    .then((res) => {
      dispatch(getDispatch(CANDIDATE_DETAILS_BY_ID_FETCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
