import React, { PureComponent } from "react";
import { Card, Table } from "reactstrap";
import axios from "axios";
import { GET_CUSTOMER_ACQUISITION_COST } from "../../../../utils/routes";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const xAxisStyle = {
  fontSize: "8px",
  fontWeight: "400",
  marginBottom: "10px",
};

export default class CustomrAcquisitionCost extends PureComponent {
  static jsfiddleUrl = "https://jsfiddle.net/alidingling/30763kr7/";

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      error: "",
    };
  }

  componentDidMount() {
    axios
      .get(GET_CUSTOMER_ACQUISITION_COST, { withCredentials: true })
      .then((res) => {
        this.setState({
          data: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          error: err,
        });
      });
  }

  render() {
    const { data } = this.state;

    return (
      <Card
        className="recharts-card center"
        style={{ height: "150px", padding: "5px" }}
      >
        <BarChart
          width={310}
          height={150}
          data={data}
          margin={{
            top: 5,
            right: 40,
            left: -5,
            bottom: 5,
          }}
          barSize={15}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="QUARTER"
            tick={{ fontSize: "10px", fontWeight: "400" }}
          />
          <YAxis
            tick={{ fontSize: "10px", fontWeight: "400" }}
            domain={[0, "dataMax"]}
          />
          <Tooltip contentStyle={{ fontSize: "10px", fontWeight: "400" }} />
          <Legend iconSize="8px" wrapperStyle={xAxisStyle} />
          <Bar dataKey="AVERAGE" barSize={15} fill="#8884d8" />
        </BarChart>
      </Card>
    );
  }
}
