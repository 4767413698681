import React, { Component } from "react";
import { Form, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// components
import {
  Validator,
  submitValidator,
  valid,
  refresh,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
// redux
import { addDepartment } from "../../../../../redux/actions/crm/crm_setup/departmentAction";

// -------------------------------------------------------------------------------------------------------------
class AddDepartment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      department: "",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      department: this.state.department,
    };
    if (valid) {
      this.props.addDepartment(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidMount contains the error refresh method and setState method for calling the render method
*/
  componentDidMount() {
    refresh();
    this.setState({
      error: "",
    });
  }

  render() {
    const { department, error } = this.state;

    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Add Department</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="6" className="mx-auto">
             
                  <FormComponent
                    labelClass="bold label-sm"
                    label="Department"
                    type="text"
                    name="department"
                    inputClass="form-control-sm"
                    value={department}
                    change={this.onChange}
                    required={true}
                    pattern="onlyLetters"
                  />
            </Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Add
            </button>
          </div>
        </Form>
        {error && <ErrorNotifier message={error} />}
        {this.props.crmDepartments.error && (
          <ErrorNotifier message={this.props.crmDepartments.error} />
        )}
        {this.props.crmDepartments.message && (
          <Notifier message={this.props.crmDepartments.message} />
        )}
      </React.Fragment>
    );
  }
}

AddDepartment.propTypes = {
  addDepartment: PropTypes.func.isRequired,
  crmDepartments: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  crmDepartments: state.crmDepartments,
});

export default connect(mapStateToProps, { addDepartment })(
  AddDepartment
);
