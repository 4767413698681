import React, { Component } from "react";
import { Link } from "react-router-dom";
import DateFns from "date-fns";
import { Container, Table } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// components
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
// redux
import {
  getLeaveManagement,
  updateLeaveManagement,
} from "../../../../redux/actions/hcm/lms/leaveManagementAction";

class PendingLeave extends Component {
  constructor() {
    super();
    this.getData = this.getData.bind(this);
    this.cancelLeave = this.cancelLeave.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  /*
getData function for getting the all list of the leave details from the database
*/
  getData() {
    this.props.getPendingLeaves();
  }

  /* cancelLeave function is to update the leaveStatus as Cancelled */
  cancelLeave(item) {
    let data = {
      id: item.id,
      leaveStatus: "Cancelled",
    };
    this.props.cancelPendingLeave(data);
  }

  render() {
    let shrink = this.props.sidebar ? "scale" : "no-scale";

    return (
      <div className={shrink}>
        <Container className="card container-card pt-2">
          <Link
            to={{
              pathname: "/pie/iam/service_menu",
              moduleName: this.props.location.moduleName,
              data: this.props.location.data,
            }}
          >
            <i className="material-icons cancel-button pointer">cancel</i>
          </Link>
          <h5 className="bold center">My Leave Requests</h5>
          <Table style={{ width: "80%", margin: "auto" }}>
            <thead>
              <tr>
                <th className="opp-head">Request ID</th>
                <th className="opp-head">From Date</th>
                <th className="opp-head">To Date</th>
                <th className="opp-head">No of Days</th>
                <th className="opp-head">Leave Type</th>
                <th className="opp-head">Leave Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.props.leaveManagement.data.length > 0 &&
                this.props.leaveManagement.data.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td className="opp-tableData">{item.id}</td>
                      <td className="opp-tableData">
                        {DateFns.format(item.fromDate, "DD-MM-YYYY")}
                      </td>
                      <td className="opp-tableData">
                        {DateFns.format(item.toDate, "DD-MM-YYYY")}
                      </td>
                      <td className="opp-tableData">{item.noOfDays}</td>
                      <td className="opp-tableData">{item.leaveType}</td>
                      <td className="opp-tableData">{item.leaveStatus}</td>
                      <td>
                        {Date.parse(item.toDate) > Date.parse(new Date()) &&
                        item.leaveStatus !== "Cancelled" ? (
                          <i
                            className="material-icons table-cancel-btn"
                            onClick={() => this.cancelLeave(item)}
                          >
                            cancel
                          </i>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          {this.props.leaveManagement.message && (
            <Notifier message={this.props.leaveManagement.message} />
          )}
          {this.props.leaveManagement.error && (
            <ErrorNotifier message={this.props.leaveManagement.error} />
          )}
        </Container>
      </div>
    );
  }
}

PendingLeave.propTypes = {
  getLeaveManagement: PropTypes.func.isRequired,
  updateLeaveManagement: PropTypes.func.isRequired,
  leaveManagement: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  leaveManagement: state.leaveManagement,
});

const mapDispatchToProps = (dispatch) => ({
  getPendingLeaves: () => dispatch(getLeaveManagement()),
  cancelPendingLeave: (data) => dispatch(updateLeaveManagement(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PendingLeave);
