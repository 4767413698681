import {
  CUSTOMER_ENTITY_GET,
  CUSTOMER_ENTITY_NAME_GET,
  CUSTOMER_ENTITY_FETCH,
  CUSTOMER_ENTITY_SEARCH,
  CUSTOMER_ENTITY_SPOC_NAME_GET,
  UPDATE_CUSTOMER_ENTITY_SPOC_NAME_GET,
  CUSTOMER_ENTITY_TAGGED_CONTACTS_GET,
  GET_ERRORS,
  SET_MESSAGE,
} from "../../../actions/types";

const initialState = {
  data: [],
  nameData: [],
  fetchData: [],
  spocNameData: [],
  taggedContactData: [],
  message: null,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CUSTOMER_ENTITY_GET:
      return {
        ...state,
        data: action.payload,
      };
    case CUSTOMER_ENTITY_NAME_GET:
      return {
        ...state,
        nameData: action.payload,
      };
    case CUSTOMER_ENTITY_SEARCH:
      return {
        ...state,
        data: action.payload,
      };
    case CUSTOMER_ENTITY_FETCH:
      return {
        ...state,
        fetchData: action.payload,
      };
    case CUSTOMER_ENTITY_SPOC_NAME_GET:
      return {
        ...state,
        spocNameData: action.payload,
      };
    case UPDATE_CUSTOMER_ENTITY_SPOC_NAME_GET:
      return {
        ...state,
        spocNameData: action.payload,
      };
    case CUSTOMER_ENTITY_TAGGED_CONTACTS_GET:
      return {
        ...state,
        taggedContactData: action.payload,
      };
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case GET_ERRORS:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
