import axios from 'axios'
import React,{useEffect, useState} from 'react'
import { Container, Table } from "reactstrap";
import { RS_API } from "../../../../../utils/config";
import {Link} from 'react-router-dom'
import {VIEWRESUME_CANDI} from '../../../../../utils/routes'

// component for view uploaded resume
export default function viewUplodedresume(props) {
 console.log(props)

  const [dataArr,setDataArr] = useState([])
  const [errorMsg,setErrorMsg] = useState("")


  // useEffect(() => {
  //   fetchResume();
  // }, [])



// const fetchResume = () =>{

//     axios
//       .get(
//         `${VIEWRESUME_CANDI}`,
//         {
//           params: {
//             canInfoId:props.canId,
//           },
//         },
//         { withCredentials: true }
//       )
//       .then((res) => {
//         setDataArr([res]);
//         if(!res.data.ok){
//           setErrorMsg(res.data.message)
//           }
//       })
//       .catch((err) => {
//         console.log(err);
//       });
// }

let shrink = props.sidebar ? "scale" : "no-scale";


    return (
      <div className={shrink}>
        <Container>
          {/* <div style={{display:"flex",justifyContent:"center"}}>
          <span style={{color:"red"}}>{errorMsg}</span>
          </div>
          <div> */}

          <i
            onClick={props.back}
            className="material-icons cancel-button pointer"
          >
            cancel
          </i>

          <div style={{ width: "100%" }}>
            <iframe
              src={
                `${RS_API}/v1/api/view-resume?canInfoId=${props.canId}`
              }
              width="100%"
              height="600rem"
            ></iframe>
          </div>
        </Container>
      </div>
    );
}
