import axios from "axios";
import { GET_JOB_CATEGORY_RSTYPE,GET_JOBCAT_MAIN } from "../../types";
import {
  ADD_JOB_CATEGORY_RS,
  GET_JOB_CATEGORY_RS,
  UPDATE_JOB_CATEGORY_RS,
  DELETE_JOB_CATEGORY_RS
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addJobCategory = (data, back) => (dispatch) => {
  axios
    .post( ADD_JOB_CATEGORY_RS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getJobCategory));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getJobCategory = () => (dispatch) => {
  axios
    .get(  GET_JOB_CATEGORY_RS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(GET_JOB_CATEGORY_RSTYPE, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
export const getJobCategoryRs = () => (dispatch) => {
  axios
    .get( `${GET_JOB_CATEGORY_RS}/select`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(GET_JOBCAT_MAIN, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateJobCategory = (data, back) => (dispatch) => {
  axios
    .put(  UPDATE_JOB_CATEGORY_RS , data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteJobCategory = (data) => (dispatch) => {
  axios
    .delete( DELETE_JOB_CATEGORY_RS, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getJobCategory));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
