import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
// components
import CustomerEntityDetails from "./CustomerEntityDetails";
import Hover from "../../../aside/Hover";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import AddCustomerEntity from "./AddCustomerEntity";
import SearchBar from "../../../common/searchbar/SearchBar";
import CustTooltip from "../../../common/tooltip/CustTooltip";
// redux
import {
  getCustomerEntity,
  updateCustomerEntity,
  searchCustomerEntity,
} from "../../../../redux/actions/crm/customer_entity/customerEntityAction";

// -----------------------------------------------------------------------------------------------

class CustomerEntity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      selection: null,
      selected: {},
      searchTerm: "",
      hover: false,
      cords: {},
      mouse: {},
      addBtn: false,
      currentPermission: {},
      message: "",
      error: "",
    };
    this.getData = this.getData.bind(this);
    this.select = this.select.bind(this);
    this.search = this.search.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.back = this.back.bind(this);
    this.toggleHoverOn = this.toggleHoverOn.bind(this);
    this.toggleHoverOff = this.toggleHoverOff.bind(this);
    this.toggleAddBtn = this.toggleAddBtn.bind(this);
    this.addBack = this.addBack.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.customers !== this.props.customers) {
      this.setState({
        data: this.props.customers.data,
      });
    }
  }

  select(item) {
    this.setState({
      selection: item,
      selected: item.id,
      hover: false,
    });
  }

  search(e) {
    e.preventDefault();
    this.setState({
      searchTerm: e.target.value,
    });
    this.onSearch(e.target.value);
  }

  onSearch(value) {
    let data = {
      entityName: value,
    };

    this.props.searchCustomerEntity(data);
  }

  toggleHoverOn(e, item) {
    this.setState({
      hover: true,
      cords: {
        one: item.comments,
      },
      mouse: {
        x: e.screenX,
        y: e.screenY,
      },
    });
  }

  toggleHoverOff() {
    this.setState({
      hover: false,
    });
  }

  back() {
    this.setState({
      selection: false,
    });
  }

  getData() {
    this.props.getCustomerEntity();
  }

  componentDidMount() {
    for (let a in this.props.auth.data.accesses) {
      if (this.props.auth.data.accesses[a].appName === "CRM") {
        for (let m in this.props.auth.data.accesses[a].pieUserModuleAccess) {
          if (
            this.props.auth.data.accesses[a].pieUserModuleAccess[m]
              .moduleName === "Customer Entities"
          ) {
            for (let s in this.props.auth.data.accesses[a].pieUserModuleAccess[
              m
            ].pieUserServiceAccess) {
              if (
                this.props.auth.data.accesses[a].pieUserModuleAccess[m]
                  .pieUserServiceAccess[s].serviceName === "Entities Management"
              ) {
                this.setState({
                  currentPermission:
                    this.props.auth.data.accesses[a].pieUserModuleAccess[m]
                      .pieUserServiceAccess[s],
                });
              }
            }
          }
        }
      }
    }
    this.getData();
  }

  /*
  toggleAddBtn function to toggle the addition page
  */
  toggleAddBtn() {
    this.setState({
      addBtn: true,
    });
  }

  /*
  addBack function to back to the list page from the add page
  */
  addBack() {
    this.setState({
      addBtn: false,
    });

    this.getData();
  }

  render() {
    let shrink;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");
    const { selection, selected, data, message, error, addBtn } = this.state;

    return (
      <div className={shrink}>
        <Container className="card container-card">
          {this.state.hover && (
            <Hover
              labels={{ one: "Comments" }}
              cords={this.state.cords}
              mouse={this.state.mouse}
            />
          )}

          {Object.keys(this.state.currentPermission).length > 0 &&
            this.state.currentPermission.creation && (
              <div className="dive">
                {!selection && (
                  <Link to="/pie/crm/add/customer_entity">
                    <CustTooltip title="Create Customer Entity">
                      <button
                        className="btn new-btn white-text label-sm"
                        onClick={this.toggleAddBtn}
                      >
                        New
                      </button>
                    </CustTooltip>
                  </Link>
                )}
              </div>
            )}
          {!selection && (
            <SearchBar
              onChange={this.search}
              value={this.state.searchTerm}
              placeholder="Search for Customer Entity"
              onSubmit={this.onSearch}
              title="Search Customer Entity"
            />
          )}
          {!selection ? (
            <Row className="pt-3">
              <Col md="12" className="p-0">
                <ul className="crm-flex-around  ul-head">
                  <li className="list-label entityId-width">Entity Id</li>
                  <li className="list-label entityCust-width">Customer Name</li>
                  <li className="list-label entityName-width">Entity Name</li>
                  <li className="list-label entityReg-width">
                    Registration No
                  </li>
                  <li className="list-label entityPan-width">PAN No</li>
                  <li className="list-label entityStatus-width">Status</li>
                </ul>
              </Col>
            </Row>
          ) : null}
          <Row onMouseMove={this.onMouseMove}>
            {!selection && !addBtn
              ? this.props.customerEntity.data.length > 0 &&
                this.props.customerEntity.data.map((item) => {
                  return (
                    <Col md="12" className="p-0">
                      <ul
                        onClick={() => {
                          this.select(item);
                        }}
                        // onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                        // onMouseLeave={this.toggleHoverOff}
                        className={`list crm-flex-around pointer ${
                          selected && selected === item.id && "selected-item"
                        } InqList`}
                        key={item.id}
                      >
                        <li className=" list-data  entityId-width">
                          {item.entityNo}
                        </li>
                        <li className="list-data entityCust-width">
                          {item.customerName}
                        </li>
                        <li className="list-data entityName-width">
                          {item.entityName}
                        </li>
                        <li className="list-data entityReg-width">
                          {item.registrationNo}
                        </li>
                        <li className="list-data entityPan-width">
                          {item.panNo}
                        </li>
                        <li className="list-data entityStatus-width">
                          {item.entityStatus}
                        </li>
                      </ul>
                    </Col>
                  );
                })
              : null}
          </Row>
          {selection && (
            <CustomerEntityDetails
              customerEntities={this.state.selection}
              back={this.back}
              permission={this.state.currentPermission}
            />
          )}
          {addBtn && <AddCustomerEntity back={this.addBack} />}
          {message && <Notifier message={message} />}
          {error && <ErrorNotifier message={error} />}
        </Container>
      </div>
    );
  }
}

CustomerEntity.propTypes = {
  auth: PropTypes.object.isRequired,
  getCustomerEntity: PropTypes.func.isRequired,
  updateCustomerEntity: PropTypes.func.isRequired,
  searchCustomerEntity: PropTypes.func.isRequired,
  customerEntity: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  customerEntity: state.customerEntity,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getCustomerEntity,
  updateCustomerEntity,
  searchCustomerEntity,
})(CustomerEntity);
