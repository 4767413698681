import React from "react";
import {countries} from "countries-list"
// select country code component
const SelectCountryCode = (props) => {
   
  const { tab, name, onChange, value, onKeyPress,widthValue } = props;
 
  let countriesNew = JSON.parse(JSON.stringify(countries));
  
  delete countriesNew["IN"];
  const opt = Object.keys(countriesNew).map((key, index) => {
   
    return <option key={key} value={countriesNew[key].phone}>{countriesNew[key].phone}</option>;
  });
  return (
    <div>
      <select
        tabIndex={tab}
        onChange={onChange}
        style={{ height: "30px", width: `${widthValue}`,fontSize:".75rem",fontWeight:400 }}
        // onKeyPress={onKeyPress}
        name={name}
        id={name}
        value={value}
      >
         <option value={value} >{value}</option>
         {Object.keys(countriesNew).map((key, index) => {
       return <option key={key} value={countriesNew[key].phone}>{countriesNew[key].phone}</option>
        })}
      </select>
    </div>
  );
};

export default SelectCountryCode;
