import axios from 'axios'
import {
  GET_JOB_LIST,
  JOB_SEARCH,
   GET_ERRORS
} from './types'
import {
   GET_CAREERS,
  SEARCH_JOB
} from '../../utils/routes'

export const searchJob = data => dispatch => {
    axios.post(SEARCH_JOB, data, {withCredentials: true})
      .then(res => {
        dispatch({
          type: JOB_SEARCH,
          payload: res.data
        })
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.message
        })
      })
  }

  export const getJob = () => dispatch => {
    const data = {domain:['Technology', 'Consulting', 'Sales', 'Support']};
    axios.post(GET_CAREERS, data,  {withCredentials: true})
      .then(res => {
        dispatch({
          type: GET_JOB_LIST,
          payload: res.data
        })
      })
      .catch(err => {
        console.log(err)
        dispatch({
          type: GET_ERRORS,
          payload: err.message
        })
      })
  }