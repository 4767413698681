import {
    PROJECT_MAILS_STONE_ADD,
    PROJECT_MAILS_STONE_FETCH,
    PROJECT_MAILS_STONE_UPDATE,
    SET_MESSAGE
} from '../actions/types';

const initialState = {
    data : [],
    message: null
}

export default function(state= initialState, action){
    switch(action.type){
        case PROJECT_MAILS_STONE_ADD:
            return {
                ...state,
                data: action.payload
            }
        case PROJECT_MAILS_STONE_FETCH:
            return {
                ...state,
                data: action.payload
            }
        case PROJECT_MAILS_STONE_UPDATE: 
            return {
                ...state,
                data: action.payload
            }
        case SET_MESSAGE:
            return {
                ...state,
                message: action.payload
            }
        default :
            return state;
    }
}