import React, { Component } from "react";
import { Form, Row, Col, FormGroup, Label, Input, FormText } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import { addRole } from "../../../../../redux/actions/iam/auth_configuration/role_management/roleAction";
import { getUserGroup } from "../../../../../redux/actions/iam/auth_configuration/user_group/userGroupAction";

class AddRole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userGroupId: "",
      roleCode: "",
      roleName: "",
      description: "",
      message: "",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      pieUserGroupId: Number(this.state.userGroupId),
      roleCode: this.state.roleCode,
      roleName: this.state.roleName,
      roleDescription: this.state.description,
    };
    if (valid) {
      this.props.addRole(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidMount contains the error refresh method and setState method to call the render method
*/
  componentDidMount() {
    this.props.getUserGroup();
    refresh();
    this.setState({
      message: "",
      error: "",
    });
  }

  render() {
    const { userGroupId, roleName, roleCode, description, error, message } =
      this.state;

    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Add Role</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="3"></Col>
            <Col md="6">
              <FormGroup>
                <Label className="label-sm bold">User Group</Label>
                <Input
                  type="select"
                  name="userGroupId"
                  value={userGroupId}
                  onChange={this.onChange}
                  className="form-control-sm"
                  required
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {this.props.userGroups.data.length > 0 &&
                    this.props.userGroups.data.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.id}-{item.groupName}
                        </option>
                      );
                    })}
                </Input>
                {errors.userGroupId && (
                  <FormText className="error">{errors.userGroupId}</FormText>
                )}
              </FormGroup>
              <FormComponent
                labelClass="bold label-sm"
                label="Role Code"
                type="text"
                name="roleCode"
                value={roleCode}
                change={this.onChange}
                required={true}
                minLength="2"
                maxLength="15"
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Role Name"
                type="text"
                name="roleName"
                value={roleName}
                change={this.onChange}
                required={true}
                minLength="2"
                maxLength="36"
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Description"
                type="textarea"
                name="description"
                value={description}
                change={this.onChange}
                placeholder="description (optional)"
                maxLength="50"
              />
            </Col>
            <Col md="3"></Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Add
            </button>
          </div>
        </Form>
        {message && <Notifier message={message} />}
        {error && <ErrorNotifier message={error} />}
        {this.props.roles.message && (
          <Notifier message={this.props.roles.message} />
        )}
        {this.props.roles.error && (
          <ErrorNotifier message={this.props.roles.error} />
        )}
      </React.Fragment>
    );
  }
}

AddRole.propTypes = {
  addRole: PropTypes.func.isRequired,
  role: PropTypes.object.isRequired,
  getUserGroup: PropTypes.func.isRequired,
  userGroups: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  roles: state.roles,
  userGroups: state.userGroups,
});

export default connect(mapStateToProps, { addRole, getUserGroup })(AddRole);
