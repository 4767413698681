import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { MdCancel } from "react-icons/md";
import DateFns from "date-fns";
import { Divider } from "@material-ui/core";
import CommonFlexComponent from "../../../common/commonFlexComponent";

// import {} from 'react-bootstrap'

import {
  Row,
  Col,
  Form,
  Container,
  FormGroup,
  Input,
  Label,
  FormText,
} from "reactstrap";
// import react icons
import { AiFillTags } from "react-icons/ai";
import { GrFormView } from "react-icons/gr";

// redux
import { updatePublishJobs } from "../../../../redux/actions/rs/requirements/publishJobsAction";

// components
import DetailsPageFooter from "./DetailsPageViewFooter";

import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../common/Validator";
import FormComponent from "../../../common/FormComponent";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";

import AddjobBoard from "./addjobBoard";
import ViewJobBoard from "./viewJobBoard";
import CustTooltip from "../../../common/tooltip/CustTooltip";

// ---------------------------------------------------------------------------

class ParticularJobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primarySkills: this.props.storeData[0].primarySkills
        ? this.props.storeData[0].primarySkills
        : "",
      secondarySkills: this.props.storeData[0].secondarySkills
        ? this.props.storeData[0].secondarySkills
        : "",
      jobDescription: this.props.storeData[0].jobDescription
        ? this.props.storeData[0].jobDescription
        : "",
      jobTiming: this.props.storeData[0].JobTimings
        ? this.props.storeData[0].JobTimings
        : "",
      edit: false,
      isUpdate: false,
      boardPopUp: false,
      viewShowPopup: false,
      message: "",
      error: "",
      getData: [],
      dataStore: [],
      showPopUp: false,

      taggedView: false,
      searchValue: "",
      selectedOptionError: null,
      selectedOptionErrorOne: null,
      changeError: "",
    };
    this.handlePopupView = this.handlePopupView.bind(this);
    this.closeBoardPopUp = this.closeBoardPopUp.bind(this);
    this.handleViewOpenJobBoard = this.handleViewOpenJobBoard.bind(this);
    this.handleCloseViewBoard = this.handleCloseViewBoard.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.backnavigate = this.backnavigate.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state.edit) {
      // this.props.getJobs()
      // this.props.storeData[0]
      refresh();
    }
    // if(prevProps !== this.props.storeData[0]){
    //   this.props.getJobs()
    //   this.props.storeData[0]
    // }
  }

  // common onchange
  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  // close function
  backnavigate() {
    this.setState({
      edit: false,
    });
    this.props.getJobs();
  }

  handlePopupView() {
    this.setState({
      boardPopUp: true,
    });
  }

  closeBoardPopUp() {
    this.setState({
      boardPopUp: false,
    });
  }

  handleViewOpenJobBoard() {
    this.setState({
      viewShowPopup: !this.state.viewShowPopup,
    });
  }

  handleCloseViewBoard() {
    this.setState({
      viewShowPopup: false,
    });
  }

  /*
  This function for updating the published job details
  */
  onSubmit(e) {
    e.preventDefault();
    console.log(this.props.storeData);
    submitValidator(e.target);

    let body = {
      id: this.props.storeData[0].id,
      jobRequirementId: Number(this.props.storeData[0].jobRequirementId),
      jobType: this.props.storeData[0].jobType,
      jobCategory: this.props.storeData[0].jobCategory,
      jobPosition: this.props.storeData[0].jobPosition,
      jobDescription: this.state.jobDescription,
      jobLocation: this.props.storeData[0].jobLocation,
      jobTimings: this.state.jobTiming,
      qualifications: this.props.storeData[0].qualifications,
      skillSets: this.props.storeData[0].skillSets,
      primarySkills: this.state.primarySkills,
      secondarySkills: this.state.secondarySkills,
      totalPositions: this.state.totalPosition,
      startDate: this.props.storeData[0].startDate,
      endDate: this.props.storeData[0].endDate,
      jobStatus: this.state.jobStatus,
      reqStatus: this.props.storeData[0].reqStatus,
      minYearsOfExp: Number(this.props.storeData[0].minYearsOfExp),
      maxYearsOfExp: Number(this.props.storeData[0].maxYearsOfExp),
    };
    if (valid) {
      this.props.updatePublishJobs(body, this.backnavigate);
    } else {
      this.setState({
        error: " Please enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  // fetch by id and display function

  render() {
    console.log(this.props.PublishJobs);

    return (
      <Row>
        {!this.state.edit ? (
          <Col md="12">
            <h5 className="bold center mb-4">
              {" "}
              Job Id :
              {this.props.storeData.length > 0 && this.props.storeData[0].id}
            </h5>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                position: "absolute",
                top: "-0.5rem",
                right: "5%",
              }}
            >
              {/* <CustTooltip title="Update Published Jobs">
                <i
                  data-toggle="tooltip"
                  data-placement="top"
                  style={{
                    color: "#0056b3",
                    marginRight: ".5rem",
                    fontSize: "1.25rem",
                  }}
                  className="material-icons pointer edit-btn"
                  onClick={() => this.setState({ edit: !this.state.edit })}
                >
                  edit
                </i>
              </CustTooltip> */}
              <CustTooltip title="Publish Job Board">
                <h3
                  data-toggle="tooltip"
                  data-placement="top"
                  onClick={this.handlePopupView}
                  style={{
                    color: "#0056b3",
                    marginRight: ".5rem",
                    cursor: "pointer",
                    fontSize: "1.5rem",
                  }}
                >
                  {" "}
                  <AiFillTags />{" "}
                </h3>
              </CustTooltip>
              <CustTooltip title="View Published Job Board">
                <h3
                  data-toggle="tooltip"
                  data-placement="top"
                  onClick={this.handleViewOpenJobBoard}
                  style={{
                    color: "#0056b3",
                    marginRight: ".5rem",
                    cursor: "pointer",
                    fontSize: "1.5rem",
                  }}
                >
                  {" "}
                  <GrFormView size="45" />{" "}
                </h3>
              </CustTooltip>
            </div>

            <Link to="/pie/rs/published_jobs">
              <i
                data-toggle="tooltip"
                data-placement="top"
                title="Close"
                onClick={() => this.props.back()}
                className="material-icons pointer cancel-button"
                style={{
                  top: "0.1rem",
                }}
              >
                cancel
              </i>
            </Link>
            {this.state.boardPopUp && (
              <AddjobBoard
                jobId={
                  this.props.storeData.length > 0 && this.props.storeData[0].id
                }
                showPopUp={this.state.boardPopUp}
                handleCloseModal={this.closeBoardPopUp}
              />
            )}

            {this.state.viewShowPopup && (
              <ViewJobBoard
                jobId={
                  this.props.storeData.length > 0 && this.props.storeData[0].id
                }
                showPopUp={this.state.viewShowPopup}
                handleCloseModal={this.handleCloseViewBoard}
              />
            )}

            {this.props.storeData.length > 0 &&
              this.props.storeData.map((data) => {
                return (
                  <div>
                    <Row>
                      <Col style={{ marginLeft: "3rem" }} md="4">
                        <ul className="CustDetails-ul">
                        <CommonFlexComponent
                            label="Req. Id"
                            data={data.jobRequirementId}
                          />
                          <CommonFlexComponent
                            label="Req. Type"
                            data={data.jobType}
                          />

                          <li>
                            <CommonFlexComponent
                              label="Job Category"
                              data={data.jobCategory}
                            />
                          </li>
                          <li>
                            <CommonFlexComponent
                              label="Job Position"
                              data={data.jobPosition}
                            />
                          </li>
                          <li>
                            <CommonFlexComponent
                              label="Qualification"
                              data={data.qualifications}
                            />
                          </li>

                          <li>
                            <CommonFlexComponent
                              label="Start Date"
                              data={
                                data.startDate
                                  ? DateFns.format(data.startDate, "DD-MM-YYYY")
                                  : ""
                              }
                            />
                          </li>

                          <li>
                            <CommonFlexComponent
                              label="End Date"
                              data={
                                data.endDate
                                  ? DateFns.format(data.endDate, "DD-MM-YYYY")
                                  : ""
                              }
                            />
                          </li>
                         
                        </ul>
                      </Col>

                      <Col style={{ marginLeft: "10rem" }} md="4">
                        <ul className="CustDetails-ul">
                        <li>
                            <CommonFlexComponent
                              label="Job Status"
                              data={data.jobStatus}
                            />
                          </li>
                          <li>
                            <CommonFlexComponent
                              label="Job Location"
                              data={data.jobLocation}
                            />
                          </li>

                          <li>
                            <CommonFlexComponent
                              label="Primary Skills"
                              data={data.primarySkills}
                            />
                          </li>
                          <li>
                            <CommonFlexComponent
                              label="Secondary Skills"
                              data={data.secondarySkills}
                            />
                          </li>
                          <li>
                            <CommonFlexComponent
                              label="Job Timing"
                              data={data.jobTimings}
                            />
                          </li>

                          <li>
                            <CommonFlexComponent
                              label=" Min Yrs of Exp."
                              data={data.minYearsOfExp}
                            />
                          </li>
                          <li>
                            <CommonFlexComponent
                              label="Max Yrs of Exp."
                              data={data.maxYearsOfExp}
                            />
                          </li>
                        </ul>
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ marginLeft: "3rem" }} md="12" >
                        <ul className="CustDetails-ul m-0">
                          <li>
                            <div
                              style={{ display: "flex" }}
                            >
                              <div
                                className="label-sm bold rs-req-span-label-col-1 pt-1"
                                style={{ width: "12%" }}
                              >
                                <span
                                  style={{
                                    fontSize: ".875rem",
                                    fontWeight: "500",
                                  }}
                                >
                                  Job Description
                                </span>
                              </div>
                              <div style={{   }}>
                                <span className="label-sm">:</span>
                              </div>
                              <div style={{ width: "78%", paddingLeft: "1%" }}>
                                <span style={{fontSize:".75rem ",fontWeight:"400"}}>
                                  {data?.jobDescription}
                                </span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                    <Divider />
                    <DetailsPageFooter
                      createdBy={data.createdBy}
                      createdOn={data.createdOn}
                      updatedBy={data.updatedBy}
                      updatedOn={data.updatedOn}
                    />
                  </div>
                );
              })}
          </Col>
        ) : (
          <Row>
            <Col md="12">
              <h5 className="bold center">Update Published Jobs</h5>
              <i
                style={{ marginTop: "0.5rem" }}
                onClick={() => this.setState({ edit: !this.state.edit })}
                className="material-icons pointer cancel-button"
              >
                cancel
              </i>
              <Form onSubmit={this.onSubmit} className="p-3 mt-2" noValidate>
                <Row>
                  <Col md="4">
                    <FormComponent
                      index="1"
                      labelClass="bold label-sm"
                      label="Primary Skills"
                      type="textarea"
                      name="primarySkills"
                      change={this.onChange}
                      value={this.state.primarySkills}
                      required={true}
                      placeholder="Primary Skills"
                      maxlength="10"
                    />

                    <FormComponent
                      index="4"
                      labelClass="bold label-sm"
                      label="Job Timing"
                      type="text"
                      name="jobTiming"
                      change={this.onChange}
                      value={this.state.jobTiming}
                      // required={true}
                      placeholder="Job Timing"
                      maxlength="10"
                    />
                  </Col>
                  <Col md="4">
                    <FormComponent
                      index="2"
                      labelClass="bold label-sm"
                      label="Secondary Skills"
                      type="textarea"
                      name="secondarySkills"
                      change={this.onChange}
                      value={this.state.secondarySkills}
                      placeholder="Secondary Skills"
                      maxlength="10"
                    />
                  </Col>
                  <Col md="4">
                    <FormComponent
                      index="3"
                      labelClass="bold label-sm"
                      label="Job Description"
                      type="textarea"
                      name="jobDescription"
                      change={this.onChange}
                      value={this.state.jobDescription}
                      required={true}
                      placeholder="Job Description"
                      maxlength="10"
                    />
                  </Col>
                </Row>
                <div className="center">
                  <button
                    type="submit"
                    className="btn center login-button white-text"

                    // disabled={this.state.buttonValue ? "disabled" : null}
                  >
                    Update
                  </button>
                </div>
              </Form>
            </Col>
          </Row>
        )}

        {this.props.PublishJobs.message ? (
          <Notifier message={this.props.PublishJobs.message} />
        ) : null}
        {this.props.PublishJobs.error ? (
          <ErrorNotifier message={this.props.PublishJobs.error} />
        ) : null}
        {/* {this.state.message ? (
          <Notifier message={this.state.message} />
        ) : null}
        {this.state.error ? (
          <ErrorNotifier message={this.state.error} />
        ) : null} */}
      </Row>
    );
  }
}

ParticularJobs.propTypes = {};

const mapStateToProps = (state) => ({
  PublishJobs: state.PublishJobs,
});

export default connect(mapStateToProps, {
  updatePublishJobs,
})(withRouter(ParticularJobs));
