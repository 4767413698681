import React, { Component } from "react";
import { Form, Row, Col,FormGroup,Input,Label,FormText } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent"
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import Notifier from "../../../../aside/Notifier";
import { MdCancel } from "react-icons/md";
import {updateCanSkill } from "../../../../../redux/actions/rs/configuration/canSkillsAction";
import {getSkillGroup} from "../../../../../redux/actions/rs/configuration/skillgroupaction"
// update can skill component
class UpdateCanSkills extends Component {
  constructor(props) {
    super(props);
    const { skillGroupId,skillVersion,id,skillGroup,  skill } = this.props.data;
    console.log(this.props.data)
    
    this.state = {
      id:id,
      skillGroupId: skillGroupId,
      skill: skill,
     
      skillBodyName:"",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.closeDataSkill = this.closeDataSkill.bind(this)
  }

  // common onchange function
  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  closeDataSkill(data){
    console.log(data)
  
    let filterData = this.state.filteredValueArray.filter(val=>val!==data)
    this.setState({
      filteredValueArray:filterData
    })
    }


  // update function
  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      id: this.state.id,
      skillGroupId:Number(this.state.skillGroupId),
      skill: this.state.skill,
 
    };
    if (valid) {
      this.props.updateCanSkill(data, this.props.back);
      // this.props.handleSkill(this.props.skillGroup)
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
   this.props.getSkillGroup();
    refresh();
    this.setState({
      error: "",
      
    });
  }

  render() {
   
    const { skillGroupId,skill, error } = this.state;

    return (
      <React.Fragment>
        <h5 className="center card-heading pt-2">Update Skill Detail</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="6" className="mx-auto">
            <FormGroup>
                <Label className="bold label-sm">Skill Group Id</Label>
                <Input
                  type="select"
                  name="skillGroupId"
                  className="form-control-sm"
                  value={this.state.skillGroupId}
                  onChange={this.onChange}
                  placeholder="Requirement Type"
                  required={true}
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {this.props.skillGroups.data.map((item) => {
                    return (
                      <option
                        key={item.id}
                        value={item.id}
                      >
                       {`${item.skillGroup}-${item.id}`}
                      </option>
                    );
                  })}
                </Input>
                {errors.skillGroupId && (
                  <FormText className="error">{errors.skillGroupId}</FormText>
                )}
              </FormGroup>
              <FormComponent
                labelClass="bold label-sm"
                label="skill"
                type="text"
                name="skill"
                inputClass="form-control-sm"
                value={skill}
                change={this.onChange}
                required={true}
                length="30"
              />
               <div className="center">
            <button type="submit" className="btn login-button white-text">
              Update
            </button>
          </div>
             

             
          
         
            
            </Col>
          </Row>
        
         
        </Form>
        {error && <ErrorNotifier message={error} />}
        {this.props.CanConSkill.error && (
          <ErrorNotifier message={this.props.CanConSkill.error} />
        )}
        {this.props.CanConSkill.message && (
          <Notifier message={this.props.CanConSkill.message} />
        )}
      </React.Fragment>
    );
  }
}

UpdateCanSkills.propTypes = {
  updateCanSkill: PropTypes.func.isRequired,
  userGroups: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  CanConSkill:state.CanConSkill,
  skillGroups:state.skillGroups
});

export default connect(mapStateToProps, {updateCanSkill,getSkillGroup })(UpdateCanSkills);
