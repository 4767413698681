import axios from "axios";
import {
  EMP_PERSONAL_INFO_GET,
  EMP_PERSONAL_INFO_FETCH,
  SET_MESSAGE,
  UPLOADED_ONBOARDING_EXTRACT_DATA,
  UPLOADED_ONBOARDINGE_PROCESS_DATA,
  UPLOADED_ONBOARDING_UNPROCESS_DATA
} from "../../../types";
import {
  CURD_EMP_PERSONAL_INFO,
  FETCH_EMP_PERSONAL_INFO,
  SEARCH_EMP_PERSONAL_INFO,
  UPLOAD_EMP_PERSONAL_INFO,
} from "../../../../../utils/routes";
import setSuccessMessage from "../../../common/setSuccessMessage";
import setErrorMessage from "../../../common/setErrorMessage";
import getDispatch from "../../../common/getDispatch";

export const addEmpPersonalInfo = (data, back) => (dispatch) => {
  let link = "/pie/hcm/hr/emp_management";

  axios
    .post(CURD_EMP_PERSONAL_INFO, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getEmpPersonalInfo));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const userBatchUpload = (data) => (dispatch) => {
  axios
    .post(UPLOAD_EMP_PERSONAL_INFO, data, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: SET_MESSAGE,
        payload: res.data.message,
      });
      dispatch({
        type: UPLOADED_ONBOARDING_EXTRACT_DATA,
        payload: res.data.extracted,
      });
      dispatch({
        type: UPLOADED_ONBOARDINGE_PROCESS_DATA,
        payload: res.data.processed,
      });
      dispatch({
        type:  UPLOADED_ONBOARDING_UNPROCESS_DATA,
        payload: res.data.unprocessed,
      });


      setTimeout(() => {
        dispatch({
          type: SET_MESSAGE,
          payload: "",
        });
        dispatch(getEmpPersonalInfo());
      }, 2000);
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getEmpPersonalInfo = () => (dispatch) => {
  axios
    .get(CURD_EMP_PERSONAL_INFO, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(EMP_PERSONAL_INFO_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const fetchEmpPersonalInfo = () => (dispatch) => {
  axios
    .get(FETCH_EMP_PERSONAL_INFO, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(EMP_PERSONAL_INFO_FETCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const searchEmpPersonalInfo = (data) => (dispatch) => {
  axios
    .post(SEARCH_EMP_PERSONAL_INFO, data, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(EMP_PERSONAL_INFO_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateEmpPersonalInfo = (data, back) => (dispatch) => {
  axios
    .put(CURD_EMP_PERSONAL_INFO, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteEmpPersonalInfo = (data) => (dispatch) => {
  axios
    .delete(CURD_EMP_PERSONAL_INFO, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getEmpPersonalInfo));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
