import React, { Component } from "react";
import PropTypes from "prop-types";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  Box,
  Typography,
  Button,
  ListItem,
  withStyles,
} from "@material-ui/core";
import { styles } from "./styles";

/**
 *css for the image upload progress bar.
 */
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#EEEEEE",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

/**
 * This component for uploading the images to the server. Common component for Image Upload.
 */
class UploadImage extends Component {
  constructor(props) {
    super(props);

    /**
     * Initial state setting.
     */
    this.state = {
      previewImage: undefined,
      progress: 0,

      message: "",
      isError: false,
      imageInfos: [],
    };
  }

  render() {
    const { previewImage, progress, message, imageInfos, isError } = this.state;

    const { classes, currentFile, selectFile } = this.props;

    return (
      <div className={`${classes.uploadImageWrapper} mg20`}>
        <label htmlFor="btn-upload">
          <input
            id="btn-upload"
            name="btn-upload"
            style={{ display: "none" }}
            type="file"
            accept="image/*"
            onChange={selectFile}
          />
          <Button className="btn-choose" variant="outlined" component="span">
            Choose App Icon
          </Button>
        </label>
        <div className={`${classes.fileName} file-name`}>
          {currentFile ? currentFile.name : null}
        </div>

        {/* upload button (future required ) */}
        {/* <Button
          className="btn-upload"
          color="primary"
          variant="contained"
          component="span"
          disabled={!currentFile}
          onClick={this.upload}
        >
          Upload
        </Button> */}

        {/* upload progress status indication (future required) */}
        {/* {currentFile && (
          <Box className="my20" display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <BorderLinearProgress variant="determinate" value={progress} />
            </Box>
            <Box minWidth={35}>
              <Typography
                variant="body2"
                color="textSecondary"
              >{`${progress}%`}</Typography>
            </Box>
          </Box>
        )} */}

        {/* display the preview of the selected image. (in future required) */}
        {/* {previewImage && (
          <div>
            <img className="preview my20" src={previewImage} alt="" />
          </div>
        )} */}

        {/* {message && (
          <Typography
            variant="subtitle2"
            className={`upload-message ${isError ? "error" : ""}`}
          >
            {message}
          </Typography>
        )} */}

        {/* <Typography variant="h6" className="list-header">
          List of Images
        </Typography>
        <ul className="list-group">
          {imageInfos &&
            imageInfos.map((image, index) => (
              <ListItem divider key={index}>
                <img
                  src={image.url}
                  alt={image.name}
                  height="80px"
                  className="mr20"
                />
                <a href={image.url}>{image.name}</a>
              </ListItem>
            ))}
        </ul> */}
      </div>
    );
  }
}

UploadImage.propTypes = {
  props: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  currentFile: PropTypes.object.isRequired,
  selectFile: PropTypes.func.isRequired,
};

export default withStyles(styles)(UploadImage);
