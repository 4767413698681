//  Authentication
export const LOGIN_DETAILS = "LOGIN_DETAILS";

export const IS_LOGGEDIN = "IS_LOGGEDIN";

export const REGISTER_NEW_USER = "REGISTER_NEW_USER";

export const AUTHENTICATE_USER = "AUTHENTICATE_USER";

export const LOGOUT_USER = "LOGOUT_USER";

//  Errors
export const GET_ERRORS = "GET_ERRORS";

//  Profile
export const GET_EMPLOYEE_DETAILS = "GET_EMPLOYEE_DETAILS";

export const UPDATE_EMPLOYEE_DETAILS = "UPDATE_EMPLOYEE_DETAILS";

export const SEARCH_EMPLOYEE = "SEARCH_EMPLOYEE";
//  Innovation
export const ADD_NEW_IDEA = "ADD_NEW_IDEA";

export const GET_IDEA_LIST = "GET_IDEA_LIST";

export const UPDATE_IDEA_ITEM = "UPDATE_IDEA_ITEM";

export const SEARCH_IDEA_ITEM = "SEARCH_IDEA_ITEM";

//  Customes/Companies
export const ADD_NEW_CUSTOMER = "ADD_NEW_CUSTOMER";

export const GET_CUSTOMER_LIST = "GET_CUSTOMER_LIST";

export const CUSTOMER_UPDATE = "CUSTOMER_UPDATE";

export const CUSTOMER_SEARCH = "CUSTOMER_SEARCH";

//Accounts
export const ACCOUNT_LIST = "ACCOUNT_LIST";

export const ACCOUNT_ALL_LIST = "ACCOUNT_ALL_LIST";

export const ACCOUNT_ADD = "ACCOUNT_ADD";

export const ACCOUNT_UPDATE = "ACCOUNT_UPDATE";

export const ACCOUNT_SEARCH = "ACCOUNT_SEARCH";

//Contacts
export const CONTACT_LIST = "CONTACT_LIST";

export const CONTACT_ALL_LIST = "CONTACT_ALL_LIST";

export const CONTACT_ADD = "CONTACT_ADD";

export const CONTACT_UPDATE = "CONTACT_UPDATE";

export const CONTACT_SEARCH = "CONTACT_SEARCH";

//Partners
export const ADD_NEW_PARTNER = "ADD_NEW_PARTNER";

export const GET_PARTNER_LIST = "GET_PARTNER_LIST";

export const PARTNER_UPDATE = "PARTNER_UPDATE";

export const PARTNER_SEARCH = "PARTNER_SEARCH";

// Partners --> Documents Upload
export const PARTNER_DOCUMENTS_DOWNLOAD = "PARTNER_DOCUMENTS_DOWNLOAD";

//Vendors
export const ADD_NEW_VENDOR = "ADD_NEW_VENDOR";

export const GET_VENDOR_LIST = "GET_VENDOR_LIST";

export const VENDOR_UPDATE = "VENDOR_UPDATE";

export const VENDOR_SEARCH = "VENDOR_SEARCH";

// Vendors --> Documents Upload
export const VENDOR_DOCUMENTS_DOWNLOAD = "VENDOR_DOCUMENTS_DOWNLOAD";

//Supplier
export const ADD_NEW_SUPPLIER = "ADD_NEW_SUPPLIER";

export const GET_SUPPLIER_LIST = "GET_SUPPLIER_LIST";

export const SUPPLIER_UPDATE = "SUPPLIER_UPDATE";

export const SUPPLIER_SEARCH = "SUPPLIER_SEARCH";

//ProductList
export const PRODUCTLIST_LIST = "PRODUCT_LIST";

export const PRODUCTLIST_ADD = "PRODUCT_ADD";

export const PRODUCTLIST_UPDATE = "PRODUCT_UPDATE";

export const PRODUCTLIST_SEARCH = "PRODUCT_SEARCH";

//  Sales
export const ADD_NEW_SALES = "ADD_NEW_SALES";

export const GET_SALES_LIST = "GET_SALES_LIST";

export const UPDATE_SALES_ITEM = "UPDATE_SALES_ITEM";

export const SEARCH_SALES_ITEM = "SEARCH_SALES_ITEM";

//Opportunity
export const GET_OPPORTUNITY_LIST = "GET_OPPORTUNITY_LIST";

// Orders
export const ADD_NEW_ORDERS = "ADD_NEW_ORDERS";

export const GET_ORDERS_LIST = "GET_ORDERS_LIST";

export const UPDATE_ORDERS_ITEM = "UPDATE_ORDERS_ITEM";

export const SEARCH_ORDERS_ITEM = "SEARCH_ORDERS_ITEM";

//  Leads
export const ADD_NEW_LEAD = "ADD_NEW_LEAD";

export const GET_LEADS_LIST = "GET_LEADS_LIST";

export const UPDATE_LEAD_ITEM = "UPDATE_LEAD_ITEM";

export const SEARCH_LEAD_ITEM = "SEARCH_LEAD_ITEM";

// Prospects
export const PROSPECTS_ADD = "PROSPECTS_ADD";

export const PROSPECTS_GET = "PROSPECTS_GET";

export const PROSPECTS_SEARCH = "PROSPECTS_SEARCH";

export const PROSPECTS_UPDATE = "PROSPECTS_UPDATE";

//  Inquiries
export const ADD_NEW_INQUIRY = "ADD_NEW_INQUIRY";

export const GET_INQUIRY_LIST = "GET_INQUIRY_LIST";

export const UPDATE_INQUIRY_ITEM = "UPDATE_INQUIRY_ITEM";

export const SEARCH_INQUIRY_ITEM = "SEARCH_INQUIRY_ITEM";

// Campaign
export const ADD_NEW_CAMPAIGN = "ADD_NEW_CAMPAIGN";

export const GET_CAMPAIGN_LIST = "GET_CAMPAIGN_LIST";

export const UPDATE_CAMPAIGN_ITEM = "UPDATE_CAMPAIGN_ITEM";

export const SEARCH_CAMPAIGN_ITEM = "SEARCH_CAMPAIGN_ITEM";

//Products
export const PRODUCT_ADD = "PRODUCT_ADD";

export const PRODUCT_LIST = "PRODUCT_LIST";

export const PRODUCT_SEARCH = "PRODUCT_SEARCH";

export const PRODUCT_UPDATE = "PRODUCT_UPDATE";

//  Projects
export const ADD_NEW_PROJECT = "ADD_NEW_PROJECT";

export const GET_PROJECT_LIST = "GET_PROJECT_LIST";

export const UPDATE_PROJECT_ITEM = "UPDATE_PROJECT_ITEM";

export const SEARCH_PROJECT_ITEM = "SEARCH_PROJECT_ITEM";

//ProjectTeamAssignment
export const PROJECT_TEAM_ASSIGNMENT_LIST = "PROJECT_TEAM_ASSIGNMENT_LIST";

//ProjectMailsStone/Goals
export const PROJECT_MAILS_STONE_ADD = "PROJECT_MAILS_STONE_ADD";

export const PROJECT_MAILS_STONE_FETCH = "PROJECT_MAILS_STONE_FETCH";

export const PROJECT_MAILS_STONE_UPDATE = "PROJECT_MAILS_STONE_UPDATE";

export const PROJECT_MAILS_STONE_DELETE = "PROJECT_MAILS_STONE_DELETE";

//Services
export const SERVICE_LIST = "SERVICE_LIST";

export const SERVICE_ADD = "SERVICE_ADD";

export const SERVICE_SEARCH = "SERVICE_SEARCH";

export const SERVICE_UPDATE = "SERVICE_UPDATE";

//serviceTeamAssignment
export const SERVICE_TEAM_ASSIGNMENT_LIST = "SERVICE_TEAM_ASSIGNMENT_LIST";

//  Message
export const SET_MESSAGE = "SET_MESSAGE";

//  Timesheet
export const SAVE_TIMESHEET = "SAVE_TIMESHEET";

//  Tech Screen Jobs
export const GET_TS_JOBS = "GET_TS_JOBS";

export const ADD_TS_JOBS = "ADD_TS_JOBS";

export const UPDATE_TS_JOBS = "UPDATE_TS_JOBS";

export const DELETE_TS_JOBS = "DELETE_TS_JOBS";

export const NOTIFY_JOBS = "NOTIFY_JOBS";

//  Tech Screen Candidates
export const GET_TS_CANDIDATES = "GET_TS_CANDIDATES";

export const ADD_TS_CANDIDATES = "ADD_TS_CANDIDATES";

export const UPDATE_TS_CANDIDATES = "UPDATE_TS_CANDIDATES";

export const DELETE_TS_CANDIDATES = "DELETE_TS_CANDIDATES";

export const NOTIFY_CANDIDATES = "NOTIFY_CANDIDATES";

//  Tech Screen Panel
export const GET_TS_PANEL = "GET_TS_PANEL";

export const ADD_TS_PANEL = "ADD_TS_PANEL";

export const UPDATE_TS_PANEL = "UPDATE_TS_PANEL";

export const DELETE_TS_PANEL = "DELETE_TS_PANEL";

export const NOTIFY_PANEL = "NOTIFY_PANEL";

//Role management
export const GET_ACCESS_LIST = "GET_ACCESS_LIST";

//Menu Management
export const MENU_SEARCH = "MENU_SEARCH";

//DropDown component
export const GET_CURRENT_PERMISSION_ARRAY = "GET_CURRENT_PERMISSION_ARRAY";

//userRole component
export const USER_ROLE_SEARCH = "USER_ROLE_SEARCH";

//jOb Component
export const JOB_SEARCH = "JOB_SEARCH";
export const GET_JOB_LIST = "GET_JOB_LIST";

//Platform - Business Geographies
export const BUSINESS_GEOGRAPHIES_GET = "BUSINESS_GEOGRAPHIES_GET";

//Platform - Business Geographies
export const BUSINESS_GEOGRAPHIES_ADD = "BUSINESS_GEOGRAPHIES_ADD";

export const BUSINESS_GEOGRAPHIES1_GET = "BUSINESS_GEOGRAPHIES1_GET";

export const BUSINESS_GEOGRAPHIES_UPDATE = "BUSINESS_GEOGRAPHIES_UPDATE";

export const BUSINESS_GEOGRAPHIES_DELETE = "BUSINESS_GEOGRAPHIES_DELETE";

//Platform - Employee Grade
export const EMPLOYEE_GRADE_GET = "EMPLOYEE_GRADE_GET";

//Platform - Business Setup - Business Type
export const BUSINESS_TYPE_ADD = "BUSINESS_TYPE_ADD";

export const BUSINESS_TYPE_GET = "BUSINESS_TYPE_GET";

export const BUSINESS_TYPE_UPDATE = "BUSINESS_TYPE_UPDATE";

export const BUSINESS_TYPE_DELETE = "BUSINESS_TYPE_DELETE";

//Platform - Employee Designation
export const EMPLOYEE_DESIGNATION_GET = "EMPLOYEE_DESIGNATION_GET";

//Platform - Contact Relation
export const CONTACT_RELATION_GET = "CONTACT_RELATION_GET";

//Platform - auth configuration - menumanagement
export const APPLICATION_GET = "APPLICATION_GET";

export const MODULE_GET = "MODULE_GET";

export const SERVICE_GET = "SERVICE_GET";

// Platform - auth configuration - access and permission
export const APPLICATION_ACCESS_PERMISSION_GET =
  "APPLICATION_ACCESS_PERMISSION_GET";

export const MODULE_ACCESS_PERMISSION_GET = "MODULE_ACCESS_PERMISSION_GET";

export const SERVICE_ACCESS_PERMISSION_GET = "SERVICE_ACCESS_PERMISSION_GET";

// Platform --> auth configuration --> user group
export const USER_GROUP_GET = "USER_GROUP_GET";

// IAM --> IAM Config
export const IAM_CONFIG_GET = "IAM_CONFIG_GET";

// Platform --> auth configuration --> Role management
export const ROLE_GET = "ROLE_GET";
export const ROLE_SEARCH = "ROLE_SEARCH";

// Role Mapping
export const ROLE_MAPPING_GET = "ROLE_MAPPING_GET";
export const ROLE_MAPPING_SEARCH = "ROLE_MAPPING_SEARCH";

//Platform - Business Setup - IdeaStatus
export const IDEA_STATUS_ADD = "IDEA_STATUS_ADD";

export const IDEA_STATUS_GET = "IDEA_STATUS_GET";

export const IDEA_STATUS_UPDATE = "IDEA_STATUS_UPDATE";

export const IDEA_STATUS_DELETE = "IDEA_STATUS_DELETE";

//Platform - CRM Setup - Departments
export const DEPARTMENTS_ADD = "DEPARTMENTS_ADD";

export const DEPARTMENTS_UPDATE = "DEPARTMENTS_UPDATE";

export const DEPARTMENTS_DELETE = "DEPARTMENTS_DELETE";

//Platform - Business Setup - RequestCategory
export const REQUEST_CATEGORY_ADD = "REQUEST_CATEGORY_ADD";

export const REQUEST_CATEGORY_GET = "REQUEST_CATEGORY_GET";

export const REQUEST_CATEGORY_UPDATE = "REQUEST_CATEGORY_UPDATE";

export const REQUEST_CATEGORY_DELETE = "REQUEST_CATEGORY_DELETE";

//Platform - Business Setup - PortalFeedback Severity
export const PORTAL_FEEDBACK_SEVERITY_ADD = "PORTAL_FEEDBACK_SEVERITY_ADD";

export const PORTAL_FEEDBACK_SEVERITY_GET = "PORTAL_FEEDBACK_SEVERITY_GET";

export const PORTAL_FEEDBACK_SEVERITY_UPDATE =
  "PORTAL_FEEDBACK_SEVERITY_UPDATE";

export const PORTAL_FEEDBACK_SEVERITY_DELETE =
  "PORTAL_FEEDBACK_SEVERITY_DELETE";

//Platform - Business Setup - PortalFeedback Priority
export const PORTAL_FEEDBACK_PRIORITY_ADD = "PORTAL_FEEDBACK_PRIORITY_ADD";

export const PORTAL_FEEDBACK_PRIORITY_GET = "PORTAL_FEEDBACK_PRIORITY_GET";

export const PORTAL_FEEDBACK_PRIORITY_UPDATE =
  "PORTAL_FEEDBACK_PRIORITY_UPDATE";

export const PORTAL_FEEDBACK_PRIORITY_DELETE =
  "PORTAL_FEEDBACK_PRIORITY_DELETE";

//Platform - Business Setup - PortalFeedback Status
export const PORTAL_FEEDBACK_STATUS_ADD = "PORTAL_FEEDBACK_STATUS_ADD";

export const PORTAL_FEEDBACK_STATUS_GET = "PORTAL_FEEDBACK_STATUS_GET";

export const PORTAL_FEEDBACK_STATUS_UPDATE = "PORTAL_FEEDBACK_STATUS_UPDATE";

export const PORTAL_FEEDBACK_STATUS_DELETE = "PORTAL_FEEDBACK_STATUS_DELETE";

//Platform - CRM Setup - Business Domain
export const CUSTOMER_BUSINESS_DOMAIN_GET = "CUSTOMER_BUSINESS_DOMAIN";

//Platform - CRM Setup - Contact Designation
export const CONTACT_DESIGNATION_ADD = "CONTACT_DESIGNATION_ADD";

export const CONTACT_DESIGNATION_UPDATE = "CONTACT_DESIGNATION_UPDATE";

export const CONTACT_DESIGNATION_DELETE = "CONTACT_DESIGNATION_DELETE";

// CRM - CRM Setup - Department
export const CRM_DEPARTMENT_GET = "CRM_DEPARTMENT_GET"

//Platform - CRM Setup - Account Status
export const ACCOUNT_STATUS_ADD = "ACCOUNT_STATUS_ADD";

export const ACCOUNT_STATUS_GET = "ACCOUNT_STATUS_GET";

export const ACCOUNT_STATUS_UPDATE = "ACCOUNT_STATUS_UPDATE";

export const ACCOUNT_STATUS_DELETE = "ACCOUNT_STATUS_DELETE";

//Platform - Marketing Setup - Campaign Type
export const CAMPAIGN_TYPE_ADD = "CAMPAIGN_TYPE_ADD";

export const CAMPAIGN_TYPE_GET = "CAMPAIGN_TYPE_GET";

export const CAMPAIGN_TYPE_UPDATE = "CAMPAIGN_TYPE_UPDATE";

export const CAMPAIGN_TYPE_DELETE = "CAMPAIGN_TYPE_DELETE";

//Platform - Marketing Setup - Campaign Success Criteria
export const CAMPAIGN_SUCCESS_CRITERIA_ADD = "CAMPAIGN_SUCCESS_CRITERIA_ADD";

export const CAMPAIGN_SUCCESS_CRITERIA_GET = "CAMPAIGN_SUCCESS_CRITERIA_GET";

export const CAMPAIGN_SUCCESS_CRITERIA_UPDATE =
  "CAMPAIGN_SUCCESS_CRITERIA_UPDATE";

export const CAMPAIGN_SUCCESS_CRITERIA_DELETE =
  "CAMPAIGN_SUCCESS_CRITERIA_DELETE";

//Platform - Marketing Setup - Lead Source
export const LEAD_SOURCE_ADD = "LEAD_SOURCE_ADD";

export const LEAD_SOURCE_GET = "LEAD_SOURCE_GET";

export const LEAD_SOURCE_UPDATE = "LEAD_SOURCE_UPDATE";

export const LEAD_SOURCE_DELETE = "LEAD_SOURCE_DELETE";

//Platform - Sales - Prospects Status
export const PROSPECTS_STATUS_ADD = "PROSPECTS_STATUS_ADD";

export const PROSPECTS_STATUS_GET = "PROSPECTS_STATUS_GET";

export const PROSPECTS_STATUS_UPDATE = "PROSPECTS_STATUS_UPDATE";

export const PROSPECTS_STATUS_DELETE = "PROSPECTS_STATUS_DELETE";

//Platform - Sales - Prospects Type
export const PROSPECTS_TYPE_ADD = "PROSPECTS_TYPE_ADD";

export const PROSPECTS_TYPE_GET = "PROSPECTS_TYPE_GET";

export const PROSPECTS_TYPE_UPDATE = "PROSPECTS_TYPE_UPDATE";

export const PROSPECTS_TYPE_DELETE = "PROSPECTS_TYPE_DELETE";

//Platform - Sales - Sales Activity
export const SALES_ACTIVITY_ADD = "SALES_ACTIVITY_ADD";

export const SALES_ACTIVITY_GET = "SALES_ACTIVITY_GET";

export const SALES_ACTIVITY_UPDATE = "SALES_ACTIVITY_UPDATE";

export const SALES_ACTIVITY_DELETE = "SALES_ACTIVITY_DELETE";

//Platform - Sales - Sales Activity Status
export const SALES_ACTIVITY_STATUS_ADD = "SALES_ACTIVITY_STATUS_ADD";

export const SALES_ACTIVITY_STATUS_GET = "SALES_ACTIVITY_STATUS_GET";

export const SALES_ACTIVITY_STATUS_UPDATE = "SALES_ACTIVITY_STATUS_UPDATE";

export const SALES_ACTIVITY_STATUS_DELETE = "SALES_ACTIVITY_STATUS_DELETE";

//Platform - Sales - Sales Deal Type
export const SALES_DEAL_TYPE_ADD = "SALES_DEAL_TYPE_ADD";

export const SALES_DEAL_TYPE_GET = "SALES_DEAL_TYPE_GET";

export const SALES_DEAL_TYPE_UPDATE = "SALES_DEAL_TYPE_UPDATE";

export const SALES_DEAL_TYPE_DELETE = "SALES_DEAL_TYPE_DELETE";

//Platform - Sales - Opportunity Source
export const SALES_OPPORTUNITY_SOURCE_ADD = "SALES_OPPORTUNITY_SOURCE_ADD";

export const SALES_OPPORTUNITY_SOURCE_GET = "SALES_OPPORTUNITY_SOURCE_GET";

export const SALES_OPPORTUNITY_SOURCE_UPDATE =
  "SALES_OPPORTUNITY_SOURCE_UPDATE";

export const SALES_OPPORTUNITY_SOURCE_DELETE =
  "SALES_OPPORTUNITY_SOURCE_DELETE";

//Platform - Sales - Opportunity Stage
export const SALES_OPPORTUNITY_STAGE_ADD = "SALES_OPPORTUNITY_STAGE_ADD";

export const SALES_OPPORTUNITY_STAGE_GET = "SALES_OPPORTUNITY_STAGE_GET";

export const SALES_OPPORTUNITY_STAGE_UPDATE = "SALES_OPPORTUNITY_STAGE_UPDATE";

export const SALES_OPPORTUNITY_STAGE_DELETE = "SALES_OPPORTUNITY_STAGE_DELETE";

//Platform - Sales - Opportunity Type
export const SALES_OPPORTUNITY_TYPE_ADD = "SALES_OPPORTUNITY_TYPE_ADD";

export const SALES_OPPORTUNITY_TYPE_GET = "SALES_OPPORTUNITY_TYPE_GET";

export const SALES_OPPORTUNITY_TYPE_UPDATE = "SALES_OPPORTUNITY_TYPE_UPDATE";

export const SALES_OPPORTUNITY_TYPE_DELETE = "SALES_OPPORTUNITY_TYPE_DELETE";

//Platform - Sales - Milestone
export const SALES_MILESTONE_ADD = "SALES_MILESTONE_ADD";

export const SALES_MILESTONE_GET = "SALES_MILESTONE_GET";

export const SALES_MILESTONE_UPDATE = "SALES_MILESTONE_UPDATE";

export const SALES_MILESTONE_DELETE = "SALES_MILESTONE_DELETE";

//Platform - Sales - Milestone Status
export const SALES_MILESTONE_STATUS_ADD = "SALES_MILESTONE_STATUS_ADD";

export const SALES_MILESTONE_STATUS_GET = "SALES_MILESTONE_STATUS_GET";

export const SALES_MILESTONE_STATUS_UPDATE = "SALES_MILESTONE_STATUS_UPDATE";

export const SALES_MILESTONE_STATUS_DELETE = "SALES_MILESTONE_STATUS_DELETE";

//Platform - Sales - Milestone Feedback
export const SALES_MILESTONE_FEEDBACK_ADD = "SALES_MILESTONE_FEEDBACK_ADD";

export const SALES_MILESTONE_FEEDBACK_GET = "SALES_MILESTONE_FEEDBACK_GET";

export const SALES_MILESTONE_FEEDBACK_UPDATE =
  "SALES_MILESTONE_FEEDBACK_UPDATE";

export const SALES_MILESTONE_FEEDBACK_DELETE =
  "SALES_MILESTONE_FEEDBACK_DELETE";

//Platform - Sales - Potential
export const SALES_POTENTIAL_ADD = "SALES_POTENTIAL_ADD";

export const SALES_POTENTIAL_GET = "SALES_POTENTIAL_GET";

export const SALES_POTENTIAL_UPDATE = "SALES_POTENTIAL_UPDATE";

export const SALES_POTENTIAL_DELETE = "SALES_POTENTIAL_DELETE";

//Platform - Product --> Product Status
export const PRODUCT_STATUS_ADD = "PRODUCT_STATUS_ADD";

export const PRODUCT_STATUS_GET = "PRODUCT_STATUS_GET";

export const PRODUCT_STATUS_UPDATE = "PRODUCT_STATUS_UPDATE";

export const PRODUCT_STATUS_DELETE = "PRODUCT_STATUS_DELETE";

// Platform - Poject Setup ----> Project Type
export const PROJECT_TYPE_GET = "PROJECT_TYPE_GET";

// Platform - Poject Setup ----> Project Status
export const PROJECT_STATUS_GET = "PROJECT_STATUS_GET";

//Platform - Project ---> Billing Type
export const BILLING_TYPE_ADD = "BILLING_TYPE_ADD";

export const BILLING_TYPE_GET = "BILLING_TYPE_GET";

export const BILLING_TYPE_UPDATE = "BILLING_TYPE_UPDATE";

export const BILLING_TYPE_DELETE = "BILLING_TYPE_DELETE";

//Platform ----> Currency
export const CURRENCY_GET = "CURRENCY_GET";

//LMS --> COMPANY HOLIDAY
export const GET_COMPANY_HOLIDAY = "GET_COMPANY_HOLIDAY";

// LMS --> Attendance Report
export const MANAGER_ATTENDANCE_REPORT_FETCH =
  "MANAGER_ATTENDANCE_REPORT_FETCH";

export const MANAGER_LEAVE_REPORT_FETCH = "MANAGER_LEAVE_REPORT_FETCH";

//Platform - Operations Setup - Partner Type
export const PARTNER_TYPE_ADD = "PARTNER_TYPE_ADD";

export const PARTNER_TYPE_GET = "PARTNER_TYPE_GET";

export const PARTNER_TYPE_UPDATE = "PARTNER_TYPE_UPDATE";

export const PARTNER_TYPE_DELETE = "PARTNER_TYPE_DELETE";

//Platform - Operations Setup - Partner Services
export const PARTNER_SERVICES_ADD = "PARTNER_SERVICES_ADD";

export const PARTNER_SERVICES_GET = "PARTNER_SERVICES_GET";

export const PARTNER_SERVICES_UPDATE = "PARTNER_SERVICES_UPDATE";

export const PARTNER_SERVICES_DELETE = "PARTNER_SERVICES_DELETE";

//Platform - Operations Setup - Partner Services
export const PARTNER_PYMT_TERMS_ADD = "PARTNER_PYMT_TERMS_ADD";

export const PARTNER_PYMT_TERMS_GET = "PARTNER_PYMT_TERMS_GET";

export const PARTNER_PYMT_TERMS_UPDATE = "PARTNER_PYMT_TERMS_UPDATE";

export const PARTNER_PYMT_TERMS_DELETE = "PARTNER_PYMT_TERMS_DELETE";

//Platform - Operations Setup -  Vendor Type
export const VENDOR_TYPE_ADD = "VENDOR_TYPE_ADD";

export const VENDOR_TYPE_GET = "VENDOR_TYPE_GET";

export const VENDOR_TYPE_UPDATE = "VENDOR_TYPE_UPDATE";

export const VENDOR_TYPE_DELETE = "VENDOR_TYPE_DELETE";

//Platform - Operations Setup - Vendor Services
export const VENDOR_SERVICES_ADD = "VENDOR_SERVICES_ADD";

export const VENDOR_SERVICES_GET = "VENDOR_SERVICES_GET";

export const VENDOR_SERVICES_UPDATE = "VENDOR_SERVICES_UPDATE";

export const VENDOR_SERVICES_DELETE = "VENDOR_SERVICES_DELETE";

//Platform - Operations Setup - Vendor Services
export const VENDOR_PYMT_TERMS_ADD = "VENDOR_PYMT_TERMS_ADD";

export const VENDOR_PYMT_TERMS_GET = "VENDOR_PYMT_TERMS_GET";

export const VENDOR_PYMT_TERMS_UPDATE = "VENDOR_PYMT_TERMS_UPDATE";

export const VENDOR_PYMT_TERMS_DELETE = "VENDOR_PYMT_TERMS_DELETE";

//Platform - Finance Setup - Account Payable --> Issue PO ---> PO Type
export const PO_TYPE_ADD = "PO_TYPE_ADD";

export const PO_TYPE_GET = "PO_TYPE_GET";

export const PO_TYPE_UPDATE = "PO_TYPE_UPDATE";

export const PO_TYPE_DELETE = "PO_TYPE_DELETE";

//Platform - Finance Setup - Account Payable --> Issue PO ---> PO Status
export const PO_STATUS_ADD = "PO_STATUS_ADD";

export const PO_STATUS_GET = "PO_STATUS_GET";

export const PO_STATUS_UPDATE = "PO_STATUS_UPDATE";

export const PO_STATUS_DELETE = "PO_STATUS_DELETE";

//Platform - Finance Setup - Claims - Claim Status
export const CLAIM_STATUS_ADD = "CLAIM_STATUS_ADD";

export const CLAIM_STATUS_GET = "CLAIM_STATUS_GET";

export const CLAIM_STATUS_UPDATE = "CLAIM_STATUS_UPDATE";

export const CLAIM_STATUS_DELETE = "CLAIM_STATUS_DELETE";

//Platform - Finance Setup - Claims - Claim Type
export const CLAIM_TYPE_ADD = "CLAIM_TYPE_ADD";

export const CLAIM_TYPE_GET = "CLAIM_TYPE_GET";

export const CLAIM_TYPE_UPDATE = "CLAIM_TYPE_UPDATE";

export const CLAIM_TYPE_DELETE = "CLAIM_TYPE_DELETE";

//Platform - Finance Setup - Claims - Employee Claim
export const EMPLOYEE_CLAIM_ADD = "EMPLOYEE_CLAIM_ADD";

export const EMPLOYEE_CLAIM_GET = "EMPLOYEE_CLAIM_GET";

export const EMPLOYEE_CLAIM_SELECT = "EMPLOYEE_CLAIM_SELECT";

export const EMPLOYEE_CLAIM_UPDATE = "EMPLOYEE_CLAIM_UPDATE";

export const EMPLOYEE_CLAIM_DELETE = "EMPLOYEE_CLAIM_DELETE";

//Platform - Finance Setup - Invoice - Payment Status
export const PAYMENT_STATUS_ADD = "PAYMENT_STATUS_ADD";

export const PAYMENT_STATUS_GET = "PAYMENT_STATUS_GET";

export const PAYMENT_STATUS_UPDATE = "PAYMENT_STATUS_UPDATE";

export const PAYMENT_STATUS_DELETE = "PAYMENT_STATUS_DELETE";

//Platform - Finance Setup - Invoice - Receivable Status
export const RECEIVABLE_STATUS_ADD = "RECEIVABLE_STATUS_ADD";

export const RECEIVABLE_STATUS_GET = "RECEIVABLE_STATUS_GET";

export const RECEIVABLE_STATUS_UPDATE = "RECEIVABLE_STATUS_UPDATE";

export const RECEIVABLE_STATUS_DELETE = "RECEIVABLE_STATUS_DELETE";

//Platform - Finance Setup - Invoice - invoice Type
export const INVOICE_TYPE_ADD = "INVOICE_TYPE_ADD";

export const INVOICE_TYPE_GET = "INVOICE_TYPE_GET";

export const INVOICE_TYPE_UPDATE = "INVOICE_TYPE_UPDATE";

export const INVOICE_TYPE_DELETE = "INVOICE_TYPE_DELETE";

//Platform - Finance Setup - Issue PO
export const ISSUE_PO_ADD = "ISSUE_PO_ADD";

export const ISSUE_PO_GET = "ISSUE_PO_GET";

export const ISSUE_PO_UPDATE = "ISSUE_PO_UPDATE";

export const ISSUE_PO_DELETE = "ISSUE_PO_DELETE";

export const PURCHASE_ITEM_GET = "PURCHASE_ITEM_GET";

export const SCHEDULED_PYMT_DATE_GET = "SCHEDULED_PYMT_DATE_GET";

export const LATEST_PO_GET = "LATEST_PO_GET";

//Platform - Finance Setup - Accounts Receivable - PO Entry
export const PO_ENTRY_ADD = "PO_ENTRY_ADD";

export const PO_ENTRY_GET = "PO_ENTRY_GET";

export const PO_ENTRY_UPDATE = "PO_ENTRY_UPDATE";

export const PO_ENTRY_DELETE = "PO_ENTRY_DELETE";

export const PO_ENTRY_PURCHASE_ITEM_GET = "PO_ENTRY_PURCHASE_ITEM_GET";

export const PO_ENTRY_SCHEDULED_PYMT_DATE_GET =
  "PO_ENTRY_SCHEDULED_PYMT_DATE_GET";

export const PO_ENTRY_LATEST_PO_GET = "PO_ENTRY_LATEST_PO_GET";

// Finance -- Payslip -- Upload Payslip
export const PAYSLIP_UPLOAD = "PAYSLIP_UPLOAD";

export const PAYSLIP_GET = "PAYSLIP_GET";

export const PAYSLIP_DOWNLOAD = "PAYSLIP_DOWNLOAD";

// Finance -- Form16 -- Upload Payslip
export const FORM16_UPLOAD = "FORM16_UPLOAD";

export const FORM16_GET = "FORM16_GET";

export const FORM16_DOWNLOAD = "FORM16_DOWNLOAD";

// Finance --- Accounts Payable --- Enter Invoice
export const INVOICE_GET = "INVOICE_GET";

export const LATEST_INVOICE_GET = "LATEST_INVOICE_GET";

export const RECIEVE_ITEM_GET = "RECIEVE_ITEM_GET";

// Finance --- Accounts Receivable --- Sales Invoice
export const SALES_INVOICE_GET = "SALES_INVOICE_GET";

export const SALES_INVOICE_RECIEVE_ITEM_GET = "SALES_INVOICE_RECIEVE_ITEM_GET";

export const LATEST_SALES_INVOICE_GET = "LATEST_SALES_INVOICE_GET";

// ---------------------------------------------------------------------------------------------
// IAM --> EMPLOYEE ONBOARDING
export const EMP_ONBOARDING_GET = "EMP_ONBOARDING_GET";

// -------------------------------------------------------------------------------------------
// HCM --> Configuration --> BusinessCountries
export const BUSINESS_COUNTRIES_GET = "BUSINESS_COUNTRIES_GET";

// HCM --> Configuration --> BusinessCountryCode
export const BUSINESS_COUNTRY_CODE_GET = "BUSINESS_COUNTRY_CODE_GET";

// HCM --> Configuration --> BusinessGeowise
export const BUSINESS_GEOWISE_GET = "BUSINESS_GEOWISE_GET";

//HCM --> Configuration --> BusinessLocations
export const BUSINESS_LOCATIONS_GET = "BUSINESS_LOCATIONS_GET";

//HCM --> Configuration --> Business Unit
export const BUSINESS_UNIT_GET = "BUSINESS_UNIT_GET";

//HCM --> Configuration --> ManagerResignationReason
export const MANAGER_RESIGNATION_REASON_GET = "MANAGER_RESIGNATION_REASON_GET";

//HCM --> Configuration --> ResignationType
export const RESIGNATION_TYPE_GET = "RESIGNATION_TYPE_GET";

// HCM --> Configuration --> EmpContactRelation
export const EMP_RELATION_GET = "EMP_RELATION_GET";

// HCM --> Configuration --> EmpDesignation
export const EMP_DESIGNATION_GET = "EMP_DESIGNATION_GET";

// HCM --> Configuration --> EmpPosition
export const EMP_POSITION_GET = "EMP_POSITION_GET";
export const EMP_POSITION_HIERARCHY_FETCH = "EMP_POSITION_HIERARCHY_FETCH";

// HCM --> Configuration --> EmpGrade
export const EMP_GRADE_GET = "EMP_GRADE_GET";

//HCM --> Configuration --> EmpLeaveType
export const LEAVE_TYPE_GET = "LEAVE_TYPE_GET";

//HCM -- Configuration -->  Emp Document Name
export const EMP_DOCUMENT_NAME_GET = "EMP_DOCUMENT_NAME_GET";

//HCM --> Configuration --> EmpRevokeReason
export const REVOKE_REASON_GET = "REVOKE_REASON_GET";

//HCM --> Configuration --> EmpQualification
export const EMP_QUALIFICATION_GET = "EMP_QUALIFICATION_GET";

//HCM --> Configuration --> EmpResignationReason
export const RESIGNATION_REASON_GET = "RESIGNATION_REASON_GET";

//HCM --> Configuration --> EmpResignationStatus
export const RESIGNATION_STATUS_GET = "RESIGNATION_STATUS_GET";

//HCM --> Configuration --> Emp Type
export const EMP_TYPE_GET = "EMP_TYPE_GET";

//HCM --> Configuration --> Emp Organization
export const EMP_ORGANIZATION_GET = "EMP_ORGANIZATION_GET";
export const EMP_ORGANIZATION_FETCH = "EMP_ORGANIZATION_FETCH";

// HCM --> Configurations --> Emp Organization Name
export const ENTERPRISE_DETAIL_GET = "ENTERPRISE_DETAIL_GET";
export const ORGANIZATION_SETUP_FETCH = "ORGANIZATION_SETUP_FETCH";

//HCM --> Configuration --> ClearanceStatus
export const CLEARANCE_STATUS_GET = "CLEARANCE_STATUS_GET";

//HCM --> Configuration --> LegalEntities
export const ORGANIZATION_GET = "ORGANIZATION_GET";

//HCM --> Configuration --> CompanyHolidays
export const COMPANY_HOLIDAYS_GET = "COMPANY_HOLIDAYS_GET";

// HCM --> Configurations --> DialingCode
export const DIALING_CODE_GET = "DIALING_CODE_GET";

// HCM --> Configurations --> Currency Management
export const BUSINESS_CURRENCY_GET = "BUSINESS_CURRENCY_GET";

// HCM --> Configurations --> Employee Status Reason
export const EMP_STATUS_REASON_GET = "EMP_STATUS_REASON_GET";

// HCM --> Configurations --> Departments
export const DEPARTMENTS_GET = "DEPARTMENTS_GET";

// HCM --> Configurations --> Personal Status
export const PERSONAL_STATUS_GET = "PERSONAL_STATUS_GET";

// HCM --> Configurations --> HCM Global
export const HCM_GLOBAL_VAR_GET = "HCM_GLOBAL_VAR_GET";

// HCM --> Configurations --> HCM User
export const HCM_USER_VAR_GET = "HCM_USER_VAR_GET";

//HCM --> MyProfile --> CertificationDetails
export const CERTIFICATION_DETAILS_GET = "CERTIFICATION_DETAILS_GET";
export const CERTIFICATION_DETAILS_FETCH = "CERTIFICATION_DETAILS_FETCH";

//HCM --> MyProfile --> Documents
export const DOCUMENTS_GET = "DOCUMENTS_GET";

//HCM --> MyProfile --> EducationalDetails
export const EDUCATION_DETAILS_GET = "EDUCATION_DETAILS_GET";

export const EDUCATION_DETAILS_FETCH = "EDUCATION_DETAILS_FETCH";

//HCM --> MyProfile --> EmpAwards
export const EMP_AWARDS_GET = "EMP_AWARDS_GET";

//HCM --> MyProfile --> EmpDependents
export const EMP_DEPENDENTS_GET = "EMP_DEPENDENTS_GET";

export const EMP_DEPENDENTS_FETCH = "EMP_DEPENDENTS_FETCH";

//HCM --> MyProfile --> EmpBenifits
export const EMP_BENIFITS_GET = "EMP_BENIFITS_GET";

//HCM --> MyProfile --> EmpNominee
export const EMP_NOMINEE_GET = "EMP_NOMINEE_GET";
export const EMP_NOMINEE_FETCH = "EMP_NOMINEE_FETCH";

//HCM --> MyProfile --> EmpProfessionalDocs
export const EMP_PROFESSIONAL_DOCS_GET = "EMP_PROFESSIONAL_DOCS_GET";

//HCM --> MyProfile --> EmpProfessionalInfo
export const EMP_PROFESSIONAL_INFO_GET = "EMP_PROFESSIONAL_INFO_GET";

export const EMP_PROFESSIONAL_INFO_FETCH = "EMP_PROFESSIONAL_INFO_FETCH";

//HCM --> MyProfile --> EmpPersonalInfo
export const EMP_PERSONAL_INFO_GET = "EMP_PERSONAL_INFO_GET";

export const EMP_PERSONAL_INFO_FETCH = "EMP_PERSONAL_INFO_FETCH";

// HCM --> MyProfile --> Contacts Address
export const CONTACTS_ADDRESS_GET = "CONTACTS_ADDRESS_GET";

export const CONTACTS_ADDRESS_FETCH = "CONTACTS_ADDRESS_FETCH";

// HCM --> MyProfile --> Contacts
export const CONTACTS_GET = "CONTACTS_GET";

// HCM --> MyProfile --> Contacts Emergency Details
export const CONTACTS_EMERGENCY_DETAILS_GET = "CONTACTS_EMERGENCY_DETAILS_GET";

// HCM --> MyProfile --> Contacts Family Details
export const CONTACTS_FAMILY_DETAILS_GET = "CONTACTS_FAMILY_DETAILS_GET";

// HCM --> MyProfile --> Accounts Details
export const BANK_ACCOUNTS_INFO_GET = "BANK_ACCOUNTS_INFO_GET";

export const OTHER_ACCOUNTS_INFO_GET = "OTHER_ACCOUNTS_INFO_GET";

// HCM --> MyProfile --> Identification Info
export const PASSPORT_DETAILS_GET = "PASSPORT_DETAILS_GET";

export const OTHER_IDENTIFIERS_GET = "OTHER_IDENTIFIERS_GET";

//HCM --> HR --> HRPolicies
export const HR_POLICIES_GET = "HR_POLICIES_GET";
export const HR_POLICIES_SEARCH = "HR_POLICIES_SEARCH";

//HCM --> HR --> EMP MANAGEMENT
export const EMP_MANAGEMENT_GET = "EMP_MANAGEMENT_GET";
export const EMP_MANAGEMENT_SEARCH = "EMP_MANAGEMENT_SEARCH";
export const ACTIVE_EMPLOYEES_FETCH = "ACTIVE_EMPLOYEES_FETCH";
export const EMP_DETAILS_FETCH = "EMP_DETAILS_FETCH";

// HCM --> HR --> Attendance
export const ATTENDANCE_GET = "ATTENDANCE_GET";
export const ATTENDANCE_FETCH = "ATTENDANCE_FETCH";

//HCM --> LMS --> LeaveManagement
export const LEAVE_MANAGEMENT_GET = "LEAVE_MANAGEMENT_GET";
export const LEAVE_MANAGEMENT_FETCH = "LEAVE_MANAGEMENT_FETCH";
export const LEAVE_MANAGEMENT_SEARCH = "LEAVE_MANAGEMENT_SEARCH";
export const EMPLOYEE_LEAVES_FETCH = "EMPLOYEE_LEAVES_FETCH";

//HCM --> LMS --> My Attendance
export const MY_ATTENDANCE_GET = "MY_ATTENDANCE_GET";
export const MY_ATTENDANCE_FETCH = "MY_ATTENDANCE_FETCH";
export const MY_ATTENDANCE_SEARCH = "MY_ATTENDANCE_SEARCH";

// HCM --> Resignation --> Employee Initiate Resignation
export const EMP_INITIATE_RESIGNATION_GET = "EMP_INITIATE_RESIGNATION_GET";
export const EMP_INITIATE_RESIGNATION_FETCH = "EMP_INITIATE_RESIGNATION_FETCH";

// HCM --> Resignation --> Manager Initiate Resignaion
export const MANAGER_INITIATE_RESIGNATION_GET =
  "MANAGER_INITIATE_RESIGNATION_GET";
export const MANAGER_INITIATE_RESIGNATION_FETCH =
  "MANAGER_INITIATE_RESIGNATION_FETCH";

// HCM --> Resignation --> Exit Clearance
export const EXIT_CLEARANCE_GET = "EXIT_CLEARANCE_GET";
export const EXIT_CLEARANCE_FETCH = "EXIT_CLEARANCE_FETCH";

//HCM --> UploadedDataOnboarding
export const UPLOADED_ONBOARDING_EXTRACT_DATA =
  "UPLOADED_ONBOARDINGEXTRACT_DATA";
export const UPLOADED_ONBOARDINGE_PROCESS_DATA =
  "UPLOADED_ONBOARDINGE_PROCESS_DATA";
export const UPLOADED_ONBOARDING_UNPROCESS_DATA =
  "UPLOADED_ONBOARDING_UNPROCESS_DATA";

// ----------------------------------------------------------------------------------------------
export const GET_CAN_SKILL_RS_SELECT_TYPE = "GET_CAN_SKILL_RS_SELECT_TYPE";

export const GET_CAN_SKILL_RS_ALL_SELECT_TYPE =
  "GET_CAN_SKILL_RS_ALL_SELECT_TYPE";
// RS --> configuration --> skill group
export const SKILL_GROUP_GET = "SKILL_GROUP_GET";
export const GET_BILLING_RS = "GET_BILLING_RS";
// RS --> configuration --> extracurricular
export const EXTRA_CURRICULAR_GET = " EXTRA_CURRICULAR_GET ";

// RS --> configuration --> document
export const DOCUMENTRS_GET = " DOCUMENTRS_GET";

// RS --> configuration --> qualification
export const QUALIFICATIONRS_GET = "DOCUMENT_GET";
//RS ---> Requirement
export const REQUIREMENT_GET = " REQUIREMENT_GET";
export const GET_REQ_LIST = "GETREQLIST";
export const GET_BILLING_LIST = "GET_BILLING_LIST";
export const GET_CAN_TYPE_SKILL = "GET_CAN_TYPE_SKILL";
export const GET_CATEGORY_LIST = "GET_CATEGORY_LIST";
export const GET_POSITION_LIST = "GET_POSITION_LIST";
export const GET_HIRING_STATUS = "GET_HIRING_STATUS";
export const GET_HIRING_STATUS_RS_TYPE = "GET_HIRING_STATUS_RS_TYPE";
export const GET_Years_expRSTYPE = "GET_Years_expRSTYPE";
export const GET_HIRING_STAGE_RSTYPE = " GET_HIRING_STAGE_RSTYPE";
export const GET_CAN_EXP_RSTYPE = " GET_CAN_EXP_RSTYPE";
export const GET_REQTYPE_RSTYPE = " GET_REQTYPE_RSTYPE";
export const GET_JOB_POSITIONRS = " GET_JOB_POSITIONRS";
export const GET_NOTICE_PERIODRS = "GET_NOTICE_PERIODRS";
export const GET_HR_LEAD_TYPE = "GET_HR_LEAD_TYPE";
export const GET_CANDIDATE_TYPECOUNTRY = "GET_CANDIDATE_COUNTRY";
export const GET_CANDIDATE_TYPEGEO = "GET_CANDIDATE_GEO";
export const GET_GOVTID_TYPELIST = "GET_GOVTID_TYPELIST";

export const GET_GOVTID_SELECT_TYPELIST = "GET_GOVTID_SELECT_TYPELIST";

export const GET_GRADE_TYPE_LIST = "GET_GRADE_TYPE_LIST";

export const GET_USER_VARIABLE_LIST_RS = "GET_USER_VARIABLE_RS";
export const GET_GLOBAL_VARIABLE_LIST_RS = "GET_GLOBAL_VARIABLE_RS";
export const GET_JOB_CATEGORY_RSTYPE = " GET_JOB_CATEGORY_RSTYPE";
export const GET_JOB_BOARD_RSTYPE = " GET_JOB_BOARD_RSTYPE";

export const GET_REQTYPE_MAIN = "GET_REQTYPE_MAIN";
export const GET_REQSTATUS_MAIN = "GET_RESTATUS_MAIN";
export const GET_JOBCAT_MAIN = "GET_JOBCAT_MAIN";
export const GET_JOBPOS_MAIN = "GET_JOBPOS_MAIN";
export const GET_QUALIFY_MAIN = "GET_QUALIFY_MAIN";
export const GET_LEADHR_MAIN = "GET_LEADHR_MAIN";
export const GET_CANDI_STATUS_MAIN = "GET_CANDI_STATUS_MAIN";
export const GET_NOTICE_MAIN = "GET_NOTICE_MAIN";
export const GET_BILLING_MAIN = "GET_BILLING_MAIN";

export const GET_JON_TIMING_TYPE = "GET_JON_TIMING_TYPE";
export const GET_JOB_TIMING_SELECT = "GET_JOB_TIMING_SELECT";

//assign hr rs
export const GET_ASSIGNED_HR_LIST = "GET_ASSIGNED_HR_LIST";
//tag requirement
export const GET_TAGGED_REQUIREMENT = "GET_TAGGED_REQUIREMENT";

export const CANDIDATE_KANBAN_GET = "CANDIDATE_KANBAN_GET";
export const CANDIDATE_COMMENTS_VIEW = "CANDIDATE_COMMENTS_VIEW";
export const CANDIDATE_STAGE_TRACKING_VIEW = "CANDIDATE_STAGE_TRACKING_VIEW";
// rs search
export const RS_SEARCH_BY_ID = "RS_SEARCH_BY_ID";
export const RS_SEARCH_BY_STRING = "RS_SEARCH_BY_ID";
// rs candidate tracking
export const GET_TAGGED_CANDI_TRACKING = "GET_TAGGED_CANDI_TRACKING";

// RS requirement publish jobs

export const GET_PUBLISH_JOB_TYPERS = "GET_PUBLISH_JOB_TYPERS";
export const GET_JOB_APPROVAL = "GET_JOB_APPROVAL";
export const SEARCH_PUBLISH_JOBS = "SEARCH_PUBLISH_JOBS";
export const EMPTY_RS_PUBLISH = "EMPTY_RS_PUBLISH";

// rs candidate
export const GET_CANDI_CURRENT_RECORDS = "GET_CANDI_CURRENT_RECORDS";
export const GET_CANDIDATE_ACTIVE_STATUS = "GET_CANDIDATE_ACTIVE_STATUS";
export const GET_PARTICULAR_CANDI = "GET_PARTICULAR_CANDI ";

// upload_rs
export const GET_UPLOAD_CAN_RS_EXTRACTED = "GET_UPLOAD_CAN_RS_EXTRACTED";
export const GET_UPLOAD_CAN_RS_PROCESSED = "GET_UPLOAD_CAN_RS_PROCESSED";
export const GET_UPLOAD_CAN_RS_UNPROCESSED = "GET_UPLOAD_CAN_RS_UNPROCESSED";
//can-tagging-rs
export const GET_UPLOAD_CAN_TAG_RS_EXTRACTED =
  "GET_UPLOAD_CAN_TAG_RS_EXTRACTED";
export const GET_UPLOAD_CAN_TAG_RS_PROCESSED =
  "GET_UPLOAD_CAN_TAG_RS_PROCESSED";
export const GET_UPLOAD_CAN_TAG_RS_UNPROCESSED =
  "GET_UPLOAD_CAN_TAG_RS_UNPROCESSED";

//req-upload-rs
export const GET_UPLOAD_REQ_RS_EXTRACTED = "GET_UPLOAD_REQ_RS_EXTRACTED ";
export const GET_UPLOAD_REQ_RS_PROCESSED = "GET_UPLOAD_REQ_RS_PROCESSED";
export const GET_UPLOAD_REQ_RS_UNPROCESSED = "GET_UPLOAD_REQ_RS_UNPROCESSED";

//upload template-rs
export const GET_UPLOADED_TEMPLATE_RS = "GET_UPLOADED_TEMPLATE_RS";
export const GET_UPLOADED_RESUME_RS = "GET_UPLOADED_RESUME_RS";
export const GET_CURRENT_FILE_VALUE = "GET_CURRENT_FILE_VALUE";

//upload skill-rs
export const GET_PROCESSED_RS_SKILLS = "GET_PROCESSED_RS_SKILLS";
export const GET_EXTRACTED_RS_SKILLS = "GET_EXTRACTED_RS_SKILLS";
export const GET_UNPROCESSED_RS_SKILLS = "GET_UNPROCESSED_RS_SKILLS";

// tagged details
export const TAGGED_DETAILS_GET = "TAGGED_DETAILS_GET";


//RS ---> MY REPORTS
export const MY_REPORTS_VIEW_SEARCH = "MY_REPORTS_VIEW_SEARCH";
export const MY_REPORTS_VIEW_GET = "MY_REPORTS_VIEW_GET";
export const MY_REPORTS_TAC_NAMES_GET = "MY_REPORTS_TAC_NAMES_GET";
export const MY_REPORTS_RESET_VIEW = "MY_REPORTS_RESET_VIEW";

// RS --> Manager View
export const MANAGER_VIEW_SEARCH = "MANAGER_VIEW_SEARCH";
export const MANAGER_VIEW_CANDIDATE_SEARCH = "MANAGER_VIEW_CANDIDATE_SEARCH";
export const MANAGER_VIEW_CUSTOMERS_GET = "MANAGER_VIEW_CUSTOMERS_GET";
export const MANAGER_VIEW_TAC_GET = "MANAGER_VIEW_TAC_GET";
export const MANAGER_VIEW_SKILL_SETS_GET = "MANAGER_VIEW_SKILL_SETS_GET";
export const MANAGER_VIEW_JOB_POSITION_GET = "MANAGER_VIEW_JOB_POSITION_GET";
export const MANAGER_VIEW_LEAD_RECRUITER_GET =
  "MANAGER_VIEW_LEAD_RECRUITER_GET";
export const MANAGER_VIEW_SOURCED_BY_GET = "MANAGER_VIEW_SOURCED_BY_GET";
export const MANAGER_VIEW_UPDATED_BY_GET = "MANAGER_VIEW_UPDATED_BY_GET";
export const MANAGER_VIEW_SOURCED_BY_DATE_GET =
  "MANAGER_VIEW_SOURCED_BY_DATE_GET";
export const MANAGER_VIEW_JOINED_OR_DECLINED_DATE_GET =
  "MANAGER_VIEW_JOINED_OR_DECLINED_DATE_GET";
export const MANAGER_VIEW_CANDIDATE_STATUS_GET =
  "MANAGER_VIEW_CANDIDATE_STATUS_GET";
export const MANAGER_VIEW_HIRING_STAGE_GET = "MANAGER_VIEW_HIRING_STAGE_GET";
export const REQUIREMENT_DETAILS_BY_ID_FETCH =
  "REQUIREMENT_DETAILS_BY_ID_FETCH";
export const CANDIDATE_DETAILS_BY_ID_FETCH = "CANDIDATE_DETAILS_BY_ID_FETCH";


export const MANAGER_HIRING_STATUS =  "MANAGER_HIRING_STATUS"

export const   FETCH_MANAGER_MULTI_SINGLE_CUST =  "FETCH_MANAGER_MULTI_SINGLE_CUST"

// CRM application types values

// CRM --> CRM Setup values
export const CUSTOMER_DOMAIN_GET = "CUSTOMER_DOMAIN_GET";
export const CUSTOMER_TYPE_GET = "CUSTOMER_TYPE_GET";
export const CUSTOMER_ACCOUNT_STATUS_GET = "CUSTOMER_ACCOUNT_STATUS_GET";
export const CUSTOMER_GEOGRAPHIES_GET = "CUSTOMER_GEOGRAPHIES_GET";
export const CUSTOMER_COUNTRIES_GET = "CUSTOMER_COUNTRIES_GET";
export const CUSTOMER_CURRENCY_GET = "CUSTOMER_CURRENCY_GET";
export const CUSTOMER_DIALING_CODE_GET = "CUSTOMER_DIALING_CODE_GET";
export const CUSTOMER_DOCUMENT_TYPE_GET = "CUSTOMER_DOCUMENT_TYPE_GET";
export const CONTACT_DESIGNATION_GET = "CONTACT_DESIGNATION_GET";
export const CONTACT_WORKING_STATUS_GET = "CONTACT_WORKING_STATUS_GET";
export const CRM_GLOBAL_GET = "CRM_GLOBAL_GET";
export const CRM_USER_GET = "CRM_USER_GET";
export const ACCOUNT_MANAGER_GET = "ACCOUNT_MANAGER_GET";
export const SALES_TEAM_GET = "SALES_TEAM_GET";

// CRM --> Customers
export const CUSTOMERS_GET = "CUSTOMERS_GET";
export const CUSTOMERS_NAME_GET = "CUSTOMERS_NAME_GET";
export const CUSTOMERS_FETCH = "CUSTOMERS_FETCH";
export const CUSTOMERS_SEARCH = "CUSTOMERS_SEARCH";
export const CUSTOMERS_SPOC_NAME_GET = "CUSTOMERS_SPOC_NAME_GET";
export const UPDATE_CUSTOMERS_SPOC_NAME_GET = "UPDATE_CUSTOMERS_SPOC_NAME_GET";
export const CUSTOMER_TAGGED_CONTACTS_GET = "CUSTOMER_TAGGED_CONTACTS_GET";

// CRM --> Customer Entity
export const CUSTOMER_ENTITY_GET = "CUSTOMER_ENTITY_GET";
export const CUSTOMER_ENTITY_NAME_GET = "CUSTOMER_ENTITY_NAME_GET";
export const CUSTOMER_ENTITY_FETCH = "CUSTOMER_ENTITY_FETCH";
export const CUSTOMER_ENTITY_SEARCH = "CUSTOMER_ENTITY_SEARCH";
export const CUSTOMER_ENTITY_SPOC_NAME_GET = "CUSTOMER_ENTITY_SPOC_NAME_GET";
export const UPDATE_CUSTOMER_ENTITY_SPOC_NAME_GET =
  "UPDATE_CUSTOMER_ENTITY_SPOC_NAME_GET";
export const CUSTOMER_ENTITY_TAGGED_CONTACTS_GET =
  "CUSTOMER_ENTITY_TAGGED_CONTACTS_GET";

// CRM --> CustomerDocuments
export const CUSTOMER_DOCUMENTS_GET = "CUSTOMER_DOCUMENTS_GET";

// CRM --> Accounts
export const ACCOUNTS_GET = "ACCOUNTS_GET";
export const ACCOUNTS_FETCH = "ACCOUNTS_FETCH";
export const ACCOUNTS_SEARCH = "ACCOUNTS_SEARCH";
export const ACCOUNTS_SPOC_NAME_GET = "ACCOUNTS_SPOC_NAME_GET";
export const UPDATE_ACCOUNTS_SPOC_NAME_GET = "UPDATE_ACCOUNTS_SPOC_NAME_GET";
export const ACCOUNT_TAGGED_CONTACTS_GET = "ACCOUNT_TAGGED_CONTACTS_GET";

// CRM --> Contacts
export const CRM_CONTACTS_GET = "CRM_CONTACTS_GET";
export const CRM_CONTACTS_NAME_GET = "CRM_CONTACTS_NAME_GET";
export const CRM_CONTACT_NAME_RS_GET = "CRM_CONTACT_NAME_RS_GET";
export const CRM_CONTACTS_FETCH = "CRM_CONTACTS_FETCH";
export const CRM_CONTACTS_SEARCH = "CRM_CONTACTS_SEARCH";

// CRM --> Contacts
export const CRM_TAG_CONTACTS_GET = "CRM_TAG_CONTACTS_GET";
export const CRM_TAG_CONTACTS_FETCH = "CRM_TAG_CONTACTS_FETCH";
export const CRM_TAG_CONTACTS_SEARCH = "CRM_TAG_CONTACTS_SEARCH";

// Experience 360 --> Requests
export const REQUESTS_GET = "REQUESTS_GET";
export const CLOSED_REQUESTS_GET = "CLOSED_REQUESTS_GET";
export const REQUESTS_FETCH = "REQUESTS_FETCH";
export const REQUESTS_SEARCH = "REQUESTS_SEARCH";
export const VIEW_REQUESTS_SEARCH = "VIEW_REQUESTS_SEARCH";

// Experience 360 --> Release Tag
export const E360_RELEASE_TAG_GET = "E360_RELEASE_TAG_GET";

export const E360_RELEASE_TAG_FETCH = "E360_RELEASE_TAG_FETCH";

// Experience 360 --> Request Assignment
export const TEAM_ASSIGNMENT_GET = "TEAM_ASSIGNMENT_GET";

export const TEAM_ASSIGNMENT_FETCH = "TEAM_ASSIGNMENT_FETCH";

// Experience 360 --> Request Conversation
export const REQUEST_CONVERSATION_GET = "REQUEST_CONVERSATION_GET";

export const REQUEST_CONVERSATION_FETCH = "REQUEST_CONVERSATION_FETCH";

// Experience 360 --> e360 Config
export const REQUEST_TYPE_GET = "REQUEST_TYPE_GET";

export const REQUEST_STATUS_GET = "REQUEST_STATUS_GET";

export const REQUEST_SEVERITY_GET = "REQUEST_SEVERITY_GET";

export const REQUEST_PRIORITY_GET = "REQUEST_PRIORITY_GET";

export const E360_GLOBAL_GET = "E360_GLOBAL_GET";

export const E360_USER_GET = "E360_USER_GET";

export const PROJECT_TEAM_GET = "PROJECT_TEAM_GET";

export const PROJECT_MEMBER_GET = "PROJECT_MEMBER_GET";

export const RELEASE_TAG_GET = "RELEASE_TAG_GET";

export const KNOWLEDGE_TYPE_GET = "KNOWLEDGE_TYPE_GET";

export const REQUEST_RESOLUTION_GET = "REQUEST_RESOLUTION_GET";

export const PRODUCT_LIST_GET = "PRODUCT_LIST_GET";

//dashboard RS

export const RS_DASHBOARD_GET = "RS_DASHBOARD_GET"

export const RS_DASHBOARD_GET_FULL_DETAILS = "RS_DASHBOARD_GET_FULL_DETAILS"

export const RS_DASHBOARD_APPLICANTS_DETAILS = "RS_DASHBOARD_APPLICANTS_DETAILS"

export const RS_DASHBOARD_POSITION_DEPARTMENT = "RS_DASHBOARD_POSITION_DEPARTMENT"


export const RS_DASHBOARD_POSITION_APPLICANTS = "RS_DASHBOARD_POSITION_APPLICANTS"
export const RS_DASHBOARD_FUNNEL = "RS_DASHBOARD_FUNNEL"

export const RS_DASHBOARD_FUNNEL_DATA = "RS_DASHBOARD_FUNNEL_DATA"
export const RS_DASHBOARD_APPLICANTS_TABLE_DETAILS= "RS_DASHBOARD_APPLICANTS_TABLE_DETAILS" 

export const RS_DASHBOARD_RATIO= " RS_DASHBOARD_RATIO" 

export const RS_DASHBOARD_PROFILE_SCREENING= "RS_DASHBOARD_PROFILE_SCREENING" 

export const RS_DASHBOARD_SOURCE= "RS_DASHBOARD_SOURCE" 


// dashnoard Manager Insights
export const MANAGER_RS_DASHBOARD_GET = "MANAGER_RS_DASHBOARD_GET"

export const MANAGER_RS_DASHBOARD_GET_FULL_DETAILS = "MANAGER_RS_DASHBOARD_GET_FULL_DETAILS"

export const MANAGER_RS_DASHBOARD_APPLICANTS_DETAILS = "MANAGER_RS_DASHBOARD_APPLICANTS_DETAILS"

export const MANAGER_RS_DASHBOARD_POSITION_DEPARTMENT = "MANAGER_RS_DASHBOARD_POSITION_DEPARTMENT"

export const RS_MANAGER_DASHBOARD_RATIO= " RS_MANAGER_DASHBOARD_RATIO" 

export const RS_MANAGER_DASHBOARD_PROFILE_SCREENING= " RS_MANAGER_DASHBOARD_PROFILE_SCREENING" 
export const RS_MANAGER_DASHBOARD_SOURCE= "RS_MANAGER_DASHBOARD_SOURCE" 
export const MANAGER_RS_DASHBOARD_POSITION_APPLICANTS = " MANAGER_RS_DASHBOARD_POSITION_APPLICANTS"
export const MANAGER_RS_DASHBOARD_FUNNEL = "MANAGER_RS_DASHBOARD_FUNNEL"
export const MANAGER_RS_DASHBOARD_FUNNEL_DATA = "MANAGER_RS_DASHBOARD_FUNNEL_DATA"
export const MANAGER_RS_DASHBOARD_APPLICANTS_TABLE_DETAILS= "MANAGER_RS_DASHBOARD_APPLICANTS_TABLE_DETAILS" 