import React, { Component } from "react";
import { Row, Col } from "reactstrap";
// components
import RequestFeed from "./RequestFeed";
import SubmitRequest from "./SubmitRequest";

// -----------------------------------------------------------------------------

export default class RequestConversation extends Component {
  render() {
    return (
      <Row>
        <Col md="6">
          <RequestFeed requestId={this.props.requestId} />
        </Col>
        <Col md="6" className="p-0">
          <SubmitRequest requestId={this.props.requestId} />
        </Col>
      </Row>
    );
  }
}
