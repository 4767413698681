import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import FormComponent from "../../../common/FormComponent";
import { updateCampaign } from "../../../../redux/actions/campaignAction";
import { getEmployeeDetails } from "../../../../redux/actions/employeeAction";
import { getCampaignType } from "../../../../redux/actions/campaignTypeAction";
import { getSuccessCriteria } from "../../../../redux/actions/campaignSuccessCriteriaAction";
import { getCurrency } from "../../../../redux/actions/currencyAction";
import {
  Validator,
  submitValidator,
  refresh,
  valid,
  errors,
} from "../../../common/Validator";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";

class UpdateCampaign extends Component {
  constructor(props) {
    super(props);
    const {
      campName,
      campaignType,
      successCriteria,
      plannedBudget,
      currencyField,
      actualSpend,
      activityType,
      date,
      time,
      description,
      actionOwner,
      status,
    } = this.props.upCampaign;
    this.state = {
      campaignName: campName,
      campaignType: campaignType,
      successCriteria: successCriteria,
      plannedBudget: plannedBudget,
      currency: currencyField,
      actualSpend: actualSpend,
      activity: activityType,
      date: date,
      time: time,
      description: description,
      actionOwner: actionOwner,
      status: status,
      message: null,
      error: null,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate() {
    if (this.props.campaign.message) {
      setTimeout(() => {
        this.props.back();
      }, 2000);
    }
  }

  onChange(e) {
    e.preventDefault();
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    const data = {
      campId: this.props.upCampaign.campId,
      campaignType: this.state.campaignType,
      successCriteria: this.state.successCriteria,
      plannedBudget: this.state.plannedBudget,
      currencyField: this.state.currency,
      actualSpend: this.state.actualSpend,
      campName: this.state.campaignName,
      status: this.state.status,
      activityType: this.state.activity,
      date: this.state.date,
      actionOwner: this.state.actionOwner,
      time: this.state.time,
      description: this.state.description,
      updatedBy: localStorage.getItem("id"),
    };
    if (valid) {
      this.props.updateCampaign(data, this.props.history);
      setTimeout(() => {
        this.props.back();
      }, 2000);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    this.props.getEmployeeDetails();
    this.props.getCampaignType();
    this.props.getSuccessCriteria();
    this.props.getCurrency();
    refresh();
  }

  render() {
    return (
      <React.Fragment>
        <h5 className="bold center pt-3">Update Campaign</h5>
        {/* <i
          className="material-icons pointer"
          style={{
            position: "absolute",
            top: "5px",
            right: "5px",
            color: "#C00",
          }}
          onClick={this.props.back}
        >
          cancel
        </i> */}
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="6">
              <FormComponent
                labelClass="bold label-sm"
                label="Campaign Name"
                name="campaignName"
                type="text"
                value={this.state.campaignName}
                change={this.onChange}
                required={true}
              />
              <FormGroup>
                <Label className="bold label-sm">Campaing Type</Label>
                <Input
                  type="select"
                  className="form-control-sm"
                  name="campaignType"
                  onChange={this.onChange}
                  value={this.state.campaignType}
                  required={true}
                >
                  <option hidden value="">
                    Select...
                  </option>
                  {this.props.campaignType.data.map((item) => {
                    return (
                      <option
                        key={item.campaignTypeId}
                        value={item.campaignType}
                      >
                        {item.campaignType}
                      </option>
                    );
                  })}
                </Input>
                {errors.campaignType && (
                  <FormText className="error">{errors.campaignType}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="bold label-sm">Success Criteria</Label>
                <Input
                  type="select"
                  className="form-control-sm"
                  name="successCriteria"
                  onChange={this.onChange}
                  value={this.state.successCriteria}
                  required={true}
                >
                  <option hidden value="">
                    Select...
                  </option>
                  {this.props.campaignSuccessCriteria.data.map((item) => {
                    return (
                      <option
                        key={item.successCriteriaId}
                        value={item.successCriteria}
                      >
                        {item.successCriteria}
                      </option>
                    );
                  })}
                </Input>
                {errors.successCriteria && (
                  <FormText className="error">
                    {errors.successCriteria}
                  </FormText>
                )}
              </FormGroup>
              <Row>
                <Col md="6" style={{ paddingLeft: "0px" }}>
                  <FormComponent
                    labelClass="bold label-sm"
                    label="Planned Budget"
                    type="number"
                    name="plannedBudget"
                    change={this.onChange}
                    value={this.state.plannedBudget}
                    required={true}
                  />
                </Col>
                <Col md="6" style={{ paddingRight: "0px" }}>
                  <Label className="bold label-sm">Currency</Label>
                  <Input
                    type="select"
                    name="currency"
                    className="form-control-sm"
                    value={this.state.currency}
                    onChange={this.onChange}
                    required
                  >
                    {this.props.currency.data.map((item) => {
                      return (
                        <option key={item.currencyId} value={item.currencyCode}>
                          {item.currencyCode}
                        </option>
                      );
                    })}
                  </Input>
                </Col>
              </Row>
              <FormComponent
                labelClass="bold label-sm"
                label="Actual Spend"
                name="actualSpend"
                type="number"
                value={this.state.actualSpend}
                change={this.onChange}
                required={true}
              />
            </Col>
            <Col md="6">
              <FormComponent
                labelClass="bold label-sm"
                label="Activity Type"
                name="activity"
                type="text"
                value={this.state.activity}
                change={this.onChange}
                required={true}
              />
              <Row>
                <Col md="6" style={{ paddingLeft: "0px" }}>
                  <FormComponent
                    labelClass="bold label-sm"
                    label="Date"
                    name="date"
                    type="date"
                    change={this.onChange}
                    value={this.state.date}
                    required={true}
                  />
                </Col>
                <Col md="6" style={{ paddingRight: "0px" }}>
                  <FormComponent
                    labelClass="bold label-sm"
                    label="Time"
                    type="time"
                    name="time"
                    change={this.onChange}
                    value={this.state.time}
                    required={true}
                  />
                </Col>
              </Row>
              <FormGroup>
                <Label className="bold label-sm">Status</Label>
                <Input
                  name="status"
                  type="select"
                  value={this.state.status}
                  onChange={this.onChange}
                  className="form-control-sm"
                  required={true}
                >
                  <option value="New">New</option>
                  <option value="WIP">Work in progress</option>
                  <option value="Closed">Closed</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label className="bold label-sm">Action Owner</Label>
                <Input
                  type="select"
                  className="form-control-sm"
                  name="actionOwner"
                  onChange={this.onChange}
                  value={this.state.actionOwner}
                  required
                >
                  {this.props.employee.data.map((item) => {
                    if (item.empActiveStatus === "Active") {
                      return (
                        <option key={item.empId} value={item.empId}>
                          {item.empId}-{item.firstName}
                        </option>
                      );
                    }
                  })}
                </Input>
              </FormGroup>
              <FormComponent
                labelClass="bold label-sm"
                label="Description"
                name="description"
                type="textarea"
                value={this.state.description}
                change={this.onChange}
                required={true}
              />
            </Col>
          </Row>
          <div className="center">
            <button className="btn login-button white-text">Update</button>
          </div>
        </Form>
        {this.props.campaign.message ? (
          <Notifier message={this.props.campaign.message} />
        ) : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
      </React.Fragment>
    );
  }
}

UpdateCampaign.propTypes = {
  campaign: PropTypes.object.isRequired,
  employee: PropTypes.object.isRequired,
  getEmployeeDetails: PropTypes.func.isRequired,
  updateCampaign: PropTypes.func.isRequired,
  getCampaignType: PropTypes.func.isRequired,
  campaignType: PropTypes.object.isRequired,
  getSuccessCriteria: PropTypes.func.isRequired,
  campaignSuccessCriteria: PropTypes.object.isRequired,
  getCurrency: PropTypes.func.isRequired,
  currency: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  campaign: state.campaign,
  employee: state.employee,
  campaignType: state.campaignType,
  campaignSuccessCriteria: state.campaignSuccessCriteria,
  currency: state.currency,
});

export default connect(mapStateToProps, {
  updateCampaign,
  getEmployeeDetails,
  getCampaignType,
  getSuccessCriteria,
  getCurrency,
})(UpdateCampaign);
