  import React, { Component } from 'react'
  import {
    Row,
    Col,
    Form,
    Container,
    FormGroup,
    Input,
    Label,
    FormText,
  } from "reactstrap";
  import {
    Validator,
    submitValidator,
    valid,
    refresh,
    errors,
  } from "../../../common/Validator";
  

  import FormComponent from "../../../common/FormComponent";
  import {getJobBoard} from '../../../../redux/actions/rs/configuration/jobBoardAction'
  import {AddPublishJobs} from '../../../../redux/actions/rs/requirements/publishJobsAction'
  import { connect } from 'react-redux';

 class UpdatedPublishJob extends Component {
    constructor(props){
    super(props)
    this.state = {
    jobBoard:"",
    jobStatus:""
    }
    this.onSubmit = this.onSubmit.bind(this)  
    this.onChange = this.onChange.bind(this)  
    }


  onChange(e){
  Validator(e.target)
  this.setState({
    [e.target.name]:e.target.value
  })

  }
    onSubmit(e){
      e.preventDefault();
      submitValidator(e.target);
     
      // if(!this.state.filteredValueArray.length){
      //   this.setState({
      //     skillErrorShow:"This field is mandatory"
      //   })
      //   errorSkill = true
      //    }
      //    else{
      //     this.setState({
      //       skillErrorShow:""
      //     })
      //     errorSkill = false 
      //    }
      let body = {
      
        jobRequirementId : Number(this.props.reqType),
        jobType: this.props.getData[0].jobReqType,
        jobCategory: this.props.getData[0].jobCategory,
        jobPosition: this.props.getData[0].jobPosition,
        jobDescription: this.props.getData[0].jobDescription,
        jobLocation: this.props.getData[0].jobLocation,
        JobTimings: 2,
        qualifications: this.props.getData[0].qualifications,
        skillSets: this.props.getData[0].skillSets,
        primarySkills: this.props.getData[0].primarySkills,
        secondarySkills: this.props.getData[0].secondarySkills,
        totalPositions: this.props.getData[0].totalPositions,
        startDate: this.props.getData[0].startDate,
        endDate: this.props.getData[0].endDate,
        jobBoardName:this.state.jobBoard,
        jobStatus:this.state.jobStatus,
        reqStatus: this.props.getData[0].reqStatus,
        minYearsOfExp: Number(this.props.getData[0].minYearsOfExp),
        maxYearsOfExp: Number(this.props.getData[0].maxYearsOfExp),
      };
     
        this.props.AddPublishJobs(body);
        // setTimeout(() => {
        //   this.props.getCustomers();
        // }, 2000);
      // } else {
      //   this.setState({
      //     error: " Please enter all mandatory fields",
      //   });
      //   setTimeout(() => {
      //     this.setState({
      //       error: "",
      //     });
      //   }, 2000);
      // }
    
   
    }




    componentDidMount(){
      this.props.getJobBoard()
    }
    render() {
        return (
            <div>
            <i
              onClick={this.props.back}
              className="material-icons pointer cancel-button"
            >
              cancel
            </i>
           <h5 className="bold center">Update Publish Jobs</h5>
          
            <Form  className="p-3 mt-2" noValidate>
            <Row>
             <div style={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"center"}}>
             <Col md="4">
            <FormGroup>
                <Label className="bold label-sm">Job Board Name</Label>
                <Input
                  tabIndex="3"
                  type="select"
                  name="jobBoard"
                  className="form-control-sm"
                  value={this.state.jobBoard}
                  onChange={this.onChange}
                  placeholder="Requirement Type"
                  required={true}
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {this.props.JobBoard.data.map((item) => {
                    return (
                      <option key={item.id} value={item.jobboardName}>
                        {item.jobboardName}
                      </option>
                    );
                  })}
                </Input>
                {errors.reqType && (
                  <FormText className="error">{errors.reqType}</FormText>
                )}
              </FormGroup>

            
               <FormComponent
                index="18"
                labelClass="bold label-sm"
                label="Job Status"
                type="text"
                name="jobStatus"
                change={this.onChange}
                value={this.state.jobStatus}
                required={true}
                placeholder="Job Status"
                maxlength="21"
              />
              
           
            
            </Col>    
          
            </div>   

        
           
          
            </Row>
            </Form>
                
            </div>
        )
    }
}

const mapStateToProps = (state) =>({
  JobBoard: state.JobBoard
})
 


export default connect(mapStateToProps,{
  getJobBoard,
  AddPublishJobs
})(UpdatedPublishJob)