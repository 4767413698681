/*  eslint-disable  */
import axios from "axios";
import { GET_PARTNER_LIST, PARTNER_SEARCH, GET_ERRORS } from "./types";
import {
  ADD_PARTNER,
  GET_PARTNER,
  UPDATE_PARTNER,
  SEARCH_PARTNERS,
} from "../../utils/routes";
import { setMessage } from "./salesActions";

export const addPartner = (data, history) => (dispatch) => {
  axios
    .post(ADD_PARTNER, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
      }, 2000);
    })
    .catch((err) => {
      console.log(err.message);
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};

export const getPartners = () => (dispatch) => {
  axios
    .get(GET_PARTNER, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: GET_PARTNER_LIST,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};

export const updatePartner = (data, history) => (dispatch) => {
  axios
    .post(UPDATE_PARTNER, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
      }, 2000);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};

export const searchPartner = (data) => (dispatch) => {
  axios
    .post(SEARCH_PARTNERS, data, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: PARTNER_SEARCH,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};

// export const addLeadCompany = (data, history) => (dispatch) => {
//   axios
//     .post(ADD_CUSTOMER, data, { withCredentials: true })
//     .then((res) => {
//       dispatch(setMessage(res.data));
//       setTimeout(() => {
//         dispatch(setMessage(null));
//       }, 2000);
//     })
//     .catch((err) => {
//       dispatch({
//         type: GET_ERRORS,
//         payload: err.message,
//       });
//     });
// };
