import axios from "axios";
import { LEAVE_TYPE_GET } from "../../types";
import { CURD_LEAVE_TYPE, USER_LEAVE_TYPE } from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addLeaveType = (data, back) => (dispatch) => {
  axios
    .post(CURD_LEAVE_TYPE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getLeaveType));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getLeaveType = () => (dispatch) => {
  axios
    .get(CURD_LEAVE_TYPE, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(LEAVE_TYPE_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserLeaveType = () => (dispatch) => {
  axios
    .get(USER_LEAVE_TYPE, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(LEAVE_TYPE_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateLeaveType = (data, back) => (dispatch) => {
  axios
    .put(CURD_LEAVE_TYPE, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getLeaveType));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteLeaveType = (data) => (dispatch) => {
  axios
    .delete(CURD_LEAVE_TYPE, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getLeaveType));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
