import {
  MANAGER_VIEW_SEARCH,
  MANAGER_VIEW_CANDIDATE_SEARCH,
  MANAGER_VIEW_CUSTOMERS_GET,
  MANAGER_VIEW_TAC_GET,
  MANAGER_VIEW_SKILL_SETS_GET,
  MANAGER_VIEW_JOB_POSITION_GET,
  MANAGER_VIEW_LEAD_RECRUITER_GET,
  MANAGER_VIEW_SOURCED_BY_GET,
  MANAGER_VIEW_UPDATED_BY_GET,
  MANAGER_VIEW_SOURCED_BY_DATE_GET,
  MANAGER_VIEW_JOINED_OR_DECLINED_DATE_GET,
  MANAGER_VIEW_CANDIDATE_STATUS_GET,
  MANAGER_VIEW_HIRING_STAGE_GET,
  REQUIREMENT_DETAILS_BY_ID_FETCH,
  CANDIDATE_DETAILS_BY_ID_FETCH,
  MANAGER_HIRING_STATUS,
  FETCH_MANAGER_MULTI_SINGLE_CUST,
  GET_ERRORS,
  SET_MESSAGE,
} from "../../../actions/types";

const initialState = {
  data: [],
  candidateData: [],
  customersData: [],
  tacData: [],
  skillSetsData: [],
  positionData: [],
  leadRecruiterData: [],
  sourcedByData: [],
  updatedByData: [],
  sourcedDateData: [],
  joinedOrDeclinedDateData: [],
  candidateStatusData: [],
  hiringStageData: [],
  hiringManagerStatusData:[],
  singleMultiFilterData:[],
  reqData: [],
  canData: [],
  message: null,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case MANAGER_VIEW_SEARCH:
      return {
        ...state,
        data: action.payload,
      };
    case MANAGER_VIEW_CANDIDATE_SEARCH:
      return {
        ...state,
        candidateData: action.payload,
      };
    case MANAGER_VIEW_CUSTOMERS_GET:
      return {
        ...state,
        customersData: action.payload,
      };
    case MANAGER_VIEW_TAC_GET:
      return {
        ...state,
        tacData: action.payload,
      };
    case MANAGER_VIEW_SKILL_SETS_GET:
      return {
        ...state,
        skillSetsData: action.payload,
      };
    case MANAGER_VIEW_JOB_POSITION_GET:
      return {
        ...state,
        positionData: action.payload,
      };

    case MANAGER_VIEW_LEAD_RECRUITER_GET:
      return {
        ...state,
        leadRecruiterData: action.payload,
      };
    case MANAGER_VIEW_SOURCED_BY_GET:
      return {
        ...state,
        sourcedByData: action.payload,
      };
    case MANAGER_VIEW_UPDATED_BY_GET:
      return {
        ...state,
        updatedByData: action.payload,
      };
    case MANAGER_VIEW_SOURCED_BY_DATE_GET:
      return {
        ...state,
        sourcedDateData: action.payload,
      };
    case MANAGER_VIEW_JOINED_OR_DECLINED_DATE_GET:
      return {
        ...state,
        joinedOrDeclinedDateData: action.payload,
      };
    case MANAGER_VIEW_CANDIDATE_STATUS_GET:
      return {
        ...state,
        candidateStatusData: action.payload,
      };
    case MANAGER_VIEW_HIRING_STAGE_GET:
      return {
        ...state,
        hiringStageData: action.payload,
      };
    case REQUIREMENT_DETAILS_BY_ID_FETCH:
      return {
        ...state,
        reqData: action.payload,
      };
    case CANDIDATE_DETAILS_BY_ID_FETCH:
      return {
        ...state,
        canData: action.payload,
      };
      case  MANAGER_HIRING_STATUS:
        return {
          ...state,
          hiringManagerStatusData : action.payload,
        };
        case  FETCH_MANAGER_MULTI_SINGLE_CUST:
          return {
            ...state,
            singleMultiFilterData : action.payload,
          };
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case GET_ERRORS:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
