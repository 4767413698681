import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// redux
import { fetchEmployeeDetails } from "../../../../redux/actions/hcm/hr/emp_management/empManagementAction";

// -----------------------------------------------------------------------------------------------------

const Details = (props) => {
  useEffect(() => {
    props.fetchEmployeeDetails(props.id);
  }, []);

  return <div>Details</div>;
};

Details.proptypes = {
  fetchEmployeeDetails: PropTypes.func.isRequired,
  employees: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  employees: state.employees,
});

export default connect(mapStateToProps, { fetchEmployeeDetails })(Details);
