import React, { Component } from "react";
import { Form, Row, Col,FormGroup,Label,Input,FormText } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import Notifier from "../../../../aside/Notifier"
import { addGlobalVaribleRs  } from "../../../../../redux/actions/rs/configuration/globalVariable";

// add billing component

class AddGlobalVariable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: "",
      value: "",
      description:"",
      isDepended:true,
      deletable:true,
      crucialArr: [
        { key: "Yes", value: "Yes" },
        { key: "No", value: "No" },
      ],
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  // common onchange function
  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  // on submit function
  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      key: this.state.key,
      value: this.state.value,
      description:this.state.description,
      isDepended:this.state.isDepended === "Yes" ? true:false,
      deletable:this.state.deletable === "Yes" ? true:false,
    };
    if (valid) {
      this.props.addGlobalVaribleRs(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidMount contains the error refresh method and setState method for calling the render method
*/
  componentDidMount() {
    refresh();
    this.setState({
      error: "",
    });
  }

  render() {
    const { key,value,description,isDepended,deletable, error } = this.state;

    return (
      <React.Fragment>
        <h5 className="center card-heading pt-2">Add Global Variable</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col  md="6" className="mx-auto">
          <FormComponent
                labelClass="bold label-sm"
                label="key"
                type="text"
                name="key"
                inputClass="form-control-sm"
                value={key}
                change={this.onChange}
                required={true}
                length="30"
              />
             
             <FormComponent
                labelClass="bold label-sm"
                label="Value"
                type="text"
                name="value"
                inputClass="form-control-sm"
                value={value}
                change={this.onChange}
                required={true}
                length="30"
              />
         
              <FormComponent
                labelClass="bold label-sm"
                label="Description"
                type="text"
                name="description"
                inputClass="form-control-sm"
                value={description}
                change={this.onChange}
                required={true}
                length="30"
              />
                <FormGroup>
                <Label className="bold label-sm">Is Depended</Label>
                <Input
                  tabIndex="10"
                  type="select"
                  name="isDepended"
                  className="form-control-sm"
                  value={isDepended}
                  onChange={this.onChange}
               
                  // required={true}
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {this.state.crucialArr.map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {item.value}
                      </option>
                    );
                  })}
                </Input>
                {errors.isDepended && (
                  <FormText className="error">{errors.isDepended}</FormText>
                )}
              </FormGroup>

              <FormGroup>
                <Label className="bold label-sm">Deletable</Label>
                <Input
                  tabIndex="10"
                  type="select"
                  name="deletable"
                  className="form-control-sm"
                  value={deletable}
                  onChange={this.onChange}
               
                  // required={true}
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {this.state.crucialArr.map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {item.value}
                      </option>
                    );
                  })}
                </Input>
                {errors.deletable && (
                  <FormText className="error">{errors.deletable}</FormText>
                )}
              </FormGroup>
            </Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Add
            </button>
          </div>
        </Form>
        {error && <ErrorNotifier message={error} />}
        {this.props.GlobalVariableRs.error && (
          <ErrorNotifier message={this.props.GlobalVariableRs.error} />
        )}
        {this.props.GlobalVariableRs.message && (
          <Notifier message={this.props.GlobalVariableRs.message} />
        )}
      </React.Fragment>
    );
  }
}

AddGlobalVariable.propTypes = {
  addBillingRs: PropTypes.func.isRequired,
  userGroups: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  GlobalVariableRs: state.GlobalVariableRs,
});

export default connect(mapStateToProps, { addGlobalVaribleRs })(AddGlobalVariable);
