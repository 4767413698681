import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import FormComponent from "../../../common/FormComponent";
import { addService } from "../../../../redux/actions/serviceAction";
import { getEmployeeDetails } from "../../../../redux/actions/employeeAction";
import { getCustomers } from "../../../../redux/actions/customerAction";
import {
  Validator,
  submitValidator,
  errors,
  valid,
  refresh,
} from "../../../common/Validator";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";

class AddService extends Component {
  constructor() {
    super();
    this.state = {
      coName: "",
      suggestions: [],
      name: "",
      description: "",
      type: "Small",
      billType: "T M",
      startDate: "",
      endDate: "",
      effort: "",
      cost: "",
      currency: "AUD",
      location: "Bangalore",
      status: "New",
      spoc: "",
      team: "",
      comment: "",
      actualStart: "",
      actualEnd: "",
      projCustSpoc: "",
      projSpocContactNo: "",
      servicePmName: "",
      addedOn: "",
      formErrors: {
        selectError: "",
      },
      message: "",
      error: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    e.preventDefault();
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let service = {
      companyName: this.state.coName,
      serviceName: this.state.name,
      serviceCreatorId: localStorage.getItem("id"),
      plannedStart: this.state.startDate,
      actualStart: this.state.actualStart,
      plannedEnd: this.state.endDate,
      actualEnd: this.state.actualEnd,
      effort: this.state.effort,
      budget: this.state.cost,
      currency: this.state.currency,
      location: this.state.location,
      description: this.state.description,
      status: this.state.status,
      comment: this.state.comment,
      serviceCustSpoc: this.state.projCustSpoc,
      serviceSpocContactNo: this.state.projSpocContactNo,
      servicePmName: this.state.servicePmName,
      resources: this.state.team,
      serviceType: this.state.type,
      billingType: this.state.billType,
    };
    if (this.state.team && this.state.servicePmName && valid) {
      this.props.addService(service, this.props.history);
    } else {
      let errors = this.state.formErrors;
      errors.selectError = "Please select option";
      this.setState({
        formErrors: errors,
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidMount function for getting the all the employee details
*/
  componentDidMount() {
    this.props.getEmployeeDetails();
    this.props.getCustomers();
    refresh();
  }

  onTextChanged = (e) => {
    Validator(e.target);
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      suggestions = this.props.customers.data
        .sort()
        .filter((v) => regex.test(v.compFullName));
    }
    this.setState(() => ({ suggestions, coName: value }));
  };

  suggestionSelected = (value) => {
    this.setState(() => ({
      coName: value,
      suggestions: [],
    }));
  };

  renderSuggestions = () => {
    const { suggestions } = this.state;
    if (suggestions.length === 0) {
      return null;
    }
    return (
      <ul className="suggestions">
        {suggestions.map((item) => {
          return (
            <li onClick={() => this.suggestionSelected(item.compFullName)}>
              {item.compFullName}
            </li>
          );
        })}
      </ul>
    );
  };

  render() {
    let shrink;
    const { selectError } = this.state.formErrors;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");

    return (
      <div className={shrink}>
        <h5 className="center bold">Add Service</h5>
        <Container className="card container-card">
          <Link to="/pie/pps/services">
            <i className="material-icons cancel-button">cancel</i>
          </Link>
          <Form onSubmit={this.onSubmit} className="p-3" noValidate>
            <Row>
              <Col md="4">
                <div>
                  <FormGroup>
                    <Label className="label-sm bold">Customer Name</Label>
                    <Input
                      type="text"
                      name="coName"
                      value={this.state.coName}
                      onChange={this.onTextChanged}
                      autoComplete="off"
                      className="form-control-sm"
                      required
                    />
                    {errors.coName && (
                      <FormText className="error">{errors.coName}</FormText>
                    )}
                  </FormGroup>
                  {this.renderSuggestions()}
                </div>
                <FormComponent
                  labelClass="bold label-sm"
                  label="Service Name"
                  type="text"
                  name="name"
                  inputClass="form-control-sm"
                  value={this.state.name}
                  change={this.onChange}
                  placeholder="Project Name"
                  required={true}
                />
                <FormGroup>
                  <Label className="bold label-sm">Service Type</Label>
                  <Input
                    type="select"
                    name="type"
                    className="form-control-sm"
                    onChange={this.onChange}
                    value={this.state.type}
                    required
                  >
                    <option value="Small">Small Service</option>
                    <option value="Large">Large Service</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">Billing Type</Label>
                  <Input
                    type="select"
                    name="billType"
                    className="form-control-sm"
                    onChange={this.onChange}
                    value={this.state.billType}
                    required
                  >
                    <option value="T M">T&M</option>
                    <option value="Fixed">Fixed</option>
                  </Input>
                </FormGroup>
                <FormComponent
                  labelClass="bold label-sm"
                  label="Service Description"
                  type="textarea"
                  name="description"
                  inputClass="form-control-sm"
                  value={this.state.description}
                  change={this.onChange}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Actual Start"
                  type="date"
                  name="actualStart"
                  inputClass="form-control-sm"
                  value={this.state.actualStart}
                  change={this.onChange}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Actual End"
                  type="date"
                  name="actualEnd"
                  inputClass="form-control-sm"
                  value={this.state.actualEnd}
                  min={this.state.actualStart}
                  change={this.onChange}
                  required={true}
                />
              </Col>
              <Col md="4">
                <FormComponent
                  labelClass="bold label-sm"
                  label="Start Date"
                  type="date"
                  name="startDate"
                  inputClass="form-control-sm"
                  value={this.state.startDate}
                  change={this.onChange}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="End Date"
                  type="date"
                  name="endDate"
                  change={this.onChange}
                  value={this.state.endDate}
                  min={this.state.startDate}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Service Effort (Hours)"
                  type="number"
                  name="effort"
                  inputClass="form-control-sm"
                  value={this.state.effort}
                  change={this.onChange}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Service Cost"
                  type="number"
                  name="cost"
                  inputClass="form-control-sm"
                  value={this.state.cost}
                  change={this.onChange}
                  required={true}
                />
                <FormGroup>
                  <Label className="bold label-sm">Currency</Label>
                  <Input
                    type="select"
                    name="currency"
                    className="form-control-sm"
                    value={this.state.currency}
                    onChange={this.onChange}
                    required
                  >
                    <option value="AUD">AUD</option>
                    <option value="EUR">EUR</option>
                    <option value="GBP">GBP</option>
                    <option value="INR">INR</option>
                    <option value="SGD">SGD</option>
                    <option value="USD">USD</option>
                  </Input>
                </FormGroup>
                <br></br>
                <FormComponent
                  labelClass="bold label-sm"
                  label="Customer Spoc "
                  type="text"
                  name="projCustSpoc"
                  inputClass="form-control-sm"
                  value={this.state.projCustSpoc}
                  change={this.onChange}
                  required={true}
                />

                <FormComponent
                  labelClass="bold label-sm"
                  label="Spoc ContactNo "
                  type="number"
                  name="projSpocContactNo"
                  inputClass="form-control-sm"
                  value={this.state.projSpocContactNo}
                  change={this.onChange}
                  required={true}
                />
              </Col>
              <Col md="4">
                <FormComponent
                  labelClass="bold label-sm"
                  label="Service Location"
                  type="text"
                  name="location"
                  inputClass="form-control-sm"
                  value={this.state.location}
                  change={this.onChange}
                  required={true}
                />
                <FormGroup>
                  <Label className="bold label-sm">Service Status</Label>
                  <Input
                    type="select"
                    name="status"
                    className="form-control-sm"
                    onChange={this.onChange}
                    value={this.state.status}
                    required
                  >
                    <option value="New">New</option>
                    <option value="WIP">Work in progress</option>
                    <option value="Delay">Delay</option>
                    <option value="Completed">Completed</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">Service SPOC</Label>
                  <Input
                    type="select"
                    name="spoc"
                    className="form-control-sm"
                    value={this.state.spoc}
                    onChange={this.onChange}
                    required
                  >
                    <option hidden>Select...</option>
                    <option value="0">Bebin Suresh</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label className="bold label-sm">Team</Label>
                  <Input
                    type="select"
                    name="team"
                    className="form-control-sm"
                    value={this.state.team}
                    onChange={this.onChange}
                    required
                  >
                    <option hidden>Select...</option>
                    <option value="0">Bebin Suresh</option>
                  </Input>
                  {selectError && this.state.team ? (
                    ""
                  ) : (
                    <FormText className="error">{selectError}</FormText>
                  )}
                </FormGroup>
                <FormComponent
                  labelClass="bold label-sm"
                  label="Comments"
                  type="textarea"
                  name="comment"
                  inputClass="form-control-sm"
                  value={this.state.comment}
                  change={this.onChange}
                  required={true}
                />
                <FormGroup>
                  <Label className="bold label-sm">Service Manager</Label>
                  <Input
                    name="servicePmName"
                    type="select"
                    className="form-control-sm"
                    onChange={this.onChange}
                    value={this.state.servicePmName}
                    required={true}
                  >
                    <option hidden>Select...</option>
                    {this.props.employee &&
                      this.props.employee.data.map((item) => {
                        return (
                          <option key={item.empId} value={item.empId}>
                            {item.empId}-{item.firstName}
                          </option>
                        );
                      })}
                  </Input>
                  {selectError && this.state.servicePmName ? (
                    ""
                  ) : (
                    <FormText className="error">{selectError}</FormText>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <div className="center">
              <button
                type="submit"
                className="btn center login-button white-text"
                disabled={this.props.services.buttonDisable ? true : false}
              >
                Add
              </button>
            </div>
          </Form>
        </Container>
        {this.props.services.message ? (
          <Notifier message={this.props.services.message} />
        ) : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
      </div>
    );
  }
}

AddService.propTypes = {
  addService: PropTypes.func.isRequired,
  getEmployeeDetails: PropTypes.func.isRequired,
  services: PropTypes.object.isRequired,
  getCustomers: PropTypes.func.isRequired,
  customers: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  services: state.services,
  employee: state.employee,
  customers: state.customers,
});

export default connect(mapStateToProps, {
  addService,
  getEmployeeDetails,
  getCustomers,
})(AddService);
