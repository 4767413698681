import React, { Component } from "react";
import axios from "axios";
import { CANDIDATE_RESUME } from "../../../utils/routes";

class CandidateProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
    };
    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this.getReport = this.getReport.bind(this);
  }

  nextPage() {
    const { numPages, pageNumber } = this.state;
    let page = pageNumber < numPages ? pageNumber + 1 : 1;
    this.setState({ pageNumber: page });
  }

  prevPage() {
    const { pageNumber } = this.state;
    let page = pageNumber === 1 ? pageNumber : pageNumber - 1;
    this.setState({ pageNumber: page });
  }

  /*
getReport function for downloading the candidate resume
*/
  getReport() {
    axios
      .get(`${CANDIDATE_RESUME}?candId=${this.props.id}`, {
        responseType: "blob",
        withCredentials: true,
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", this.props.resume);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        this.setState({
          error: "download failed",
        });
      });
    setTimeout(() => {
      this.setState({
        message: "",
        error: "",
      });
    }, 2000);
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  render() {
    return (
      <div>
        <button
          className="btn login-button white-text"
          onClick={this.getReport}
        >
          Download Resume
        </button>
      </div>
    );
  }
}

export default CandidateProfile;
