// API variable imported
import {
  API,
  HCM_API,
  IAM_API,
  RS_API,
  CAN_API,
  CRM_API,
  GG_AUTH_API,
  E360_API,
  MATCH_CAN_API,
  MARKETING_API
} from "./config";

// Notifications EndPoints List
export const FETCH_MYDAY = `${API}/myday/fetch`;

// Inquiries endPoints List
export const CURD_INQUIRY = `${MARKETING_API}/v1/api/mktg/inquiryinfo`;

export const SEARCH_INQUIRY = `${MARKETING_API}/v1/api/mktg/inquiryinfo/search`

export const CHANGE_PASSWORD = `${IAM_API}/v1/api/pie-user-iam/change-password`;

//  Careers
export const GET_CAREERS = `${API}/careers`;

export const ADD_CAREERS = `${API}/careers/add`;

export const UPDATE_CAREERS = `${API}/careers/update`;

export const SEARCH_CAREERS = `${API}/careers/search`;

//  Login
export const LOGIN = `${IAM_API}/v1/api/pie-user-iam/login`;

export const EMP_IS_LOGGEDIN = `${IAM_API}/v1/api/pie-user-iam/isLoggedIn`;

export const GET_EMP_ACCESS_AND_PERMISSION_DETAILS = `${IAM_API}/v1/api/pie-user-iam/current-pie-user-access`;

export const LOGOUT = `${IAM_API}/v1/api/pie-user-iam/logout`;

export const RE_SEND_EMAIL = `${IAM_API}/v1/api/pie-user-iam/resend-mail`;

//  Employee
export const EMPLOYEE_DETAILS = `${HCM_API}/employee/fetch`;

export const EMPLOYEE_LIST = `${HCM_API}/employee/select`;

export const REGISTER_NEW_EMPLOYEE = `${HCM_API}/employee/create`;

export const EMPLOYEE_UPDATE = `${HCM_API}/employee/update`;

export const EMPLOYEE_FETCH1 = `${HCM_API}/employee/fetch1`;

export const CREATE_PERSONAL_INFO = `${HCM_API}/employeeperinfo/create`;

export const ALL_PERSONAL_INFO = `${HCM_API}/employeeperinfo/select`;

export const EMPLOYEE_PERSONAL_INFO = `${HCM_API}/employeeperinfo/fetch`;

export const UPDATE_EMPLOYEE_PERSONAL = `${HCM_API}/employeeperinfo/update`;

export const ALL_PROFESSIONAL_INFO = `${HCM_API}/employeeprof/select`;

export const EMPLOYEE_PROFESSIONAL_INFO = `${HCM_API}/employeeprof/fetch`;

export const CREATE_EMPLOYEE_PROFESSION = `${HCM_API}/employeeprof/create`;

export const UPDATE_EMPLOYEE_PROFESSION = `${HCM_API}/employeeprof/update`;

export const DELETE_EMPLOYEE_PROFESSION = `${HCM_API}/employeeprof/delete`;

export const UPLOAD_PROFESSION_DOCUMENTS = `${HCM_API}/v1/api/hcm/empprofessionaldocs`;

export const FETCH_PROFESSION_DOCUMENTS = `${HCM_API}/profdoc/fetch`;

export const DOWNLOAD_PROFESSION_DOCUMENTS = `${HCM_API}/profdoc/download`;

export const DELETE_PROFESSION_DOCUMENTS = `${HCM_API}/profdoc/delete`;

export const EMPLOYEE_BANK_DETAILS = `${HCM_API}/employeebankinfo/fetch`;

export const UPDATE_BANK_INFO = `${HCM_API}/employeebankinfo/update`;

export const CREATE_BANK_DETAILS = `${HCM_API}/employeebankinfo/create`;

export const ALL_EDUCATION = `${HCM_API}/EmployeeEducationinfo/fetch`;

export const EMPLOYEE_EDUCATION = `${HCM_API}/EmployeeEducationinfo/select`;

export const CREATE_EMPLOYEE_EDUCATION = `${HCM_API}/EmployeeEducationinfo/create`;

export const UPDATE_EMPLOYEE_EDUCATION = `${HCM_API}/EmployeeEducationinfo/update`;

export const DELETE_EMPLOYEE_EDUCATION = `${HCM_API}/EmployeeEducationinfo/delete`;

export const EMPLOYEE_CERTIFICATION = `${HCM_API}/Employeecertificationinfo/select`;

export const CREATE_EMPLOYEE_CERTIFICATION = `${HCM_API}/Employeecertificationinfo/create`;

export const UPDATE_EMPLOYEE_CERTIFICATION = `${HCM_API}/EmployeeCertificationinfo/update`;

export const DELETE_EMPLOYEE_CERTIFICATION = `${HCM_API}/Employeecertificationinfo/delete`;

export const EMPLOYEE_PASSPORT_DETAILS = `${HCM_API}/passportdetails/fetch`;

export const ALL_PASSPORT_DETAILS = `${HCM_API}/passportdetails/select`;

export const UPDATE_PASSPORT_INFO = `${HCM_API}/passportdetails/update`;

export const CREATE_PASSPORT_DETAILS = `${HCM_API}/passportdetails/create`;

export const FETCH_OTHER_ACCOUNT_DETAILS = `${HCM_API}/otheraccount/fetch`;

export const UPDATE_OTHER_ACCOUNT_DETAILS = `${HCM_API}/otheraccount/update`;

export const FETCH_EMPLOYEE_ADDRESS = `${HCM_API}/address/fetch`;

export const UPDATE_EMPLOYEE_ADDRESS = `${HCM_API}/address/update`;

export const FETCH_EMPLOYEE_CONTACT_DETAILS = `${HCM_API}/contacts/fetch`;

export const UPDATE_EMPLOYEE_CONTACT_DETAILS = `${HCM_API}/contacts/update`;

export const FETCH_EMERGENCY_CONTACT_DETAILS = `${HCM_API}/emercontact/fetch`;

export const UPDATE_EMERGENCY_CONTACT_DETAILS = `${HCM_API}/emercontact/update`;

export const FETCH_FAMILY_CONTACT_DETAILS = `${HCM_API}/family/fetch`;

export const UPDATE_FAMILY_CONTACT_DETAILS = `${HCM_API}/family/update`;

export const UPLOAD_EMPLOYEE_DOCUMENTS = `${HCM_API}/documents/upload`;

export const FETCH_EMPLOYEE_DOCUMENTS = `${HCM_API}/documents/fetch`;

export const DELETE_EMPLOYEE_DOCUMENTS = `${HCM_API}/documents/delete`;

export const CREATE_EMPLOYEE_AWARD = `${HCM_API}/award/create`;

export const FETCH_EMPLOYEE_AWARD = `${HCM_API}/award/fetch`;

export const DELETE_EMPLOYEE_AWARD = `${HCM_API}/award/delete`;

export const UPDATE_EMPLOYEE_AWARD = `${HCM_API}/award/update`;

export const FETCH_OTHER_IDENTIFIERS = `${HCM_API}/otheridentifiers/fetch`;

export const UPDATE_OTHER_IDENTIFIERS = `${HCM_API}/otheridentifiers/update`;

export const CREATE_EMPLOYEE_DEPENDENT = `${HCM_API}/dependent/create`;

export const FETCH_EMPLOYEE_DEPENDENT = `${HCM_API}/dependent/fetch`;

export const DELETE_EMPLOYEE_DEPENDENT = `${HCM_API}/dependent/delete`;

export const UPDATE_EMPLOYEE_DEPENDENT = `${HCM_API}/dependent/update`;

export const GET_NOMINEE = `${HCM_API}/nominee/fetch`;

export const ADD_NOMINEE = `${HCM_API}/nominee/create`;

export const DELETE_NOMINEE = `${HCM_API}/nominee/delete`;

export const UPDATE_NOMINEE = `${HCM_API}/nominee/update`;

export const FETCH_PARTNER_BANK_ACC = `${HCM_API}/partnerbankacc/fetch`;

export const UPDATE_PARTNER_BANK_ACC = `${HCM_API}/partnerbankacc/update`;

export const FETCH_PARTNER_DOCUMENTS = `${HCM_API}/partnerdoc/fetch`;

export const UPLOAD_PARTNER_NDA_DOCUMENTS = `${HCM_API}/partnerdoc/ndaupload`;

export const UPLOAD_PARTNER_AGREEMET_DOCUMENTS = `${HCM_API}/partnerdoc/uploadaggrement`;

export const UPLOAD_PARTNER_CANCELLED_DOCUMENTS = `${HCM_API}/partnerdoc/cancelledcheque`;

export const UPLOAD_PARTNER_CIN_DOCUMENTS = `${HCM_API}/partnerdoc/cinupload`;

export const UPLOAD_PARTNER_GST_DOCUMENTS = `${HCM_API}/partnerdoc/gstupload`;

export const UPLOAD_PARTNER_PAN_DOCUMENTS = `${HCM_API}/partnerdoc/panupload`;

export const UPLOAD_PARTNER_MSMED_DOCUMENTS = `${HCM_API}/partnerdoc/msmedupload`;

export const UPLOAD_PARTNER_OTHER_DOCUMENTS = `${HCM_API}/partnerdoc/othersupporting`;

export const FETCH_PARTNER_ESCALATION = `${HCM_API}/escalation/fetch`;

export const UPDATE_PARTNER_ESCALATION = `${HCM_API}/escalation/update`;

export const FETCH_PARTNER_TAXPYMT_DETAILS = `${HCM_API}/taxpymtdetail/fetch`;

export const UPDATE_PARTNER_TAXPYMT_DETAILS = `${HCM_API}/taxpymtdetail/update`;

export const CREATE_PARTNER_PROD_CAT = `${HCM_API}/partnerprodcat/create`;

export const FETCH_PARTNER_PROD_CAT = `${HCM_API}/partnerprodcat/fetch`;

export const UPDATE_PARTNER_PROD_CAT = `${HCM_API}/partnerprodcat/update`;

export const CREATE_PARTNER_SERVE_CAT = `${HCM_API}/partnerservicecat/create`;

export const FETCH_PARTNER_SERVE_CAT = `${HCM_API}/partnerservicecat/fetch`;

export const UPDATE_PARTNER_SERVE_CAT = `${HCM_API}/partnerservicecat/update`;

// Forgot password EndPoint
export const FORGOT_PASSWORD = `${IAM_API}/v1/api/pie-user-iam/forgot-password`;

export const RESET_PASSWORD = `${IAM_API}/v1/api/pie-user-iam/reset-password`;

export const FETCH_ACCESS = `${API}/access/fetch`;

// Vendor EndPoint List
export const FETCH_VENDOR_BANK_ACC = `${API}/vendoraccdetails/fetch`;

export const UPDATE_VENDOR_BANK_ACC = `${API}/vendoraccdetails/update`;

export const FETCH_VENDOR_DOCUMENTS = `${API}/vendordoc/fetch`;

export const UPLOAD_VENDOR_NDA_DOCUMENTS = `${API}/vendordoc/ndaupload`;

export const UPLOAD_VENDOR_AGREEMET_DOCUMENTS = `${API}/vendordoc/uploadaggrement`;

export const UPLOAD_VENDOR_CANCELLED_DOCUMENTS = `${API}/vendordoc/cancelledcheque`;

export const UPLOAD_VENDOR_CIN_DOCUMENTS = `${API}/vendordoc/cinupload`;

export const UPLOAD_VENDOR_GST_DOCUMENTS = `${API}/vendordoc/gstupload`;

export const UPLOAD_VENDOR_PAN_DOCUMENTS = `${API}/vendordoc/panupload`;

export const UPLOAD_VENDOR_MSMED_DOCUMENTS = `${API}/vendordoc/msmedupload`;

export const UPLOAD_VENDOR_OTHER_DOCUMENTS = `${API}/vendordoc/othersupporting`;

export const FETCH_VENDOR_ESCALATION = `${API}/vendorescalation/fetch`;

export const UPDATE_VENDOR_ESCALATION = `${API}/vendorescalation/update`;

export const FETCH_VENDOR_TAXPYMT_DETAILS = `${API}/vendortaxpymt/fetch`;

export const UPDATE_VENDOR_TAXPYMT_DETAILS = `${API}/vendortaxpymt/update`;

export const CREATE_VENDOR_PROD_CAT = `${API}/vendorprodcat/create`;

export const FETCH_VENDOR_PROD_CAT = `${API}/vendorprodcat/fetch`;

export const UPDATE_VENDOR_PROD_CAT = `${API}/vendorprodcat/update`;

export const CREATE_VENDOR_SERVE_CAT = `${API}/vendorservicecat/create`;

export const FETCH_VENDOR_SERVE_CAT = `${API}/vendorservicecat/fetch`;

export const UPDATE_VENDOR_SERVE_CAT = `${API}/vendorservicecat/update`;

// Partners Service location
export const CREATE_SERVICE_LOCATION = `${API}/servicelocation/create`;

export const FETCH_SERVICE_LOCATION = `${API}/servicelocation/fetch`;

export const UPDATE_SERVICE_LOCATION = `${API}/servicelocation/update`;

// Vendors Service location
export const CREATE_VENDOR_LOCATION = `${API}/vendorserviceloc/create`;

export const FETCH_VENDOR_LOCATION = `${API}/vendorserviceloc/fetch`;

export const UPDATE_VENDOR_LOCATION = `${API}/vendorserviceloc/update`;

// Employee Initiate Resignation EndPoints List
export const CREATE_EMPLOYEE_INITIATE_RESIGNATION = `${API}/empinitiatereg/create`;

// Manager Initiate Resignation EndPoints List
export const CREATE_MANAGER_INITIATE_RESIGNATION = `${API}/maninitiatereg/create`;

// ExitClear EndPoints List
export const UPDATE_EXIT_CLEARANCE = `${HCM_API}/exitclear/update`;

//Products
export const GET_PRODUCT = `${API}/product/select`;

export const ADD_PRODUCT = `${API}/product/create`;

export const SEARCH_PRODUCT = `${API}/product/search`;

export const UPDATE_PRODUCT = `${API}/product/update`;

//  Projects
export const MY_PROJECTS = `${API}/project/fetch`;

export const ALL_PROJECTS = `${API}/project/select`;

export const NEW_PROJECT = `${API}/project/create`;

export const UPDATE_PROJECT = `${API}/project/update`;

// ProjectTeamAssignment
export const GET_PROJECT_TEAM_ASSIGNMENT = `${API}/assignment/fetch`;

// ProjectMailsStone/Goals
export const ADD_PROJECT_MAILS_STONE = `${API}/projmiles/create`;

export const FETCH_PROJECT_MAILS_STONE = `${API}/projmiles/fetch`;

export const UPDATE_PROJECT_MAILS_STONE = `${API}/projmiles/update`;

export const DELETE_PROJECT_MAILS_STONE = `${API}/projmiles/delete`;

// Portal Feedback EndPoint List
export const CREATE_PORTAL_FEEDBACK = `${API}/feedback/create`;

export const SEARCH_PORTAL_FEEDBACK = `${API}/feedback/search`;

export const UPDATE_PORTAL_FEEDBACK = `${API}/feedback/update`;

export const FETCH_PORTAL_FEEDBACK = `${API}/feedback/fetch`;

export const SELECT_PORTAL_FEEDBACK = `${API}/feedback/select`;

//End of Services Menu EndPoint List

//serviceTeamAssignment
export const GET_SERVICE_TEAM_ASSIGNMENT = `${API}/serviceassign/fetch`;

//  Resource
export const GET_RESOURCES = `${API}/proresource/fetch`;

export const ALL_RESOURCES = `${API}/proresource/select`;

export const ADD_RESOURCE = `${API}/proresource/create`;

export const UPDATE_RESOURCE = `${API}/proresource/update`;

// Dashboard---> Innovation EndPoints List
//  Innovation EndPoints List
export const GET_IDEAS = `${API}/idea/fetch`;

export const NEW_IDEA = `${API}/idea/create`;

export const UPDATE_IDEA = `${API}/idea/update`;

export const UPLOAD_IDEA_DOCUMENTS = `${API}/ideadoc/upload`;

export const UPDATE_IDEA_DOCUMENTS = `${API}/ideadoc/update`;

export const FETCH_IDEA_DOCUMENTS = `${API}/ideadoc/select`;

export const DOWNLOAD_IDEA_DOCUMENTS = `${API}/ideadoc/download`;

export const DELETE_IDEA_DOCUMENTS = `${API}/ideadoc/delete`;

// CRM EndPoints List
// CRM Insights EndPoints List
// EndPoint for ContactByDesignation Graph
export const GET_CUSTOMER_CONTACT_DESIGNATION = `${API}/customer/contactdesigantion`;

// EndPoint for CustomerAcquisitionCost Graph
export const GET_CUSTOMER_ACQUISITION_COST = `${API}/order/caccost`;

// EndPoint for CustomerByAccounts Graph
export const GET_CUSTOMER_BY_ACCOUNTS = `${API}/account/customeraccount`;

// EndPoint for CustomerChurn Graph
export const GET_CUSTOMER_CHURN = `${API}/camp/totalcount`;

// EndPoint for CustomerLocation Graph
export const GET_CUSTOMER_LOCATION = `${API}/customer/customerlocation`;

// EndPoint for CustomerType Graph
export const GET_CUSTOMER_TYPE_GRAPH = `${API}/customer/customertype`;

// EndPoint for ProposalVsWins Graph
export const GET_PROPOSALS_VS_WINS = `${API}/order/proposalwins`;

// EndPoint for LengthOfSalesCycle Graph
export const GET_LENGTH_OF_SALES_CYCLE = `${API}/order/crmaverage`;

// EndPoint for RateOfExpansion Graph
export const GET_RATE_OF_EXPANSION = `${API}/order/expansionorder`;

// EndPoint for RateOfRenewal Graph
export const GET_RATE_OF_RENEWAL = `${API}/order/renewalorder`;

// EndPoint for SupportTicket Graph
export const GET_SUPPORT_TICKET = `${API}/feedback/supportticket`;

// EndPoint for TopUpSelles Graph
export const GET_TOP_UP_SELLES = `${API}/camp/count1`;

// End of the CRM Insights EndPoints List

// CRM-->Customers EndPoint List
export const GET_CUSTOMER = `${API}/customer/select`;

export const ADD_CUSTOMER = `${API}/customer/new`;

export const UPDATE_CUSTOMER = `${API}/customer/update`;

export const FETCH_CUSTOMER = `${API}/customer/fetch`;

export const UPLOAD_CUSTOMER_COMPANYDOCS = `${API}/companydocs/upload`;

export const UPDATE_CUSTOMER_COMPANYDOCS = `${API}/companydocs/update`;

export const SELECT_CUSTOMER_COMPANYDOCS = `${API}/companydocs/select`;

export const CUSTOMER_COMPANYDOCS = `${API}/companydocs`;

export const DELETE_CUSTOMER_COMPANYDOCS = `${API}/companydocs/delete`;

//Accounts
export const GET_ACCOUNT = `${API}/account/select`;

export const GET_ALL_ACCOUNT = `${API}/account/select1`;

export const ADD_ACCOUNT = `${API}/account/create`;

export const UPDATE_ACCOUNT = `${API}/account/update`;

export const SEARCH_ACCOUNT = `${API}/account/search`;

//Contacts
export const GET_CONTACT = `${API}/custcontact/select`;

export const GET_ALL_CONTACT = `${API}/custcontact/select1`;

export const ADD_CONTACT = `${API}/custcontact/create`;

export const UPDATE_CONTACT = `${API}/custcontact/update`;

export const SEARCH_CONTACT = `${API}/custcontact/search`;

export const SEARCH_CONTACT_DESIGNATION = `${API}/custcontact/search`;

//Partners
export const GET_PARTNER = `${API}/partner/select`;

export const ADD_PARTNER = `${API}/partner/create`;

export const UPDATE_PARTNER = `${API}/partner/update`;

export const SEARCH_PARTNERS = `${API}/partner/search`;

// Partners ---> Documents Upload
export const DOWNLOAD_PARTNER_NDA_DOCUMENTS = `${API}/partnerdoc/ndadownload`;

export const DOWNLOAD_PARTNER_AGGREMENT_DOCUMENTS = `${API}/partnerdoc/aggrementdownload`;

export const DOWNLOAD_PARTNER_OTHER_DOCUMENTS = `${API}/partnerdoc/otherdownload`;

export const DOWNLOAD_PARTNER_CHEQUE_DOCUMENTS = `${API}/partnerdoc/chequedownload`;

export const DOWNLOAD_PARTNER_MSMED_DOCUMENTS = `${API}/partnerdoc/msmeddownload`;

export const DOWNLOAD_PARTNER_CIN_DOCUMENTS = `${API}/partnerdoc/cindownload`;

export const DOWNLOAD_PARTNER_GST_DOCUMENTS = `${API}/partnerdoc/gstdownload`;

export const DOWNLOAD_PARTNER_PAN_DOCUMENTS = `${API}/partnerdoc/pandownload`;

//Vendors
export const GET_VENDOR = `${API}/vendor/select`;

export const ADD_VENDOR = `${API}/vendor/create`;

export const UPDATE_VENDOR = `${API}/vendor/update`;

export const SEARCH_VENDORS = `${API}/vendor/search`;

// Vendors ---> Documents Upload
export const DOWNLOAD_VENDOR_NDA_DOCUMENTS = `${API}/vendordoc/ndadownload`;

export const DOWNLOAD_VENDOR_AGGREMENT_DOCUMENTS = `${API}/vendordoc/aggrementdownload`;

export const DOWNLOAD_VENDOR_OTHER_DOCUMENTS = `${API}/vendordoc/otherdownload`;

export const DOWNLOAD_VENDOR_CHEQUE_DOCUMENTS = `${API}/vendordoc/chequedownload`;

export const DOWNLOAD_VENDOR_MSMED_DOCUMENTS = `${API}/vendordoc/msmeddownload`;

export const DOWNLOAD_VENDOR_CIN_DOCUMENTS = `${API}/vendordoc/cindownload`;

export const DOWNLOAD_VENDOR_GST_DOCUMENTS = `${API}/vendordoc/gstdownload`;

export const DOWNLOAD_VENDOR_PAN_DOCUMENTS = `${API}/vendordoc/pandownload`;

//Suppliers
export const GET_SUPPLIER = `${API}/supplier/select`;

export const ADD_SUPPLIER = `${API}/supplier/create`;

export const UPDATE_SUPPLIER = `${API}/supplier/update`;

export const SEARCH_SUPPLIERS = `${API}/supplier/search`;

//ProductList
export const GET_PRODUCTLIST = `${API}/prodcatlog/select`;

export const ADD_PRODUCTLIST = `${API}/prodcatlog/create`;

export const UPDATE_PRODUCTLIST = `${API}/prodcatlog/update`;

export const SEARCH_PRODUCTLIST = `${API}/prodcatlog/search`;

//  Attendance
export const ATTENDANCE_DETAILS = `${API}/empattendance/select`;

export const TIME_IN = `${API}/empattendance/timein`;

export const TIME_OUT = `${API}/empattendance/timeout`;

export const ATTENDANCE_FETCH = `${API}/empattendance/fetch`;

//  Lms
export const APPLY_LEAVE = `${HCM_API}/leavemanagement/create`;

export const DISPLAY_LEAVE = `${HCM_API}/leavemanagement/select`;

export const UPDATE_LEAVE = `${HCM_API}/leavemanagement/update`;

export const SEARCH_LEAVE = `${HCM_API}/leavemanagement/search`;

export const GET_HOLIDAYS = `${HCM_API}/companyholiday/select`;

export const ADD_HOLIDAY = `${HCM_API}/companyholiday/create`;

export const REMOVE_HOLIDAYS = `${HCM_API}/companyholiday/delete`;

export const UPDATE_HOLIDAYS = `${HCM_API}/companyholiday/update`;

export const LEAVE_BALANCE = `${HCM_API}/leavemanagement/fetch`;

// HCM --> LMS --> Attendance Report

export const FETCH_MANAGER_DAY_ATTENDANCE_REPORT = `${HCM_API}/v1/api/hcm/empattendance/daywise`;

export const FETCH_MANAGER_WEEK_ATTENDANCE_REPORT = `${HCM_API}/v1/api/hcm/empattendance/selectedempattendanceweekly`;

export const FETCH_MANAGER_MONTH_ATTENDANCE_REPORT = `${HCM_API}/v1/api/hcm/empattendance/selectedempattendancemonthly`;

// HCM --> LMS --> Leave Report

export const FETCH_MANAGER_DAY_LEAVE_REPORT = `${HCM_API}/v1/api/hcm/leavemanagement/daywise/`;

export const FETCH_MANAGER_WEEK_LEAVE_REPORT = `${HCM_API}/v1/api/hcm/leavemanagement/selectedempsleavedetailsweekly`;

export const FETCH_MANAGER_MONTH_LEAVE_REPORT = `${HCM_API}/v1/api/hcm/leavemanagement/selectedempsleavedetailsmonthly`;

//HR ---> Resignation
export const GET_EXIT_CLEARANCE = `${HCM_API}/exitclear/select`;

export const GET_RESIGNATION_STATUS_CHECK = `${HCM_API}/exitclear/fetch`;

//  Timesheet
// export const SUBMIT_TIMESHEET = `${API}/timemanagement/create`;

export const UPDATE_TIMESHEET = `${API}/timemanagement/update`;

export const VIEW_TIMESHEET = `${API}/timemangement/fetch`;

export const SEARCH_POJ_TIME = `${API}/timemangement/search`;

//Campaign
export const CURD_CAMPAIGN = `${MARKETING_API}/v1/api/mktg/campaign`

export const CAMPAIGN_SEARCH = `${MARKETING_API}/v1/api/mktg/campaign/search`;


// Marketing EndPoint List
// Marketing Insights EndPoint List
// CampaignOriginatedTopThreeCustOrders EndPoint
export const GET_LEAD_DISTRIBUSION = `${MARKETING_API}/v1/api/mktg/marketinginsights/leaddistribution`;

// CampaignType To Lead Ratio EndPoint
export const GET_CAMPTYPE_LEAD_RATIO = `${MARKETING_API}/v1/api/mktg/marketinginsights/campaigndistribution`;

// Inquiry Converted To Lead EndPoint
export const GET_INQUIRY_CONVERT_LEAD = `${MARKETING_API}/v1/api/mktg/marketinginsights/inquiriestrend`;

// Lead Generated CampType EndPoint
export const GET_LEAD_GENERATED_CAMPTYPE = `${API}/Lead/count2`;

// Lead last one quarter EndPoint
export const GET_LEAD_LAST_ONE_QUARTER = `${MARKETING_API}/v1/api/mktg/marketinginsights/geowiseleads`;

// Monthly Camp Plan EndPoint
export const GET_MONTHLY_CAMP_PLAN = `${MARKETING_API}/v1/api/mktg/marketinginsights/budgetprojection`;

// Open Leads by Potential EndPoint
export const GET_OPEN_LEADS_POTENTIAL = `${API}/lead/countstatus`;

// Planned vs ActualSpend EndPoint
export const GET_PLANNED_ACTUALSPEND = `${MARKETING_API}/v1/api/mktg/marketinginsights/spendanalysis`;

// Top Five Lead Source Last Year EndPoint
export const GET_TOP_FIVE_LEAD_SOURCE = `${MARKETING_API}/v1/api/mktg/marketinginsights/campaignanalysis`;

// Total Campaigns EndPoint
export const GET_TOTAL_CAMPAIGNS = `${MARKETING_API}/v1/api/mktg/marketinginsights/campaigns`;

// Total Inquiries EndPoint
export const GET_TOTAL_INQUIRIES = `${MARKETING_API}/v1/api/mktg/marketinginsights/inquiries`;

// Total Inquiries EndPoint
export const GET_TOTAL_LEADS = `${MARKETING_API}/v1/api/mktg/marketinginsights/leads`;

//  Leads
export const CURD_LEADS = `${MARKETING_API}/v1/api/mktg/leadmanagement`

export const SEARCH_LEADS = `${MARKETING_API}/v1/api/mktg/leadmanagement/search`

// Leads ---> Activity EndPoints List
export const CURD_LEAD_ACTIVITY = `${MARKETING_API}/v1/api/mktg/leadactivity`

// Prospects ---> Activity EndPoints List
export const UPDATE_PROSPECTS_ACTIVITY = `${API}/prosactivity/update`;

//Prospects
export const GET_PROSPECTS = `${API}/prospects/select`;

export const ADD_PROSPECTS = `${API}/prospects/create`;

export const SEARCH_PROSPECTS = `${API}/prospects/search`;

export const UPDATE_PROSPECTS = `${API}/prospects/update`;

//  Sales
export const ALL_SALES = `${API}/Opportunity/select`;

export const MY_SALES = `${API}/Opportunity/fetch`;

export const NEW_SALES = `${API}/Opportunity/create`;

export const UPDATE_SALES = `${API}/Opportunity/update`;

// Oreders
export const ALL_ORDERS = `${API}/order/select`;

export const MY_ORDERS = `${API}/order/fetch`;

export const NEW_ORDERS = `${API}/order/create`;

export const UPDATE_ORDERS = `${API}/order/update`;

export const SEARCH_ORDERS = `${API}/order/search`;

//  Search

export const SEARCH_SALES = `${API}/opportunity/search`;

export const SEARCH_PROJECTS = `${API}/project/search`;

export const SEARCH_IDEA = `${API}/idea/search`;

export const SEARCH_JOB = `${API}/careers/search`;

export const EMPLOYEE_SEARCH = `${API}/employee/search`;

//  Upload
export const PROJECT_UPLOAD = `${API}/projectdocuments/upload`;

//  TechScreening
export const ALL_CANDIDATES = `${API}/candidate/select`;

export const CREATE_CANDIDATE = `${API}//maintain/upload`;

export const UPDATE_CANDIDATE = `${API}/candidate/update`;

export const SEARCH_CANDIDATES = `${API}/candidate/search`;

export const DELETE_CANDIDATE = `${API}/candidate/delete`;

export const CANDIDATE_RESUME = `${API}/candidate/download`;

export const UPLOAD_CANDIDATE = `${API}/candidate/upload`;

export const ALL_JOBS = `${API}/techjobs/select`;

export const CREATE_JOB = `${API}/techjobs/create`;

export const UPDATE_JOB = `${API}/techjobs/update`;

export const DELETE_JOB = `${API}/techjobs/delete`;

export const ALL_PANEL = `${API}/panel/select`;

export const CREATE_PANEL = `${API}/panel/create`;

export const UPDATE_PANEL = `${API}/panel/update`;

export const DELETE_PANEL = `${API}/panel/delete`;

// export const SEARCH_ROLE = `${API}/role/search`;

export const SEARCH_MENU = `${API}/menu/search`;

export const DOWNLOAD_MAINTAIN = `${API}/maintain/download`;

export const UPDATE_MAINTAIN = `${API}/maintain/update`;

export const SEARCH_MAINTAIN = `${API}/maintain/search`;

export const SELECT_MAINTAIN = `${API}/maintain/select`;

// Role EndPoint List
export const GET_ROLE = `${IAM_API}/v1/api/pie-user-iam/pie-user-role`;

export const ADD_ROLE = `${IAM_API}/v1/api/pie-user-iam/create/pie-user-role`;

export const UPDATE_ROLE = `${IAM_API}/v1/api/pie-user-iam/update/pie-user-role`;

export const DELETE_ROLE = `${IAM_API}/v1/api/pie-user-iam/delete/pie-user-role`;

export const SEARCH_ROLE = `${IAM_API}/v1/api/pie-user-iam/search-role-management`;

// Menu Management EndPoint List
export const GET_APPLICATION = `${IAM_API}/v1/api/pie-user-iam/pie-user-application`;

export const ADD_APPLICATION = `${IAM_API}/v1/api/pie-user-iam/create/pie-user-application`;

export const UPDATE_APPLICATION = `${IAM_API}/v1/api/pie-user-iam/update/pie-user-application`;

export const DELETE_APPLICATION = `${IAM_API}/v1/api/pie-user-iam/delete/pie-user-application`;

export const GET_MODULE = `${IAM_API}/v1/api/pie-user-iam/pie-user-module`;

export const ADD_MODULE = `${IAM_API}/v1/api/pie-user-iam/create/pie-user-module`;

export const UPDATE_MODULE = `${IAM_API}/v1/api/pie-user-iam/update/pie-user-module`;

export const DELETE_MODULE = `${IAM_API}/v1/api/pie-user-iam/delete/pie-user-module`;

export const GET_SERVICE = `${IAM_API}/v1/api/pie-user-iam/pie-user-service`;

export const ADD_SERVICE = `${IAM_API}/v1/api/pie-user-iam/create/pie-user-service`;

export const UPDATE_SERVICE = `${IAM_API}/v1/api/pie-user-iam/update/pie-user-service`;

export const DELETE_SERVICE = `${IAM_API}/v1/api/pie-user-iam/delete/pie-user-service`;

export const SEARCH_SERVICE = `${IAM_API}/v1/api/pie-user-iam/delete/pie-user-service`;

// Access and Permission Management EndPoint List
export const GET_APPLICATION_ACCESS_PERMISSION = `${IAM_API}/v1/api/pie-user-iam/pie-access-application`;

export const UPDATE_APPLICATION_ACCESS_PERMISSION = `${IAM_API}/v1/api/pie-user-iam/update/pie-access-application`;

export const GET_MODULE_ACCESS_PERMISSION = `${IAM_API}/v1/api/pie-user-iam/pie-access-module`;

export const UPDATE_MODULE_ACCESS_PERMISSION = `${IAM_API}/v1/api/pie-user-iam/update/pie-access-module`;

export const GET_SERVICE_ACCESS_PERMISSION = `${IAM_API}/v1/api/pie-user-iam/pie-access-service`;

export const UPDATE_SERVICE_ACCESS_PERMISSION = `${IAM_API}/v1/api/pie-user-iam/update/pie-access-service`;

//userRole component

export const SEARCH_CANDIDATE_M = `${API}/apply/search`;

export const UPDATE_CANDIDATE_m = `${API}/apply/update`;

export const GET_CANDIDATE_M = `${API}/apply/select`;

export const DOWNLOAD_CANDIDATE_M = `${API}/apply/download`;

export const DELETE_CANDIDATE_M = `${API}/apply/delete`;

export const GET_PROJECT_ASGN = `${API}/assignment/select`;

export const UPDATE_PROJECT_ASGN = `${API}/assignment/update`;

export const FETCH_PROJECT_ASGN = `${API}/assignment/fetch`;

export const DELETE_PROJECT_ASGN = `${API}/assignment/delete`;

export const SEARCH_PROJECT_ASGN = `${API}/assignment/search`;

// Role Mapping api's

export const GET_ROLE_MAPPING = `${IAM_API}/v1/api/pie-user-iam/pie-user-role-mapping`;

export const ADD_ROLE_MAPPING = `${IAM_API}/v1/api/pie-user-iam/create/pie-user-role-mapping`;

export const UPDATE_ROLE_MAPPING = `${IAM_API}/v1/api/pie-user-iam/update/pie-user-role-mapping`;

export const DELETE_ROLE_MAPPING = `${IAM_API}/v1/api/pie-user-iam/delete/pie-user-role-mapping`;

export const SEARCH_ROLE_MAPPING = `${IAM_API}/v1/api/pie-user-iam/search-role-mapping`;

//Platform - Business Geographies
export const GET_BUSINESS_GEOGRAPHIES = `${API}/businesscountry/select`;

export const DELETE_BUSINESS_COUNTRIES = `${API}/businesscountry/delete`;

export const UPDATE_BUSINESS_COUNTRIES = `${API}/businesscountry/update`;

//Platform - Business Geographies
export const ADD_BUSINESS_GEOGRAPHIES = `${API}/geowise/create`;

export const GET_BUSINESS_GEOGRAPHIES1 = `${API}/geowise/select`;

export const UPDATE_BUSINESS_GEOGRAPHIES = `${API}/geowise/update`;

export const DELETE_BUSINESS_GEOGRAPHIES = `${API}/geowise/delete`;

//Platform - Business Location
export const GET_BUSINESS_LOCATION = `${API}/location/select`;

export const CREATE_BUSINESS_LOCATION = `${API}/location/create`;

export const DELETE_BUSINESS_LOCATION = `${API}/location/delete`;

export const UPDATE_BUSINESS_LOCATION = `${API}/location/update`;

//Platform - Employee Grade
export const GET_EMPLOYEE_GRADE = `${API}/empgrade/select`;

export const CREATE_EMPLOYEE_GRADE = `${API}/empgrade/create`;

export const UPDATE_EMPLOYEE_GRADE = `${API}/empgrade/update`;

export const DELETE_EMPLOYEE_GRADE = `${API}/empgrade/delete`;

//Platform -Business Unit
export const GET_BUSINESS_UNIT = `${API}/businessunit/select`;

export const CREATE_BUSINESS_UNIT = `${API}/businessunit/create`;

export const DELETE_BUSINESS_UNIT = `${API}/businessunit/delete`;

export const UPDATE_BUSINESS_UNIT = `${API}/businessunit/update`;

//Platform - Business Setup --> Business Type
export const ADD_BUSINESS_TYPE = `${API}/salesbusinesstype/create`;

export const GET_BUSINESS_TYPE = `${API}/salesbusinesstype/select`;

export const UPDATE_BUSINESS_TYPE = `${API}/salesbusinesstype/update`;

export const DELETE_BUSINESS_TYPE = `${API}/salesbusinesstype/delete`;

//Platform - Employee Designation
export const GET_EMPLOYEE_DESIGNATION = `${API}/empdesignation/select`;

export const CREATE_EMPLOYEE_DESIGNATION = `${API}/empdesignation/create`;

export const UPDATE_EMPLOYEE_DESIGNATION = `${API}/empdesignation/update`;

export const DELETE_EMPLOYEE_DESIGNATION = `${API}/empdesignation/delete`;

//Platform - Employee Type
export const GET_EMPLOYEE_TYPE = `${API}/emptype/select`;

export const CREATE_EMPLOYEE_TYPE = `${API}/emptype/create`;

export const UPDATE_EMPLOYEE_TYPE = `${API}/emptype/update`;

export const DELETE_EMPLOYEE_TYPE = `${API}/emptype/delete`;

//Platform - Contact Relation
export const GET_CONTACT_RELATION = `${API}/contrelation/select`;

export const CREATE_CONTACT_RELATION = `${API}/contrelation/create`;

export const UPDATE_CONTACT_RELATION = `${API}/contrelation/update`;

export const DELETE_CONTACT_RELATION = `${API}/contrelation/delete`;

//Platform - BloodGroup
export const GET_BLOODGROUP = `${API}/bloodgroup/select`;

//Platform - Document Name
export const GET_DOCUMENT_NAME = `${API}/docname/select`;

export const CREATE_DOCUMENT_NAME = `${API}/docname/create`;

export const UPDATE_DOCUMENT_NAME = `${API}/docname/update`;

export const DELETE_DOCUMENT_NAME = `${API}/docname/delete`;

//Platfrom - authConfiguration - menuManagement
export const GET_MENU = `${API}/menu/fetch`;

export const CREATE_MENU = `${API}/menu/create`;

export const GET_MENU_ONE = `${API}/menu/fetch1`;

export const UPDATE_MENU = `${API}/menu/update`;

export const DELETE_MENU = `${API}/menu/delete`;

//Platfrom - authConfiguration - subMenuManagement
export const GET_SUB_MENU = `${API}/submenu/fetch`;

export const CREATE_SUB_MENU = `${API}/submenu/create`;

export const UPDATE_SUB_MENU = `${API}/submenu/update`;

export const DELETE_SUB_MENU = `${API}/submenu/delete`;

// Platform -->auth configuration ---> user group
export const ADD_USER_GROUP = `${IAM_API}/v1/api/pie-user-iam/create/pie-user-group`;

export const GET_USER_GROUP = `${IAM_API}/v1/api/pie-user-iam/pie-user-group`;

export const UPDATE_USER_GROUP = `${IAM_API}/v1/api/pie-user-iam/update/pie-user-group`;

export const DELETE_USER_GROUP = `${IAM_API}/v1/api/pie-user-iam/delete/pie-user-group`;

// IAM --> IAM CONFIG
export const CURD_IAM_CONFIG = `${IAM_API}/v1/api/iam-config`;

//Platform - BusinessSetup - LegalEntity
export const GET_LEGALENTITY = `${API}/legalentities/select`;

export const CREATE_LEGALENTITY = `${API}/legalentities/create`;

export const UPDATE_LEGALENTITY = `${API}/legalentities/update`;

export const DELETE_LEGALENTITY = `${API}/legalentities/delete`;

//Platform - BusinessSetup - LeaveType
export const GET_LEAVE_TYPE = `${API}/leavetype/select`;

export const CREATE_LEAVE_TYPE = `${API}/leavetype/create`;

export const UPDATE_LEAVE_TYPE = `${API}/leavetype/update`;

export const DELETE_LEAVE_TYPE = `${API}/leavetype/delete`;

//Platform - Business Setup - IdeaStatus
export const ADD_IDEA_STATUS = `${API}/ideastatus/create`;

export const GET_IDEA_STATUS = `${API}/ideastatus/select`;

export const UPDATE_IDEA_STATUS = `${API}/ideastatus/update`;

export const DELETE_IDEA_STATUS = `${API}/ideastatus/delete`;

//Platform - CRM Setup - Departments
export const ADD_DEPARTMENTS = `${API}/custbusinessunit/create`;

export const GET_DEPARTMENTS = `${API}/custbusinessunit/select`;

export const UPDATE_DEPARTMENTS = `${API}/custbusinessunit/update`;

export const DELETE_DEPARTMENTS = `${API}/custbusinessunit/delete`;

//Platform - Business Setup - PortalFeedback RequestCategory
export const ADD_REQUEST_CATEGORY = `${API}/reqcategory/create`;

export const GET_REQUEST_CATEGORY = `${API}/reqcategory/select`;

export const UPDATE_REQUEST_CATEGORY = `${API}/reqcategory/update`;

export const DELETE_REQUEST_CATEGORY = `${API}/reqcategory/delete`;

//Platform - Business Setup - PortalFeedback Severity
export const ADD_PORTAL_FEEDBACK_SEVERITY = `${API}/severity/create`;

export const GET_PORTAL_FEEDBACK_SEVERITY = `${API}/severity/select`;

export const UPDATE_PORTAL_FEEDBACK_SEVERITY = `${API}/severity/update`;

export const DELETE_PORTAL_FEEDBACK_SEVERITY = `${API}/severity/delete`;

//Platform - Business Setup - PortalFeedback Priority
export const ADD_PORTAL_FEEDBACK_PRIORITY = `${API}/priority/create`;

export const GET_PORTAL_FEEDBACK_PRIORITY = `${API}/priority/select`;

export const UPDATE_PORTAL_FEEDBACK_PRIORITY = `${API}/priority/update`;

export const DELETE_PORTAL_FEEDBACK_PRIORITY = `${API}/priority/delete`;

//Platform - Business Setup - PortalFeedback Status
export const ADD_PORTAL_FEEDBACK_STATUS = `${API}/feedstatus/create`;

export const GET_PORTAL_FEEDBACK_STATUS = `${API}/feedstatus/select`;

export const UPDATE_PORTAL_FEEDBACK_STATUS = `${API}/feedstatus/update`;

export const DELETE_PORTAL_FEEDBACK_STATUS = `${API}/feedstatus/delete`;

//Platform - CRM Setup - Customer Business Domain
export const GET_CUSTOMER_BUSINESS_DOMAIN = `${API}/custbusidomain/select`;

export const CREATE_CUSTOMER_BUSINESS_DOMAIN = `${API}/custbusidomain/create`;

export const UPDATE_CUSTOMER_BUSINESS_DOMAIN = `${API}/custbusidomain/update`;

export const DELETE_CUSTOMER_BUSINESS_DOMAIN = `${API}/custbusidomain/delete`;

//Platform - CRM Setup - Customer Type
export const GET_CUSTOMER_TYPE = `${API}/custtype/select`;

export const CREATE_CUSTOMER_TYPE = `${API}/custtype/create`;

export const UPDATE_CUSTOMER_TYPE = `${API}/custtype/update`;

export const DELETE_CUSTOMER_TYPE = `${API}/custtype/delete`;

//Platform - CRM Setup - Contact Designation
export const ADD_CONTACT_DESIGNATION = `${API}/contactdesignation/create`;

export const GET_CONTACT_DESIGNATION = `${API}/contactdesignation/select`;

export const UPDATE_CONTACT_DESIGNATION = `${API}/contactdesignation/update`;

export const DELETE_CONTACT_DESIGNATION = `${API}/contactdesignation/delete`;

//Platform - CRM Setup - Account Status
export const ADD_ACCOUNT_STATUS = `${API}/custaccstatus/create`;

export const GET_ACCOUNT_STATUS = `${API}/custaccstatus/select`;

export const UPDATE_ACCOUNT_STATUS = `${API}/custaccstatus/update`;

export const DELETE_ACCOUNT_STATUS = `${API}/custaccstatus/delete`;

//Platform - Marketing Setup - Campaign Type
export const ADD_CAMPAIGN_TYPE = `${API}/camptype/create`;

export const GET_CAMPAIGN_TYPE = `${API}/camptype/select`;

export const UPDATE_CAMPAIGN_TYPE = `${API}/camptype/update`;

export const DELETE_CAMPAIGN_TYPE = `${API}/camptype/delete`;

//Platform - Marketing Setup - Campaign Success Criteria
export const ADD_CAMPAIGN_SUCCESS_CRITERIA = `${API}/successcriteria/create`;

export const GET_CAMPAIGN_SUCCESS_CRITERIA = `${API}/successcriteria/select`;

export const UPDATE_CAMPAIGN_SUCCESS_CRITERIA = `${API}/successcriteria/update`;

export const DELETE_CAMPAIGN_SUCCESS_CRITERIA = `${API}/successcriteria/delete`;

//Platform - Marketing Setup - Lead Source
export const ADD_LEAD_SOURCE = `${API}/leadsource/create`;

export const GET_LEAD_SOURCE = `${API}/leadsource/select`;

export const UPDATE_LEAD_SOURCE = `${API}/leadsource/update`;

export const DELETE_LEAD_SOURCE = `${API}/leadsource/delete`;

//Platform - Sales Setup - Prospects Status
export const ADD_PROSPECTS_STATUS = `${API}/prospectsstatus/create`;

export const GET_PROSPECTS_STATUS = `${API}/prospectsstatus/select`;

export const UPDATE_PROSPECTS_STATUS = `${API}/prospectsstatus/update`;

export const DELETE_PROSPECTS_STATUS = `${API}/prospectsstatus/delete`;

//Platform - Sales Setup - Prospects Type
export const ADD_PROSPECTS_TYPE = `${API}/prospectstype/create`;

export const GET_PROSPECTS_TYPE = `${API}/prospectstype/select`;

export const UPDATE_PROSPECTS_TYPE = `${API}/prospectstype/update`;

export const DELETE_PROSPECTS_TYPE = `${API}/prospectstype/delete`;

//Platform - Sales Setup - Sales Activity
export const ADD_SALES_ACTIVITY = `${API}/salesactivitylist/create`;

export const GET_SALES_ACTIVITY = `${API}/salesactivitylist/select`;

export const UPDATE_SALES_ACTIVITY = `${API}/salesactivitylist/update`;

export const DELETE_SALES_ACTIVITY = `${API}/salesactivitylist/delete`;

//Platform - Sales Setup - Sales Activity Status
export const ADD_SALES_ACTIVITY_STATUS = `${API}/salesactivitystatus/create`;

export const GET_SALES_ACTIVITY_STATUS = `${API}/salesactivitystatus/select`;

export const UPDATE_SALES_ACTIVITY_STATUS = `${API}/salesactivitystatus/update`;

export const DELETE_SALES_ACTIVITY_STATUS = `${API}/salesactivitystatus/delete`;

//Platform - Sales Setup - Sales Deal Type
export const ADD_SALES_DEAL_TYPE = `${API}/salesdealtype/create`;

export const GET_SALES_DEAL_TYPE = `${API}/salesdealtype/select`;

export const UPDATE_SALES_DEAL_TYPE = `${API}/salesdealtype/update`;

export const DELETE_SALES_DEAL_TYPE = `${API}/salesdealtype/delete`;

//Platform - Sales Setup - Opportunity Source
export const ADD_SALES_OPPORTUNITY_SOURCE = `${API}/opportunitysource/create`;

export const GET_SALES_OPPORTUNITY_SOURCE = `${API}/opportunitysource/select`;

export const UPDATE_SALES_OPPORTUNITY_SOURCE = `${API}/opportunitysource/update`;

export const DELETE_SALES_OPPORTUNITY_SOURCE = `${API}/opportunitysource/delete`;

//Platform - Sales Setup - Opportunity Stage
export const ADD_SALES_OPPORTUNITY_STAGE = `${API}/oppstage/create`;

export const GET_SALES_OPPORTUNITY_STAGE = `${API}/oppstage/select`;

export const UPDATE_SALES_OPPORTUNITY_STAGE = `${API}/oppstage/update`;

export const DELETE_SALES_OPPORTUNITY_STAGE = `${API}/oppstage/delete`;

//Platform - Sales Setup - Opportunity Type
export const ADD_SALES_OPPORTUNITY_TYPE = `${API}/opptype/create`;

export const GET_SALES_OPPORTUNITY_TYPE = `${API}/opptype/select`;

export const UPDATE_SALES_OPPORTUNITY_TYPE = `${API}/opptype/update`;

export const DELETE_SALES_OPPORTUNITY_TYPE = `${API}/opptype/delete`;

//Platform - Sales Setup - Milestone
export const ADD_SALES_MILESTONE = `${API}/salesgoal/create`;

export const GET_SALES_MILESTONE = `${API}/salesgoal/select`;

export const UPDATE_SALES_MILESTONE = `${API}/salesgoal/update`;

export const DELETE_SALES_MILESTONE = `${API}/salesgoal/delete`;

//Platform - Sales Setup - Milestone Status
export const ADD_SALES_MILESTONE_STATUS = `${API}/salesmilestatus/create`;

export const GET_SALES_MILESTONE_STATUS = `${API}/salesmilestatus/select`;

export const UPDATE_SALES_MILESTONE_STATUS = `${API}/salesmilestatus/update`;

export const DELETE_SALES_MILESTONE_STATUS = `${API}/salesmilestatus/delete`;

//Platform - Sales Setup - Milestone Feedback
export const ADD_SALES_MILESTONE_FEEDBACK = `${API}/salesmilefeedback/create`;

export const GET_SALES_MILESTONE_FEEDBACK = `${API}/salesmilefeedback/select`;

export const UPDATE_SALES_MILESTONE_FEEDBACK = `${API}/salesmilefeedback/update`;

export const DELETE_SALES_MILESTONE_FEEDBACK = `${API}/salesmilefeedback/delete`;

//Platform - Sales Setup - Potential
export const ADD_SALES_POTENTIAL = `${API}/oppprobability/create`;

export const GET_SALES_POTENTIAL = `${API}/oppprobability/select`;

export const UPDATE_SALES_POTENTIAL = `${API}/oppprobability/update`;

export const DELETE_SALES_POTENTIAL = `${API}/oppprobability/delete`;

export const ADD_PLATFORM_QUALIFICATION = `${API}/qualification/create`;

export const GET_PLATFORM_QUALIFICATION = `${API}/qualification/select`;

export const UPDATE_PLATFORM_QUALIFICATION = `${API}/qualification/update`;

export const DELETE_PLATFORM_QUALIFICATION = `${API}/qualification/delete`;

//Platform - Product Setup - Product Status
export const ADD_PRODUCT_STATUS = `${API}/productstatus/create`;

export const GET_PRODUCT_STATUS = `${API}/productstatus/select`;

export const UPDATE_PRODUCT_STATUS = `${API}/productstatus/update`;

export const DELETE_PRODUCT_STATUS = `${API}/productstatus/delete`;

//Platform - Project Setup ---> Project Type
export const GET_PROJECT_TYPE = `${API}/projtype/select`;

export const CREATE_PROJECT_TYPE = `${API}/projtype/create`;

export const UPDATE_PROJECT_TYPE = `${API}/projtype/update`;

export const DELETE_PROJECT_TYPE = `${API}/projtype/delete`;

//Platform - Project Setup ---> Project Status
export const GET_PROJECT_STATUS = `${API}/projectstatus/select`;

export const CREATE_PROJECT_STATUS = `${API}/projectstatus/create`;

export const UPDATE_PROJECT_STATUS = `${API}/projectstatus/update`;

export const DELETE_PROJECT_STATUS = `${API}/projectstatus/delete`;

//Platform - Project Setup ---> Project Task
export const GET_PROJECT_TASKS = `${API}/platformtask/select`;

export const CREATE_PROJECT_TASKS = `${API}/platformtask/create`;

export const UPDATE_PROJECT_TASKS = `${API}/platformtask/update`;

export const DELETE_PROJECT_TASKS = `${API}/platformtask/delete`;

//Platform -Project Setup - Billing Type
export const ADD_BILLING_TYPE = `${API}/projbillingtype/create`;

export const GET_BILLING_TYPE = `${API}/projbillingtype/select`;

export const UPDATE_BILLING_TYPE = `${API}/projbillingtype/update`;

export const DELETE_BILLING_TYPE = `${API}/projbillingtype/delete`;

//Platform - Currency
export const GET_CURRENCY = `${API}/currencymanagement/select`;

export const CREATE_CURRENCY = `${API}/currencymanagement/create`;

export const UPDATE_CURRENCY = `${API}/currencymanagement/update`;

export const DELETE_CURRENCY = `${API}/currencymanagement/delete`;

//Platform - HCM Setup - ManagerResignationReason
export const GET_MANAGER_RESIGNATION_REASON = `${API}/managerreg/select`;

export const CREATE_MANAGER_RESIGNATION_REASON = `${API}/managerreg/create`;

export const UPDATE_MANAGER_RESIGNATION_REASON = `${API}/managerreg/update`;

export const DELETE_MANAGER_RESIGNATION_REASON = `${API}/managerreg/delete`;

//Platform - HCM Setup - ResignationType
export const GET_RESIGNATION_TYPE = `${API}/regtype/select`;

export const CREATE_RESIGNATION_TYPE = `${API}/regtype/create`;

export const UPDATE_RESIGNATION_TYPE = `${API}/regtype/update`;

export const DELETE_RESIGNATION_TYPE = `${API}/regtype/delete`;

//Platform - HCM Setup - ResignationStatus
export const GET_RESIGNATION_STATUS = `${API}/regstatus/select`;

export const CREATE_RESIGNATION_STATUS = `${API}/regstatus/create`;

export const UPDATE_RESIGNATION_STATUS = `${API}/regstatus/update`;

export const DELETE_RESIGNATION_STATUS = `${API}/regstatus/delete`;

//Platform - HCM Setup - ClearanceStatus
export const GET_CLEARANCE_STATUS = `${API}/clearstatus/select`;

export const CREATE_CLEARANCE_STATUS = `${API}/clearstatus/create`;

export const UPDATE_CLEARANCE_STATUS = `${API}/clearstatus/update`;

export const DELETE_CLEARANCE_STATUS = `${API}/clearstatus/delete`;

//Platform - Operations Setup --> Partner Type
export const ADD_PARTNER_TYPE = `${API}/partnertypereg/create`;

export const GET_PARTNER_TYPE = `${API}/partnertypereg/select`;

export const UPDATE_PARTNER_TYPE = `${API}/partnertypereg/update`;

export const DELETE_PARTNER_TYPE = `${API}/partnertypereg/delete`;

//Platform - Operations Setup --> Partner Services
export const ADD_PARTNER_SERVICES = `${API}/partnerserreg/create`;

export const GET_PARTNER_SERVICES = `${API}/partnerserreg/select`;

export const UPDATE_PARTNER_SERVICES = `${API}/partnerserreg/update`;

export const DELETE_PARTNER_SERVICES = `${API}/partnerserreg/delete`;

//Platform - Operations Setup --> Partner Pymt Terms
export const ADD_PARTNER_PYMT_TERMS = `${API}/partnerpymt/create`;

export const GET_PARTNER_PYMT_TERMS = `${API}/partnerpymt/select`;

export const UPDATE_PARTNER_PYMT_TERMS = `${API}/partnerpymt/update`;

export const DELETE_PARTNER_PYMT_TERMS = `${API}/partnerpymt/delete`;

//Platform - Operations Setup --> Vendor Type
export const ADD_VENDOR_TYPE = `${API}/vendortypereg/create`;

export const GET_VENDOR_TYPE = `${API}/vendortypereg/select`;

export const UPDATE_VENDOR_TYPE = `${API}/vendortypereg/update`;

export const DELETE_VENDOR_TYPE = `${API}/vendortypereg/delete`;

//Platform - Operations Setup --> Partner Services
export const ADD_VENDOR_SERVICES = `${API}/vendorserreg/create`;

export const GET_VENDOR_SERVICES = `${API}/vendorserreg/select`;

export const UPDATE_VENDOR_SERVICES = `${API}/vendorserreg/update`;

export const DELETE_VENDOR_SERVICES = `${API}/vendorserreg/delete`;

//Platform - Operations Setup --> Partner Pymt Terms
export const ADD_VENDOR_PYMT_TERMS = `${API}/vendorpymt/create`;

export const GET_VENDOR_PYMT_TERMS = `${API}/vendorpymt/select`;

export const UPDATE_VENDOR_PYMT_TERMS = `${API}/vendorpymt/update`;

export const DELETE_VENDOR_PYMT_TERMS = `${API}/vendorpymt/delete`;

//Platform - Finance Setup --> Claims --> Claim Status
export const ADD_CLAIM_STATUS = `${API}/financeclaimstatus/create`;

export const GET_CLAIM_STATUS = `${API}/financeclaimstatus/select`;

export const UPDATE_CLAIM_STATUS = `${API}/financeclaimstatus/update`;

export const DELETE_CLAIM_STATUS = `${API}/financeclaimstatus/delete`;

//Platform - Finance Setup --> Claims --> Claim Type
export const ADD_CLAIM_TYPE = `${API}/financeclaimtype/create`;

export const GET_CLAIM_TYPE = `${API}/financeclaimtype/select`;

export const UPDATE_CLAIM_TYPE = `${API}/financeclaimtype/update`;

export const DELETE_CLAIM_TYPE = `${API}/financeclaimtype/delete`;

//Platform - Finance Setup --> Claims --> Employee Claim
export const ADD_EMPLOYEE_CLAIM = `${API}/empclaim/create`;

export const GET_EMPLOYEE_CLAIM = `${API}/empclaim/fetch`;

export const SELECT_EMPLOYEE_CLAIM = `${API}/empclaim/select`;

export const UPDATE_EMPLOYEE_CLAIM = `${API}/empclaim/update`;

export const DELETE_EMPLOYEE_CLAIM = `${API}/financeclaimtype/delete`;

// Finance -- Payslip -- Upload Payslip
export const UPLOAD_PAYSLIP = `${API}/financepayslip/create`;

export const GET_PAYSLIP = `${API}/financepayslip/fetch`;

export const DOWNLOAD_PAYSLIP = `${API}/financepayslip/download`;

// Finance -- Form 16 -- Upload Form16
export const UPLOAD_FORM16 = `${API}/financeform16upload/create`;

export const GET_FORM16 = `${API}/financeform16upload/fetch`;

export const DOWNLOAD_FORM16 = `${API}/financeform16upload/download`;

// finance -- Accounts Payable --- Entry Invoice
export const GET_INVOICE = `${API}/financeinvoicepymt/select`;

export const ADD_INVOICE = `${API}/financeinvoicepymt/create`;

export const UPDATE_INVOICE = `${API}/financeinvoicepymt/update`;

export const ADD_RECIEVE_ITEM = `${API}/financereceiveitems/create`;

export const UPDATE_RECIEVE_ITEM = `${API}/financereceiveitems/update`;

export const GET_RECIEVE_ITEM = `${API}/financereceiveitems/fetch`;

export const GET_LATEST_INVOICE = `${API}/financeinvoicepymt/fetch `;

//Platform - Finance Setup --> Accounts Payable --> Issue Po --> PO Type
export const ADD_PO_TYPE = `${API}/financepotype/create`;

export const GET_PO_TYPE = `${API}/financepotype/select`;

export const UPDATE_PO_TYPE = `${API}/financepotype/update`;

export const DELETE_PO_TYPE = `${API}/financepotype/delete`;

//Platform - Finance Setup --> Accounts Payable --> Issue Po --> PO Status
export const ADD_PO_STATUS = `${API}/financeissuepostatus/create`;

export const GET_PO_STATUS = `${API}/financeissuepostatus/select`;

export const UPDATE_PO_STATUS = `${API}/financeissuepostatus/update`;

export const DELETE_PO_STATUS = `${API}/financeissuepostatus/delete`;

//Platform - Finance Setup --> Invoice --> Payment Status
export const ADD_PAYMENT_STATUS = `${API}/financepymtstatus/create`;

export const GET_PAYMENT_STATUS = `${API}/financepymtstatus/select`;

export const UPDATE_PAYMENT_STATUS = `${API}/financepymtstatus/update`;

export const DELETE_PAYMENT_STATUS = `${API}/financepymtstatus/delete`;

//Platform - Finance Setup --> Invoice --> Receivable Status
export const ADD_RECEIVABLE_STATUS = `${API}/financereceivablestatus/create`;

export const GET_RECEIVABLE_STATUS = `${API}/financereceivablestatus/select`;

export const UPDATE_RECEIVABLE_STATUS = `${API}/financereceivablestatus/update`;

export const DELETE_RECEIVABLE_STATUS = `${API}/financereceivablestatus/delete`;

//Platform - Finance Setup --> Invoice --> Invoice Type
export const ADD_INVOICE_TYPE = `${API}/financeinvoicetype/create`;

export const GET_INVOICE_TYPE = `${API}/financeinvoicetype/select`;

export const UPDATE_INVOICE_TYPE = `${API}/financeinvoicetype/update`;

export const DELETE_INVOICE_TYPE = `${API}/financeinvoicetype/delete`;

//Platform - Finance Setup --> Issue PO
export const ADD_ISSUE_PO = `${API}/financeissuepo/create`;

export const GET_ISSUE_PO = `${API}/financeissuepo/select`;

export const UPDATE_ISSUE_PO = `${API}/financeissuepo/update`;

export const DELETE_ISSUE_PO = `${API}/financeinvoicetype/delete`;

export const ADD_PURCHASE_ITEM = `${API}/financepurchaseitems/create`;

export const UPDATE_PURCHASE_ITEM = `${API}/financepurchaseitems/update`;

export const GET_PURCHASE_ITEM = `${API}/financepurchaseitems/fetch`;

export const ADD_SCHEDULED_PYMT_DATE = `${API}/financepymtschedule/create`;

export const UPDATE_SCHEDULED_PYMT_DATE = `${API}/financepymtschedule/update`;

export const GET_SCHEDULED_PYMT_DATE = `${API}/financepymtschedule/fetch`;

export const GET_LATEST_PO = `${API}/financeissuepo/fetch`;

//Platform - Finance Setup --> Accounts receivable  --> PO Entry
export const ADD_PO_ENTRY = `${API}/financeinvoicepoentry/create`;

export const GET_PO_ENTRY = `${API}/financeinvoicepoentry/select`;

export const UPDATE_PO_ENTRY = `${API}/financeinvoicepoentry/update`;

export const DELETE_PO_ENTRY = `${API}/financeinvoicetype/delete`;

export const ADD_PO_ENTRY_PURCHASE_ITEM = `${API}/financeinvoicereceivableitem/create`;

export const UPDATE_PO_ENTRY_PURCHASE_ITEM = `${API}/financeinvoicereceivableitem/update`;

export const GET_PO_ENTRY_PURCHASE_ITEM = `${API}/financeinvoicereceivableitem/fetch`;

export const ADD_PO_ENTRY_SCHEDULED_PYMT_DATE = `${API}/financeinvoicepymtschedule/create`;

export const UPDATE_PO_ENTRY_SCHEDULED_PYMT_DATE = `${API}/financeinvoicepymtschedule/update`;

export const GET_PO_ENTRY_SCHEDULED_PYMT_DATE = `${API}/financeinvoicepymtschedule/fetch`;

export const GET_PO_ENTRY_LATEST_PO = `${API}/financeinvoicepoentry/fetch`;

// finance -- Accounts Receivable--- Sales Invoice
export const GET_SALES_INVOICE = `${API}/financesalesinvoice/select`;

export const ADD_SALES_INVOICE = `${API}/financesalesinvoice/create`;

export const UPDATE_SALES_INVOICE = `${API}/financesalesinvoice/update`;

export const ADD_SALES_INVOICE_RECIEVE_ITEM = `${API}/financesalesreceived/create`;

export const UPDATE_SALES_INVOICE_RECIEVE_ITEM = `${API}/financesalesreceived/update`;

export const GET_SALES_INVOICE_RECIEVE_ITEM = `${API}/financesalesreceived/fetch`;

export const GET_LATEST_SALES_INVOICE = `${API}/financesalesinvoice/fetch1`;

//Platform - Policies
export const UPLOAD_POLICIES = `${API}/policy/upload`;

export const GET_POLICIES = `${API}/policy/select`;

export const UPDATE_POLICIES = `${API}/policy/update`;

export const DELETE_POLICIES = `${API}/policy/delete`;

export const SEARCH_POLICIES = `${API}/policy/search`;

export const DOWNLOAD_POLICIES = `${API}/policy/download`;

// AccessMain menu EndPoint List
export const FETCH_ACCESS_MAIN_ONE = `${API}/accessmain/fetch1`;

export const FETCH_ACCESS_MAIN = `${API}/accessmain/fetch`;

export const CREATE_ACCESS_MAIN = `${API}/accessmain/create`;

export const UPDATE_ACCESS_MAIN = `${API}/accessmain/update`;

export const DELETE_ACCESS_MAIN = `${API}/accessmain/delete`;

// Access EndPoint List
export const UPDATE_ACCESS = `${API}/access/update`;

export const DELETE_ACCESS = `${API}/access/delete`;

export const CREATE_ACCESS = `${API}/access/create`;

// Service Documents EndPoint List
export const UPLOAD_SERVICE_DOCUMENTS = `${API}/servicedocs/upload`;

export const UPDATE_SERVICE_DOCUMENTS = `${API}/servicedocs/update`;

export const DELETE_SERVICE_DOCUMENTS = `${API}/servicedocs/delete`;

export const DOWNLOAD_SERVICE_DOCUMENTS = `${API}/servicedocs/download`;

export const FETCH_SERVICE_DOCUMENTS = `${API}/servicedocs/fetch`;

// Service Assign EndPoint List
export const CREATE_SERVICE_ASSIGN = `${API}/serviceassign/create`;

export const UPDATE_SERVICE_ASSIGN = `${API}/serviceassign/update`;

export const DELETE_SERVICE_ASSIGN = `${API}/serviceassign/delete`;

// Service Task EndPoint List
export const CREATE_SERVICE_TASK = `${API}/servicetask/create`;

export const UPDATE_SERVICE_TASK = `${API}/servicetask/update`;

export const DELETE_SERVICE_TASK = `${API}/serviceTASK/delete`;

export const FETCH_SERVICE_TASK = `${API}/servicetask/fetch`;

// Service MileStone EndPoint List
export const CREATE_SERVICE_MILESTONE = `${API}/servicemiles/create`;

export const UPDATE_SERVICE_MILESTONE = `${API}/servicemiles/update`;

export const DELETE_SERVICE_MILESTONE = `${API}/serviceMILESTONE/delete`;

export const FETCH_SERVICE_MILESTONE = `${API}/servicemiles/fetch`;

// Service Activity EndPoint List
export const CREATE_SERVICE_ACTIVITY = `${API}/serviceactivity/create`;

export const UPDATE_SERVICE_ACTIVITY = `${API}/serviceactivity/update`;

export const DELETE_SERVICE_ACTIVITY = `${API}/serviceACTIVITY/delete`;

export const FETCH_SERVICE_ACTIVITY = `${API}/serviceactivity/fetch`;

// Service Products Documents EndPoint List
export const UPLOAD_PRODUCT_DOCUMENTS = `${API}/productdocs/upload`;

export const UPDATE_PRODUCT_DOCUMENTS = `${API}/productdocs/update`;

export const DOWNLOAD_PRODUCT_DOCUMENTS = `${API}/productdocs/download`;

export const FETCH_PRODUCT_DOCUMENTS = `${API}/productdocs/fetch`;

export const DELETE_PRODUCT_DOCUMENTS = `${API}/productdocs/delete`;

// Product Graph EndPoint List
export const PRODUCT_COUNTS = `${API}/product/count`;

// Project Graph EndPoint List
export const PROJECT_COUNTS = `${API}/project/count`;

// Service Projects Documents EndPoint List
export const UPLOAD_PROJECT_DOCUMENTS = `${API}/PROJECTdocs/upload`;

export const UPDATE_PROJECT_DOCUMENTS = `${API}/projectdocuments/update`;

export const DOWNLOAD_PROJECT_DOCUMENTS = `${API}/projectdocuments/download`;

export const GET_PROJECT_DOCUMENTS = `${API}/projectdocuments/select`;

export const DELETE_PROJECT_DOCUMENTS = `${API}/projectdocuments/delete`;

// Project Task EndPoint List
export const CREATE_PROJECT_TASK = `${API}/projtask/create`;

export const UPDATE_PROJECT_TASK = `${API}/projtask/update`;

export const DELETE_PROJECT_TASK = `${API}/PROJECTTASK/delete`;

export const FETCH_PROJECT_TASK = `${API}/projtask/fetch`;

// Project Assign EndPoint List
export const CREATE_PROJECT_ASSIGN = `${API}/assignment/create`;

export const UPDATE_PROJECT_ASSIGN = `${API}/assignment/update`;

export const DELETE_PROJECT_ASSIGN = `${API}/assignment/delete`;

// Project Activity EndPoint List
export const CREATE_PROJECT_ACTIVITY = `${API}/projactivity/create`;

export const UPDATE_PROJECT_ACTIVITY = `${API}/projactivity/update`;

export const DELETE_PROJECT_ACTIVITY = `${API}/PROJECTACTIVITY/delete`;

export const FETCH_PROJECT_ACTIVITY = `${API}/projactivity/fetch`;

//Opportunity Documents EndPoint List
export const UPLOAD_OPPORTUNITY_DOCUMENTS = `${API}/OpportunityDoc/upload`;

export const UPDATE_OPPORTUNITY_DOCUMENTS = `${API}/OpportunityDoc/update`;

export const DOWNLOAD_OPPORTUNITY_DOCUMENTS = `${API}/OpportunityDoc/download`;

export const GET_OPPORTUNITY_DOCUMENTS = `${API}/OpportunityDoc/select`;

export const DELETE_OPPORTUNITY_DOCUMENTS = `${API}/OpportunityDoc/delete`;

// Opportunity Activity EndPoint List
export const CREATE_OPPORTUNITY_ACTIVITY = `${API}/opportunityactivity/create`;

export const CREATE_OPPORTUNITY_ACTION = `${API}/oppaction/create`;

export const UPDATE_OPPORTUNITY_ACTIVITY = `${API}/opportunityactivity/update`;

export const UPDATE_OPPORTUNITY_ACTION = `${API}/oppaction/update`;

export const DELETE_OPPORTUNITY_ACTIVITY = `${API}/OPPORTUNITYACTIVITY/delete`;

export const FETCH_OPPORTUNITY_ACTIVITY = `${API}/opportunityactivity/fetch`;

export const FETCH_OPPORTUNITY_ACTION = `${API}/oppaction/fetch`;

// Opportunity PqPage EndPoint List
export const CREATE_OPPORTUNITY_CPQ = `${API}/projactivity/create`;

export const UPDATE_OPPORTUNITY_CPQ = `${API}/projactivity/update`;

export const DELETE_OPPORTUNITY_CPQ = `${API}/PROJECTACTIVITY/delete`;

export const FETCH_OPPORTUNITY_CPQ = `${API}/cpq/fetch`;

export const FETCH_OPPORTUNITY_PRODUCT_CPQ = `${API}/pqproduct/fetch`;

export const CREATE_OPPORTUNITY_PRODUCT_CPQ = `${API}/pqproduct/create`;

export const UPDATE_OPPORTUNITY_PRODUCT_CPQ = `${API}/pqproduct/update`;

export const DELETE_OPPORTUNITY_PRODUCT_CPQ = `${API}/pqproduct/delete`;

export const FETCH_OPPORTUNITY_PROJECT_CPQ = `${API}/pqproject/fetch`;

export const CREATE_OPPORTUNITY_PROJECT_CPQ = `${API}/pqproject/create`;

export const UPDATE_OPPORTUNITY_PROJECT_CPQ = `${API}/pqproject/update`;

export const DELETE_OPPORTUNITY_PROJECT_CPQ = `${API}/pqproject/delete`;

export const FETCH_OPPORTUNITY_SERVICE_CPQ = `${API}/pqservice/fetch`;

export const CREATE_OPPORTUNITY_SERVICE_CPQ = `${API}/pqservice/create`;

export const UPDATE_OPPORTUNITY_SERVICE_CPQ = `${API}/pqservice/update`;

export const DELETE_OPPORTUNITY_SERVICE_CPQ = `${API}/pqservice/delete`;

export const OPPORTUNITY_PIPELINE = `${API}/Opportunity/opppipeline`;

export const OPPORTUNITY_ORDERS = `${API}/order/opporders`;

export const ORDERS_BUSINESS = `${API}/order/orderbusiness`;

export const ORDERS_LEAD_SOURCE = `${API}/order/orderlead`;

export const OPPORTUNITY_PROSPECT_LEAD = `${API}/Opportunity/prospectlead`;

export const ORDER_SALES_COMPARISION = `${API}/order/salescomparision`;

export const ORDER_SALES_AVERAGE = `${API}/order/salesaverage`;

export const ORDER_SALES_GROWTH = `${API}/order/salesgrowth`;

export const ORDER_CUST = `${API}/order/ordercust`;

export const ORDER_LEAD_SOURCE = `${API}/order/orderleadsource`;

export const ORDER_AMOUNT = `${API}/order/orderamount`;

export const SERVICE_COUNT = `${API}/service/count`;

//Orders Documents EndPoint List
export const UPLOAD_ORDER_DOCUMENTS = `${API}/orderdoc/upload`;

export const UPDATE_ORDER_DOCUMENTS = `${API}/orderdoc/update`;

export const DOWNLOAD_ORDER_DOCUMENTS = `${API}/orderdoc/download`;

export const FETCH_ORDER_DOCUMENTS = `${API}/orderdoc/fetch`;

export const DELETE_ORDER_DOCUMENTS = `${API}/orderdoc/delete`;

// Order PqPage EndPoint List
export const CREATE_ORDER_CPQ = `${API}/projactivity/create`;

export const UPDATE_ORDER_CPQ = `${API}/projactivity/update`;

export const DELETE_ORDER_CPQ = `${API}/PROJECTACTIVITY/delete`;

export const FETCH_ORDER_CPQ = `${API}/orderpricing/fetch`;

export const FETCH_ORDER_PRODUCT_CPQ = `${API}/productorder/fetch`;

export const CREATE_ORDER_PRODUCT_CPQ = `${API}/productorder/create`;

export const UPDATE_ORDER_PRODUCT_CPQ = `${API}/productorder/update`;

export const DELETE_ORDER_PRODUCT_CPQ = `${API}/productorder/delete`;

export const FETCH_ORDER_PROJECT_CPQ = `${API}/projectorder/fetch`;

export const CREATE_ORDER_PROJECT_CPQ = `${API}/projectorder/create`;

export const UPDATE_ORDER_PROJECT_CPQ = `${API}/projectorder/update`;

export const DELETE_ORDER_PROJECT_CPQ = `${API}/projectorder/delete`;

export const FETCH_ORDER_SERVICE_CPQ = `${API}/serviceorder/fetch`;

export const CREATE_ORDER_SERVICE_CPQ = `${API}/serviceorder/create`;

export const UPDATE_ORDER_SERVICE_CPQ = `${API}/serviceorder/update`;

export const DELETE_ORDER_SERVICE_CPQ = `${API}/serviceorder/delete`;

export const ORDER_DEAL_VALUE = `${API}/order/dealvalue`;

// Prospect Activity EndPoint List
export const CREATE_PROSPECT_ACTIVITY = `${API}/prosactivity/create`;

export const CREATE_PROSPECT_ACTION = `${API}/oppaction/create`;

export const UPDATE_PROSPECT_ACTIVITY = `${API}/prosactivity/update`;

export const UPDATE_PROSPECT_ACTION = `${API}/oppaction/update`;

export const DELETE_PROSPECT_ACTIVITY = `${API}/PROSPECTACTIVITY/delete`;

export const FETCH_PROSPECT_ACTIVITY = `${API}/prosactivity/fetch`;

export const FETCH_PROSPECT_ACTION = `${API}/oppaction/fetch`;

// Other routes
export const CREATE_CUSTTYPE = `${API}/custtype`;

export const TIME_SHEET_EXCEL = `${API}/timesheet/excel`;

export const CANDIDATE_EXCEL = `${API}/candidate/excel`;

export const FETCH_TIME_MANAGEMENT = `${API}/timemanagement/fetch`;

export const SEARCH_TIME_MANAGEMENT = `${API}/timemanagement/search`;

// -----------------------------------------------------------------------------------
// IAM --> EMP ONBOARDING END POINTS
export const CURD_EMP_ONBOARDING = `${IAM_API}/v1/api/pie-user-iam/pie-user`;
export const GET_EMP_ONBOARDING = `${IAM_API}/v1/api/pie-user-iam/pie-user`;
export const SEARCH_EMP_ONBOARDING = `${IAM_API}/v1/api/pie-user-iam/search-onboarding`;

// IAM --> CREATE ACCOUNT END POINTS
export const CURD_CREATE_ACCOUNT = `${IAM_API}/v1/api/pie-user-iam/create-account`;

// --------------------------------------------------------------------------------------
// HCM --> Configurations --> BusinessCountries
export const CURD_BUSINESS_COUNTRIES = `${HCM_API}/v1/api/hcm/businesscountries`;
export const USER_BUSINESS_COUNTRIES = `${HCM_API}/v1/api/hcm/businesscountries/select`;

// HCM --> Configurations --> BusinessGeowise
export const CURD_BUSINESS_GEOWISE = `${HCM_API}/v1/api/hcm/businessgeographies`;
export const USER_BUSINESS_GEOWISE = `${HCM_API}/v1/api/hcm/businessgeographies/select`;

// HCM --> Configurations --> BusinessLocations
export const CURD_BUSINESS_LOCATIONS = `${HCM_API}/v1/api/hcm/businesslocations`;
export const USER_BUSINESS_LOCATIONS = `${HCM_API}/v1/api/hcm/businesslocations/select`;

// HCM --> Configurations --> BusinessUnit
export const CURD_BUSINESS_UNIT = `${HCM_API}/v1/api/hcm/businessunit`;
export const USER_BUSINESS_UNIT = `${HCM_API}/v1/api/hcm/businessunit/select`;

// HCM --> Configurations --> EmpDependents
export const CURD_EMP_DEPENDENTS = `${HCM_API}/v1/api/hcm/empdependents`;
export const FETCH_EMP_DEPENDENTS = `${HCM_API}/v1/api/hcm/empdependents/fetch`;
export const USER_EMP_DEPENDENTS = `${HCM_API}/v1/api/hcm/departments/select`;

// HCM --> Configurations --> EmpDesignation
export const CURD_EMP_DESIGNATION = `${HCM_API}/v1/api/hcm/employeedesignation`;

// HCM --> Configurations --> EmpPosition
export const CURD_EMP_POSITION = `${HCM_API}/v1/api/hcm/employeeposition`;
export const USER_EMP_POSITION = `${HCM_API}/v1/api/hcm/employeeposition/select`;
export const GET_EMP_POSITION_GRADE = `${HCM_API}/v1/api/hcm/employeeposition/gradelevel`;

// HCM --> Configurations --> EmpGrade
export const CURD_EMP_GRADE = `${HCM_API}/v1/api/hcm/employeegrade`;
export const USER_EMP_GRADE = `${HCM_API}/v1/api/hcm/employeegrade/select`;

// HCM --> Configurations --> EmpLeaveType
export const CURD_LEAVE_TYPE = `${HCM_API}/v1/api/hcm/leave-type`;
export const USER_LEAVE_TYPE = `${HCM_API}/v1/api/hcm/leave-type/select`;

// HCM --> Configurations --> EmpBenifits
export const CURD_EMP_BENIFITS = `${HCM_API}/v1/api/hcm/employeebenifits`;

// HCM --> Configurations --> EmpDocumentName
export const CURD_EMP_DOCUMENT_NAME = `${HCM_API}/v1/api/hcm/employeedocumentname`;
export const USER_EMP_DOCUMENT_NAME = `${HCM_API}/v1/api/hcm/employeedocumentname/select`;

// HCM --> Configurations --> EmpRevokeReason
export const CURD_REVOKE_REASON = `${HCM_API}/v1/api/hcm/employeerevokereason`;
export const USER_REVOKE_REASON = `${HCM_API}/v1/api/hcm/employeerevokereason/select`;

// HCM --> Configurations --> EmpNominee
export const CURD_EMP_NOMINEE = `${HCM_API}/v1/api/hcm/empnominee`;
export const FETCH_EMP_NOMINEE = `${HCM_API}/v1/api/hcm/empnominee/fetch`;

// HCM --> Configurations --> EmpOrganization
export const CURD_EMP_ORGANIZATION = `${HCM_API}/v1/api/hcm/employeeorganization`;
export const USER_EMP_ORGANIZATION = `${HCM_API}/v1/api/hcm/organization/select`;

export const FETCH_EMP_ORGANIZATION = `${HCM_API}/v1/api/hcm/employeeorganization/fetch`;

// HCM --> Configurations --> EmpProfessionalDocs
export const CURD_EMP_PROFESSIONAL_DOCS = `${HCM_API}/v1/api/hcm/empprofessionaldocs`;

// HCM --> Configurations --> EmpProfessionalInfo
export const CURD_EMP_PROFESSIONAL_INFO = `${HCM_API}/v1/api/hcm/empprofessionalinfo`;

export const FETCH_EMP_PROFESSIONAL_INFO = `${HCM_API}/v1/api/hcm/empprofessionalinfo/fetch`;

// HCM --> Configurations --> EmpPersonalInfo
export const CURD_EMP_PERSONAL_INFO = `${HCM_API}/v1/api/hcm/employeepersonal`;

export const FETCH_EMP_PERSONAL_INFO = `${HCM_API}/v1/api/hcm/employeepersonal/fetch`;

export const SEARCH_EMP_PERSONAL_INFO = `${HCM_API}/v1/api/hcm/employeepersonal/personalsearch`;

export const UPLOAD_EMP_PERSONAL_INFO = `${HCM_API}/v1/api/hcm/hcm-pie-user-batch/pie-user-batch`;

// HCM --> Configurations --> EmpQualification
export const CURD_EMP_QUALIFICATION = `${HCM_API}/v1/api/hcm/employeequalificaton`;
export const USER_EMP_QUALIFICATION = `${HCM_API}/v1/api/hcm/employeequalificaton/select`;

// HCM --> Configurations --> EmpResignationReason
export const CURD_RESIGNATION_REASON = `${HCM_API}/v1/api/hcm/employeeresignationreason`;
export const USER_RESIGNATION_REASON = `${HCM_API}/v1/api/hcm/employeeresignationreason/select`;

// HCM --> Configurations --> EmpResignationStatus
export const CURD_RESIGNATION_STATUS = `${HCM_API}/v1/api/hcm/employeeresignationstatus`;
export const USER_RESIGNATION_STATUS = `${HCM_API}/v1/api/hcm/employeeresignationstatus/select`;

// HCM --> Configurations --> EmpType
export const CURD_EMP_TYPE = `${HCM_API}/v1/api/hcm/employeetype`;
export const USER_EMP_TYPE = `${HCM_API}/v1/api/hcm/employeetype/select`;

// HCM --> Configurations --> ClearanceStatus
export const CURD_CLEARANCE_STATUS = `${HCM_API}/v1/api/hcm/clearancestatus`;
export const USER_CLEARANCE_STATUS = `${HCM_API}/v1/api/hcm/clearancestatus/select`;

// HCM --> Configurations --> HrPolicies
export const CURD_HR_POLICIES = `${HCM_API}/v1/api/hcm/hrpolicies`;
export const SEARCH_HR_POLICIES = `${HCM_API}/v1/api/hcm/hrpolicies/search`;

// HCM --> Configurations --> LegalEntities
export const CURD_ORGANIZATION = `${HCM_API}/v1/api/hcm/organization`;
export const USER_ORGANIZATION = `${HCM_API}/v1/api/hcm/organization/select`;

// HCM --> Configurations --> ManagerResignationReason
export const CURD_MANAGER_RESIGNATION_REASON = `${HCM_API}/v1/api/hcm/managerresignationreason`;
export const USER_MANAGER_RESIGNATION_REASON = `${HCM_API}/v1/api/hcm/managerresignationreason/select`;

// HCM --> Configurations --> ResignationType
export const CURD_RESIGNATION_TYPE = `${HCM_API}/v1/api/hcm/resignationtype`;
export const USER_RESIGNATION_TYPE = `${HCM_API}/v1/api/hcm/resignationtype/select`;

// HCM --> Configurations --> Company Holidays
export const CURD_COMPANY_HOLIDAYS = `${HCM_API}/v1/api/hcm/companyholidays`;
export const USER_COMPANY_HOLIDAYS = `${HCM_API}/v1/api/hcm/companyholidays/select`;

// HCM --> Configurations --> EmpOrganizationName
export const CURD_ENTERPRISE_DETAIL = `${HCM_API}/v1/api/hcm/enterprise`;
export const USER_ENTERPRISE_DETAIL = `${HCM_API}/v1/api/hcm/enterprise/select`;

// HCM --> Configurations --> DialingCode
export const CURD_DIALING_CODE = `${HCM_API}/v1/api/hcm/dialingcode`;

// HCM --> Configurations --> Currency Management
export const CURD_BUSINESS_CURRENCY = `${HCM_API}/v1/api/hcm/currencymanagement`;
export const USER_BUSINESS_CURRENCY = `${HCM_API}/v1/api/hcm/currencymanagement/select`;

// HCM --> Configurations --> EmpStatusReason
export const CURD_EMP_STATUS_REASON = `${HCM_API}/v1/api/hcm/employeestatusreason`;
export const USER_EMP_STATUS_REASON = `${HCM_API}/v1/api/hcm/employeestatusreason/select`;

// HCM --> Configurations --> Departments
export const CURD_DEPARTMENTS = `${HCM_API}/v1/api/hcm/departments`;
export const USER_DEPARTMENTS = `${HCM_API}/v1/api/hcm/departments/select`;

// HCM --> Configurations --> Personal Status
export const CURD_PERSONAL_STATUS = `${HCM_API}/v1/api/hcm/personal-status`;
export const USER_PERSONAL_STATUS = `${HCM_API}/v1/api/hcm/personal-status/select`;

// HCM --> Configurations --> HCM Global
export const CURD_HCM_GLOBAL_VAR = `${HCM_API}/v1/api/hcm/hcm-global-var`;
export const USER_HCM_GLOBAL_VAR = `${HCM_API}/v1/api/hcm/hcm-global-var/select`;

// HCM --> Configurations --> HCM User
export const CURD_HCM_USER_VAR = `${HCM_API}/v1/api/hcm/hcm-user-var`;
export const USER_HCM_USER_VAR = `${HCM_API}/v1/api/hcm/hcm-user-var/select`;

// HCM --> MyProfile --> CertificationDetails
export const CURD_CERTIFICATION_DETAILS = `${HCM_API}/v1/api/hcm/certificationdetails`;
export const FETCH_CERTIFICATION_DETAILS = `${HCM_API}/v1/api/hcm/certificationdetails/fetch`;

// HCM --> MyProfile --> EducationDetails
export const CURD_EDUCATION_DETAILS = `${HCM_API}/v1/api/hcm/educationdetails`;
export const FETCH_EDUCATION_DETAILS = `${HCM_API}/v1/api/hcm/educationdetails/fetch`;

// HCM --> MyProfile --> EmpAwards
export const CURD_EMP_AWARDS = `${HCM_API}/v1/api/hcm/honorsawards`;

// HCM --> MyProfile --> Documents
export const CURD_DOCUMENTS = `${HCM_API}/v1/api/hcm/documents`;

// HCM --> MyProfile --> EmpRelation
export const CURD_EMP_RELATION = `${HCM_API}/v1/api/hcm/empcontactrelation`;
export const USER_EMP_RELATION = `${HCM_API}/v1/api/hcm/empcontactrelation/select`;

// HCM --> MyProfile --> Contacts Address
export const CURD_CONTACTS_ADDRESS = `${HCM_API}/v1/api/hcm/contactsaddress`;

export const FETCH_CONTACTS_ADDRESS = `${HCM_API}/v1/api/hcm/contactsaddress`;

// HCM --> MyProfile --> Contacts
export const CURD_CONTACTS = `${HCM_API}/v1/api/hcm/contacts`;

// HCM --> MyProfile --> Contacts Emergency Details
export const CURD_CONTACTS_EMERGENCY_DETAILS = `${HCM_API}/v1/api/hcm/contactsemergencydetails`;

// HCM --> MyProfile --> Contacts Family Details
export const CURD_CONTACTS_FAMILY_DETAILS = `${HCM_API}/v1/api/hcm/contactsfamilydetails`;

// HCM --> MyProfile --> Accounts Details
export const CURD_BANK_ACCOUNTS_INFO = `${HCM_API}/v1/api/hcm/empbankaccountinfo`;
export const CURD_OTHER_ACCOUNTS_INFO = `${HCM_API}/v1/api/hcm/empotheraccountinfo`;

// HCM --> MyProfile --> Identification Info
export const CURD_PASSPORT_DETAILS = `${HCM_API}/v1/api/hcm/emppassportdetails`;
export const CURD_OTHER_IDENTIFIERS = `${HCM_API}/v1/api/hcm/empotheridentifiers`;

export const TA_EMP_DETAIL = `${HCM_API}/v1/api/hcm/employeemanagement/fetchactiveemployees`;

// HCM --> HR --> Attendance
export const CURD_ATTENDANCE = `${HCM_API}/v1/api/hcm/empattendance`;
export const FETCH_ATTENDANCE = `${HCM_API}/v1/api/hcm/empattendance/fetch`;

// HCM --> HR --> Emp Management
export const CURD_EMP_MANAGEMENT = `${HCM_API}/v1/api/hcm/employeemanagement`;
export const SEARCH_EMP_MANAGEMENT = `${HCM_API}/v1/api/hcm/employeemanagement/employeesearch`;
export const FETCH_ACTIVE_EMPLOYEES = `${HCM_API}/v1/api/hcm/employeemanagement/fetchactiveemployees`;
export const FETCH_EMP_DETAILS = `${HCM_API}/v1/api/hcm/employeemanagement/fetchtotalemployeedata`;

// HCM --> LMS
export const CURD_MY_ATTENDANCE = `${HCM_API}/v1/api/hcm/empattendance`;
export const FETCH_MY_ATTENDANCE = `${HCM_API}/v1/api/hcm/empattendance/fetch`;
export const SEARCH_MY_ATTENDANCE = `${HCM_API}/v1/api/hcm/empattendance/search`;

// HCM --> LMS
export const CURD_LEAVE_MANAGEMENT = `${HCM_API}/v1/api/hcm/leave-transaction`;
export const FETCH_LEAVE_MANAGEMENT = `${HCM_API}/v1/api/hcm/leave-management/fetch`;
export const SEARCH_LEAVE_MANAGEMENT = `${HCM_API}/v1/api/hcm/leave-management/search`;

// HCM --> Resignation --> EmpInitiateResignation
export const CURD_EMP_INITIATE_RESIGNATION = `${HCM_API}/v1/api/hcm/employeeinitiateresignation`;

// HCM --> Resignation --> ManagerInitiateResignation
export const CURD_MANAGER_INITIATE_RESIGNATION = `${HCM_API}/v1/api/hcm/managerinitiateresignation`;

// HCM --> Resignation --> Exit Clearance
export const CURD_EXIT_CLEARANCE = `${HCM_API}/v1/api/hcm/exitclearance`;
export const FETCH_EXIT_CLEARANCE = `${HCM_API}/v1/api/hcm/exitclearance/fetch`;

//RS --> configuration -->skill group

export const ADD_SKILL_GROUP = `${RS_API}/v1/api/skill-group`;
export const GET_SKILL_GROUP = `${RS_API}/v1/api/skill-group/rs`;
export const UPDATE_SKILL_GROUP = `${RS_API}/v1/api/skill-group`;
export const DELETE_SKILL_GROUP = `${RS_API}/v1/api/skill-group`;

//RS --> configuration -->EXtraCurricularActivity

export const ADD_EXTRA = `${RS_API}/v1/api/can-ext-cur-type`;
export const GET_Extra = `${RS_API}/v1/api/can-ext-cur-type`;
export const UPDATE_Extra = `${RS_API}/v1/api/can-ext-cur-type`;
export const DELETE_EXTRA = `${RS_API}/v1/api/can-ext-cur-type`;

//RS->insights graphs
export const INSIGHTS_RS_API = `${RS_API}/v1/api/candidate-tracking/fetch-past-five`;

//RS --> configuration -->Documents

export const ADD_DOCUMENT = `${RS_API}/v1/api/can-upload-document`;
export const GET_DOCUMENT = `${RS_API}/v1/api/can-upload-document/rs`;
export const UPDATE_DOCUMENT = `${RS_API}/v1/api/can-upload-document`;
export const DELETE_DOCUMENT = `${RS_API}/v1/api/can-upload-document`;

export const ADD_QUALIFICATION = `${RS_API}/v1/api/can-qualification`;
export const GET_QUALIFICATION = `${RS_API}/v1/api/can-qualification/rs`;
export const GET_QUALIFICATION_MAIN_API = `${RS_API}/v1/api/can-qualification/select`;
export const UPDATE_QUALIFICATION = `${RS_API}/v1/api/can-qualification`;
export const DELETE_QUALIFICATION = `${RS_API}/v1/api/can-qualification`;

export const ADD_BILLING_RS = `${RS_API}/v1/api/billing-type`;
export const GET_BILLING_RS = `${RS_API}/v1/api/billing-type`;
export const GET_BILLING_MAIN_API = `${RS_API}/v1/api/billing-type/select`;
export const UPDATE_BILLING_RS = `${RS_API}/v1/api/billing-type`;
export const DELETE_BILLING_RS = `${RS_API}/v1/api/billing-type`;

export const ADD_HIRING_RS = `${RS_API}/v1/api/hiring-status`;

export const GET_HIRING_RS = `${RS_API}/v1/api/hiring-status`;
export const GET_HIRING_RS_SELECT = `${RS_API}/v1/api/hiring-status/select`;
export const UPDATE_HIRING_RS = `${RS_API}/v1/api/hiring-status`;
export const DELETE_HIRING_RS = `${RS_API}/v1/api/hiring-status`;

export const ADD_REQSTATUS_RS = `${RS_API}/v1/api/requirement-status`;

export const GET_REQSTATUS_RS = `${RS_API}/v1/api/requirement-status`;
export const GET_REQSTATUS_MAIN = `${RS_API}/v1/api/requirement-status/select`;
export const UPDATE_REQSTATUS_RS = `${RS_API}/v1/api/requirement-status`;
export const DELETE_REQSTATUS_RS = `${RS_API}/v1/api/requirement-status`;

export const ADD_YEARSEXP_RS = `${RS_API}/v1/api/years-of-experience`;
export const GET_YEARSEXP_RS = `${RS_API}/v1/api/years-of-experience`;
export const UPDATE_YEARSEXP_RS = `${RS_API}/v1/api/years-of-experience`;
export const DELETE_YEARSEXP_RS = `${RS_API}/v1/api/years-of-experience`;

export const ADD_HIRING_STAGE_RS = `${RS_API}/v1/api/hiring-stage`;
export const GET_HIRING_STAGE_RS = `${RS_API}/v1/api/hiring-stage`;
export const UPDATE_HIRING_STAGE_RS = `${RS_API}/v1/api/hiring-stage`;
export const DELETE_HIRING_STAGE_RS = `${RS_API}/v1/api/hiring-stage`;

export const ADD_JOB_BOARD_RS = `${RS_API}/v1/api/job-board`;
export const GET_JOB_BOARD_RS = `${RS_API}/v1/api/job-board`;
export const UPDATE_JOB_BOARD_RS = `${RS_API}/v1/api/job-board`;
export const DELETE_JOB_BOARD_RS = `${RS_API}/v1/api/job-board`;

export const ADD_CAN_STATUS_RS = `${RS_API}/v1/api/candidate-status`;
export const GET_CAN_STATUS_RS = `${RS_API}/v1/api/candidate-status`;
export const GET_CAN_STATUS_MAIN = `${RS_API}/v1/api/candidate-status/select`;
export const UPDATE_CAN_STATUS_RS = `${RS_API}/v1/api/candidate-status`;
export const DELETE_CAN_STATUS_RS = `${RS_API}/v1/api/candidate-status`;

export const ADD_CAN_SKILL_RS = `${RS_API}/v1/api/skill-detail`;
export const GET_CAN_SKILL_RS = `${RS_API}/v1/api/skill-detail`;
export const GET_CAN_SKILL_RS_SELECT = `${RS_API}/v1/api/skill-detail/select`;
export const GET_CAN_SKILL_RS_SELECT_ALL_API = `${RS_API}/v1/api/skill-detail/allskills`;
export const UPDATE_CAN_SKILL_RS = `${RS_API}/v1/api/skill-detail`;
export const DELETE_CAN_SKILL_RS = `${RS_API}/v1/api/skill-detail`;

export const ADD_REQTYPE_RS = `${RS_API}/v1/api/requirement-type`;
export const GET_REQTYPE_RS = `${RS_API}/v1/api/requirement-type`;
export const GET_REQTYPE_MAIN_API = `${RS_API}/v1/api/requirement-type/select`;
export const UPDATE_REQTYPE_RS = `${RS_API}/v1/api/requirement-type`;
export const DELETE_REQTYPE_RS = `${RS_API}/v1/api/requirement-type`;

export const ADD_JOB_CATEGORY_RS = `${RS_API}/v1/api/job-category`;
export const GET_JOB_CATEGORY_RS = `${RS_API}/v1/api/job-category`;
export const GET_JOB_CATEGORY_MAIN_API = `${RS_API}/v1/api/job-category/select`;
export const UPDATE_JOB_CATEGORY_RS = `${RS_API}/v1/api/job-category`;
export const DELETE_JOB_CATEGORY_RS = `${RS_API}/v1/api/job-category`;

export const ADD_NOTICE_PERIOD_RS = `${RS_API}/v1/api/notice-period`;
export const GET_NOTICE_PERIOD_RS = `${RS_API}/v1/api/notice-period/rs`;
export const GET_NOTICE_PERIOD_MAIN_API = `${RS_API}/v1/api/notice-period/select`;
export const UPDATE_NOTICE_PERIOD_RS = `${RS_API}/v1/api/notice-period`;
export const DELETE_NOTICE_PERIOD_RS = `${RS_API}/v1/api/notice-period`;

export const ADD_USER_VARIABLE_RS = `${RS_API}/v1/api/rs-user-var`;
export const GET_USER_VARIABLE_RS = `${RS_API}/v1/api/rs-user-var`;
export const UPDATE_USER_VARIABLE_RS = `${RS_API}/v1/api/rs-user-var`;
export const DELETE_USER_VARIABLE_RS = `${RS_API}/v1/api/rs-user-var`;

export const ADD_GLOBAL_VARIABLE_RS = `${RS_API}/v1/api/rs-global-var`;
export const GET_GLOBAL_VARIABLE_RS = `${RS_API}/v1/api/rs-global-var`;
export const UPDATE_GLOBAL_VARIABLE_RS = `${RS_API}/v1/api/rs-global-var`;
export const DELETE_GLOBAL_VARIABLE_RS = `${RS_API}/v1/api/rs-global-var`;

export const ADD_JOB_POSITION_RS = `${RS_API}/v1/api/job-position`;
export const GET_JOB_POSITION_RS = `${RS_API}/v1/api/job-position`;
export const GET_JOB_POSITION_MAIN_API = `${RS_API}/v1/api/job-position/select`;
export const UPDATE_JOB_POSITION_RS = `${RS_API}/v1/api/job-position`;
export const DELETE_JOB_POSITION_RS = `${RS_API}/v1/api/job-position`;

export const ADD_HR_LEAD_RS = `${RS_API}/v1/api/lead-recuriter`;
export const GET_HR_LEAD_RS = `${RS_API}/v1/api/lead-recuriter`;
export const GET_HR_LEAD_MAIN_API = `${RS_API}/v1/api/lead-recuriter/select`;
export const UPDATE_HR_LEAD_RS = `${RS_API}/v1/api/lead-recuriter`;
export const DELETE_HR_LEAD_RS = `${RS_API}/v1/api/lead-recuriter`;

export const ADD_CANDIDATE_COUNTRIES = `${RS_API}/v1/api/can-country`;
export const GET_CANDIDATE_COUNTRIES = `${RS_API}/v1/api/can-country`;
export const UPDATE_CANDIDATE_COUNTRIES = `${RS_API}/v1/api/can-country`;
export const DELETE_CANDIDATE_COUTRIES = `${RS_API}/v1/api/can-country`;

export const ADD_CANDIDATE_GEO = `${RS_API}/v1/api/can-geo`;
export const GET_CANDIDATE_GEO = `${RS_API}/v1/api/can-geo`;
export const UPDATE_CANDIDATE_GEO = `${RS_API}/v1/api/can-geo`;
export const DELETE_CANDIDATE_GEO = `${RS_API}/v1/api/can-geo`;

export const ADD_CANDIDATE_GRADE = `${RS_API}/v1/api/can-qualification-grade`;
export const GET_CANDIDATE_GRADE = `${RS_API}/v1/api/can-qualification-grade/rs`;
export const UPDATE_CANDIDATE_GRADE = `${RS_API}/v1/api/can-qualification-grade`;
export const DELETE_CANDIDATE_GRADE = `${RS_API}/v1/api/can-qualification-grade`;

export const ADD_CANDIDATE_GOVID = `${RS_API}/v1/api/can-gov-id-name`;
export const GET_CANDIDATE_GOVID = `${RS_API}/v1/api/can-gov-id-name/rs`;
export const GET_CANDIDATE_SELECT_GOVID = `${RS_API}/v1/api/can-gov-id-name/select`;
export const UPDATE_CANDIDATE_GOVID = `${RS_API}/v1/api/can-gov-id-name`;
export const DELETE_CANDIDATE_GOVID = `${RS_API}/v1/api/can-gov-id-name`;

export const ADD_JOB_TIMING_API = `${RS_API}/v1/api/job-timing`;
export const GET_JOB_TIMING_API = `${RS_API}/v1/api/job-timing`;
export const UPDATE_JOB_TIMING_API = `${RS_API}/v1/api/job-timing`;
export const DELETE_JOB_TIMING_API = `${RS_API}/v1/api/job-timing`;

export const VIEWRESUME_CANDI = `${RS_API}/v1/api/view-resume`;
export const SEARCH_CANDI = `${CAN_API}/v1/api/search`;
export const TAG_SEARCH_CANDI = `${CAN_API}/v1/api/candidate-search`;
export const SEARCH_REQUIREMENT = `${RS_API}/v1/api/resourcerequirement-search`;
export const SEARCH_WITH_ID = `${RS_API}/v1/api/job-requirement`;
export const SEARCH_JOBS_API = `${RS_API}/v1/api/job-search`;

export const CANDIDATE_RESUME_UPLOAD_RS = `${RS_API}/v1/api/create-pie-candidate`;
export const RS_CANDI_CREATION_ADD = `${RS_API}/v1/api/create-pie-candidate/two`;
export const RS_CANDI_CREATION_ADD_THREE = `${RS_API}/v1/api/create-pie-candidate/three`;
export const CANDIDATE_LATEST_VIEW = `${CAN_API}/v1/api/search`;
export const CANDIDATE_FULL_VIEW = `${CAN_API}/v1/api/search/get-can-detail `;
// RS CONFIG skill upload
export const SKILL_UPLOAD_RS = `${RS_API}/v1/api/batch-upload/skill-upload`;

// RS --->Requirement
export const RsRequirements = `${RS_API}/v1/api/job-requirement`;
export const FETCHBYID = `${RS_API}/v1/api/job-requirement`;
export const FETCH_REQ_TYPE = `${RS_API}/v1/api/requirement-type`;
export const FETCH_BILLING_RS = `${RS_API}/v1/api/billing-type`;
export const FETCH_CATEGORY_RS = `${RS_API}/v1/api/job-category`;
export const FETCH_POSITION_RS = `${RS_API}/v1/api/job-position`;
export const ADD_RS_CANDIDATE = `${RS_API}/v1/api/create-pie-candidate`;

//apply jobs candidate
export const ADD_CAN_APPLY_JOBS = `${RS_API}/v1/api/candidate-tracking`;
export const GET_CAN_APPLY_JOBS = `${RS_API}/v1/api/candidate-tracking`;
export const UPDATE_CAN_APPLY_JOBS = `${RS_API}/v1/api/candidate-tracking`;
export const DELETE_CAN_APPLY_JOBS = `${RS_API}/v1/api/candidate-tracking`;
export const GET_CANDIDATE_KANBAN = `${RS_API}/v1/api/candidate-tracking/rs`;
export const GET_CANDIDATE_COMMENTS_API = `${RS_API}/v1/api/hiring-stage-tracking/rs`;
export const GET_CANDIDATE_STAGE_TRACKING_API = `${RS_API}/v1/api/hiring-stage-tracking/hire`;
//apply jobs rs
export const ADD_APPLY_JOBS = `${RS_API}/v1/api/candidate-tracking`;
export const GET_APPLY_JOBS = `${RS_API}/v1/api/candidate-tracking`;
export const UPDATE_APPLY_JOBS = `${RS_API}/v1/api/candidate-tracking`;
export const DELETE_APPLY_JOBS = `${RS_API}/v1/api/candidate-tracking`;

//assigned HR
export const ADD_ASSIGNED_HR_API = `${RS_API}/v1/api/req-assignment`;
export const VIEW_ASSIGNED_HR_API = `${RS_API}/v1/api/req-assignment`;
export const UPDATE_ASSIGNED_HR_API = `${RS_API}/v1/api/req-assignment`;
export const DELETE_ASSIGNED_HR_API = `${RS_API}/v1/api/req-assignment`;

export const GET_TAGGED_DETAILS = `${RS_API}/v1/api/apply-job`;
export const GET_CAN_TAGGED_DETAILS = `${RS_API}/v1/api/apply-job/requirements`;
export const SEARCH_REQID_CAN = `${RS_API}/v1/api/job-requirement`;
// export const GET_MATCHED_CANDI = `${RS_API}/v1/api/candidate-tag-search`;
export const GET_MATCHED_CANDI = `${MATCH_CAN_API}/api/matching-candidates`;

// RS-requirement publish jobs
export const ADD_RS_PUBLISH_JOBS = `${RS_API}/v1/api/published-job`;
export const GET_RS_PUBLISH_JOBS = `${RS_API}/v1/api/published-job`;
export const UPDATE_RS_PUBLISH_JOBS = `${RS_API}/v1/api/published-job`;
export const DELETE_RS_PUBLISH_JOBS = `${RS_API}/v1/api/published-job`;

// job approval
export const GET_JOB_APPROVAL_API = `${RS_API}/v1/api/published-job/new`;

export const RS_PUBLISH_JOBS_SEARCH_API = `${RS_API}/v1/api/job-search/rs`;

export const JOB_BOARD_NAME_API = `${RS_API}/v1/api/published-job-board`;
// export const VIEWRESUME_CANDI = `${CAN_API}/v1/api/view-resume`;

export const FETCH_PARTICULAR_CANDIDATES = `${CAN_API}/v1/api/search/get-can-detail`;
export const GET_STATUS_INFO_CAN = `${IAM_API}/v1/api/auth/is-active`;
// RS --> Upload --> Requirements
export const UPLOAD_REQUIREMENTS = `${RS_API}/v1/api/batch-upload/resource-requirement`;
export const UPLOAD_CANDIDATES = `${RS_API}/v1/api/batch-upload/candidate-batch-upload`;
export const CANDIDATE_ERROR_LOG = `${RS_API}/v1/api/candidate-error-log`;
export const UPLOAD_CANDIDATE_TAGGING = `${RS_API}/v1/api/batch-upload/batch-upload`;
export const CURD_TEMPLATE_RS_DOCUMENTS = `${RS_API}/v1/api/upload-template`;
export const CURD_RESUME_RS_DOCUMENTS = `${RS_API}/v1/api/rs-resume/multiple-resume-upload`;

//RS---DASHBOARD

export const RS_DASHBOARD_API = `${RS_API}/v1/api/dashboard-graph/all`;

export const RS_DASHBOARD_FULL_DETAILS_API = `${RS_API}/v1/api/dashboard-graph/details`;

export const RS_DASHBOARD_FULL_APPLICANTS_API = `${RS_API}/v1/api/dashboard-graph/allApplicants`;

export const RS_DASHBOARD_POSITION_DEPARTMENT_API = `${RS_API}/v1/api/dashboard-graph/jobPostion`;

export const RS_DASHBOARD_POSITION_APPLICANTS_API = `${RS_API}/v1/api/dashboard-graph/application`;

export const RS_DASHBOARD_FUNNEL_API = `${RS_API}/v1/api/dashboard-graph/funnel`;
export const RS_DASHBOARD_APPLICANTS_TABLE_API = `${RS_API}/v1/api/dashboard-graph/table`;

export const RS_DASHBOARD_RATIO_API = `${RS_API}/v1/api/dashboard-graph/ratio`;

export const RS_DASHBOARD_PROFILE_SCREENING_API = `${RS_API}/v1/api/dashboard-graph/profile`;

export const RS_DASHBOARD_SOURCE_API = `${RS_API}/v1/api/dashboard-graph/source`;


export const MANAGER_RS_DASHBOARD_API = `${RS_API}/v1/api/manager-dashboard/all`;

export const MANAGER_RS_DASHBOARD_FULL_DETAILS_API = `${RS_API}/v1/api/manager-dashboard/details`;

export const MANAGER_RS_DASHBOARD_FULL_APPLICANTS_API = `${RS_API}/v1/api/manager-dashboard/allApplicants`;

export const MANAGER_RS_DASHBOARD_POSITION_DEPARTMENT_API = `${RS_API}/v1/api/manager-dashboard/jobPostion`;

export const MANAGER_RS_DASHBOARD_POSITION_APPLICANTS_API = `${RS_API}/v1/api/manager-dashboard/application`;

export const MANAGER_RS_DASHBOARD_FUNNEL_API = `${RS_API}/v1/api/manager-dashboard/funnel`;

export const MANAGER_RS_DASHBOARD_FUNNEL_DATA_API = `${RS_API}/v1/api/manager-dashboard/funnel/data`;

export const RS_MANAGER_DASHBOARD_RATIO_API = `${RS_API}/v1/api/manager-dashboard/ratio`;

export const RS_MANAGER_DASHBOARD_SOURCE_API = `${RS_API}/v1/api/manager-dashboard/source`;

export const RS_MANAGER_DASHBOARD_PROFILE_SCREENING_API = `${RS_API}/v1/api/manager-dashboard/profile`;

// RS --> Manager View
export const SEARCH_MANAGER_VIEW = `${RS_API}/v1/api/v2-manager-view-search`;
export const SEARCH_MANAGER_VIEW_CANDIDATE = `${RS_API}/v1/api/manager-view-search/candidate`;
export const GET_MANAGER_VIEW_CUSTOMERS = `${RS_API}/v1/api/manager-view-search/all-customer`;

export const GET_MANAGER_VIEW_TAC = `${RS_API}/v1/api/v2-manager-view-search/get-filters-tac-names`;
export const GET_MANAGER_VIEW_LEAD_RECRUITER = `${RS_API}/v1/api/v2-manager-view-search/get-filters-lead-recuitor`;
export const GET_MANAGER_VIEW_SOURCED_BY = `${RS_API}/v1/api/v2-manager-view-search/get-filters-tagged-by`;
export const GET_MANAGER_VIEW_UPDATED_BY = `${RS_API}/v1/api/v2-manager-view-search/get-filters-updated-by`;


export const GET_MANAGER_VIEW_SKILL_SETS = `${RS_API}/v1/api/manager-view-search/all-skill-sets`;
export const GET_MANAGER_VIEW_JOB_POSITION = `${RS_API}/v1/api/manager-view-search/all-job-positions`;

export const GET_MANAGER_VIEW_SOURCED_DATE = `${RS_API}/v1/api/manager-view-search/all-sourced-month`;
export const GET_MANAGER_VIEW_JOINED_OR_DECLINED_DATE = `${RS_API}/v1/api/manager-view-search/all-join-declined-date`;
export const GET_MANAGER_VIEW_CANDIDATE_STATUS = `${RS_API}/v1/api/manager-view-search/all-candidate-status`;
export const GET_MANAGER_VIEW_HIRING_STAGE = `${RS_API}/v1/api/manager-view-search/get-all-hiring-stage`;
export const FETCH_REQUIREMENT_DETAILS_BY_ID = `${RS_API}/v1/api/job-requirement`;
export const FETCH_CANDIDATE_DETAILS_BY_ID = `${CAN_API}/v1/api/search/get-can-detail`;
export const FETCH_MANAGER_HIRING_STATUS_API = `${RS_API}/v1/api/hiring-status/manager-view/select`;


export const FETCH_MANAGER_MULTI_SINGLE_CUST_API = `${RS_API}/v1/api/v2-manager-view-search/get-filters`;

//RS ---> MY_REPORTS VIEW TWO

export const FETCH_REPORTS_MULTI_SINGLE_CUST_API = `${RS_API}/v1/api/v2-manager-view-search/personal-filters`;
export const FETCH_TAC_REPORTS_API = `${RS_API}/v1/api/v2-manager-view-search//personal-filters-tac-names`;
export const SEARCH_MY_REPORTS_API = `${RS_API}/v1/api/v2-manager-view-search/personal`;



export const SEARCH_MANAGER_VIEW_TWO = `${RS_API}/v2/api/v2-manager-view-search`;
export const SEARCH_MANAGER_VIEW_CANDIDATE_TWO = `${RS_API}/v2/api/manager-view-search/candidate`;
export const GET_MANAGER_VIEW_CUSTOMERS_TWO = `${RS_API}/v2/api/manager-view-search/all-customer`;
export const GET_MANAGER_VIEW_TAC_TWO = `${RS_API}/v2/api/manager-view-search/all-cus-spoc`;
export const GET_MANAGER_VIEW_SKILL_SETS_TWO = `${RS_API}/v2/api/manager-view-search/all-skill-sets`;
export const GET_MANAGER_VIEW_JOB_POSITION_TWO = `${RS_API}/v2/api/manager-view-search/all-job-positions`;
export const GET_MANAGER_VIEW_LEAD_RECRUITER_TWO = `${RS_API}/v2/api/manager-view-search/all-lead-recruiter`;
export const GET_MANAGER_VIEW_SOURCED_BY_TWO = `${RS_API}/v2/api/manager-view-search/all-sourced-by`;
export const GET_MANAGER_VIEW_UPDATED_BY_TWO = `${RS_API}/v2/api/manager-view-search/all-updated-by`;
export const GET_MANAGER_VIEW_SOURCED_DATE_TWO = `${RS_API}/v2/api/manager-view-search/all-sourced-month`;
export const GET_MANAGER_VIEW_JOINED_OR_DECLINED_DATE_TWO = `${RS_API}/v2/api/manager-view-search/all-join-declined-date`;
export const GET_MANAGER_VIEW_CANDIDATE_STATUS_TWO = `${RS_API}/v2/api/manager-view-search/all-candidate-status`;
export const GET_MANAGER_VIEW_HIRING_STAGE_TWO = `${RS_API}/v2/api/manager-view-search/get-all-hiring-stage`;
export const FETCH_REQUIREMENT_DETAILS_BY_ID_TWO = `${RS_API}/v2/api/job-requirement`;
export const FETCH_CANDIDATE_DETAILS_BY_ID_TWO = `${CAN_API}/v2/api/search/get-can-detail`;
export const FETCH_MANAGER_HIRING_STATUS_API_TWO = `${RS_API}/v2/api/hiring-status/manager-view/select`;

// CRM Routes
// CRM --> CRM Setup Routes
export const CURD_CUSTOMER_TYPE = `${CRM_API}/v1/api/crm/customertype`;
export const USER_CUSTOMER_TYPE = `${CRM_API}/v1/api/crm/customertype/select`;
export const CURD_CUSTOMER_DOMAIN = `${CRM_API}/v1/api/crm/customerbusinessdomain`;
export const USER_CUSTOMER_DOMAIN = `${CRM_API}/v1/api/crm/customerbusinessdomain/select`;
export const CURD_CUSTOMER_GEOGRAPHIES = `${CRM_API}/v1/api/crm/customergeographies`;
export const USER_CUSTOMER_GEOGRAPHIES = `${CRM_API}/v1/api/crm/customergeographies/select`;
export const CURD_CUSTOMER_COUNTRIES = `${CRM_API}/v1/api/crm/customercountries`;
export const USER_CUSTOMER_COUNTRIES = `${CRM_API}/v1/api/crm/customercountries/select`;
export const CURD_CUSTOMER_CURRENCY = `${CRM_API}/v1/api/crm/customercurrency`;
export const USER_CUSTOMER_CURRENCY = `${CRM_API}/v1/api/crm/customercurrency/select`;
export const CURD_CUSTOMER_DIALING_CODE = `${CRM_API}/v1/api/crm/customerdialingcode`;
export const CURD_CUSTOMER_DOCUMENT_TYPE = `${CRM_API}/v1/api/crm/customerdocumenttype`;
export const USER_CUSTOMER_DOCUMENT_TYPE = `${CRM_API}/v1/api/crm/customerdocumenttype/select`;
export const CURD_CUSTOMER_ACCOUNT_STATUS = `${CRM_API}/v1/api/crm/customeraccountstatus`;
export const USER_CUSTOMER_ACCOUNT_STATUS = `${CRM_API}/v1/api/crm/customeraccountstatus/select`;
export const CURD_CONTACT_DESIGNATION = `${CRM_API}/v1/api/crm/customercontactdesignation`;
export const USER_CONTACT_DESIGNATION = `${CRM_API}/v1/api/crm/customercontactdesignation/select`;
export const CURD_CRM_DEPARTMENT = `${CRM_API}/v1/api/crm/contact-department`
export const CURD_CONTACT_WORKING_STATUS = `${CRM_API}/v1/api/crm/contactworkingstatus`;
export const USER_CONTACT_WORKING_STATUS = `${CRM_API}/v1/api/crm/contactworkingstatus/select`;
export const CURD_CRM_GLOBAL = `${CRM_API}/v1/api/crm/crm-global`;
export const CURD_CRM_USER = `${CRM_API}/v1/api/crm/crm-user`;
export const CURD_ACCOUNT_MANAGER = `${CRM_API}/v1/api/crm/account-manager`;
export const USER_ACCOUNT_MANAGER = `${CRM_API}/v1/api/crm/account-manager/select`;
export const CURD_SALES_TEAM = `${CRM_API}/v1/api/crm/sales-team`;
export const USER_SALES_TEAM = `${CRM_API}/v1/api/crm/sales-team/select`;

// CRM --> Customers Routes
export const CURD_CUSTOMERS = `${CRM_API}/v1/api/crm/customerlist`;
export const GET_CUSTOMERS_NAME = `${CRM_API}/v1/api/crm/customerlist/select`;
export const SEARCH_CUSTOMERS = `${CRM_API}/v1/api/crm/customerlist/search`;
export const GET_CUSTOMERS_SPOC_NAME = `${CRM_API}/v1/api/crm/contacts/customerspoclist`;
export const GET_UPDATE_CUSTOMERS_SPOC_NAME = `${CRM_API}/v1/api/crm/customercontacts/customerupdatespoc`;
export const GET_CUSTOMER_TAGGED_CONTACTS = `${CRM_API}/v1/api/crm/customercontacts/viewcustcontact`;

export const CURRENT_MAIL_ID = `${IAM_API}/v1/api/auth/verify/signup`;

// CRM --> Customer Entity Routes
export const CURD_CUSTOMER_ENTITY = `${CRM_API}/v1/api/crm/customer-entity`;
export const GET_CUSTOMER_ENTITY_NAME = `${CRM_API}/v1/api/crm/customer-entity/select`;
export const SEARCH_CUSTOMER_ENTITY = `${CRM_API}/v1/api/crm/customer-entity/search`;
export const GET_CUSTOMER_ENTITY_SPOC_NAME = `${CRM_API}/v1/api/crm/customercontacts/entityspoclist`;
export const GET_UPDATE_CUSTOMER_ENTITY_SPOC_NAME = `${CRM_API}/v1/api/crm/customercontacts/entityupdatespoc`;
export const GET_CUSTOMER_ENTITY_TAGGED_CONTACTS = `${CRM_API}/v1/api/crm/customercontacts/viewentitycontact`;

// CRM --> CustomerDocuments
export const CURD_CUSTOMER_DOCUMENTS = `${CRM_API}/v1/api/crm/customerdocuments`;

// CRM --> Accounts Routes
export const CURD_ACCOUNTS = `${CRM_API}/v1/api/crm/customeraccounts`;
export const SEARCH_ACCOUNTS = `${CRM_API}/v1/api/crm/customeraccounts/search`;
export const GET_ACCOUNTS_SPOC_NAME = `${CRM_API}/v1/api/crm/customercontacts/accountspoclist`;
export const GET_UPDATE_ACCOUNTS_SPOC_NAME = `${CRM_API}/v1/api/crm/customercontacts/accountupdatespoc`;
export const GET_ACCOUNT_TAGGED_CONTACTS = `${CRM_API}/v1/api/crm/customercontacts/viewaccountcontact`;

// CRM --> Contacts Routes
export const CURD_CRM_CONTACTS = `${CRM_API}/v1/api/crm/contacts`;
export const GET_CRM_CONTACTS_NAME = `${CRM_API}/v1/api/crm/contacts/select`;
export const GET_CRM_RS_CONTACT_NAME = `${CRM_API}/v1/api/crm/contacts/custcontact`;
export const SEARCH_CRM_CONTACTS = `${CRM_API}/v1/api/crm/contacts/search`;

// CRM --> Tag Contacts Routes
export const CURD_CRM_TAG_CONTACTS = `${CRM_API}/v1/api/crm/customercontacts`;
export const UPDATE_CRM_COMMON_TAG_CONTACTS = `${CRM_API}/v1/api/crm/customercontacts/entityacctag`;
export const SEARCH_CRM_TAG_CONTACTS = `${CRM_API}/v1/api/crm/customercontacts/search`;

// Experience 360 --> Requests
export const CURD_REQUESTS = `${E360_API}/v1/api/e360/e360-request`;
export const GET_CLOSED_REQUESTS = `${E360_API}/v1/api/e360/e360-request/globalvarbasedrecords`;
export const SEARCH_REQUESTS = `${E360_API}/v1/api/e360/e360-request/search`;
export const SEARCH_VIEW_REQUESTS = `${E360_API}/v1/api/e360/e360-request/viewreq`;

// Experience 360 --> Release Tag
export const CURD_E360_RELEASE_TAG = `${E360_API}/v1/api/e360/e360-release-tag`;

// Experience 360 --> Request Assignment
export const CURD_TEAM_ASSIGNMENT = `${E360_API}/v1/api/e360/team-assignment`;

// Experience 360 --> Request Conversation
export const CURD_REQUEST_CONVERSATION = `${E360_API}/v1/api/e360/req-conversation`;

// Experience 360 --> e360 Config
export const CURD_REQUEST_TYPE = `${E360_API}/v1/api/e360/request-type`;
export const USER_REQUEST_TYPE = `${E360_API}/v1/api/e360/request-type/select`;
export const CURD_REQUEST_STATUS = `${E360_API}/v1/api/e360/request-status`;
export const USER_REQUEST_STATUS = `${E360_API}/v1/api/e360/request-status/select`;
export const CURD_REQUEST_SEVERITY = `${E360_API}/v1/api/e360/request-severity`;
export const USER_REQUEST_SEVERITY = `${E360_API}/v1/api/e360/request-severity/select`;
export const CURD_REQUEST_PRIORITY = `${E360_API}/v1/api/e360/request-priority`;
export const USER_REQUEST_PRIORITY = `${E360_API}/v1/api/e360/request-priority/select`;
export const CURD_E360_GLOBAL = `${E360_API}/v1/api/e360/e360-global-var`;
export const USER_E360_GLOBAL = `${E360_API}/v1/api/e360/e360-global-var/select`;
export const CURD_E360_USER = `${E360_API}/v1/api/e360/e360-user-var`;
export const USER_E360_USER = `${E360_API}/v1/api/e360/e360-user-var/select`;
export const CURD_PROJECT_TEAM = `${E360_API}/v1/api/e360/project-team`;
export const USER_PROJECT_TEAM = `${E360_API}/v1/api/e360/project-team/select`;
export const CURD_PROJECT_MEMBER = `${E360_API}/v1/api/e360/project-member`;
export const USER_PROJECT_MEMBER = `${E360_API}/v1/api/e360/project-member/select`;
export const CURD_RELEASE_TAG = `${E360_API}/v1/api/e360/release-tag`;
export const USER_RELEASE_TAG = `${E360_API}/v1/api/e360/release-tag/select`;
export const CURD_KNOWLEDGE_TYPE = `${E360_API}/v1/api/e360/knowledge-type`;
export const USER_KNOWLEDGE_TYPE = `${E360_API}/v1/api/e360/knowledge-type/select`;
export const CURD_REQUEST_RESOLUTION = `${E360_API}/v1/api/e360/request-resolution`;
export const USER_REQUEST_RESOLUTION = `${E360_API}/v1/api/e360/request-resolution/select`;
export const CURD_PRODUCT_LIST = `${E360_API}/v1/api/e360/product-list`;
export const USER_PRODUCT_LIST = `${E360_API}/v1/api/e360/product-list/select`;
