import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import dateFns from "date-fns";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  FormText,
  Button,
} from "reactstrap";
// material ui
import { Divider, IconButton } from "@material-ui/core";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import EditIcon from "@material-ui/icons/Edit";
// components
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import {
  Validator,
  valid,
  refresh,
  submitValidator,
  errors,
} from "../../../common/Validator";
import DetailsPageFooter from "../../../common/details_footer/DetailsPageFooter";
import Details from "./Details";

// redux
import { updateEmpManagement } from "../../../../redux/actions/hcm/hr/emp_management/empManagementAction";
import { getUserBusinessGeowise } from "../../../../redux/actions/hcm/business_setup/businessGeowiseAction";
import { getUserBusinessCountries } from "../../../../redux/actions/hcm/business_setup/businessCountriesAction";
import { getUserBusinessLocations } from "../../../../redux/actions/hcm/business_setup/businessLocationsAction";
import { getUserOrganization } from "../../../../redux/actions/hcm/business_setup/organizationAction";
import { getUserBusinessUnit } from "../../../../redux/actions/hcm/business_setup/businessUnitAction";
import { getUserDepartments } from "../../../../redux/actions/hcm/business_setup/departmentsAction";
import { getUserEmpType } from "../../../../redux/actions/hcm/configurations/empTypeAction";
import { getUserEmpPosition } from "../../../../redux/actions/hcm/configurations/empPositionAction";
import { getUserEmpGrade } from "../../../../redux/actions/hcm/configurations/empGradeAction";
import { getUserEmpStatusReason } from "../../../../redux/actions/hcm/configurations/empStatusReasonAction";

// --------------------------------------------------------------------------------------------------------

class EmployeeDetails extends Component {
  constructor(props) {
    super(props);
    const {
      id,
      organizationName,
      empBusinessUnit,
      empDepartment,
      empNo,
      geoName,
      countryName,
      locationName,
      empType,
      dateOfJoin,
      empPosition,
      grade,
      empStatus,
      organizationEmailId,
      lastWorkingDate,
    } = this.props.employee;

    const {
      title,
      firstName,
      middleName,
      lastName,
      dob,
      gender,
      bloodGroup,
      aadharNo,
      panNo,
      mobileNumber,
      personalEmailId,
    } = this.props.employee.hcmpersonal;
    this.state = {
      id: id,
      organizationName: organizationName,
      businessUnit: empBusinessUnit,
      department: empDepartment,
      empNo: empNo,
      title: title,
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      dob: dob,
      geography: geoName,
      country: countryName,
      location: locationName,
      gender: gender,
      aadharNo: aadharNo,
      panNo: panNo,
      bloodGroup: bloodGroup,
      empType: empType,
      mobile: mobileNumber,
      personalEmail: personalEmailId,
      created: dateFns.format(dateOfJoin, "YYYY-MM-DD"),
      position: empPosition,
      grade: grade,
      empStatus: empStatus,
      email: organizationEmailId,
      empStatusReason: " ",
      modal: false,
      action: "",
      reason: "",
      lastWorkingDate: lastWorkingDate || null,
      remarks: "",
      reHire: "",
      reasonForNoHire: "",
      startDate: "",
      edit: false,
      details: false,
      currentPermission: {},
      message: "",
      error: "",
      formErrors: {
        action: "",
      },
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  /*
This function update the employee details
body as the employee details
*/

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let emp = {
      id: this.state.id,
      organizationName: this.state.organizationName,
      empBusinessUnit: this.state.businessUnit,
      empDepartment: this.state.department,
      geoName: this.state.geography,
      countryName: this.state.country,
      locationName: this.state.location,
      dateOfJoin: this.state.created,
      empPosition: this.state.position,
      grade: this.state.grade,
      organizationEmailId: this.state.email,
      // empStatus: this.state.empStatus,
      action: this.state.action,
      empStatusReason: this.state.empStatusReason,
      lastWorkingDate: this.state.lastWorkingDate,
      remarks: this.state.remarks,
      reHire: this.state.reHire,
      reasonForNoHire: this.state.reasonForNoHire,
    };

    if (valid) {
      this.props.updateEmpManagement(emp, this.props.back);
    } else {
      let errors = this.state.formErrors;
      errors.action = "This field is mandatory";
      this.setState({
        error: "Enter all mandatory fields",
        formErrors: errors,
      });
      setTimeout(() => {
        this.setState({
          message: "",
          error: "",
        });
      }, 2000);
    }
  }

  /*
toggle function to set state for modle toggling
*/
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  componentDidMount() {
    for (let a in this.props.auth.data.accesses) {
      if (this.props.auth.data.accesses[a].appName === "HCM") {
        for (let m in this.props.auth.data.accesses[a].pieUserModuleAccess) {
          if (
            this.props.auth.data.accesses[a].pieUserModuleAccess[m]
              .moduleName === "Employees"
          ) {
            for (let s in this.props.auth.data.accesses[a].pieUserModuleAccess[
              m
            ].pieUserServiceAccess) {
              if (
                this.props.auth.data.accesses[a].pieUserModuleAccess[m]
                  .pieUserServiceAccess[s].serviceName === "Employees"
              ) {
                this.setState({
                  currentPermission:
                    this.props.auth.data.accesses[a].pieUserModuleAccess[m]
                      .pieUserServiceAccess[s],
                });
              }
            }
          }
        }
      }
    }
    refresh();
    this.props.getUserBusinessGeowise();
    this.props.getUserBusinessCountries();
    this.props.getUserBusinessLocations();
    this.props.getUserEmpType();
    this.props.getUserEmpPosition();
    this.props.getUserEmpGrade();
    this.props.getUserOrganization();
    this.props.getUserBusinessUnit();
    this.props.getUserDepartments();
    this.props.getUserEmpStatusReason();
  }

  render() {
    const {
      organizationName,
      businessUnit,
      department,
      geography,
      country,
      location,
      empType,
      mobile,
      grade,
      email,
      position,
      created,
    } = this.state;
    const { action } = this.state.formErrors;
    let currentDate = dateFns.format(new Date(), "YYYY-MM-D");

    return (
      <Row className="m-0">
        <React.Fragment>
          {!this.state.edit ? (
            !this.state.details ? (
              <Col md="12">
                <h5 className="bold pointer center pt-2 pb-2">
                  Employee Details
                </h5>
                <button
                  onClick={() => this.setState({ details: true })}
                  className="btn cust-chart-button white-text"
                  title="View Employee Details"
                >
                  Details
                </button>
                {Object.keys(this.state.currentPermission).length > 0 &&
                  this.state.currentPermission.updation && (
                    // <div className="div">
                    //   <i
                    //     className="material-icons  edit-button pointer "
                    //     onClick={() => this.setState({ edit: !this.state.edit })}
                    //   ></i>
                    // </div>
                    <IconButton
                      className="edit-button"
                      color="primary"
                      onClick={() => this.setState({ edit: !this.state.edit })}
                      title="edit customer"
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                {this.state.empStatus === "Active" && (
                  <IconButton
                    className="tag-button"
                    color="primary"
                    title="Relieve/Terminate"
                    onClick={this.toggle}
                  >
                    <IndeterminateCheckBoxIcon />
                  </IconButton>
                )}

                <i
                  onClick={this.props.back}
                  className="material-icons pointer cancel-button"
                >
                  cancel
                </i>
                <Row>
                  <Col md="4">
                    <ul className="CustDetails-ul">
                      <li>
                        <span className="label-sm bold emp-span-label-col-1">
                          Title
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.title}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-1">
                          First Name
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.firstName}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-1">
                          Middle Name
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.middleName}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-1">
                          Last Name
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.lastName}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-1">
                          Date of Birth
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;
                          {dateFns.format(this.state.dob, "DD-MM-YYYY")}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-1">
                          Gender
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.gender}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-1">
                          Personal Email
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.personalEmail}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-1">
                          Mobile No.
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{mobile}
                        </span>
                      </li>
                    </ul>
                  </Col>
                  <Col md="4">
                    <ul className="CustDetails-ul">
                      <li>
                        <span className="label-sm bold emp-span-label-col-2">
                          Emp No.
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.empNo}
                        </span>
                      </li>

                      <li>
                        <span className="label-sm bold emp-span-label-col-2">
                          Geography
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.geography}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-2">
                          Country
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.country}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-2">
                          Location
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.location}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-2">
                          Date of Join
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;
                          {dateFns.format(created, "DD-MM-YYYY")}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-2">
                          Position
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{position}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-2">
                          Grade
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.grade}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-2">
                          Aadhar No.
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.aadharNo}
                        </span>
                      </li>
                    </ul>
                  </Col>
                  <Col md="4" className="col-reset">
                    <ul className="CustDetails-ul">
                      <li>
                        <span className="label-sm bold emp-span-label-col-3">
                          PAN No.
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.panNo}
                        </span>
                      </li>

                      <li>
                        <span className="label-sm bold emp-span-label-col-3">
                          Org Name
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{organizationName}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-3">
                          Business Unit
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{businessUnit}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-3">
                          Department
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{department}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-3">
                          Employee Type
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{empType}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-3">
                          Email
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{email}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-3">
                          Status
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.empStatus}
                        </span>
                      </li>
                      {this.state.empStatus === "Inactive" && (
                        <li>
                          <span
                            className="label-sm bold emp-span-label-col-3"
                            title="Last Working Date"
                          >
                            LWD
                          </span>
                          <span className=" label-sm">
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            {dateFns.format(
                              this.state.lastWorkingDate,
                              "DD-MM-YYYY"
                            )}
                          </span>
                        </li>
                      )}
                    </ul>
                  </Col>
                </Row>
                {/* {this.state.empStatus === "Active" ? (
                <Row>
                  <button
                    className=" btn TerminateButton"
                    onClick={this.toggle}
                  >
                    Relieve/Terminate
                  </button>
                </Row>
              ) : null} */}
                <Divider />
                <DetailsPageFooter
                  createdBy={this.props.employee.createdBy}
                  createdOn={this.props.employee.createdOn}
                  updatedBy={this.props.employee.updatedBy}
                  updatedOn={this.props.employee.updatedOn}
                />
              </Col>
            ) : (
              <Col md="12">
                <i
                  onClick={() => this.setState({ details: false })}
                  className="material-icons pointer cancel-button"
                >
                  cancel
                </i>
                <Details data={this.state.data} id={this.state.id} />
              </Col>
            )
          ) : (
            <Col md="12">
              <h5 className="bold pointer center pt-2">Update Employee</h5>
              <i
                onClick={this.props.back}
                className="material-icons pointer cancel-button"
              >
                cancel
              </i>
              <Form onSubmit={this.onSubmit} className="p-3" noValidate>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label className="bold label-sm">Organization Name</Label>
                      <Input
                        type="select"
                        name="organizationName"
                        className="form-control-sm"
                        value={organizationName}
                        onChange={this.onChange}
                        required={true}
                      >
                        <option hidden value="">
                          Select...
                        </option>
                        {this.props.organization.data.length > 0 &&
                          this.props.organization.data.map((item) => {
                            return (
                              <option
                                key={item.id}
                                value={item.orgLegalEntityName}
                              >
                                {item.orgLegalEntityName}
                              </option>
                            );
                          })}
                      </Input>
                      {errors.organizationName && (
                        <FormText className="error">
                          {errors.organizationName}
                        </FormText>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label className="bold label-sm">Business Unit</Label>
                      <Input
                        type="select"
                        name="businessUnit"
                        className="form-control-sm"
                        value={businessUnit}
                        onChange={this.onChange}
                        required={true}
                      >
                        <option hidden value="">
                          Select...
                        </option>
                        {this.props.businessUnit.data.length > 0 &&
                          this.props.businessUnit.data
                            .filter(
                              (value) =>
                                value.orgLegalEntityName === organizationName
                            )
                            .map((item) => {
                              return (
                                <option key={item.id} value={item.busiUnitName}>
                                  {item.busiUnitName}
                                </option>
                              );
                            })}
                      </Input>
                      {errors.businessUnit && (
                        <FormText className="error">
                          {errors.businessUnit}
                        </FormText>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label className="bold label-sm">Department</Label>
                      <Input
                        type="select"
                        name="department"
                        className="form-control-sm"
                        value={department}
                        onChange={this.onChange}
                        required={true}
                      >
                        <option hidden value="">
                          Select...
                        </option>
                        {this.props.departments.data.length > 0 &&
                          this.props.departments.data
                            .filter(
                              (value) => value.busiUnitName === businessUnit
                            )
                            .map((item) => {
                              return (
                                <option
                                  key={item.id}
                                  value={item.departmentName}
                                >
                                  {item.departmentName}
                                </option>
                              );
                            })}
                      </Input>
                      {errors.department && (
                        <FormText className="error">
                          {errors.department}
                        </FormText>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label className="bold label-sm">Geography</Label>
                      <Input
                        type="select"
                        name="geography"
                        className="form-control-sm"
                        value={geography}
                        onChange={this.onChange}
                        placeholder="Geography"
                        required={true}
                      >
                        <option value="" hidden>
                          Select...
                        </option>
                        {this.props.businessGeowise.data.map((item) => {
                          return (
                            <option key={item.geoId} value={item.geoName}>
                              {item.geoName}
                            </option>
                          );
                        })}
                      </Input>
                      {errors.geography && (
                        <FormText className="error">
                          {errors.geography}
                        </FormText>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label className="bold label-sm">Country</Label>
                      <Input
                        type="select"
                        name="country"
                        className="form-control-sm"
                        value={country}
                        onChange={this.onChange}
                        placeholder="Country"
                        required={true}
                      >
                        <option value="" hidden>
                          Select...
                        </option>
                        {this.props.businessCountries.data.map((item) => {
                          if (item.geoName === geography) {
                            return (
                              <option
                                key={item.countryId}
                                value={item.countryName}
                              >
                                {item.countryName}
                              </option>
                            );
                          }
                        })}
                      </Input>
                      {errors.country && (
                        <FormText className="error">{errors.country}</FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className="bold label-sm">Work Location</Label>
                      <Input
                        type="select"
                        name="location"
                        className="form-control-sm"
                        value={location}
                        onChange={this.onChange}
                        required={true}
                      >
                        <option hidden value="">
                          Select...
                        </option>
                        {this.props.businessLocations &&
                          this.props.businessLocations.data.map((item) => {
                            if (country === item.countryName) {
                              return (
                                <option
                                  key={item.locationId}
                                  value={item.locationName}
                                >
                                  {item.locationCode}-{item.locationName}
                                </option>
                              );
                            }
                          })}
                      </Input>
                      {errors.location && (
                        <FormText className="error">{errors.location}</FormText>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label className="bold label-sm">Emp Position</Label>
                      <Input
                        type="select"
                        name="position"
                        className="form-control-sm"
                        value={this.state.position}
                        onChange={this.onChange}
                        required={true}
                      >
                        <option hidden value="">
                          Select...
                        </option>
                        {this.props.empPosition.data.length > 0 &&
                          this.props.empPosition.data.map((item) => {
                            return (
                              <option
                                key={item.positionId}
                                value={item.positionName}
                              >
                                {item.positionName}
                              </option>
                            );
                          })}
                      </Input>
                      {errors.empPosition && (
                        <FormText className="error">
                          {errors.empPosition}
                        </FormText>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label className="bold label-sm">Grade</Label>
                      <Input
                        type="select"
                        name="grade"
                        className="form-control-sm"
                        value={grade}
                        onChange={this.onChange}
                        required={true}
                      >
                        <option hidden value="">
                          Select...
                        </option>
                        {this.props.empGrade.data.length > 0 &&
                          this.props.empGrade.data.map((item) => {
                            // if (
                            //   this.state.position ===
                            //   item.empposition.positionName
                            // ) {
                            return (
                              <option key={item.gradeId} value={item.grade}>
                                {item.grade}
                              </option>
                            );
                            // }
                          })}
                      </Input>
                      {errors.grade && (
                        <FormText className="error">{errors.grade}</FormText>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label className="bold label-sm">Date of Join</Label>
                      <Input
                        type="date"
                        name="created"
                        className="form-control-sm"
                        value={created}
                        onChange={this.onChange}
                        required={true}
                      />
                      {errors.created && (
                        <FormText className="error">{errors.created}</FormText>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label className="bold label-sm">
                        Organization Email Id
                      </Label>
                      <Input
                        type="email"
                        name="email"
                        className="form-control-sm"
                        value={email}
                        onChange={this.onChange}
                        placeholder="email"
                        required={true}
                        disabled={true}
                      />
                      {errors.email && (
                        <FormText className="error">{errors.email}</FormText>
                      )}
                    </FormGroup>
                    {this.state.empStatus === "Inactive" && (
                      <FormGroup>
                        <Label className="label-sm bold">
                          Last Working Date
                        </Label>
                        <Input
                          type="date"
                          name="lastWorkingDate"
                          value={this.state.lastWorkingDate}
                          onChange={this.onChange}
                          // min={this.state.created}
                          min={currentDate}
                          className="form-control-sm "
                          required={true}
                        />
                        {errors.lastWorkingDate && (
                          <FormText className="ReasonError">
                            {errors.lastWorkingDate}
                          </FormText>
                        )}
                      </FormGroup>
                    )}
                  </Col>
                </Row>
                <div className="center">
                  <button type="submit" className="btn btn-custom">
                    Update
                  </button>
                </div>
              </Form>
            </Col>
          )}
        </React.Fragment>
        <Modal
          size="md"
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="apply-modal"
        >
          <ModalHeader toggle={this.toggle}>
            Relieve or Terminate Employee
          </ModalHeader>
          <ModalBody>
            <Form className="p-3" onSubmit={this.onSubmit} noValidate>
              <FormGroup className="row p-3">
                <Label className="col-md-5 bold">Action</Label>
                <Input
                  type="select"
                  name="action"
                  onChange={this.onChange}
                  value={this.state.action}
                  className="form-control-sm col-md-7"
                  required={true}
                >
                  <option hidden value="">
                    Select...
                  </option>
                  <option value="Terminate">Terminate</option>
                  <option value="Relieve">Relieve</option>
                  {/* <option value="Convert">Convert</option> */}
                </Input>
                {!this.state.action && action && (
                  <FormText className="ReasonError">{action}</FormText>
                )}
                {this.state.action === "Convert" &&
                this.props.employee.empType === "E" ? (
                  <FormText className="ReasonError">
                    you can't convert Employee
                  </FormText>
                ) : null}
              </FormGroup>
              <FormGroup className="row p-3">
                <Label className="col-md-5 bold">Reason</Label>
                <Input
                  type="select"
                  name="empStatusReason"
                  className="form-control-sm col-md-7"
                  value={this.state.empStatusReason}
                  onChange={this.onChange}
                  required={true}
                >
                  <option hidden value="">
                    Select...
                  </option>
                  {this.props.empStatusReason.data.length > 0 &&
                    this.props.empStatusReason.data.map((item) => {
                      return (
                        <option key={item.id} value={item.statusReasonName}>
                          {item.statusReasonName}
                        </option>
                      );
                    })}
                </Input>
                {errors.empStatusReason && (
                  <FormText className="ReasonError">
                    {errors.empStatusReason}
                  </FormText>
                )}
              </FormGroup>

              <FormGroup className="row p-3">
                <Label className="col-md-5 bold">Last Working Date</Label>
                <Input
                  type="date"
                  name="lastWorkingDate"
                  value={this.state.lastWorkingDate}
                  onChange={this.onChange}
                  // min={this.state.created}
                  min={currentDate}
                  className="form-control-sm col-md-7"
                  required={true}
                />
                {errors.lastWorkingDate && (
                  <FormText className="ReasonError">
                    {errors.lastWorkingDate}
                  </FormText>
                )}
              </FormGroup>
              <FormGroup className="row p-3">
                <Label className="col-md-5 bold">Remarks</Label>
                <Input
                  type="textarea"
                  className="form-control-sm col-md-7"
                  name="remarks"
                  value={this.state.remarks}
                  onChange={this.onChange}
                />
              </FormGroup>
              <FormGroup className="row p-3">
                <Label className="col-md-5 bold">Is Rehirable ?</Label>
                <Input
                  type="select"
                  name="reHire"
                  onChange={this.onChange}
                  value={this.state.reHire}
                  className="form-control-sm col-md-7"
                  required={true}
                >
                  <option hidden value="">
                    Select...
                  </option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                  {/* <option value="Convert">Convert</option> */}
                </Input>
                {errors.reHire && (
                  <FormText className="ReasonError">{errors.reHire}</FormText>
                )}
              </FormGroup>
              {this.state.reHire === "false" && (
                <FormGroup className="row p-3">
                  <Label className="col-md-5 bold">Reason for 'No'</Label>
                  <Input
                    type="textarea"
                    className="form-control-sm col-md-7"
                    name="reasonForNoHire"
                    value={this.state.reasonForNoHire}
                    onChange={this.onChange}
                    required={true}
                  />
                  {errors.reasonForNoHire && (
                    <FormText className="ReasonError">
                      {errors.reasonForNoHire}
                    </FormText>
                  )}
                </FormGroup>
              )}
              {this.state.action === "Convert" &&
              this.props.employee.empType !== "E" ? (
                <React.Fragment>
                  <FormGroup className="row p-3">
                    <Label className="col-md-5 bold">EmpType</Label>
                    <Input
                      name="empType"
                      type="select"
                      value={this.state.empType}
                      className="form-control-sm col-md-7"
                      onChange={this.onChange}
                      required={true}
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      <option value="Employee">Employee</option>
                      {/* <option value="T" disabled>
                        Trainee
                      </option>
                      <option value="C" disabled>
                        Contigent
                      </option> */}
                    </Input>
                    {errors.empType && (
                      <FormText className="error">{errors.empType}</FormText>
                    )}
                  </FormGroup>
                  <FormGroup className="row p-3">
                    <Label className="col-md-5 bold">Start Date</Label>
                    <Input
                      type="date"
                      className="form-control-sm col-md-7"
                      min={this.state.lastWorkingDate}
                      name="startDate"
                      value={this.state.startDate}
                      onChange={this.onChange}
                      required={true}
                    />
                    {errors.startDate && (
                      <FormText className="ReasonError">
                        {errors.startDate}
                      </FormText>
                    )}
                  </FormGroup>
                  <FormGroup className="row p-3">
                    <Label className="col-md-5 bold">Designation</Label>
                    <Input
                      type="text"
                      className="form-control-sm col-md-7"
                      name="designation"
                      value={this.state.designation}
                      onChange={this.onChange}
                      required={true}
                    />
                    {errors.designation && (
                      <FormText className="ReasonError">
                        {errors.designation}
                      </FormText>
                    )}
                  </FormGroup>
                </React.Fragment>
              ) : null}
              <FormGroup className="center">
                <button type="submit" className="btn white-text login-button">
                  Submit
                </button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
        {this.state.message ? <Notifier message={this.state.message} /> : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
        {this.props.empManagement.message ? (
          <Notifier message={this.props.empManagement.message} />
        ) : null}
        {this.props.empManagement.error ? (
          <ErrorNotifier message={this.props.empManagement.error} />
        ) : null}
      </Row>
    );
  }
}

EmployeeDetails.propTypes = {
  prop: PropTypes,
  auth: PropTypes.object.isRequired,
  getUserBusinessGeowise: PropTypes.func.isRequired,
  businessGeowise: PropTypes.object.isRequired,
  getUserBusinessCountries: PropTypes.func.isRequired,
  businessCountries: PropTypes.object.isRequired,
  getUserBusinessLocations: PropTypes.func.isRequired,
  businessLocations: PropTypes.object.isRequired,
  getUserEmpType: PropTypes.func.isRequired,
  empType: PropTypes.object.isRequired,
  getUserEmpPosition: PropTypes.func.isRequired,
  empPosition: PropTypes.func.isRequired,
  getUserEmpGrade: PropTypes.func.isRequired,
  empGrade: PropTypes.object.isRequired,
  updateEmpManagement: PropTypes.func.isRequired,
  getUserOrganization: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  getUserBusinessUnit: PropTypes.func.isRequired,
  businessUnit: PropTypes.object.isRequired,
  getUserDepartments: PropTypes.func.isRequired,
  department: PropTypes.object.isRequired,
  empStatusReason: PropTypes.object.isRequired,
  getUserEmpStatusReason: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  businessGeowise: state.businessGeowise,
  businessCountries: state.businessCountries,
  businessLocations: state.businessLocations,
  empType: state.empType,
  empManagement: state.empManagement,
  empPosition: state.empPosition,
  empGrade: state.empGrade,
  organization: state.organization,
  businessUnit: state.businessUnit,
  departments: state.departments,
  empStatusReason: state.empStatusReason,
});

const mapDispatchToProps = (dispatch) => ({
  getUserBusinessGeowise: () => dispatch(getUserBusinessGeowise()),
  getUserBusinessCountries: () => dispatch(getUserBusinessCountries()),
  getUserBusinessLocations: () => dispatch(getUserBusinessLocations()),
  getUserEmpType: () => dispatch(getUserEmpType()),
  getUserEmpPosition: () => dispatch(getUserEmpPosition()),
  getUserEmpGrade: () => dispatch(getUserEmpGrade()),
  updateEmpManagement: (emp, back) => dispatch(updateEmpManagement(emp, back)),
  getUserOrganization: () => dispatch(getUserOrganization()),
  getUserBusinessUnit: () => dispatch(getUserBusinessUnit()),
  getUserDepartments: () => dispatch(getUserDepartments()),
  getUserEmpStatusReason: () => dispatch(getUserEmpStatusReason()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeDetails);
