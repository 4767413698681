import React, { Component } from "react";
import { Row, Col, Card } from "reactstrap";
import CampaignType from "./campaign/campaign_type/CampaignType";
import SuccessCriteria from "./campaign/success_criteria/SuccessCriteria";
import LeadSource from "./lead/lead_source/LeadSource";

class MarketingSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: "",
    };

    this.back = this.back.bind(this);
    this.chooseForm = this.chooseForm.bind(this);
  }

  /*
back function to come back to the home page
*/
  back() {
    this.setState({
      edit: "",
    });
  }

  /*
chooseForm function to select the particular page to render
*/
  chooseForm() {
    let component = this.state.edit;

    if (component === "campaignType") {
      return <CampaignType back={this.back} />;
    }
    if (component === "successCriteria") {
      return <SuccessCriteria back={this.back} />;
    }
    if (component === "leadSource") {
      return <LeadSource back={this.back} />;
    }
  }

  render() {
    let shrink = this.props.sidebar ? "scale" : "no-scale";

    return (
      <div className={shrink}>
        {!this.state.edit ? (
          <Row>
            <div className="platform-head">
              <p className="platform-p">Marketing Setup</p>
            </div>
            <div className="platform-category-div">
              <Row>
                <Col md="6">
                  <Card
                    className="myprofile-card"
                    onClick={() => this.setState({ edit: "campaignType" })}
                  >
                    {/* <i className="material-icons">person</i> */}
                    Campaign Type
                  </Card>
                </Col>
                <Col md="6">
                  <Card
                    className="myprofile-card"
                    onClick={() => this.setState({ edit: "successCriteria" })}
                  >
                    {/* <i className="material-icons">supervisor_account</i> */}
                    Campaign Success Criteria
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Card
                    className="myprofile-card"
                    onClick={() => this.setState({ edit: "leadSource" })}
                  >
                    {/* <i className="material-icons">person</i> */}
                    Lead Source
                  </Card>
                </Col>
                {/* <Col md="6">
                  <Card
                    className="myprofile-card"
                    onClick={() => this.setState({ edit: "successCriteria" })}
                  >
                    Campaign Success Criteria
                  </Card>
                </Col> */}
              </Row>
            </div>
          </Row>
        ) : (
          this.chooseForm()
        )}
      </div>
    );
  }
}

export default MarketingSetup;
