import React, { Component } from "react";
import axios from "axios";
import { Container, Row, Col, Form } from "reactstrap";
import { CHANGE_PASSWORD } from "../../../utils/routes";
import { Link } from "react-router-dom";
// components
import { Validator, submitValidator, valid } from "../../common/Validator";
import FormComponent from "../../common/FormComponent";
import Notifier from "../../aside/Notifier";
import ErrorNotifier from "../../aside/ErrorNotifier";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: "",
      newPassword: "",
      newPassword2: "",
      message: null,
      error: null,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    e.preventDefault();
    Validator(e.target);
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    if (valid) {
      if (this.state.newPassword === this.state.newPassword2) {
        const data = {
          currentPassword: this.state.currentPassword,
          newPassword: this.state.newPassword,
          confirmPassword: this.state.newPassword2,
        };
        axios
          .post(CHANGE_PASSWORD, data, { withCredentials: true })
          .then((res) => {
            this.setState({
              message: res.data.message,
            });
            setTimeout(() => {
              this.setState({
                message: null,
              });
              this.props.history.push("/pie");
            }, 2000);
          })
          .catch((err) => {
            this.setState({
              error: "Could not change password",
            });
            setTimeout(() => {
              this.setState({
                error: null,
              });
            }, 5000);
          });
      } else {
        this.setState({
          error: "Passwords do not match",
        });
        setTimeout(() => {
          this.setState({
            error: null,
          });
        }, 5000);
      }
    } else {
      this.setState({
        error: "Please enter the required details",
      });
      setTimeout(() => {
        this.setState({
          error: null,
        });
      }, 5000);
    }
  }

  render() {
    let shrink;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");
    return (
      <div className={shrink}>
        {this.state.message ? <Notifier message={this.state.message} /> : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
        <Container className="card container-card container-width">
          <h4 className="bold center pt-2">Change Password</h4>

          <Link
            to={{
              pathname: "/pie",
            }}
          >
            <i className="material-icons cancel-button pointer">cancel</i>
          </Link>

          <Row>
            <Col md="2"></Col>
            <Col md="8">
              <Form className="p-3" onSubmit={this.onSubmit} noValidate>
                <FormComponent
                  labelClass="bold label-sm"
                  label="Current Password"
                  type="password"
                  name="currentPassword"
                  value={this.state.currentPassword}
                  change={this.onChange}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="New Password"
                  type="password"
                  name="newPassword"
                  value={this.state.newPassword}
                  change={this.onChange}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Confirm Password"
                  type="password"
                  name="newPassword2"
                  value={this.state.newPassword2}
                  change={this.onChange}
                  required={true}
                />
                <div className="center">
                  <button className="btn login-button white-text">
                    Change Password
                  </button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default ChangePassword;
