import React, { Component } from "react";
import { Form, Row, Col, FormGroup, Label, Input, FormText } from "reactstrap";
import DateFns from "date-fns";
import axios from "axios";
// componets
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import { CURD_EMP_AWARDS } from "../../../../../utils/routes";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";

// ----------------------------------------------------------------------------------------
class UpdateHonorsAndAwards extends Component {
  constructor(props) {
    super(props);
    const { awardName, dateAwarded, awardedBy, remarks, fileName } =
      this.props.data;
    this.state = {
      awardName: awardName,
      dateAwarded: DateFns.format(dateAwarded, "YYYY-MM-DD"),
      awardedBy: awardedBy,
      remarks: remarks,
      file: [],
      fileName: fileName,
      fileError: "",
      message: "",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleFile = this.handleFile.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    const { fileError } = this.state;
    let fd = new FormData();
    fd.append("id", this.props.data.id);
    fd.append("empId", localStorage.getItem("id"));
    fd.append("awardName", this.state.awardName);
    fd.append("dateAwarded", this.state.dateAwarded);
    fd.append("awardedBy", this.state.awardedBy);
    fd.append("remarks", this.state.remarks);
    fd.append("updatedBy", localStorage.getItem("id"));
    fd.append("fileUpload", this.state.file);

    if (valid && !fileError) {
      axios
        .put(CURD_EMP_AWARDS, fd, { withCredentials: true })
        .then((res) => {
          this.setState({
            message: res.data.message,
          });
          setTimeout(() => {
            this.setState({
              message: "",
            });

            this.props.back();
          }, 2000);
        })
        .catch((err) => {
          this.setState({
            error: err.message,
          });
          setTimeout(() => {
            this.setState({
              error: "",
            });
          }, 2000);
        });
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidMount contains the error refresh method and here set the state for calling render method
*/
  componentDidMount() {
    refresh();
    this.setState({
      message: "",
      error: "",
    });
  }

  handleFile(e) {
    Validator(e.target);
    let allowedExtension = /(\.pdf|\.docx)$/i;
    if (allowedExtension.exec(e.target.files[0].name)) {
      if ((e.target.files[0].size / 1024 / 1024).toFixed(2) < 2) {
        this.setState({
          file: e.target.files[0] || [],
          fileError: "",
        });
      } else {
        this.setState({
          file: e.target.files[0],
          fileError: "File size must less than 2MB",
        });
      }
    } else {
      this.setState({
        file: e.target.files[0],
        fileError: "File type not allowed",
      });
    }
  }

  render() {
    const {
      awardName,
      dateAwarded,
      awardedBy,
      remarks,
      fileName,
      file,
      fileError,
      error,
      message,
    } = this.state;
    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Update Honors / Awards </h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="3"></Col>
            <Col md="6">
              <FormComponent
                labelClass="bold label-sm"
                label="Award Name"
                type="text"
                name="awardName"
                inputClass="form-control-sm"
                value={awardName}
                change={this.onChange}
                required={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Date Awarded"
                type="date"
                name="dateAwarded"
                inputClass="form-control-sm"
                value={dateAwarded}
                change={this.onChange}
                required={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Awarded By"
                type="text"
                name="awardedBy"
                inputClass="form-control-sm"
                value={awardedBy}
                change={this.onChange}
                required={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Remarks"
                type="textarea"
                name="remarks"
                inputClass="form-control-sm"
                value={remarks}
                change={this.onChange}
              />
              <div className="file-div">
                <FormGroup className="file-display">
                  <Label className="bold label-sm">Attachment</Label>
                  <Input
                    disabled
                    type="text"
                    value={file.name}
                    className="form-control-sm"
                  />
                  {fileError && (
                    <FormText className="error ">{fileError}</FormText>
                  )}
                  {errors.fileUpload && (
                    <FormText className="error ">{errors.fileUpload}</FormText>
                  )}
                </FormGroup>
                <FormGroup className="file-browse-btn-div">
                  <label className="btn label-sm file-browse-btn">
                    Browse
                    <Input
                      type="file"
                      name="fileUpload"
                      style={{
                        display: "none",
                        marginTop: "23px",
                        marginLeft: "-28px",
                      }}
                      className="form-control-sm file-input"
                      onChange={this.handleFile}
                      accept=".pdf, .png"
                    />
                  </label>
                </FormGroup>
              </div>
            </Col>
            <Col md="3"></Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Update
            </button>
          </div>
        </Form>
        {message && <Notifier message={message} />}
        {error && <ErrorNotifier message={error} />}
      </React.Fragment>
    );
  }
}

export default UpdateHonorsAndAwards;
