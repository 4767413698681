import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Form,
  FormGroup,
} from "reactstrap";

// material ui
import { IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";

// components
import CandidateKanbanDetails from "./CandidateKanbanDetails";
import ViewKanbanCandidates from "./ViewKanbanCandidates";
import FormComponent from "../../../../common/FormComponent";
import {
  submitValidator,
  Validator,
  valid,
} from "../../../../common/Validator";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import CustTooltip from "../../../../common/tooltip/CustTooltip";
import UpdateCandidateKanban from "./UpdateCandidateKanban";

// redux
import {
  getCandidateKanban,
  updateTagRequirement,
} from "../../../../../redux/actions/rs/requirements/tagCandidate/tagCandidate";

// --------------------------------------------------------------------------------------

export class CandidateKanban extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "",
      selectedItemData: {},
      detailsPage: false,
      viewCandidates: false,
      searchTerm: "",
      draggedItemData: {},
      subItem: "",
      remarks: "",
      modal: false,
      error: "",
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.props.getCandidateKanban(this.props.reqId);
  };

  select = (item) => {
    this.setState({
      selected: item.id,
      detailsPage: true,
      selectedItemData: item,
    });
  };

  closeCandidateKanbanDetails = () => {
    this.setState({
      detailsPage: false,
    });
  };

  handleViewCandidates = () => {
    this.setState({
      viewCandidates: true,
    });
  };

  handleCloseViewCandidates = () => {
    this.setState({
      viewCandidates: false,
    });
  };

  search = (e) => {
    this.setState({
      searchTerm: e.target.value,
    });
  };

  /* 
This toggle function is for the model toggle after the submission of remarks
*/
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      remarks: "",
    });
  };

  closeToggle = () => {
    this.setState({
      modal: false,
    });
    this.getData();
  };

  onChange = (e) => {
    Validator(e.target);
    e.preventDefault();
    this.setState({
      remarks: e.target.value,
    });
  };

  onDragOver = (e) => {
    e.preventDefault();
  };

  onDragStart = (e, item) => {
    e.dataTransfer.setData("item", item.id);
    this.setState({
      draggedItemData: item,
      subItem: item.id,
    });
  };

  async onDrop(e, cat) {
    let id = e.dataTransfer.getData("item");
    let newData;
    newData =
      this.props.tagReq.canKanbanData.length > 0 &&
      this.props.tagReq.canKanbanData.filter((item) => {
        if (item.id === parseInt(id, 10)) {
          item.candidateStatus = cat;
          item.hiringStage = " ";
        }
        return newData;
      });
    this.setState({
      ...this.state,
      newData,
    });
    await this.toggle();
  }

  async onDropHiringStage(e, cat) {
    let id = e.dataTransfer.getData("item");
    let newData;
    newData =
      this.props.tagReq.canKanbanData.length > 0 &&
      this.props.tagReq.canKanbanData.filter((item) => {
        if (item.id === parseInt(id, 10)) {
          item.hiringStage = cat;
          item.candidateStatus = "Hiring Stage";
        }
        return newData;
      });
    this.setState({
      ...this.state,
      newData,
    });
    await this.toggle();
  }
  /*
To validate the remarks form during onSubmit using the subitValidator and errors function
*/
  onSubmit = (e) => {
    e.preventDefault();
    submitValidator(e.target);
    const data = this.props.tagReq.canKanbanData.filter((item) => {
      if (item.id === this.state.subItem) {
        return item;
      } else {
        return null;
      }
    });
    data[0].hiringStagefeedback = this.state.remarks;
    const reqData = {
      id: data[0].id,
      jobReqirementId: data[0].jobRequirementId,
      canUserId: data[0].canUserId,
      canInfoId: data[0].canInfoId,
      candidateSource: data[0].candidateSource,
      candidateStatus: data[0].candidateStatus,
      hiringStagefeedback: data[0].hiringStagefeedback,
      taggedBy: data[0].taggedBy,
      taggedOn: data[0].taggedOn,
      hiringStage: data[0].hiringStage,
    };
    if (valid) {
      this.props.updateTagRequirement(reqData, this.closeToggle, this.getData);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  };

  render() {
    const {
      selected,
      detailsPage,
      viewCandidates,
      searchTerm,
      modal,
      remarks,
      error,
    } = this.state;

    return (
      <>
        <Modal isOpen={modal} toggle={this.toggle}>
          <ModalHeader toggle={this.closeToggle}>Confirm changes?</ModalHeader>
          <ModalBody>
            {/* <Form className="p-3" onSubmit={this.onSubmit} noValidate>
              <FormComponent
                labelClass="bold label-sm"
                label="Comments"
                type="textarea"
                name="remarks"
                change={this.onChange}
                value={remarks}
                required={true}
              />
              <FormGroup className="center">
                <button type="submit" className="btn login-button white-text">
                  Submit
                </button>
              </FormGroup>
            </Form> */}
            <UpdateCandidateKanban
              data={this.state.draggedItemData}
              back={this.closeToggle}
            />
          </ModalBody>
        </Modal>
        <Modal
          size="xl"
          isOpen={this.props.taggedView}
          toggle={this.props.closeModel}
        >
          {!viewCandidates ? (
            <>
              {!detailsPage ? (
                <>
                  <ModalHeader>
                    <input
                      type="text"
                      onChange={this.search}
                      value={this.state.searchTerm}
                      className="canKanban-searchfield form-control-sm"
                      placeholder="Search for Candidate..."
                    />
                    <CustTooltip title="Search">
                      <IconButton
                        type="submit"
                        style={{
                          border: "none",
                          width: "3%",
                          position: "absolute",
                          top: "0.65rem",
                          right: "8%",
                          height: "1.9rem",
                          color: "#0056b3",
                          padding: ".1rem",
                        }}
                      >
                        <SearchIcon className="mui-icon-size" />
                      </IconButton>
                    </CustTooltip>
                    <CustTooltip title="View Candidates">
                      <IconButton
                        className="e360-request-eye-button"
                        color="primary"
                        onClick={this.handleViewCandidates}
                        style={{ top: "0.65rem", right: "4%" }}
                      >
                        <RemoveRedEyeIcon className="mui-icon-size" />
                      </IconButton>
                    </CustTooltip>
                    <div>
                      <i
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Close"
                        onClick={this.props.closeModel}
                        className="material-icons pointer cancel-button"
                      >
                        cancel
                      </i>
                    </div>
                    <h5 className="center bold">
                      Candidate Kanban for Req.ID : {this.props.reqId}{" "}
                    </h5>
                  </ModalHeader>
                  <ModalBody>
                    <Row className="p-1">
                      <Col
                        md="4"
                        onDragOver={this.onDragOver}
                        // onDrop={(e) => this.onDrop(e, "Tagged")}
                        className="card p-2"
                      >
                        <h6 className="center bold m-2">Applied/Tagged</h6>
                        <ul className="idea-flex-around ul-head">
                          <li className="list-label canKanbanId-width">
                            Can. Id
                          </li>
                          <li className="list-label canKanbanName-width">
                            Name
                          </li>
                          <li className="list-label canKanbanMobile-width">
                            Mobile
                          </li>
                          <li className="list-label canKanbanStatus-width">
                            Can. Status
                          </li>
                        </ul>

                        {this.props.tagReq.canKanbanData.length > 0 &&
                          this.props.tagReq.canKanbanData
                            .filter((val) => {
                              if (this.state.searchTerm == " ") {
                                return val;
                              } else if (
                                val.canInfoId.toString().includes(searchTerm) ||
                                (val.candidateStatus &&
                                  val.candidateStatus
                                    .toLowerCase()
                                    .includes(
                                      this.state.searchTerm.toLowerCase()
                                    )) ||
                                (val.firstName &&
                                  val.firstName
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase())) ||
                                (val.lastName &&
                                  val.lastName
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase())) ||
                                (val.mobileNo &&
                                  val.mobileNo.includes(searchTerm))
                              ) {
                                return val;
                              }
                            })
                            .map((item) => {
                              if (
                                item.candidateStatus &&
                                item.candidateStatus !== "Hiring Stage" 
                              ) {
                                return (
                                  <ul
                                    key={item.id}
                                    onClick={() => {
                                      this.select(item);
                                    }}
                                    // onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                                    // onMouseLeave={this.toggleHoverOff}
                                    draggable
                                    onDragStart={(e) =>
                                      this.onDragStart(e, item)
                                    }
                                    className={`list idea-flex-around pointer ${
                                      selected &&
                                      selected === item.id &&
                                      "selected-item"
                                    } InqList`}
                                  >
                                    <li className="list-data canKanbanId-width">
                                      {item.canInfoId}
                                    </li>
                                    <li className="list-data canKanbanName-width">
                                      {item.firstName}&nbsp;{item.lastName}
                                    </li>
                                    <li className="list-data canKanbanMobile-width">
                                      {item.mobileNo}
                                    </li>
                                    <li className="list-data canKanbanStatus-width">
                                      {item.candidateStatus}
                                    </li>
                                  </ul>
                                );
                              } else {
                                return null;
                              }
                            })}
                      </Col>
                      <Col
                        md="4"
                        onDragOver={this.onDragOver}
                        onDrop={(e) =>
                          this.onDropHiringStage(e, "Profile Screening")
                        }
                        className="card p-2"
                      >
                        <h6 className="center bold m-2">
                          Interview in Progress
                        </h6>
                        <ul className="idea-flex-around ul-head">
                          <li className="list-label canKanbanId-width">
                            Can. Id
                          </li>
                          <li className="list-label canKanbanName-width">
                            Name
                          </li>
                          <li className="list-label canKanbanStage-width">
                            Hiring Stage
                          </li>
                          <li className="list-label canKanbanStatus-width">
                            Status
                          </li>
                        </ul>
                        {this.props.tagReq.canKanbanData.length > 0 &&
                          this.props.tagReq.canKanbanData
                            .filter((val) => {
                              if (this.state.searchTerm == " ") {
                                return val;
                              } else if (
                                val.canInfoId.toString().includes(searchTerm) ||
                                (val.firstName &&
                                  val.firstName
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase())) ||
                                (val.lastName &&
                                  val.lastName
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase())) ||
                                (val.hiringStage &&
                                  val.hiringStage
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase())) ||
                                (val.hiringStatus &&
                                  val.hiringStatus
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase()))
                              ) {
                                return val;
                              }
                            })
                            .map((item) => {
                              if (
                                item.hiringStage &&
                                item.hiringStage !== "Offered" &&  
                                item.candidateStatus === "Hiring Stage"
                              ) {
                                return (
                                  <ul
                                    key={item.id}
                                    onClick={() => {
                                      this.select(item);
                                    }}
                                    //   onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                                    //   onMouseLeave={this.toggleHoverOff}
                                    draggable
                                    onDragStart={(e) =>
                                      this.onDragStart(e, item)
                                    }
                                    className={`list idea-flex-around pointer ${
                                      selected &&
                                      selected === item.id &&
                                      "selected-item"
                                    } InqList`}
                                  >
                                    <li className="list-data canKanbanId-width">
                                      {item.canInfoId}
                                    </li>
                                    <li className="list-data canKanbanName-width">
                                      {item.firstName}&nbsp;{item.lastName}
                                    </li>
                                    <li className="list-data canKanbanStage-width">
                                      {item.hiringStage}
                                    </li>
                                    <li className="list-data canKanbanStatus-width">
                                      {item.hiringStatus}
                                    </li>
                                  </ul>
                                );
                              } else {
                                return null;
                              }
                            })}
                      </Col>
                      <Col
                        md="4"
                        className="card p-2"
                        onDrop={(e) => this.onDropHiringStage(e, "Offered")}
                        onDragOver={this.onDragOver}
                      >
                        <h6 className="center bold m-2">Offered/Joined</h6>
                        <ul className="idea-flex-around ul-head">
                          <li className="list-label canKanbanId-width">
                            Can. Id
                          </li>
                          <li className="list-label canKanbanName-width">
                            Name
                          </li>
                          <li className="list-label canKanbanStage-width">
                            Hiring Stage
                          </li>
                          <li className="list-label canKanbanStatus-width">
                            Status
                          </li>
                        </ul>
                        {this.props.tagReq.canKanbanData.length > 0 &&
                          this.props.tagReq.canKanbanData
                            .filter((val) => {
                              if (this.state.searchTerm == " ") {
                                return val;
                              } else if (
                                val.canInfoId.toString().includes(searchTerm) ||
                                (val.firstName &&
                                  val.firstName
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase())) ||
                                (val.lastName &&
                                  val.lastName
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase())) ||
                                (val.hirignStage &&
                                  val.hiringStage
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase())) ||
                                (val.hiringStatus &&
                                  val.hiringStatus
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase()))
                              ) {
                                return val;
                              }
                            })
                            .map((item) => {
                              if (item.hiringStage === "Offered") {
                                return (
                                  <ul
                                    key={item.id}
                                    onClick={() => {
                                      this.select(item);
                                    }}
                                    // onMouseEnter={(e) => this.toggleHoverOn(e, item)}
                                    // onMouseLeave={this.toggleHoverOff}
                                    draggable
                                    onDragStart={(e) =>
                                      this.onDragStart(e, item)
                                    }
                                    className={`list idea-flex-around pointer ${
                                      selected &&
                                      selected === item.id &&
                                      "selected-item"
                                    } InqList`}
                                  >
                                    <li className="list-data canKanbanId-width">
                                      {item.canInfoId}
                                    </li>
                                    <li className="list-data canKanbanName-width">
                                      {item.firstName}&nbsp;{item.lastName}
                                    </li>
                                    <li className="list-data canKanbanStage-width">
                                      {item.hiringStage}
                                    </li>
                                    <li className="list-data canKanbanStatus-width">
                                      {item.hiringStatus}
                                    </li>
                                  </ul>
                                );
                              }
                            })}
                      </Col>
                      {/* {this.state.message && <Notifier message={this.state.message} />}
              {this.state.error && <ErrorNotifier message={this.state.error} />} */}
                    </Row>
                  </ModalBody>
                </>
              ) : (
                <CandidateKanbanDetails
                  canTrackId={this.state.selected}
                  back={this.closeCandidateKanbanDetails}
                  data={this.state.selectedItemData}
                />
              )}
            </>
          ) : (
            <ViewKanbanCandidates
              closeModal={this.handleCloseViewCandidates}
              data={this.props.tagReq.canKanbanData}
            />
          )}
        </Modal>
        {error && <ErrorNotifier message={error} />}
      </>
    );
  }
}

CandidateKanban.propTypes = {
  getCandidateKanban: PropTypes.func.isRequired,
  updateTagRequirement: PropTypes.func.isRequired,
  tagReq: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  tagReq: state.tagReq,
});

const mapDispatchToProps = (dispatch) => ({
  getCandidateKanban: (reqId) => dispatch(getCandidateKanban(reqId)),
  updateTagRequirement: (reqData, back) =>
    dispatch(updateTagRequirement(reqData, back)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CandidateKanban);
