import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Form } from "reactstrap";
// components
import { textChecker } from "../../../../common/FormValidator";
import FormComponent from "../../../../common/FormComponent";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import {
  submitValidator,
  valid,
  Validator,
  refresh,
} from "../../../../common/Validator";
// redux
import { addCompanyHolidays } from "../../../../../redux/actions/hcm/configurations/companyHolidaysAction";

// --------------------------------------------------------------------------------------------------------------
class AddHoliday extends Component {
  constructor() {
    super();
    this.state = {
      date: "",
      day: "",
      occasion: "",
      message: "",
      error: "",
      formErrors: {
        day: "",
        occasion: "",
      },
    };
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  /* This onBlur function is giving validation to the AddHoliday form
    for condition checking we used switch conditions
*/
  onBlur(e) {
    const { name, value } = e.target;
    let errors = this.state.formErrors;

    switch (name) {
      case "day":
        errors.day = textChecker.test(value)
          ? ""
          : "Use alphabets , numbers or spaces";
        break;
      case "occasion":
        errors.occasion = textChecker.test(value)
          ? ""
          : "Use alphabets , numbers or spaces";
        break;
      default:
        break;
    }
    this.setState({
      formErrors: errors,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      holidayDate: this.state.date,
      holidayOccasion: this.state.occasion,
      holidayDay: this.state.day,
    };
    if (valid) {
      this.props.addCompanyHolidays(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    refresh();
  }
  render() {
    const { day, occasion } = this.state.formErrors;

    return (
      <React.Fragment>
        <h5 className="bold center pt-2">Add Company Holiday</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>

        <Row>
          <Col md="2"></Col>
          <Col md="8">
            <Form className="p-3" onSubmit={this.onSubmit} noValidate>
              <FormComponent
                labelClass="bold label-sm"
                label="Date of leave"
                type="date"
                name="date"
                inputClass="form-control-sm"
                value={this.state.date}
                change={this.onChange}
                required={true}
                max="9999-12-31"
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Day of leave"
                type="text"
                name="day"
                inputClass="form-control-sm"
                error={day}
                value={this.state.day}
                change={this.onChange}
                blur={this.onBlur}
                required={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Occasion"
                type="text"
                name="occasion"
                inputClass="form-control-sm"
                error={occasion}
                value={this.state.occasion}
                change={this.onChange}
                blur={this.onBlur}
                required={true}
              />
              <div className="center">
                <button type="submit" className="btn login-button white-text">
                  Submit
                </button>
              </div>
            </Form>
          </Col>
        </Row>
        {this.props.companyHolidays.message && (
          <Notifier message={this.props.companyHolidays.message} />
        )}
        {this.props.companyHolidays.error && (
          <ErrorNotifier message={this.props.companyHolidays.error} />
        )}
      </React.Fragment>
    );
  }
}

AddHoliday.propTypes = {
  addCompanyHolidays: PropTypes.func.isRequired,
  companyHolidays: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  companyHolidays: state.companyHolidays,
});

export default connect(mapStateToProps, { addCompanyHolidays })(AddHoliday);
