import {
    MANAGERRS_DASHBOARD_GET,
    RS_DASHBOARD_GET_FULL_DETAILS,
    RS_DASHBOARD_APPLICANTS_DETAILS,
    RS_DASHBOARD_POSITION_DEPARTMENT,
    RS_DASHBOARD_POSITION_APPLICANTS,
    RS_DASHBOARD_FUNNEL,
    RS_DASHBOARD_APPLICANTS_TABLE_DETAILS,
    RS_MANAGER_DASHBOARD_RATIO,
    MANAGER_RS_DASHBOARD_GET,
    MANAGER_RS_DASHBOARD_GET_FULL_DETAILS,
    MANAGER_RS_DASHBOARD_APPLICANTS_DETAILS,
    MANAGER_RS_DASHBOARD_POSITION_DEPARTMENT,
    MANAGER_RS_DASHBOARD_POSITION_APPLICANTS,
    MANAGER_RS_DASHBOARD_FUNNEL,
    RS_MANAGER_DASHBOARD_PROFILE_SCREENING,
    RS_MANAGER_DASHBOARD_SOURCE,
    GET_ERRORS,
    SET_MESSAGE,
  } from "../../../actions/types";
  
  const initialState = {
    data: [],
    message: null,
    error: null,
    mainData:[],
    applicantsData:[],
    positionData:[],
    positionApplicants:[],
    managerSourceData:[],
    funnelData:[],
    profileScreeningData:[],
    tableData:[],
    ratioData:[]
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case  MANAGER_RS_DASHBOARD_GET:
        return {
          ...state,
          data: action.payload,
        };

        case  RS_MANAGER_DASHBOARD_RATIO:
          return{
            ...state,
            ratioData:action.payload
          };

        case   MANAGER_RS_DASHBOARD_APPLICANTS_DETAILS:
          return {
            ...state,
            applicantsData: action.payload,
          };

      case  MANAGER_RS_DASHBOARD_GET_FULL_DETAILS:
        return{
          ...state,
          mainData:action.payload
        }
        case  MANAGER_RS_DASHBOARD_POSITION_DEPARTMENT:
          return{
            ...state,
            positionData:action.payload
          }
          case  MANAGER_RS_DASHBOARD_POSITION_APPLICANTS:
            return{
              ...state,
              positionApplicants:action.payload
            }
            case   MANAGER_RS_DASHBOARD_FUNNEL:
              return{
                ...state,
                funnelData:action.payload
              }

              case  RS_DASHBOARD_APPLICANTS_TABLE_DETAILS:
                return{
                  ...state,
                  tableData:action.payload
                }

                case  RS_MANAGER_DASHBOARD_PROFILE_SCREENING:
                  return{
                    ...state,
                    profileScreeningData:action.payload
                  }

                  case   RS_MANAGER_DASHBOARD_SOURCE:
                    return{
                      ...state,
                      managerSourceData:action.payload
                    }
      
      case SET_MESSAGE:
        return {
          ...state,
          message: action.payload,
        };
      case GET_ERRORS:
        return {
          ...state,
          error: action.payload,
        };
      default:
        return state;
    }
  }