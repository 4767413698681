import React, { Component } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { Card } from "reactstrap";
import axios from "axios";
import { GET_TOP_UP_SELLES } from "../../../../utils/routes";

const data = [
  {
    name: "Rajesh",
    "Planned Budget": 4000,
    "Actual Spend": 2400,
    amt: 2400,
  },
  {
    name: "Govindh",
    "Planned Budget": 3000,
    "Actual Spend": 1398,
    amt: 2210,
  },
  {
    name: "Ramesh",
    "Planned Budget": 2000,
    "Actual Spend": 800,
    amt: 2290,
  },
];

const xAxisStyle = {
  fontSize: "8px",
  fontWeight: "400",
  marginBottom: "10px",
};

export default class PlannedVsActualSpend extends Component {
  static jsfiddleUrl = "https://jsfiddle.net/alidingling/30763kr7/";

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      error: "",
    };
  }

  componentDidMount() {
    axios
      .get(GET_TOP_UP_SELLES, { withCredentials: true })
      .then((res) => {
        this.setState({
          data: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          error: err,
        });
      });
  }

  render() {
    // const { data } = this.state;

    return (
      <Card className="recharts-card">
        <BarChart
          width={310}
          height={150}
          data={data}
          margin={{
            top: 5,
            right: 35,
            left: -15,
            bottom: 5,
          }}
          barSize={15}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            tick={{ fontSize: "10px", fontWeight: "400" }}
          />
          <YAxis
            tick={{ fontSize: "10px", fontWeight: "400" }}
            domain={[0, "dataMax"]}
          />
          <Tooltip contentStyle={{ fontSize: "10px", fontWeight: "400" }} />
          <Legend iconSize="8px" wrapperStyle={xAxisStyle} />
          <Bar dataKey="Planned Budget" barSize={15} fill="#8884d8" />
          <Bar dataKey="Actual Spend" barSize={15} fill="#82ca9d" />
        </BarChart>
      </Card>
    );
  }
}
