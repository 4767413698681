import React, { Component } from "react";
import { Container, Table } from "reactstrap";
import AddSuccessCriteria from "./AddSuccessCriteria";
import UpdateSuccessCriteria from "./UpdateSuccessCriteria";
import Notifier from "../../../../../aside/Notifier";
import ErrorNotifier from "../../../../../aside/ErrorNotifier";
import {
  getSuccessCriteria,
  deleteSuccessCriteria,
} from "../../../../../../redux/actions/campaignSuccessCriteriaAction";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class SuccessCriteria extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addBtn: false,
      updateBtn: false,
      updateData: [],
    };

    this.toggleAddBtn = this.toggleAddBtn.bind(this);
    this.addBack = this.addBack.bind(this);
    this.toggleUpdateBtn = this.toggleUpdateBtn.bind(this);
    this.updateBack = this.updateBack.bind(this);
    this.getData = this.getData.bind(this);
    this.deleteSuccessCriteria = this.deleteSuccessCriteria.bind(this);
  }

  /*
toggleAddBtn function to toggle the addition page
*/
  toggleAddBtn() {
    this.setState({
      addBtn: true,
    });
  }

  /*
addBack function to back to the list page from the add page
*/
  addBack() {
    this.setState({
      addBtn: false,
    });

    this.getData();
  }

  /*
toggleUpdateBtn function to toggle the update form
*/
  toggleUpdateBtn(item) {
    this.setState({
      updateBtn: true,
      updateData: item,
    });
  }

  /*
updateBack function to redirect to back main page
*/
  updateBack() {
    this.setState({
      updateBtn: false,
    });

    this.getData();
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.props.getSuccessCriteria();
  }

  deleteSuccessCriteria(id) {
    let data = {
      successCriteriaId: id,
    };
    this.props.deleteSuccessCriteria(data);
  }

  render() {
    const { addBtn, updateBtn, updateData } = this.state;

    return (
      <Container className="card container-card">
        {!addBtn && !updateBtn ? (
          <React.Fragment>
            <button
              className="btn add-button white-text label-sm"
              onClick={this.toggleAddBtn}
            >
              Add
            </button>
            <h5 className="bold center pt-2">Success Criteria</h5>
            <i
              className="material-icons cancel-button pointer"
              onClick={this.props.back}
            >
              cancel
            </i>
            <Table className="center">
              <thead>
                <tr>
                  <th className="opp-head ">ID</th>
                  <th className="opp-head ">Success Criteria</th>
                  <th className="opp-head ">Description</th>
                  <th className="opp-head ">CreatedBy</th>
                  <th className="opp-head ">CreatedOn</th>
                  <th className="opp-head ">UpdatedBy</th>
                  <th className="opp-head ">UpdatedOn</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.props.campaignSuccessCriteria.data &&
                  this.props.campaignSuccessCriteria.data.map((item) => {
                    return (
                      <tr>
                        <td className="opp-tableData">
                          {item.successCriteriaId}
                        </td>
                        <td className="opp-tableData">
                          {item.successCriteria}
                        </td>
                        <td className="opp-tableData">{item.description}</td>
                        <td className="opp-tableData">{item.createdBy}</td>
                        <td className="opp-tableData">{item.createdOn}</td>
                        <td className="opp-tableData">{item.updatedBy}</td>
                        <td className="opp-tableData">{item.updatedOn}</td>
                        <td>
                          <span
                            className="material-icons pointer opp-action-edit"
                            style={{ color: "blue" }}
                            onClick={() => this.toggleUpdateBtn(item)}
                          >
                            edit
                          </span>
                        </td>
                        <td>
                          <i
                            className="material-icons pointer"
                            onClick={() =>
                              this.deleteSuccessCriteria(item.successCriteriaId)
                            }
                          >
                            delete
                          </i>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </React.Fragment>
        ) : null}
        {addBtn && <AddSuccessCriteria back={this.addBack} />}
        {updateBtn && (
          <UpdateSuccessCriteria back={this.updateBack} data={updateData} />
        )}
        {this.props.campaignSuccessCriteria.message && (
          <Notifier message={this.props.campaignSuccessCriteria.message} />
        )}
        {this.props.campaignSuccessCriteria.error && (
          <ErrorNotifier message={this.props.campaignSuccessCriteria.error} />
        )}
      </Container>
    );
  }
}

SuccessCriteria.propTypes = {
  getSuccessCriteria: PropTypes.func.isRequired,
  deleteSuccessCriteria: PropTypes.func.isRequired,
  campaignSuccessCriteria: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  campaignSuccessCriteria: state.campaignSuccessCriteria,
});

export default connect(mapStateToProps, {
  getSuccessCriteria,
  deleteSuccessCriteria,
})(SuccessCriteria);
