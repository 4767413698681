import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import {
  FETCH_PROJECT_ACTIVITY,
  UPDATE_TIMESHEET,
  ALL_PROJECTS,
  SEARCH_TIME_MANAGEMENT,
} from "../../../utils/routes";
import Notifier from "../../aside/Notifier";
import ErrorNotifier from "../../aside/ErrorNotifier";
import { getProjectTeamAssignments } from "../../../redux/actions/projectTeamAssignmentAction";
import { getServiceTeamAssignments } from "../../../redux/actions/serviceTeamAssignmentAction";
import { getServices } from "../../../redux/actions/serviceAction";

class ProjectRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permissionlist: this.props.auth.permissionlist || {},
      searchTerm: "",
      projectAssign: "",
      serviceAssign: "",
      projCode: this.props.project.projCode,
      message: "",
      error: "",
      count: 1,
      data: [],
      projData: [],
    };
    // this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.searchProject = this.searchProject.bind(this);
    this.getData = this.getData.bind(this);
    this.getProjectActivityData = this.getProjectActivityData.bind(this);
  }

  getData() {
    axios.get(ALL_PROJECTS, { withCredentials: true }).then((res) => {
      this.setState({
        data: res.data,
      });
    });
  }

  componentDidMount() {
    console.log(this.props.project.projCode);
    console.log("Hiii");
    this.setState({
      permissionlist: this.props.auth.permissionlist,
    });
    this.getData();
    this.props.getServices();
    this.props.getProjectTeamAssignments();
    this.props.projectTeamAssignment.data.map((item) => {
      if (item.empId === localStorage.getItem("id")) {
        this.setState({
          projectAssign: true,
        });
      }
      return null;
    });

    this.props.getServiceTeamAssignments();
    this.props.serviceTeamAssignment.data.map((item1) => {
      if (item1.assignedTo === localStorage.getItem("id")) {
        this.setState({
          serviceAssign: true,
        });
      }
      return null;
    });

    this.getProjectActivityData();
  }

  getProjectActivityData() {
    let data = {
      projCode: this.state.projCode,
    };

    axios
      .post(FETCH_PROJECT_ACTIVITY, data, { withCredentials: true })
      .then((res) => {
        this.setState({
          projData: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          error: "Fetch Failed",
        });
      });
    setTimeout(() => {
      this.setState({
        message: "",
        error: "",
      });
    }, 2000);
  }

  componentDidUpdate(prevProps) {
    console.log("this is timesheet projectRow componentDidUpdate");
    if (prevProps.auth !== this.props.auth) {
      this.setState({
        permissionlist: this.props.auth.permisssionlist,
      });
    }
  }

  forceRefresh = () => {
    this.setState({ ...this.state });
  };

  searchProject(e) {
    const data = {
      projectName: e.target.value,
    };
    axios
      .post(SEARCH_TIME_MANAGEMENT, data, { withCredentials: true })
      .then((res) => {
        this.setState({
          searchData: res.data[0],
        });
      })
      .catch((err) => {
        console.log(err);
      });

    if (this.state.projCode !== this.props.project.projCode) {
      console.log("to get the activity data");
      this.getProjectActivityData();
    }
  }

  onSave() {
    const {
      projCode,
      task,
      serviceName,
      workShift,
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      timesheetId,
    } = this.props.project;
    const date = new Date();
    const data = {
      empId: localStorage.getItem("id"),
      timesheetId: timesheetId,
      projCode: projCode || "",
      serviceName: serviceName || "",
      task: task || "",
      workShift: workShift || "",
      monday: monday || "",
      tuesday: tuesday || "",
      wednesday: wednesday || "",
      thursday: thursday || "",
      friday: friday || "",
      saturday: saturday || "",
      sunday: "0",
      timeDate: date,
      taskHours: "10:00",
      weekNo: this.props.weekNo,
      projectHours: "10:00",
      timeRemarks: "test",
      billableStatus: "yes",
      status: "New",
    };
    axios
      .post(UPDATE_TIMESHEET, data, { withCredentials: true })
      .then((res) => {
        this.setState({
          message: res.data,
        });
        setTimeout(() => {
          this.setState({
            message: "",
          });
          this.props.fetchTimeSheetData();
        }, 5000);
      })
      .catch((err) => {
        this.setState({ error: "Submission Failed" });
        setTimeout(() => {
          this.setState({
            error: "",
          });
        }, 5000);
      });
  }

  render() {
    const { permissionlist } = this.state;
    const {
      projCode,
      serviceName,
      task,
      workShift,
      sunday,
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      status,
    } = this.props.project;
    let currentPermission;
    let projectNameSelect = projCode || "";
    let serviceNameSelect = serviceName || "ServiceName";
    let taskSelect = task || "Task";
    let workShiftSelect = workShift || "Shift";

    for (let x in permissionlist.response.permissions) {
      if (permissionlist.response.permissions[x].groupId === "4.6") {
        currentPermission = permissionlist.response.permissions[x];
      }
    }

    return (
      <tr style={{ background: "#ffffcc" }}>
        <td></td>
        <td className="center label-sm bold">
          {this.state.projectAssign &&
          (projCode || Object.keys(this.props.project).length === 1) ? (
            <select
              className="timesheet-input"
              name="projCode"
              value={projectNameSelect}
              style={{ width: "auto" }}
              onChange={(e) =>
                this.props.handleChange(
                  e,
                  this.props.identity,
                  this.forceRefresh
                )
              }
              disabled={status === "submited" ? true : false}
            >
              <option hidden>ProjectName</option>
              {this.props.projectTeamAssignment.data.map((item1) => {
                if (item1.empId === localStorage.getItem("id")) {
                  return this.state.data.map((item) => {
                    if (item.projCode === item1.projCode) {
                      return item.projectName ? (
                        <option key={item.projCode} value={item.projCode}>
                          {item.projCode}-{item.projectName}
                        </option>
                      ) : null;
                    }
                    return null;
                  });
                }
                return null;
              })}
            </select>
          ) : null}
          {this.state.serviceAssign &&
          (serviceName || Object.keys(this.props.project).length === 1) ? (
            <select
              className="timesheet-input"
              name="serviceName"
              value={serviceNameSelect}
              style={{ width: "auto" }}
              onChange={(e) =>
                this.props.handleChange(
                  e,
                  this.props.identity,
                  this.forceRefresh
                )
              }
              disabled={status === "submited" ? true : false}
            >
              <option value="ServiceName" disabled>
                ServiceName
              </option>
              {this.props.serviceTeamAssignment.data.map((item1) => {
                if (item1.assignedTo === localStorage.getItem("id")) {
                  return this.props.services.data.map((item) => {
                    if (item.serviceId === item1.serviceId) {
                      return (
                        <option key={item.serviceId} value={item.serviceName}>
                          {item.serviceId}-{item.serviceName}
                        </option>
                      );
                    }
                    return null;
                  });
                }
                return null;
              })}
            </select>
          ) : null}
        </td>
        <td className="center label-sm bold">
          {/* <Select placeholder="Task"  */}
          {/* styles={customStyles} value={selectedOptionTask} name="taskOptions" onChange={this.taskHandleChange} options={taskOptions} required />  */}
          <select
            className="timesheet-input"
            name="task"
            style={{ width: "auto" }}
            value={taskSelect}
            onChange={(e) =>
              this.props.handleChange(e, this.props.identity, this.forceRefresh)
            }
            disabled={status === "submited" ? true : false}
          >
            <option vaule="Task" disabled>
              Task
            </option>
            {this.state.projData &&
              this.state.projData.map((item) => {
                return (
                  <option key={item.projActivityId} value={item.projActivity}>
                    {item.projActivity}
                  </option>
                );
              })}

            {/* <option value="Requirement Analysis">Requirement Analysis</option>
            <option value="Design">Design</option>
            <option value="Development">Development</option>
            <option vaule="Testing">Testing</option>
            <option value="Implementation">Implementation</option>
            <option value="Support">Support</option> */}
          </select>
        </td>
        <td className="center label-sm bold">
          <select
            className="timesheet-input"
            name="workShift"
            value={workShiftSelect}
            onChange={(e) =>
              this.props.handleChange(e, this.props.identity, this.forceRefresh)
            }
            disabled={status === "submited" ? true : false}
          >
            <option value="Shift" disabled>
              Shift
            </option>
            <option value="Day">Day</option>
            <option value="Night">Night</option>
          </select>
        </td>
        <td className="center label-sm bold">
          <input
            type="number"
            name="sunday"
            className={"timesheet-input-day"}
            onChange={(e) =>
              this.props.handleChange(e, this.props.identity, this.forceRefresh)
            }
            value={sunday}
            disabled
          />
        </td>
        <td className="center label-sm bold">
          <input
            name="monday"
            type="number"
            className={"timesheet-input-day "}
            onChange={(e) =>
              this.props.handleChange(e, this.props.identity, this.forceRefresh)
            }
            value={monday}
            disabled={status === "submited" ? true : false}
          />
        </td>
        <td className="center label-sm bold">
          <input
            type="number"
            name="tuesday"
            className={"timesheet-input-day "}
            onChange={(e) =>
              this.props.handleChange(e, this.props.identity, this.forceRefresh)
            }
            value={tuesday}
            disabled={status === "submited" ? true : false}
          />
        </td>
        <td className="center label-sm bold">
          <input
            type="number"
            name="wednesday"
            className={"timesheet-input-day"}
            onChange={(e) =>
              this.props.handleChange(e, this.props.identity, this.forceRefresh)
            }
            value={wednesday}
            disabled={status === "submited" ? true : false}
          />
        </td>
        <td className="center label-sm bold">
          <input
            type="number"
            name="thursday"
            className={"timesheet-input-day "}
            onChange={(e) =>
              this.props.handleChange(e, this.props.identity, this.forceRefresh)
            }
            value={thursday}
            disabled={status === "submited" ? true : false}
          />
        </td>
        <td className="center label-sm bold">
          <input
            type="number"
            name="friday"
            className={"timesheet-input-day "}
            onChange={(e) =>
              this.props.handleChange(e, this.props.identity, this.forceRefresh)
            }
            value={friday}
            disabled={status === "submited" ? true : false}
          />
        </td>
        <td className="center label-sm bold">
          <input
            type="number"
            name="saturday"
            className={"timesheet-input-day "}
            onChange={(e) =>
              this.props.handleChange(e, this.props.identity, this.forceRefresh)
            }
            value={saturday}
            disabled={status === "submited" ? true : false}
          />
        </td>
        {currentPermission.create1 ? (
          <td>
            {status !== "submited" ? (
              <span
                className="timesheet-button save-button"
                onClick={this.onSave}
              >
                Save
              </span>
            ) : null}
          </td>
        ) : null}
        {this.state.message && <Notifier message={this.state.message} />}
        {this.state.error && <ErrorNotifier message={this.state.error} />}
      </tr>
    );
  }
}

ProjectRow.propTypes = {
  auth: PropTypes.object.isRequired,
  getProjectTeamAssignments: PropTypes.func.isRequired,
  projectTeamAssignment: PropTypes.object.isRequired,
  getServiceTeamAssignments: PropTypes.func.isRequired,
  serviceTeamAssignment: PropTypes.object.isRequired,
  getServices: PropTypes.func.isRequired,
  services: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  projectTeamAssignment: state.projectTeamAssignment,
  serviceTeamAssignment: state.serviceTeamAssignment,
  services: state.services,
});

export default connect(mapStateToProps, {
  getProjectTeamAssignments,
  getServiceTeamAssignments,
  getServices,
})(ProjectRow);
