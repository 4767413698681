import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  FormText,
} from "reactstrap";
import { updateIdea, getIdea } from "../../../../redux/actions/ideaActions";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../common/Validator";
import FormComponent from "../../../common/FormComponent";
import DocumentUpload from "./DocumentUpload";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import { getIdeaStatus } from "../../../../redux/actions/ideaStatusAction";

class ViewIdea extends Component {
  constructor(props) {
    super(props);
    const {
      ideaCode,
      businessDesc,
      useCase,
      solution,
      customers,
      remarks,
      problemStmt,
      benefits,
      status,
      creatorId,
      createdOn,
      updatedBy,
      modifiedOn,
    } = this.props.idea;
    this.state = {
      permissionlist: this.props.auth.permissionlist || {},
      id: ideaCode,
      useCase: useCase,
      customers: customers,
      problem: problemStmt,
      benefits: benefits,
      solution: solution,
      businessDesc: businessDesc,
      status: status,
      remarks: remarks,
      creatorId: creatorId,
      createdOn: createdOn,
      updatedBy: updatedBy,
      modifiedOn: modifiedOn,
      edit: false,
      message: null,
      error: null,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.message) {
      setTimeout(() => {
        this.props.back();
      }, 2000);
    }
    if (prevProps.auth !== this.props.auth) {
      this.setState({
        permissionlist: this.props.auth.permissionlist,
      });
    }
  }

  onChange(e) {
    e.preventDefault();
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let idea = {
      ideaCode: this.state.id,
      problemStmt: this.state.problem,
      benefits: this.state.benefits,
      status: this.state.status,
      remarks: this.state.remarks,
      businessDesc: this.state.businessDesc,
      useCase: this.state.useCase,
      solution: this.state.solution,
      customers: this.state.customers,
      updatedBy: localStorage.getItem("id"),
    };

    if (valid) {
      this.props.updateIdea(idea, this.props.history);
      setTimeout(() => {
        this.props.cancel();
      }, 2000);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 5000);
    }
  }

  componentDidMount() {
    this.setState({
      permissionlist: this.props.auth.permissionlist,
    });

    refresh();
    this.props.getIdeaStatus();
  }

  render() {
    const { permissionlist } = this.state;

    let currentPermission;
    for (let x in permissionlist.response.permissions1) {
      if (permissionlist.response.permissions1[x].subMenuId === "1.2") {
        currentPermission = permissionlist.response.permissions1[x];
      }
    }

    return (
      <Container className="card container-card p-3">
        {!this.state.edit ? (
          <Col md="12">
            <i
              className="material-icons pointer cancel-button"
              onClick={() => this.props.cancel()}
            >
              cancel
            </i>
            <h5 className="bold center mb-2">Idea Details</h5>
            <Row>
              <Col md="6">
                <ul style={{ listStyle: "none" }}>
                  <li>
                    <span className="label-sm bold idea-span-label-col-1">
                      Problem statement
                    </span>
                    &nbsp;&nbsp;:&nbsp;&nbsp;
                    <span className="label-sm idea-span-data-col-1">
                      {this.state.problem}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold idea-span-label-col-1">
                      Use Cases
                    </span>
                    &nbsp;&nbsp;:&nbsp;&nbsp;
                    <span className="label-sm idea-span-data-col-1">
                      {this.state.useCase}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold idea-span-label-col-1">
                      Benefits
                    </span>
                    &nbsp;&nbsp;:&nbsp;&nbsp;
                    <span className="label-sm idea-span-data-col-1">
                      {this.state.benefits}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold idea-span-label-col-1">
                      Solution
                    </span>
                    &nbsp;&nbsp;:&nbsp;&nbsp;
                    <span className="label-sm idea-span-data-col-1">
                      {this.state.solution}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold idea-span-label-col-1">
                      Status
                    </span>
                    &nbsp;&nbsp;:&nbsp;&nbsp;
                    <span className="label-sm ">{this.state.status}</span>
                  </li>
                  <li>
                    <span className="label-sm bold idea-span-label-col-1">
                      Remarks
                    </span>
                    &nbsp;&nbsp;:&nbsp;&nbsp;
                    <span className="label-sm idea-span-data-col-1">
                      {this.state.remarks}
                    </span>
                  </li>
                </ul>
              </Col>
              <Col md="6">
                <ul style={{ listStyle: "none" }}>
                  <li>
                    <span className="label-sm bold idea-span-label-col-2">
                      Business Description
                    </span>
                    &nbsp;&nbsp;:&nbsp;&nbsp;
                    <span className="label-sm idea-span-data-col-2">
                      {this.state.businessDesc}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold idea-span-label-col-2">
                      Targeted Customers
                    </span>
                    &nbsp;&nbsp;:&nbsp;&nbsp;
                    <span className="label-sm idea-span-data-col-2">
                      {this.state.customers}
                    </span>
                  </li>

                  <li>
                    <span className="label-sm bold idea-span-label-col-2">
                      Created By
                    </span>
                    &nbsp;&nbsp;:&nbsp;&nbsp;
                    <span className="label-sm ">{this.state.creatorId}</span>
                  </li>
                  <li>
                    <span className="label-sm bold idea-span-label-col-2">
                      Created On
                    </span>
                    &nbsp;&nbsp;:&nbsp;&nbsp;
                    <span className="label-sm ">{this.state.createdOn}</span>
                  </li>
                  <li>
                    <span className="label-sm bold idea-span-label-col-2">
                      Updated By
                    </span>
                    &nbsp;&nbsp;:&nbsp;&nbsp;
                    <span className="label-sm ">{this.state.updatedBy}</span>
                  </li>
                  <li>
                    <span className="label-sm bold idea-span-label-col-2">
                      Modified On
                    </span>
                    &nbsp;&nbsp;:&nbsp;&nbsp;
                    <span className="label-sm ">{this.state.modifiedOn}</span>
                  </li>
                </ul>
              </Col>
            </Row>
            {currentPermission.update1 ? (
              <div className="center">
                {this.state.status === "New" ||
                this.state.status === "Evolution" ? (
                  <button
                    className="btn login-button white-text m-2"
                    onClick={() => this.setState({ edit: !this.state.edit })}
                  >
                    Update
                  </button>
                ) : null}
              </div>
            ) : null}
            <hr />
            <DocumentUpload idea={this.props.idea.ideaCode} />
          </Col>
        ) : (
          <Form
            onSubmit={this.onSubmit}
            className="p-3"
            style={{ position: "relative" }}
            noValidate
          >
            <i
              className="material-icons pointer cancel-button "
              onClick={() => this.setState({ edit: !this.state.edit })}
            >
              cancel
            </i>
            <h5 className="center bold">Update Idea</h5>
            <Row>
              <Col md="6">
                <FormComponent
                  labelClass="bold label-sm"
                  label="Business Description"
                  type="text"
                  name="businessDesc"
                  value={this.state.businessDesc}
                  change={this.onChange}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Problem Statement"
                  type="text"
                  name="problem"
                  value={this.state.problem}
                  change={this.onChange}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Solution"
                  type="text"
                  name="solution"
                  value={this.state.solution}
                  change={this.onChange}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Benefits"
                  type="text"
                  name="benefits"
                  value={this.state.benefits}
                  change={this.onChange}
                  required={true}
                />
              </Col>
              <Col md="6">
                <FormComponent
                  labelClass="bold label-sm"
                  label="Use Case"
                  type="text"
                  name="useCase"
                  value={this.state.useCase}
                  change={this.onChange}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Targeted Customers"
                  type="text"
                  name="customers"
                  value={this.state.customers}
                  change={this.onChange}
                  required={true}
                />
                <FormGroup>
                  <Label className="label-sm bold">Status</Label>
                  <Input
                    name="status"
                    type="select"
                    value={this.state.status}
                    onChange={this.onChange}
                    className="form-control-sm"
                    required={true}
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.ideaStatus.data.map((item) => {
                      return (
                        <option key={item.ideaStatusId} value={item.ideaStatus}>
                          {item.ideaStatus}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.status && (
                    <FormText className="error">{errors.status}</FormText>
                  )}
                </FormGroup>
                <FormComponent
                  labelClass="bold label-sm"
                  label="Remarks"
                  type="textarea"
                  name="remarks"
                  value={this.state.remarks}
                  change={this.onChange}
                  required={true}
                />
              </Col>
            </Row>
            <div className="center">
              <button type="submit" className="btn btn-custom">
                Update
              </button>
            </div>
          </Form>
        )}
        {this.props.idea.message && (
          <Notifier message={this.props.idea.message} />
        )}
        {this.state.error && <ErrorNotifier message={this.state.error} />}
      </Container>
    );
  }
}

ViewIdea.propTypes = {
  idea: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  updateIdea: PropTypes.func.isRequired,
  getIdea: PropTypes.func.isRequired,
  getIdeaStatus: PropTypes.func.isRequired,
  ideaStatus: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  msg: state.idea,
  auth: state.auth,
  ideaStatus: state.ideaStatus,
});

export default connect(mapStateToProps, { updateIdea, getIdea, getIdeaStatus })(
  ViewIdea
);
