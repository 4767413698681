import axios from "axios";
import { LOGIN_DETAILS, IS_LOGGEDIN } from "../../types";
import {
  LOGIN,
  LOGOUT,
  GET_EMP_ACCESS_AND_PERMISSION_DETAILS,
  EMP_IS_LOGGEDIN,
  RE_SEND_EMAIL,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const login = (data, history) => (dispatch) => {
  axios
    .post(LOGIN, data, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(LOGIN_DETAILS, res));
      dispatch(empIsLoggedIn);
      history.push("/pie");
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const logout = () => (dispatch) => {
  axios
    .post(LOGOUT, null, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, empIsLoggedIn));
      window.location.assign("/");
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getEmpAccessAndPermissionDetails = () => (dispatch) => {
  axios
    .get(GET_EMP_ACCESS_AND_PERMISSION_DETAILS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(LOGIN_DETAILS, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const empIsLoggedIn = () => (dispatch) => {
  axios
    .get(EMP_IS_LOGGEDIN, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(IS_LOGGEDIN, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const resendEmail = (data) => (dispatch) => {
  axios
    .post(RE_SEND_EMAIL, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
