import axios from "axios";
import { PERSONAL_STATUS_GET } from "../../types";
import {
  CURD_PERSONAL_STATUS,
  USER_PERSONAL_STATUS,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addPersonalStatus = (data, back) => (dispatch) => {
  axios
    .post(CURD_PERSONAL_STATUS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getPersonalStatus));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getPersonalStatus = () => (dispatch) => {
  axios
    .get(CURD_PERSONAL_STATUS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(PERSONAL_STATUS_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserPersonalStatus = () => (dispatch) => {
  axios
    .get(USER_PERSONAL_STATUS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(PERSONAL_STATUS_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updatePersonalStatus = (data, back) => (dispatch) => {
  axios
    .put(CURD_PERSONAL_STATUS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getPersonalStatus));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deletePersonalStatus = (data) => (dispatch) => {
  axios
    .delete(CURD_PERSONAL_STATUS, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getPersonalStatus));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
