import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card } from "reactstrap";
import { searchJob, getJob } from "../../../../redux/actions/jobAction";
import JobDetails from "./JobDetails";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";

class Jobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.job.data || [],
      permissionlist: this.props.auth.permissionlist || {},
      selection: null,
      message: "",
      searchTerm: "",
      error: "",
    };
    this.getData = this.getData.bind(this);
    this.select = this.select.bind(this);
    this.back = this.back.bind(this);
    this.search = this.search.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.job !== this.props.job) {
      this.setState({
        data: this.props.job.data,
      });
    }
    if (prevProps.auth !== this.props.auth) {
      this.setState({
        permissionlist: this.props.auth.permissionlist,
      });
    }
  }
  select(item) {
    this.setState({
      selection: item,
    });
  }
  search(e) {
    e.preventDefault();
    this.setState({
      searchTerm: e.target.value,
    });
    this.onSearch(e.target.value);
  }
  onSearch(value) {
    // Number datatype goes in jobCode and String datatype goes in jobRole
    // We cannot send both Jobcode and Jobrole value at the same time we have
    // to send either one of them as empty or zero value

    let data = {};
    let isInteger = value.length && /^[0-9]*$/.test(value);
    if (isInteger) {
      data.jobCode = Number(value);
      data.jobRole = "null";
    } else {
      data.jobCode = 0;
      data.jobRole = value;
    }
    this.props.searchJob(data);
  }
  back() {
    this.setState({
      selection: false,
    });
    this.getData();
  }
  componentDidMount() {
    this.getData();
  }
  getData() {
    this.props.getJob();
  }
  render() {
    const { data, selection, permissionlist } = this.state;
    let shrink;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");
    let currentPermission;

    for (let x in permissionlist.response.permissions1) {
      if (permissionlist.response.permissions1[x].subMenuId === "9.6") {
        currentPermission = permissionlist.response.permissions1[x];
      }
    }

    return (
      <div className={shrink}>
        <Container className="card container-card">
          {currentPermission.create1 ? (
            <div>
              {!selection ? (
                <Link to="/pie/add/job">
                  <button className="btn add-button white-text label-sm">
                    Add
                  </button>
                </Link>
              ) : null}
            </div>
          ) : null}
          {!selection && <h5 className="bold center pt-2">Job List</h5>}
          {!selection && (
            <input
              type="text"
              onChange={this.search}
              value={this.state.searchTerm}
              className="searchfield form-control-sm mr-3"
              placeholder="JobCode/JobRole/Skills"
            />
          )}
          {selection ? (
            <i
              onClick={this.back}
              className="material-icons cancel-button pointer"
            >
              cancel
            </i>
          ) : null}
          <Row>
            {!selection ? (
              data &&
              data.map((item) => {
                return (
                  <Col
                    md="3"
                    key={item.jobCode}
                    onClick={() => this.select(item)}
                  >
                    <Card className="p-3 mb-2 shadow pointer">
                      <h6>{item.jobRole}</h6>
                      <p>{item.domain}</p>
                    </Card>
                  </Col>
                );
              })
            ) : (
              <JobDetails job={this.state.selection} back={this.back} />
            )}
          </Row>
        </Container>
        {this.state.message ? <Notifier message={this.state.message} /> : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
      </div>
    );
  }
}

Jobs.propTypes = {
  searchJob: PropTypes.func.isRequired,
  getJob: PropTypes.func.isRequired,
  job: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  job: state.job,
});

export default connect(mapStateToProps, { searchJob, getJob })(Jobs);
