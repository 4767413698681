import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  FormText,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
// material ui
import { IconButton, Divider } from "@material-ui/core";
// icons
import EditIcon from "@material-ui/icons/Edit";
import LabelIcon from "@material-ui/icons/Label";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";

// components
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../common/Validator";
import FormComponent from "../../../common/FormComponent";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import DetailsPageFooter from "../../../common/details_footer/DetailsPageFooter";
import SearchSelectBoxWithAdd from "../../../common/selectbox/SearchSelectBoxWithAdd";
import CustomizedDialogs from "../../../common/customized-dialog/CustomizedDialogs";
import UpdateTagContact from "../common/UpdateTagContact";
import TagContactCard from "../common/TagContactCard";

// redux
import {
  updateAccounts,
  getAccounts,
  getAccountsSpocName,
  getUpdateAccountsSpocName,
  getAccountTaggedContacts,
} from "../../../../redux/actions/crm/accounts/accountsAction";
import { getCustomersName } from "../../../../redux/actions/crm/customers/customersAction";
import { getCustomerEntityName } from "../../../../redux/actions/crm/customer_entity/customerEntityAction";
import { getUserCustomerAccountStatus } from "../../../../redux/actions/crm/crm_setup/customerAccountStatusAction";
import { getUserAccountManager } from "../../../../redux/actions/crm/crm_setup/accountMangerAcction";
import { getUserSalesTeam } from "../../../../redux/actions/crm/crm_setup/salesTeamAction";

// -----------------------------------------------------------------------------------------------------------
class AccountDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerName: "",
      customerEntityName: "",
      accountName: "",
      contactId: "",
      contactName: "",
      contactEmail: "",
      dialingCode: "",
      contactNo: "",
      businessUnit: "",
      department: "",
      accountManager: "",
      salesRep: "",
      remarks: "",
      status: "",
      edit: false,
      open: false,
      viewTaggedContacts: false,
      message: "",
      error: "",
      spocError: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.accounts.message) {
      setTimeout(() => {
        this.props.back();
      }, 2000);
    }
    if (prevState.customerName !== this.state.customerName) {
      this.props.getUpdateAccountsSpocName(
        this.props.accountsDetail.customerdetailId
      );
    }
  }

  onChange(e) {
    e.preventDefault();
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleContactNameChange = (value) => {
    this.setState({
      contactId: value && value.contactId,
      contactName: value && value.contactName,
      contactEmail: value && value.contactEmail,
      dialingCode: value && value.contactDialingCode,
      contactNo: value && value.contactMobileNo,
    });
  };

  /*
  This function for updating the customer details
  */
  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let account = {
      id: this.props.accountsDetail.id.toString(),
      customerdetailId: this.state.customerName.toString(),
      customerentityId: this.state.customerEntityName.toString(),
      accountName: this.state.accountName,
      contactId: this.state.contactId,
      contactName: this.state.contactName,
      businessUnitName: this.state.businessUnit,
      departmentName: this.state.department,
      accountManager: this.state.accountManager,
      salesRep: this.state.salesRep,
      remarks: this.state.remarks,
      accountStatus: this.state.status,
    };
    if (valid && this.state.contactName) {
      this.props.updateAccounts(account, this.props.back);
      setTimeout(() => {
        this.props.getAccounts();
      }, 2000);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
        spocError: this.state.contactName ? " " : "This field is mandatory",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
  ComponentDidMount getting the selected customer details and setting the state of customer details
  */
  componentDidMount() {
    refresh();
    const {
      accountName,
      contactId,
      contactName,
      businessUnitName,
      departmentName,
      accountManager,
      salesRep,
      customerdetailId,
      customerentityId,
      remarks,
      accountStatus,
    } = this.props.accountsDetail;
    this.setState({
      customerName: customerdetailId,
      customerEntityName: customerentityId,
      accountName: accountName,
      contactId: contactId,
      contactName: contactName,
      businessUnit: businessUnitName,
      department: departmentName,
      accountManager: accountManager,
      salesRep: salesRep,
      remarks: remarks,
      status: accountStatus,
    });
    this.props.getCustomersName();
    this.props.getCustomerEntityName();
    this.props.getUserCustomerAccountStatus();
    this.props.getUserAccountManager();
    this.props.getUserSalesTeam();
  }

  resetSpocNameError = () => {
    this.setState({
      spocError: "",
    });
  };

  // modal function for open
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  // modal function for close
  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  // modal function for view tagged contacts
  handleClickView = () => {
    this.setState({
      viewTaggedContacts: true,
    });
    this.props.getAccountTaggedContacts(
      this.props.accountsDetail.customerdetailId,
      this.props.accountsDetail.customerentityId,
      this.props.accountsDetail.id
    );
  };

  // modal function for close view tagged contacts
  handleCloseView = () => {
    this.setState({
      viewTaggedContacts: false,
    });
  };

  render() {
    return (
      <Row>
        {!this.state.edit ? (
          <Col md="12">
            <h5 className="bold center mb-4">
              Account : {this.props.accountsDetail.accountNo}
            </h5>
            {typeof this.props.permission !== "undefined" &&
              Object.keys(this.props.permission).length > 0 &&
              this.props.permission.updation && (
                <IconButton
                  className="edit-button"
                  color="primary"
                  onClick={() => this.setState({ edit: !this.state.edit })}
                  title="edit account"
                >
                  <EditIcon />
                </IconButton>
              )}
            <IconButton
              className="tag-button"
              color="primary"
              title="tag contact"
              onClick={this.handleClickOpen}
            >
              <LabelIcon />
            </IconButton>
            <IconButton
              className="eye-button"
              color="primary"
              title="view tagged contacts"
              onClick={this.handleClickView}
            >
              <RemoveRedEyeIcon />
            </IconButton>
            <i
              onClick={() => this.props.back()}
              className="material-icons pointer cancel-button"
            >
              cancel
            </i>
            <Row>
              <Col md="6">
                <ul className="CustDetails-ul">
                  {/* <li>
                    <span className="label-sm bold accounts-span-label-col-1">
                      Customer Id
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.state.customerName}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold accounts-span-label-col-1">
                      Customer Name
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.accountsDetail.customerName}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold accounts-span-label-col-1">
                      Entity Id
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.state.customerEntityName}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold accounts-span-label-col-1">
                      Entity Name
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.accountsDetail.entityName}
                    </span>
                  </li> */}
                  {/* <li>
                    <span className="label-sm bold accounts-span-label-col-1">
                      Account No
                    </span>
                    <span className=" label-sm">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.accountsDetail.id}
                    </span>
                  </li> */}
                  <li>
                    <span className="list-label accounts-span-label-col-1">
                      Account Name
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.accountName}
                    </span>
                  </li>
                  <li>
                    <span className="list-label accounts-span-label-col-1">
                      Business Unit
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.businessUnit}
                    </span>
                  </li>
                  <li>
                    <span className="list-label accounts-span-label-col-1">
                      Department
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.department}
                    </span>
                  </li>
                  <li>
                    <span className="list-label accounts-span-label-col-1">
                      SPOC Name
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.contactName}
                    </span>
                  </li>
                </ul>
              </Col>
              <Col md="6">
                <ul className="CustDetails-ul">
                  <li>
                    <span className="list-label accounts-span-label-col-2">
                      Status
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.status}
                    </span>
                  </li>
                  <li>
                    <span className="list-label accounts-span-label-col-2">
                      Acc Manager
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.accountManager}
                    </span>
                  </li>
                  <li>
                    <span className="list-label accounts-span-label-col-2">
                      Sales Executive
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.salesRep}
                    </span>
                  </li>
                  <li>
                    <span className="list-label accounts-span-label-col-2">
                      Comments
                    </span>
                    <span className="list-data">
                      &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.remarks}
                    </span>
                  </li>
                </ul>
              </Col>
            </Row>
            <Divider />
            <DetailsPageFooter
              createdBy={this.props.accountsDetail.createdBy}
              createdOn={this.props.accountsDetail.createdOn}
              updatedBy={this.props.accountsDetail.updatedBy}
              updatedOn={this.props.accountsDetail.updatedOn}
            />
          </Col>
        ) : (
          <Col md="12" className="p-0">
            <h5 className="bold center">
              Update Customer Account : {this.props.accountsDetail.accountNo}
            </h5>
            <i
              onClick={() => this.setState({ edit: !this.state.edit })}
              className="material-icons pointer cancel-button"
            >
              cancel
            </i>
            <Form
              onSubmit={this.onSubmit}
              className="p-3 pl--5 pr--5"
              noValidate
            >
              <Row>
                <Col md="6">
                  {/* <FormGroup>
                    <Label className="label-sm bold">Customer Name</Label>
                    <Input
                      type="select"
                      name="customerName"
                      onChange={this.onChange}
                      value={this.state.customerName}
                      required={true}
                      disabled={true}
                      tabIndex={1}
                    >
                      {this.props.customers &&
                        this.props.customers.nameData.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.customerNo}-{item.customerName}
                            </option>
                          );
                        })}
                    </Input>
                  </FormGroup> */}

                  <FormComponent
                    labelClass="bold label-sm"
                    label="Account Name"
                    name="accountName"
                    type="text"
                    change={this.onChange}
                    value={this.state.accountName}
                    maxlength="27"
                    required={true}
                    tabIndex={1}
                  />
                  <FormComponent
                    labelClass="bold label-sm"
                    label="Business Unit"
                    type="text"
                    name="businessUnit"
                    change={this.onChange}
                    value={this.state.businessUnit}
                    placeholder="Business Unit"
                    required={true}
                    tabIndex={3}
                  />
                  <Row>
                    <Col md="6" className="p-0">
                      <SearchSelectBoxWithAdd
                        options={this.props.accounts.spocNameData}
                        value={this.state.contactName}
                        handleChange={(value) =>
                          this.handleContactNameChange(value)
                        }
                        reset={this.resetSpocNameError}
                        spocError={this.state.spocError}
                        label="SPOC Name"
                        tabIndex={5}
                      />
                    </Col>
                    <Col md="6" className="pr-0">
                      <ul className="CustDetails-ul">
                        <li>
                          <span className="label-sm bold customer-span-label-col-1">
                            Email
                          </span>
                          <span className=" label-sm">
                            &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.contactEmail}
                          </span>
                        </li>
                        <li>
                          <span className="label-sm bold customer-span-label-col-1">
                            Mobile
                          </span>
                          <span className=" label-sm">
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            {this.state.dialingCode &&
                              `${this.state.dialingCode}-`}
                            {this.state.contactNo}
                          </span>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label className="bold label-sm">Account Manager</Label>
                    <Input
                      type="select"
                      name="accountManager"
                      value={this.state.accountManager}
                      onChange={this.onChange}
                      className="form-control-sm"
                      tabIndex={7}
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.accountManager.data.map((item) => {
                        return (
                          <option key={item.id} value={item.empNo}>
                            {item.empNo}-{item.empName}
                          </option>
                        );
                      })}
                    </Input>
                    {errors.accountManager && (
                      <FormText className="error">
                        {errors.accountManager}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  {/* <FormGroup>
                    <Label className="label-sm bold">
                      Customer Entity Name
                    </Label>
                    <Input
                      type="select"
                      name="customerEntityName"
                      onChange={this.onChange}
                      value={this.state.customerEntityName}
                      required={true}
                      disabled={true}
                      tabIndex={2}
                    >
                      {this.props.customerEntity &&
                        this.props.customerEntity.nameData.map((item) => {
                          if (
                            Number(this.state.customerName) ===
                            Number(item.customerdetailId)
                          ) {
                            return (
                              <option key={item.id} value={item.id}>
                                {item.entityNo}-{item.entityName}
                              </option>
                            );
                          }
                        })}
                    </Input>
                  </FormGroup> */}
                  <FormGroup>
                    <Label className="bold label-sm">Account Status</Label>
                    <Input
                      type="select"
                      name="status"
                      value={this.state.status}
                      onChange={this.onChange}
                      className="form-control-sm"
                      required={true}
                      tabIndex={2}
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.customerAccountStatus.data.map((item) => {
                        return (
                          <option key={item.id} value={item.statusName}>
                            {item.statusName}
                          </option>
                        );
                      })}
                    </Input>
                    {errors.status && (
                      <FormText className="error">{errors.status}</FormText>
                    )}
                  </FormGroup>
                  <FormComponent
                    labelClass="bold label-sm"
                    label="Department"
                    type="text"
                    name="department"
                    change={this.onChange}
                    value={this.state.department}
                    placeholder="Department"
                    tabIndex={4}
                  />
                  <FormGroup>
                    <Label className="bold label-sm">Sales Executive</Label>
                    <Input
                      type="select"
                      name="salesRep"
                      value={this.state.salesRep}
                      onChange={this.onChange}
                      className="form-control-sm"
                      tabIndex={6}
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      {this.props.salesTeam.data.map((item) => {
                        return (
                          <option key={item.id} value={item.empNo}>
                            {item.empNo}-{item.empName}
                          </option>
                        );
                      })}
                    </Input>
                    {errors.salesRep && (
                      <FormText className="error">{errors.salesRep}</FormText>
                    )}
                  </FormGroup>

                  <FormComponent
                    labelClass="bold label-sm"
                    label="Comments"
                    type="textarea"
                    name="remarks"
                    change={this.onChange}
                    value={this.state.remarks}
                    placeholder="Remarks"
                    tabIndex={8}
                  />
                </Col>
              </Row>
              <div className="center">
                <button type="submit" className="btn btn-custom" tabIndex={9}>
                  Update
                </button>
              </div>
            </Form>
          </Col>
        )}
        {this.props.accounts.message ? (
          <Notifier message={this.props.accounts.message} />
        ) : null}
        {this.props.accounts.error ? (
          <ErrorNotifier message={this.props.accounts.error} />
        ) : null}
        <CustomizedDialogs
          open={this.state.open}
          handleClose={this.handleClose}
          title="Tag Contact"
        >
          <UpdateTagContact
            back={this.handleClose}
            customerdetailId={this.props.accountsDetail.customerdetailId}
            customerentityId={this.props.accountsDetail.customerentityId}
            accountName={this.props.accountsDetail.id}
            spocNameData={this.props.accounts.spocNameData}
            getData={this.props.getAccountsSpocName}
            name="account"
          />
        </CustomizedDialogs>
        <Modal size="xl" isOpen={this.state.viewTaggedContacts}>
          <ModalHeader toggle={this.handleCloseView}>
            View Tag Contacts
          </ModalHeader>
          <ModalBody>
            <TagContactCard
              data={this.props.accounts.taggedContactData}
              permission={this.props.permission}
              name="account"
            />
          </ModalBody>
        </Modal>
      </Row>
    );
  }
}

AccountDetails.propTypes = {
  accounts: PropTypes.object.isRequired,
  updateAccounts: PropTypes.func.isRequired,
  getAccountsSpocName: PropTypes.func.isRequired,
  getUpdateAccountsSpocName: PropTypes.func.isRequired,
  getAccountTaggedContacts: PropTypes.func.isRequired,
  getAccounts: PropTypes.func.isRequired,
  getCustomersName: PropTypes.func.isRequired,
  getCustomerEntityName: PropTypes.func.isRequired,
  customerEntity: PropTypes.object.isRequired,
  getUserCustomerAccountStatus: PropTypes.func.isRequired,
  customerAccountStatus: PropTypes.object.isRequired,
  getUserAccountManager: PropTypes.func.isRequired,
  accountManager: PropTypes.object.isRequired,
  getUserSalesTeam: PropTypes.func.isRequired,
  salesTeam: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  accounts: state.accounts,
  customers: state.customers,
  customerEntity: state.customerEntity,
  customerAccountStatus: state.customerAccountStatus,
  accountManager: state.accountManager,
  salesTeam: state.salesTeam,
});

export default connect(mapStateToProps, {
  updateAccounts,
  getAccounts,
  getAccountsSpocName,
  getUpdateAccountsSpocName,
  getAccountTaggedContacts,
  getCustomersName,
  getCustomerEntityName,
  getUserCustomerAccountStatus,
  getUserAccountManager,
  getUserSalesTeam,
})(withRouter(AccountDetails));
