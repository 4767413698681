import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormText,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Select from "react-select";
import { connect } from "react-redux";
import axios from "axios";
import DateFns from "date-fns";
import { getPartners } from "../../../../../redux/actions/partnerAction";
import { getVendors } from "../../../../../redux/actions/vendorAction";
import {
  addIssuePO,
  addPurchaseItem,
  getPurchaseItems,
  addScheduledPymtDate,
  getScheduledPymtDate,
  addUpdateIssuePO,
} from "../../../../../redux/actions/finance/accounts_payable/issue_po/issuePOAction";
import { getCurrency } from "../../../../../redux/actions/currencyAction";
import { getPartnerPymtTerms } from "../../../../../redux/actions/partnerPymtTermsAction";
import { getVendorPymtTerms } from "../../../../../redux/actions/vendorPymtTermsAction";
import { getPOType } from "../../../../../redux/actions/finance/accounts_payable/issue_po/poTypeAction";
import { getUserBusinessLocations } from "../../../../../redux/actions/hcm/business_setup/businessLocationsAction";
import {
  FETCH_PARTNER_TAXPYMT_DETAILS,
  FETCH_VENDOR_TAXPYMT_DETAILS,
  FETCH_PARTNER_BANK_ACC,
  FETCH_VENDOR_BANK_ACC,
} from "../../../../../utils/routes";
import FormComponent from "../../../../common/FormComponent";
import {
  errors,
  Validator,
  submitValidator,
  valid,
  refresh,
} from "../../../../common/Validator";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import Notifier from "../../../../aside/Notifier";

/**
 * @class AddInvoice
 **/

class AddIssuePO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      poName: "",
      poType: "",
      poTo: "",
      partnerNameSelectedOption: "",
      vendorNameSelectedOption: "",
      address: "",
      contactName: "",
      contactEmail: "",
      contactPhoneNumber: "",
      shipToLocation: "",
      shipToAddress: "",
      shipToContactEmail: "",
      shipToContactNo: "",
      poCurrency: "",
      poDescription: "",
      paymentTerms: "",
      remarks: "",
      paymentInstructions: "",
      totalAmount: "",
      totalGst: "",
      poTotalAmount: "",
      partnerNameSelectedOptionError: "",
      vendorNameSelectedOptionError: "",
      taxData: [],
      bankData: [],
      purchaseItemModal: false,
      poNo: "",
      purchaseItemNo: "",
      pspName: "",
      quantity: "",
      amount: "",
      amountGST: "",
      pymtScheduleModal: false,
      scheduledPymtDate: "",
      pymtAmount: "",
      toggleCreatePo: true,
      data: [],
      purchaseItemPage: 1,
      purchaseItemContains: 2,
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.purchaseItemSubmit = this.purchaseItemSubmit.bind(this);
    this.pymtScheduleSubmit = this.pymtScheduleSubmit.bind(this);
    this.selectPurchaseItemPage = this.selectPurchaseItemPage.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  partnerNameHandleChange = (partnerNameSelectedOption) => {
    console.log("partnerName Change");
    if (partnerNameSelectedOption) {
      this.setState({
        partnerNameSelectedOption,
        partnerNameSelectedOptionError: null,
      });
      let data = {
        partnerId: partnerNameSelectedOption.fetchValue,
      };
      axios
        .post(FETCH_PARTNER_TAXPYMT_DETAILS, data, { withCredentials: true })
        .then((res) => {
          this.setState({
            gstin: res.data[0].gst,
            pan: res.data[0].panNo,
          });
        });
      axios
        .post(FETCH_PARTNER_BANK_ACC, data, { withCredentials: true })
        .then((res) => {
          this.setState({
            bankName: res.data[0].bankName,
            bankIfscCode: res.data[0].ifscCode,
            bankAccountName: res.data[0].accountName,
            bankAccountNumber: res.data[0].accountNumber,
          });
        });
    } else {
      this.setState({
        partnerNameSelectedOptionError: "This field is mandatory",
      });
    }
  };

  vendorNameHandleChange = (vendorNameSelectedOption) => {
    if (vendorNameSelectedOption) {
      this.setState({
        vendorNameSelectedOption,
        vendorNameSelectedOptionError: null,
      });
      let data = {
        vendorId: vendorNameSelectedOption.fetchValue,
      };
      axios
        .post(FETCH_VENDOR_TAXPYMT_DETAILS, data, { withCredentials: true })
        .then((res) => {
          this.setState({
            gstin: res.data[0].gst,
            pan: res.data[0].panNo,
          });
        });
      axios
        .post(FETCH_VENDOR_BANK_ACC, data, { withCredentials: true })
        .then((res) => {
          this.setState({
            bankName: res.data[0].bankName,
            bankIfscCode: res.data[0].ifscCode,
            bankAccountName: res.data[0].accountName,
            bankAccountNumber: res.data[0].accountNumber,
          });
        });
    } else {
      this.setState({
        vendorNameSelectedOptionError: "This field is mandatory",
      });
    }
  };

  componentDidMount() {
    this.props.getCurrency();
    this.props.getPOType();
    this.props.getUserBusinessLocations();
  }

  // componentDidUpdate function we will get the vendor and partners details based on the invoice type selection
  componentDidUpdate(prevProps, prevState) {
    if (prevState.poTo !== this.state.poTo) {
      if (this.state.poTo === "Partner") {
        this.props.getPartners();
        this.props.getPartnerPymtTerms();
      }
      if (this.state.poTo === "Vendor") {
        this.props.getVendors();
        this.props.getVendorPymtTerms();
      }
    }
    if (
      prevState.partnerNameSelectedOption !==
      this.state.partnerNameSelectedOption
    ) {
      this.props.partners.data.map((item) => {
        if (
          item.partnerFullName === this.state.partnerNameSelectedOption.value
        ) {
          this.setState({
            address: item.billingAddress,
            contactName: item.primaryFirstName,
            contactEmail: item.primaryOfficialEmail,
            contactPhoneNumber: item.primaryContactNo,
          });
        }
      });
    }
    if (
      prevState.vendorNameSelectedOption !== this.state.vendorNameSelectedOption
    ) {
      this.props.vendors.data.map((item) => {
        if (item.vendorFullName === this.state.vendorNameSelectedOption.value) {
          this.setState({
            address: item.billingAddress,
            contactName: item.primaryFirstName,
            contactEmail: item.primaryOfficialEmail,
            contactPhoneNumber: item.primaryContactNo,
          });
        }
      });
    }
    if (prevState.shipToLocation !== this.state.shipToLocation) {
      this.props.busiLocation.data.map((item) => {
        if (this.state.shipToLocation === item.locationName) {
          this.setState({
            shipToAddress: item.address,
            poCurrency: item.currency,
          });
        }
      });
    }

    if (prevProps.issuePOs.latestPOData !== this.props.issuePOs.latestPOData) {
      this.setState({
        toggleCreatePo: false,
        poNo: this.props.issuePOs.latestPOData[0].poNo,
      });
    }
    if (prevState.amount !== this.state.amount) {
      this.setState({
        amountGST: (this.state.amount * 0.18).toFixed(2),
      });
    }

    if (
      prevProps.issuePOs.purchaseItemData !==
      this.props.issuePOs.purchaseItemData
    ) {
      if (this.props.issuePOs.purchaseItemData.length !== 0) {
        let arr = this.props.issuePOs.purchaseItemData;
        let sumAmount = 0;
        let sumAmountGst = 0;
        arr.forEach(function (obj) {
          sumAmount += obj.amount * obj.qty;
          sumAmountGst += obj.gstAmount * obj.qty;
        });
        this.setState({
          totalAmount: sumAmount,
          totalGst: sumAmountGst.toFixed(2),
          data: this.props.issuePOs.purchaseItemData,
        });
      }
    }
  }

  // onSubmit function to submit the form
  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    const {
      partnerNameSelectedOption,
      vendorNameSelectedOption,
      partnerNameSelectedOptionError,
      vendorNameSelectedOptionError,
    } = this.state;
    if (valid && (partnerNameSelectedOption || vendorNameSelectedOption)) {
      let data = {
        poName: this.state.poName,
        poType: this.state.poType,
        poTo: this.state.poTo,
        vpsName:
          (this.state.partnerNameSelectedOption &&
            this.state.partnerNameSelectedOption.value) ||
          (this.state.vendorNameSelectedOption &&
            this.state.vendorNameSelectedOption.value),
        vpsBillingAddress: this.state.address,
        contactName: this.state.contactName,
        contactEmail: this.state.contactEmail,
        contactMobileNo: this.state.contactPhoneNumber,
        shipToLocation: this.state.shipToLocation,
        shipToAddress: this.state.shipToAddress,
        shipToContactMobileEmail: this.state.shipToContactEmail,
        shipToContactMobileNo: this.state.shipToContactNo,
        poDescription: this.state.poDescription,
        poDate: new Date(),
        poCurrency: this.state.poCurrency,
        pymtTerm: this.state.paymentTerms,
        createdBy: localStorage.getItem("id"),
      };

      this.props.addIssuePO(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all Mandatory Fields",
      });
      if (!partnerNameSelectedOption) {
        this.setState({
          partnerNameSelectedOptionError: "This field is mandatory",
        });
      }
      // if (!vendorNameSelectedOption) {
      //   this.setState({
      //     vendorNameSelectedOptionError: "This field is Mandatory",
      //   });
      // }
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  // purchaseItemSubmit function for submitting the form details
  purchaseItemSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      poNo: this.state.poNo,
      purchaseItemNo: this.state.purchaseItemNo,
      ppsName: this.state.pspName,
      qty: this.state.quantity,
      amount: this.state.amount,
      gstAmount: this.state.amountGST,
    };
    if (valid) {
      this.props.addPurchaseItem(data, this.purchaseItemToggle);
    } else {
      this.setState({
        error: "Enter all Mandatory Fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  // pymtScheduleSubmit function for submitting the form details
  pymtScheduleSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      poNo: this.state.poNo,
      scheduledPymtDate: this.state.scheduledPymtDate,
      paymentAmount: this.state.pymtAmount,
    };
    if (valid) {
      this.props.addScheduledPymtDate(data, this.pymtScheduleToggle);
    } else {
      this.setState({
        error: "Enter all Mandatory Fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  purchaseItemReset = () => {
    this.setState({
      purchaseItemNo: "",
      pspName: "",
      quantity: "",
      amount: "",
      amountGST: "",
    });
  };

  purchaseItemToggle = () => {
    this.setState({
      purchaseItemModal: !this.state.purchaseItemModal,
    });
    refresh();
    this.purchaseItemReset();
    let data1 = {
      poNo: this.state.poNo,
    };
    this.props.getPurchaseItems(data1);
  };

  pymtScheduleReset = () => {
    this.setState({
      scheduledPymtDate: "",
      pymtAmount: "",
    });
  };

  pymtScheduleToggle = () => {
    this.setState({
      pymtScheduleModal: !this.state.pymtScheduleModal,
    });
    refresh();
    this.pymtScheduleReset();
    let data1 = {
      poNo: this.state.poNo,
    };
    this.props.getScheduledPymtDate(data1);
  };

  // updateIssuePo function to update the totalAmount, totalGst and poTotalAmount in issuePoTable
  updateIssuePo = (poTotalAmount) => {
    let data = {
      poNo: this.state.poNo,
      totalAmount: this.state.totalAmount,
      poTotalAmount: poTotalAmount,
      totalGst: this.state.totalGst,
      updatedBy: localStorage.getItem("id"),
    };
    this.props.addUpdateIssuePO(data);
  };

  /*
selectPurchaseItemPage function to select number of pages for activity pagination
*/
  selectPurchaseItemPage(e) {
    this.setState({ purchaseItemPage: e.target.id });
  }
  render() {
    const {
      poName,
      poType,
      poTo,
      partnerNameSelectedOption,
      vendorNameSelectedOption,
      address,
      contactName,
      contactEmail,
      contactPhoneNumber,
      shipToLocation,
      shipToAddress,
      shipToContactEmail,
      shipToContactNo,
      poCurrency,
      poDescription,
      paymentTerms,
      remarks,
      paymentInstructions,
      partnerNameSelectedOptionError,
      vendorNameSelectedOptionError,
      purchaseItemModal,
      purchaseItemNo,
      pspName,
      quantity,
      amount,
      amountGST,
      totalAmount,
      totalGst,
      pymtScheduleModal,
      scheduledPymtDate,
      pymtAmount,
      toggleCreatePo,
      error,
    } = this.state;

    let partnerNameOptions = [];
    for (let i = 0; i < this.props.partners.data.length; i++) {
      var x = {};
      x["value"] = this.props.partners.data[i].partnerFullName;
      x["label"] = this.props.partners.data[i].partnerFullName;
      x["fetchValue"] = this.props.partners.data[i].partnerId;

      partnerNameOptions.push(x);
    }

    let vendorNameOptions = [];
    for (let i = 0; i < this.props.vendors.data.length; i++) {
      var x = {};
      x["value"] = this.props.vendors.data[i].vendorFullName;
      x["label"] = this.props.vendors.data[i].vendorFullName;
      x["fetchValue"] = this.props.vendors.data[i].vendorId;

      vendorNameOptions.push(x);
    }

    // Purchase Item Pagination
    const { data, purchaseItemPage, purchaseItemContains } = this.state;
    const purchaseItemLength = data.length;
    const purchaseItemLastItem = purchaseItemPage * purchaseItemContains;
    const purchaseItemFirstItem = purchaseItemLastItem - purchaseItemContains;
    const purchaseItems = data.slice(
      purchaseItemFirstItem,
      purchaseItemLastItem
    );
    const purchaseItemPageNos = [];
    for (
      let n = 1;
      n <= Math.ceil(purchaseItemLength / purchaseItemContains);
      n++
    ) {
      purchaseItemPageNos.push(n);
    }

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 mx-auto">
            <Container className="card container-card">
              <h5 className="center bold pt-2">New PO</h5>
              <i
                className="material-icons pointer cancel-button"
                onClick={this.props.back}
              >
                cancel
              </i>
              <Row>
                {toggleCreatePo ? (
                  <Col md="8" className="mx-auto">
                    <Form onSubmit={this.onSubmit} className="p-3" noValidate>
                      <Row>
                        <Col md="6">
                          <FormComponent
                            label="PO Name"
                            labelClass="label-sm bold"
                            type="text"
                            name="poName"
                            value={poName}
                            change={this.onChange}
                            className="form-control-sm"
                            required={true}
                          />
                          <FormGroup>
                            <Label className="label-sm bold">PO Type</Label>
                            <Input
                              type="select"
                              name="poType"
                              value={poType}
                              className="form-control-sm"
                              onChange={this.onChange}
                              required={true}
                            >
                              <option value="" hidden>
                                Select
                              </option>
                              {this.props.poTypes.data.map((item) => {
                                return (
                                  <option
                                    key={item.poTypeId}
                                    value={item.poType}
                                  >
                                    {item.poType}
                                  </option>
                                );
                              })}
                            </Input>
                            {errors.poType && (
                              <FormText className="error">
                                {errors.poType}
                              </FormText>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label className="label-sm bold">PO To</Label>
                            <Input
                              type="select"
                              name="poTo"
                              value={poTo}
                              className="form-control-sm"
                              onChange={this.onChange}
                              required={true}
                            >
                              <option value="" hidden>
                                Select
                              </option>
                              <option value="Partner">Partner</option>
                              <option value="Vendor">Vendor</option>
                            </Input>
                            {errors.poTo && (
                              <FormText className="error">
                                {errors.poTo}
                              </FormText>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label className="label-sm bold">
                              Partner / Vendor Name
                            </Label>
                            {poTo ? (
                              poTo === "Partner" ? (
                                <Select
                                  name="partnerNameOptions"
                                  value={partnerNameSelectedOption}
                                  onChange={this.partnerNameHandleChange}
                                  options={partnerNameOptions}
                                  className="select-form-control-sm"
                                  required
                                />
                              ) : (
                                <Select
                                  name="vendorNameOptions"
                                  value={vendorNameSelectedOption}
                                  onChange={this.vendorNameHandleChange}
                                  options={vendorNameOptions}
                                  className="select-form-control-sm"
                                  required
                                />
                              )
                            ) : (
                              <Select
                                name="partnerNameOptions"
                                className="select-form-control-sm"
                                required
                              />
                            )}
                            {partnerNameSelectedOptionError && (
                              <FormText className="error ">
                                {partnerNameSelectedOptionError}
                              </FormText>
                            )}
                            {vendorNameSelectedOptionError && (
                              <FormText className="error ">
                                {vendorNameSelectedOptionError}
                              </FormText>
                            )}
                          </FormGroup>
                          <FormComponent
                            label="Partner / Vendor Address"
                            labelClass="label-sm bold"
                            type="text"
                            name="address"
                            value={address}
                            className="form-control-sm"
                            required={true}
                            disabled={true}
                          />
                          <FormComponent
                            label="Contact Name"
                            labelClass="label-sm bold"
                            type="text"
                            name="contactName"
                            value={contactName}
                            className="form-control-sm"
                            required={true}
                            disabled={true}
                          />
                          <FormComponent
                            label="Contact Email"
                            labelClass="label-sm bold"
                            type="text"
                            name="conatactEmail"
                            value={contactEmail}
                            className="form-control-sm"
                            required={true}
                            disabled={true}
                          />
                          <FormComponent
                            label="Contact Phone Number"
                            labelClass="label-sm bold"
                            type="tel"
                            name="contactPhoneNumber"
                            value={contactPhoneNumber}
                            className="form-control-sm"
                            required={true}
                            disabled={true}
                          />
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label className="bold label-sm">
                              Ship To Location
                            </Label>
                            <Input
                              type="select"
                              name="shipToLocation"
                              className="form-control-sm "
                              value={shipToLocation}
                              onChange={this.onChange}
                              required={true}
                            >
                              <option value="" hidden>
                                Select...
                              </option>
                              {this.props.busiLocation.data.map((item) => {
                                return (
                                  <option
                                    key={item.locationId}
                                    value={item.locationName}
                                  >
                                    {item.locationCode}-{item.locationName}
                                  </option>
                                );
                              })}
                            </Input>
                            {errors.shipToLocation && (
                              <FormText className="error">
                                {errors.shipToLocation}
                              </FormText>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label className="bold label-sm">
                              Ship To Address
                            </Label>
                            <Input
                              type="text"
                              name="shipToAddress"
                              className="form-control-sm "
                              value={shipToAddress}
                              onChange={this.onChange}
                              required={true}
                              disabled={true}
                            ></Input>
                            {errors.shipToAddress && (
                              <FormText className="error">
                                {errors.shipToAddress}
                              </FormText>
                            )}
                          </FormGroup>
                          <FormComponent
                            label="Ship To Contact Email"
                            labelClass="label-sm bold"
                            type="email"
                            name="shipToContactEmail"
                            value={shipToContactEmail}
                            className="form-control-sm"
                            change={this.onChange}
                            required={true}
                          />
                          <FormComponent
                            label="Ship To Contact Number"
                            labelClass="label-sm bold"
                            type="tel"
                            name="shipToContactNo"
                            value={shipToContactNo}
                            className="form-control-sm"
                            change={this.onChange}
                            required={true}
                          />
                          <FormGroup>
                            <Label className="bold label-sm">PO Currency</Label>
                            <Input
                              type="text"
                              name="poCurrency"
                              className="form-control-sm "
                              value={poCurrency}
                              onChange={this.onChange}
                              required={true}
                              disabled={true}
                            ></Input>
                            {errors.poCurrency && (
                              <FormText className="error">
                                {errors.poCurrency}
                              </FormText>
                            )}
                          </FormGroup>
                          <FormComponent
                            label="PO Description"
                            labelClass="label-sm bold"
                            type="textarea"
                            name="poDescription"
                            value={poDescription}
                            className="form-control-sm"
                            change={this.onChange}
                            required={true}
                          />
                          <FormGroup>
                            <Label className="label-sm bold">
                              Payment Terms
                            </Label>
                            <Input
                              type="select"
                              className="form-control-sm"
                              name="paymentTerms"
                              value={paymentTerms}
                              onChange={this.onChange}
                              required={true}
                            >
                              <option value="" hidden>
                                Select...
                              </option>
                              {poTo
                                ? poTo === "Partner"
                                  ? this.props.partnerPymtTerms.data.map(
                                      (item) => {
                                        return (
                                          <option
                                            key={item.partnerPymtId}
                                            value={item.partnerPymtName}
                                          >
                                            {item.partnerPymtName}
                                          </option>
                                        );
                                      }
                                    )
                                  : this.props.vendorPymtTerms.data.map(
                                      (item) => {
                                        return (
                                          <option
                                            key={item.vendorPymtId}
                                            value={item.vendorPymtName}
                                          >
                                            {item.vendorPymtName}
                                          </option>
                                        );
                                      }
                                    )
                                : null}
                            </Input>
                            {errors.paymentTerms && (
                              <FormText className="error">
                                {errors.paymentTerms}
                              </FormText>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="center">
                        <button
                          type="submit"
                          className="btn center login-button white-text"
                        >
                          Create
                        </button>
                      </div>
                    </Form>
                  </Col>
                ) : (
                  <Col md="6">
                    <h6>PO Details</h6>
                    <div className="details-flex-main-div">
                      <div className="details-flex-div-one">PO Name</div>
                      <div className="details-flex-div-two">:</div>
                      <div className="details-flex-div-three">
                        {this.props.issuePOs.latestPOData[0] &&
                          this.props.issuePOs.latestPOData[0].poName}
                      </div>
                    </div>
                    <div className="details-flex-main-div">
                      <div className="details-flex-div-one">PO No</div>
                      <div className="details-flex-div-two">:</div>
                      <div className="details-flex-div-three">
                        {this.props.issuePOs.latestPOData[0] &&
                          this.props.issuePOs.latestPOData[0].poNo}
                      </div>
                    </div>
                    <div className="details-flex-main-div">
                      <div className="details-flex-div-one">PO Type</div>
                      <div className="details-flex-div-two">:</div>
                      <div className="details-flex-div-three">
                        {this.props.issuePOs.latestPOData[0] &&
                          this.props.issuePOs.latestPOData[0].poType}
                      </div>
                    </div>
                    <div className="details-flex-main-div">
                      <div className="details-flex-div-one">PO To</div>
                      <div className="details-flex-div-two">:</div>
                      <div className="details-flex-div-three">
                        {this.props.issuePOs.latestPOData[0] &&
                          this.props.issuePOs.latestPOData[0].poTo}
                      </div>
                    </div>
                    <div className="details-flex-main-div">
                      <div className="details-flex-div-one">Name</div>
                      <div className="details-flex-div-two">:</div>
                      <div className="details-flex-div-three">
                        {this.props.issuePOs.latestPOData[0] &&
                          this.props.issuePOs.latestPOData[0].vpsName}
                      </div>
                    </div>
                    <div className="details-flex-main-div">
                      <div className="details-flex-div-one">
                        Billing Address
                      </div>
                      <div className="details-flex-div-two">:</div>
                      <div className="details-flex-div-three">
                        {this.props.issuePOs.latestPOData[0] &&
                          this.props.issuePOs.latestPOData[0].vpsBillingAddress}
                      </div>
                    </div>
                    <div className="details-flex-main-div">
                      <div className="details-flex-div-one">Contact Name</div>
                      <div className="details-flex-div-two">:</div>
                      <div className="details-flex-div-three">
                        {this.props.issuePOs.latestPOData[0] &&
                          this.props.issuePOs.latestPOData[0].contactName}
                      </div>
                    </div>
                    <div className="details-flex-main-div">
                      <div className="details-flex-div-one">Contact Email</div>
                      <div className="details-flex-div-two">:</div>
                      <div className="details-flex-div-three">
                        {this.props.issuePOs.latestPOData[0] &&
                          this.props.issuePOs.latestPOData[0].contactEmail}
                      </div>
                    </div>
                    <div className="details-flex-main-div">
                      <div className="details-flex-div-one">Contact Number</div>
                      <div className="details-flex-div-two">:</div>
                      <div className="details-flex-div-three">
                        {this.props.issuePOs.latestPOData[0] &&
                          this.props.issuePOs.latestPOData[0].contactMobileNo}
                      </div>
                    </div>
                    <div className="details-flex-main-div">
                      <div className="details-flex-div-one">
                        Ship To Address
                      </div>
                      <div className="details-flex-div-two">:</div>
                      <div className="details-flex-div-three">
                        {this.props.issuePOs.latestPOData[0] &&
                          this.props.issuePOs.latestPOData[0].shipToAddress}
                      </div>
                    </div>
                    <div className="details-flex-main-div">
                      <div className="details-flex-div-one">
                        Ship To Location
                      </div>
                      <div className="details-flex-div-two">:</div>
                      <div className="details-flex-div-three">
                        {this.props.issuePOs.latestPOData[0] &&
                          this.props.issuePOs.latestPOData[0].shipToLocation}
                      </div>
                    </div>
                    <div className="details-flex-main-div">
                      <div className="details-flex-div-one">Contact Email</div>
                      <div className="details-flex-div-two">:</div>
                      <div className="details-flex-div-three">
                        {this.props.issuePOs.latestPOData[0] &&
                          this.props.issuePOs.latestPOData[0]
                            .shipToContactMobileEmail}
                      </div>
                    </div>
                    <div className="details-flex-main-div">
                      <div className="details-flex-div-one">Contact Number</div>
                      <div className="details-flex-div-two">:</div>
                      <div className="details-flex-div-three">
                        {this.props.issuePOs.latestPOData[0] &&
                          this.props.issuePOs.latestPOData[0]
                            .shipToContactMobileNo}
                      </div>
                    </div>
                    <div className="details-flex-main-div">
                      <div className="details-flex-div-one">PO Date</div>
                      <div className="details-flex-div-two">:</div>
                      <div className="details-flex-div-three">
                        {this.props.issuePOs.latestPOData[0] &&
                          this.props.issuePOs.latestPOData[0].poDate}
                      </div>
                    </div>
                    <div className="details-flex-main-div">
                      <div className="details-flex-div-one">PO Currency</div>
                      <div className="details-flex-div-two">:</div>
                      <div className="details-flex-div-three">
                        {this.props.issuePOs.latestPOData[0] &&
                          this.props.issuePOs.latestPOData[0].poCurrency}
                      </div>
                    </div>
                    <div className="details-flex-main-div">
                      <div className="details-flex-div-one">PO Description</div>
                      <div className="details-flex-div-two">:</div>
                      <div className="details-flex-div-three">
                        {this.props.issuePOs.latestPOData[0] &&
                          this.props.issuePOs.latestPOData[0].poDescription}
                      </div>
                    </div>
                    <div className="details-flex-main-div addIssuePo-paymentTerms-div">
                      <div className="details-flex-div-one">Payment Terms</div>
                      <div className="details-flex-div-two">:</div>
                      <div className="details-flex-div-three">
                        {this.props.issuePOs.latestPOData[0] &&
                          this.props.issuePOs.latestPOData[0].pymtTerm}
                      </div>
                    </div>
                  </Col>
                )}
                {!toggleCreatePo && (
                  <Col md="6">
                    <Row className="addIssuePo-purchaseItems-div">
                      <div className="milestone-div">
                        <h6 className="center">Purchase Items</h6>
                        <button
                          className="btn milestone-add-btn white-text label-sm"
                          onClick={this.purchaseItemToggle}
                        >
                          Add
                        </button>
                        <Table>
                          <thead>
                            <tr>
                              <th className="opp-head">Name</th>
                              <th className="opp-head">Qty</th>
                              <th className="opp-head">Amount</th>
                              <th className="opp-head">GST</th>
                            </tr>
                          </thead>
                          <tbody>
                            {purchaseItems &&
                              purchaseItems.map((item) => {
                                return (
                                  <tr key={item.purchaseItemsId}>
                                    <td>{item.ppsName}</td>
                                    <td>{item.qty}</td>
                                    <td>{item.amount}</td>
                                    <td>{item.gstAmount}</td>
                                  </tr>
                                );
                              })}
                            {this.props.issuePOs.purchaseItemData.length !==
                              0 && (
                              <tr>
                                <td>Total Amount:</td>
                                <td>{totalAmount}</td>
                                <td>Total GST:</td>
                                <td>{totalGst}</td>
                              </tr>
                            )}
                            {this.props.issuePOs.purchaseItemData.length !==
                              0 && (
                              <tr>
                                <td></td>
                                <td>
                                  <button
                                    className="btn center login-button white-text"
                                    onClick={() =>
                                      this.updateIssuePo(
                                        this.state.totalAmount +
                                          parseFloat(this.state.totalGst)
                                      )
                                    }
                                  >
                                    Update
                                  </button>
                                </td>
                                <td></td>
                                <td></td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                        <div className="flex action-pagination">
                          {purchaseItemPageNos.map((page) => (
                            <button
                              key={page}
                              id={page}
                              onClick={this.selectPurchaseItemPage}
                              className="btn btn-light btn-sm label-sm"
                            >
                              {page}
                            </button>
                          ))}
                        </div>
                      </div>
                    </Row>
                    <Row className="addIssuePo-pymtSchedule-div">
                      <div className="milestone-div">
                        <h6 className="center">Payment Schedule</h6>
                        <button
                          className="btn label-sm white-text addIssuePo-paymentSchedule-add-btn"
                          onClick={this.pymtScheduleToggle}
                        >
                          Add
                        </button>
                        <Table>
                          <thead>
                            <tr>
                              <th className="opp-head">PO No</th>
                              <th className="opp-head">Date</th>
                              <th className="opp-head">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.props.issuePOs.scheduledPymtDateData.map(
                              (item) => {
                                return (
                                  <tr key={item.scheduleId}>
                                    <td>{item.poNo}</td>
                                    <td>
                                      {item.scheduledPymtDate &&
                                        DateFns.format(
                                          item.scheduledPymtDate,
                                          "DD-MM-YYYY"
                                        )}
                                    </td>
                                    <td>{item.paymentAmount}</td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </Row>
                  </Col>
                )}
              </Row>

              <Modal isOpen={purchaseItemModal} size="md">
                <ModalHeader toggle={this.purchaseItemToggle}>
                  Purchase Items
                </ModalHeader>
                <ModalBody>
                  <Form onSubmit={this.purchaseItemSubmit} noValidate>
                    <Row>
                      <Col md="6" className="mx-auto">
                        {/* <FormGroup>
                      <Label className="bold label-sm">PO Number</Label>
                      <Input
                        type="select"
                        name="poNo"
                        className="form-control-sm "
                        value={poNo}
                        onChange={this.onChange}
                        required={true}
                      >
                        <option value="" hidden>
                          Select...
                        </option>
                        {this.props.issuePOs.data.map((item) => {
                          return (
                            <option key={item.poNo} value={item.poNo}>
                              {item.poNo}
                            </option>
                          );
                        })}
                      </Input>
                      {errors.poNo && (
                        <FormText className="error">{errors.poNo}</FormText>
                      )}
                    </FormGroup> */}
                        <FormComponent
                          label="Purchase Item Number"
                          labelClass="label-sm  bold"
                          className="form-control-sm"
                          type="number"
                          name="purchaseItemNo"
                          value={purchaseItemNo}
                          change={this.onChange}
                          required={true}
                        />
                        <FormComponent
                          label="Product/Service/Project Name"
                          labelClass="label-sm  bold"
                          className="form-control-sm"
                          type="text"
                          name="pspName"
                          value={pspName}
                          change={this.onChange}
                          required={true}
                        />
                        <FormComponent
                          label="Quantity"
                          labelClass="label-sm  bold"
                          className="form-control-sm"
                          type="number"
                          name="quantity"
                          value={quantity}
                          change={this.onChange}
                          required={true}
                        />
                        <FormComponent
                          label="Amount"
                          labelClass="label-sm  bold"
                          className="form-control-sm"
                          type="number"
                          name="amount"
                          value={amount}
                          change={this.onChange}
                          required={true}
                        />
                        <FormComponent
                          label="Amount GST"
                          labelClass="label-sm  bold"
                          className="form-control-sm"
                          type="number"
                          name="amountGST"
                          value={amountGST}
                          change={this.onChange}
                          required={true}
                          disabled={true}
                        />
                      </Col>
                    </Row>
                    <div className="center">
                      <button
                        type="submit"
                        className="btn center login-button white-text"
                      >
                        Add
                      </button>
                    </div>
                  </Form>
                </ModalBody>
              </Modal>
              <Modal isOpen={pymtScheduleModal} size="md">
                <ModalHeader toggle={this.pymtScheduleToggle}>
                  Schedule Date
                </ModalHeader>
                <ModalBody>
                  <Form onSubmit={this.pymtScheduleSubmit} noValidate>
                    <Row>
                      <Col md="6" className="mx-auto">
                        {/* <FormGroup>
                      <Label className="bold label-sm">PO Number</Label>
                      <Input
                        type="select"
                        name="poNo"
                        className="form-control-sm "
                        value={poNo}
                        onChange={this.onChange}
                        required={true}
                      >
                        <option value="" hidden>
                          Select...
                        </option>
                        {this.props.issuePOs.data.map((item) => {
                          return (
                            <option key={item.poNo} value={item.poNo}>
                              {item.poNo}
                            </option>
                          );
                        })}
                      </Input>
                      {errors.poNo && (
                        <FormText className="error">{errors.poNo}</FormText>
                      )}
                    </FormGroup> */}
                        <FormComponent
                          label="Scheduled Payment Date"
                          labelClass="label-sm  bold"
                          className="form-control-sm"
                          type="date"
                          name="scheduledPymtDate"
                          value={scheduledPymtDate}
                          change={this.onChange}
                          required={true}
                        />
                        <FormComponent
                          label="Payment Amount"
                          labelClass="label-sm  bold"
                          className="form-control-sm"
                          type="number"
                          name="pymtAmount"
                          value={pymtAmount}
                          change={this.onChange}
                          required={true}
                        />
                      </Col>
                    </Row>
                    <div className="center">
                      <button
                        type="submit"
                        className="btn center login-button white-text"
                      >
                        Add
                      </button>
                    </div>
                  </Form>
                </ModalBody>
              </Modal>
            </Container>
            {error && <ErrorNotifier message={error} />}
            {this.props.issuePOs.message && (
              <Notifier message={this.props.issuePOs.message} />
            )}
            {this.props.issuePOs.error && (
              <ErrorNotifier message={this.props.issuePOs.error} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

AddIssuePO.propTypes = {
  getPartners: PropTypes.func.isRequired,
  partners: PropTypes.object.isRequired,
  getVendors: PropTypes.func.isRequired,
  vendors: PropTypes.object.isRequired,
  addIssuePO: PropTypes.func.isRequired,
  addUpdateIssuePO: PropTypes.func.isRequired,
  issuePOs: PropTypes.object.isRequired,
  addPurchaseItem: PropTypes.func.isRequired,
  getCurrency: PropTypes.func.isRequired,
  currency: PropTypes.object.isRequired,
  getPartnerPymtTerms: PropTypes.func.isRequired,
  partnerPymtTerms: PropTypes.object.isRequired,
  getVendorPymtTerms: PropTypes.func.isRequired,
  vendorPymtTerms: PropTypes.object.isRequired,
  getPOType: PropTypes.func.isRequired,
  poTypes: PropTypes.object.isRequired,
  getUserBusinessLocations: PropTypes.func.isRequired,
  busiLocations: PropTypes.object.isRequired,
  getPurchaseItems: PropTypes.func.isRequired,
  addScheduledPymtDate: PropTypes.func.isRequired,
  getScheduledPymtDate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  partners: state.partners,
  vendors: state.vendors,
  issuePOs: state.issuePOs,
  currency: state.currency,
  partnerPymtTerms: state.partnerPymtTerms,
  vendorPymtTerms: state.vendorPymtTerms,
  poTypes: state.poTypes,
  busiLocation: state.busiLocation,
});

export default connect(mapStateToProps, {
  getPartners,
  getVendors,
  addIssuePO,
  addUpdateIssuePO,
  addPurchaseItem,
  getCurrency,
  getPartnerPymtTerms,
  getVendorPymtTerms,
  getPOType,
  getUserBusinessLocations,
  getPurchaseItems,
  addScheduledPymtDate,
  getScheduledPymtDate,
})(AddIssuePO);
