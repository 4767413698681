import React, { Component } from "react";
import { Row, Col, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// components
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../../common/Validator";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
// redux
import { addEmpDependents } from "../../../../../redux/actions/hcm/my_profile/empDependentsAction";

class AddDependent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      type: "",
      dob: "",
      age: "",
      message: "",
      error: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    const data = {
      dependentName: this.state.name,
      dependentType: this.state.type,
      dateOfBirth: this.state.dob,
      age: this.state.age,
    };
    if (valid) {
      this.props.addEmpDependents(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidMount function contains the form error data refresh method
*/
  componentDidMount() {
    refresh();
    this.setState({
      message: "",
      error: "",
    });
  }

  render() {
    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Add Dependent </h5>
        <i
          className="material-icons cancel-button pointer"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="3"></Col>
            <Col md="6">
              <FormGroup>
                <Label className="label-sm bold">Dependent Name</Label>
                <Input
                  type="text"
                  name="name"
                  value={this.state.name}
                  onChange={this.onChange}
                  className="form-control-sm"
                  required={true}
                />
                {errors.name && (
                  <FormText className="error">{errors.name}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="label-sm bold">Dependent Type</Label>
                <Input
                  type="select"
                  name="type"
                  value={this.state.type}
                  onChange={this.onChange}
                  className="form-control-sm"
                  required={true}
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  <option value="Spouse">Spouse</option>
                  <option value="Child">Child</option>
                  <option value="Brother">Brother</option>
                  <option value="Sister">Sister</option>
                  <option value="Father">Father</option>
                  <option value="Mother">Mother</option>
                  <option value="Father in Law">Father in Law</option>
                  <option value="Mother in Law">Mother in Law</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label className="label-sm bold">Date Of Birth</Label>
                <Input
                  type="date"
                  name="dob"
                  value={this.state.dob}
                  onChange={this.onChange}
                  className="form-control-sm"
                  required={true}
                />
                {errors.dob && (
                  <FormText className="error">{errors.dob}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="label-sm bold">Age</Label>
                <Input
                  type="number"
                  name="age"
                  value={this.state.age}
                  onChange={this.onChange}
                  className="form-control-sm"
                  required={true}
                />
                {errors.age && (
                  <FormText className="error">{errors.age}</FormText>
                )}
              </FormGroup>
            </Col>
            <Col md="3"></Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Add
            </button>
          </div>
        </Form>
        {this.props.empDependents.message ? (
          <Notifier message={this.props.empDependents.message} />
        ) : null}
        {this.props.empDependents.error ? (
          <ErrorNotifier message={this.props.empDependents.error} />
        ) : null}
      </React.Fragment>
    );
  }
}

AddDependent.propTypes = {
  addEmpDependents: PropTypes.func.isRequired,
  empDependents: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  empDependents: state.empDependents,
});

export default connect(mapStateToProps, { addEmpDependents })(AddDependent);
