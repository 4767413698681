import React, { Component } from "react";
import { Form, Row, Col, FormGroup, Label, Input, FormText } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// components
import {
  Validator,
  submitValidator,
  valid,
  errors,
  refresh,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
// redux
import { getUserBusinessCountries } from "../../../../../redux/actions/hcm/business_setup/businessCountriesAction";
import { getUserBusinessCurrency } from "../../../../../redux/actions/hcm/business_setup/businessCurrencyAction";
import { updateBusinessLocations } from "../../../../../redux/actions/hcm/business_setup/businessLocationsAction";

class UpdateBusinessLocation extends Component {
  constructor(props) {
    super(props);
    const {
      id,
      countryName,
      state,
      locationCode,
      locationName,
      currency,
      address,
      remarks,
    } = this.props.data;
    this.state = {
      locationId: id,
      countryName: countryName,
      state: state,
      locationCode: locationCode,
      locationName: locationName,
      currency: currency,
      address: address,
      remarks: remarks,
      message: "",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      id: this.state.locationId,
      countryName: this.state.countryName,
      state: this.state.state,
      locationCode: this.state.locationCode,
      locationName: this.state.locationName,
      currency: this.state.currency,
      address: this.state.address,
      remarks: this.state.remarks,
      updatedBy: localStorage.getItem("id"),
    };
    if (valid) {
      this.props.updateBusinessLocations(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    this.props.getUserBusinessCountries();
    this.props.getUserBusinessCurrency();
    refresh();
    this.setState({
      message: "",
      error: "",
    });
  }

  render() {
    const {
      countryName,
      state,
      locationCode,
      locationName,
      currency,
      address,
      remarks,
      error,
      message,
    } = this.state;
    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Update Business Location</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="3"></Col>
            <Col md="6">
              <FormGroup>
                <Label className="bold label-sm">Country Name</Label>
                <Input
                  type="select"
                  name="countryName"
                  value={countryName}
                  onChange={this.onChange}
                  className="form-control-sm"
                  required={true}
                >
                  <option value="" hidden>
                    Select....
                  </option>
                  {this.props.businessCountries.data.length > 0 &&
                    this.props.businessCountries.data.map((item) => {
                      return (
                        <option key={item.id} value={item.countryName}>
                          {item.countryName}
                        </option>
                      );
                    })}
                </Input>
                {errors.countryName && (
                  <FormText className="error">{errors.countryName}</FormText>
                )}
              </FormGroup>
              <FormComponent
                labelClass="bold label-sm"
                label="State"
                type="text"
                name="state"
                inputClass="form-control-sm"
                value={state}
                change={this.onChange}
                required={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Location Code"
                type="text"
                name="locationCode"
                inputClass="form-control-sm"
                value={locationCode}
                change={this.onChange}
                required={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Location Name"
                type="text"
                name="locationName"
                inputClass="form-control-sm"
                value={locationName}
                change={this.onChange}
                required={true}
              />
              <FormGroup>
                <Label className="bold label-sm">Currency</Label>
                <Input
                  type="select"
                  name="currency"
                  value={currency}
                  onChange={this.onChange}
                  className="form-control-sm"
                  required={true}
                >
                  <option value="" hidden>
                    Select....
                  </option>
                  {this.props.businessCurrency.data.length > 0 &&
                    this.props.businessCurrency.data.map((item) => {
                      if (countryName === item.countryName) {
                        return (
                          <option key={item.id} value={item.currencyName}>
                            {item.currencyName}
                          </option>
                        );
                      }
                    })}
                </Input>
                {errors.currency && (
                  <FormText className="error">{errors.currency}</FormText>
                )}
              </FormGroup>
              <FormComponent
                labelClass="bold label-sm"
                label="Address"
                type="text"
                name="address"
                inputClass="form-control-sm"
                value={address}
                change={this.onChange}
                required={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Remarks"
                type="textarea"
                name="remarks"
                inputClass="form-control-sm"
                value={remarks}
                change={this.onChange}
              />
            </Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Update
            </button>
          </div>
        </Form>
        {this.props.businessLocations.message && (
          <Notifier message={this.props.businessLocations.message} />
        )}
        {this.props.businessLocations.error && (
          <ErrorNotifier message={this.props.businessLocations.error} />
        )}
        {this.state.error && <ErrorNotifier message={this.state.error} />}
      </React.Fragment>
    );
  }
}

UpdateBusinessLocation.propTypes = {
  getUserBusinessCountries: PropTypes.func.isRequired,
  businessCountries: PropTypes.object.isRequired,
  getUserBusinessCurrency: PropTypes.func.isRequired,
  businessCurrency: PropTypes.object.isRequired,
  updateBusinessCountries: PropTypes.func.isRequired,
  businessCountries: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  businessCountries: state.businessCountries,
  businessCurrency: state.businessCurrency,
  businessLocations: state.businessLocations,
});

export default connect(mapStateToProps, {
  getUserBusinessCountries,
  getUserBusinessCurrency,
  updateBusinessLocations,
})(UpdateBusinessLocation);
