import axios from "axios";
import { UPLOAD_REQUIREMENTS, } from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import {GET_UPLOAD_REQ_RS_EXTRACTED,GET_UPLOAD_REQ_RS_PROCESSED,GET_UPLOAD_REQ_RS_UNPROCESSED} from '../../types'

export const uploadRequirements = (data) => (dispatch) => {
  axios
    .post(UPLOAD_REQUIREMENTS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res));
      dispatch({
        type: GET_UPLOAD_REQ_RS_EXTRACTED,
        payload: res.data.extracted,
      });
      dispatch({
        type:GET_UPLOAD_REQ_RS_PROCESSED,
        payload: res.data.processed,
      });
      dispatch({
        type:GET_UPLOAD_REQ_RS_UNPROCESSED,
        payload: res.data.unprocessed,
      });
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
