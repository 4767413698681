// HOME(SIGNIN PAGE) url

export const HOME = process.env.REACT_APP_HOME;



// web page title variable

export const TITLE = process.env.REACT_APP_TITLE;



// Variable for the backend connection

export const API = "http://165.22.215.61:1340";



// Variable for the google recaptcha

// export const RECAPTCHA_SITE_KEY =

//   process.env.REACT_APP_RECAPTCHA_SITE_KEY ||

//   "6Lerm5caAAAAAL6z_QQSNaD1iGT1fY3Nhdq8Z7Ck";



// Variable for the IAM Service

export const IAM_API = process.env.REACT_APP_IAM_API || "https://apidev.growthgamut.com/pieiamapi"

// Variable for the HCM Service

export const HCM_API = process.env.REACT_APP_HCM_API 

// variable for the RS Service

export const RS_API = process.env.REACT_APP_RS_API  || "https://apidev.growthgamut.com/ggrsapi"

// variable for the CAN Service

export const CAN_API = process.env.REACT_APP_CAN_API || "https://apidev.growthgamut.com/ggcanapi"

// variable for the CRM Service

export const CRM_API = process.env.REACT_APP_CRM_API 

// variable for the Experience 360 services 

export const E360_API = process.env.REACT_APP_E360_API || "https://apidev.growthgamut.com/piee360api"

export const MATCH_CAN_API = process.env.REACT_APP_MATCH_CAN_API || "https://apidev.growthgamut.com/scores"
// variable for the CAN Service

// variable for the Marketing 
export const MARKETING_API = process.env.REACT_APP_MARKETING_API 
