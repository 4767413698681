import React from "react";
import { Navbar, NavbarBrand, Container } from "reactstrap";
import logo from "../../../assets/img/pionlogo.png";

const LoginNavigation = () => {
  return (
    <Navbar color="dark" dark fixed="top" expand="md" id="navigation">
      <Container>
        <NavbarBrand>
          <img
            src={logo}
            height="40"
            style={{ margin: 0 }}
            alt="PION global"
            id="logo"
          />
        </NavbarBrand>
      </Container>
    </Navbar>
  );
};
export default LoginNavigation;
