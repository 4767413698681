import React, { Component } from "react";
import { Card, Table } from "reactstrap";
import axios from "axios";
import { GET_LEAD_LAST_ONE_QUARTER } from "../../../../utils/routes";

// const data = [
//   {
//     Month: "Q1",
//     Country: "India",
//     "Count of Leads": 5,
//   },
//   {
//     Month: "Q1",
//     Country: "USA",
//     "Count of Leads": 6,
//   },
//   {
//     Month: "Q1",
//     Country: "UK",
//     "Count of Leads": 5,
//   },
//   {
//     Month: "Q1",
//     Country: "Germany",
//     "Count of Leads": 7,
//   },
//   {
//     Month: "Q1",
//     Country: "France",
//     "Count of Leads": 8,
//   },
// ];

export default class LeadsInLastOneQuarterGeowise extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      error: "",
    };
  }

  componentDidMount() {
    axios
      .get(GET_LEAD_LAST_ONE_QUARTER, { withCredentials: true })
      .then((res) => {
        this.setState({
          data: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          error: err,
        });
      });
  }
  render() {
    const { data } = this.state;

    return (
      <Card
        className="recharts-card center"
        style={{ height: "150px", padding: "5px" }}
      >
        <Table hover>
          <thead>
            <tr>
              <th
                style={{
                  padding: "0 0 0 5px",
                  fontWeight: "600",
                  fontSize: "12px",
                }}
              >
                Quarter
              </th>
              <th
                style={{
                  padding: "0px",
                  fontWeight: "600",
                  fontSize: "12px",
                  textAlign: "left",
                }}
              >
                Country
              </th>
              <th
                style={{ padding: "0px", fontWeight: "600", fontSize: "12px" }}
              >
                Count of Leads
              </th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 && data.map((item) => {
              return (
                <tr>
                  <td
                    style={{
                      padding: "0 0 0 5px",
                      fontWeight: "400",
                      fontSize: "12px",
                    }}
                  >
                    Q1
                  </td>
                  <td
                    style={{
                      padding: "0px",
                      fontWeight: "400",
                      fontSize: "12px",
                      textAlign: "left",
                    }}
                  >
                    {item.country}
                  </td>
                  <td
                    style={{
                      padding: "0px",
                      fontWeight: "400",
                      fontSize: "12px",
                    }}
                  >
                    {item.CountOfLeads}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>
    );
  }
}
