import React, { Component } from "react";
import axios from "axios";
import {
  UPLOAD_PRODUCT_DOCUMENTS,
  UPDATE_PRODUCT_DOCUMENTS,
  DOWNLOAD_PRODUCT_DOCUMENTS,
  FETCH_PRODUCT_DOCUMENTS,
  DELETE_PRODUCT_DOCUMENTS,
} from "../../../../utils/routes";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import { FormText } from "reactstrap";

class DocumentUpload extends Component {
  constructor() {
    super();
    this.state = {
      file: [],
      docs: [],
      name: "",
      fileError: "",
      message: "",
      error: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getData = this.getData.bind(this);
    this.getDocs = this.getDocs.bind(this);
    this.deleteDoc = this.deleteDoc.bind(this);
  }

  onChange(e) {
    let allowedExtension = /(\.pdf|\.png)$/i;
    if (allowedExtension.exec(e.target.files[0].name)) {
      if ((e.target.files[0].size / 1024 / 1024).toFixed(2) < 2) {
        this.setState({
          file: e.target.files[0] || [],
          name: e.target.files[0].name || "",
          fileError: "",
        });
      } else {
        this.setState({
          file: e.target.files[0],
          fileError: "File size must less than 2MB",
        });
      }
    } else {
      this.setState({
        file: e.target.files[0],
        fileError: "File type not allowed",
      });
    }
  }

  onSubmit() {
    const { fileError } = this.state;
    let file = this.state.file;
    let fd = new FormData();
    fd.append("productDoc", file);
    if (!fileError) {
      axios
        .post(UPLOAD_PRODUCT_DOCUMENTS, fd, { withCredentials: true })
        .then((res) => {
          const data = {
            productDocId: res.data.productDocId,
            productId: this.props.id,
          };

          axios
            .post(UPDATE_PRODUCT_DOCUMENTS, data, { withCredentials: true })
            .then((res) => {
              this.setState({
                message: "Attachment added successfully",
              });
              setTimeout(() => {
                this.setState({ message: "" });
                this.getData();
              }, 2000);
            })
            .catch((err) => {
              this.setState({ error: err.message });
              setTimeout(() => {
                this.setState({ error: "" });
              }, 2000);
            });
        })
        .catch((err) => {
          this.setState({ error: err.message });
        });
      setTimeout(() => {
        this.setState({ error: "" });
      }, 2000);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  getData() {
    const data = {
      productId: this.props.id,
    };
    axios
      .post(FETCH_PRODUCT_DOCUMENTS, data, { withCredentials: true })
      .then((res) => {
        this.setState({
          docs: res.data,
        });
      });
  }

  componentDidMount() {
    this.getData();
  }

  getDocs(file) {
    const data = {
      productDocId: file.productDocId,
    };
    axios
      .post(DOWNLOAD_PRODUCT_DOCUMENTS, data, {
        responseType: "blob",
        withCredentials: true,
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file.productName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        this.setState({
          error: err.message,
        });
      });
    setTimeout(() => {
      this.setState({
        message: "",
        error: "",
      });
    }, 2000);
  }

  deleteDoc(file) {
    const data = {
      productDocId: file.productDocId,
    };

    axios
      .post(DELETE_PRODUCT_DOCUMENTS, data, { withCredentials: true })
      .then((res) => {
        this.setState({
          message: "Attachment deleted successfully",
        });
        setTimeout(() => {
          this.setState({ message: "" });
        }, 2000);
        this.getData();
      })
      .catch((err) => {
        this.setState({
          error: err.message,
        });
        setTimeout(() => {
          this.setState({ error: "" });
        }, 2000);
      });
  }

  render() {
    const { fileError } = this.state;
    return (
      <div className="center">
        <hr />
        <h6 className="center bold">Attachments</h6>
        {this.state.docs &&
          this.state.docs.map((file) => {
            return (
              <span
                key={file.projProDocId}
                className="m-3 card document-card pointer"
              >
                <i
                  className="material-icons cancel-button pointer"
                  onClick={() => this.deleteDoc(file)}
                >
                  cancel
                </i>
                <p
                  onClick={() => this.getDocs(file)}
                  className="bold label-sm p-2"
                >
                  {file.productName}
                </p>
              </span>
            );
          })}

        <hr />
        <label htmlFor="file" className="bold label-sm">
          Add new attachment :
        </label>
        <input
          type="file"
          name="file"
          className="form-control-sm"
          style={{ fontSize: "11px" }}
          onChange={this.onChange}
          accept=".pdf, .png"
        />
        <input
          type="Submit"
          value="Upload"
          style={{ fontSize: "10px" }}
          onClick={this.onSubmit}
          readOnly
        />
        {fileError && <FormText className="error">{fileError}</FormText>}
        <hr />
        {this.state.message && <Notifier message={this.state.message} />}
        {this.state.error && <ErrorNotifier message={this.state.error} />}
      </div>
    );
  }
}
export default DocumentUpload;
