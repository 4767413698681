import axios from "axios";
import { CLEARANCE_STATUS_GET } from "../../types";
import {
  CURD_CLEARANCE_STATUS,
  USER_CLEARANCE_STATUS,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addClearanceStatus = (data, back) => (dispatch) => {
  axios
    .post(CURD_CLEARANCE_STATUS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getClearanceStatus));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getClearanceStatus = () => (dispatch) => {
  axios
    .get(CURD_CLEARANCE_STATUS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CLEARANCE_STATUS_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserClearanceStatus = () => (dispatch) => {
  axios
    .get(USER_CLEARANCE_STATUS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CLEARANCE_STATUS_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateClearanceStatus = (data, back) => (dispatch) => {
  axios
    .put(CURD_CLEARANCE_STATUS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getClearanceStatus));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteClearanceStatus = (data) => (dispatch) => {
  axios
    .delete(CURD_CLEARANCE_STATUS, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getClearanceStatus));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
