import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  FormText
} from "reactstrap";
import { updateService } from '../../../../redux/actions/serviceAction';
import { getEmployeeDetails } from '../../../../redux/actions/employeeAction';
import { getCustomers } from '../../../../redux/actions/customerAction';
import { Validator, submitValidator, errors, valid, refresh } from '../../../common/Validator'
import FormComponent from '../../../common/FormComponent'
import Notifier from '../../../aside/Notifier'
import ErrorNotifier from '../../../aside/ErrorNotifier'
 
class UpdateService extends Component {
  constructor(props) {
    super(props);
    const {
      serviceId,
      companyName,
      serviceName,
      effort,
      plannedStart,
      plannedEnd,
      actualStart,
      actualEnd,
      budget,
      currency,
      location,
      status,
      description,
      resources,
      servicePmName,
      serviceCustSpoc,
      serviceSpocContactNo,
      serviceSpocEmail,
      comment,      
      serviceType,
      billingType,      
    } = this.props.item;
    this.state = {
      serviceId: serviceId,      
      compName: companyName,
      suggestions: [],
      serviceName: serviceName,
      plannedStart: plannedStart,
      plannedEnd: plannedEnd,
      actualStart: actualStart,
      actualEnd: actualEnd,
      effort: effort,
      budget: budget,
      currency: currency,
      location: location,
      status: status,
      description: description,
      spoc: serviceCustSpoc,
      spocNo: serviceSpocContactNo,
      spocMail: serviceSpocEmail,
      teams: resources,
      pmName: servicePmName,
      serviceType: serviceType,
      billType: billingType,
      remarks: comment,
      message: null,
      error: null
    };
    this.onChange = this.onChange.bind(this); 
    this.onSubmit = this.onSubmit.bind(this);    
  }

 
  componentDidMount(){
    this.props.getEmployeeDetails();
    this.props.getCustomers();
    refresh();
  }
  
  onTextChanged = e => {
    Validator(e.target)
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      suggestions = this.props.customers.data.sort().filter(v => regex.test(v.compFullName));
    }
    this.setState(() => ({ suggestions, compName: value }));
  };

  suggestionSelected = (value) => {
    this.setState(() => ({
      compName: value,
      suggestions: []
    }));
  }

  renderSuggestions = () => {
    const { suggestions } = this.state;
    if (suggestions.length === 0) {
      return null;
    }
    return (
      <ul className="suggestions">
        {suggestions.map(item => {
          return <li  onClick={() => this.suggestionSelected(item.compFullName)}>{item.compFullName}</li>;
        })}
      </ul>
    );
  }

  onChange(e) {
    e.preventDefault();
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value
    });
  }  

/*
This function for updating the project with body as the project details
*/
  onSubmit(e) {
    e.preventDefault(); 
    submitValidator(e.target);
    let service = {
      serviceId: this.state.serviceId,
      companyName: this.state.compName,
      serviceName: this.state.serviceName,
      plannedStart: this.state.plannedStart,
      plannedEnd: this.state.plannedEnd,
      actualStart: this.state.actualStart,
      actualEnd: this.state.actualEnd,
      effort: this.state.effort,
      budget: this.state.budget,
      currency: this.state.currency,
      location: this.state.location,
      status: this.state.status,
      description: this.state.description,
      serviceCustSpoc: this.state.spoc,
      serviceSpocContactNo: this.state.spocNo,
      serviceSpocEmail:this.state.spocMail,
      resources: this.state.teams,
      servicePmName: this.state.pmName,
      serviceType: this.state.serviceType,
      billingType: this.state.billType,
      comment: this.state.remarks,
      updatedBy: localStorage.getItem('id')
    };
    if(valid){
      this.props.updateService(service)
      setTimeout(() => {
          this.props.back();
      }, 2000)
    }else{
      this.setState({
        error: 'Enter all mandatory fields'
      })
      setTimeout(() => {
        this.setState({
          error: ''
        })
      }, 2000)
    }
  }

  render() {
    return (
      <Container className="container-card" style={{position: 'relative'}}>
        <i
          className="cancel-button material-icons pointer"
          onClick={this.props.back}
        >cancel</i>
        <Form onSubmit={ this.onSubmit } className="p-3" noValidate>
          <h5 className="bold center">Update Service</h5>
          <Row>
            <Col md="4">                          
              <div>
                <FormGroup>
                  <Label className="label-sm bold">Customer Name</Label>
                  <Input
                    type="text"
                    name="compName"
                    value={this.state.compName}
                    onChange={this.onTextChanged}
                    autoComplete="off"
                    className="form-control-sm"
                    required
                  />
                  {errors.compName && <FormText className="error">{errors.compName}</FormText>}
                </FormGroup>
                {this.renderSuggestions()}
                </div>
              <FormComponent                
                label="Service Name"
                labelClass="label-sm bold"
                type="text"
                name="serviceName"
                value={this.state.serviceName}
                change={this.onChange}
                required={true}
              />
              <FormComponent                
                label="Planned Start"
                labelClass="label-sm bold"
                type="date"
                name="plannedStart"
                value={this.state.plannedStart}
                change={this.onChange}  
                required={true}              
              />
              <FormComponent
                label="Planned End"
                labelClass="label-sm bold"
                type="date"
                name="plannedEnd"
                value={this.state.plannedEnd}
                change={this.onChange}  
                min={this.state.plannedStart}
                required={true}              
              />
              <FormComponent                
                label="Actual Start"
                labelClass="label-sm bold"
                type="date"
                name="actualStart"
                value={this.state.actualStart}
                change={this.onChange}  
                required={true}              
              />
              <FormComponent
                label="Actual End"
                labelClass="label-sm bold"
                type="date"
                name="actualEnd"
                value={this.state.actualEnd}
                change={this.onChange}  
                min={this.state.actualStart}
                required={true}              
              />
              <FormComponent                
                label="Customer Spoc"
                labelClass="label-sm bold"
                type="text"
                name="spoc"
                value={this.state.spoc}
                change={this.onChange}
                required={true}
              />
            </Col>
            <Col md="4">
            <FormComponent                
                label="Customer Contact No"
                labelClass="label-sm bold"
                type="number"
                name="spocNo"
                value={this.state.spocNo}
                change={this.onChange}
                required={true}
              />
              <FormComponent                
                label="Customer Contact Email"
                labelClass="label-sm bold"
                type="email"
                name="spocMail"
                value={this.state.spocMail || ''}
                change={this.onChange}
                required
              />
              <FormComponent                
                label="Service Effort"
                labelClass="label-sm bold"
                type="number"
                name="effort"
                value={this.state.effort}
                change={this.onChange}
                required={true}                
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Service Cost"
                type="number"
                name="budget"
                change={this.onChange}
                value={this.state.budget}
                required={true}
              />
              <FormGroup>
                    <Label className="bold label-sm">Currency</Label>
                    <Input
                      type="select"
                      name="currency"
                      className="form-control-sm"
                      value={this.state.currency}
                      onChange={this.onChange}
                      required
                    >
                      <option value="AUD">AUD</option>
                      <option value="EUR">EUR</option>
                      <option value="GBP">GBP</option>
                      <option value="INR">INR</option>
                      <option value="SGD">SGD</option>
                      <option value="USD">USD</option>
                    </Input>
                  </FormGroup>
              <FormComponent                
                label="Service Location"
                labelClass="label-sm bold"
                type="text"
                name="location"
                value={this.state.location}
                change={this.onChange}
                required={true}
              />
              <FormGroup>
                <Label className="bold label-sm">Status</Label>
                <Input
                  name="status"
                  type="select"
                  className="form-control-sm"
                  onChange={this.onChange}
                  value={this.state.status}
                  required={true}
                >
                  <option vlaue="New">New</option>
                  <option vlaue="WIP">WIP</option>
                  <option vlaue="Complete">Completed</option>
                  <option vlaue="Reject">Rejected</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label className="bold label-sm">Service Manager</Label>
                <Input
                  name="pmName"
                  type="select"
                  className="form-control-sm"
                  onChange={this.onChange}
                  value={this.state.pmName}
                  required={true}
                >
                {this.props.employee &&
                  this.props.employee.data.map(item => {
                    return <option
                      key={item.empId}
                      value={`${item.firstName} ${item.lastName}`}>
                      {`${item.firstName} ${item.lastName}`}
                    </option>
                  })}
                </Input>
              </FormGroup>
              <FormComponent                
                label="Teams"
                labelClass="label-sm bold"
                type="text"
                name="teams"
                value={this.state.teams}
                change={this.onChange}    
                required={true}            
              />
              <FormGroup>
                <Label className="bold label-sm">Service type</Label>
                <Input
                  name="serviceType"
                  type="select"
                  className="form-control-sm"
                  onChange={this.onChange}
                  value={this.state.serviceType}
                  required={true}
                >
                  <option value="Small">Small</option>
                  <option value="Large">Large</option>
                </Input>
              </FormGroup>
             <FormGroup>
                    <Label className="bold label-sm">Billing Type</Label>
                    <Input
                      type="select"
                      name="billType"
                      className="form-control-sm"
                      onChange={this.onChange}
                      value={this.state.billType}
                      required
                    >
                      <option value="T M">T&M</option>
                      <option value="Fixed">Fixed</option>
                    </Input>
                  </FormGroup> 
              <FormComponent                
                label="Service Description"
                labelClass="label-sm bold"
                type="textarea"
                name="description"
                value={this.state.description}
                change={this.onChange} 
                required={true}               
              />
              <FormComponent
                label="Remarks"
                labelClass="label-sm bold"
                type="textarea"
                name="remarks"
                value={this.state.remarks}
                change={this.onChange}
                required={true}
              />
            </Col>
          </Row>
          <div className="center">
            <button
              type="submit"
              className="btn btn-custom"
            >
              Update
            </button>
          </div>
        </Form>
        {this.props.services.message && <Notifier message={this.props.services.message} />}
        {this.state.error && <ErrorNotifier message={this.state.error} />}
      </Container>
    );
  }
}

updateService.propTypes = {
    updateService : PropTypes.func.isRequired,
    getEmployeeDetails: PropTypes.func.isRequired,
    employee : PropTypes.object.isRequired,
    services : PropTypes.object.isRequired,
    getCustomers: PropTypes.func.isRequired,
    customers: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    employee : state.employee,
    services : state.services,
    customers : state.customers
})

export default connect(
                        mapStateToProps,
                        { updateService, getEmployeeDetails, getCustomers })
                        (UpdateService);