import axios from "axios";
import { DEPARTMENTS_GET } from "../../types";
import { CURD_DEPARTMENTS, USER_DEPARTMENTS } from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addDepartments = (data, back) => (dispatch) => {
  axios
    .post(CURD_DEPARTMENTS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getDepartments));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getDepartments = () => (dispatch) => {
  axios
    .get(CURD_DEPARTMENTS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(DEPARTMENTS_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUserDepartments = () => (dispatch) => {
  axios
    .get(USER_DEPARTMENTS, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(DEPARTMENTS_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

// export const fetchDepartments = () => (dispatch) => {
//   axios
//     .get(FETCH_DEPARTMENTS, { withCredentials: true })
//     .then((res) => {
//       dispatch(getDispatch(DEPARTMENTS_FETCH, res));
//     })
//     .catch((err) => {
//       dispatch(setErrorMessage(err));
//     });
// };

export const updateDepartments = (data, back) => (dispatch) => {
  axios
    .put(CURD_DEPARTMENTS, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getDepartments));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteDepartments = (data) => (dispatch) => {
  axios
    .delete(CURD_DEPARTMENTS, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getDepartments));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
