import axios from "axios";
import { GET_UPLOADED_RESUME_RS,GET_CURRENT_FILE_VALUE } from "../../types";
import {  CURD_RESUME_RS_DOCUMENTS  } from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addResumeUpload = (data, back,current) => (dispatch) => {

  axios
    .post( CURD_RESUME_RS_DOCUMENTS , data, { withCredentials: true })
    .then((res) => {
     dispatch(setSuccessMessage(res, back));
      dispatch({
        type:"GET_CURRENT_FILE_VALUE",
        payload:current
      })
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getResumeUpload = (data) => (dispatch) => {
  console.log(data)
  axios
    .get(`${ CURD_RESUME_RS_DOCUMENTS}/common` , { withCredentials: true })
    .then((res) => {
      console.log(res)
      dispatch(getDispatch( GET_UPLOADED_RESUME_RS, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateResumeUpload = (data, back) => (dispatch) => {
  axios
    .put( CURD_RESUME_RS_DOCUMENTS , data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteResumeUpload = (data) => (dispatch) => {
  axios
    .delete( CURD_RESUME_RS_DOCUMENTS , { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getResumeUpload));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
