import React, { Component } from "react";
import dateFns from "date-fns";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// components
import ViewAttendance from "./ViewAttendance";
// redux
import { fetchMyAttendance } from "../../../../../redux/actions/hcm/lms/myAttendanceAction";

// --------------------------------------------------------------------------------------------------------
class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leaveDays: [],
      presentDays: [],
      currentMonth: new Date(),
      selectedDate: new Date(),
      isSelect: false,
      modalProp: "",
      timeProp: "",
      timeOutProp: "",
      attendanceStatus: "",
    };
    this.mainLogic = this.mainLogic.bind(this);
    this.previousMonth = this.previousMonth.bind(this);
    this.nextMonth = this.nextMonth.bind(this);
    this.back = this.back.bind(this);
    this.assignProps = this.assignProps.bind(this);
    this.checkPresence = this.checkPresence.bind(this);
    this.assignTime = this.assignTime.bind(this);
    this.clearAbsence = this.clearAbsence.bind(this);
    this.checkTimeOutPresence = this.checkTimeOutPresence.bind(this);
    this.checkStatus = this.checkStatus.bind(this);
  }

  componentDidMount() {
    this.props.fetchMyAttendance(this.mainLogic);
  }

  mainLogic() {
    let Ldays = [];
    let Pdays = [];
    this.props.myAttendance.fetchData.forEach((item) => {
      let split_arr = item.submittedOn.split("-");
      let date = parseInt(split_arr[2], 10);
      if (!item.timeIn) {
        for (let i = 1; i < 32; i++) {
          if (i === date) {
            Ldays.push(parseInt(split_arr[2], 10));
            this.setState({ leaveDays: Ldays });
          }
        }
      } else {
        for (let i = 1; i < 32; i++) {
          if (i === date) {
            Pdays.push({
              day: parseInt(split_arr[2], 10),
              month: parseInt(split_arr[1], 10),
              timeIn: item.timeIn,
              timeOut: item.timeOut,
              attendanceStatus: item.attendanceStatus,
            });
            this.setState({
              presentDays: Pdays,
            });
          }
        }
      }
    });
  }

  onSelect = (date) => {
    this.setState({
      selectedDate: date,
    });
  };

  back() {
    this.setState({
      isSelect: !this.state.isSelect,
    });
  }

  clearAbsence() {
    this.setState({
      modalProp: "",
      timeProp: "",
      timeOutProp: "",
    });
    this.back();
  }

  checkPresence(date, month) {
    let r;
    this.state.presentDays.map((item) => {
      if (parseInt(date) === item.day && parseInt(month) === item.month) {
        r = item.timeIn;
      }
      return r;
    });
    return r;
  }

  /*
checkTimeOutPresence function for getting the timeOut data
*/
  checkTimeOutPresence(date, month) {
    let o;
    this.state.presentDays.map((item) => {
      if (parseInt(date) === item.day && parseInt(month) === item.month) {
        o = item.timeOut;
      }
      return o;
    });
    return o;
  }

  /*
checkStatus function for getting the attendanceStatus
*/
  checkStatus(date, month) {
    let p;
    this.state.presentDays.map((item) => {
      if (parseInt(date) === item.day && parseInt(month) === item.month) {
        p = item.attendanceStatus;
      }
      return p;
    });
    return p;
  }

  assignProps() {
    this.setState({
      modalProp: "Absent",
    });
    this.back();
  }

  assignTime(data, data1, data2) {
    this.setState({
      timeProp: data,
      timeOutProp: data1,
      attendanceStatus: data2,
    });
    this.back();
  }

  toggleModal() {
    return (
      <ViewAttendance
        back={this.clearAbsence}
        date={this.state.selectedDate}
        timeData={this.state.timeProp}
        timeOutData={this.state.timeOutProp}
        absent={this.state.modalProp}
        attendanceStatus={this.state.attendanceStatus}
      />
    );
  }

  previousMonth() {
    this.setState({
      currentMonth: dateFns.subMonths(this.state.currentMonth, 1),
    });
  }

  nextMonth() {
    this.setState({
      currentMonth: dateFns.addMonths(this.state.currentMonth, 1),
    });
  }

  renderHeader() {
    const dateFormat = "MMMM YYYY";
    return (
      <div className="header row flex-middle">
        {this.state.isSelect && this.toggleModal()}
        <div className="col col-start">
          <div className="icon" onClick={this.previousMonth}>
            <b>chevron_left</b>
          </div>
        </div>
        <div className="col col-center">
          <span>{dateFns.format(this.state.currentMonth, dateFormat)}</span>
        </div>
        <div className="col col-end">
          <div className="icon" onClick={this.nextMonth}>
            <b>chevron_right</b>
          </div>
        </div>
      </div>
    );
  }

  renderDays() {
    const dateFormat = "dddd";
    const days = [];
    let startDate = dateFns.startOfWeek(this.state.currentMonth);

    for (let i = 0; i < 7; i++) {
      days.push(dateFns.format(dateFns.addDays(startDate, i), dateFormat));
    }

    return (
      <div className="days row">
        {days.map((day) => {
          return (
            <div className="col col-center" key={day}>
              <b>{day}</b>
            </div>
          );
        })}
      </div>
    );
  }

  dataRender = (day, dateFormat, monthFormat) => {
    let formattedDate = dateFns.format(day, dateFormat);
    let formattedMonth = dateFns.format(day, monthFormat);
    //  var currentTime = new Date();

    var defaultAttendanceTime = new Date();
    defaultAttendanceTime.setHours(18);

    return this.state.presentDays.map((item) => {
      if (
        parseInt(formattedDate) === item.day &&
        parseInt(formattedMonth) === item.month
      ) {
        return (
          <div key={item.day}>
            <span className="label-sm lead">IN:&nbsp;{item.timeIn}</span>
            <br />
            <span className="label-sm lead">OUT:&nbsp;{item.timeOut}</span>
            {/* <span className="label-sm lead">OUT:&nbsp;{(item.day === parseInt(dateFns.format(new Date(), 'D')))? (item.timeOut)&&(currentTime > defaultAttendanceTime)?item.timeOut:"NA":(item.timeOut)?item.timeOut:"NA"}</span>  */}
          </div>
        );
      }
      return null;
    });
  };

  renderCells() {
    const { currentMonth, selectedDate } = this.state;
    const monthStart = dateFns.startOfMonth(currentMonth);
    const monthEnd = dateFns.endOfMonth(currentMonth);
    const startDate = dateFns.startOfWeek(monthStart);
    const endDate = dateFns.endOfWeek(monthEnd);

    const dateFormat = "D";
    const monthFormat = "MM";
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = "";
    let newFormattedDate = "";
    let formattedMonth = "";

    // Logic for displaying date in calender

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = dateFns.format(day, dateFormat);
        newFormattedDate = dateFns.format(day, dateFormat);
        formattedMonth = dateFns.format(day, monthFormat);

        const dayClone = day;
        const timeData = this.checkPresence(newFormattedDate, formattedMonth);
        const timeOutData = this.checkTimeOutPresence(
          newFormattedDate,
          formattedMonth
        );
        const attendanceStatus = this.checkStatus(
          newFormattedDate,
          formattedMonth
        );
        if (this.state.leaveDays.indexOf(parseInt(formattedDate, 10)) > -1) {
          days.push(
            <div
              className={`col cell ${
                !dateFns.isSameMonth(day, monthStart)
                  ? "disabled"
                  : dateFns.isSameDay(day, selectedDate)
                  ? "selected"
                  : "leave"
              }`}
              key={day}
              onClick={() => this.onSelect(dateFns.parse(dayClone))}
              onDoubleClick={this.assignProps}
            >
              <span className="number">{formattedDate}</span>
              <span className="bg">{formattedDate}</span>
            </div>
          );
        } else {
          days.push(
            <div
              className={`col cell ${
                !dateFns.isSameMonth(day, monthStart)
                  ? "disabled"
                  : dateFns.isSameDay(day, selectedDate)
                  ? "selected"
                  : ""
              }`}
              key={day}
              onClick={() => this.onSelect(dateFns.parse(dayClone))}
              onDoubleClick={() =>
                this.assignTime(timeData, timeOutData, attendanceStatus)
              }
            >
              <span className="number">{formattedDate}</span>
              <span className="bg">{formattedDate}</span>

              {this.dataRender(day, dateFormat, monthFormat)}
            </div>
          );
        }
        day = dateFns.addDays(day, 1);
      }
      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  }

  render() {
    return (
      <div className="calendar">
        {this.renderHeader()}
        {this.renderDays()}
        {this.renderCells()}
      </div>
    );
  }
}

Calendar.propTypes = {
  fetchMyAttendance: PropTypes.func.isRequired,
  myAttendance: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  fetchMyAttendance: PropTypes.func.isRequired,
  myAttendance: state.myAttendance,
});

export default connect(mapStateToProps, { fetchMyAttendance })(Calendar);
