import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
} from "reactstrap";
import { connect } from "react-redux";
import AddPOEntry from "./AddPOEntry";
import POEntryDetails from "./POEntryDetails";
import {
  getPOEntry,
  updatePOEntry,
  searchIssuePO,
  resetItems,
} from "../../../../../redux/actions/finance/accounts_receivable/po_entry/poEntryAction";
import {
  refresh,
  submitValidator,
  valid,
  Validator,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import Hover from "../../../../aside/Hover";

/**
 * @class POEntry
 **/

class POEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addPoEntry: false,
      data: this.props.poEntry.data || [],
      selection: false,
      selected: {},
      subItem: {},
      modal: false,
      hover: false,
      cords: {},
      mouse: {},
      searchTerm: "",
      remarks: "",
      message: "",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.select = this.select.bind(this);
    this.back = this.back.bind(this);
    this.search = this.search.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.toggleHoverOn = this.toggleHoverOn.bind(this);
    this.toggleHoverOff = this.toggleHoverOff.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.toggle1 = this.toggle1.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.issuePOs !== this.props.issuePOs) {
      this.setState({
        data: this.props.issuePOs.data,
      });
    }
  }

  search(e) {
    e.preventDefault();
    this.setState({
      searchTerm: e.target.value,
    });
    this.onSearch(e.target.value);
  }

  onSearch(value) {
    let data = {};
    let isInteger = value.length && /^[0-9]*$/.test(value);
    if (isInteger) {
      data.pymtEntryId = Number(value);
      data.name = "null";
    } else {
      data.poId = 0;
      data.name = value;
    }
    this.props.searchIssuePO(data);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      remarks: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    const data = this.state.data.filter((item) => {
      if (item.poId === this.state.subItem) {
        return item;
      } else {
        return null;
      }
    });

    const dragIssuePO = {
      poId: data[0].poId,
      poEntryStatus: data[0].poEntryStatus,
      remarks: this.state.remarks,
    };
    if (valid) {
      this.props.updatePOEntry(dragIssuePO, this.toggle1);
      setTimeout(() => {
        this.toggle();
        this.getData();
      }, 2000);
    } else {
      this.setState({
        error: "Enter mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  toggleHoverOn(e, item) {
    this.setState({
      hover: true,
      cords: {
        one: item.contactName,
        two: item.contactEmail,
        three: item.contactMobileNo,
      },
      mouse: {
        x: e.screenX,
        y: e.screenY,
      },
    });
  }

  toggleHoverOff() {
    this.setState({
      hover: false,
    });
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
      remarks: "",
    });
  }

  /*
Toggle1 function for modal cancel button here called the getData Fuction
*/
  toggle1() {
    this.setState({
      modal: false,
    });
    this.getData();
  }

  select(item) {
    this.setState({
      selection: item,
      selected: item.poId,
      popup: true,
    });
  }

  back() {
    this.setState({
      selection: false,
    });

    this.getData();
  }

  onDragOver(e) {
    e.preventDefault();
  }
  onDragStart(e, item) {
    e.dataTransfer.setData("item", item.poId);
    this.setState({
      subItem: item.poId,
    });
  }

  async onDrop(e, cat) {
    let id = e.dataTransfer.getData("item");
    let newData;
    newData = this.state.data.filter((item) => {
      if (item.poId === parseInt(id, 10)) {
        item.poEntryStatus = cat;
        this.setState({
          subItem: item,
        });
      }
      return newData;
    });
    this.setState({
      ...this.state,
      newData,
    });
    await this.toggle();
  }

  // openAddPOEntry function to open the IssuePO add form
  openAddPOEntry = () => {
    this.setState({
      addPoEntry: true,
    });
    this.props.resetItems();
  };

  // closeAddPOEntry function to close the IssuePO add form
  closeAddPOEntry = () => {
    this.setState({
      addPoEntry: false,
    });
    this.getData();
  };

  // componentDidMount function for calling the IssuePO list data
  componentDidMount() {
    this.getData();
  }

  // getData function to call the all the IssuePO data
  getData = () => {
    this.props.getPOEntry();
  };

  render() {
    const {
      addPoEntry,
      data,
      selection,
      selected,
      modal,
      hover,
      cords,
      remarks,
      error,
    } = this.state;

    return (
      <div className="container-fluid">
        <div className="row">
          <Modal isOpen={modal} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle1}>Confirm changes?</ModalHeader>
            <ModalBody>
              <Form className="p-3" onSubmit={this.onSubmit} noValidate>
                <FormComponent
                  labelClass="bold label-sm"
                  label="Remarks"
                  type="text"
                  name="remarks"
                  change={this.onChange}
                  value={remarks}
                  required={true}
                />
                <FormGroup className="center">
                  <button type="submit" className="btn login-button white-text">
                    Submit
                  </button>
                </FormGroup>
              </Form>
            </ModalBody>
          </Modal>
          {!addPoEntry ? (
            <div className="col-12 mx-auto">
              {!selection ? (
                <Container className="card container-card">
                  {/* <input
                  type="text"
                  onChange={this.search}
                  value={this.state.searchTerm}
                  className="searchfield form-control-sm"
                  placeholder="Invoice Id/Name"
                /> */}
                  {hover && (
                    <Hover
                      cords={cords}
                      labels={{
                        one: "Contact Name",
                        two: "Contact Email",
                        three: "Contact Number",
                      }}
                      mouse={this.state.mouse}
                    />
                  )}
                  <div className="div">
                    <button
                      className="btn add-button white-text label-sm"
                      onClick={this.openAddPOEntry}
                    >
                      Add
                    </button>
                  </div>
                  <i
                    className="material-icons pointer"
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      color: "#C00",
                    }}
                    onClick={this.props.back}
                  >
                    cancel
                  </i>
                  <h5 className="bold center pt-2">PO Entry</h5>
                  <Row className="p-1">
                    <Col
                      md="4"
                      onDragOver={this.onDragOver}
                      onDrop={(e) => this.onDrop(e, "New")}
                      className="card p-2"
                    >
                      <h6 className="center bold m-2">New</h6>
                      <ul className="list flex-around  ul-head ">
                        <li className="list-item bold pad">ID</li>
                        <li className="list-item bold flex-1">Type</li>
                        <li className="list-item bold flex-1">Name</li>
                      </ul>
                      {this.props.poEntry.data &&
                        this.props.poEntry.data.map((item) => {
                          if (item.poEntryStatus === "New") {
                            return (
                              <ul
                                key={item.poId}
                                onClick={() => {
                                  this.select(item);
                                }}
                                onMouseEnter={(e) =>
                                  this.toggleHoverOn(e, item)
                                }
                                onMouseLeave={this.toggleHoverOff}
                                draggable
                                onDragStart={(e) => this.onDragStart(e, item)}
                                className={`list flex-around pointer ${
                                  selected &&
                                  selected === item.poId &&
                                  "selected-item"
                                } InqList`}
                              >
                                <li className="list-table-item pad">
                                  {item.poId}
                                </li>
                                <li className="list-table-item flex-1">
                                  {item.poType}
                                </li>
                                <li className="list-table-item flex-1">
                                  {item.customerName}
                                </li>
                              </ul>
                            );
                          } else {
                            return null;
                          }
                        })}
                    </Col>
                    <Col
                      md="4"
                      onDragOver={this.onDragOver}
                      onDrop={(e) => this.onDrop(e, "WIP")}
                      className="card p-2"
                    >
                      <h6 className="center bold m-2">Work in progress</h6>
                      <ul className="list flex-around  ul-head">
                        <li className="list-item bold pad">ID</li>
                        <li className="list-item bold flex-1">Type</li>
                        <li className="list-item bold flex-1">Name</li>
                      </ul>
                      {data &&
                        data.map((item) => {
                          if (item.poEntryStatus === "WIP") {
                            return (
                              <ul
                                key={item.poId}
                                onClick={() => {
                                  this.select(item);
                                }}
                                onMouseEnter={(e) =>
                                  this.toggleHoverOn(e, item)
                                }
                                onMouseLeave={this.toggleHoverOff}
                                draggable
                                onDragStart={(e) => this.onDragStart(e, item)}
                                className={`list flex-around pointer ${
                                  selected &&
                                  selected === item.poId &&
                                  "selected-item"
                                } InqList`}
                              >
                                <li className="list-table-item pad">
                                  {item.poId}
                                </li>
                                <li className="list-table-item flex-1">
                                  {item.poType}
                                </li>
                                <li className="list-table-item flex-1">
                                  {item.customerName}
                                </li>
                              </ul>
                            );
                          } else {
                            return null;
                          }
                        })}
                    </Col>
                    <Col
                      md="4"
                      className="card p-2"
                      onDrop={(e) => this.onDrop(e, "Closed")}
                      onDragOver={this.onDragOver}
                    >
                      <h6 className="center bold m-2">Completed</h6>
                      <ul className="list flex-around  ul-head">
                        <li className="list-item bold pad">ID</li>
                        <li className="list-item bold flex-1">Type</li>
                        <li className="list-item bold flex-1">Name</li>
                      </ul>
                      {data &&
                        data.map((item) => {
                          if (
                            item.poEntryStatus !== "New" &&
                            item.poEntryStatus !== "WIP"
                          ) {
                            return (
                              <ul
                                key={item.poId}
                                onClick={() => {
                                  this.select(item);
                                }}
                                onMouseEnter={(e) =>
                                  this.toggleHoverOn(e, item)
                                }
                                onMouseLeave={this.toggleHoverOff}
                                // draggable
                                // onDragStart={ (e) => this.onDragStart(e, item) }
                                className={`list flex-around pointer ${
                                  selected &&
                                  selected === item.poId &&
                                  "selected-item"
                                } ${
                                  item.PoEntryStatus === "Complete" &&
                                  "completed-item"
                                } InqList`}
                              >
                                <li className="list-table-item pad">
                                  {item.poId}
                                </li>
                                <li className="list-table-item flex-1">
                                  {item.poType}
                                </li>
                                <li className="list-table-item flex-1">
                                  {item.customerName}
                                </li>
                              </ul>
                            );
                          } else {
                            return null;
                          }
                        })}
                    </Col>
                  </Row>
                </Container>
              ) : (
                <POEntryDetails
                  back={this.back}
                  POEntryDetails={this.state.selection}
                />
              )}
            </div>
          ) : (
            <AddPOEntry back={this.closeAddPOEntry} />
          )}
        </div>
      </div>
    );
  }
}

POEntry.propTypes = {
  getPOEntry: PropTypes.func.isRequired,
  updatePOEntry: PropTypes.func.isRequired,
  poEntry: PropTypes.object.isRequired,
  resetItems: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  poEntry: state.poEntry,
});

export default connect(mapStateToProps, {
  getPOEntry,
  resetItems,
  updatePOEntry,
})(POEntry);
