import React from "react";
// material ui
import { IconButton } from "@material-ui/core";
// material icons
import EditIcon from "@material-ui/icons/Edit";

// ------------------------------------------------------------------

function EditIconButton({ classes, color, handleClick, title, ...others }) {
  return (
    <>
      <IconButton
        className={classes}
        color={color}
        onClick={handleClick}
        title={title}
      >
        <EditIcon />
      </IconButton>
    </>
  );
}

export default EditIconButton;
