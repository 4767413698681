import React, { Component } from "react";
import DateFns from "date-fns";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// components
import UpdatePassportDetails from "./UpdatePassportDetails";
// redux
import { getPassportDetails } from "../../../../../../redux/actions/hcm/my_profile/identification_info/passportDetailsAction";

// -------------------------------------------------------------------------------------------------------------------------------
class PassportDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateBtn: false,
    };
    this.openUpdateForm = this.openUpdateForm.bind(this);
    this.closeUpdateForm = this.closeUpdateForm.bind(this);
    this.getData = this.getData.bind(this);
  }

  openUpdateForm() {
    this.setState({
      updateBtn: true,
    });
  }

  closeUpdateForm() {
    this.setState({
      updateBtn: false,
    });
    this.getData();
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.props.getPassportDetails();
  }

  render() {
    const { updateBtn } = this.state;

    return (
      <React.Fragment>
        {!updateBtn ? (
          <React.Fragment>
            <i
              className="material-icons pointer contact-edit-btn"
              onClick={this.openUpdateForm}
            >
              edit
            </i>
            <h5 className="bold center">Passport Details</h5>
            <ul>
              <li>
                <span className="label-sm bold contact-details-span">
                  Geography
                </span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  <span className="my-profile-identification-info-span-data">
                    {this.props.passportDetails.data.length > 0
                      ? this.props.passportDetails.data[0].geoName
                      : null}
                  </span>
                </span>
              </li>
              <li>
                <span className="label-sm bold contact-details-span">
                  Country Name
                </span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  <span className="my-profile-identification-info-span-data">
                    {this.props.passportDetails.data.length > 0
                      ? this.props.passportDetails.data[0].countryName
                      : null}
                  </span>
                </span>
              </li>
              <li>
                <span className="label-sm bold contact-details-span">
                  Passport No.
                </span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  <span className="my-profile-identification-info-span-data">
                    {this.props.passportDetails.data.length > 0
                      ? this.props.passportDetails.data[0].passportNumber
                      : null}
                  </span>
                </span>
              </li>
              <li>
                <span className="label-sm bold contact-details-span">
                  Issue place
                </span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  <span className="my-profile-identification-info-span-data">
                    {this.props.passportDetails.data.length > 0
                      ? this.props.passportDetails.data[0].issuePlace
                      : null}
                  </span>
                </span>
              </li>
              <li>
                <span className="label-sm bold contact-details-span">
                  Issue Date
                </span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  <span className="my-profile-identification-info-span-data">
                    {this.props.passportDetails.data.length > 0
                      ? DateFns.format(
                          this.props.passportDetails.data[0].issueDate,
                          "DD-MM-YYYY"
                        )
                      : null}
                  </span>
                </span>
              </li>
              <li>
                <span className="label-sm bold contact-details-span">
                  Expiry Date
                </span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  <span className="my-profile-identification-info-span-data">
                    {this.props.passportDetails.data.length > 0
                      ? DateFns.format(
                          this.props.passportDetails.data[0].expiryDate,
                          "DD-MM-YYYY"
                        )
                      : null}
                  </span>
                </span>
              </li>
              <li>
                <span className="label-sm bold contact-details-span">
                  Remarks
                </span>
                <span className=" label-sm">
                  &nbsp;&nbsp;:&nbsp;&nbsp;
                  <span className="my-profile-identification-info-span-data">
                    {this.props.passportDetails.data.length > 0
                      ? this.props.passportDetails.data[0].remarks
                      : null}
                  </span>
                </span>
              </li>
            </ul>
          </React.Fragment>
        ) : null}
        {updateBtn && (
          <UpdatePassportDetails
            back={this.closeUpdateForm}
            data={this.props.passportDetails.data}
          />
        )}
      </React.Fragment>
    );
  }
}

PassportDetails.propTypes = {
  getPassportDetails: PropTypes.func.isRequired,
  passportDetails: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  passportDetails: state.passportDetails,
});

export default connect(mapStateToProps, { getPassportDetails })(
  PassportDetails
);
