import React, { Component } from "react";
import { Form, Row, Col,Label,Input,FormText,FormGroup} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getJobCategory,
} from "../../../../../redux/actions/rs/configuration/jobCategoryAction";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import Notifier from "../../../../aside/Notifier";
import { addJobPosition  } from "../../../../../redux/actions/rs/configuration/jobPositionAction";
// add position component
class AddJobPosition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobPositionName: "",
      jobPositionDescription: "",
      jobCategory:"",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
//  common onchange function
  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  // submit function
  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      jobCategoryId:Number(this.state.jobCategory),
      jobPositionName: this.state.jobPositionName,
      jobPositionDescription: this.state.jobPositionDescription,
     
    };
    if (valid) {
      this.props.addJobPosition(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidMount contains the error refresh method and setState method for calling the render method
*/
  componentDidMount() {
    this.props.getJobCategory()
    refresh();
    this.setState({
      error: "",
    });
  }

  render() {
   
    const { jobPositionName,jobPositionDescription,jobCategory, error } = this.state;

    return (
      <React.Fragment>
        <h5 className="center card-heading pt-2">Add Job Position</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="6" className="mx-auto">

            <FormGroup>
                <Label className="bold label-sm">Job Category </Label>
                <Input
                  type="select"
                  name="jobCategory"
                  className="form-control-sm"
                  value={this.state.jobCategory}
                  onChange={this.onChange}
              
                  required={true}
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {this.props.JobCategory.data.map((item) => {
                    return (
                      <option
                        key={item.id}
                        value={item.id}
                      >
                        {item.jobCatName}-{item.id}
                      </option>
                    );
                  })}
                </Input>
                {errors.jobCategory && (
                  <FormText className="error">{errors.jobCategory}</FormText>
                )}
              </FormGroup>
              <FormComponent
                labelClass="bold label-sm"
                label="Job Position Name"
                type="text"
                name="jobPositionName"
                inputClass="form-control-sm"
                value={jobPositionName}
                change={this.onChange}
                required={true}
                length="30"
              />
             
        
              <FormComponent
                labelClass="bold label-sm"
                label="Job Position Description"
                type="text"
                name="jobPositionDescription"
                inputClass="form-control-sm"
                value={jobPositionDescription}
                change={this.onChange}
                required={true}
                length="30"
              />
             
            </Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Add
            </button>
          </div>
        </Form>
        {error && <ErrorNotifier message={error} />}
        {this.props.JobPosition.error && (
          <ErrorNotifier message={this.props.JobPosition.error} />
        )}
        {this.props.JobPosition.message && (
          <Notifier message={this.props.JobPosition.message} />
        )}
      </React.Fragment>
    );
  }
}

AddJobPosition.propTypes = {
  addJobPosition: PropTypes.func.isRequired,
  userGroups: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  JobPosition: state.JobPosition,
  JobCategory: state.JobCategory,
});

export default connect(mapStateToProps, { addJobPosition, getJobCategory })(AddJobPosition);
