import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { Card } from "reactstrap";
import axios from "axios";
import { GET_CUSTOMER_BY_ACCOUNTS } from "../../../../utils/routes";

const xAxisStyle = {
  fontSize: "8px",
  fontWeight: "400",
  marginBottom: "10px",
};

export default class CustomerByAccounts extends PureComponent {
  static jsfiddleUrl = "https://jsfiddle.net/alidingling/xqjtetw0/";

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      error: "",
    };
  }

  componentDidMount() {
    axios
      .get(GET_CUSTOMER_BY_ACCOUNTS, { withCredentials: true })
      .then((res) => {
        this.setState({
          data: res.data.rows,
        });
      })
      .catch((err) => {
        this.setState({
          error: err,
        });
      });
  }

  render() {
    const { data } = this.state;

    return (
      <Card className="recharts-card">
        <BarChart
          width={310}
          height={130}
          data={data}
          margin={{
            top: 5,
            right: 40,
            left: -20,
            bottom: 5,
          }}
          barSize={15}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="compFullName"
            tick={{ fontSize: "10px", fontWeight: "400" }}
            hide
          />
          <YAxis
            tick={{ fontSize: "10px", fontWeight: "400" }}
            domain={[0, "dataMax"]}
          />
          <Tooltip contentStyle={{ fontSize: "10px", fontWeight: "400" }} />
          {/* <Legend iconSize="8px" wrapperStyle={xAxisStyle} /> */}
          <Bar dataKey="Accounts" barSize={15} fill="#8884d8" />
        </BarChart>
      </Card>
    );
  }
}
