import React, { Component } from "react";
import { Form, FormGroup, Label, Input } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// components
import FormComponent from "../../../../../common/FormComponent";
import {
  Validator,
  submitValidator,
  refresh,
  valid,
} from "../../../../../common/Validator";
import Notifier from "../../../../../aside/Notifier";
import ErrorNotifier from "../../../../../aside/ErrorNotifier";
// redux
import { updateBankAccountsInfo } from "../../../../../../redux/actions/hcm/my_profile/accounts_details/bankAccountsInfoAction";

// --------------------------------------------------------------------------------------------------------------------------------------
class UpdateBankInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountName: this.props.data.length ? this.props.data[0].accountName : "",
      accountNo: this.props.data.length ? this.props.data[0].accountNo : "",
      accountType: this.props.data.length
        ? this.props.data[0].accountType
        : "Savings",
      bankName: this.props.data.length ? this.props.data[0].bankName : "",
      branchName: this.props.data.length ? this.props.data[0].branchName : "",
      branchAddress: this.props.data.length
        ? this.props.data[0].branchAddress
        : "",
      ifscCode: this.props.data.length ? this.props.data[0].ifscCode : "",
      message: "",
      error: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      accountName: this.state.accountName,
      accountNo: this.state.accountNo,
      accountType: this.state.accountType,
      bankName: this.state.bankName,
      branchName: this.state.branchName,
      branchAddress: this.state.branchAddress,
      ifscCode: this.state.ifscCode,
    };
    if (valid) {
      this.props.updateBankAccountsInfo(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    refresh();
    this.setState({
      message: "",
      error: "",
    });
  }

  render() {
    const {
      accountName,
      accountNo,
      accountType,
      bankName,
      branchName,
      branchAddress,
      ifscCode,
      message,
      error,
    } = this.state;

    return (
      <React.Fragment>
        <i
          className="material-icons cancel-button pointer"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <FormComponent
            labelClass="bold label-sm"
            label="Account Name"
            type="text"
            name="accountName"
            inputClass="form-control-sm"
            value={accountName}
            change={this.onChange}
            required={true}
          />
          <FormComponent
            labelClass="bold label-sm"
            label="Account No."
            type="number"
            name="accountNo"
            inputClass="form-control-sm"
            value={accountNo}
            change={this.onChange}
            required={true}
          />
          <FormGroup>
            <Label className="bold label-sm">Account Type</Label>
            <Input
              type="select"
              name="accountType"
              value={accountType}
              onChange={this.onChange}
              required={true}
            >
              <option value="Savings">Savings</option>
              <option value="Current">Current</option>
            </Input>
          </FormGroup>
          <FormComponent
            labelClass="bold label-sm"
            label="Bank Name"
            type="text"
            name="bankName"
            value={bankName}
            change={this.onChange}
            required={true}
          />
          <FormComponent
            labelClass="bold label-sm"
            label="Branch Name"
            type="text"
            name="branchName"
            value={branchName}
            change={this.onChange}
            required={true}
          />
          <FormComponent
            labelClass="bold label-sm"
            label="Branch Address"
            type="text"
            name="branchAddress"
            value={branchAddress}
            change={this.onChange}
            required={true}
          />
          <FormComponent
            labelClass="bold label-sm"
            label="IFSC Code"
            type="text"
            name="ifscCode"
            value={ifscCode}
            change={this.onChange}
            required={true}
            maxlength="11"
          />
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Update
            </button>
          </div>
        </Form>
        {this.props.bankAccountsInfo.message && (
          <Notifier message={this.props.bankAccountsInfo.message} />
        )}
        {this.props.bankAccountsInfo.error && (
          <ErrorNotifier message={this.props.bankAccountsInfo.error} />
        )}
      </React.Fragment>
    );
  }
}

UpdateBankInformation.propTypes = {
  updateBankAccountsInfo: PropTypes.func.isRequired,
  bankAccountsInfo: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  bankAccountsInfo: state.bankAccountsInfo,
});

export default connect(mapStateToProps, { updateBankAccountsInfo })(
  UpdateBankInformation
);
