import React, { Component } from "react";
import { Form, Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DateFns from "date-fns";
// components
import {
  Validator,
  submitValidator,
  valid,
  refresh,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
// redux
import { updateCompanyHolidays } from "../../../../../redux/actions/hcm/configurations/companyHolidaysAction";

// -----------------------------------------------------------------------------------------------------------
class UpdateCompanyHoliday extends Component {
  constructor(props) {
    super(props);
    const { id, holidayDate, holidayOccasion, holidayDay } = this.props.data;
    this.state = {
      holidayId: id,
      phDate: holidayDate,
      phOccasion: holidayOccasion,
      phDay: holidayDay,
      message: "",
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      id: this.state.holidayId,
      holidayDate: this.state.phDate,
      holidayOccasion: this.state.phOccasion,
      holidayDay: this.state.phDay,
    };
    if (valid) {
      this.props.updateCompanyHolidays(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidMount contains the error refresh method and setState method to call the render method
*/
  componentDidMount() {
    refresh();
    this.setState({
      message: "",
      error: "",
    });
  }

  render() {
    const { phDate, phOccasion, phDay, error, message } = this.state;

    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Update Company Holiday</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Row>
          <Col md="2"></Col>
          <Col md="8">
            <Form className="p-3" onSubmit={this.onSubmit}>
              <FormComponent
                labelClass="bold label-sm"
                label="Date of leave"
                type="date"
                name="phDate"
                inputClass="form-control-sm"
                value={DateFns.format(phDate, "YYYY-MM-DD")}
                change={this.onChange}
                required={true}
                max="9999-12-31"
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Day of leave"
                type="text"
                name="phDay"
                inputClass="form-control-sm"
                value={phDay}
                change={this.onChange}
                required={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="Occasion"
                type="text"
                name="phOccasion"
                inputClass="form-control-sm"
                value={phOccasion}
                change={this.onChange}
                required={true}
              />
              <div className="center">
                <button type="submit" className="btn login-button white-text">
                  Submit
                </button>
              </div>
            </Form>
          </Col>
        </Row>
        {this.props.companyHolidays.message && (
          <Notifier message={this.props.companyHolidays.message} />
        )}
        {this.props.companyHolidays.error && (
          <ErrorNotifier message={this.props.companyHolidays.error} />
        )}
      </React.Fragment>
    );
  }
}

UpdateCompanyHoliday.proptypes = {
  updateCompanyHolidays: PropTypes.func.updateCompanyHolidays,
  companyHolidays: PropTypes.object.companyHolidays,
};

const mapStateToProps = (state) => ({
  companyHolidays: state.companyHolidays,
});

export default connect(mapStateToProps, { updateCompanyHolidays })(
  UpdateCompanyHoliday
);
