import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Table,
  Modal,
  Input,
  FormGroup,
  Label,
  ModalBody,
  ModalHeader,
  Form,
  FormText,
} from "reactstrap";
import DocumentUpload from "./DocumentUpload";
import {
  CREATE_SERVICE_TASK,
  UPDATE_SERVICE_TASK,
  CREATE_SERVICE_MILESTONE,
  UPDATE_SERVICE_MILESTONE,
  FETCH_SERVICE_TASK,
  FETCH_SERVICE_MILESTONE,
} from "../../../../utils/routes";
import Hover from "../../../aside/Hover";
import axios from "axios";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import { getServiceTeamAssignments } from "../../../../redux/actions/serviceTeamAssignmentAction";
import ServiceAssignPage from "./ServiceAssignPage";
import {
  errors,
  submitValidator,
  Validator,
  valid,
} from "../../../common/Validator";
import TeamActivity from "./TeamActivity";

class ServiceDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permissionlist: this.props.auth.permissionlist || {},
      data: [],
      actionData: [],
      modal: false,
      activityUpdateModal: false,
      milestoneId: "",
      milestone: "PoC",
      targetDate: "",
      actualDate: "",
      description: "",
      status: "New",
      feedback: "Excellent",
      deliverables: "",
      remarks: "",
      taskId: "",
      task: "",
      taskDeliverables: "",
      taskRemarks: "",
      taskStatus: "New",
      taskDescription: "",
      message: "",
      error: "",
      actionModal: false,
      actionUpdateModal: false,
      hover: false,
      cords: {},
      activityHover: false,
      activityCords: {},
      mouse: {},
      page: 1,
      contains: 4,
      activityPage: 1,
      activityContains: 2,
      dateError: "",
      teamActivityBtn: "",
      formErrors: {
        assignedTo: "",
        spoc: "",
      },
    };
    this.updateNow = this.updateNow.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getData = this.getData.bind(this);
    this.actionToggle = this.actionToggle.bind(this);
    this.getActionData = this.getActionData.bind(this);
    this.actionSubmit = this.actionSubmit.bind(this);
    this.actionUpdate = this.actionUpdate.bind(this);
    this.actionUpdateToggle = this.actionUpdateToggle.bind(this);
    this.refresh = this.refresh.bind(this);
    this.activityRefresh = this.activityRefresh.bind(this);
    this.actionUpdateSubmit = this.actionUpdateSubmit.bind(this);
    this.selectPage = this.selectPage.bind(this);
    this.selectActivityPage = this.selectActivityPage.bind(this);
    this.toggleHoverOn = this.toggleHoverOn.bind(this);
    this.toggleHoverOff = this.toggleHoverOff.bind(this);
    this.activityUpdate = this.activityUpdate.bind(this);
    this.activityUpdateToggle = this.activityUpdateToggle.bind(this);
    this.toggleActivtiyHoverOn = this.toggleActivityHoverOn.bind(this);
    this.toggleActivityHoverOff = this.toggleActivityHoverOff.bind(this);
    this.activityUpdateSubmit = this.activityUpdateSubmit.bind(this);
    this.teamActivityToggle = this.teamActivityToggle.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.auth !== this.props.auth) {
      this.setState({
        permissionlist: this.props.auth.permisssionlist,
      });
    }
  }

  /*
selectPage function to select number of pages for pagination
*/
  selectPage(e) {
    this.setState({ page: e.target.id });
  }

  /*
selectActivityPage function to select number of pages for activity pagination
*/
  selectActivityPage(e) {
    this.setState({ activityPage: e.target.id });
  }

  updateNow() {
    this.props.update();
    this.props.back();
  }

  toggle() {
    this.activityRefresh();
    this.setState({
      modal: !this.state.modal,
    });

    let data = {
      serviceId: this.props.detail.serviceId,
    };
    this.props.getServiceTeamAssignments(data);
  }

  activityRefresh() {
    this.setState({
      task: "",
      taskDeliverables: "",
      taskRemarks: "",
      taskStatus: "New",
      taskDescription: "",
    });
  }

  /*
refresh function to reset all the state value to initial value
*/
  refresh() {
    this.setState({
      milestone: "PoC",
      targetDate: "",
      actualDate: "",
      deliverables: "",
      description: "",
      status: "New",
      feedback: "Excellent",
      remarks: "",
    });
  }

  actionToggle() {
    this.refresh();
    this.setState({
      actionModal: !this.state.actionModal,
    });

    let data = {
      serviceId: this.props.detail.serviceId,
    };
    this.props.getServiceTeamAssignments(data);
  }

  /*
actionUpdateToggle function to toggle the action update modal form
*/
  actionUpdateToggle() {
    this.setState({
      actionUpdateModal: !this.state.actionUpdateModal,
    });
  }

  /*
activityUpdateToggle function to toggle the activity update modal form
*/
  activityUpdateToggle() {
    this.setState({
      activityUpdateModal: !this.state.activityUpdateModal,
    });
  }

  /*
toggleHoverOn function to display the data when mouseHover the listData 
*/
  toggleHoverOn(e, item) {
    this.setState({
      hover: true,
      cords: {
        one: item.feedback,
        two: item.description,
        three: item.remarks,
      },
      mouse: {
        x: e.screenX,
        y: e.screenY,
      },
    });
  }

  /*
toggleHoverOff function to remove the mouse hover details
*/
  toggleHoverOff() {
    this.setState({
      hover: false,
    });
  }

  /*
toggleHoverOn function to display the data when mouseHover the listData 
*/
  toggleActivityHoverOn(e, item) {
    this.setState({
      activityHover: true,
      activityCords: {
        one: item.description,
      },
      mouse: {
        x: e.screenX,
        y: e.screenY,
      },
    });
  }

  /*
toggleActivityHoverOff function to remove the mouse hover details for activity
*/
  toggleActivityHoverOff() {
    this.setState({
      activityHover: false,
    });
  }

  onChange(e) {
    Validator(e.target);
    if (e.target.name === "actualDate") {
      if (Date.parse(e.target.value) < Date.parse(this.state.targetDate)) {
        this.setState({
          dateError: "actualDate must be greater than or equal to targetDate",
        });
      } else {
        this.setState({
          [e.target.name]: e.target.value,
          dateError: "",
        });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      serviceId: this.props.detail.serviceId,
      serviceTaskName: this.state.task,
      deliverables: this.state.taskDeliverables,
      remarks: this.state.taskRemarks,
      status: this.state.taskStatus,
      description: this.state.taskDescription,
      createdBy: localStorage.getItem("id"),
    };
    if (valid) {
      axios
        .post(CREATE_SERVICE_TASK, data, { withCredentials: true })
        .then((res) => {
          this.setState({
            message: res.data,
          });
        })
        .catch((err) => {
          this.setState({
            error: err.message,
          });
        });
      setTimeout(() => {
        this.setState({
          message: "",
          error: "",
        });
        this.toggle();
        this.getData();
      }, 2000);
    } else {
      let errors = this.state.formErrors;
      errors.assignedTo = "Please select option";
      this.setState({
        formErrors: errors,
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
activityUpdateSubmit function to submit the activity update form
*/
  activityUpdateSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      serviceId: this.props.detail.serviceId,
      serviceTaskId: this.state.taskId,
      serviceTaskName: this.state.task,
      deliverables: this.state.taskDeliverables,
      remarks: this.state.taskRemarks,
      taskStatus: this.state.taskStatus,
      description: this.state.taskDescription,
      createdBy: localStorage.getItem("id"),
    };
    if (valid) {
      axios
        .post(UPDATE_SERVICE_TASK, data, { withCredentials: true })
        .then((res) => {
          this.setState({
            message: res.data,
          });
        })
        .catch((err) => {
          this.setState({
            error: err.message,
          });
        });
      setTimeout(() => {
        this.setState({
          message: "",
          error: "",
        });
        this.activityUpdateToggle();
        this.getData();
      }, 2000);
    } else {
      let errors = this.state.formErrors;
      errors.assignedTo = "Please select option";
      this.setState({
        formErrors: errors,
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  actionSubmit(e) {
    e.preventDefault();
    // submitValidator(e.target);
    let data = {
      serviceId: this.props.detail.serviceId,
      serviceMilestoneName: this.state.milestone,
      targetDate: this.state.targetDate,
      actualDate: this.state.actualDate,
      deliverables: this.state.deliverables,
      status: this.state.status,
      description: this.state.description,
      feedback: this.state.feedback,
      remarks: this.state.remarks,
      createdBy: localStorage.getItem("id"),
    };
    if (!this.state.dateError) {
      axios
        .post(CREATE_SERVICE_MILESTONE, data, { withCredentials: true })
        .then((res) => {
          this.setState({
            message: res.data,
          });
        })
        .catch((err) => {
          this.setState({
            error: err.message,
          });
        });
      setTimeout(() => {
        this.setState({
          message: "",
          error: "",
        });
        this.actionToggle();
        this.getActionData();
      }, 2000);
    } else {
      let errors = this.state.formErrors;
      errors.spoc = "Please select option";
      this.setState({
        formErrors: errors,
        // error: 'Enter all mandatory fields'
      });
      // setTimeout(() => {
      //   this.setState({
      //     error: ''
      //   })
      // }, 2000)
    }
  }

  /*
actionUpdateSubmit funtion to submit the action update form
*/
  actionUpdateSubmit(e) {
    e.preventDefault();
    // submitValidator(e.target);
    let data = {
      serviceMilestoneId: this.state.milestoneId,
      serviceId: this.props.detail.serviceId,
      serviceMilestoneName: this.state.milestone,
      targetDate: this.state.targetDate,
      actualDate: this.state.actualDate,
      deliverables: this.state.deliverables,
      status: this.state.status,
      description: this.state.description,
      feedback: this.state.feedback,
      remarks: this.state.remarks,
      updatedBy: localStorage.getItem("id"),
    };
    // if(valid){
    axios
      .post(UPDATE_SERVICE_MILESTONE, data, { withCredentials: true })
      .then((res) => {
        this.setState({
          message: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          error: err.message,
        });
      });
    setTimeout(() => {
      this.setState({
        message: "",
        error: "",
      });
      this.actionUpdateToggle();
      this.getActionData();
    }, 2000);
    // } else {
    //   this.setState({
    //     error: 'Enter all mandatory fields'
    //   })
    //   setTimeout(() => {
    //     this.setState({
    //       error:''
    //     })
    //   }, 2000)
    // }
  }

  /*
actionUpdate function is to update the action list
*/
  actionUpdate(item) {
    this.setState({
      milestoneId: item.serviceMilestoneId,
      milestone: item.serviceMilestoneName,
      targetDate: item.targetDate,
      actualDate: item.actualDate,
      description: item.description,
      deliverables: item.deliverables,
      status: item.status,
      feedback: item.feedback,
      remarks: item.remarks,
      actionUpdateModal: !this.state.actionUpdateModal,
    });

    let data = {
      serviceId: this.props.detail.serviceId,
    };
    this.props.getServiceTeamAssignments(data);
  }

  /*
activityUpdate function to set the state value for the update form
*/
  activityUpdate(item) {
    this.setState({
      taskId: item.serviceTaskId,
      task: item.serviceTaskName,
      taskDeliverables: item.deliverables,
      taskRemarks: item.remarks,
      taskStatus: item.taskStatus,
      taskDescription: item.description,
      activityUpdateModal: !this.state.activityUpdateModal,
    });

    let data = {
      serviceId: this.props.detail.serviceId,
    };
    this.props.getServiceTeamAssignments(data);
  }

  getData() {
    let data = {
      serviceId: this.props.detail.serviceId,
    };
    axios
      .post(FETCH_SERVICE_TASK, data, { withCredentials: true })
      .then((res) => {
        this.setState({
          data: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          error: err.message,
        });
      });
    setTimeout(() => {
      this.setState({
        message: "",
        error: "",
      });
    }, 2000);
  }

  getActionData() {
    let data = {
      serviceId: this.props.detail.serviceId,
    };
    axios
      .post(FETCH_SERVICE_MILESTONE, data, { withCredentials: true })
      .then((res) => {
        this.setState({
          actionData: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          error: err.message,
        });
      });
    setTimeout(() => {
      this.setState({
        message: "",
        error: "",
      });
    }, 2000);
  }

  componentDidMount() {
    this.setState({
      permissionlist: this.props.auth.permissionlist,
    });

    let data = {
      serviceId: this.props.detail.serviceId,
    };
    this.props.getServiceTeamAssignments(data);
    this.getData();
    this.getActionData();
  }

  /*
teamActivityToggle function to toggle the team Activity page
*/
  teamActivityToggle() {
    this.setState({
      teamActivityBtn: !this.state.teamActivityBtn,
    });
  }

  render() {
    const { permissionlist, teamActivityBtn } = this.state;
    let currentPermission;

    for (let x in permissionlist.response.permissions) {
      if (permissionlist.response.permissions[x].groupId === "3.4") {
        currentPermission = permissionlist.response.permissions[x];
      }
    }

    // Action Pagination
    const { actionData, page, contains } = this.state;
    const length = actionData.length;
    const lastItem = page * contains;
    const firstItem = lastItem - contains;
    const items = actionData.slice(firstItem, lastItem);
    const pageNos = [];
    for (let i = 1; i <= Math.ceil(length / contains); i++) {
      pageNos.push(i);
    }

    // Activity Pagination
    const { data, activityPage, activityContains } = this.state;
    const activityLength = data.length;
    const activityLastItem = activityPage * activityContains;
    const activityFirstItem = activityLastItem - activityContains;
    const activityItems = data.slice(activityFirstItem, activityLastItem);
    const activityPageNos = [];
    for (let n = 1; n <= Math.ceil(activityLength / activityContains); n++) {
      activityPageNos.push(n);
    }

    return (
      <React.Fragment>
        {teamActivityBtn ? (
          <div className="team-activity-div">
            <TeamActivity
              back={this.teamActivityToggle}
              serviceId={this.props.detail.serviceId}
            />
          </div>
        ) : (
          <div className="project-details p-3">
            <button
              className="btn cust-chart-button white-text"
              onClick={this.teamActivityToggle}
            >
              Team activity
            </button>
            {this.state.hover && (
              <Hover
                labels={{
                  one: "FeedBack",
                  two: "Description",
                  three: "Remarks",
                }}
                cords={this.state.cords}
                mouse={this.state.mouse}
              />
            )}
            {this.state.activityHover && (
              <Hover
                labels={{ one: "Description" }}
                cords={this.state.activityCords}
                mouse={this.state.mouse}
              />
            )}
            <i
              className="material-icons pointer"
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                color: "#C00",
              }}
              onClick={this.props.back}
            >
              cancel
            </i>
            <h5 className="center">{this.props.detail.serviceName}</h5>
            <hr />
            <Row>
              <Col md="6">
                <div className="leadDiv">
                  <Row>
                    <Col md="6">
                      <ul style={{ listStyle: "none" }} className="lead-ul">
                        <li>
                          <span className="label-sm bold service-span-label-col-1">
                            Customers
                          </span>
                          <span className="label-sm ">
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            {this.props.detail.companyName}
                          </span>
                        </li>
                        <li>
                          <span className="label-sm bold service-span-label-col-1">
                            Service Type
                          </span>
                          <span className="label-sm ">
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            {this.props.detail.serviceType}
                          </span>
                        </li>
                        <li>
                          <span className="label-sm bold service-span-label-col-1">
                            Created By
                          </span>
                          <span className="label-sm ">
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            {this.props.detail.serviceCreatorId}
                          </span>
                        </li>
                        <li>
                          <span className="label-sm bold service-span-label-col-1">
                            Planned Start
                          </span>
                          <span className="label-sm ">
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            {this.props.detail.plannedStart}
                          </span>
                        </li>
                        <li>
                          <span className="label-sm bold service-span-label-col-1">
                            Planned End
                          </span>
                          <span className="label-sm ">
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            {this.props.detail.plannedEnd}
                          </span>
                        </li>
                        <li>
                          <span className="label-sm bold service-span-label-col-1">
                            Actual Start
                          </span>
                          <span className="label-sm ">
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            {this.props.detail.actualStart}
                          </span>
                        </li>
                        <li>
                          <span className="label-sm bold service-span-label-col-1">
                            Actual End
                          </span>
                          <span className="label-sm ">
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            {this.props.detail.actualEnd}
                          </span>
                        </li>
                        <li>
                          <span className="label-sm bold service-span-label-col-1">
                            Service Effort
                          </span>
                          <span className="label-sm ">
                            &nbsp;&nbsp;:&nbsp;&nbsp;{this.props.detail.effort}
                          </span>
                        </li>
                        <li>
                          <span className="label-sm bold service-span-label-col-1">
                            Service Cost
                          </span>
                          <span className="label-sm ">
                            &nbsp;&nbsp;:&nbsp;&nbsp;{this.props.detail.budget}
                          </span>
                        </li>
                        <li>
                          <span className="label-sm bold service-span-label-col-1">
                            Service Location
                          </span>
                          <span className="label-sm ">
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            {this.props.detail.location}
                          </span>
                        </li>
                        <li>
                          <span className="label-sm bold service-span-label-col-1">
                            Currency
                          </span>
                          <span className="label-sm ">
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            {this.props.detail.currency}
                          </span>
                        </li>
                        <li>
                          <span className="label-sm bold service-span-label-col-1">
                            Status
                          </span>
                          <span className="label-sm ">
                            &nbsp;&nbsp;:&nbsp;&nbsp;{this.props.detail.status}
                          </span>
                        </li>
                      </ul>
                    </Col>
                    <Col md="6" className="project-details-col">
                      <ul style={{ listStyle: "none" }} className="lead-ul">
                        <li>
                          <span className="label-sm bold service-span-label-col-2">
                            Customer Spoc
                          </span>
                          <span className="label-sm ">
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            {this.props.detail.serviceCustSpoc}
                          </span>
                        </li>
                        <li>
                          <span className="label-sm bold service-span-label-col-2">
                            Spoc Contact No.
                          </span>
                          <span className="label-sm ">
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            {this.props.detail.serviceSpocContactNo}
                          </span>
                        </li>
                        <li>
                          <span className="label-sm bold service-span-label-col-2">
                            Spoc Email
                          </span>
                          <span className="label-sm ">
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            {this.props.detail.serviceSpocEmail}
                          </span>
                        </li>
                        <li>
                          <span className="label-sm bold service-span-label-col-2">
                            ServiceManager Name
                          </span>
                          <span className="label-sm ">
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            {this.props.detail.servicePmName}
                          </span>
                        </li>
                        <li>
                          <span className="label-sm bold service-span-label-col-2">
                            Teams
                          </span>
                          <span className="label-sm ">
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            {this.props.detail.resources}
                          </span>
                        </li>
                        <li>
                          <span className="label-sm bold service-span-label-col-2">
                            Billing Type
                          </span>
                          <span className="label-sm ">
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            {this.props.detail.billingType}
                          </span>
                        </li>
                        <li>
                          <span className="label-sm bold service-span-label-col-2">
                            Added On
                          </span>
                          <span className="label-sm ">
                            &nbsp;&nbsp;:&nbsp;&nbsp;{this.props.detail.addedOn}
                          </span>
                        </li>
                        <li>
                          <span className="label-sm bold service-span-label-col-2">
                            Updated On
                          </span>
                          <span className="label-sm ">
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            {this.props.detail.updatedOn}
                          </span>
                        </li>
                        <li>
                          <span className="label-sm bold service-span-label-col-2">
                            Description
                          </span>
                          <span className="label-sm ">
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            {this.props.detail.description}
                          </span>
                        </li>
                        <li>
                          <span className="label-sm bold service-span-label-col-2">
                            Comments
                          </span>
                          <span className="label-sm ">
                            &nbsp;&nbsp;:&nbsp;&nbsp;{this.props.detail.comment}
                          </span>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                  {currentPermission.update1 ? (
                    <div className="div">
                      <div className="center">
                        {this.props.detail.status === "New" ||
                        this.props.detail.status === "WIP" ? (
                          <button
                            className="btn login-button white-text"
                            onClick={this.updateNow}
                          >
                            Update
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* <DocumentUpload id={this.props.detail.serviceId}/> */}
                    </div>
                  ) : null}
                </div>
              </Col>
              <Col md="6">
                <Row className="opp-action-div">
                  <div>
                    <h6 className="center">Milestone</h6>
                    {this.props.detail.status === "New" ||
                    this.props.detail.status === "WIP" ? (
                      <button
                        className="btn opportunity-activity-add-button white-text label-sm"
                        onClick={this.actionToggle}
                      >
                        Add
                      </button>
                    ) : (
                      ""
                    )}
                    <Table>
                      <thead>
                        <tr>
                          <th className="opp-head">ID</th>
                          <th className="opp-head">Milestone</th>
                          <th className="opp-head">TargetDate</th>
                          <th className="opp-head">ActualDate</th>
                          <th className="opp-head">Deliverables</th>
                          <th className="opp-head">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {items &&
                          items.map((item) => {
                            return (
                              <tr
                                key={item.serviceMilestoneId}
                                onMouseEnter={(e) =>
                                  this.toggleHoverOn(e, item)
                                }
                                onMouseLeave={this.toggleHoverOff}
                              >
                                <td className="opp-tableData">
                                  {item.serviceMilestoneId}
                                </td>
                                <td className="opp-tableData">
                                  {item.serviceMilestoneName}
                                </td>
                                <td className="opp-tableData">
                                  {item.targetDate}
                                </td>
                                <td className="opp-tableData">
                                  {item.actualDate}
                                </td>
                                <td className="opp-tableData">
                                  {item.deliverables}
                                </td>
                                <td className="opp-tableData">{item.status}</td>
                                <td>
                                  {this.props.detail.status === "New" ||
                                  this.props.detail.status === "WIP" ? (
                                    <span
                                      className="material-icons pointer opp-action-edit"
                                      style={{ color: "blue" }}
                                      onClick={() => this.actionUpdate(item)}
                                    >
                                      edit
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                    <div className="flex action-pagination">
                      {pageNos.map((page) => (
                        <button
                          key={page}
                          id={page}
                          onClick={this.selectPage}
                          className="btn btn-light btn-sm label-sm"
                        >
                          {page}
                        </button>
                      ))}
                    </div>
                  </div>
                </Row>
                <Row className="opp-action-div">
                  <div>
                    <h6 className="center">Task</h6>
                    {this.props.detail.status === "New" ||
                    this.props.detail.status === "WIP" ? (
                      <button
                        className="btn label-sm white-text opportunity-action-button"
                        onClick={this.toggle}
                      >
                        Add
                      </button>
                    ) : (
                      ""
                    )}
                    <Table>
                      <thead>
                        <tr>
                          <th className="opp-head">ID</th>
                          <th className="opp-head">Task</th>
                          <th className="opp-head">Remarks</th>
                          <th className="opp-head">Deliverables</th>
                          <th className="opp-head">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {activityItems &&
                          activityItems.map((item) => {
                            return (
                              <tr
                                onMouseEnter={(e) =>
                                  this.toggleActivtiyHoverOn(e, item)
                                }
                                onMouseLeave={this.toggleActivityHoverOff}
                              >
                                <td className="opp-tableData">
                                  {item.serviceTaskId}
                                </td>
                                <td className="opp-tableData">
                                  {item.serviceTaskName}
                                </td>
                                <td className="opp-tableData">
                                  {item.remarks}
                                </td>
                                <td className="opp-tableData">
                                  {item.deliverables}
                                </td>
                                <td className="opp-tableData">
                                  {item.taskStatus}
                                </td>
                                <td>
                                  {this.props.detail.status === "New" ||
                                  this.props.detail.status === "WIP" ? (
                                    <span
                                      className="material-icons pointer opp-action-edit"
                                      style={{ color: "blue" }}
                                      onClick={() => this.activityUpdate(item)}
                                    >
                                      edit
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                    <div className="flex action-pagination">
                      {activityPageNos.map((page) => (
                        <button
                          key={page}
                          id={page}
                          onClick={this.selectActivityPage}
                          className="btn btn-light btn-sm label-sm"
                        >
                          {page}
                        </button>
                      ))}
                    </div>
                  </div>
                </Row>
              </Col>
            </Row>
            <Modal
              size="md"
              isOpen={this.state.modal}
              toggle={this.toggle}
              className="apply-modal"
            >
              <ModalHeader toggle={this.toggle}>Add New Task</ModalHeader>
              <ModalBody>
                <Form className="p-3" onSubmit={this.onSubmit} noValidate>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label className=" bold">Task</Label>
                        <Input
                          type="text"
                          name="task"
                          onChange={this.onChange}
                          value={this.state.task}
                          className="form-control-sm"
                          required={true}
                        />
                        {errors.task && (
                          <FormText className="error">{errors.task}</FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label className=" bold">Deliverables</Label>
                        <Input
                          type="text"
                          name="taskDeliverables"
                          value={this.state.taskDeliverables}
                          onChange={this.onChange}
                          className="form-control-sm "
                          required={true}
                        />
                        {errors.taskDeliverables && (
                          <FormText className="error">
                            {errors.taskDeliverables}
                          </FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label className=" bold">Description</Label>
                        <Input
                          name="taskDescription"
                          type="textarea"
                          value={this.state.taskDescription}
                          className="form-control-sm"
                          onChange={this.onChange}
                          required={true}
                        />
                        {errors.taskDescription && (
                          <FormText className="error">
                            {errors.taskDescription}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label className="bold">Status</Label>
                        <Input
                          type="select"
                          name="taskStatus"
                          value={this.state.taskStatus}
                          className="form-control-sm"
                          onChange={this.onChange}
                        >
                          <option value="New">New</option>
                          <option value="WIP">WIP</option>
                          <option value="Closed">Closed</option>
                          <option value="Hold">Hold</option>{" "}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold">Remarks</Label>
                        <Input
                          type="textarea"
                          name="taskRemarks"
                          value={this.state.taskRemarks}
                          onChange={this.onChange}
                          className="form-control-sm"
                          required={true}
                        />
                        {errors.taskRemarks && (
                          <FormText className="error">
                            {errors.taskRemarks}
                          </FormText>
                        )}
                      </FormGroup>
                      {/* <FormGroup>
                                <Label className="bold">
                                    Assigned To
                                </Label>
                                <Input
                                    type="select"
                                    name="assignedTo"
                                    value={this.state.assignedTo}
                                    className="form-control-sm"
                                    onChange={this.onChange}
                                >
                                    <option hidden>Not Assigned</option>
                                    {this.props.projectTeamAssignment && 
                                     this.props.projectTeamAssignment.data.map(item => {
                                        return <option key={item.assignmentId} value={item.empId}>{item.empId}</option>
                                    })}
                                </Input>
                                {assignedTo && this.state.assignedTo ? "" : <FormText className="error">{assignedTo}</FormText>}
                            </FormGroup> */}
                    </Col>
                  </Row>
                  <FormGroup className="center">
                    <button
                      type="submit"
                      className="btn white-text login-button"
                    >
                      Submit
                    </button>
                  </FormGroup>
                </Form>
              </ModalBody>
            </Modal>
            <Modal
              size="md"
              isOpen={this.state.activityUpdateModal}
              toggle={this.activityUpdateToggle}
              className="apply-modal"
            >
              <ModalHeader toggle={this.activityUpdateToggle}>
                Update Task
              </ModalHeader>
              <ModalBody>
                <Form
                  className="p-3"
                  onSubmit={this.activityUpdateSubmit}
                  noValidate
                >
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label className=" bold">Task</Label>
                        <Input
                          type="text"
                          name="task"
                          onChange={this.onChange}
                          value={this.state.task}
                          className="form-control-sm"
                          required={true}
                        />
                        {errors.task && (
                          <FormText className="error">{errors.task}</FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label className=" bold">Deliverables</Label>
                        <Input
                          type="text"
                          name="taskDeliverables"
                          value={this.state.taskDeliverables}
                          onChange={this.onChange}
                          className="form-control-sm "
                          required={true}
                        />
                        {errors.taskDeliverables && (
                          <FormText className="error">
                            {errors.taskDeliverables}
                          </FormText>
                        )}
                      </FormGroup>
                      {/* <FormGroup>
                                <Label className="bold">
                                    Time
                                </Label>
                                <Input
                                    type="time"
                                    name="time"
                                    value={this.state.time}
                                    onChange={this.onChange}
                                    className="form-control-sm"
                                    required={true}
                                />
                                {errors.time && <FormText className="error">{errors.time}</FormText>}
                            </FormGroup > */}
                      <FormGroup>
                        <Label className="bold">Status</Label>
                        <Input
                          type="select"
                          name="taskStatus"
                          value={this.state.taskStatus}
                          className="form-control-sm"
                          onChange={this.onChange}
                        >
                          <option value="New">New</option>
                          <option value="WIP">WIP</option>
                          <option value="Closed">Closed</option>
                          <option value="Hold">Hold</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      {/* <FormGroup>
                                <Label className="bold">
                                    Assigned To
                                </Label>
                                <Input
                                    type="select"
                                    name="assignedTo"
                                    value={this.state.assignedTo}
                                    className="form-control-sm"
                                    onChange={this.onChange}
                                >
                                    <option hidden>Not Assigned</option>
                                    {this.props.projectTeamAssignment && 
                                     this.props.projectTeamAssignment.data.map(item => {
                                        return <option key={item.assignmentId} value={item.empId}>{item.empId}</option>
                                    })}
                                </Input>
                                {assignedTo && this.state.assignedTo ? "" : <FormText className="error">{assignedTo}</FormText>}
                            </FormGroup> */}
                      <FormGroup>
                        <Label className=" bold">Description</Label>
                        <Input
                          name="taskDescription"
                          type="textarea"
                          value={this.state.taskDescription}
                          className="form-control-sm"
                          onChange={this.onChange}
                          required={true}
                        />
                        {errors.taskDescription && (
                          <FormText className="error">
                            {errors.taskDescription}
                          </FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label className=" bold">Remarks</Label>
                        <Input
                          name="taskRemarks"
                          type="textarea"
                          value={this.state.taskRemarks}
                          className="form-control-sm"
                          onChange={this.onChange}
                          required={true}
                        />
                        {errors.taskRemarks && (
                          <FormText className="error">
                            {errors.taskRemarks}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup className="center">
                    <button
                      type="submit"
                      className="btn white-text login-button"
                    >
                      Submit
                    </button>
                  </FormGroup>
                </Form>
              </ModalBody>
            </Modal>
            <Modal
              size="md"
              isOpen={this.state.actionModal}
              toggle={this.actionToggle}
              className="apply-modal"
            >
              <ModalHeader toggle={this.actionToggle}>
                Add New Milestone
              </ModalHeader>
              <ModalBody>
                <Form className="p-3" onSubmit={this.actionSubmit}>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label className="bold">Milestone</Label>
                        <Input
                          type="select"
                          name="mailsStone"
                          onChange={this.onChange}
                          value={this.state.mailsStone}
                          className="form-control-sm"
                          required={true}
                        >
                          <option value="PoC">PoC</option>
                          <option value="Pilot">Pilot</option>
                          <option value="Requirements">Requirements</option>
                          <option value="Design">Design</option>
                          <option value="Implementation">Implementation</option>
                          <option value="Release">Release</option>
                          <option value="Sign off ">Sign off </option>
                        </Input>
                        {errors.mailsStone && (
                          <FormText className="error">
                            {errors.mailsStone}
                          </FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold">Target Date</Label>
                        <Input
                          type="date"
                          name="targetDate"
                          value={this.state.targetDate}
                          onChange={this.onChange}
                          className="form-control-sm"
                          required={true}
                        />
                        {errors.targetDate && (
                          <FormText className="error">
                            {errors.targetDate}
                          </FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold">Actual Date</Label>
                        <Input
                          type="date"
                          name="actualDate"
                          value={this.state.actualDate}
                          onChange={this.onChange}
                          className="form-control-sm"
                          min={this.state.targetDate}
                          required={true}
                        />
                        {errors.actualDate && (
                          <FormText className="error">
                            {errors.actualDate}
                          </FormText>
                        )}
                        {this.state.dateError && (
                          <FormText className="error">
                            {this.state.dateError}
                          </FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold">Description</Label>
                        <Input
                          name="description"
                          type="textarea"
                          value={this.state.description}
                          className="form-control-sm"
                          onChange={this.onChange}
                          required={true}
                        />
                        {errors.description && (
                          <FormText className="error">
                            {errors.description}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label className="bold">Status</Label>
                        <Input
                          type="select"
                          name="status"
                          value={this.state.status}
                          onChange={this.onChange}
                          className="form-control-sm"
                        >
                          <option value="New">New</option>
                          <option value="WIP">WIP</option>
                          <option value="Completed">Completed</option>
                          <option value="Hold">Hold</option>
                          <option value="Delay">Delay</option>
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold">FeedBack</Label>
                        <Input
                          type="select"
                          name="feedback"
                          value={this.state.feedback}
                          onChange={this.onChange}
                          className="form-control-sm "
                        >
                          <option value="Excellent">Excellent</option>
                          <option value="Good">Good</option>
                          <option value="Average">Average</option>
                          <option value="Challenge">Challenge</option>
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold">Deliverables</Label>
                        <Input
                          name="deliverables"
                          type="text"
                          value={this.state.deliverables}
                          className="form-control-sm"
                          onChange={this.onChange}
                          required={true}
                        />
                        {errors.deliverables && (
                          <FormText className="error">
                            {errors.deliverables}
                          </FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold">Remarks</Label>
                        <Input
                          name="remarks"
                          type="textarea"
                          value={this.state.remarks}
                          className="form-control-sm"
                          onChange={this.onChange}
                          required={true}
                        />
                        {errors.remarks && (
                          <FormText className="error">
                            {errors.remarks}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup className="center">
                    <button
                      type="submit"
                      className="btn white-text login-button"
                    >
                      Submit
                    </button>
                  </FormGroup>
                </Form>
              </ModalBody>
            </Modal>
            <Modal
              size="md"
              isOpen={this.state.actionUpdateModal}
              toggle={this.actionUpdateToggle}
              className="apply-modal"
            >
              <ModalHeader toggle={this.actionUpdateToggle}>
                Update Milestone
              </ModalHeader>
              <ModalBody>
                <Form className="p-3" onSubmit={this.actionUpdateSubmit}>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label className="bold">Milestone</Label>
                        <Input
                          type="select"
                          name="mailsStone"
                          onChange={this.onChange}
                          value={this.state.mailsStone}
                          className="form-control-sm"
                          required={true}
                        >
                          <option value="PoC">PoC</option>
                          <option value="Pilot">Pilot</option>
                          <option value="Requirements">Requirements</option>
                          <option value="Design">Design</option>
                          <option value="Implementation">Implementation</option>
                          <option value="Release">Release</option>
                          <option value="Sign off ">Sign off </option>
                        </Input>
                        {errors.mailsStone && (
                          <FormText className="error">
                            {errors.mailsStone}
                          </FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold">Target Date</Label>
                        <Input
                          type="date"
                          name="targetDate"
                          value={this.state.targetDate}
                          onChange={this.onChange}
                          className="form-control-sm"
                          required={true}
                        />
                        {errors.targetDate && (
                          <FormText className="error">
                            {errors.targetDate}
                          </FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold">Actual Date</Label>
                        <Input
                          type="date"
                          name="actualDate"
                          value={this.state.actualDate}
                          onChange={this.onChange}
                          className="form-control-sm"
                          min={this.state.targetDate}
                          required={true}
                        />
                        {errors.actualDate && (
                          <FormText className="error">
                            {errors.actualDate}
                          </FormText>
                        )}
                        {this.state.dateError && (
                          <FormText className="error">
                            {this.state.dateError}
                          </FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold">Description</Label>
                        <Input
                          name="mailsStoneDescription"
                          type="textarea"
                          value={this.state.mailsStoneDescription}
                          className="form-control-sm"
                          onChange={this.onChange}
                          required={true}
                        />
                        {errors.mailsStoneDescription && (
                          <FormText className="error">
                            {errors.mailsStoneDescription}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label className="bold">Status</Label>
                        <Input
                          type="select"
                          name="status"
                          value={this.state.status}
                          onChange={this.onChange}
                          className="form-control-sm"
                        >
                          <option value="New">New</option>
                          <option value="WIP">WIP</option>
                          <option value="Completed">Completed</option>
                          <option value="Hold">Hold</option>
                          <option value="Delay">Delay</option>
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold">FeedBack</Label>
                        <Input
                          type="select"
                          name="feedback"
                          value={this.state.feedback}
                          onChange={this.onChange}
                          className="form-control-sm "
                        >
                          <option value="Excellent">Excellent</option>
                          <option value="Good">Good</option>
                          <option value="Average">Average</option>
                          <option value="Challenge">Challenge</option>
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold">Deliverables</Label>
                        <Input
                          name="deliverables"
                          type="text"
                          value={this.state.deliverables}
                          className="form-control-sm"
                          onChange={this.onChange}
                          required={true}
                        />
                        {errors.deliverables && (
                          <FormText className="error">
                            {errors.deliverables}
                          </FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold">Remarks</Label>
                        <Input
                          name="remarks"
                          type="textarea"
                          value={this.state.remarks}
                          className="form-control-sm"
                          onChange={this.onChange}
                          required={true}
                        />
                        {errors.remarks && (
                          <FormText className="error">
                            {errors.remarks}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup className="center">
                    <button
                      type="submit"
                      className="btn white-text login-button"
                    >
                      Submit
                    </button>
                  </FormGroup>
                </Form>
              </ModalBody>
            </Modal>
            <div className="projectAssign">
              <ServiceAssignPage serviceId={this.props.detail.serviceId} />
            </div>
            <DocumentUpload id={this.props.detail.serviceId} />
            {this.state.message ? (
              <Notifier message={this.state.message} />
            ) : null}
            {this.state.error ? (
              <ErrorNotifier message={this.state.error} />
            ) : null}
          </div>
        )}
      </React.Fragment>
    );
  }
}

ServiceDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  getServiceTeamAssignments: PropTypes.func.isRequired,
  serviceTeamAssignment: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  serviceTeamAssignment: state.serviceTeamAssignment,
});

export default connect(mapStateToProps, { getServiceTeamAssignments })(
  ServiceDetails
);
