import axios from "axios";
import { DOCUMENTRS_GET } from "../../types";
import {
ADD_DOCUMENT,
UPDATE_DOCUMENT,
GET_DOCUMENT,
DELETE_DOCUMENT
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addDocument = (data, back) => (dispatch) => {
  axios
    .post( ADD_DOCUMENT, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back,getDocument));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getDocument = () => (dispatch) => {

  axios
    .get(GET_DOCUMENT, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(DOCUMENTRS_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateDocument = (data, back) => (dispatch) => {
  axios
    .put(UPDATE_DOCUMENT, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteDocument = (data) => (dispatch) => {
  axios
    .delete(DELETE_DOCUMENT, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getDocument));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
