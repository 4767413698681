import {
    GET_JOB_TIMING_SELECT,
    GET_JON_TIMING_TYPE,
    GET_ERRORS,
    SET_MESSAGE,
  } from "../../../actions/types";
  
  const initialState = {
    data: [],
    message: null,
    error: null,
    mainData:[]
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case  GET_JON_TIMING_TYPE:
        return {
          ...state,
          data: action.payload,
        };
        case  GET_JOB_TIMING_SELECT:
          return {
            ...state,
            mainData: action.payload,
          };
      case SET_MESSAGE:
        return {
          ...state,
          message: action.payload,
        };
      case GET_ERRORS:
        return {
          ...state,
          error: action.payload,
        };
      default:
        return state;
    }
  }