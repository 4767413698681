import axios from "axios";
import { GET_CANDIDATE_TYPECOUNTRY } from "../../types";
import {
ADD_CANDIDATE_COUNTRIES,
GET_CANDIDATE_COUNTRIES,
UPDATE_CANDIDATE_COUNTRIES,
DELETE_CANDIDATE_COUTRIES
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addCanCountry = (data, back) => (dispatch) => {
  axios
    .post(ADD_CANDIDATE_COUNTRIES, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back, getCanCountry));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getCanCountry = () => (dispatch) => {
  axios
    .get( GET_CANDIDATE_COUNTRIES, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch( GET_CANDIDATE_TYPECOUNTRY, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateCanCountrty = (data, back) => (dispatch) => {
  axios
    .put(UPDATE_CANDIDATE_COUNTRIES , data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteCanCountry = (data) => (dispatch) => {
  axios
    .delete( DELETE_CANDIDATE_COUTRIES, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getCanCountry));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
