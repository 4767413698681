import React, { Component } from "react";
import axios from "axios";
import { Table } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import dateFns from "date-fns";
import ProjectRow from "./ProjectRow";
import { getProjectTeamAssignments } from "../../../redux/actions/projectTeamAssignmentAction";
import { getServiceTeamAssignments } from "../../../redux/actions/serviceTeamAssignmentAction";
import { FETCH_TIME_MANAGEMENT, UPDATE_TIMESHEET } from "../../../utils/routes";

class ProjectList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      projCode: "",
      rows: null,
      day: this.props.day,
      weekNo: this.props.weekNo,
    };
    this.loadRows = this.loadRows.bind(this);
    this.addNewRow = this.addNewRow.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  fetchData = () => {
    let data = {
      empId: localStorage.getItem("id"),
    };
    axios
      .post(FETCH_TIME_MANAGEMENT, data, { withCredentials: true })
      .then((res) => {
        this.setState({
          data: res.data,
          rows: res.data.length,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  loadRows() {
    let array = [];
    for (let i = 0; i < this.state.rows; i++) {
      array.push(
        <ProjectRow
          key={i}
          identity={i}
          project={this.state.data[i]}
          projCode={this.state.projCode}
          handleChange={this.onChange}
          searchData={this.state.searchData}
          weekNo={this.props.weekNo}
          fetchTimeSheetData={this.fetchData}
        />
      );
    }
    return array.map((item) => {
      if (this.props.weekNo === item.props.project.weekNo) {
        return item;
      }
      return null;
    });
  }

  onChange(e, identity, fn) {
    let newData = [...this.state.data];
    newData[identity][e.target.name] = e.target.value;
    this.setState(
      (prevState) => ({
        ...prevState,
        data: newData,
      }),
      () => fn()
    );
  }

  componentDidUpdate() {
    if (this.props.day !== this.state.day) {
      this.setState({
        day: this.props.day,
        weekNo: dateFns.getISOWeek(this.state.day),
      });
    }
  }

  componentDidMount() {
    this.fetchData();

    this.props.getProjectTeamAssignments();

    this.props.getServiceTeamAssignments();
  }

  /*
addNewRow function for adding the new row after clicking the add icon in time sheet
*/
  addNewRow() {
    const newList = this.state.data;
    newList.push({ weekNo: this.props.weekNo });
    this.setState({
      data: newList,
      rows: this.state.rows + 1,
    });
  }

  /*
onSubmit funtion for submitting the timeSheet data upto current date
*/
  onSubmit() {
    this.state.data.forEach((item) => {
      if (this.props.weekNo === item.weekNo) {
        const {
          projectName,
          serviceName,
          task,
          workShift,
          monday,
          tuesday,
          wednesday,
          thursday,
          friday,
          saturday,
          sunday,
          timesheetId,
          weekNo,
        } = item;
        const date = new Date();
        const data = {
          timesheetId: timesheetId,
          empId: localStorage.getItem("id"),
          projectName,
          serviceName,
          task: task,
          workShift: workShift,
          monday,
          tuesday,
          wednesday,
          thursday,
          friday,
          saturday,
          sunday,
          timeDate: date,
          taskHours: "10:00",
          weekNo,
          projectHours: "10:00",
          timeRemarks: "test",
          billableStatus: "yes",
          status: "submited",
        };
        this.submitData(data);
        this.fetchData();
      }
    });
  }

  submitData = async (data) => {
    let response = [];
    try {
      const res = await axios.post(UPDATE_TIMESHEET, data, {
        withCredentials: true,
      });
      response = res.data;
    } catch (err) {
      //Handle err
    }
    return response;
  };

  render() {
    let weekStart = dateFns.startOfWeek(this.state.day);
    let days = [];
    for (let i = 0; i < 7; i++) {
      days.push(dateFns.format(dateFns.addDays(weekStart, i), "D"));
    }

    return (
      <div>
        <h6 className="center p-2">
          {dateFns.format(this.state.day, "MMMM")}&nbsp;
          {dateFns.format(this.state.day, "YYYY")}
        </h6>
        <Table className="timesheet-table employee-table">
          <thead className="table-header">
            <tr>
              <td className="material-icons pointer" onClick={this.addNewRow}>
                add
              </td>
              <th className="center label-sm bold">ProjectName/ServiceName</th>
              <th className="center label-sm bold">Task</th>
              <th className="center label-sm bold">Shift</th>
              <th className="center label-sm bold">
                Sun
                <br />
                {days[0]}
              </th>
              <th className="center label-sm bold">
                Mon
                <br />
                {days[1]}
              </th>
              <th className="center label-sm bold">
                Tue
                <br />
                {days[2]}
              </th>
              <th className="center label-sm bold">
                Wed
                <br />
                {days[3]}
              </th>
              <th className="center label-sm bold">
                Thu
                <br />
                {days[4]}
              </th>
              <th className="center label-sm bold">
                Fri
                <br />
                {days[5]}
              </th>
              <th className="center label-sm bold">
                Sat
                <br />
                {days[6]}
              </th>
              <th className="center label-sm bold"></th>
              <th className="center label-sm bold"></th>
            </tr>
          </thead>
          <tbody>
            {this.loadRows()}
            {/* {(dateFns.format(new Date(), "D") === days[6]) ?  */}
            <tr>
              <td colspan="10">
                <span
                  className="timesheet-button submit-button "
                  onClick={this.onSubmit}
                >
                  Submit
                </span>
              </td>
            </tr>
            {/* :null} */}
          </tbody>
        </Table>
      </div>
    );
  }
}

ProjectList.propTypes = {
  getProjectTeamAssignments: PropTypes.func.isRequired,
  projectTeamAssignment: PropTypes.object.isRequired,
  getServiceTeamAssignments: PropTypes.func.isRequired,
  serviceTeamAssignment: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  projectTeamAssignment: state.projectTeamAssignment,
  serviceTeamAssignment: state.serviceTeamAssignment,
});

export default connect(mapStateToProps, {
  getProjectTeamAssignments,
  getServiceTeamAssignments,
})(ProjectList);
