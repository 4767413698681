import {
  GET_TAGGED_REQUIREMENT,
  CANDIDATE_KANBAN_GET,
  CANDIDATE_COMMENTS_VIEW,
  CANDIDATE_STAGE_TRACKING_VIEW ,
  GET_ERRORS,
  SET_MESSAGE,
} from "../../../../actions/types";

const initialState = {
  data: [],
  canKanbanData: [],
  stageData:[],
  commentsData:[],
  message: null,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TAGGED_REQUIREMENT:
      return {
        ...state,
        data: action.payload,
      };
    case CANDIDATE_KANBAN_GET:
      return {
        ...state,
        canKanbanData: action.payload,
      };
      case CANDIDATE_COMMENTS_VIEW:
        return {
          ...state,
          commentsData: action.payload,
        };
        case CANDIDATE_STAGE_TRACKING_VIEW:
          return {
            ...state,
            stageData: action.payload,
          };
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case GET_ERRORS:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
