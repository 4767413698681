import axios from "axios";
import {
  GET_EMPLOYEE_DETAILS,
  UPDATE_EMPLOYEE_DETAILS,
  SEARCH_EMPLOYEE,
  GET_ERRORS,
} from "./types";
import {
  EMPLOYEE_DETAILS,
  EMPLOYEE_UPDATE,
  EMPLOYEE_SEARCH,
} from "../../utils/routes";

export const getEmployeeDetails = () => (dispatch) => {
  axios
    .get(EMPLOYEE_DETAILS, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: GET_EMPLOYEE_DETAILS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};

export const updateEmployee = (data) => (dispatch) => {
  axios
    .post(EMPLOYEE_UPDATE, data, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: UPDATE_EMPLOYEE_DETAILS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};

export const searchEmployee = (data) => (dispatch) => {
  axios
    .post(EMPLOYEE_SEARCH, data, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: SEARCH_EMPLOYEE,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};
