import {
  EMP_PERSONAL_INFO_GET,
  EMP_PERSONAL_INFO_FETCH,
  GET_ERRORS,
  SET_MESSAGE,
  UPLOADED_ONBOARDING_EXTRACT_DATA,
  UPLOADED_ONBOARDINGE_PROCESS_DATA,
  UPLOADED_ONBOARDING_UNPROCESS_DATA
} from "../../../../actions/types";

const initialState = {
  data: [],
  fetchData: [],
  dataExtracted:[],
  dataProcessed:[],
  dataUnProcessed:[],
  message: null,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case EMP_PERSONAL_INFO_GET:
      return {
        ...state,
        data: action.payload,
      };
    case EMP_PERSONAL_INFO_FETCH:
      return {
        ...state,
        fetchData: action.payload,
      };
      case  UPLOADED_ONBOARDING_EXTRACT_DATA:
        return {
          ...state,
          dataExtracted: action.payload,
        };
        case  UPLOADED_ONBOARDINGE_PROCESS_DATA:
          return {
            ...state,
            dataProcessed: action.payload,
          };
          case UPLOADED_ONBOARDING_UNPROCESS_DATA:
            return {
              ...state,
              dataUnProcessed: action.payload,
            };
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case GET_ERRORS:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
