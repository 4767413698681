import React, { Component } from "react";
import { Form, Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DateFns from "date-fns";
// components
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import FormComponent from "../../../../common/FormComponent";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
} from "../../../../common/Validator";
// redux
import { updateEmpProfessionalInfo } from "../../../../../redux/actions/hcm/my_profile/empProfessionalInfoAction";

class UpdateProfessionalDetails extends Component {
  constructor(props) {
    super(props);
    const { employerName, designation, fromDate, toDate } = this.props.data;
    this.state = {
      employerName: employerName || "",
      designation: designation || "",
      fromDate: fromDate || "",
      toDate: toDate || "",
      dateError: "",
      currentDate: "",
      message: "",
      error: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    if (e.target.name === "toDate" || e.target.name === "fromDate") {
      if (
        Date.parse(e.target.value) < Date.parse(this.state.fromDate) ||
        Date.parse(e.target.value) > Date.parse(this.state.currentDate)
      ) {
        this.setState({
          [e.target.name]: e.target.value,
          dateError: "Date shoule less than currentDate and toDate >= fromDate",
        });
      } else {
        this.setState({
          [e.target.name]: e.target.value,
          dateError: "",
        });
      }
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  onSubmit(e, NoOfYears) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      id: this.props.data.id,
      employerName: this.state.employerName,
      designation: this.state.designation,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      noOfYears: NoOfYears,
    };
    if (valid && !this.state.dateError) {
      this.props.updateEmpProfessionalInfo(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    refresh();
    this.setState({
      message: "",
      error: "",
    });
  }

  render() {
    let { employerName, designation, fromDate, toDate, currentDate } =
      this.state;

    let startDate = new Date(fromDate);

    let endDate = new Date(toDate);

    let year = endDate.getFullYear() - startDate.getFullYear();

    let month = Math.abs(endDate.getMonth() - startDate.getMonth());

    let noOfYears = year + "." + month;

    return (
      <React.Fragment>
        <h5 className="bold center pt-2">Update Employment Details</h5>
        <i
          className="material-icons cancel-button pointer"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form
          onSubmit={(e) => this.onSubmit(e, noOfYears)}
          className="p-3"
          noValidate
        >
          <Row>
            <Col md="6">
              <FormComponent
                labelClass="bold label-sm"
                label="Employer Name"
                type="text"
                name="employerName"
                change={this.onChange}
                value={employerName}
                required={true}
              />

              <FormComponent
                labelClass="bold label-sm"
                label="Designation"
                type="text"
                name="designation"
                change={this.onChange}
                value={designation}
                required={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="From Date"
                type="date"
                name="fromDate"
                change={this.onChange}
                value={DateFns.format(fromDate, "YYYY-MM-DD")}
                required={true}
                error={this.state.dateError}
                max={currentDate}
              />
            </Col>
            <Col md="6">
              <FormComponent
                labelClass="bold label-sm"
                label="To Date"
                type="date"
                name="toDate"
                change={this.onChange}
                value={DateFns.format(toDate, "YYYY-MM-DD")}
                min={this.state.fromDate}
                max={currentDate}
                error={this.state.dateError}
                required={true}
              />
              <FormComponent
                labelClass="bold label-sm"
                label="No of Years"
                type="text"
                value={noOfYears ? noOfYears : this.props.data.noOfYears}
                disabled
              />
            </Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Update
            </button>
          </div>
        </Form>
        {this.props.empProfessionalInfo.message ? (
          <Notifier message={this.props.empProfessionalInfo.message} />
        ) : null}
        {this.props.empProfessionalInfo.error ? (
          <ErrorNotifier message={this.props.empProfessionalInfo.error} />
        ) : null}
      </React.Fragment>
    );
  }
}

UpdateProfessionalDetails.propTypes = {
  updateEmpProfessionalInfo: PropTypes.func.isRequired,
  empProfessionalInfo: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  empProfessionalInfo: state.empProfessionalInfo,
});

export default connect(mapStateToProps, { updateEmpProfessionalInfo })(
  UpdateProfessionalDetails
);
