import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  FormText,
} from "reactstrap";
// components
import FormComponent from "../../../common/FormComponent";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../common/Validator";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
// redux
import { addRequests } from "../../../../redux/actions/experience_360/requestsAction";
import { getUserRequestType } from "../../../../redux/actions/experience_360/e360_config/requestTypeAction";
import { getUserRequestPriority } from "../../../../redux/actions/experience_360/e360_config/requestPriorityAction";
import { getUserRequestSeverity } from "../../../../redux/actions/experience_360/e360_config/requestSeverityAction";
import { getUserRequestResolution } from "../../../../redux/actions/experience_360/e360_config/requestResolutionAction";
import { getUserProjectTeam } from "../../../../redux/actions/experience_360/e360_config/projectTeamAction";
import { getUserReleaseTag } from "../../../../redux/actions/experience_360/e360_config/releaseTagAction";
import { getUserProductList } from "../../../../redux/actions/experience_360/e360_config/productListAction";

// ------------------------------------------------------------------------------------------

class AddRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestType: "",
      reqTitle: "",
      productName: "",
      feature: "",
      dialingCode:
        Object.keys(this.props.auth.data).length > 0 &&
        this.props.auth.data.pieUser.dialingCode,
      contactNumber:
        Object.keys(this.props.auth.data).length > 0 &&
        this.props.auth.data.pieUser.mobileNumber,
      detailedDescription: "",
      comments: "",
      severity: "",
      priority: "",
      status: "New",
      resolution: null,
      releaseName: null,
      file: [],
      fileError: "",
      error: "",
      message: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    e.preventDefault();
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleFile = (e) => {
    Validator(e.target);
    let allowedExtension = /(\.png|\.pdf|\.jpeg)$/i;
    if (allowedExtension.exec(e.target.files[0].name)) {
      if ((e.target.files[0].size / 1024 / 1024).toFixed(2) <= 2) {
        this.setState({
          file: e.target.files[0] || [],
          fileError: "",
        });
      } else {
        this.setState({
          file: e.target.files[0],
          fileError: "File size must less than 2MB",
        });
      }
    } else {
      this.setState({
        file: e.target.files[0],
        fileError: "File type not allowed",
      });
    }
  };

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);

    const { fileError } = this.state;

    let fd = new FormData();

    fd.append("reqType", this.state.requestType);
    fd.append("reqTitle", this.state.title);
    fd.append("productName", this.state.productName);
    fd.append("feature", this.state.feature);
    fd.append(
      "requesterName",
      Object.keys(this.props.auth.data).length > 0 &&
        this.props.auth.data.pieUser.firstName
    );
    fd.append("dialingCode", this.state.dialingCode);
    fd.append("contactNumber", this.state.contactNumber);
    fd.append("detailedDescription", this.state.detailedDescription);
    fd.append("comments", this.state.comments);
    fd.append("severity", this.state.severity);
    fd.append("priority", this.state.priority);
    fd.append("status", this.state.status);
    fd.append("resolution", this.state.resolution);
    fd.append("releaseName", this.state.releaseName);
    fd.append("releaseVersion", this.state.releaseName);
    fd.append("attachmentPath", this.state.file);

    if (valid && !fileError) {
      this.props.addRequests(fd, this.props.history);
    } else {
      this.setState({
        error: "Enter mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    refresh();
    this.props.getUserRequestType();
    this.props.getUserRequestSeverity();
    this.props.getUserRequestPriority();
    this.props.getUserRequestResolution();
    this.props.getUserProjectTeam();
    this.props.getUserReleaseTag();
    this.props.getUserProductList();
    this.setState({
      message: "",
      error: "",
    });
  }

  render() {
    let shrink;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");
    return (
      <div className={shrink}>
        <Container className="card container-card">
          <h5 className="center bold">Create Request</h5>
          <Link to="/pie/experience_360/requests">
            <i
              className="material-icons pointer"
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                color: "#C00",
              }}
            >
              cancel
            </i>
          </Link>
          <Form
            onSubmit={this.onSubmit}
            className="p-3 mt-2 pl--5 pr--5"
            noValidate
          >
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label className="label-sm bold">Request Type</Label>
                  <Input
                    type="select"
                    className="form-control-sm"
                    name="requestType"
                    onChange={this.onChange}
                    value={this.state.requestType}
                    required={true}
                    tabIndex="1"
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.requestType.data.map((item) => {
                      return (
                        <option key={item.id} value={item.reqTypeName}>
                          {item.reqTypeName}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.requestType && (
                    <FormText className="error">{errors.requestType}</FormText>
                  )}
                </FormGroup>
                <FormComponent
                  labelClass="bold label-sm"
                  label="Feature"
                  type="text"
                  name="feature"
                  change={this.onChange}
                  value={this.state.feature}
                  required={true}
                  tabIndex="3"
                />

                <FormGroup>
                  <Label className="label-sm bold">Severity</Label>
                  <Input
                    type="select"
                    className="form-control-sm"
                    name="severity"
                    onChange={this.onChange}
                    value={this.state.severity}
                    required
                    tabIndex="5"
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.requestSeverity.data.map((item) => {
                      return (
                        <option key={item.id} value={item.severityName}>
                          {item.severityName}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.severity && (
                    <FormText className="error">{errors.severity}</FormText>
                  )}
                </FormGroup>

                <FormComponent
                  labelClass="bold label-sm"
                  label="Detailed Description"
                  type="textarea"
                  name="detailedDescription"
                  change={this.onChange}
                  value={this.state.detailedDescription}
                  required={true}
                  tabIndex="7"
                />
                <Row>
                  <Col md="2" className="p-0">
                    <FormComponent
                      labelClass="bold label-sm"
                      label="Code"
                      type="number"
                      name="dialingCode"
                      change={this.onChange}
                      value={this.state.dialingCode}
                      tabIndex="9"
                    />
                  </Col>
                  <Col md="10" className="p-0">
                    <FormComponent
                      labelClass="bold label-sm"
                      label="Contact No."
                      type="tel"
                      name="contactNumber"
                      change={this.onChange}
                      value={this.state.contactNumber}
                      tabIndex="10"
                    />
                  </Col>
                </Row>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label className="label-sm bold">Product Name</Label>
                  <Input
                    type="select"
                    className="form-control-sm"
                    name="productName"
                    onChange={this.onChange}
                    value={this.state.productName}
                    required={true}
                    tabIndex="2"
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.productList.data.map((item) => {
                      return (
                        <option key={item.id} value={item.productName}>
                          {item.productName}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.productName && (
                    <FormText className="error">{errors.productName}</FormText>
                  )}
                </FormGroup>
                <FormComponent
                  labelClass="bold label-sm"
                  label="Request Title/Summary"
                  type="text"
                  name="title"
                  change={this.onChange}
                  value={this.state.title}
                  required={true}
                  tabIndex="4"
                />

                <FormGroup>
                  <Label className="label-sm bold">Priority</Label>
                  <Input
                    type="select"
                    className="form-control-sm"
                    name="priority"
                    onChange={this.onChange}
                    value={this.state.priority}
                    tabIndex="6"
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.requestPriority.data.map((item) => {
                      return (
                        <option key={item.id} value={item.priorityName}>
                          {item.priorityName}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.priority && (
                    <FormText className="error">{errors.priority}</FormText>
                  )}
                </FormGroup>
                <FormComponent
                  labelClass="bold label-sm"
                  label="Comments"
                  type="textarea"
                  name="comments"
                  change={this.onChange}
                  value={this.state.comments}
                  tabIndex="8"
                />
                <div className="file-div ">
                  <FormGroup className="file-display mr-1">
                    <Label className="bold label-sm">Attachment</Label>
                    <Input
                      disabled
                      type="text"
                      value={this.state.file.name || ""}
                      className="form-control-sm "
                      name="attachmentPath"
                    />
                    {this.state.fileError && (
                      <FormText className="error ">
                        {this.state.fileError}
                      </FormText>
                    )}
                    {errors.attachmentPath && (
                      <FormText className="error">
                        {errors.attachmentPath}
                      </FormText>
                    )}
                  </FormGroup>
                  <FormGroup className="file-browse-btn-div">
                    <label
                      className="btn label-sm file-browse-btn"
                      tabIndex="11"
                    >
                      Browse
                      <Input
                        type="file"
                        name="attachmentPath"
                        style={{
                          display: "none",
                          marginTop: "23px",
                          marginLeft: "-28px",
                        }}
                        className="form-control-sm file-input"
                        onChange={this.handleFile}
                      />
                    </label>
                  </FormGroup>
                </div>
              </Col>
            </Row>
            <div className="center">
              <button
                type="submit"
                className="btn center login-button white-text"
                disabled={this.state.buttonValue ? "disabled" : null}
                tabIndex="12"
              >
                Submit
              </button>
            </div>
          </Form>
        </Container>
        {this.props.requests.message ? (
          <Notifier message={this.props.requests.message} />
        ) : null}
        {this.props.requests.error ? (
          <ErrorNotifier message={this.props.requests.error} />
        ) : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
      </div>
    );
  }
}

AddRequests.propTypes = {
  getUserRequestType: PropTypes.func.isRequired,
  requestType: PropTypes.object.isRequired,
  getUserRequestSeverity: PropTypes.func.isRequired,
  requestSeverity: PropTypes.object.isRequired,
  getUserRequestPriority: PropTypes.func.isRequired,
  requestPriority: PropTypes.object.isRequired,
  addRequests: PropTypes.func.isRequired,
  requests: PropTypes.object.isRequired,
  getUserRequestResolution: PropTypes.func.isRequired,
  requestResolution: PropTypes.object.isRequired,
  getUserProjectTeam: PropTypes.func.isRequired,
  supportTeam: PropTypes.object.isRequired,
  getUserReleaseTag: PropTypes.func.isRequired,
  releaseTag: PropTypes.object.isRequired,
  getUserProductList: PropTypes.func.isRequired,
  productList: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  requestType: state.requestType,
  requestSeverity: state.requestSeverity,
  requestPriority: state.requestPriority,
  requests: state.requests,
  requestResolution: state.requestResolution,
  supportTeam: state.supportTeam,
  releaseTag: state.releaseTag,
  productList: state.productList,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  getUserRequestType,
  getUserRequestSeverity,
  getUserRequestPriority,
  addRequests,
  getUserRequestResolution,
  getUserProjectTeam,
  getUserReleaseTag,
  getUserProductList,
})(AddRequests);
