import axios from "axios";
import {
  CUSTOMER_ENTITY_GET,
  CUSTOMER_ENTITY_FETCH,
  CUSTOMER_ENTITY_SEARCH,
  CUSTOMER_ENTITY_NAME_GET,
  CUSTOMER_ENTITY_SPOC_NAME_GET,
  UPDATE_CUSTOMER_ENTITY_SPOC_NAME_GET,
  CUSTOMER_ENTITY_TAGGED_CONTACTS_GET,
} from "../../types";
import {
  CURD_CUSTOMER_ENTITY,
  SEARCH_CUSTOMER_ENTITY,
  GET_CUSTOMER_ENTITY_NAME,
  GET_CUSTOMER_ENTITY_SPOC_NAME,
  GET_UPDATE_CUSTOMER_ENTITY_SPOC_NAME,
  GET_CUSTOMER_ENTITY_TAGGED_CONTACTS,
} from "../../../../utils/routes";
import setSuccessMessage from "../../common/setSuccessMessage";
import setErrorMessage from "../../common/setErrorMessage";
import getDispatch from "../../common/getDispatch";

export const addCustomerEntity = (data, history) => (dispatch) => {
  let link = "/pie/crm/customer_entity";
  axios
    .post(CURD_CUSTOMER_ENTITY, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getCustomerEntity, history, link));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const searchCustomerEntity = (data) => (dispatch) => {
  axios
    .post(SEARCH_CUSTOMER_ENTITY, data, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CUSTOMER_ENTITY_SEARCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getCustomerEntity = () => (dispatch) => {
  axios
    .get(CURD_CUSTOMER_ENTITY, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CUSTOMER_ENTITY_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getCustomerEntityName = () => (dispatch) => {
  axios
    .get(GET_CUSTOMER_ENTITY_NAME, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CUSTOMER_ENTITY_NAME_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getCustomerEntitySpocName = (data) => (dispatch) => {
  axios
    .get(`${GET_CUSTOMER_ENTITY_SPOC_NAME}/${data}`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CUSTOMER_ENTITY_SPOC_NAME_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getUpdateCustomerEntitySpocName = (data) => (dispatch) => {
  axios
    .get(`${GET_UPDATE_CUSTOMER_ENTITY_SPOC_NAME}/${data}`, {
      withCredentials: true,
    })
    .then((res) => {
      dispatch(getDispatch(UPDATE_CUSTOMER_ENTITY_SPOC_NAME_GET, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const getCustomerEntityTaggedContacts =
  (customer, entity) => (dispatch) => {
    axios
      .get(`${GET_CUSTOMER_ENTITY_TAGGED_CONTACTS}/${customer}/${entity}`, {
        withCredentials: true,
      })
      .then((res) => {
        dispatch(getDispatch(CUSTOMER_ENTITY_TAGGED_CONTACTS_GET, res));
      })
      .catch((err) => {
        dispatch(setErrorMessage(err));
      });
  };

export const fetchCustomerEntity = (data) => (dispatch) => {
  axios
    .get(`${CURD_CUSTOMER_ENTITY}/${data}`, { withCredentials: true })
    .then((res) => {
      dispatch(getDispatch(CUSTOMER_ENTITY_FETCH, res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const updateCustomerEntity = (data, back) => (dispatch) => {
  axios
    .put(CURD_CUSTOMER_ENTITY, data, { withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, back));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};

export const deleteCustomerEntity = (data) => (dispatch) => {
  axios
    .delete(CURD_CUSTOMER_ENTITY, { data, withCredentials: true })
    .then((res) => {
      dispatch(setSuccessMessage(res, null, getCustomerEntity));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
