import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Row, Col, Card } from "reactstrap";
import { connect } from "react-redux";
import axios from "axios";
import DateFns from "date-fns";



// redux



// --------------------------------------------------------------------------------

// view matched candidate component
function ViewInfoCandidates({
  isOpenCandi,
  closeModel,
  isToogleView,
  data,
  isReq,
  isShowTac 
}) {

  const [edit, setEdit] = useState(false);
  const [canId, setCanId] = useState(null);
  const [uniqueData, setUniqueData] = useState(null);

 
  // useEffect(() => {
  //   let body = {
  //     primarySkills: matchedData[0].primarySkills
  //       ? matchedData[0].primarySkills
  //       : "",
  //     secondarySkills: matchedData[0].secondarySkills
  //       ? matchedData[0].secondarySkills
  //       : "",
  //     jobLocation: matchedData[0].jobLocation ? matchedData[0].jobLocation : "",
  //     minYearsOfExp: matchedData[0].minYearsOfExp.toString()
  //       ? matchedData[0].minYearsOfExp.toString()
  //       : "",
  //     maxCtc: matchedData[0].maxCtc ? matchedData[0].maxCtc : "",
  //     joiningDate: matchedData[0].startDate ? matchedData[0].startDate : "",
  //     jobDescription: matchedData[0].jobDescription
  //       ? matchedData[0].jobDescription
  //       : "",
  //     reqType: matchedData[0].jobReqType ? matchedData[0].jobReqType : "",
  //     qualification: matchedData[0].qualifications
  //       ? matchedData[0].qualifications
  //       : "",
  //     jobPosition: matchedData[0].jobPosition ? matchedData[0].jobPosition : "",
  //   };

   

  // resume open
 

  // close resume toggle

 const extractCanSkills = (data) =>{
 let primarySkillString = ""
 if(data.length > 1){
  for(let key of data){
    primarySkillString += `${key.skill}, `
    } 
 }
 else if(data.length == 1){
  primarySkillString = data[0].skill
  
 }
 

 return primarySkillString
 }

  return (
    <div>
      <Modal size="xl" show={isOpenCandi} onHide={closeModel}>
        {!edit && (
          <div>
            <div>
              <i
                data-toggle="tooltip"
                data-placement="top"
                title="Close"
                onClick={closeModel}
                className="material-icons pointer cancel-button"
              >
                cancel
              </i>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1rem",
                // fontSize:"1.25rem",
                // fontWeight:400
              }}
            >
              <Modal.Title style={{ fontSize: "1.25rem", fontWeight: 400 }}>
                View Details
              </Modal.Title>
            </div>

            <Modal.Body>
              <Row className="mt-3">
                {data.length > 0 &&
                  data.map((item) => (
                    <Col
                      // onClick={() => viewResumeClick(item.canDetail.id)}
                      md="4"
                      key={item.ci_id}
                      className="employee-list-col"
                    >
                      <Card className="p-2 mb-2 shadow pointer">
                        <ul className="CustDetails-ul employee-list-ul">
                         
                          <li>
                            <span
                              style={{ fontWeight: 500 }}
                              className="label-sm bold emp-span-label-rs"
                            >
                              {" "}
                             Can Id
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span
                                style={{ fontSize: "0.75rem", fontWeight: 400 }}
                                className="employee-list-span-data-rs"
                              >
                                { item.canInfo.id }

                              </span>
                            </span>
                          </li>
                          {isReq && <li>
                            <span
                              style={{ fontWeight: 500 }}
                              className="label-sm bold emp-span-label-rs"
                            >
                              {" "}
                             Req Id
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span
                                style={{ fontSize: "0.75rem", fontWeight: 400 }}
                                className="employee-list-span-data-rs"
                              >
                                {  item.jobRequirement.id 
}
                              </span>
                            </span>
                          </li>}
                          <li>
                            <span
                              style={{ fontWeight: 500 }}
                              className="label-sm bold emp-span-label-rs"
                            >
                              {" "}
                              Name
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span
                                style={{ fontSize: "0.75rem", fontWeight: 400 }}
                                className="employee-list-span-data-rs"
                              >
                                { `${item?.canInfo?.firstName} ${item?.canInfo.lastName}` }
                              </span>
                            </span>
                          </li>

                          <li>
                            <span
                              style={{ fontWeight: 500 }}
                              className="label-sm bold emp-span-label-rs"
                            >
                              {" "}
                              Email Id
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span
                                style={{ fontSize: "0.75rem", fontWeight: 400 }}
                                className="employee-list-span-data-rs"
                              >
                                { item?.canInfo.eMailId
}
                              </span>
                            </span>
                          </li>
                          <li>
                            <span
                              style={{ fontWeight: 500 }}
                              className="label-sm bold emp-span-label-rs"
                            >
                              {" "}
                              Phone No.
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span
                                style={{ fontSize: "0.75rem", fontWeight: 400 }}
                                className="employee-list-span-data-rs"
                              >
                                { item?.canInfo?.mobileNo}
                              </span>
                            </span>
                          </li>
                          <li>
                            <span
                              style={{ fontWeight: 500 }}
                              className="label-sm bold emp-span-label-rs"
                            >
                              {" "}
                              Updated On
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span
                                style={{ fontSize: "0.75rem", fontWeight: 400 }}
                                className="employee-list-span-data-rs"
                              >
                                 { item?.updatedOn ?
                                  DateFns.format(item?.updatedOn, "DD-MM-YYYY"):""
                               }
                              </span>
                            </span>
                          </li>
                          <li>
                            <span
                              style={{ fontWeight: 500 }}
                              className="label-sm bold emp-span-label-rs"
                            >
                              {" "}
                              Location
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span
                                style={{ fontSize: "0.75rem", fontWeight: 400 }}
                                className="employee-list-span-data-rs"
                              >
                                {  item?.canInfo?.location}
                              </span>
                            </span>
                          </li>
                          <li>
                            <span
                              style={{ fontWeight: 500 }}
                              className="label-sm bold emp-span-label-rs"
                            >
                              {" "}
                              Yrs Of Experience
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span
                                style={{ fontSize: "0.75rem", fontWeight: 400 }}
                                className="employee-list-span-data-rs"
                              >
                                { item?.canInfo?.yearOfExp}
                              </span>
                            </span>
                          </li>
                          { !isShowTac  ? <div>
                          <li>
                            <span
                              style={{ fontWeight: 500 }}
                              className="label-sm bold emp-span-label-rs"
                            >
                              {" "}
                              Hiring Stage
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span
                                style={{ fontSize: "0.75rem", fontWeight: 400 }}
                                className="employee-list-span-data-rs"
                              >
                                {isToogleView ?  item?.hiringStage: item?.ct_hiringStage}
                              </span>
                            </span>
                          </li>
                          <li>
                            <span
                              style={{ fontWeight: 500 }}
                              className="label-sm bold emp-span-label-rs"
                            >
                              {" "}
                              Hiring Status
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span
                                style={{ fontSize: "0.75rem", fontWeight: 400 }}
                                className="employee-list-span-data-rs"
                              >
                                {isToogleView ?  item?.hiringStatus: item?.ct_hiringStatus}
                              </span>
                            </span>
                          </li>
                          <li>
                            <span
                              style={{ fontWeight: 500 }}
                              className="label-sm bold emp-span-label-rs"
                            >
                              {" "}
                              Candidate Status
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span
                                style={{ fontSize: "0.75rem", fontWeight: 400 }}
                                className="employee-list-span-data-rs"
                              >
                                {isToogleView ?  item?.candidateStatus: item?.ct_candidateStatus}
                              </span>
                            </span>
                          </li>
                          </div> :

<div>
<li>
  <span
    style={{ fontWeight: 500 }}
    className="label-sm bold emp-span-label-rs"
  >
    {" "}
    Can. Skills
  </span>
  <span className=" label-sm">
    &nbsp;&nbsp;:&nbsp;&nbsp;
    <span
      style={{ fontSize: "0.75rem", fontWeight: 400 }}
      className="employee-list-span-data-rs"
    >
      {
        item?.canInfo?.primarySkills.length > 0 ? extractCanSkills( item?.canInfo?.primarySkills) : ""
}
    </span>
  </span>
</li>
<li>
  <span
    style={{ fontWeight: 500 }}
    className="label-sm bold emp-span-label-rs"
  >
    {" "}
    Req. Skills
  </span>
  <span className=" label-sm">
    &nbsp;&nbsp;:&nbsp;&nbsp;
    <span
      style={{ fontSize: "0.75rem", fontWeight: 400 }}
      className="employee-list-span-data-rs"
    >
      { item?.jobRequirement?.primarySkills
}
    </span>
  </span>
</li>
<li>
  <span
    style={{ fontWeight: 500 }}
    className="label-sm bold emp-span-label-rs"
  >
    {" "}
    Customer Name
  </span>
  <span className=" label-sm">
    &nbsp;&nbsp;:&nbsp;&nbsp;
    <span
      style={{ fontSize: "0.75rem", fontWeight: 400 }}
      className="employee-list-span-data-rs"
    >
      { item?.jobRequirement?.custName}
    </span>
  </span>
</li>
<li>
  <span
    style={{ fontWeight: 500 }}
    className="label-sm bold emp-span-label-rs"
  >
    {" "}
   TAC Name
  </span>
  <span className=" label-sm">
    &nbsp;&nbsp;:&nbsp;&nbsp;
    <span
      style={{ fontSize: "0.75rem", fontWeight: 400 }}
      className="employee-list-span-data-rs"
    >
      { item?.jobRequirement?.tacName}
    </span>
  </span>
</li>
</div>
                          
                          
                          }
                          {/* <li>
                            <span
                              style={{ fontWeight: 500 }}
                              className="label-sm bold emp-span-label-rs"
                            >
                              {" "}
                              Notice Period
                            </span>
                            <span className=" label-sm">
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <span
                                style={{ fontSize: "0.75rem", fontWeight: 400 }}
                                className="employee-list-span-data-rs"
                              >
                                {item?.ci_yearOfExp}
                              </span>
                            </span>
                          </li> */}
                        </ul>
                        {/* <Divider style ={{marginTop:"0.5rem"}} />
                        <DetailedPageFooter
                          createdBy={item.createdBy}
                          createdOn={item.createdOn}
                          updatedBy={item.updatedBy}
                          updatedOn={item.updatedOn}
                        /> */}
                      </Card>
                    </Col>
                  ))}
              </Row>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </div>
        )}

        
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  requirements: state.requirements,
});

export default connect(mapStateToProps, )(
  ViewInfoCandidates
);
