/*  eslint-disable  */
import axios from "axios";
import {
  PRODUCT_ADD,
  PRODUCT_LIST,
  PRODUCT_UPDATE,
  PRODUCT_SEARCH,
  GET_ERRORS,
} from "./types";
import {
  GET_PRODUCT,
  ADD_PRODUCT,
  SEARCH_PRODUCT,
  UPDATE_PRODUCT,
} from "../../utils/routes";
import { setMessage } from "./salesActions";

export const addProduct = (data, history) => (dispatch) => {
  axios
    .post(ADD_PRODUCT, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      dispatch({ type: PRODUCT_ADD, payload: true });
      setTimeout(() => {
        dispatch(setMessage(null));
        dispatch({ type: PRODUCT_ADD, payload: false });
        history.push("/pie/pps/products");
      }, 2000);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};

export const getProducts = () => (dispatch) => {
  axios
    .get(GET_PRODUCT, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: PRODUCT_LIST,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};

export const updateProduct = (data) => (dispatch) => {
  axios
    .post(UPDATE_PRODUCT, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
      }, 2000);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};

export const searchProduct = (data) => (dispatch) => {
  axios
    .post(SEARCH_PRODUCT, data, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: PRODUCT_SEARCH,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};
