import React from "react";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";
import Calendar from "./Calendar";

const Attendance = ({ sidebar, location }) => {
  let shrink;
  sidebar ? (shrink = "scale") : (shrink = "no-scale");
  return (
    <div className={shrink}>
      <Container className="card container-card pt-2">
        <h5 className="pointer bold center pt-2 mb-2">Attendance</h5>
        <Link
          to={{
            pathname: "/pie/iam/service_menu",
            moduleName: location.moduleName,
            data: location.data,
          }}
        >
          <i className="material-icons cancel-button pointer">cancel</i>
        </Link>
        <Calendar />
      </Container>
    </div>
  );
};

export default Attendance;
