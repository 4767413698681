import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormText,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Select from "react-select";
import { connect } from "react-redux";
import DateFns from "date-fns";
import {
  addSalesInvoice,
  addUpdateSalesInvoice,
  getSalesInvoices,
  addRecieveItem,
  getRecieveItems,
} from "../../../../../redux/actions/finance/accounts_receivable/sales_invoice/salesInvoiceAction";
import { getCurrency } from "../../../../../redux/actions/currencyAction";
import { getInvoiceType } from "../../../../../redux/actions/finance/invoice/invoiceTypeAction";
import { getPOEntry } from "../../../../../redux/actions/finance/accounts_receivable/po_entry/poEntryAction";
import { getCustomers } from "../../../../../redux/actions/customerAction";
import FormComponent from "../../../../common/FormComponent";
import {
  errors,
  Validator,
  submitValidator,
  valid,
  refresh,
} from "../../../../common/Validator";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import Notifier from "../../../../aside/Notifier";

/**
 * @class AddSalesInvoice
 **/

class AddSalesInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salesInvoiceId: "",
      poId: "",
      poName: "",
      poIdName: "",
      poType: "",
      invoiceNumber: "",
      invoiceDate: "",
      invoiceType: "",
      customerName: "",
      address: "",
      contactName: "",
      contactEmail: "",
      contactPhoneNumber: "",
      gstin: "",
      pan: "",
      tin: "",
      bankName: "",
      bankIfscCode: "",
      bankAccountName: "",
      bankAccountNumber: "",
      invoiceCurrency: "",
      invoiceAmount: "",
      invoiceGst: "",
      invoiceTotalAmount: "",
      recieveItemModal: false,
      purchaseItemNo: "",
      pspName: "",
      quantity: "",
      amount: "",
      amountGST: "",
      toggleCreateSalesInvoice: true,
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    if (e.target.name === "poId") {
      this.setState({
        poIdName: e.target.value,
        poId: e.target.value.split(",")[0],
        poName: e.target.value.split(",")[1],
      });
    } else {
      Validator(e.target);
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  customerNameHandleChange = (customerNameSelectedOption) => {
    if (customerNameSelectedOption) {
      this.setState({
        customerNameSelectedOption,
        customerNameSelectedOptionError: null,
      });
      this.props.customers.data.map((item) => {
        if (item.compFullName === customerNameSelectedOption.value) {
          this.setState({
            gstin: item.gst,
            pan: item.panNo,
            tin: item.tinNo,
          });
        }
      });
    } else {
      this.setState({
        customerNameSelectedOptionError: "This field is mandatory",
      });
    }
  };

  componentDidMount() {
    this.props.getCurrency();
    this.props.getInvoiceType();
    this.props.getCustomers();
    this.props.getPOEntry();
  }

  // componentDidUpdate function we will get the vendor and partners details based on the invoice type selection
  componentDidUpdate(prevProps, prevState) {
    if (prevState.poId !== this.state.poId) {
      this.props.poEntry.data.map((item) => {
        if (item.poId.toString() === this.state.poId) {
          this.setState({
            poType: item.poType,
            customerName: item.customerName,
            address: item.shipToAddress,
            contactName: item.contactName,
            contactEmail: item.contactEmail,
            contactPhoneNumber: item.contactMobileNo,
            shipToContactNo: item.shipToContactMobileNo,
            paymentTerms: item.pymtTerm,
          });
        }
      });
    }
    if (
      prevProps.salesInvoice.latestSalesInvoiceData !==
      this.props.salesInvoice.latestSalesInvoiceData
    ) {
      this.setState({
        toggleCreateSalesInvoice: false,
        salesInvoiceId:
          this.props.salesInvoice.latestSalesInvoiceData[0].salesInvoiceId,
      });
    }
    if (prevState.amount !== this.state.amount) {
      this.setState({
        amountGST: (this.state.amount * 0.18).toFixed(2),
      });
    }
    if (
      prevProps.salesInvoice.recieveItemData !==
      this.props.salesInvoice.recieveItemData
    ) {
      if (this.props.salesInvoice.recieveItemData.length !== 0) {
        let arr = this.props.salesInvoice.recieveItemData;
        let sumAmount = 0;
        let sumAmountGst = 0;
        arr.forEach(function (obj) {
          sumAmount += obj.amount * obj.qty;
          sumAmountGst += obj.gstAmount * obj.qty;
        });
        this.setState({
          invoiceAmount: sumAmount,
          invoiceGst: sumAmountGst.toFixed(2),
        });
      }
    }
  }

  // onSubmit function to submit the form
  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    const { customerNameSelectedOption, customerNameSelectedOptionError } =
      this.state;
    if (valid) {
      let data = {
        poId: this.state.poId,
        poEntryName: this.state.poName,
        invoiceType: this.state.invoiceType,
        invoiceNo: this.state.invoiceNumber,
        invoiceDate: this.state.invoiceDate,
        customerName: this.state.customerName,
        address: this.state.address,
        contactName: this.state.contactName,
        contactEmail: this.state.contactEmail,
        contactMobileNo: this.state.contactPhoneNumber,
        pan: this.state.pan,
        tin: this.state.tin,
        gst: this.state.gstin,
        bankName: this.state.bankName,
        bankIfscCode: this.state.bankIfscCode,
        bankAccName: this.state.bankAccountName,
        bankAccNo: this.state.bankAccountNumber,
        invoiceCurrency: this.state.invoiceCurrency,
        createdBy: localStorage.getItem("id"),
      };

      this.props.addSalesInvoice(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all Mandatory Fields",
      });
      if (!customerNameSelectedOption) {
        this.setState({
          customerNameSelectedOptionError: "This field is mandatory",
        });
      }
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidCatch(err, info) {
    console.log(err);
  }

  receiveItemReset = () => {
    this.setState({
      purchaseItemNo: "",
      pspName: "",
      quantity: "",
      amount: "",
      amountGST: "",
    });
  };

  recieveItemToggle = () => {
    this.setState({
      recieveItemModal: !this.state.recieveItemModal,
    });
    refresh();
    this.receiveItemReset();
    let data1 = {
      salesInvoiceId: this.state.salesInvoiceId,
    };
    this.props.getRecieveItems(data1);
  };

  // recieveItemSubmit function for submitting the form details
  recieveItemSubmit = (e) => {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      // poId: this.state.poId,
      salesInvoiceId: this.state.salesInvoiceId,
      receivableItemNo: this.state.purchaseItemNo,
      ppsName: this.state.pspName,
      qty: this.state.quantity,
      amount: this.state.amount,
      gstAmount: this.state.amountGST,
    };
    if (valid) {
      this.props.addRecieveItem(data, this.recieveItemToggle);
    } else {
      this.setState({
        error: "Enter all Mandatory Fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  };

  // updateInvoice function to update the totalAmount, totalGst and poTotalAmount in InvoiceTable
  updateInvoice = (invoiceTotalAmount) => {
    let data = {
      salesInvoiceId: this.state.salesInvoiceId,
      invoiceAmount: this.state.invoiceAmount,
      invoiceTotalAmount: invoiceTotalAmount,
      invoiceGst: this.state.invoiceGst,
      updatedBy: localStorage.getItem("id"),
    };
    this.props.addUpdateSalesInvoice(data);
  };

  render() {
    const {
      poId,
      poName,
      poIdName,
      poType,
      invoiceNumber,
      invoiceDate,
      invoiceType,
      customerName,
      address,
      contactName,
      contactEmail,
      contactPhoneNumber,
      gstin,
      pan,
      tin,
      bankName,
      bankIfscCode,
      bankAccountName,
      bankAccountNumber,
      invoiceCurrency,
      invoiceAmount,
      invoiceGst,
      customerNameSelectedOptionError,
      recieveItemModal,
      purchaseItemNo,
      pspName,
      quantity,
      amount,
      amountGST,
      toggleCreateSalesInvoice,
      error,
    } = this.state;

    let customerNameOptions = [];
    for (let i = 0; i < this.props.customers.data.length; i++) {
      var x = {};
      x["value"] = this.props.customers.data[i].compFullName;
      x["label"] = this.props.customers.data[i].compFullName;
      x["fetchValue"] = this.props.customers.data[i].compCode;

      customerNameOptions.push(x);
    }

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 mx-auto">
            <Container className="card container-card">
              <h5 className="center bold pt-2">New Sales Invoice</h5>
              <i
                className="material-icons pointer cancel-button"
                onClick={this.props.back}
              >
                cancel
              </i>
              <Row>
                {toggleCreateSalesInvoice ? (
                  <Col md="8" className="mx-auto">
                    <Form onSubmit={this.onSubmit} className="p-3" noValidate>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label className="label-sm bold">PO Id</Label>
                            <Input
                              type="select"
                              name="poId"
                              value={poIdName}
                              className="form-control-sm"
                              onChange={this.onChange}
                              required={true}
                            >
                              <option value="" hidden>
                                Select
                              </option>
                              {this.props.poEntry.data.map((item) => {
                                return (
                                  <option
                                    key={item.poId}
                                    value={`${item.poId},${item.poEntryName}`}
                                  >
                                    {item.poId}-{item.poEntryName}
                                  </option>
                                );
                              })}
                            </Input>
                            {errors.poId && (
                              <FormText className="error">
                                {errors.poId}
                              </FormText>
                            )}
                          </FormGroup>
                          <FormComponent
                            label="PO Type"
                            labelClass="label-sm bold"
                            type="text"
                            name="poType"
                            value={poType}
                            className="form-control-sm"
                            change={this.onChange}
                            required={true}
                            disabled={true}
                          />
                          <FormComponent
                            label="Invoice Number"
                            labelClass="label-sm bold"
                            type="number"
                            name="invoiceNumber"
                            value={invoiceNumber}
                            className="form-control-sm"
                            change={this.onChange}
                            required={true}
                          />
                          <FormComponent
                            label="Invoice Date"
                            labelClass="label-sm bold"
                            type="date"
                            name="invoiceDate"
                            value={invoiceDate}
                            className="form-control-sm"
                            change={this.onChange}
                            required={true}
                          />
                          <FormGroup>
                            <Label className="label-sm bold">
                              Invoice Type
                            </Label>
                            <Input
                              type="select"
                              name="invoiceType"
                              value={invoiceType}
                              className="form-control-sm"
                              onChange={this.onChange}
                              required={true}
                            >
                              <option value="" hidden>
                                Select
                              </option>
                              {this.props.invoiceTypes.data.map((item) => {
                                return (
                                  <option
                                    key={item.invoiceTypeId}
                                    value={item.invoiceType}
                                  >
                                    {item.invoiceType}
                                  </option>
                                );
                              })}
                            </Input>
                            {errors.invoiceType && (
                              <FormText className="error">
                                {errors.invoiceType}
                              </FormText>
                            )}
                          </FormGroup>
                          {/* <FormGroup>
                          <Label className="label-sm bold">Customer Name</Label>
                          <Select
                            name="customerNameOptions"
                            value={customerNameSelectedOption}
                            onChange={this.customerNameHandleChange}
                            options={customerNameOptions}
                            className="select-form-control-sm"
                            required
                          />

                          {customerNameSelectedOptionError && (
                            <FormText className="error ">
                              {customerNameSelectedOptionError}
                            </FormText>
                          )}
                        </FormGroup> */}
                          <FormComponent
                            label="Customer Name"
                            labelClass="label-sm bold"
                            type="text"
                            name="customerName"
                            value={customerName}
                            className="form-control-sm"
                            change={this.onChange}
                            required={!poId ? false : true}
                            disabled={!poId ? false : true}
                          />
                          <FormComponent
                            label="Address"
                            labelClass="label-sm bold"
                            type="text"
                            name="address"
                            value={address}
                            className="form-control-sm"
                            change={this.onChange}
                            required={!poId ? false : true}
                            disabled={!poId ? false : true}
                          />
                          <FormComponent
                            label="Contact Name"
                            labelClass="label-sm bold"
                            type="text"
                            name="contactName"
                            value={contactName}
                            className="form-control-sm"
                            change={this.onChange}
                            required={!poId ? false : true}
                            disabled={!poId ? false : true}
                          />
                          <FormComponent
                            label="Contact Email"
                            labelClass="label-sm bold"
                            type="email"
                            name="contactEmail"
                            value={contactEmail}
                            className="form-control-sm"
                            change={this.onChange}
                            required={!poId ? false : true}
                            disabled={!poId ? false : true}
                          />
                          <FormComponent
                            label="Contact Phone Number"
                            labelClass="label-sm bold"
                            type="tel"
                            name="contactPhoneNumber"
                            value={contactPhoneNumber}
                            className="form-control-sm"
                            change={this.onChange}
                            required={!poId ? false : true}
                            disabled={!poId ? false : true}
                          />
                        </Col>
                        <Col md="6">
                          <FormComponent
                            label="GSTIN"
                            labelClass="label-sm bold"
                            type="text"
                            name="gstin"
                            value={gstin}
                            change={this.onChange}
                            className="form-control-sm"
                            required={true}
                            maxlength="15"
                          />
                          <FormComponent
                            label="PAN"
                            labelClass="label-sm bold"
                            type="text"
                            name="pan"
                            value={pan}
                            change={this.onChange}
                            className="form-control-sm"
                            required={true}
                            maxlength="10"
                          />
                          <FormComponent
                            label="TIN"
                            labelClass="label-sm bold"
                            type="text"
                            name="tin"
                            value={tin}
                            change={this.onChange}
                            className="form-control-sm"
                            required={true}
                            maxlength="11"
                          />
                          <FormComponent
                            label="Bank Name"
                            labelClass="label-sm bold"
                            type="text"
                            name="bankName"
                            value={bankName}
                            className="form-control-sm"
                            change={this.onChange}
                            required={true}
                          />
                          <FormComponent
                            label="Bank IFSC Code"
                            labelClass="label-sm bold"
                            type="text"
                            name="bankIfscCode"
                            value={bankIfscCode}
                            className="form-control-sm"
                            change={this.onChange}
                            required={true}
                          />
                          <FormComponent
                            label="Bank Account Name"
                            labelClass="label-sm bold"
                            type="text"
                            name="bankAccountName"
                            value={bankAccountName}
                            className="form-control-sm"
                            change={this.onChange}
                            required={true}
                          />
                          <FormComponent
                            label="Bank Account Number"
                            labelClass="label-sm bold"
                            type="number"
                            name="bankAccountNumber"
                            value={bankAccountNumber}
                            className="form-control-sm"
                            change={this.onChange}
                            required={true}
                          />
                          <FormGroup>
                            <Label className="bold label-sm">
                              Invoice Currency
                            </Label>
                            <Input
                              type="select"
                              name="invoiceCurrency"
                              className="form-control-sm "
                              value={invoiceCurrency}
                              onChange={this.onChange}
                              required={true}
                            >
                              <option value="" hidden>
                                Select...
                              </option>
                              {this.props.currency.data.map((item) => {
                                return (
                                  <option
                                    key={item.currencyId}
                                    value={item.currencyCode}
                                  >
                                    {item.currencyCode}
                                  </option>
                                );
                              })}
                            </Input>
                            {errors.invoiceCurrency && (
                              <FormText className="error">
                                {errors.invoiceCurrency}
                              </FormText>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="center">
                        <button
                          type="submit"
                          className="btn center login-button white-text"
                        >
                          Add
                        </button>
                      </div>
                    </Form>
                  </Col>
                ) : (
                  <React.Fragment>
                    <Col md="6">
                      <h6>Sales Invoice Details</h6>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          PO ID
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.salesInvoice.latestSalesInvoiceData[0] &&
                            this.props.salesInvoice.latestSalesInvoiceData[0]
                              .poId}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          PO Name
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.salesInvoice.latestSalesInvoiceData[0] &&
                            this.props.salesInvoice.latestSalesInvoiceData[0]
                              .poEntryName}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Invoice Number
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.salesInvoice.latestSalesInvoiceData[0] &&
                            this.props.salesInvoice.latestSalesInvoiceData[0]
                              .invoiceNo}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Invoice Date
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.salesInvoice.latestSalesInvoiceData[0] &&
                            DateFns.format(
                              this.props.salesInvoice.latestSalesInvoiceData[0]
                                .invoiceDate,
                              "DD-MM-YYYY"
                            )}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Invoice Type
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.salesInvoice.latestSalesInvoiceData[0] &&
                            this.props.salesInvoice.latestSalesInvoiceData[0]
                              .invoiceType}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Customer Name
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.salesInvoice.latestSalesInvoiceData[0] &&
                            this.props.salesInvoice.latestSalesInvoiceData[0]
                              .customerName}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Address
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.salesInvoice.latestSalesInvoiceData[0] &&
                            this.props.salesInvoice.latestSalesInvoiceData[0]
                              .address}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Contact Name
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.salesInvoice.latestSalesInvoiceData[0] &&
                            this.props.salesInvoice.latestSalesInvoiceData[0]
                              .contactName}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Contact Email
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.salesInvoice.latestSalesInvoiceData[0] &&
                            this.props.salesInvoice.latestSalesInvoiceData[0]
                              .contactEmail}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Contact No
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.salesInvoice.latestSalesInvoiceData[0] &&
                            this.props.salesInvoice.latestSalesInvoiceData[0]
                              .contactMobileNo}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          PAN
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.salesInvoice.latestSalesInvoiceData[0] &&
                            this.props.salesInvoice.latestSalesInvoiceData[0]
                              .pan}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          TIN
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.salesInvoice.latestSalesInvoiceData[0] &&
                            this.props.salesInvoice.latestSalesInvoiceData[0]
                              .tin}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          GST
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.salesInvoice.latestSalesInvoiceData[0] &&
                            this.props.salesInvoice.latestSalesInvoiceData[0]
                              .gst}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Bank Name
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.salesInvoice.latestSalesInvoiceData[0] &&
                            this.props.salesInvoice.latestSalesInvoiceData[0]
                              .bankName}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Bank IFSC
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.salesInvoice.latestSalesInvoiceData[0] &&
                            this.props.salesInvoice.latestSalesInvoiceData[0]
                              .bankIfscCode}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Bank Account Name
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.salesInvoice.latestSalesInvoiceData[0] &&
                            this.props.salesInvoice.latestSalesInvoiceData[0]
                              .bankAccName}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Bank Account No
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.salesInvoice.latestSalesInvoiceData[0] &&
                            this.props.salesInvoice.latestSalesInvoiceData[0]
                              .bankAccNo}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Invoice Currency
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.salesInvoice.latestSalesInvoiceData[0] &&
                            this.props.salesInvoice.latestSalesInvoiceData[0]
                              .invoiceCurrency}
                        </div>
                      </div>
                      {/* <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Payment Terms
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.salesInvoice.latestSalesInvoiceData[0] &&
                            this.props.salesInvoice.latestSalesInvoiceData[0].pymtTerm}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Payment Instructions
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.salesInvoice.latestSalesInvoiceData[0] &&
                            this.props.salesInvoice.latestSalesInvoiceData[0]
                              .pymtInstructions}
                        </div>
                      </div> */}
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Status
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.salesInvoice.latestSalesInvoiceData[0] &&
                            this.props.salesInvoice.latestSalesInvoiceData[0]
                              .pymtStatus}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <Row className="milestone-row">
                        <div className="milestone-div">
                          <h6 className="center">Receive Items</h6>
                          <button
                            className="btn milestone-add-btn white-text label-sm"
                            onClick={this.recieveItemToggle}
                          >
                            Add
                          </button>
                          <Table>
                            <thead>
                              <tr>
                                <th className="opp-head">Name</th>
                                <th className="opp-head">Qty</th>
                                <th className="opp-head">Amount</th>
                                <th className="opp-head">GST</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.props.salesInvoice.recieveItemData.map(
                                (item) => {
                                  return (
                                    <tr key={item.recieveItemsId}>
                                      <td>{item.ppsName}</td>
                                      <td>{item.qty}</td>
                                      <td>{item.amount}</td>
                                      <td>{item.gstAmount}</td>
                                    </tr>
                                  );
                                }
                              )}
                              {this.props.salesInvoice.recieveItemData
                                .length !== 0 && (
                                <tr>
                                  <td>Total Amount:</td>
                                  <td>{invoiceAmount}</td>
                                  <td>Total GST:</td>
                                  <td>{invoiceGst}</td>
                                </tr>
                              )}
                              {this.props.salesInvoice.recieveItemData
                                .length !== 0 && (
                                <tr>
                                  <td></td>
                                  <td>
                                    <button
                                      className="btn center login-button white-text"
                                      onClick={() =>
                                        this.updateInvoice(
                                          this.state.invoiceAmount +
                                            parseFloat(this.state.invoiceGst)
                                        )
                                      }
                                    >
                                      Update
                                    </button>
                                  </td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </Row>
                    </Col>
                  </React.Fragment>
                )}
              </Row>
              <Modal isOpen={recieveItemModal} size="md">
                <ModalHeader toggle={this.recieveItemToggle}>
                  Receive Items
                </ModalHeader>
                <ModalBody>
                  <Form onSubmit={this.recieveItemSubmit} noValidate>
                    <Row>
                      <Col md="6" className="mx-auto">
                        <FormComponent
                          label="Purchase Item Number"
                          labelClass="label-sm  bold"
                          className="form-control-sm"
                          type="number"
                          name="purchaseItemNo"
                          value={purchaseItemNo}
                          change={this.onChange}
                          required={true}
                        />
                        <FormComponent
                          label="Product/Service/Project Name"
                          labelClass="label-sm  bold"
                          className="form-control-sm"
                          type="text"
                          name="pspName"
                          value={pspName}
                          change={this.onChange}
                          required={true}
                        />
                        <FormComponent
                          label="Quantity"
                          labelClass="label-sm  bold"
                          className="form-control-sm"
                          type="number"
                          name="quantity"
                          value={quantity}
                          change={this.onChange}
                          required={true}
                        />
                        <FormComponent
                          label="Amount"
                          labelClass="label-sm  bold"
                          className="form-control-sm"
                          type="number"
                          name="amount"
                          value={amount}
                          change={this.onChange}
                          required={true}
                        />
                        <FormComponent
                          label="Amount GST"
                          labelClass="label-sm  bold"
                          className="form-control-sm"
                          type="number"
                          name="amountGST"
                          value={amountGST}
                          change={this.onChange}
                          required={true}
                          disabled={true}
                        />
                      </Col>
                    </Row>
                    <div className="center">
                      <button
                        type="submit"
                        className="btn center login-button white-text"
                      >
                        Add
                      </button>
                    </div>
                  </Form>
                </ModalBody>
              </Modal>
            </Container>
            {error && <ErrorNotifier message={error} />}
            {this.props.salesInvoice.message && (
              <Notifier message={this.props.salesInvoice.message} />
            )}
            {this.props.salesInvoice.error && (
              <ErrorNotifier message={this.props.salesInvoice.error} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

AddSalesInvoice.propTypes = {
  addSalesInvoice: PropTypes.func.isRequired,
  addUpdateSalesInvoice: PropTypes.func.isRequired,
  addRecieveItem: PropTypes.func.isRequired,
  getRecieveItems: PropTypes.func.isRequired,
  getCurrency: PropTypes.func.isRequired,
  currency: PropTypes.object.isRequired,
  getInvoiceType: PropTypes.func.isRequired,
  invoiceTypes: PropTypes.object.isRequired,
  getSalesInvoice: PropTypes.func.isRequired,
  salesInvoice: PropTypes.object.isRequired,
  getPOEntry: PropTypes.func.isRequired,
  poEntry: PropTypes.object.isRequired,
  getCustomers: PropTypes.func.isRequired,
  customers: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  currency: state.currency,
  invoiceTypes: state.invoiceTypes,
  salesInvoice: state.salesInvoice,
  poEntry: state.poEntry,
  customers: state.customers,
});

export default connect(mapStateToProps, {
  addSalesInvoice,
  addUpdateSalesInvoice,
  addRecieveItem,
  getRecieveItems,
  getCurrency,
  getInvoiceType,
  getSalesInvoices,
  getPOEntry,
  getCustomers,
})(AddSalesInvoice);
