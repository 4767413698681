import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import DateFns from "date-fns";
import UpdateSalesInvoice from "./UpdateSalesInvoice";

class SalesInvoiceDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
    };
    this.updateNow = this.updateNow.bind(this);
  }

  updateNow() {
    this.setState({
      edit: true,
    });
  }

  render() {
    const { edit } = this.state;

    return (
      <Container className="card container-card p-3">
        <i
          className="material-icons pointer"
          style={{
            position: "absolute",
            top: "5px",
            right: "5px",
            color: "#C00",
          }}
          onClick={this.props.back}
        >
          cancel
        </i>
        {!edit ? (
          <React.Fragment>
            <h5 className="center">Sales Invoice Details</h5>
            <hr />
            <Row>
              <Col md="6">
                <ul style={{ listStyle: "none" }}>
                  <li>
                    <span className="label-sm bold inquiry-span-label-col-1">
                      ID
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.salesInvoiceDetails.salesInvoiceId}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold inquiry-span-label-col-1">
                      PO Id
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.salesInvoiceDetails.poId}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold inquiry-span-label-col-1">
                      PO Name
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.salesInvoiceDetails.poEntryName}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold inquiry-span-label-col-1">
                      Invoice No
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.salesInvoiceDetails.invoiceNo}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold inquiry-span-label-col-1">
                      Invoice Date
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.salesInvoiceDetails.invoiceDate &&
                        DateFns.format(
                          this.props.salesInvoiceDetails.invoiceDate,
                          "DD-MM-YYYY"
                        )}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold inquiry-span-label-col-1">
                      Invoice Type
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.salesInvoiceDetails.invoiceType}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold inquiry-span-label-col-1">
                      Name
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.salesInvoiceDetails.customerName}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold inquiry-span-label-col-1">
                      Address
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.salesInvoiceDetails.address}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold inquiry-span-label-col-1">
                      Contact Name
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.salesInvoiceDetails.contactName}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold inquiry-span-label-col-1">
                      Contact Email
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.salesInvoiceDetails.contactEmail}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold inquiry-span-label-col-1">
                      contact No
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.salesInvoiceDetails.contactMobileNo}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold inquiry-span-label-col-1">
                      GSTIN
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.salesInvoiceDetails.gst}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold inquiry-span-label-col-1">
                      PAN
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.salesInvoiceDetails.pan}
                    </span>
                  </li>
                </ul>
              </Col>
              <Col md="6">
                <ul style={{ listStyle: "none" }}>
                  <li>
                    <span className="label-sm bold enter-invoice-span-label-col-2">
                      TIN
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.salesInvoiceDetails.tin}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold enter-invoice-span-label-col-2">
                      Bank Name
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.salesInvoiceDetails.bankName}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold enter-invoice-span-label-col-2">
                      IFSC Code
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.salesInvoiceDetails.bankIfscCode}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold enter-invoice-span-label-col-2">
                      Account Name
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.salesInvoiceDetails.bankAccName}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold enter-invoice-span-label-col-2">
                      Account No
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.salesInvoiceDetails.bankAccNo}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Invoice Currency
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.salesInvoiceDetails.invoiceCurrency}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Invoice Amount
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.salesInvoiceDetails.invoiceAmount}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Invoice GST
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.salesInvoiceDetails.invoiceGst}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Invoice Total Amount
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.salesInvoiceDetails.invoiceTotalAmount}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Payment Terms
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.salesInvoiceDetails.pymtTerm}
                    </span>
                  </li>
                  {/* <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Scheduled Date
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.salesInvoiceDetails.scheduledPymtDate}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Payment Amount
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.salesInvoiceDetails.pymtAmount}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Paid Amount
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.salesInvoiceDetails.paidAmount}
                    </span>
                  </li> */}
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Instructions
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.salesInvoiceDetails.pymtInstructions}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Payment Status
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.salesInvoiceDetails.pymtStatus}
                    </span>
                  </li>
                  <li>
                    <span className="label-sm bold bold enter-invoice-span-label-col-2">
                      Remarks
                    </span>
                    <span className="label-sm ">
                      &nbsp;&nbsp;:&nbsp;&nbsp;
                      {this.props.salesInvoiceDetails.remarks}
                    </span>
                  </li>
                </ul>
              </Col>
            </Row>
            <div className="div">
              <hr />
              <div className="center">
                {/* {this.props.inquiry.inqStatus !== "Closed" &&
                this.props.inquiry.inqStatus !== "Lead" ? ( */}
                <button
                  className="btn login-button white-text"
                  onClick={this.updateNow}
                >
                  Update
                </button>
                {/* ) : (
                  ""
                )} */}
              </div>
            </div>
          </React.Fragment>
        ) : (
          <UpdateSalesInvoice
            salesInvoiceDetail={this.props.salesInvoiceDetails}
            back={this.props.back}
          />
        )}
      </Container>
    );
  }
}

export default SalesInvoiceDetails;
