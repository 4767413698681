import React from "react";
// select country code component
const SelectCountryCode = (props) => {
  const { countries,tab, name, onChange, value, onKeyPress,widthValue } = props;

  // let countriesNew = JSON.parse(JSON.stringify(countries));
  // delete countriesNew["IN"];
  // const opt = Object.keys(countriesNew).map((key, index) => {
   
  //   return <option key={key} value={key}>{countriesNew[key].emoji} +{countriesNew[key].phone.split(',')[0]}</option>;
  // });
  return (
    <div>
      <select
        tabIndex={tab}
        onChange={onChange}
        style={{ height: "30px", width: `${widthValue}`,fontSize:".75rem",fontWeight:400 }}
        // onKeyPress={onKeyPress}
        name={name}
        id={name}
        value={value}
      >
         <option value={value} key={value}> {value}</option>
       
      </select>
    </div>
  );
};

export default SelectCountryCode;
