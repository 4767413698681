import React, { Component } from "react";
import PropTypes from "prop-types";
import { Navbar, Nav, NavItem, NavLink, NavbarBrand, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// components
import logo from "../../../assets/img/pionlogo.png";
import notification from "../../../assets/img/notification.png";
import person from "../../../assets/img/person.png";
import AccountBox from "../../aside/AccountBox";

// ---------------------------------------------------------------------------------------

class Navigation extends Component {
  constructor() {
    super();
    this.state = {
      click: false,
      visible: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleConnectionChange = this.handleConnectionChange.bind(this);
  }

  handleClick() {
    this.setState({
      click: !this.state.click,
    });
  }

  componentDidMount() {
    this.handleConnectionChange();
    window.addEventListener("online", this.handleConnectionChange);
    window.addEventListener("offline", this.handleConnectionChange);
  }

  componentWillUnmount() {
    window.removeEventListener("online", this.handleConnectionChange);
    window.removeEventListener("offline", this.handleConnectionChange);
  }

  handleConnectionChange() {
    if (!navigator.onLine) {
      this.setState({
        visible: true,
      });
    } else {
      this.setState({
        visible: false,
      });
    }
  }

  render() {
    const user = localStorage.getItem("username");
    const { visible } = this.state;

    return (
      <div>
        <Navbar color="light" light fixed="top" expand="md" id="navigation">
          <div className="sidebar-toggle-container">
            <i
              className="material-icons white-text sidebar-icon pointer"
              onClick={this.props.toggle}
            >
              menu
            </i>
          </div>

          <NavbarBrand>
            <img
              src={logo}
              height="40"
              style={{ marginLeft: ".8rem" }}
              alt="PION global"
              id="logo"
            />
          </NavbarBrand>

          <Nav navbar className="ml-auto pr-2">
            <NavItem>
              <NavLink style={{ color: "#04044C", marginRight: "20px" }}>
                <Link to="/pie">
                  <img src={notification} alt="Notification" />
                </Link>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                onMouseEnter={this.handleClick}
                style={{ color: "#04044C", marginRight: "7rem" }}
              >
                <img src={person} alt="Person" />
              </NavLink>
              {this.state.click ? (
                <AccountBox click={this.handleClick} name={user} />
              ) : null}
            </NavItem>
          </Nav>
        </Navbar>
        <Alert color="dark" isOpen={visible} className="internet-info-alert">
          <p>No Internet. Reconnect to continue the work...</p>
        </Alert>
      </div>
    );
  }
}

Navigation.propTypes = {
  toggle: PropTypes.func.isRequired,
  empManagement: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  empManagement: state.empManagement,
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Navigation);
