import axios from 'axios'
import React,{useEffect, useState} from 'react'
import { Container, Table } from "reactstrap";
import {useLocation,Link} from 'react-router-dom'
import {VIEWRESUME_CANDI} from '../../../../utils/routes'
import { RS_API } from "../../../../utils/config";


// view matched candidate uploaded resume
export default function viewUplodedresume(props) {

  const [dataArr,setDataArr] = useState([])
  const [errorMsg,setErrorMsg] = useState("")

  // useEffect(() => {
  //   fetchResume();
  // }, [])

//  function for get resume of particular candidate 

// const fetchResume = () =>{
//     axios
//       .get(
//         `${VIEWRESUME_CANDI}`,
//         {
//           params: {
//             canInfoId: props.fetchid,
//           },
//         },
//         { withCredentials: true }
//       )
//       .then((res) => {
//         setDataArr([res.data]);
//         if(!res.data.ok){
//         setErrorMsg(res.data.message)
//         }
//       })
//       .catch((err) => {
//         console.log(err);
//       });
// }

let shrink = props.sidebar ? "scale" : "no-scale";


    return (
      <div className={shrink}>
        <Container className="card container-card">
          {/* <div style={{display:"flex",justifyContent:"center"}}>
          <span style={{color:"red"}}>{errorMsg}</span>
          </div>
        */}
      
            <i onClick ={props.navigate} className="material-icons cancel-button pointer">cancel</i>
     
      
         
            <div style={{ width: "100%" }}>
            <div style={{ width: "100%" }}>
            <iframe
              src={
                `${RS_API}/v1/api/view-resume?canInfoId=${props.fetchid}`
              }
              width="100%"
              height="600rem"
            ></iframe>
          </div>
      {/* <div style={{ width: "100%" }}>
        <PDFViewer
          style={{ width: "100%",height:"500px" }}
        >
          <Document>
            <Page size="A4" >
              <View>
                <View
                  style={{
                    height: "100%",
                    width: "100%",
                    margin:"10%",

                  marginRight:"10%"
                  
                  }}
                >
                   {dataArr.map((detail) => (
                  <Text
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      marginTop: "10px",
                    }}
                  
                  >
                   {detail.resumeSummary}
                  </Text>
                ))}

                
                </View>
               
              </View>

            
          
            </Page>
          </Document>
        </PDFViewer>
      </div> */}
    </div>
            
    

        </Container>
      </div>
    );
}
