import React, { Component, useEffect } from "react";
import { Row, Col, Card } from "reactstrap";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import * as d3 from "d3";
import D3Funnel from "d3-funnel";
import DateFns from "date-fns";

import { ImSearch } from "react-icons/im";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import CustTooltip from "../../../../common/tooltip/CustTooltip";

import greenUpArrow from "../../../../../assets/img/green-up-arrow.png";
import redDownArrow from "../../../../../assets/img/red-down-arrow.png";
import yellowRectangle from "../../../../../assets/img/yellow-two.png"

import { Input, FormGroup, Label } from "reactstrap";

import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  ResponsiveContainer,
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Cell,
} from "recharts";
import axios from "axios";
import ViewFullDetailsModel from "./viewFullDetailsInfo";
import ViewFullDetailsFunnelInfo from "./viewFullDetailsFunnelInfo";
import ViewCategoryDeatailsModel from "./viewPositionCategoryInfo";
import { INSIGHTS_RS_API } from "../../../../../utils/routes";
import {
  getDashBoardDetails,
  getDashBoardMoreDetails,
  getDashBoardFullApplicants,
  getDashboardPosition,
  getDashboardApplicants,
  getDashboardFunnel,
  getDashboardApplicantsDetails,
  getDashboardFunnelData,
  getDashboardGraphRatio,
  getProfileScreeningManager,
  getManagerDashboardsSource
} from "../../../../../redux/actions/rs/dashboard/managerInsightsAction";
import { connect } from "react-redux";
import { useState } from "react";

// const CustomizedLabel = React.createClass({
//  render () {
//     const {x, y, fill, value} = this.props;
//      return <text
//                x={x}
//                y={y}

//                fontSize='16'
//                fontFamily='sans-serif'
//                fill={fill}
//                textAnchor="start">{value}%</text>
//   }
// });
const dataFunnel = [
  { label: "Hired", value: "Hired", labelColor: "#ffff00" },
  { label: "Interview", value: "Interview", labelColor: "#329932" },
  { label: "Screening", value: "Screening", labelColor: "#fff" },
  { label: "Applications", value: "Applications", labelColor: "#000" },
];

// const optionsFunnel = {
//   chart: {
//     height: 180,
//     width: 200,
//   },
//   block: {
//     dynamicHeight: true,
//     minHeight: 15,
//     highlight: true,
//     curve: {
//       enabled: true,
//       height: 20,
//     },
//     animate: 100,
//   },
//   tooltip: {
//     enabled: true,
//     format: "{l}: {f}",
//   },
//   label: {
//     format: function (label, value) {
//       return "" + value;
//     },
//   },
// };

const CustomizedLabel = (props) => {
  const { x, y, fill, value } = props;
  return (
    <text
      x={x}
      y={y}
      fontSize="14"
      fontFamily="sans-serif"
      fill={fill}
      textAnchor="start"
    >
      {value}
    </text>
  );
};

const ManagerGraph = (props) => {
  const [data, setData] = useState([]);
  const [fulleDetailsData, setFullDetailsData] = useState([]);
  const [fullDetailsApplicantsData, setFullDetailsApplicationData] = useState(
    []
  );

  const [funnelCardData, setFunnelCardData] = useState([]);
  const [applicantsGraphHoverData, setApplicantsGraphHoverdata] = useState([]);
  const [categoryGraphHoverData, setCategoryGraphHoverData] = useState([]);
  const[funnelScreeningData,setFunnelScreeningData] = useState([])
  const[dashBoardSourceHoverData,setDashBoardSourceHoverData] = useState([])
  const [isOpenFunnelCandi, setIsOpenFunnelCandi] = useState(false);
  const[isOpenScreeningCandi,setIsOpenScreeningCandi] = useState(false)

  const [isOpenCategoryCandi, setIsOpenCategoryCandi] = useState(false);
  const [isOpenApplicantsCandi, setIsOpenApplicantsCandi] = useState(false);
  const[isOpenSourceCandi,setIsOpenSourceCandi] = useState(false)
  

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [dateError, setDateError] = useState("");
  const [isToggleView, setIsToggleView] = useState(false);
  const [isOpenFullCandi, setIsOpenFullCandi] = useState(false);
  const [isOpenCandi, setIsOpenCandi] = useState(false);
  const [isOpenHiredCandi, setIsOpenHiredCandi] = useState(false);
  const [isOpenOfferedCandi, setIsOpenOfferedCandi] = useState(false);
  const [isOpenDeclinedCandi, setIsOpenDeclinedCandi] = useState(false);
  const [isOpenRejectedCandi, setIsOpenRejectedCandi] = useState(false);

  const dataTwoGraphs = [
    { name: "Social media", value: "tech" },
    { name: "JobBoards", value: "technology" },
    { name: "Website", value: "IT" },
  ];

  var dataPoint;
  var total;
  const options = {
    animationEnabled: true,
    title: {
      text: "Sales Analysis",
    },
    data: [
      {
        type: "funnel",
        // toolTipContent: "<b>{label}</b>: {y} <b>({percentage}%)</b>",
        indexLabelPlacement: "inside",
        indexLabel: "{label} ({percentage}%)",
        dataPoints: [
          { y: 1400, label: "Prospects" },
          { y: 1212, label: "Qualified Prospects" },
          { y: 1080, label: "Proposals" },
          { y: 665, label: "Negotiation" },
          { y: 578, label: "Final Sales" },
        ],
      },
    ],
  };
  //calculate percentage
  dataPoint = options.data[0].dataPoints;
  total = dataPoint[0].y;
  for (var i = 0; i < dataPoint.length; i++) {
    if (i == 0) {
      options.data[0].dataPoints[i].percentage = 100;
    } else {
      options.data[0].dataPoints[i].percentage = (
        (dataPoint[i].y / total) *
        100
      ).toFixed(2);
    }
  }

  const funnelCardClick = (data) => {
    console.log(props?.managerInsights?.funnelData[0][data]);
    setFunnelCardData(props?.managerInsights?.funnelData[0][data]);
    setIsOpenFunnelCandi(true);
    setIsToggleView(false);
  };

  const funnelScreeningClick = (data) => {
   
    setFunnelScreeningData(props?.managerInsights?.profileScreeningData[0][data]);
    setIsOpenScreeningCandi(true);
    setIsToggleView(false);
  };


  const handleCategaryGraphClick = (data) => {
    setCategoryGraphHoverData(props?.managerInsights?.positionData[0][data]);
    setIsOpenCategoryCandi(!isOpenCategoryCandi);
  };

  // const handleApplicantsGraphClick = (data) => {
  //   setApplicantsGraphHoverdata(
  //     props?.managerInsights?.positionApplicants[0][data]
  //   );
  //   setIsOpenApplicantsCandi(!isOpenApplicantsCandi);
  // };

  // const handleDashboardSourceClick = (data) => {
  //   setDashBoardSourceHoverData(
  //     props?.managerInsights?.managerSourceData[0][data]
  //   );
  //   setIsOpenSourceCandi(!isOpenSourceCandi);
  // };

  const optionsFunnel = {
    chart: {
      height: 180,
      width: 200,
    },
    block: {
      dynamicHeight: true,
      minHeight: 15,
      highlight: true,
      curve: {
        enabled: true,
        height: 20,
      },
      animate: 100,
    },
    tooltip: {
      enabled: true,
      format: "{l}- {f}",
    },
    events: {
      click: {
        block: (label, value) => {
          funnelCardClick(value.label.raw);
        },
      },
    },

    label: {
      format: function (label, value) {
        return "" + value;
      },
    },
  };

  const optionsFunnelTwo = {
    chart: {
      height: 180,
      width: 200,
    },
    block: {
      dynamicHeight: true,
      minHeight: 15,
      highlight: true,
      curve: {
        enabled: true,
        height: 20,
      },
      animate: 100,
    },
    tooltip: {
      enabled: true,
      format: "{l}- {f}",
    },
    events: {
      click: {
        block: (label, value) => {
          funnelScreeningClick(value.label.raw);
        },
      },
    },

    label: {
      format: function (label, value) {
        return "" + value;
      },
    },
  };


  const CustomizedLabel = (props) => {
    const { x, y, fill, value } = props;
    return (
      <text
        x={x}
        y={y}
        fontSize="14"
        fontFamily="sans-serif"
        fill={fill}
        textAnchor="start"
      >
        {value}
      </text>
    );
  };

  let shrink = props.sidebar ? "scaleGraph" : "no-scaleGraph";

  const COLORS = [
    "#FDD017",
      "#E8A317",
      "#FC7F03",
      "#7BB4E3",
      "#38ACEC",
     
    
      "#0000FF",
      "#BCE954",
      "#6CBB3c",
      "#008000",
  ];

  let resultOne = Object.keys(
    props?.managerInsights?.positionData.length > 0 &&
      props?.managerInsights?.positionData[0]
  );
  console.log(resultOne);
  const data01 = [];
  for (const key of resultOne) {
    if (props?.managerInsights?.positionData[0][key].length > 0) {
      data01.push({
        name: key,
        value: props?.managerInsights?.positionData[0][key].length,
      });
    }
  }

  let resultTwo = Object.keys(
    props?.managerInsights?.positionApplicants.length > 0 &&
      props?.managerInsights?.positionApplicants[0]
  );
  console.log(resultTwo);
  const data02 = [];
  for (const key of resultTwo) {
    if (props?.managerInsights?.positionApplicants[0][key]> 0) {
      data02.push({
        name: key,
        value: props?.managerInsights?.positionApplicants[0][key],
      });
    }
  }

  let resultFour = Object.keys(
    props?.managerInsights?.managerSourceData.length > 0 &&
      props?.managerInsights?.managerSourceData[0]
  );
  console.log(resultFour);
  const dataFive = [];
  for (const key of resultFour) {
    if (props?.managerInsights?.managerSourceData[0][key]> 0) {
      dataFive.push({
        name: key,
        value: props?.managerInsights?.managerSourceData[0][key],
      });
    }
    console.log(dataFive);
  }


  let data04 = [
    { name: "Social media", value: 3 },
    { name: "Job Boards", value: 3 },
    { name: "Websites", value: 3 },
    { name: "Referrals", value: 3 },
  ];

  const handleHiredCandi = (event) => {
    props?.managerInsights.mainData[0][event].length > 0 &&
      setIsToggleView(false);
    setFullDetailsData(props?.managerInsights.mainData[0][event]);
    setIsOpenCandi(!isOpenCandi);
  };

  const handleDeclinedCandi = (event) => {
    props?.managerInsights.mainData[0][event].length > 0 &&
      setIsToggleView(false);
    setFullDetailsData(props?.managerInsights.mainData[0][event]);
    setIsOpenCandi(!isOpenCandi);
  };

  const handleOfferedCandi = (event) => {
    props?.managerInsights.mainData[0][event].length > 0 &&
    setIsToggleView(false);
    setFullDetailsData(props?.managerInsights.mainData[0][event]);
    setIsOpenCandi(!isOpenCandi);
  };

  const handleRejectedCandi = (event) => {
    props?.managerInsights.mainData[0][event].length > 0 &&
      setIsToggleView(false);
    setFullDetailsData(props?.managerInsights.mainData[0][event]);
    setIsOpenCandi(!isOpenCandi);
  };

  const handleShortListedCandi = (event) => {
    props?.managerInsights.mainData[0][event].length > 0 &&
      setIsToggleView(false);
    setFullDetailsData(props?.managerInsights.mainData[0][event]);
    setIsOpenCandi(!isOpenCandi);
  };

  const handleOthersCandi = (event) => {
    props?.managerInsights.mainData[0][event].length > 0 &&
      setIsToggleView(false);
    setFullDetailsData(props?.managerInsights.mainData[0][event]);
    setIsOpenCandi(!isOpenCandi);
  };

  const handleFullApplicantsCandi = (event) => {
    props?.managerInsights.applicantsData?.length > 0 && setIsToggleView(true);
    setFullDetailsApplicationData(props?.managerInsights?.applicantsData);
    setIsOpenFullCandi(!isOpenFullCandi);
  };

  useEffect(() => {
    props.getDashboardFunnel();
    props.getDashBoardDetails();
    props.getDashBoardMoreDetails();
    // props.getDashBoardFullApplicants();
    props.getDashboardPosition();
    props.getDashboardApplicants();
    props.getProfileScreeningManager()
    props.getDashboardGraphRatio();
    props.getManagerDashboardsSource()
  }, []);

  const handleDateClick = () => {
    let body = {
      fromDate: fromDate,
      endDate: toDate,
    };

    if (fromDate && toDate) {
      props.getDashBoardDetails(body);
      props.getDashBoardMoreDetails(body);
      props.getDashboardFunnel(body);
      // props.getDashBoardFullApplicants(body);
      props.getDashboardPosition(body);
      props.getDashboardApplicants(body);
      props.getProfileScreeningManager(body)
      props.getManagerDashboardsSource(body)
      // props.getDashboardGraphRatio(body);
    } else {
      setDateError("date should not be empty");
      setTimeout(() => {
        setDateError("");
      }, 2000);
    }
  };

  useEffect(() => {
    let resultThree = Object.keys(
      props?.managerInsights?.funnelData.length > 0 &&
        props?.managerInsights?.funnelData[0]
    );
    const data03 = [];
    const colorCode = [
      "#FDD017",
      "#E8A317",
      "#FC7F03",
      "#38ACEC",
      "#0000FF",
      "#6CBB3c",
      "#008000",
    ];

    resultThree.forEach((curr, index, arr) => {
      data03.push({
        label: curr,
        value: Number(
          props?.managerInsights?.funnelData[0][curr].length > 0
            ? props?.managerInsights?.funnelData[0][curr].length
            : "0"
        ),
        backgroundColor: colorCode[index],
      });
    });

    console.log(data03);
    const chart = new D3Funnel("#funnel");
    data03.length > 0 && chart.draw(data03, optionsFunnel);
    
    let resultFour = Object.keys(
      props?.managerInsights?.profileScreeningData.length > 0 &&
        props?.managerInsights?.profileScreeningData[0]
    );

    const data04 = []

    const colorTwoCode = [
      "#FDD017",
      "#E8A317",
      "#FC7F03",
      "#7BB4E3",
      "#38ACEC",
     
    
      "#0000FF",
      "#BCE954",
      "#6CBB3c",
      "#008000",
    ];

    resultFour.forEach((curr, index, arr) => {
      data04.push({
        label: curr,
        value: Number(
          props?.managerInsights?.profileScreeningData[0][curr].length > 0
            ? props?.managerInsights?.profileScreeningData[0][curr].length
            : "0"
        ),
        backgroundColor: colorTwoCode[index],
      });
    });

    console.log(data03);
    const chartTwo= new D3Funnel("#funnelTwo");
    data04.length > 0 && chartTwo.draw(data04, optionsFunnelTwo);


  }, [props?.managerInsights]);

  // useEffect(() => {
  //   props.getDashboardFunnelData();
  // }, []);

  const closeDetailsModel = () => {
    setIsOpenCandi(false);
  };

  const closeDetailsFullModel = () => {
    setIsOpenFullCandi(false);
  };

  const closeDetailsApplicantsModel = () => {
    setIsOpenApplicantsCandi(false);
  };

  const closeDetailsCategoryModel = () => {
    setIsOpenCategoryCandi(false);
  };

  const closeDetailsFunnelCandi = () => {
    setIsOpenFunnelCandi(false);
  };

  const closeDetailsScreeningCadi = () => {
    setIsOpenScreeningCandi(false);
  };

  const closeDetailsSourceCandi = () => {
    setIsOpenSourceCandi(false);
  };

  console.log(isOpenFunnelCandi);

  return (
    <div className={shrink}>
      <div className="flex-top-firstConatiner">
        <div className="flex-Graphcontainer">
          <div
          //  onClick={handleFullApplicantsCandi}
           >
            <CustTooltip title="Total Applications">
              <span style={{ fontWeight: "bold" }}>Applications</span>
            </CustTooltip>
            <span
              style={{ fontSize: "1rem", fontWeight: "bold", color: "#003C82" }}
            >
              {" "}
              {props.managerInsights.data[0]?.TotalApplicants}
            </span>
          </div>
          <div onClick={() => handleHiredCandi("HiredCandiadte")}>
            <span style={{ fontWeight: "bold" }}> Hired/Joined </span>
            <span
              style={{ fontSize: "1rem", fontWeight: "bold", color: "#003C82" }}
            >
              {" "}
              {props.managerInsights.data[0]?.HiredCandiadte}{" "}
            </span>
          </div>
          <div onClick={() => handleOfferedCandi("OfferedCandidate")}>
            <span style={{ fontWeight: "bold" }}> Offered</span>

            <span
              style={{ fontSize: "1rem", fontWeight: "bold", color: "#003C82" }}
            >
              {" "}
              {props.managerInsights.data[0]?.OfferedCandidate}{" "}
            </span>
          </div>
          <div onClick={() => handleOfferedCandi("Accepted")}>
            <CustTooltip title="Offer Accepted">
              <span style={{ fontWeight: "bold" }}> Accepted</span>
            </CustTooltip>
            <span
              style={{ fontSize: "1rem", fontWeight: "bold", color: "#003C82" }}
            >
              {" "}
              {props.managerInsights.data[0]?.Accepted}{" "}
            </span>
          </div>
          <div onClick={() => handleDeclinedCandi("DeclineCandidate")}>
            <CustTooltip title="Offer Declined">
              <span style={{ fontWeight: "bold" }}> Declined</span>
            </CustTooltip>
            <span
              style={{ fontSize: "1rem", fontWeight: "bold", color: "#003C82" }}
            >
              {" "}
              {props.managerInsights.data[0]?.DeclineCandidate}
            </span>
          </div>
          <div onClick={() => handleShortListedCandi("ShortlistedCandidate")}>
            <CustTooltip title="Interview Shortlisted">
              <span style={{ fontWeight: "bold" }}> Shortlisted</span>
            </CustTooltip>
            <span
              style={{ fontSize: "1rem", fontWeight: "bold", color: "#003C82" }}
            >
              {" "}
              {props.managerInsights.data[0]?.ShortlistedCandidate}{" "}
            </span>
          </div>

          <div 
          // onClick={() => handleRejectedCandi("RejectedCandidate")}
          >
            <CustTooltip title="Interview Rejected">
              <span style={{ fontWeight: "bold" }}> Rejected</span>
            </CustTooltip>
            <span
              style={{ fontSize: "1rem", fontWeight: "bold", color: "#003C82" }}
            >
              {" "}
              {props.managerInsights.data[0]?.RejectedCandidate}
            </span>
          </div>

          <div 
          // onClick={() => handleOthersCandi("Others")}
          
          
          >
            <CustTooltip title="Candidates other than hiring stage">
              <span style={{ fontWeight: "bold" }}> Others</span>
            </CustTooltip>
            <span
              style={{ fontSize: "1rem", fontWeight: "bold", color: "#003C82" }}
            >
              {" "}
              {props.managerInsights.data[0]?.Others}
            </span>
          </div>
          {/* <div>
        
              <button>ckjnckjc</button>

              </div> */}
        </div>

        <div className="dateManager-classInsights">
          <div className="dateManager-classInsights-one">
            <FormGroup className="mb-0-5">
              <Label
                style={{
                  fontWeight: "bold",
                  fontSize: "0.80rem",
                  marginLeft: "1.5rem",
                }}
              >
                From Date
              </Label>

              <Input
                type="date"
                name="sourcedFrom"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                className="form-control-sm manager-view-select-box manager-date-input"
                required={true}
                tabIndex={8}
              />
            </FormGroup>

            <FormGroup className="mb-0-5">
              <Label
                style={{
                  fontWeight: "bold",
                  fontSize: "0.80rem",
                  marginLeft: "1.5rem",
                }}
              >
                To Date
              </Label>

              <Input
                type="date"
                name="sourcedTo"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                className="form-control-sm manager-view-select-box manager-date-input"
                required={true}
                tabIndex={8}
              />
            </FormGroup>
          </div>

          <h3
            data-toggle="tooltip"
            data-placement="top"
            onClick={handleDateClick}
            style={{
              color: "#0056b3",
              marginLeft: ".5rem",
              cursor: "pointer",
              fontSize: "1rem",
            }}
          >
            {" "}
            <ImSearch />{" "}
          </h3>
        </div>
      </div>

      <div className="flex-GraphcontainerTwo">
        <div className="graphChild-col-3">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <span
              style={{
                margin: "auto",
                fontSize: "0.80rem",
                fontWeight: "bold",
                marginBottom: "0.5rem",
              }}
            >
              Recruitment Funnel
            </span>
          </div>
          <div style={{ marginTop: "0.5rem" }} id="funnel" />
        </div>
        <div className="graphChild-col-3">

        <div style={{ display: "flex", justifyContent: "center" }}>
            <span
              style={{
                margin: "auto",
                fontSize: "0.80rem",
                fontWeight: "bold",
                marginBottom: "0.5rem",
              }}
            >
             Profile Screening Status
            </span>
          </div>
          <div style={{ marginTop: "0.5rem" }} id="funnelTwo" />
         
          {/* <ResponsiveContainer>
            <BarChart
              data={dataTwoGraphs}
              layout="vertical"
              barCategoryGap={10}
            >
              <XAxis type="category" />
              <YAxis type="category" dataKey="name" />

              <Bar dataKey="value" fill="#003C82" label={<CustomizedLabel />} />
            </BarChart>
          </ResponsiveContainer> */}
        </div>

        <div className="graphChild-col-4">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <span
              style={{
                margin: "auto",
                fontSize: "0.80rem",
                fontWeight: "bold",
                marginTop: "0.5rem",
              }}
            >
             Monthly Growth Trends ( % )
            </span>
          </div>
          <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between", 
            marginTop:"1.5rem"
          }}>
             <div style={{ display: "flex",
              flexDirection: "row",
              marginTop:"1rem",
              justifyContent: "space-between", }}>
          
              <span style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
                Requirements
              </span>
              <CustTooltip title = {`Past month - ${props.managerInsights.ratioData[0]?.PastMonthJobRequirementCount}
              ${" "}
              Current month - ${props.managerInsights.ratioData[0]?.CurrentMonthJobRequirementCount
              }
              `}>
              <span style={{ marginLeft: "1rem",cursor:"pointer" }}>
                {" "}
                {props.managerInsights.ratioData[0]?.JobRequirementTrendPercentages}
              </span>
              </CustTooltip>

              {Number(props.managerInsights.ratioData[0]?.JobRequirementTrendPercentages) > 0 &&
              <img style={{marginLeft:"1rem",marginTop:"-1rem"}} src={greenUpArrow} alt="arrow" /> }
              {Number(props.managerInsights.ratioData[0]?.JobRequirementTrendPercentages) < 0 &&
              <img style={{marginLeft:"1rem",marginTop:"-1rem"}} src={redDownArrow}  alt="arrow" />}
               {Number(props.managerInsights.ratioData[0]?.JobRequirementTrendPercentages) === 0 &&
              <img style={{marginLeft:"1rem",marginTop:"-1rem",height:"3rem",width:"3rem"}} src={yellowRectangle}  alt="arrow" />}

             
          

            {/* <div style={{ marginLeft: "1rem" }}>
            
              <br></br>

              <img
                  src={redDownArrow}
                  alt="arrow"
                  style={{ marginTop: "-10px" }}
                />

              <img
                  src={yellowUpArrow}
                  alt="arrow"
                  style={{ marginTop: "-10px" }}
                />

             
            </div> */}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop:"1rem"
            }}
          >
          
              <span style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
                Offered
              </span>
              <CustTooltip title = {`Past month - ${props.managerInsights.ratioData[0]?.PastMonthOffredCandidateCount}
               
               ${"    "} Current month - ${props.managerInsights.ratioData[0]?.CurrentMonthOfferedCandidateCount
              }
              `}>
              <span style={{ marginLeft: "3rem",cursor:"pointer" }}>
                {" "}
                {props.managerInsights.ratioData[0]?.OfferedTrendPercentage}
              </span>
              </CustTooltip>
              {Number(props.managerInsights.ratioData[0]?.OfferedTrendPercentage) > 0 &&
              <img style={{marginLeft:"1rem",marginTop:"-1rem"}} src={greenUpArrow} alt="arrow" /> }
              {Number(props.managerInsights.ratioData[0]?.OfferedTrendPercentage) < 0 &&
              <img style={{marginLeft:"1rem",marginTop:"-1rem"}} src={redDownArrow}  alt="arrow" />}
               {Number(props.managerInsights.ratioData[0]?.OfferedTrendPercentage) === 0 &&
              <img style={{marginLeft:"1rem",marginTop:"-1rem",height:"3rem",width:"3rem"}} src={yellowRectangle}  alt="arrow" />}
              
              
          

            {/* <small>Trend</small> */}

            {/* <img
                  src={redDownArrow}
                  alt="arrow"
                  style={{ marginTop: "-10px" }}
                /> */}

            {/* <img
                  src={yellowUpArrow}
                  alt="arrow"
                  style={{ marginTop: "-10px" }}
                /> */}
          </div>

          

          <div style={{ display: "flex", flexDirection: "row",justifyContent:"space-between",marginTop:"1rem" }}>
        
              <span style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
                Joined 
              </span>
              <CustTooltip title = {`Past month - ${props.managerInsights.ratioData[0]?.PastMonthJoinedCandidateCount}
              ${" "}
              Current month - ${props.managerInsights.ratioData[0]?.CurrentMonthJoinedCandidateCount
              }
              `}>
              <span style={{ marginLeft: "3rem",cursor:"pointer" }}>
                {" "}
                {props.managerInsights.ratioData[0]?.JoinedTrendPercentages}
              </span>
              </CustTooltip>

              {Number(props.managerInsights.ratioData[0]?.JoinedTrendPercentages) > 0 &&
              <img style={{marginLeft:"1rem",marginTop:"-1rem"}} src={greenUpArrow} alt="arrow" /> }
              {Number(props.managerInsights.ratioData[0]?.JoinedTrendPercentages) < 0 &&
              <img style={{marginLeft:"1rem",marginTop:"-1rem"}} src={redDownArrow}  alt="arrow" />}
               {Number(props.managerInsights.ratioData[0]?.JoinedTrendPercentages) === 0 &&
              <img style={{marginLeft:"1rem",marginTop:"-1rem",height:"3rem",width:"3rem"}} src={yellowRectangle}  alt="arrow" />}

            
           

            {/* <div style={{ marginLeft: "1rem" }}>
              <small>Trend</small>
              <br></br>

              <img
                  src={redDownArrow}
                  alt="arrow"
                  style={{ marginTop: "-10px" }}
                />

              <img
                  src={yellowUpArrow}
                  alt="arrow"
                  style={{ marginTop: "-10px" }}
                />

             
            </div> */}
          </div>
         
          </div>

          {/* <span  style={{fontSize:"0.8rem",fontWeight:"bold"}}>New Requirements </span>
<span> {this.props.rsDashboard.ratioData[0]?.NewReqiurement
}</span>
<span style={{fontSize:"0.8rem",fontWeight:"bold"}}>Joined Candidates</span>
<span> {this.props.rsDashboard.ratioData[0]?.JoinedCandidate
}</span>
<span style={{fontSize:"0.8rem",fontWeight:"bold"}}>Offered Candidates</span>
<span> {this.props.rsDashboard.ratioData[0]?.OfferedCandidate
}</span> */}
        </div>
      </div>

      <div className="flex-GraphcontainerThree">
      <div className="graphChild-col-1">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <span
              style={{
                alignSelf: "flex-start",
                fontSize: "0.80rem",
                marginTop: "0.5rem",
                fontWeight: "bold",
              }}
            >
              Open Positions by Job Category
            </span>
          </div>

          <ResponsiveContainer width="100%">
            <PieChart width={300}>
              <Pie
                dataKey="value"
                isAnimationActive={false}
                data={data01}
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#8884d8"
                label
              >
                {data01.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    onClick={() => handleCategaryGraphClick(entry.name)}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>

              {/* <Pie dataKey="value" data={data02} cx={500} cy={200} innerRadius={40} outerRadius={80} fill="#82ca9d" /> */}
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>

      <div className="graphChild-col-1">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <span
              style={{
                marginTop: "0.5rem",
                fontSize: "0.80rem",
                fontWeight: "bold",
              }}
            >
              Applicants by Job Category
            </span>
          </div>

          <ResponsiveContainer width="100%">
            <PieChart width={300}>
              <Pie
                dataKey="value"
                isAnimationActive={false}
                data={data02}
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#8884d8"
                label
              >
                {data02.map((entry, index) => (
                  <Cell
                    
                    key={`cell-${index}`}
                    // onClick={() => handleApplicantsGraphClick(entry.name)}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>

              {/* <Pie dataKey="value" data={data02} cx={500} cy={200} innerRadius={40} outerRadius={80} fill="#82ca9d" /> */}
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>

        


    
        <div className="graphChild-col-1">
        <div style={{ display: "flex", justifyContent: "center" }}>
            <span
              style={{
                margin: "auto",
                fontSize: "0.80rem",
                fontWeight: "bold",
                marginTop: "0.5rem",
              }}
            >
              Applicants  by Source
            </span>
          </div>

          <ResponsiveContainer width="100%">
            <PieChart width={300}>
              <Pie
                dataKey="value"
                isAnimationActive={false}
                data={ dataFive}
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#8884d8"
                label
              >
                {dataFive.map((entry, index) => (
                  <Cell
                    // onClick={()=> handleDashboardSourceClick(entry.name)}
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>

              {/* <Pie dataKey="value" data={data02} cx={500} cy={200} innerRadius={40} outerRadius={80} fill="#82ca9d" /> */}
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>

        </div>

        

        
       
      
        {/* <div  className="graphChild-colOne-2">
   
    
          <TableContainer  >
                       <Table  sx={{ width: "50rem" }} >
                         <TableHead style={{ background: "#003C82" }}>
                           <TableRow >
                             <TableCell  style={{color:"#FFFFFF",width:"30%"}} >Application Name</TableCell>
                             <TableCell style={{color:"#FFFFFF",width:"20%"}}>Email</TableCell>
                             <TableCell style={{color:"#FFFFFF",width:"15%"}}>Job Title</TableCell>
                             <TableCell style={{color:"#FFFFFF",width:"25%"}} width={300}> Applied Date</TableCell>
                             <TableCell style={{color:"#FFFFFF",width:"30%"}}> Status</TableCell>
                           </TableRow>
                         </TableHead>
                         <TableBody>
   
                       {
                       props?.rsDashboard?.tableData.length > 0 && 
                        props?.rsDashboard?.tableData.map((table)=>{
                         return(
                           <TableRow style={{height:"60px"}}
                           >
                              <TableCell style={{width:"30%"}} >
                            {`${table.canInfo[0].firstName} ${table.canInfo[0].lastName}`}
                              </TableCell>
                              <TableCell  style={{width:"20%"}}>
                             {table.canInfo[0].eMailId}
                              </TableCell>
                              <TableCell style={{width:"15%"}}>
                             {
                             table.jobRequirement.length &&
                             table.jobRequirement[0].jobPosition
   
                             }
                              </TableCell>
                              <TableCell style={{width:"25%"}}>
                             {DateFns.format(table.taggedOn,"DD-MM-YYYY")}
                              </TableCell>
                              <TableCell  style={{width:"30%"}}>
                              {
                             table.jobRequirement.length &&
                             table.hiringStatus
   
                             }
                              </TableCell>
                          
                            </TableRow>
                         )
                        })
                       }
                          
                    
                         </TableBody>
                       </Table>
                     </TableContainer> 
   </div>*/}
      </div>

      {isOpenCandi && (
        <ViewFullDetailsModel
          isToogleView={isToggleView}
          isOpenCandi={isOpenCandi}
          closeModel={closeDetailsModel}
          data={fulleDetailsData}
          isReq={false}
          isShowTac ={false}
        />
      )}

      {isOpenFullCandi && (
        <ViewFullDetailsModel
          isToogleView={isToggleView}
          isOpenCandi={isOpenFullCandi}
          closeModel={closeDetailsFullModel}
          data={fullDetailsApplicantsData}
          isReq={false}
          isShowTac ={false}
        />
      )}

      {isOpenApplicantsCandi && (
        <ViewFullDetailsModel
          isToogleView={isToggleView}
          isOpenCandi={isOpenApplicantsCandi}
          closeModel={closeDetailsApplicantsModel}
          data={applicantsGraphHoverData}
          isReq={false}
          isShowTac ={false}
        />
      )}

      {isOpenFunnelCandi && (
        <ViewFullDetailsFunnelInfo
          isToogleView={isToggleView}
          isOpenCandi={isOpenFunnelCandi}
          closeModel={closeDetailsFunnelCandi}
          data={funnelCardData}
          isReq={true}
          isShowTac ={true}
        />
      )}

{isOpenScreeningCandi && (
        <ViewFullDetailsFunnelInfo
          isToogleView={isToggleView}
          isOpenCandi={isOpenScreeningCandi}
          closeModel={closeDetailsScreeningCadi}
          data={funnelScreeningData}
          isReq={true}
          isShowTac ={true}

        />
      )}

      {isOpenCategoryCandi && (
        <ViewCategoryDeatailsModel
          isToogleView={isToggleView}
          isOpenCandi={isOpenCategoryCandi}
          closeModel={closeDetailsCategoryModel}
          data={categoryGraphHoverData}
          isShowTac ={true}
        />
      )}

{isOpenSourceCandi && (
        <ViewFullDetailsModel
          isToogleView={isToggleView}
          isOpenCandi={isOpenSourceCandi}
          closeModel={closeDetailsSourceCandi}
          data={dashBoardSourceHoverData}
          isShowTac ={true}
        />
      )}

      {/* <Col md="6" className="graph-col">
               <Card className="graph-card" >
            <LineChart width={600} height={300} data={this.state.data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
             
           <CartesianGrid strokeDasharray="5 5" />
           <XAxis dataKey="date" />
           <YAxis domain={[0, 60]}  />
           <Tooltip />
           <Legend />
           <Line
             type="monotone"
             dataKey="created"
             stroke="#8884d8"
             activeDot={{ r: 8 }}
           />
             <Line
             type="monotone"
             dataKey="tagged"
             stroke="#FBB117"
             activeDot={{ r: 8 }}
           />
     </LineChart>
         </Card>
           </Col> */}

      {dateError && <ErrorNotifier message={dateError} />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  rsDashboard: state.rsDashboard,
  managerInsights: state.managerInsights,
});

export default connect(mapStateToProps, {
  getDashBoardDetails,
  getDashBoardMoreDetails,
  getDashBoardFullApplicants,
  getDashboardPosition,
  getDashboardApplicants,
  getDashboardFunnel,
  getDashboardApplicantsDetails,
  getDashboardFunnelData,
  getDashboardGraphRatio,
  getProfileScreeningManager,
  getManagerDashboardsSource
})(ManagerGraph);
