import React, { Component } from "react";
import { Card, Row, Col } from "reactstrap";
import IssuePO from "./issue_po/IssuePO";
import Invoices from "./enter_invoice/Invoices";

class AccountsPaybleDashboard extends Component {
  constructor() {
    super();
    this.state = {
      edit: "",
    };
    this.back = this.back.bind(this);
    this.chooseForm = this.chooseForm.bind(this);
  }

  back() {
    this.setState({
      edit: "",
    });
  }

  chooseForm() {
    let component = this.state.edit;
    if (component === "issuePO") {
      return <IssuePO back={this.back} />;
    }
    if (component === "invoices") {
      return <Invoices back={this.back} />;
    }
  }

  render() {
    let shrink;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");
    return (
      <div className={shrink}>
        {!this.state.edit ? (
          <div className="container-fluid">
            <div className="row">
              <div className="col-10 mx-auto">
                <div>
                  <h4 className="bold center pt-2 pb-3">Accounts Payable</h4>
                  <Row className="content-center">
                    <Col md="4">
                      <Card
                        className="center profile-edit-card"
                        onClick={() => this.setState({ edit: "issuePO" })}
                      >
                        <h6 className="bold">Issue PO</h6>
                      </Card>
                    </Col>
                    <Col md="4">
                      <Card
                        className="center profile-edit-card"
                        onClick={() => this.setState({ edit: "invoices" })}
                      >
                        <h6 className="bold">Purchase Invoice</h6>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        ) : (
          this.chooseForm()
        )}
      </div>
    );
  }
}
export default AccountsPaybleDashboard;
