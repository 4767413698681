import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormText,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { connect } from "react-redux";
import axios from "axios";
import DateFns from "date-fns";
import { getPartners } from "../../../../../redux/actions/partnerAction";
import { getVendors } from "../../../../../redux/actions/vendorAction";
import {
  addInvoice,
  addRecieveItem,
  getRecieveItems,
  addUpdateInvoice,
} from "../../../../../redux/actions/finance/accounts_payable/enter_invoice/invoiceAction";
import { getCurrency } from "../../../../../redux/actions/currencyAction";
import { getInvoiceType } from "../../../../../redux/actions/finance/invoice/invoiceTypeAction";
import { getIssuePOs } from "../../../../../redux/actions/finance/accounts_payable/issue_po/issuePOAction";
import {
  FETCH_PARTNER_TAXPYMT_DETAILS,
  FETCH_VENDOR_TAXPYMT_DETAILS,
  FETCH_PARTNER_BANK_ACC,
  FETCH_VENDOR_BANK_ACC,
} from "../../../../../utils/routes";
import FormComponent from "../../../../common/FormComponent";
import {
  errors,
  Validator,
  submitValidator,
  valid,
  refresh,
} from "../../../../common/Validator";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import Notifier from "../../../../aside/Notifier";

/**
 * @class AddInvoice
 **/

class AddInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      purchaseId: "",
      poNo: "",
      poName: "",
      poNoName: "",
      poType: "",
      poAmount: "",
      invoiceNumber: "",
      invoiceDate: "",
      invoiceType: "",
      vpsName: "",
      address: "",
      contactName: "",
      contactEmail: "",
      contactPhoneNumber: "",
      shipToAddress: "",
      shipToContactNo: "",
      gstin: "",
      pan: "",
      tin: "",
      bankName: "",
      bankIfscCode: "",
      bankAccountName: "",
      bankAccountNumber: "",
      invoiceCurrency: "",
      invoiceAmount: "",
      invoiceGst: "",
      invoiceTotalAmount: "",
      paymentTerms: "",
      paymentInstructions: "",
      remarks: "",
      recieveItemModal: false,
      purchaseItemNo: "",
      pspName: "",
      quantity: "",
      amount: "",
      amountGST: "",
      toggleCreateInvoice: true,
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    if (e.target.name === "poNo") {
      console.log(e.target.value);
      this.setState({
        poNoName: e.target.value,
        poNo: e.target.value.split(",")[0],
        poName: e.target.value.split(",")[1],
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  partnerNameHandleChange = (Id) => {
    let data = {
      partnerId: Id,
    };
    axios
      .post(FETCH_PARTNER_TAXPYMT_DETAILS, data, { withCredentials: true })
      .then((res) => {
        this.setState({
          gstin: res.data.length !== 0 && res.data[0].gst,
          pan: res.data.length !== 0 && res.data[0].panNo,
          tin: res.data.length !== 0 && res.data[0].tinNo,
        });
      });
    axios
      .post(FETCH_PARTNER_BANK_ACC, data, { withCredentials: true })
      .then((res) => {
        this.setState({
          bankName: res.data.length !== 0 && res.data[0].bankName,
          bankIfscCode: res.data.length !== 0 && res.data[0].ifscCode,
          bankAccountName: res.data.length !== 0 && res.data[0].accountName,
          bankAccountNumber: res.data.length !== 0 && res.data[0].accountNumber,
        });
      });
  };

  vendorNameHandleChange = (Id) => {
    let data = {
      vendorId: Id,
    };
    axios
      .post(FETCH_VENDOR_TAXPYMT_DETAILS, data, { withCredentials: true })
      .then((res) => {
        console.log(res.data.length !== 0 && res.data[0].tinNo);
        this.setState({
          gstin: res.data.length !== 0 && res.data[0].gst,
          pan: res.data.length !== 0 && res.data[0].panNo,
          tin: res.data.length !== 0 && res.data[0].tinNo,
        });
      });
    axios
      .post(FETCH_VENDOR_BANK_ACC, data, { withCredentials: true })
      .then((res) => {
        this.setState({
          bankName: res.data.length !== 0 && res.data[0].bankName,
          bankIfscCode: res.data.length !== 0 && res.data[0].ifscCode,
          bankAccountName: res.data.length !== 0 && res.data[0].accountName,
          bankAccountNumber: res.data.length !== 0 && res.data[0].accountNumber,
        });
      });
  };

  componentDidMount() {
    this.props.getCurrency();
    this.props.getInvoiceType();
    this.props.getIssuePOs();
  }

  // componentDidUpdate function we will get the vendor and partners details based on the invoice type selection
  componentDidUpdate(prevProps, prevState) {
    if (prevState.poNo !== this.state.poNo) {
      this.props.issuePOs.data.map((item) => {
        if (item.poNo.toString() === this.state.poNo) {
          console.log(item.poTotalAmount);
          this.setState({
            poType: item.poType,
            poAmount: item.poTotalAmount,
            vpsName: item.vpsName,
            address: item.vpsBillingAddress,
            contactName: item.contactName,
            contactEmail: item.contactEmail,
            contactPhoneNumber: item.contactMobileNo,
            shipToAddress: item.shipToAddress,
            shipToContactNo: item.shipToContactMobileNo,
            paymentTerms: item.pymtTerm,
            paymentInstructions: item.pymtInstructions,
          });
          if (item.poTo === "Partner") {
            this.props.getPartners();
            let partnerId;
            this.props.partners.data.map((item1) => {
              if (item1.partnerFullName === item.vpsName) {
                partnerId = item1.partnerId;
              }
            });
            this.partnerNameHandleChange(partnerId);
          }
          if (item.poTo === "Vendor") {
            this.props.getVendors();
            let vendorId;
            this.props.vendors.data.map((item2) => {
              if (item2.vendorFullName === item.vpsName) {
                vendorId = item2.vendorId;
              }
            });
            this.vendorNameHandleChange(vendorId);
          }
        }
      });
    }
    if (
      prevProps.invoices.latestInvoiceData !==
      this.props.invoices.latestInvoiceData
    ) {
      this.setState({
        toggleCreateInvoice: false,
        purchaseId: this.props.invoices.latestInvoiceData[0].purchaseId,
      });
    }
    if (prevState.amount !== this.state.amount) {
      this.setState({
        amountGST: (this.state.amount * 0.18).toFixed(2),
      });
    }
    if (
      prevProps.invoices.recieveItemData !== this.props.invoices.recieveItemData
    ) {
      if (this.props.invoices.recieveItemData.length !== 0) {
        let arr = this.props.invoices.recieveItemData;
        let sumAmount = 0;
        let sumAmountGst = 0;
        arr.forEach(function (obj) {
          sumAmount += obj.amount * obj.qty;
          sumAmountGst += obj.gstAmount * obj.qty;
        });
        this.setState({
          invoiceAmount: sumAmount,
          invoiceGst: sumAmountGst.toFixed(2),
        });
      }
    }
  }

  // onSubmit function to submit the form
  onSubmit(e) {
    console.log(this.state.poAmount, this.state.tin);
    e.preventDefault();
    submitValidator(e.target);
    if (valid) {
      let data = {
        poNo: this.state.poNo,
        poName: this.state.poName,
        poAmount: this.state.poAmount,
        invoiceNo: this.state.invoiceNumber,
        invoiceDate: this.state.invoiceDate,
        invoiceType: this.state.invoiceType,
        vpsName: this.state.vpsName,
        vpsAddress: this.state.address,
        contactName: this.state.contactName,
        contactEmail: this.state.contactEmail,
        contactMobileNo: this.state.contactPhoneNumber,
        shipToAddress: this.state.shipToAddress,
        shipToContactMobileNo: this.state.shipToContactNo,
        pan: this.state.pan,
        tin: this.state.tin,
        gst: this.state.gstin,
        bankName: this.state.bankName,
        bankIfscCode: this.state.bankIfscCode,
        bankAccName: this.state.bankAccountName,
        bankAccNo: this.state.bankAccountNumber,
        invoiceCurrency: this.state.invoiceCurrency,
        // pymtTerm: this.state.paymentTerms,
        // pymtInstructions: this.state.paymentInstructions,
        createdBy: localStorage.getItem("id"),
      };

      this.props.addInvoice(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all Mandatory Fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  recieveItemToggle = () => {
    this.setState({
      recieveItemModal: !this.state.recieveItemModal,
    });
    refresh();
    this.receiveItemReset();
    let data1 = {
      purchaseId: this.state.purchaseId,
    };
    this.props.getRecieveItems(data1);
  };

  receiveItemReset = () => {
    this.setState({
      purchaseItemNo: "",
      pspName: "",
      quantity: "",
      amount: "",
      amountGST: "",
    });
  };

  // recieveItemSubmit function for submitting the form details
  recieveItemSubmit = (e) => {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      purchaseId: this.state.purchaseId,
      // poNo: this.state.poNo,
      purchaseItemNo: this.state.purchaseItemNo,
      ppsName: this.state.pspName,
      qty: this.state.quantity,
      amount: this.state.amount,
      gstAmount: this.state.amountGST,
    };
    if (valid) {
      this.props.addRecieveItem(data, this.recieveItemToggle);
    } else {
      this.setState({
        error: "Enter all Mandatory Fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  };

  // updateInvoice function to update the totalAmount, totalGst and poTotalAmount in InvoiceTable
  updateInvoice = (invoiceTotalAmount) => {
    let data = {
      purchaseId: this.state.purchaseId,
      invoiceAmount: this.state.invoiceAmount,
      invoiceTotalAmount: invoiceTotalAmount,
      invoiceGst: this.state.invoiceGst,
      updatedBy: localStorage.getItem("id"),
    };
    this.props.addUpdateInvoice(data);
  };

  render() {
    const {
      poNo,
      poName,
      poNoName,
      poType,
      poAmount,
      invoiceNumber,
      invoiceDate,
      invoiceType,
      vpsName,
      address,
      contactName,
      contactEmail,
      contactPhoneNumber,
      shipToAddress,
      shipToContactNo,
      gstin,
      pan,
      tin,
      bankName,
      bankIfscCode,
      bankAccountName,
      bankAccountNumber,
      invoiceCurrency,
      invoiceAmount,
      invoiceGst,
      paymentTerms,
      paymentInstructions,
      recieveItemModal,
      purchaseItemNo,
      pspName,
      quantity,
      amount,
      amountGST,
      toggleCreateInvoice,
      error,
    } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 mx-auto">
            <Container className="card container-card">
              <h5 className="center bold pt-2">New Invoice</h5>
              <i
                className="material-icons pointer cancel-button"
                onClick={this.props.back}
              >
                cancel
              </i>
              <Row>
                {toggleCreateInvoice ? (
                  <Col md="8" className="mx-auto">
                    <Form onSubmit={this.onSubmit} className="p-3" noValidate>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label className="label-sm bold">PO No</Label>
                            <Input
                              type="select"
                              name="poNo"
                              value={poNoName}
                              className="form-control-sm"
                              onChange={this.onChange}
                            >
                              <option value="" hidden>
                                Select
                              </option>
                              {this.props.issuePOs.data.map((item) => {
                                return (
                                  <option
                                    key={item.poNo}
                                    value={`${item.poNo}, ${item.poName}`}
                                  >
                                    {item.poNo}-{item.poName}
                                  </option>
                                );
                              })}
                            </Input>
                            {errors.poNo && (
                              <FormText className="error">
                                {errors.poNo}
                              </FormText>
                            )}
                          </FormGroup>
                          <FormComponent
                            label="PO Type"
                            labelClass="label-sm bold"
                            type="text"
                            name="poType"
                            value={poType}
                            change={this.onChange}
                            className="form-control-sm"
                            required={!poNo ? false : true}
                            disabled={!poNo ? false : true}
                          />
                          <FormComponent
                            label="PO Amount"
                            labelClass="label-sm bold"
                            type="number"
                            name="poAmount"
                            value={poAmount}
                            change={this.onChange}
                            className="form-control-sm"
                            required={!poNo ? false : true}
                            disabled={!poNo ? false : true}
                          />
                          <FormComponent
                            label="Invoice Number"
                            labelClass="label-sm bold"
                            type="number"
                            name="invoiceNumber"
                            value={invoiceNumber}
                            change={this.onChange}
                            className="form-control-sm"
                            required={true}
                          />
                          <FormComponent
                            label="Invoice Date"
                            labelClass="label-sm bold"
                            type="date"
                            name="invoiceDate"
                            value={invoiceDate}
                            className="form-control-sm"
                            change={this.onChange}
                            required={true}
                          />
                          <FormGroup>
                            <Label className="label-sm bold">
                              Invoice Type
                            </Label>
                            <Input
                              type="select"
                              name="invoiceType"
                              value={invoiceType}
                              className="form-control-sm"
                              onChange={this.onChange}
                              required={true}
                            >
                              <option value="" hidden>
                                Select
                              </option>
                              {this.props.invoiceTypes.data.map((item) => {
                                return (
                                  <option
                                    key={item.invoiceTypeId}
                                    value={item.invoiceType}
                                  >
                                    {item.invoiceType}
                                  </option>
                                );
                              })}
                            </Input>
                            {errors.invoiceType && (
                              <FormText className="error">
                                {errors.invoiceType}
                              </FormText>
                            )}
                          </FormGroup>
                          <FormComponent
                            label="Partner / Vendor Name"
                            labelClass="label-sm bold"
                            type="text"
                            name="vpsName"
                            value={vpsName}
                            className="form-control-sm"
                            change={this.onChange}
                            required={!poNo ? false : true}
                            disabled={!poNo ? false : true}
                          />
                          {/* <FormGroup>
                          <Label className="label-sm bold">
                            Partner / Vendor Name
                          </Label>
                          {invoiceFrom ? (
                            invoiceFrom === "Partner" ? (
                              <Select
                                name="partnerNameOptions"
                                value={partnerNameSelectedOption}
                                onChange={this.partnerNameHandleChange}
                                options={partnerNameOptions}
                                className="select-form-control-sm"
                                required
                              />
                            ) : (
                              <Select
                                name="vendorNameOptions"
                                value={vendorNameSelectedOption}
                                onChange={this.vendorNameHandleChange}
                                options={vendorNameOptions}
                                className="select-form-control-sm"
                                required
                              />
                            )
                          ) : (
                            <Select
                              name="partnerNameOptions"
                              className="select-form-control-sm"
                              required
                            />
                          )}
                          {partnerNameSelectedOptionError && (
                            <FormText className="error ">
                              {partnerNameSelectedOptionError}
                            </FormText>
                          )}
                          {vendorNameSelectedOptionError && (
                            <FormText className="error ">
                              {vendorNameSelectedOptionError}
                            </FormText>
                          )}
                        </FormGroup> */}
                          <FormComponent
                            label="Partner / Vendor Address"
                            labelClass="label-sm bold"
                            type="text"
                            name="address"
                            value={address}
                            change={this.onChange}
                            className="form-control-sm"
                            required={!poNo ? false : true}
                            disabled={!poNo ? false : true}
                          />
                          <FormComponent
                            label="Contact Name"
                            labelClass="label-sm bold"
                            type="text"
                            name="contactName"
                            value={contactName}
                            change={this.onChange}
                            className="form-control-sm"
                            required={!poNo ? false : true}
                            disabled={!poNo ? false : true}
                          />
                          <FormComponent
                            label="Contact Email"
                            labelClass="label-sm bold"
                            type="text"
                            name="conatactEmail"
                            value={contactEmail}
                            change={this.onChange}
                            className="form-control-sm"
                            required={!poNo ? false : true}
                            disabled={!poNo ? false : true}
                          />
                          <FormComponent
                            label="Contact Phone Number"
                            labelClass="label-sm bold"
                            type="tel"
                            name="contactPhoneNumber"
                            value={contactPhoneNumber}
                            change={this.onChange}
                            className="form-control-sm"
                            required={!poNo ? false : true}
                            disabled={!poNo ? false : true}
                          />
                        </Col>
                        <Col md="6">
                          <FormComponent
                            label="Ship To Address"
                            labelClass="label-sm bold"
                            type="text"
                            name="shipToAddress"
                            value={shipToAddress}
                            change={this.onChange}
                            className="form-control-sm"
                            required={!poNo ? false : true}
                            disabled={!poNo ? false : true}
                          />
                          <FormComponent
                            label="Ship To Contact Number"
                            labelClass="label-sm bold"
                            type="tel"
                            name="shipToContactNo"
                            value={shipToContactNo}
                            change={this.onChange}
                            className="form-control-sm"
                            required={!poNo ? false : true}
                            disabled={!poNo ? false : true}
                          />
                          <FormComponent
                            label="GSTIN"
                            labelClass="label-sm bold"
                            type="text"
                            name="gstin"
                            value={gstin}
                            change={this.onChange}
                            className="form-control-sm"
                            required={!poNo ? false : true}
                            disabled={!poNo ? false : true}
                          />
                          <FormComponent
                            label="PAN"
                            labelClass="label-sm bold"
                            type="text"
                            name="pan"
                            value={pan}
                            change={this.onChange}
                            className="form-control-sm"
                            required={!poNo ? false : true}
                            disabled={!poNo ? false : true}
                          />
                          <FormComponent
                            label="TIN"
                            labelClass="label-sm bold"
                            type="text"
                            name="tin"
                            value={tin}
                            change={this.onChange}
                            className="form-control-sm"
                            required={!poNo ? false : true}
                            disabled={!poNo ? false : true}
                          />
                          <FormComponent
                            label="Bank Name"
                            labelClass="label-sm bold"
                            type="text"
                            name="bankName"
                            value={bankName}
                            change={this.onChange}
                            className="form-control-sm"
                            required={!poNo ? false : true}
                            disabled={!poNo ? false : true}
                          />
                          <FormComponent
                            label="Bank IFSC Code"
                            labelClass="label-sm bold"
                            type="text"
                            name="bankIfscCode"
                            value={bankIfscCode}
                            change={this.onChange}
                            className="form-control-sm"
                            required={!poNo ? false : true}
                            disabled={!poNo ? false : true}
                          />
                          <FormComponent
                            label="Bank Account Name"
                            labelClass="label-sm bold"
                            type="text"
                            name="bankAccountName"
                            value={bankAccountName}
                            change={this.onChange}
                            className="form-control-sm"
                            required={!poNo ? false : true}
                            disabled={!poNo ? false : true}
                          />
                          <FormComponent
                            label="Bank Account Number"
                            labelClass="label-sm bold"
                            type="number"
                            name="bankAccountNumber"
                            value={bankAccountNumber}
                            change={this.onChange}
                            className="form-control-sm"
                            required={!poNo ? false : true}
                            disabled={!poNo ? false : true}
                          />
                          <FormGroup>
                            <Label className="bold label-sm">
                              Invoice Currency
                            </Label>
                            <Input
                              type="select"
                              name="invoiceCurrency"
                              className="form-control-sm "
                              value={invoiceCurrency}
                              onChange={this.onChange}
                              required={true}
                            >
                              <option value="" hidden>
                                Select...
                              </option>
                              {this.props.currency.data.map((item) => {
                                return (
                                  <option
                                    key={item.currencyId}
                                    value={item.currencyCode}
                                  >
                                    {item.currencyCode}
                                  </option>
                                );
                              })}
                            </Input>
                            {errors.invoiceCurrency && (
                              <FormText className="error">
                                {errors.invoiceCurrency}
                              </FormText>
                            )}
                          </FormGroup>
                          {/* <FormComponent
                            label="Payment Terms"
                            labelClass="label-sm bold"
                            type="text"
                            name="paymentTerms"
                            value={paymentTerms}
                            className="form-control-sm"
                            required={true}
                            disabled={true}
                          />
                          <FormComponent
                            label="Payment Instructions"
                            labelClass="label-sm bold"
                            type="text"
                            name="paymentInstructions"
                            value={paymentInstructions}
                            className="form-control-sm"
                            required={true}
                            disabled={true}
                          /> */}
                        </Col>
                      </Row>
                      <div className="center">
                        <button
                          type="submit"
                          className="btn center login-button white-text"
                        >
                          Add
                        </button>
                      </div>
                    </Form>
                  </Col>
                ) : (
                  <div>
                    <Col md="6">
                      <h6>Invoice Details</h6>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          PO No
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.invoices.latestInvoiceData[0] &&
                            this.props.invoices.latestInvoiceData[0].poNo}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          PO Name
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.invoices.latestInvoiceData[0] &&
                            this.props.invoices.latestInvoiceData[0].poName}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          PO Amount
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.invoices.latestInvoiceData[0] &&
                            this.props.invoices.latestInvoiceData[0].poAmount}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Invoice No
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.invoices.latestInvoiceData[0] &&
                            this.props.invoices.latestInvoiceData[0].invoiceNo}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Invoice Date
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.invoices.latestInvoiceData[0] &&
                            DateFns.format(
                              this.props.invoices.latestInvoiceData[0]
                                .invoiceDate,
                              "DD-MM-YYYY"
                            )}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Inoice Type
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.invoices.latestInvoiceData[0] &&
                            this.props.invoices.latestInvoiceData[0]
                              .invoiceType}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Name
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.invoices.latestInvoiceData[0] &&
                            this.props.invoices.latestInvoiceData[0].vpsName}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Address
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.invoices.latestInvoiceData[0] &&
                            this.props.invoices.latestInvoiceData[0].vpsAddress}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Contact Name
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.invoices.latestInvoiceData[0] &&
                            this.props.invoices.latestInvoiceData[0]
                              .contactName}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Contact Email
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.invoices.latestInvoiceData[0] &&
                            this.props.invoices.latestInvoiceData[0]
                              .contactEmail}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Contact No
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.invoices.latestInvoiceData[0] &&
                            this.props.invoices.latestInvoiceData[0]
                              .contactMobileNo}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Ship Address
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.invoices.latestInvoiceData[0] &&
                            this.props.invoices.latestInvoiceData[0]
                              .shipToAddress}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Contact Number
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.invoices.latestInvoiceData[0] &&
                            this.props.invoices.latestInvoiceData[0]
                              .shipToContactMobileNo}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          GST
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.invoices.latestInvoiceData[0] &&
                            this.props.invoices.latestInvoiceData[0].gst}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          PAN
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.invoices.latestInvoiceData[0] &&
                            this.props.invoices.latestInvoiceData[0].pan}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          TIN
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.invoices.latestInvoiceData[0] &&
                            this.props.invoices.latestInvoiceData[0].tin}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Bank Name
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.invoices.latestInvoiceData[0] &&
                            this.props.invoices.latestInvoiceData[0].bankName}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Bank IFSC
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.invoices.latestInvoiceData[0] &&
                            this.props.invoices.latestInvoiceData[0]
                              .bankIfscCode}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Bank Account Name
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.invoices.latestInvoiceData[0] &&
                            this.props.invoices.latestInvoiceData[0]
                              .bankAccName}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Bank Account No
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.invoices.latestInvoiceData[0] &&
                            this.props.invoices.latestInvoiceData[0].bankAccNo}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Invoice Currency
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.invoices.latestInvoiceData[0] &&
                            this.props.invoices.latestInvoiceData[0]
                              .invoiceCurrency}
                        </div>
                      </div>
                      {/* <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Payment Terms
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.invoices.latestInvoiceData[0] &&
                            this.props.invoices.latestInvoiceData[0].pymtTerm}
                        </div>
                      </div>
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Payment Instructions
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.invoices.latestInvoiceData[0] &&
                            this.props.invoices.latestInvoiceData[0]
                              .pymtInstructions}
                        </div>
                      </div> */}
                      <div className="details-flex-main-div">
                        <div className="purchaseInvoice-details-flex-div-one">
                          Invoice Status
                        </div>
                        <div className="details-flex-div-two">:</div>
                        <div className="details-flex-div-three">
                          {this.props.invoices.latestInvoiceData[0] &&
                            this.props.invoices.latestInvoiceData[0]
                              .invoiceStatus}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <Row className="milestone-row">
                        <div className="milestone-div">
                          <h6 className="center">Received Items</h6>
                          <button
                            className="btn milestone-add-btn white-text label-sm"
                            onClick={this.recieveItemToggle}
                          >
                            Add
                          </button>
                          <Table>
                            <thead>
                              <tr>
                                <th className="opp-head">Name</th>
                                <th className="opp-head">Qty</th>
                                <th className="opp-head">Amount</th>
                                <th className="opp-head">GST</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.props.invoices.recieveItemData.map(
                                (item) => {
                                  return (
                                    <tr key={item.recieveItemsId}>
                                      <td>{item.ppsName}</td>
                                      <td>{item.qty}</td>
                                      <td>{item.amount}</td>
                                      <td>{item.gstAmount}</td>
                                    </tr>
                                  );
                                }
                              )}
                              {this.props.invoices.recieveItemData.length !==
                                0 && (
                                <tr>
                                  <td>Total Amount:</td>
                                  <td>{invoiceAmount}</td>
                                  <td>Total GST:</td>
                                  <td>{invoiceGst}</td>
                                </tr>
                              )}
                              {this.props.invoices.recieveItemData.length !==
                                0 && (
                                <tr>
                                  <td></td>
                                  <td>
                                    <button
                                      className="btn center login-button white-text"
                                      onClick={() =>
                                        this.updateInvoice(
                                          this.state.invoiceAmount +
                                            parseFloat(this.state.invoiceGst)
                                        )
                                      }
                                    >
                                      Update
                                    </button>
                                  </td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </Row>
                    </Col>
                  </div>
                )}
              </Row>
              <Modal isOpen={recieveItemModal} size="md">
                <ModalHeader toggle={this.recieveItemToggle}>
                  Received Items
                </ModalHeader>
                <ModalBody>
                  <Form onSubmit={this.recieveItemSubmit} noValidate>
                    <Row>
                      <Col md="6" className="mx-auto">
                        <FormComponent
                          label="Purchase Item Number"
                          labelClass="label-sm  bold"
                          className="form-control-sm"
                          type="number"
                          name="purchaseItemNo"
                          value={purchaseItemNo}
                          change={this.onChange}
                          required={true}
                        />
                        <FormComponent
                          label="Product/Service/Project Name"
                          labelClass="label-sm  bold"
                          className="form-control-sm"
                          type="text"
                          name="pspName"
                          value={pspName}
                          change={this.onChange}
                          required={true}
                        />
                        <FormComponent
                          label="Quantity"
                          labelClass="label-sm  bold"
                          className="form-control-sm"
                          type="number"
                          name="quantity"
                          value={quantity}
                          change={this.onChange}
                          required={true}
                        />
                        <FormComponent
                          label="Amount"
                          labelClass="label-sm  bold"
                          className="form-control-sm"
                          type="number"
                          name="amount"
                          value={amount}
                          change={this.onChange}
                          required={true}
                        />
                        <FormComponent
                          label="Amount GST"
                          labelClass="label-sm  bold"
                          className="form-control-sm"
                          type="number"
                          name="amountGST"
                          value={amountGST}
                          change={this.onChange}
                          required={true}
                          disabled={true}
                        />
                      </Col>
                    </Row>
                    <div className="center">
                      <button
                        type="submit"
                        className="btn center login-button white-text"
                      >
                        Add
                      </button>
                    </div>
                  </Form>
                </ModalBody>
              </Modal>
            </Container>
            {error && <ErrorNotifier message={error} />}
            {this.props.invoices.message && (
              <Notifier message={this.props.invoices.message} />
            )}
            {this.props.invoices.error && (
              <ErrorNotifier message={this.props.invoices.error} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

AddInvoice.propTypes = {
  getPartners: PropTypes.func.isRequired,
  partners: PropTypes.object.isRequired,
  getVendors: PropTypes.func.isRequired,
  vendors: PropTypes.object.isRequired,
  addInvoice: PropTypes.func.isRequired,
  addUpdateInvoice: PropTypes.func.isRequired,
  addRecieveItem: PropTypes.func.isRequired,
  getRecieveItems: PropTypes.func.isRequired,
  invoices: PropTypes.object.isRequired,
  getCurrency: PropTypes.func.isRequired,
  currency: PropTypes.object.isRequired,
  getInvoiceType: PropTypes.func.isRequired,
  invoiceTypes: PropTypes.object.isRequired,
  getIssuePOs: PropTypes.func.isRequired,
  issuePOs: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  partners: state.partners,
  vendors: state.vendors,
  invoices: state.invoices,
  currency: state.currency,
  invoiceTypes: state.invoiceTypes,
  issuePOs: state.issuePOs,
});

export default connect(mapStateToProps, {
  getPartners,
  getVendors,
  addInvoice,
  addUpdateInvoice,
  addRecieveItem,
  getRecieveItems,
  getCurrency,
  getInvoiceType,
  getIssuePOs,
})(AddInvoice);
