import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, FormText } from "reactstrap";
import { addIdea } from "../../../../redux/actions/ideaActions";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
} from "../../../common/Validator";
import FormComponent from "../../../common/FormComponent";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import { getIdeaStatus } from "../../../../redux/actions/ideaStatusAction";

class NewIdea extends Component {
  constructor() {
    super();
    this.state = {
      businessIdea: "",
      statement: "",
      useCase: "",
      solution: "",
      benefits: "",
      targets: "",
      status: "New",
      remark: "",
      attatchment: "",
      createdOn: "",
      attFile: null,
      buttonValue: false,
      message: null,
      error: null,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    e.preventDefault();
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      creatorId: localStorage.getItem("id"),
      businessDesc: this.state.businessIdea,
      problemStmt: this.state.statement,
      useCase: this.state.useCase,
      solution: this.state.solution,
      benefits: this.state.benefits,
      customers: this.state.targets,
      status: this.state.status,
      remarks: this.state.remark,
    };
    if (valid) {
      this.props.addIdea(data, this.props.history);
      this.setState({
        buttonValue: !this.state.buttonValue,
      });
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidMount function contains the refresh method for the refreshing the erros data
*/
  componentDidMount() {
    refresh();
    this.props.getIdeaStatus();
  }

  render() {
    let shrink;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");

    return (
      <div className={shrink}>
        <h5 className="center bold">New Idea</h5>
        <Container className="card container-card">
          <Link to="/pie/ideas">
            <i className="material-icons pointer cancel-button">cancel</i>
          </Link>
          <Form onSubmit={this.onSubmit} className="p-3" noValidate>
            <Row>
              <Col md="4">
                <FormComponent
                  labelClass="bold label-sm"
                  label="Business idea description"
                  type="text"
                  name="businessIdea"
                  value={this.state.businessIdea}
                  change={this.onChange}
                  placeholder="Description"
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Problem Statement"
                  type="text"
                  name="statement"
                  value={this.state.statement}
                  change={this.onChange}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Use cases"
                  type="text"
                  name="useCase"
                  value={this.state.useCase}
                  change={this.onChange}
                  required={true}
                />
              </Col>
              <Col md="4">
                <FormComponent
                  labelClass="bold label-sm"
                  label="Solution"
                  type="text"
                  name="solution"
                  value={this.state.solution}
                  change={this.onChange}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Targeted Customers"
                  type="text"
                  name="targets"
                  value={this.state.targets}
                  change={this.onChange}
                  required={true}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Benefits"
                  type="text"
                  name="benefits"
                  value={this.state.benefits}
                  change={this.onChange}
                  required={true}
                />
              </Col>
              <Col md="4">
                <FormComponent
                  labelClass="bold label-sm"
                  label="Remarks"
                  type="textarea"
                  name="remark"
                  value={this.state.remark}
                  change={this.onChange}
                  required={true}
                />
              </Col>
            </Row>
            <div className="center">
              <button
                type="submit"
                className="btn mr-5 center login-button white-text"
                disabled={this.state.buttonValue ? "disabled" : null}
              >
                Add
              </button>
              <FormText className="error">{this.props.errors.error}</FormText>
            </div>
          </Form>
        </Container>
        {this.props.idea.message ? (
          <Notifier message={this.props.idea.message} />
        ) : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
      </div>
    );
  }
}

NewIdea.propTypes = {
  addIdea: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  getIdeaStatus: PropTypes.func.isRequired,
  ideaStatus: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  idea: state.idea,
  ideaStatus: state.ideaStatus,
});

export default connect(mapStateToProps, { addIdea, getIdeaStatus })(NewIdea);
