import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  iconBtn: {
    padding: "0.125rem",
    marginLeft:"1rem"
  },
  icon: {
    color: "#0056b3",
    fontSize: "1.25rem",
  },
}));
