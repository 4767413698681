import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import dateFns from "date-fns";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  FormText,
} from "reactstrap";
// material-ui
import { Divider } from "@material-ui/core";
// components
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import {
  Validator,
  valid,
  refresh,
  submitValidator,
  errors,
} from "../../../common/Validator";
import EmployeeInfo from "./EmployeeInfo";
import DetailsPageFooter from "../../../common/details_footer/DetailsPageFooter";
// redux
import { updateEmployee } from "../../../../redux/actions/iam/emp_onboarding/empOnboardingAction";
import { getBusinessGeographies } from "../../../../redux/actions/busiGeographiesAction";
import { getBusinessGeographies1 } from "../../../../redux/actions/businessGeographiesAction";
import { getUserBusinessLocations } from "../../../../redux/actions/hcm/business_setup/businessLocationsAction";
import { getEmployeeGrade } from "../../../../redux/actions/empGradeAction";
import { getUserOrganization } from "../../../../redux/actions/hcm/business_setup/organizationAction";
import { getBusinessUnit } from "../../../../redux/actions/busiUnitAction";
import { getEmployeeDesignation } from "../../../../redux/actions/empDesignationAction";
import { getUserEmpType } from "../../../../redux/actions/hcm/configurations/empTypeAction";

// ----------------------------------------------------------------------------------------------------
class EmployeeDetails extends Component {
  constructor(props) {
    super(props);
    const {
      id,
      empNo,
      firstName,
      lastName,
      geoName,
      countryName,
      mobileNumber,
      personalEmailId,
      organizationEmailId,
      organizationName,
      empPosition,
      empStatus,
    } = this.props.employee;
    this.state = {
      id: id,
      empNo: empNo,
      firstName: firstName,
      lastName: lastName,
      geoName,
      country: countryName,
      mobile: mobileNumber,
      personalEmail: personalEmailId,
      email: organizationEmailId,
      organizationName,
      empPosition,
      status: empStatus,
      modal: false,
      action: "",
      reason: "",
      effectiveDate: "",
      startDate: "",
      remarks: "",
      edit: false,
      employeeInfo: false,
      message: "",
      error: "",
      formErrors: {
        action: "",
      },
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.employeeInfoToggle = this.employeeInfoToggle.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.auth !== this.props.auth) {
      this.setState({
        permissionlist: this.props.auth.permisssionlist,
      });
    }
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  /*
This function update the employee details
body as the employee details
*/

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let emp = {
      id: this.state.id,
      empId: this.state.empId,
      firstName: this.state.firstName,
      middleName: this.state.middleName,
      lastName: this.state.lastName,
      dob: this.state.dob,
      countryName: this.state.country,
      locationName: this.state.location,
      genderType: this.state.gender,
      bloodGroup: this.state.bloodGroup,
      empType: this.state.empType,
      mobileNumber: this.state.mobile,
      personalEmailId: this.state.personalEmail,
      dateOfJoin: this.state.created,
      grade: this.state.grade,
      empStatus: this.state.status,
      organizationEmailId: this.state.email,
      updatedBy: localStorage.getItem("id"),
    };

    if (valid) {
      this.props.updateEmployee(emp, this.props.back);
    } else {
      let errors = this.state.formErrors;
      errors.action = "Please select the option";
      this.setState({
        error: "Enter all mandatory fields",
        formErrors: errors,
      });
      setTimeout(() => {
        this.setState({
          message: "",
          error: "",
        });
      }, 2000);
    }
  }

  /*
toggle function to set state for modle toggling
*/
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  componentDidMount() {
    refresh();
  }

  employeeInfoToggle() {
    this.setState({
      employeeInfo: !this.state.employeeInfo,
    });
  }

  render() {
    const { empType, employeeInfo } = this.state;
    const { action } = this.state.formErrors;
    let currentDate = dateFns.format(new Date(), "YYYY-MM-D");

    return (
      <Row>
        {!employeeInfo ? (
          <React.Fragment>
            <i
              onClick={this.props.back}
              className="material-icons pointer cancel-button"
            >
              cancel
            </i>

            {!this.state.edit ? (
              <Col md="12">
                {/* {this.props.locationData.updation && (
                  <div className="div"> */}
                {/* <i
                      className="material-icons  edit-button pointer "
                      onClick={() => this.setState({ edit: !this.state.edit })}
                    >
                      edit
                    </i> */}
                {/* </div>
                )} */}
                <h5 className="bold pointer center pt-2">User Details</h5>
                <Row>
                  <Col md="6">
                    <ul className="CustDetails-ul">
                      <li>
                        <span className="label-sm bold emp-span-label-col-1">
                          Emp Number
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.empNo}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-1">
                          First Name
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.firstName}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-1">
                          Last Name
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.lastName}
                        </span>
                      </li>

                      <li>
                        <span className="label-sm bold emp-span-label-col-1">
                          Geography
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.geoName}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-1">
                          Country
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.country}
                        </span>
                      </li>
                    </ul>
                  </Col>
                  <Col md="6" className="col-reset">
                    <ul className="CustDetails-ul">
                      <li>
                        <span className="label-sm bold emp-span-label-col-3">
                          Mobile No.
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.mobile}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-3">
                          Personal Email
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.personalEmail}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-3">
                          Email
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.email}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-3">
                          Organization Name
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.organizationName}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-3">
                          Emp Position
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.empPosition}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-3">
                          Status
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.status}
                        </span>
                      </li>
                      {/* <li>
                        <span className="label-sm bold emp-span-label-col-3">
                          Created By
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;
                          {this.props.employee.empCreatorId}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-3">
                          Created On
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;
                          {dateFns.format(
                            this.props.employee.createdOn,
                            "DD-MM-YYYY"
                          )}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-3">
                          Updated By
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;
                          {this.props.employee.updatedBy}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold emp-span-label-col-3">
                          Updated On
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;
                          {dateFns.format(
                            this.props.employee.updatedOn,
                            "DD-MM-YYYY"
                          )}
                        </span>
                      </li> */}
                    </ul>
                  </Col>
                </Row>
                <Divider />
                <DetailsPageFooter
                  createdBy={this.props.employee.createdBy}
                  createdOn={this.props.employee.createdOn}
                  updatedBy={this.props.employee.updatedBy}
                  updatedOn={this.props.employee.updatedOn}
                />
              </Col>
            ) : (
              <Col md="12">
                <h5 className="bold pointer center pt-2">Update User</h5>
                <Form onSubmit={this.onSubmit} className="p-3" noValidate>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <Label className="bold label-sm">First Name</Label>
                        <Input
                          type="text"
                          name="firstName"
                          className="form-control-sm"
                          value={this.state.firstName}
                          onChange={this.onChange}
                          onBlur={this.onBlur}
                          placeholder="First Name (required)"
                          required={true}
                        />
                        {errors.firstName && (
                          <FormText className="error">
                            {errors.firstName}
                          </FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold label-sm">Middle Name</Label>
                        <Input
                          type="text"
                          name="middleName"
                          className="form-control-sm"
                          value={this.state.middleName}
                          onChange={this.onChange}
                          placeholder="Middle name "
                          onBlur={this.onBlur}
                        />
                        {errors.middleName && (
                          <FormText className="error">
                            {errors.middleName}
                          </FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold label-sm">Last Name</Label>
                        <Input
                          type="text"
                          name="lastName"
                          className="form-control-sm"
                          value={this.state.lastName}
                          onChange={this.onChange}
                          onBlur={this.onBlur}
                          placeholder="Last name (required)"
                          required={true}
                        />
                        {errors.lastName && (
                          <FormText className="error">
                            {errors.lastName}
                          </FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold label-sm">Date of Birth</Label>
                        <Input
                          type="date"
                          name="dob"
                          className="form-control-sm"
                          value={dateFns.format(this.state.dob, "YYYY-MM-DD")}
                          onChange={this.onChange}
                          placeholder="Date of birth (required)"
                          required={true}
                        />
                        {errors.dob && (
                          <FormText className="error">{errors.dob}</FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold label-sm">Gender</Label>
                        <Input
                          type="select"
                          name="gender"
                          className="form-control-sm"
                          value={this.state.gender}
                          onChange={this.onChange}
                          required={true}
                        >
                          <option value="" hidden>
                            Select...
                          </option>
                          <option value="Female">Female</option>
                          <option value="Male">Male</option>
                          <option value="Other">Other</option>
                        </Input>
                        {errors.gender && (
                          <FormText className="error">{errors.gender}</FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Label className="bold label-sm">Blood Group</Label>
                        <Input
                          type="select"
                          name="bloodGroup"
                          className="form-control-sm"
                          value={this.state.bloodGroup}
                          onChange={this.onChange}
                          required={true}
                        >
                          <option value="O +ive">O +ive</option>
                          <option value="O -ive">O -ive</option>
                          <option value="A +ive">A +ive</option>
                          <option value="A -ive">A -ive</option>
                          <option value="B +ive">B +ive</option>
                          <option value="B -ive">B -ive</option>
                          <option value="AB +ive">AB +ive</option>
                          <option value="AB -ive">AB -ive</option>
                        </Input>
                        {errors.bloodGroup && (
                          <FormText className="error">
                            {errors.bloodGroup}
                          </FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold label-sm">Employee Type</Label>
                        <Input
                          type="text"
                          name="empType"
                          className="form-control-sm"
                          value={this.state.empType}
                          onChange={this.onChange}
                          required={true}
                        ></Input>
                      </FormGroup>

                      <FormGroup>
                        <Label className="bold label-sm">Country</Label>
                        <Input
                          type="text"
                          name="country"
                          className="form-control-sm"
                          value={this.state.country}
                          onChange={this.onChange}
                          required={true}
                        ></Input>
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold label-sm">Work Location</Label>
                        <Input
                          type="text"
                          name="location"
                          className="form-control-sm"
                          value={this.state.location}
                          onChange={this.onChange}
                          required={true}
                        ></Input>
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold label-sm">Date of Join</Label>
                        <Input
                          type="date"
                          name="created"
                          className="form-control-sm"
                          value={dateFns.format(
                            this.state.created,
                            "YYYY-MM-DD"
                          )}
                          onChange={this.onChange}
                          required={true}
                        />
                        {errors.created && (
                          <FormText className="error">
                            {errors.created}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Label className="bold label-sm">Grade</Label>
                        <Input
                          type="text"
                          name="grade"
                          className="form-control-sm"
                          value={this.state.grade}
                          onChange={this.onChange}
                          required={true}
                        ></Input>
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold label-sm">Mobile No.</Label>
                        <Input
                          type="tel"
                          name="mobile"
                          className="form-control-sm"
                          value={this.state.mobile}
                          onChange={this.onChange}
                          placeholder="Mobile (required)"
                          pattern="[789][0-9]{9}"
                          required={true}
                        />
                        {errors.mobile && (
                          <FormText className="error">{errors.mobile}</FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold label-sm">
                          Personal Email Id
                        </Label>
                        <Input
                          type="email"
                          name="personalEmail"
                          className="form-control-sm"
                          value={this.state.personalEmail}
                          onChange={this.onChange}
                          placeholder="personalEmail (required)"
                        />
                        {errors.personaleEmail && (
                          <FormText className="error">
                            {errors.personaleEmail}
                          </FormText>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold label-sm">Email Id</Label>
                        <Input
                          type="email"
                          name="email"
                          className="form-control-sm"
                          value={this.state.email}
                          onChange={this.onChange}
                          placeholder="email"
                          required={true}
                        />
                        {errors.email && (
                          <FormText className="error">{errors.email}</FormText>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="center">
                    <button type="submit" className="btn btn-custom">
                      Update
                    </button>
                  </div>
                </Form>
              </Col>
            )}
          </React.Fragment>
        ) : (
          <EmployeeInfo back={this.employeeInfoToggle} empId={this.state.id} />
        )}
        <Modal
          size="md"
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="apply-modal"
        >
          <ModalHeader toggle={this.toggle}>
            Terminate or Convert to Employee
          </ModalHeader>
          <ModalBody>
            <Form className="p-3" onSubmit={this.onSubmit} noValidate>
              <FormGroup className="row p-3">
                <Label className="col-md-5 bold">Action</Label>
                <Input
                  type="select"
                  name="action"
                  onChange={this.onChange}
                  value={this.state.action}
                  className="form-control-sm col-md-7"
                  required={true}
                >
                  <option hidden value="">
                    Select...
                  </option>
                  <option value="Terminate">Terminate</option>
                  <option value="Convert">Convert</option>
                </Input>
                {!this.state.action && action && (
                  <FormText className="ReasonError">{action}</FormText>
                )}
                {this.state.action === "Convert" &&
                this.props.employee.empType === "E" ? (
                  <FormText className="ReasonError">
                    you can't convert Employee
                  </FormText>
                ) : null}
              </FormGroup>
              <FormGroup className="row p-3">
                <Label className="col-md-5 bold">Reason</Label>
                <Input
                  type="text"
                  name="reason"
                  onChange={this.onChange}
                  value={this.state.reason}
                  className="form-control-sm col-md-7"
                  required={true}
                />
                {errors.reason && (
                  <FormText className="ReasonError">{errors.reason}</FormText>
                )}
              </FormGroup>
              <FormGroup className="row p-3">
                <Label className="col-md-5 bold">Effective Date</Label>
                <Input
                  type="date"
                  name="effectiveDate"
                  value={this.state.effectiveDate}
                  onChange={this.onChange}
                  min={this.state.created}
                  max={currentDate}
                  className="form-control-sm col-md-7"
                  required={true}
                />
                {errors.effectiveDate && (
                  <FormText className="ReasonError">
                    {errors.effectiveDate}
                  </FormText>
                )}
              </FormGroup>
              {this.state.action === "Convert" &&
              this.props.employee.empType !== "E" ? (
                <React.Fragment>
                  <FormGroup className="row p-3">
                    <Label className="col-md-5 bold">EmpType</Label>
                    <Input
                      name="empType"
                      type="select"
                      value={this.state.empType}
                      className="form-control-sm col-md-7"
                      onChange={this.onChange}
                      required={true}
                    >
                      <option value="" hidden>
                        Select...
                      </option>
                      <option value="E">Employee</option>
                      {/* <option value="T" disabled>
                        Trainee
                      </option>
                      <option value="C" disabled>
                        Contigent
                      </option> */}
                    </Input>
                    {errors.empType && (
                      <FormText className="error">{errors.empType}</FormText>
                    )}
                  </FormGroup>
                  <FormGroup className="row p-3">
                    <Label className="col-md-5 bold">Start Date</Label>
                    <Input
                      type="date"
                      className="form-control-sm col-md-7"
                      min={this.state.effectiveDate}
                      name="startDate"
                      value={this.state.startDate}
                      onChange={this.onChange}
                      required={true}
                    />
                    {errors.startDate && (
                      <FormText className="ReasonError">
                        {errors.startDate}
                      </FormText>
                    )}
                  </FormGroup>
                  <FormGroup className="row p-3">
                    <Label className="col-md-5 bold">Designation</Label>
                    <Input
                      type="text"
                      className="form-control-sm col-md-7"
                      name="designation"
                      value={this.state.designation}
                      onChange={this.onChange}
                      required={true}
                    />
                    {errors.designation && (
                      <FormText className="ReasonError">
                        {errors.designation}
                      </FormText>
                    )}
                  </FormGroup>
                  <FormGroup className="row p-3">
                    <Label className="col-md-5 bold">Remarks</Label>
                    <Input
                      type="textarea"
                      className="form-control-sm col-md-7"
                      name="remarks"
                      value={this.state.remarks}
                      onChange={this.onChange}
                      required={true}
                    />
                    {errors.remarks && (
                      <FormText className="ReasonError">
                        {errors.remarks}
                      </FormText>
                    )}
                  </FormGroup>
                </React.Fragment>
              ) : null}
              <FormGroup className="center">
                <button type="submit" className="btn white-text login-button">
                  Submit
                </button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
        {this.state.message ? <Notifier message={this.state.message} /> : null}
        {this.state.error ? <ErrorNotifier message={this.state.error} /> : null}
        {this.props.employees.message ? (
          <Notifier message={this.props.employees.message} />
        ) : null}
        {this.props.employees.error ? (
          <ErrorNotifier message={this.props.employees.error} />
        ) : null}
      </Row>
    );
  }
}

EmployeeDetails.propTypes = {
  updateEmployee: PropTypes.func.isRequired,
  employees: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getBusinessGeographies1: PropTypes.func.isRequired,
  businessGeographies: PropTypes.object.isRequired,
  getUserOrganization: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  employees: state.employees,
  auth: state.auth,
  busiGeographies: state.busiGeographies,
  businessGeographies: state.businessGeographies,
  busiLocation: state.busiLocation,
  empGrade: state.empGrade,
  organization: state.organization,
  busiUnit: state.busiUnit,
  empDesignation: state.empDesignation,
  empType: state.empType,
});

export default connect(mapStateToProps, {
  updateEmployee,
  getBusinessGeographies,
  getBusinessGeographies1,
  getUserBusinessLocations,
  getEmployeeGrade,
  getUserOrganization,
  getBusinessUnit,
  getEmployeeDesignation,
  getUserEmpType,
})(EmployeeDetails);
