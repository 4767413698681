import React, { Component } from "react";
import { Form, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
} from "../../../../common/Validator";
import FormComponent from "../../../../common/FormComponent"
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import Notifier from "../../../../aside/Notifier";
import {updateEXtraCurricular } from "../../../../../redux/actions/rs/configuration/extracurri";

// update extra curricular component
class UpdateExtraGroup extends Component {
  constructor(props) {
    super(props);
    const { id, extCurActType, groupDescription } = this.props.data;
    
    this.state = {
      userGroupId: id,
      name: extCurActType,
      description: groupDescription,
      error: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
// common onchange function
  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  // submit function
  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      id: this.state.userGroupId,
      extCurActType: this.state.name,
      groupDescription: this.state.description,
      updatedBy: localStorage.getItem("id"),
    };
    if (valid) {
      this.props.updateEXtraCurricular(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    refresh();
    this.setState({
      error: "",
    });
  }

  render() {
    const { name, description, error } = this.state;

    return (
      <React.Fragment>
        <h5 className="center card-heading pt-2">Extracurricular Activities</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="6" className="mx-auto">
              <FormComponent
                labelClass="bold label-sm"
                label="Activity Name"
                type="text"
                name="name"
                inputClass="form-control-sm"
                value={name}
                change={this.onChange}
                required={true}
                length="30"
              />
            
            </Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Update
            </button>
          </div>
        </Form>
        {error && <ErrorNotifier message={error} />}
        {this.props.extraCurricularActivity.error && (
          <ErrorNotifier message={this.props.extraCurricularActivity.error} />
        )}
        {this.props.extraCurricularActivity.message && (
          <Notifier message={this.props.extraCurricularActivity.message} />
        )}
      </React.Fragment>
    );
  }
}

UpdateExtraGroup.propTypes = {
  updateSkillGroup: PropTypes.func.isRequired,
  userGroups: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  extraCurricularActivity: state.extraCurricularActivity,
});

export default connect(mapStateToProps, {updateEXtraCurricular })(UpdateExtraGroup);
