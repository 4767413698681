import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  FormText,
} from "reactstrap";
// components
import FormComponent from "../../../common/FormComponent";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../common/Validator";
import Notifier from "../../../aside/Notifier";
import ErrorNotifier from "../../../aside/ErrorNotifier";
import SearchSelectBoxWithAdd from "../../../common/selectbox/SearchSelectBoxWithAdd";

// redux
import { getCustomersName } from "../../../../redux/actions/crm/customers/customersAction";
import { getCustomerEntityName } from "../../../../redux/actions/crm/customer_entity/customerEntityAction";
import {
  addAccounts,
  getAccountsSpocName,
} from "../../../../redux/actions/crm/accounts/accountsAction";
import { getUserAccountManager } from "../../../../redux/actions/crm/crm_setup/accountMangerAcction";
import { getUserSalesTeam } from "../../../../redux/actions/crm/crm_setup/salesTeamAction";

// ----------------------------------------------------------------------------------------------------------

class AddAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: "",
      entityId: "",
      accountName: "",
      contactId: "",
      contactName: "",
      contactEmail: "",
      dialingCode: "",
      contactNo: "",
      businessUnit: "",
      department: "",
      accountManager: "",
      salesRep: "",
      remarks: "",
      buttonValue: false,
      formErrors: {
        customerName: "",
      },
      spocError: "",
      error: "",
      message: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    e.preventDefault();
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleContactNameChange = (value) => {
    this.setState({
      contactId: value && value.contactId,
      contactName: value && value.contactName,
      contactEmail: value && value.contactEmail,
      dialingCode: value && value.contactDialingCode,
      contactNo: value && value.contactMobileNo,
    });
  };

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let account = {
      customerdetailId: this.state.customerId,
      customerentityId: this.state.entityId,
      accountName: this.state.accountName,
      contactId: this.state.contactId,
      contactName: this.state.contactName,
      businessUnitName: this.state.businessUnit,
      departmentName: this.state.department,
      accountManager: this.state.accountManager,
      salesRep: this.state.salesRep,
      remarks: this.state.remarks,
      accountStatus: "New",
    };
    if (this.state.customerId && valid && this.state.contactName) {
      this.props.addAccounts(account, this.props.history);
      this.setState({
        buttonValue: !this.state.buttonValue,
      });
      // setTimeout(() => {
      //   this.props.back();
      // }, 2000);
    } else {
      let errors = this.state.formErrors;
      errors.customerName = "Please select option";
      this.setState({
        formErrors: errors,
        error: "Enter mandatory fields",
        spocError: this.state.contactName ? "" : "This field is mandatory",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  componentDidMount() {
    this.props.getCustomersName();
    this.props.getCustomerEntityName();

    this.props.getUserAccountManager();
    this.props.getUserSalesTeam();
    refresh();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.entityId !== this.state.entityId) {
      this.props.getAccountsSpocName(
        this.state.customerId,
        this.state.entityId
      );
    }
  }

  resetSpocNameError = () => {
    this.setState({
      spocError: "",
    });
  };

  render() {
    let shrink;
    const { customerName } = this.state.formErrors;
    this.props.sidebar ? (shrink = "scale") : (shrink = "no-scale");

    return (
      <div className={shrink}>
        <Container className="card container-card">
          <h5 className="center bold mt-2">Add Customer Account</h5>
          <Link to="/pie/crm/accounts">
            <i
              className="material-icons pointer"
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                color: "#C00",
              }}
            >
              cancel
            </i>
          </Link>
          <Form
            onSubmit={this.onSubmit}
            className="p-3 mt-2 pl--5 pr--5"
            noValidate
          >
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label className="label-sm bold">Customer Name</Label>
                  <Input
                    type="select"
                    name="customerId"
                    onChange={this.onChange}
                    value={this.state.customerId}
                    required={true}
                    tabIndex={1}
                  >
                    <option hidden>Select...</option>
                    {this.props.customers &&
                      this.props.customers.nameData.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.customerNo}-{item.customerName}
                          </option>
                        );
                      })}
                  </Input>
                  {!this.state.customerId && (
                    <FormText className="error">{customerName}</FormText>
                  )}
                </FormGroup>
                <FormComponent
                  labelClass="bold label-sm"
                  label="Account Name"
                  type="text"
                  name="accountName"
                  change={this.onChange}
                  value={this.state.accountName}
                  placeholder="Account Name"
                  required={true}
                  tabIndex={3}
                />
                <FormComponent
                  labelClass="bold label-sm"
                  label="Department"
                  type="text"
                  name="department"
                  change={this.onChange}
                  value={this.state.department}
                  placeholder="Department"
                  tabIndex={5}
                />
                <FormGroup>
                  <Label className="bold label-sm">Sales Executive</Label>
                  <Input
                    type="select"
                    name="salesRep"
                    value={this.state.salesRep}
                    onChange={this.onChange}
                    className="form-control-sm"
                    tabIndex={7}
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.salesTeam.data.map((item) => {
                      return (
                        <option key={item.id} value={item.empNo}>
                          {item.empNo}-{item.empName}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.salesRep && (
                    <FormText className="error">{errors.salesRep}</FormText>
                  )}
                </FormGroup>
                <FormComponent
                  labelClass="bold label-sm"
                  label="Comments"
                  type="textarea"
                  name="remarks"
                  change={this.onChange}
                  value={this.state.remarks}
                  placeholder="Remarks"
                  tabIndex={9}
                />
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label className="label-sm bold">Customer Entity Name</Label>
                  <Input
                    type="select"
                    name="entityId"
                    onChange={this.onChange}
                    value={this.state.entityId}
                    required={true}
                    tabIndex={2}
                  >
                    <option hidden>Select...</option>
                    {this.props.customerEntity &&
                      this.props.customerEntity.nameData.map((item) => {
                        if (this.state.customerId === item.customerdetailId) {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.entityNo}-{item.entityName}
                            </option>
                          );
                        }
                      })}
                  </Input>
                  {!this.state.entityId && (
                    <FormText className="error">{customerName}</FormText>
                  )}
                </FormGroup>
                <FormComponent
                  labelClass="bold label-sm"
                  label="Business Unit"
                  type="text"
                  name="businessUnit"
                  change={this.onChange}
                  value={this.state.businessUnit}
                  placeholder="Business Unit"
                  required={true}
                  tabIndex={4}
                />
                <Row>
                  <Col md="6" className="p-0">
                    <SearchSelectBoxWithAdd
                      options={this.props.accounts.spocNameData}
                      value={this.state.contactName}
                      handleChange={(value) =>
                        this.handleContactNameChange(value)
                      }
                      reset={this.resetSpocNameError}
                      spocError={this.state.spocError}
                      label="SPOC Name"
                      customerName={this.state.customerId}
                      entityName={this.state.entityId}
                      whichSpoc="account"
                      tabIndex={6}
                    />
                  </Col>
                  <Col md="6" className="pr-0">
                    <ul className="CustDetails-ul">
                      <li>
                        <span className="label-sm bold customer-span-label-col-1">
                          Email
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;{this.state.contactEmail}
                        </span>
                      </li>
                      <li>
                        <span className="label-sm bold customer-span-label-col-1">
                          Mobile
                        </span>
                        <span className=" label-sm">
                          &nbsp;&nbsp;:&nbsp;&nbsp;
                          {this.state.dialingCode &&
                            `${this.state.dialingCode}-`}
                          {this.state.contactNo}
                        </span>
                      </li>
                    </ul>
                  </Col>
                </Row>
                <FormGroup>
                  <Label className="bold label-sm">Account Manager</Label>
                  <Input
                    type="select"
                    name="accountManager"
                    value={this.state.accountManager}
                    onChange={this.onChange}
                    className="form-control-sm"
                    tabIndex={8}
                  >
                    <option value="" hidden>
                      Select...
                    </option>
                    {this.props.accountManager.data.map((item) => {
                      return (
                        <option key={item.id} value={item.empNo}>
                          {item.empNo}-{item.empName}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.accountManager && (
                    <FormText className="error">
                      {errors.accountManager}
                    </FormText>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <div className="center">
              <button
                type="submit"
                className="btn center login-button white-text"
                tabIndex={10}
              >
                Submit
              </button>
            </div>
          </Form>
        </Container>
        {this.props.accounts.message ? (
          <Notifier message={this.props.accounts.message} />
        ) : null}
        {this.props.accounts.error ? (
          <ErrorNotifier message={this.props.accounts.error} />
        ) : null}
      </div>
    );
  }
}

AddAccount.propTypes = {
  addAccounts: PropTypes.func.isRequired,
  getAccountsSpocName: PropTypes.func.isRequired,
  accounts: PropTypes.object.isRequired,
  getCustomersName: PropTypes.func.isRequired,
  customers: PropTypes.object.isRequired,
  getCustomerEntityName: PropTypes.func.isRequired,
  customerEntity: PropTypes.object.isRequired,
  getUserAccountManager: PropTypes.func.isRequired,
  accountManager: PropTypes.object.isRequired,
  getUserSalesTeam: PropTypes.func.isRequired,
  salesTeam: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  accounts: state.accounts,
  customers: state.customers,
  customerEntity: state.customerEntity,
  accountManager: state.accountManager,
  salesTeam: state.salesTeam,
});

export default connect(mapStateToProps, {
  addAccounts,
  getAccountsSpocName,
  getCustomersName,
  getCustomerEntityName,
  getUserAccountManager,
  getUserSalesTeam,
})(AddAccount);
