/*  eslint-disable  */
import axios from "axios";
import {
  ADD_NEW_CUSTOMER,
  GET_CUSTOMER_LIST,
  CUSTOMER_UPDATE,
  CUSTOMER_SEARCH,
  GET_ERRORS,
} from "./types";
import {
  ADD_CUSTOMER,
  GET_CUSTOMER,
  UPDATE_CUSTOMER,
  SEARCH_CUSTOMERS,
} from "../../utils/routes";
import { setMessage } from "./salesActions";

export const addCompany = (data, history) => (dispatch) => {
  axios
    .post(ADD_CUSTOMER, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
        history.push("/pie/crm/customers");
      }, 2000);
    })
    .catch((err) => {
      console.log(err.message);
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
      setTimeout(() => {
        dispatch({
          type: GET_ERRORS,
          payload: null,
        });
      }, 2000);
    });
};

export const getCustomers = () => (dispatch) => {
  axios
    .get(GET_CUSTOMER, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: GET_CUSTOMER_LIST,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};

export const updateCustomer = (data, history) => (dispatch) => {
  axios
    .post(UPDATE_CUSTOMER, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
      }, 2000);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};

export const searchCustomer = (data) => (dispatch) => {
  axios
    .post(SEARCH_CUSTOMERS, data, { withCredentials: true })
    .then((res) => {
      dispatch({
        type: CUSTOMER_SEARCH,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};

export const addLeadCompany = (data, history) => (dispatch) => {
  axios
    .post(ADD_CUSTOMER, data, { withCredentials: true })
    .then((res) => {
      dispatch(setMessage(res.data));
      setTimeout(() => {
        dispatch(setMessage(null));
      }, 2000);
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message,
      });
    });
};
