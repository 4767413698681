import React, { Component } from "react";
import { Form, Row, Col, FormGroup, Label, Input, FormText } from "reactstrap";
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
} from "../../../../common/Validator";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// components
import FormComponent from "../../../../common/FormComponent";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
// redux
import { addRequestType } from "../../../../../redux/actions/experience_360/e360_config/requestTypeAction";

// -------------------------------------------------------------------------------------------------

class AddRequestType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestType: "",
      color: "#000000",
      description: "",
      error: "",
      message: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    Validator(e.target);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    let data = {
      reqTypeName: this.state.requestType,
      color: this.state.color,
      reqTypeDescription: this.state.description,
    };
    if (valid) {
      this.props.addRequestType(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidMount contains the error refresh method and setState method for calling the render method
*/
  componentDidMount() {
    refresh();
    this.setState({
      error: "",
    });
  }

  render() {
    const { requestType, color, description, error } = this.state;

    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Add Request Type</h5>
        <i
          className="material-icons pointer cancel-button"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="6" className="mx-auto">
              <Row>
                <Col md="10" className="p-0">
                  <FormComponent
                    labelClass="bold label-sm"
                    label="Request Type"
                    type="text"
                    name="requestType"
                    inputClass="form-control-sm"
                    value={requestType}
                    change={this.onChange}
                    required={true}
                  />
                </Col>
                <Col md="2" className="pr-0">
                  <FormComponent
                    labelClass="bold label-sm"
                    label="Color"
                    type="color"
                    name="color"
                    inputClass="form-control-sm"
                    value={color}
                    change={this.onChange}
                  />
                </Col>
              </Row>

              <FormComponent
                labelClass="bold label-sm"
                label="Description"
                type="textarea"
                name="description"
                inputClass="form-control-sm"
                value={description}
                change={this.onChange}
              />
            </Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Add
            </button>
          </div>
        </Form>
        {error && <ErrorNotifier message={error} />}
        {this.props.requestType.error && (
          <ErrorNotifier message={this.props.requestType.error} />
        )}
        {this.props.requestType.message && (
          <ErrorNotifier message={this.props.requestType.message} />
        )}
      </React.Fragment>
    );
  }
}

AddRequestType.propTypes = {
  addRequestType: PropTypes.func.isRequired,
  requestType: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  requestType: state.requestType,
});

export default connect(mapStateToProps, { addRequestType })(AddRequestType);
