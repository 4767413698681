import React, { Component } from "react";
import { Container, Table } from "reactstrap";
import dateFns from "date-fns";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// components
import AddRole from "./AddRole";
import UpdateRole from "./UpdateRole";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
import ConfirmDialog from "../../../../common/confirm-dialog/ConfirmDialog";
// redux
import {
  getRole,
  deleteRole,
  searchRole,
} from "../../../../../redux/actions/iam/auth_configuration/role_management/roleAction";

// ----------------------------------------------------------------------------------------
class Roles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addBtn: false,
      updateBtn: false,
      updateData: [],
      searchTerm: "",
      confirmDialog: {
        isOpen: false,
        title: "",
        subTitle: "",
      },
      error: "",
      message: "",
    };

    this.toggleAddBtn = this.toggleAddBtn.bind(this);
    this.addBack = this.addBack.bind(this);
    this.toggleUpdateBtn = this.toggleUpdateBtn.bind(this);
    this.updateBack = this.updateBack.bind(this);
    this.getData = this.getData.bind(this);
    this.deleteRole = this.deleteRole.bind(this);
    this.search = this.search.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  /*
toggleAddBtn function to toggle the addition page
*/
  toggleAddBtn() {
    this.setState({
      addBtn: true,
    });
  }

  /*
addBack function to back to the list page from the add page
*/
  addBack() {
    this.setState({
      addBtn: false,
    });

    this.getData();
  }

  /*
toggleUpdateBtn function to toggle the update form
*/
  toggleUpdateBtn(item) {
    this.setState({
      updateBtn: true,
      updateData: item,
    });
  }

  /*
updateBack function to redirect to back main page
*/
  updateBack() {
    this.setState({
      updateBtn: false,
    });

    this.getData();
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.props.getRole();
  }

  deleteRole(id) {
    let data = {
      id: id,
    };
    this.props.deleteRole(data, this.getData);
    this.setState((prevState) => ({
      ...prevState.confirmDialog,
      confirmDialog: {
        isOpen: false,
      },
    }));
  }

  setConfirmDialog = (obj) => {
    this.setState((prevProps) => ({
      confirmDialog: {
        ...prevProps.confirmDialog,
        isOpen: obj.isOpen,
      },
    }));
  };

  search(e) {
    e.preventDefault();
    this.setState({
      searchTerm: e.target.value,
    });

    this.onSearch(e.target.value);
  }

  onSearch(value) {
    let data = {
      role: value,
    };

    this.props.searchRole(data);
  }

  render() {
    const { addBtn, updateBtn, updateData, searchTerm, message, error } =
      this.state;
    let shrink = this.props.sidebar ? "scale" : "no-scale";

    return (
      <div className={shrink}>
        <Container className="card container-card">
          {!addBtn && !updateBtn ? (
            <React.Fragment>
              {typeof this.props.location.serviceAccessData !== "undefined" &&
                this.props.location.serviceAccessData.creation && (
                  <button
                    className="btn add-button white-text label-sm"
                    onClick={this.toggleAddBtn}
                  >
                    Add
                  </button>
                )}
              <h5 className="bold center pt-2">Roles</h5>
              <input
                type="text"
                onChange={this.search}
                value={searchTerm}
                className="searchfield form-control-sm mr-3"
                placeholder="Role"
              />
              <Link
                to={{
                  pathname: "/pie/iam/service_menu",
                  moduleName: this.props.location.moduleName,
                  data: this.props.location.data,
                }}
              >
                <i className="material-icons cancel-button pointer">cancel</i>
              </Link>
              <Table className="center">
                <thead>
                  <tr>
                    <th className="opp-head ">Group</th>
                    <th className="opp-head ">Role Code</th>
                    <th className="opp-head ">Role Name</th>
                    <th className="opp-head ">Description</th>
                    <th className="opp-head ">CreatedBy</th>
                    <th className="opp-head ">CreatedOn</th>
                    <th className="opp-head ">UpdatedBy</th>
                    <th className="opp-head ">UpdatedOn</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.roles.data.length > 0 &&
                    this.props.roles.data.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td className="opp-tableData">
                            {item.pieUserGroup.groupName}
                          </td>
                          <td className="opp-tableData">{item.roleCode}</td>
                          <td className="opp-tableData">{item.roleName}</td>
                          <td className="opp-tableData">
                            {item.roleDescription}
                          </td>
                          <td className="opp-tableData">{item.createdBy}</td>
                          <td className="opp-tableData">
                            {item.createdOn &&
                              dateFns.format(item.createdOn, "DD/MM/YYYY")}
                          </td>
                          <td className="opp-tableData">{item.updatedBy}</td>
                          <td className="opp-tableData">
                            {item.updatedOn &&
                              dateFns.format(item.updatedOn, "DD/MM/YYYY")}
                          </td>
                          {typeof this.props.location.serviceAccessData !==
                            "undefined" &&
                            this.props.location.serviceAccessData.updation && (
                              <td>
                                <span
                                  className="material-icons pointer opp-action-edit"
                                  style={{ color: "blue" }}
                                  onClick={() => this.toggleUpdateBtn(item)}
                                >
                                  edit
                                </span>
                              </td>
                            )}
                          {typeof this.props.location.serviceAccessData !==
                            "undefined" &&
                            this.props.location.serviceAccessData.deletion && (
                              <td>
                                <i
                                  className="material-icons pointer"
                                  onClick={() =>
                                    this.setState({
                                      confirmDialog: {
                                        isOpen: true,
                                        title:
                                          "Are you sure to delete this record?",
                                        subTitle:
                                          "You can't undo this operation",
                                        onConfirm: () =>
                                          this.deleteRole(item.id),
                                      },
                                    })
                                  }
                                >
                                  delete
                                </i>
                              </td>
                            )}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </React.Fragment>
          ) : null}
          {addBtn && <AddRole back={this.addBack} />}
          {updateBtn && <UpdateRole back={this.updateBack} data={updateData} />}
          {message && <Notifier message={message} />}
          {error && <ErrorNotifier message={error} />}
          {this.props.roles.message && (
            <Notifier message={this.props.roles.message} />
          )}
          {this.props.roles.error && (
            <ErrorNotifier message={this.props.roles.error} />
          )}
        </Container>
        <ConfirmDialog
          confirmDialog={this.state.confirmDialog}
          setConfirmDialog={this.setConfirmDialog}
        />
      </div>
    );
  }
}

Roles.propTypes = {
  getRole: PropTypes.func.isRequired,
  deleteRole: PropTypes.func.isRequired,
  searchRole: PropTypes.func.isRequired,
  roles: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  roles: state.roles,
});

export default connect(mapStateToProps, { getRole, deleteRole, searchRole })(
  Roles
);
