import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import clsx from "clsx";

// material ui
import {
  Card,
  CardHeader,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  IconButton,
  Button,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";

// redux actions import
import { createAccount } from "../../../redux/actions/auth/createAccountAction";

// components
import { Validator, errors } from "../../common/Validator";
import Notifier from "../../aside/Notifier";
import ErrorNotifier from "../../aside/ErrorNotifier";

// --------------------------------------------------------
const useStyles = makeStyles((theme) => ({
  card: {
    width: 400,
    height: 300,
    textAlign: "center",
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: "40ch",
  },
  card_header: {},
}));

// ---------------------------------------------------------
export const CreateAccount = (props) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    password: null,
    confirmPassword: null,
    showPassword: false,
    empId: "",
    tempPassword: "",
    error: null,
    passError: null,
    confirmPassError: null,
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    setValues({
      empId: queryParams.get("ssdic"),
      tempPassword: queryParams.get("ssocu"),
    });
  }, []);

  const handleChange = (prop) => (event) => {
    Validator(event);
    if (prop === "password") {
      if (
        RegExp(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
        ).test(event.target.value)
      ) {
        setValues({ ...values, [prop]: event.target.value, passError: null });
      } else {
        setValues({
          ...values,
          [prop]: event.target.value,
          passError:
            "Password length atleast 8 characters (1 lowerCase, 1 upperCase, 1 number, 1 special character)",
        });
      }
    }
    if (prop === "confirmPassword") {
      if (
        RegExp(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
        ).test(event.target.value)
      ) {
        setValues({
          ...values,
          [prop]: event.target.value,
          confirmPassError: null,
        });
      } else {
        setValues({
          ...values,
          [prop]: event.target.value,
          confirmPassError:
            "Password length atleast 8 characters (1 lowerCase, 1 upperCase, 1 number, 1 special character)",
        });
      }
    }
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const createAccount = (e) => {
    e.preventDefault();
    let data = {
      ssdic: values.empId,
      ssocu: values.tempPassword,
      password: values.password,
    };
    if (values.password === values.confirmPassword) {
      props.createAccount(data);
    } else {
      setValues({
        error: "Password do not match",
      });
      setTimeout(() => {
        setValues({
          error: null,
        });
      }, 5000);
    }
  };

  return (
    <React.Fragment>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={12} md={6}>
          <Card className={classes.card}>
            <CardHeader title="Activate User" className={classes.card_header} />
            <FormControl
              className={clsx(classes.margin, classes.textField)}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                name="password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
              {values.passError && (
                <FormHelperText id="component-helper-text" color="red">
                  {values.passError}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              className={clsx(classes.margin, classes.textField)}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Confirm Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={values.confirmPassword}
                onChange={handleChange("confirmPassword")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
              {values.confirmPassError && (
                <FormHelperText id="component-helper-text" color="red">
                  {values.confirmPassError}
                </FormHelperText>
              )}
            </FormControl>
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={createAccount}
              >
                Submit
              </Button>
            </Box>
          </Card>
        </Grid>
        {props.createAccountValues.message && (
          <Notifier message={props.createAccountValues.message} />
        )}
        {props.createAccountValues.error && (
          <ErrorNotifier message={props.createAccountValues.error} />
        )}
        {values.error && <ErrorNotifier message={values.error} />}
      </Grid>
    </React.Fragment>
  );
};

CreateAccount.propTypes = {
  props: PropTypes,
  createAccount: PropTypes.func.isRequired,
  createAccountValues: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  createAccountValues: state.createAccountValues,
});

const mapDispatchToProps = (dispatch) => ({
  createAccount: (data) => dispatch(createAccount(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount);
