import axios from "axios";
import { SKILL_UPLOAD_RS} from "../../../../utils/routes";
import { SET_MESSAGE, GET_PROCESSED_RS_SKILLS,GET_EXTRACTED_RS_SKILLS,GET_UNPROCESSED_RS_SKILLS } from "../../types";
import setSuccessMessage from "../../common/setSuccessMessage";
import { push } from 'react-router-redux';
import { useHistory, useLocation } from "react-router-dom";
import { Redirect } from 'react-router'
import setErrorMessage from "../../common/setErrorMessage";

export const uploadCanSkillRs = (data) => (dispatch) => {

  axios
    .post(SKILL_UPLOAD_RS, data, { withCredentials: true })
    .then((res) => {
        console.log(res)
      dispatch({
        type: SET_MESSAGE,
        payload: res.data.message,
      });
      dispatch({
        type: GET_EXTRACTED_RS_SKILLS,
        payload: res.data.extracted,
      });
      dispatch({
        type:GET_PROCESSED_RS_SKILLS ,
        payload: res.data.processed,
      });
      dispatch({
        type:GET_UNPROCESSED_RS_SKILLS,
        payload: res.data.unprocessed,
      });

      setTimeout(() => {
        dispatch({
          type: SET_MESSAGE,
          payload: "",
        });
       }, 2000);
    })
    .catch((err) => {
      dispatch(setErrorMessage(err));
    });
};
