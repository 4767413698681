import React, { Component } from "react";
import { Row, Col, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DateFns from "date-fns";
// components
import {
  Validator,
  submitValidator,
  valid,
  refresh,
  errors,
  PercentageValidation,
} from "../../../../common/Validator";
import Notifier from "../../../../aside/Notifier";
import ErrorNotifier from "../../../../aside/ErrorNotifier";
// redux
import { addEducationDetails } from "../../../../../redux/actions/hcm/my_profile/educationDetailsAction";
import { getUserEmpQualification } from "../../../../../redux/actions/hcm/configurations/empQualificationAction";

// -----------------------------------------------------------------------------------------------------------

class AddEducation extends Component {
  constructor() {
    super();
    this.state = {
      qualification: "",
      specialization: "",
      institute: "",
      university: "",
      completion: "",
      grade: "",
      percentage: "",
      message: "",
      error: "",
      dateError: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    if (e.target.name === "percentage") {
      PercentageValidation(e.target);
    } else {
      Validator(e.target);
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    submitValidator(e.target);
    const data = {
      empQual: this.state.qualification,
      empSpeci: this.state.specialization,
      instiName: this.state.institute,
      univName: this.state.university,
      compDate: this.state.completion,
      grade: this.state.grade,
      percentage: this.state.percentage,
    };
    if (valid) {
      this.props.addEducationDetails(data, this.props.back);
    } else {
      this.setState({
        error: "Enter all mandatory fields",
      });
      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);
    }
  }

  /*
componentDidMount function contains the form error data refresh method
*/
  componentDidMount() {
    refresh();
    this.setState({
      message: "",
      error: "",
    });
    this.props.getUserEmpQualification();
  }

  render() {
    return (
      <React.Fragment>
        <h5 className="center bold pt-2">Add Education </h5>
        <i
          className="material-icons cancel-button pointer"
          onClick={this.props.back}
        >
          cancel
        </i>
        <Form onSubmit={this.onSubmit} className="p-3" noValidate>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label className="label-sm bold">Qualification</Label>
                <Input
                  type="select"
                  name="qualification"
                  value={this.state.qualification}
                  onChange={this.onChange}
                  className="form-control-sm"
                  required={true}
                >
                  <option value="" hidden>
                    Select...
                  </option>
                  {this.props.empQualification.data.length > 0 &&
                    this.props.empQualification.data.map((item) => {
                      return (
                        <option key={item.id} value={item.qualification}>
                          {item.qualification}
                        </option>
                      );
                    })}
                </Input>
                {errors.qualification && (
                  <FormText className="error">{errors.qualification}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="label-sm bold">Specialization</Label>
                <Input
                  type="text"
                  name="specialization"
                  value={this.state.specialization}
                  onChange={this.onChange}
                  className="form-control-sm"
                  required={true}
                />
                {errors.specialization && (
                  <FormText className="error">{errors.specialization}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="label-sm bold">Institution</Label>
                <Input
                  type="text"
                  name="institute"
                  value={this.state.institute}
                  onChange={this.onChange}
                  className="form-control-sm"
                  required={true}
                />
                {errors.institute && (
                  <FormText className="error">{errors.institute}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="label-sm bold">University</Label>
                <Input
                  type="text"
                  name="university"
                  value={this.state.university}
                  onChange={this.onChange}
                  className="form-control-sm"
                  required={true}
                />
                {errors.university && (
                  <FormText className="error">{errors.university}</FormText>
                )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label className="label-sm bold">Completed on</Label>
                <Input
                  type="date"
                  name="completion"
                  value={this.state.completion}
                  onChange={this.onChange}
                  className="form-control-sm"
                  required={true}
                  max={DateFns.format(new Date(), "YYYY-MM-DD")}
                />
                {errors.completion && (
                  <FormText className="error">{errors.completion}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="label-sm bold">Grade</Label>
                <Input
                  type="text"
                  name="grade"
                  value={this.state.grade}
                  onChange={this.onChange}
                  className="form-control-sm"
                  required={true}
                />
                {errors.grade && (
                  <FormText className="error">{errors.grade}</FormText>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="label-sm bold">Percentage</Label>
                <Input
                  type="number"
                  name="percentage"
                  value={this.state.percentage}
                  onChange={this.onChange}
                  className="form-control-sm"
                  required={true}
                />
                {errors.percentage && (
                  <FormText className="error">{errors.percentage}</FormText>
                )}
              </FormGroup>
            </Col>
          </Row>
          <div className="center">
            <button type="submit" className="btn login-button white-text">
              Add
            </button>
          </div>
        </Form>
        {this.props.educationDetails.message ? (
          <Notifier message={this.props.educationDetails.message} />
        ) : null}
        {this.props.educationDetails.error ? (
          <ErrorNotifier message={this.props.educationDetails.error} />
        ) : null}
      </React.Fragment>
    );
  }
}

AddEducation.propTypes = {
  addEducationDetails: PropTypes.func.isRequired,
  educationDetails: PropTypes.object.isRequired,
  getUserEmpQualification: PropTypes.func.isRequired,
  empQualification: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  educationDetails: state.educationDetails,
  empQualification: state.empQualification,
});

export default connect(mapStateToProps, {
  addEducationDetails,
  getUserEmpQualification,
})(AddEducation);
